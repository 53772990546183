<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-08 18:18:34
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="Card">
    <div class="listItem" @click="goDetails">
      <div class="itemTitle">{{cardData.projectName}}</div>
      <div class="line1">
        <div class="itemTotal">
          <span style="color: #9299a9;">{{$t('M.Total Target')}}</span>
          ：{{cardData.amount}}{{cardData.coinName}}
        </div>
        <div class="itemOver">
          <span style="color: #9299a9;">{{$t('M.complete')}}</span>
          :{{cardData.appliedPercent}}%
        </div>
      </div>
      <div class="line2">
        <span style="color: #9299a9;">{{$t('M.Conversion Ratio')}}</span>
        ：1{{cardData.coinName}}={{cardData.price}}{{cardData.priceCoinName}}
      </div>

      <div class="timeBox" v-if="state=='coming' || state=='ongoing'">
        <img src="../../assets/img/IEO/time.png" alt="" class="timeIcon" style="width:13px;height:13px;">
        <!-- 准备开始 -->
        <div class="time" v-if="state=='coming'">{{$t('M.Distance Start')}}{{Days}}{{$t('M.Day-3')}} {{Hours}}:{{Minutes}}:{{Seconds}}</div>
        <div class="btn" v-if="state=='coming'">{{$t('M.Preheating')}}</div>
        <!-- 开始中 -->
        <div class="time" v-if="state=='ongoing'">{{$t('M.Time_Left')}}：{{Days}}{{$t('M.Day-3')}} {{Hours}}:{{Minutes}}:{{Seconds}}</div>
        <div class="btn" v-if="state=='ongoing'">{{$t('M.Participate Order')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      link: '',
      showTimeDown: '',
      state: ''
    }
  },
  props: {
    cardData: Object,
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    Days() {
      return `${this.formatSeconds(this.showTimeDown)}`.split("_")[0];
    },
    // 倒计时 小时
    Hours() {
      return `${this.formatSeconds(this.showTimeDown)}`.split("_")[1];
    },
    Minutes() {
      return `${this.formatSeconds(this.showTimeDown)}`.split("_")[2];
    },
    Seconds() {
      return `${this.formatSeconds(this.showTimeDown)}`.split("_")[3];
    },
  },
  mounted() {
    this.state = this.cardData.status;
    this.changeSate()
  },
  methods: {

    changeSate() {
      switch (this.state) {
        case "coming":
          this.showTimeDown = this.cardData.startCountDown;
          break;
        case "ongoing":
          this.showTimeDown = this.cardData.endCountDown;
          break;
      }
      if (this.showTimeDown > 0) {
        this.timeObj = setInterval(() => {
          this.showTimeDown = this.showTimeDown - 1000;
          if (this.showTimeDown <= 0) {
            clearInterval(this.timeObj)
            this.$emit('updata')
          }
        }, 1000);
      } else {
        this.showTimeDown = 0;
      }
    },
    goDetails() {
      this.$router.replace({
        name: 'IEOdetails', query: { id: this.cardData.id }
      })
    },
    formatSeconds(date) {
      function formatTime(time) {
        if (time < 10) time = `0${parseInt(time)}`
        return time
      }
      const timeObj = this.getDateTime(date)
      let {
        dayTime,
        hourTime,
        minuteTime,
        secondTime
      } = timeObj
      let result
      result = `${dayTime}_${formatTime(hourTime)}_${formatTime(minuteTime)}_${formatTime(secondTime)}`
      return result
    },
    getDateTime(date) {
      // 获取总秒数
      let secondTime = parseInt(date / 1000)
      let dayTime = 0 // 天
      let minuteTime = 0 // 分
      let hourTime = 0 // 小时

      // 如果秒数大于60，将秒数转换成整数
      if (secondTime >= 60) {
        // 获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60)
        // 获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60)
        // 如果分钟大于60，将分钟转换成小时
        if (minuteTime >= 60) {
          // 获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60)
          // 获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60)
        }
        if (hourTime >= 24) {
          dayTime = parseInt(hourTime / 24)
          hourTime = parseInt(hourTime % 24)
        }
      }
      return {
        dayTime,
        hourTime,
        minuteTime,
        secondTime
      }
    }

  },
  destroyed() {
    clearInterval(this.timeObj)
  }
}
</script>

<style lang="scss" scoped>
.Card {
  font-size: 12px;
  .listItem {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 10px;
    line-height: 24px;
    margin-bottom: 15px;
    .itemTitle {
      font-weight: 600;
    }
    .line1 {
      display: flex;
      .itemTotal {
        flex: 1.2;
      }
      .itemOver {
        flex: 1;
      }
    }

    .timeBox {
      line-height: 36px;
      display: flex;
      justify-content: space-between;
      position: relative;
      .timeIcon {
        position: absolute;
        left: 0;
        bottom: 11px;
      }
      .time {
        margin-left: 20px;
        color: #00b897;
      }
      .btn {
        width: 90px;
        height: 30px;
        background: #00b897;
        border-radius: 4px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        line-height: 14px;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>