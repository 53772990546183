<template>
  <div class="container transfer">
    <nav-bar :showTitle="false" :showRight="false" :border="true">
      <router-link slot="title" class="title" :to="{path:'recharging',query:{ type: 'transfer', back: true }}">
        {{query.coinName}} {{$t('M.Transfer')}}
        <img src="@/assets/img/assets/icon_down.png" alt="">
      </router-link>
      <router-link slot="right" class="right" :to="{path:'transferRecord'}">{{$t('M.Historical record')}}</router-link>
    </nav-bar>

    <div class="transfer_box">
      <div class="transfer_box_left">
        <div class="left_top">
          <div @click="!transferStatus?showType = !showType:''">
            <span class="span_first">{{$t('M.assets_transfer_dialog_from')}}</span>
            <span class="fromAccount"> {{accountOptions[fromAccount]}}</span>
            <img v-if="!transferStatus" src="@/assets/img/assets/icon_san.png" alt="">
          </div>
        </div>
        <div class="left_bottom">
          <div @click="transferStatus?showType = !showType:''">
            <span class="span_first">{{$t('M.assets_transfer_to')}}</span>
            <span class="toAccount"> {{accountOptions[toAccount]}}</span>
            <img v-if="transferStatus" src="@/assets/img/assets/icon_san.png" alt="">
          </div>
        </div>
      </div>
      <div class="transfer_box_right">
        <img @click.stop="onClickChange" src="@/assets/img/assets/icon_huaz.png" alt="">
      </div>
    </div>

    <div class="transfer_num">
      <div class="tit">{{$t('M.assets_transfer_count')}}</div>

      <van-field v-model="totalStr" center :placeholder="$t('M.input_quantity')" :formatter="formatter">
        <div class="button" slot="button" @click="onClickAll">{{$t('M.all')}}</div>
      </van-field>
      <div class="transfer_num_desc">{{$t('M.assets_can_transfer')}}{{query.coinName}}：{{total}}</div>

    </div>

    <div class="btn_wrap">
      <div class="button" @click="onSubmit">{{$t('M.Transfer')}}</div>
      <div class="desc">{{$t('M.assets_wallet_assets_transfer_tip')}}</div>
    </div>

    <!-- 账户类型 -->
    <van-action-sheet v-model="showType" :actions="typeList" :cancel-text="$t('M.Cancel')" @select="onSelect" @cancel="onCancel" />
  </div>
</template>

<script>
import navBar from '@/components/navBar';
import {mapGetters} from 'vuex'
import {Toast} from "vant";

export default {
  name: 'transfer',
  components: {
    navBar
  },
  computed: {
    ...mapGetters([
      'selectCoin', 'partnerId',
    ]),
    type() {
      return this.fromAccount + '_TO' + '_' + this.toAccount
    }
  },
  data() {
    return {
      query: {},
      totalStr: '',
      fromAccount: 'ACCOUNT',
      toAccount: '',
      transferStatus: true,
      total: 0,
      typeList: [],

      showType: false,
      showTypevalue: '',

      accountOptions: {
        ACCOUNT: this.$t('M.assets_transfer_dialog_wallet'),
        CONTRACT: this.$t('M.Sustainable account'),
        OPTION_NEW: this.$t('M.Sec Swap Account'),
        BB: this.$t('M.assets_transfer_dialog_bb'),
        OPTION: this.$t('M.Option Account'),
        OTC: this.$t('M.ios_法币') + this.$t('M.otc_account'),
      },

    };
  },
  beforeMount() {
    this.initQuery()
  },
  mounted() {
    this.getTransferCoin()

  },

  methods: {

    initQuery() {
      if (!this.$route.query.coinId || this.selectCoin.back) {
        this.query = { ...this.$route.query, ...this.selectCoin }
      } else {
        this.query = { ...this.selectCoin, ...this.$route.query }
      }

    },

    getTransferCoin() {
      this.$api.transferCoin(this.query.coinId, this.fromAccount, this.partnerId).then((response) => {
        if (!response.meta.success) return
        const data = JSON.parse(JSON.stringify(response.data))
        this.total = data.total * 1
        if (this.typeList.length) return
        const excludeTypes = new Set(['OPTION_CYCLE']);
        data.typeList = data.typeList.filter(item => !excludeTypes.has(item));
        this.typeList = data.typeList && data.typeList.map((item, i) => (i == 0 ? {
          id: i,
          name: this.accountOptions[item],
          value: item,
          color: '#00b897'
        } : {id: i, name: this.accountOptions[item], value: item, color: ''}))
        this.toAccount = this.typeList && this.typeList[0].value
      })
    },


    // 全选
    onClickAll() {
      if (this.total >= 0) {
        this.totalStr = this.total
      }
    },


    async onClickChange() {
      if (this.toAccount === "CONTRACT") {
        Toast.loading({
          forbidClick: true,
          duration: 0,
        });
        const res = await this.$api.currentPosition({positionsType: ''})
        Toast.clear();
        if (res.data.list.length > 0) {
          this.$notify(this.$t("M.TransferRequiresClosing"))
          return
        }
      }

      this.transferStatus = !this.transferStatus
      let str = this.fromAccount
      this.fromAccount = this.toAccount
      this.toAccount = str
      this.totalStr = ''
      this.getTransferCoin()
    },


    // 账户类型
    onSelect(item) {
      this.showType = false;
      if (this.transferStatus) {
        this.toAccount = item.value
      } else {
        this.fromAccount = item.value
      }
      this.typeList.forEach(el => {
        el.color = ''
        if (el.id == item.id) {
          el.color = '#00b897'
        }
      });
      this.getTransferCoin()
    },
    onCancel() { this.showType = false; },
    // 划转
    onSubmit() {
      const params = {
        type: this.type,
        totalStr: this.totalStr,
        coinId: this.query.coinId
      }
      if (!this.totalStr || this.totalStr <= 0) return this.$notify(this.$t('M.account_failure_00103'))
      if (this.totalStr > this.total) return this.$notify(this.$t('M.inputs_numbers_greater_can_number'))
      this.$api.accountTransfer(params).then((response) => {
        if (response.meta.success) {
          this.$notify(response.meta.message)
          this.totalStr = ''
          this.fromAccount = 'ACCOUNT'
          this.$store.dispatch('getUserWalletAssets')
          this.$router.back()
        }
      })

    },


  },

  destroyed() {
    this.$store.dispatch('resetSelectCoin')
  }
};
</script>

<style lang="scss" scoped>
.transfer {
  font-size: 16px;
  padding: 0 15px;
}
.title {
  font-size: 18px;
  font-weight: 400;
  color: #191b27;
}
.right {
  font-size: 14px;
  font-weight: 400;
  color: #5c6573;
}

.transfer_box {
  width: 100%;
  height: 86px;
  border-radius: 4px;
  border: 1px solid #c7ccd8;
  margin: 25px 0 10px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .transfer_box_left {
    .left_top,
    .left_bottom {
      width: 255px;
      height: 42px;
      line-height: 42px;
      font-size: 14px;
      font-weight: 400;
      color: #191b27;
      position: relative;
      .span_first {
        color: #9299a9;
        margin-right: 15px;
      }
      img {
        width: 8px;
        height: 8px;
        margin: 5px;
        position: absolute;
        right: 0;
        bottom: 7px;
      }
    }
    .left_top {
      border-bottom: 1px solid #ebebf0;
    }
  }
  .transfer_box_right {
    img {
      width: 55px;
      height: 70px;
    }
  }
}
.transfer_num {
  font-size: 12px;
  font-weight: 400;
  .tit {
    height: 40px;
    color: #191b27;
    line-height: 40px;
  }
  .button {
    width: 58px;
    height: 100%;
    text-align: center;
    border-left: 1px solid #ebebf0;
    color: #00b897;
  }
  .transfer_num_desc {
    height: 40px;
    font-size: 12px;
    line-height: 40px;
    color: #9299a9;
  }
}

::v-deep .van-cell {
  padding-right: 0;
}
::v-deep .transfer_num .van-field {
  width: 100%;
  height: 43px;
  border-radius: 4px;
  border: 1px solid #c7ccd8;
  color: #9299a9;
}
</style>