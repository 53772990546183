<template>
  <div class="newsContent container">
    <van-nav-bar :title="title" left-text="" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div class="box">
      <div v-html="content"></div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      listNew: [],
      refreshing: false,
      finished: false,
      loading: false,
      content: '',
      title: ''
    }
  },
  computed: {
    ...mapGetters([
      'partnerId', 'lang', "isLogin"
    ]),
  },
  mounted() {
    this.loadList()
    if (this.isLogin) {
      this.$api.updatePopupStatus({ id: this.$route.query.id }).then((response) => {
      })
    }
  },
  methods: {
    loadList() {
      this.$api.newsBulletin({ language: this.lang, partnerId: this.partnerId, id: this.$route.query.id }).then((response) => {
        if (!response.meta.success) return
        this.content = response.data.content
        this.title = response.data.title
      })
    },
    /* 刷新事件 */
    onRefresh() {
      this.loadList();
    },
    onClickLeft() {
      this.$router.back()
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep a {
  color: #0000ee !important;
}
.newsContent {
  .box {
    padding: 15px;
    color: #5c6573;
    font-size: 14px;
    img {
      max-width: 100%;
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
</style>