<template>

  <!-- 验证码组件 -->
  <van-popup v-model="show" position="bottom" :close-on-click-overlay="false">
    <div class="icon_close" @click="onClose">
      <img src="@/assets/img/assets/icon_close.png" alt="">
    </div>
    <div class="title">{{title}}</div>

    <!-- 短信验证码 -->
    <van-field v-if="isEnablePhone" v-model="queryParams.msgCode " center :placeholder="$t('M.login_please_input1')" :formatter="formatterNum">
      <van-button class="button" slot="button" @click="onClickSend(1)" :disabled="disabledCode">{{valiBtn}}</van-button>
    </van-field>
    <!-- 邮箱验证码 -->
    <van-field v-if="isEnableMail" v-model="queryParams.emailCode" center :placeholder="$t('M.input_email_code')" :formatter="formatterNum">
      <van-button class="button" slot="button" @click="onClickSend(2)" :disabled="disabledCode2">{{valiBtn2}}</van-button>
    </van-field>
    <!-- 谷歌验证码 -->
    <van-field v-if="isEnableGoogle" v-model="queryParams.googleCode" center :placeholder="$t('M.user_please_input9')" :formatter="formatterNum">
    </van-field>

    <div class="btn_wrap">
      <div class="button submit_button" @click="onSubmit">{{$t('M.affirm')}}</div>
    </div>
  </van-popup>
</template>

<script>
import { mapGetters, } from 'vuex'

export default {
  name: 'popupCode',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    onlyEnableGoogle: {
      type: Boolean,
      default: false
    },
    // title: {
    //   type: String,
    //   default: ''
    // },
    type: {
      type: String,
      default: 'withdrawal'
    },
    params: {
      type: Object,
      default: () => ({})
    },

  },
  computed: {
    ...mapGetters([
      'token', 'user', 'partnerId'
    ]),
    title() {
      if (this.isEnableGoogle && (!this.isEnableMail && !this.isEnablePhone)) {
        return this.$t("M.Google_code")
      }
      if (this.isEnableMail && (!this.isEnablePhone && !this.isEnableGoogle)) {
        return this.$t("M.email_code")
      }
      if (this.isEnablePhone && (!this.isEnableMail && !this.isEnableGoogle)) {
        return this.$t("M.SMS_code")
      }

      return this.$t("M.Verification Code")
    }
  },
  watch: {
    show(n) {
      if (n) {
        // this.$store.dispatch('getUserData')
        this.getSecurity()
      }
    },
  },
  data() {
    return {

      isEnableGoogle: false,
      isEnableMail: false,
      isEnablePhone: false,
      isEnablePassword: false,

      queryParams: {
        googleCode: '',
        emailCode: '',
        msgCode: '',
      },

      valiBtn: this.$t("M.send"),
      disabledCode: false,
      valiBtn2: this.$t("M.send"),
      disabledCode2: false,


    };
  },

  mounted() {

  },

  methods: {
    reSetForm() {
      this.queryParams = {
        googleCode: '',
        emailCode: '',
        msgCode: '',
      }
    },
    onClose() {
      this.reSetForm()
      this.$emit('onClose')
    },

    // 确认按钮
    onSubmit() {
      if (this.isEnablePhone && !this.queryParams.msgCode) return
      if (this.isEnableMail && !this.queryParams.emailCode) return
      if (this.isEnableGoogle && !this.queryParams.googleCode) return
      this.$emit('onSubmit', this.queryParams)
      this.reSetForm()

    },
    // 获取用户密码验证码信息
    getSecurity() {

      // 以传入方式获取
      const { isEnableGoogle, isEnableMail, isEnablePhone, email, phone } = this.params
      this.isEnableGoogle = isEnableGoogle
      this.isEnableMail = isEnableMail
      this.isEnablePhone = isEnablePhone

      //   this.$api.userRefreshUser({ token: this.token }).then((response) => {
      //     if (!response.meta.success) return
      //     const { isEnableGoogle, isEnableMail, isEnablePhone, withDrawTypes, userId } = response.data
      //     this.queryParams.userId = userId
      //     if (this.type == "withdrawal" || this.type == "flashCash") {
      //       this.isEnableGoogle = isEnableGoogle && withDrawTypes.indexOf('google') > -1
      //       this.isEnableMail = isEnableMail && withDrawTypes.indexOf('email') > -1
      //       this.isEnablePhone = isEnablePhone && withDrawTypes.indexOf('phone') > -1
      //       this.isEnablePassword = withDrawTypes.indexOf('password') > -1

      //     } else {
      //       this.isEnableGoogle = isEnableGoogle
      //       this.isEnableMail = isEnableMail
      //       this.isEnablePhone = isEnablePhone
      //     }


      //   })
    },

    // 验证码倒数60秒
    tackBtn(i) {
      if (i == 1) {
        let time = 59
        const timer = setInterval(() => {
          if (time === 0) {
            clearInterval(timer)
            this.valiBtn = this.$t('M.get_code')
            this.disabledCode = false
          } else {
            this.disabledCode = true
            if (time < 10) {
              this.valiBtn = '0' + time + 's'
            } else {
              this.valiBtn = time + 's'
            }
            time--
          }
        }, 1000)
      }
      if (i == 2) {
        let time2 = 59
        const timer2 = setInterval(() => {
          if (time2 === 0) {
            clearInterval(timer2)
            this.valiBtn2 = this.$t('M.get_code')
            this.disabledCode2 = false
          } else {
            // this.loadingCode2 = false
            this.disabledCode2 = true
            if (time2 < 10) {
              this.valiBtn2 = '0' + time2 + 's'
            } else {
              this.valiBtn2 = time2 + 's'
            }
            time2--
          }
        }, 1000)
      }


    },

    // 发送验证码
    onClickSend(i) {
      let action = this.params.action || 'RETRIEVE_PASS'

      if (i == 1) { // 短信验证码
        const params = {
          action: action,
          phone: this.params.phone,
          nationCode: this.params.countryCode,
          partnerId: this.partnerId
        }
        this.$api.sendSms(params).then((response) => {
          if (!response.meta.success) return
          this.$notify(response.meta.message)
          this.tackBtn(i)
        })
      }
      if (i == 2) {// 邮箱验证码
        const params = {
          action: action,
          email: this.params.email,
          partnerId: this.partnerId
        }
        this.$api.sendMail(params).then((response) => {
          if (!response.meta.success) return
          this.$notify(response.meta.message)
          this.tackBtn(i)
        })
      }
    },


    formatterNum(value) {
      return value.replace(/[^0-9]/g, '')
    }



  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  max-height: 70%;
  min-height: 30%;
  padding: 0 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .title {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
  }
  .van-field {
    width: 100%;
    height: 43px;
    border-radius: 4px;
    border: 1px solid #c7ccd8;
    color: #9299a9;
    margin-bottom: 15px;
    .van-field__control {
      color: #9299a9;
    }
  }
  .btn_wrap {
    margin: 0;
  }
}
.van-button {
  padding: 0;
  border: none;
  color: #00b897;
  background-color: transparent;
}
.van-button--disabled {
  color: #00b897;
}
</style>