<template>
  <div class="container">
    <nav-bar class="nav_bar"> </nav-bar>
    <div class="title_box">
      <div class="title">{{$t('M.find_password')}}</div>
      <div class="tips"> {{$t('M.unraise_recovering_password')}} </div>
    </div>
    <div class="section">
      <div class="userName_cell">
        <van-field class="userName" v-model="userName" center clearable :placeholder="$t('M.enter_account')">
        </van-field>
      </div>

      <div class="button" @click="onClickNext">{{$t('M.next')}}</div>
    </div>
    <!-- 网易 -->
    <div ref="captcha"></div>
    <!-- 验证码 -->
    <popup-password :show="show" :params="params" @onClose="onClosed" @onSubmit="onSubmit" />
    <!-- <van-overlay :show="showOverlay" /> -->
    <Verify ref="verify" :captcha-type="'blockPuzzle'" :img-size="{ width: '300px', height: '200px' }" @success="login" />

  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapGetters } from 'vuex'

import navBar from '@/components/navBar';
import popupPassword from '@/components/popupPassword/popupCode.vue';
import Verify from '@/components/verifition/Verify'

export default {
  name: 'forgotPassword',
  components: {
    navBar, popupPassword,Verify
  },
  computed: {
    ...mapGetters([
      'partnerId', 'clientType', 'partnerNo', 'lang', 'langWY'
    ]),
  },
  data() {
    return {
      instance: null, // 网易空间
      userName: '',
      show: false,
      params: {}
    };
  },
  created() {
    this.getCookie()
  },
  mounted() {

  },

  methods: {
    login(params) {
      console.log("验证成功")
      this.forgetPasswordNext();
    },
    onClickRight() {
      this.$router.push({ name: 'login' })
    },
    getCookie() {
      const userName = Cookies.get("userName")
      this.userName = userName === undefined ? this.userName : userName
    },
    // 下一步
    onClickNext() {
      if (!this.userName.trim()) return
      this.$api.sliderSwitch().then(data => {
        if (data.data.sliderSwitch) {
          /* 获取验证 */
          // this.initNECaptcha()
          this.$refs.verify.show()
        } else {
          this.forgetPasswordNext()
        }
      })
    },

    onClosed() {
      this.show = false
    },
    onSubmit(bolb) {
      this.onClosed()
      this.forgetPasswordValidate(bolb)
    },
    // 下一步-获取验证类型
    forgetPasswordNext() {
      const params = {
        userName: this.userName,
        partnerId: this.partnerId,
        clientType: this.clientType,
        partnerNo: this.partnerNo,
      }
      this.$api.forgetPasswordNext(params).then((response) => {
        if (!response.meta.success) return
        this.params = response.data
        setTimeout(() => {
          this.show = true
        }, 300);
      })
    },
    // 忘记密码-验证
    forgetPasswordValidate(bolb) {
      const params = {
        phoneCode: bolb.msgCode, phone: this.params.phone,
        email: this.params.email, emailCode: bolb.emailCode,
        googleCode: bolb.googleCode,
        token: this.params.token,
        partnerId: this.partnerId,
        partnerNo: this.partnerNo,
      }
      let code = []
      if (bolb.msgCode) {
        code.push(bolb.msgCode)
      }
      if (bolb.emailCode) {
        code.push(bolb.emailCode)
      }

      if (bolb.googleCode) {
        code.push(bolb.googleCode)
      }

      this.$api.forgetPasswordValidate(params).then((response) => {
        if (!response.meta.success) return
        this.$notify(response.meta.message)
        this.$router.push({ name: 'resetPassword', params: { userName: this.userName, code: code.join('') } })
      })
    },
    /* 初始化网易易盾 */
    initNECaptcha() {

      /* 获取验证码的ID */
      this.$api.verify({
        "timestamp": new Date().getTime(),
        "partnerNo": 1000010001,
        "type": 'wyTouch',
        "partnerId": this.partnerId,
      }).then((data) => {
        let verify = data.data.verify;
        let that = this;
        if (!verify) {
          this.initNECaptcha();
        }

        let sendLang = this.langWY[this.lang];

        let options = {
          element: that.$refs.captcha,
          captchaId: verify,
          mode: "popup",
          lang: sendLang,
          feedbackEnable: false,
          onVerify: function (err, data) {
            if (!err) {
              that.forgetPasswordNext();
            }
          },
        };

        initNECaptcha(
          options,
          function onload(instance) {
            that.instance = instance;
            that.instance.popUp()
          },
          function onerror(err) {
            console.log(err);
          }
        );
      });
    },

  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar__arrow {
  font-size: 24px;
}
.title_box {
  margin: 30px 0;
  padding: 0 35px;

  .title {
    font-size: 16px;
    font-weight: 600;
  }
  .tips {
    font-size: 12px;
    color: #e95252;
    margin-top: 10px;
    display: flex;
    // align-items: center;
    padding-left: 20px;
    position: relative;
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: '!';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1.5px solid;
      font-weight: 600;
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.section {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .userName_cell {
    width: 300px;
    margin: 56px 0;
    .userName {
      background: rgba(204, 204, 204, 0.2);
      border-radius: 4px;
    }
  }
  .button {
    width: 300px;
    height: 35px;
    background: #00b897;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: Adobe Heiti Std;
    color: #fff;
  }
}
</style>