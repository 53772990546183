<!-- settings -->
<template>
  <div class="settings container">
    <div id="commonhead" class="commonhead comheadmenu " style="display: block;">
      <img @click="back" class="commonhead-icon" src="../../assets/img/public/ic_back.png" /> <!-- ../../assets/img/back.png -->
      <span class="commonhead-tit">{{$t("M.setting")}}</span>
    </div>
    <div class="main-content">
      <div class="top-functional">
        <van-cell class="item-cell" :value="$t('M.US_dollar')">
          <template #title>
            <span class="item-title">{{$t("M.converted_currency")}}</span>
          </template>
        </van-cell>

        <van-cell class="item-cell" is-link>
          <template #title>
            <span class="item-title">{{$t("M.language")}}</span>
          </template>
          <template #default>
            <span class="item-title" @click="showLanguage = !showLanguage">
              <!-- {{$t("M.Simplified_Chinese")}} -->
              {{langValue}}
            </span>
          </template>

        </van-cell>

        <van-cell class="item-cell" :value="$t('M.GreenRed')">
          <template #title>
            <span class="item-title">{{$t("M.Color Preference")}}</span>
          </template>
        </van-cell>

        <!-- <van-cell class="item-cell" value="日間模式" is-link>
          <template #title>
            <span class="item-title">模式切換</span>
          </template>
        </van-cell> -->

        <!-- <van-cell class="item-cell" value="1.3.0" is-link>
          <template #title>
            <span class="item-title">版本更新</span>
          </template>
        </van-cell> -->
      </div>

      <div class="btn_wrap">
        <van-button v-if="isLogin" class="btn-commit button" color="#00b897" @click="showDialog = true">{{$t("M.exit")}}</van-button>
        <router-link v-else class="btn-commit button" to="login">{{$t("M.log_in")}}</router-link>

      </div>

    </div>

    <!-- 确认退出弹框 -->
    <Dialog :show="showDialog" :message="$t('M.Are you sure you want to quit')" :title="$t('M.exit')" :confirmButtonText="$t('M.confirm')"
      @closed="onClosed" @confirm="onConfirm">
    </Dialog>

    <!-- 多语言 -->
    <van-action-sheet v-model="showLanguage" :actions="actionsLanguage" :cancel-text="$t('M.Cancel')" @select="onSelect" @cancel="onCancel" />
  </div>
</template>

<script>
// import settingsService from '@/api/settings.js'
import { Toast } from 'vant'
import Dialog from '@/components/dialog';
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    Dialog
  },
  computed: {
    ...mapGetters([
      'isLogin', 'lang'
    ]),
    ...mapMutations(['SET_LANG'])

  },
  data() {
    return {
      isBankEnable: false,
      isAlipayEnable: false,
      isWeixinEnable: false,
      isXilianEnable: false,
      isPaypalEnable: false,
      isAlipayBind: false,
      isPaypalBind: false,
      isXilianBind: false,
      isWeixinBind: false,
      isBankBind: false,

      showDialog: false,
      showTitle: this.$t('M.exit'),
      showMessage: this.$t('M.Are you sure you want to quit'),

      showLanguage: false,
      langValue: '',
      actionsLanguage: []
    }
  },



  mounted() {
    // this.getReceiptMethods()
    this.initLang()
  },

  methods: {
    back() {
      this.$router.go(-1);//返回上一层
    },

    onClosed() {
      this.showDialog = false
    },
    onConfirm() {
      this.onClosed()
      this.$store.dispatch('logout').then(() => {
        this.$router.push('login')
      })
    },


    // 多语言设置
    onSelect(item) {
      this.showLanguage = false;
      this.langValue = item.name
      this.actionsLanguage.forEach(el => {
        el.color = ''
        if (el.shortName == item.shortName) {
          el.color = '#00b897'
          this.$i18n.locale = item.shortName
          this.$store.commit('SET_LANG', item.shortName)
        }
      });

    },
    onCancel() { this.showLanguage = false; },
    // 多语言初始化
    initLang() {
      this.$api.getlanguage().then((response) => {
        const { meta, data } = response
        if (!meta.success) return
        data && data.forEach(item => {
          item.color = ''
          item.id = item.shortName
          if (item.shortName == this.lang) {
            this.langValue = item.name
            item.color = '#00b897'
          }
        })
        this.actionsLanguage = data || []
      })

    },


  }
}
</script>
<style lang="scss" scoped>
.settings {
  min-height: 100vh;
  width: 100vw;
  background-color: #f8f8f8;
  .navbar {
    position: fixed;
    width: 100vw;
    background-color: #ffffff;
    // /deep/ .van-ellipsis{
    //   color: #ffffff !important;
    // }

    // /deep/ .van-icon {
    //   color: #ffffff !important;
    // }
  }

  .commonhead {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 44px;
    line-height: 44px;
    font-size: 2.8vw;
    background: #ffffff;
    color: #191b27;
    text-align: center;
    border-bottom: solid 1px #ebebf0;
  }

  .commonhead-icon {
    width: 10px;
    margin-top: 14px;
    margin-left: 20px;
    float: left;
  }

  .commonhead-tit {
    font-size: 4.2vw;
    margin-left: -10vw;
  }

  .commonhead-user {
    position: absolute;
    width: 6.8vw;
    top: 1.8vw;
    right: 28px;
  }

  .navbar-handle {
    width: 18px;
  }

  .van-hairline--bottom::after {
    border-bottom-width: 0rem;
  }

  .main-content {
    width: 100vw;
    height: 100vh;
    // padding: 30px;
    display: flex;
    display: -webkit-flex; /* Safari */
    flex-direction: column;
  }

  .top-functional {
    width: 92vw;
    margin-left: 4vw;
    margin-top: 60px;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .sec-functional {
    width: 92vw;
    height: 108px;
    margin-left: 4vw;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .item-cell {
    height: 52.5px;
    background-color: transparent;
    padding: 14.5px;

    .receipt-icon {
      width: 14px;
      height: 14px;
      margin-top: 5px;
    }

    .receipt-title {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    .receipt-content {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    // .item-title {
    // color: #191b27;
    // }

    .item-custom-bx {
      position: relative;
      display: inline-block;
      float: right;
      color: #9299a9;
    }

    .ic-notice {
      left: -20px;
      top: 4px;
      width: 16px;
      height: 16px;
      position: absolute;
    }

    .van-cell__value {
      color: #9299a9;
    }
  }

  .item-cell.top-cell {
    height: 70px;
  }

  .tip-txt {
    margin: 12px 15px;
    font-size: 12px;
    color: #5c6573;
  }

  .item-cell::after {
    border-bottom: 1.5px solid #ebebf0;
  }

  .cell-level {
    width: 100%;

    .level-bx {
      width: 100%;
    }

    .level-tit {
      display: inline-block;
      font-size: 15px;
    }

    .level-tip {
      display: inline-block;
      font-size: 12px;
      color: #00b897;
      float: right;
    }

    .level-bar {
      display: inline-block;
      width: 98.33px;
      height: 4px;
      background-color: #d5d5d5;
    }

    .level-bar.selected {
      background-color: #00b897;
    }

    .mg-lft {
      margin-left: 10px;
    }
  }
}

.btn-commit {
  position: absolute;
  width: 345px;
  height: 46px;
  left: 15px;
  bottom: 100px;
  margin: 0 auto;
}

</style>
