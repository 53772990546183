export const M = {
    "Slideright": "Bitte halten Sie den Schieber und ziehen Sie ihn nach rechts.",
    "Pleaseverification": "Bitte Sicherheitsüberprüfung abschließen.",
    "Pleaseorder": "Bitte nacheinander klicken.",
    "Verificationagain": "Überprüfung fehlgeschlagen, bitte erneut versuchen.",
    "10 orders": "10 Aufträge",
    "24h High": "24H Hoch",
    "24h Low": "24H Tief",
    "24h Trading Vol": "24 Stunden Handelsvolumen",
    "24h Vol": "24H Volumen",
    "ADL mechanism": "ADL Mechanismus",
    "AMT": "Menge",
    "Activity _home": "Aktivitäten",
    "Actual transaction number": "Tatsächliche Transaktionsanzahl",
    "Actual transaction price": "Tatsächlicher Transaktionspreis",
    "Add margin": "Margin hinzufügen",
    "Adjust leverage": "Hebel anpassen",
    "Adjust success": "Anpassung erfolgreich.",
    "Advanced limit order offers 3": "Erweiterte Limitaufträge bieten 3 Optionen: 'Nur Maker', 'Alles oder sofort stornieren', 'Sofort ausführen und Rest stornieren'.",
    "Alipay": "Alipay",
    "All Closed": "Alles geschlossen.",
    "All Filled": "Vollständig ausgeführt.",
    "AllDetails": "Details zur Zwangsliquidation anzeigen.",
    "AllType": "Alle Typen.",
    "Amount can flat": "Verfügbare Menge.",
    "Appeal_reason": "Beschwerdegrund.",
    "Applet service": "App-Service.",
    "Are you sure to cancel the order？": "Sind Sie sicher, dass Sie die Bestellung stornieren möchten?",
    "Asked_to_record": "Bitte Video gemäß den Anforderungen aufnehmen.",
    "Asset conversion": "Vermögen umrechnen.",
    "Asset_types": "Vermögensarten.",
    "At the current price": "Zum aktuellen Marktpreis bestellen.",
    "Avail.": "Verfügbar.",
    "Avail. margin": "Verfügbare Margin.",
    "Available": "Verfügbar.",
    "Avg close price": "Durchschnittspreis beim Schließen.",
    "Avg open price": "Durchschnittspreis beim Öffnen.",
    "Avg.price": "Durchschnittspreis.",
    "BBO": "Gegenseitiger Preis.",
    "Bankcard": "Bankkarte.",
    "Bankruptcy price": "Insolvenzpreis.",
    "Batch Cancel": "Batch-Stornierung.",
    "Branch_address": "Filialadresse.",
    "Buy crypto_home": "Krypto kaufen.",
    "Calculations are not based on": "Berechnungen sind nur Richtwerte.",
    "Can't calculate": "Kann nicht berechnet werden.",
    "Cancel": "Abbrechen.",
    "Cancel all": "Alle stornieren.",
    "Cancel order": "Bestellung stornieren.",
    "Cancel_the_login": "Anmeldung abbrechen.",
    "Capital cost": "Kapitalfluss.",
    "Capital cost - Expenditure": "Kapitalaufwand.",
    "Capital fee": "Kapitalgebühr.",
    "Capital fee - all": "Kapitalgebühr - alle.",
    "Capital fee - income": "Kapitalgebühr - Einnahmen.",
    "Capital success": "Erfolgreich storniert.",
    "Charge_money": "Geld aufladen.",
    "Chinese_Yuan": "Renminbi.",
    "Close all of": "Sind Sie sicher, dass Sie schließen möchten?",
    "Close long": "Long schließen.",
    "Close out time": "Schließzeit.",
    "Close price": "Schlusskurs.",
    "Close short": "Short schließen.",
    "Closed": "Geschlossene Menge.",
    "Closing PnL gains": "Gewinne beim Schließen.",
    "Closing PnL ratio": "Gewinnquote beim Schließen.",
    "Closing profit": "Schlussgewinn.",
    "coin_failure_0002": "Währungsdaten existieren nicht.",
    "Common functions": "Häufige Funktionen.",
    "Communtiy_home": "Gemeinschaft.",
    "Conceal canceled orders": "Stornierte Aufträge verbergen.",
    "Confirm_the_login": "Anmeldung bestätigen.",
    "Confirm_to_mention_money": "Abhebung bestätigen.",
    "Cont": "Stück.",
    "Copy link": "Link kopieren.",
    "Cross": "Kreuzweise.",
    "Currency_rate_description": "Gebührenbeschreibung.",
    "Current": "Aktuell.",
    "Delete_option": "Löschen.",
    "Determine_to_cancel": "Stornierung bestätigen?",
    "Don't show me again": "Nicht wieder anzeigen.",
    "Draw_inconsistency": "Passwort stimmt nicht überein.",
    "Draw_the_unlock": "Bitte schieben Sie, um ein neues Passwort festzulegen.",
    "Enter email address": "Bitte E-Mail-Adresse eingeben.",
    "Enter expected PnL": "Bitte erwarteten Gewinn eingeben.",
    "Enter expected PnL Ratio": "Bitte erwartete Gewinnquote eingeben.",
    "Enter leverage from 1 up to 125": "Bitte Hebel von 1 bis 125 eingeben.",
    "Enter phone number": "Bitte Telefonnummer eingeben.",
    "Enter the verification code": "Bitte Bestätigungscode eingeben.",
    "Equity": "Währungsanteil.",
    "Est liquidation price": "Geschätzter Liquidationspreis.",
    "Face value": "Vertragswert.",
    "Filed Value": "Erfolgreicher Wert.",
    "Fill or Kill": "Alles oder sofort stornieren.",
    "Filled amt": "Ausgeführte Menge.",
    "Filled time": "Nach Ausführungszeit.",
    "Flash_strong": "Starker Blitz.",
    "Funding rate": "Finanzierungsrate.",
    "Funding rate - 1": "Finanzierungsrate.",
    "Funding rate comparison": "Finanzierungsratenvergleich.",
    "Funding rate time": "Finanzierungsratenzeit",
    "Fuzzy_information": "Unklare Informationen",
    "Gesture_error": "Passworteingabe fehlerhaft",
    "Google_code": "Google-Authentifizierungscode",
    "Help_home": "Hilfe",
    "Hide_no_asset_currency": "Währungen ohne Vermögen ausblenden",
    "Highest PnL ratio": "Höchste Rendite",
    "Historical record": "Historische Aufzeichnungen",
    "I agree to": "Ich stimme zu",
    "I have read and agree to this agreement": "Ich habe die Vereinbarung gelesen und stimme zu",
    "If_the_buyer_is_at": "Wenn der Käufer bei",
    "Immediately or Cancel": "Sofort ausführen und Rest stornieren",
    "In force": "In Kraft",
    "Index": "Spot-Index",
    "Index Price": "Indexpreis",
    "Index_contrct": "Spot-Index",
    "Info": "Vertragsinformationen",
    "Initial margin": "Erste Margin",
    "Instrument": "Aktuelles Handelsinstrument",
    "Insurance fund": "Risikoreserve",
    "Interest bearing record": "Zinsaufzeichnung",
    "Invalid_qr_code": "Ungültiger QR-Code",
    "Invite code(Optional)": "Bitte Einladungscode eingeben (optional)",
    "InviteCodeMuster": "Bitte Einladungscode eingeben (erforderlich)",
    "Invite_home": "Einladen",
    "Isolated": "Isoliert",
    "Lack_of_edge": "Ecken fehlen",
    "Last Price": "Marktpreis",
    "Latest Filled price": "Neuester Ausführungspreis",
    "Latest Filled price01": "Neuester Preis",
    "Latest price": "Aktueller Preis",
    "Leverage": "Hebel",
    "Leverage after increase": "Hebel nach Erhöhung:",
    "Leverage11": "Aktueller Hebel",
    "Leverage_BB": "Hebel anpassen",
    "Limit order is an order": "Limitauftrag bedeutet, dass der Benutzer die Auftragsgröße und den akzeptablen Preis festlegt. Wenn der Markt den Erwartungen des Benutzers entspricht, wird der Auftrag zum besten Preis innerhalb des Limitbereichs ausgeführt.",
    "Limit price": "Limitpreis",
    "Limit/Market": "Limit/Markt",
    "LimitAndMarket": "Limit/Markt",
    "Liquidation price": "Liquidationspreis",
    "Liquidation price after increase": "Liquidationspreis nach Erhöhung:",
    "Long": "Long",
    "Lowest PnL ratio": "Niedrigste Rendite",
    "MAIL_COUNTRY_NOT_EXITS": "Das aktuelle Land existiert nicht! Bitte hinzufügen und erneut senden.",
    "MAIL_NOT_MAIL_PROVIDER": "Derzeit kein E-Mail-Anbieter, bitte hinzufügen und erneut senden!",
    "MAIL_TEMPLATE_NOT_FOUND": "Aktuelle E-Mail-Vorlage existiert nicht! Bitte hinzufügen und erneut senden.",
    "MAIL_TO_ADDRESS_NOT_FOUND": "Aktuelle E-Mail-Adresse existiert nicht, bitte überprüfen und erneut senden.",
    "MJRefreshAutoFooterIdleText": "Klicken oder nach oben ziehen, um mehr zu laden",
    "MJRefreshAutoFooterNoMoreDataText": "Alle Daten sind bereits geladen",
    "MJRefreshAutoFooterRefreshingText": "Lade mehr Daten...",
    "MJRefreshBackFooterIdleText": "Nach oben ziehen, um mehr zu laden",
    "MJRefreshBackFooterNoMoreDataText": "Alle Daten sind bereits geladen",
    "MJRefreshBackFooterPullingText": "Loslassen, um sofort mehr zu laden",
    "MJRefreshBackFooterRefreshingText": "Lade mehr Daten...",
    "MJRefreshHeaderDateTodayText": "Heute",
    "MJRefreshHeaderIdleText": "Nach unten ziehen, um zu aktualisieren",
    "MJRefreshHeaderLastTimeText": "Zuletzt aktualisiert:",
    "MJRefreshHeaderNoneLastDateText": "Keine Aufzeichnungen",
    "MJRefreshHeaderPullingText": "Loslassen, um sofort zu aktualisieren",
    "MJRefreshHeaderRefreshingText": "Aktualisiere Daten...",
    "MKT Close ALL": "Marktpreis alles schließen",
    "Margin add": "Margin hinzufügen",
    "Margin mode": "Gesamte Position",
    "Margin ratio": "Marginquote",
    "Margin reduce": "Margin reduzieren",
    "Margin transfer": "Marginübertragung",
    "Margin-1": "Margin-Balance",
    "Margin-2": "Margin anpassen",
    "Margined": "Vertrag",
    "Mark price": "Marktpreis",
    "Market": "Marktpreis",
    "Market Order Dig": "Der Marktauftrag erlaubt es den Benutzern, zum besten aktuellen Marktpreis zu bestellen, um eine schnelle Transaktion zu erreichen.",
    "Market Overview": "Marktübersicht",
    "Market_transaction": "Marktpreis-Transaktion",
    "Max": "Maximal stornierbar",
    "Max Open int": "Maximale Position",
    "Max buy": "Maximal kaufen",
    "Max long": "Maximal Long",
    "Max position size at current leverage": "Maximal zu öffnende Position bei aktuellem Hebel:",
    "Max sell": "Maximal verkaufen",
    "Max short": "Maximal Short",
    "Max position size at current leverage": "Maximal zu öffnende Position bei aktuellem Hebel",
    "Mention_money": "Abheben",
    "Mention_money_address": "Bitte geben Sie die richtige Adresse ein",
    "Mention_money_tips_ios": "Hinweis\n1. Geben Sie die Abhebungsadresse direkt ein, es ist eine Verifizierung von Kapitalpasswort, SMS, E-Mail oder Google-Authentifizierung erforderlich.\n2. Adressen, die gebunden sind, sind Ihre verifiziert Adresse, die nächste Abhebung benötigt keine weitere Verifizierung.",
    "Messages_notification": "*Bitte bestätigen Sie, dass Ihre Bankkarte den SMS-Benachrichtigungsdienst aktiviert hat.",
    "Mini Programs": "Mini-Programme",
    "Minimum_handling_charge": "Die Abhebemenge muss höher als die Gebühren sein",
    "Minimum_withdrawal": "Minimale Abhebemenge pro Transaktion",
    "MoreNot9": "Die ausgewählten Module dürfen 14 nicht überschreiten",
    "Most increase": "Maximal erhöhen",
    "Most reduce": "Maximal reduzieren",
    "Must be integer multiple of": "Muss ein ganzzahliges Vielfaches sein",
    "Not Enabled": "Nicht aktiviert",
    "Not_on": "Derzeit nicht verfügbar",
    "Not_to_mention_money": "Zur Sicherheit Ihrer Mittel können Sie innerhalb einer Stunde nach dem Zurücksetzen des Handelspassworts keine Abhebungen oder andere sensible Operationen durchführen.",
    "OTC account": "Fiat-Konto",
    "OTC assets": "OTC-Wallet",
    "OTC交易须知": "OTC Handelsrichtlinien",
    "On Hold": "Eingefroren",
    "One-way": "Einseitig",
    "One/Two-way": "Ein-/Zweiseitig",
    "Open Time": "Eröffnungszeit",
    "Open amount": "Eröffnungsmenge",
    "Open int.": "Offene Position",
    "Open interest": "Offene Position",
    "Open long": "Long eröffnen",
    "Open orders": "Aktuelle Aufträge",
    "Open price": "Eröffnungspreis",
    "Open short": "Short eröffnen",
    "Order Details": "Auftragsdetails",
    "Order Sorting": "Auftrags sortierung",
    "Order Value": "Auftragswert",
    "Order amt": "Auftragssumme",
    "Order history": "Auftragsgeschichte",
    "Order price": "Auftragspreis",
    "Order trigger direction": "Auslösungsrichtung",
    "Order type": "Auftragstyp",
    "PL": "Gewinn",
    "PL Ratio": "Rendite",
    "Pair01": "Währung",
    "Parse_failure": "Speichern fehlgeschlagen",
    "Partially Filled": "Teilweise ausgeführt",
    "Partially closed": "Teilweise geschlossen",
    "Password": "Passwort",
    "Payment_type": "Zahlungsart",
    "Perpetual": "Ewiger Vertrag",
    "Perpetual funds daybook": "Kapitalflüsse",
    "Perpetual01": "Ewige",
    "Pingclose": "Position schließen",
    "Please input a password": "Bitte Passwort eingeben",
    "Position": "Position",
    "Position balance": "Positionssaldo",
    "Position history": "Positionshistorie",
    "QR_code": "QR-Code",
    "Quantity cannot exceed": "Max. Menge 125",
    "Quantity not less than": "Min. Menge 1",
    "Recent close": "Zuletzt geschlossen",
    "Recording_requirements": "Aufnahmeanforderungen",
    "Required position margin at current leverage": "Benötigte Margin:",
    "Reset_transaction_password": "Passwort zurücksetzen",
    "Revoked": "Widerrufen",
    "SL": "Stop-Loss",
    "SL order": "Stop-Loss Auftrag",
    "SL order price": "Stop-Loss Preis",
    "SL order price can't be empty": "Preis darf nicht leer sein",
    "SL trigger price": "Stop-Loss Auslösungspreis",
    "SL trigger price can't be empty": "Auslösungspreis darf nicht leer sein",
    "SMS_30_OUT_RESTRICTED": "30 Sekunden warten.",
    "SMS_BAD_ARGUMENT_FORMAT": "Ungültiges Handyformat.",
    "SMS_COUNTRY_NOT_EXITS": "Land existiert nicht, bitte überprüfen!",
    "SMS_DAY_LIMIT_PER_MOBILE": "Limit pro 24 Std. überschritten.",
    "SMS_DUP_IN_SHORT_TIME": "In 30 Sekunden mehrfach gesendet.",
    "SMS_FAILURE": "Versand fehlgeschlagen.",
    "SMS_HOUR_LIMIT_PER_MOBILE": "Limit pro Stunde überschritten.",
    "SMS_MARKET_FORBIDDEN": "Marketing-SMS vorübergehend nicht erlaubt.",
    "SMS_NOT_SMS_PROVIDER": "Kein SMS-Anbieter, bitte hinzufügen!",
    "SMS_NOT_SMS_PROVIDER_TEMPLATE": "Keine Vorlage für diesen SMS-Anbieter vorhanden!",
    "SMS_PHONE_OVER_COUNT": "Verifizierungscode-Limit überschritten, bitte warten!",
    "SMS_PHONE_PROHIBIT_SEND": "SMS-Versand für diese Nummer gesperrt!",
    "SMS_SUCCESS": "Versand erfolgreich.",
    "SMS_TOO_MANY_TIME_IN_5": "In 5 Min. mehrfach gesendet.",
    "SMS_UNKNOWN_EXCEPTION": "Unbekannte Systemausnahme.",
    "SMS_code": "SMS-Code",
    "Save picture": "Bild speichern",
    "Scanning_to_obtain": "Scannen für Adresse",
    "See auto deleverage liquidation for details": "Details zur automatischen Liquidation",
    "Set_transaction_password": "Passwort festlegen",
    "Settlement1": "Bis zur Abrechnung",
    "Settlrment coin": "Abrechnungswährung",
    "Share time": "Teilen",
    "Shielding success": "Blockierung erfolgreich",
    "Short": "Short",
    "Sign Up": "Registrieren",
    "Sign in": "Anmelden",
    "Simplified_Chinese": "Traditionelles Chinesisch",
    "StatusOrder": "Auftragsstatus",
    "Stop loss": "Stop-Loss festlegen",
    "Stop profit stop loss": "Mindestens eine Option wählen.",
    "Sustainable account": "Ewiges Konto",
    "Sustainable assets": "Ewige Vermögenswerte",
    "Switch to PnL": "Zu Gewinn wechseln",
    "Switch to PnL ratio": "Zu Rendite wechseln",
    "TP": "Take Profit",
    "TP order": "Take Profit Auftrag",
    "TP order price": "Take Profit Preis",
    "TP order price can't be empty": "Preis darf nicht leer sein",
    "TP trigger price": "Take Profit Auslösungspreis",
    "TP trigger price can't be empty": "Auslösungspreis darf nicht leer sein",
    "TP | SL": "Take Profit und Stop-Loss",
    "TP | SL order": "Take Profit und Stop-Loss Auftrag",
    "Take profit": "Take Profit festlegen",
    "Telegraphic_transfer_address": "Überweisungsadresse",
    "The Calculator": "Rechner",
    "The maximum flatable quantity is": "Max. schließbare Menge:",
    "The maximum leverage is": "Max. Hebel:",
    "The minimum flatable quantity is": "Min. schließbare Menge:",
    "The minimum leverage is": "Min. Hebel:",
    "The poster has been saved to the album": "Poster gespeichert",
    "The_correct_sample": "Korrektes Beispiel",
    "The_input_signal": "Bitte Passwort eingeben",
    "The_new_address": "Neue Adresse",
    "They_are_the_number_of": "Auftragsanzahl",
    "Tick size": "Min. Preisänderung",
    "To_update": "Aktualisieren",
    "Toggle_front_camera": "Bitte auf die Frontkamera wechseln und Kopf zentrieren",
    "Total": "Gesamtposition",
    "Total coin": "Gesamtanzahl",
    "Total cont": "Gesamtverträge",
    "Total value": "Gesamtwert",
    "Total_asset_valuation": "Gesamtvermögensbewertung",
    "Trade type": "Handelsart",
    "Trade_sector": "Bitte wählen",
    "Transfer": "Übertragen",
    "Transfer_to_remind": "Bitte bei Überweisung beachten!",
    "Trigger mark price": "Auslösender Marktpreis",
    "Trigger price": "Auslöserpreis",
    "Two-way": "Zweiseitig",
    "UPL": "Nicht realisierte Gewinne/Verluste",
    "US_dollar": "US-Dollar",
    "Unblocked": "Blockierung aufgehoben",
    "Uneasy lies the head that wears a crown": "Erst stark, dann schwach.",
    "Unfilled": "Nicht ausgeführt",
    "Unfilled11": "Warten auf Ausführung",
    "Upload_collection_code": "Bitte Zahlungscode hochladen",
    "Usdt-Margined": "USDT-Vertrag",
    "Users who register": "Neue Nutzer müssen Passwort festlegen",
    "Verification Code": "Verifizierungscode",
    "Videoing_requirement": "Aufnahmeanforderungen",
    "View": "Anzeigen",
    "View more": "Positionsdetails",
    "Waiting effect": "Warten auf Wirkung",
    "Waiting_for_the_payment": "Ausstehende Zahlung",
    "Wechat": "WeChat",
    "WestUnion": "Western Union",
    "Without_her": "Nicht im Bild",
    "abandoned": "Aufgegeben",
    "abnormal_assets": "Abnormale Kontovermögen, Operation nicht möglich!",
    "about_us": "Über uns",
    "account": "Konto",
    "account_empty": "Konto falsch",
    "account_failure_0001": "Transaktions-ID zu Redis fehlgeschlagen!",
    "account_failure_0002": "Unzureichende Verfügbarkeit!",
    "account_failure_0003": "Benutzerfrostung Protokollierung fehlgeschlagen!",
    "account_failure_0004": "Frostung fehlgeschlagen!",
    "account_failure_0005": "Händlerabzug Protokollierung fehlgeschlagen!",
    "account_failure_0006": "Händlerabzug fehlgeschlagen!",
    "account_failure_0007": "Benutzerverfügbarkeit Protokollierung fehlgeschlagen!",
    "account_failure_0008": "Benutzerverfügbarkeit fehlgeschlagen!",
    "account_failure_0009": "Händler neue Währung, Benutzer Fehler!",
    "account_failure_0010": "Händler neue Währung fehlgeschlagen!",
    "account_failure_00100": "Händler-ID darf nicht leer sein!",
    "account_failure_00101": "Benutzer-ID darf nicht leer sein!",
    "account_failure_00102": "Währungs-ID darf nicht leer sein!",
    "account_failure_00103": "Menge darf nicht leer sein!",
    "account_failure_00104": "Menge darf nicht unter Mindestbetrag liegen!",
    "account_failure_00105": "Handels-Passwort darf nicht leer sein!",
    "account_failure_00106": "Handels-Passwort falsch!",
    "account_failure_00107": "Preis darf nicht leer sein!",
    "account_failure_00108": "Einzelpreis darf nicht unter Mindestpreis liegen!",
    "account_failure_00109": "Benutzer existiert nicht!",
    "account_failure_0011": "Händler-Wallet hat diese Währung bereits!",
    "account_failure_00110": "PUSH-Vermögen fehlgeschlagen!",
    "account_failure_00111": "PUSH-ID darf nicht leer sein!",
    "account_failure_00112": "PUSH-Vermögenszahlung fehlgeschlagen!",
    "account_failure_00113": "PUSH-Vermögen Stornierung fehlgeschlagen!",
    "account_failure_00114": "Konnte keine Einzahlung-Adresse abrufen!",
    "account_failure_00115": "Abhebungsadresse darf nicht leer sein!",
    "account_failure_00116": "Hinzufügen der Abhebungsadresse fehlgeschlagen!",
    "account_failure_00117": "ID darf nicht leer sein!",
    "account_failure_00118": "Löschen der Abhebungsadresse fehlgeschlagen!",
    "account_failure_00119": "Netzwerkfehler, bitte später versuchen!",
    "account_failure_0012": "Unzureichende Frostung, nicht möglich!",
    "account_failure_00120": "Diese Abhebungsadresse existiert bereits!",
    "account_failure_00121": "Keine PUSH-Währungen vorhanden!",
    "account_failure_00122": "Handyverifizierungscode fehlt!",
    "account_failure_00123": "E-Mail-Verifizierungscode fehlt!",
    "account_failure_00124": "Google-Verifizierungscode fehlt!",
    "account_failure_00125": "Verifizierungscode falsch!",
    "account_failure_00126": "Selbst-PUSH verboten!",
    "account_failure_00127": "Abhebungsadresse ungültig!",
    "account_failure_00129": "Passwortänderung innerhalb von 24 Std. nicht erlaubt!",
    "account_failure_0013": "Händlerfrostung Protokollierung fehlgeschlagen!",
    "account_failure_00130": "Vermögen unausgeglichen, keine Operation möglich!",
    "account_failure_00131": "Abnormale Kontovermögen, Handelsstatus deaktiviert!",
    "account_failure_00132": "Label stimmt nicht überein!",
    "account_failure_00133": "Währungsart existiert nicht oder ist gesperrt!",
    "account_failure_0014": "Händlerfrostung fehlgeschlagen!",
    "account_failure_0015": "Benutzerverzinsung Protokollierung fehlgeschlagen!",
    "account_failure_0016": "Benutzerverzinsung fehlgeschlagen!",
    "account_failure_0017": "Händlerverzinsung Protokollierung fehlgeschlagen!",
    "account_failure_0018": "Händlerverzinsung fehlgeschlagen!",
    "account_failure_0019": "Benutzerverfügbarkeit zu Frostung fehlgeschlagen!",
    "account_failure_0020": "Benutzerverfügbarkeit zu Frostung fehlgeschlagen, Protokollierung fehlgeschlagen!",
    "account_failure_0021": "Benutzerfrostung zu Verfügbarkeit Protokollierung fehlgeschlagen!",
    "account_failure_0022": "Benutzerfrostung zu Verfügbarkeit fehlgeschlagen!",
    "account_failure_0023": "Händlerverfügbarkeit Protokollierung fehlgeschlagen!",
    "account_failure_0024": "Händlerverfügbarkeit fehlgeschlagen!",
    "account_failure_0025": "Benutzerfrostung Abzug Protokollierung fehlgeschlagen!",
    "account_failure_0026": "Benutzerfrostung Abzug fehlgeschlagen!",
    "account_failure_0027": "Benutzerverfügbarkeit Abzug Protokollierung fehlgeschlagen!",
    "account_failure_0028": "Benutzerverfügbarkeit Abzug fehlgeschlagen!",
    "account_failure_0029": "Rollback fehlgeschlagen, Benutzerfrostung Protokollierung fehlgeschlagen!",
    "account_failure_0030": "Rollback fehlgeschlagen, Benutzerfrostung fehlgeschlagen!",
    "account_failure_0031": "Nicht genug Vermögen zum Rollback!",
    "account_failure_0032": "Rollback fehlgeschlagen, Händlerfrostung Protokollierung fehlgeschlagen!",
    "account_failure_0033": "Rollback fehlgeschlagen, Händlervermögen Wiederherstellung fehlgeschlagen!",
    "account_failure_0034": "Händlerfrostung Protokollierung fehlgeschlagen!",
    "account_failure_0035": "Händlerfrostung fehlgeschlagen!",
    "account_failure_0036": "Händlerverfügbarkeit zu Frostung Protokollierung fehlgeschlagen!",
    "account_failure_0037": "Händlerverfügbarkeit zu Frostung fehlgeschlagen!",
    "account_failure_0038": "Händlerfrostung zu Verfügbarkeit Protokollierung fehlgeschlagen!",
    "account_failure_0039": "Händlerfrostung zu Verfügbarkeit fehlgeschlagen!",
    "account_failure_0040": "Plattformverfügbarkeit Protokollierung fehlgeschlagen!",
    "account_failure_0041": "Plattformverfügbarkeit fehlgeschlagen!",
    "account_failure_0042": "Transaktion ausgeführt mit Ausnahme!",
    "account_failure_0043": "Transaktion bereits fehlgeschlagen!",
    "account_failure_0044": "Transaktion erfolgreich ausgeführt!",
    "account_failure_0045": "Transaktion wird ausgeführt!",
    "account_failure_0046": "Verarbeitung erfolgreich!",
    "account_failure_0047": "Rollback fehlgeschlagen, Plattformvermögen Wiederherstellung fehlgeschlagen!",
    "account_failure_0048": "Rollback fehlgeschlagen, Plattformvermögen Protokollierung fehlgeschlagen!",
    "account_failure_0049": "Diese Währung existiert bereits!",
    "account_failure_0050": "Händler neue Währung fehlgeschlagen!",
    "account_failure_0051": "Diese Währung existiert bereits!",
    "account_failure_0053": "Falscher Transaktionstyp!",
    "account_failure_0054": "Händlerverfügbarkeit hinzufügen fehlgeschlagen!",
    "account_failure_0055": "Händlerverfügbarkeit Protokollierung fehlgeschlagen!",
    "account_failure_0056": "Benutzer Tabelle Händler, Plattform Gewinnübertragung fehlgeschlagen!",
    "account_failure_0057": "Benutzer Tabelle Händler, Plattform Gewinnübertragung Protokollierung fehlgeschlagen!",
    "account_failure_0058": "Benutzerverfügbarkeit zu Anlage fehlgeschlagen!",
    "account_failure_0059": "Benutzerverfügbarkeit zu Anlage Protokollierung fehlgeschlagen!",
    "account_failure_0060": "Nicht genug Anlage, nicht ausführbar!",
    "account_failure_0061": "Benutzeranlage zu Verfügbarkeit fehlgeschlagen!",
    "account_failure_0062": "Benutzeranlage zu Verfügbarkeit, Protokollierung fehlgeschlagen!",
    "account_failure_0063": "Rollback fehlgeschlagen, keine operablen Daten!",
    "account_failure_0064": "Diese Währung derzeit nicht verfügbar!",
    "account_failure_0065": "Kein Zugriff auf diese Währung, bitte kontaktieren Sie den Support!",
    "account_failure_0071": "Benutzer Lagerung fehlerhaft!",
    "account_failure_0072": "Benutzer Einzahlungsprotokoll existiert nicht oder wurde geändert!",
    "activitie_end": "Aktivität beendet",
    "activity_invalid": "Ungültig",
    "actual_amount": "Tatsächlicher Gesamtbetrag",
    "actual_exchange_amount": "Tatsächlicher Umtauschbetrag",
    "actual_to_account": "Tatsächlicher Gutschrift",
    "addSuccess": "Erfolgreich hinzugefügt",
    "add_channel_function": "Neue Verifizierungskanal-Funktion hinzufügen",
    "add_charge_and_transfer_function": "Neue Einzahlungs- und Übertragungsfunktion hinzufügen",
    "added_alert_setting_function": "Neue Warnungseinstellung hinzufügen",
    "added_day_mode_toggle": "Neuer Tag-Nacht-Modus hinzufügen",
    "address_tag": "Tag",
    "advanced_limit_order": "Erweiterter Limitauftrag",
    "advertising": "Werbung",
    "advertising_management": "Werbungsverwaltung",
    "affirm": "Bestätigen",
    "affirm_pass": "Passwort bestätigen",
    "again_digits6": "Bitte neues Passwort erneut eingeben",
    "again_password": "Bitte Anmeldpasswort erneut eingeben",
    "airdrop_coin": "Airdrop-Währung",
    "airdrop_date": "Airdrop-Datum",
    "airdrop_number": "Airdrop-Menge",
    "alipay_account": "Alipay-Konto",
    "alipay_upload": "*Alipay QR-Code hochladen: Alipay-Startseite > Geld empfangen > Bild speichern.",
    "all": "Alle",
    "all_buy": "Alle Käufe",
    "all_country": "Alle Länder",
    "all_order": "Alle Bestellungen",
    "all_sell": "Alle Verkäufe",
    "already_use": "Bereits aktiviert",
    "amount_of_payout": "Auszahlungsbetrag",
    "announcement_all": "Alle Ankündigungen",
    "announcement_center": "Ankündigungszentrum",
    "announcement_official": "Offizielle Ankündigung",
    "annualized_rate": "Jahreszins",
    "appeal_cancel": "Beschwerde Entscheidung, Bestellung abgebrochen",
    "appeal_complete": "Beschwerde Entscheidung, Bestellung abgeschlossen",
    "appeal_dispose_time": "Bearbeitungszeit der Beschwerde",
    "appeal_of_time": "Einreichungszeit der Beschwerde",
    "apply_locked": "Antrag auf Lagerung",
    "asset_allocatio": "Vermögensverteilung",
    "assets_asset_valuation": "Vermögensbewertung",
    "assets_can_transfer": "Übertragbar",
    "assets_cash_flow": "Geldfluss",
    "assets_coin_funds_flow": "Währungsfluss",
    "assets_currency_assets": "Währungsvermögen",
    "assets_deposit_income_tip": "Dieser Ertragswert wird durch den aktuellen Wechselkurs berechnet.",
    "assets_fiat_assets": "OTC-Vermögen",
    "assets_fiat_money_flow": "OTC-Geldfluss",
    "assets_flowing_details": "Flussdetails",
    "assets_prompt_001": "[Bitte sichern Sie Ihren Schlüssel zur Verhinderung von Verlust]",
    "assets_reduced_asset": "Vermögen reduziert",
    "assets_row_mining": "Aufträge Minen",
    "assets_swipe_direction": "Übertragungsrichtung",
    "assets_transfer": "Übertragen",
    "assets_transfer_coin": "Übertragungswährung",
    "assets_transfer_count": "Übertragungsmenge",
    "assets_transfer_currency": "Geldübertragung",
    "assets_transfer_dialog_bb": "Währungs-Konto",
    "assets_transfer_dialog_fiat": "Fiat-Konto",
    "assets_transfer_dialog_from": "Von",
    "assets_transfer_dialog_transfer_to": "Übertragen an",
    "assets_transfer_dialog_wallet": "Wallet-Konto",
    "assets_transfer_record": "Übertragungsprotokoll",
    "assets_transfer_to": "An",
    "assets_wallet": "Wallet",
    "assets_wallet_assets": "Wallet-Vermögen",
    "assets_wallet_assets_transfer_tip": "*Nur Wallet-Vermögen können übertragen werden, um Transaktionen durchzuführen. Übertragungen zwischen Konten sind gebührenfrei.",
    "assets_wallet_funds_flow": "Wallet-Geldfluss",
    "at_least_one": "Mindestens eine Handels-Passwortverifizierung aktivieren",
    "attention_blacklist": "Beobachtung / Sperrliste",
    "attention_to_see": "Wenn Sie mir folgen möchten, klicken Sie hier!",
    "audit_result": "Prüfergebnis",
    "authenticated": "Zertifiziert",
    "authentication": "Identitätsüberprüfung",
    "available_balance": "Verfügbarer Saldo",
    "average_purchase_price": "Durchschnittspreis",
    "avoid_close_set": "Passwortfreies Setzen",
    "back": "Zurück",
    "balance": "Saldo",
    "bank_card": "Bankkarte",
    "bank_name": "Bankname",
    "bank_num": "Bankkartennummer",
    "barcode_in_the_box_scan": "QR-Code/Barcode in das Feld legen, um automatisch zu scannen",
    "bb_assets": "Währungsvermögen",
    "bb_history_finished": "Abgeschlossen",
    "bb_time": "Zeit",
    "bb_used": "Aktiviert",
    "be_usable": "Verwendbar",
    "bear_consequences_your_investment": "Ich bin mir der Investitionsrisiken bewusst und übernehme sie freiwillig, mein Bestätigungscode ist:",
    "become": "Werden",
    "been_authenticated": "Sie haben sich erfolgreich verifiziert",
    "beginner_guide_title": "Führende Plattform für digitale Vermögenswerte",
    "belong_level": "Zugehörige Stufe",
    "between_and": "Bis",
    "bill": "Rechnung",
    "billing_details": "Rechnungsdetails",
    "bind_email": "E-Mail binden",
    "bind_google": "Google Authenticator",
    "bind_google_authenticator": "Google Authenticator binden",
    "bind_phone": "Telefon binden",
    "branch_name": "Filiale",
    "bug_order": "Kaufauftrag",
    "buy": "Kaufen",
    "buy_1": "Kaufen",
    "buy_now": "Jetzt kaufen",
    "buy_quantity": "Kaufmenge",
    "buy_rate": "Kaufgebühr",
    "buyer": "Käufer",
    "buyer_has_paid_attention": "Käufer hat gezahlt",
    "buyer_payment": "Käufer hat gezahlt",
    "buyer_phone": "Käufer Telefonnummer",
    "buyer_uid": "Käufer-ID",
    "calculate": "Berechnen",
    "calculations are for your reference only": "Berechnungen sind nur zur Orientierung",
    "can_available": "Erhältlich",
    "canceled": "Abgebrochen",
    "cancellations": "Stornierungen",
    "centre": "Zentrum",
    "change1": "Ändern",
    "change_gesture_password": "Passwort ändern",
    "change_google": "Google Authenticator wechseln",
    "change_pay_style": "Zahlungsart ändern",
    "change_phone": "Telefon ändern",
    "charge_after_the_transaction": "Gebühren nach Transaktion",
    "charge_out": "Gebühren müssen innerhalb",
    "check_the_agree": "Zustimmung ankreuzen",
    "china": "China",
    "choose_picture": "Bild auswählen",
    "clearing_time": "Abrechnungszeit",
    "click_enter_batch_edit_page": "Klicken Sie hier, um zur Batch-Bearbeitungsseite zu gelangen",
    "click_on_the_copy": "Klicken Sie zum Kopieren",
    "clinch_a_deal": "Der andere muss mehrere Male gehandelt haben (0 bedeutet unbegrenzt)",
    "close": "Schließen",
    "closed_successfully": "Erfolgreich geschlossen",
    "cloud_mining_pool": "Cloud Mining Pool",
    "coin_failure_0001": "Währung existiert nicht",
    "collect": "Auswahl",
    "collection_time": "Einsammelzeit",
    "commission": "Verdiente Provision",
    "common_failure": "Fehlgeschlagen!",
    "common_failure_0001": "Netzwerkfehler",
    "common_problem": "Betriebsanleitung",
    "common_success": "Erfolgreich",
    "complete": "Vollständig",
    "completed": "Transaktion abgeschlossen",
    "computer_login": "Anmeldung am Computer",
    "confirm": "Bestätigen",
    "confirm_exit": "Ausloggen bestätigen?",
    "confirm_password": "Bitte Passwort bestätigen",
    "confirm_submission": "Einreichung bestätigen",
    "confirm_the_purchase": "Kauf bestätigen",
    "confirm_to_give_up": "Aufgeben bestätigen",
    "confirm_to_sell": "Verkauf bestätigen",
    "confirm_upgrade": "Upgrade bestätigen",
    "confirm_vote": "Stimmen bestätigen",
    "confirmation_information": "Bestätigungsinformationen",
    "confrim_delete_warn": "Löschen bestätigen?",
    "contact_service": "Kontaktiere den Support",
    "contact_us": "Kontaktiere uns",
    "contract_Order": "Auftrag",
    "contrct Agree Content": "Um Ihre Rechte zu schützen, lesen Sie bitte die \"BitMatrix-Dauervertrag Vereinbarung\".",
    "contrct Agree Content footer": "\"BitMatrix-Dauervertrag Vereinbarung\".",
    "contrct Agree Content header": "Um Ihre Rechte zu schützen, lesen Sie bitte sorgfältig.",
    "contrct_decimal": "Dezimal.",
    "convert_into": "Umrechnen.",
    "converted_currency": "Umgerechnete Währung.",
    "copartner-upgrade-failure": "Upgrade fehlgeschlagen.",
    "copartner-upgrade-no": "Upgrade-Bedingungen nicht erfüllt.",
    "copartner-upgrade-ok": "Upgrade möglich.",
    "copartner-upgrade-success": "Upgrade erfolgreich.",
    "copy_content": "In die Zwischenablage kopiert.",
    "country": "Land.",
    "create_time": "Erstellungszeit.",
    "cumulative_income": "Kumulative Einnahmen:",
    "cumulative_participation": "Kumulative Teilnahme.",
    "currency": "Währung.",
    "currency_fee": "Währungsgebühr.",
    "currency_information": "Währungsdetails.",
    "currency_name": "Währungsname.",
    "currency_not_found": "Währungstyp nicht gefunden.",
    "currency_select": "Währungsauswahl.",
    "current_day_return": "Tagesrendite.",
    "current_entrust": "Aktuelle Beauftragung.",
    "current_identity": "Aktuelle Identität.",
    "current_polling_date": "Aktuelles Abstimmungsdatum.",
    "current_status": "Aktueller Status.",
    "currently_available": "Derzeit verfügbar.",
    "date": "Datum.",
    "date_of_birth": "Geburtsdatum.",
    "day_highs_and_lows": "Tageshoch und -tief.",
    "day_highs_and_lows1": "Tageshoch und -tief.",
    "day_income": "Tag.",
    "day_trading": "Tageshandel.",
    "daytime": "Tag.",
    "ddress_management": "Adressverwaltung.",
    "deal_amount": "Gesamtbetrag.",
    "deal_done": "Abgeschlossen.",
    "deal_gross": "Gesamtmenge.",
    "deal_of_the_week": "Woche Handel.",
    "deal_price": "Durchschnittspreis.",
    "deal_sum": "Transaktionssumme.",
    "deal_the_detail": "Auftragsdetails.",
    "deal_volume": "Handelsvolumen.",
    "deduct_coin": "Abzugswährung.",
    "default_settlement": "Standard-Abrechnung.",
    "defaults": "Standardwerte.",
    "deposit": "Einzahlung.",
    "deposit_details": "Einzahlungsdetails.",
    "deposit_income": "Einzahlungsrendite.",
    "deposit_lock": "Einzahlungslock.",
    "deposit_lock_details": "Details zum Einzahlungslock.",
    "deposit_returned_principal": "Hauptbetrag zurückgegeben.",
    "depth": "Tiefe.",
    "detailed_rules": "Detaillierte Regeln.",
    "details": "Details.",
    "digits6": "Bitte 6 Ziffern eingeben.",
    "direct_market": "Direktwerbung.",
    "direct_reward": "Direktbelohnung.",
    "direction": "Richtung.",
    "discount_rate": "Rabatt.",
    "discount_currency_introduction": "Währungsbeschreibung.",
    "discount_dialog_tip1": "Herzlichen Glückwunsch! Teilnahmebedingungen erfüllt.",
    "discount_dialog_tip2": "Sie wurden als glücklicher Nutzer ausgewählt, nach Erhalt der Berechtigung können Sie teilnehmen!",
    "discount_dialog_tip3": "Sie haben die Berechtigung.",
    "discount_dialog_tip4": "Bitte nehmen Sie innerhalb der Aktionszeit teil, da die Teilnahmeplätze begrenzt sind!",
    "discount_dialog_tip5": "Nach Verzicht keine Teilnahme möglich.",
    "discount_dialog_tip6": "Hinweis: Erfolgreiche Teilnahme kann nicht beantragt werden.",
    "discount_dialog_tip7": "Hinweis: Erfolgreiche Berechtigung wird abgezogen.",
    "discount_purchase": "Rabatt einlösen.",
    "discounted_or_original_price": "Rabattpreis / Originalpreis.",
    "dispose_result": "Verarbeitungsergebnis.",
    "disqualification": "Verzicht auf Berechtigung.",
    "divided_earnings": "Gewinnbeteiligung.",
    "drag": "Bitte nach rechts ziehen.",
    "each_input": "Jede Transaktion erfordert Passwortbestätigung.",
    "edt_selfchoose": "Selbst auswählen.",
    "effective": "Wirksam.",
    "eligibility": "Teilnahmeberechtigung.",
    "email": "E-Mail-Registrierung.",
    "email_address_format": "Bitte gültige E-Mail-Adresse eingeben.",
    "email_code": "E-Mail-Bestätigungscode.",
    "empty_deal_pass": "Transaktionspasswort darf nicht leer sein.",
    "end_time": "Endzeit.",
    "end_date": "Enddatum.",
    "end_time": "Endzeit.",
    "enter_account": "Bitte Telefon/E-Mail eingeben.",
    "enter_cause_of_complaint": "Bitte Beschwerdegrund eingeben.",
    "enter_fb_buy_price": "Bitte Kaufpreis eingeben.",
    "enter_fb_sell_price": "Bitte Verkaufspreis eingeben.",
    "enter_your_password": "Bitte Passwort eingeben.",
    "enter_password": "Bitte Passwort eingeben.",
    "entrust_amount": "Beauftragte Menge.",
    "entrust_failure_0001": "Netzwerkfehler.",
    "entrust_failure_0002": "Abfragefehler.",
    "entrust_failure_0003": "Beauftragungsart darf nicht leer sein.",
    "entrust_failure_0004": "Händler-ID darf nicht leer sein.",
    "entrust_failure_0005": "Benutzer-ID darf nicht leer sein.",
    "entrust_failure_0006": "Benutzer existiert nicht.",
    "entrust_failure_0007": "Handels-Paar-ID darf nicht leer sein.",
    "entrust_failure_0009": "Handels-Paar existiert nicht.",
    "entrust_failure_0010": "Transaktionspasswort darf nicht leer sein.",
    "entrust_failure_0011": "Transaktionspasswort fehlerhaft.",
    "entrust_failure_0012": "Auftragstyp darf nicht leer sein.",
    "entrust_failure_0013": "Auftragsmenge darf nicht leer sein.",
    "entrust_failure_0014": "Limitpreis darf nicht leer sein.",
    "entrust_failure_0015": "Auftrags-ID darf nicht leer sein.",
    "entrust_failure_0016": "Auftrag existiert nicht.",
    "entrust_failure_0017": "Dieser Auftrag wurde gelöscht.",
    "entrust_failure_0018": "Dieser Auftrag kann nicht storniert werden.",
    "entrust_failure_0019": "Versionsnummer darf nicht leer sein.",
    "entrust_failure_0020": "Marktpreisauftrag kann nicht storniert werden.",
    "entrust_failure_0021": "Aktuelle Daten wurden aktualisiert.",
    "entrust_failure_0022": "Derzeit keine Aufträge, Marktpreisauftrag kann nicht platziert werden.",
    "entrust_failure_0023": "Handels-Paar deaktiviert oder zugehörige Währung deaktiviert.",
    "entrust_failure_0024": "Dieses Handels-Paar hat den Handel nicht eröffnet.",
    "entrust_failure_0025": "Auftragspreis liegt unter dem Mindestpreis oder über dem Höchstpreis.",
    "entrust_failure_0026": "Auftragsmenge liegt unter der Mindestmenge oder über der Höchstmenge.",
    "entrust_failure_0027": "Dieses Konto ist für den Handel gesperrt, bitte kontaktieren Sie den Kundendienst.",
    "entrust_failure_0030": "Übersteigt die tägliche maximale Preissteigerung von {} für dieses Handels-Paar, Bestellung kann nicht platziert werden.",
    "entrust_failure_0031": "Übersteigt die tägliche maximale Preisverringerung von {} für dieses Handels-Paar, Bestellung kann nicht platziert werden.",
    "entrust_msg_match_type_001": "Limitauftrag.",
    "entrust_msg_match_type_002": "Marktpreisauftrag.",
    "entrust_msg_status_001": "Nicht ausgeführt.",
    "entrust_msg_status_002": "Teilweise ausgeführt.",
    "entrust_msg_status_003": "Vollständig ausgeführt.",
    "entrust_msg_status_004": "Storniert.",
    "entrust_msg_type_001": "Verkauf.",
    "entrust_msg_type_002": "Kauf.",
    "entrusted_price": "Beauftragter Preis.",
    "entry_orders": "Auftrag platzieren.",
    "equal_principal": "Gleicher Kapitalbetrag.",
    "error_email": "E-Mail-Fehler.",
    "error_network_anomaly": "Netzwerkfehler.",
    "error_phone": "Bitte gültige Telefonnummer eingeben.",
    "error_unknow": "Unbekannter Netzwerkfehler.",
    "event_detail": "Veranstaltungsdetails.",
    "event_detail_warn_tip": "*Hinweis: Digitale Vermögenswerte sind innovative Vermögenswerte mit hohen Preisschwankungen. Bitte sorgfältig abwägen und fundierte Investitionsentscheidungen treffen!",
    "event_start_time": "Veranstaltungsbeginn.",
    "every day": "Jeden Tag.",
    "everyday": "Täglich.",
    "exchange": "Schnellwechsel.",
    "exchange_currency": "Wechselwährung.",
    "exchange_details": "Schnellwechsel-Details.",
    "exchange_history": "Schnellwechsel-Historie.",
    "exchange_quantity": "Wechselmenge.",
    "exchange_rules": "Wechselregeln.",
    "exchangeable": "Wechselbar.",
    "exclusive_posters": "Exklusive Poster erstellen.",
    "exclusive_posters-Video": "Poster erstellen.",
    "exit": "Ausloggen.",
    "expected_return": "Erwartete Rendite.",
    "expired": "Abgelaufen.",
    "express_area": "Schnellbereich.",
    "face_recognition": "Gesichtserkennung.",
    "failure": "Fehler.",
    "fb_lookup": "Klicken Sie hier, um zu sehen.",
    "fb_order_number": "Bestellnummer.",
    "feeze_thaw_msg_status_0001": "Eingefroren.",
    "feeze_thaw_msg_status_0002": "Entfroren.",
    "fill_in_at_least_one": "Bitte mindestens einen Punkt ausfüllen.",
    "fill_wrong": "Falsche Eingabe kann zu Verlusten führen, bitte sorgfältig überprüfen.",
    "filtrate": "Filtern.",
    "filtrate_content": "Filtern Sie Zahlungsmethoden, Länder, Fiat-Währungen hier!",
    "financial_management_is_cancel": "Erträge aus Einzahlungen wurden storniert! Bitte nicht erneut einreichen!",
    "financial_management_is_delete": "Dieser Anlagetyp wurde gelöscht.",
    "financial_management_is_disable": "Dieser Anlagetyp wurde deaktiviert.",
    "financial_management_not_cancel": "Regelmäßige Investitionen können nicht storniert werden!",
    "financial_management_number_not_found": "Investitionsmenge darf nicht leer sein.",
    "financial_management_record_add_failure": "Benutzer hat nicht genug Vermögen, bitte einzahlen und dann investieren!",
    "financial_management_record_less_than_min_number": "Hinzugefügte Menge ist kleiner als die festgelegte Mindestmenge {}.",
    "financial_management_type_not_found": "Fehler beim Anzeigen der Anlagedaten, Anlagetyp falsch gewählt!",
    "financial_management_type_not_null": "Anlagetyp darf nicht leer sein.",
    "find_password": "Passwort zurücksetzen.",
    "fingerprint": "Fingerabdruck.",
    "fingerprint_verification": "Fingerabdruckverifizierung.",
    "first_bind_phone": "Bitte zuerst Telefonnummer binden.",
    "first_period": "Erste Periode.",
    "following_account": "Auf folgendes Konto.",
    "free": "Transaktionsgebühren.",
    "Takerfree": "Taker-Gebühren.",
    "Makerfree": "Maker-Gebühren.",
    "freeze": "Einfrieren.",
    "freeze_to_be_processed": "Einfrieren zur Bearbeitung.",
    "fuc_use_discount": "{} verwenden, um Transaktionsgebühren zu zahlen * Rabatt.",
    "futu": "Nebendiagramm.",
    "gathering": "Zahlung empfangen.",
    "gears": "Gang.",
    "generation1": "1. Generation.",
    "gesture_error_limit": "Maximale Anzahl an fehlerhaften Eingaben für das Gestenpasswort erreicht.",
    "gesture_password": "Gestenpasswort.",
    "gesture_unlock": "Gestenentsperrung.",
    "get_code": "Bestätigungscode anfordern.",
    "goSign": "Jetzt registrieren.",
    "go_change": "Jetzt ändern.",
    "go_login": "Bitte zuerst einloggen.",
    "go_pay": "Jetzt bezahlen.",
    "go_setting": "Jetzt einstellen.",
    "go_to_pick_up": "Jetzt abholen.",
    "go_to_receiving": "Jetzt empfangen.",
    "go_to_redeem": "Jetzt einlösen.",
    "google_verification_code": "Google-Bestätigungscode.",
    "grievance_freeze": "Einfrieren zur Beschwerde.",
    "has_been_on": "Ist gelistet.",
    "have_been_frozen": "Wurde eingefroren.",
    "have_received": "Wurde empfangen.",
    "help_center": "Hilfezentrum.",
    "hide_canceled": "Abgebrochene verstecken.",
    "hide_other": "Alle Aufträge anzeigen.",
    "high_frequency_partner": "Hochfrequenzpartner.",
    "highest_single": "Höchster Einzelbetrag.",
    "highfrequency_fee_sharing": "Hochfrequenz-Gebührenbeteiligung.",
    "hint": "Hinweis.",
    "hint_password": "Bitte Passwort eingeben.",
    "hint_phone_email": "Bitte Telefonnummer/E-Mail eingeben.",
    "historical_transaction": "Historische Transaktionen.",
    "history": "Historie.",
    "home_page": "Startseite.",
    "horizontal_version": "Horizontale Ansicht.",
    "hours": "Stunden.",
    "i18n_failure_0001": "Systemsprachenabfrage fehlgeschlagen.",
    "i18n_failure_0002": "Länderwörterbuchabfrage fehlgeschlagen.",
    "iTo_draw_again": "Bitte Passwort erneut eingeben.",
    "i_see": "Ich verstehe.",
    "i_see1": "Verstanden.",
    "id_card_back_direction_failure": "Rückseite des Ausweises hat falsche Richtung.",
    "id_card_front_direction_failure": "Vorderseite des Ausweises hat falsche Richtung.",
    "id_cord": "Ausweisnummer.",
    "id_type": "Ausweistyp.",
    "identification": "Identifikation fehlgeschlagen.",
    "identity_authentication": "Identitätsprüfung.",
    "identity_card": "Personalausweis.",
    "if_at": "Wenn bei.",
    "ignore": "Ignorieren.",
    "immediately_invited": "Sofort einladen.",
    "immediately_join": "Sofort beitreten.",
    "in_trading": "Im Handel.",
    "income": "Einnahmen.",
    "income_currency_type": "Währungstyp der Einzahlung.",
    "income_deposit_principal": "Einzahlungsgrundlage.",
    "incomeing": "In Einnahmen.",
    "increment quantity": "Eingabemenge darf die maximale Erhöhung nicht überschreiten.",
    "indirect_promotion": "Indirekte Promotion.",
    "indirect_reward": "Indirekte Belohnung.",
    "individual_selling_tips": "Kann nicht an eigene Bestellungen verkauft werden.",
    "input_Branch_address": "Bitte Filialadresse eingeben.",
    "input_address_labels": "Bitte Etiketten eingeben.",
    "input_alipay_account": "Bitte Alipay-Konto eingeben.",
    "input_bank_card_number": "Bitte Bankkartennummer eingeben.",
    "input_bank_name": "Bitte Banknamen eingeben.",
    "input_buyer_uid": "Bitte Käufer-UID eingeben.",
    "input_currency_referred": "Bitte Abkürzung der Währung eingeben.",
    "input_deposit_amount": "Bitte Einzahlungsbetrag eingeben.",
    "input_email": "Bitte E-Mail-Adresse eingeben.",
    "input_email_code": "Bitte E-Mail-Bestätigungscode eingeben.",
    "input_id_cord": "Bitte Ausweisnummer eingeben.",
    "input_lock_amount": "Bitte Menge für die Sperrung eingeben.",
    "input_mention_money_address": "Bitte Abhebungsadresse eingeben.",
    "input_money": "Bitte Betrag eingeben.",
    "input_name": "Bitte Namen eingeben.",
    "input_newphone_code": "Bitte neue Telefonnummer eingeben.",
    "input_nike_name": "Bitte Spitznamen eingeben.",
    "input_old_google_code": "Bitte alten Google-Bestätigungscode eingeben.",
    "input_old_pass": "Bitte altes Passwort eingeben.",
    "input_paypal_acctount": "Bitte PayPal-Konto eingeben.",
    "input_phone": "Bitte Telefonnummer eingeben.",
    "input_phone_code": "Bitte Bestätigungscode eingeben.",
    "input_price": "Bitte Preis eingeben.",
    "input_quantity": "Bitte Menge eingeben.",
    "input_remark": "Bitte Bemerkung eingeben.",
    "input_service_charge": "Bitte Transaktionsgebühren eingeben.",
    "input_trading_volume": "Bitte Handelsvolumen eingeben.",
    "input_transaction_password": "Bitte Transaktionspasswort eingeben.",
    "input_wechat_account": "Bitte WeChat-Konto eingeben.",
    "input_wire_transfer": "Bitte Überweisungsadresse eingeben.",
    "inputs_numbers_greater_can_number": "Eingabemenge ist größer als verfügbare Menge.",
    "insufficient_light": "Unzureichendes Licht.",
    "interest": "Zinsen.",
    "invitation_details": "Einladungsbelohnungsdetails.",
    "invitation_register_ad_not_found": "App-Teileinladung zur Registrierung nicht verfügbar.",
    "invitation_register_ad_tatle_not_found": "App-Teileinladung zur Registrierung Titel nicht verfügbar.",
    "invitation_register_coin_not_found": "App-Teileinladung zur Registrierung Belohnungswährung nicht verfügbar.",
    "invitation_register_logo_not_found": "App-Teileinladung zur Registrierung Logo nicht verfügbar.",
    "invitation_register_not_found": "Einladungsregistrierungslink nicht verfügbar.",
    "invite_code": "Einladungscode (optional).",
    "invite_code1": "Einladungscode.",
    "invite_commission": "Provision.",
    "invite_gift": "Einladungsgeschenk.",
    "invite_reward": "Einladungsbelohnung.",
    "invited_UID": "Eingeladene UID.",
    "invitees_are_available": "Einladende können erhalten.",
    "inviter-is-user": "Der eingegebene Einladende kann nicht die eigene Person sein.",
    "inviter-not-exist": "Der eingegebene Einladende existiert nicht.",
    "inviter_no_null": "Einladender darf nicht leer sein.",
    "ios_24小时排行榜": "24-Stunden-Rangliste.",
    "ios_OTC交易须知": "OTC-Handelsinformationen.",
    "ios_PAYPAL": "PAYPAL.",
    "ios_otc_order_prompt_001": "Wenn innerhalb von Minuten kein Zahlungseingang erfolgt und die Schaltfläche 'Zahlung bestätigen' nicht geklickt wird, wird die Bestellung nach Ablauf des Countdowns automatisch storniert.",
    "ios_otc_order_prompt_002": "Wenn innerhalb von Minuten kein Zahlungseingang bei dem Verkäufer erfolgt und die Schaltfläche 'Zahlung bestätigen' nicht geklickt wird, wird die Bestellung nach Ablauf des Countdowns automatisch storniert.",
    "ios_万": "Zehntausend.",
    "ios_上传身份证反面": "Rückseite des Ausweises hochladen.",
    "ios_上传身份证正面": "Vorderseite des Ausweises hochladen.",
    "ios_两次密碼不一致请再次输入密碼": "Die beiden Passwörter stimmen nicht überein, bitte Passwort erneut eingeben.",
    "ios_个确认大约需要": "Die Bestätigung benötigt etwa.",
    "ios_中国大陆": "Festlandchina.",
    "ios_中级合伙人": "Mittelstufiger Partner.",
    "ios_主版块": "Hauptbereich.",
    "ios_之间": "Zwischen.",
    "ios_买单": "Kaufauftrag.",
    "ios_买方必须成交过几次(0等于不限制)": "Der Käufer muss ein paar Mal erfolgreich gehandelt haben (0 bedeutet keine Einschränkung).",
    "ios_二维码/条形码": "QR-Code/Barcode.",
    "ios_付款": "Zahlung.",
    "ios_充币地址": "Einzahlungsadresse.",
    "ios_先设置交易密码": "Bitte zuerst Transaktionspasswort festlegen.",
    "ios_先设置交易密碼": "Bitte zuerst Transaktionspasswort festlegen.",
    "ios_关闭照明": "Beleuchtung ausschalten.",
    "ios_分享失败": "Teilen fehlgeschlagen.",
    "ios_分享成功": "Teilen erfolgreich.",
    "ios_初级合伙人": "Anfängerpartner.",
    "ios_卖单": "Verkaufsauftrag.",
    "ios_卖家已付款提示": "Hinweis: Verkäufer hat bezahlt.",
    "ios_卖方必须成交过几次(0等于不限制)": "Der Verkäufer muss ein paar Mal erfolgreich gehandelt haben (0 bedeutet keine Einschränkung).",
    "ios_去解绑": "Entbinden.",
    "ios_发布成功": "Veröffentlichung erfolgreich.",
    "ios_发送成功": "Versand erfolgreich.",
    "ios_各种费率说明": "Erläuterung verschiedener Gebühren.",
    "ios_团队人数不足": "Nicht genügend Teammitglieder.",
    "ios_复制QQ号": "QQ-Nummer kopieren.",
    "ios_复制地址": "Adresse kopieren.",
    "ios_如果你已完成线下付款，请点击下方已付款按钮。如果未完成付款，却点击已付款将会受到严厉惩罚，甚至永久冻结账户！": "Wenn Sie die Offline-Zahlung abgeschlossen haben, klicken Sie bitte auf die Schaltfläche 'Bereits bezahlt' unten. Wenn Sie die Zahlung nicht abgeschlossen haben, aber auf 'Bereits bezahlt' klicken, wird dies streng bestraft, bis hin zur dauerhaften Kontosperrung!",
    "ios_客户端或在线钱包将您需要充值的": "Der Client oder das Online-Wallet zeigt, was Sie aufladen müssen.",
    "ios_客户端或在线钱包将您需要充幣的": "Der Client oder das Online-Wallet zeigt, was Sie aufladen müssen.",
    "ios_客服": "Kundendienst.",
    "ios_密碼设置成功！": "Passwort erfolgreich festgelegt!",
    "ios_将该地址设置为认证地址": "Diese Adresse als Zertifizierungsadresse festlegen.",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充值，不影响到账。\n3、最小充值金额": "Stundenzeit, bitte Geduld haben.\n2. Dieselbe Adresse kann mehrfach aufgeladen werden, ohne dass dies die Gutschrift beeinflusst.\n3. Minimaler Einzahlungsbetrag.",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充幣，不影响到账。\n3、最小充幣金额": "Stundenzeit, bitte Geduld haben.\n2. Dieselbe Adresse kann mehrfach aufgeladen werden, ohne dass dies die Gutschrift beeinflusst.\n3. Minimaler Einzahlungsbetrag.",
    "ios_已付款": "Bereits bezahlt.",
    "ios_币币": "Krypto-Krypto.",
    "ios_常见问题": "Häufige Fragen.",
    "ios_开启照明": "Beleuchtung einschalten.",
    "ios_意见反馈": "Feedback.",
    "ios_成交": "Abschluss.",
    "ios_我的": "Mein Bereich.",
    "ios_手机号错误": "Handynummer falsch.",
    "ios_提交成功，等待认证": "Erfolgreich eingereicht, warten auf die Bestätigung.",
    "ios_提币数量不能大于手续费": "Abhebungsmenge darf nicht größer als die Gebühr sein.",
    "ios_提币数量减去手续费后不能": "Die Abhebungsmenge minus die Gebühr darf nicht.",
    "ios_收款二维码": "Zahlung empfangen QR-Code.",
    "ios_收藏成功": "Erfolgreich gespeichert.",
    "ios_数目发送到该地址。发送完成后，系统会自动在此交易获得": "Betrag an diese Adresse gesendet. Nach Abschluss des Versands erhält das System automatisch in dieser Transaktion.",
    "ios_暂无网络": "Keine Netzwerkverbindung.",
    "ios_最多可锁": "Maximal sperrbar.",
    "ios_最大限额": "Maximalbetrag.",
    "ios_最小限额": "Minimalbetrag.",
    "ios_未解锁": "Nicht entsperrt.",
    "ios_没有中国大陆身份证的用户请通过网站版高级认证": "Benutzer ohne ID-Karte aus Festlandchina sollten über die Website eine hochrangige Authentifizierung durchführen.",
    "ios_法币": "Fiat-Währung.",
    "ios_注册表示同意": "Registrierung bedeutet Zustimmung.",
    "ios_注册表示同意用户协议": "Registrierung bedeutet Zustimmung zu den Nutzerbedingungen.",
    "ios_登录过期，请重新登录": "Login abgelaufen, bitte erneut einloggen.",
    "ios_直接推荐人uid": "Direkter Empfehlungs-UID.",
    "ios_直推人数不足": "Nicht genügend direkte Empfehlungen.",
    "ios_绑定成功": "Erfolgreich gebunden.",
    "ios_请上传您的身份证": "Bitte laden Sie Ihren Ausweis hoch.",
    "ios_请上传身份证反面照": "Bitte laden Sie ein Foto der Rückseite Ihres Ausweises hoch.",
    "ios_请上传身份证正面照": "Bitte laden Sie ein Foto der Vorderseite Ihres Ausweises hoch.",
    "ios_请先绑定收款方式": "Bitte zuerst Zahlungsmethode binden.",
    "ios_请先设置支付方式": "Bitte zuerst Zahlungsmethode festlegen.",
    "ios_请输入价格和数量": "Bitte Preis und Menge eingeben.",
    "ios_请输入新邮箱": "Bitte neue E-Mail eingeben.",
    "ios_请输入正确的账号格式": "Bitte geben Sie das richtige Kontofomat ein.",
    "ios_请输入身份证号": "Bitte Ausweisnummer eingeben.",
    "ios_请输入限额": "Bitte Limit eingeben.",
    "ios_请通过": "Bitte durch.",
    "ios_资产": "Vermögen.",
    "ios_超时": "Zeitüberschreitung.",
    "ios_身份证号": "Ausweisnummer.",
    "ios_退出成功": "Erfolgreich abgemeldet.",
    "ios_邀请人可获得 * 的佣金": "Der Einladende kann eine Provision von * erhalten.",
    "ios_锁仓数量不能为空": "Die Sperrmengen dürfen nicht leer sein.",
    "ios_间接接推荐人uid": "Indirekte Empfehlungs-UID.",
    "ios_隐藏其他挂单": "Andere Aufträge ausblenden.",
    "ios_高级合伙人": "Hochrangiger Partner.",
    "is_bind": "Gebunden.",
    "is_delete_select": "Soll die Auswahl gelöscht werden?",
    "issued": "Ausgegeben.",
    "issued_to_lock": "An Sperre ausgegeben.",
    "k_close": "Schließen.",
    "k_max": "Max.",
    "k_min": "Min.",
    "k_num": "Menge.",
    "k_open": "Öffnen.",
    "kline_target": "Ziel.",
    "language": "Sprache.",
    "lead_content": "Bestellabfrage, Anzeige veröffentlichen, Zahlungseinstellungen, folgen/blockieren wurde hierher verschoben!",
    "least_four": "Bitte mindestens 4 Punkte verbinden.",
    "less-than-min": "Unter dem Mindestbetrag für die Sperre.",
    "lf there are pending": "Wenn es ausstehende Aufträge in entgegengesetzter Richtung gibt, werden diese vor der vollständigen Schließung storniert.",
    "limit": "Limit-Einstellungen.",
    "limit_orderAA": "Limitauftrag.",
    "limit_set": "Einstellungen beschränken (optional)",
    "limited_price": "Limitpreis",
    "liquidation": "Zwangsveräußerung",
    "liquidation AMT": "Zwangsveräußern Menge",
    "liquidation details": "Details zur Zwangsveräußerung",
    "loading": "Wird geladen",
    "lock-expire": "Sperrfrist abgelaufen",
    "lock_amount": "Sperrmenge",
    "lock_duration": "Sperrdauer",
    "lock_person": "Sperrperson",
    "lock_record": "Sperrprotokoll",
    "lock_remaining": "Verbleibende Sperre",
    "lock_reward": "Sperrprämie",
    "lock_time": "Sperrzeit",
    "locked": "Gesperrt",
    "locked_closed": "Abgeschlossen",
    "locked_insufficient_balance": "Unzureichendes Guthaben",
    "locked_settled": "Abgerechnet",
    "locked_unsettlement": "Unabgerechnet",
    "locked_warehouse": "Gesperrt",
    "log_in": "Einloggen",
    "login": "Einloggen",
    "login_expired": "Anmeldung abgelaufen, bitte erneut einloggen.",
    "login_forget_pwd": "Passwort vergessen?",
    "login_name_txt": "Benutzername",
    "login_pass": "Anmeldepasswort",
    "logion_welcome_txt": "Willkommen beim Einloggen",
    "mail": "E-Mail",
    "mailbox": "E-Mail-Verifizierung",
    "mailbox_bind_success": "E-Mail-Bindung erfolgreich",
    "mailbox_new": "E-Mail",
    "main_picture": "Hauptbild",
    "manage_reward_details": "Details zur Belohnungsverwaltung",
    "management_reward": "Belohnungsmanagement",
    "management_share": "Verwaltung der Teilung",
    "mandarin": "Bitte lesen Sie die Zahlen auf Mandarin vollständig vor.",
    "mandarin_reading_content": "Bitte lesen Sie den folgenden Inhalt in standardsprachlichem Mandarin.",
    "manual_recharge_msg_status_0001": "Warten auf Aufladung",
    "manual_recharge_msg_status_0002": "Genehmigung erteilt",
    "manual_recharge_msg_status_0003": "Aufladung erfolgreich",
    "manual_recharge_msg_type_0001": "Normale Aufladung",
    "manual_recharge_msg_type_0002": "Kapitalbearbeitung",
    "manual_recharge_msg_type_0003": "Aktionsgeschenk",
    "margin": "Sicherheitsleistung",
    "market_failure_00001": "Fehler beim Abrufen der Marktdaten",
    "market_failure_00002": "Händler-ID existiert nicht",
    "market_failure_00003": "Kein verfügbares Handelsgebiet für den Händler",
    "market_failure_00004": "Fehler beim Abrufen der Krypto-Handelsdaten",
    "market_failure_00005": "Handelsparname darf nicht leer sein",
    "market_failure_00006": "Kein verfügbares Segment unter dem Händler",
    "market_failure_00007": "Fehler beim Abrufen der Handelsinformationsdaten",
    "market_failure_00008": "Fehler beim Abrufen der K-Daten",
    "market_failure_00009": "Fehler beim Abrufen der Markttiefendaten",
    "market_failure_00010": "Fehlerhafte Anforderungsparameter",
    "market_failure_00011": "Ungültiges Handelspaar",
    "market_low_price": "Höchster Kaufpreis auf dem Markt",
    "market_order": "Marktauftrag",
    "market_price": "Marktpreis",
    "market_sell_price": "Niedrigster Verkaufspreis auf dem Markt",
    "market_switch_moved_here": "Die Umschaltung zwischen horizontalen/vertikalen Aufträgen wurde hierher verschoben",
    "match": "Übereinstimmung",
    "match_the_number_of": "Übereinstimmende Menge",
    "max_lock_volume": "Maximale Sperrmenge",
    "maximum_30_characters": "Maximal 30 Zeichen",
    "maximum_coins": "Maximale Abhebemenge ist",
    "maximum_withdrawal": "Maximale Einzelabhebung",
    "mention_a_currency": "Abhebungsmenge",
    "min_lock_volume": "Minimale Sperrmenge",
    "min_recharge_amount": "Kleine Einzahlungen, die noch nicht gebucht sind, werden automatisch gebucht, wenn die gesamte Einzahlung die Mindestmenge von {}* erreicht.",
    "minhour": "Minutenstunde",
    "minimum_coins": "Minimale Abhebungsmenge ist",
    "minimum_purchase_quantity": "Kaufmenge muss größer sein als die verbleibende Menge {}",
    "minimum_quantity_sold": "Verkaufsmengen müssen größer sein als die verbleibende Menge {}",
    "minutes": "Minuten",
    "mobile phone": "Handynummer",
    "money": "Betrag",
    "more": "Mehr",
    "more Or than": "Long Position: Nennwert * Anzahl * Schließungspreis - Nennwert * Anzahl * Eröffnungspreis\n\nShort Position: Nennwert * Anzahl * Eröffnungspreis - Nennwert * Anzahl * Schließungspreis",
    "more-than-max": "Über dem maximalen Sperrlimit",
    "more11": "Mehr",
    "more_can_save": "Maximal einzahlbar",
    "more_home": "Mehr",
    "msg_camera_framework_bug": "Bitte aktivieren Sie die Kameraberechtigung",
    "must_retain_one": "Entweder Handy, Google oder E-Mail muss aufbewahrt werden",
    "my_income": "Mein Einkommen",
    "my_prompt_001": "*Nach der Änderung der Handynummer müssen Sie sich mit der neuen Handynummer anmelden.",
    "my_prompt_002": "*Hinweis: Bitte verwenden Sie unbedingt Ihr eigenes verifiziertes Konto. Die aktivierte Zahlungsmethode wird beim OTC-Handel dem Käufer angezeigt, Sie können bis zu 5 auswählen, aber maximal 3 aktivieren.",
    "my_prompt_003": "*Nach der Änderung der E-Mail müssen Sie sich mit der neuen E-Mail anmelden.",
    "my_vote": "Meine Abstimmung",
    "name": "Name",
    "name_empty": "Name darf nicht leer sein",
    "nationality": "Nationalität",
    "need_me_record_video": "Muss ein Video von mir selbst aufnehmen",
    "never_enter": "Nie das Transaktionspasswort eingeben",
    "new_address": "Neue Adresse hinzufügen",
    "new_pass": "Neues Passwort",
    "new_pass_empty": "Bitte neues Passwort eingeben",
    "new_pass_minimum": "Das Passwort darf nicht weniger als 6 Zeichen lang sein",
    "new_report_statistics": "Neue Berichterstattungsstatistik",
    "newest_versions": "Die aktuelle Version ist bereits die neueste!",
    "next": "Nächster Schritt",
    "nick_name": "Spitzname",
    "nickname_des_txt": "Der Spitzname unterstützt nur Chinesisch, Englisch und Zahlen, die Länge ist auf 15 Zeichen begrenzt",
    "nighttime": "Nacht",
    "ninety_days": "Letzte 90 Tage",
    "no-lock-detail": "Keine Sperrinformationen vorhanden",
    "no_Senior_certification": "Bitte führen Sie zuerst die Hochstufung durch",
    "no_data": "Keine Daten vorhanden",
    "no_flash": "Das aktuelle Gerät hat keinen Blitz und kann die Beleuchtungsfunktion nicht aktivieren",
    "no_more_data": "Keine weiteren Daten vorhanden",
    "no_photo_taking": "Ihr Gerät unterstützt keine Fotoaufnahme",
    "no_price": "Preis darf nicht leer sein",
    "no_quantity": "Bitte geben Sie eine gültige Menge ein",
    "no_realname_auth": "Bitte führen Sie zuerst die Identitätsprüfung durch",
    "no_use": "Nicht aktiviert",
    "none": "Kein",
    "not_bound": "Nicht gebunden",
    "not_frequency_partner": "Sie sind derzeit kein Partner",
    "not_redemption": "Keine Einlösung berechtigt",
    "not_started": "Nicht gestartet",
    "not_to_issue": "Nicht ausgegeben",
    "not_trading": "Dieser Token kann derzeit nicht gehandelt werden",
    "notpass": "Prüfung nicht bestanden",
    "null_address_tag": "Adressenetikett darf nicht leer sein",
    "null_code": "Bestätigungscode darf nicht leer sein",
    "null_email": "E-Mail darf nicht leer sein",
    "null_password": "Passwort darf nicht leer sein",
    "null_phone": "Handynummer darf nicht leer sein",
    "number": "Stück",
    "number_empty": "Menge darf nicht 0 sein",
    "number_of_arrivals": "Anzahl der Gutschriften",
    "number_of_issues": "Gesamtzahl der Ausgaben",
    "number_of_withdrawal": "Tägliche Abhebungsanzahl",
    "obtained": "Vom Markt genommen",
    "occlusion": "Gesicht verdecken",
    "occupy": "Belegen",
    "official_community": "Offizielle Community",
    "official_verification_channel": "Verifizierungskanal",
    "old_pass": "Altes Passwort",
    "old_pass_empty": "Bitte das ursprüngliche Passwort eingeben",
    "on_sale": "Zum Verkauf",
    "online_service": "Online-Kundendienst",
    "open XKD perpetual swap": "Öffnen Sie den BitMatrix-Dauervertrag",
    "open perpetual swap": "Öffnen Sie den Dauervertrag",
    "open_app": "App öffnen",
    "open_it": "Öffnen",
    "open_successfully": "Erfolgreich geöffnet",
    "open_touchID": "TouchID aktivieren?",
    "opposite_side_uid": "Gegenseite",
    "option": "Option",
    "optional": "Optional",
    "optional_area": "Optionale Zone",
    "options": "Auswahlmöglichkeiten",
    "order": "Auftrag",
    "order time": "Nach Auftragserstellungszeit",
    "order time1": "Auftragserstellungszeit",
    "order_a_complaint": "Auftrag beschweren",
    "order_details": "Auftragsdetails",
    "order_form": "Bestellung",
    "order_history": "Auftragshistorie",
    "order_inquiry": "Auftragsermittlung",
    "order_number": "Auftragsnummer",
    "order_time": "Bestellzeit",
    "ordinary_partner": "Ordinary Partner",
    "otc_account": "Konto",
    "otc_confirm_Put_the_coin": "Bestätigen Sie, dass Sie die Überweisung des anderen erhalten haben! (Warnung: Klicken Sie auf Bestätigen, bevor Sie die Zahlung erhalten, um Streitigkeiten zu vermeiden, wenden Sie sich an den Kundendienst)",
    "otc_confirm_the_payment": "Zahlung bestätigen",
    "otc_confirm_the_receipt": "Zahlungseingang bestätigen",
    "otc_failure_0001": "Konto wurde deaktiviert, bitte kontaktieren Sie umgehend den Kundendienst",
    "otc_failure_0002": "Konto hat die Hochstufung nicht bestanden, kann keine OTC-Operationen durchführen",
    "otc_failure_0003": "Transaktionspasswort des Kontos ist nicht korrekt",
    "otc_failure_0004": "Bestell-ID ist ungültig",
    "otc_failure_0006": "Statusänderung fehlgeschlagen",
    "otc_failure_0009": "Dieser Auftragstyp existiert nicht",
    "otc_failure_0010": "Stornierung fehlgeschlagen",
    "otc_failure_0011": "Dieser Auftrag wurde bereits abgeschlossen oder storniert",
    "otc_failure_0012": "Der aktuelle Benutzer ist kein OTC-Händler und hat kein Recht, diese Funktion zu nutzen",
    "otc_failure_0013": "Entsperrung fehlgeschlagen, Stornierung fehlgeschlagen",
    "otc_failure_0015": "Dieser Auftragstyp existiert nicht",
    "otc_failure_0016": "Auftragsmenge darf nicht kleiner oder gleich 0 sein",
    "otc_failure_0017": "Handelspreis darf nicht kleiner oder gleich 0 sein",
    "otc_failure_0018": "Mindestlimit für eine Einzeltransaktion darf nicht kleiner sein als 0",
    "otc_failure_0019": "Maximales Limit für eine Einzeltransaktion darf nicht kleiner sein als das Mindestlimit für eine Einzeltransaktion",
    "otc_failure_0020": "Diese Fiat-Währung wird nicht unterstützt",
    "otc_failure_0021": "Diese Fiat-Währung ist derzeit nicht verfügbar",
    "otc_failure_0022": "Händler-Krypto-ID ist ungültig",
    "otc_failure_0023": "Mindestlimit für eine Einzeltransaktion darf nicht kleiner sein als das Mindestlimit für diese Krypto-Währung {}",
    "otc_failure_0024": "Maximales Limit für eine Einzeltransaktion darf nicht größer sein als das maximale Limit für diese Krypto-Währung {}",
    "otc_failure_0025": "Auftragsmenge hat mehr Dezimalstellen als {}",
    "otc_failure_0026": "Auftragsgesamtbetrag darf nicht kleiner sein als die Mindestbestellgrenze {}",
    "otc_failure_0027": "Auftragsgesamtbetrag darf nicht größer sein als das Benutzerlimit {}",
    "otc_failure_0028": "Auftragsgesamtbetrag darf nicht größer sein als das Händlerlimit {}",
    "otc_failure_0029": "Preis darf nicht kleiner sein als {}",
    "otc_failure_0030": "Preis darf nicht größer sein als {}",
    "otc_failure_0031": "Der aktuelle Benutzer hat kein Zahlungs-Account gebunden",
    "otc_failure_0032": "Einfrieren fehlgeschlagen, Auftrag fehlgeschlagen",
    "otc_failure_0033": "Maximale Anzahl gleichzeitiger Bestellungen darf nicht kleiner sein als 0",
    "otc_failure_0034": "Mindestanzahl an abgeschlossenen Transaktionen für Käufer darf nicht kleiner sein als 0",
    "otc_failure_0035": "Dieser Benutzer hat die Händlerzertifizierung bestanden oder befindet sich in der Überprüfung",
    "otc_failure_0036": "Der Antragsteller hat nicht genügend erfolgreiche OTC-Transaktionen",
    "otc_failure_0037": "Entsperrung fehlgeschlagen, Stornierung fehlgeschlagen",
    "otc_failure_0038": "Der verfügbare Kontostand des Antragstellers {} ist unzureichend｛｝",
    "otc_failure_0039": "Aktuelle Währung nicht verfügbar",
    "otc_failure_0040": "Aktuelle Währung ist nicht verfügbar",
    "otc_failure_0041": "Einreichung erfolgreich, bitte auf Überprüfung warten",
    "otc_failure_0042": "Entschuldigung, dieser Benutzer befindet sich derzeit im Überprüfungsstatus und kann keinen weiteren Antrag stellen",
    "otc_failure_0043": "Keine dieser Handelsarten",
    "otc_failure_0044": "Selbstkauf und -verkauf sind verboten",
    "otc_failure_0046": "Die maximale Anzahl gleichzeitig bearbeiteter Aufträge {} wurde erreicht, bitte später erneut versuchen",
    "otc_failure_0047": "Der Käufer muss mindestens {} Transaktionen abgeschlossen haben",
    "otc_failure_0048": "Kaufmenge darf nicht kleiner oder gleich 0 sein",
    "otc_failure_0049": "Kaufmenge darf nicht größer sein als die aktuelle verbleibende Menge dieser Bestellung",
    "otc_failure_0050": "Auftrags-ID ist ungültig",
    "otc_failure_0051": "Die Gesamtkaufmenge darf nicht kleiner sein als das minimale Handelslimit der Währung {}",
    "otc_failure_0052": "Kaufbetrag darf nicht größer sein als das maximale Handelslimit der Währung {}",
    "otc_failure_0053": "Der aktuelle Auftragstyp ist kein Verkaufsauftrag",
    "otc_failure_0054": "Der aktuelle Auftragsstatus ist nicht korrekt",
    "otc_failure_0055": "Kaufbetrag darf nicht kleiner sein als das minimale Einzelhandelslimit {}",
    "otc_failure_0056": "Kaufbetrag darf nicht größer sein als das maximale Einzelhandelslimit {}",
    "otc_failure_0057": "Kein solcher Status",
    "otc_failure_0058": "Änderungsanzahl der Auftragsaufzeichnung ist nicht korrekt",
    "otc_failure_0059": "Einfrieren fehlgeschlagen, Einzelhandel fehlgeschlagen",
    "otc_failure_0060": "Bitte binden Sie ein Zahlungskonto des angegebenen Typs in den Zahlungseinstellungen!",
    "otc_failure_0061": "Der Verkäufer muss mindestens {} Transaktionen abgeschlossen haben",
    "otc_failure_0062": "Verkaufsmenge darf nicht kleiner oder gleich 0 sein",
    "otc_failure_0063": "Verkaufsmenge darf nicht größer sein als die aktuelle verbleibende Menge dieser Bestellung",
    "otc_failure_0064": "Der aktuelle Auftragstyp ist kein Kaufauftrag",
    "otc_failure_0065": "Die Gesamtsumme des Verkaufs darf nicht kleiner sein als das minimale Handelslimit der Währung {}",
    "otc_failure_0066": "Verkaufsbetrag darf nicht größer sein als das maximale Handelslimit der Währung {}",
    "otc_failure_0067": "Verkaufsbetrag darf nicht kleiner sein als das minimale Einzelhandelslimit {}",
    "otc_failure_0068": "Verkaufsbetrag darf nicht größer sein als das maximale Einzelhandelslimit {}",
    "otc_failure_0069": "Zahlungskonto-ID ist ungültig",
    "otc_failure_0070": "Zahlungsbestätigung fehlgeschlagen",
    "otc_failure_0073": "Änderung des Bestellstatus fehlgeschlagen",
    "otc_failure_0074": "Währungsübertragung fehlgeschlagen, Zahlungsbestätigung fehlgeschlagen",
    "otc_failure_0075": "Zahlungsmethode darf nicht leer sein",
    "otc_failure_0076": "SMS-Versand fehlgeschlagen {}",
    "otc_failure_0077": "Diese Handelswährung existiert nicht",
    "otc_failure_0078": "Diese Fiat-Währung existiert nicht",
    "otc_failure_0079": "Änderung des Bestellstatus fehlgeschlagen",
    "otc_failure_0080": "Diese Handelswährung ist deaktiviert",
    "otc_failure_0081": "Diese Fiat-Währung ist deaktiviert",
    "otc_failure_0082": "Dieser Auftrag existiert nicht",
    "otc_failure_0083": "OTC hat derzeit keine Handelsfiat-Währung eingestellt",
    "otc_failure_0084": "OTC hat derzeit keine Handelswährung eingestellt",
    "otc_failure_0085": "Verbleibende Menge der Bestellung ist unzureichend",
    "otc_failure_0090": "Dieses Konto wurde vom OTC-Handel ausgeschlossen, bitte kontaktieren Sie den Kundenservice",
    "otc_failure_0091": "Reguläre Benutzer können keine OTC-Aufträge aufgeben, bitte beantragen Sie zuerst den Verkäuferstatus",
    "otc_failure_0092": "Der aktuelle Zahlungszeitraum für die Bestellung ist nicht abgelaufen, der Käufer kann keine Beschwerde einreichen",
    "otc_failure_0093": "Diese Bestellung wurde bereits beanstandet, bitte kontaktieren Sie den Kundenservice",
    "otc_failure_0094": "Aufgrund der relevanten Vorschriften Ihres Registrierungsstandorts können Sie diese Aktion nicht durchführen",
    "otc_failure_0095": "Diese Bestellung ist vorübergehend nicht handelbar",
    "otc_failure_0096": "Das Vermögen des Verkäuferkontos ist abnormal, daher kann diese Aktion vorübergehend nicht durchgeführt werden",
    "otc_failure_0097": "Die Anzahl der OTC-Stornierungen an diesem Tag hat das Limit überschritten, der Handel ist verboten",
    "otc_failure_0098": "Sie haben derzeit unerledigte Bestellungen, bitte schließen Sie die Bestellung ab, bevor Sie fortfahren.",
    "otc_margin_certification": "Margin-Zertifizierung",
    "otc_mine_account_paid": "Ich habe gezahlt",
    "otc_order_prompt_003": "Wenn der Käufer innerhalb der vorgeschriebenen Zeit nicht zahlt und die Schaltfläche 'Zahlung bestätigen' klickt, wird die Bestellung nach Ablauf des Countdowns automatisch storniert",
    "otc_picture_no_null": "Bitte laden Sie mindestens ein Bild hoch",
    "otc_prompt_001": "1. Die Zahlungsfrist für Bestellungen beträgt 30 Minuten, bitte zahlen Sie rechtzeitig und klicken Sie auf die Schaltfläche (Ich habe gezahlt).\n2. Die Währung des Verkäufers wird vom System gesperrt und verwaltet, bitte bestellen Sie ohne Bedenken.",
    "otc_rate": "Gebühr",
    "otc_report_order": "Abgeschlossene Bestellung",
    "otc_success_0001": "Stornierung erfolgreich",
    "otc_success_0002": "Verkauf erfolgreich",
    "otc_success_0003": "Kauf erfolgreich",
    "otc_success_0004": "Zahlungsbestätigung erfolgreich",
    "otc_success_0005": "Zahlungsbestätigung erfolgreich, Bestellung abgeschlossen",
    "otc_success_0006": "Beschwerde erfolgreich",
    "otc_waiting_for_confirmation": "Warten auf Bestätigung des Verkäufers",
    "other_settings": "Weitere Einstellungen",
    "overtime_pay": "Zahlung nicht innerhalb der Frist, Bestellung storniert",
    "param-error": "Parameterfehler",
    "partner": "Partner",
    "partner_commission": "Partner können Provisionen von Untergeordneten erhalten",
    "partner_commission_fee": "Provisionsgebühr",
    "partner_current_level": "Aktuelles Niveau",
    "partner_get_all_subordinate": "Kann alle Untergeordneten erhalten",
    "partner_management": "Partnerverwaltung",
    "partner_need_pay": "Zahlung erforderlich",
    "partner_need_recommend": "Muss direkt empfohlen werden",
    "partner_recommended": "Direkt empfohlen",
    "partner_start_and_end_time": "Start- und Endzeit",
    "partner_team": "Team",
    "partner_team_member": "Anzahl der erforderlichen Teammitglieder erreichen",
    "partner_upgrade_immediately": "Jetzt upgraden",
    "pass_muster": "Wenn nicht konform, klicken Sie hier",
    "passed": "Genehmigt",
    "passport": "Reisepass",
    "password_disagree": "Die beiden Passwörter stimmen nicht überein",
    "password_is_different": "Passworteingabe stimmt nicht überein",
    "password_text": "Passwort",
    "password_wrong": "Setzen Sie ein Anmeldepasswort von 8-20 Zeichen",
    "past_activities": "Frühere Aktivitäten",
    "pause_recharge": "Diese Währung kann nicht aufgeladen werden",
    "pause_withdraw": "Diese Währung kann nicht abgehoben werden",
    "pay_number": "Zahlungsbetrag",
    "pay_wey": "Zahlungsmethode",
    "payee": "Zahlungsempfänger",
    "payfor_type": "Zahlungsart",
    "payment": "Zahlung",
    "payment_amount": "Zahlungsbetrag",
    "payment_currency": "Zahlungswährung",
    "payment_has_been": "(Ich habe gezahlt)",
    "payment_term": "Zahlungsart",
    "paypal_account": "PayPal-Konto",
    "pending_review": "Warten auf Überprüfung",
    "people": "Personen",
    "period": "Zeitraum",
    "personal_detail": "Persönliche Daten",
    "personal_purchase_tips": "Eigenen Auftrag kann man nicht kaufen",
    "phone": "Telefon",
    "phone_code": "Telefonbestätigungscode",
    "phone_register": "Telefonregistrierung",
    "phone_verification": "SMS-Bestätigung",
    "phone_verify": "Telefonbestätigung",
    "photo_album": "Fotogalerie",
    "please_enter": "Bitte eingeben",
    "please_enter_abbreviation_or_letter": "Bitte Suchinhalt eingeben",
    "please_input_content": "Bitte Inhalt eingeben",
    "please_pay": "Bitte zahlen",
    "please_verification": "Bitte bestätigen",
    "pop_Date": "Datum",
    "pop_reset": "Zurücksetzen",
    "position at market price?": "Position zum Marktpreis vollständig schließen?",
    "post_only": "Nur Maker (Post only)",
    "price": "Preis",
    "price_cannot_be_less": "Preis darf nicht kleiner sein als {}",
    "price_cannot_be_more": "Preis darf nicht größer sein als {}",
    "price_empty": "Preis darf nicht 0 sein",
    "principal": "Hauptbetrag",
    "promote_links": "Werbelink",
    "promotion_statistical": "Werbestatistik",
    "prtner_invalid": "Ungültig",
    "publish_a_trade_order": "Handelsauftrag veröffentlichen",
    "purchase": "Kauf",
    "push": "PUSH-Vermögen",
    "push_maximum_volume": "PUSH-Menge übersteigt verfügbaren Betrag {}",
    "push_minimum_trading_volume": "PUSH-Menge unterliegt dem minimalen Handelsvolumen {}",
    "quantity": "Menge",
    "quantity_deal": "Menge/Abschluss",
    "quota": "Kontingent",
    "range": "Bereich",
    "real_income": "Echtes Einkommen",
    "real_name_authentication": "Echtheitsprüfung",
    "reason": "Grund",
    "rebate_amount": "Rückvergütung",
    "rebate_record": "Rückvergütungsprotokoll",
    "rebate_reward": "Rückvergütungsbelohnung",
    "receiving_set": "Zahlungseinstellungen",
    "receiving_set_title": "Bitte richten Sie zuerst Ihre Zahlungsmethode ein",
    "recently_deal1": "Kürzlich abgeschlossen",
    "recharge_notice": "Hinweise zur Einzahlung\n1. Bitte senden Sie die benötigte Menge von {} über den {}-Client oder eine Online-Wallet an diese Adresse. Nach Abschluss der Überweisung wird das System die Einzahlung automatisch auf Ihr Konto auf dieser Plattform gutschreiben, nachdem die Transaktion {} Bestätigungen erhalten hat. Bitte haben Sie etwas Geduld.\n2. An dieselbe Adresse können mehrere Einzahlungen vorgenommen werden, dies hat keinen Einfluss auf das Konto.\n3. Der Mindestbetrag für Einzahlungen beträgt {}.",
    "recharge_source": "Einzahlungsquelle",
    "recharge_withdraw_failure_0001": "Netzwerkanomalie",
    "recharge_withdraw_failure_0002": "Transaktionspasswort darf nicht leer sein",
    "recharge_withdraw_failure_0003": "Bestätigungscode darf nicht leer sein",
    "recharge_withdraw_failure_0004": "Händler-ID darf nicht leer sein",
    "recharge_withdraw_failure_0005": "Benutzer-ID darf nicht leer sein",
    "recharge_withdraw_failure_0006": "Benutzer existiert nicht",
    "recharge_withdraw_failure_0007": "Währungs-ID darf nicht leer sein",
    "recharge_withdraw_failure_0009": "Währung existiert nicht",
    "recharge_withdraw_failure_0010": "Google Bestätigungscode darf nicht leer sein",
    "recharge_withdraw_failure_0011": "Transaktionspasswort falsch",
    "recharge_withdraw_failure_0012": "Einzahlungsart darf nicht leer sein",
    "recharge_withdraw_failure_0013": "Einzahlungsbetrag darf nicht leer sein",
    "recharge_withdraw_failure_0014": "Einzahlungsnotiz darf nicht leer sein",
    "recharge_withdraw_failure_0015": "Einzahlungs-ID darf nicht leer sein",
    "recharge_withdraw_failure_0016": "Einzahlungsantrag existiert nicht",
    "recharge_withdraw_failure_0017": "Nur wartende Einzahlungen können gelöscht werden",
    "recharge_withdraw_failure_0018": "Nur wartende Einzahlungen können überprüft werden",
    "recharge_withdraw_failure_0019": "Nur genehmigte Einzahlungen können freigegeben werden",
    "recharge_withdraw_failure_0020": "Versionsnummer darf nicht leer sein",
    "recharge_withdraw_failure_0021": "Parameterfehler",
    "recharge_withdraw_failure_0022": "Aktuelle Daten wurden aktualisiert",
    "recharge_withdraw_failure_0023": "ID darf nicht leer sein",
    "recharge_withdraw_failure_0024": "Nur im gesperrten Status kann überprüft werden",
    "recharge_withdraw_failure_0025": "Nur im wartenden Abhebungsstatus kann gesperrt werden",
    "recharge_withdraw_failure_0026": "Nur im wartenden Abhebungs- oder gesperrten Status kann storniert werden",
    "recharge_withdraw_failure_0027": "Eingefrorene Menge darf nicht leer sein",
    "recharge_withdraw_failure_0028": "Einfrierungsgrund darf nicht leer sein",
    "recharge_withdraw_failure_0029": "Entfrostungsnotiz darf nicht leer sein",
    "recharge_withdraw_failure_0030": "Abhebungsbetrag darf nicht leer sein",
    "recharge_withdraw_failure_0031": "Abhebungsadresse darf nicht leer sein",
    "recharge_withdraw_failure_0032": "Transaktionsgebühr darf nicht leer sein",
    "recharge_withdraw_failure_0033": "Transaktionsgebühr anormal",
    "recharge_withdraw_failure_0034": "Daten existieren nicht",
    "recharge_withdraw_failure_0035": "Dieses Vermögen kann nicht entfroren werden",
    "recharge_withdraw_failure_0036": "Abhebungsadresse ist ungültig",
    "recharge_withdraw_failure_0041": "Dieses Konto wurde für Abhebungen gesperrt, bitte wenden Sie sich an den Kundenservice",
    "recharge_withdraw_failure_0042": "Die Anzahl der Abhebungen hat das Tageslimit erreicht",
    "recharge_withdraw_failure_0043": "Gesamtabhebungsbetrag überschreitet das Tageslimit",
    "recharge_withdraw_failure_0044": "Anomalie beim Abrufen von VIP-Stufeninformationen",
    "recharge_withdraw_failure_0045": "Anomalie beim Abrufen von Händlerwährungsinformationen",
    "recharge_withdraw_failure_0046": "Abhebungen sind innerhalb von 24 Stunden nach Zurücksetzen des Passworts verboten!",
    "recharge_withdraw_failure_0048": "Anomalie beim Abrufen des maximalen Einzelabhebungsbetrags",
    "recharge_withdraw_failure_0049": "Anomalie beim Abrufen des minimalen Einzelabhebungsbetrags",
    "recharge_withdraw_failure_0050": "Eingabemenge muss kleiner als das Maximallimit sein",
    "recharge_withdraw_failure_0051": "Eingabemenge muss größer als das Minimallimit sein",
    "recharge_withdraw_msg_status_0001": "Warten auf Abhebung",
    "recharge_withdraw_msg_status_0002": "Wird gesendet",
    "recharge_withdraw_msg_status_0003": "Abhebung erfolgreich",
    "recharge_withdraw_msg_status_0004": "Storniert",
    "recharge_withdraw_msg_status_0005": "In Bestätigung",
    "recharge_withdraw_msg_status_0006": "Einzahlung erfolgreich",
    "recharge_withdraw_msg_status_0007": "Genehmigt",
    "recharge_withdraw_msg_type_0001": "Einzahlung",
    "recharge_withdraw_msg_type_0002": "Abhebung",
    "recharge_withdraw_msg_withdraw_type_0001": "Plattforminterne Übertragung",
    "recharge_withdraw_msg_withdraw_type_0002": "Externe Abhebung",
    "record": "Aufzeichnung",
    "record_the_video": "Klicken Sie hier, um ein Video aufzunehmen",
    "recording": "Wiedergabe neu aufnehmen",
    "redeem_now": "Jetzt einlösen",
    "redeemed": "Eingelöst",
    "redeeming": "Einlösen",
    "redemption_amount": "Einlösungsbetrag",
    "redemption_time": "Einlösungszeit",
    "referrer": "Empfehler",
    "register": "Registrieren",
    "registered_successfully": "Erfolgreich registriert",
    "registration_award_details": "Details zur Registrierungsbelohnung",
    "registration_incentives": "Registrierungsanreize",
    "registration_time": "Registrierungszeit",
    "regular": "Regelmäßig",
    "regular_return": "Regelmäßige monatliche Rückzahlung",
    "release": "Veröffentlichen",
    "release_operation": "Auftrag veröffentlichen, hier klicken um zu handeln!",
    "release_the_order": "Auftrag veröffentlichen",
    "release_time": "Freigabezeit",
    "remark": "Bemerkung",
    "remember_state": "Anmeldestatus speichern",
    "repeal": "Widerrufen",
    "reset": "Zurücksetzen",
    "reset_login_password": "Anmeldepasswort ändern",
    "reset_pass": "Passwort zurücksetzen",
    "residue": "Rest",
    "retrieve_the_deposit": "Einzahlung zurückholen",
    "return_amount": "Rückzahlungsbetrag",
    "return_method": "Rückzahlungsart",
    "return_the_principal": "Hauptbetrag zurückgeben",
    "revenue_details": "Einnahmedetails",
    "revocation_all": "Alle widerrufen",
    "reward_amount": "Belohnungsbetrag",
    "reward_locked": "Belohnung gesperrt",
    "reward_status": "Belohnungsstatus",
    "reward_type": "Belohnungsart",
    "rewards_record": "Belohnungsaufzeichnung",
    "right_now_register": "Neue Benutzerregistrierung",
    "roll_out": "Überweisung",
    "save": "Speichern",
    "save_coin": "Einzahlen",
    "save_coin_count": "Einzahlungsbetrag",
    "save_coin_days": "Einzahlungstage",
    "save_coin_duration": "Einzahlungsdauer",
    "save_currency": "Währung einzahlen",
    "save_money_amount": "Gesamtbetrag der Einzahlung",
    "save_successfully": "Erfolgreich gespeichert",
    "save_the_image": "Bild herunterladen",
    "scan_QR_code": "Bitte scannen Sie keine QR-Codes aus unbekannten Quellen",
    "scan_result": "Scan-Ergebnis",
    "search": "Suche",
    "search_coin_placeholder": "Suchen Sie nach interessierenden Währungen",
    "search_keyword": "Suchbegriff",
    "search_name": "Suchname",
    "search_result": "Suchergebnisse",
    "search_trade_pair_placeholder": "Suchen Sie nach interessierenden Handelspaaren",
    "security_center": "Sicherheitszentrum",
    "security_level": "Sicherheitsstufe",
    "select_date_of_borth": "Bitte wählen Sie Ihr Geburtsdatum",
    "select_delete_warn": "Bitte wählen Sie die zu löschende Warnung aus",
    "sell": "Verkaufen",
    "sell_1": "Verkauf",
    "sell_order": "Verkaufsauftrag",
    "sell_quantity": "Verkaufsmenge",
    "sell_rate": "Verkaufsgebühr",
    "seller": "Verkäufer",
    "seller_phone": "Verkäufer Telefonnummer",
    "send": "Senden",
    "senior_certification": "Erweiterte Zertifizierung",
    "senior_certification1": "Erweiterte Zertifizierung",
    "service": "Kundenservice QQ:",
    "service_charge": "Servicegebühr",
    "session_timeout": "Sitzung abgelaufen, bitte erneut anmelden",
    "set_Password": "Ändern",
    "set_gesture_pass": "Bitte setzen Sie ein Gestenpasswort",
    "set_login_password": "Anmeldepasswort festlegen",
    "setting": "Einstellungen",
    "setting_alipay": "Alipay einrichten",
    "setting_bank_card": "Bankkarte einrichten",
    "setting_message_warn_tip": "*Wenn Sie die Speicherung aktivieren, erhalten Sie Warnmeldungen. Bei Deaktivierung werden keine Warnmeldungen angezeigt.",
    "setting_paypal": "PayPal einrichten",
    "setting_paypassword": "Bitte zuerst Transaktionspasswort festlegen",
    "setting_wechat": "WeChat einrichten",
    "setting_western_union": "Western Union einrichten",
    "share_invitation_link": "Einladungslink teilen",
    "share_link": "Teilen Sie den Link mit Freunden",
    "share_to_do": "Teilen an",
    "shift_to": "Übertragen nach",
    "single_least": "Einzelbetrag unter {}",
    "single_minimum": "Einzelminimum",
    "single_more": "Einzelbetrag größer als {}",
    "skip": "Überspringen",
    "small_exchange": "Kleinbetragswechsel",
    "small_exchange_tip": "*Hinweis: Innerhalb von 24 Stunden kann nur einmal ein Asset im Wert von weniger als {}{} gewechselt werden. Zurzeit nicht handelbare Währungen können nicht gewechselt werden, vorläufige Wechselgebühr beträgt {}.",
    "small_exchange_tip-1": "*Hinweis: Die Marktpreise ändern sich ständig, bitte orientieren Sie sich am aktuellen Preis. Zurzeit nicht handelbare Währungen können nicht gewechselt werden, vorläufige Wechselgebühr beträgt {0}%.",
    "small_exchange_valuation": "Bewertung",
    "small_exchange_valuation-1": "BTC-Bewertung",
    "smallest_unit_price": "PUSH-Preis kleiner als der Mindestpreis {}",
    "so adjusted to": "ist ein ganzzahliges Vielfaches und wird automatisch auf",
    "sold_out": "Nicht verfügbar",
    "start time": "Startzeit",
    "start_date": "Startdatum",
    "start_time": "Startzeit",
    "statistics_report": "Bericht Statistik",
    "status": "Status",
    "submit": "Video einreichen",
    "submitBtn": "Einreichen",
    "succeed": "Erfolgreich",
    "successful_invitation": "Einladung erfolgreich",
    "successfully_received": "Erfolgreich erhalten",
    "successfully_set": "Erfolgreich eingestellt",
    "super_partner": "Super Partner",
    "switch_account": "Konto wechseln",
    "system_top_up": "System Einzahlung",
    "system_unlock": "System entsperren",
    "tab_assets": "Vermögen",
    "tab_home": "Startseite",
    "tab_markets": "Märkte",
    "tab_swaps": "Verträge",
    "tab_trade": "Handel",
    "taking_pictures": "Foto machen",
    "telegraph": "Telegram-Gruppe:",
    "temporary_not_online_contact_service": "Momentan offline, bitte den Kundenservice kontaktieren",
    "the_day": "An diesem Tag",
    "the_event_is_over": "Die Veranstaltung ist beendet",
    "the_latest_price": "Neuesten Preis",
    "the_password_is_correct": "Das Passwort ist korrekt",
    "theme": "Thema",
    "this_month": "Diesen Monat",
    "this_week": "Diese Woche",
    "ticket": "Ticket",
    "time_of_payment": "Zahlungszeit",
    "time_the_input": "{0}h keine Eingabe des Transaktionspassworts erforderlich",
    "timeout": "Zeitüberschreitung",
    "timeout_affirm": "Zeitüberschreitung ohne Bestätigung",
    "timeout_deal": "Zeitüberschreitung, automatische Transaktion",
    "timeout_to_cancel": "Zahlungszeitüberschreitung, automatisch storniert",
    "to_confirm": "Bestätigen",
    "total_buyer": "Gesamtanzahl der Käufer",
    "total_count": "Gesamtanzahl",
    "total_exchange": "Gesamtwechselmenge",
    "total_lock_amount": "Gesamtsperrmenge",
    "total_money": "Gesamtbetrag",
    "total_return": "Voraussichtliche Gesamtrendite",
    "trade_fee": "Handelsgebühr",
    "trade_type": "Handelsart",
    "trading_currency": "Handelswährung",
    "trading_fiat_currency": "Handelsfiatwährung",
    "trading_instructions": "Handelsanweisungen",
    "trading_on": "Handelspaar",
    "trading_pair_abbreviation": "Bitte geben Sie das Kürzel des Handelspaares ein",
    "trading_this_month": "Handel in diesem Monat",
    "trading_volume": "Handelsvolumen",
    "transaction_description": "Transaktionsbeschreibung",
    "transaction_password": "Transaktionspasswort",
    "transaction_price": "Transaktionspreis",
    "transfer_number": "Übertragungsmenge",
    "try_it_now": "Jetzt ausprobieren",
    "type": "Typ",
    "unable_to_access_album": "Das aktuelle Gerät unterstützt keinen Zugriff auf das Album",
    "unaccalimed": "Nicht abgeholt",
    "unavailable": "Nicht verfügbar",
    "under_review": "In Überprüfung",
    "univalence": "Einzelpreis",
    "unlock_details": "Entsperrdetails",
    "unlock_mode": "Entsperrmodus",
    "unlock_period": "Entsperrzeitraum",
    "unlock_plan": "Entsperrplan",
    "unlock_plan_unlock": "Entsperren",
    "unlock_record": "Entsperrprotokoll",
    "unlock_time": "Entsperrzeit",
    "unlocked": "Entsperrt",
    "unredeemed": "Nicht eingelöst",
    "unverified": "Nicht verifiziert",
    "up_to_three": "Maximal drei aktivieren",
    "upgrade": "Upgrade",
    "upload_again": "Erneut hochladen",
    "upload_front": "Vorderseite des Ausweises hochladen",
    "upload_identity_card": "Bitte laden Sie Ihren Ausweis hoch",
    "upload_image_success": "Bild erfolgreich hochgeladen",
    "upload_information_as_required": "Bitte die Daten strikt gemäß den obigen Anforderungen hochladen, andernfalls kann die Verifizierung fehlschlagen",
    "upload_passport": "Bitte laden Sie Ihren Reisepass hoch",
    "upload_passport_info_picture": "Foto der Informationsseite des Reisepasses hochladen",
    "upload_reverse": "Rückseite des Ausweises hochladen",
    "ups_and_downs": "Tagesänderung",
    "ups_downs_habit": "Änderungsgewohnheit",
    "usable": "Verwendbar",
    "used": "Verwendet",
    "user-bind-failure": "Bindung des Einladenden fehlgeschlagen",
    "user-bind-success": "Bindung des Einladenden erfolgreich",
    "user-collection-error": "Fehler",
    "user-country-code-not-empty": "Ländercode darf nicht leer sein",
    "user-disable-trade": "Das aktuelle Konto ist für den Handel gesperrt, bitte wenden Sie sich an den Kundenservice!",
    "user-down-notbind": "Dieser Benutzer kann nicht mit untergeordneten Benutzern verbunden werden",
    "user-email-fail-often": "E-Mail wird zu häufig gesendet, bitte nach 120 Sekunden erneut senden",
    "user-email-not-empty": "E-Mail-Adresse darf nicht leer sein",
    "user-email-verification-code-out": "E-Mail-Bestätigungscode abgelaufen, bitte erneut senden",
    "user-fail-advanced-auth": "Erweiterte Verifizierung fehlgeschlagen",
    "user-fail-advanced-authed": "Benutzer wurde bereits erweitert verifiziert",
    "user-fail-assetnotenough-error": "Nicht genügend Vermögen",
    "user-fail-bank-count-error": "Maximal 3 Zahlungsmethoden aktivieren",
    "user-fail-bank-inotc": "Zahlungsmethode hat offene Aufträge in OTC und kann nicht deaktiviert werden",
    "user-fail-cardno-repeat-error": "Ausweisnummer wurde bereits verwendet",
    "user-fail-email-code": "E-Mail-Bestätigungscode falsch",
    "user-fail-google-binded": "Google ist bereits gebunden",
    "user-fail-google-code": "Google-Bestätigungscode falsch",
    "user-fail-inviter-notexist": "Einlader existiert nicht",
    "user-fail-login-disable": "Das aktuelle Konto ist für die Anmeldung gesperrt, bitte wenden Sie sich an den Kundenservice!",
    "user-fail-login-lock": "Konto wurde gesperrt",
    "user-fail-login-password": "Benutzername oder Passwort falsch, verbleibende Versuche {}",
    "user-fail-mail-binded": "E-Mail ist bereits gebunden",
    "user-fail-not-realname": "Bitte zuerst die Identitätsverifizierung durchführen",
    "user-fail-oldpassword-error": "Altes Passwort falsch",
    "user-fail-partner-not-found": "Händler existiert nicht",
    "user-fail-partnernotfound": "Händler existiert nicht",
    "user-fail-password": "Benutzername oder Passwort falsch",
    "user-fail-password-blank": "Passwort darf nicht leer sein",
    "user-fail-password-blank1": "Bestätigungs-Passwort darf nicht leer sein",
    "user-fail-password-repeat": "Ihr neues Passwort ist identisch mit dem alten Passwort, bitte erneut einstellen!",
    "user-fail-password-thin": "Passwortstärke zu schwach",
    "user-fail-passwword-eq-paypassword": "Transaktionspasswort ist identisch mit dem Anmeldepasswort, bitte erneut einstellen",
    "user-fail-paypassword-error": "Transaktionspasswort falsch",
    "user-fail-paypassword-notset": "Bitte zuerst das Transaktionspasswort festlegen",
    "user-fail-paypassword-set": "Transaktionspasswort wurde festgelegt",
    "user-fail-phone-binded": "Telefonnummer ist bereits gebunden",
    "user-fail-realname-auth": "Benutzer-Identitätsverifizierung fehlgeschlagen",
    "user-fail-realname-authed": "Benutzer wurde bereits identifiziert",
    "user-fail-realname-notset": "Bitte zuerst die Identitätsverifizierung durchführen",
    "user-fail-reg": "Registrierung fehlgeschlagen, Serverfehler",
    "user-fail-reg-mail": "E-Mail-Format ist ungültig",
    "user-fail-reg-mail-exist": "E-Mail wurde bereits registriert",
    "user-fail-reg-phone-exist": "Telefonnummer wurde bereits registriert",
    "user-fail-securitycount": "Mindestens eine Verifizierungsmethode muss erhalten bleiben",
    "user-fail-sms-code": "SMS-Bestätigungscode falsch",
    "user-fail-sms-code-new": "SMS-Bestätigungscode für neue Telefonnummer falsch",
    "user-fail-sms-code-old": "SMS-Bestätigungscode für alte Telefonnummer falsch",
    "user-fail-usernameisblank": "Benutzername darf nicht leer sein",
    "user-id-or-nationCode—is-null": "Benutzer-ID oder Ländercode ist leer",
    "user-login-success": "Anmeldung erfolgreich",
    "user-mail-fail": "E-Mail-Versand fehlgeschlagen",
    "user-mail-success": "E-Mail erfolgreich gesendet",
    "user-not-exist": "Dieser Benutzer existiert nicht",
    "user-notopen-success": "Momentan nicht aktiviert",
    "user-phone-not-empty": "Telefonnummer darf nicht leer sein",
    "user-sms-fail": "Versand fehlgeschlagen, Serverfehler",
    "user-sms-fail-often": "SMS wird zu häufig gesendet",
    "user-sms-success": "SMS erfolgreich gesendet",
    "user-sms-verification-code-out": "Bestätigungscode abgelaufen, bitte erneut senden",
    "user-success-advanced-auth": "Erweiterte Verifizierung erfolgreich",
    "user-success-realname-auth": "Benutzer-Identitätsverifizierung erfolgreich",
    "user-validate-code-fail": "Bestätigungscode ist falsch",
    "user-validate-fail": "Überprüfung fehlgeschlagen",
    "user-vip-common-fail": "VIP-Kauf fehlgeschlagen",
    "user_agreement": "Bitte bestätigen Sie die Zustimmung zur Nutzungsbedingungen",
    "user_collection_cencel_success": "Abgebrochen",
    "user_collection_insert_success": "Hinzugefügt",
    "user_pay_disable_state": "Dieses Konto hat die maximale Anzahl an falschen Transaktionspasswörtern erreicht und wurde für den Handel gesperrt, bitte versuchen Sie es nach 2 Stunden erneut",
    "user_pay_is_error": "Transaktionspasswort falsch, Sie können es noch {} Mal eingeben, nach Überschreitung wird es für 2 Stunden gesperrt",
    "user_pay_password_not_setting": "Benutzer hat kein Transaktionspasswort festgelegt, bitte zuerst ein Transaktionspasswort festlegen",
    "user_protocol": "Benutzerprotokoll",
    "user_service_agreement": "Nutzungsbedingungen",
    "user_vip_has_expire": "Dieses Konto hat die VIP-Laufzeit überschritten und kann nicht aufgewertet werden!",
    "user_vip_update_failure": "Dieses Konto unterstützt derzeit keine Verlängerung oder Aufwertung, bitte wenden Sie sich an den Kundenservice!",
    "valid_identity_card": "Bitte geben Sie eine gültige Ausweisnummer ein",
    "valuation": "Währungsbewertung",
    "verification_code_error": "Bestätigungscode falsch",
    "verification_failed_001": "Verifizierung fehlgeschlagen",
    "verification_finish": "Verifizierung abgeschlossen",
    "version_updating": "Versionsaktualisierung",
    "versions": "Versionen",
    "vertical_version": "Hochformat",
    "view_activity": "Aktivität anzeigen",
    "view_lock": "Sperren anzeigen",
    "volamount": "Käufer muss mindestens {} Transaktionen durchgeführt haben",
    "volamount_sell": "Verkäufer muss mindestens {} Transaktionen durchgeführt haben",
    "vote": "Abstimmen",
    "vote_add_coin": "Stimmen Sie für die Währung",
    "vote_return": "Rückgabe",
    "voting_coin": "Abstimmungswährung",
    "voting_number": "Abstimmungsanzahl",
    "voting_time": "Abstimmungszeit",
    "wait_buyer_payment": "Auf Käuferzahlung warten",
    "wait_for_payment": "Warten auf Zahlung",
    "waiting_for_receiving": "Warten auf Bestätigung des Verkäufers",
    "warm_prompt": "Freundliche Erinnerung",
    "warning_setTitle": "Warnungseinstellungen",
    "wechat_account": "WeChat-Konto",
    "wechat_upload": "*So laden Sie den QR-Code in WeChat hoch: Öffnen Sie die WeChat-Startseite > Mein > Geldbörse > Zahlung empfangen > Bild speichern, und laden Sie dann den QR-Code aus Ihrem Handyalbum hoch.",
    "week": "Woche",
    "welcome": "Willkommen bei BitMatrix",
    "welcome_to": "Willkommen bei",
    "winning_record": "Gewinnaufzeichnungen",
    "withdrawal_amount": "Tägliches Abhebungslimit",
    "withdrawal_charge": "Abhebungsgebühr",
    "xilian": "Western Union",
    "yesterday_income": "Einnahmen von gestern",
    "you_have_selected": "Sie haben ausgewählt",
    "Your closing number": "Ihre Schließungsanzahl ist kleiner als das Mindestbestandsniveau!",
    "Your have pending Cross orders": "Es gibt aktuelle Cross-Orders, bitte stornieren Sie alle Cross-Orders, bevor Sie die Hebelverhältnisse ändern.",
    "Your have pending isolated orders": "Es gibt aktuelle isolierte Orders, bitte stornieren Sie alle isolierten Orders, bevor Sie die Hebelverhältnisse ändern.",
    "Home page apps": "Startseiten-Apps",
    "Home_Edit": "Bearbeiten",
    "recently_deal01": "Transaktionen",
    "depth01": "Tiefe",
    "depth02": "Tiefendiagramm",
    "discount_currency_introduction01": "Währungsübersicht",
    "Info001": "Einführung",
    "Avg cost": "Durchschnittskosten",
    "Long position": "Long-Position",
    "Short position": "Short-Position",
    "Hold on": "Warten Sie",
    "Done": "Fertig",
    "new_version": "Neue Version gefunden",
    "Announcement": "Ankündigung",
    "View details": "Details anzeigen",
    "Option Assets": "Optionsvermögen",
    "Option Account": "Optionskonto",
    "Option": "Option",
    "1M": "1 Min",
    "5M": "5 Min",
    "15M": "15 Min",
    "30M": "30 Min",
    "1H": "1 Std",
    "Delivery Time": "Zeit bis zur Lieferung",
    "Call": "Bullish",
    "Put": "Bearish",
    "Draw": "Neutral",
    "Draw2": "Neutral",
    "Call1": "Long",
    "Put1": "Short",
    "Draw1": "Neutral",
    "Buy-2": "Kaufen",
    "Rate of return": "Rendite",
    "Change": "Änderung",
    "Buy Option": "Option kaufen",
    "Awaiting": "Warten auf Lieferung",
    "Delivery": "Meine Lieferung",
    "Checkout": "Lieferprotokoll",
    "Front": "Aktuelle Periode",
    "Back": "Nächste Periode",
    "Coming soon": "Demnächst",
    "delivery settlement": "Lieferabrechnung läuft",
    "Ongoing": "In Bearbeitung",
    "Pending purchase": "Ausstehender Kauf",
    "Billing Amount": "Abrechnungsmenge",
    "Amount": "Kaufmenge",
    "Delivery result": "Lieferergebnis",
    "Delivery time": "Lieferzeit",
    "Up": "Steigen",
    "Down": "Fallen",
    "lookUp": "Bullish",
    "lookDown": "Bearish",
    "Delivery details": "Lieferdetails",
    "Index Composition": "Indexzusammensetzung",
    "Exchange": "Börse",
    "Change2": "Änderung",
    "Weights": "Gewichte",
    "Order number": "Bestellnummer",
    "Option Session": "Optionssitzung",
    "Buy Time": "Kaufzeit",
    "Opening time": "Eröffnungszeit",
    "Bet Type": "Kaufart",
    "Delivery Price": "Lieferpreis",
    "Settlement Currency": "Abrechnungswährung",
    "K-line": "K-Linie",
    "UpSelect": "Steigerungswahl",
    "DownSelect": "Fallwahl",
    "Front has ended, please place another order": "Diese Periode ist beendet, bitte eine neue Bestellung aufgeben",
    "Successfully ordered": "Bestellung erfolgreich",
    "Insufficient balance": "Unzureichendes Guthaben",
    "Quantity purchased must be greater than the minimum limit": "Gekaufte Menge muss größer als das Minimum sein",
    "Quantity purchased must be less than the maximum limit": "Gekaufte Menge muss kleiner als das Maximum sein",
    "The current time is not in the purchase time range": "Die aktuelle Zeit liegt nicht im Kaufzeitraum",
    "expected": "Erwartete Rendite",
    "To_a_game": "Noch eine Runde",
    "Settlement Result": "Abrechnungsergebnis",
    "Delivery-1": "Lieferung",
    "Ups and downs": "Preisschwankungen",
    "duration": "Dauer",
    "Option funds daybook": "Optionsfonds Buch",
    "Open innovative options trading": "Innovative Optionen Handelsaktivierung",
    "Confirm Open": "Aktivierung bestätigen",
    "Option Agree Content": "Um Ihre Rechte zu schützen, lesen Sie bitte die \"Vereinbarung zur Nutzung des innovativen Optionshandels\" sorgfältig durch, bevor Sie die Optionen aktivieren.",
    "Option Agree Content footer": "\"Vereinbarung zur Nutzung des innovativen Optionshandels\"",
    "Option Agree Content header": "Um Ihre Rechte zu schützen, lesen Sie bitte die Bedingungen sorgfältig durch, bevor Sie die Optionen aktivieren.",
    "Service upgrade, please try again later!": "Service-Upgrade, bitte später erneut versuchen!",
    "month": "Monat",
    "Invitation": "Einladung",
    "Markets": "Märkte",
    "Swaps": "Swaps",
    "Trade": "Handel",
    "Trade-1": "Zum Handel",
    "Assets": "Vermögen",
    "Buy": "Kaufen",
    "Help": "Hilfe",
    "Service": "Kundenservice",
    "Activity": "Aktivität",
    "Communtiy": "Gemeinschaft",
    "No search results": "Keine Suchergebnisse",
    "The option account asset is 0, please transfer from the wallet account to the option account first.": "Das Vermögen des Optionskontos beträgt 0, bitte übertragen Sie zuerst von Ihrem Wallet-Konto auf das Optionskonto.",
    "Perpetual account assets are 0, please transfer from wallet account to perpetual account first.": "Das Vermögen des ewigen Kontos beträgt 0, bitte übertragen Sie zuerst von Ihrem Wallet-Konto auf das ewige Konto.",
    "Financing": "Finanzierung",
    "Load": "Laden",
    "Replay001": "Wiederholen",
    "Constellation Program": "Vorstellung - Sternzeichen-Programm",
    "Constellation Program2": "Vorstellung - Sternzeichen-Finanzierung",
    "rate rankings": "Rangliste der Gewinnquoten",
    "rate rankings2": "Empfohlene Finanzierungsstars der Woche",
    "CXT Quotes": "Zitate",
    "Digital asset trading tool service platform": "Plattform für digitale Vermögenshandelswerkzeuge",
    "Service_home": "Service",
    "Strategic cooperation": "Strategische Kooperation",
    "Financing assets": "Finanzierungsvermögen",
    "Remove 0 from the first number when registering!": "Entfernen Sie die 0 von der ersten Zahl bei der Registrierung!",
    "Are you sure you want to quit": "Sind Sie sicher, dass Sie beenden möchten?",
    "Opening Price": "Eröffnungspreis",
    "Closing Price": "Schlusskurs",
    "Formula interpretation": "Formelinterpretation",
    "Note: If the input box is left blank": "Hinweis: Wenn das Eingabefeld leer bleibt, bedeutet dies, dass keine aktuellen Daten der Börse abgerufen wurden.",
    "Option Sessions": "Optionssitzungen",
    "Result": "Ergebnis",
    "When data for an exchange is not available": "*Wenn keine Daten einer Börse verfügbar sind, beträgt der Gewichtungswert 0%. Wenn gültige Datenquellen ≤ 2, wird dies als Unentschieden definiert.",
    "Assume that the index is derived from the opening prices of five exchanges": "Angenommen, der Index stammt von den Eröffnungspreisen von fünf Börsen: A, B, C, D, E; die Schlusskurse sind: A`, B`, C`, D`, E`.",
    "The proportion of those five exchanges are": "Die Gewichtungen der fünf Börsen sind: a%, b%, c%, d%, e%",
    "Stream bureau": "Unentschieden",
    "(valid data source ≤ 2)": "(gültige Datenquelle ≤ 2)",
    "Option calculator": "Optionsrechner",
    "Round selection": "Rundenauswahl",
    "Select option": "Option auswählen",
    "Average opening price": "Durchschnittlicher Eröffnungspreis",
    "Average closing price": "Durchschnittlicher Schlusskurs",
    "Calculate now": "Jetzt berechnen",
    "Calculation Results": "Berechnungsergebnisse",
    "Calculating Time": "Berechnungszeit",
    "Management": "Verwaltung",
    "Other_home": "Sonstiges",
    "All applications": "Alle Anwendungen",
    "Boon": "Vorteil",
    "Coin_Address_draw": "Abhebungsadresse",
    "Congratulations": "Herzlichen Glückwunsch",
    "Profit": "Gewinn",
    "Gainers": "Zuwächse",
    "Drop": "Rückgang",
    "Order Price": "Kaufpreis",
    "Order Price1": "Kaufpreis",
    "Sec Swap": "Sekundenvertrag",
    "Expected Return": "Erwartete Rendite",
    "Sec Swap Assets": "Sekundenvertragsvermögen",
    "Sec Swap Bill": "Sekundenvertragsgeldfluss",
    "Sec Swap Account": "Sekundenvertragskonto",
    "Go Login": "Zum Log-in",
    "NSO Session": "NSO Zeit",
    "Wallet Value1": "Wallet-Vermögen",
    "Spot Value1": "Krypto-Vermögen",
    "PERP Value1": "Ewiges Vermögen",
    "Option Value1": "Optionsvermögen",
    "Fiat Value1": "OTC-Wallet",
    "Pledge_Amount": "Pfandmenge",
    "total_return2": "Kumulierte Rendite",
    "Violation_Loss": "Vertragsstrafe",
    "Cycle": "Zyklus",
    "Locked2": "Gesperrt",
    "Time_Left": "Verbleibende Zeit",
    "Cancel_Pledge": "Pfand stornieren",
    "countDown_day": "Tage",
    "Entrust2": "Auftragserteilung",
    "All2": "Alle",
    "Ongoing2": "Laufend",
    "Near2": "Demnächst",
    "Ended2": "Beendet",
    "Cancel the pledge?": "Möchten Sie das Pfand wirklich stornieren?",
    "Completed2": "Abgeschlossen",
    "Cancelled2": "Storniert",
    "Lock-up mining": "Lock-up Mining",
    "Rules Description": "Regelbeschreibung",
    "Today Expected": "Erwartete Rendite heute",
    "Order Escrow": "Treuhandauftrag",
    "Minimum": "Mindestbetrag",
    "Daily Return": "Tägliche Rendite",
    "Buy-3": "Kaufen",
    "Minimum Amount": "Mindestpfandbetrag",
    "Lockup Period": "Lock-up Zeitraum",
    "Confirm Pledge": "Pfand bestätigen",
    "Buy-4": "Kaufen",
    "Conversion Starter": "Start der Umwandlung",
    "Listing": "Börsennotierung",
    "Total Target": "Gesamtziel",
    "Conversion Ratio": "Umwandlungsquote",
    "Single Limited": "Einzelperson begrenzt",
    "Participate Order": "Teilnahmeauftrag",
    "Fundraised": "Bereits gesammelt",
    "Current Progress": "Aktueller Fortschritt",
    "Time Left": "Verbleibende Zeit",
    "Start Time": "Startzeit",
    "End Time": "Endzeit",
    "Conversion Amount": "Anzahl der Abonnierungen",
    "Conversion Now": "Jetzt abonnieren",
    "Conditions": "Teilnahmebedingungen",
    "Preheating": "Vorbereitung",
    "Records": "Abonnierungsaufzeichnungen",
    "Select": "Auswählen",
    "To": "Bis",
    "Active Currency": "Aktive Währung",
    "Amount-3": "Betrag",
    "Conversion Time": "Abonnierungszeit",
    "Arrival Time": "Gutschriftzeit",
    "Not Arrived": "Noch nicht angekommen",
    "Hours-1": "Std",
    "Risk warning: It is displayed": "Risikohinweis: Dies ist eine innovative Vermögensanzeige mit digitalen Vermögenseigenschaften. Bitte bewerten Sie die Preise sorgfältig und treffen Sie wohlüberlegte Investitionsentscheidungen!",
    "Week-1": "Woche",
    "S-1": "Sek",
    "minutes-2": "Min",
    "You must pass basic KYC": "Benutzer müssen die grundlegende Identitätsprüfung bestehen",
    "You must pass advanced KYC": "Benutzer müssen die erweiterte Identitätsprüfung bestehen",
    "Account order exceeds": "Kontostand überschreitet",
    "Suspend": "Handel aussetzen",
    "Successfully": "Abonnierung erfolgreich",
    "Ongoing-1": "Abonnierung läuft",
    "Failed": "Abonnierung fehlgeschlagen",
    "New_Token_Subscribe": "Neue Token-Abonnierung",
    "More_awaits_you": "Mehr Reichtum wartet auf Sie",
    "Distance Start": "Verbleibende Zeit bis zum Start",
    "day-2": "Tag",
    "Must Eligible": "Teilnahmebedingungen müssen erfüllt sein",
    "Custodial funds": "Treuhandmittel",
    "Default Ratio": "Vertragsstrafe-Prozentsatz",
    "Wallet Available": "Verfügbares Wallet",
    "Done-2": "Abgeschlossen",
    "Eligible Amount": "Genehmigte Menge",
    "Eligible Price": "Genehmigter Betrag",
    "New Token Subscribe": "Neue Token-Abonnierung",
    "Subscribe Now": "Jetzt abonnieren",
    "Review Time": "Prüfzeit",
    "Review Passed": "Prüfung bestanden",
    "Review Failed": "Prüfung nicht bestanden",
    "Not Reviewed": "Noch nicht überprüft",
    "Denial Reason": "Ablehnungsgrund",
    "If not received, please contact service": "Wenn nicht angekommen, bitte den Kundenservice kontaktieren!",
    "Returned to wallet": "Zurück auf das Wallet-Konto",
    "From wallet assets": "Verfügbar aus Wallet-Vermögen",
    "Day-3": "Tag",
    "Sec Swap asset is 0, please transfer from wallet account to Sec Swap first": "Sekundenvertragsvermögen ist 0, bitte zuerst von Wallet-Konto auf Sekundenvertrag übertragen",
    "Settlement": "In Abrechnung",
    "Completed": "Abrechnung abgeschlossen",
    "Password Setting": "Passworteinstellung",
    "Set password and email": "Passwort und E-Mail einstellen",
    "Email Settings": "E-Mail-Einstellungen",
    "First-time registered users mus": "Erstregistrierte Benutzer müssen die Passworteinstellung und E-Mail-Einstellung abschließen",
    "Driver ID": "Führerschein",
    "Preview": "Anfrage",
    "cancelTime": "Stornierungszeit",
    "Total_assets_valuation": "Gesamtvermögensbewertung",
    "Invite Friends": "Freunde einladen",
    "RedGreen": "Rot steigt, Grün fällt",
    "GreenRed": "Grün steigt, Rot fällt",
    "Color Preference": "Bevorzugung der Preisbewegung",
    "Fuzzy Information": "Unklare Informationen",
    "user_bind_xilain_trade_pwd": "Bitte geben Sie das Handelskennwort ein",
    "login_please_input1": "Bitte geben Sie den SMS-Code ein",
    "user_please_input9": "Bitte geben Sie den Google-Code ein",
    "user_link_name": "Kettenname",
    "user_coin_bring_together": "Zusammenführen",
    "chongbi0": "Einzahlungshinweise:",
    "chongbi1": "1. Es ist verboten, andere Vermögenswerte als {0} einzuzahlen. Jede Einzahlung von nicht-{1}-Vermögenswerten ist nicht rückholbar.",
    "chongbi2": "2. Bitte senden Sie die benötigte Anzahl von {1} über den {0}-Client oder Online-Wallet an diese Adresse. Nach dem Versand wird das System die Einzahlung automatisch verarbeiten, nachdem {2} Bestätigungen erhalten wurden. Bitte haben Sie Geduld.",
    "chongbi3": "2. Dieselbe Adresse kann mehrfach verwendet werden, ohne die Gutschrift zu beeinträchtigen.",
    "chongbi4": "3. Mindestbetragsanforderung für Einzahlungen: {0}.",
    "currency_withdrawal_rate_rules": "{0} Abhebungsgebührenregel:",
    "currency_withdrawal_rate_rules1": "1. Um die Sicherheit der Benutzermittel zu gewährleisten, kann die Plattform Ihre Abhebungsanfrage telefonisch bestätigen. Bitte achten Sie darauf, den Anruf anzunehmen;",
    "currency_withdrawal_rate_rules2": "2. {0} Einzahlungen müssen {1} Bestätigungen erhalten, bevor eine Abhebung erlaubt ist;",
    "currency_withdrawal_rate_rules3": "2. Abhebungsbetrag ≤ Verfügbares Vermögen - Unbestätigte digitale Vermögenswerte;",
    "currency_withdrawal_rate_rules4": "3. Täglich maximal {0} Abhebungen, maximal {1} pro Tag.",
    "currency_transaction_password": "Passwort",
    "long_press_to_save_to_album": "Lang drücken, um in das Album zu speichern",
    "the_fee_is_outside_the_range": "Die Gebühr liegt außerhalb des zulässigen Bereichs",
    "start_and_end_date": "Start- und Enddatum",
    "The_withdrawal_amount_cannot_be_less": "Der Abhebungsbetrag kann nicht kleiner als {0} sein",
    "withdrawal_details": "Abhebungsdetails",
    "RegisterRemove0": "Die erste Ziffer bei der Registrierung muss 0 entfernt werden",
    "closeLockUpPopup": "Die Stornierung des Pfandes führt zu einer Vertragsstrafe von {0} Tagen, der Betrag der Vertragsstrafe beträgt '{1}{2}', der tatsächlich gutgeschriebene Betrag beträgt '{3}{4}'.",
    "LockUpDuan": "Lock-up Mining",
    "NewTokenSubscribe": "Neue Token-Abonnierung",
    "MoreWealthAwaitsYou": "Mehr Reichtum wartet auf Sie",
    "TopGainers": "Top-Gewinner",
    "Newest": "Neueste Token",
    "Hot": "Beliebte Token",
    "DatChange": "Tägliche Preisänderung",

    "SetPassword": "Passwort festlegen",
    "comm_password": "Transaktionspasswort",
    "unraise_recovering_password": "Nach Passwortwiederherstellung 1 Stunde keine Abhebung möglich",
    "PDleaseUseSafariBrowser": "Bitte Safari-Browser verwenden",
    "ADddToDesktop": "BitMatrix zum Desktop hinzufügen",
    "CDlickBelow": "Unten klicken",
    "SDelectAddToHomeScreen": "und 'Zum Startbildschirm hinzufügen' wählen",
    "ADddToDesktop2": "BitMatrix zum Desktop hinzufügen",
    "CDommonbrowserAddMethod": "Häufige Browser-Hinzufügungsmethoden",
    "user_senior_text1": "Hinweis",
    "user_senior_text2": "Foto max. 5 MB, Formate: jpg, bmp, png.",
    "user_senior_text3": "Foto ohne Wasserzeichen, klar und vollständig, keine Bearbeitung.",
    "user_senior_text4": "Händisch",
    "user_senior_text5": "ID-Kartenfoto",
    "user_senior_text6": ": Halten Sie Ihre",
    "user_senior_text7": "heutige Datumsangabe",
    "user_senior_text8": "und",
    "user_senior_text9": "UID",
    "user_senior_text10": "auf einem Zettel und machen Sie ein nicht spiegelverkehrtes Foto.",
    "user_senior_text11": ", während die andere Hand ein handgeschriebenes",
    "user_senior_text12": "Halten Sie ein weißes Blatt mit folgendem Inhalt: Inhalte, die nicht übereinstimmen, werden nicht genehmigt:",
    "user_senior_text13": "„Ich bin mir der Investitionsrisiken bewusst und übernehme freiwillig die Verantwortung“",
    "user_senior_text14": "。",
    "user_ID_card": "ID-Karte",
    "user_passport": "Reisepass",
    "Driver_ID": "Führerschein",
    "user_senior_upload3": "Laden Sie ein Foto mit gehaltenem Ausweis + UID + Datum hoch",
    "ServiceUpgrade": "Dienstupgrade",
    "ReferencePrice": "Referenzpreis",
    "referenceTips": "Nicht der endgültige Preis, nur zur Information",
    "NewCoinsLaunch": "Neue Münzen Veröffentlichung",
    "CurrentlyPurchased": "Aktuelle Abonnements",
    "TotalMarketValue": "Gesamtmarktwert",
    "IEORatio": "Abonnementsverhältnis",
    "CurrencyIntroduction": "Einführung in die Währung",
    "ConvertNow": "Jetzt abonnieren",
    "RemainingAmount": "Verbleibender Betrag",
    "referenceTips": "Maßgeblich ist der tatsächliche Kaufpreis",
    "ServiceUpgrade": "Dienstupgrade",
    "LastDays7": "Letzte 7 Tage",
    "LastDays30": "Letzte 30 Tage",
    "LastDays90": "Letzte 90 Tage",
    "LockUpTips": "Hinweis: Die verbleibenden nicht genehmigten {0}{1} Vermögenswerte wurden auf Ihr Wallet-Konto zurücküberwiesen.",
    "PromotionalMining": "Promotions-Mining",

    "hide_coin": "Münzen unter {0} BTC ausblenden",
    "GoldAssets": "Goldvermögen",
    "ModifyEmail": "E-Mail ändern",
    "NewEmail": "Neue E-Mail",
    "NewEmailAddress": "Neue E-Mail-Adresse eingeben",
    "VerifyNewEmail": "Neue E-Mail verifizieren",
    "EnterNewEmailCode": "Neuen E-Mail-Bestätigungscode eingeben",
    "VerifyOldEmail": "Alte E-Mail verifizieren",
    "EnterOldEmailCode": "Alten E-Mail-Bestätigungscode eingeben",
    "VerifyLoginPassword": "Anmeldepasswort verifizieren",
    "EnterLoginPassword": "Anmeldepasswort eingeben",
    "loan": "Kreditzentrum",
    "loanText": "Bietet sichere und zuverlässige Kreditdienste für verschiedene Zwecke und höhere Liquidität, ohne bestehende Krypto-Assets verkaufen zu müssen.",
    "loanQuery": "Kreditberatung",
    "Stop_surplus_tips": "Wenn der Marktpreis {} erreicht, wird das Take-Profit-Order ausgelöst, geschätzter Gewinn nach Abschluss {}",
    "Stop_loss_tips": "Wenn der Marktpreis {} erreicht, wird das Stop-Loss-Order ausgelöst, geschätzter Verlust nach Abschluss {}",
    "Stop_surplus_tips1": "Wenn der Marktpreis {} erreicht, wird das Take-Profit-Order ausgelöst, geschätzter Verlust nach Abschluss {}",
    "Stop_loss_tips1": "Wenn der Marktpreis {} erreicht, wird das Stop-Loss-Order ausgelöst, geschätzter Gewinn nach Abschluss {}",
    "Countdown": "Countdown",
    "contract_After_adjustment": "Nach der Anpassung werden {} USDT als zusätzliche Margin von Ihrem Konto abgebucht",
    "contract_subject_to": " (basierend auf dem tatsächlich überwiesenen Betrag)",
    "contract_the_used_margin": "Nach der Anpassung wird die verwendete Margin um {} USDT reduziert, der reduzierte Teil bleibt im Position.",
    "contract_transferred_to": "Kann auf das Kontoguthaben übertragen werden",
    "contract_After_adjustment_q": "Nach der Anpassung wird die Margin für die Position um {} USDT erhöht",
    "contract_the_used_margin_q": "Nach der Anpassung wird die Margin für die Position um {} USDT reduziert",
    "Please1": "Bitte geben Sie den Triggerpreis für Take-Profit ein",
    "Please2": "Bitte geben Sie den Take-Profit-Orderpreis ein",
    "Please3": "Bitte geben Sie den Triggerpreis für Stop-Loss ein",
    "Please4": "Bitte geben Sie den Stop-Loss-Orderpreis ein",
    "Stop profit6": "Der Triggerpreis für Take-Profit muss höher als der letzte Transaktionspreis sein",
    "stop loss7": "Der Triggerpreis für Stop-Loss muss niedriger als der letzte Transaktionspreis sein",
    "Please8": "Bitte geben Sie die Menge ein",
    "Please9": "Es gibt keine ausgleichbare Menge",
    "CountdownTime": "Countdown",
    "h1": "Partnerantrag",
    "h2": "Rangliste der Rückvergütungen",
    "h3": "Als Partner erhalten Sie",
    "h4": "Aufstiegsbedingungen",
    "h5": "Für den Kauf eines Junior-Partners sind folgende Zahlungen erforderlich",
    "h6": "Aktueller Kontostand",
    "h7": "Genesis-Partner",
    "h8": "Rückvergütung",
    "h9": "Jetzt aufsteigen",
    "h10": "Bereits das höchste Niveau",
    "h11": "Rückvergütungsprämie",
    "h12": "Rückvergütungsrang",
    "h13": "Teammenschenanzahl",
    "h14": "Rückvergütungsaufzeichnungen",
    "h15": "Eingeladene Person",
    "h16": "Abgerechnet",
    "h17": "Nicht abgerechnet",
    "h18": "Untergebene genießen",
    "h19": "Zu zahlender Betrag",
    "h20": "Aufstieg",
    "h21": "Details zur Rückvergütung",
    "h22": "Rang",
    "h23": "Benutzer",
    "h24": "Rückvergütungsbelohnung",
    "h25": "Aktuelles Niveau kann erhalten",
    "h26": "Die gesamte Provision wird in USDT umgerechnet und summiert.",
    "h27": "Es sind {} direkte Empfehlungen von gewöhnlichen Benutzern erforderlich",
    "h28": "Bereits {} gültige gewöhnliche Benutzer direkt empfohlen",
    "h29": "Die Teamgröße muss {} Personen erreichen",
    "h30": "Teamgröße {} Personen",
    "Stop profit10": "Der Triggerpreis für Take-Profit muss kleiner als der letzte Transaktionspreis sein",
    "stop loss10": "Der Triggerpreis für Stop-Loss muss größer als der letzte Transaktionspreis sein",
    "Earn": "Vermögensverwaltung",
    "Simple": "Geld verdienen und ausgeben, beides ist möglich",
    "Low1": "Niedriges Risiko, stabile Erträge",
    "Low2": "Geringes Risiko, stabile Erträge, jederzeit einlösbar",
    "CurrentAccount": "Guthaben",
    "Stable": "Stabile Wertsteigerung, Vermögenswachstumsinstrument",
    "EarnLocked": "Festgeldanlage",
    "Searchpopular": "Einfache Vermögensverwaltung, jederzeit Erträge genießen",
    "Annualized": "Jährliche Rendite {}%, sichere Anlage, überdurchschnittliche Rückgaben",
    "CoinsLC": "Münzen",
    "PurchaseLC": "Kaufbetrag",
    "PurchaseLC2": "({} Mindestkauf)",
    "Maximum": "Maximal",
    "EstimatedInterest": "Geschätzte Zinsen",
    "RuleLC": "Regeln",
    "SubscriptionTime": "Zeitraum der Zeichnung",
    "Interestaccrualtime": "Zinsansammlung",
    "RevenueTime": "Ertragszeit",
    "RedemptionCycle": "Rückzahlungszyklus",
    "ReturnNow": "Jetzt zurückkehren",
    "BuyLC": "Kaufen",
    "PurchaseSuccessful": "Kauf erfolgreich",
    "DepositLC": "Einzahlung",
    "Yesterday": "Einnahmen von gestern",
    "TotalLC": "Gesamtmenge der Einzahlungen",
    "TotalReturn": "Kumulative Erträge",
    "DepositValuation": "Wert der Einlagen",
    "DepositDetail": "Einzelheiten zur Einzahlung",
    "RevenueDetails": "Details zu den Erträgen",
    "DepositType": "Art der Einzahlung",
    "ReturnMethod": "Zinsrückzahlungsmethode",
    "DepositAmount": "Einzahlungsmenge",
    "APR": "Jahreszinssatz",
    "Expected": "Erwartete Erträge",
    "DepositCycle": "Einzahlungszeitraum",
    "CreationTime": "Erstellungszeit",
    "EarnProfit": "Aktuelle Zinsen",
    "DepositCost": "Einzahlungsbetrag",
    "TotalRelease": "Gesamtfreigabe",
    "ReleaseTime": "Freigabezeit",
    "Flexible": "Flexible Einlage",
    "Fixed": "Festgeld",
    "Freeze": "Einfrieren",
    "Completed3": "Abgeschlossen",
    "Cancelled": "Storniert",
    "DefaultSettlement": "Vertragsverletzungsabrechnung",
    "PurchaseAmount": "Kaufbetrag (ohne Einschränkungen)",
    "RetrieveDeposited": "Einlage zurückholen",
    "PreviewConversion": "Vorschau auf den Umtausch",
    "ToEnsureThe": "Um die Sicherheit Ihres Kontos zu gewährleisten und höhere Handelslimits zu erfüllen, benötigen wir eine erweiterte Identitätsprüfung.",
    "ToVerification": "Klicken Sie auf die Schaltfläche, um den Kundenservice zur Authentifizierung zu kontaktieren.",
    "ToContact": "Jetzt den Kundenservice zur Authentifizierung kontaktieren",
    contract_entrust_failure_0080 : 'Der Bestellbetrag darf nicht weniger als 100 USDT betragen.',
    "comm_nickname_rule1": "Darf nicht mehr als 7 chinesische Zeichen oder 15 englische Buchstaben enthalten.",
    "comm_nickname_rule2": "Darf keine Leerzeichen oder Sonderzeichen enthalten.",
    "otc_upload_picture3":"Hochgeladene Bilder dürfen nur im Format jpeg/jpg/png/bmp sein!",
    "CertificationDifficulties":'Gibt es ein Problem mit der Verifizierung?',
}