export const M = {
    "Slideright": "Παρακαλώ κρατήστε το ρυθμιστικό και σύρετέ το στα δεξιά",
    "Pleaseverification": "Παρακαλώ ολοκληρώστε την επαλήθευση ασφαλείας",
    "Pleaseorder": "Παρακαλώ κάντε κλικ διαδοχικά",
    "Verificationagain": "Η επαλήθευση απέτυχε, παρακαλώ δοκιμάστε ξανά",
    "10 orders": "10 παραγγελίες",
    "24h High": "24Ω Ύψιστο",
    "24h Low": "24Ω Χαμηλό",
    "24h Trading Vol": "24Ω Όγκος Συναλλαγών",
    "24h Vol": "24Ω Όγκος",
    "ADL mechanism": "Μηχανισμός ADL",
    "AMT": "Ποσό",
    "Activity _home": "Δραστηριότητα",
    "Actual transaction number": "Πραγματικός αριθμός συναλλαγών",
    "Actual transaction price": "Πραγματική τιμή συναλλαγής",
    "Add margin": "Προσθήκη περιθωρίου",
    "Adjust leverage": "Ρύθμιση μόχλευσης",
    "Adjust success": "Επιτυχής ρύθμιση",
    "Advanced limit order offers 3": "Η προηγμένη εντολή ορίου προσφέρει τρεις μηχανισμούς ισχύος: 'Μόνο Maker', 'Συμπλήρωση ή Ακύρωση' και 'Άμεση Συμπλήρωση'.",
    "Alipay": "Alipay",
    "All Closed": "Όλα κλειστά",
    "All Filled": "Πλήρως συμπληρωμένα",
    "AllDetails": "Δείτε λεπτομέρειες εκκαθάρισης",
    "AllType": "Όλοι οι τύποι",
    "Amount can flat": "Ποσό που μπορεί να κλείσει",
    "Appeal_reason": "Λόγος προσφυγής του άλλου μέρους",
    "Applet service": "Υπηρεσία mini πρόγραμμα",
    "Are you sure to cancel the order？": "Είστε σίγουροι ότι θέλετε να ακυρώσετε την παραγγελία;",
    "Asked_to_record": "Παρακαλώ εγγράψτε το βίντεο σύμφωνα με τις οδηγίες παρακάτω",
    "Asset conversion": "Μετατροπή στοιχείων",
    "Asset_types": "Τύποι στοιχείων",
    "At the current price": "Δημιουργία παραγγελίας στην τρέχουσα αγορά",
    "Avail.": "Διαθέσιμο",
    "Avail. margin": "Διαθέσιμο περιθώριο",
    "Available": "Διαθέσιμο",
    "Avg close price": "Μέση τιμή εκκαθάρισης",
    "Avg open price": "Μέση τιμή ανοίγματος",
    "Avg.price": "Μέση τιμή",
    "BBO": "Τιμή αντιπάλου",
    "Bankcard": "Τραπεζική κάρτα",
    "Bankruptcy price": "Τιμή πτώχευσης",
    "Batch Cancel": "Μαζική ακύρωση",
    "Branch_address": "Διεύθυνση υποκαταστήματος",
    "Buy crypto_home": "Αγορά κρυπτονομισμάτων",
    "Calculations are not based on": "Τα αποτελέσματα υπολογισμού είναι μόνο για αναφορά.",
    "Can't calculate": "Δεν μπορεί να υπολογιστεί",
    "Cancel": "Ακύρωση",
    "Cancel all": "Μαζική ακύρωση",
    "Cancel order": "Ακύρωση παραγγελίας",
    "Cancel_the_login": "Ακύρωση σύνδεσης",
    "Capital cost": "Κόστος κεφαλαίου",
    "Capital cost - Expenditure": "Κόστος κεφαλαίου - Έξοδος",
    "Capital fee": "Χρέωση κεφαλαίου",
    "Capital fee - all": "Χρέωση κεφαλαίου - Όλα",
    "Capital fee - income": "Χρέωση κεφαλαίου - Έσοδα",
    "Capital success": "Ακυρώθηκε",
    "Charge_money": "Φόρτωμα χρημάτων",
    "Chinese_Yuan": "Γιουάν",
    "Close all of": "Είστε σίγουροι ότι θέλετε να κλείσετε",
    "Close long": "Κλείσιμο μακρύ",
    "Close out time": "Χρόνος πλήρους εκκαθάρισης",
    "Close price": "Τιμή εκκαθάρισης",
    "Close short": "Κλείσιμο βραχέος",
    "Closed": "Εκκαθάριση ποσότητας",
    "Closing PnL gains": "Κέρδη εκκαθάρισης",
    "Closing PnL ratio": "Αναλογία κερδών εκκαθάρισης",
    "Closing profit": "Κέρδος εκκαθάρισης",
    "coin_failure_0002": "Τα στοιχεία νομισμάτων δεν υπάρχουν προσωρινά",
    "Common functions": "Συνηθισμένες λειτουργίες",
    "Communtiy_home": "Κοινότητα",
    "Conceal canceled orders": "Απόκρυψη ακυρωμένων",
    "Confirm_the_login": "Επιβεβαίωση σύνδεσης",
    "Confirm_to_mention_money": "Επιβεβαίωση ανάληψης χρημάτων",
    "Cont": "Διαρκεί",
    "Copy link": "Αντιγραφή συνδέσμου",
    "Cross": "Διασταυρούμενο",
    "Currency_rate_description": "Περιγραφή ποσοστού",
    "Current": "Τρέχον",
    "Delete_option": "Διαγραφή",
    "Determine_to_cancel": "Είστε σίγουροι ότι θέλετε να ακυρώσετε;",
    "Don't show me again": "Μην μου το δείξετε ξανά",
    "Draw_inconsistency": "Η εισαγωγή κωδικού δεν συμφωνεί",
    "Draw_the_unlock": "Παρακαλώ σύρετε για να ρυθμίσετε νέο κωδικό",
    "Enter email address": "Εισάγετε τη διεύθυνση email σας",
    "Enter expected PnL": "Εισάγετε αναμενόμενο κέρδος",
    "Enter expected PnL Ratio": "Εισάγετε αναμενόμενη αναλογία κέρδους",
    "Enter leverage from 1 up to 125": "Εισάγετε μόχλευση από 1 έως 125",
    "Enter phone number": "Εισάγετε τον αριθμό τηλεφώνου σας",
    "Enter the verification code": "Εισάγετε τον κωδικό επαλήθευσης",
    "Equity": "Δικαιώματα νομισμάτων",
    "Est liquidation price": "Εκτιμώμενη τιμή εκκαθάρισης",
    "Face value": "Αξία συμβολαίου",
    "Filed Value": "Εκκαθαρισμένη αξία",
    "Fill or Kill": "Πλήρης εκτέλεση ή ακύρωση",
    "Filled amt": "Συμπληρωμένο ποσό",
    "Filled time": "Με βάση το χρόνο εκκαθάρισης",
    "Flash_strong": "Δυνατή αναλαμπή",
    "Funding rate": "Ποσοστό χρηματοδότησης",
    "Funding rate - 1": "Ποσοστό χρηματοδότησης",
    "Funding rate comparison": "Σύγκριση ποσοστού χρηματοδότησης",
    "Funding rate time": "Χρόνος ποσοστού χρηματοδότησης",
    "Fuzzy_information": "Θολή πληροφορία",
    "Gesture_error": "Λάθος εισαγωγή κωδικού",
    "Google_code": "Κωδικός Google",
    "Help_home": "Βοήθεια",
    "Hide_no_asset_currency": "Απόκρυψη νομισμάτων χωρίς στοιχεία",
    "Highest PnL ratio": "Υψηλότερη αναλογία κέρδους",
    "Historical record": "Ιστορικό καταγραφών",
    "I agree to": "Συμφωνώ με",
    "I have read and agree to this agreement": "Έχω διαβάσει και συμφωνώ με αυτή τη συμφωνία",
    "If_the_buyer_is_at": "Αν ο αγοραστής είναι",
    "Immediately or Cancel": "Άμεση εκτέλεση και ακύρωση",
    "In force": "Σε ισχύ",
    "Index": "Δείκτης",
    "Index Price": "Τιμή δείκτη",
    "Index_contrct": "Συμβόλαιο δείκτη",
    "Info": "Πληροφορίες συμβολαίου",
    "Initial margin": "Αρχικό περιθώριο",
    "Instrument": "Τρέχον είδος συναλλαγών",
    "Insurance fund": "Υπολειπόμενο ταμείο κινδύνου",
    "Interest bearing record": "Καταγραφή τόκων",
    "Invalid_qr_code": "Μη έγκυρος κωδικός QR",
    "Invite code(Optional)": "Εισάγετε κωδικό πρόσκλησης (προαιρετικό)",
    "InviteCodeMuster": "Εισάγετε κωδικό πρόσκλησης (υποχρεωτικό)",
    "Invite_home": "Πρόσκληση",
    "Isolated": "Μοναδικός",
    "Lack_of_edge": "Έλλειψη άκρης",
    "Last Price": "Τιμή αγοράς",
    "Latest Filled price": "Τελευταία τιμή εκκαθάρισης",
    "Latest Filled price01": "Τελευταία τιμή",
    "Latest price": "Τελευταία τιμή",
    "Leverage": "Αναλογία μόχλευσης",
    "Leverage after increase": "Μόχλευση μετά την αύξηση:",
    "Leverage11": "Τρέχουσα πραγματική μόχλευση",
    "Leverage_BB": "Ρύθμιση μόχλευσης",
    "Limit order is an order": "Η εντολή ορίου είναι μια εντολή με καθορισμένο ποσό και αποδεκτή τιμή.",
    "Limit price": "Τιμή ορίου",
    "Limit/Market": "Όριο/Αγορά",
    "LimitAndMarket": "Όριο/Αγορά",
    "Liquidation price": "Τιμή εκκαθάρισης",
    "Liquidation price after increase": "Τιμή εκκαθάρισης μετά την αύξηση:",
    "Long": "Μακρύς",
    "Lowest PnL ratio": "Χαμηλότερη αναλογία κέρδους",
    "MAIL_COUNTRY_NOT_EXITS": "Η τρέχουσα χώρα δεν υπάρχει! Προσθέστε την και στείλτε ξανά το email",
    "MAIL_NOT_MAIL_PROVIDER": "Δεν υπάρχει πάροχος email, παρακαλώ προσθέστε και δοκιμάστε ξανά!",
    "MAIL_TEMPLATE_NOT_FOUND": "Το τρέχον πρότυπο email δεν υπάρχει! Προσθέστε το και δοκιμάστε ξανά",
    "MAIL_TO_ADDRESS_NOT_FOUND": "Η τρέχουσα διεύθυνση email δεν υπάρχει, παρακαλώ ελέγξτε και δοκιμάστε ξανά",
    "MJRefreshAutoFooterIdleText": "Κάντε κλικ ή σύρετε για περισσότερα",
    "MJRefreshAutoFooterNoMoreDataText": "Όλα τα δεδομένα έχουν φορτωθεί",
    "MJRefreshAutoFooterRefreshingText": "Φορτώνει περισσότερα δεδομένα...",
    "MJRefreshBackFooterIdleText": "Συρρίξτε για περισσότερα",
    "MJRefreshBackFooterNoMoreDataText": "Όλα τα δεδομένα έχουν φορτωθεί",
    "MJRefreshBackFooterPullingText": "Αφήστε για άμεση φόρτωση",
    "MJRefreshBackFooterRefreshingText": "Φορτώνει περισσότερα δεδομένα...",
    "MJRefreshHeaderDateTodayText": "Σήμερα",
    "MJRefreshHeaderIdleText": "Συρρίξτε για ανανέωση",
    "MJRefreshHeaderLastTimeText": "Τελευταία ενημέρωση:",
    "MJRefreshHeaderNoneLastDateText": "Χωρίς καταγραφές",
    "MJRefreshHeaderPullingText": "Αφήστε για άμεση ανανέωση",
    "MJRefreshHeaderRefreshingText": "Ανανέωση δεδομένων...",
    "MKT Close ALL": "Κλείσιμο στην αγορά",
    "Margin add": "Προσθήκη περιθωρίου",
    "Margin mode": "Λειτουργία όλων των θέσεων",
    "Margin ratio": "Αναλογία περιθωρίου",
    "Margin reduce": "Μείωση περιθωρίου",
    "Margin transfer": "Μεταφορά περιθωρίου",
    "Margin-1": "Υπόλοιπο περιθωρίου",
    "Margin-2": "Ρύθμιση περιθωρίου",
    "Margined": "Συμβόλαιο",
    "Mark price": "Τιμή σήμανσης",
    "Market": "Αγορά",
    "Market Order Dig": "Η εντολή αγοράς επιτρέπει στους χρήστες να παραγγέλνουν στην τρέχουσα καλύτερη τιμή της αγοράς.",
    "Market Overview": "Γενική εικόνα της αγοράς",
    "Market_transaction": "Συναλλαγές στην τιμή της αγοράς",
    "Max": "Μέγιστο ανακληθέν",
    "Max Open int": "Μέγιστη θέση",
    "Max buy": "Μέγιστη αγορά",
    "Max long": "Μέγιστο μακρύ",
    "Max position size at current leverage": "Μέγιστο μέγεθος θέσης με τρέχουσα μόχλευση:",
    "Max sell": "Μέγιστη πώληση",
    "Max short": "Μέγιστο βραχύ",
    "Max position size at current leverage": "Μέγιστο μέγεθος θέσης με τρέχουσα μόχλευση",
    "Mention_money": "Ανάληψη χρημάτων",
    "Mention_money_address": "Εισάγετε τη σωστή διεύθυνση",
    "Mention_money_tips_ios": "Συμβουλές\n1. Εισάγετε απευθείας τη διεύθυνση ανάληψης, χρειάζεται επιβεβαίωση κωδικού, SMS, email ή κωδικός Google.\n2. Προσθέτοντας μια διεύθυνση, θα είναι η δική σας πιστοποιημένη διεύθυνση για μελλοντικές αναλήψεις.",
    "Messages_notification": "*Επιβεβαιώστε ότι η τραπεζική σας κάρτα έχει ενεργοποιήσει την υπηρεσία ειδοποίησης SMS",
    "Mini Programs": "Mini προγράμματα",
    "Minimum_handling_charge": "Η ποσότητα ανάληψης πρέπει να είναι μεγαλύτερη από την αμοιβή",
    "Minimum_withdrawal": "Ελάχιστη ανάληψη ανά συναλλαγή",
    "MoreNot9": "Οι επιλεγμένες ενότητες δεν μπορούν να υπερβαίνουν τις 14",
    "Most increase": "Μέγιστη αύξηση",
    "Most reduce": "Μέγιστη μείωση",
    "Must be integer multiple of": "Πρέπει να είναι πολλαπλάσιο ακέραιου",
    "Not Enabled": "Μη ενεργοποιημένο",
    "Not_on": "Προς το παρόν δεν είναι διαθέσιμο",
    "Not_to_mention_money": "Για την ασφάλεια των χρημάτων σας, δεν θα μπορείτε να κάνετε αναλήψεις ή ευαίσθητες ενέργειες για 1 ώρα μετά την επαναρύθμιση του κωδικού",
    "OTC account": "Λογαριασμός fiat",
    "OTC assets": "Πορτοφόλι OTC",
    "OTC交易须知": "Οδηγίες για συναλλαγές OTC",
    "On Hold": "Σε αναμονή",
    "One-way": "Μονοδρομικό",
    "One/Two-way": "Μονοδρομικό/Δίδρομο",
    "Open Time": "Χρόνος Άνοιγματος",
    "Open amount": "Ποσό Άνοιγματος",
    "Open int.": "Ανοιχτές Θέσεις",
    "Open interest": "Ανοιχτό Ενδιαφέρον",
    "Open long": "Άνοιγμα Μακρού",
    "Open orders": "Ανοιχτές Παραγγελίες",
    "Open price": "Τιμή Άνοιγματος",
    "Open short": "Άνοιγμα Βραχέος",
    "Order Details": "Λεπτομέρειες Παραγγελίας",
    "Order Sorting": "Ταξινόμηση Παραγγελιών",
    "Order Value": "Αξία Παραγγελίας",
    "Order amt": "Συνολικό Ποσό Παραγγελίας",
    "Order history": "Ιστορικό Παραγγελιών",
    "Order price": "Τιμή Παραγγελίας",
    "Order trigger direction": "Κατεύθυνση Ενεργοποίησης",
    "Order type": "Τύπος Παραγγελίας",
    "PL": "Κέρδη",
    "PL Ratio": "Αναλογία Κερδών",
    "Pair01": "Ζεύγος Συμβολαίου",
    "Parse_failure": "Αποτυχία Αποθήκευσης",
    "Partially Filled": "Μερικώς Εκπληρωμένη",
    "Partially closed": "Μερικώς Κλειστή",
    "Password": "Κωδικός",
    "Payment_type": "Τύπος Πληρωμής",
    "Perpetual": "Διαρκές",
    "Perpetual funds daybook": "Ημερολόγιο Διαρκών Κεφαλαίων",
    "Perpetual01": "Διαρκές",
    "Pingclose": "Κλείσιμο Θέσης",
    "Please input a password": "Εισάγετε Κωδικό",
    "Position": "Θέση",
    "Position balance": "Υπόλοιπο Θέσης",
    "Position history": "Ιστορικό Θέσεων",
    "QR_code": "Κωδικός QR",
    "Quantity cannot exceed": "Η ποσότητα δεν μπορεί να ξεπερνά",
    "Quantity not less than": "Η ποσότητα δεν μπορεί να είναι λιγότερη από",
    "Recent close": "Πρόσφατο Κλείσιμο",
    "Recording_requirements": "Απαιτήσεις Ηχογράφησης",
    "Required position margin at current leverage": "Απαιτούμενο περιθώριο:",
    "Reset_transaction_password": "Επαναφορά Κωδικού Συναλλαγών",
    "Revoked": "Ανακλημένο",
    "SL": "Σταμάτημα Ζημίας",
    "SL order": "Εντολή Σταματήματος Ζημίας",
    "SL order price": "Τιμή Εντολής Σταματήματος Ζημίας",
    "SL order price can't be empty": "Η τιμή δεν μπορεί να είναι κενή",
    "SL trigger price": "Τιμή Ενεργοποίησης Ζημίας",
    "SL trigger price can't be empty": "Η τιμή ενεργοποίησης δεν μπορεί να είναι κενή",
    "SMS_30_OUT_RESTRICTED": "Περιορισμένη αποστολή κωδικών, 30 δευτερόλεπτα ανά μήνυμα.",
    "SMS_BAD_ARGUMENT_FORMAT": "Λάθος μορφή τηλεφώνου.",
    "SMS_COUNTRY_NOT_EXITS": "Αυτή η χώρα δεν υπάρχει, ελέγξτε ξανά!",
    "SMS_DAY_LIMIT_PER_MOBILE": "Περιορισμός αποστολής εντός 24 ωρών.",
    "SMS_DUP_IN_SHORT_TIME": "Επαναλαμβανόμενο μήνυμα εντός 30 δευτερολέπτων.",
    "SMS_FAILURE": "Αποστολή απέτυχε",
    "SMS_HOUR_LIMIT_PER_MOBILE": "Περιορισμός αποστολής εντός 1 ώρας.",
    "SMS_MARKET_FORBIDDEN": "Μη επιτρεπτή αποστολή SMS marketing.",
    "SMS_NOT_SMS_PROVIDER": "Μη διαθέσιμος πάροχος SMS.",
    "SMS_NOT_SMS_PROVIDER_TEMPLATE": "Μη διαθέσιμο πρότυπο SMS.",
    "SMS_PHONE_OVER_COUNT": "Αυτή η γραμμή έχει ξεπεράσει τον αριθμό αποστολής.",
    "SMS_PHONE_PROHIBIT_SEND": "Αυτός ο αριθμός απαγορεύει την αποστολή SMS.",
    "SMS_SUCCESS": "Αποστολή επιτυχής",
    "SMS_TOO_MANY_TIME_IN_5": "Περισσότερες από 3 επαναλήψεις σε 5 λεπτά.",
    "SMS_UNKNOWN_EXCEPTION": "Άγνωστη εξαίρεση συστήματος.",
    "SMS_code": "Κωδικός SMS",
    "Save picture": "Αποθήκευση Εικόνας",
    "Scanning_to_obtain": "Σάρωση για διεύθυνση",
    "See auto deleverage liquidation for details": "Δείτε αυτόματη εκκαθάριση.",
    "Set_transaction_password": "Ορισμός Κωδικού Συναλλαγών",
    "Settlement1": "Ρύθμιση Χρεώσεων",
    "Settlrment coin": "Νόμισμα Ρύθμισης",
    "Share time": "Χρόνος Κοινοποίησης",
    "Shielding success": "Επιτυχής αποκλεισμός",
    "Short": "Βραχύς",
    "Sign Up": "Εγγραφή",
    "Sign in": "Σύνδεση",
    "Simplified_Chinese": "Απλή Κινεζική",
    "StatusOrder": "Κατάσταση Παραγγελίας",
    "Stop loss": "Ορισμός Σταματήματος Ζημίας",
    "Stop profit stop loss": "Επιλέξτε τουλάχιστον ένα από τα δύο.",
    "Sustainable account": "Διαρκής Λογαριασμός",
    "Sustainable assets": "Διαρκή Κεφάλαια",
    "Switch to PnL": "Εναλλαγή σε Κέρδη",
    "Switch to PnL ratio": "Εναλλαγή σε Αναλογία Κερδών",
    "TP": "Σταμάτημα Κέρδους",
    "TP order": "Εντολή Σταματήματος Κέρδους",
    "TP order price": "Τιμή Εντολής Σταματήματος Κέρδους",
    "TP order price can't be empty": "Η τιμή δεν μπορεί να είναι κενή",
    "TP trigger price": "Τιμή Ενεργοποίησης Κέρδους",
    "TP trigger price can't be empty": "Η τιμή ενεργοποίησης δεν μπορεί να είναι κενή",
    "TP | SL": "κέρδος/ζημιά",
    "TP | SL order": "Εντολή κέρδος/ζημιά",
    "Take profit": "Ορισμός Σταματήματος Κέρδους",
    "Telegraphic_transfer_address": "Διεύθυνση Ταχυδρομικής Μεταφοράς",
    "The Calculator": "Ο Υπολογιστής",
    "The maximum flatable quantity is": "Μέγιστη ποσότητα κλεισίματος:",
    "The maximum leverage is": "Μέγιστη μόχλευση:",
    "The minimum flatable quantity is": "Ελάχιστη ποσότητα κλεισίματος:",
    "The minimum leverage is": "Ελάχιστη μόχλευση:",
    "The poster has been saved to the album": "Η αφίσα αποθηκεύτηκε.",
    "The_correct_sample": "Σωστό Παράδειγμα",
    "The_input_signal": "Εισάγετε τον κωδικό",
    "The_new_address": "Νέα Διεύθυνση",
    "They_are_the_number_of": "Αριθμός Παραγγελιών",
    "Tick size": "Μέγεθος Τικ",
    "To_update": "Ενημέρωση",
    "Toggle_front_camera": "Αλλάξτε σε μπροστινή κάμερα.",
    "Total": "Συνολική Θέση",
    "Total coin": "Συνολικός Αριθμός Νόμισμα",
    "Total cont": "Συνολικοί Συμβολαίου",
    "Total value": "Συνολική Αξία",
    "Total_asset_valuation": "Συνολική Εκτίμηση Περιουσίας",
    "Trade type": "Τύπος Συναλλαγής",
    "Trade_sector": "Επιλέξτε",
    "Transfer": "Μεταφορά",
    "Transfer_to_remind": "Σημειώστε κατά τη μεταφορά.",
    "Trigger mark price": "Τιμή Ενεργοποίησης Σήμανσης",
    "Trigger price": "Τιμή Ενεργοποίησης",
    "Two-way": "Δίδρομο",
    "UPL": "Μη Πραγματοποιημένα Κέρδη/Ζημίες",
    "US_dollar": "Δολάριο ΗΠΑ",
    "Unblocked": "Απεμπλοκή",
    "Uneasy lies the head that wears a crown": "Δύσκολα τα κεφάλια με στέμμα.",
    "Unfilled": "Μη Εκπληρωμένο",
    "Unfilled11": "Σε Αναμονή Εκπλήρωσης",
    "Upload_collection_code": "Ανεβάστε τον κωδικό συλλογής.",
    "Usdt-Margined": "USDT Συμβόλαιο",
    "Users who register": "Νέοι χρήστες πρέπει να ρυθμίσουν τον κωδικό.",
    "Verification Code": "Κωδικός Επαλήθευσης",
    "Videoing_requirement": "Απαιτήσεις Βίντεο",
    "View": "Προβολή",
    "View more": "Λεπτομέρειες Θέσεων",
    "Waiting effect": "Αναμονή Ενεργοποίησης",
    "Waiting_for_the_payment": "Αναμονή Πληρωμής",
    "Wechat": "WeChat",
    "WestUnion": "Western Union",
    "Without_her": "Χωρίς Αυτήν",
    "abandoned": "Απορριφθείς",
    "abnormal_assets": "Ανωμαλία Περιουσιακών Στοιχείων!",
    "about_us": "Σχετικά με Εμάς",
    "account": "Λογαριασμός",
    "account_empty": "Λάθος Λογαριασμός",
    "account_failure_0001": "Αποτυχία προσθήκης ID!",
    "account_failure_0002": "Μη επαρκές διαθέσιμο!",
    "account_failure_0003": "Αποτυχία ημερολογίου για παγωμένο χρήστη!",
    "account_failure_0004": "Αποτυχία παγωμένου χρήστη!",
    "account_failure_0005": "Αποτυχία ημερολογίου για μείωση διαθέσιμου!",
    "account_failure_0006": "Αποτυχία μείωσης διαθέσιμου!",
    "account_failure_0007": "Αποτυχία ημερολογίου για αύξηση διαθέσιμου!",
    "account_failure_0008": "Αποτυχία αύξησης διαθέσιμου!",
    "account_failure_0009": "Αποτυχία προσθήκης νέου νομίσματος!",
    "account_failure_0010": "Αποτυχία προσθήκης νέου νομίσματος!",
    "account_failure_00100": "Το ID έμπορου δεν μπορεί να είναι κενό!",
    "account_failure_00101": "Το ID χρήστη δεν μπορεί να είναι κενό",
    "account_failure_00102": "Το ID νομίσματος δεν μπορεί να είναι κενό",
    "account_failure_00103": "Η ποσότητα δεν μπορεί να είναι κενή",
    "account_failure_00104": "Η ποσότητα δεν μπορεί να είναι μικρότερη από την ελάχιστη συναλλαγή",
    "account_failure_00105": "Ο κωδικός συναλλαγής δεν μπορεί να είναι κενός",
    "account_failure_00106": "Λάθος κωδικός συναλλαγής",
    "account_failure_00107": "Η τιμή δεν μπορεί να είναι κενή",
    "account_failure_00108": "Η τιμή δεν μπορεί να είναι μικρότερη από την ελάχιστη τιμή",
    "account_failure_00109": "Ο χρήστης δεν υπάρχει!",
    "account_failure_0011": "Η πορτοφόλι του εμπόρου για αυτό το νόμισμα υπάρχει ήδη!",
    "account_failure_00110": "Αποτυχία εκκίνησης PUSH περιουσίας!",
    "account_failure_00111": "Το PUSHID δεν μπορεί να είναι κενό",
    "account_failure_00112": "Αποτυχία πληρωμής PUSH περιουσίας!",
    "account_failure_00113": "Αποτυχία ακύρωσης PUSH περιουσίας!",
    "account_failure_00114": "Αδυναμία απόκτησης διεύθυνσης κατάθεσης",
    "account_failure_00115": "Η διεύθυνση ανάληψης δεν μπορεί να είναι κενή!",
    "account_failure_00116": "Αποτυχία προσθήκης διεύθυνσης ανάληψης",
    "account_failure_00117": "Το ID δεν μπορεί να είναι κενό",
    "account_failure_00118": "Αποτυχία διαγραφής διεύθυνσης ανάληψης",
    "account_failure_00119": "Διαδικτυακή ανωμαλία, παρακαλώ δοκιμάστε αργότερα!",
    "account_failure_0012": "Ανεπαρκής κατάθεση, αδυναμία επεξεργασίας!",
    "account_failure_00120": "Αυτή η διεύθυνση ανάληψης υπάρχει ήδη",
    "account_failure_00121": "Δεν υπάρχουν νομίσματα για PUSH",
    "account_failure_00122": "Ο κωδικός επιβεβαίωσης κινητού είναι κενός!",
    "account_failure_00123": "Ο κωδικός επιβεβαίωσης email είναι κενός!",
    "account_failure_00124": "Ο κωδικός επιβεβαίωσης Google είναι κενός!",
    "account_failure_00125": "Λάθος κωδικός επιβεβαίωσης!",
    "account_failure_00126": "Απαγορευμένο αυτο-PUSH!",
    "account_failure_00127": "Η διεύθυνση ανάληψης δεν είναι έγκυρη!",
    "account_failure_00129": "Η αλλαγή κωδικού δεν επιτρέπεται εντός 24 ωρών",
    "account_failure_0013": "Αποτυχία καταγραφής ημερολογίου για πάγωμα εμπόρου!",
    "account_failure_00130": "Αναλογίες περιουσίας άνισες, δεν είναι δυνατή η επεξεργασία",
    "account_failure_00131": "Ανωμαλία περιουσιακών στοιχείων, απενεργοποιημένη κατάσταση συναλλαγής",
    "account_failure_00132": "Ετικέτες μη συμβατές",
    "account_failure_00133": "Ο τύπος νομίσματος δεν υπάρχει ή έχει απενεργοποιηθεί",
    "account_failure_0014": "Αποτυχία πάγωσης εμπόρου!",
    "account_failure_0015": "Αποτυχία καταγραφής ημερολογίου για αύξηση διαθέσιμου!",
    "account_failure_0016": "Αποτυχία αύξησης διαθέσιμου χρήστη!",
    "account_failure_0017": "Αποτυχία μείωσης διαθέσιμου εμπόρου!",
    "account_failure_0018": "Αποτυχία μείωσης διαθέσιμου!",
    "account_failure_0019": "Αποτυχία μεταφοράς διαθέσιμου από τον χρήστη!",
    "account_failure_0020": "Αποτυχία μεταφοράς διαθέσιμου, αποτυχία καταγραφής ημερολογίου!",
    "account_failure_0021": "Αποτυχία μεταφοράς διαθέσιμου χρήστη!",
    "account_failure_0022": "Αποτυχία μεταφοράς διαθέσιμου!",
    "account_failure_0023": "Αποτυχία καταγραφής ημερολογίου για αύξηση διαθέσιμου εμπόρου!",
    "account_failure_0024": "Αποτυχία αύξησης διαθέσιμου εμπόρου!",
    "account_failure_0025": "Αποτυχία καταγραφής ημερολογίου για μείωση διαθέσιμου χρήστη!",
    "account_failure_0026": "Αποτυχία μείωσης διαθέσιμου χρήστη!",
    "account_failure_0027": "Αποτυχία καταγραφής ημερολογίου για μείωση διαθέσιμου!",
    "account_failure_0028": "Αποτυχία μείωσης διαθέσιμου!",
    "account_failure_0029": "Αποτυχία επαναφοράς, αποτυχία καταγραφής ημερολογίου!",
    "account_failure_0030": "Αποτυχία επαναφοράς, αποτυχία επαναφοράς!",
    "account_failure_0031": "Ανεπαρκής περιουσία, αδυναμία επαναφοράς!",
    "account_failure_0032": "Αποτυχία επαναφοράς, αποτυχία καταγραφής ημερολογίου!",
    "account_failure_0033": "Αποτυχία επαναφοράς, αποτυχία αποκατάστασης περιουσίας εμπόρου",
    "account_failure_0034": "Αποτυχία καταγραφής ημερολογίου για αύξηση πάγωσης εμπόρου!",
    "account_failure_0035": "Αποτυχία αύξησης πάγωσης εμπόρου!",
    "account_failure_0036": "Αποτυχία καταγραφής ημερολογίου για μεταφορά διαθέσιμου εμπόρου!",
    "account_failure_0037": "Αποτυχία μεταφοράς διαθέσιμου εμπόρου!",
    "account_failure_0038": "Αποτυχία καταγραφής ημερολογίου για πάγωμα διαθέσιμου!",
    "account_failure_0039": "Αποτυχία πάγωσης διαθέσιμου!",
    "account_failure_0040": "Αποτυχία καταγραφής ημερολογίου για αύξηση διαθέσιμου πλατφόρμας!",
    "account_failure_0041": "Αποτυχία αύξησης διαθέσιμου πλατφόρμας!",
    "account_failure_0042": "Αυτή η συναλλαγή εκτελείται με σφάλμα!",
    "account_failure_0043": "Αυτή η συναλλαγή έχει αποτύχει!",
    "account_failure_0044": "Αυτή η συναλλαγή έχει ολοκληρωθεί!",
    "account_failure_0045": "Αυτή η συναλλαγή εκτελείται!",
    "account_failure_0046": "Επεξεργασία επιτυχής!",
    "account_failure_0047": "Αποτυχία επαναφοράς, αποτυχία αποκατάστασης περιουσίας πλατφόρμας",
    "account_failure_0048": "Αποτυχία επαναφοράς, αποτυχία καταγραφής ημερολογίου!",
    "account_failure_0049": "Αυτό το νόμισμα υπάρχει ήδη στην πλατφόρμα!",
    "account_failure_0050": "Αποτυχία προσθήκης νέου νομίσματος στην πλατφόρμα!",
    "account_failure_0051": "Αυτό το νόμισμα υπάρχει ήδη στην πλατφόρμα!",
    "account_failure_0053": "Λάθος τύπος συναλλαγής!",
    "account_failure_0054": "Αποτυχία αύξησης διαθέσιμου εμπόρου!",
    "account_failure_0055": "Αποτυχία καταγραφής ημερολογίου για αύξηση διαθέσιμου εμπόρου!",
    "account_failure_0056": "Αποτυχία μεταφοράς κερδών από τον χρήστη στην πλατφόρμα!",
    "account_failure_0057": "Αποτυχία καταγραφής ημερολογίου μεταφοράς κερδών!",
    "account_failure_0058": "Αποτυχία μεταφοράς διαθέσιμου για επενδύσεις!",
    "account_failure_0059": "Αποτυχία καταγραφής ημερολογίου μεταφοράς διαθέσιμου για επενδύσεις!",
    "account_failure_0060": "Ανεπαρκής επενδυτική ποσότητα, αδυναμία εκτέλεσης!",
    "account_failure_0061": "Αποτυχία μεταφοράς διαθέσιμου σε επενδύσεις!",
    "account_failure_0062": "Αποτυχία καταγραφής ημερολογίου για μεταφορά διαθέσιμου σε επενδύσεις!",
    "account_failure_0063": "Αποτυχία επαναφοράς, δεν υπάρχουν διαθέσιμα δεδομένα!",
    "account_failure_0064": "Αυτό το νόμισμα δεν είναι διαθέσιμο προς το παρόν",
    "account_failure_0065": "Δεν έχετε δικαίωμα πρόσβασης σε αυτό το νόμισμα, παρακαλώ επικοινωνήστε με την υποστήριξη",
    "account_failure_0071": "Ανωμαλία κατά την κατάθεση του χρήστη!",
    "account_failure_0072": "Η καταγραφή κατάθεσης του χρήστη δεν υπάρχει ή έχει τροποποιηθεί",
    "activitie_end": "Η δραστηριότητα έχει λήξει",
    "activity_invalid": "Έχει λήξει",
    "actual_amount": "Πραγματικό ποσό εκταμίευσης",
    "actual_exchange_amount": "Πραγματική ποσότητα ανταλλαγής",
    "actual_to_account": "Πραγματική κατάθεση",
    "addSuccess": "Προσθήκη επιτυχής",
    "add_channel_function": "Προσθήκη λειτουργίας επιβεβαίωσης καναλιού",
    "add_charge_and_transfer_function": "Προσθήκη λειτουργίας κατάθεσης και μεταφοράς",
    "added_alert_setting_function": "Προσθήκη λειτουργίας ρυθμίσεων ειδοποίησης",
    "added_day_mode_toggle": "Προσθήκη λειτουργίας εναλλαγής ημερήσιου τρόπου",
    "address_tag": "Ετικέτα",
    "advanced_limit_order": "Προηγμένη εντολή ορίου",
    "advertising": "Διαφήμιση",
    "advertising_management": "Διαχείριση Διαφημίσεων",
    "affirm": "Επιβεβαίωση",
    "affirm_pass": "Επιβεβαίωση Κωδικού",
    "again_digits6": "Παρακαλώ εισάγετε ξανά τον νέο κωδικό",
    "again_password": "Παρακαλώ εισάγετε ξανά τον κωδικό σύνδεσης",
    "airdrop_coin": "Νόμισμα αεροδρομίου",
    "airdrop_date": "Ημερομηνία αεροδρομίου",
    "airdrop_number": "Ποσότητα αεροδρομίου",
    "alipay_account": "Λογαριασμός Alipay",
    "alipay_upload": "*Μέθοδος φόρτωσης κωδικού QR στο Alipay: Ανοίξτε την αρχική σελίδα Alipay > Λήψη χρημάτων > Αποθήκευση εικόνας, ανεβάστε τον κωδικό που αποθηκεύτηκε στο άλμπουμ σας.",
    "all": "Όλα",
    "all_buy": "Όλες οι αγορές",
    "all_country": "Όλες οι χώρες",
    "all_order": "Όλες οι παραγγελίες",
    "all_sell": "Όλες οι πωλήσεις",
    "already_use": "Ήδη ενεργοποιημένο",
    "amount_of_payout": "Ποσό πληρωμής",
    "announcement_all": "Όλες οι ανακοινώσεις",
    "announcement_center": "Κέντρο Ανακοινώσεων",
    "announcement_official": "Επίσημη Ανακοίνωση",
    "annualized_rate": "Ετήσιος Ρυθμός",
    "appeal_cancel": "Αίτηση ακύρωσης, παραγγελία ακυρώθηκε",
    "appeal_complete": "Αίτηση ολοκλήρωσης, παραγγελία ολοκληρώθηκε",
    "appeal_dispose_time": "Χρόνος επεξεργασίας αίτησης",
    "appeal_of_time": "Χρόνος υποβολής αίτησης",
    "apply_locked": "Αίτηση για πάγωμα",
    "asset_allocatio": "Κατανομή Περιουσίας",
    "assets_asset_valuation": "Αξιολόγηση Περιουσίας",
    "assets_can_transfer": "Μπορεί να μεταφερθεί",
    "assets_cash_flow": "Ταμειακή ροή",
    "assets_coin_funds_flow": "Ροή κεφαλαίων νομισμάτων",
    "assets_currency_assets": "Περιουσία νομισμάτων",
    "assets_deposit_income_tip": "Η απόδοση υπολογίζεται με βάση την τρέχουσα ισοτιμία, η τελική απόδοση θα είναι αυτή που θα καταβληθεί.",
    "assets_fiat_assets": "OTC Περιουσία",
    "assets_fiat_money_flow": "Ταμειακή ροή OTC",
    "assets_flowing_details": "Λεπτομέρειες ροής",
    "assets_prompt_001": "[Παρακαλώ αποθηκεύστε σωστά το κλειδί για να αποφύγετε την απώλεια]",
    "assets_reduced_asset": "Εκτιμώμενη Περιουσία",
    "assets_row_mining": "Εξόρυξη παραγγελιών",
    "assets_swipe_direction": "Κατεύθυνση μεταφοράς",
    "assets_transfer": "Μεταφορά",
    "assets_transfer_coin": "Μεταφορά νομίσματος",
    "assets_transfer_count": "Ποσότητα μεταφοράς",
    "assets_transfer_currency": "Μεταφορά κεφαλαίων",
    "assets_transfer_dialog_bb": "Λογαριασμός νομισμάτων",
    "assets_transfer_dialog_fiat": "Λογαριασμός fiat",
    "assets_transfer_dialog_from": "Από",
    "assets_transfer_dialog_transfer_to": "Μεταφορά σε",
    "assets_transfer_dialog_wallet": "Λογαριασμός πορτοφολιού",
    "assets_transfer_record": "Καταγραφή μεταφοράς",
    "assets_transfer_to": "Προς",
    "assets_wallet": "Πορτοφόλι",
    "assets_wallet_assets": "Περιουσία πορτοφολιού",
    "assets_wallet_assets_transfer_tip": "* Μόνο η μεταφορά από το πορτοφόλι σε λογαριασμό μπορεί να γίνει συναλλαγή. Δεν υπάρχουν χρεώσεις για μεταφορές μεταξύ λογαριασμών.",
    "assets_wallet_funds_flow": "Ροή κεφαλαίων πορτοφολιού",
    "at_least_one": "Πρέπει να ενεργοποιήσετε τουλάχιστον μία κατάσταση κωδικού συναλλαγής",
    "attention_blacklist": "Παρακολούθηση / Μαύρη λίστα",
    "attention_to_see": "Θέλετε να με παρακολουθήσετε; Κάντε κλικ για να δείτε!",
    "audit_result": "Αποτέλεσμα ελέγχου",
    "authenticated": "Επιβεβαιωμένο",
    "authentication": "Επαλήθευση ταυτότητας",
    "available_balance": "Διαθέσιμο υπόλοιπο",
    "average_purchase_price": "Μέση τιμή αγοράς",
    "avoid_close_set": "Ρύθμιση χωρίς κωδικό",
    "back": "Πίσω",
    "balance": "Υπόλοιπο",
    "bank_card": "Κάρτα τράπεζας",
    "bank_name": "Όνομα τράπεζας",
    "bank_num": "Αριθμός κάρτας τράπεζας",
    "barcode_in_the_box_scan": "Τοποθετήστε τον κωδικό QR/μπαρούτσα στο πλαίσιο για αυτόματο σάρωμα",
    "bb_assets": "Περιουσία νομισμάτων",
    "bb_history_finished": "Ολοκληρώθηκε",
    "bb_time": "Χρόνος",
    "bb_used": "Ενεργοποιημένο",
    "be_usable": "Μπορεί να χρησιμοποιηθεί",
    "bear_consequences_your_investment": "Γνωρίζω και αναλαμβάνω τον επενδυτικό κίνδυνο, ο κωδικός μου είναι:",
    "become": "Γίνε",
    "been_authenticated": "Έχετε επιβεβαιωθεί",
    "beginner_guide_title": "Παγκόσμια ηγετική πλατφόρμα ψηφιακών περιουσιακών στοιχείων",
    "belong_level": "Επίπεδο ανήκει",
    "between_and": "Ανάμεσα σε",
    "bill": "Λογαριασμός",
    "billing_details": "Λεπτομέρειες χρέωσης",
    "bind_email": "Σύνδεση email",
    "bind_google": "Google Authenticator",
    "bind_google_authenticator": "Σύνδεση Google Authenticator",
    "bind_phone": "Σύνδεση τηλεφώνου",
    "branch_name": "Όνομα υποκαταστήματος",
    "bug_order": "Αγορά",
    "buy": "Αγορά",
    "buy_1": "Αγορά",
    "buy_now": "Αγοράστε τώρα",
    "buy_quantity": "Ποσότητα αγοράς",
    "buy_rate": "Προμήθεια αγοράς",
    "buyer": "Αγοραστής",
    "buyer_has_paid_attention": "Ο αγοραστής έχει πληρώσει, προσέξτε",
    "buyer_payment": "Ο αγοραστής έχει πληρώσει",
    "buyer_phone": "Τηλέφωνο αγοραστή",
    "buyer_uid": "Αγοραστής",
    "calculate": "Υπολόγισε",
    "calculations are for your reference only": "Τα αποτελέσματα υπολογισμού είναι μόνο για αναφορά",
    "can_available": "Διαθέσιμο",
    "canceled": "Ακυρώθηκε",
    "cancellations": "Ακυρώσεις",
    "centre": "Κέντρο",
    "change1": "Αλλαγή",
    "change_gesture_password": "Αλλαγή κωδικού",
    "change_google": "Αλλαγή Google Authenticator",
    "change_pay_style": "Αλλαγή τρόπου πληρωμής",
    "change_phone": "Αλλαγή τηλεφώνου",
    "charge_after_the_transaction": "Χρέωση μετά την ολοκλήρωση",
    "charge_out": "Η προμήθεια πρέπει να είναι εντός",
    "check_the_agree": "Συμφωνώ με την επιλογή",
    "china": "Κίνα",
    "choose_picture": "Επιλέξτε εικόνα",
    "clearing_time": "Χρόνος διακανονισμού",
    "click_enter_batch_edit_page": "Κάντε κλικ για να εισέλθετε στη σελίδα μαζικής επεξεργασίας",
    "click_on_the_copy": "Κάντε κλικ για αντιγραφή",
    "clinch_a_deal": "Ο άλλος πρέπει να έχει συναλλαγές (0 σημαίνει χωρίς περιορισμούς)",
    "close": "Κλείσιμο",
    "closed_successfully": "Κλείσιμο επιτυχές",
    "cloud_mining_pool": "Σύννεφο εξόρυξης",
    "coin_failure_0001": "Το νόμισμα δεν υπάρχει",
    "collect": "Αυτοεπιλεγμένα",
    "collection_time": "Χρόνος συλλογής",
    "commission": "Λαμβανόμενη προμήθεια",
    "common_failure": "Αποτυχία!",
    "common_failure_0001": "Διαδικτυακή ανωμαλία",
    "common_problem": "Οδηγίες χρήσης",
    "common_success": "Επιτυχία",
    "complete": "Ολοκληρώθηκε",
    "completed": "Η συναλλαγή ολοκληρώθηκε",
    "computer_login": "Είσοδος από υπολογιστή",
    "confirm": "Επιβεβαίωση",
    "confirm_exit": "Είστε σίγουροι ότι θέλετε να εξέλθετε;",
    "confirm_password": "Παρακαλώ επιβεβαιώστε τον κωδικό σύνδεσης",
    "confirm_submission": "Επιβεβαίωση υποβολής",
    "confirm_the_purchase": "Επιβεβαίωση αγοράς",
    "confirm_to_give_up": "Επιβεβαίωση εγκατάλειψης",
    "confirm_to_sell": "Επιβεβαίωση πώλησης",
    "confirm_upgrade": "Επιβεβαίωση αναβάθμισης",
    "confirm_vote": "Επιβεβαίωση ψήφου",
    "confirmation_information": "Πληροφορίες επιβεβαίωσης",
    "confrim_delete_warn": "Είστε σίγουροι ότι θέλετε να διαγράψετε την προειδοποίηση;",
    "contact_service": "Επικοινωνία με υποστήριξη",
    "contact_us": "Επικοινωνήστε μαζί μας",
    "contract_Order": "Εντολή",
    "contrct Agree Content": "Για την προστασία των δικαιωμάτων σας, πριν από την ενεργοποίηση της σύμβασης, θα πρέπει να διαβάσετε προσεκτικά την «Συμφωνία Συνεχιζόμενης Σύμβασης BitMatrix»",
    "contrct Agree Content footer": "«Συμφωνία Συνεχιζόμενης Σύμβασης BitMatrix»",
    "contrct Agree Content header": "Για την προστασία των δικαιωμάτων σας, διαβάστε προσεκτικά",
    "contrct_decimal": "Δεκαδικά",
    "convert_into": "Μετατροπή σε",
    "converted_currency": "Μετατρεπόμενο νόμισμα",
    "copartner-upgrade-failure": "Αποτυχία αναβάθμισης",
    "copartner-upgrade-no": "Δεν πληρούνται οι προϋποθέσεις αναβάθμισης",
    "copartner-upgrade-ok": "Μπορεί να αναβαθμιστεί",
    "copartner-upgrade-success": "Αναβάθμιση επιτυχής",
    "copy_content": "Έχει αντιγραφεί στο πρόχειρο",
    "country": "Χώρα",
    "create_time": "Χρόνος δημιουργίας",
    "cumulative_income:": "Συγκεντρωτικά έσοδα:",
    "cumulative_participation": "Συγκεντρωτική συμμετοχή",
    "currency": "Νόμισμα",
    "currency_fee": "Προμήθεια νομίσματος",
    "currency_information": "Λεπτομέρειες νομίσματος",
    "currency_name": "Όνομα νομίσματος",
    "currency_not_found": "Ο τύπος νομίσματος δεν υπάρχει",
    "currency_select": "Επιλογή νομίσματος",
    "current_day_return": "Ημερήσια επιστροφή",
    "current_entrust": "Τρέχουσα εντολή",
    "current_identity": "Τρέχουσα ταυτότητα",
    "current_polling_date": "Ημερομηνία ψηφοφορίας",
    "current_status": "Τρέχουσα κατάσταση",
    "currently_available": "Διαθέσιμο αυτή τη στιγμή",
    "date": "Ημερομηνία",
    "date_of_birth": "Ημερομηνία γέννησης",
    "day_highs_and_lows": "Υψηλά και χαμηλά ημέρας",
    "day_highs_and_lows1": "Υψηλά και χαμηλά ημέρας",
    "day_income": "Ημέρα",
    "day_trading": "Ημερήσιες συναλλαγές",
    "daytime": "Ημέρα",
    "ddress_management": "Διαχείριση διευθύνσεων",
    "deal_amount": "Συνολικό ποσό συναλλαγής",
    "deal_done": "Ολοκληρώθηκε",
    "deal_gross": "Συναλλαγές/Συνολικά",
    "deal_of_the_week": "Συναλλαγές της εβδομάδας",
    "deal_price": "Μέση τιμή συναλλαγής",
    "deal_sum": "Ποσό συναλλαγής",
    "deal_the_detail": "Λεπτομέρειες παραγγελίας",
    "deal_volume": "Ποσό συναλλαγής",
    "deduct_coin": "Αφαίρεση νομίσματος",
    "default_settlement": "Κανονισμός",
    "defaults": "Προεπιλογές",
    "deposit": "Κατάθεση με ένα κλικ",
    "deposit_details": "Λεπτομέρειες κατάθεσης",
    "deposit_income": "Έσοδα κατάθεσης",
    "deposit_lock": "Κλείδωμα κατάθεσης",
    "deposit_lock_details": "Λεπτομέρειες κλειδώματος κατάθεσης",
    "deposit_returned_principal": "Επιστροφή κεφαλαίου",
    "depth": "Βάθος",
    "detailed_rules": "Λεπτομερείς κανόνες",
    "details": "Λεπτομέρειες",
    "digits6": "Εισάγετε 6 ψηφία",
    "direct_market": "Άμεση προώθηση",
    "direct_reward": "Άμεση ανταμοιβή",
    "direction": "Κατεύθυνση",
    "discount_Rate": "Ποσοστό έκπτωσης",
    "discount_currency_introduction": "Εισαγωγή νομισμάτων",
    "discount_dialog_tip1": "Συγχαρητήρια! Πληροίτε",
    "discount_dialog_tip2": "τις προϋποθέσεις συμμετοχής και επιλέχθηκα τυχαία από το σύστημα ως τυχερός χρήστης. Μετά την απόκτηση δικαιώματος, θα έχετε δικαίωμα συμμετοχής!",
    "discount_dialog_tip3": "Έχετε αποκτήσει",
    "discount_dialog_tip4": "δικαίωμα ανταλλαγής. Οι θέσεις είναι περιορισμένες, παρακαλώ συμμετάσχετε εντός της περιόδου της εκδήλωσης!",
    "discount_dialog_tip5": "Αφού αποποιηθείτε το δικαίωμα, δεν μπορείτε να συμμετάσχετε",
    "discount_dialog_tip6": "στην εκδήλωση και δεν μπορείτε να υποβάλετε αίτηση συμμετοχής.",
    "discount_dialog_tip7": "Σημείωση: Η επιτυχής απόκτηση δικαιώματος θα αφαιρέσει",
    "discount_purchase": "Ανταλλαγή έκπτωσης",
    "discounted_or_original_price": "Τιμή με έκπτωση/Αρχική τιμή",
    "dispose_result": "Αποτέλεσμα επεξεργασίας",
    "disqualification": "Αποποίηση δικαιώματος",
    "divided_earnings": "Διανεμηθέν κέρδος",
    "drag": "Παρακαλώ κρατήστε το ρυθμιστικό και σύρετε προς τα δεξιά",
    "each_input": "Επιβεβαίωση κωδικού συναλλαγής σε κάθε συναλλαγή",
    "edt_selfchoose": "Επεξεργασία αυτοεπιλογής",
    "effective": "Ικανό",
    "eligibility": "Δικαίωμα λήψης",
    "email": "Εγγραφή email",
    "email_address_format": "Παρακαλώ εισάγετε έγκυρη διεύθυνση email",
    "email_code": "Κωδικός επιβεβαίωσης email",
    "empty_deal_pass": "Ο κωδικός συναλλαγής δεν μπορεί να είναι κενός",
    "end time": "Χρόνος λήξης",
    "end_date": "Ημερομηνία λήξης",
    "end_time": "Χρόνος λήξης",
    "enter_account": "Εισάγετε το τηλέφωνο/email σας",
    "enter_cause_of_complaint": "Εισάγετε τον λόγο καταγγελίας",
    "enter_fb_buy_price": "Εισάγετε την τιμή αγοράς",
    "enter_fb_sell_price": "Εισάγετε την τιμή πώλησης",
    "enter_your_password": "Εισάγετε τον κωδικό σύνδεσης",
    "enter_password": "Εισάγετε τον κωδικό",
    "entrust_amount": "Ποσότητα εντολής",
    "entrust_failure_0001": "Διαδικτυακή ανωμαλία",
    "entrust_failure_0002": "Λάθος τύπος αναζήτησης",
    "entrust_failure_0003": "Ο τύπος αντιστοίχισης δεν μπορεί να είναι κενός",
    "entrust_failure_0004": "ID εμπόρου δεν μπορεί να είναι κενό",
    "entrust_failure_0005": "ID χρήστη δεν μπορεί να είναι κενό",
    "entrust_failure_0006": "Ο χρήστης δεν υπάρχει",
    "entrust_failure_0007": "ID ζεύγους συναλλαγών δεν μπορεί να είναι κενό",
    "entrust_failure_0009": "Το ζεύγος συναλλαγών δεν υπάρχει",
    "entrust_failure_0010": "Ο κωδικός συναλλαγής δεν μπορεί να είναι κενός",
    "entrust_failure_0011": "Λάθος κωδικός συναλλαγής",
    "entrust_failure_0012": "Ο τύπος εντολής δεν μπορεί να είναι κενός",
    "entrust_failure_0013": "Η ποσότητα εντολής δεν μπορεί να είναι κενή",
    "entrust_failure_0014": "Η τιμή εντολής ορίου δεν μπορεί να είναι κενή",
    "entrust_failure_0015": "ID εντολής δεν μπορεί να είναι κενό",
    "entrust_failure_0016": "Η εντολή δεν υπάρχει",
    "entrust_failure_0017": "Η εντολή έχει διαγραφεί",
    "entrust_failure_0018": "Η εντολή δεν μπορεί να ακυρωθεί",
    "entrust_failure_0019": "Ο αριθμός έκδοσης δεν μπορεί να είναι κενός",
    "entrust_failure_0020": "Η εντολή αγοράς δεν μπορεί να ακυρωθεί",
    "entrust_failure_0021": "Τα τρέχοντα δεδομένα έχουν ενημερωθεί",
    "entrust_failure_0022": "Δεν υπάρχουν τρέχουσες εντολές, δεν μπορείτε να υποβάλετε εντολή αγοράς",
    "entrust_failure_0023": "Το ζεύγος συναλλαγών έχει απενεργοποιηθεί ή το σχετικό νόμισμα έχει απενεργοποιηθεί",
    "entrust_failure_0024": "Το ζεύγος συναλλαγών δεν έχει ενεργοποιηθεί για συναλλαγές",
    "entrust_failure_0025": "Η τιμή εντολής είναι κάτω από την ελάχιστη ή πάνω από την μέγιστη τιμή",
    "entrust_failure_0026": "Η ποσότητα εντολής είναι κάτω από την ελάχιστη ή πάνω από την μέγιστη ποσότητα",
    "entrust_failure_0027": "Αυτός ο λογαριασμός έχει απαγορευτεί για συναλλαγές, παρακαλώ επικοινωνήστε με την υποστήριξη",
    "entrust_failure_0030": "Υπερβαίνει την μέγιστη ημερήσια αύξηση του ζεύγους συναλλαγών {} και δεν μπορεί να υποβληθεί",
    "entrust_failure_0031": "Υπερβαίνει την μέγιστη ημερήσια πτώση του ζεύγους συναλλαγών {} και δεν μπορεί να υποβληθεί",
    "entrust_msg_match_type_001": "Εντολή ορίου",
    "entrust_msg_match_type_002": "Εντολή αγοράς",
    "entrust_msg_status_001": "Μη εκτελεσμένη",
    "entrust_msg_status_002": "Μερική εκτέλεση",
    "entrust_msg_status_003": "Πλήρης εκτέλεση",
    "entrust_msg_status_004": "Ακυρώθηκε",
    "entrust_msg_type_001": "Εντολή πώλησης",
    "entrust_msg_type_002": "Εντολή αγοράς",
    "entrusted_price": "Τιμή εντολής",
    "entry_orders": "Εντολές",
    "equal_principal": "Ισοδύναμο κεφάλαιο",
    "error_email": "Λάθος email",
    "error_network_anomaly": "Διαδικτυακή ανωμαλία",
    "error_phone": "Παρακαλώ εισάγετε έγκυρο τηλέφωνο",
    "error_unknow": "Διαδικτυακό σφάλμα",
    "event_detail": "Λεπτομέρειες εκδήλωσης",
    "event_detail_warn_tip": "* Σημαντική σημείωση: Τα ψηφιακά περιουσιακά στοιχεία είναι καινοτόμα περιουσιακά στοιχεία με μεγάλη διακύμανση τιμών. Παρακαλώ κάντε λογική αξιολόγηση και επιλέξτε προσεκτικά τις επενδυτικές σας αποφάσεις!",
    "event_start_time": "Χρόνος έναρξης εκδήλωσης",
    "every day": "Κάθε μέρα",
    "everyday": "Καθημερινά",
    "exchange": "Συναλλαγή",
    "exchange_currency": "Νόμισμα ανταλλαγής",
    "exchange_details": "Λεπτομέρειες συναλλαγής",
    "exchange_history": "Ιστορικό συναλλαγών",
    "exchange_quantity": "Ποσότητα ανταλλαγής",
    "exchange_rules": "Κανόνες ανταλλαγής",
    "exchangeable": "Μπορεί να ανταλλαγεί",
    "exclusive_posters": "Δημιουργία αποκλειστικών αφισών",
    "exclusive_posters-Video": "Δημιουργία αφίσας",
    "exit": "Έξοδος",
    "expected_return": "Αναμενόμενη απόδοση",
    "expired": "Έχει λήξει",
    "express_area": "Ταχεία περιοχή",
    "face_recognition": "Αναγνώριση προσώπου",
    "failure": "Αποτυχία",
    "fb_lookup": "Κάντε κλικ για να δείτε",
    "fb_order_number": "Αριθμός παραγγελίας",
    "feeze_thaw_msg_status_0001": "Σε κατάσταση πάγωσης",
    "feeze_thaw_msg_status_0002": "Απελευθερωμένο",
    "fill_in_at_least_one": "Παρακαλώ συμπληρώστε τουλάχιστον μία επιλογή",
    "fill_wrong": "Λάθος συμπλήρωση μπορεί να προκαλέσει απώλεια περιουσίας, παρακαλώ ελέγξτε προσεκτικά",
    "filtrate": "Φιλτράρισμα",
    "filtrate_content": "Φιλτράρετε τρόπους πληρωμής, χώρες, νομίσματα εδώ!",
    "financial_management_is_cancel": "Η απόδοση κερδών έχει ακυρωθεί! Μην υποβάλετε ξανά!",
    "financial_management_is_delete": "Αυτή η κατηγορία επενδύσεων έχει διαγραφεί",
    "financial_management_is_disable": "Αυτή η κατηγορία επενδύσεων έχει απενεργοποιηθεί",
    "financial_management_not_cancel": "Η τακτική επένδυση δεν μπορεί να ακυρωθεί!",
    "financial_management_number_not_found": "Η ποσότητα επένδυσης δεν μπορεί να είναι κενή",
    "financial_management_record_add_failure": "Η προσωπική περιουσία του χρήστη δεν είναι επαρκής, παρακαλώ ανανεώστε πριν προχωρήσετε σε επενδύσεις!",
    "financial_management_record_less_than_min_number": "Η ποσότητα νομισμάτων που προστέθηκε είναι μικρότερη από την ελάχιστη ποσότητα {}",
    "financial_management_type_not_found": "Η εμφάνιση πληροφοριών επενδύσεων απέτυχε, επιλέξτε σωστή κατηγορία επένδυσης!",
    "financial_management_type_not_null": "Ο τύπος επένδυσης δεν μπορεί να είναι κενός",
    "find_password": "Ανακτήστε τον κωδικό",
    "fingerprint": "Δακτυλικό αποτύπωμα",
    "fingerprint_verification": "Επαλήθευση δακτυλικού αποτυπώματος",
    "first_bind_phone": "Παρακαλώ πρώτα συνδέστε τον αριθμό τηλεφώνου",
    "first_period": "Πρώτη περίοδος",
    "following_account": "Στο παρακάτω λογαριασμό",
    "free": "Χωρίς προμήθεια",
    "Takerfree": "Προμήθεια Taker",
    "Makerfree": "Προμήθεια Maker",
    "freeze": "Πάγωμα",
    "freeze_to_be_processed": "Πάγωμα υπό επεξεργασία, περιμένετε",
    "fuc_use_discount": "Χρησιμοποιήστε {} για να πληρώσετε την προμήθεια συναλλαγής * έκπτωση",
    "futu": "Δεύτερη εικόνα",
    "gathering": "Συγκέντρωση πληρωμών",
    "gears": "Ταχύτητες",
    "generation1": "1η γενιά",
    "gesture_error_limit": "Ο αριθμός εισαγωγής λανθασμένου κωδικού έχει φτάσει το όριο",
    "gesture_password": "Κωδικός χειρονομιών",
    "gesture_unlock": "Ξεκλείδωμα με χειρονομία",
    "get_code": "Λάβετε τον κωδικό επιβεβαίωσης",
    "goSign": "Πηγαίνετε για εγγραφή",
    "go_change": "Πηγαίνετε για αλλαγή",
    "go_login": "Παρακαλώ πρώτα συνδεθείτε",
    "go_pay": "Πηγαίνετε για πληρωμή",
    "go_setting": "Πηγαίνετε στις ρυθμίσεις",
    "go_to_pick_up": "Πηγαίνετε να παραλάβετε",
    "go_to_receiving": "Πηγαίνετε να λάβετε πληρωμές",
    "go_to_redeem": "Πηγαίνετε να ανταλλάξετε",
    "google_verification_code": "Κωδικός επιβεβαίωσης Google",
    "grievance_freeze": "Πάγωμα καταγγελίας",
    "has_been_on": "Έχει αναρτηθεί",
    "have_been_frozen": "Έχει παγώσει",
    "have_received": "Έχει ληφθεί",
    "help_center": "Κέντρο βοήθειας",
    "hide_canceled": "Απόκρυψη ακυρωμένων",
    "hide_other": "Εμφάνιση όλων των εντολών",
    "high_frequency_partner": "Συνεργάτης υψηλής συχνότητας",
    "highest_single": "Μέγιστη μεμονωμένη",
    "highfrequency_fee_sharing": "Μοιρασιά προμηθειών υψηλής συχνότητας",
    "hint": "Σημείωση",
    "hint_password": "Εισάγετε τον κωδικό",
    "hint_phone_email": "Εισάγετε τηλέφωνο/email",
    "historical_transaction": "Ιστορικό συναλλαγών",
    "history": "Ιστορία",
    "home_page": "Αρχική σελίδα",
    "horizontal_version": "Οριζόντια έκδοση",
    "hours": "Ώρες",
    "i18n_failure_0001": "Αποτυχία αναζήτησης γλώσσας",
    "i18n_failure_0002": "Αποτυχία αναζήτησης λεξικού χώρας",
    "iTo_draw_again": "Εισάγετε ξανά τον κωδικό",
    "i_see": "Κατάλαβα",
    "i_see1": "Το κατάλαβα",
    "id_card_back_direction_failure": "Λάθος κατεύθυνση πίσω της ταυτότητας",
    "id_card_front_direction_failure": "Λάθος κατεύθυνση μπροστά της ταυτότητας",
    "id_cord": "Αριθμός ταυτότητας",
    "id_type": "Τύπος ταυτότητας",
    "identification": "Αποτυχία αναγνώρισης",
    "identity_authentication": "Ταυτοποίηση",
    "identity_card": "Ταυτότητα",
    "if_at": "Εάν είστε στο",
    "ignore": "Αγνοήστε",
    "immediately_invited": "Άμεση πρόσκληση",
    "immediately_join": "Άμεση συμμετοχή",
    "in_trading": "Σε συναλλαγή",
    "income": "Έσοδα",
    "income_currency_type": "Τύπος νομισμάτων",
    "income_deposit_principal": "Κεφάλαιο κατάθεσης",
    "incomeing": "Έσοδα σε εξέλιξη",
    "increment quantity": "Η εισαγόμενη ποσότητα δεν μπορεί να ξεπερνά τη μέγιστη αύξηση",
    "indirect_promotion": "Έμμεση προώθηση",
    "indirect_reward": "Έμμεση ανταμοιβή",
    "individual_selling_tips": "Δεν μπορείτε να πουλήσετε τις δικές σας εντολές",
    "input_Branch_address": "Εισάγετε τη διεύθυνση υποκαταστήματος",
    "input_address_labels": "Εισάγετε ετικέτες",
    "input_alipay_account": "Εισάγετε τον λογαριασμό Alipay",
    "input_bank_card_number": "Εισάγετε τον αριθμό τραπεζικής κάρτας",
    "input_bank_name": "Εισάγετε το όνομα της τράπεζας",
    "input_buyer_uid": "Εισάγετε το UID του αγοραστή",
    "input_currency_referred": "Εισάγετε το ακρωνύμιο νομίσματος",
    "input_deposit_amount": "Εισάγετε την ποσότητα κατάθεσης",
    "input_email": "Εισάγετε το email",
    "input_email_code": "Εισάγετε τον κωδικό email",
    "input_id_cord": "Εισάγετε τον αριθμό ταυτότητας",
    "input_lock_amount": "Εισάγετε την ποσότητα κλειδώματος",
    "input_mention_money_address": "Εισάγετε τη διεύθυνση ανάληψης",
    "input_money": "Εισάγετε το ποσό",
    "input_name": "Εισάγετε το όνομα",
    "input_newphone_code": "Εισάγετε τον νέο αριθμό τηλεφώνου",
    "input_nike_name": "Εισάγετε το ψευδώνυμο",
    "input_old_google_code": "Εισάγετε τον παλιό κωδικό Google",
    "input_old_pass": "Εισάγετε τον παλιό κωδικό",
    "input_paypal_acctount": "Εισάγετε τον λογαριασμό PayPal",
    "input_phone": "Εισάγετε τον αριθμό τηλεφώνου",
    "input_phone_code": "Εισάγετε τον κωδικό επιβεβαίωσης",
    "input_price": "Εισάγετε την τιμή",
    "input_quantity": "Εισάγετε την ποσότητα",
    "input_remark": "Εισάγετε σημείωση",
    "input_service_charge": "Εισάγετε την προμήθεια",
    "input_trading_volume": "Εισάγετε τον όγκο συναλλαγών",
    "input_transaction_password": "Εισάγετε τον κωδικό συναλλαγών",
    "input_wechat_account": "Εισάγετε τον λογαριασμό WeChat",
    "input_wire_transfer": "Εισάγετε τη διεύθυνση ηλεκτρονικής μεταφοράς",
    "inputs_numbers_greater_can_number": "Η εισαγόμενη ποσότητα υπερβαίνει την επιτρεπόμενη ποσότητα μεταφοράς",
    "insufficient_light": "Ανεπαρκής φωτισμός",
    "interest": "Επιτόκιο",
    "invitation_details": "Λεπτομέρειες προσκλήσεων",
    "invitation_register_ad_not_found": "Διαφήμιση πρόσκλησης εγγραφής δεν βρέθηκε",
    "invitation_register_ad_tatle_not_found": "Τίτλος διαφήμισης πρόσκλησης δεν βρέθηκε",
    "invitation_register_coin_not_found": "Νόμισμα ανταμοιβής πρόσκλησης δεν βρέθηκε",
    "invitation_register_logo_not_found": "Λογότυπο πρόσκλησης δεν βρέθηκε",
    "invitation_register_not_found": "Σύνδεσμος πρόσκλησης δεν βρέθηκε",
    "invite_code": "Κωδικός πρόσκλησης (προαιρετικός)",
    "invite_code1": "Κωδικός πρόσκλησης",
    "invite_commission": "Προμήθεια",
    "invite_gift": "Δώρο πρόσκλησης",
    "invite_reward": "Ανταμοιβή πρόσκλησης",
    "invited_UID": "Προσκεκλημένο UID",
    "invitees_are_available": "Οι προσκαλούμενοι μπορούν να αποκτήσουν",
    "inviter-is-user": "Ο προσκαλών δεν μπορεί να είναι ο ίδιος",
    "inviter-not-exist": "Ο προσκαλών δεν υπάρχει",
    "inviter_no_null": "Ο προσκαλών δεν μπορεί να είναι κενός",
    "ios_24小时排行榜": "Κατάλογος 24 ωρών",
    "ios_OTC交易须知": "Οδηγίες OTC",
    "ios_PAYPAL": "PAYPAL",
    "ios_otc_order_prompt_001": "Αν δεν γίνει πληρωμή εντός λεπτών και δεν πατήσετε 'Επιβεβαίωση πληρωμής', η παραγγελία θα ακυρωθεί αυτόματα.",
    "ios_otc_order_prompt_002": "Αν δεν γίνει πληρωμή στον πωλητή εντός λεπτών και δεν πατήσετε 'Επιβεβαίωση πληρωμής', η παραγγελία θα ακυρωθεί αυτόματα.",
    "ios_万": "Χίλια",
    "ios_上传身份证反面": "Ανεβάστε την πίσω πλευρά της ταυτότητας",
    "ios_上传身份证正面": "Ανεβάστε την μπροστινή πλευρά της ταυτότητας",
    "ios_两次密碼不一致请再次输入密碼": "Οι δύο κωδικοί δεν ταιριάζουν, εισάγετε ξανά.",
    "ios_个确认大约需要": "Η επιβεβαίωση θα χρειαστεί περίπου",
    "ios_中国大陆": "Ηπειρωτική Κίνα",
    "ios_中级合伙人": "Μέσος συνεργάτης",
    "ios_主版块": "Κύρια ενότητα",
    "ios_之间": "Μεταξύ",
    "ios_买单": "Εντολή αγοράς",
    "ios_买方必须成交过几次(0等于不限制)": "Ο αγοραστής πρέπει να έχει πραγματοποιήσει συναλλαγές (0 σημαίνει χωρίς περιορισμούς)",
    "ios_二维码/条形码": "QR Code/Barcode",
    "ios_付款": "Πληρωμή",
    "ios_充币地址": "Διεύθυνση κατάθεσης",
    "ios_先设置交易密码": "Ρυθμίστε πρώτα τον κωδικό συναλλαγών",
    "ios_先设置交易密碼": "Ρυθμίστε πρώτα τον κωδικό συναλλαγών",
    "ios_关闭照明": "Κλείστε το φως",
    "ios_分享失败": "Αποτυχία κοινοποίησης",
    "ios_分享成功": "Επιτυχής κοινοποίηση",
    "ios_初级合伙人": "Αρχάριος συνεργάτης",
    "ios_卖单": "Εντολή πώλησης",
    "ios_卖家已付款提示": "Σημείωση: Ο πωλητής έχει πληρώσει",
    "ios_卖方必须成交过几次(0等于不限制)": "Ο πωλητής πρέπει να έχει πραγματοποιήσει συναλλαγές (0 σημαίνει χωρίς περιορισμούς)",
    "ios_去解绑": "Πηγαίνετε για αποσύνδεση",
    "ios_发布成功": "Επιτυχής ανάρτηση",
    "ios_发送成功": "Επιτυχής αποστολή",
    "ios_各种费率说明": "Επεξήγηση διαφόρων ποσοστών",
    "ios_团队人数不足": "Ανεπαρκής αριθμός ομάδας",
    "ios_复制QQ号": "Αντιγράψτε τον αριθμό QQ",
    "ios_复制地址": "Αντιγράψτε τη διεύθυνση",
    "ios_如果你已完成线下付款，请点击下方已付款按钮。如果未完成付款，却点击已付款将会受到严厉惩罚，甚至永久冻结账户！": "Αν έχετε ολοκληρώσει την πληρωμή offline, πατήστε το κουμπί 'Έχω πληρώσει' παρακάτω. Αν πατήσετε 'Έχω πληρώσει' χωρίς να έχετε ολοκληρώσει την πληρωμή, θα υποστείτε αυστηρές ποινές, ακόμα και μόνιμο πάγωμα του λογαριασμού σας!",
    "ios_客户端或在线钱包将您需要充值的": "Ο πελάτης ή το online πορτοφόλι θα σας χρειαστεί να καταθέσετε",
    "ios_客户端或在线钱包将您需要充幣的": "Ο πελάτης ή το online πορτοφόλι θα σας χρειαστεί να καταθέσετε νομίσματα",
    "ios_客服": "Εξυπηρέτηση πελατών",
    "ios_密碼设置成功！": "Ο κωδικός ρυθμίστηκε επιτυχώς!",
    "ios_将该地址设置为认证地址": "Ορίστε αυτή τη διεύθυνση ως διεύθυνση επαλήθευσης",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充值，不影响到账。\n3、最小充值金额": "Ώρες αναμονής, παρακαλώ περιμένετε.\n2. Η ίδια διεύθυνση μπορεί να χρησιμοποιηθεί πολλές φορές για κατάθεση, χωρίς να επηρεάζει την πίστωση.\n3. Ελάχιστο ποσό κατάθεσης",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充幣，不影响到账。\n3、最小充幣金额": "Ώρες αναμονής, παρακαλώ περιμένετε.\n2. Η ίδια διεύθυνση μπορεί να χρησιμοποιηθεί πολλές φορές για κατάθεση, χωρίς να επηρεάζει την πίστωση.\n3. Ελάχιστο ποσό κατάθεσης",
    "ios_已付款": "Έχει πληρωθεί",
    "ios_币币": "Νόμισμα-νόμισμα",
    "ios_常见问题": "Συχνές ερωτήσεις",
    "ios_开启照明": "Ανοίξτε το φως",
    "ios_意见反馈": "Ανατροφοδότηση",
    "ios_成交": "Συναλλαγή",
    "ios_我的": "Δικό μου",
    "ios_手机号错误": "Λάθος αριθμός τηλεφώνου",
    "ios_提交成功，等待认证": "Υποβολή επιτυχής, περιμένετε έγκριση",
    "ios_提币数量不能大于手续费": "Η ποσότητα ανάληψης δεν μπορεί να είναι μεγαλύτερη από την προμήθεια",
    "ios_提币数量减去手续费后不能": "Η ποσότητα ανάληψης μείον την προμήθεια δεν μπορεί να είναι",
    "ios_收款二维码": "QR Code πληρωμής",
    "ios_收藏成功": "Επιτυχής αποθήκευση",
    "ios_数目发送到该地址。发送完成后，系统会自动在此交易获得": "Ποσό έχει αποσταλεί σε αυτή τη διεύθυνση. Μετά την ολοκλήρωση της αποστολής, το σύστημα θα αποκτήσει αυτόματα αυτή τη συναλλαγή.",
    "ios_暂无网络": "Δεν υπάρχει διαθέσιμο δίκτυο.",
    "ios_最多可锁": "Μέγιστο ποσό κλειδώματος.",
    "ios_最大限额": "Μέγιστο όριο.",
    "ios_最小限额": "Ελάχιστο όριο.",
    "ios_未解锁": "Μη ξεκλειδωμένο.",
    "ios_没有中国大陆身份证的用户请通过网站版高级认证": "Οι χρήστες χωρίς κινεζική ταυτότητα της ηπειρωτικής Κίνας, παρακαλούμε χρησιμοποιήστε την έκδοση ιστοσελίδας για ανώτερη πιστοποίηση.",
    "ios_法币": "OTC.",
    "ios_注册表示同意": "Η εγγραφή δηλώνει συμφωνία.",
    "ios_注册表示同意用户协议": "Η εγγραφή δηλώνει συμφωνία με τους όρους χρήσης.",
    "ios_登录过期，请重新登录": "Η σύνδεση έχει λήξει, παρακαλώ συνδεθείτε ξανά.",
    "ios_直接推荐人uid": "UID άμεσου προτεινόμενου.",
    "ios_直推人数不足": "Ανεπαρκής αριθμός άμεσων προτάσεων.",
    "ios_绑定成功": "Επιτυχής σύνδεση.",
    "ios_请上传您的身份证": "Παρακαλώ ανεβάστε την ταυτότητά σας.",
    "ios_请上传身份证反面照": "Παρακαλώ ανεβάστε φωτογραφία της πίσω πλευράς της ταυτότητας.",
    "ios_请上传身份证正面照": "Παρακαλώ ανεβάστε φωτογραφία της μπροστινής πλευράς της ταυτότητας.",
    "ios_请先绑定收款方式": "Παρακαλώ πρώτα να συνδέσετε τον τρόπο πληρωμής.",
    "ios_请先设置支付方式": "Παρακαλώ πρώτα να ρυθμίσετε τη μέθοδο πληρωμής.",
    "ios_请输入价格和数量": "Παρακαλώ εισάγετε τιμή και ποσότητα.",
    "ios_请输入新邮箱": "Παρακαλώ εισάγετε νέο email.",
    "ios_请输入正确的账号格式": "Παρακαλώ εισάγετε τη σωστή μορφή λογαριασμού.",
    "ios_请输入身份证号": "Παρακαλώ εισάγετε τον αριθμό ταυτότητας.",
    "ios_请输入限额": "Παρακαλώ εισάγετε το όριο.",
    "ios_请通过": "Παρακαλώ περάστε μέσω.",
    "ios_资产": "Περιουσιακά στοιχεία.",
    "ios_超时": "Χρόνος λήξης.",
    "ios_身份证号": "Αριθμός ταυτότητας.",
    "ios_退出成功": "Έξοδος επιτυχής.",
    "ios_邀请人可获得 * 的佣金": "Ο προσκαλών μπορεί να κερδίσει προμήθεια *.",
    "ios_锁仓数量不能为空": "Η ποσότητα κλειδώματος δεν μπορεί να είναι κενή.",
    "ios_间接接推荐人uid": "UID έμμεσου προτεινόμενου.",
    "ios_隐藏其他挂单": "Απόκρυψη άλλων εντολών.",
    "ios_高级合伙人": "Ανώτερος συνεργάτης.",
    "is_bind": "Είναι συνδεδεμένο.",
    "is_delete_select": "Θέλετε να διαγράψετε την επιλογή;",
    "issued": "Εκδόθηκε.",
    "issued_to_lock": "Εκδόθηκε για κλείδωμα.",
    "k_close": "Κλείσιμο.",
    "k_max": "Μέγιστο.",
    "k_min": "Ελάχιστο.",
    "k_num": "Ποσότητα.",
    "k_open": "Άνοιγμα.",
    "kline_target": "Στόχος.",
    "language": "Γλώσσα.",
    "lead_content": "Αναζητήσεις παραγγελιών, δημοσίευση διαφημίσεων, ρυθμίσεις πληρωμής, παρακολουθήστε/μπλοκάρετε εδώ!",
    "least_four": "Παρακαλώ συνδέστε τουλάχιστον 4 σημεία.",
    "less-than-min": "Κάτω από το ελάχιστο όριο κλειδώματος.",
    "lf there are pending": "Εάν υπάρχουν εκκρεμείς εντολές περιορισμού αντίθετης κατεύθυνσης, θα ακυρωθούν πριν την πλήρη εκκαθάριση.",
    "limit": "Ρυθμίσεις περιορισμού.",
    "limit_orderAA": "Εντολή περιορισμού.",
    "limit_set": "Ρυθμίσεις περιορισμού (προαιρετικό).",
    "limited_price": "Περιορισμένη τιμή.",
    "liquidation": "Εκκαθάριση.",
    "liquidation AMT": "Ποσότητα εκκαθάρισης.",
    "liquidation details": "Λεπτομέρειες εκκαθάρισης.",
    "loading": "Φόρτωση...",
    "lock-expire": "Η διάρκεια κλειδώματος έχει λήξει.",
    "lock_amount": "Ποσότητα κλειδώματος.",
    "lock_duration": "Διάρκεια κλειδώματος.",
    "lock_person": "Άτομο κλειδώματος.",
    "lock_record": "Καταγραφή κλειδώματος.",
    "lock_remaining": "Υπόλοιπο κλειδώματος.",
    "lock_reward": "Αμοιβή κλειδώματος.",
    "lock_time": "Χρόνος κλειδώματος.",
    "locked": "Κλειδωμένο.",
    "locked_closed": "Έχει εκκαθαριστεί.",
    "locked_insufficient_balance": "Ανεπαρκές υπόλοιπο.",
    "locked_settled": "Έχει εκκαθαριστεί.",
    "locked_unsettlement": "Μη εκκαθαρισμένο.",
    "locked_warehouse": "Σε κατάσταση κλειδώματος.",
    "log_in": "Σύνδεση.",
    "login": "Σύνδεση.",
    "login_expired": "Η σύνδεση έχει λήξει, παρακαλώ συνδεθείτε ξανά.",
    "login_forget_pwd": "Ξεχάσατε τον κωδικό;",
    "login_name_txt": "Όνομα χρήστη.",
    "login_pass": "Κωδικός σύνδεσης.",
    "logion_welcome_txt": "Καλώς ήρθατε στη σύνδεση.",
    "mail": "Email.",
    "mailbox": "Επαλήθευση email.",
    "mailbox_bind_success": "Η σύνδεση email ήταν επιτυχής.",
    "mailbox_new": "Email.",
    "main_picture": "Κύρια εικόνα.",
    "manage_reward_details": "Διαχείριση λεπτομερειών αμοιβής.",
    "management_reward": "Διαχείριση αμοιβής.",
    "management_share": "Διαχείριση μερίσματος.",
    "mandarin": "Παρακαλώ διαβάστε αριθμούς στα Κινέζικα.",
    "mandarin_reading_content": "Παρακαλώ διαβάστε το παρακάτω περιεχόμενο στα Κινέζικα.",
    "manual_recharge_msg_status_0001": "Αναμονή κατάθεσης.",
    "manual_recharge_msg_status_0002": "Έγκριση επιτυχής.",
    "manual_recharge_msg_status_0003": "Κατάθεση επιτυχής.",
    "manual_recharge_msg_type_0001": "Κανονική κατάθεση.",
    "manual_recharge_msg_type_0002": "Επεξεργασία κεφαλαίων.",
    "manual_recharge_msg_type_0003": "Δωρεά από εκδήλωση.",
    "margin": "Περιθώριο.",
    "market_failure_00001": "Αποτυχία λήψης τιμών αρχικής σελίδας.",
    "market_failure_00002": "ID έμπορα δεν υπάρχει.",
    "market_failure_00003": "Δεν υπάρχουν διαθέσιμες περιοχές συναλλαγών για τον έμπορα.",
    "market_failure_00004": "Αποτυχία λήψης τιμών νομισμάτων.",
    "market_failure_00005": "Το όνομα του ζεύγους συναλλαγών δεν μπορεί να είναι κενό.",
    "market_failure_00006": "Δεν υπάρχουν διαθέσιμες πληροφορίες για τις κατηγορίες κάτω από τον έμπορα.",
    "market_failure_00007": "Αποτυχία λήψης πληροφοριών για το ζεύγος συναλλαγών.",
    "market_failure_00008": "Αποτυχία λήψης δεδομένων K-line.",
    "market_failure_00009": "Αποτυχία λήψης δεδομένων βάθους.",
    "market_failure_00010": "Λάθος παράμετροι αιτήματος.",
    "market_failure_00011": "Μη έγκυρο ζεύγος συναλλαγών.",
    "market_low_price": "Μέγιστη τιμή αγοράς στην αγορά.",
    "market_order": "Εντολή αγοράς.",
    "market_price": "Τιμή αγοράς.",
    "market_sell_price": "Ελάχιστη τιμή πώλησης στην αγορά.",
    "market_switch_moved_here": "Η εναλλαγή οριζόντιας/κάθετης οθόνης έχει μεταφερθεί εδώ.",
    "match": "Αγώνα.",
    "match_the_number_of": "Ποσότητα αγώνα.",
    "max_lock_volume": "Μέγιστος όγκος κλειδώματος.",
    "maximum_30_characters": "Μέχρι 30 χαρακτήρες.",
    "maximum_coins": "Η μέγιστη ποσότητα ανάληψης είναι.",
    "maximum_withdrawal": "Μέγιστη ανάληψη ανά συναλλαγή.",
    "mention_a_currency": "Ποσότητα ανάληψης.",
    "min_lock_volume": "Ελάχιστος όγκος κλειδώματος.",
    "min_recharge_amount": "Η ελάχιστη ποσότητα κατάθεσης είναι {}* και θα πιστωθεί αυτόματα όταν φτάσει το απαιτούμενο ποσό.",
    "minhour": "Διακλάδωση.",
    "minimum_coins": "Η ελάχιστη ποσότητα ανάληψης είναι.",
    "minimum_purchase_quantity": "Η ποσότητα αγοράς πρέπει να είναι μεγαλύτερη από την υπόλοιπη ποσότητα {}.",
    "minimum_quantity_sold": "Η ποσότητα πώλησης πρέπει να είναι μεγαλύτερη από την υπόλοιπη ποσότητα {}.",
    "minutes": "Λεπτά.",
    "mobile phone": "Αριθμός κινητού.",
    "money": "Ποσό.",
    "more": "Περισσότερα.",
    "more Or than": "Περισσότερα ή ίσα: αξία*ποσότητα*μέση τιμή πώλησης - αξία*ποσότητα*μέση τιμή αγοράς\n\nΚενή θέση: αξία*ποσότητα*μέση τιμή αγοράς - αξία*ποσότητα*μέση τιμή πώλησης.",
    "more-than-max": "Υπερβαίνει το μέγιστο όριο κλειδώματος.",
    "more11": "Περισσότερα.",
    "more_can_save": "Μέχρι να αποθηκευτεί.",
    "more_home": "Περισσότερα.",
    "msg_camera_framework_bug": "Παρακαλώ ενεργοποιήστε την άδεια χρήσης κάμερας.",
    "must_retain_one": "Πρέπει να διατηρηθεί τουλάχιστον ένα από τα κινητό, Google, ή email.",
    "my_income": "Το εισόδημά μου.",
    "my_prompt_001": "*Μετά την επιτυχία αλλαγής αριθμού κινητού, πρέπει να χρησιμοποιήσετε τον νέο αριθμό για να συνδεθείτε.",
    "my_prompt_002": "*Σημαντική σημείωση: Βεβαιωθείτε ότι χρησιμοποιείτε τον προσωπικό σας λογαριασμό με το πραγματικό σας όνομα. Οι ενεργοποιημένες μέθοδοι πληρωμής θα εμφανίζονται στους αγοραστές κατά την OTC συναλλαγή. Μπορείτε να ρυθμίσετε έως και 5, αλλά να ενεργοποιήσετε το πολύ 3.",
    "my_prompt_003": "*Μετά την επιτυχία αλλαγής email, πρέπει να χρησιμοποιήσετε το νέο email για να συνδεθείτε.",
    "my_vote": "Η ψήφος μου.",
    "name": "Όνομα.",
    "name_empty": "Το όνομα δεν μπορεί να είναι κενό.",
    "nationality": "Ιθαγένεια.",
    "need_me_record_video": "Πρέπει να ηχογραφήσω βίντεο.",
    "never_enter": "Ποτέ μην εισάγετε τον κωδικό συναλλαγών.",
    "new_address": "Νέα διεύθυνση.",
    "new_pass": "Νέος κωδικός.",
    "new_pass_empty": "Παρακαλώ εισάγετε νέο κωδικό.",
    "new_pass_minimum": "Ο κωδικός δεν μπορεί να είναι μικρότερος από 6 χαρακτήρες.",
    "new_report_statistics": "Νέα στατιστικά αναφοράς.",
    "newest_versions": "Η τρέχουσα έκδοση είναι η πιο πρόσφατη!",
    "next": "Επόμενο βήμα",
    "nick_name": "Ψευδώνυμο",
    "nickname_des_txt": "Το ψευδώνυμο υποστηρίζει μόνο κινέζικα και αγγλικά, μέχρι 15 χαρακτήρες",
    "nighttime": "Νύχτα",
    "ninety_days": "Τελευταίοι 90 ημέρες",
    "no-lock-detail": "Δεν υπάρχουν πληροφορίες κλειδώματος",
    "no_Senior_certification": "Παρακαλώ κάντε πρώτα ανώτερη πιστοποίηση",
    "no_data": "Δεν υπάρχουν διαθέσιμα δεδομένα",
    "no_flash": "Η τρέχουσα συσκευή δεν έχει φλας",
    "no_more_data": "Δεν υπάρχουν περισσότερα",
    "no_photo_taking": "Η συσκευή σας δεν υποστηρίζει φωτογράφιση",
    "no_price": "Η τιμή δεν μπορεί να είναι κενή",
    "no_quantity": "Παρακαλώ εισάγετε σωστή ποσότητα",
    "no_realname_auth": "Παρακαλώ κάντε πρώτα πιστοποίηση πραγματικού ονόματος",
    "no_use": "Μη ενεργοποιημένο",
    "none": "Κανένα",
    "not_bound": "Δεν έχει συνδεθεί",
    "not_frequency_partner": "Δεν είστε ακόμα συνεργάτης",
    "not_redemption": "Χωρίς δικαίωμα εξαργύρωσης",
    "not_started": "Δεν έχει ξεκινήσει",
    "not_to_issue": "Δεν έχει εκδοθεί",
    "not_trading": "Αυτή η κρυπτονομίσματα δεν μπορεί να διαπραγματευτεί",
    "notpass": "Η έγκριση δεν πέρασε",
    "null_address_tag": "Η ετικέτα διεύθυνσης δεν μπορεί να είναι κενή",
    "null_code": "Ο κωδικός επιβεβαίωσης δεν μπορεί να είναι κενός",
    "null_email": "Το email δεν μπορεί να είναι κενό",
    "null_password": "Ο κωδικός δεν μπορεί να είναι κενός",
    "null_phone": "Ο αριθμός κινητού δεν μπορεί να είναι κενός",
    "number": "Κομμάτια",
    "number_empty": "Η ποσότητα δεν μπορεί να είναι 0",
    "number_of_arrivals": "Ποσότητα παραλαβής",
    "number_of_issues": "Συνολική ποσότητα έκδοσης",
    "number_of_withdrawal": "Αριθμός αναλήψεων ανά ημέρα",
    "obtained": "Αποσύρθηκε",
    "occlusion": "Απόκρυψη προσώπου",
    "occupy": "Καταλαμβάνει",
    "official_community": "Επίσημη κοινότητα",
    "official_verification_channel": "Κανάλι επαλήθευσης",
    "old_pass": "Παλαιός κωδικός",
    "old_pass_empty": "Παρακαλώ εισάγετε τον παλιό κωδικό",
    "on_sale": "Προς πώληση",
    "online_service": "Online εξυπηρέτηση πελατών",
    "open XKD perpetual swap": "Ανοίξτε το BitMatrix μόνιμο συμβόλαιο",
    "open perpetual swap": "Ανοίξτε μόνιμο συμβόλαιο",
    "open_app": "Άνοιγμα εφαρμογής",
    "open_it": "Άνοιγμα",
    "open_successfully": "Άνοιγμα επιτυχές",
    "open_touchID": "Θέλετε να ενεργοποιήσετε το TouchID;",
    "opposite_side_uid": "Αντίπαλος",
    "option": "Επιλογή",
    "optional": "Προαιρετικό",
    "optional_area": "Προαιρετική περιοχή",
    "options": "Μέθοδοι επιλογής",
    "order": "Εντολή",
    "order time": "Κατά ημερομηνία εντολής",
    "order time1": "Ημερομηνία εντολής",
    "order_a_complaint": "Καταγγελία παραγγελίας",
    "order_details": "Λεπτομέρειες παραγγελίας",
    "order_form": "Φόρμα παραγγελίας",
    "order_history": "Ιστορικό παραγγελιών",
    "order_inquiry": "Ερώτηση παραγγελίας",
    "order_number": "Αριθμός παραγγελίας",
    "order_time": "Χρόνος παραγγελίας",
    "ordinary_partner": "Κανονικός συνεργάτης",
    "otc_account": "Λογαριασμός",
    "otc_confirm_Put_the_coin": "Επιβεβαιώστε ότι λάβατε τη μεταφορά του άλλου μέρους! (Προσοχή: Μην επιβεβαιώσετε πριν την παραλαβή)",
    "otc_confirm_the_payment": "Επιβεβαίωση πληρωμής",
    "otc_confirm_the_receipt": "Επιβεβαίωση παραλαβής",
    "otc_failure_0001": "Ο λογαριασμός έχει απενεργοποιηθεί, παρακαλώ επικοινωνήστε με την υποστήριξη",
    "otc_failure_0002": "Ο λογαριασμός δεν έχει περάσει την ανώτερη πιστοποίηση, δεν μπορείτε να κάνετε OTC συναλλαγές",
    "otc_failure_0003": "Ο κωδικός συναλλαγών είναι λανθασμένος",
    "otc_failure_0004": "Η ID παραγγελίας είναι μη έγκυρη",
    "otc_failure_0006": "Αποτυχία αλλαγής κατάστασης",
    "otc_failure_0009": "Αυτός ο τύπος εντολής δεν υπάρχει",
    "otc_failure_0010": "Αποτυχία ακύρωσης",
    "otc_failure_0011": "Αυτή η εντολή έχει ολοκληρωθεί ή ακυρωθεί",
    "otc_failure_0012": "Ο τρέχων χρήστης δεν είναι έμπορος OTC",
    "otc_failure_0013": "Αποτυχία αποδέσμευσης, αποτυχία ακύρωσης",
    "otc_failure_0015": "Δεν υπάρχει αυτός ο τύπος εντολής",
    "otc_failure_0016": "Η ποσότητα εντολής δεν μπορεί να είναι μικρότερη ή ίση με 0",
    "otc_failure_0017": "Η τιμή συναλλαγής δεν μπορεί να είναι μικρότερη ή ίση με 0",
    "otc_failure_0018": "Η ελάχιστη ποσότητα ανά συναλλαγή δεν μπορεί να είναι μικρότερη από 0",
    "otc_failure_0019": "Η μέγιστη ποσότητα ανά συναλλαγή δεν μπορεί να είναι μικρότερη από την ελάχιστη ποσότητα",
    "otc_failure_0020": "Δεν υποστηρίζεται αυτή η νομισματική μονάδα",
    "otc_failure_0021": "Αυτή η νομισματική μονάδα δεν είναι διαθέσιμη",
    "otc_failure_0022": "Λάθος ID νομισμάτων εμπόρου",
    "otc_failure_0023": "Η ελάχιστη ποσότητα ανά συναλλαγή δεν μπορεί να είναι μικρότερη από αυτή την ελάχιστη ποσότητα {}",
    "otc_failure_0024": "Η μέγιστη ποσότητα ανά συναλλαγή δεν μπορεί να είναι μεγαλύτερη από αυτή την μέγιστη ποσότητα {}",
    "otc_failure_0025": "Η ποσότητα εντολής έχει περισσότερες από {} δεκαδικές θέσεις",
    "otc_failure_0026": "Η συνολική ποσότητα εντολής δεν μπορεί να είναι μικρότερη από το ελάχιστο ποσό {}",
    "otc_failure_0027": "Η συνολική ποσότητα εντολής δεν μπορεί να είναι μεγαλύτερη από το όριο του χρήστη {}",
    "otc_failure_0028": "Η συνολική ποσότητα εντολής δεν μπορεί να είναι μεγαλύτερη από το όριο του εμπόρου {}",
    "otc_failure_0029": "Η τιμή δεν μπορεί να είναι μικρότερη από {}",
    "otc_failure_0030": "Η τιμή δεν μπορεί να είναι μεγαλύτερη από {}",
    "otc_failure_0031": "Ο τρέχων χρήστης δεν έχει συνδέσει λογαριασμό πληρωμής",
    "otc_failure_0032": "Αποτυχία κατάψυξης, αποτυχία εντολής",
    "otc_failure_0033": "Η μέγιστη ποσότητα παραγγελιών που μπορεί να επεξεργαστεί ταυτόχρονα δεν μπορεί να είναι μικρότερη από 0",
    "otc_failure_0034": "Ο αγοραστής πρέπει να έχει ολοκληρώσει τουλάχιστον 0 συναλλαγές",
    "otc_failure_0035": "Αυτός ο χρήστης έχει πιστοποιηθεί ως έμπορος ή είναι υπό εξέταση",
    "otc_failure_0036": "Ο αιτών δεν έχει επαρκείς συναλλαγές OTC",
    "otc_failure_0037": "Αποτυχία αποδέσμευσης, αποτυχία ακύρωσης",
    "otc_failure_0038": "Ο τρέχων λογαριασμός του αιτούντα {} έχει ανεπαρκές υπόλοιπο ｛｝",
    "otc_failure_0039": "Δεν υπάρχει τρέχουσα κρυπτονομίσματα",
    "otc_failure_0040": "Η τρέχουσα κρυπτονομίσματα δεν είναι διαθέσιμη",
    "otc_failure_0041": "Η υποβολή ήταν επιτυχής, παρακαλώ περιμένετε την έγκριση",
    "otc_failure_0042": "Λυπάμαι, αυτός ο χρήστης είναι υπό έλεγχο και δεν μπορεί να υποβάλει ξανά αίτηση",
    "otc_failure_0043": "Δεν υπάρχει αυτός ο τύπος συναλλαγής",
    "otc_failure_0044": "Απαγορεύεται η αυτοαγορά και η αυτοπώληση",
    "otc_failure_0046": "Η μέγιστη ποσότητα παραγγελιών που μπορεί να επεξεργαστεί αυτή η εντολή {} έχει φτάσει το όριο, παρακαλώ δοκιμάστε ξανά αργότερα",
    "otc_failure_0047": "Ο αγοραστής πρέπει να έχει ολοκληρώσει {} συναλλαγές",
    "otc_failure_0048": "Η ποσότητα αγοράς δεν μπορεί να είναι μικρότερη ή ίση με 0",
    "otc_failure_0049": "Η ποσότητα αγοράς δεν μπορεί να είναι μεγαλύτερη από την τρέχουσα διαθέσιμη ποσότητα",
    "otc_failure_0050": "Η ID παραγγελίας είναι λανθασμένη",
    "otc_failure_0051": "Η συνολική ποσότητα αγοράς δεν μπορεί να είναι μικρότερη από το ελάχιστο όριο {}",
    "otc_failure_0052": "Η ποσότητα αγοράς δεν μπορεί να είναι μεγαλύτερη από το μέγιστο όριο {}",
    "otc_failure_0053": "Η τρέχουσα εντολή δεν είναι πώληση",
    "otc_failure_0054": "Η τρέχουσα κατάσταση εντολής δεν είναι σωστή",
    "otc_failure_0055": "Η ποσότητα αγοράς δεν μπορεί να είναι μικρότερη από το ελάχιστο όριο {}",
    "otc_failure_0056": "Η ποσότητα αγοράς δεν μπορεί να είναι μεγαλύτερη από το μέγιστο όριο {}",
    "otc_failure_0057": "Δεν υπάρχει αυτή η κατάσταση",
    "otc_failure_0058": "Η αλλαγή του αριθμού καταγραφών εντολών δεν είναι σωστή",
    "otc_failure_0059": "Αποτυχία κατάψυξης, αποτυχία αγοράς",
    "otc_failure_0060": "Παρακαλώ συνδέστε τον λογαριασμό πληρωμής του εμπόρου στις ρυθμίσεις πληρωμής!",
    "otc_failure_0061": "Ο πωλητής πρέπει να έχει ολοκληρώσει {} συναλλαγές",
    "otc_failure_0062": "Η ποσότητα πώλησης δεν μπορεί να είναι μικρότερη ή ίση με 0",
    "otc_failure_0063": "Η ποσότητα πώλησης δεν μπορεί να είναι μεγαλύτερη από την τρέχουσα διαθέσιμη ποσότητα",
    "otc_failure_0064": "Η τρέχουσα εντολή δεν είναι αγορά",
    "otc_failure_0065": "Η συνολική ποσότητα πώλησης δεν μπορεί να είναι μικρότερη από το ελάχιστο όριο {}",
    "otc_failure_0066": "Η ποσότητα πώλησης δεν μπορεί να είναι μεγαλύτερη από το μέγιστο όριο {}",
    "otc_failure_0067": "Η ποσότητα πώλησης δεν μπορεί να είναι μικρότερη από το ελάχιστο όριο {}",
    "otc_failure_0068": "Η ποσότητα πώλησης δεν μπορεί να είναι μεγαλύτερη από το μέγιστο όριο {}",
    "otc_failure_0069": "Η ID λογαριασμού πληρωμής είναι λανθασμένη",
    "otc_failure_0070": "Αποτυχία επιβεβαίωσης πληρωμής",
    "otc_failure_0073": "Αποτυχία αλλαγής κατάστασης παραγγελίας",
    "otc_failure_0074": "Αποτυχία μεταφοράς νομισμάτων, αποτυχία επιβεβαίωσης παραλαβής",
    "otc_failure_0075": "Η μέθοδος πληρωμής δεν μπορεί να είναι κενή",
    "otc_failure_0076": "Αποτυχία αποστολής SMS {}",
    "otc_failure_0077": "Αυτή η κρυπτονομίσματα δεν υπάρχει",
    "otc_failure_0078": "Αυτή η νομισματική μονάδα δεν υπάρχει",
    "otc_failure_0079": "Αποτυχία αλλαγής κατάστασης παραγγελίας",
    "otc_failure_0080": "Αυτή η κρυπτονομίσματα έχει απενεργοποιηθεί",
    "otc_failure_0081": "Αυτή η νομισματική μονάδα έχει απενεργοποιηθεί",
    "otc_failure_0082": "Αυτή η παραγγελία δεν υπάρχει",
    "otc_failure_0083": "Η OTC δεν έχει ρυθμίσει τρέχουσα νομισματική μονάδα",
    "otc_failure_0084": "Η OTC δεν έχει ρυθμίσει τρέχουσα κρυπτονομίσματα",
    "otc_failure_0085": "Η διαθέσιμη ποσότητα εντολής είναι ανεπαρκής",
    "otc_failure_0090": "Αυτός ο λογαριασμός έχει απαγορευτεί από τις OTC συναλλαγές, παρακαλώ επικοινωνήστε με την υποστήριξη",
    "otc_failure_0091": "Οι κανονικοί χρήστες δεν μπορούν να κάνουν OTC εντολές, παρακαλώ ζητήστε να γίνετε έμπορος πρώτα",
    "otc_failure_0092": "Η παραλαβή του τρέχοντος παραγγελία δεν έχει λήξει, ο αγοραστής δεν μπορεί να υποβάλει καταγγελία",
    "otc_failure_0093": "Αυτή η παραγγελία έχει καταγγελθεί, παρακαλώ επικοινωνήστε με την υποστήριξη",
    "otc_failure_0094": "Δεν μπορείτε να εκτελέσετε αυτή τη λειτουργία σύμφωνα με τους κανονισμούς της περιοχής σας.",
    "otc_failure_0095": "Αυτή η παραγγελία δεν είναι διαθέσιμη προς συναλλαγή.",
    "otc_failure_0096": "Ο λογαριασμός του πωλητή έχει προβλήματα, δεν μπορεί να εκτελέσει αυτή τη λειτουργία.",
    "otc_failure_0097": "Έχετε υπερβεί τον αριθμό ακυρώσεων OTC για σήμερα, απαγορεύεται η συναλλαγή.",
    "otc_failure_0098": "Έχετε ανοιχτές παραγγελίες, παρακαλώ ολοκληρώστε τις πρώτα.",
    "otc_margin_certification": "Πιστοποίηση περιθωρίου",
    "otc_mine_account_paid": "Έχω πληρώσει",
    "otc_order_prompt_003": "Εάν ο αγοραστής δεν πληρώσει εντός της προθεσμίας και δεν πατήσει 'Επιβεβαίωση πληρωμής', η παραγγελία θα ακυρωθεί αυτόματα.",
    "otc_picture_no_null": "Παρακαλώ ανεβάστε τουλάχιστον μία εικόνα.",
    "otc_prompt_001": "1. Η διάρκεια πληρωμής της παραγγελίας είναι 30 λεπτά. Παρακαλώ πληρώστε και πατήστε (Έχω πληρώσει).\n2. Τα νομίσματα του πωλητή είναι κλειδωμένα από το σύστημα.",
    "otc_rate": "Ποσοστό",
    "otc_report_order": "Ολοκληρωμένες παραγγελίες",
    "otc_success_0001": "Ακύρωση επιτυχής",
    "otc_success_0002": "Πώληση επιτυχής",
    "otc_success_0003": "Αγορά επιτυχής",
    "otc_success_0004": "Επιβεβαίωση πληρωμής επιτυχής",
    "otc_success_0005": "Επιβεβαίωση παραλαβής επιτυχής, η παραγγελία ολοκληρώθηκε",
    "otc_success_0006": "Καταγγελία επιτυχής",
    "otc_waiting_for_confirmation": "Αναμένοντας επιβεβαίωση από τον πωλητή",
    "other_settings": "Άλλες ρυθμίσεις",
    "overtime_pay": "Προθεσμία πληρωμής πέρασε, η παραγγελία ακυρώθηκε",
    "param-error": "Σφάλμα παραμέτρου",
    "partner": "Συνεργάτης",
    "partner_commission": "Ο συνεργάτης μπορεί να κερδίσει προμήθεια από τους υφισταμένους του.",
    "partner_commission_fee": "Προμήθεια",
    "partner_current_level": "Τρέχων επίπεδο",
    "partner_get_all_subordinate": "Μπορεί να αποκτήσει όλους τους υφισταμένους.",
    "partner_management": "Διαχείριση συνεργατών",
    "partner_need_pay": "Πρέπει να πληρώσει",
    "partner_need_recommend": "Πρέπει να προτείνει",
    "partner_recommended": "Έχει προταθεί",
    "partner_start_and_end_time": "Χρονικό διάστημα",
    "partner_team": "Ομάδα",
    "partner_team_member": "Απαιτείται αριθμός μελών στην ομάδα",
    "partner_upgrade_immediately": "Αναβάθμιση τώρα",
    "pass_muster": "Εάν δεν πληροί τις προϋποθέσεις, πατήστε εδώ",
    "passed": "Πέρασε",
    "passport": "Διαβατήριο",
    "password_disagree": "Οι κωδικοί δεν ταιριάζουν",
    "password_is_different": "Οι κωδικοί είναι διαφορετικοί",
    "password_text": "Κωδικός",
    "password_wrong": "Ρυθμίστε έναν κωδικό 8-20 χαρακτήρων",
    "past_activities": "Προηγούμενες δραστηριότητες",
    "pause_recharge": "Αυτό το νόμισμα δεν υποστηρίζει κατάθεση.",
    "pause_withdraw": "Αυτό το νόμισμα δεν υποστηρίζει ανάληψη.",
    "pay_number": "Ποσότητα πληρωμής",
    "pay_wey": "Μέθοδος πληρωμής",
    "payee": "Λήπτης",
    "payfor_type": "Μέθοδος πληρωμής",
    "payment": "Πληρωμή",
    "payment_amount": "Ποσό πληρωμής",
    "payment_currency": "Νόμισμα πληρωμής",
    "payment_has_been": "(Έχω πληρώσει)",
    "payment_term": "Μέθοδος λήψης",
    "paypal_account": "Λογαριασμός PayPal",
    "pending_review": "Σε εκκρεμότητα ελέγχου",
    "people": "Άτομα",
    "period": "Περίοδος",
    "personal_detail": "Προσωπικά στοιχεία",
    "personal_purchase_tips": "Δεν μπορείτε να αγοράσετε την παραγγελία σας.",
    "phone": "Τηλέφωνο",
    "phone_code": "Κωδικός επιβεβαίωσης τηλεφώνου",
    "phone_register": "Εγγραφή μέσω τηλεφώνου",
    "phone_verification": "Επιβεβαίωση μέσω SMS",
    "phone_verify": "Επιβεβαίωση τηλεφώνου",
    "photo_album": "Φωτογραφικό άλμπουμ",
    "please_enter": "Παρακαλώ εισάγετε",
    "please_enter_abbreviation_or_letter": "Παρακαλώ εισάγετε περιεχόμενο αναζήτησης",
    "please_input_content": "Παρακαλώ εισάγετε περιεχόμενο",
    "please_pay": "Παρακαλώ πληρώστε",
    "please_verification": "Παρακαλώ επαληθεύστε",
    "pop_Date": "Ημερομηνία",
    "pop_reset": "Επαναφορά",
    "position at market price?": "Θέλετε να κλείσετε τη θέση στην τρέχουσα τιμή;",
    "post_only": "Μόνο Maker (Post only)",
    "price": "Τιμή",
    "price_cannot_be_less": "Η τιμή δεν μπορεί να είναι μικρότερη από {}",
    "price_cannot_be_more": "Η τιμή δεν μπορεί να είναι μεγαλύτερη από {}",
    "price_empty": "Η τιμή δεν μπορεί να είναι 0",
    "principal": "Κεφάλαιο",
    "promote_links": "Σύνδεσμοι προώθησης",
    "promotion_statistical": "Στατιστικά προώθησης",
    "prtner_invalid": "Μη έγκυρο",
    "publish_a_trade_order": "Δημοσίευση παραγγελίας",
    "purchase": "Αγορά",
    "push": "PUSH περιουσιακών στοιχείων",
    "push_maximum_volume": "Η ποσότητα PUSH υπερβαίνει το διαθέσιμο υπόλοιπο {}",
    "push_minimum_trading_volume": "Η ποσότητα PUSH είναι μικρότερη από την ελάχιστη ποσότητα {}",
    "quantity": "Ποσότητα",
    "quantity_deal": "Ποσότητα/Συναλλαγές",
    "quota": "Όριο",
    "range": "Εύρος",
    "real_income": "Πραγματικό εισόδημα",
    "real_name_authentication": "Πιστοποίηση πραγματικού ονόματος",
    "reason": "Λόγος",
    "rebate_amount": "Ποσό επιστροφής",
    "rebate_record": "Καταγραφή επιστροφής",
    "rebate_reward": "Ανταμοιβή επιστροφής",
    "receiving_set": "Ρυθμίσεις παραλαβής",
    "receiving_set_title": "Παρακαλώ ρυθμίστε τη μέθοδο παραλαβής πρώτα",
    "recently_deal1": "Πρόσφατες συναλλαγές",
    "recharge_notice": "Οδηγίες κατάθεσης\n1. Στείλτε τον απαιτούμενο αριθμό {} στο παρακάτω διεύθυνση μέσω {}. Μετά την αποστολή, περιμένετε {} επιβεβαιώσεις.\n2. Μπορείτε να καταθέσετε πολλές φορές στην ίδια διεύθυνση.\n3. Ελάχιστο ποσό κατάθεσης {}.",
    "recharge_source": "Πηγή κατάθεσης",
    "recharge_withdraw_failure_0001": "Δικτυακό σφάλμα",
    "recharge_withdraw_failure_0002": "Ο κωδικός συναλλαγής δεν μπορεί να είναι κενός",
    "recharge_withdraw_failure_0003": "Ο κωδικός επιβεβαίωσης δεν μπορεί να είναι κενός",
    "recharge_withdraw_failure_0004": "ID εμπόρου δεν μπορεί να είναι κενό",
    "recharge_withdraw_failure_0005": "ID χρήστη δεν μπορεί να είναι κενό",
    "recharge_withdraw_failure_0006": "Ο χρήστης δεν υπάρχει",
    "recharge_withdraw_failure_0007": "ID νομίσματος δεν μπορεί να είναι κενό",
    "recharge_withdraw_failure_0009": "Το νόμισμα δεν υπάρχει",
    "recharge_withdraw_failure_0010": "Ο κωδικός Google δεν μπορεί να είναι κενός",
    "recharge_withdraw_failure_0011": "Ο κωδικός συναλλαγής είναι λάθος",
    "recharge_withdraw_failure_0012": "Ο τύπος κατάθεσης δεν μπορεί να είναι κενός",
    "recharge_withdraw_failure_0013": "Η ποσότητα κατάθεσης δεν μπορεί να είναι κενή",
    "recharge_withdraw_failure_0014": "Η σημείωση κατάθεσης δεν μπορεί να είναι κενή",
    "recharge_withdraw_failure_0015": "ID κατάθεσης δεν μπορεί να είναι κενό",
    "recharge_withdraw_failure_0016": "Η κατάθεση δεν υπάρχει",
    "recharge_withdraw_failure_0017": "Μπορούν να διαγραφούν μόνο οι αναμενόμενες καταθέσεις",
    "recharge_withdraw_failure_0018": "Μπορούν να ελεγχθούν μόνο οι αναμενόμενες καταθέσεις",
    "recharge_withdraw_failure_0019": "Μπορούν να απελευθερωθούν μόνο οι εγκεκριμένες καταθέσεις",
    "recharge_withdraw_failure_0020": "Ο αριθμός έκδοσης δεν μπορεί να είναι κενός",
    "recharge_withdraw_failure_0021": "Σφάλμα παραμέτρου",
    "recharge_withdraw_failure_0022": "Τα δεδομένα έχουν ενημερωθεί",
    "recharge_withdraw_failure_0023": "ID δεν μπορεί να είναι κενό",
    "recharge_withdraw_failure_0024": "Μπορούν να ελεγχθούν μόνο οι καταθέσεις σε κατάσταση κλειδώματος",
    "recharge_withdraw_failure_0025": "Μπορούν να κλειδώσουν μόνο οι αναμενόμενες αναλήψεις",
    "recharge_withdraw_failure_0026": "Μπορούν να ακυρωθούν μόνο οι αναμενόμενες αναλήψεις ή κλειδώματα",
    "recharge_withdraw_failure_0027": "Η ποσότητα κλειδώματος δεν μπορεί να είναι κενή",
    "recharge_withdraw_failure_0028": "Ο λόγος κλειδώματος δεν μπορεί να είναι κενός",
    "recharge_withdraw_failure_0029": "Η σημείωση αποδέσμευσης δεν μπορεί να είναι κενή",
    "recharge_withdraw_failure_0030": "Η ποσότητα ανάληψης δεν μπορεί να είναι κενή",
    "recharge_withdraw_failure_0031": "Η διεύθυνση ανάληψης δεν μπορεί να είναι κενή",
    "recharge_withdraw_failure_0032": "Η προμήθεια δεν μπορεί να είναι κενή",
    "recharge_withdraw_failure_0033": "Σφάλμα προμήθειας",
    "recharge_withdraw_failure_0034": "Τα δεδομένα δεν υπάρχουν",
    "recharge_withdraw_failure_0035": "Αυτή η μονάδα δεν μπορεί να αποδεσμευτεί",
    "recharge_withdraw_failure_0036": "Η διεύθυνση ανάληψης είναι λάθος",
    "recharge_withdraw_failure_0041": "Αυτός ο λογαριασμός έχει απαγορευτεί από αναλήψεις, παρακαλώ επικοινωνήστε με την υποστήριξη",
    "recharge_withdraw_failure_0042": "Ο αριθμός αναλήψεων έχει φτάσει το όριο της ημέρας",
    "recharge_withdraw_failure_0043": "Η συνολική ποσότητα αναλήψεων υπερβαίνει το ημερήσιο όριο",
    "recharge_withdraw_failure_0044": "Σφάλμα στην απόκτηση πληροφοριών VIP",
    "recharge_withdraw_failure_0045": "Σφάλμα στην απόκτηση πληροφοριών νομισμάτων εμπόρου",
    "recharge_withdraw_failure_0046": "Απαγορεύονται οι αναλήψεις για 24 ώρες μετά την επαναφορά του κωδικού!",
    "recharge_withdraw_failure_0048": "Σφάλμα στην απόκτηση μεγίστου ποσού ανάληψης",
    "recharge_withdraw_failure_0049": "Σφάλμα στην απόκτηση ελάχιστου ποσού ανάληψης",
    "recharge_withdraw_failure_0050": "Η εισαγόμενη ποσότητα πρέπει να είναι μικρότερη από το μέγιστο όριο",
    "recharge_withdraw_failure_0051": "Η εισαγόμενη ποσότητα πρέπει να είναι μεγαλύτερη από το ελάχιστο όριο",
    "recharge_withdraw_msg_status_0001": "Αναμένοντας ανάληψη",
    "recharge_withdraw_msg_status_0002": "Αποστολή σε εξέλιξη",
   "recharge_withdraw_msg_status_0003": "Ανάληψη επιτυχής",
    "recharge_withdraw_msg_status_0004": "Ακύρωση",
    "recharge_withdraw_msg_status_0005": "Σε επιβεβαίωση",
    "recharge_withdraw_msg_status_0006": "Κατάθεση επιτυχής",
    "recharge_withdraw_msg_status_0007": "Εξετάστηκε",
    "recharge_withdraw_msg_type_0001": "Κατάθεση",
    "recharge_withdraw_msg_type_0002": "Ανάληψη",
    "recharge_withdraw_msg_withdraw_type_0001": "Μεταφορά εντός πλατφόρμας",
    "recharge_withdraw_msg_withdraw_type_0002": "Εξωτερική ανάληψη",
    "record": "Καταγραφή",
    "record_the_video": "Κάντε κλικ για εγγραφή βίντεο",
    "recording": "Εγγραφή ξανά",
    "redeem_now": "Ανταλλάξτε τώρα",
    "redeemed": "Έχει ανταλλαχθεί",
    "redeeming": "Ανταλλαγή σε εξέλιξη",
    "redemption_amount": "Ποσότητα ανταλλαγής",
    "redemption_time": "Χρόνος ανταλλαγής",
    "referrer": "Συστήσας",
    "register": "Εγγραφή",
    "registered_successfully": "Εγγραφή επιτυχής",
    "registration_award_details": "Λεπτομέρειες επιβράβευσης εγγραφής",
    "registration_incentives": "Επιβράβευση εγγραφής",
    "registration_time": "Χρόνος εγγραφής",
    "regular": "Κανονικό",
    "regular_return": "Κανονική μηνιαία επιστροφή",
    "release": "Δημοσίευση",
    "release_operation": "Δημοσιεύστε παραγγελία, κάντε κλικ εδώ!",
    "release_the_order": "Δημοσίευση παραγγελίας",
    "release_time": "Χρόνος δημοσίευσης",
    "remark": "Σημείωση",
    "remember_state": "Να θυμάστε την κατάσταση σύνδεσης",
    "repeal": "Αναίρεση",
    "reset": "Επαναφορά",
    "reset_login_password": "Αλλαγή κωδικού σύνδεσης",
    "reset_pass": "Επαναφορά κωδικού",
    "residue": "Υπόλοιπο",
    "retrieve_the_deposit": "Ανάκτηση κατάθεσης",
    "return_amount": "Ποσότητα επιστροφής",
    "return_method": "Μέθοδος επιστροφής",
    "return_the_principal": "Επιστροφή κεφαλαίου",
    "revenue_details": "Λεπτομέρειες εσόδων",
    "revocation_all": "Αναίρεση όλων",
    "reward_amount": "Ποσότητα επιβράβευσης",
    "reward_locked": "Κλειδώθηκε η επιβράβευση",
    "reward_status": "Κατάσταση επιβράβευσης",
    "reward_type": "Τύπος επιβράβευσης",
    "rewards_record": "Καταγραφή επιβραβεύσεων",
    "right_now_register": "Εγγραφή νέου χρήστη",
    "roll_out": "Μεταφορά",
    "save": "Αποθήκευση",
    "save_coin": "Κατάθεση",
    "save_coin_count": "Ποσότητα κατάθεσης",
    "save_coin_days": "Ημέρες κατάθεσης",
    "save_coin_duration": "Διάρκεια κατάθεσης",
    "save_currency": "Κατάθεση νομισμάτων",
    "save_money_amount": "Συνολική ποσότητα κατάθεσης",
    "save_successfully": "Αποθήκευση επιτυχής",
    "save_the_image": "Κατεβάστε την εικόνα",
    "scan_QR_code": "Μην σαρώσετε QR κωδικούς από άγνωστες πηγές",
    "scan_result": "Αποτέλεσμα σάρωσης",
    "search": "Αναζητήστε",
    "search_coin_placeholder": "Αναζητήστε το νόμισμα που σας ενδιαφέρει",
    "search_keyword": "Λέξη-κλειδί αναζήτησης",
    "search_name": "Όνομα αναζήτησης",
    "search_result": "Αποτελέσματα αναζήτησης",
    "search_trade_pair_placeholder": "Αναζητήστε το ζευγάρι συναλλαγών που σας ενδιαφέρει",
    "security_center": "Κέντρο ασφάλειας",
    "security_level": "Επίπεδο ασφάλειας",
    "select_date_of_borth": "Επιλέξτε ημερομηνία γέννησης",
    "select_delete_warn": "Επιλέξτε την προειδοποίηση που θέλετε να διαγράψετε",
    "sell": "Πώληση",
    "sell_1": "Πώληση",
    "sell_order": "Παραγγελία πώλησης",
    "sell_quantity": "Ποσότητα πώλησης",
    "sell_rate": "Προμήθεια πώλησης",
    "seller": "Πωλητής",
    "seller_phone": "Τηλέφωνο πωλητή",
    "send": "Αποστολή",
    "senior_certification": "Ανώτερη πιστοποίηση",
    "senior_certification1": "Ανώτερη πιστοποίηση",
    "service": "Εξυπηρέτηση πελατών QQ:",
    "service_charge": "Προμήθεια",
    "session_timeout": "Η σύνδεση έχει λήξει, παρακαλώ συνδεθείτε ξανά",
    "set_Password": "Αλλαγή",
    "set_gesture_pass": "Ρυθμίστε τον κωδικό χειρονομίας",
    "set_login_password": "Ρυθμίστε τον κωδικό σύνδεσης",
    "setting": "Ρυθμίσεις",
    "setting_alipay": "Ρύθμιση Alipay",
    "setting_bank_card": "Ρύθμιση τραπεζικής κάρτας",
    "setting_message_warn_tip": "*Ανοίξτε για να λαμβάνετε προειδοποιήσεις, κλείστε για να μην λαμβάνετε.",
    "setting_paypal": "Ρύθμιση PayPal",
    "setting_paypassword": "Ρυθμίστε πρώτα τον κωδικό συναλλαγών",
    "setting_wechat": "Ρύθμιση WeChat",
    "setting_western_union": "Ρύθμιση Western Union",
    "share_invitation_link": "Μοιραστείτε τον σύνδεσμο πρόσκλησης",
    "share_link": "Μοιραστείτε τον σύνδεσμο με φίλους σας",
    "share_to_do": "Μοιραστείτε στο",
    "shift_to": "Μεταφορά σε",
    "single_least": "Ελάχιστο όριο ανάληψης μικρότερο από {}",
    "single_minimum": "Ελάχιστη ποσότητα",
    "single_more": "Ελάχιστο όριο ανάληψης μεγαλύτερο από {}",
    "skip": "Παράκαμψη",
    "small_exchange": "Μικρή ανταλλαγή",
    "small_exchange_tip": "*Σημαντική σημείωση: Μπορείτε να ανταλλάξετε μία φορά εντός 24 ωρών με αξία κάτω από {}{}. Τα νομίσματα που έχουν αφαιρεθεί δεν είναι διαθέσιμα προς ανταλλαγή. Η προσωρινή προμήθεια είναι {}.",
    "small_exchange_tip-1": "*Σημαντική σημείωση: Η τιμή της αγοράς αλλάζει συνεχώς. Η πραγματική τιμή ισχύει. Τα νομίσματα που έχουν αφαιρεθεί δεν είναι διαθέσιμα προς ανταλλαγή. Η προσωρινή προμήθεια είναι {0}%.",
    "small_exchange_valuation": "Αξιολόγηση",
    "small_exchange_valuation-1": "Αξιολόγηση BTC",
    "smallest_unit_price": "Η τιμή PUSH είναι μικρότερη από την ελάχιστη τιμή {}.",
    "so adjusted to": "θα προσαρμοστεί σε ακέραιο πολλαπλάσιο του",
    "sold_out": "Έχει αφαιρεθεί",
    "start time": "Χρόνος έναρξης",
    "start_date": "Ημερομηνία έναρξης",
    "start_time": "Ώρα έναρξης",
    "statistics_report": "Στατιστική αναφορά",
    "status": "Κατάσταση",
    "submit": "Υποβολή βίντεο",
    "submitBtn": "Υποβολή",
    "succeed": "Επιτυχία",
    "successful_invitation": "Επιτυχής πρόσκληση",
    "successfully_received": "Λήψη επιτυχής",
    "successfully_set": "Ρύθμιση επιτυχής",
    "super_partner": "Υπερσύγχρονος συνεργάτης",
    "switch_account": "Αλλαγή λογαριασμού",
    "system_top_up": "Σύστημα κατάθεσης",
    "system_unlock": "Σύστημα ξεκλειδώματος",
    "tab_assets": "Περιουσιακά στοιχεία",
    "tab_home": "Αρχική σελίδα",
    "tab_markets": "Αγορές",
    "tab_swaps": "Συμβόλαια",
    "tab_trade": "Συναλλαγές",
    "taking_pictures": "Λήψη φωτογραφιών",
    "telegraph": "Ομάδα Telegram:",
    "temporary_not_online_contact_service": "Προς το παρόν δεν είναι διαθέσιμος, παρακαλώ επικοινωνήστε με την εξυπηρέτηση πελατών",
    "the_day": "Σήμερα",
    "the_event_is_over": "Η εκδήλωση έχει τελειώσει",
    "the_latest_price": "Τελευταία τιμή",
    "the_password_is_correct": "Ο κωδικός είναι σωστός",
    "theme": "Θέμα",
    "this_month": "Αυτόν τον μήνα",
    "this_week": "Αυτή την εβδομάδα",
    "ticket": "Εισιτήριο",
    "time_of_payment": "Χρόνος πληρωμής",
    "time_the_input": "{0} ώρες χωρίς εισαγωγή κωδικού συναλλαγής",
    "timeout": "Έχει λήξει",
    "timeout_affirm": "Λήξη χωρίς επιβεβαίωση",
    "timeout_deal": "Αυτόματη ολοκλήρωση λόγω λήξης",
    "timeout_to_cancel": "Λήξη πληρωμής, αυτόματη ακύρωση",
    "to_confirm": "Για επιβεβαίωση",
    "total_buyer": "Συνολικός αριθμός πωλητών",
    "total_count": "Συνολική ποσότητα",
    "total_exchange": "Συνολικός όγκος",
    "total_lock_amount": "Συνολική ποσότητα κλειδώματος",
    "total_money": "Συνολικό ποσό",
    "total_return": "Προβλεπόμενα συνολικά έσοδα",
    "trade_fee": "Προμήθεια συναλλαγής",
    "trade_type": "Τύπος συναλλαγής",
    "trading_currency": "Νόμισμα συναλλαγής",
    "trading_fiat_currency": "Νόμισμα fiat",
    "trading_instructions": "Οδηγίες συναλλαγής",
    "trading_on": "Συναλλαγές σε",
    "trading_pair_abbreviation": "Εισάγετε τη συντομογραφία του ζεύγους",
    "trading_this_month": "Συναλλαγές αυτού του μήνα",
    "trading_volume": "Όγκος συναλλαγών",
    "transaction_description": "Περιγραφή συναλλαγής",
    "transaction_password": "Κωδικός συναλλαγής",
    "transaction_price": "Τιμή συναλλαγής",
    "transfer_number": "Ποσότητα μεταφοράς",
    "try_it_now": "Δοκιμάστε το τώρα",
    "type": "Τύπος",
    "unable_to_access_album": "Η συσκευή δεν υποστηρίζει πρόσβαση στη βιβλιοθήκη",
    "unaccalimed": "Μη αξιωμένο",
    "unavailable": "Μη διαθέσιμο",
    "under_review": "Σε εξέταση",
    "univalence": "Τιμή μονάδας",
    "unlock_details": "Λεπτομέρειες ξεκλειδώματος",
    "unlock_mode": "Τρόπος ξεκλειδώματος",
    "unlock_period": "Περίοδος ξεκλειδώματος",
    "unlock_plan": "Σχέδιο ξεκλειδώματος",
    "unlock_plan_unlock": "Ξεκλείδωμα",
    "unlock_record": "Καταγραφή ξεκλειδώματος",
    "unlock_time": "Χρόνος ξεκλειδώματος",
    "unlocked": "Έχει ξεκλειδωθεί",
    "unredeemed": "Μη ανταλλαχθέν",
    "unverified": "Μη επιβεβαιωμένο",
    "up_to_three": "Μέχρι τρεις",
    "upgrade": "Αναβάθμιση",
    "upload_again": "Αναβάθμιση ξανά",
    "upload_front": "Ανεβάστε την μπροστινή πλευρά ταυτότητας",
    "upload_identity_card": "Ανεβάστε την ταυτότητά σας",
    "upload_image_success": "Η εικόνα ανέβηκε με επιτυχία",
    "upload_information_as_required": "Πρέπει να ανεβάσετε τα στοιχεία σύμφωνα με τις παραπάνω απαιτήσεις.",
    "upload_passport": "Ανεβάστε το διαβατήριό σας",
    "upload_passport_info_picture": "Ανεβάστε φωτογραφία σελίδας πληροφοριών διαβατηρίου",
    "upload_reverse": "Ανεβάστε την πίσω πλευρά ταυτότητας",
    "ups_and_downs": "Μέγιστη πτώση/άνοδος της ημέρας",
    "ups_downs_habit": "Συνήθεια ανόδου/πτώσης",
    "usable": "Χρήσιμο",
    "used": "Χρησιμοποιημένο",
    "user-bind-failure": "Αποτυχία σύνδεσης προσκαλεσμένου",
    "user-bind-success": "Επιτυχία σύνδεσης προσκαλεσμένου",
    "user-collection-error": "Αποτυχία",
    "user-country-code-not-empty": "Ο διεθνής κωδικός δεν μπορεί να είναι κενός",
    "user-disable-trade": "Η τρέχουσα δυνατότητα συναλλαγών έχει απενεργοποιηθεί, παρακαλώ επικοινωνήστε με την υποστήριξη!",
    "user-down-notbind": "Αυτός ο χρήστης δεν μπορεί να συνδεθεί με κατώτερο χρήστη",
    "user-email-fail-often": "Η αποστολή email είναι πολύ συχνή, παρακαλώ επαναλάβετε μετά από 120 δευτερόλεπτα",
    "user-email-not-empty": "Η διεύθυνση email δεν μπορεί να είναι κενή",
    "user-email-verification-code-out": "Ο κωδικός επιβεβαίωσης email έχει λήξει, παρακαλώ στείλτε ξανά",
    "user-fail-advanced-auth": "Αποτυχία ανώτερης πιστοποίησης",
    "user-fail-advanced-authed": "Ο χρήστης έχει ήδη πιστοποιηθεί ανώτερα",
    "user-fail-assetnotenough-error": "Μη επαρκή περιουσιακά στοιχεία",
    "user-fail-bank-count-error": "Μπορείτε να έχετε μέγιστο τρεις μεθόδους πληρωμής",
    "user-fail-bank-inotc": "Η μέθοδος πληρωμής έχει παραγγελία στο OTC, δεν μπορεί να απενεργοποιηθεί",
    "user-fail-cardno-repeat-error": "Ο αριθμός ταυτότητας έχει ήδη χρησιμοποιηθεί",
    "user-fail-email-code": "Λάθος κωδικός email",
    "user-fail-google-binded": "Η Google έχει συνδεθεί",
    "user-fail-google-code": "Λάθος κωδικός Google",
    "user-fail-inviter-notexist": "Ο προσκαλών δεν υπάρχει",
    "user-fail-login-disable": "Η τρέχουσα δυνατότητα σύνδεσης έχει απενεργοποιηθεί, παρακαλώ επικοινωνήστε με την υποστήριξη!",
    "user-fail-login-lock": "Ο λογαριασμός έχει κλειδωθεί",
    "user-fail-login-password": "Λάθος όνομα χρήστη ή κωδικός, απομένουν {} προσπάθειες",
    "user-fail-mail-binded": "Η διεύθυνση email έχει ήδη συνδεθεί",
    "user-fail-not-realname": "Παρακαλώ πιστοποιηθείτε πρώτα",
    "user-fail-oldpassword-error": "Λάθος αρχικός κωδικός",
    "user-fail-partner-not-found": "Ο έμπορος δεν υπάρχει",
    "user-fail-partnernotfound": "Ο έμπορος δεν υπάρχει",
    "user-fail-password": "Λάθος όνομα χρήστη ή κωδικός",
    "user-fail-password-blank": "Ο κωδικός δεν μπορεί να είναι κενός",
    "user-fail-password-blank1": "Ο κωδικός επιβεβαίωσης δεν μπορεί να είναι κενός",
    "user-fail-password-repeat": "Ο νέος κωδικός είναι ίδιος με τον παλιό, παρακαλώ ρυθμίστε ξανά!",
    "user-fail-password-thin": "Η ισχύς του κωδικού είναι πολύ αδύναμη",
    "user-fail-passwword-eq-paypassword": "Ο κωδικός συναλλαγής είναι ίδιος με τον κωδικό σύνδεσης, παρακαλώ ρυθμίστε ξανά",
    "user-fail-paypassword-error": "Λάθος κωδικός πληρωμής",
    "user-fail-paypassword-notset": "Παρακαλώ ρυθμίστε πρώτα τον κωδικό πληρωμής",
    "user-fail-paypassword-set": "Ο κωδικός πληρωμής έχει ρυθμιστεί",
    "user-fail-phone-binded": "Ο αριθμός τηλεφώνου έχει ήδη συνδεθεί",
    "user-fail-realname-auth": "Αποτυχία πιστοποίησης πραγματικού ονόματος",
    "user-fail-realname-authed": "Ο χρήστης έχει ήδη πιστοποιηθεί με πραγματικό όνομα",
    "user-fail-realname-notset": "Παρακαλώ πιστοποιηθείτε πρώτα",
    "user-fail-reg": "Αποτυχία εγγραφής, σφάλμα διακομιστή",
    "user-fail-reg-mail": "Λάθος μορφή email",
    "user-fail-reg-mail-exist": "Η διεύθυνση email έχει ήδη εγγραφεί",
    "user-fail-reg-phone-exist": "Ο αριθμός τηλεφώνου έχει ήδη εγγραφεί",
    "user-fail-securitycount": "Πρέπει να διατηρήσετε τουλάχιστον μία μέθοδο επιβεβαίωσης",
    "user-fail-sms-code": "Λάθος κωδικός SMS",
    "user-fail-sms-code-new": "Λάθος κωδικός SMS νέου αριθμού",
    "user-fail-sms-code-old": "Λάθος κωδικός SMS παλιού αριθμού",
    "user-fail-usernameisblank": "Το όνομα χρήστη δεν μπορεί να είναι κενό",
    "user-id-or-nationCode—is-null": "Ο ID χρήστη ή ο διεθνής κωδικός είναι κενός",
    "user-login-success": "Επιτυχής σύνδεση",
    "user-mail-fail": "Αποτυχία αποστολής email",
    "user-mail-success": "Επιτυχής αποστολή email",
    "user-not-exist": "Αυτός ο χρήστης δεν υπάρχει",
    "user-notopen-success": "Προς το παρόν δεν είναι διαθέσιμος",
    "user-phone-not-empty": "Ο αριθμός τηλεφώνου δεν μπορεί να είναι κενός",
    "user-sms-fail": "Αποτυχία αποστολής, σφάλμα διακομιστή",
    "user-sms-fail-often": "Η αποστολή SMS είναι πολύ συχνή",
    "user-sms-success": "Επιτυχής αποστολή SMS",
    "user-sms-verification-code-out": "Ο κωδικός επιβεβαίωσης έχει λήξει, παρακαλώ στείλτε ξανά",
    "user-success-advanced-auth": "Επιτυχής ανώτερη πιστοποίηση",
    "user-success-realname-auth": "Επιτυχής πιστοποίηση πραγματικού ονόματος",
    "user-validate-code-fail": "Λάθος κωδικός επιβεβαίωσης",
    "user-validate-fail": "Αποτυχία επαλήθευσης",
    "user-vip-common-fail": "Αποτυχία αγοράς VIP",
    "user_agreement": "Παρακαλώ επιλέξτε να συμφωνήσετε με τη συμφωνία υπηρεσιών χρήστη",
    "user_collection_cencel_success": "Έχει ακυρωθεί",
    "user_collection_insert_success": "Έχει προστεθεί στα αγαπημένα",
    "user_pay_disable_state": "Η δυνατότητα συναλλαγών έχει απενεργοποιηθεί λόγω υπέρβασης κωδικών, παρακαλώ δοκιμάστε ξανά μετά από 2 ώρες",
    "user_pay_is_error": "Λάθος κωδικός συναλλαγής, μπορείτε να προσπαθήσετε {} φορές ακόμη, υπέρβαση θα οδηγήσει σε πάγωμα για 2 ώρες",
    "user_pay_password_not_setting": "Ο χρήστης δεν έχει ορίσει κωδικό συναλλαγής, παρακαλώ ρυθμίστε πρώτα",
    "user_protocol": "Συμφωνία χρήστη",
    "user_service_agreement": "Συμφωνία υπηρεσιών χρήστη",
    "user_vip_has_expire": "Αυτός ο λογαριασμός VIP έχει λήξει και δεν μπορεί να αναβαθμιστεί!",
    "user_vip_update_failure": "Αυτός ο λογαριασμός δεν υποστηρίζει ανανέωση ή αναβάθμιση, παρακαλώ επικοινωνήστε με την υποστήριξη!",
    "valid_identity_card": "Παρακαλώ εισάγετε σωστό αριθμό ταυτότητας",
    "valuation": "Αξιολόγηση κατάθεσης",
    "verification_code_error": "Λάθος κωδικός επιβεβαίωσης",
    "verification_failed_001": "Αποτυχία επιβεβαίωσης",
    "verification_finish": "Η επιβεβαίωση ολοκληρώθηκε",
    "version_updating": "Ενημέρωση έκδοσης",
    "versions": "Εκδόσεις",
    "vertical_version": "Κατακόρυφη έκδοση",
    "view_activity": "Δείτε την εκδήλωση",
    "view_lock": "Δείτε το κλείδωμα",
    "volamount": "Ο αγοραστής πρέπει να έχει ολοκληρώσει {} συναλλαγές",
    "volamount_sell": "Ο πωλητής πρέπει να έχει ολοκληρώσει {} συναλλαγές",
    "vote": "Ψηφοφορία",
    "vote_add_coin": "Ψηφίστε για προσθήκη νομισμάτων",
    "vote_return": "Επιστροφή",
    "voting_coin": "Νόμισμα ψηφοφορίας",
    "voting_number": "Αριθμός ψηφοφορίας",
    "voting_time": "Χρόνος ψηφοφορίας",
    "wait_buyer_payment": "Αναμονή πληρωμής από τον αγοραστή",
    "wait_for_payment": "Αναμονή πληρωμής",
    "waiting_for_receiving": "Αναμονή επιβεβαίωσης πληρωμής από τον πωλητή",
    "warm_prompt": "Θερμή προειδοποίηση",
    "warning_setTitle": "Ρυθμίσεις προειδοποίησης",
    "wechat_account": "Λογαριασμός WeChat",
    "wechat_upload": "*Μέθοδος ανάρτησης QR WeChat: Ανοίξτε την αρχική σελίδα του WeChat > Εγώ > Πορτοφόλι > Πληρωμή > Αποθήκευση εικόνας, ανεβάστε τον κωδικό πληρωμής που βρίσκεται στη βιβλιοθήκη φωτογραφιών.",
    "week": "Εβδομάδα",
    "welcome": "Καλώς ήρθατε στο BitMatrix",
    "welcome_to": "Καλώς ήρθατε",
    "winning_record": "Καταγραφή νικών",
    "withdrawal_amount": "Ημερήσιο όριο ανάληψης",
    "withdrawal_charge": "Προμήθεια ανάληψης",
    "xilian": "Δυτική Ένωση",
    "yesterday_income": "Κέρδη χθες",
    "you_have_selected": "Έχετε επιλέξει",
    "Your closing number": "Ο αριθμός κλεισίματος είναι μικρότερος από τον ελάχιστο όγκο!",
    "Your have pending Cross orders": "Υπάρχουν εκκρεμείς εντολές, παρακαλώ ακυρώστε τις πρώτες.",
    "Your have pending isolated orders": "Υπάρχουν εκκρεμείς εντολές, παρακαλώ ακυρώστε τις πρώτες.",
    "Home page apps": "Εφαρμογές Αρχικής Σελίδας",
    "Home_Edit": "Επεξεργασία",
    "recently_deal01": "Συναλλαγές",
    "depth01": "Βάθος",
    "depth02": "Διάγραμμα βάθους",
    "discount_currency_introduction01": "Εισαγωγή νομισμάτων",
    "Info001": "Πληροφορίες",
    "Avg cost": "Μέση τιμή",
    "Long position": "Μακριά θέση",
    "Short position": "Σύντομη θέση",
    "Hold on": "Περίμενε",
    "Done": "Ολοκληρώθηκε",
    "new_version": "Νέα έκδοση διαθέσιμη",
    "Announcement": "Ανακοίνωση",
    "View details": "Δείτε λεπτομέρειες",
    "Option Assets": "Περιουσιακά στοιχεία επιλογών",
    "Option Account": "Λογαριασμός επιλογών",
    "Option": "Επιλογή",
    "1M": "1 λεπτό",
    "5M": "5 λεπτά",
    "15M": "15 λεπτά",
    "30M": "30 λεπτά",
    "1H": "1 ώρα",
    "Delivery Time": "Χρόνος παράδοσης",
    "Call": "Αγορά",
    "Put": "Πώληση",
    "Draw": "Ισοπαλία",
    "Draw2": "Ισοπαλία",
    "Call1": "Αγορά",
    "Put1": "Πώληση",
    "Draw1": "Ισοπαλία",
    "Buy-2": "Αγορά",
    "Rate of return": "Απόδοση",
    "Change": "Αλλαγή",
    "Buy Option": "Αγορά επιλογής",
    "Awaiting": "Αναμονή παράδοσης",
    "Delivery": "Η παράδοση μου",
    "Checkout": "Ιστορικό παράδοσης",
    "Front": "Τρέχουσα",
    "Back": "Επόμενη",
    "Coming soon": "Σύντομα διαθέσιμο",
    "delivery settlement": "Σε εκκαθάριση",
    "Ongoing": "Σε αγορά",
    "Pending purchase": "Σε αναμονή αγοράς",
    "Billing Amount": "Ποσό εκκαθάρισης",
    "Amount": "Ποσότητα αγοράς",
    "Delivery result": "Αποτέλεσμα παράδοσης",
    "Delivery time": "Χρόνος παράδοσης",
    "Up": "Άνοδος",
    "Down": "Πτώση",
    "lookUp": "Αγορά",
    "lookDown": "Πώληση",
    "Delivery details": "Λεπτομέρειες παράδοσης",
    "Index Composition": "Σύνθεση δείκτη",
    "Exchange": "Χρηματιστήριο",
    "Change2": "Αλλαγή",
    "Weights": "Βάρη",
    "Order number": "Αριθμός παραγγελίας",
    "Option Session": "Συνεδρία επιλογών",
    "Buy Time": "Χρόνος αγοράς",
    "Opening time": "Χρόνος ανοίγματος",
    "Bet Type": "Τύπος στοιχήματος",
    "Delivery Price": "Τιμή παράδοσης",
    "Settlement Currency": "Νόμισμα εκκαθάρισης",
    "K-line": "Γραμμή K",
    "UpSelect": "Επιλογή ανόδου",
    "DownSelect": "Επιλογή πτώσης",
    "Front has ended, please place another order": "Η περίοδος έχει λήξει, παρακαλώ κάντε νέα παραγγελία.",
    "Successfully ordered": "Παραγγελία επιτυχής",
    "Insufficient balance": "Ανεπαρκές υπόλοιπο",
    "Quantity purchased must be greater than the minimum limit": "Η ποσότητα αγοράς πρέπει να είναι μεγαλύτερη από το ελάχιστο όριο.",
    "Quantity purchased must be less than the maximum limit": "Η ποσότητα αγοράς πρέπει να είναι μικρότερη από το μέγιστο όριο.",
    "The current time is not in the purchase time range": "Η τρέχουσα ώρα δεν είναι εντός της περιόδου αγοράς.",
    "expected": "Αναμενόμενα κέρδη",
    "To_a_game": "Ξαναπαίξτε",
    "Settlement Result": "Αποτέλεσμα εκκαθάρισης",
    "Delivery-1": "Παράδοση",
    "Ups and downs": "Ανοδικές και καθοδικές κινήσεις",
    "duration": "Διάρκεια",
    "Option funds daybook": "Ημερολόγιο κεφαλαίων επιλογών",
    "Open innovative options trading": "Ανοίξτε καινοτόμες συναλλαγές επιλογών",
    "Confirm Open": "Επιβεβαίωση ανοίγματος",
    "Option Agree Content": "Προτού ανοίξετε επιλογές, διαβάστε προσεκτικά τη συμφωνία.",
    "Option Agree Content footer": "Συμφωνία χρήσης επιλογών",
    "Option Agree Content header": "Διαβάστε προσεκτικά πριν ανοίξετε επιλογές",
    "Service upgrade, please try again later!": "Αναβάθμιση υπηρεσίας, δοκιμάστε ξανά αργότερα!",
    "month": "Μήνας",
    "Invitation": "Πρόσκληση",
    "Markets": "Αγορές",
    "Swaps": "Συμβόλαια",
    "Trade": "Συναλλαγή",
    "Trade-1": "Προχωρήστε στη συναλλαγή",
    "Assets": "Περιουσιακά στοιχεία",
    "Buy": "Αγορά νομισμάτων",
    "Help": "Βοήθεια",
    "Service": "Εξυπηρέτηση",
    "Activity": "Δραστηριότητα",
    "Communtiy": "Κοινότητα",
    "No search results": "Χωρίς αποτελέσματα αναζήτησης",
    "The option account asset is 0, please transfer from the wallet account to the option account first.": "Ο λογαριασμός επιλογών έχει 0 περιουσιακά στοιχεία, παρακαλώ μεταφέρετε από τον λογαριασμό πορτοφολιού.",
    "Perpetual account assets are 0, please transfer from wallet account to perpetual account first.": "Ο λογαριασμός αέναης έχει 0 περιουσιακά στοιχεία, παρακαλώ μεταφέρετε από τον λογαριασμό πορτοφολιού.",
    "Financing": "Χρηματοδότηση",
    "Load": "Φόρτωση",
    "Replay001": "Επαναλαμβάνω",
    "Constellation Program": "Παρουσίαση - Πρόγραμμα Ζώδια",
    "Constellation Program2": "Παρουσίαση - Χρηματοδότηση Ζώδια",
    "rate rankings": "Κατάταξη ποσοστού νίκης",
    "rate rankings2": "Προτεινόμενοι χρηματοδότες της εβδομάδας",
    "CXT Quotes": "Τιμές",
    "Digital asset trading tool service platform": "Πλατφόρμα υπηρεσιών εργαλείων ψηφιακού εμπορίου",
    "Service_home": "Υπηρεσίες",
    "Strategic cooperation": "Στρατηγική συνεργασία",
    "Financing assets": "Περιουσιακά στοιχεία χρηματοδότησης",
    "Remove 0 from the first number when registering!": "Αφαιρέστε το 0 από τον πρώτο αριθμό κατά την εγγραφή!",
    "Are you sure you want to quit": "Είστε σίγουροι ότι θέλετε να αποσυνδεθείτε;",
    "Opening Price": "Τιμή ανοίγματος",
    "Closing Price": "Τιμή κλεισίματος",
    "Formula interpretation": "Ερμηνεία τύπου",
    "Note: If the input box is left blank": "Σημείωση: Αν το πεδίο εισόδου μείνει κενό",
    "Option Sessions": "Συνεδρίες επιλογών",
    "Result": "Αποτέλεσμα",
    "When data for an exchange is not available": "*Όταν δεν υπάρχουν δεδομένα για το χρηματιστήριο, το βάρος είναι 0%. Αν τα έγκυρα δεδομένα ≤2, ορίζεται ως ισόπαλο.",
    "Assume that the index is derived from the opening prices of five exchanges": "Υποθέτουμε ότι ο δείκτης προέρχεται από τις τιμές ανοίγματος πέντε χρηματιστηρίων.",
    "The proportion of those five exchanges are": "Τα βάρη των πέντε χρηματιστηρίων είναι: a%, b%, c%, d%, e%",
    "Stream bureau": "Ισοπαλία",
    "(valid data source ≤ 2)": "（Έγκυρες πηγές δεδομένων ≤ 2）",
    "Option calculator": "Υπολογιστής επιλογών",
    "Round selection": "Επιλογή γύρου",
    "Select option": "Επιλέξτε επιλογή",
    "Average opening price": "Μέση τιμή ανοίγματος",
    "Average closing price": "Μέση τιμή κλεισίματος",
    "Calculate now": "Υπολογίστε τώρα",
    "Calculation Results": "Αποτελέσματα υπολογισμού",
    "Calculating Time": "Χρόνος υπολογισμού",
    "Management": "Διαχείριση",
    "Other_home": "Άλλα",
    "All applications": "Όλες οι εφαρμογές",
    "Boon": "Οφέλη",
    "Coin_Address_draw": "Διεύθυνση ανάληψης",
    "Congratulations": "Συγχαρητήρια",
    "Profit": "Κέρδος",
    "Gainers": "Αύξηση",
    "Drop": "Πτώση",
    "Order Price": "Τιμή παραγγελίας",
    "Order Price1": "Τιμή παραγγελίας",
    "Sec Swap": "Δευτερόλεπτα συμβολαίου",
    "Expected Return": "Αναμενόμενη απόδοση",
    "Sec Swap Assets": "Περιουσιακά στοιχεία συμβολαίου",
    "Sec Swap Bill": "Ημερολόγιο κεφαλαίων συμβολαίου",
    "Sec Swap Account": "Λογαριασμός συμβολαίου",
    "Go Login": "Πηγαίνετε για σύνδεση",
    "NSO Session": "Συνεδρία NSO",
    "Wallet Value1": "Αξία πορτοφολιού",
    "Spot Value1": "Αξία νομισμάτων",
    "PERP Value1": "Αξία αέναης",
    "Option Value1": "Αξία επιλογών",
    "Fiat Value1": "Αξία OTC",
    "Pledge_Amount": "Ποσότητα υποθήκης",
    "total_return2": "Συνολικά κέρδη",
    "Violation_Loss": "Ποινές",
    "Cycle": "Κύκλος",
    "Locked2": "Κλειδωμένο",
    "Time_Left": "Υπόλοιπος χρόνος",
    "Cancel_Pledge": "Ακυρώστε την υποθήκη;",
    "countDown_day": "Ημέρες",
    "Entrust2": "Εντολή",
    "All2": "Όλα",
    "Ongoing2": "Σε εξέλιξη",
    "Near2": "Σύντομα",
    "Ended2": "Ολοκληρώθηκε",
    "Cancel the pledge?": "Είστε σίγουροι ότι θέλετε να ακυρώσετε την υποθήκη;",
    "Completed2": "Ολοκληρώθηκε",
    "Cancelled2": "Ακυρώθηκε",
    "Lock-up mining": "Εξόρυξη κλειδώματος",
    "Rules Description": "Περιγραφή κανόνων",
    "Today Expected": "Αναμενόμενα κέρδη σήμερα",
    "Order Escrow": "Προστασία παραγγελίας",
    "Minimum": "Ελάχιστο",
    "Daily Return": "Ημερήσια απόδοση",
    "Buy-3": "Αγορά",
    "Minimum Amount": "Ελάχιστο ποσό υποθήκης",
    "Lockup Period": "Περίοδος κλειδώματος",
    "Confirm Pledge": "Επιβεβαίωση υποθήκης",
    "Buy-4": "Αγορά",
    "Conversion Starter": "Πρώτη υποβολή",
    "Listing": "Καταχώριση",
    "Total Target": "Συνολικός στόχος",
    "Conversion Ratio": "Αναλογία υποβολής",
    "Single Limited": "Ατομικό όριο",
    "Participate Order": "Συμμετοχή στην υποβολή",
    "Fundraised": "Συγκεντρωμένα κεφάλαια",
    "Current Progress": "Τρέχουσα πρόοδος",
    "Time Left": "Υπολειπόμενος χρόνος",
    "Start Time": "Ώρα έναρξης",
    "End Time": "Ώρα λήξης",
    "Conversion Amount": "Ποσότητα συμμετοχής",
    "Conversion Now": "Συμμετοχή τώρα",
    "Conditions": "Όροι συμμετοχής",
    "Preheating": "Προθέρμανση",
    "Records": "Ιστορικό συμμετοχής",
    "Select": "Επιλογή",
    "To": "Προς",
    "Active Currency": "Ενεργό νόμισμα",
    "Amount-3": "Ποσό",
    "Conversion Time": "Χρόνος συμμετοχής",
    "Arrival Time": "Χρόνος παραλαβής",
    "Not Arrived": "Δεν έχει παραληφθεί",
    "Hours-1": "ώρες",
    "Risk warning: It is displayed": "Προειδοποίηση κινδύνου: Πρόκειται για καινοτόμα περιουσιακά στοιχεία. Να είστε προσεκτικοί με τις τιμές και τις επενδυτικές αποφάσεις!",
    "Week-1": "εβδομάδα",
    "S-1": "δευτερόλεπτα",
    "minutes-2": "λεπτά",
    "You must pass basic KYC": "Πρέπει να περάσετε βασικό KYC",
    "You must pass advanced KYC": "Πρέπει να περάσετε προχωρημένο KYC",
    "Account order exceeds": "Ο λογαριασμός υπερβαίνει",
    "Suspend": "Αναστολή συναλλαγών",
    "Successfully": "Συμμετοχή επιτυχής",
    "Ongoing-1": "Συμμετοχή σε εξέλιξη",
    "Failed": "Συμμετοχή αποτυχημένη",
    "New_Token_Subscribe": "Συμμετοχή σε νέο νόμισμα",
    "More_awaits_you": "Περισσότερα σας περιμένουν",
    "Distance Start": "Υπολειπόμενος χρόνος έναρξης",
    "day-2": "ημέρες",
    "Must Eligible": "Πρέπει να πληρούνται οι όροι συμμετοχής",
    "Custodial funds": "Κεφάλαια σε φύλαξη",
    "Default Ratio": "Ποσοστό ποινής",
    "Wallet Available": "Διαθέσιμο πορτοφόλι",
    "Done-2": "Ολοκληρώθηκε",
    "Eligible Amount": "Ποσότητα έγκρισης",
    "Eligible Price": "Τιμή έγκρισης",
    "New Token Subscribe": "Συμμετοχή σε νέο νόμισμα",
    "Subscribe Now": "Συμμετοχή τώρα",
    "Review Time": "Χρόνος αναθεώρησης",
    "Review Passed": "Η αναθεώρηση εγκρίθηκε",
    "Review Failed": "Η αναθεώρηση απέτυχε",
    "Not Reviewed": "Δεν έχει αναθεωρηθεί",
    "Denial Reason": "Λόγος απόρριψης",
    "If not received, please contact service": "Αν δεν έχει παραληφθεί, παρακαλώ επικοινωνήστε με την υποστήριξη!",
    "Returned to wallet": "Επιστράφηκε στο πορτοφόλι",
    "From wallet assets": "Διαθέσιμα από το πορτοφόλι",
    "Day-3": "ημέρα",
    "Sec Swap asset is 0, please transfer from wallet account to Sec Swap first": "Οι περιουσίες του λογαριασμού δευτερόλεπτης είναι 0, παρακαλώ μεταφέρετε πρώτα από το πορτοφόλι.",
    "Settlement": "Σε εκκαθάριση",
    "Completed": "Η εκκαθάριση ολοκληρώθηκε",
    "Password Setting": "Ρύθμιση κωδικού",
    "Set password and email": "Ρυθμίστε κωδικό και email",
    "Email Settings": "Ρυθμίσεις email",
    "First-time registered users mus": "Οι νέοι χρήστες πρέπει να ρυθμίσουν κωδικό και email.",
    "Driver ID": "Άδεια οδήγησης",
    "Preview": "Προεπισκόπηση",
    "cancelTime": "Χρόνος ακύρωσης",
    "Total_assets_valuation": "Συνολική εκτίμηση περιουσιακών στοιχείων",
    "Invite Friends": "Προσκαλέστε φίλους",
    "RedGreen": "Κόκκινο ανέρχεται, πράσινο υποχωρεί",
    "GreenRed": "Πράσινο ανέρχεται, κόκκινο υποχωρεί",
    "Color Preference": "Προτίμηση χρώματος",
    "Fuzzy Information": "Θολή πληροφορία",
    "user_bind_xilain_trade_pwd": "Εισάγετε τον κωδικό συναλλαγών",
    "login_please_input1": "Εισάγετε τον κωδικό SMS",
    "user_please_input9": "Εισάγετε τον κωδικό Google",
    "user_link_name": "Όνομα αλυσίδας",
    "user_coin_bring_together": "Συγκέντρωση",
    "chongbi0": "Οδηγίες κατάθεσης:",
    "chongbi1": "1. Απαγορεύεται η κατάθεση άλλων περιουσιακών στοιχείων εκτός από {0}. Οποιαδήποτε κατάθεση μη {1} περιουσιακού στοιχείου δεν μπορεί να ανακτηθεί.",
    "chongbi2": "2. Στείλτε την απαιτούμενη ποσότητα {1} στη διεύθυνση μέσω του πελάτη {0} ή online πορτοφολιού. Μετά την αποστολή, το σύστημα θα προσθέσει τα κρυπτονομίσματα στον λογαριασμό σας μετά από {2} επιβεβαιώσεις.",
    "chongbi3": "3. Η ίδια διεύθυνση μπορεί να χρησιμοποιηθεί για πολλές καταθέσεις.",
    "chongbi4": "4. Ελάχιστο ποσό κατάθεσης {0}.",
    "currency_withdrawal_rate_rules": "Κανόνες ποσοστού ανάληψης {0}:",
    "currency_withdrawal_rate_rules1": "1. Για την ασφάλεια των χρημάτων σας, η πλατφόρμα μπορεί να επιβεβαιώσει τηλεφωνικά την ανάληψη σας.",
    "currency_withdrawal_rate_rules2": "2. Η κατάθεση {0} επιτρέπεται μόνο μετά από {1} επιβεβαιώσεις.",
    "currency_withdrawal_rate_rules3": "3. Μπορείτε να αποσύρετε ποσό ≤ διαθέσιμα περιουσιακά στοιχεία - μη επιβεβαιωμένα κρυπτονομίσματα.",
    "currency_withdrawal_rate_rules4": "4. Μέγιστες αναλήψεις {0} φορές την ημέρα, με μέγιστο ποσό {1}.",
    "currency_transaction_password": "Κωδικός",
    "long_press_to_save_to_album": "Πατήστε παρατεταμένα για αποθήκευση στο άλμπουμ",
    "the_fee_is_outside_the_range": "Η χρέωση είναι εκτός εύρους",
    "start_and_end_date": "Ημερομηνίες έναρξης και λήξης",
    "The_withdrawal_amount_cannot_be_less": "Το ποσό ανάληψης δεν μπορεί να είναι λιγότερο από {0}",
    "withdrawal_details": "Λεπτομέρειες ανάληψης",
    "RegisterRemove0": "Αφαιρέστε το 0 από τον πρώτο αριθμό κατά την εγγραφή",
    "closeLockUpPopup": "Η ακύρωση της υποθήκης θα προκαλέσει ποινή {0} ημερών, το ποσό της ποινής είναι “{1}{2}”, το πραγματικό ποσό που θα παραληφθεί είναι “{3}{4}”.",
    "LockUpDuan": "Εξόρυξη κλειδώματος",
    "NewTokenSubscribe": "Συμμετοχή σε νέο νόμισμα",
    "MoreWealthAwaitsYou": "Περισσότερος πλούτος σας περιμένει",
    "TopGainers": "Κορυφαίοι κερδισμένοι",
    "Newest": "Νέα νομίσματα",
    "Hot": "Δημοφιλή",
    "DatChange": "Ημερήσιες μεταβολές",
    "SetPassword": "Ρύθμιση κωδικού",
    "comm_password": "Κωδικός συναλλαγών",
    "unraise_recovering_password": "Μετά την επαναφορά του κωδικού, δεν μπορείτε να κάνετε ανάληψη για 1 ώρα",
    "PDleaseUseSafariBrowser": "Παρακαλώ χρησιμοποιήστε τον περιηγητή Safari",
    "ADddToDesktop": "Προσθέστε το BitMatrix στην Επιφάνεια Εργασίας",
    "CDlickBelow": "Κάντε κλικ παρακάτω",
    "SDelectAddToHomeScreen": "και επιλέξτε προσθήκη στην αρχική οθόνη",
    "ADddToDesktop2": "Προσθέστε το BitMatrix στην Επιφάνεια Εργασίας",
    "CDommonbrowserAddMethod": "Μέθοδος προσθήκης σε κοινόχρηστο περιηγητή",
    "user_senior_text1": "Προσοχή",
    "user_senior_text2": "Το μέγεθος αρχείου φωτογραφίας δεν πρέπει να υπερβαίνει τα 5MB! Μορφή αρχείου: jpg, bmp, png κ.λπ.! ",
    "user_senior_text3": "Βεβαιωθείτε ότι η φωτογραφία δεν έχει υδατογράφημα, είναι καθαρή, οι πληροφορίες ταυτότητας είναι ευκρινείς, η φωτογραφία είναι πλήρης και δεν είναι επεξεργασμένη! ",
    "user_senior_text4": "Χειριστής",
    "user_senior_text5": "Φωτογραφία ταυτότητας",
    "user_senior_text6": ": Χρειάζεστε να κρατάτε",
    "user_senior_text7": "την ημερομηνία",
    "user_senior_text8": "και",
    "user_senior_text9": "UID",
    "user_senior_text10": "σε μια μη ανακλαστική φωτογραφία.",
    "user_senior_text11": ", κρατώντας με το άλλο χέρι ένα φύλλο",
    "user_senior_text12": "Χειρόγραφη σημείωση που λέει:",
    "user_senior_text13": "«Είμαι ενήμερος και αναλαμβάνω τον επενδυτικό κίνδυνο»",
    "user_senior_text14": "。",
    "user_ID_card": "Ταυτότητα",
    "user_passport": "Διαβατήριο",
    "Driver_ID": "Άδεια οδήγησης",
    "user_senior_upload3": "Ανεβάστε φωτογραφία ταυτότητας + UID + ημερομηνία",
    "ServiceUpgrade": "Αναβάθμιση υπηρεσίας",
    "ReferencePrice": "Τιμή αναφοράς",
    "referenceTips": "Δεν είναι η τελική τιμή, μόνο για αναφορά",
    "NewCoinsLaunch": "Λανσάρισμα νέων νομισμάτων",
    "CurrentlyPurchased": "Τρέχουσα συμμετοχή",
    "TotalMarketValue": "Συνολική αγοραία αξία",
    "IEORatio": "Αναλογία συμμετοχής",
    "CurrencyIntroduction": "Εισαγωγή νομισμάτων",
    "ConvertNow": "Συμμετοχή τώρα",
    "RemainingAmount": "Υπολειπόμενος ποσό",
    "referenceTips": "Βασιστείτε στην πραγματική τιμή αγοράς",
    "ServiceUpgrade": "Αναβάθμιση υπηρεσίας",
    "LastDays7": "Τελευταίες 7 ημέρες",
    "LastDays30": "Τελευταίες 30 ημέρες",
    "LastDays90": "Τελευταίες 90 ημέρες",
    "LockUpTips": "Σημείωση: Τα μη εγκεκριμένα {0}{1} περιουσιακά στοιχεία έχουν επιστραφεί στο πορτοφόλι σας.",
    "PromotionalMining": "Προωθητική εξόρυξη",
    "hide_coin": "Απόκρυψη νομισμάτων κάτω από {0} BTC",
    "GoldAssets": "Χρυσά περιουσιακά στοιχεία",
    "ModifyEmail": "Τροποποίηση email",
    "NewEmail": "Νέο email",
    "NewEmailAddress": "Εισάγετε νέα διεύθυνση email",
    "VerifyNewEmail": "Επιβεβαίωση νέου email",
    "EnterNewEmailCode": "Εισάγετε τον κωδικό επιβεβαίωσης νέου email",
    "VerifyOldEmail": "Επιβεβαίωση παλιού email",
    "EnterOldEmailCode": "Εισάγετε τον κωδικό επιβεβαίωσης του παλιού email",
    "VerifyLoginPassword": "Επιβεβαίωση κωδικού σύνδεσης",
    "EnterLoginPassword": "Εισάγετε τον κωδικό σύνδεσης",
    "loan": "Κέντρο δανεισμού",
    "loanText": "Προσφέρουμε ασφαλείς υπηρεσίες δανεισμού κρυπτονομισμάτων για διάφορους σκοπούς με υψηλή ρευστότητα.",
    "loanQuery": "Συμβουλές δανεισμού",
    "Stop_surplus_tips": "Όταν η τιμή αγοράς φτάσει το {}, θα ενεργοποιηθεί η εντολή κερδών, αναμενόμενο κέρδος {}.",
    "Stop_loss_tips": "Όταν η τιμή αγοράς φτάσει το {}, θα ενεργοποιηθεί η εντολή ζημίας, αναμενόμενη ζημία {}.",
    "Stop_surplus_tips1": "Όταν η τιμή αγοράς φτάσει το {}, θα ενεργοποιηθεί η εντολή κερδών, αναμενόμενη ζημία {}.",
    "Stop_loss_tips1": "Όταν η τιμή αγοράς φτάσει το {}, θα ενεργοποιηθεί η εντολή ζημίας, αναμενόμενο κέρδος {}.",
    "Countdown": "Αντίστροφη μέτρηση",
    "contract_After_adjustment": "Μετά την προσαρμογή, θα μεταφερθούν {} USDT από τον λογαριασμό ως επιπλέον περιθώριο.",
    "contract_subject_to": "(Σύμφωνα με το πραγματικό ποσό που θα μεταφερθεί)",
    "contract_the_used_margin": "Μετά την προσαρμογή, το χρησιμοποιούμενο περιθώριο θα μειωθεί κατά {} USDT, το μειούμενο ποσό θα παραμείνει στην θέση.",
    "contract_transferred_to": "Μπορεί να μεταφερθεί στο υπόλοιπο λογαριασμού.",
    "contract_After_adjustment_q": "Μετά την προσαρμογή, το περιθώριο θα αυξηθεί κατά {} USDT.",
    "contract_the_used_margin_q": "Μετά την προσαρμογή, το περιθώριο θα μειωθεί κατά {} USDT.",
    "Please1": "Εισάγετε την τιμή ενεργοποίησης κερδών",
    "Please2": "Εισάγετε την τιμή εντολής κερδών",
    "Please3": "Εισάγετε την τιμή ενεργοποίησης ζημίας",
    "Please4": "Εισάγετε την τιμή εντολής ζημίας",
    "Stop profit6": "Η τιμή ενεργοποίησης κερδών πρέπει να είναι μεγαλύτερη από την τελευταία τιμή συναλλαγής.",
    "stop loss7": "Η τιμή ενεργοποίησης ζημίας πρέπει να είναι μικρότερη από την τελευταία τιμή συναλλαγής.",
    "Please8": "Εισάγετε ποσότητα",
    "Please9": "Δεν υπάρχει διαθέσιμη ποσότητα για κλείσιμο.",
    "CountdownTime": "Χρόνος αντίστροφης μέτρησης",
    "h1": "Αίτηση Συνεργάτη",
    "h2": "Κατάταξη επιστροφών",
    "h3": "Γίνετε συνεργάτης και κερδίστε",
    "h4": "Προϋποθέσεις αναβάθμισης",
    "h5": "Για να γίνετε αρχάριος συνεργάτης απαιτείται πληρωμή",
    "h6": "Τρέχον υπόλοιπο",
    "h7": "Πρώτος συνεργάτης",
    "h8": "Επιστροφή",
    "h9": "Αναβάθμιση τώρα",
    "h10": "Είστε ήδη στο ανώτατο επίπεδο",
    "h11": "Επιστροφή μπόνους",
    "h12": "Κατάταξη επιστροφών",
    "h13": "Αριθμός ομάδας",
    "h14": "Ιστορικό επιστροφών",
    "h15": "Πρόσωπο που προσκλήθηκε",
    "h16": "Έχει εκκαθαριστεί",
    "h17": "Δεν έχει εκκαθαριστεί",
    "h18": "Δικαιούστε κατώτερους",
    "h19": "Απαιτείται πληρωμή",
    "h20": "Αναβάθμιση",
    "h21": "Όροι επιστροφής",
    "h22": "Κατάταξη",
    "h23": "Χρήστης",
    "h24": "Επιστροφή επιβράβευσης",
    "h25": "Αυτή η κατηγορία παρέχει",
    "h26": "Συγκεντρώστε όλη την προμήθεια σε USDT.",
    "h27": "Απαιτείται άμεση πρόσκληση {} κανονικών χρηστών.",
    "h28": "Έχετε προσκαλέσει {} ενεργούς κανονικούς χρήστες.",
    "h29": "Απαιτείται η ομάδα να έχει {} μέλη.",
    "h30": "Ομαδικός αριθμός {} άτομα.",
    "Stop profit10": "Η τιμή ενεργοποίησης κερδών πρέπει να είναι μικρότερη από την τελευταία τιμή συναλλαγής.",
    "stop loss10": "Η τιμή ενεργοποίησης ζημίας πρέπει να είναι μεγαλύτερη από την τελευταία τιμή συναλλαγής.",
    "Earn": "Επενδύσεις",
    "Simple": "Κέρδη και έξοδα χωρίς προβλήματα",
    "Low1": "Χαμηλός κίνδυνος, σταθερές αποδόσεις",
    "Low2": "Μικρός κίνδυνος, σταθερές αποδόσεις, άμεση ανάληψη",
    "CurrentAccount": "Λογαριασμός αποταμίευσης",
    "Stable": "Σταθερή αύξηση, εργαλείο πλούτου",
    "EarnLocked": "Κανονικές επενδύσεις",
    "Searchpopular": "Εύκολες επενδύσεις, απολαύστε τα κέρδη σας",
    "Annualized": "Ετήσια απόδοση {}%, ασφαλή περιουσιακά στοιχεία, υψηλές αποδόσεις",
    "CoinsLC": "Νομίσματα",
    "PurchaseLC": "Ποσό αγοράς",
    "PurchaseLC2": "({} ελάχιστο)",
    "Maximum": "Μέγιστο",
    "EstimatedInterest": "Εκτιμώμενο ενδιαφέρον",
    "RuleLC": "Κανόνες",
    "SubscriptionTime": "Χρόνος συνδρομής",
    "Interestaccrualtime": "Χρόνος κεφαλαιοποίησης",
    "RevenueTime": "Χρόνος κερδών",
    "RedemptionCycle": "Κύκλος απόσυρσης",
    "ReturnNow": "Επιστρέψτε τώρα",
    "BuyLC": "Αγορά",
    "PurchaseSuccessful": "Αγορά επιτυχής",
    "DepositLC": "Κατάθεση νομισμάτων",
    "Yesterday": "Κέρδη χθες",
    "TotalLC": "Συνολική κατάθεση",
    "TotalReturn": "Συνολικά κέρδη",
    "DepositValuation": "Εκτίμηση κατάθεσης",
    "DepositDetail": "Λεπτομέρειες κατάθεσης",
    "RevenueDetails": "Λεπτομέρειες κερδών",
    "DepositType": "Τύπος κατάθεσης",
    "ReturnMethod": "Μέθοδος επιστροφής",
    "DepositAmount": "Ποσότητα κατάθεσης",
    "APR": "Ετήσιο ποσοστό",
    "Expected": "Αναμενόμενα κέρδη",
    "DepositCycle": "Κύκλος κατάθεσης",
    "CreationTime": "Χρόνος δημιουργίας",
    "EarnProfit": "Κέρδη της περιόδου",
    "DepositCost": "Κεφάλαιο κατάθεσης",
    "TotalRelease": "Συνολική απελευθέρωση",
    "ReleaseTime": "Χρόνος απελευθέρωσης",
    "Flexible": "Ευέλικτο",
    "Fixed": "Κανονικό",
    "Freeze": "Πάγωμα",
    "Completed3": "Ολοκληρώθηκε",
    "Cancelled": "Ακυρώθηκε",
    "DefaultSettlement": "Εκκαθάριση λόγω παραβίασης",
    "PurchaseAmount": "Ποσό αγοράς (χωρίς περιορισμούς)",
    "RetrieveDeposited": "Ανάκτηση κατατεθειμένων νομισμάτων",
    "PreviewConversion": "Προεπισκόπηση αποτελεσμάτων μετατροπής",
    "ToEnsureThe": "Για την ασφάλεια του λογαριασμού σας και την εκπλήρωση των απαιτήσεων υψηλότερων συναλλαγών, απαιτείται ανώτερη πιστοποίηση.",
    "ToVerification": "Κάντε κλικ στο κουμπί αποστολής για να επικοινωνήσετε με την υποστήριξη.",
    "ToContact": "Επικοινωνήστε με την υποστήριξη για πιστοποίηση.",
    "otc_upload_picture3":"Οι εικόνες UUpload μπορούν να είναι μόνο σε μορφή jpeg/jpg/png/bmp!",
    "CertificationDifficulties":'Υπάρχει πρόβλημα με την επαλήθευση;',
    "comm_nickname_rule1": "Δεν μπορεί να υπερβαίνει τα 15 γράμματα.",
    "comm_nickname_rule2": "Δεν μπορεί να περιέχει κενά και ειδικούς χαρακτήρες.",
    contract_entrust_failure_0080 : 'Το ποσό παραγγελίας δεν πρέπει να είναι μικρότερο από 100 USDT.',
}