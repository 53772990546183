<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-15 15:30:14
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="lockup container">
    <!-- <van-nav-bar :title="$t('M.Lock-up mining')" left-text="" left-arrow @click-left="onClickLeft" :right-text="$t('M.Rules Description')" /> -->
    <van-sticky>
      <nav-bar :title="$route.query.flag ? $t('M.PromotionalMining') : $t('M.Lock-up mining')" :showRight="false">
        <router-link slot="right" to="rules">{{$t('M.Rules Description')}}</router-link>
      </nav-bar>
      <!-- 顶部盒子 -->
      <div class="topBox">
        <div class="leftBox">
          <p class="titText">{{ $t('M.Custodial funds') }}</p>
          <div class="moneyBox">
            <div class="moneyNum">
              {{ userData.buyTotalCount }}
            </div>
            <div class="moneyBoin">
              USDT
            </div>
          </div>
        </div>
        <div class="rightBox" @click="goLog">{{ $t('M.Entrust2') }}</div>
      </div>
      <!-- 信息盒子 -->
      <div class="contant">
        <div class="itemBox">
          <p class="num">{{ userData.anticipatedIncome }}</p>
          <p class="tips">{{ $t('M.Today Expected') }}</p>
        </div>
        <div class="itemBox">
          <p class="num">{{ userData.alreadyIncome }}</p>
          <p class="tips">{{ $t('M.total_return2') }}</p>
        </div>
        <div class="itemBox">
          <p class="num">{{ userData.orderNum }}</p>
          <p class="tips">{{ $t('M.Order Escrow') }}</p>
        </div>
      </div>
    </van-sticky>

    <!-- 列表页面 -->
    <div class="listBox">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :pulling-text="$t('M.MJRefreshHeaderIdleText')"
        :loosing-text="$t('M.MJRefreshHeaderPullingText')" :loading-text="$t('M.loading')">
        <van-list v-model="listLoading" :finished="finished" @load="loadList" :loading-text="$t('M.loading')">
          <div class="listItem" v-for="(item, idx) in listData" :key="idx">
            <div class="top">
              <div class="name">{{ item.showName }}</div>
              <div class="btn" @click="buy(item)">{{ $t('M.Buy-3') }}</div>
            </div>
            <div class="bottom">
              <div class="contantItem left">
                <p class="tit">{{ $t('M.Minimum') }}</p>
                <p class="num">{{ item.minCount }}</p>
              </div>
              <div class="contantItem center">
                <p class="tit" v-if="$route.query.flag">{{ $t('M.PL Ratio') }}</p>
                <p class="tit" v-else>{{ $t('M.Daily Return') }}</p>
                <p class="num">{{ item.rate }}%</p>
              </div>
              <div class="contantItem right">
                <p class="tit">{{ $t('M.Cycle') }}</p>
                <p class="num">{{ item.period }} {{ $t('M.Day-3') }}</p>
              </div>
            </div>
          </div>
        </van-list>
        <div class="noData" v-if="listData.length == 0 && finished == true">
          <img class="imgNoData" src="../../assets/img/public/nodata.png" alt="" srcset="" />
          <p class="text">{{ $t('M.no_data') }}</p>
        </div>
      </van-pull-refresh>
    </div>

    <van-popup v-model="show" position="bottom" :style="{ height: '361px' }">
      <div class="buyBox">
        <div class="top">
          <div class="tit">{{ $t('M.buy') }}</div>
          <img src="../../assets/img/assets/icon_close.png" class="close" @click="show = false" alt="" srcset="">
        </div>
        <div class="heng"></div>
        <div class="contantBox">
          <div class="item">
            <p class="tit">{{ $t('M.Active Currency') }}</p>
            <p class="num">{{ buyObj.coinName }}</p>
          </div>
          <div class="item">
            <p class="tit">{{ $t('M.Minimum Amount') }}</p>
            <p class="num">{{ buyObj.minCount }}</p>
          </div>
        </div>
        <div class="contantBox">
          <div class="item" v-if="!$route.query.flag">
            <p class="tit">{{ $t('M.Violation_Loss') }}</p>
            <p class="num">{{ buyObj.breakRate }}%</p>
          </div>
          <div class="item">
            <p class="tit">{{ $t('M.Lockup Period') }}</p>
            <p class="num">{{ buyObj.period }} {{ $t('M.Day-3') }}</p>
          </div>
        </div>

        <div class="inputBox">
          <van-field v-model="betValue" class="box" :placeholder="`${$t('M.Minimum Amount')}${buyObj.minCount}`" />
          <div class="name">{{ buyObj.coinName }}</div>
        </div>
        <p class="userMoney">{{ $t('M.Available') }}：{{ buyObj.total }} {{ buyObj.coinName }}</p>

        <div class="sub" @click="confirm">
          {{ $t('M.Confirm Pledge') }}
        </div>

      </div>
    </van-popup>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import navBar from '@/components/navBar';

export default {
  components: {
    navBar
  },
  data() {
    return {
      show: false,
      betValue: '',
      finishedText: this.$t('M.no_more_data'),
      userData: {
        "alreadyIncome": "0",
        "anticipatedIncome": "0",
        "buyTotalCount": "0",
        "orderNum": 0
      },
      refreshing: false,
      listLoading: false,
      finished: false,
      listData: [],
      pageNum: 1,
      buyObj: {}
    }
  },
  computed: {
    ...mapGetters([
      'user', 'walletData', 'isLogin'
    ]),
  },
  mounted() {
    /* 获取用户综合信息 */
    this.$route.query.flag ? localStorage.setItem("lockUpFlag", "true") : localStorage.setItem("lockUpFlag", "false")
    if (this.isLogin) {
      this.getorderStatisticsorderStatistics()
      this.$store.dispatch('getUserWalletAssets')
    }
  },
  methods: {
    /* 历史订单 */
    goLog() {
      this.$router.push({ name: 'lockUpLog' })
    },
    /* 获取综合信息 */
    getorderStatisticsorderStatistics() {
      this.$api.orderStatistics({ isNew: this.$route.query.flag ? 1 : 0 }).then((res) => {
        this.userData = res.data
      })
    },
    /* 确认购买 */
    confirm() {
      if (this.betValue > this.buyObj.total * 1) {
        this.$notify(this.$t('M.Insufficient balance'))
        return;
      }
      if (this.betValue < this.buyObj.minCount * 1) {
        this.$notify(this.$t('M.recharge_withdraw_failure_0051'))
        return;
      }

      this.$api.lumProjectAddOrder({
        projectId: this.buyObj.id,
        buyCount: this.betValue
      }).then((res) => {
        this.getorderStatisticsorderStatistics()
        this.$store.dispatch('getUserWalletAssets')
        if (res.meta.success) {
          this.$notify(this.$t('M.' + res.meta.i18n_code))
          this.goLog()
        } else {
          this.$notify(res.meta.message)
        }
        this.show = false;
      })

    },
    /* 点击购买 */
    buy(item) {
      if (this.isLogin) {
        this.buyObj = item
        this.betValue = ''
        this.walletData.userCoinWalletVOPageInfo.list.findIndex(item1 => {
          if (item1.coinId == item.coinId) {
            this.buyObj.total = item1.total;
          }
        })
        this.show = true;
      } else {
        this.$router.push({ name: 'login' })
        return;
      }
    },
    onRefresh() {
      this.pageNum = 1;
      this.listData = []
      this.finished = false;
      this.listLoading = true;
      this.loadList()
    },
    async loadList() {
      let parame = {
        pageSize: 10,
        pageNum: this.pageNum,
      }
      let res;
      if (this.$route.query.flag) {
        res = await this.$api.listProjects2(parame)
      } else {
        res = await this.$api.listProjects(parame)
      }
      if (res.data.list.length < 10) {
        this.finished = true;
      }
      if (this.pageNum == 1) {
        this.listData = []
      }
      for (let i = 0; i < res.data.list.length; i++) {
        switch (this.$i18n.locale) {
          case "zh_CN":
            res.data.list[i].showName = res.data.list[i].projectZhName;
            break;
          case "zh_TW":
            res.data.list[i].showName = res.data.list[i].projectTwName;
            break;
          case "en_US":
            res.data.list[i].showName = res.data.list[i].projectEnName;
            break;
          case "ja_JP":
            res.data.list[i].showName = res.data.list[i].projectJaName;
            break;
          case "ko_KR":
            res.data.list[i].showName = res.data.list[i].projectKoName;
            break;
          case "vi_VN":
            res.data.list[i].showName = res.data.list[i].projectViName;
            break;
          case "ba_BA":
            res.data.list[i].showName = res.data.list[i].projectBrName;
            break;

          default:
            res.data.list[i].showName = res.data.list[i].projectEnName;
            break;
        }

        if (this.$route.query.flag) {
          let  rate = parseFloat(res.data.list[i].rate)
          let  period = res.data.list[i].period
          let result = Math.round(rate * period);
          result = Math.max(result, 1);
          res.data.list[i].rate = result
        }
      }
      this.pageNum = res.data.pageNum + 1;
      this.listData = this.listData.concat(res.data.list);
      this.listLoading = false;
      this.refreshing = false;

    },
    copyCode() {

    },
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.lockup {
  font-size: 12px;
  background-color: #f8f8fb;

  .topBox {
    width: 100%;
    height: 100px;
    // background: linear-gradient(315deg, #e9ba6c 0%, #f4dab0 100%);
    background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
    display: flex;
    padding: 0 26px;
    align-items: center;
    justify-content: space-between;

    .leftBox {
      font-size: 13px;
      color: #fff;
      line-height: 19px;

      .moneyBox {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        color: #fff;

        .moneyNum {
          font-size: 30px;
          line-height: 36px;
        }

        .moneyBoin {
          font-size: 13px;
          line-height: 19px;
        }
      }
    }

    .rightBox {
      font-size: 13px;
      font-weight: 600;
      color: #fff;
      line-height: 30px;
      height: 30px;
      border-radius: 15px;
      padding: 0 16px;
      border: 1px solid #fff;
    }
  }

  .contant {
    width: 100%;
    //height: 78px;
    // background: linear-gradient(90deg, #f4dba8 0%, #e5bf83 100%);
    background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
    padding: 0 15px;
    display: flex;

    .itemBox {
      flex: 1;
      text-align: center;

      .num {
        font-size: 21px;
        font-weight: 600;
        color: #fff;
        line-height: 30px;
        padding-top: 15px;
      }

      .tips {
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        line-height: 17px;
        margin-top: 2px;
      }
    }
  }

  .listBox {
    padding: 13px 15px;

    .listItem {
      width: 100%;
      height: 110px;
      background: #ffffff;
      border-radius: 5px;
      padding: 15px 10px;
      margin-bottom: 12px;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
          font-size: 15px;
          font-weight: 600;
          color: #131520;
          line-height: 21px;
        }

        .btn {
          width: 77px;
          height: 30px;
          background: #00b897;
          border-radius: 4px;
          color: #fff;
          line-height: 30px;
          text-align: center;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .contantItem {
          .tit {
            font-size: 12px;
            font-weight: 400;
            color: #9299a9;
            line-height: 17px;
          }

          .num {
            font-size: 13px;
            font-weight: 600;
            color: #131520;
            line-height: 19px;
            margin-top: 5px;
          }
        }

        .left {
          text-align: left;
        }

        .center {
          text-align: center;
        }

        .right {
          text-align: right;
        }
      }
    }
  }

  .buyBox {
    padding: 19px 15px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .close {
        width: 22px;
        height: 22px;
      }

      .tit {
        font-size: 18px;
        font-weight: 600;
        color: #191b27;
        line-height: 25px;
      }
    }

    .heng {
      width: 100%;
      height: 1px;
      background-color: #ebebf0;
      margin-top: 9px;
    }

    .contantBox {
      display: flex;

      .item {
        flex: 1;
        text-align: left;
        margin-top: 13px;

        .tit {
          color: #9299a9;
          line-height: 17px;
          font-size: 12px;
        }

        .num {
          font-weight: 600;
          font-size: 13px;
          color: #131520;
          line-height: 19px;
          margin-top: 5px;
        }
      }
    }

    .inputBox {
      position: relative;
      margin-top: 18px;

      .box {
        border: 1px solid #9299a9;
        border-radius: 4px;
      }

      .name {
        position: absolute;
        right: 11px;
        top: 11px;
        font-size: 15px;
        color: #191b27;
      }
    }

    .userMoney {
      font-size: 12px;
      color: #9299a9;
      margin-top: 5px;
    }

    .sub {
      width: 100%;
      height: 46px;
      background: #00b897;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      line-height: 46px;
      margin-top: 25px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

::v-deep .van-nav-bar__right .van-nav-bar__text {
  color: #5c6573;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
</style>
