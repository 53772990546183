import { render, staticRenderFns } from "./Identity.vue?vue&type=template&id=dcd459e0&scoped=true"
import script from "./Identity.vue?vue&type=script&lang=js"
export * from "./Identity.vue?vue&type=script&lang=js"
import style0 from "./Identity.vue?vue&type=style&index=0&id=dcd459e0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcd459e0",
  null
  
)

export default component.exports