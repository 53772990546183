<template>
  <div class="trade container">
    <div class="topBar">
      <div class="titel">
        <img src="../../assets/img/trade/img2.png" @click="tradeListShow = true" alt="" srcset="">
        {{ symbol.tradeName }}
      </div>
      <div class="marketBtnBox">

        <!-- <van-popover v-model="moreFlag" trigger="click" :actions="moreActions" placement="bottom-end"
          @select="onMoreSelect" overlay>
          <template #reference>
            <img src="../../assets/img/trade/img6.png" alt="" srcset="">
          </template>
        </van-popover> -->
        <router-link :to="{ name: 'contracTradeLine', params: { symbol: symbol.symbol, } }" style="padding-top:5px;">
          <img
              src="../../assets/img/trade/img8.png" alt="" srcset=""></router-link>
        <!-- <img v-if="!isCollectionFlag" @click="collectionCoin" src="../../assets/img/trade/img7.png" alt="" srcset="">
        <img v-if="isCollectionFlag" @click="collectionCoin" src="../../assets/img/trade/star.png" alt="" srcset=""> -->
      </div>
    </div>

    <div class="main">
      <!-- 左侧操作区 -->
      <div class="left">

        <!-- 全仓 -->
        <div class="orderTypeSelect border br" @click="fullWarehouseShow = true">
            <span v-if="fullWarehouseArr.length != 0"> {{
                fullWarehouseArr[fullWarehouseActive].name
              }}</span>
          <img class="jiantou" src="../../assets/img/trade/img5.png" alt="" srcset="">
        </div>
        <!-- 限价委托 -->
        <div class="orderTypeSelect border br" @click="priceOrderShow = true">
          <span>{{ priceOrderArr[priceOrderActive].name }}</span>
          <img class="jiantou" src="../../assets/img/trade/img5.png" alt="" srcset="">
        </div>
        <!-- 高级限价委托 -->
        <div class="orderTypeSelect border br" @click="premiumLimitOrderShow = true"
             v-if="priceOrderArr[priceOrderActive].flag == 'gaojixianjia'">
          <span style="font-size: 10px;">{{ premiumLimitOrderArr[premiumLimitOrderActive].name }}</span>
          <img class="jiantou" src="../../assets/img/trade/img5.png" alt="" srcset="">
        </div>
        <!-- 限价逐仓 -->
        <div class="multi-position" v-if="fullWarehouseArr.length != 0">
          <div class="cang duocang" v-if="fullWarehouseArr[fullWarehouseActive].flag == 'BY'"
               @click="onactiveSubset(1)">
            <div class="leftText"> {{ $t('M.Long position') }}</div>
            <div class="right">{{ currentLeverData.byOpenUp }}x</div>
          </div>
          <div class="cang kongcang" v-if="fullWarehouseArr[fullWarehouseActive].flag == 'BY'"
               @click="onactiveSubset(2)">
            <div class="leftText"> {{ $t('M.Short position') }}</div>
            <div class="right">{{ currentLeverData.byOpenDown }}x</div>
          </div>
          <div class="cang kongcang" v-if="fullWarehouseArr[fullWarehouseActive].flag != 'BY'"
               @click="onactiveSubset(3)">
            <div class="leftText"> {{ $t('M.Leverage') }}</div>
            <div class="right">{{ currentLeverData.totalOpenUp }}x</div>
          </div>
        </div>
        <!-- 市价委托 -->
        <div class="marketOrder" v-if="priceOrderArr[priceOrderActive].flag == 'shijia'">
          {{ $t('M.At the current price') }}
        </div>
        <!-- 价格 -->
        <div class="goumaijiage border br" v-if="priceOrderArr[priceOrderActive].flag != 'shijia'">
          <van-field v-model="buyMoney" class="" :disabled="counterpartyPriceBtn" type="number"
                     :placeholder="$t('M.price')"/>
          <div class="btn">
            <div class="duishou" v-bind:class="[counterpartyPriceBtn ? 'duishou2' : '']" @click="counterpartyPrice">
              {{ $t('M.BBO') }}
            </div>
            <div class="biming">USDT</div>
          </div>
        </div>
        <!-- 购买数量 -->
        <div class="buyNumber border br">
          <van-field v-model="buyNumber" class="" @input="buyNumberInput" type="text" :placeholder="$t('M.quantity')"/>
          <div class="coinnName jiantou">{{$t('M.Cont')}}</div>
        </div>
        <div>
          <span style="color: #8394a6;" v-if="orderUsdt > 0">≈ {{ orderUsdt }} USDT</span>
        </div>
        <!-- 拖拽条 -->
        <!--        <div class="buySlect">-->
        <!--          <div class="bar">-->
        <!--            <div class="btnBox">-->
        <!--              <div class="item">-->
        <!--                <div class="itemChild" v-bind:class="[barValue > 0 ? 'itemChildActive' : '']"></div>-->
        <!--              </div>-->
        <!--              <div class="item">-->
        <!--                <div class="itemChild" v-bind:class="[barValue >= 25 ? 'itemChildActive' : '']"></div>-->
        <!--              </div>-->
        <!--              <div class="item">-->
        <!--                <div class="itemChild" v-bind:class="[barValue >= 50 ? 'itemChildActive' : '']"></div>-->
        <!--              </div>-->
        <!--              <div class="item">-->
        <!--                <div class="itemChild" v-bind:class="[barValue >= 75 ? 'itemChildActive' : '']"></div>-->
        <!--              </div>-->
        <!--              <div class="item">-->
        <!--                <div class="itemChild" v-bind:class="[barValue >= 100 ? 'itemChildActive' : '']"></div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="vantBar">-->
        <!--              <van-slider v-model="barValue" active-color="#00b897" inactive-color="#c7ccd8" bar-height="1px"-->
        <!--                          @input="changeBarValue">-->
        <!--                <template #button>-->
        <!--                  <div class="custombutton"></div>-->
        <!--                </template>-->
        <!--              </van-slider>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="numBar">-->
        <!--            <div class="item" style="text-align:left">0%</div>-->
        <!--            <div class="item" style="text-align:center">50%</div>-->
        <!--            <div class="item" style="text-align:right">100%</div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="percent">
          <div class="progress-container">
            <div class="progress-bar">
              <div
                  v-for="(stage, index) in stages"
                  :key="index"
                  class="progress-segment cursor-pointer"
                  :class="{'progress-segment-active':progressNumber ===stage }"
                  @click=" percentChange( stage )"
              >
                {{ stage }}%
              </div>
            </div>
          </div>
        </div>
        <!-- 余额信息 -->
        <div class="showUserMoney">
          <div class="item"><span>{{ $t('M.usable') }}({{ dishDefault.buyname }})</span><span>
                {{ assetUsd }}</span></div>
        </div>
        <!-- 切换按钮 -->
        <div class="buyTypeBtnBox">
          <div class="item buy" @click="changeTradeType(true)">
            {{ $t('M.Long') }}
          </div>
          <div class="item sell" @click="changeTradeType(false)">
            {{ $t('M.Short') }}
          </div>
        </div>

        <!-- 余额信息 -->
        <div class="showUserMoney border">
          <div class="item1"><span> {{ $t('M.Max long') }}</span><span>{{ sellTotalCount }}
                {{ dishDefault.sellname }}</span></div>
          <div class="item1"><span> {{ $t('M.Max short') }}</span><span>{{ buyTotalCount }}
                {{ dishDefault.sellname }}</span></div>
        </div>

      </div>
      <div class="right">

        <div class="rigehtTop">
          <!--          <p>{{ $t('M.Funding rate') }}/-->
          <p>{{ $t('M.CountdownTime') }}</p>
          <p class="time">
            <!--            {{ fundRate }}%/-->
            <van-count-down :time="countdown"/>
          </p>
        </div>

        <div class="selectType">
          <van-popover v-model="selectDecimal" placement="bottom" trigger="click" :actions="disDepthDecimal" overlay
                       style="width:100%;">
            <div class="boxHome">
              <van-cell v-for="(item, idx) in disDepthDecimal" :key="idx" class="selectItem" :value="item.text"
                        @click="selectDecimalEvn(item)"
                        v-bind:class="[item.id == disDepthDecimalActive.id ? 'active' : '']"/>
            </div>
            <template #reference>
              <div class="item">{{ disDepthDecimalActive.text }}<img class="jiantou"
                                                                     src="../../assets/img/trade/img5.png" alt=""
                                                                     srcset=""/>
              </div>
            </template>
          </van-popover>
        </div>
        <div class="markTit">
          <!-- 价格 -->
          <div>
            {{ $t('M.price') }}({{ dishDefault.buyname }})
          </div>
          <!-- 数量 -->
          <div>
            {{ $t('M.quantity') }}({{ symbol.nowCoin }})
          </div>
        </div>

        <div class="tradBox">
          <!-- 卖入柱状图 -->
          <div class="sellDataBox" v-if="selectDirectionActive.id != 1">
            <div class="item" v-for="(item, dix) in showSellsList" @click="selectMoney(item)">
              <div class="price">{{ item.price }}</div>
              <div class="num">{{ item.amount }}</div>
              <div class="color-sell-bg" v-if="item.price != '--'"
                   :style="`width:${item.progressiveTotal / sellsProgressiveTotal * 100}%`"></div>
            </div>
          </div>
          <!-- 价格 -->
          <p class="nowPrice" v-bind:class="[rose > 0 ? 'up' : 'down']">{{ latestDone }}{{
              dishDefault.buyname
            }}</p>
          <!-- 买入柱状图 -->
          <div class="buyDataBox" v-if="selectDirectionActive.id != 2">
            <div class="item" v-for="(item, dix) in showBuyssList" @click="selectMoney(item)">
              <div class="price">{{ item.price }}</div>
              <div class="num">{{ item.amount }}</div>
              <div class="color-sell-bg" v-if="item.price != '--'"
                   :style="`width:${item.progressiveTotal / buysProgressiveTotal * 100}%`">
              </div>
            </div>
          </div>
        </div>

        <div class="biaojijiage">
          <p><span class="left">{{ $t('M.Mark price') }}:</span><span>{{ markedPrice }}</span></p>
          <p><span class="left">{{ $t('M.Index_contrct') }}:</span><span>{{ spotIndex }}</span></p>
        </div>

      </div>
    </div>
    <!-- 底部 list -->
    <TradeList ref="tradeList" @getUserMoney="getUserMoney2" :disTradeList="disTradeList" :latestDone="latestDone"
               :symbol="symbol" :tradeListUpDataItem="tradeListUpDataItem" :tradeList="tradeList"/>

    <TradeLeftPopup :tradeListShow="tradeListShow" @isCollection="isCollection" @closeTradeList="closeTradeList"
                    @changeSymbol="changeSymbol" @listenTradeList="listenTradeList" @saveTradeList="saveTradeList"/>
    <!-- 全仓选择 -->
    <van-action-sheet class="entrustSelectBox" v-model="fullWarehouseShow" :cancel-text="$t('M.Cancel')"
                      close-on-click-action>
      <div class="content">
        <div class="item" v-for="(item, idx) in fullWarehouseArr" :idx="idx" @click="selectEntrust(idx)"
             v-bind:class="[idx == fullWarehouseActive ? 'item1' : '']">
          {{ item.name }}
        </div>
      </div>
    </van-action-sheet>
    <!-- 委托选择弹窗 -->
    <van-action-sheet class="entrustSelectBox" v-model="priceOrderShow" :cancel-text="$t('M.Cancel')"
                      close-on-click-action>
      <div class="content">
        <div class="item" v-for="(item, idx) in priceOrderArr" :idx="idx" @click="selectEntrust2(idx)"
             v-bind:class="[idx == priceOrderActive ? 'item1' : '']">
          {{ item.name }}
        </div>
      </div>
    </van-action-sheet>
    <!-- 委托选择弹窗 -->
    <van-action-sheet class="entrustSelectBox" v-model="premiumLimitOrderShow" :cancel-text="$t('M.Cancel')"
                      close-on-click-action>
      <div class="content">
        <div class="item" v-for="(item, idx) in premiumLimitOrderArr" :idx="idx" @click="selectEntrust3(idx)"
             v-bind:class="[idx == premiumLimitOrderActive ? 'item1' : '']">
          {{ item.name }}
        </div>
      </div>
    </van-action-sheet>

    <!-- 密码 -->
    <popup-password :show="showPassword" :onlyEnablePassword="showPassword" @onClose="onClosePassword"
                    @onSubmit="onSubmitPassword"/>

    <!-- 倍数 -->
    <van-popup v-model="activeSubsetShow" closeable round position="bottom">
      <Certain2 v-if="activeSubsetShow" :itemData="activeSubsetObj" @dreg="activeSubsetdreg">
      </Certain2>
    </van-popup>

    <!-- 实名弹窗 -->
    <van-popup v-model="realNamePopShow" closeable round>
      <div class="popopContont">
        <div class="title">{{ $t('M.hint') }}</div>
        <div class="conttant">{{ $t('M.no_realname_auth') }}</div>
        <router-link to="identity">
          <div class="btn">{{ $t('M.go_setting') }}</div>
        </router-link>
      </div>
    </van-popup>
    <!-- 交易密码 -->
    <van-popup v-model="transactionCodePopShow" closeable round>
      <div class="popopContont">
        <div class="title">{{ $t('M.hint') }}</div>
        <div class="conttant">{{ $t('M.ios_先设置交易密码') }}</div>
        <router-link to="setExchangePwd">
          <div class="btn">{{ $t('M.go_setting') }}</div>
        </router-link>
      </div>
    </van-popup>

    <!-- 开通协议 -->
    <van-popup v-model="havePermissionShow" closeable round>
      <div class="popopContont">
        <div class="title">{{ $t('M.hint') }}</div>
        <div class="conttant">{{ $t('M.open perpetual swap') }}?</div>
        <div class="btn" @click="openPermission">{{ $t('M.Confirm Open') }}</div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import TradeList from "./tradeComponet/tradeList.vue";
import TradeLeftPopup from "./tradeComponet/tradeLeftPopup.vue";
import popupPassword from '@/components/popupPassword';
import Certain2 from "@/components/popupList/Certain2.vue";
import {Toast} from 'vant';
import {mapGetters} from 'vuex'
import {cutOutPointLength} from "@/utils/util";

export default {
  components: {TradeList, TradeLeftPopup, popupPassword, Certain2},
  data() {
    return {
      showPassword: false,
      rose: "",//涨跌幅
      betMoney: "",//交易额
      buyNumber: '', //购买数量
      buyMoney: 0, //购买价格
      tradeType: true, //类型, true 买入 ,false卖出
      barValue: 0, //滑动数值
      tradeList: [],  //交易对信息
      tradeListShow: false,  //交易对列表是否显示
      turnover: '',//交易额
      symbol: {
        tradeName: '',  //交易对显示名称
        targetCoin: {}, //目标币种 "btn/USDT" 后面的
      }, //当前交易对信息
      isCollectionFlag: false,
      entrustSelectData: {//委托选择框数据
        matchTypeList: [],//选项
      },
      entrustSelectShow: false,
      entrustSelectActive: 0,
      fullWarehouseArr: [],
      fullWarehouseShow: false,
      fullWarehouseActive: 0,
      priceOrderArr: [
        // {
        //   name: this.$t('M.limit_orderAA'),
        //   flag: "xianjia",
        // },
        // {
        //   name: this.$t('M.advanced_limit_order'),
        //   flag: "gaojixianjia",
        // },
        {
          name: this.$t('M.market_order'),
          flag: "shijia",
        },
      ],
      priceOrderShow: false,
      priceOrderActive: 0,
      premiumLimitOrderArr: [{
        name: this.$t('M.post_only'),
        flag: "POST_ONLY",
      },
        {
          name: this.$t('M.Fill or Kill'),
          flag: "FOK",
        },
        {
          name: this.$t('M.Immediately or Cancel'),
          flag: "IOC",
        },],
      premiumLimitOrderShow: false,
      premiumLimitOrderActive: 0,
      asset: {
        buyCoin: 0,
        buyCoinFrozen: 0,
        sellCoin: 0,
        sellCoinFrozen: 0,
      },//钱包信息
      /* 盘口 */
      dishDefault: {}, //基本数据
      disDepthDecimal: [], //小数位
      selectDirectionArr: [
        {text: 'defaults', id: 0},//默认
        {text: 'bug_order', id: 1},//买盘
        {text: 'sell_order', id: 2},//卖盘
      ],
      selectDirectionActive: {text: 'defaults', id: 0},
      //买盘,卖盘
      disDepthResult: {
        buys: {
          list: []
        },
        sells: {
          list: []
        },
        latestDone: {
          price: 0,
          rose: 0
        }
      }, // 买卖数据
      allBuys: [],  //所有的盘口买入
      allSells: [], //所有的盘口卖出
      buys: [],  //显示的盘口买入
      sells: [], //显示的盘口卖出
      latestDone: 0,//盘口最后的价格
      disTradeList: [], // 底部挂单买卖数据
      selectDecimal: false, //选择小数位的选择框
      selectDecimalCss: {},//样式
      disDepthDecimalActive: {}, //小数位选择值
      socket: null,//socket对象
      pako: require("pako"),
      selectDirection: false,
      dishSymbol: "", //盘口当前的值
      sellsProgressiveTotal: "",//盘口买入 横柱的分母
      buysProgressiveTotal: "",//盘口买入 横柱的分母
      minBetAdd: 0,//最小加价幅度
      countExchange: 0,//最小加价幅度和最小下注数量
      getTradeAssetTime: null,
      listen: null,
      isLoading: false,
      rate: 1,
      ratebuyMoney: 0,
      ratelatestDone: 0,
      fiatShow: false,
      fatPayUrl: '',
      /* 三个点,更多 */
      moreFlag: false,
      moreActions: [
        {text: this.$t('M.Charge_money'), icon: require('@/assets/img/public/smallchong.png'), flag: 1},
        {text: this.$t('M.Transfer'), icon: require('@/assets/img/public/smalltrade.png'), flag: 2},
      ],
      /* 标记价格 */
      markedPrice: '',
      /* 现货指数 */
      spotIndex: '',
      /* 资金费率 */
      fundRate: "",
      /* 倒计时 */
      countdown: '',
      /* 限价委托 选择 */
      contractsActive: '',
      /* 限价委托 数组 */
      contractsArr: [],
      /* 合约余额 */
      assetUsd: 0,
      HoldMarginRatioArr: [],
      sellTotalCount: 0,
      buyTotalCount: 0,
      currentLeverData: {"byOpenUp": "1", "byOpenDown": "1", "totalOpenUp": "1"},
      activeSubsetShow: false,
      activeSubsetObj: {},
      /* 对手价格按钮 */
      counterpartyPriceBtn: false,
      /* 实名弹窗 */
      realNamePopShow: false,
      /* 设置交易密码 */
      transactionCodePopShow: false,
      /*是否开通协议 */
      whetherHavePermission: false,
      havePermissionShow: false,
      orderUsdt: 0,
      dataUpdateTimer: null,
      showSellsList: [],
      showBuyssList: [],
      listenTradeListString: '',
      tradeListUpDataItem: {},
      stages: [20, 50, 80, 100],
      progressNumber: 20,
    }
  },
  computed: {
    ...mapGetters([
      'isLogin', 'user', "lang"
    ]),
  },
  watch: {
    latestDone: function (newQuestion, oldQuestion) {
      this.ratelatestDone = this.latestDone * this.rate
      this.ratelatestDone = this.cutOutPointLength(this.ratelatestDone, 8)
    },
    sells: {
      handler(newVal) {
        this.showSellsList = newVal
      },
      deep: true
    },
    buys: {
      handler(newVal) {
        this.showBuyssList = newVal
      },
      deep: true
    },
  },
  created() {

  },
  mounted() {
    this.queryEntrustSelectBox()
    this.getUserMoney()
    this.queryFuturesBindStatus()
    this.startFakeDataGeneration()
  },
  methods: {
    closestMultiple(n, x) {
      if (x > n) {
        return x;
      } else {
        n = n + parseInt(x / 2, 10);
        n = n - (n % x);
        return n.toFixed(2);
      }
    },
    percentChange(data) {
      this.progressNumber = data
      if(this.sellTotalCount == 0 || data == 100){
        this.buyNumber = this.sellTotalCount
      }else{
        let num = this.closestMultiple(this.$big.mul(this.$big.div(this.progressNumber, 100), this.sellTotalCount), this.symbol.faceValue)
        this.buyNumber = num
      }
    },
    listenTradeList(str) {
      this.listenTradeListString = str
    },
    saveTradeList(arr) {
      this.tradeList = arr
    },

    /**
     * 生成指定范围内的随机浮点数，并保留两位小数
     *
     * @param {number} min - 随机数范围的最小值（包含）
     * @param {number} max - 随机数范围的最大值（包含）
     * @returns {number} - 在指定范围内的随机浮点数，保留两位小数
     */
    getRandomFloat(min, max) {
      min = Number(min);
      max = Number(max);
      const randomValue = Math.random() * (max - min) + min;
      return parseFloat(randomValue.toFixed(2));
    },
    startFakeDataGeneration() {
      this.dataUpdateTimer = setInterval(() => {
        if (this.sells.length == 0 || this.buys.length == 0) return

        let jiaData1 = this.sells
        for (let i = 0; i < jiaData1.length; i++) {
          if (i != 0 && i != jiaData1.length - 1) {
            let a = jiaData1[i - 1].progressiveTotal
            let b = jiaData1[i + 1].progressiveTotal
            let c = jiaData1[i].progressiveTotal
            jiaData1[i].progressiveTotal = this.getRandomAround(a, b, c)
          }
        }
        this.showSellsList = jiaData1

        let jiaData2 = this.buys
        for (let i = 0; i < jiaData2.length; i++) {
          if (i != 0 && i != jiaData2.length - 1) {
            let a = jiaData2[i - 1].progressiveTotal
            let b = jiaData2[i + 1].progressiveTotal
            let c = jiaData2[i].progressiveTotal
            jiaData2[i].progressiveTotal = this.getRandomAround(a, b, c)
          }
        }
        this.showBuyssList = jiaData2
      }, 1000)
    },
    getRandomAround(a, b, c) {
      const min = Math.min(a, b);
      const max = Math.max(a, b);
      // 确保c在合法范围内
      c = Math.max(min, Math.min(c, max));

      // 处理a等于b的情况，直接返回原值
      if (min === max) return c;

      // 计算当前值到边界的距离
      const distanceToMin = c - min;
      const distanceToMax = max - c;

      // 动态计算波动范围：取较小距离的10%，且至少为总范围的1%
      let delta;
      if (distanceToMin === 0 || distanceToMax === 0) {
        delta = (max - min) * 0.01; // 边界时用总范围的1%
      } else {
        delta = Math.min(distanceToMin, distanceToMax) * 0.2; // 正常波动范围
      }

      // 计算实际波动区间
      const lower = c - delta;
      const upper = c + delta;
      const adjustedLower = Math.max(min, lower);
      const adjustedUpper = Math.min(max, upper);
      const adjustedUpper2 = adjustedLower + Math.random() * (adjustedUpper - adjustedLower);

      // 生成随机数
      return adjustedUpper2.toFixed(4)
    },
    /* 下单 */
    async changeTradeType(flag) {
      if (!this.isLogin) {
        this.$router.push({name: 'login'})
        return;
      } else {
        /* 实名 */
        // if (this.user.realNameAuth == "n") {
        //   this.realNamePopShow = true
        //   return
        // }
        /* 交易密码 */
        if (this.user.paypasswordSet == "n") {
          this.transactionCodePopShow = true
          return
        }
        /* 是否开通了协议 */
        if (!this.whetherHavePermission) {
          this.havePermissionShow = true
          return
        }

        let sendData = {
          tradeId: this.symbol.contractTradeTypeId,
          positionsType: this.fullWarehouseArr[this.fullWarehouseActive].flag,
          tradeMode: flag ? 'OPEN_UP' : 'OPEN_DOWN',
          source: "IOS",
          matchType: ""
        }
        /* 价格 */
        if (this.counterpartyPriceBtn) {
          sendData.price = flag ? this.sells[this.sells.length - 1].price : this.buys[0].price
        } else {
          sendData.price = this.buyMoney
        }
        /*价格*/
        let price;
        /* 如果是市价 */
        if (this.priceOrderArr[this.priceOrderActive].flag == 'shijia') {
          sendData.price = ""
          if (flag) {
            price = this.sells[this.sells.length - 1].price;
          } else {
            price = this.buys[0].price;
          }
        } else {
          price = sendData.price - 0
        }

        /* 取数量 */
        let num = 0
        // if (this.buyNumber.includes("%")) {
        //   var result = this.buyNumber.replace(/%/g, "");
        //   if (result <= 0) {
        //     this.$notify(this.$t('M.number_empty'))
        //     return
        //   }
        //
        //   let count = 0;
        //   //购买的金额
        //   let buyCount = result / 100 * this.assetUsd
        //   /* 杠杆倍数 */
        //   let totalOpenUp = parseFloat(this.currentLeverData.totalOpenUp)
        //   /* 做空手续费 */
        //   let openTaker = parseFloat(this.symbol.openTaker)
        //
        //   /*最小下单*/
        //   count = buyCount - (buyCount * totalOpenUp * openTaker)
        //   num = ((count * totalOpenUp) / parseFloat(price)).toFixed(8) - 0
        //
        // } else {
        //   if (this.buyNumber <= 0) {
        //     this.$notify(this.$t('M.number_empty'))
        //     return
        //   }
        //   num = this.buyNumber
        // }
        if (this.buyNumber <= 0) {
          this.$notify(this.$t('M.number_empty'))
          return
        }
        // if(this.buyNumber  ==  this.sellTotalCount) {
        //   let count = 0;
        //   //购买的金额
        //   let buyCount =  this.assetUsd-1
        //   /* 杠杆倍数 */
        //   let totalOpenUp = parseFloat(this.currentLeverData.totalOpenUp)
        //   /* 做空手续费 */
        //   let openTaker = parseFloat(this.symbol.openTaker)
        //   /*最小下单*/
        //   count = buyCount - (buyCount * totalOpenUp * openTaker)
        //   num = ((count * totalOpenUp) / parseFloat(price)).toFixed(8) - 0
        // }else {
        //   sendData.count =  this.buyNumber
        // }

        sendData.count =  this.buyNumber


        // sendData.count = num
        let face = this.symbol.faceValue - 0
        sendData.count = Math.floor(sendData.count / face) * face

        /* matchType */
        if (this.priceOrderArr[this.priceOrderActive].flag == 'xianjia') {
          sendData.matchType = 'LIMIT_DEFAULT'
        }
        if (this.priceOrderArr[this.priceOrderActive].flag == 'gaojixianjia') {
          sendData.matchType = this.premiumLimitOrderArr[this.premiumLimitOrderActive].flag
        }
        if (this.priceOrderArr[this.priceOrderActive].flag == 'shijia') {
          sendData.matchType = 'MARKET_DEFAULT'
        }
        this.$api.contractsaveEntrust(sendData).then(res => {
          if (res.meta.success) {
            this.$notify(res.meta.message)
            this.getUserMoney()
            this.getLongShortNum()
            this.$refs.tradeList.apiData()

            this.orderUsdt = 0;
            this.barValue = 0;
            this.buyNumber = ''
          }
        });

      }
    },
    /* 开通协议 */
    openPermission() {
      this.$api.agreementyes({
        contractType: "USDT_SUSTAINABLE",
        confirm: true
      }).then(res => {
        if (res.meta.success) {
          this.whetherHavePermission = true;
          this.havePermissionShow = false;
          this.$notify(res.meta.message)
        }
      });
    },
    /* 是否开通了协议 */
    queryFuturesBindStatus() {
      if (!this.isLogin) return;
      this.$api.queryFuturesBindStatus({
        partnerId: "472437831826935808",
        contractType: "USDT_SUSTAINABLE"
      }).then(res => {
        // 添加数据检查
        if (res && res.data) {
          this.whetherHavePermission = res.data.status || false;
        } else {
          this.whetherHavePermission = false;
          console.warn('获取合约开通状态数据异常');
        }
      }).catch(err => {
        console.error('获取合约开通状态失败:', err);
        this.whetherHavePermission = false;
      });
    },
    /* 对手价格 */
    counterpartyPrice() {
      this.counterpartyPriceBtn = !this.counterpartyPriceBtn
      if (this.counterpartyPriceBtn) {
        this.buyMoney = '';
      } else {
        this.buyMoney = this.latestDone
      }
    },
    /* 显示杠杆弹窗 */
    onactiveSubset(flag) {
      if (!this.isLogin) return;
      let lever;
      let positionsType = this.fullWarehouseArr[this.fullWarehouseActive].flag;
      let openType;
      switch (flag) {
        case 1:
          lever = this.currentLeverData.byOpenUp
          openType = "OPEN_UP"
          break;
        case 2:
          lever = this.currentLeverData.byOpenDown
          openType = "OPEN_DOWN"
          break;
        case 3:
          lever = this.currentLeverData.totalOpenUp
          openType = "OPEN_UP"
          break;
      }
      this.activeSubsetObj = {...this.symbol, lever, flag, openType, positionsType}
      this.activeSubsetShow = true;
    },
    activeSubsetdreg() {
      this.activeSubsetShow = false;
      this.currentLever()
    },
    /* 请求用户余额 */
    getUserMoney() {
      if (this.isLogin) {
        this.$api.getUserContractFinanceList({
          partnerId: "472437831826935808",
        }).then(res => {
          // 添加数据检查
          if (res && res.data && res.data.accountList) {
            if (res.data.accountList.length > 0) {
              this.assetUsd = this.cutOutPointLength(res.data.accountList[0].enablePosition, 4);
            } else {
              this.assetUsd = 0;
            }
          } else {
            this.assetUsd = 0;
            console.warn('获取用户余额数据异常');
          }
        }).catch(err => {
          console.error('获取用户余额失败:', err);
          this.assetUsd = 0;
        });
      }
    },
    getUserMoney2() {
      this.getUserMoney()
      this.getLongShortNum()
    },
    /* 选择更多选项 */
    onMoreSelect(data) {
      let obj = null;
      this.entrustSelectData.coinList.find((item) => {
        if (item.name == this.symbol.targetCoin) obj = item;
      })
      if (data.flag == 1) {
        this.$router.push({path: '/chargingAddress', query: {coinName: obj.name, coinId: obj.id, type: 'recharge'}})
      } else {
        this.$router.push({path: '/transfer', query: {coinName: obj.name, coinId: obj.id, type: 'transfer'}})
      }
    },
    /* 改变交易对 */
    changeSymbol(data) {
      this.symbol = data;
      this.symbol.nowCoin = this.symbol.coinName
      this.barValue = 0;
      this.getPankouChengjiao()
      this.getNewTimeTradePairDetail()
    },
    // 获取最新资金费率数据
    getNewTimeTradePairDetail(params) {
      this.$api.contractGetTradeTypeTicker({
        tradeName: this.symbol.symbol,
        partnerId: "472437831826935808",
      }).then(res => {
        if (res && res.meta.success) {
          this.fundRate = parseFloat(res.data.fundRate);
          this.countdown = res.data.interval;
          /* 全仓数组 */
          this.fullWarehouseArr = [];
          this.fullWarehouseShow = false;
          if (res.data.positionsType == 'ALL' || res.data.positionsType == 'TOTAL') {
            this.fullWarehouseArr.push({
              name: this.$t('M.Cross'),
              flag: "TOTAL",
            })
            this.fullWarehouseActive = 0;
          }
          // console.log(this.fullWarehouseArr);
          // if (res.data.positionsType == 'ALL' || res.data.positionsType == 'BY') {
          //   this.fullWarehouseArr.push({
          //     name: this.$t('M.Isolated'),
          //     flag: "BY",
          //   })
          //   this.fullWarehouseActive = 0;
          // }

          // if (res.data.positionsType == 'ALL') {
          //   this.fullWarehouseActive = 1
          // } else {
          //   this.fullWarehouseActive = 0;
          // }
          this.currentLever();
        }
      });
    },
    /*获取当前杠杆 */
    currentLever() {
      if (this.isLogin) {
        let params = {
          tradeId: this.symbol.contractTradeTypeId,
        };
        this.$api.currentLever(params).then(res => {
          if (res.meta.success) {
            this.currentLeverData = res.data;
          }
          this.getLongShortNum()
        });
      }
    },
    /* 获取当前可开张 */
    getLongShortNum() {
      let params = {
        tradeId: this.symbol.contractTradeTypeId,
        positionsType: this.fullWarehouseArr[this.fullWarehouseActive].flag,
        lastPrice: this.dishDefault.last,
        partnerId: "472437831826935808",
      };
      this.$api.getLongShortNum(params).then(res => {
        if (res && res.data) {
          this.sellTotalCount = res.data.upCount || 0;
          this.buyTotalCount = res.data.downCount || 0;
        } else {
          this.sellTotalCount = 0;
          this.buyTotalCount = 0;
          console.warn('getLongShortNum: Invalid response data');
        }
      }).catch(err => {
        console.error('getLongShortNum failed:', err);
        this.sellTotalCount = 0;
        this.buyTotalCount = 0;
      });
    },
    /* 下拉刷新 */
    onRefresh() {
      this.initDishData()
      this.getPankouChengjiao()
    },

    /* 初始化盘口的下单数据 */
    initDishData() {
      this.buyNumber = '';
      this.turnover = '';
      this.betMoney = '';
      this.barValue = 0;
      if (this.tradeType) {
        if (this.sells[this.sells.length - 1].price != '--') {
          this.buyMoney = this.sells[this.sells.length - 1].price
        } else {
          this.buyMoney = ''
        }
      } else {
        if (this.buys[0].price != '--') {
          this.buyMoney = this.buys[0].price
        } else {
          this.buyMoney = ''
        }
      }
    },
    /* 支付密码回调 */
    onSubmitPassword(payPassword) {
      this.saveEntrust(payPassword)
      this.onClosePassword()
    },
    /* 下单 */
    saveEntrust(payPwd) {
      let sendData = {
        matchType: this.entrustSelectActive == 0 ? "LIMIT" : "MARKET",
        price: this.entrustSelectActive == 0 ? this.buyMoney : '',
        source: "ios",
        tradeId: this.symbol.partnerTradeId,
        partnerId: "472437831826935808",
        partnerNo: "1000010001",
        type: this.tradeType ? "BUY" : "SELL",
        userId: this.user.id
      }
      if (this.entrustSelectActive == 0) {
        sendData.count = this.buyNumber
      } else {
        if (this.tradeType) {
          sendData.count = this.turnover
        } else {
          sendData.count = this.buyNumber
        }
      }
      sendData.count = parseInt(sendData.count)
      if (payPwd) sendData.payPwd = payPwd;
      this.$api.contractsaveEntrust(sendData).then((response) => {
        if (!response.meta.success) return
        this.$notify(response.meta.message)
        /* 重置 */
        this.initDishData()

        this.getPankouChengjiao()
      })
    },
    /* 关闭了支付 */
    onClosePassword() {
      this.showPassword = false
    },
    /* 购买数量变化 */
    buyNumberInput() {
      var str = this.buyNumber;
      var result = str.replace(/[^0-9.]/g, "");
      this.buyNumber = result

      let newPrice = this.dishDefault.last
      this.orderUsdt = (this.buyNumber * newPrice) / parseFloat(this.currentLeverData.totalOpenUp)
      this.orderUsdt = cutOutPointLength(this.orderUsdt, 2)
    },
    /* 购买额度变化 */
    turnoverInput() {
      const wei = this.turnover.toString().split(".");
      if (wei.length != 1 && wei[1].length >= this.dishDefault.countExchange) {
        this.turnover = this.cutOutPointLength(this.turnover, this.dishDefault.countExchange)
      }
      if (wei[0].length > 8) {
        wei[0] = wei[0].substr(0, 8)
        this.turnover = this.cutOutPointLength(wei.join('.'), this.dishDefault.countExchange)
      }
    },
    /* 滑动条改变 */
    changeBarValue() {
      this.buyNumber = this.barValue + '%'
      this.orderUsdt = cutOutPointLength(this.barValue / 100 * this.assetUsd, 2)
    },
    /* 选择小数点 */
    selectDecimalEvn(item) {
      this.selectDecimal = false;
      if (item.id == this.disDepthDecimalActive.id) return;

      /* 先取消掉 */
      this.socket.send(JSON.stringify({
        tag: 'CANCEL',
        content: this.dishSymbol,
        id: 'ios'
      }))
      /* 检测数据 */
      this.socket.send(JSON.stringify({
        tag: 'REQ',
        content: `market.${this.dishDefault.id}.depth.step1.${item.id}`,
        id: 'ios'
      }))
      /* 订阅 */
      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: `market.${this.dishDefault.id}.depth.step1.${item.id}`,
        id: 'ios'
      }))
      /* 记录当前盘口的订阅值 */
      this.dishSymbol = `market.${this.dishDefault.id}.depth.step1.${item.id}`;

      this.disDepthDecimalActive = item;
    },
    /* 选择看的盘 */
    selectDirectionEvn(item) {
      this.selectDirectionActive = item;
      /* 需要截取多少位 */
      const sliceNum = this.selectDirectionActive.id == 0 ? 7 : 14;
      /* 卖盘 */
      this.sells = this.allSells.slice(0, sliceNum).reverse()
      this.sellsProgressiveTotal = this.sells[0].progressiveTotal
      while (this.sells.length < sliceNum) {
        let addType = '';
        this.selectDirectionActive.id == 0 ? addType = "unshift" : addType = "push";
        this.sells[addType]({
          amount: "--",
          index: "--",
          price: "--",
          progressiveTotal: "--",
          total: "--",
        })
      }

      /* 买盘 */
      this.buys = this.allBuys.slice(0, sliceNum)
      this.buysProgressiveTotal = this.buys[this.buys.length - 1].progressiveTotal
      while (this.buys.length < sliceNum) {
        this.buys.push({
          amount: "--",
          index: "--",
          price: "--",
          progressiveTotal: "--",
          total: "--",
        })
      }

      this.selectDirection = false;
    },
    /* 选择价格 */
    selectMoney(item) {
      if (item.price != '--') {
        this.buyMoney = Number(item.price);
      }
    },
    /* 获取汇率 */
    tradeAreaPrice() {
      this.$api.tradeAreaPrice({partnerId: "472437831826935808", shortName: "USD"}).then((response) => {
        for (let index = 0; index < response.data.usd.length; index++) {
          const element = response.data.usd[index];
          if (element.coinName == this.symbol.targetCoin) {
            this.rate = element.coinPrice - 0
          }
        }
        this.buyMoney = this.buyMoney - 0;
        this.latestDone = this.latestDone - 0;
      })
    },
    /* 初始化socket */
    initSocket() {
      const BrowserWebSocket = window.WebSocket || window.MozWebSocket
      this.socket = new BrowserWebSocket(`wss://${this.$apiUrl}/v1/websocket/contract/market`)
      this.socket.onopen = this.onopenWS
      this.socket.onmessage = this.onmessageWS
      this.socket.onerror = this.onerrorWS
      this.socket.onclose = this.oncloseWS
    },
    /* 打开时 */
    onopenWS() {
      this.sendInitSymbol()
    },
    /* 订阅的socket */
    sendInitSymbol() {
      this.tradeSymbol = `market.${this.dishDefault.id}.trade`
      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: this.tradeSymbol,
        id: 'ios'
      }))
      /* 记录当前盘口的订阅值 */
      this.dishSymbol = `market.${this.dishDefault.id}.depth.step1`;
      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: this.dishSymbol,
        id: 'ios'
      }))
      this.socket.send(JSON.stringify({
        tag: "SUB",
        content: `market.${this.listenTradeListString}.ticker`,
        id: "pc"
      }))
    },

    /* 收到消息 */
    onmessageWS(data) {
      let blob = data.data;
      let reader = new FileReader();
      reader.readAsBinaryString(blob);
      reader.onload = (res) => {
        let text = this.pako.inflate(res.target.result, {to: "string"});
        let msg = JSON.parse(text);
        if (msg.type == 1) {
          // console.log(msg.data)
          /* 最近成交 */
          if (msg.tradeType == 'TICKER') {
            if (msg.data.tradeName == this.symbol) {
            this.markedPrice = msg.data.markLast
            this.spotIndex = msg.data.indexLast
            }
            this.tradeListUpDataItem = msg.data
          }
          /* 卖盘 */
          if (msg.tradeType == "DEPTH") {
            /* 需要截取多少位 */
            const sliceNum = this.selectDirectionActive.id == 0 ? 7 : 14;
            /* 卖盘 */
            if (msg.data.sells.list) {
              this.allSells = msg.data.sells.list;
            } else {
              this.allSells = [];
            }
            this.sells = this.allSells.slice(0, sliceNum).reverse()
            this.sellsProgressiveTotal = this.sells[0] ? this.sells[0].progressiveTotal : 0
            while (this.sells.length < sliceNum) {
              let addType = '';
              this.selectDirectionActive.id == 0 ? addType = "unshift" : addType = "push";
              this.sells[addType]({
                amount: "--",
                index: "--",
                price: "--",
                progressiveTotal: "--",
                total: "--",
              })
            }
            if (msg.data.buys.list) {
              this.allBuys = msg.data.buys.list;
            } else {
              this.allBuys = [];
            }
            /* 买盘 */
            this.buys = this.allBuys.slice(0, sliceNum)
            this.buysProgressiveTotal = this.buys[this.buys.length - 1] ? this.buys[this.buys.length - 1].progressiveTotal : 0
            while (this.buys.length < sliceNum) {
              this.buys.push({
                amount: "--",
                index: "--",
                price: "--",
                progressiveTotal: "--",
                total: "--",
              })
            }
            this.latestDone = msg.data.latestDone.price;
            this.rose = msg.data.latestDone.rose;
            // this.$refs.tradeList.apiData()
          }
        }
        if (msg.type == 2) {
          this.socket.send(JSON.stringify({
            tag: 'HEART',
            content: msg.data,
            id: 'market_001'
          }))
        }
      };
    },
    /* 出现错误 */
    onerrorWS() {
      this.socket.close()
      if (this.socket.readyState !== 3) {
        this.socket = null
        console.log("重连了");
        this.initSocket()
      }
    },
    /* 关闭时 */
    oncloseWS(evn) {
      console.log(evn);

    },
    /* 关闭弹出层 */
    closeTradeList() {
      this.tradeListShow = false;
    },
    /* 价格加减 */
    buyMoneyAdd(flag) {
      const buyMoney = Number(this.buyMoney);
      if (flag) {
        this.buyMoney = this.cutOutPointLength(this.accAdd(buyMoney, this.minBetAdd), this.dishDefault.priceExchange)
      } else {
        this.buyMoney = this.cutOutPointLength(this.Subtr(buyMoney, this.minBetAdd), this.dishDefault.priceExchange)
      }
    },
    accAdd(arg1, arg2) {
      var r1, r2, m, c;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      c = Math.abs(r1 - r2);
      m = Math.pow(10, Math.max(r1, r2));
      if (c > 0) {
        var cm = Math.pow(10, c);
        if (r1 > r2) {
          arg1 = Number(arg1.toString().replace(".", ""));
          arg2 = Number(arg2.toString().replace(".", "")) * cm;
        } else {
          arg1 = Number(arg1.toString().replace(".", "")) * cm;
          arg2 = Number(arg2.toString().replace(".", ""));
        }
      } else {
        arg1 = Number(arg1.toString().replace(".", ""));
        arg2 = Number(arg2.toString().replace(".", ""));
      }
      return (arg1 + arg2) / m;
    },
    Subtr(arg1, arg2) {
      var r1, r2, m, n;
      try {
        r1 = arg1.toString().split(".")[1].length
      } catch (e) {
        r1 = 0
      }
      try {
        r2 = arg2.toString().split(".")[1].length
      } catch (e) {
        r2 = 0
      }
      m = Math.pow(10, Math.max(r1, r2));
      n = (r1 >= r2) ? r1 : r2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    /* 盘口数据 */
    getPankouChengjiao() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      this.$api.contractmoveBBMarket({
        partnerId: '472437831826935808',
        partnerNo: '1000010001',
        tradeName: this.symbol.symbol,
        depthStep: "APP"
      }).then((res) => {
        /* 盘口基本信息 */
        this.dishDefault = res.data.defaultBBTickerResult.content[0];
        /* 计算最小加价 */
        let num = ['0.'];
        for (let index = 0; index < this.dishDefault.priceExchange - 1; index++) {
          num.push(0)
        }
        num.push(1)
        this.minBetAdd = Number(num.join('') - 0)
        /* 标记价格 */
        this.markedPrice = this.dishDefault.markLast
        /* 现货指数 */
        this.spotIndex = this.dishDefault.indexLast
        /* 小数点 */
        this.disDepthDecimal = res.data.depthDecimal
        /* 买卖价格 */
        this.latestDone = res.data.depthResult.latestDone.price;
        /* 最近成交 */
        this.disTradeList = res.data.tradeList;
        /* 默认交易价格 */
        this.buyMoney = Number(res.data.depthResult.latestDone.price);
        /* 显示文本 */
        this.disDepthDecimal.forEach(item => {
          if (this.lang == "zh_TW") {
            item.text = item.chineseName
          } else {
            item.text = item.englishName
          }
        })
        /* 默认小数位 */
        this.disDepthDecimalActive = this.disDepthDecimal.find(item => item.default == true)

        /* 涨跌幅 */
        this.rose = res.data.depthResult.latestDone.rose;

        /* 盘口显示的数据 */

        /* 需要截取多少位 */
        const sliceNum = this.selectDirectionActive.id == 0 ? 7 : 14;
        /* 卖盘 */
        this.sells = []
        this.buys = []
        try {
          if (res.data.depthResult.sells && res.data.depthResult.sells.list.length != 0) {
            this.allSells = res.data.depthResult.sells.list;
          } else {
            this.allSells = [];
          }

          this.sells = this.allSells.slice(0, sliceNum).reverse()
          this.sellsProgressiveTotal = this.sells[0] ? this.sells[0].progressiveTotal : 0

          while (this.sells.length < sliceNum) {
            let addType = '';
            this.selectDirectionActive.id == 0 ? addType = "unshift" : addType = "push";
            this.sells[addType]({
              amount: "--",
              index: "--",
              price: "--",
              progressiveTotal: "--",
              total: "--",
            })
          }

          /* 买盘 */
          if (res.data.depthResult.buys && res.data.depthResult.buys.list.length != 0) {
            this.allBuys = res.data.depthResult.buys.list;
          } else {
            this.allBuys = [];
          }

          this.buys = this.allBuys.slice(0, sliceNum)
          this.buysProgressiveTotal = this.buys[this.buys.length - 1] ? this.buys[this.buys.length - 1].progressiveTotal : 0
          while (this.buys.length < sliceNum) {
            this.buys.push({
              amount: "--",
              index: "--",
              price: "--",
              progressiveTotal: "--",
              total: "--",
            })
          }
        } catch (error) {
          console.log(error)
        }
        Toast.clear();
        this.isLoading = false
        /* 初始化socket */
        if (!this.socket) {
          this.initSocket()
        } else {
          this.changeSocket()
          // this.$refs.tradeList.getUpdata()
        }
      })
    },
    /* 改变socket订阅-先取消订阅 */
    changeSocket() {
      this.socket.send(JSON.stringify({
        tag: 'CANCEL',
        content: this.tradeSymbol,
        id: 'ios'
      }))
      this.socket.send(JSON.stringify({
        tag: 'CANCEL',
        content: this.dishSymbol,
        id: 'ios'
      }))
      /* 订阅新的 */
      this.sendInitSymbol()
    },
    /* 当前交易对是否收藏 */
    isCollection() {
      if (this.isLogin) {
        this.$api.isCollection({
          partnerId: '472437831826935808',
          partnerNo: '1000010001',
          tradeId: this.symbol.symbol
        }).then((res) => {
          this.isCollectionFlag = res.data;
        })
      }
    },
    /* 当前交易对 用户余额信息 */
    getTradeAsset() {
      if (this.isLogin) {
        this.$api.getTradeAsset({
          partnerId: '472437831826935808',
          partnerNo: '1000010001',
          tradeId: this.symbol.partnerTradeId
        }).then((res) => {
          this.asset = res.data
        })
      }
    },
    /* 收藏或取消 */
    collectionCoin() {
      if (!this.isLogin) {
        this.$router.push("login")
        return
      }
      Toast.loading({
        message: this.$t('M.loading'),
        forbidClick: true,
        duration: 0,
      });
      this.$api.collection({
        partnerId: '472437831826935808',
        partnerNo: '1000010001',
        tradeId: this.symbol.symbol
      }).then((data) => {
        if (data.data.status == "cancel") {
          this.$notify(this.$t("M.user_collection_cencel_success"))
        } else {
          this.$notify(this.$t("M.user_collection_insert_success"))
        }
        this.isCollection()
        Toast.clear();
      })
    },
    /* 查询委托选择框 */
    queryEntrustSelectBox() {
      this.$api.queryEntrustSelectBox({
        partnerId: '472437831826935808',
        partnerNo: '1000010001'
      }).then((res) => {
        this.entrustSelectData = res.data;
      })
    },
    /* 选择委托类型*/
    selectEntrust(idx) {
      this.fullWarehouseActive = idx;
      this.fullWarehouseShow = false;
      this.getLongShortNum()
    },
    /* 选择委托类型*/
    selectEntrust2(idx) {
      this.priceOrderActive = idx;
      this.priceOrderShow = false;
    },
    /* 选择委托类型*/
    selectEntrust3(idx) {
      this.premiumLimitOrderActive = idx;
      this.premiumLimitOrderShow = false;
    },
    /* 选择小数位显示 */
    onSelect() {
      this.selectDecimal = true;
    },
  },
  /* 销毁 */
  beforeDestroy() {
    var qc = setInterval(function () {
    }, 1)
    for (let i = 0; i < qc; i++) {
      clearInterval(i)
    }

    if (this.socket) {
      this.socket.close()
    }
  },

}
</script>

<style lang="scss" scoped>
.trade {
  background: #f8f8fb;
  font-size: 12px;

  .headSelsec {
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ebebf0;
    position: relative;
    background-color: #fff;

    .selectItem {
      width: 85px;
      height: 30px;
      background: #F10E38;
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
    }

    .selectItem1 {
      width: 85px;
      height: 30px;
      background: #ffffff;
      border-radius: 2px;
      font-size: 14px;
      color: #a6abb5;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      border: 1px solid #a6abb5;
      margin-left: 15px;
    }

    .userCenter {
      position: absolute;
      left: 20px;
      width: 30px;
      height: 30px;
    }
  }

  .topBar {
    padding: 15px 15px 0 15px;
    background-color: #fff;
    display: flex;

    .titel {
      // flex: 1;
      display: flex;
      height: 23px;
      font-size: 16px;
      font-weight: 600;
      color: #191b27;
      line-height: 23px;
      align-items: center;

      img {
        width: 17px;
        height: 15px;
        margin-right: 10px;
      }

      .up {
        color: #18ae83;
        font-size: 12px;
        margin-left: 15px;
      }

      .down {
        color: #ED0070;
        font-size: 12px;
        margin-left: 15px;
      }
    }

    .marketBtnBox {
      flex: 1;
      display: flex;
      height: 23px;
      align-items: center;
      flex-direction: row-reverse;
      padding-right: 5px;

      img {
        width: 16px;
        height: 16px;
        margin-left: 25px;
      }
    }
  }

  .main {
    padding: 15px;
    padding-top: 0;
    background-color: #fff;
    display: flex;

    .left {
      flex: 1;
      padding-right: 7.5px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .titel {
        display: flex;
        height: 23px;
        font-size: 16px;
        font-weight: 600;
        color: #191b27;
        line-height: 23px;
        align-items: center;

        img {
          width: 17px;
          height: 15px;
          margin-right: 10px;
        }

        .up {
          color: #18ae83;
          font-size: 12px;
          margin-left: 15px;
        }

        .down {
          color: #ED0070;
          font-size: 12px;
          margin-left: 15px;
        }
      }

      .buyTypeBtnBox {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        flex-direction: column;

        .item {
          width: 100%;
          height: 35px;
          text-align: center;
          line-height: 35px;
          font-size: 14px;
          background-size: 100% 100%;
          color: #fff;
          border-radius: 20px;
        }

        .buy {
          margin-bottom: 10px;
          background-color: #18AE83;
        }

        .sell {
          background-color: #ED0070;
        }
      }

      .orderTypeSelect {
        width: 165px;
        height: 30px;
        margin-top: 10px;
        position: relative;
        padding: 0px 10px;
        font-size: 13px;
        color: #191b27;
        line-height: 30px;
      }

      .br {
        //border-radius: 20px;
      }

      .multi-position {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .cang {
          display: flex;
          justify-content: space-between;
          padding: 6px 10px;
          border: 1px solid #c7ccd8;
          border-radius: 20px;
          flex: 1;

          .leftText {
            color: #C8C9CC;
          }

          .right {
            text-align: right;
          }
        }
      }

      .marketOrder {
        width: 100%;
        height: 30px;
        line-height: 12px;
        margin-top: 10px;
        text-align: center;
        border: 1px solid #c7ccd8;
        border-radius: 20px;
        color: #C8C9CC;
        background-color: #F8F8FA;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .buyMoney {
        width: 165px;
        height: 40px;
        margin-top: 10px;
        position: relative;

        .box {
          display: flex;
          width: 61px;
          height: 40px;
          right: 0;

          .itme {
            height: 40px;
            line-height: 38px;
            text-align: center;
          }

          .itme1 {
            flex: 1;
          }
        }
      }

      .buyChange {
        margin-top: 1px;
        font-size: 12px;
        color: #9299a9;
        line-height: 17px;
      }

      .buyNumber {
        margin-top: 10px;
        position: relative;

        .coinnName {
          width: 25px;
          height: 18px;
          font-size: 13px;
          color: #9299a9;
          line-height: 18px;
        }
      }

      .goumaijiage {
        width: 100%;
        border: 1px solid #c7ccd8;
        margin-top: 14px;
        display: flex;

        .btn {
          flex: 1;
          position: relative;
          font-size: 12px;
          white-space: nowrap;
          line-height: 34px;

          .duishou {
            background-color: #F7F7F7;
            //border-radius: 0px 20px 20px 0px;
            padding: 0 8px;
            height: 34px;
          }

          .duishou2 {
            background-color: #EBEAEF;
          }

          .biming {
            position: absolute;
            right: 100%;
            padding-right: 10px;
            top: 0px;
            color: #c7ccd8;
          }
        }
      }

      .buySlect {
        width: 100%;
        margin-top: 17px;
        position: relative;

        .bar {
          padding: 0 2.5px;

          .vantBar {
            padding: 0px 8px;

            .custombutton {
              width: 17px;
              height: 17px;
              background-image: url('../../assets/img/trade/img1.png');
              background-size: 100% 100%;
            }
          }

          .btnBox {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-left: 4px;
            padding-right: 9px;
            top: -4px;
            .item {

              .itemChild {
                width: 8px;
                height: 8px;
                background: #c7ccd8;
                border-radius: 50%;
                margin: 0 auto;
                font-size: 11px;
                color: #5c6573;
                line-height: 15px;
              }

              .itemChildActive {
                background: #00b897;
              }
            }
          }
        }

        .numBar {
          display: flex;
          width: 100%;
          justify-content: space-between;
          text-align: center;
          margin-top: 10px;

          .item {
            flex: 1;
            color: #5c6573;
          }
        }
      }

      .showBuyMoney {
        margin-top: 8px;
        font-size: 13px;
        font-weight: 400;
        color: #5c6573;
        line-height: 19px;
      }

      .buyBtnNow {
        width: 100%;
        height: 40px;
        margin-top: 16px;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 600;

        color: #ffffff;
        line-height: 40px;
        text-align: center;
      }

      .buyBtnNowBuy {
        background: #18ae83;
      }

      .buyBtnNowSell {
        background: #ED0070;
      }

      .buyBtnNowM {
        margin-top: 62px;
      }

      .showUserMoney {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 400;
        color: #5c6573;
        line-height: 20px;
        padding: 10px 0;

        .item {
          display: flex;
          justify-content: space-between;
          // padding: 0 10px;
        }

        .item1 {
          display: flex;
          justify-content: space-between;
          padding: 0 10px;
        }
      }
    }

    .right {
      flex: 1;
      padding-left: 7.5px;

      .markTit {
        display: flex;
        margin-top: 13px;
        color: #5c6573;
        line-height: 22px;
        justify-content: space-between;
      }

      .buyDataBox {
        margin-top: 4px;
        min-height: 135px;

        .item {
          width: 100%;
          height: 20px;
          display: flex;
          font-size: 11px;
          line-height: 20px;
          position: relative;
          transition: all 0.5s;
          justify-content: space-between;

          .price {
            color: #18ae83;
            z-index: 10;
          }

          .num {
            color: #191b27;
            z-index: 10;
          }

          .color-sell-bg {
            position: absolute;
            z-index: 0;
            top: 0;
            right: 0;
            max-width: 100%;
            height: 100%;
            transition: all 0.5s;
            background: #d6ede5 !important;
          }
        }
      }

      .tradBox {
        // height: 355px;
        overflow: hidden;
      }

      .biaojijiage {
        p {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;

          .left {
            color: #5c6573;
          }
        }
      }

      .sellDataBox {
        margin-top: 8px;
        min-height: 135px;

        .item {
          width: 100%;
          height: 20px;
          display: flex;
          font-size: 11px;
          line-height: 20px;
          position: relative;
          transition: all 0.5s;
          justify-content: space-between;

          .price {
            color: #ED0070;
            z-index: 10;
          }

          .num {
            color: #191b27;
            z-index: 10;
          }

          .color-sell-bg {
            position: absolute;
            z-index: 0;
            top: 0;
            right: 0;
            max-width: 100%;
            height: 100%;
            transition: all 0.5s;
            background: #f9dfde !important;
          }
        }
      }

      .nowPrice {
        margin-top: 6px;
        font-size: 16px;
        color: #18ae83;
        line-height: 23px;
      }

      .up {
        color: #18ae83;
      }

      .down {
        color: #ED0070;
      }

      .changePrice {
        font-size: 11px;
        color: #5c6573;
        line-height: 15px;
      }

      .rigehtTop {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-bottom: 10px;
        text-align: right;

        .time {
          display: flex;
          font-size: 14px;
        }
      }

      .selectType {
        display: flex;
        width: 100%;
        //border-radius: 20px;
        border: 1px solid #c7ccd8;
        height: 30px;
        line-height: 30px;

        .item {
          flex: 1;
          position: relative;
          padding-left: 10px;
          font-size: 12px;
          color: #5c6573;
        }

        .item1 {
          border-right: 1px solid #c7ccd8;
        }
      }
    }
  }

  .border {
    border: 1px solid #c7ccd8;
    border-radius: 2px;
  }

  .jiantou {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 5px;
  }

  .entrustSelectBox {
    .content {
      .item {
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        border-bottom: 1px solid #eceef3;
      }

      .item1 {
        color: #00b897;
      }
    }
  }

  .percent {
    //height: 20px;
    margin-top: 10px;
    .progress-container {
      background: white;
      width: 100%;
      border-radius: 4px;
    }

    .progress-bar {
      display: flex;
      height: 30px;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #eee;
      padding: 1px;
    }

    .progress-segment {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #495666;
      font-size: 12px;
      font-weight: bold;
      transition: background-color 0.3s ease;
      flex: 1;
      border-radius: 4px;
    }

    .progress-segment-active {
      background-color: #00b897;
      color: #fff;
    }
  }

}

.selectDecimalPop {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: transparent;

  .selectDecimalPopShowBox {
    position: absolute;
    right: 54px;
    top: 100px;
    width: 92px;
    max-height: 240px;
    overflow-y: scroll;
    overflow-x: visible;
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    border-radius: 3px;

    .jiantou {
      position: absolute;
      width: 20px;
      height: 10px;
      right: 8px;
      top: 0;
    }

    .contant {
      width: 100%;
      background-color: #fff;
      padding: 0 20px;

      .item {
        line-height: 51px;
        text-align: center;
      }

      .item1 {
        color: #00b897;
      }
    }
  }

  .selectDecimalPopShowBox::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }
}

.boxHome {
  width: 82px;
  max-height: 260px;
  overflow-y: scroll;
  text-align: center;

  .selectItem {
    text-align: center;
    width: 82px;
  }

  .active {
    .van-cell__value--alone {
      color: #00b897 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
::v-deep .trade {
  .van-cell::after {
    border-bottom: 0px;
  }
}

::v-deep .van-cell {
  padding: 5px 10px;
  background-color: transparent;
}

::v-deep .buyMoney {
  .van-cell {
    padding: 8px 10px;
  }
}

::v-deep .van-divider {
  margin: 0;
}

.van-cell::after {
  border-bottom: 0px;
}

.popopContont {
  width: 70vw;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;

  .title {
    color: #000;
    font-size: 16px;
  }

  .conttant {
    color: #333;
    margin: 20px 0;
  }

  .btn {
    width: 100%;
    color: #fff;
    background-color: #00b897;
    padding: 8px 0;
    border-radius: 20px;
  }
}
</style>
