<template>
  <div class="authenticationVideo container">
    <div id="commonhead" class="commonhead comheadmenu " style="display: block;">
      <img @click="back" class="commonhead-icon" src="../../assets/img/public/ic_back.png" /> <!-- ../../assets/img/back.png -->
      <span class="commonhead-tit">{{$t("M.identity_authentication")}}</span>
    </div>

    <div class="auth-title">
      {{$t("M.user_senior_upload3")}}
    </div>

    <div class="idcard-box">
      <van-uploader :after-read="afterRead" @click-upload="upload(1)">
        <img class="idcard-f" :src="img1" @load='loadImg' />
      </van-uploader>
    </div>

    <div class="auth-title">
      {{$t("M.user_senior_text1")}}
      <!-- <p class="mgt12">{{$t("M.need_me_record_video")}}</p>
      <p>{{$t("M.Toggle_front_camera")}}</p>
      <p>{{$t("M.mandarin")}}<span class="r-content">{{$t("M.bear_consequences_your_investment")}} {{ vcode }} ”</span></p> -->
      <p>1. {{$t("M.user_senior_text2")}}</p>
      <p>2. {{$t("M.user_senior_text3")}}</p>
      <p>3. {{$t("M.user_senior_text1")}} <span v-if="cardFlag == 1">{{ $t('M.user_senior_text5') }}</span><span
          v-if="cardFlag == 2">{{ $t('M.user_passport') }}</span><span v-if="cardFlag == 3">{{ $t('M.Driver_ID') }}</span>
        {{ $t('M.user_senior_text6') }}
        <span v-if="cardFlag == 1" class="r-content">{{ $t('M.user_ID_card') }}</span><span v-if="cardFlag == 2" class="r-content">
          {{ $t('M.user_passport') }}</span><span v-if="cardFlag == 3"
          class="r-content">{{ $t('M.Driver_ID') }}</span>{{ $t('M.user_senior_text11') }} <span
          class="r-content">{{ $t('M.user_senior_text9') }}</span>
        {{ $t('M.user_senior_text8') }} <span class="r-content">{{ $t('M.user_senior_text7') }}</span> {{ $t('M.user_senior_text10') }}
        {{ $t('M.user_senior_text12') }} <span class="r-content">{{ $t('M.user_senior_text13') }}{{ $t('M.user_senior_text14') }}</span>
      </p>

    </div>
    <!-- <div class="auth-title-tip">
      {{$t("M.upload_information_as_required")}}
    </div> -->
    <van-button class="btn-commit" color="#00b897" @click="send">{{$t("M.confirm_submission")}}</van-button>
  </div>
</template>

<script>
/**
 * Authentication组件
 */
import { Toast } from 'vant';
export default {
  name: 'authenticationVideo',
  data() {
    return {
      vcode: "***",
      videoImage: require("@/assets/img/userCenter/video_fpage.png"),
      videoImageUrl: '',
      cardFlag: '1',
      img1: require("@/assets/img/userCenter/chi.png"),
      fileUrl: '',
      addFlag: ''
    }
  },

  mounted() {

    this.$api.getVerityInformation().then((res) => {
      console.log(res)
      this.cardFlag = res.data.authInfo.cardType;
    })
  },

  methods: {
    upload(flag) {
      this.addFlag = flag;
    },
    loadImg() {
      Toast.clear();
    },
    // 将base64转换为file文件
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, {
        type: mime
      })
    },
    /* 读取文件后 */
    afterRead(file) {

      let that = this;
      let canvas = document.createElement('canvas') // 创建Canvas对象(画布)
      let context = canvas.getContext('2d')
      let img = new Image()
      img.src = file.content // 指定图片的DataURL(图片的base64编码数据)
      /* 加载到上传文件后 */
      img.onload = () => {
        canvas.width = 1280
        canvas.height = 720
        context.drawImage(img, 0, 0, 1280, 720)
        file.content = canvas.toDataURL(file.file.type, 0.8) // 0.92为默认压缩质量
        /* 昨晚处理 */
        let files = that.dataURLtoFile(file.content, file.file.name)
        Toast.loading({
          forbidClick: true,
          duration: 0,
        });

        let formData = new FormData()
        formData.append('file', files)
        formData.append('type', 'other')

        that.$api.uploadfile(formData).then((res) => {
          if (res.meta.success) {
            this.fileUrl = res.data.fileUrl
            this.img1 = res.data.fileUrl
            Toast.clear();
          } else {
            Toast.clear();
            that.$notify(that.$t('M.upload_again'))
          }
        })

      }
    },
    getVideoBase64(url) {
      return new Promise(function (resolve, reject) {
        let dataURL = '';
        let video = document.createElement("video");
        video.setAttribute('crossOrigin', 'anonymous');//处理跨域
        video.setAttribute('src', url);
        video.setAttribute('autoplay', true);
        video.setAttribute('width', 400);
        video.setAttribute('height', 240);
        video.setAttribute('preload', 'auto');
        video.addEventListener('canplay', function () {
          let canvas = document.createElement("canvas"),
            width = video.width, //canvas的尺寸和图片一样
            height = video.height;
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
          dataURL = canvas.toDataURL('image/jpeg'); //转换为base64
          resolve(dataURL);
        });
      })
    },
    back() {
      this.$router.go(-1);//返回上一层
    },
    send() {
      if (this.fileUrl == '') {
        this.$notify(this.$t("M.record_the_video"))
      } else {
        let params = {
          idcardFront: localStorage.getItem('addImg1'),
          idcardBack: localStorage.getItem('addImg2'),
          idcardHand: this.fileUrl,
        }
        this.$api.advancedAuthH5(params).then((res) => {
          if (res.meta.success) {
            this.$store.dispatch('getUserData')
            this.$router.replace({ name: 'identity' })
          } else {
            Toast.clear();
            this.$notify(this.$t('M.' + res.meta.i18n_code))
          }
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.authenticationVideo {
  padding-top: 44px;
  background-color: #f8f8f8;
  min-height: 100vh;
  padding-bottom: 40px;
}

.commonhead {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 44px;
  line-height: 44px;
  font-size: 2.8vw;
  background: #ffffff;
  color: #191b27;
  text-align: center;
  border-bottom: solid 1px #ebebf0;
}

.commonhead-icon {
  width: 10px;
  margin-top: 14px;
  margin-left: 20px;
  float: left;
}

.commonhead-tit {
  font-size: 4.2vw;
  margin-left: -10vw;
}

.commonhead-user {
  position: absolute;
  width: 6.8vw;
  top: 1.8vw;
  right: 28px;
}

.auth-title {
  color: #5c6573;
  font-size: 14px;
  margin: 15px;
}

.auth-title-tip {
  margin: 15px;
  font-size: 12px;
  color: #00b897;
}

.auth-title p {
  font-size: 12px;
}

.auth-title .mgt12 {
  margin-top: 12px;
}

.loading-img {
  margin-left: 10%;
  margin-top: 32vw;
  width: 80vw;
}

.loading-sp {
  position: absolute;
  width: 30vw;
  top: 50vw;
  left: 50%;
  margin-left: -15vw;
}

.custom-image .van-empty__image {
  width: 120px;
  height: 120px;
}

.idcard-box {
  width: 345px;
  height: 202px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-left: 15px;
}

.idcard-f {
  width: 248px;
  height: 152px;
  margin: 20px 48.5px;
}

.upload-tip {
  width: 100%;
  text-align: center;
  color: #00b897;
  font-size: 14px;
  margin-top: -10px;
  padding: 0;
}

.bottom-sample {
  margin-left: 5px;
}

.s-box {
  position: relative;
  display: inline-block;
  width: 78.5px;
  margin-left: 10px;
}

.sp-img {
  width: 78.5px;
}

.sp-txt {
  display: block;
  width: 78.5px;
  font-size: 13px;
  color: #9299a9;
  text-align: center;
}

.r-content {
  color: #00b897;
}

.btn-commit {
  width: 345px;
  height: 46px;
  margin-left: 15px;
  margin-top: 20px;
}
</style>
