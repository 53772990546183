/*
 * @Author:  
 * @Date: 2022-06-15 17:29:54
 * @LastEditors:  
 * @LastEditTime: 2022-06-15 17:30:12
 * @FilePath: \exchange-h5\src\assets\js\commonFunc.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 *
 * @param s 保留小数点位数
 * @param minOrMax 取大值还是取小值 min 向下取整 max向上取整 round四舍五入
 * @returns {string}
 */
Number.prototype.newToFixed = function newToFixed(s, minOrMax) {
    let size = 'ceil';
    if (minOrMax === 'min') {
        size = 'floor';
    }
    if (minOrMax === 'max') {
        size = 'ceil';
    }
    if (minOrMax === 'round') {
        size = 'round';
    }
    let str = String(Math[size](this * Math.pow(10, s)) / Math.pow(10, s));
    const fixed = str.split('.');
    let fixedLength;
    if (fixed[1] && s > fixed[1].length) {
        fixedLength = s - fixed[1].length
    }
    if (!fixed[1] && s > 0) {
        fixedLength = s;
        str += '.';
    }
    for (let i = 0; i < fixedLength; i++) {
        str += '0';
    }
    return str;
}