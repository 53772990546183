<template>
  <van-dialog v-model="show">
    <div class="title">{{title || $t('M.hint')}}</div>

    <div v-if="message" class="message">{{message}}</div>
    <slot name="content" />

    <div class="icon_close" @click="closed">
      <img src="@/assets/img/assets/icon_close.png" alt="">
    </div>
    <div class="footer_button">
      <div class="button" @click="confirm">{{confirmButtonText || $t('M.affirm') }}</div>
    </div>
  </van-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    confirmButtonText: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
    };
  },

  mounted() {

  },

  methods: {
    closed() {
      this.$emit('closed')

    },
    confirm() {
      this.$emit('confirm')
    }
  },
};
</script>

<style lang="scss" scoped>
.van-dialog {
  width: 270px;
  background: transparent;
  font-family: PingFangSC-Regular, PingFang SC;
  border-radius: 7px;
  // border: 1px solid #2d303d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
::v-deep {
  .van-dialog__header {
    padding-top: 20px;
    font-size: 18px;
    color: #000000;
    background: #fff;
    border: 0;
  }
  .van-dialog__content {
    flex: 1;
    min-height: 50px;
    font-size: 15px;
    background: #fff;
    .van-dialog__message {
      padding: 10px 0;
      font-size: 15px;
    }
    .message {
      flex: 1;
      text-align: center;
      min-height: 45px;
      font-size: 15px;
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .van-dialog__footer {
    display: none;
    height: 50px;
    background: #00b897;

    .van-dialog__confirm {
      height: 50px;
      background: #00b897;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
.title {
  padding-top: 20px;
  font-size: 18px;
  text-align: center;
  color: #000000;
  background: #fff;
}
.footer_button {
  .button {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #00b897;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>