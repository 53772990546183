<template>
  <div class='container'>
    <nav-bar :title="$t('M.exchange')" :showRight="false">
      <router-link class="right" slot="right" to="flashCashRecords">
        {{$t('M.exchange_history')}}
      </router-link>
    </nav-bar>

    <div class="capital_list">
      <div class="capital_list_item" v-for="(item,i) in detailVoList" :key="i">
        <div class="row list_item_tit">
          <van-checkbox v-model="item.checked" checked-color="#00b897" shape="square"> {{ item.exchangeCoinName }}
          </van-checkbox>
        </div>
        <div class="row list_item_tit">
          <van-field v-model="item.totalcount" :border="true"
                     :formatter="formatter" :readonly="!item.checked"
                     :style="{background:!item.checked?'#F8F8FB':'#fff'}" :label="$t('M.exchange_quantity')" type="number" @focus="maxNum = item.total"/>
        </div>

        <div class="row num_wrap">
          <div>
            <span>{{$t('M.available_balance')}}</span>
            <span>{{ item.total || '0'}}</span>
          </div>
          <div>
            <span>{{$t('M.small_exchange_valuation-1')}}</span>
            <span>{{ item.referenceAmount || '0'}}</span>
          </div>
        </div>

      </div>
      <div class="noData" v-if="!detailVoList.length && finished == true">
        <img class="imgNoData" src="@/assets/img/public/nodata.png" alt="" srcset="" />
        <p class="text">{{$t('M.no_data')}}</p>
      </div>
      <div class="footer">
        <div v-if="exchangeRate" class="desc">
          {{$t('M.small_exchange_tip-1',[exchangeRate])}}
        </div>
        <div class="Row">
          <div class="cell">
            <div class="left"> {{$t('M.exchangeable')}} </div>
            <div class="targetSum">{{targetSum}}</div>
            <van-popover v-if="targetCoinName" v-model="showPopover" trigger="click" placement="top" :actions="actions" @select="onSelect">
              <template #reference>
                <div class="sjx">{{targetCoinName}} </div>
              </template>
            </van-popover>
          </div>
          <van-button @click="onShowDialog">{{$t('M.Preview')}}</van-button>
        </div>
      </div>
    </div>
    <Dialog :show="showDialog" :title="$t('M.exchange_details')" :confirmButtonText="$t('M.exchange')" @closed="onClosed" @confirm="onConfirm">
      <div class="content" slot="content">
        <div class="cell">
          <span>{{$t('M.total_exchange')}} </span>
          <span>{{targetCount}} {{targetCoinName}}</span>
        </div>
        <div class="cell">
          <span>{{$t('M.free')}} </span>
          <span>{{ cutOutPointLength(targetCount*exchangeRate/100 ,8)}} {{targetCoinName}}</span>
        </div>
        <div class="cell">
          <span>{{$t('M.actual_exchange_amount')}} </span>
          <span>{{ cutOutPointLength (targetCount - cutOutPointLength(targetCount*exchangeRate/100 ,8),8)}} {{targetCoinName}}</span>
        </div>
      </div>
    </Dialog>
    <!-- 密码验证 -->
    <popup-password :show="show" type="flashCash" @onClose="onClosedPass" @onSubmit="onSubmitPass" />
    <van-overlay :show="showOverlay" />
  </div>
</template>
<script>
import navBar from '@/components/navBar';
import popupPassword from '@/components/popupPassword';
import Dialog from '@/components/dialog';

export default {
  components: { navBar, Dialog, popupPassword },
  props: {},
  data() {
    return {
      detailVoList: [],
      exchangeRate: 0,
      showPopover: false,
      show: false,
      radio: '1',
      target: {},
      ids: [],
      select: [],
      showDialog: false,
      showOverlay: false,
      finished: false,
      targetSum: 0,
      isInputPayPasswd: false,
      actions: [],
      maxNum:null,
      targetCount:0,
      seletData:[]
    };
  },
  computed: {

    targetCoinName() {
      if (this.target.targetCoinName) return this.target.targetCoinName
      return ''
    }
  },
  watch: {
    detailVoList: {
      handler(n) {
        let select = []
        n.length && n.forEach(el => {
          if (el.checked) {
            select.push(el)
          }

        })
        this.select = select
        this.getSum()
        // this.getCount()
      },
      deep: true
    }
  },
  beforeMount() { },
  mounted() {
    this.getWaitExchangeResult()
  },
  methods: {
    formatter(value) {
      if(this.maxNum==null) return value
      if(value[value.length-1] == '.') return value
      return this.maxNum > 0 ? Math.min(value, this.maxNum || 0) : value
    },
    onSelect(action) {
      if (action) {
        this.target = action
        this.getSum()
      }
    },
    onShowDialog() {
      if (!this.targetSum || !this.finished) return
      this.getCount()
      this.showDialog = true
    },
    onClosed() {
      this.showDialog = false
    },
    onConfirm() {
      this.onClosed()
      this.getIsInputPayPasswd()

    },

    onClosedPass() {
      this.show = false
    },
    onSubmitPass(bolb) {
      this.onClosedPass()
      if (bolb.payCode) {
        this.exchange(bolb.payCode)
      }
    },


    getSum() {
      let sum = 0
      if (this.select.length && this.target.targetCoinId) {
        this.select.forEach(item => {
          item.targetVoList.forEach(el => {
            if (el.targetCoinId == this.target.targetCoinId) {
              sum += Number(el.targetAmount)
            }
          })
        })
      }
      this.targetSum = this.cutOutPointLength(sum, 8)
    },
    getCount() {
      let arr = []
      this.select.forEach(item => {
        this.detailVoList.forEach(el => {
          if (item.exchangeCoinId == el.exchangeCoinId) {
            arr.push({
              coinId: el.exchangeCoinId,
              amount: el.totalcount,
            })
          }
        })
      })
      this.seletData = arr
      this.$api.queryExchangeResult(arr).then((res) => {
        let sum = 0
        res.data.detailVoList.forEach(item => {
          item.targetVoList.forEach(el => {
            if (el.targetCoinId == this.target.targetCoinId) {
              sum += Number(el.targetAmount)
            }
          })
        })
        this.targetCount = this.cutOutPointLength(sum, 8)
      })
    },
    getIsInputPayPasswd() {
      this.$api.isInputPayPasswd().then((response) => {
        if (!response.meta.success) return
        this.isInputPayPasswd = response.data
        if (this.isInputPayPasswd) {
          this.show = true
        } else {
          this.exchange('1234')
        }
      })
    },
    // 获取闪兑信息
    getWaitExchangeResult() {
      this.$api.getWaitExchangeResult().then((response) => {
        this.finished = true
        // this.loading = false
        if (!response.meta.success) return
        const { detailVoList, exchangeRate } = response.data
        this.exchangeRate = exchangeRate
        if (!detailVoList.length) {
          this.target = {}
          this.detailVoList = []
          this.actions = []
          return
        }
        this.target = {}
        this.detailVoList = []
        this.actions = []
        this.maxNum =null
        detailVoList.forEach(element => {
          element.totalcount = element.total
          element.checked = false
          element.targetVoList.forEach(item => {
            item.text = item.targetCoinName
            if (item.targetCoinName == 'USDT') {
              this.target = item
            }
          })
        });
        this.detailVoList = detailVoList
        this.actions = this.detailVoList[0] && this.detailVoList[0].targetVoList || []
      })
    },

    // 确认闪兑
    exchange(password) {
      this.showOverlay = true
      setTimeout(() => {
        this.showOverlay = false
      }, 1500);
      const ids = this.select.map((item) => (item.exchangeCoinId))
      let list = []
      this.seletData.forEach((item, index) => {
        list.push(item.coinId+'@'+item.amount)
      })
      const params = { targetCoinId: this.target.targetCoinId, password: password, coinIds: ids.join(','),list:list.toString() }
      this.$api.exchange(params).then((response) => {
        if (!response.data) return
        if (!response.meta.success) return
        this.$notify(response.meta.message)
        setTimeout(() => {
          this.getWaitExchangeResult()
        }, 500);
      })
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-cell {
  border: 1px solid #C7CCD8;
  // padding: 5px;

}
.capital_list {
  padding-bottom: 130px;
  .capital_list_item {
    padding: 15px;
    border-bottom: 1px solid #ebebf0;
    .list_item_tit {
      margin: 2px 0 10px;
    }
    .row {
      display: flex;
      font-size: 18px;
      font-weight: 400;
      color: #191b27;
      align-items: center;
    }
    img {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 4px;
    }
    .num_wrap {
      justify-content: space-around;
      > div {
        height: 46px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: #5c6573;
      }
      > div:nth-child(2) {
        align-items: flex-end;
      }
    }
  }
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;

  .Row {
    height: 80px;
    padding: 10px 15px 0;
    background: #f8f8fb;
    display: flex;
    justify-content: space-between;
    .cell {
      width: 255px;
      height: 40px;
      border-radius: 4px;
      padding: 0 20px 0 10px;
      border: 1px solid #c7ccd8;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        color: #131520;
      }
      .targetSum {
        flex: 1;
        color: #9299a9;
        margin-left: 8px;
        word-break: break-all;
      }
      .sjx {
        color: #5c6573;
      }
    }
    .van-button {
      width: 80px;
      height: 40px;
      // line-height: 40px;
      background: #00b897;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
    }
  }

  .desc {
    width: 100%;
    padding: 0 15px;
    font-size: 12px;
    font-weight: 400;
    color: #9299a9;
    margin-bottom: 15px;
  }
}

.content {
  padding: 15px;
  .cell {
    height: 40px;
    font-size: 13px;
    font-weight: 400;
    color: #131520;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

::v-deep {
  .van-checkbox {
    height: 30px;
  }
}
</style>
