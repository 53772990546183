<template>
  <div class="container">
    <nav-bar :title="$t('M.ddress_management')" :showRight="false">
      <router-link slot="right" class="right" :to="{ path:'adressAdd',query:{coinId: this.query.coinId }}">
        <img src="@/assets/img/assets/icon_address.png" alt="">
      </router-link>
    </nav-bar>

    <!-- 列表 -->
    <div class="main">
      <div class="list">
        <van-swipe-cell class="item" v-for="(item,i) in userWithdrawAddress" :key="i">
          <div @click="onClickItem(item)">
            <div class="address">{{item.remark}}</div>
            <div class="address">
              {{item.address}}
            </div>
            <div class="address">{{$t("M.user_link_name")}}:{{item.rechargeType}}</div>
          </div>
          <template #right>
            <van-button square :text="$t('M.Delete_option')" type="danger" class="delete-button" @click="onClickDelete(item.id,i)" />
          </template>
        </van-swipe-cell>
      </div>
      <div class="noData" v-if="!userWithdrawAddress.length">
        <img class="imgNoData" src="@/assets/img/public/nodata.png" alt="" srcset="" />
        <p class="text">{{$t('M.no_data')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from '@/components/navBar';
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'addressList',
  components: {
    navBar
  },
  computed: {
    ...mapGetters([
      'userWithdrawAddress'
    ]),
    ...mapMutations(['SET_SELECT_ADDRESS']),

  },
  data() {
    return {
      query: {},
      finishedText: '',

    };
  },
  beforeMount() {
    Object.assign(this.query, this.$route.query);
  },
  mounted() {
  },

  methods: {
    onClickItem(item) {
      this.$store.commit('SET_SELECT_ADDRESS', item)
      this.$router.back()
    },
    onClickDelete(id, i) {
      this.$api.deleteUserWithdrawAddress({ id }).then((response) => {
        if (!response.meta.success) return
        this.$notify(response.meta.message)
        const list = JSON.parse(JSON.stringify(this.userWithdrawAddress)) || []
        list.splice(i)
        this.$store.commit('SET_WITHDRAW_ADDRESS', list)
      })
    }
  },

};
</script>

<style lang="scss" scoped>
.container {
  background: #f8f7fc;
  .right {
    width: 20px;
    height: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.list {
  width: 100%;
  padding: 0 15px;
  .item {
    width: 100%;
    height: 87px;
    background: #ffffff;
    border-radius: 4px;
    font-size: 14px;
    color: #5c6573;
    margin-top: 10px;
    padding: 0 0 0 15px;

    .address {
      width: 315px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      height: 22px;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep {
  .van-swipe-cell__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .delete-button {
    height: 100%;
    background: #00b897;
    border: none;
  }
}
</style>
