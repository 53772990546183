<!--
 * @Author: LAPTOP-00G5PKII\kiko
 * @Date: 2022-05-11 14:36:04
 * @LastEditors:
 * @LastEditTime: 2022-06-26 16:39:07
 * @FilePath: \cxexh5\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <keep-alive v-if="isRouterAlive">
      <router-view v-if="this.$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!this.$route.meta.keepAlive && isRouterAlive" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getToken, setToken } from '@/utils/auth'
import Clipboard from 'clipboard'

export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'password', "isLogin",
    ]),
    ...mapMutations(['SET_TOKEN']),
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  created() {
    const token = this.$route.query.token || getToken()
    this.$store.commit('SET_TOKEN', token)
    setToken(token)
    this.$store.dispatch('getUserData')
  },
  mounted() {
    this.initClipboard()
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      })
    },

    // 初始化复制
    initClipboard() {
      const clipboard = new Clipboard('.icon-copy')
      clipboard.on('success', () => {
        this.$notify(this.$t('M.copy_content'))
      })
    },
  }
};
</script>
<style lang="scss" src="./assets/style/common.scss"></style>
<style lang="scss" src="./assets/style/vant.scss"></style>
