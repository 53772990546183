<template>
  <div class="container assetRecords">
    <nav-bar :title="query.title" :showRight="false">
      <router-link class="" slot="right" :to="{path:'/walletFlow',query: { title: $t('M.assets_wallet_funds_flow')  }}">
        {{$t('M.assets_cash_flow')}}
      </router-link>
    </nav-bar>
    <!-- <van-sticky> -->
    <div class="capital_top">
      <div class="capital_top_title">
        <span>{{$t('M.assets_reduced_asset')}}</span>
        <img class="icon" v-show="show" src="@/assets/img/assets/icon_eyeoon.png" alt="" @click="onClickShow">
        <img class="icon icon_eyeoff" v-show="!show" src="@/assets/img/assets/icon_eyeoff.png" alt="" @click="onClickShow">
      </div>
      <div class="total row" :style="totalStyle">
        <span>{{show? walletData && walletData.amountCNY || '0':'******'}}</span>
        <span v-show="show" class="info">{{currencySymbol}}</span>
      </div>
      <div class="button_wrap row">
        <div class="button" @click="onClickCB">{{$t('M.Charge_money')}}</div>
        <div class="button" @click="onClickTB">{{$t('M.recharge_withdraw_msg_type_0002')}}</div>
<!--        <div class="button" @click="onClickHZ">{{$t('M.Transfer')}}</div>-->
      </div>
      <!-- 闪兑 -->
<!--      <router-link class="flash_cash" to="flashCash"> {{$t('M.exchange')}} </router-link>-->

    </div>
    <!-- </van-sticky> -->
    <div class="capital_list">
      <!-- 隐藏小额币种 -->
      <hide-search-coin @onChecked="onChecked" @onSearch="onSearch" :top="4.9" :searchCoinList="coinList" />

      <div :class="[active == i && activeShow?'capital_list_item_active' :'' , 'capital_list_item']" v-for="(item,i) in searchCoinList" :key="i"
        @click="onClickItem(i)">
        <div class="row list_item_tit">
          <div class="row">
            <img :src="item.logo" alt="">
            {{item.coinName}}
          </div>
          <div class="col">
            <span>{{$t('M.Available')}}</span>
            <span>{{show? item.total || '0':'****'}}</span>
          </div>

        </div>
        <div class="row num_wrap">
          <div>
            <span>{{$t('M.Financing')}}</span>
            <span>{{show?item.financing || '0':'****'}}</span>
          </div>
          <div>
            <span>{{$t('M.freeze')}}</span>
            <span>{{show?item.frozen || '0':'****'}}</span>
          </div>
          <div>
            <span>{{$t('M.locked')}}</span>
            <span>{{show?item.wareHouse || '0':'****'}}</span>
          </div>
        </div>
        <div v-show="activeShow && active == i" class="button_wrap_active">
          <div class="row matchmaking">
            <span>{{$t("M.user_coin_bring_together")}}</span>
            <span>{{show? `${item.amount || '0'}≈${item.cnyValue>0? cutOutPointLength(item.cnyValue/rate,2,true) : '0.00'}` :'****'}}
              {{currencySymbol}}</span>
          </div>
          <div class="button_wrap row">
            <div v-if="item.recharge" class="button" @click.stop="onClickCB(item)">{{ $t('M.Charge_money') }}</div>
            <div v-else class="button button_disable" @click.stop="onClickStop">{{ $t('M.Charge_money') }}</div>
            <div v-if="item.withdraw" class="button" @click.stop="onClickTB(item)">
              {{ $t('M.recharge_withdraw_msg_type_0002') }}
            </div>
            <div v-else class="button button_disable" @click.stop="onClickStop">
              {{ $t('M.recharge_withdraw_msg_type_0002') }}
            </div>
            <div class="button" @click.stop="onClickHZ(item)">{{$t('M.Transfer')}}</div>
          </div>
        </div>

      </div>

      <Dialog :show="dialog.showDialog" :message="dialog.showMessage" :confirmButtonText="$t('M.go_setting')" @closed="onClosed" @confirm="onConfirm">
      </Dialog>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import navBar from '@/components/navBar';
import Dialog from '@/components/dialog';
import hideSearchCoin from '@/components/hideAndSearchCoin';
import {GetOs} from '@/utils/ststemTool'

export default {
  name: 'assetRecords',
  components: {
    navBar, Dialog, hideSearchCoin
  },
  computed: {
    ...mapGetters([
      'rate', 'walletData', 'user', 'coinList', 'currencySymbol'
    ]),

    totalStyle() {
      if (GetOs() == 'iPhone') {
        return 'align-items: center;'
      }
      return 'align-items: flex-end;'
    }
  },
  data() {
    return {
      query: {},
      show: true,
      active: '',
      activeShow: true,

      amountCNY: '',
      dialog: {
        showDialog: false,
        showMessage: '',
        path: ''
      },

      isTotalGtZero: false,
      searchCoinList: [],
      filterArr:[]
    };
  },
  created() {
    this.$store.dispatch('getUserWalletAssets')

  },
  beforeMount() {
    Object.assign(this.query, this.$route.query);
    this.searchCoinList = JSON.parse(JSON.stringify(this.coinList))
  },

  async mounted() {
    const filterArr = await this.$api.filterLockCoin()
    this.filterArr = filterArr.data
  },

  methods: {


    onClickShow() {
      this.show = !this.show
    },

    onClickItem(i) {

      if (this.active == i) {
        this.activeShow = !this.activeShow
      } else {
        this.activeShow = true
        this.active = i
      }

    },

    onClickStop() { },
    // 充币
    onClickCB(item) {
      if (item && item.recharge) {
        this.$router.push({ path: '/chargingAddress', query: { coinName: item.coinName, coinId: item.coinId, type: 'recharge' } })
      } else {
        this.$router.push({ path: '/recharging', query: { type: 'recharge' } })
      }
    },
    // 提币
    onClickTB(item) {
      if (item.coinId) {
        if (!item.withdraw) return this.$notify(this.$t('M.pause_withdraw'))
        // if (this.user.realNameAuth != "y") {
        //   this.dialog.showDialog = true
        //   this.dialog.showMessage = this.$t('M.user-fail-not-realname')
        //   this.dialog.path = "/identity"
        //   return
        // }
        if (!this.user.payPassword) {
          this.dialog.showDialog = true
          this.dialog.showMessage = this.$t('M.setting_paypassword')
          this.dialog.path = "/setExchangePwd"
          return
        }
        if (item.realNameWithdrawAmount > 0) {
          return this.$router.push({ path: '/withdrawal', query: { coinName: item.coinName, coinId: item.coinId, type: 'withdraw' } })
        }
        // if (item.realNameWithdrawAmount <= 0 || this.user.advancedAuth != "pass") {
        //   this.dialog.showDialog = true
        //   this.dialog.showMessage = this.$t('M.no_Senior_certification')
        //   this.dialog.path = "/identity"
        //   return
        // }
        this.$router.push({ path: '/withdrawal', query: { coinName: item.coinName, coinId: item.coinId, type: 'withdraw' } })
      } else {
        // if (this.user.realNameAuth != "y") {
        //   this.dialog.showDialog = true
        //   this.dialog.showMessage = this.$t('M.user-fail-not-realname')
        //   this.dialog.path = "/identity"
        //   return
        // }
        this.$router.push({ path: '/recharging', query: { type: 'withdraw' } })
      }
    },
    // 划转
    onClickHZ(item) {
      if(item.coinId && this.filterArr.includes(item.coinId)){
        this.$notify(this.$t("M.locked_warehouse"))
        return
      }
      if (item) {
        this.$router.push({ path: '/transfer', query: { coinName: item.coinName, coinId: item.coinId, type: 'transfer' } })
      } else {
        this.$router.push({ path: '/transfer', query: { type: 'transfer' } })
      }
    },
    onClosed() {
      this.dialog.showDialog = false
      setTimeout(() => {
        this.dialog = {
          showDialog: false,
          showMessage: '',
          path: ''
        }
      }, 500);
    },
    onConfirm() {
      this.onClosed()
      setTimeout(() => {
        if (this.dialog.path) {
          this.$router.push(this.dialog.path)
        }
      }, 100);

    },


    // 隐藏小于0.001币种
    onChecked(e) {
      this.isTotalGtZero = e
      this.$store.dispatch('getUserWalletAssets', this.isTotalGtZero ? 'noall' : 'all').then(() => {
        this.searchCoinList = JSON.parse(JSON.stringify(this.coinList))
      })

    },
    // 币种搜索
    onSearch(list) {
      this.searchCoinList = list
    }


  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
}
.flexC {
  justify-content: center;
  align-items: center;
}
.assetRecords {
  height: 100%;
  overflow-y: scroll;
}
.button_wrap {
  justify-content: space-around;
  align-items: center;
  .button {
    width: 100px;
    line-height: 24px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #fff;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
  }
}
.capital_top {
  position: fixed;
  top: 50px;
  z-index: 999;
  width: 100%;
  padding: 20px 15px;
  // background: url('~@/assets/img/assets/bg_capital.png') no-repeat center;
  // background-size: cover;
  background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  .capital_top_title {
    width: 100%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
      margin-right: 20px;
    }
    .icon {
      width: 18px;
    }
    .icon_eyeoff {
      width: 18px;
      height: 8px;
    }
  }

  .total {
    height: 40px;
    font-weight: bold;
    justify-content: center;
    align-items: flex-end;
    //margin-bottom: 20px;
    > span:first-child {
      font-size: 30px;
      line-height: 1;
      font-family: DINCondensed-Bold, DINCondensed;
    }
    > span.info {
      font-size: 13px;
      font-weight: 600;
      margin-left: 5px;
    }
  }
}

.capital_list {
  // margin-top: 168px;
  margin-top: 213px;
  padding-bottom: 50px;
  .capital_list_item {
    padding: 15px;
    border-bottom: 1px solid #ebebf0;
    .list_item_tit {
      margin: 2px 0 10px;
      justify-content: space-between;
      .col {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 12px;
        font-weight: 400;
        color: #5c6573;
      }
    }
    .row {
      font-size: 16px;
      font-weight: 400;
      color: #191b27;
      align-items: center;
    }
    img {
      width: 18px;
      height: 18px;
      border: 1px solid;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 4px;
    }
    .num_wrap {
      justify-content: space-around;
      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: #5c6573;
        height: 40px;
      }
      > div:nth-child(2) {
        align-items: center;
      }
      > div:nth-child(3) {
        align-items: flex-end;
      }
    }
    .button_wrap {
      .button {
        background: #00b897;
      }
      .button_disable {
        background: #ccc;
      }
    }
  }
  .capital_list_item_active {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    background: #f8f8fb;
    .button_wrap_active {
      border-top: 1px solid #ebebf0;
      margin-top: 15px;
      .matchmaking {
        height: 30px;
        font-size: 10px;
        color: #999;
        justify-content: space-between;
        margin-bottom: 15px;
      }
      .button_wrap {
        .button {
          color: #fff;
          border: none;
          opacity: 1;
        }
      }
    }
  }
}
::v-deep .van-sticky--fixed {
  top: 50px;
}

.flash_cash {
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
  height: 37px;
  line-height: 37px;
  // background: rgba(160, 94, 13, 0.5);
  background: linear-gradient(90deg, #87c5fc 0%, #00b897 100%);
  border-radius: 0px 5px 0px 5px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}
</style>
