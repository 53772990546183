<!-- home -->
<template>
  <div class="setExchangePwd container">
    <nav-bar class="nav_bar" :title="!user.payPassword ? $t('M.Set_transaction_password') : $t('M.Reset_transaction_password')" />
    <!-- <div id="commonhead" class="commonhead comheadmenu " style="display: block;">
      <img @click="back" class="commonhead-icon" src="../../assets/img/public/ic_back.png" />
      <span class="commonhead-tit">{{$t("M.Set_transaction_password")}}</span>
    </div> -->

    <!-- 修改支付密码 -->
    <div v-if="user.payPassword" class="section">
      <div class="main-content">
        <div class="top-functional">
          <van-cell-group inset>
            <van-field v-model="payPassword" type="password" :label="$t('M.new_pass')" :placeholder="$t('M.digits6')" maxlength="6"
              :formatter="formatterNum" />
            <van-field v-model="rePwd" type="password" :label="$t('M.affirm_pass')" :placeholder="$t('M.again_digits6')" maxlength="6"
              :formatter="formatterNum" />

            <!-- 短信验证码 -->
            <van-field v-if="isPhoneEnable=='enable'" v-model="phoneCode" :label="$t('M.phone_code')" :placeholder="$t('M.login_please_input1')"
              :formatter="formatterNum">
              <van-button class="button" slot="button" @click="onClickSend(1)" :disabled="disabledCode">{{valiBtn}}</van-button>
            </van-field>
            <!-- 邮箱验证码 -->
            <van-field v-if="isMailEnable=='enable'" v-model="emailCode" :label="$t('M.email_code')" :placeholder="$t('M.input_email_code')"
              :formatter="formatterNum">
              <van-button class="button" slot="button" @click="onClickSend(2)" :disabled="disabledCode2">{{valiBtn2}}</van-button>
            </van-field>
            <!-- 谷歌验证码 -->
            <van-field v-if="isGoogleEnable=='enable'" v-model="googleCode" :label="$t('M.Google_code')" :placeholder="$t('M.user_please_input9')"
              :formatter="formatterNum">
            </van-field>

          </van-cell-group>
        </div>
      </div>
      <div class="bottom-tip tips">
        {{$t('M.Not_to_mention_money')}}
      </div>
      <div class="box">
        <van-button @click="onClickSubmit" class="btn-commit" color="#00b897">{{$t('M.affirm')}}</van-button>
      </div>
    </div>
    <!-- 设置支付密码 -->
    <div v-if="!user.payPassword" class="section">
      <div class="main-content">
        <div class="top-functional">
          <van-cell-group inset>
            <van-field v-model="payPassword" type="password" :label="$t('M.Set_transaction_password')" :placeholder="$t('M.digits6')"
              input-align="right" maxlength="6" :formatter="formatterNum" />
            <van-field v-model="rePwd" type="password" :label="$t('M.affirm_pass')" :placeholder="$t('M.again_digits6')" input-align="right"
              maxlength="6" :formatter="formatterNum" />
            <van-field v-model="nickName" type="text" :label="$t('M.nick_name')" :placeholder="$t('M.input_nike_name')" input-align="right" />

          </van-cell-group>
        </div>
        <div class="bottom-tip">
          <!-- 提示：手機號修改成功后需要使用新的手機號進行登錄 -->
        </div>
        <div class="box">
          <van-button @click="goPhoneChangeNew" class="btn-commit" color="#00b897">{{$t('M.affirm')}}</van-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import navBar from '@/components/navBar';

export default {
  components: {
    navBar,
  },
  data() {
    return {
      nickName: '',
      payPassword: '',
      rePwd: '',
      phoneCode: '',
      emailCode: '',
      googleCode: '',
      valiBtn: this.$t("M.get_code"),
      disabledCode: false,
      valiBtn2: this.$t("M.get_code"),
      disabledCode2: false,

      isPhoneEnable: 'disable',
      isMailEnable: 'disable',
      isGoogleEnable: 'disable',
    }
  },

  computed: {
    ...mapGetters([
      'user'
    ])
  },
  created() {
    this.nickName = this.user.nickName
    this.statusSecurityCenter()

  },

  mounted() {
  },

  methods: {

    resetForm() {
      this.nickName = ''
      this.payPassword = ''
      this.rePwd = ''
      this.phoneCode = ''
    },
    // 获取绑定验证信息
    statusSecurityCenter() {
      this.$api.statusSecurityCenter().then((response) => {
        if (!response.meta.success) return
        const { isGoogleBind, isGoogleEnable, isMailBind, isMailEnable, isPhoneBind, isPhoneEnable } = response.data
        // this.isPhoneBind = isPhoneBind
        this.isPhoneEnable = isPhoneEnable
        // this.isMailBind = isMailBind
        this.isMailEnable = isMailEnable
        // this.isGoogleBind = isGoogleBind
        this.isGoogleEnable = isGoogleEnable
      })
    },

    // 设置交易密码
    goPhoneChangeNew() {
      if (!this.payPassword) return this.$notify(this.$t('M.entrust_failure_0010'))
      if (this.payPassword.length < 6) return this.$notify(this.$t('M.digits6'))
      if (this.payPassword !== this.rePwd) return this.$notify(this.$t('M.ios_两次密码不一致请再次输入密码'))
      if (!this.nickName) return this.$notify(this.$t('M.input_nike_name'))
      this.$api.payPassword(this.payPassword, this.nickName).then((response) => {
        if (!response.meta.success) return
        this.$notify(response.meta.message)
        this.resetForm()
        this.$store.dispatch('getUserData')
        this.$router.back()
      })
    },

    formatterNum(value) {
      return value.replace(/[^0-9]/g, '')
    },
    // 验证码倒数60秒
    tackBtn(i) {
      if (i == 1) {
        let time = 59
        const timer = setInterval(() => {
          if (time === 0) {
            clearInterval(timer)
            this.valiBtn = this.$t('M.get_code')
            this.disabledCode = false
          } else {
            this.disabledCode = true
            if (time < 10) {
              this.valiBtn = '0' + time + 's'
            } else {
              this.valiBtn = time + 's'
            }
            time--
          }
        }, 1000)
      }
      if (i == 2) {
        let time2 = 59
        const timer2 = setInterval(() => {
          if (time2 === 0) {
            clearInterval(timer2)
            this.valiBtn2 = this.$t('M.get_code')
            this.disabledCode2 = false
          } else {
            // this.loadingCode2 = false
            this.disabledCode2 = true
            if (time2 < 10) {
              this.valiBtn2 = '0' + time2 + 's'
            } else {
              this.valiBtn2 = time2 + 's'
            }
            time2--
          }
        }, 1000)
      }
    },

    // 发送验证码
    onClickSend(i) {
      const action = 'CHANGE_TRADERS_PASS'
      const { phone, country, partnerId, email } = this.user

      if (i == 1) { // 短信验证码
        const params = {
          action: action,
          phone: phone,
          nationCode: country,
          partnerId: partnerId
        }
        this.$api.sendSms(params).then((response) => {
          if (!response.meta.success) return
          this.$notify(response.meta.message)
          this.tackBtn(i)
        })
      }
      if (i == 2) {// 邮箱验证码
        const params = {
          action: action,
          email: email,
          partnerId: partnerId
        }
        this.$api.sendMail(params).then((response) => {
          if (!response.meta.success) return
          this.$notify(response.meta.message)
          this.tackBtn(i)
        })
      }



    },
    // 确认修改交易密码
    onClickSubmit() {
      if (!this.payPassword) return this.$notify(this.$t('M.new_pass_empty'))
      if (this.payPassword.length < 6) return this.$notify(this.$t('M.digits6'))
      if (this.payPassword !== this.rePwd) return this.$notify(this.$t('M.ios_两次密码不一致请再次输入密码'))

      // if (this.isPhoneEnable == 'disable' && !this.phoneCode) return

      const params = {
        phoneCode: this.phoneCode,
        payPassword: this.payPassword,
        emailCode: this.emailCode,
        googleCode: this.googleCode,
      }
      this.$api.updatePayPassword(params).then((response) => {
        if (!response.meta.success) return
        this.$notify(response.meta.message)
        this.resetForm()
        this.$store.dispatch('getUserData')
        this.$router.back()
      })
    },


  }
}
</script>
<style lang="scss" scoped>
.setExchangePwd {
  // min-height: 100vh;
  // width: 100vw;
  background-color: #f8f8f8;

  .navbar-handle {
    width: 18px;
  }

  .van-hairline--bottom::after {
    border-bottom-width: 0rem;
  }

  .main-content {
    width: 100vw;
    height: 100%;
    // padding: 30px;
    display: flex;
    display: -webkit-flex; /* Safari */
    flex-direction: column;
  }

  .top-functional {
    width: 92vw;
    min-height: 134px;
    margin-left: 4vw;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .sec-functional {
    width: 92vw;
    height: 108px;
    margin-left: 4vw;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .item-cell {
    height: 52.5px;
    background-color: transparent;
    padding: 14.5px;

    .receipt-icon {
      width: 14px;
      height: 14px;
      margin-top: 5px;
    }

    .receipt-title {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    .receipt-content {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    .item-title {
      color: #191b27;
    }

    .item-custom-bx {
      position: relative;
      display: inline-block;
      float: right;
      color: #9299a9;
    }

    .ic-notice {
      left: -20px;
      top: 4px;
      width: 16px;
      height: 16px;
      position: absolute;
    }

    .van-cell__value {
      color: #9299a9;
    }
  }

  .item-cell.top-cell {
    height: 70px;
  }

  .tip-txt {
    margin: 12px 15px;
    font-size: 12px;
    color: #5c6573;
  }

  .item-cell::after {
    border-bottom: 1.5px solid #ebebf0;
  }

  .cell-level {
    width: 100%;

    .level-bx {
      width: 100%;
    }

    .level-tit {
      display: inline-block;
      font-size: 15px;
    }

    .level-tip {
      display: inline-block;
      font-size: 12px;
      color: #00b897;
      float: right;
    }

    .level-bar {
      display: inline-block;
      width: 98.33px;
      height: 4px;
      background-color: #d5d5d5;
    }

    .level-bar.selected {
      background-color: #00b897;
    }

    .mg-lft {
      margin-left: 10px;
    }
  }
}

.phone-label {
  font-size: 15px;
  color: #191b27;
}

.btn-vcode {
  font-size: 15px;
  color: #00b897;
}

.van-cell::after {
  background-color: #ebebf0;
}
.box {
  width: 100%;
  display: flex;
}
.btn-commit {
  width: 345px;
  height: 46px;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
}

// 提示
.bottom-tip {
  color: #5c6573;
  font-size: 10px;
  padding: 0 15px 0 35px;
}
.tips {
  margin: 15px 0;
  position: relative;
  &::before {
    position: absolute;
    left: 15px;
    top: 0;
    content: '!';
    display: inline-block;
    width: 10px;
    height: 10px;
    color: #e95252;
    border-radius: 50%;
    border: 1.5px solid;
    font-weight: 600;
    margin-right: 5px;
    transform: translateY(1px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
::v-deep {
  .van-cell {
    padding: 10px 0;
  }
  .van-field__button {
    display: flex;
    align-items: center;
    .van-button {
      height: 100%;
      padding: 0;
      border: none;
      color: #00b897;
      background-color: transparent;
    }
    .van-button--disabled {
      color: #00b897;
    }
  }

  .van-field__label {
    color: #191b27;
  }
}
</style>
