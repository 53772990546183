<template>
  <div class="tradeList">
    <van-tabs v-model="active" line-width="16px" line-height="3px" title-active-color="#00b897"
      title-inactive-color="#5C6573" color="#00b897" @change="changeBar" >
      <van-tab title-class="titItem" :title="$t('M.Open orders')+'('+EntCount+')'">
        <!-- 二级tab页 -->
        <div class="Subset">
          <van-tabs class="SubsetBar" v-model="activeSubset" line-width="16px" line-height="3px" title-active-color="#00b897"
              title-inactive-color="#5C6573" color="#F10E38"  @change="changeBarSubset">
              <van-cell style="font-size:12px;">
                <template #title>
<!--                  <van-checkbox icon-size="12px" v-model="Variety"  shape="square"><span>{{$t('M.Instrument')}}</span></van-checkbox>-->
                </template>
                <template #right-icon>
                  <span @click="orderShow = true" style="color: #F10E38;">
                    <van-icon name="qr" />
                    {{$t('M.Cancel all')}}
                  </span>
                </template>
              </van-cell>
              <van-tab title-class="titItem" name="DEFAULT" :title="$t('M.Market')">
                <!-- LimitAndMarket -->
                <!-- <van-list v-model="loading" :finished="finished" @load="onLoad" loading="loading"> -->
                  <div class="box" @click="SelectPopup(item,'DEFAULT')" v-for="(item,index) in defaultList" :key="index">
                    <van-row class="row"  gutter="5">
                      <van-col span="22"><span class="Sub title">{{item.tradeName}}</span></van-col>
                      <van-col span="2"><span @click.stop="YcancelEntrust(item,'DEFAULT')" class="Sub title"><van-icon color="#F10E38" name="share" /></span></van-col>
                    </van-row>
                    <van-row gutter="5">
                      <van-col class="margin" span="24">
                        <van-tag color="#D6EDE5" text-color="#18ae83" v-if="item.tradeMode === 'OPEN_UP'">{{tradeMode(item.tradeMode)}}</van-tag>
                        <van-tag color="#f9dfde" text-color="#F10E38" v-else>{{tradeMode(item.tradeMode)}}</van-tag>
                        <van-tag color="#f8f8f8" text-color="#5C6573">{{positionsType(item.positionsType)}}</van-tag>
                        <span class="Sub">{{item.lever}}X</span>
                        <span>{{item.entrustTime}}</span>
                      </van-col>
                    </van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.Order amt')}}：</span><span class="Sub">{{item.count}}{{ item.sellCoinName }}</span></van-col>
                      <van-col span="12"><span>{{$t('M.Order price')}}：</span><span class="Sub">{{item.price}}</span></van-col></van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.deal_done')}}：</span> <span class="Sub">{{item.completeCount}}{{ item.sellCoinName }}</span></van-col>
                      <van-col span="12"><span>{{$t('M.deal_price')}}：</span><span class="Sub">{{item.completePrice | completePrice}}</span></van-col>
                    </van-row>
                  </div>
                  <img v-if="defaultList.length == 0 && finished" class="imgNoData" src="../../../assets/img/public/nodata.png" alt="" srcset="">
                  <p v-if="defaultList.length == 0 && finished" class="textNoData">{{ $t('M.no_data') }}</p>
                <!-- </van-list> -->
              </van-tab>
              <van-tab v-if="false" title-class="titItem" name="ADVANCED_PRICE_LIMIT" :title="$t('M.advanced_limit_order')">
                <!-- <van-list v-model="loading" :finished="finished" @load="onLoad" loading="loading"> -->
                  <div class="box" @click="SelectPopup(item,'ADVANCED_PRICE_LIMIT')" v-for="(item,index) in ADVANCEDList" :key="index">
                    <van-row class="row"  gutter="5">
                      <van-col span="22"><span class="Sub title">{{item.tradeName}}</span></van-col>
                      <van-col span="2"><span @click.stop="YcancelEntrust(item,'DEFAULT')"  class="Sub title"><van-icon color="#F10E38" name="share" /></span></van-col>
                    </van-row>
                    <van-row gutter="5">
                      <van-col span="24" class="margin">
                        <van-tag color="#e7f7f2" text-color="#F10E38">{{tradeMode(item.tradeMode)}}</van-tag>
                        <van-tag color="#f8f8f8" text-color="#5C6573">{{positionsType(item.positionsType)}}</van-tag>
                        <span class="Sub">{{item.lever}}X</span>
                        <span>{{item.entrustTime}}</span>
                      </van-col>
                    </van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.Order amt')}}：</span><span class="Sub">{{item.count}}{{ item.sellCoinName }}</span></van-col>
                      <van-col span="12"><span>{{$t('M.Order price')}}：</span><span class="Sub">{{item.price}}</span></van-col></van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.deal_done')}}：</span> <span class="Sub">{{item.completeCount}}{{ item.sellCoinName }}</span></van-col>
                      <van-col span="12"><span>{{$t('M.deal_price')}}：</span><span class="Sub">{{item.completePrice | completePrice}}</span></van-col>
                    </van-row>
                  </div>
                  <img v-if="ADVANCEDList.length == 0 && finished" class="imgNoData" src="../../../assets/img/public/nodata.png" alt="" srcset="">
                  <p v-if="ADVANCEDList.length == 0 && finished" class="textNoData">{{ $t('M.no_data') }}</p>
                <!-- </van-list> -->
              </van-tab>
              <van-tab title-class="titItem" name="TP_SL" :title="$t('M.TP | SL')">
                <div class="box" @click="SelectPopup(item,'TP_SL')" v-for="(item,index) in dTP_SLList" :key="index">
                    <van-row class="row"  gutter="5">
                      <van-col span="22"><span class="Sub title">{{item.tradeName}}</span></van-col>
                      <van-col span="2"><span @click.stop="YcancelEntrust(item,'TP_SL')" class="Sub title"><van-icon color="#F10E38" name="share" /></span></van-col>
                    </van-row>
                    <van-row gutter="5">
                      <van-col span="24" class="margin" >
                        <van-tag v-if="item.tradeMode!='CLOSE_DOWN'" color="#e7f7f2" text-color="#F10E38">{{tradeMode(item.tradeMode)}}</van-tag>
                        <van-tag v-else color="#ffefef" text-color="#e9697e">{{tradeMode(item.tradeMode)}}</van-tag>
                        <van-tag color="#f8f8f8" text-color="#5C6573">{{positionsType(item.positionsType)}}</van-tag>
                        <span class="Sub">{{item.lever}}X</span>
                        <span>{{item.createTime}}</span>
                      </van-col>
                    </van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.TP trigger price')}}：</span><span class="Sub">{{(item.tpTriggerPrice-0).toFixed(2)}}</span></van-col>
                      <van-col span="12"><span>{{$t('M.TP order price')}}：</span><span class="Sub">{{item.tpSavePrice!=0?item.tpSavePrice:$t('M.Market')}}</span></van-col></van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{ $t('M.SL trigger price') }}：</span> <span class="Sub">{{(item.slTriggerPrice-0).toFixed(2)}}</span></van-col>
                      <van-col span="12"><span>{{ $t('M.SL order price') }}：</span><span class="Sub">{{item.slSavePrice!=0?item.slSavePrice:$t('M.Market')}}</span></van-col>
                    </van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.Order amt')}}：</span><span class="Sub">{{item.count}}{{ item.sellCoinName }}</span></van-col>
                    </van-row>
                  </div>
                  <img v-if="dTP_SLList.length == 0 && finished" class="imgNoData" src="../../../assets/img/public/nodata.png" alt="" srcset="">
                  <p v-if="dTP_SLList.length == 0 && finished" class="textNoData">{{ $t('M.no_data') }}</p>
              </van-tab>


        </van-tabs>
        <div class="SubsetBarSub" @click="activeSubsetShow2 = true">{{$t('M.all')}} <van-icon name="arrow" /></div>
        </div>
      </van-tab>
      <van-tab title-class="titItem" :title="$t('M.Position')+'('+positionList.length+')'">
              <van-cell style="font-size:12px;">
                <template #title>
                  <van-checkbox icon-size="12px" v-model="Variety"  shape="square"><span>{{$t('M.Instrument')}}</span></van-checkbox>
                </template>
                <template #right-icon>
                  <span @click="activeSubsetShow7 = true">
                    {{$t('M.all')}}<van-icon name="arrow" />
                  </span>
                </template>
              </van-cell>
        <!-- <van-list v-model="loading" :finished="finished" @load="onLoad" loading="loading"> -->
                  <div  class="box"   v-for="(item,index) in filteredPositionList" :key="index">

                    <van-row class="row"  gutter="5">
                      <van-col span="22"><span class="Sub title">{{item.tradeName}}</span></van-col>
<!--                      <van-col span="2"><span @click.stop="YcancelEntrust(item,'DEFAULT')" class="Sub title"><van-icon color="#00b897" name="share" /></span></van-col>-->
                    </van-row>
                    <van-row gutter="5">
                      <van-col span="24" class="margin" >
                        <van-tag v-if="item.openType!='OPEN_DOWN'" color="#e7f7f2" text-color="#18ae83">{{tradeMode2(item.openType)}}</van-tag>
                        <van-tag v-else color="#ffefef" text-color="#ED0070">{{tradeMode2(item.openType)}}</van-tag>
                        <van-tag color="#f8f8f8" text-color="#5C6573">{{positionsType(item.positionsType)}}</van-tag>
                        <van-tag @click="sraterData=item,activeSubsetShow3 =true" color="#f8f8f8" text-color="#5C6573">{{item.lever}}X<van-icon color="#00b897" name="edit" /></van-tag>
                      </van-col>
                    </van-row>
                    <van-row >
                      <van-col class="left" span="8"><span>{{$t('M.PL')}}({{item.buyCoinNickname}})</span></van-col>
                      <van-col class="center" span="8"><span>{{$t('M.Open interest')}}({{item.sellCoinNickname}})
                        <van-icon @click="explanatoryData(item)" name="question-o" />
                      </span></van-col>
                      <van-col class="right" v-if="item.positionsType=='BY'" span="8"><span>{{$t('M.Margin-1')}}({{item.buyCoinNickname}})</span></van-col>
                      <van-col class="right" v-else span="8"><span>{{$t('M.Initial margin')}}({{item.buyCoinNickname}})</span></van-col>
                    </van-row>
                    <van-row>
                      <van-col class="left" span="8"><span class="Sub"
                                                           :class="Math.sign(item.allIncomeAmount) == -1?'red':'gre'">
                        {{ Math.sign(item.allIncomeAmount) == -1 ? '' : '+' }}{{
                          item.allIncomeAmount
                        }} ({{
                          Math.sign(item.allIncomeAmount) == -1 ? '' : '+'
                        }}{{ item.allIncomeAmountRate }}%)</span></van-col>
                      <van-col class="center" span="8"><span class="Sub">{{item.holdAmount}}</span></van-col>

                      <!-- <van-col v-if="item.positionsType=='BY'" span="8"><span class="Sub">{{item.margin}}</span></van-col> -->
                      <van-col class="right"  span="8"><span class="Sub Sub2">{{item.positionAmount}}</span></van-col>
                    </van-row>
                    <van-row >
                      <van-col class="left" span="8"><span>{{$t('M.Avg open price')}}({{item.buyCoinNickname}})</span></van-col>
                      <van-col class="center" span="8"><span>{{$t('M.Margin ratio')}}</span></van-col>
                      <van-col v-if="item.positionsType=='BY'" class="center" span="8"><span>{{$t('M.Est liquidation price')}}</span></van-col>
<!--                  <van-col class="center" span="8"><span>{{$t('M.Est liquidation price')}}</span></van-col>-->
                    </van-row>
                    <van-row >
                      <van-col class="left" span="8"><span class="Sub">{{item.openPrice}}</span></van-col>
                      <van-col class="center" span="8"><span class="Sub">{{(item.positionRate-0).toFixed(4)}}%</span></van-col>
                      <van-col v-if="item.positionsType=='BY'" class="right" span="8"><span class="Sub">{{item.anPreForcePrice}}{{item.buyCoinNickname}}</span></van-col>
                    </van-row>
                    <van-row v-if="item.positionsType!='BY'" gutter="5">
                      <van-col span="6"><van-button @click="sraterData=item,activeSubsetShow5 =true" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.TP | SL')}}</span></van-button></van-col>
                      <van-col span="6"><van-button @click="sraterData=item,activeSubsetShow6 =true" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.Pingclose')}}</span></van-button></van-col>
                      <van-col span="6"><van-button @click="sraterData=item,activeSubsetShow8 =true" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.MKT Close ALL')}}</span></van-button></van-col>
                      <van-col span="6">
                        <van-button @click="showActionShow(item)" color="#cde3f7" block size="small"><span
                            style="color: #00b897;">{{ $t('M.OneClickBackhand') }}</span></van-button>
                      </van-col>    </van-row>
                    <van-row v-else gutter="5">
                      <van-col span="6"><van-button @click="sraterData=item,activeSubsetShow4 =true" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.Margin-2')}}</span></van-button></van-col>
                      <van-col span="6"><van-button @click="sraterData=item,activeSubsetShow5 =true" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.TP | SL')}}</span></van-button></van-col>
                      <van-col span="6"><van-button @click="sraterData=item,activeSubsetShow6 =true" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.Pingclose')}}</span></van-button></van-col>
                      <van-col span="6"><van-button @click="sraterData=item,activeSubsetShow8 =true" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.MKT Close ALL')}}</span></van-button></van-col>
                    </van-row>
                  </div>

                  <img v-if="positionList.length == 0 && finished" class="imgNoData" src="../../../assets/img/public/nodata.png" alt="" srcset="">
                  <p v-if="positionList.length == 0 && finished" class="textNoData">{{ $t('M.no_data') }}</p>
                <!-- </van-list> -->
      </van-tab>
      <van-tab title-class="titItem" :title="$t('M.tab_assets')">
        <!-- <van-list v-model="loading" :finished="finished" @load="onLoad" loading="loading"> -->
                  <div class="box" v-for="(item,index) in property.accountList" :key="index">
                    <van-row class="row"  gutter="5">
                      <van-col span="24" style="display: flex;"><img style="width: 20px;height: 20px;margin-right: 5px;" :src="property.coinlogs[index]" alt=""><span class="Sub title">{{item.coinName}}</span></van-col>
                    </van-row>
                    <van-row >
                      <van-col span="10"><span>{{$t('M.Equity')}}</span></van-col>
                      <van-col span="6"><span>{{$t('M.occupy')}}</span></van-col>
                      <van-col class="right" span="8"><span>{{$t('M.usable')}}</span></van-col>
                    </van-row>
                    <van-row >
                      <van-col span="10"><span class="Sub">{{item.accountRights}}</span></van-col>
                      <van-col span="6"><span class="Sub">{{item.usePosition}}</span></van-col>
                      <van-col class="right" span="8"><span class="Sub">{{item.enablePosition}}</span></van-col>
                    </van-row>
                    <van-row >
                      <van-col span="10"><span>{{$t('M.UPL')}}</span></van-col>
                      <van-col span="6"><span>{{$t('M.Margin ratio')}}</span></van-col>
                      <van-col class="right" span="8"><span>{{$t('M.Leverage11')}}</span></van-col>
                    </van-row>
                    <van-row >
                      <van-col span="10"><span class="Sub">{{item.unRealizationIncome}}</span></van-col>
                      <van-col span="6"><span class="Sub">{{item.positionRate}}%</span></van-col>
                      <van-col class="right" span="8"><span class="Sub">{{item.realLevel}}</span></van-col>
                    </van-row>
                  </div>
        <!-- </van-list> -->
      </van-tab>
    </van-tabs>
    <van-dialog v-model="orderShow" :title="$t('M.Cancel all')"  show-cancel-button @confirm="orderConfirm" @cancel="orderCancel" :confirm-button-text="$t('M.affirm')" confirm-button-color="#00b897" :cancel-button-text="$t('M.Cancel')">
      <div class="box" style="padding: 10px 20px;">
      <van-row class="row"  gutter="5">
        <van-col class="left" span="12"><span >{{$t('M.Order type')}}</span></van-col>
        <van-col class="right" span="12"><span class="Sub" >{{$t('M.all')}}</span></van-col>
      </van-row>
      <van-row class="row"  gutter="5">
        <van-col class="left" span="12"><span >{{$t('M.Trade type')}}</span></van-col>
        <van-col class="right" span="12"><span class="Sub" >{{$t('M.all')}}</span></van-col>
      </van-row>
      <van-row class="row"  gutter="5">
        <van-col class="left" span="12"><span >{{$t('M.Max')}}</span></van-col>
        <van-col class="right" span="12"><span class="Sub" >{{$t('M.10 orders')}}</span></van-col>
      </van-row>
    </div>
    </van-dialog>
    <van-dialog v-model="activeSubsetShow8" :title="$t('M.Close all of')+sraterData.tradeName+tradeMode(sraterData.openType)+sraterData.lever+'X'+$t('M.position at market price?')"
    show-cancel-button @confirm="entrustAll" :confirm-button-text="$t('M.affirm')" confirm-button-color="#00b897" :cancel-button-text="$t('M.Cancel')">
      <div class="box" >
      <van-row class="row"  gutter="5">
        <van-col class="left" span="24"><span >{{$t('M.lf there are pending')}}</span></van-col>
      </van-row>
    </div>
    </van-dialog>
    <van-dialog v-model="activeSubsetShow9"
     @confirm="activeSubsetShow9 = false" :confirm-button-text="$t('M.affirm')" confirm-button-color="#00b897" >
      <div class="box" style="padding:20px">
      <van-row class="row"  gutter="5">
        <van-col class="left" span="12"><span >{{$t('M.Total cont')}}</span></van-col>
        <van-col class="left" span="12"><span >{{$t('M.Total coin')}}</span></van-col>
      </van-row>
      <van-row class="row"  gutter="5">
        <van-col class="left" span="12"><span class="Sub">{{explanatory.hasPositionNum}}</span></van-col>
        <van-col class="left" span="12"><span class="Sub">{{explanatory.holdAmount}}{{ explanatory.sellCoinNickname }}</span></van-col>
      </van-row>
      <van-row class="row"  gutter="5">
        <van-col class="left" span="12"><span >{{$t('M.Total value')}}</span></van-col>
      </van-row>
      <van-row class="row"  gutter="5">
        <van-col class="left" span="12"><span class="Sub">${{explanatory.hasPositionVal}}</span></van-col>
      </van-row>
    </div>
    </van-dialog>
    <van-popup v-model="activeSubsetShow1" position="bottom"  :style="{ height: '100%' }">
      <listInfo  v-if="activeSubsetShow1" :itemData="sraterData" :keytype="infoKey" :history="historyShown" @dreg="activeSubsetShow1 = false"></listInfo>
    </van-popup>
    <van-popup v-model="activeSubsetShow2" position="bottom"  :style="{ height: '100%' }">
      <wholeList ref="wholeList" :latestDone="latestDone" v-if="activeSubsetShow2" @dreg="activeSubsetShow2 = false" @dialog="orderShowFun" @popup="SelectPopup"></wholeList>
    </van-popup>
    <van-popup v-model="activeSubsetShow3" closeable round position="bottom">
      <certain v-if="activeSubsetShow3" @update="BinListData" :itemData="sraterData" @dreg="apiData(activeSubsetShow3 = false)"></certain>
    </van-popup>
    <van-popup v-model="activeSubsetShow4" closeable round position="bottom">
      <Margin v-if="activeSubsetShow4" @update="BinListData" @upMoney="getUserMoney" :itemData="sraterData" @dreg="apiData(activeSubsetShow4 = false)"></Margin>
    </van-popup>
    <van-popup v-model="activeSubsetShow5" closeable round position="bottom">
      <stopPhase  v-if="activeSubsetShow5" @update="BinListData" :latestDone="latestDone" :itemData="sraterData" @dreg="apiData()" @dregs="activeSubsetShow5 = false"></stopPhase>
    </van-popup>
    <van-popup v-model="activeSubsetShow6" closeable round position="bottom">
      <Closing v-if="activeSubsetShow6" @update="BinListData" @upMoney="getUserMoney" :latestDone="latestDone" :itemData="sraterData" @dreg="apiData(activeSubsetShow6 = false)"></Closing>
    </van-popup>
    <van-popup v-model="activeSubsetShow7" position="bottom"  :style="{ height: '100%' }">
      <BinList :userActualPositionAccountList="userActualPositionAccountList" :tradeListUpDataItem="tradeListUpDataItem" :tradeList="tradeList" ref="BinList" v-if="activeSubsetShow7" @dreg="activeSubsetShow7 = false" @popup="contentDialog"></BinList>
    </van-popup>
    <van-popup v-model="shareShow" closeable round position="bottom">
      <share :latestDone="latestDone" :item="shareItem" ref="share" v-if="shareShow" @dreg="shareShow = false" @popup="YcancelEntrust"></share>
    </van-popup>
    <van-action-sheet v-model="actionShow" :title="$t('M.OneClickBackhand')">
      <div class="reverseContent">
        <van-divider/>
        <div class="fanShouTitle">
          <div class="leftName">{{ reverseData.tradeName }}</div>
<!--          <div class="rightPirce">{{ latestDone }}{{ reverseData.buyCoinNickname }}</div>-->
        </div>
        <div class="fanShouSamallTips">
          <div class="biaoQian">
            {{ $t('M.Cross') }}
          </div>
          <div class="fangXiang">
            {{ reverseData.openType === 'OPEN_UP' ? $t('M.MultipleReversalsEmpty') : $t('M.EmptyReversal') }}
          </div>
        </div>
        <div class="nowContent">
          <div class="nowContentTitle">
            <div class="biaoQian biaoQianSell">
              {{ $t('M.Market') }}{{ reverseData.openType === 'OPEN_UP' ? $t('M.Close long') : $t('M.Close short') }}
            </div>
            <div class="beiShu">
              {{ reverseData.lever }}X
            </div>
          </div>
          <div class="nowContentData">
            <div class="dataBox">
              <div class="tit">
                {{ $t('M.Pingclose') }}{{ $t('M.quantity') }}
              </div>
              <div class="data">
                {{ reverseData.holdAmount }} {{ reverseData.sellCoinNickname }}
              </div>
            </div>
            <div class="dataBox">
              <div class="tit">
                {{ $t('M.Order price') }}
              </div>
              <div class="data">
                {{ $t('M.Market') }}
              </div>
            </div>
          </div>
        </div>
        <van-divider>{{ reverseData.openType === 'OPEN_UP' ? $t('M.BackhandOpenEmpty') : $t('M.BackhandOpenLong') }}</van-divider>
        <div class="nowContent">
          <div class="nowContentTitle">
            <div class="biaoQian biaoQianBuy">
              {{ $t('M.Market') }}{{ reverseData.openType === 'OPEN_UP' ? $t('M.Open short') : $t('M.Open long') }}
            </div>
            <div class="beiShu">
              {{ reverseData.lever }}X
            </div>
          </div>
          <div class="nowContentData">
            <div class="dataBox">
              <div class="tit">
                {{ $t('M.Open amount') }}
              </div>
              <div class="data">
                {{ reverseData.holdAmount }} {{ reverseData.sellCoinNickname }}
              </div>
            </div>
            <div class="dataBox">
              <div class="tit">
                {{ $t('M.Order price') }}
              </div>
              <div class="data">
                {{ $t('M.Market') }}
              </div>
            </div>
          </div>
        </div>

        <van-checkbox v-model="checkedShowAgain" shape="square" style="margin: 12px 0;"> {{
            $t("M.DontShowMeAgain")
          }}
        </van-checkbox>

        <van-button round size="large" color="#005DB1" @click="inversionButton"> {{ $t('M.confirm') }}</van-button>
      </div>
    </van-action-sheet>

  </div>
</template>

<script>
import wholeList from "@/components/popupList/wholeList.vue";
import listInfo from "@/components/popupList/listInfo.vue";
import certain from "@/components/popupList/certain.vue";
import Margin from "@/components/popupList/Margin.vue";
import stopPhase from "@/components/popupList/stopPhase.vue";
import Closing from "@/components/popupList/Closing.vue";
import BinList from "@/components/popupList/BinList.vue";
import share from "@/components/share/share.vue";
import {Toast} from 'vant';
import {mapGetters} from "vuex";

export default {
  components: { wholeList,listInfo,certain,Margin,stopPhase,Closing,BinList,share},
  computed: {
    ...mapGetters(["isLogin", "user", "lang", "otherUser"]),
    filteredPositionList() {
      if (this.Variety) {
        return this.positionList.filter(item => item.symbol === this.symbol.symbol);
      } else {
        return this.positionList;
      }
    }
  },
  props: {
    disTradeList: Array,
    symbol: {
      type: Object,
      default:
        () => {
          return {
            collect: true,
            displayStatus: "visible",
            nowCoin: "BTC",
            partnerTradeId: "641201074438406144",
            sellCoinLogo: "https://fubt-3.oss-cn-hongkong.aliyuncs.com/cdbbdbdf-4307-4bf7-a7ee-ea77fca10b0f",
            symbol: "btcusdt",
            targetCoin: "USDT",
            tradeId: "480309422875213824",
            tradeName: "BTC/USDT",
          }
        },
    },
    latestDone:{
      type:Number|null,
      default: 0
    },
    tradeListUpDataItem: {
      type: Object,
      default: () => ({}) // 使用工厂函数返回一个新的对象
    },
    tradeList: {
      type: Array,
      default: () => ([]) // 使用工厂函数返回一个新的对象
    },
  },
  //使用vue计算属性


  data() {
    return {
      shareShow:false,
      shareItem:{},
      active: 0, //选中
      activeSubset:0,//二级子菜单选中
      activePopup:0,//弹窗选中
      checked: false, //显示全部
      loading: false,
      finished: true,
      Variety:false,
      list: [],
      page: 1,
      showPopover:false,
      activeSubsetShow1:false,//内容弹窗
      activeSubsetShow2:false,//列表弹窗
      activeSubsetShow3:false,//修改杠杆
      activeSubsetShow4:false,//调整保证金
      activeSubsetShow5:false,//止盈止损
      activeSubsetShow6:false,//平仓
      activeSubsetShow7:false,//仓位全部
      activeSubsetShow8:false,//市价全平
      activeSubsetShow9:false,//持仓量注释
      orderShow:false,
      actions:[{text: this.$t('M.LimitAndMarket'),},{text: this.$t('advanced_limit_order'),},{text: this.$t('TP | SL'),}],
      actionsContent: this.$t('M.LimitAndMarket'),
      value:1,
      defaultList:[],//限价/市价 list
      ADVANCEDList:[],//高级期限委托 list
      dTP_SLList:[],//止盈止损 list
      positionList:[],//仓位List
      sraterData:'',//详情数据
      infoKey:'',//
      EntCount:0,
      historyShown:null,
      property:{},
      orderType:null,
      socket:null,
      pako: require("pako"),
      userActualPositionAccountList:[],
      explanatory:{},
      actionShow: false,
      checkedShowAgain: false,
      reverseData: {},
    };
  },
  filters:{
    completePrice(str){
      return str!='0'?str:'--'
    }
  },
  mounted() {
    // console.log(this.latestDone);
      this.apiData()
      this.initSocket()
  },
  watch: {
    tradeListUpDataItem:{
      handler(newQuestion, oldQuestion) {
        const tradePairListArr = new Map(this.tradeList.map(item => [item.symbol, item]));
        this.positionList.forEach(item => {
          if(item.symbol == newQuestion.tradeName){
            const matchingItemB = tradePairListArr.get(item.symbol);
            //手续费绿
            let feeMaker = item.openType === "OPEN_UP" ? matchingItemB.openMaker : matchingItemB.openTaker //4.46218025
            //开仓价格
            let openPrice = item.openPrice
            //开仓数量
            let openNumber = item.holdAmount
            //手续费
            let feeNumber = openPrice * openNumber * feeMaker
            if (item.openType === "OPEN_UP") {
              let shouyiNumber = (newQuestion.last - openPrice) * openNumber - feeNumber
              let shouyiNumberBai = (shouyiNumber / (openPrice * openNumber)) * item.lever
              item.allIncomeAmount = shouyiNumber.toFixed(4)
              item.allIncomeAmountRate = (shouyiNumberBai * 100).toFixed(2)
            } else {
              let shouyiNumber = (openPrice - newQuestion.last) * openNumber - feeNumber
              let shouyiNumberBai = (shouyiNumber / (openPrice * openNumber)) * item.lever
              item.allIncomeAmount = shouyiNumber.toFixed(4)
              item.allIncomeAmountRate = (shouyiNumberBai * 100).toFixed(2)
            }
          }
        });
      },
      deep: true
    },
  },
  /* 销毁 */
  beforeDestroy() {
    // console.log("???????");
    // var qc = setInterval(function () { }, 1)
    // for (let i = 0; i < qc; i++) {
    //   clearInterval(i)
    // }
    if (this.socket) {this.socket.close()}
  },
  methods: {
    toInversion() {
      let params = {
        positionId: this.reverseData.id,
        positionsType: this.reverseData.positionsType,
        tradeMode: this.reverseData.openType === "OPEN_UP" ? "CLOSE_UP" : "CLOSE_DOWN",
        closeType: 'MARKET',
        count: '0',
        tradeId: this.reverseData.contractCoinId,
        source: 'IOS'
      }
      this.$api.oneClickReverseOpen(params).then(res => {
        Toast(res.meta.message)
        setTimeout(() => {
          this.actionShow = false
          this.reverseData = {}
          this.apiData()
          this.BinListData()
          this.$emit('getUserMoney')
        },1000)
      })
    },
    inversionButton() {
      if (this.checkedShowAgain) {
        let params = {
          noReminder: true,
        }
        this.$api.confirmReverseOpen(params).then(res => {
          this.$store.dispatch('getUserData')
        })
      }
      this.toInversion()
    },
    showActionShow(data) {
      this.reverseData = data;
      if (this.otherUser.noReminder && this.otherUser.noReminder === 'true') {
        this.toInversion()
      } else {
        this.actionShow = true;
      }
    },
    getUserMoney(){
      this.$emit('getUserMoney')
    },
    explanatoryData(item){
      this.explanatory = item
      this.activeSubsetShow9 = true
    },
    /* 初始化socket */
    initSocket() {
      if (!this.isLogin)return
      const BrowserWebSocket = window.WebSocket || window.MozWebSocket
      this.socket = new BrowserWebSocket(`wss://${this.$apiUrl}/v1/websocket/contract/socket`)
      this.socket.onopen = this.onopenWS
      this.socket.onmessage = this.onmessageWS
      this.socket.onerror = this.onerrorWS
      this.socket.onclose = this.oncloseWS
    },
        /* 打开时 */
    onopenWS() {
      this.sendInitSymbol()
    },
    /* 订阅的socket */
    sendInitSymbol() {
      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: `market.${this.user.id}.USER_CONTRACT_DATA`,
        id: 'android'
      }))
    },
    /* 收到消息 */
    onmessageWS(data) {
      let blob = data.data;
      let reader = new FileReader();
      reader.readAsBinaryString(blob);

      reader.onload = (res) => {
        let text = this.pako.inflate(res.target.result, { to: "string" });
        let msg = JSON.parse(text);
        // console.log(msg);
        // this.userActualPositionAccountList=[]
        if(msg.userActualPositionAccountList!=null) {
          this.userActualPositionAccountList = msg.userActualPositionAccountList
        this.property.accountList[0]['accountRights'] = msg.userActualContractAccountList[0].accountRights.toFixed(4)
        this.property.accountList[0].usePosition = msg.userActualContractAccountList[0].usePosition.toFixed(4)
        this.property.accountList[0].enablePosition = msg.userActualContractAccountList[0].enablePosition.toFixed(4)
        this.property.accountList[0].unRealizationIncome = msg.userActualContractAccountList[0].unRealizationIncome.toFixed(4)
        this.property.accountList[0].positionRate = (msg.userActualContractAccountList[0].positionRate*100).toFixed(4)
        this.property.accountList[0].realLevel = msg.userActualContractAccountList[0].realLevel==null?0:msg.userActualContractAccountList[0].realLevel.toFixed(4)
        msg.userActualPositionAccountList.forEach((v,i)=>{
              this.positionList.forEach((k,j)=>{
                  if (v.contractPositionVo.id == k.id) {
                  this.positionList[j].positionAmount = v.positionAmount.toFixed(4)
                  this.positionList[j].openPrice = v.openPrice
                  this.positionList[j].preForcePrice = v.preForcePrice
                  this.positionList[j].nowTagPrice = v.nowTagPrice
                  this.positionList[j].positionRate = v.positionRate * 100
                  this.positionList[j].holdIncome = v.holdIncome.toFixed(2)
                  this.positionList[j].holdIncomeRate = ((v.holdIncomeRate*100).toFixed(2))+'%'
                }
              })
        })
      }
        // this.positionList = msg.userActualPositionAccountList
        // if (msg.type == 1) {
        //   if (msg.tradeType == 'TICKER') {
        //     this.markedPrice = msg.data.markLast
        //     this.spotIndex = msg.data.indexLast
        //   }
        // }
      };
    },
    /* 出现错误 */
    onerrorWS() {
      this.socket.close()
      if (this.socket.readyState !== 3) {
        this.socket = null
        this.initSocket()
      }
    },
    /* 关闭时 */
    oncloseWS() {
      if (this.socket.readyState !== 2 && this.closeType !== 1) {
        this.socket = null
        this.initSocket()
      }
    },
    //触发兄弟组件更新
    BinListData(){
      if(!this.activeSubsetShow7)return
      this.$refs.BinList.apiData();
    },
    orderShowFun(key){
      this.orderShow=true
      this.orderType = key
    },
    entrustAll(){
      if (!this.isLogin)return
      let params = {
          positionId:this.sraterData.id,
          positionsType:this.sraterData.positionsType,
          tradeMode:this.sraterData.openType === "OPEN_UP" ? "CLOSE_UP" : "CLOSE_DOWN",
          closeType:'MARKET',
          count:'0',
          tradeId:this.sraterData.contractCoinId,
          source:'IOS'
        }
        this.$api.saveCloseEntrust(params).then(res=>{
          this.apiData()
          this.BinListData()
          this.$emit('getUserMoney')
        })
    },
    apiData(){
      // this.defaultList = []
      // this.ADVANCEDList =[]
      // this.dTP_SLList=[]
      // this.positionList= []
      if (!this.isLogin)return
      this.currentEntrust('DEFAULT')
      this.currentEntrust('ADVANCED_PRICE_LIMIT')
      this.currentEntrust('TP_SL')
      this.currentEntrustCount()
      this.currentPosition()
      this.getUserContractFinanceList()

    },
    getUserContractFinanceList(){
      if (!this.isLogin)return
      this.$api.getUserContractFinanceList().then(res=>{
        this.property = res.data
        // console.log(res);
      })

    },
    //仓位查询
    currentPosition(){
      if (!this.isLogin) return;
      this.$api.currentPosition().then(res => {
        // 添加数据检查
        if (res && res.data) {
          this.positionList = res.data.list || [];
        } else {
          this.positionList = [];
          console.warn('获取仓位数据异常');
        }
      }).catch(err => {
        console.error('获取仓位数据失败:', err);
        this.positionList = [];
      });
    },
    currentEntrustCount(){
      if (!this.isLogin) return;
      this.$api.currentEntrustCount().then(res => {
        // 添加数据检查
        if (res && res.data) {
          this.EntCount = res.data.count || 0;
        } else {
          this.EntCount = 0;
          console.warn('获取委托数量数据异常');
        }
      }).catch(err => {
        console.error('获取委托数量失败:', err);
        this.EntCount = 0;
      });
    },
    YcancelEntrust(item,key){
      if (!this.isLogin)return
      this.shareShow = true
      this.shareItem = item
      // this.$api.YcancelEntrust({entrustId:item.id,type:key}).then(res=>{
      //   if(res.meta.success){
      //     Toast(this.$t('M.common_success'))
      //     this.currentEntrustCount()
      //     this.currentEntrust(this.activeSubset)
      //     }
      //     // console.log('chedanchenggong',res);
      // })
    },
    positionsType(key){
      if(key =='BY') return this.$t('M.Isolated')
      if(key =='TOTAL') return this.$t('M.Cross')
    },
    tradeMode(key){
      if(key =='OPEN_UP') return this.$t('M.Open long')
      if(key =='OPEN_DOWN') return this.$t('M.Open short')
      if(key =='CLOSE_UP') return this.$t('M.Close long')
      if(key =='CLOSE_DOWN') return this.$t('M.Close short')
    },
    tradeMode2(key){
      if(key =='OPEN_UP') return this.$t('M.Long')
      if(key =='OPEN_DOWN') return this.$t('M.Short')
    },
    //列表
    currentEntrust(key){
      if (!this.isLogin)return
            if(key == 'TP_SL'){
              this.$api.currentTpslEntrust().then(res=>{
                  // console.log(res);
                  if (res && res.data && res.data.list){
                    this.dTP_SLList = res.data.list
                  }
              })
              return
            }
            this.$api.currentEntrust({requestMatchType:key}).then((res) => {
              // console.log(res.data);
                if(key == 'DEFAULT'){
                  this.defaultList = res.data.list
                }
                if(key == 'ADVANCED_PRICE_LIMIT'){
                  this.ADVANCEDList = res.data.list
                }

            })
        },
    contentDialog(item,key){
      if(key == 'share'){
        this.YcancelEntrust(item)
      }
      this.sraterData = item
      this['activeSubsetShow'+key] = true;
    },
    orderCancel(){
      this.orderType = null;
      this.orderShow=false;
    },
    //批量撤单确认按键
    orderConfirm(){
      if (!this.isLogin)return
      let arr = []
      let type = 'DEFAULT'
      let key = this.activeSubset
      console.log(this.activeSubset);
      console.log(this.orderType);
      if(this.orderType != null){
        key = this.orderType
      }
      if(key == 'DEFAULT'){
        this.defaultList.forEach((v,i)=>{
          arr.push(v.id)
        })
      }
      if(key == 'ADVANCED_PRICE_LIMIT'){
        this.ADVANCEDList.forEach((v,i)=>{
          arr.push(v.id)
        })
        type = 'ADVANCED_PRICE_LIMIT'
      }
      if(key == 'TP_SL'){
        this.dTP_SLList.forEach((v,i)=>{
          arr.push(v.id)
        })
        type = 'TP_SL'
      }
      console.log(type);
      this.$api.batchCancelEntrustByIds({entrustIds:arr.toString(),type:type}).then(res=>{
        if(res.meta.success){
          Toast(this.$t('M.common_success'))
          this.apiData()
          // this.currentEntrust(this.activeSubset)
          // console.log(this.activeSubsetShow2);
          // console.log(this.$refs.wholeList);
          if(this.activeSubsetShow2){
            this.$refs.wholeList.currentEntrust('TP_SL')
            this.orderType = null
          }
        }
          this.orderShow=false
      })

    },
    //点击品种弹窗
    SelectPopup(item,key,history){
      this.sraterData = item
      this.infoKey = key
      this.historyShown = history
      this.activeSubsetShow1 = true
    },
    //选中交易品种
    SelectedVariety(){
      console.log(this.Variety)
    },
    /* 查看快速详情 */
    lookHurryDetail(item) {
      this.$router.push({
        path: `/TradeHurryDetail`,
        query: {
          id: item.id
        }
      })
    },
    changeBar() {
      if(this.active == 0){
        this.currentEntrustCount()
        this.currentEntrust('DEFAULT')
        this.currentEntrust('ADVANCED_PRICE_LIMIT')
        this.currentEntrust('TP_SL')
      }
      if(this.active == 1){
        this.currentEntrustCount()
        this.currentPosition()
      }
      if(this.active == 2){

      }
    },
    //二级tab
    changeBarSubset(e) {
      // console.log(e);
      this.currentEntrust(e)
      // if (this.active == 1) {
      //   if (!this.isLogin) {
      //     this.$router.push("login")
      //     return
      //   };
      //   this.list = [];
      //   this.page = 1;
      //   this.onLoad();
      // }
    },
    //弹窗tab
    changeBarPopup(){

    },
    allOrder() {
      if (this.isLogin) {
        this.$router.push({
          path: `/TradeOrder`,
        });

      } else {
        this.$router.push({
          name: "login",
        });
      }
    },
    onLoad() {
      if (!this.isLogin) return;
      let sendData = {
        currentPage: this.page,
        pageSize: 10,

      }
      if (!this.checked) {
        sendData.tradeId = this.symbol.partnerTradeId
        sendData.partnerId = "472437831826935808"
        sendData.partnerNo = "1000010001"
        sendData.userId = this.user.id
      }


      this.$api
        .queryMyEntrust(sendData)
        .then((response) => {
          if (response.data.list.length < 10) {
            this.finished = true;
          } else {
            this.finished = false;
          }
          this.loading = false;
          this.page++;
          this.list = this.list.concat(response.data.list);
          for (let i = 0; i < this.list.length; i++) {
            const element = this.list[i];
            element.jindu = (element.completeCount / element.count) * 100
          }
        });
    },
    getUpdata() {
      this.page = 1;
      this.list = [];
      this.onLoad();
    },
    cexiao(item) {
      console.log(item);
      this.$api
        .cancelEntrust({
          id: item.id,
          version: 1,
        })
        .then((response) => {
          console.log(response);
          this.$notify(response.meta.message);
          this.getUpdata();
        });
    },
    //止盈止损
    stopData(item,key){
      this.sraterData = item
      this['activeSubsetShow'+key] = true;
    },
  },
};
</script>
<style lang="scss">
.SubsetBar{
  .van-tabs__wrap{
    width: 80%;
   .van-tabs__line{
      display: none!important;
    }
  }
}
</style>
<style lang="scss" scoped>
.box{
  padding: 10px;
  .van-row{
    .margin{
      span{
        margin-right: 10px;
      }
    }
    margin-bottom: 10px;
    span{
      color: #5C6573;
    }
    .Sub{
      color: #000;
      word-wrap: break-word;
    }
    .Sub2{
      color: #00b897;
    }
    .title{
      font-size: 16px;
    }
    .left{text-align: left;}
    .right{text-align: right;}
    .center{text-align: center;}
    .red{
      color: #e54d68;
    }
    .gre{
      color: #1baf84;
    }
  }
}
.Subset{
  position: relative;
  .SubsetBarSub{
    color:#5C6573;
    // font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 44px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.imgNoData {
  width: 170px;
  height: 105px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

.textNoData {
  text-align: center;
  line-height: 100px;
}

.tradeList {
  margin-top: 8px;
  width: 100%;
  background-color: #fff;
  .titItem {
    font-size: 16px;
    font-weight: 600;
  }

  .recentlyBox {
    padding: 15px;

    .titBox {
      display: flex;
    }

    .listBox {
      margin-top: 11px;

      .itemBox {
        display: flex;
        margin-bottom: 15px;

        .buy {
          color: #18ae83;
        }

        .sell {
          color: #ED0070;
        }
      }
    }

    .item {
      flex: 1;
      font-size: 13px;
      color: #9299a9;
      line-height: 19px;
    }

    .item1 {
      text-align: left;
      color: #000;
    }

    .item2 {
      text-align: center;
    }

    .item3 {
      color: #000;
      text-align: right;
    }
  }

  .nowEntrust {
    .btnBox {
      width: 100%;
      background-color: #f8f8fb;
      display: flex;
      padding: 0 15px;
      line-height: 40px;
      height: 40px;
      color: #9299a9;
      justify-content: space-between;

      .allOrder {
        display: flex;
        align-items: center;

        span {
          margin-left: 8px;
        }
      }
    }

    .orderListBox {
      width: 100%;
      padding: 0 15px;
      background-color: #fff;

      .orderList {
        width: 100%;

        .item {
          width: 100%;
          margin-top: 15px;

          .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .tit {
              font-size: 16px;
              font-weight: 600;
              color: #191b27;
              line-height: 23px;
            }

            .cexiao {
              width: 16px;
              height: 16px;
            }
          }

          .mine {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .type {
              // width: 36px;
              height: 20px;
              background: rgba(255, 96, 96, 0.1);
              border-radius: 2px;
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              color: #ED0070;
              line-height: 20px;
            }

            .type2 {
              // width: 36px;
              height: 20px;
              background: rgba(255, 96, 96, 0.1);
              border-radius: 2px;
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              color: #ED0070;
              line-height: 20px;
              background: #d6ede5 !important;
              color: #18ae83;
            }

            .progress {
              width: 100px;
              height: 4px;
              background: rgba(199, 204, 216, 0.2);
              border-radius: 2px;
              margin-left: 20px;
            }

            .num {
              margin-left: 10px;
            }
          }

          .bottom {
            display: flex;
            margin-top: 15px;

            .item {
              flex: 1;
              line-height: 19px;
              font-size: 13px;
              margin-top: 0;
              padding-bottom: 15px;
              border-bottom: 1px solid #ebebf0;

              .tit {
                font-weight: 400;
                color: #9299a9;
              }

              .content {
                font-weight: 600;
                color: #191b27;
                margin-top: 5px;
                white-space: nowrap;
                font-size: 10px;
              }
            }

            .item1 {
              text-align: left;
            }

            .item2 {
              text-align: center;
            }

            .item3 {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

.reverseContent {
  padding: 0 15px;
  padding-bottom: 30px;

  .fanShouTitle {
    margin-top: 16px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }

  .fanShouSamallTips {
    margin-top: 12px;
    display: flex;
    font-size: 12px;
    line-height: 20px;

    .biaoQian {
      height: 20px;
      background: #F8F8F8;
      border-radius: 3px 3px 3px 3px;
      padding: 0px 6px;
      color: #888888;
    }

    .fangXiang {
      margin-left: 15px;
    }
  }

  .nowContent {
    margin-top: 11px;
    padding: 10px 12px;
    background: rgba(188, 188, 188, 0.1);
    margin-bottom: 12px;

    .nowContentTitle {
      display: flex;

      .biaoQian {
        height: 20px;
        border-radius: 3px 3px 3px 3px;
        padding: 0px 6px;
      }

      .beiShu {
        margin-left: 15px;
        font-weight: 400;
      }


      .biaoQianSell {
        background: #F6E7E9;
        color: #E54C67;
      }

      .biaoQianBuy {
        background: #DFF2EE;
        color: #00B897;
      }
    }

    .nowContentData {
      display: flex;
      margin-top: 16px;
      padding-bottom: 15px;

      .dataBox {
        flex: 1;
        text-align: center;

        .tit {
          color: #888888;
          line-height: 14px;
        }

        .data {
          color: #000000;
          line-height: 21px;
        }
      }
    }
  }
}
::v-deep .van-tab__text--ellipsis {
  -webkit-box-orient: horizontal;
}

::v-deep .van-action-sheet__header {
  text-align: left;
  padding-left: 15px;
  font-size: 18px;
  font-weight: 800;
}
::v-deep .van-tab__text--ellipsis {
  -webkit-box-orient: horizontal;
}
::v-deep .van-tabs__wrap {
  border-bottom: 1px solid #f8f8fb;
}

::v-deep .van-action-sheet {
  max-height: 70% !important;
}
</style>
