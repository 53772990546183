<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-19 18:45:25
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="setPassword container">
    <van-nav-bar :title="$t('M.mailbox')" left-text="" left-arrow @click-left="onClickLeft" />

    <div class="content">
      <van-cell-group inset>
        <van-cell :title="$t('M.mailbox_new')" :value="showEmailName" />
        <van-cell :title="$t('M.mailbox')">
          <template #right-icon>
            <van-switch :value="otherUser.isEnableMail" active-color="#00b897" inactive-color="#dcdee0" size="18px"
                        @input="onInputSwitch"/>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <PassWordPopup :show="passWordPopupShow" @changePassWordPopupShow="changePassWordPopupShow" validationtype="email" />
  </div>
</template>


<script>
import {mapGetters} from 'vuex'
import PassWordPopup from './PassWordPopup.vue'

export default {
  components: {
    PassWordPopup
  },
  data() {
    return {
      passWordPopupShow: false,
      showEmailName: ''
    }
  },
  computed: {
    ...mapGetters([
      'user', "otherUser"
    ]),

  },
  mounted() {
    console.log(this.otherUser)
    // this.showEmail = 
    let strArr = this.user.email.split("@")
    this.showEmailName = strArr[0].substr(0, 3) + "***@" + strArr[1];
  },
  methods: {
    changePassWordPopupShow() {
      this.passWordPopupShow = !this.passWordPopupShow;
    },
    /* 更换手机 */
    goChangePhonePage() {
      this.$router.push("changePhone")
    },
    /* 开关切换 */
    onInputSwitch() {
      if (this.otherUser.isEnableMail) {
        let num = 0;
        if (this.otherUser.isEnablePhone) {
          num++;
        }
        if (this.otherUser.isEnableMail) {
          num++;
        }
        if (this.otherUser.isEnableGoogle) {
          num++;
        }

        if (num <= 1) {
          this.$notify(this.$t('M.must_retain_one'))
          return
        }
      }
      

      this.passWordPopupShow = true;
    },
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.setPassword {
  font-size: 12px;
  background-color: #f8f8f8;
  .content {
    padding-top: 12px;
    border-radius: 8px;
    .van-cell-group {
      border-radius: 10px;
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
</style>