<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-19 16:03:17
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="setPassword container">
        <van-nav-bar :title="$t('M.ModifyEmail')" left-text="" left-arrow @click-left="onClickLeft" />

        <div class="content">
            <van-form @submit="onSubmit">
                <van-cell-group inset>
                    <van-field  type="textarea" rows="1" autosize v-model="newEmail" :name="$t('M.NewEmail')" :label="$t('M.NewEmail')"
                        :placeholder="$t('M.NewEmailAddress')" />
                    <div class="codebox">
                        <van-field  v-model="newEmailCode" :name="$t('M.VerifyNewEmail')" :label="$t('M.VerifyNewEmail')"
                        type="textarea" :placeholder="$t('M.EnterNewEmailCode')"></van-field>

                        <button slot="button" class="btn" :disabled="disabledBtn1" @click="sendCode"> {{ disabledBtnText
                        }}</button>
                    </div>
                    <div class="codebox" v-if="typeEmail">
                        <van-field v-model="oldEmailCode" type="textarea" rows="1" autosize :name="$t('M.VerifyOldEmail')"
                            :label="$t('M.VerifyOldEmail')" :placeholder="$t('M.EnterOldEmailCode')" />
                        <button class="btn" @click="sendCode2"> {{ disabledBtnText2 }}</button>
                    </div>
                    <van-field v-if="typePas" v-model="loginPas" :name="$t('M.VerifyLoginPassword')" type="password"
                        :label="$t('M.VerifyLoginPassword')" :placeholder="$t('M.EnterLoginPassword')" />
                </van-cell-group>
                <div style="margin: 16px;">
                    <van-button round block type="primary" native-type="submit">
                      {{$t('M.submitBtn')}}
                    </van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import { encrypt } from "@/utils/util.js";
export default {
    data() {
        return {
            newEmail: '',
            newEmailCode: '',
            oldEmailCode: '',
            loginPas: '',
            disabledBtn1: false,//获取新邮箱验证码
            disabledBtnText: this.$t('M.VerifyNewEmail'),
            disabledBtn2: false,//获取新邮箱验证码
            disabledBtnText2: this.$t('M.VerifyOldEmail'),
            typeEmail: false,
            typePas: false,
        }
    },
    computed: {
        ...mapGetters([
            'user'
        ]),
    },
    mounted() {
        this.$api.getUpChangeEmailRules().then((res) => {
            console.log(res);
            let str = res.data.checkEmail
            this.typeEmail = str.includes("check_email")
            this.typePas = str.includes("check_password")
        })
    },
    methods: {
        // 提交
        onSubmit() {

            if (this.newEmail.length == 0) {
                this.$notify(this.$t('M.NewEmailAddress'))
                return
            }
            if (this.newEmailCode.length == 0) {
                this.$notify(this.$t('M.EnterNewEmailCode'))
                return
            }
            if (this.typeEmail && this.oldEmailCode.length == 0) {
                this.$notify(this.$t('M.EnterOldEmailCode'))
                return
            }
            if (this.typePas && this.loginPas.length == 0) {
                this.$notify(this.$t('M.EnterLoginPassword'))
                return
            }

            const params = {
                email: this.newEmail,
                oldCode: this.oldEmailCode,
                newCode: this.newEmailCode,
                password: encrypt(this.loginPas),
            }
            this.$api.changeEmail(params).then((response) => {
                this.$notify(response.meta.message)
                if (response.meta.success) {
                    this.$store.dispatch('logout').then(() => {
                        this.$router.push('login')
                    })
                }
            })

        },
        sendCode() {
            if (this.newEmail.length == 0) { return }
            const { partnerId, email } = this.user
            const params = {
                action: "UPDATE_EMAIL",
                email: this.newEmail,
                partnerId: partnerId
            }
            this.$api.sendMail(params).then((response) => {
                if (!response.meta.success) return
                this.$notify(response.meta.message)
                this.disabledBtn1 = true;

                let countDown = 60;
                const timer = setInterval(() => {
                    if (countDown > 0) {
                        this.disabledBtnText = `${countDown}s`;
                    } else {
                        this.disabledBtn = false;
                        this.disabledBtnText = this.$t('M.VerifyNewEmail')
                        clearInterval(timer);
                    }
                    countDown--;
                }, 1000);
            })
        },
        sendCode2() {
            const { partnerId, email } = this.user
            const params = {
                action: "UPDATE_EMAIL",
                email: email,
                partnerId: partnerId
            }
            this.$api.sendMail(params).then((response) => {
                if (!response.meta.success) return
                this.$notify(response.meta.message)
                this.disabledBtn2 = true;

                let countDown = 60;
                const timer = setInterval(() => {
                    if (countDown > 0) {
                        this.disabledBtnText2 = `${countDown}s`;
                    } else {
                        this.disabledBtn2 = false;
                        this.disabledBtnText2 = this.$t('M.VerifyNewEmail')
                        clearInterval(timer);
                    }
                    countDown--;
                }, 1000);
            })
        },
        validator(val) {
            return /^[\w.-]+@\w+(\.\w+)+$/.test(val);
        },
        onClickLeft() {
            this.$router.back()
        }
    },
    destroyed() {
        clearInterval(this.timeObj)
    }
}
</script>
  
<style lang="scss" scoped>
.setPassword {
    font-size: 12px;
}


.codebox {
    position: relative;

    .btn {
        position: absolute;
        right: 10px;
        top: 12px;
        background-color: #F8F8F8;
    }
}

.getmgs {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
  color: #00b897;
}

::v-deep .van-nav-bar .van-icon {
    color: #000;
}

::v-deep .van-cell {
    background-color: #f8f8f8;
    padding: 10px 10px;
    margin-top: 10px;
    border-radius: 5px;
}

::v-deep .van-button--primary {
    background-color: #f8f8f8;
    border: 0;
  color: #00b897;
}

::v-deep .van-field--error .van-field__control {
    color: #D1D3D4 !important;
}

.content {

    .area {
        background-color: #f8f8f8;
        padding: 5px 5px;
        width: 70px;
        text-align: center;
        border-radius: 20px;
        margin-top: 20px;
    }

    .next {
        width: 100%;
        height: 46px;
      background: #00b897;
        border-radius: 4px;
        text-align: center;
        line-height: 46px;
        font-size: 18px;
        color: #fff;
        margin-top: 50px;
    }
}

::v-deep .van-popup {
    .van-search--show-action {
        padding-right: 10px;
    }

    .van-search__content {
        border-radius: 5px;

        .van-icon {
            color: #8a8a8a;
        }
    }

    .van-search__action {
        color: #8a8a8a;
    }

    .countriesListBox {
        .listItem {
            font-size: 14px;
            line-height: 54px;
            margin: 0 19px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #e5e5e5;
        }
    }
}
</style>