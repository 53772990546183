<template>
  <div class="popupCertain" :style="{pointerEvents:contractData.entrustCount!=0?'none':''}">
          <div class="title">
            <div class="titletop">{{$t('M.Leverage_BB')}}</div>
            <div class="titlebottom">{{itemData.tradeName}}</div>
          </div>
          <div class="sub">{{$t('M.Current')}}{{ itemData.lever }}.0X</div>
          <div class="pper">
            <van-stepper class="popupStepper" disabled :show-plus='false' :show-minus='false' v-model="value" @change="selectChange"  input-width="40px" button-size="24px" max="125" min="1" />
          </div>
          <!-- <van-tabs  title-active-color="#fff" @change="changeNumber" v-model="activeValue"  title-inactive-color="#5C6573" color="#F10E38"  type="card">
            <van-tab :title-style="{width:'70px'}" name="1" title="1X"></van-tab>
            <van-tab :title-style="{width:'70px'}" name="2" title="2X"></van-tab>
            <van-tab :title-style="{width:'70px'}" name="3" title="3X"></van-tab>
            <van-tab :title-style="{width:'70px'}" name="5" title="5X"></van-tab>
            <van-tab :title-style="{width:'70px'}" name="10" title="10X"></van-tab>
            <van-tab :title-style="{width:'70px'}" name="20" title="20X"></van-tab>
            <van-tab :title-style="{width:'70px'}" name="30" title="30X"></van-tab>
            <van-tab :title-style="{width:'70px'}" name="50" title="50X"></van-tab>
            <van-tab :title-style="{width:'70px'}" name="75" title="75X"></van-tab>
          </van-tabs> -->
          <div class="activeNum">
              <div v-for="(item,index) in leverList" :key="index" @click="changeNumber(activeNum = item)" :class="activeNum==item?'active':''">{{ item }}X</div>
            </div>
          <div class="describe">
            <div>
              <span>
                {{$t('M.Max position size at current leverage')}}
              </span><span style="color: #00b897;">{{contractData.openAmount}}{{ itemData.sellCoinNickname }}</span>
            </div>
            <div>
              <span>{{$t('M.Required position margin at current leverage')}}</span><span style="color: #00b897;">{{contractData.needPosition}}{{ itemData.buyCoinNickname }}</span>
            </div>
          </div>
          <div class="tips">
            <span v-if="contractData.needChangePosition > 0">
                {{
                    itemData.positionsType === "BY"
                        ? $t("M.contract_After_adjustment").format([
                        contractData.needChangePosition
                          ]) + $t("M.contract_subject_to")
                        : $t("M.contract_After_adjustment_q").format([
                        contractData.needChangePosition
                          ])
                }}
            </span>
            <span v-if="contractData.needChangePosition < 0">
                {{
                    itemData.positionsType === "BY"
                        ? $t("M.contract_the_used_margin").format([
                              Math.abs(
                                contractData.needChangePosition
                              )
                          ]) + $t("M.contract_transferred_to")
                        : $t("M.contract_the_used_margin_q").format([
                              Math.abs(
                                contractData.needChangePosition
                              )
                          ])
                }}
            </span>
            <span v-if="contractData.entrustCount!=0">
                {{
                    itemData.positionsType === "BY"
                        ? $t("M.Your have pending isolated orders")
                        : $t("M.Your have pending Cross orders")
                }}
            </span>
          </div>
          <div class="buttoncon">
            <van-button :color="contractData.entrustCount!=0?'#b0a5ab':'#00b897'" size="small" round :disabled="contractData.entrustCount!=0" block @click="confirmLever">{{$t('M.confirm')}}</van-button>
          </div>
      </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["isLogin", "user", "lang"]),
  },
  props:['itemData'],
  data(){
    return {
      value:'',
      activeValue:'',
      leverList:['10','20','50','100','125'],
      activeNum:'',
      contractData:{}
    }
  },
  mounted(){
    this.value = this.itemData.lever
    this.activeValue = this.itemData.lever
    this.selectChange()
  },
  methods:{
    selectChange(){
      console.log(this.isLogin);
      if (!this.isLogin)return
      this.activeNum = this.value
      let params = {
          tradeId: this.itemData.contractCoinId,
          positionsType: this.itemData.positionsType,
          tradeMode: this.itemData.openType,
          lever: this.value
      };
      this.$api.selectChange(params).then(res=>{
        this.contractData = res.data
        // console.log(res);
      })
    },
    confirmLever(){
      if (!this.isLogin)return

      let params = {
          tradeId: this.itemData.contractCoinId,
          positionsType: this.itemData.positionsType,
          tradeMode: this.itemData.openType,
          lever: this.value
      };
      this.$api.changeLever(params).then(res=>{
          this.$emit('dreg')
          this.$emit('update')
          this.$emit('upMoney')
      });
    },
    changeNumber(name){
      this.value = name
    }
  }
}
</script>

<style lang="scss">
.popupCertain{
  padding: 10px;
  .tips {
            width: 100%;
            padding: 12px 0;
            min-height: 42px;
            display: flex;
            flex-direction: column;
            color: #fe3f51;
        }
  .van-tabs__wrap{
        height: auto;
    }
    .van-tabs__nav--card{
        margin: 0;
    }
  .buttoncon{
    margin: 15px 0;
  }
  .describe{
    margin-top: 10px;
    padding: 5px;
    background-color: #f8f8f8;
    div{
      padding: 5px;
    }
  }
  .title{
    border-bottom: 1px solid #e3e5eb;
    .titletop{
      color: #000;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 5px;
    }
    .titlebottom{
      // font-size: 16px;
      color: #000;
      padding-bottom: 5px;
    }
  }
  .sub{
    text-align: center;
    padding: 10px;
    color: #5C6573;
  }
  .pper{
    text-align: center;
    padding: 5px;
    margin-bottom: 10px;
    .popupStepper{
      .van-stepper__minus,.van-stepper__plus{
        background-color: white;
        border: 1px solid #F10E38;
        color: #F10E38;
        border-radius: 5px;
      }
      .van-stepper__input{
        color: #F10E38;
        font-size: 18px;
        background-color: #fff;
      }
      .van-stepper__minus--disabled{
        background-color: white;
        border: 1px solid #e3e5eb;
        color: #e3e5eb;
        border-radius: 5px;
      }
    }
  }
  .activeNum::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  .activeNum{
      overflow-x: auto;
      border: 1px solid #F10E38;
      white-space: nowrap;
      div{
        display: inline-block;
        height: 30px;
        border-right: 1px solid #F10E38;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        line-height: 30px;
        text-align: center;
        width: 70px;
      }
      div:last-child{
        border-right: none;
      }
      .active{
        color: #fff;
        background-color: #F10E38;
      }
    }
}
</style>
