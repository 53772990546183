<template>
  <div class="deep">
    <div id="deepDiv"></div>
    <div class="titBox">
      <div class="item">
        <div class="dian dian1"></div>
        <div class="text">{{$t('M.bug_order')}}</div>
      </div>
      <div class="item item1">
        <div class="dian dian2"></div>
        <div class="text">{{$t('M.sell_order')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
require('echarts/lib/chart/line')
require('echarts/lib/component/tooltip')
export default {
  data() {
    return {
      buys: [],
      sells: [],
      depthCharts: '',

      options: {
        backgroundColor: '',
        grid: {
          "left": "0px",
          "top": "0px",
          "right": "0px",
          "bottom": "18px"
        },
        title: {},
        textStyle: {
          color: '#b2b7d0'
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(92,101,115,0.04)',
          borderColor: 'rgba(146,153,169,0.2)',
          borderRadius: 3,
          padding: 10,
          hideDelay: 8000,
          confine: true,
          style: {
          },
          textStyle: {
            color: '#191B27'
          },
          extraCssText: 'z-index:2',
          axisPointer: {
            snap: true,
            confine: true,
            type: 'line',
            lineStyle: {
              type: 'dashed'
            }
          },
          formatter: (params) => {
            return `${params[0].seriesIndex == 0 ? this.$t("M.buy_1") : this.$t("M.sell_1")}<br/>${params[0].data[0]}<br/>${params[0].data[1]}`
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(177, 181, 178,0.1)'],
              width: 1,
              type: 'solid'
            }
          },
        },
        yAxis: {
          position: 'right',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(177, 181, 178,0.1)'],
              width: 1,
              type: 'solid'
            },
          },
          minInterval: 0.01, //分割刻度
          scale: true, //自适应
          axisTick: {
            show: true,
            inside: true //刻度内置
          },
          axisLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          axisLabel: {
            margin: 10,
            inside: true,
            // formatter: newNum => {
            //   switch (this.$language_S_X) {
            //     case 'zh_CN':
            //       if (newNum >= 100000000) {
            //         newNum = `${this.$keep2Num(newNum / 100000000)}亿`
            //       } else if (newNum >= 10000) {
            //         newNum = `${this.$keep2Num(newNum / 10000)}万`
            //       }
            //       return newNum
            //     default:
            //       if (newNum >= 1000000) {
            //         newNum = `${this.$keep2Num(newNum / 1000000)}M`
            //       } else if (newNum >= 1000) {
            //         newNum = `${this.$keep2Num(newNum / 1000)}K`
            //       }
            //       return newNum
            //   }
            // }
          }
        },
        animation: false
      },
      series: [
        // 买
        {
          // 委托量
          name: this.$t('M.entrust_amount'),
          type: 'line',
          color: 'rgba(24, 174, 131,.3)',
          itemStyle: {
            normal: {
            }
          },
          lineStyle: {
            width: 1,
            color: '#0AA869'
          },
          areaStyle: {},
          data: this.buys,
          symbolSize: 0
        },
        // 卖
        {
          // 委托量
          name: this.$t('M.entrust_amount'),
          type: 'line',
          color: 'rgba(255, 96, 96,0.3)',
          lineStyle: {
            width: 1,
            color: '#ED0071'
          },
          areaStyle: {},
          data: this.sells,
          symbolSize: 0
        }
      ]
    }
  },
  mounted() {
    this.depthCharts = echarts.init(document.getElementById('deepDiv'))
  },
  methods: {
    /* 更新数据 */
    setWatchData(newVal) {
      if (newVal) {
        if (newVal.buy) {
          this.series[0].data = newVal.buy
        }
        if (newVal.sell) {
          this.series[1].data = newVal.sell
        }
        if (newVal.buys) {
          this.series[0].data = newVal.buys
        }
        if (newVal.sells) {
          this.series[1].data = newVal.sells
        }
        this.options.series = this.series
        this.depthCharts.clear()
        this.depthCharts.setOption(this.options)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.deep {
  width: 100vw;
  // height: 500px;
  position: relative;
  .titBox {
    position: absolute;
    top: 20px;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    .item {
      height: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      .dian {
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
      .dian1 {
        background: #0AA869;
      }
      .dian2 {
        background: #ED0071;
      }
      .text {
        margin-left: 5px;
      }
    }
    .item1 {
      margin-left: 16px;
    }
  }
}
#deepDiv {
  width: 100vw;
  height: 400px;
}
</style>