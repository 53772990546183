<!-- 首页行情组件 --> 
<template>
  <div class='market'>
    <van-swipe class="swipe" indicator-color="#00b897">
      <van-swipe-item v-for="(item,i) in list" :key="i">
        <div class="item_box" v-for="(el,j) in item" :key="j" @click="onClick(el)">
          <div class="tr coin">{{el.sellSymbol}}/{{el.buySymbol}}</div>
          <div :class="['tr' ,'num' , el.chg>0?'up':el.chg<0?'down' :'']">{{el.last}}</div>
          <div :class="['tr' ,'per' , el.chg>0?'up':el.chg<0?'down' :'']">{{el.chg>0?'+':''}}{{cutOutPointLength(el.chg,2,true)}}%</div>
          <div class="tr cny">≈ {{cutOutPointLength(el.last*rate,2)}} {{currencySymbol}}</div>

        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  props: {},
  computed: {
    ...mapGetters([
      'partnerId', 'partnerNo', 'currencySymbol'
    ]),
  },
  data() {
    return {
      socketType: 'HOME_RANKING',
      socket: null,
      pako: require("pako"),
      list: [],
      rate: 1
    };
  },

  beforeMount() { },
  mounted() {
    this.tradeAreaPrice()
    this.getList()
    this.initSocket()
  },
  methods: {
    // 汇率
    tradeAreaPrice() {
      this.$api.tradeAreaPrice({ partnerId: this.partnerId, currency: this.currencySymbol }).then((response) => {
        response.data.usd.findIndex(item => {
          if (item.coinName == "USDT") {
            this.rate = item.coinPrice;
          }
        })
      })
    },
    getList() {
      this.$api.homeRanking({
        partnerNo: this.partnerNo,
        type: this.socketType,
        partnerId: this.partnerId,
      }).then((response) => {
        const data = response.data
        if (!data) return
        const result = [];
        for (var i = 0; i < data.length; i += 3) {
          result.push(data.slice(i, i + 3));
        }
        this.list = result;
        this.list && this.list.forEach(item => { item.last = this.cutOutPointLength(item.last) })
      })
    },
    onClick(el) {
      console.log(el.tradeName);
      this.$router.push({
        name: `TradeLine`,
        params: {
          symbol: el.tradeName,
        }
      })
    },


    initSocket() {
      const BrowserWebSocket = window.WebSocket || window.MozWebSocket
      this.socket = new BrowserWebSocket(`wss://${this.$apiUrl}/v1/websocket/market`)
      this.socket.onopen = this.onopenWS
      this.socket.onmessage = this.onmessageWS
      this.socket.onerror = this.onerrorWS
      this.socket.onclose = this.oncloseWS
    },
    onopenWS() {

      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: `market.0.${this.socketType}`,
        id: 'ios',
        domain: this.partnerId,
      }))
    },
    onmessageWS(data) {
      let blob = data.data;
      let reader = new FileReader();
      reader.readAsBinaryString(blob);
      reader.onload = (res) => {
        let text = this.pako.inflate(res.target.result, { to: "string" });
        let msg = JSON.parse(text);
        if (msg.type == 1) {
          const result = [];
          for (var i = 0; i < msg.data.length; i += 3) {
            result.push(msg.data.slice(i, i + 3));
          }
          this.list = result;
          this.list && this.list.forEach(item => { item.last = this.cutOutPointLength(item.last) })
        }
      };
    },
    onerrorWS() {
      this.socket.close()
      console.log('连接失败重连中')
      if (this.socket.readyState !== 3) {
        this.socket = null
        this.initSocket()
      }
    },
    oncloseWS() {
      console.log('websocket已断开....正在尝试重连')
      if (this.socket.readyState !== 2 && this.closeType !== 1) {
        this.socket = null
        this.initSocket()
      }
    },

  },
  beforeDestroy() {
    this.socket.close()
  },
};
</script>
<style lang="scss" scoped>
.market {
  .swipe {
    padding: 0 15px;
    margin-top: 10px;
    overflow: hidden;
    .van-swipe-item {
      color: #fff;
      font-size: 20px;
      height: 120px;
      text-align: center;
      display: flex;
      .item_box {
        // width: 108px;
        width: 29%;
        height: 100px;
        border-radius: 2px;
        padding: 3px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .tr {
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #191b27;
        }
        .num {
          font-size: 15px;
          font-weight: 600;
          color: #9299a9;
        }
        .per {
          font-size: 10px;
          color: #9299a9;
        }
        .cny {
          font-size: 10px;
          color: #5c6573;
          margin-top: 3px;
        }
        .down {
          color: #ED0071;
        }
        .up {
          color: #0AA869;
        }
      }
      .item_box:nth-child(2) {
        margin: 0 2.5vw;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep {
  .van-swipe__indicators {
    .van-swipe__indicator {
      width: 17px !important;
    }
  }
}
</style>