<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-19 18:52:38
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="password">
    <van-action-sheet v-model="show" @click-overlay="closeWindow">
      <div class="content">
        <div class="closeBox">
          <img src="../../../assets/img/assets/icon_close.png" @click="closeWindow" alt="" srcset="">
        </div>
        <div v-if="!openFlag">
          <div class="item" v-if="otherUser.isEnablePhone ">
            <van-field v-model="phoneCode" :placeholder="$t('M.login_please_input1')" />
            <div class="sendmsg" @click="getCode(1)" v-if="phoneDownNum <= 0">{{$t('M.send')}}</div>
            <div class="sendmsg" v-if="phoneDownNum > 0">{{phoneDownNum}}s</div>
          </div>
          <div class="item" v-if="otherUser.isEnableMail">
            <van-field v-model="emailCode" :placeholder="$t('M.input_email_code')" />
            <div class="sendmsg" @click="getCode(2)" v-if="emailDownNum <= 0">{{$t('M.send')}}</div>
            <div class="sendmsg" v-if="emailDownNum > 0">{{emailDownNum}}s</div>
          </div>
          <div class="item" v-if="otherUser.isEnableGoogle">
            <van-field v-model="googleCode" :placeholder="$t('M.user_please_input9')" />
          </div>
        </div>

        <div v-if="openFlag">
          <div class="item" v-if="validationtype == 'phone'">
            <van-field v-model="phoneCode" :placeholder="$t('M.login_please_input1')" />
            <div class="sendmsg" @click="getCode(1)" v-if="phoneDownNum <= 0">{{$t('M.send')}}</div>
            <div class="sendmsg" v-if="phoneDownNum > 0">{{phoneDownNum}}s</div>
          </div>
          <div class="item" v-if="validationtype == 'email'">
            <van-field v-model="emailCode" :placeholder="$t('M.input_email_code')" />
            <div class="sendmsg" @click="getCode(2)" v-if="emailDownNum <= 0">{{$t('M.send')}}</div>
            <div class="sendmsg" v-if="emailDownNum > 0">{{emailDownNum}}s</div>
          </div>
          <div class="item" v-if="validationtype == 'google'">
            <van-field v-model="googleCode" :placeholder="$t('M.user_please_input9')" />
          </div>
        </div>

        <div class="btn" @click="sur">{{$t('M.confirm')}}</div>

      </div>
    </van-action-sheet>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      phoneCode: '',
      phoneTime: null,
      phoneDownNum: 0,

      emailCode: '',
      emailTime: null,
      emailDownNum: 0,

      googleCode: '',
      googleTime: null,
      googleDownNum: 0,

      openFlag: false,
      action: ''
    }
  },
  props: {
    show: {
      type: Boolean,
    },
    type: { type: String, default: '' },
    validationtype: { type: String, default: '' },
  },
  computed: {
    ...mapGetters([
      'user', "otherUser"
    ]),
  },
  watch: {
    "show": function (newVal, oldVal) {
      if (newVal == true) {
        this.openFlag = false;
        if (!this.otherUser.isEnablePhone && this.validationtype == 'phone') {
          this.openFlag = true
        }
        if (!this.otherUser.isEnableMail && this.validationtype == 'email') {
          this.openFlag = true
        }

        if (this.openFlag) {
          switch (this.validationtype) {
            case "phone":
              this.action = 'UPDATE_PHONE_OPEN'
              break;
            case "email":
              this.action = 'UPDATE_EMAIL_OPEN'
              break;
          }
        } else {
          switch (this.validationtype) {
            case "phone":
              this.action = 'UPDATE_PHONE_CLOSE'
              break;
            case "email":
              this.action = 'UPDATE_EMAIL_CLOSE'
              break;
          }
        }
      }
    }
  },
  mounted() { },
  methods: {
    sur() {
      if (!this.openFlag) {
        if (this.otherUser.isEnablePhone && this.phoneCode == '') {
          this.$notify(this.$t('M.login_please_input1'))
          return
        }
        if (this.otherUser.isEnableMail && this.emailCode == '') {
          this.$notify(this.$t('M.input_email_code'))
          return
        }
        if (this.otherUser.isEnableGoogle && this.googleCode == '') {
          this.$notify(this.$t('M.user_please_input9'))
          return
        }
      } else {
        if (this.otherUser.validationtype == "phone" && this.phoneCode == '') {
          this.$notify(this.$t('M.login_please_input1'))
          return
        }
        if (this.otherUser.isEnableMail == "email" && this.emailCode == '') {
          this.$notify(this.$t('M.input_email_code'))
          return
        }
        if (this.otherUser.isEnableGoogle == "google" && this.googleCode == '') {
          this.$notify(this.$t('M.user_please_input9'))
          return
        }
      }


      let status = ''

      switch (this.validationtype) {
        case "phone":
          status = this.otherUser.isEnablePhone ? "disable" : "enable"
          break;
        case "email":
          status = this.otherUser.isEnableMail ? "disable" : "enable"
          break;

        default:
          break;
      }
      let sendData = {
        phoneCode: this.phoneCode,
        partnerNo: "1000010001",
        status: status,
        action: this.action,
        googleCode: this.googleCode,
        emailCode: this.emailCode,
        type: this.validationtype,
      }
      this.$api.enable(sendData)
        .then((data) => {
          if (data.meta.success) {
            this.$store.dispatch('getUserData')
            this.closeWindow()
            this.$notify(data.meta.message)
          } else {
            this.$notify(data.meta.message)
          }
        });

    },
    /* 关闭 */
    closeWindow() {
      this.$emit('changePassWordPopupShow')
      this.phoneCode = '';
      this.phoneTime = null;
      this.phoneDownNum = 0;

      this.emailCode = '';
      this.emailTime = null;
      this.emailDownNum = 0;

      this.googleCode = '';
      this.googleTime = null;
      this.googleDownNum = 0;

      clearInterval(this.phoneTime)
      clearInterval(this.emailTime)
    },
    /* 获取短信验证 */
    getCode(num) {
      let sendData = {
        partnerId: "472437831826935808",
        nationCode: this.user.country,
        action: this.action
      }
      let apiText = ''
      switch (num) {
        case 1:
          sendData.phone = this.user.phone
          apiText = 'sendSms'
          break;
        case 2:
          sendData.email = this.user.email
          apiText = 'sendMail'
          break;
      }

      this.$api[apiText](sendData)
        .then((data) => {
          if (data.meta.success) {

            switch (num) {
              case 1:
                this.phoneDownNum = 59;
                this.timerDown(num);
                break;
              case 2:
                this.emailDownNum = 59;
                this.timerDown(num);
                break;
            }

            this.$notify(data.meta.message)
          } else {
            this.$notify(data.meta.message)
          }
        });
    },
    timerDown(num) {
      switch (num) {
        case 1:
          this.phoneTime = setInterval(() => {
            this.phoneDownNum--;
            if (this.phoneDownNum <= 0) {
              this.phoneDownNum = 0;
              clearInterval(this.phoneTime);
            }
          }, 1000);
          break;
        case 2:
          this.emailTime = setInterval(() => {
            this.emailDownNum--;
            if (this.emailDownNum <= 0) {
              this.emailDownNum = 0;
              clearInterval(this.emailTime);
            }
          }, 1000);
          break;
      }
    }
  },
  destroyed() {
    clearInterval(this.phoneTime)
    clearInterval(this.emailTime)
  }
}
</script>

<style lang="scss" scoped>
.password {
  font-size: 12px;
  .content {
    padding-bottom: 50px;
    .closeBox {
      width: 100%;
      margin-top: 20px;
      text-align: right;
      margin-bottom: 20px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 20px;
      }
    }
    .item {
      position: relative;
      width: 95%;
      margin: 0 auto;
      margin-bottom: 20px;
      .sendmsg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 14px;
        color: #00b897;
      }
    }
    .btn {
      width: 345px;
      height: 46px;
      background: #00b897;
      border-radius: 4px;
      margin: 0 auto;
      font-size: 18px;
      text-align: center;
      line-height: 46px;
      color: #ffffff;
    }
  }
}
::v-deep .van-cell {
  background-color: #f8f8f8;
}
</style>