<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-13 17:28:43
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="setPassword">
    <nav-bar :title="$t('M.ios_客服')"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import navBar from '@/components/navBar';

export default {
  components: {
    navBar,
  },
  data() {
    return {
      msgLanguage: "en-us",
      loading: true,
    }
  },
  computed: {
    ...mapGetters([
      'user', 'partnerId'
    ]),

  },

  mounted() {
    // this.$api.selectConfigInfo({ partnerId: this.partnerId }).then((res) => {
    //   if (res.data.baseStatus == "disable") {
    //     console.log("关闭状态")
    //   } else {
    //     this.initZendesk(res.data.link);
    //   }
    // })

    this.initZendesk('https://static.zdassets.com/ekr/snippet.js?key=7b035d29-9a4e-4ce2-ab74-54658b20e0de');

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.fun, false);
    }
  },
  methods: {

    initZendesk(link) {
      let that = this;
      let zdeskEL = document.getElementById("ze-snippet");
      if (!zdeskEL) {
        const script = window.document.createElement("script");
        script.id = "ze-snippet";
        script.src = link;
        script.defer = "defer";
        window.document.body.appendChild(script);
        script.onload = () => {
          zE('messenger', 'show');
          zE('messenger', 'open');
          zE('messenger:on', 'close', function () {
            zE('messenger', 'hide');
            // var self = document.getElementById('launcher');
            // self.style.display = "none";
            that.$router.back()
          })
        };
      } else {
        zE('messenger', 'show');
        zE('messenger', 'open');
      }

    },
    fun() {
      if (zE) {
        zE('messenger', 'hide');
      }
      this.$router.back()
    },

  },
  destroyed() {
    window.removeEventListener('popstate', this.fun, false);//false阻止默认事件
  },
}
</script>

<style lang="scss" scoped>
.setPassword {
  font-size: 12px;
  width: 100%;
  height: 100%;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-loading {
  text-align: center;
  margin-top: 30px;
}

.iframe {
  width: 100%;
  height: calc(100vh - 1.333rem);
}
</style>