<template>
  <div class="container chargingAddress">
    <nav-bar :showTitle="false" :showRight="false">
      <router-link slot="title" class="title" :to="{path:'recharging',query:{ type: query.type, back: true }}">
        {{ query.coinName }} {{ $t('M.recharge_withdraw_msg_type_0002') }}
        <img src="@/assets/img/assets/icon_down.png" alt="">
      </router-link>
      <router-link slot="right" class="right"
                   :to="{path:'withdrawalRecords',query:{coinId: this.query.coinId ,active:2} }">
        {{ $t('M.Historical record') }}
      </router-link>
    </nav-bar>

    <div class="main">
      <!-- <van-sticky :offset-top="50" v-if="chainData.chainList"> -->
      <div class="tabs cell">
        <div class="tit">{{ $t('M.user_link_name') }}</div>

        <div class="tabs_item">
          <div v-for="(item,i) in chainData.chainList" :key="i" :class="[active==i ?'active_btn':'','btn']"
               @click="onChange(i)">{{ item.chain }}
          </div>
        </div>
      </div>
      <!-- </van-sticky> -->

      <div class="cell">
        <div class="tit">{{ $t('M.mention_a_currency') }}
          <span>({{ $t('M.Available') }} {{ assets.userCoin }} {{ query.coinName }})</span>
        </div>
        <van-field v-model="amount" center
                   :placeholder="$t('M.transfer_number') + '>=' + assets.minCoin || numberFloat.min"
                   :formatter="formatter">
          <div class="button" slot="button" @click="onClickAll">{{ $t('M.all') }}</div>
        </van-field>
      </div>

      <div class="cell">
        <div class="tit">{{ $t('M.Coin_Address_draw') }}</div>
        <van-field v-model="withdrawAddress" center>
          <div class="slot_button" slot="button">
            <router-link class="button" :to="{ path:'addressList',query: {coinId: this.query.coinId, back: true} }">
              {{ $t('M.ddress_management') }}
            </router-link>
          </div>
        </van-field>
      </div>

      <div class="cell">
        <div class="tit">{{ $t('M.free') }}</div>
        <van-field v-model="networkFees" :disabled=" ['USDT', 'BTC', 'ETH'].includes(query.coinName)" center
                   :formatter="formatter"></van-field>
        <div class="desc">
          <span>{{ $t('M.range') }}：{{ assets.minFee + '-' + assets.maxFee }}</span>
          <span>{{ $t('M.actual_to_account') }} <span>{{ changeSum }}</span> {{ query.coinName }}</span>
        </div>
      </div>

      <div class="btn_wrap">
        <div class="button submit_button" @click="onSubmit">{{ $t('M.Confirm_to_mention_money') }}</div>
        <div class="desc">
          <p>{{ $t('M.currency_withdrawal_rate_rules', [query.coinName]) }}</p>
          <p>
            {{ $t('M.currency_withdrawal_rate_rules1') }}
          </p>
          <!--          <p>-->
          <!--            {{$t('M.currency_withdrawal_rate_rules2',[query.coinName,12])}}-->
          <!--          </p>-->
          <p>
            {{ $t('M.currency_withdrawal_rate_rules3') }}
          </p>
          <p>
            {{
              $t('M.currency_withdrawal_rate_rules4', [chainData.maxWithdrawTimesPerDay, chainData.maxWithdrawAmountPerDay + query.coinName])
            }}
          </p>
        </div>
      </div>

    </div>

    <popup-password :show="show" @onClose="onClosed" @onSubmit="onSubmitPass"
                    :title="$t('M.currency_transaction_password')" :params="params"/>

  </div>
</template>

<script>
import navBar from '@/components/navBar';
import popupPassword from '@/components/popupPassword';

import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'withdrawal',
  components: {
    navBar, popupPassword
  },
  computed: {
    ...mapGetters([
      'selectCoin', 'selectAddress', 'partnerId'
    ]),
    ...mapMutations(['SET_WITHDRAW_ADDRESS']),

    changeSum() {
      const {minCoin, minFee, maxFee} = this.assets
      if (Number(this.amount) < Number(minCoin)) return 0
      if (this.networkFees < minFee || this.networkFees > maxFee) return 0
      if (this.amount - this.networkFees <= 0) return 0
      return (this.cutOutPointLength(this.amount - this.networkFees, 4))
    }
  },
  inject: ['reload'],
  data() {
    return {
      query: {},
      active: 0,
      chainData: {},
      assets: {
        minFee: 0,
        maxFee: 0,
        feePrecision: 0,
        maxCoin: 0,
        minCoin: 0,
        totalCoin: 0,
        userCoin: 0,
      },
      numberFloat: {
        min: 0,
        max: ''
      },
      numberFloatCharges: {
        min: 0,
        max: ''
      },

      amount: '',
      withdrawAddress: '',
      networkFees: '',

      show: false,

      params: {}

    };
  },
  watch: {
    amount(n) {
      // console.log(n);
      if (this.assets.userCoin - this.assets.maxCoin >= 0) {
        if (n - this.assets.maxCoin >= 0) return this.amount = this.assets.maxCoin

      } else {
        if (n - this.assets.userCoin >= 0) return this.amount = this.assets.userCoin
      }

    }

  },
  activated() {
    this.initQuery()
    this.coinWallet()

    const {address} = this.selectAddress
    this.withdrawAddress = address && address
  },

  mounted() {

  },

  methods: {
    initQuery() {
      if (this.selectCoin.back) {
        return this.query = {...this.$route.query, ...this.selectCoin}
      }
      this.query = this.$route.query || this.selectCoin
    },

    onChange(e) {
      this.amount = ''
      this.withdrawAddress = ''
      this.active = e
      this.getAssets()
    },

    // 全选
    onClickAll() {
      if (this.assets.userCoin * 1 <= 0) return
      if (this.assets.userCoin * 1 >= this.assets.maxCoin) {
        this.amount = this.assets.maxCoin
      } else {
        this.amount = this.assets.userCoin
      }
    },

    coinWallet() {
      this.$api.coinWallet(this.query.coinId).then((response) => {
        if (!response.meta.success) return
        this.chainData = response.data
        this.getAssets()
      })
    },

    getAssets() {
      const params = {
        coinId: this.query.coinId,
        rechargeType: this.chainData.chainList ? this.chainData.chainList[this.active].chain : ''
      }
      this.$api.getAssets(params).then((response) => {

        if (!response.meta.success) return this.$store.commit('SET_WITHDRAW_ADDRESS', {})
        const {minCoin, maxCoin, minFee, maxFee, coinAddress} = response.data
        //
        this.$store.commit('SET_WITHDRAW_ADDRESS', coinAddress.userWithdrawAddressDtoList)
        this.assets = response.data
        this.numberFloat.min = minCoin
        this.numberFloat.max = maxCoin
        this.networkFees = minFee
        this.numberFloatCharges.min = minFee
        this.numberFloatCharges.max = maxFee

      }).catch(() => {
        this.$store.commit('SET_WITHDRAW_ADDRESS', {})
      })

    },
    // 验证地址
    validateAddress() {
      const params = {
        address: this.withdrawAddress,
        coinId: this.query.coinId,
        rechargeType: this.chainData.chainList ? this.chainData.chainList[this.active].chain : ''
      }
      this.$api.validateAddress(params).then((response) => {
        if (!response.meta.success) return false
        return true
      }).then((res) => {
        if (res) {
          this.show = true
        }
      })
    },
    onSubmit() {

      const {maxFee, minFee, minCoin} = this.assets
      const params = {
        amount: Number(this.amount),
        networkFees: Number(this.networkFees),
        withdrawAddress: this.withdrawAddress,
        coinId: this.query.coinId,
        usdtType: this.chainData.chainList ? this.chainData.chainList[this.active].chain : ''
      }

      if (!params.amount || params.amount <= 0) return this.$notify(this.$t('M.account_failure_00103'))
      if (params.amount < minCoin) return this.$notify(this.$t('M.The_withdrawal_amount_cannot_be_less', [minCoin]))
      if (params.amount <= params.networkFees) return this.$notify(this.$t('M.Minimum_handling_charge'))

      if (!this.withdrawAddress) return this.$notify(this.$t('M.Coin_Address_draw'))

      if (!params.networkFees || params.networkFees <= 0) return this.$notify(this.$t('M.input_service_charge'))

      if (params.networkFees < minFee || params.networkFees > maxFee) return this.$notify(this.$t('M.the_fee_is_outside_the_range') + minFee + '-' + maxFee)

      this.params = params
      this.validateAddress()

    },
    // 确认交易密码的回调
    onSubmitPass() {
      this.amount = ''
      this.onClosed()
      this.getAssets()
      this.$router.replace({path: '/withdrawalRecords', query: {active: 2, coinId: this.query.coinId,}});
    },

    onClosed() {
      this.show = false
    }
  },
  beforeRouteLeave(to, form, next) {
    // 进入子页面缓存 退出重载
    if (to.name !== 'addressList') {
      // console.log(' 退出重载');
      this.$store.commit('SET_SELECT_ADDRESS', {})
      this.reload();
    }
    next()
  }
  // destroyed() {
  //   this.$store.commit('SET_SELECT_ADDRESS', {})
  // }
};
</script>

<style lang="scss" scoped>
.chargingAddress {
  padding: 0 15px;

  .title {
    font-size: 18px;
    font-weight: 400;
    color: #191b27;
  }

  ::v-deep .right {
    //font-size: 10px!important;
    font-weight: 400;
    color: #5c6573;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    max-width: 100px;
    line-height: 16px;
    text-align: right;
  }

  .main {
    .tabs {
      //padding: 0 15px;

      .tabs_item {
        display: flex;
        align-items: center;
        min-height: 28px;

        .btn {
          width: 85px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 2px;
          font-size: 14px;
          font-weight: 400;
          color: #9299a9;
          border: 1px solid #9299a9;
          margin-right: 15px;
        }

        .active_btn {
          background: #00b897;
          font-weight: 600;
          color: #ffffff;
          border: none;
        }
      }
    }

    .cell {
      font-size: 14px;
      font-weight: 400;

      .tit {
        font-size: 14px;
        color: #191b27;
        padding: 20px 0 15px;
        font-weight: 600;

        span {
          font-size: 12px;
          color: #9299a9;
        }
      }

      .slot_button {
        display: flex;
        align-items: center;

        img {
          width: 19px;
          height: 19px;
        }
      }

      .button {
        // width: 58px;
        padding: 0 0 0 15px;
        height: 100%;
        text-align: center;
        border-left: 1px solid #ebebf0;
        color: #00b897;
        margin-left: 10px;
      }

      .desc {
        font-size: 12px;
        color: #9299a9;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;

        > span {
          > span {
            color: #00b897;
          }
        }
      }
    }
  }
}

::v-deep .cell .van-field {
  width: 100%;
  height: 43px;
  border-radius: 4px;
  border: 1px solid #c7ccd8;
  color: #9299a9;

  .van-field__control {
    color: #9299a9;
  }
}
</style>
