<template>
    <div class="share-page">
            <div  ref="parentElement" class="shareBox">
                
                <div class="titleTime">{{$t('M.Share time')}} {{localTime}}</div>
                <div class="title">{{$t('M.Uneasy lies the head that wears a crown')}}
                    </div>
                <div class="box">
                    <div class="b-title">
                        {{$t('M.PL Ratio')}}
                        <van-tag v-if="item.openType!='OPEN_DOWN'" color="#e7f7f2" text-color="#18ae83">{{tradeMode2(item.openType)}}</van-tag>
                        <van-tag v-else color="#ffefef" text-color="#ED0070">{{tradeMode2(item.openType)}}</van-tag>
                    </div>
                    <div v-if="item.allIncomeAmount" class="b-Yield"> <span class="Sub"
                                                           :class="Math.sign(item.allIncomeAmount) == -1?'red':'gre'">
                        {{
                          Math.sign(item.allIncomeAmount) == -1 ? '' : '+'
                        }}{{ item.allIncomeAmountRate?item.allIncomeAmountRate:cutOutPointLength((parseFloat(item.incomeRate) * 100),2) }}%</span>
                    </div>
                    <div v-else class="b-Yield"> <span class="Sub"
                                                           :class="Math.sign(item.incomeRate) == -1?'red':'gre'">
                        {{
                          Math.sign(item.incomeRate) == -1 ? '' : '+'
                        }}{{ cutOutPointLength((parseFloat(item.incomeRate) * 100),2) }}%</span>
                    </div>
                    <div class="box-content">
                        <div>
                            <p class="t1">{{item.tradeName.split(' ')[0]}}</p>
                            <p class="s1">{{item.tradeName.split(' ')[1]}}</p>
                        </div>
                        <div>
                            <p class="t1">{{item.openPrice}}</p>
                            <p class="s1">{{$t('M.Avg open price')}}</p>
                        </div>
                        <div>
                            <p class="t1">{{item.unwindPrice}}</p>
                            <p class="s1">{{$t('M.Close price')}}</p>
                        </div>
                    </div>
                    <div class="box-bottom">
                        <img src="@/assets/img/trade/login@2x.png" alt="">
                        <canvas ref="qrcode" /> 
                    </div>
                </div>

                <img ref="bgImage" src="@/assets/img/trade/fen.png" alt="">
                
            </div>
            <div class="footer">
                <van-button class="cancel" @click="$emit('dreg')">{{$t('M.Cancel')}}</van-button>
                <van-button class="save" @click="saveImage">{{$t('M.Save picture')}}</van-button>
                
            </div>
      <!-- <img ref="bgImage" :src="backgroundImage" alt="背景图" /> -->
    </div>
  </template>
  
  <script>
  import html2canvas from 'html2canvas';
  import QRCode from 'qrcode';
  export default {
    data() {
      return {
        localTime: '',
        qrLink: 'https://www.BitMatrixtrading.com/downloadApp' // 这里替换为你要生成二维码的链接
      };
    },
    created(){
        
        
    },
    props:['item','latestDone'],
    mounted(){
        this.getLocalTime();
        // console.log(this.item,this.latestDone);
        // this.$api.appUrl().then(res=>{
        //     this.qrLink = res.data.iosUrl
        //     this.generateQRCode();
        // })
      this.generateQRCode();
    },
    methods: {
        async saveImage() {
            try {
                const parentElement = this.$refs.parentElement;
                const canvas = await html2canvas(parentElement);
                const dataURL = canvas.toDataURL('image/png');
                const a = document.createElement('a');
                a.href = dataURL;
                a.download = 'share_image.png';
                a.click();
            } catch (error) {
                console.error('', error);
            }
        },
         generateQRCode() {
            try {
                const qrcodeElement = this.$refs.qrcode;
                console.log(qrcodeElement);
                 QRCode.toCanvas(qrcodeElement, this.qrLink);
            } catch (error) {
                console.error('生成二维码时出错:', error);
            }
        },
        tradeMode2(key){
            if(key =='OPEN_UP') return this.$t('M.Long')
            if(key =='OPEN_DOWN') return this.$t('M.Short')
        },
        getLocalTime() {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = monthNames[now.getMonth()];
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        this.localTime = `${day}.${month} ${year} ${hours}:${minutes}:${seconds}`;
        },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .shareBox{
    position: relative;
    display: inline-block;
    .titleTime{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 13px;
        color: #742F25;
        line-height: 15px;
        text-align: left;
        position: absolute;
        top: 10px;
        left: 15px;
    }
    .title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 25px;
        color: #742F25;
        line-height: 35px;
        position: absolute;
        top: 70px;
        left: 20px;
        width: 185px;
        height: 70px;
    }
    .box{
        width: 295px;
        height: 280px;
        background: rgba(255,255,255,0.9);
        border-radius: 12px 12px 12px 12px;
        // margin: auto;
        position: absolute;
        top: 170px;
        left: 20px;
        backdrop-filter: blur(5px);
        padding: 20px;
        .b-title{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #000000;
            line-height: 18px;
            text-align: center;
            margin-bottom: 15px;
        }
        .b-Yield{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 30px;
            color: #00B897;
            line-height: 35px;
            text-align: center;
            margin-bottom: 20px;
        }
        .box-content{
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            .t1{
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #000000;
                line-height: 14px;
                text-align: center;
                margin-bottom: 5px;
            }
            .s1{
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 11px;
                color: #888888;
                line-height: 13px;
                text-align: center;
            }
        }
        .box-bottom{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            img{
                width: 70px;
                height: 70px;
            }
            canvas{
                width: 70px!important;
                height: 70px!important;
            }
        }
    }
  }
  .share-page {
    text-align: center;
    padding: 40px 20px 20px 20px;
  }
  .share-page img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  .red{
      color: #e54d68;
    }
    .gre{
      color: #1baf84;
    }
    .footer{
        display: flex;
        justify-content: space-between;
        .save{
            width: 160px;
            height: 38px;
            background: #00b897;
            border-radius: 19px 19px 19px 19px;
            color: #fff;
        }
        .cancel{
            width: 160px;
            height: 38px;
            border-radius: 19px 19px 19px 19px;
            border: 1px solid #D1D4DD;
            color: #888888;
        }
    }
    
  </style>    