
<template>
  <div class="detail">
    <van-sticky>
      <nav-bar class="nav_bar" :title="$t('M.deal_the_detail')" />
    </van-sticky>
    <div class="contant">
      <div class="top">
        <div class="left">
          <div class="type2" v-if="contantData.type == 'BUY'">
            {{ $t("M.buy") }}
          </div>
          <div class="type" v-if="contantData.type !== 'BUY'">
            {{ $t("M.sell") }}
          </div>
          <div class="duiming">
            {{ contantData.sellCoinName }}/{{ contantData.buyCoinName }}
          </div>
        </div>
        <div class="right">

        </div>
      </div>
      <div class="bottom">
        <div class="itembottom item1">
          <p class="tit">{{ $t("M.deal_amount") }}({{ contantData.buyCoinName }})</p>
          <p class="content">{{ contantData.completeAmount }}</p>
        </div>
        <div class="itembottom item2">
          <p class="tit">
            {{ $t("M.deal_price") }}({{ contantData.buyCoinName }})
          </p>
          <p class="content">{{ contantData.completePrice }}</p>
        </div>
        <div class="itembottom item3">
          <p class="tit">{{ $t("M.trading_volume") }}({{ contantData.sellCoinName }})</p>
          <p class="content">{{ contantData.completeCount }}</p>
        </div>
      </div>
      <div class="bottom">
        <div class="itembottom item1">
          <p class="tit">{{ $t("M.free") }}({{ contantData.type== 'BUY' ? contantData.sellCoinName :contantData.buyCoinName }})</p>
          <p class="content">{{ contantData.fees }}</p>
        </div>
      </div>
    </div>
    <div class="BOX">
      <div class="item">
        <div class="left">{{ $t("M.order_number") }} </div>
        <div class="right rightimg">{{ contantData.orderList[0].id }} <img style="width:15px;height:15px;" @click="copy(contantData.orderList[0].id)"
            src="../../assets/img/public/copy.png" alt=""></div>
      </div>
      <div class="item">
        <div class="left">{{ $t("M.bb_time") }} </div>
        <div class="right">{{ contantData.orderList[0].createTime }} </div>
      </div>
      <div class="item">
        <div class="left">{{ $t("M.transaction_price") }} </div>
        <div class="right">{{ contantData.completePrice }} </div>
      </div>
      <div class="item">
        <div class="left">{{ $t("M.trading_volume") }} </div>
        <div class="right">{{ contantData.count }} </div>
      </div>
      <div class="item">
        <div class="left">{{ $t("M.free") }}({{ contantData.type== 'BUY' ? contantData.sellCoinName :contantData.buyCoinName }}) </div>
        <div class="right">{{ contantData.fees }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
export default {
  components: {
    navBar,
  },
  data() {
    return {
      loading: false,
      finished: true,
      list: [],
      page: 1,
      contantData: {},
      getid: ''
    };
  },
  mounted() {

    this.getid = this.$route.query.id;

    this.$api.queryMyEntrustOrder({
      partnerId: '472437831826935808',
      partnerNo: '1000010001',
      entrustId: this.getid,
    }).then((res) => {
      console.log(res)
      this.contantData = res.data
    })
  },
  methods: {
    copy(data) {
      this.copyUrl(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.BOX {
  background: #f8f8fb;
  width: 345px;
  margin: 0 auto;
  margin-top: 15px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 40px;
  padding: 15px;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      color: #9299a9;
    }
    .right {
      color: #191b27;
    }
  }
}
.contant {
  padding: 15px;
  border-bottom: 1px solid #ebebf0;
}
.rightimg {
  display: flex;
  align-items: center;
  img {
    margin-left: 5px;
  }
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .right {
    font-size: 12px;
    display: flex;
    align-items: center;
    img {
      width: 6px;
      height: 10px;
    }
    .rightText {
      color: #00b897;
    }
  }

  .left {
    display: flex;
    align-items: center;
  }
  .duiming {
    font-size: 16px;
    font-weight: 600;
    color: #191b27;
    margin-left: 15px;
  }
  .type {
    // width: 36px;
    height: 20px;
    background: rgba(255, 96, 96, 0.1);
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #ED0071;
    line-height: 20px;
    padding: 0 4px;
  }
  .type2 {
    // width: 36px;
    height: 20px;
    background: rgba(255, 96, 96, 0.1);
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #ED0071;
    line-height: 20px;
    background: #d6ede5 !important;
    color: #0AA869;
    padding: 0 4px;
  }
}
.bottom {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
  .itembottom {
    line-height: 19px;
    font-size: 13px;
    margin-top: 0;
    word-break: break-all;
    white-space: normal;
    padding: 0 2px;
    .tit {
      font-weight: 400;
      color: #9299a9;
    }
    .content {
      color: #191b27;
      margin-top: 5px;
      white-space: nowrap;
      font-size: 10px;
    }
  }
  .item1 {
    text-align: left;
  }
  .item2 {
    text-align: center;
  }
  .item3 {
    text-align: right;
  }
}
</style>