<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-18 17:50:14
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="Details container">
    <van-nav-bar :title="details.projectName" left-text="" left-arrow @click-left="onClickLeft" />
    <div class="content">

      <div class="topbox">
        <div class="title">
          {{details.projectName}}
        </div>
        <div class="smallTit">
          {{$t('M.Fundraised')}}：<span>{{details.appliedAmount}}{{details.coinName}}</span>
        </div>
        <div class="smallTit">
          {{$t('M.Current Progress')}}：<span>{{details.appliedPercent}}%</span>
        </div>
        <van-progress class="progress" pivot-text="" color="#00b897" :percentage="details.appliedPercent" stroke-width="8" />

        <div class="smallTit overtime"><span>{{$t('M.Time Left')}}</span>
        </div>
        <div class="timeBox">
          <div class="timeItem">
            <div class="topNum">
              {{Week}}
            </div>
            <div class="text">
              {{$t('M.Week-1')}}
            </div>
          </div>
          <div class="timeItem">
            <div class="topNum">
              {{Days}}
            </div>
            <div class="text">
              {{$t('M.day-2')}}
            </div>
          </div>
          <div class="timeItem">
            <div class="topNum">
              {{Hours}}
            </div>
            <div class="text">
              {{$t('M.hours')}}
            </div>
          </div>
          <div class="timeItem">
            <div class="topNum">
              {{Minutes}}
            </div>
            <div class="text">
              {{$t('M.minutes')}}
            </div>
          </div>
          <div class="timeItem">
            <div class="topNum">
              {{Seconds}}
            </div>
            <div class="text">
              {{$t('M.S-1')}}
            </div>
          </div>
        </div>

      </div>
      <!-- 时间 -->
      <div class="timeDetalse">
        <div class="item">
          <div class="itmeName"> {{$t('M.Total Target')}}:</div>
          <div class="itemData">{{details.amount}}{{details.coinName}}(1{{details.coinName}}={{details.price}}{{details.priceCoinName}})</div>
        </div>
        <div class="item">
          <div class="itmeName"> {{$t('M.start_time')}}:</div>
          <div class="itemData">{{details.startTime}}</div>
        </div>
        <div class="item">
          <div class="itmeName"> {{$t('M.End Time')}}:</div>
          <div class="itemData">{{details.endTime}}</div>
        </div>
      </div>

      <!-- 按钮 -->
      <div class="btnBox">
        <div class="buyBox">
          <div class="buyText">{{$t('M.Conversion Amount')}}</div>
          <div class="buyInput">
            <van-field v-model="bayNum" center clearable type="digit">
              <template #button>
                <div>{{details.priceCoinName}}</div>
              </template>
            </van-field>
          </div>

          <div class="balanceBox">
            <van-popover v-model="showPopover" trigger="click" :actions="actions" placement="top">
              <template #reference>
                <img src="../../assets/img/IEO/why.png" alt="" srcset="" style="width:14px;">
              </template>
            </van-popover>
            <div class="text">{{$t('M.Available')}}:<span>{{userMoney}}{{details.priceCoinName}}</span></div>
          </div>
        </div>

        <!-- 已结束 -->
        <div class="btn" v-if="details.endCountDown  <= 0">
          {{$t('M.Ended2')}}
        </div>

        <!-- 等待开始 -->
        <div class="btn" v-if="details.startCountDown > 0">
          {{$t('M.Preheating')}}
        </div>

        <!-- 立即申购 -->
        <div class="btn now" v-if="details.startCountDown <= 0 && details.endCountDown  > 0" @click="nowBuy">
          {{$t('M.Conversion Now')}}
        </div>
      </div>

      <!-- 条件 -->
      <div class="conditions" v-if="conditionsArr.length != 0 ">
        <div class="tit">{{$t('M.Conditions')}}</div>
        <ol style="list-style: initial;list-style-type: decimal;" class="listBox">
          <li v-for="(item,idx) in  conditionsArr" :key="idx">{{item}}</li>
        </ol>
      </div>

      <div v-html="details.projectDesc" style="margin-top: 15px;"></div>
      <div v-html="details.projectDetail"></div>
      <div v-html="details.questions"></div>

      <p class="bottomTips">{{$t('M.Risk warning: It is displayed')}}</p>

    </div>
    <OnlyPassword :show="show" @onSubmit="submit" @onClose='onClosePassword' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OnlyPassword from '@/components/popupPassword/OnlyPassword.vue';
import { Toast } from 'vant';
export default {
  components: {
    OnlyPassword
  },
  data() {
    return {
      details: {
        projectDesc: '',
        appliedAmount: '',
        coinName: '',
        appliedPercent: '',
        projectDetail: '',
        questions: '',
        status: 'ended',
      },
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [{ text: this.$t('M.From wallet assets') }],
      /* 买的钱 */
      bayNum: null,
      userMoney: 0,
      conditionsArr: [],
      showTimeDown: 0,
      timeObj: '',
      /* 所持币种是否满足 */
      time1Flag: true,
      time2Flag: true,
      /* 所需认证是否满足条件 */
      advancedAuthFlag: true,
      realNameAuthFlag: true,
      polling: '',
      show: false,
      rushResultTime: null
    }
  },
  computed: {
    ...mapGetters([
      'user', 'isLogin'
    ]),
    Week() {
      return parseInt(`${this.formatSeconds(this.showTimeDown)}`.split("_")[0] / 7);
    },
    Days() {
      return `${this.formatSeconds(this.showTimeDown)}`.split("_")[0] % 7;
    },
    // 倒计时 小时
    Hours() {
      return `${this.formatSeconds(this.showTimeDown)}`.split("_")[1];
    },
    Minutes() {
      return `${this.formatSeconds(this.showTimeDown)}`.split("_")[2];
    },
    Seconds() {
      return `${this.formatSeconds(this.showTimeDown)}`.split("_")[3];
    },

  },

  mounted() {
    this.initData()
    // this.timeObj = setInterval(() => {
    //   this.initData()
    // }, 1000);
    /* 查询对列标识 */
    if (this.isLogin) {
      this.getUserMoney()
      // this.checkQueueFlag()
      // this.getJointTermCoin()
    }
  },
  methods: {
    onClosePassword() {
      this.show = false
    },
    checkQueueFlag() {
      this.$api.checkQueueFlag({ projectSn: this.$route.query.id }).then((res) => {
        this.time1Flag = res.data.term1
        this.time2Flag = res.data.term2
      })
    },
    /* 校验 */
    getJointTermCoin() {
      this.$api.getJointTermCoin({ id: this.$route.query.id }).then((res) => {
        this.time1Flag = res.data.term1
        this.time2Flag = res.data.term2
        this.userMoney = res.data.usableCoinNum
      })
    },
    nowBuy() {
      if (!this.isLogin) {
        this.$router.push({ name: 'login' })
        return;
      }
      if (!this.advancedAuthFlag) {
        this.$notify(`${this.$t('M.Conditions')}:${this.$t('M.You must pass advanced KYC')}`)
        return;
      }
      if (!this.realNameAuthFlag) {
        this.$notify(`${this.$t('M.Conditions')}:${this.$t('M.You must pass basic KYC')}`)
        return;
      }
      if (!this.time1Flag) {
        this.$notify(`${this.$t('M.Conditions')}:${this.$t('M.Account order exceeds')}${this.details.term1CoinLimit}${this.details.term1CoinName}`)
        return;
      }
      if (!this.time2Flag) {
        this.$notify(`${this.$t('M.Conditions')}:${this.$t('M.Account order exceeds')}${this.details.term2CoinLimit}${this.details.term2CoinName}`)
        return;
      }
      if (this.bayNum < this.details.downLimit * 1) {
        this.$notify(this.$t('M.recharge_withdraw_failure_0051'))
        return;
      }
      if (this.bayNum > this.details.upLimit * 1) {
        this.$notify(this.$t('M.recharge_withdraw_failure_0050'))
        return;
      }
      if (this.bayNum > this.userMoney * 1) {
        this.$notify(this.$t('M.Insufficient balance'))
        return;
      }
      this.$api.isInputPayPasswd().then((res) => {
        console.log("res", res)
        if (res.data) {
          this.show = true;
        } else {
          this.submit()
        }
      })
    },
    submit(code) {
      let sendData = {
        id: this.details.id,
        payPassword: code ? code : '',
        rushNum: this.bayNum,
      }
      this.show = false;
      this.betNum = '';
      this.$api.rushToPurchase(sendData).then((res) => {
        if (res.meta.success) {
          // this.$notify(this.$t('M.Successfully'))
          /* 下单成功.轮询请求结果 */
          this.getRushResult(res.data)
        } else {
          this.$notify(res.meta.message)
        }
        this.getUserMoney()
      })
    },
    getRushResult(id) {

      this.rushResultTime = setInterval(() => {
        this.$api.getRushResult({ orderSn: id }).then((res) => {

          if (res.meta.success) {
            let text = ""
            if (res.data == "queuing") {
              text = this.$t("M.Ongoing2")
              Toast.loading({
                message: text,
                forbidClick: true,
                duration: 0
              });
            }
            if (res.data != "failed") {
              this.$notify(res.meta.message)
              clearInterval(this.rushResultTime)
              Toast.clear()
            }
          } else {
            clearInterval(this.rushResultTime)
            Toast.clear()
            this.$notify(res.meta.message)
          }
        })
      }, 1000);

    },
    initData() {
      window.clearInterval(this.timeObj)
      window.clearInterval(this.polling)
      if (!this.$route.query.id) {
        return
      }

      this.$api.getProjectDetails({ id: this.$route.query.id }).then((res) => {
        this.conditionsArr = []
        this.details = res.data.details;
        if (this.details.authType != 'dispense_with_auth') {
          if (this.details.authType == 'real_name_auth') {
            this.conditionsArr.push(this.$t('M.You must pass basic KYC'))
            if (this.user.realNameAuth != 'y') {
              this.realNameAuthFlag = false;
            }
          } else {
            this.conditionsArr.push(this.$t('M.You must pass advanced KYC'))
            if (this.user.advancedAuth != "pass") {
              this.realNameAuthFlag = false;
            }
          }
        }
        /* 币种要求 */
        if (this.details.term1CoinName != '') {
          this.conditionsArr.push(`${this.$t('M.Account order exceeds')}${this.details.term1CoinLimit}${this.details.term1CoinName}`)
        }
        if (this.details.term2CoinName != '') {
          this.conditionsArr.push(`${this.$t('M.Account order exceeds')}${this.details.term2CoinLimit}${this.details.term2CoinName}`)
        }

        if (this.details.startCountDown > 0) {
          this.showTimeDown = this.details.startCountDown;
        } else {
          this.showTimeDown = this.details.endCountDown;
        }

        if (this.details.status != 'ended') {
          if (this.showTimeDown > 20000) {
            this.timeObj = setInterval(() => {
              this.showTimeDown = this.showTimeDown - 1000;
              if (this.showTimeDown <= 20000) {
                this.initData()
                return
              }
              if (this.showTimeDown % 2000 == 0) {
                this.initData()
              }
            }, 1000);
          } else {
            this.timeObj = setInterval(() => {
              this.initData()
            }, 1000);
          }
        }
      })
    },

    /* 请求用户余额 */

    getUserMoney() {
      if (this.isLogin) {
        this.$api.getJointTermCoin({ id: this.$route.query.id }).then((res) => {
          this.time1Flag = res.data.term1
          this.time2Flag = res.data.term2
          this.userMoney = res.data.usableCoinNum
        })
      }
    },
    onSelect(action) {
      Toast(action.text);
    },
    copyCode() {

    },
    onClickLeft() {
      this.$router.replace("IEO")
    },

    formatSeconds(date) {
      function formatTime(time) {
        if (time < 10) time = `0${parseInt(time)}`
        return time
      }
      const timeObj = this.getDateTime(date)
      let {
        dayTime,
        hourTime,
        minuteTime,
        secondTime
      } = timeObj
      let result
      result = `${formatTime(dayTime)}_${formatTime(hourTime)}_${formatTime(minuteTime)}_${formatTime(secondTime)}`
      return result
    },
    getDateTime(date) {
      // 获取总秒数
      let secondTime = parseInt(date / 1000)
      let dayTime = 0 // 天
      let minuteTime = 0 // 分
      let hourTime = 0 // 小时

      // 如果秒数大于60，将秒数转换成整数
      if (secondTime >= 60) {
        // 获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60)
        // 获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60)
        // 如果分钟大于60，将分钟转换成小时
        if (minuteTime >= 60) {
          // 获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60)
          // 获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60)
        }
        if (hourTime >= 24) {
          dayTime = parseInt(hourTime / 24)
          hourTime = parseInt(hourTime % 24)
        }
      }
      return {
        dayTime,
        hourTime,
        minuteTime,
        secondTime
      }
    }

  },
  destroyed() {
    window.clearInterval(this.polling)
    window.clearInterval(this.timeObj)
    window.clearInterval(this.rushResultTime)
  }
}
</script>
<style lang="scss" scoped>
.Details {
  img {
    width: 100% !important;
  }

  font-size: 12px;
  background-color: #f8f8fb;
  height: 100%;
  .content {
    padding: 12px 15px;
    .topbox {
      padding: 12px 10px;
      background-color: #fff;
      border-radius: 4px;
      .title {
        font-size: 18px;
        font-weight: 600;
        color: #00b897;
        line-height: 25px;
        margin-bottom: 10px;
      }
      .smallTit {
        font-size: 13px;
        color: #9299a9;
        line-height: 20px;
        span {
          color: #000;
        }
      }
      .timeBox {
        display: flex;
        justify-content: space-between;
        text-align: center;
        margin-top: 5px;
        .timeItem {
          width: 44px;
          .topNum {
            width: 100%;
            height: 40px;
            // background: #faf4e9;
            background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
            border-radius: 4px;
            color: #fff;
            text-align: center;
            font-size: 16px;
            line-height: 40px;
          }
          .text {
            line-height: 35px;
            color: #9299a9;
          }
        }
      }
      .progress {
        margin-top: 12px;
      }
      .overtime {
        margin-top: 20px;
      }
    }
    .timeDetalse {
      padding: 8px 10px;
      background-color: #fff;
      border-radius: 4px;
      margin-top: 12px;
      font-size: 13px;
      line-height: 34px;
      .item {
        display: flex;
        .itmeName {
          color: #9299a9;
        }
      }
    }

    .btnBox {
      width: 100%;
      background-color: #fff;
      padding: 20px 10px;
      margin-top: 20px;
      border-radius: 5px;
      .btn {
        width: 260px;
        height: 46px;
        background-color: #c7ccd8;
        line-height: 46px;
        text-align: center;
        margin: 0 auto;
        border-radius: 4px;
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
        margin-top: 30px;
      }
      .now {
        background-color: #00b897;
        color: #fff;
      }
      .buyBox {
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: relative;
        padding-bottom: 26px;
        .buyInput {
          border: 1px solid #c7ccd8;
          border-radius: 4px;
        }
        .balanceBox {
          position: absolute;
          right: 14px;
          bottom: 0;
          display: flex;
          height: 17px;
          align-items: flex-end;

          .text {
            margin-left: 5px;
            span {
              color: #00b897;
            }
          }
        }
      }
    }
    .conditions {
      margin-top: 20px;
      .tit {
        font-size: 15px;
        font-weight: 600;
        color: #000;
      }
      .listBox {
        margin-top: 5px;
        margin-left: 15px;
        color: #9299a9;
        line-height: 19px;
      }
    }
    .bottomTips {
      color: #00b897;
      margin-top: 20px;
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
::v-deep .van-cell--center {
  color: #000;
  border-radius: 4px;
}
::v-deep .van-popover__wrapper {
  width: 14px;
  height: 14px;
}
</style>