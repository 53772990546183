<template>
  <div class="box" :style="topStr">
    <div class='hideAndSearchCoin'>
      <div>
        <van-switch v-model="checked" active-color="#00b897" inactive-color="#9299A9" @input="onInput" />
        <span>{{ lessConfigure.flag? $t('M.hide_coin',[lessConfigure.hideCountConfigure]) : $t('M.Hide_no_asset_currency') }}</span>
      </div>
      <van-search left-icon="" v-model="searchValue" :placeholder="$t('M.search')" />
    </div>
  </div>
</template>
<script>

export default {

  props: {
    searchCoinList: {
      type: Array,
      default: () => []
    },
    top: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      searchValue: '',
      checked: false,
      lessConfigure: {}
    };
  },
  computed: {
    topStr() {
      if (this.top) {
        return 'top:' + this.top + 'rem'
      }
      return ''
    }

  },

  watch: {
    searchValue(n) {
      const searchCoinList = JSON.parse(JSON.stringify(this.searchCoinList))
      if (n) {
        const coinList = searchCoinList.filter(item => item.coinName.toUpperCase().indexOf(n.toUpperCase()) > -1)
        this.$emit('onSearch', coinList)
      } else {
        this.$emit('onSearch', searchCoinList)
      }
    }
  },
  mounted() {
    this.getAccountHideLessCoin()
  },
  methods: {
    getAccountHideLessCoin() {
      this.$api.accountHideLessCoin().then((response) => {
        if (!response.data || !response.meta.success) return
        this.lessConfigure = response.data
      })
    },
    onInput(e) {
      this.$emit('onChecked', e)
    }
  },
};
</script>
<style lang="scss" scoped>
.box {
  background: #fff;
  width: 100%;
  height: 80px;
  padding: 10px 0;
  position: fixed;
  top: 150px;
  z-index: 999;
}
.hideAndSearchCoin {
  width: 100%;
  height: 60px;
  padding: 0 15px;
  background: #f8f8fb;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;

    span {
      font-size: 12px;
      color: #5c6573;
      margin-left: 8px;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep {
  .van-switch {
    font-size: 14px;
  }
  .van-search {
    width: 90px;
    height: 30px;
    background: #ffffff;
    border-radius: 15px;
    overflow: hidden;
    .van-search__content {
      height: 25px;
      background: #ffffff;
      padding-left: 0;
    }
    .van-field__body,
    .van-field__value {
      height: 100%;
      line-height: 30px;
    }
    .van-field__value {
      .van-field__body {
        display: flex;
        align-items: center;
        input {
          font-size: 12px;
          color: #5c6573;
        }
        i {
          font-size: 16px;
        }
      }
    }
    .van-icon-clear {
      transform: translate(1px, 1px);
    }
  }
}
</style>