<!--
 * @Author:
 * @Date: 2022-06-14 09:19:47
 * @LastEditors:
 * @LastEditTime: 2022-07-18 16:44:47
 * @FilePath: \exchange-h5\src\components\HelloWorld.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div :class="['newOption', optionOrderPopupFlag?'blur':'']">
    <!-- 交易对信息 -->
    <van-sticky>
      <div class="tradingTitle tradingTitle1">
        <img class="tradList" @click="tradingPopupShow = true" src="../../assets/img/newOption/tradingList.png" alt="" />
        <div class="tradName" @click="tradingPopupShow = true">{{selectTradSymbol}}</div>
        <div class="tradTime" @click="tradingPopupShow = true">· {{selectTradTime}}</div>
        <img class="tradbing" @click="exchangePopupShow = true" src="../../assets/img/newOption/tradingBing.png" alt="" />
      </div>
    </van-sticky>
    <div class="tradingTitle tradingTitle2">
      <div class="tradName">{{selectTradName}} · {{selectTradTime}}</div>
      <div class="tradNum">{{KlineShowMoneyBox.y_value}}</div>
    </div>
    <!-- 切换K线选项 -->
    <div class="changeKline">
      <div class="tradName">{{$t("M.minhour")}}</div>
      <!-- <img class="jiantou" src="../assets/img/newOption/jiantou.png" alt="" /> -->
    </div>
    <!-- K线 -->
    <div class="Kline">
      <div class="KlineBox" id="klineArea" ref="pageContainer"></div>
      <div id="klinetitle" class="klinetitle" v-show="KlineShowMoneyBox.show"
        :style="{ top: KlineShowMoneyBox.y_top + 'px' ,transition: 'all 0s ease'}">
        <div class="dian"></div>
        <div class="xian" :style="{ width: KlineShowMoneyBox.y_width + 'px' }"></div>
        <div class="title" :style="{ width: KlineShowMoneyBox.y_width - 18 + 'px' }">
          <i class="fuzhu"></i>
          <div style="font-size:10px;color: #fff;line-height:16px;">{{KlineShowMoneyBox.y_value}}</div>
        </div>
      </div>
      <div id="jg" v-if="KineJgshow" :style="{
          right: KineJgright + 'px',
          transition: 'all 0.1s ease',
        }">
        <div class="jg_font">
          <span>
            <img src="../../assets/img/newOption/jg.svg" alt="" />
          </span>
          <span> {{$t("M.Delivery-1")}} </span>
        </div>
        <div class="jg_bg">
          <img style="position:absolute;left:-3px;" class="bg" src="../../assets/img/newOption/jg.png" alt="">
        </div>
        <div class="jg_foter">
          {{KlineShowMoneyBox.time}}
        </div>
      </div>
    </div>
    <!-- <klinekx :openData="openData" :selectTradObj="selectTradObj" :coinName="selectTradName" :optionKline="optionKline" :optionHistoryKline="optionHistoryKline" :k_kine="klineChangeObj.isKline">
    </klinekx> -->

    <!-- 操作区域 -->
    <div class="playArea">
      <!-- 切换项 -->
      <div class="topContant">
        <div class="selectBox">
          <div class="selectItem" v-bind:class="[lookShapeFlag?'duo1':'duo0']" @click="lookShapeFlag = true">{{$t("M.Call")}}</div>
          <div class="selectItem" v-bind:class="[!lookShapeFlag?'kong1':'kong0']" @click="lookShapeFlag = false">{{$t("M.Put")}}</div>
        </div>
        <div class="timeBox">
          <img src="../../assets/img/newOption/time.png" alt="" />
          {{tradDownTime}}
        </div>
      </div>
      <!-- 下注金额 -->
      <div class="inputBox">
        <van-field v-model="betNum" class="input" type="digit" :border="true" :placeholder="$t('M.ios_最小限额')+openData.minBuy" />
        <div class="coinName">USDT</div>
      </div>
      <!-- 金钱信息 -->
      <div class="moneyBox">
        <div class="yue">
          <span>{{$t("M.balance")}}</span>：{{userMoney}} USDT
          <img src="../../assets/img/newOption/change.png" @click="showTransBtn" alt="" />
        </div>
        <div class="shouyi">{{$t("M.expected")}}：{{earningsMoney}} USDT</div>
      </div>
      <!-- 拖拽 -->
      <div class="drag">
        <div class="roundBox">
          <div :class="['roundItem',sliderRate > 0? 'round_item_active' :' ']"> </div>
          <div :class="['roundItem',sliderRate >= 25? 'round_item_active' :' ']"> </div>
          <div :class="['roundItem',sliderRate >= 50? 'round_item_active' :' ']"> </div>
          <div :class="['roundItem',sliderRate >= 75? 'round_item_active' :' ']"> </div>
          <div :class="['roundItem',sliderRate >= 100? 'round_item_active' :' ']"> </div>
        </div>
        <div class="roundNumBox">
          <div class="roundItem roundItem1">0%</div>
          <div class="roundItem roundItem2">50%</div>
          <div class="roundItem roundItem3">100%</div>
        </div>
        <van-slider class="dragLine" inactive-color="#C7CCD8" active-color="#00b897" v-model="dragValue" min="0" @change="dragLineOnChange" />
      </div>

      <!-- 对抗图 -->
      <div class="copeBox">
        <div class="copeLineBox">
          <div class="leftBox" :style="{ width: Jpercent + '%' }"></div>
          <div class="rightBox"></div>
        </div>
        <div class="leftNum">{{Jpercent}}%</div>
        <div class="rightNum">{{100-Jpercent}}%</div>
        <img class="fenge" :style="{ left: Jpercent + '%' }" src="../../assets/img/newOption/fenge.png" alt="" />
      </div>

      <van-button class="betBtn" v-if="!betBtnFlag" v-bind:class="[lookShapeFlag?'betBtn1':'betBtn0']" @click="on_trading">
        {{lookShapeFlag?$t("M.Call"):$t("M.Put")}}({{ betBtnFlag ?cutOutPointLength( openData.upRatio*100,2) : cutOutPointLength( openData.downRatio*100,2)}}%)
      </van-button>
      <van-button class="betBtn" v-if="betBtnFlag" :disabled="true">{{$t("M.Delivery time")}} {{tradDownTime}}
      </van-button>
    </div>
    <div class="dise"></div>
    <!-- 订单 -->
    <van-tabs v-model="orderActive" color="#00b897" title-active-color="#00b897" title-inactive-color="#5C6573" line-width="16px" line-height="3px"
      :animated="true" class="logsTabs" @change="changeOrderActive" sticky offset-top="39px">
      <van-tab :title="$t('M.Awaiting')">
        <van-empty class="custom-image" :image="noDataImg" :description="$t('M.no_data')" />
      </van-tab>
      <van-tab :title="$t('M.Checkout')">
        <van-list v-model="logListloading" :finished="logListFinished" finished-text="" @load="logListLoad" loading-text="loading...">
          <van-cell class="logItem" :border="false">
            <div style="display:flex;justify-content: space-between;">
              <div class="logItemChildBox logItemChildTit logItemChildBox-left">
                {{$t("M.Sec Swap")}}
              </div>
              <div class="logItemChildBox logItemChildTit logItemChildBox-left">
                {{$t("M.Order Price")}}
              </div>
              <div class="logItemChildBox logItemChildTit logItemChildBox-right">
                {{$t("M.Expected Return")}}
              </div>
              <div class="logItemChildBox logItemChildTit logItemChildBox-right" style="flex:1.3;">
                {{$t("M.Billing Amount")}}
              </div>
            </div>
          </van-cell>
          <van-cell class="logItem" :border="false" is-link v-for="(item,idx) in logLoadArr" :key="idx"
            @click="logItemData = item,logItemFlag = true">
            <div style="display:flex;justify-content: space-between;">
              <div class="logItemChildBox logItemChildBox-left">
                <div class="smallBox1">{{item.sellCoinNickname}} {{item.optionTimeType}}
                  <span v-if="item.directionType == 'up'">{{ $t("M.Up")}}</span>
                  <span v-if="item.directionType != 'up'">{{ $t("M.Down")}}</span>
                </div>
                <div class="smallBox2"> {{item.orderTime}}</div>
              </div>
              <div class="logItemChildBox logItemChildBox-left">{{ cutOutPointLength(item.buyTimePrice,2) }}</div>
              <div class="logItemChildBox logItemChildBox-right">{{ showRation(item.ration)+"%"}}</div>
              <div class="logItemChildBox logItemChildBox-right" v-bind:class="[item.deliveryPrice > 0 ? 'reduce0' : 'reduce1']">
                {{item.deliveryPrice}} USDT
              </div>
            </div>
          </van-cell>
        </van-list>
        <van-empty class="custom-image" :image="noDataImg" :description="$t('M.no_data')" v-if="logLoadArr.length == 0" />
      </van-tab>
    </van-tabs>

    <!-- 左交易对 侧边栏 -->
    <van-popup v-model="tradingPopupShow" position="left" :style="{ width: '220px', height: '100%' }">
      <p class="typeTitle pl15">{{$t('M.Sec Swap')}}</p>
      <div class="tradingSmallBox">
        <div class="tradingSmallTit">{{$t('M.Sec Swap')}}</div>
        <div class="tradingSmallTit">{{$t('M.Delivery Time')}}</div>
      </div>
      <div class="tradingListBox">
        <div class="tradingItemBox" v-for="(tradItem,idx) in tradListArr" :key="idx">
          <div class="tit">
            <div class="text">{{tradItem[0].sellCoinName}}</div>
            <div class="bgImg"></div>
          </div>
          <div class="tradingItem" v-for="(trad,tradidx) in tradItem" :key="tradidx" @click="synbolTabChange(trad)">
            <div class="tradingName">
              <div class="tradingCoin">{{trad.sellCoinName}}</div>
              <div class="tradingTime">{{trad.optionTimeType}}</div>
            </div>
            <div class="tradingTimeRight">{{trad.optionDataList[0].showBackTime}}</div>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 右 指数构成侧边栏 -->
    <van-popup class="exchangePopup" v-model="exchangePopupShow" position="right" :style="{ width: '100%', height: '100%' }">
      <van-nav-bar :title="$t('M.Index Composition')" left-arrow @click-left="exchangePopupShow = false" />
      <div class="itemBox itemTit title">
        <div class="item item1">{{$t('M.Exchange')}}</div>
        <div class="item">{{$t('M.Latest price')}}</div>
        <div class="item item3">{{$t('M.Weights')}}</div>
      </div>
      <div class="itemBox itemTit" v-for="(item,idx) in selectTradObj.exch3rdPriceList" :key="idx">
        <div class="item item1">{{item.platformCode}}</div>
        <div class="item" v-bind:class="[item.priceLimit > 0 ? 'up' : 'down']">{{item.closePrice}}</div>
        <div class="item item3">{{item.weightCoefficient *100 +'%'}}</div>
      </div>
    </van-popup>

    <!-- 右 历史记录详情 -->
    <van-popup class="logItem" v-model="logItemFlag" position="right" :style="{ width: '100%', height: '100%' }">
      <van-nav-bar :title="$t('M.Delivery details')" left-arrow @click-left="logItemFlag = false" @click-right="popopShare">
        <!-- <template #right>
          <img class="fenxiang" src="../../assets/img/newOption/fenxiang.png" alt="" srcset="">
        </template> -->
      </van-nav-bar>
      <p class="money" v-bind:class="[logItemData.deliveryPrice > 0 ? 'up' : 'down']">{{logItemData.deliveryPrice}}</p>
      <p class="smalltit">{{$t('M.Billing Amount')}}(USDT)</p>
      <div class="logItem">
        <div>{{$t('M.order_number')}}</div>
        <div class="results">{{logItemData.orderNo}}</div>
      </div>
      <div class="logItem">
        <div>{{$t('M.NSO Session')}}</div>
        <div class="results">{{logItemData.sellCoinNickname}}{{logItemData.optionTimeType}}</div>
      </div>
      <div class="logItem">
        <div>{{$t('M.Buy Time')}}</div>
        <div class="results">{{logItemData.createTime}}</div>
      </div>
      <div class="logItem">
        <div>{{$t('M.Delivery time')}}</div>
        <div class="results">{{logItemData.deliveryTime}}</div>
      </div>
      <div class="logItem">
        <div>{{$t('M.Bet Type')}}</div>
        <div class="results" v-bind:class="[logItemData.directionType == 'up' ? 'up' : 'down']">
          {{logItemData.directionType == 'up' ?  $t('M.Up') :$t('M.Down')}}</div>
      </div>
      <div class="logItem">
        <div>{{$t('M.Amount')}}</div>
        <div class="results">{{logItemData.orderPrice}}</div>
      </div>
      <div class="logItem">
        <div>{{$t('M.PL Ratio')}}</div>
        <div class="results">{{logItemData.ration}}</div>
      </div>
      <div class="logItem">
        <div>{{$t('M.Order Price1')}}</div>
        <div class="results">{{logItemData.buyTimePrice}}</div>
      </div>
      <div class="logItem">
        <div>{{$t('M.Delivery Price')}}</div>
        <div class="results">{{logItemData.closePrice}}</div>
      </div>
      <div class="logItem">
        <div>{{$t('M.Settlement Currency')}}</div>
        <div class="results">USDT</div>
      </div>
      <div class="logItem">
        <div>{{$t('M.status')}}</div>
        <div class="results">{{logItemData.status}}</div>
      </div>
    </van-popup>

    <!-- 秒合约弹窗 -->
    <van-popup v-model="optionOrderPopupFlag" round :close-on-click-overlay='false'
      style="width:80%;padding:20px 0 0;border-radius: 10px;background-color: transparent" get-container="#app">
      <div class="popupcontant" id="shareImgDom">
        <p class="optionOrderPopupTit">{{orderData.tradeName}}</p>
        <div class="planBody">
          <van-circle v-if="optionOrderPopupData.state == 0" v-model="optionOrderPopupData.plan" :rate="100" :stroke-width="60"
            :text="optionOrderPopupData.beginStartTimeNum" layer-color="#e0e0e0" color="#00b897" size="130px" style="margin:0 auto;display: block;" />

          <div class="resultWait" v-if="optionOrderPopupData.state == 1">
            <img class="resultWaitImg" src="../../assets/img/newOption/jiesuan2.png" />
            <p class="resultWaitText">{{$t('M.Settlement')}}...</p>
          </div>
          <div class="resultOver" v-if="optionOrderPopupData.state == 2">
            <img class="resultOverImg" src="../../assets/img/newOption/huojiang.png" />
            <p class="resultOverText" v-bind:class="[optionOrderPopupData.resultIncome > 0 ? 'win' : 'loser']">
              {{ optionOrderPopupData.resultIncome }} <span>USDT</span>
            </p>
            <div class="over">{{$t('M.Completed')}}</div>
          </div>
          <span slot="footer" class="dialog-footer">
            <div class="orderDataBox">
              <div class="dataitem">
                <div class="left" v-if="!sharePopupShow">{{$t('M.ReferencePrice')}}</div>
                <div class="left" v-if="sharePopupShow">{{$t('M.Order Price')}}</div>
                <div class="right">{{ orderData.buyTimePrice }} USDT</div>
              </div>
              <div class="tips" v-if="!sharePopupShow">{{$t('M.referenceTips')}}</div>
              <div class="dataitem">
                <div class="left">{{$t('M.Cycle')}}</div>
                <div class="right">{{ orderData.optionTimeTypeS }}s</div>
              </div>
              <div class="dataitem">
                <div class="left">{{$t('M.direction')}}</div>
                <div class="right" v-if="orderData.directionType == 'up'" style="color: #01b184">
                  {{$t('M.Up')}}
                </div>
                <div class="right" v-if="orderData.directionType != 'up'" style="color: #fe3f51">
                  {{$t('M.Down')}}
                </div>
              </div>
              <div class="dataitem">
                <div class="left">{{$t('M.AMT')}}</div>
                <div class="right">{{ orderData.orderPrice }} USDT</div>
              </div>
              <div class="dataitem">
                <div class="left" v-if="sharePopupShow">{{$t('M.PL Ratio')}}</div>
                <div class="left" v-if="!sharePopupShow">{{$t('M.Expected Return')}}</div>
                <div class="right">{{ showRation(orderData.ration) }}%</div>
              </div>
              <div class="dataitem">
                <div class="left" v-if="sharePopupShow">{{$t('M.income')}}</div>
                <div class="left" v-if="!sharePopupShow">{{$t('M.expected')}}</div>
                <div class="right">{{ orderData.exceptProfit }} USDT</div>
              </div>
            </div>
          </span>
        </div>
      </div>
      <div class="fenxiangBtn" v-if="sharePopupShow">
        <div class="close" @click="optionOrderPopupFlag = false">{{$t('M.recharge_withdraw_msg_status_0004')}}</div>
        <div class="save" @click="saveImg">{{$t('M.Save picture')}}</div>
      </div>
    </van-popup>

    <transfer :showTransfer="showTransfer" @onClose="onTransClose" @onConfirm="onTransConfirm">
    </transfer>
  </div>
</template>

<script>
/* 引入图表 */
import * as echarts from 'echarts/core';
import {GridComponent} from 'echarts/components';
import {LineChart} from 'echarts/charts';
import {UniversalTransition} from 'echarts/features';
import {CanvasRenderer} from 'echarts/renderers';
// import CircleProgress from "vue-circleprogressbar";
import {mapGetters} from 'vuex'
import {createSocket, oncloseWS, sendWSPush} from "../../assets/js/socket";
import {cutOutPointLength} from "@/utils/util.js";
import transfer from '@/components/transfer';
import html2canvas from 'html2canvas'

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);
// import Progress from "easy-circular-progress";
// import klinekx from "./klineContract.vue";
var moment = require('moment-timezone')
moment.tz.setDefault("America/New_York");

export default {
  name: "newOption",
  components: {
    // CircleProgress,
    transfer
    // klinekx,
  },
  data() {
    return {
      chart: null,
      dragValue: 0,
      noDataImg: require("@/assets/img/public/nodata.png"),
      list: [],
      /* 历史订单加载状态 */
      logListloading: false,
      /* 是否加载了全部 */
      logListFinished: false,
      /* 加载历史发送的数据 */
      logLoadDataPageNum: 1,
      /* 加载历史数据 */
      logLoadArr: [],
      /* 左侧交易对显示控制 */
      tradingPopupShow: false,
      /* 交易对列表 */
      tradListArr: [],
      /* 交割列表 */
      orderActive: '',
      /* 选择的交易对名字 */
      selectTradSymbol: '',
      /* 选择的交易对币种名字 */
      selectTradName: '',
      /* 选择的交易对对象 */
      selectTradObj: {},
      /* 选择的交易对时间 */
      selectTradTime: '',
      /* 右上角显示的价格 */
      nowCoinShowMoneyNum: 0,
      /* 分时线配置数据 */
      options: {},
      /* 心跳包解密 */
      pako: require("pako"),
      handleSelectData: [],
      d_echarts: {},
      optionKline: [],
      optionHistoryKline: [],
      /* 截取多少条数据 */
      numLine: 40,
      /* 用户余额 */
      userMoney: 0,
      /* 下单倒计时 */
      tradDownTime: '00:00',
      /* 看多看空  true-多 false-空*/
      lookShapeFlag: true,
      /* 禁用下单 */
      betBtnFlag: false,
      /* 下单金额 */
      betNum: '',
      /* 下单金额提示 */
      betNumTips: '',
      /* 综合信息 */
      openData: {
        // 购买数据
        upRatio: 0, //看涨收益率
        downRatio: 0, // 看跌收益率
        maxBuy: 12000, //支付限额最大买入量
        minBuy: 0, //支付限额最小买入量
        isMinBuy: 0, //是否包含支付限额最小买入量：0-不包括，1-包括
        isMaxBuy: 0, //是否包含支付限额最大买入量：0-不包括，1-包括
        direction: 0.01,
      },
      /* 柱状图百分比 */
      Jpercent: 50,
      /* 是否登录 */
      // isLogin: true,
      /* 下单数据弹窗显示 */
      optionOrderPopupFlag: false,
      sharePopupShow: false,
      /* 下单数据弹窗 */
      optionOrderPopupData: {
        /* 进度 */
        plan: 0,
        /* 显示的倒计时 */
        popupBackNum: 0,
        /* 倒计时对象 */
        timeBackObj: null,
        /* 开始倒计时的文本 */
        beginStartTimeNum: 0,
        /* 状态 0倒计时 1等待 2结果 */
        state: 0,
        /* 等待结果倒计时对象 */
        resultWaitObj: null,
        /* 等待结果的时间 */
        resultWaitTimeNum: 0,
        /* 输赢结果 */
        resultIncome: 0,
      },
      /* 下单弹窗的数据 */
      orderData: {
        optionTimeTypeS: '',
        buyTimePrice: '',
        directionType: '',
        orderPrice: '',
        exceptProfit: '',
      },
      /* 交易所指数构成 */
      exchangePopupShow: false,
      /* 历史记录详情数据 */
      logItemData: {},
      /* 历史记录详情显示 */
      logItemFlag: false,
      /* K线价格显示盒子 */
      KlineShowMoneyBox: {
        y_widths: '',
        y_value: '',
        y_top: '',
        KineJgwidth: 50,
        show: false
      },
      /* K线切换数据 */
      klineChangeObj: {
        /* 显示的文本 */
        showNme: this.$t('M.minhour'),
        /* 是否是K线 */
        isKline: false,
        /* 是否显示选择popup */
        isShowPopup: false,
      },
      /* 交割线是否显示 */
      KineJgshow: false,
      showTransfer: false,


      sliderRate: 0,
    };
  },
  created() { },
  computed: {
    ...mapGetters([
      'isLogin', 'user'
    ]),
    // 计算属性的 getter
    earningsMoney: function () {
      return this.lookShapeFlag ? cutOutPointLength(this.openData.upRatio * this.betNum, 4) : cutOutPointLength(this.openData.downRatio * this.betNum, 4);
    }
  },
  watch: {
    isLogin(val) {
      this.getUserMoney()
    }
  },
  beforeMount() {
    // if(this.isLogin){
    //   this.$store.dispatch('getUserWalletAssets')
    // }
  },
  mounted() {
    var chartDom = document.getElementById('klineArea');
    this.chart = echarts.init(chartDom);

    this.options = {
      "backgroundColor": "#fff",
      "grid": {
        "left": "0px",
        "top": "10px",
        "right": "80px",
        "bottom": "30px"
      },
      "xAxis": {
        "type": "time",
        "splitLine": {
          "lineStyle": {
            "color": "#F2F6FC"
          }
        },
        "axisLine": {
          "lineStyle": {
            "color": "#F2F6FC"
          }
        },
        "axisTick": {
          "length": 10
        },
        "splitNumber": 4,
        "axisLabel": {
          "color": "#A6ADBA",
          "margin": 16,
          formatter: function (value, index) {
            return moment(value).format("HH:mm:ss")
          },
        },
        "title": {
          "aligin": "center"
        },
        "boundaryGap": false
      },
      "yAxis": {
        "minInterval": 0.00001,
        "type": "value",
        "position": "right",
        "axisTick": {
          length: 10,
        },
        "axisLabel": {
          "show": true,
          "margin": 16,
          "color": "#A6ADBA",
          formatter: function (value, index) {
            let num = localStorage.getItem("options-num") * 1 ? localStorage.getItem("options-num") * 1 : 2;
            let rpq = "";

            rpq = Number(value).newToFixed(num, "min");

            var newParamsName = "";
            var paramsNameNumber = rpq.length;
            var provideNumber = 100;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                var start = p * provideNumber;
                var end = start + provideNumber;
                if (p == rowNumber - 1) {
                  tempStr = rpq.substring(start, paramsNameNumber);
                } else {
                  tempStr = rpq.substring(start, end) + "\n";
                }
                newParamsName += tempStr;
              }
            } else {
              newParamsName = rpq;
            }

            return newParamsName;
          },
        },
        "axisLine": {
          "lineStyle": {
            "color": "#F2F6FC"
          }
        },
        min: 0,
        max: 0,
        "interval": 0,
        "splitLine": {
          "show": true,
          "lineStyle": {
            "color": "#F2F6FC"
          }
        },
        axisTick: {
          show: true,
        },
      },
      "series": [
        {
          "type": "line",
          "itemStyle": {
            "color": "#00b897",
            "width": "2px"
          },
          "symbol": "none",
          "smooth": true,
          "areaStyle": {
            "color": {
              "x": 0,
              "y": 0,
              "x2": 0,
              "y2": 1,
              "type": "linear",
              "global": false,
              "colorStops": [
                {
                  "offset": 0,
                  "color": "rgba(237, 0, 113, 0.28)"
                },
                {
                  "offset": 1,
                  "color": "rgba(254, 207, 120, 0)"
                }
              ]
            }
          },
          "data": []
        }
      ]
    };
    /* 请求交易对 */
    this.getTradList();
    /* 用户余额 */
    this.getUserMoney()
    /* 请求下单数据弹窗 */
    localStorage.setItem("UpFlag", "1")

    setTimeout(() => {
      if (this.isLogin) {
        this.getAddOrderPopupData()
      }
    }, 1000);

  },
  methods: {
    /* 秒合约弹窗分享 */
    popopShare() {
      console.log("分享弹窗");
      // this.orderData
      this.optionOrderPopupData.state = 2;
      this.optionOrderPopupData.resultIncome = this.logItemData.deliveryPrice;
      this.orderData = this.logItemData;
      this.orderData.optionTimeTypeS = this.logItemData.optionTimeType.split("M")[0] * 60;
      console.log(this.logItemData);
      this.sharePopupShow = true;
      this.optionOrderPopupFlag = true;
    },
    saveImg() {
      let that = this;
      // 获取要导出的DOM
      const rect = document.querySelector('#shareImgDom').getBoundingClientRect()
      html2canvas(document.querySelector('#shareImgDom'), {
        width: rect.width,
        height: rect.height
      }).then(function (canvas) {
        const pageData = canvas.toDataURL('image/jpeg', 1.0)
        const imgData = pageData.replace('image/jpeg', 'image/octet-stream')
        const imgName = 'share.jpg'
        const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
        save_link.href = imgData
        save_link.download = imgName
        const event = document.createEvent('MouseEvents')
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
        save_link.dispatchEvent(event)
        that.optionOrderPopupFlag = false;
      })
    },
    showTransBtn() {
      if (!this.isLogin) {
        this.$router.push({ name: 'login' })
        return;
      }
      this.showTransfer = true
    },
    onTransClose() {
      this.showTransfer = false;
      this.getUserMoney()
    },
    onTransConfirm() {
      this.showTransfer = false;
      this.getUserMoney()
    },
    changeOrderActive() {
      this.logLoadArr = []
      this.logLoadDataPageNum = 1;
      this.logListFinished = false;
      if (this.orderActive == "1" && this.isLogin && !this.logListloading) {
        setTimeout(() => {
          if (this.logLoadArr.length == 0) {
            this.logListLoad()
          }
        }, 500);
      }
    },
    /* 去个人中心页面 */
    goUserCenter() {
      this.$router.push({ name: 'userCenter' })
    },
    /* 加载历史列表 */
    logListLoad() {
      if (this.isLogin) {
        let parmes = {}
        parmes.optionTradeConfigId = this.openData.tradeConfigId;
        parmes.optionTimeType = this.openData.optionTimeType;
        parmes.status = 'exec,over,refund';
        parmes.pageNum = this.logLoadDataPageNum;
        parmes.pageSize = '10';
        parmes.status = 'exec,over,refund';
        if (this.logLoadDataPageNum == 1) this.logLoadArr = [];
        this.$api.findOptionOrder(parmes).then((res) => {
          this.logListloading = false;
          this.logLoadDataPageNum++;
          for (let index = 0; index < res.data.list.length; index++) {
            const element = res.data.list[index].orderTime;
            let str = '';
            let str1 = element.split(' ')[0]
            let str2Arr = element.split(' ')[2].split(':')
            str = `${str1} ${str2Arr[0]}:${str2Arr[1]}`
            res.data.list[index].orderTime = str;
          }
          this.logLoadArr.push(...res.data.list)
          if (res.data.list.length < 10) { this.logListFinished = true; }
        })
      } else {
        this.logListFinished = true;
      }

    },
    /* 去下单 */
    async on_trading(num) {
      // 登录状态
      if (!this.isLogin) {
        this.$router.push({ name: 'login' })
        return;
      }
      // if (this.$userInfo_X) {
      // 是否可以购买
      if (this.selectTradObj.isStopBuy == 0) {

        let parmes = {};
        parmes.optionPeriodRecordId = this.openData.optionPeriodRecordId
        parmes.orderPrice = this.betNum
        parmes.directionType = this.lookShapeFlag ? "up" : 'down'

        const orderPrice = this.betNum;

        // 判断本期是否结束
        // 判断可用余额
        if (orderPrice * 1 <= this.userMoney) {
          // 判断金钱大小
          let addOrderFlag = true;

          /* 如果有最小限额 */
          if (this.openData.isMinBuy) {
            if (this.openData.minBuy > orderPrice) {
              addOrderFlag = false;
              this.$notify(this.$t('M.Quantity purchased must be greater than the minimum limit'));
              this.getUserMoney();
              return
            }
          }
          /* 如果有最大限额 */
          if (this.openData.isMaxBuy) {
            if (this.openData.maxBuy < orderPrice) {
              addOrderFlag = false;
              this.$notify(this.$t("M.Quantity purchased must be less than the maximum limit"));
              this.getUserMoney();
              return
            }
          }
          /* 下单成功 */
          if (addOrderFlag) {
            this.$api.addOrder(parmes).then((res) => {
              /* 刷新余额 */
              this.getUserMoney();
              /* 更新弹窗 */
              this.getAddOrderPopupData()
              /* 刷新历史数据表格 */
              this.changeOrderActive()
            })
          }
        } else {
          this.$notify(this.$t("M.Insufficient balance"));
        }

      } else {
        this.$notify(this.$t("M.Service upgrade, please try again later!"));
      }
    },
    /* 刷新历史数据弹窗 */
    getAddOrderPopupData() {
      if (this.isLogin) {
        this.$api.optionOrderPopup().then((res) => {
          this.orderData = res.data;
          if (Object.keys(res.data).length != 0) {
            if (res.data.backTime > 0) {
              /* 开始等待下单结束倒计时 */
              this.orderPopupTimeStart();
              this.optionOrderPopupData.state = 0;
              this.sharePopupShow = false;
              this.optionOrderPopupFlag = true;
            } else {
              /* 开始等待开奖倒计时 */
              this.startOrderLoading();
              this.optionOrderPopupData.state = 1;
              this.sharePopupShow = false;
              this.optionOrderPopupFlag = true;
            }
          } else {
            this.optionOrderPopupFlag = false;
            this.changeOrderActive()
            this.optionOrderPopupData.state = 0;
            this.removeOrderPopupTimeStart();
          }
        })
      }
    },
    /* 显示预期收益率 */
    showRation(num) {
      return cutOutPointLength(num * 100, 2);
    },
    /* 开始等待下单结束倒计时 */
    orderPopupTimeStart() {
      let total = "";
      if (localStorage.getItem("optionPopupFlag") == "1") {
        /* 倒计时总时间为这期的总时间 */
        total = this.orderData.optionTimeTypeS;
      } else {
        /* 倒计时总时间为剩余时间 */
        total = this.orderData.backTime;
      }
      this.optionOrderPopupData.beginStartTimeNum = this.orderData.backTime;
      this.optionOrderPopupData.plan = (this.optionOrderPopupData.beginStartTimeNum / total) * 100;
      localStorage.setItem("optionPopupFlag", 1);
      /* 倒计时 */
      this.optionOrderPopupData.timeBackObj = setInterval(() => {
        this.optionOrderPopupData.beginStartTimeNum--;
        this.optionOrderPopupData.plan = (this.optionOrderPopupData.beginStartTimeNum / total) * 100;
        /* 结束,获取结果 */
        if (this.optionOrderPopupData.beginStartTimeNum <= 0) {
          this.removeOrderPopupTimeStart();
          this.startOrderLoading();
        }
        if (this.optionOrderPopupData.beginStartTimeNum % 5 == 0) {
          this.getUserMoney()
        }
      }, 1000);

    },
    /* 开始等待结果 */
    startOrderLoading() {

      this.optionOrderPopupData.resultWaitObj = setInterval(() => {
        this.optionOrderPopupData.resultWaitTimeNum++;
        if (this.optionOrderPopupData.resultWaitTimeNum % 2 == 0) {
          this.$api.getPopupInfo({ optionPeriodRecordId: this.orderData.optionPeriodRecordId, }).then((res) => {
            if (Object.keys(res.data).length != 0) {
              this.optionOrderPopupData.state = 2;
              this.optionOrderPopupData.resultIncome = res.data.income;
              clearInterval(this.optionOrderPopupData.resultWaitObj);
              setTimeout(() => {
                this.optionOrderPopupFlag = false;
                this.getUserMoney()
                this.changeOrderActive()
                var qc = setInterval(function () { }, 1)
                for (let i = 0; i < qc; i++) {
                  clearInterval(i)
                }
              }, 3000);
            }
          })
        }
      }, 1000);

    },
    /* 删除下单弹窗倒计时 */
    removeOrderPopupTimeStart() {
      clearInterval(this.optionOrderPopupData.timeBackObj);
      // this.openPopopShow = false;
      this.optionOrderPopupData.state = 1;
      this.optionOrderPopupData.plan = 0;
      this.optionOrderPopupData.beginStartTimeNum = 0;
      localStorage.setItem("optionPopupFlag", 0);
    },
    /* 切换交易对 */
    synbolTabChange(trad) {
      localStorage.setItem("newOptionTrad", JSON.stringify(trad))
      let params = {
        optionTradeConfigId: trad.tradeConfigId,
        symbol: trad.symbol,
        optionTimeType: trad.optionTimeType,
        sellCoinName: trad.sellCoinName.toLowerCase(),
      }
      // this.handleIndex = i_index
      this.tradingPopupShow = false
      let that = this;
      that.getKlineDataBySocket(
        "CANCEL",
        this.selectTradName,
        "option_kline_last_price_V2.min5"
      );
      that.getKlineDataBySocket(
        "CANCEL",
        this.selectTradName,
        "option_kline.min5.step5"
      );


      this.coinName = params.sellCoinName;
      that.getKlineDataBySocket(
        "SUB",
        params.sellCoinName,
        "option_kline_last_price_V2.min5"
      );
      that.getKlineDataBySocket(
        "SUB",
        params.sellCoinName,
        "option_kline.min5.step5"
      );

      this.selectTradObj = trad;
      this.selectTradName = trad.sellCoinName;
      this.selectTradTime = trad.optionTimeType;
      this.selectTradSymbol = trad.symbol;
      this.nowCoinShowMoneyNum = cutOutPointLength(trad.shou, this.selectTradObj.priceDecimal);
      this.openData = trad.optionDataList[0]
      this.deatdell()
      // /* 获取分时的价格数据 */
      this.$api.getOptionPriceList({ symbol: this.selectTradName }).then((res) => {
        this.setWatchData(res.data);
      })

    },
    // 订阅 k线 socket
    getKlineDataBySocket(type, symbol, intver) {
      sendWSPush({
        tag: type,
        content: `market.${symbol}.${intver}`,
        id: "app",
      });
    },
    /* 看多看空 */
    clickLookShape() {
      this.lookShapeFlag = !this.lookShapeFlag;
    },
    /* 请求用户余额 */
    getUserMoney() {
      if (this.isLogin) {
        this.$api
          .getAvailableBalance()
          .then((res) => {
            this.userMoney = res.data.total;
          });
      }
    },
    /* 请求交易对列表 */
    getTradList() {
      this.$api
        .getnewOptionHanderSectleData()
        .then((data) => {
          this.tradListArr = []
          /* 处理交易对列表数据 */
          this.tidyTradingSymbolList(data.data.resultList)
          let tradObj = this.tradListArr[0][0]
          if (localStorage.getItem('newOptionTrad')) {
            let newOptionTrad = JSON.parse(localStorage.getItem('newOptionTrad'));
            for (let index = 0; index < this.tradListArr.length; index++) {
              const element = this.tradListArr[index];
              for (let g = 0; g < element.length; g++) {
                const element1 = element[g];
                if (element1.symbol == newOptionTrad.symbol && element1.optionTimeType == newOptionTrad.optionTimeType) {
                  tradObj = element1
                }
              }
            }
          }
          this.selectTrad(tradObj)
        });
    },
    /* 选择交易对 */
    selectTrad(obj) {
      this.selectTradName = obj.sellCoinName;
      this.selectTradTime = obj.optionTimeType;
      this.selectTradSymbol = obj.symbol;
      this.nowCoinShowMoneyNum = cutOutPointLength(obj.shou, this.selectTradObj.priceDecimal);
      this.selectTradObj = obj;
      this.openData = obj.optionDataList[0]

      // /* 获取分时的价格数据 */
      this.$api.getOptionPriceList({ symbol: this.selectTradName }).then((res) => {
        this.setWatchData(res.data);
      })

      /* 去订阅socket */
      createSocket(`wss://${this.$apiUrl}/v1/websocket/new/contract/option`, '472437831826935808');
      window.addEventListener("onmessageWS", this.receiveSocketMessage);
    },
    /* socket 接收消息 */
    receiveSocketMessage(evt) {
      let blob = evt.detail.data;
      let reader = new FileReader();
      reader.readAsBinaryString(blob);
      reader.onload = (res) => {
        let text = this.pako.inflate(res.target.result, { to: "string" });
        let msg = JSON.parse(text);
        // 心跳包
        if (msg.type == 2) {
          onopenWS();
        } else if (msg.type == 1) {
          if (msg.tradeType == "OPTIONDATA") {
            this.sectleData = msg.data.resultList;
            this.handleHeaderSelectData(
              msg.data.resultList
            );
          }
          if (msg.tradeType == "OPTION_KLINE_LAST_PRICE_V2") {
            // 交割线数据
            if (msg.symbol.toLowerCase() == this.selectTradName.toLowerCase()) {
              this.d_echarts = msg.data;
              this.setWatchDatas(this.d_echarts);
            }
          }
          // // K线数据
          if (msg.tradeType == "OPTION_KLINE") {
            if (msg.symbol.toLowerCase() == this.selectTradName.toLowerCase()) {
              this.optionKline = msg.data;
            }
          }
        }
      };
    },
    /* 处理头部下拉函数 */
    handleHeaderSelectData(sectleData) {
      /* 处理交易对 */
      this.tidyTradingSymbolList(sectleData)
      /* 更新价格数据 */
      let idx = this.tradListArr.findIndex((item) => {
        return item[0].symbol == this.selectTradSymbol
      })
      let idx2 = this.tradListArr[idx].findIndex((item) => {
        return item.optionTimeType == this.selectTradTime
      })
      /* 更新被选中交易对的数据 */
      this.selectTradObj = this.tradListArr[idx][idx2];
      if (this.selectTradObj.shou && this.selectTradObj.shou != 0) {
        this.nowCoinShowMoneyNum = cutOutPointLength(this.selectTradObj.shou, this.selectTradObj.priceDecimal);
      }
      this.openData = this.selectTradObj.optionDataList[0];
      const optionData = this.selectTradObj.optionDataList[0];
      this.betBtnFlag = optionData.backTime <= 10;
      this.tradDownTime = this.formateSeconds(optionData.backTime);
      if (optionData.backTime == 0) { this.Jpercent = 50; }
      /* 更新柱状图数据 */
      if (optionData.status == "open") {
        let downPrice = optionData.downPrice; //看跌的数值
        let upPrice = optionData.upPrice; //看涨的数值
        // //计算多空双方的百分比柱状图
        if ((optionData.backTime % 6) == 0) {
          if (downPrice == 0 && upPrice == 0) {
            this.Jpercent = 50;
          }
          if (downPrice == 0 && upPrice != 0) {
            this.Jpercent = 80;
          }
          if (downPrice != 0 && upPrice == 0) {
            this.Jpercent = 20;
          }
          if (upPrice != 0 && downPrice != 0) {
            if (
              downPrice / (downPrice + upPrice) > 0.8 ||
              upPrice / (downPrice + upPrice) < 0.2
            ) {
              this.Jpercent = 20;
            } else if (
              downPrice / (downPrice + upPrice) < 0.2 ||
              upPrice / (downPrice + upPrice) > 0.8
            ) {
              this.Jpercent = 80;
            } else {
              this.Jpercent = (upPrice / (downPrice + upPrice)) * 100;
            }
          }
        }
      }

    },
    /* 滑动 */
    dragLineOnChange(value) {
      this.sliderRate = value
      this.betNum = (value / 100) * this.userMoney
      if (this.betNum == 0) {
        this.betNum = ''
      }
    },
    /* 处理交易对列表 */
    tidyTradingSymbolList(data) {

      let symbolArr = []
      let arr = [];
      for (const iterator of data) {
        const idx = symbolArr.indexOf(iterator.symbol);
        iterator.optionDataList[0].showBackTime = this.formateSeconds(iterator.optionDataList[0].backTime);
        if (idx < 0) {
          symbolArr.push(iterator.symbol)
          arr[arr.length] = []
          arr[arr.length - 1].push(iterator)
        } else {
          arr[idx].push(iterator)
        }
      }
      this.tradListArr = arr
    },
    // 数据重置
    deatdell() {
      this.options.series[0].data = [];
      this.maxValue = 0;
      this.minValue = 0;
      this.listNUM = [];
      this.options.yAxis.interval = 0;
      this.numATm = 0;
    },

    /* 处理分时价格数据 */
    setWatchData(newVal) {
      var _this = this;
      let datac = newVal,
        dataList = [],
        dataListYs = [];
      let datab = datac.slice(-40);
      let num = this.selectTradObj.priceDecimal;
      for (var i = 0; i < datab.length; i++) {
        dataListYs.push(Number(datab[i].price).newToFixed(num, "min"));
        let c = {
          name: new Date(datab[i].createDate * 1),
          value: [
            new Date(datab[i].createDate * 1),
            datab[i].price,
            datab[i].symbol,
          ],
        };
        dataList.push(c);
      }
      let qaw = datab[datab.length - 1].createDate * 1;
      for (var f = 1; f < 8; f++) {
        let c = {
          name: new Date(qaw + 1000 * f),
          value: [new Date(qaw + 1000 * f), null, null],
          // value: [new Date(qaw + 1000 * f), null,null],
        };
        dataList.push(c);
      }
      _this.options.series[0].data = dataList;
      this.max_min(dataListYs[dataListYs.length - 1]);
    },
    // 动态渲染图表
    setWatchDatas(newVal) {
      let boltLINE = sessionStorage.getItem("boltLINE");
      /* 断线重连 */
      let createDate = newVal.createDate * 1;
      let num = this.selectTradObj.priceDecimal;
      let c = {
        name: new Date(createDate * 1),
        value: [
          new Date(createDate * 1),
          Number(newVal.price).newToFixed(num, "min"),
          newVal.symbol,
        ],
      };
      this.options.series[0].data.splice(this.numLine, 1, c);
      this.options.series[0].data.shift();
      let cd = {
        name: new Date(createDate + 8 * 1000),
        value: [new Date(createDate + 8 * 1000), null, null],
      };
      this.options.series[0].data.push(cd);
      this.chart.setOption({
        series: [
          {
            data: this.options.series[0].data,
          },
        ],
      });
      this.max_min(newVal.price);
      // }
    },

    // 遍历搞大小
    max_min(val) {
      //时间数组
      let dataList = this.options.series[0].data;
      //时间数字
      let numerList = [];
      for (var i = 0; i < dataList.length; i++) {
        if (dataList[i].value[1] != "null" && dataList[i].value[1] != null) {
          numerList.push(dataList[i].value[1]);
        }
      }
      let maxValue = Math.max(...numerList);
      let minValue = Math.min(...numerList);
      //获取最大值和最小值
      let interObj = this.getLeftData(minValue, maxValue);
      //获取最大值和最小值和时间间隔
      this.options.yAxis.interval = interObj.interval;
      this.options.yAxis.min = interObj.min;
      this.options.yAxis.max = interObj.max;

      //保留小数位 
      let num = this.selectTradObj.priceDecimal;

      // let vals = Number(val).newToFixed(num,'min');
      // 金额
      let vals = null;
      let valsType = this.isInteger(val * 1);
      // debugger
      if (valsType) {
        vals = val;
      } else {
        let ctnum = val.toString().split(".")[1].slice(0, num);
        vals = (val.toString().split(".")[0] + "." + ctnum) * 1;
      }
      // debugger
      var y_widths = 80;
      let citylength =
        this.options.yAxis.min.toFixed(0).toString().length + num;
      let y_valueLength = vals.toString().length;
      if (citylength > 8 || y_valueLength > 8) {
        y_widths = citylength * 10;
        this.options.grid.right = y_widths + "px";
      } else {
        this.options.grid.right = "80px";
        y_widths = 80;
      }
      this.options.yAxis.axisLabel.formatter = this.formatter

      this.chart.setOption(this.options);
      let datas = {
        //价格的最大值
        y_max: interObj.max,
        //价格的最小值
        y_min: interObj.min,
        //当前的金额
        y_value: vals,
        //金额的宽度
        y_widths: y_widths,
      };
      this.gbsj(datas);
    },
    gbsj(val) {
      let { y_max, y_min, y_value, y_widths } = val;
      this.KlineShowMoneyBox.show = true;
      this.KlineShowMoneyBox.y_width = y_widths - 30;
      this.KlineShowMoneyBox.y_value = y_value;
      this.KlineShowMoneyBox.y_top = 138 - ((y_value - y_min) / (y_max - y_min)) * 138 - 8;
      this.titleShow = true;
      // this.KlineShowMoneyBoxy_top.y_width = ((this.$refs.pageContainer.offsetWidth - y_widths - 8) / 14) * 3 + 6;
      if (this.openData.backTime < 8 && this.openData.backTime > 0) {
        this.KineJgshow = true;
        this.KineJgright = 6.5 * (8 - this.openData.backTime) - 10;
        this.KlineShowMoneyBox.time = this.openData.scene.split(' ')[1] + ":00"
      } else {
        this.KineJgshow = false;
      }

    },
    formatter(value, index) {
      let num = this.selectTradObj.priceDecimal;
      let rpq = "";

      rpq = Number(value).newToFixed(num, "min");

      var newParamsName = "";
      var paramsNameNumber = rpq.length;
      var provideNumber = 100;
      var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
      if (paramsNameNumber > provideNumber) {
        for (var p = 0; p < rowNumber; p++) {
          var tempStr = "";
          var start = p * provideNumber;
          var end = start + provideNumber;
          if (p == rowNumber - 1) {
            tempStr = rpq.substring(start, paramsNameNumber);
          } else {
            tempStr = rpq.substring(start, end) + "\n";
          }
          newParamsName += tempStr;
        }
      } else {
        newParamsName = rpq;
      }

      return newParamsName;
    }
    ,
    // 最大最小
    getLeftData(min, max) {
      // 数据差值 3位
      let diff = max - min;
      let dissf = 0.000000004 - (diff % 0.000000004);
      let interval = parseFloat(((diff + dissf) / 4).toFixed(9));
      let minNun = min - interval * 2 - dissf / 2;
      let maxNun = interval * 7 + minNun;
      return {
        max: maxNun,
        min: minNun,
        interval: interval,
      };
    },
    // 判断是否整数
    isInteger(obj) {
      return typeof obj === "number" && obj % 1 === 0;
    },
    // 秒转分钟
    formateSeconds(endTime) {
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime >= 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
          if (min < 10) {
            min = "0" + min;
          }
        }
      }
      result = `${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },
  },
  beforeDestroy() {
    var qc = setInterval(function () { }, 1)
    for (let i = 0; i < qc; i++) {
      clearInterval(i)
    }
    oncloseWS();
    localStorage.setItem("UpFlag", "0")
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.newOption {
  font-size: 12px;
  .headSelsec {
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ebebf0;
    position: relative;
    .selectItem {
      width: 85px;
      height: 30px;
      background: #00b897;
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
    }
    .userCenter {
      position: absolute;
      left: 20px;
      width: 30px;
      height: 30px;
    }
  }
}

.tradingTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 0 15px;
  font-size: 16px;
  color: #191b27;
  line-height: 23px;
  margin-top: 15px;
  .tradList {
    width: 17px;
    height: 15px;
  }
  .tradName {
    margin-left: 7px;
  }
  .tradTime {
    color: #9299a9;
    margin-left: 8px;
  }
  .tradbing {
    width: 17px;
    height: 17px;
    position: absolute;
    right: 15px;
  }
}
.tradingTitle2 {
  font-size: 18px;
  justify-content: space-between;
  .tradNum {
    font-size: 25px;
    color: #0AA869;
  }
  .tradName {
    margin-left: 0px;
  }
}
.changeKline {
  display: flex;
  font-size: 14px;
  justify-content: flex-start;
  padding: 0 15px;
  color: #00b897;
  line-height: 20px;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 5px;
  .jiantou {
    width: 10px;
    height: 5px;
    margin-left: 5px;
  }
}
.KlineBox {
  height: 168px;
}
.playArea {
  padding: 0 15px;
  .topContant {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    .selectBox {
      display: flex;
      font-size: 14px;
      .selectItem {
        width: 137px;
        height: 35px;
        font-weight: 400;
        line-height: 35px;
        text-align: center;
        background-size: 100% 100%;
      }

      .duo0 {
        background-image: url('../../assets/img/newOption/duo0.png');
        color: #9299a9;
      }
      .duo1 {
        background-image: url('../../assets/img/newOption/duo1.png');
        color: #ffffff;
      }
      .kong0 {
        background-image: url('../../assets/img/newOption/kong0.png');
        color: #9299a9;
      }
      .kong1 {
        background-image: url('../../assets/img/newOption/kong1.png');
        color: #ffffff;
      }
    }
  }
  .timeBox {
    height: 35px;
    line-height: 35px;
    color: #191b27;
    font-weight: 600;
    font-size: 13px;
    display: flex;
    align-items: center;
    img {
      width: 13px;
      height: 13px;
      margin-right: 3px;
    }
  }
  .inputBox {
    position: relative;
    margin-top: 15px;
    .input {
      height: 40px;
      border-radius: 2px;
      border: 1px solid #c7ccd8;
      line-height: 40px;
      padding: 0 10px;
    }
    .coinName {
      position: absolute;
      right: 10px;
      font-size: 13px;
      color: #9299a9;
      line-height: 40px;
      top: 0;
    }
  }
  .moneyBox {
    display: flex;
    font-size: 12px;
    line-height: 38px;
    justify-content: space-between;
    white-space: nowrap;
    .yue {
      display: flex;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
        margin-left: 10px;
      }
      span {
        color: #5c6573;
      }
    }
    .shouyi {
      color: #ED0071;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .drag {
    position: relative;
    .roundBox {
      display: flex;
      justify-content: space-between;
      .roundItem {
        width: 8px;
        height: 8px;
        background: #c7ccd8;
        border-radius: 50%;
      }
      .round_item_active {
        background: #00b897;
      }
    }
    .roundNumBox {
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      color: #5c6573;
      line-height: 15px;
      margin-top: 9px;

      .roundItem {
        width: 40px;
      }
      .roundItem1 {
        text-align: left;
      }
      .roundItem2 {
        text-align: center;
      }
      .roundItem3 {
        text-align: right;
      }
    }
    .dragLine {
      position: absolute;
      top: 3px;
    }
  }
  .copeBox {
    position: relative;
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
    margin-top: 12px;
    .copeLineBox {
      height: 17px;
      display: flex;
      .leftBox {
        width: 50%;
        height: 17px;
        background-color: #40CFA6;
        border-radius: 2px 0 0 2px;
        transition: all 1s ease;
      }
      .rightBox {
        flex: 1;
        height: 17px;
        background-color: #ED0072;
        border-radius: 0 2px 2px 0;
      }
    }
    .leftNum {
      position: absolute;
      left: 12px;
      top: 0;
    }
    .rightNum {
      position: absolute;
      right: 12px;
      top: 0;
    }
    .fenge {
      position: absolute;
      top: 0;
      left: 50%;
      width: 5px;
      height: 17px;
      transition: all 1s ease;
    }
  }
  .betBtn {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    line-height: 40px;
    color: #ffffff;
    margin-top: 30px;
  }
  .betBtn0 {
    background: #ED0071;
  }
  .betBtn1 {
    background: #0AA869;
  }
}
.dise {
  height: 8px;
  background: #f8f8fb;
  margin-top: 20px;
}
.logsTabs {
  border-bottom: 1px solid #ebebf0;
  .logItem {
    line-height: 42px;
    color: #191b27;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    .logItemChildBox-left {
      text-align: left;
    }
    .logItemChildBox-right {
      text-align: right;
    }

    .logItemChildTit {
      color: #9299a9;
    }
    .logItemChildBox {
      flex: 1;
      box-sizing: border-box;
      .smallBox1 {
        font-size: 12px;
        color: #191b27;
        line-height: 17px;
        margin-top: 6px;
      }
      .smallBox2 {
        font-size: 10px;
        line-height: 15px;
        color: #9299a9;
      }
    }
    .add {
      color: #0AA869;
    }
    .reduce1 {
      color: #ED0071;
      flex: 1.3;
    }
    .reduce0 {
      color: #40cfa6;
      flex: 1.3;
    }
  }
}
.typeTitle {
  font-size: 22px;
  line-height: 31px;
  font-weight: 600;
  color: #191b27;
  margin-top: 30px;
}
.tradingSmallBox {
  display: flex;
  padding: 0 15px;
  color: #9299a9;
  font-size: 13px;
  justify-content: space-between;
  margin-top: 15px;
  border-bottom: 1px solid #ebebf0;
  padding-bottom: 10px;
}
.tradingListBox {
  padding: 13px 15px;
  .tradingItemBox {
    .tit {
      .text {
        font-size: 16px;
        color: #00b897;
        line-height: 23px;
      }
      .bgImg {
        background: linear-gradient(to right, #00b897, rgba(0, 184, 151, 0.5));
        width: 22px;
        height: 4px;
        margin-top: 4px;
      }
    }
  }
  .tradingItem {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    .tradingName {
      display: flex;
      .tradingTime {
        color: #9299a9;
        margin-left: 7px;
        margin-bottom: 15px;
      }
    }
  }
}
.planBody {
  // width: 100%;
  // height: 261px;
  box-sizing: border-box;
  padding-top: 15px;
  .content {
    position: relative;
  }
  .content :first-child {
    display: block;
    margin: 0 auto;
  }

  .center_text {
    position: absolute;
    top: 0;
  }
  .showPlanNum {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-size: 22px;
    font-weight: 400;
  }
  .resultWait {
    width: 100%;
    position: relative;
    .resultWaitImg {
      width: 130px;
      height: 130px;
      display: block;
      margin: 0 auto;
    }
    .resultWaitText {
      color: #131520;
      font-size: 18px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
    }
  }
  .resultOver {
    position: relative;
    .resultOverImg {
      display: block;
      width: 182px;
      height: 46px;
      margin: 0 auto;
      margin-top: 10px;
    }
    .resultOverText {
      text-align: center;
      line-height: 45px;
      font-size: 30px;
      font-weight: 600;
      margin-top: 20px;
      span {
        font-size: 16px;
        color: #131520;
      }
    }
    .win {
      color: #01b184;
    }
    .loser {
      color: #fe3f51;
    }
    .over {
      position: absolute;
      width: 100%;
      font-size: 18px;
      font-weight: 600;
      line-height: 38px;
      color: #131520;
      top: -2px;
      text-align: center;
    }
  }
}
.over {
  position: absolute;
  top: 10;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  line-height: 38px;
  color: #131520;
  top: 6px;
  text-align: center;
}
.popupcontant {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}
.fenxiangBtn {
  display: flex;
  text-align: center;
  margin-top: 26px;
  justify-content: space-between;
  font-size: 18px;
  line-height: 46px;
  .close {
    width: 48%;
    border-radius: 4px;
    height: 46px;
    background-color: #fff;
  }
  .save {
    width: 48%;
    border-radius: 4px;
    height: 46px;
    background-color: #00b897;
    color: #fff;
  }
}

.optionOrderPopupTit {
  font-size: 18px;
  font-weight: 600;
  padding: 0 20px;
}
.orderDataBox {
  box-sizing: border-box;
  padding: 15px 20px;
  margin-top: 20px;
  background: rgba(188, 188, 188, 0.1);
  .dataitem {
    display: flex;
    justify-content: space-between;
    line-height: 34px;
    font-size: 14px;
    // .right {
    // font-weight: 600;
    // }
    .left {
      color: #000;
      font-weight: 450;
    }
  }
  .tips {
    color: #e54c67;
    font-weight: 450;
    font-size: 9px;
    margin-top: -5px;
    margin-bottom: 5px;
  }
}

.exchangePopup {
  .itemBox {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    .item {
      flex: 1;
      text-align: center;
    }
    .item1 {
      text-align: left;
    }
    .item3 {
      text-align: right;
      color: #191b27;
    }
    .itemBox:nth-child(2) {
      .item1 {
        color: #9299a9;
      }
      .item3 {
        color: #9299a9;
      }
    }
  }
  .itemTit {
    font-size: 14px;
    line-height: 40px;
  }
  .title {
    .item {
      font-size: 13px;
      color: #9299a9;
    }
  }
}
.logItem {
  p {
    text-align: center;
  }
  .fenxiang {
    width: 16px;
    height: 16px;
  }
  .money {
    font-size: 30px;
    line-height: 43px;
    color: #0AA869;
    margin-top: 25px;
  }
  .smalltit {
    font-size: 13px;
    color: #9299a9;
    line-height: 19px;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .logItem {
    display: flex;
    justify-content: space-between;
    color: #5c6573;
    line-height: 34px;
    font-size: 14px;
    padding: 0 15px;
    .results {
      color: #191b27;
    }
  }
}

.pl15 {
  padding-left: 15px;
}

.up {
  color: #01b184 !important;
}
.down {
  color: #fe3f51 !important;
}

::v-deep .van-slider__button {
  background-image: url('../../assets/img/newOption/yuandian.png');
  background-size: 100% 100%;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 50px;
  border-bottom: 1px solid #ebebf0;
}
::v-deep .custom-image .van-empty__image {
  width: 170px;
  height: 105px;
}
::v-deep .van-empty__description {
  color: #c7ccd8;
}
::v-deep .van-cell {
  padding-top: 0;
  padding-bottom: 0;
  height: 42px;
}
// ::v-deep .van-cell__value {
//   display: flex;
//   justify-content: space-between;
// }
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
::v-deep .van-button--disabled {
  background-color: #c7ccd8 !important;
  opacity: 1;
}
::v-deep .van-hairline--bottom::after {
  border: 0 solid #c2c4c8;
  border-bottom-width: 1px;
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}
::v-deep .van-cell__right-icon {
  line-height: 43px;
  color: #9299a9;
  margin-left: 0;
  font-size: 12px;
}
.Kline {
  position: relative;
  .klinetitle {
    height: 32px;
    position: absolute;
    right: 7px;
    bottom: 125px;
    display: flex;
    align-items: center;
    .dian {
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: radial-gradient(
        circle,
        #00b897 0%,
        rgba(23, 208, 178, 0.99) 50%,
        rgba(23, 208, 178, 0) 100%
      );
      animation: mymove 1s infinite;
    }
    .xian {
      border-top: 1px dashed #ccc;
      flex: 1;
    }
    .title {
      min-width: 60px;
      background: #00b897;
      line-height: 22px;
      color: #fff;
      text-align: center;
      position: relative;
      .jiantou {
        position: absolute;
        display: block;
        border: 11px solid transparent;
        border-right-color: #00b897;
        top: 0;
        left: -22px;
      }
      /*只兼容火狐*/
      @-moz-document url-prefix() {
        .fuzhu {
          display: block;
          width: 1px;
          height: 22px;
          background: #00b897;
          position: absolute;
          left: -1px;
        }
      }

      div {
        width: 100%;
        overflow: hidden;
        color: #131626;
      }
    }
    .titleUP {
      background: #01b184;
    }
    .titleDOWN {
      background: #fe3f51;
    }
  }
}

.klineChangeDiv {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  .top {
    width: 100%;
    height: 230px;
  }
  .content {
    width: 100%;
    padding: 0 15px;
    background-color: #ffffff;
    display: flex;
    font-size: 12px;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 10px;
    .item {
      width: 61px;
      height: 30px;
      background: rgba(146, 153, 169, 0.1);
      border-radius: 4px;
      text-align: center;
      line-height: 30px;
      color: #9299a9;
      margin-top: 10px;
    }
    .active {
      border: 1px solid #00b897;
      background-color: #ffffff;
    }
  }
  .content1 {
    justify-content: flex-start;
    .item {
      margin-right: 10px;
    }
  }
  .bottom {
    // position: absolute;
    // right: 0;
    // top: 0;
    // left: 0;
    // bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    flex-grow: 1;
  }
}
#jg {
  position: absolute;
  width: 80px;
  height: 120px;
  top: 8px;
  right: 0px;
  // background: url(../../../assets/options/jg.png);
  background-size: 100% 100%;
  font-size: 12px;
  .jg_font {
    position: absolute;
    top: -8px;
    left: -210px;
    color: #00b897;
    width: 200px;
    text-align: right;
  }

  .jg_bg {
    width: 83px;
    height: 134px;
    box-sizing: border-box;
    position: relative;
    .yuan {
      width: 15px;
      height: 15px;
      border: 3px solid #00b897;
      box-sizing: border-box;
      position: absolute;
      border-radius: 50%;
      &:nth-child(1) {
        top: -6px;
        left: -9px;
      }
      &:nth-child(2) {
        bottom: -6px;
        left: -9px;
      }
    }
    img {
      height: 100%;
    }
  }
  .jg_foter {
    height: 22px;
    padding: 0 4px;
    text-align: center;
    line-height: 22px;
    background: #00b897;
    position: absolute;
    bottom: -43px;
    color: #fff;
    left: -43%;
  }
}

::v-deep {
  .van-circle__text {
    font-size: 30px;
    font-family: DIN Condensed-Bold, DIN Condensed;
    font-weight: bold;
    color: #000000;
  }
}
.blur {
  filter: blur(2px);
}
</style>