export const M = {
    "Slideright": "Arraste o controle para a direita",
    "Pleaseverification": "Complete a verificação de segurança",
    "Pleaseorder": "Clique em ordem sequencial",
    "Verificationagain": "Verificação falhou, tente novamente",
    "10 orders": "10 ordens",
    "24h High": "Alta 24H",
    "24h Low": "Baixa 24H",
    "24h Trading Vol": "Volume 24H",
    "24h Vol": "Volume 24H",
    "ADL mechanism": "Mecanismo ADL",
    "AMT": "Quantidade",
    "Activity _home": "Atividade",
    "Actual transaction number": "Número real de transações",
    "Actual transaction price": "Preço real da transação",
    "Add margin": "Adicionar margem",
    "Adjust leverage": "Ajustar alavancagem",
    "Adjust success": "Ajuste bem-sucedido",
    "Advanced limit order offers 3": "Ordens limitadas avançadas oferecem três mecanismos de ativação: 'Apenas Maker', 'Preencher ou Cancelar', 'Preencher e Cancelar o restante'; o padrão é 'Sempre Válido'.",
    "Alipay": "Alipay",
    "All Closed": "Todos fechados",
    "All Filled": "Todo preenchido",
    "AllDetails": "Ver detalhes de liquidação",
    "AllType": "Todos os tipos",
    "Amount can flat": "Quantidade disponível",
    "Appeal_reason": "Motivo da apelação",
    "Applet service": "Serviço de applet",
    "Are you sure to cancel the order？": "Tem certeza de que deseja cancelar a ordem?",
    "Asked_to_record": "Grave o vídeo conforme solicitado abaixo",
    "Asset conversion": "Conversão de ativos",
    "Asset_types": "Tipos de ativos",
    "At the current price": "Order pelo preço de mercado atual",
    "Avail.": "Disponível",
    "Avail. margin": "Margem disponível",
    "Available": "Disponível",
    "Avg close price": "Preço médio de fechamento",
    "Avg open price": "Preço médio de abertura",
    "Avg.price": "Preço médio",
    "BBO": "Preço de oferta",
    "Bankcard": "Cartão bancário",
    "Bankruptcy price": "Preço de falência",
    "Batch Cancel": "Cancelar em lote",
    "Branch_address": "Endereço da agência",
    "Buy crypto_home": "Comprar cripto",
    "Calculations are not based on": "Resultados apenas para referência, este preço de liquidação representa dados de uma única direção de abertura.",
    "Can't calculate": "Não é possível calcular",
    "Cancel": "Cancelar",
    "Cancel all": "Cancelar tudo",
    "Cancel order": "Cancelar ordem",
    "Cancel_the_login": "Cancelar login",
    "Capital cost": "Custo de capital",
    "Capital cost - Expenditure": "Custo de capital - Despesa",
    "Capital fee": "Taxa de capital",
    "Capital fee - all": "Taxa de capital - Tudo",
    "Capital fee - income": "Taxa de capital - Receita",
    "Capital success": "Cancelado com sucesso",
    "Charge_money": "Carregar fundos",
    "Chinese_Yuan": "Yuan",
    "Close all of": "Você confirma que",
    "Close long": "Fechar posição longa",
    "Close out time": "Tempo de fechamento total",
    "Close price": "Preço de fechamento",
    "Close short": "Fechar posição curta",
    "Closed": "Quantidade fechada",
    "Closing PnL gains": "Ganhos de fechamento",
    "Closing PnL ratio": "Taxa de lucro de fechamento",
    "Closing profit": "Lucro de fechamento",
    "coin_failure_0002": "Dados da moeda não disponíveis",
    "Common functions": "Funções comuns",
    "Communtiy_home": "Comunidade",
    "Conceal canceled orders": "Ocultar ordens canceladas",
    "Confirm_the_login": "Confirmar login",
    "Confirm_to_mention_money": "Confirmar retirada",
    "Cont": "Cont",
    "Copy link": "Copiar link",
    "Cross": "Total",
    "Currency_rate_description": "Descrição da taxa",
    "Current": "Atual",
    "Delete_option": "Excluir",
    "Determine_to_cancel": "Confirmar cancelamento?",
    "Don't show me again": "Não me avise novamente",
    "Draw_inconsistency": "Senhas não coincidem",
    "Draw_the_unlock": "Deslize para definir nova senha",
    "Enter email address": "Insira seu e-mail",
    "Enter expected PnL": "Insira lucro esperado",
    "Enter expected PnL Ratio": "Insira taxa de lucro esperada",
    "Enter leverage from 1 up to 125": "Insira alavancagem de 1 a 125",
    "Enter phone number": "Insira seu número de telefone",
    "Enter the verification code": "Insira o código de verificação",
    "Equity": "Equidade de moeda",
    "Est liquidation price": "Preço estimado de liquidação",
    "Face value": "Valor de contrato",
    "Filed Value": "Valor realizado",
    "Fill or Kill": "Preencher ou Cancelar",
    "Filled amt": "Quantidade preenchida",
    "Filled time": "Por hora de preenchimento",
    "Flash_strong": "Flash forte",
    "Funding rate": "Taxa de financiamento",
    "Funding rate - 1": "Taxa de financiamento",
    "Funding rate comparison": "Comparação de taxa de financiamento",
    "Funding rate time": "Tempo da taxa de financiamento",
    "Fuzzy_information": "Informação ambígua",
    "Gesture_error": "Erro na senha",
    "Google_code": "Código Google",
    "Help_home": "Ajuda",
    "Hide_no_asset_currency": "Ocultar moedas sem ativos",
    "Highest PnL ratio": "Maior taxa de lucro",
    "Historical record": "Registro histórico",
    "I agree to": "Concordo",
    "I have read and agree to this agreement": "Li e concordo com este acordo",
    "If_the_buyer_is_at": "Se o comprador estiver em",
    "Immediately or Cancel": "Immediate ou Cancelar",
    "In force": "Em vigor",
    "Index": "Índice à vista",
    "Index Price": "Preço do índice",
    "Index_contrct": "Índice à vista",
    "Info": "Resumo do contrato",
    "Initial margin": "Margem inicial",
    "Instrument": "Produto de negociação atual",
    "Insurance fund": "Saldo do fundo de risco",
    "Interest bearing record": "Registro de juros",
    "Invalid_qr_code": "Código QR inválido",
    "Invite code(Optional)": "Código de convite (opcional)",
    "InviteCodeMuster": "Código de convite (obrigatório)",
    "Invite_home": "Convite",
    "Isolated": "Isolado",
    "Lack_of_edge": "Falta de borda",
    "Last Price": "Último preço",
    "Latest Filled price": "Último preço preenchido",
    "Latest Filled price01": "Último preço",
    "Latest price": "Último preço",
    "Leverage": "Alavancagem",
    "Leverage after increase": "Alavancagem após aumento:",
    "Leverage11": "Alavancagem atual",
    "Leverage_BB": "Ajustar alavancagem",
    "Limit order is an order": "Ordem limitada permite que o usuário defina quantidade e preço aceitável, executando ao melhor preço dentro do limite quando o mercado corresponder.",
    "Limit price": "Preço limite",
    "Limit/Market": "Limite/Mercado",
    "LimitAndMarket": "Limite/Mercado",
    "Liquidation price": "Preço de liquidação",
    "Liquidation price after increase": "Preço de liquidação após aumento:",
    "Long": "Posição longa",
    "Lowest PnL ratio": "Menor taxa de lucro",
    "MAIL_COUNTRY_NOT_EXITS": "País atual não existe! Adicione e envie novamente.",
    "MAIL_NOT_MAIL_PROVIDER": "Nenhum provedor de e-mail disponível, adicione e envie novamente!",
    "MAIL_TEMPLATE_NOT_FOUND": "Modelo de e-mail não encontrado! Adicione e envie novamente.",
    "MAIL_TO_ADDRESS_NOT_FOUND": "Endereço de e-mail não encontrado, verifique e envie novamente.",
    "MJRefreshAutoFooterIdleText": "Clique ou puxe para carregar mais",
    "MJRefreshAutoFooterNoMoreDataText": "Todos os dados carregados",
    "MJRefreshAutoFooterRefreshingText": "Carregando mais dados...",
    "MJRefreshBackFooterIdleText": "Puxe para carregar mais",
    "MJRefreshBackFooterNoMoreDataText": "Todos os dados carregados",
    "MJRefreshBackFooterPullingText": "Solte para carregar mais",
    "MJRefreshBackFooterRefreshingText": "Carregando mais dados...",
    "MJRefreshHeaderDateTodayText": "Hoje",
    "MJRefreshHeaderIdleText": "Puxe para atualizar",
    "MJRefreshHeaderLastTimeText": "Última atualização:",
    "MJRefreshHeaderNoneLastDateText": "Sem registros",
    "MJRefreshHeaderPullingText": "Solte para atualizar",
    "MJRefreshHeaderRefreshingText": "Atualizando dados...",
    "MKT Close ALL": "Fechar tudo a preço de mercado",
    "Margin add": "Adicionar margem",
    "Margin mode": "Modo de todas as posições",
    "Margin ratio": "Relação de margem",
    "Margin reduce": "Reduzir margem",
    "Margin transfer": "Transferência de margem",
    "Margin-1": "Saldo da margem",
    "Margin-2": "Ajustar margem",
    "Margined": "Contrato",
    "Mark price": "Preço de referência",
    "Market": "Preço de mercado",
    "Market Order Dig": "Ordem de mercado permite que o usuário compre ao melhor preço atual, visando uma negociação rápida.",
    "Market Overview": "Visão geral do mercado",
    "Market_transaction": "Transação a preço de mercado",
    "Max": "Máximo cancelável",
    "Max Open int": "Máxima posição aberta",
    "Max buy": "Máximo de compra",
    "Max long": "Máxima posição longa",
    "Max position size at current leverage": "Máximo de abertura com alavancagem atual:",
    "Max sell": "Máximo de venda",
    "Max short": "Máxima posição curta",
    "Max position size at current leverage": "Máximo de abertura com alavancagem atual",
    "Mention_money": "Retirada",
    "Mention_money_address": "Insira um endereço correto",
    "Mention_money_tips_ios": "Dicas\n1. Insira o endereço de retirada, precisa validar senha, SMS, e-mail ou código Google.\n2. Endereço adicionado será seu endereço autenticado, não será necessário validar na próxima retirada.",
    "Messages_notification": "*Confirme se seu cartão bancário tem serviço de notificação por SMS ativo.",
    "Mini Programs": "Mini programas",
    "Minimum_handling_charge": "Quantidade de retirada deve ser maior que a taxa",
    "Minimum_withdrawal": "Retirada mínima por transação",
    "MoreNot9": "Módulos selecionados não podem exceder 14",
    "Most increase": "Máximo de aumento",
    "Most reduce": "Máximo de redução",
    "Must be integer multiple of": "Deve ser múltiplo inteiro de",
    "Not Enabled": "Não habilitado",
    "Not_on": "Não disponível no momento",
    "Not_to_mention_money": "Para segurança, não poderá retirar ou realizar operações sensíveis até 1 hora após a redefinição da senha.",
    "OTC account": "Conta em moeda fiduciária",
    "OTC assets": "Carteira OTC",
    "OTC交易须知": "Instruções para negociação OTC",
    "On Hold": "Congelado",
    "One-way": "Unidirecional",
    "One/Two-way": "Unidirecional/Bidirecional",
    "Open Time": "Hora de abertura",
    "Open amount": "Quantidade de abertura",
    "Open int.": "Volume em aberto",
    "Open interest": "Interesse em aberto",
    "Open long": "Abrir posição longa",
    "Open orders": "Ordens abertas",
    "Open price": "Preço de abertura",
    "Open short": "Abrir posição curta",
    "Order Details": "Detalhes da ordem",
    "Order Sorting": "Classificação de ordens",
    "Order Value": "Valor da ordem",
    "Order amt": "Total da ordem",
    "Order history": "Histórico de ordens",
    "Order price": "Preço da ordem",
    "Order trigger direction": "Direção do gatilho da ordem",
    "Order type": "Tipo de ordem",
    "PL": "Lucro",
    "PL Ratio": "Taxa de lucro",
    "Pair01": "Moeda do contrato",
    "Parse_failure": "Erro ao salvar",
    "Partially Filled": "Preenchido parcialmente",
    "Partially closed": "Fechado parcialmente",
    "Password": "Senha",
    "Payment_type": "Tipo de pagamento",
    "Perpetual": "Contrato perpétuo",
    "Perpetual funds daybook": "Fluxo de fundos perpétuo",
    "Perpetual01": "Perpétuo",
    "Pingclose": "Fechar posição",
    "Please input a password": "Por favor, insira a senha",
    "Position": "Posição",
    "Position balance": "Saldo da posição",
    "Position history": "Histórico de posições",
    "QR_code": "Código QR",
    "Quantity cannot exceed": "Quantidade não pode exceder 125",
    "Quantity not less than": "Quantidade não pode ser menor que 1",
    "Recent close": "Fechamento recente",
    "Recording_requirements": "Requisitos de gravação",
    "Required position margin at current leverage": "Margem requerida para a posição atual:",
    "Reset_transaction_password": "Redefinir senha de transação",
    "Revoked": "Revogado",
    "SL": "Stop Loss",
    "SL order": "Ordem de Stop Loss",
    "SL order price": "Preço da ordem de Stop Loss",
    "SL order price can't be empty": "Preço da ordem de Stop Loss não pode estar vazio",
    "SL trigger price": "Preço de gatilho de Stop Loss",
    "SL trigger price can't be empty": "Preço de gatilho de Stop Loss não pode estar vazio",
    "SMS_30_OUT_RESTRICTED": "Verificação enviada com frequência, só pode enviar uma a cada 30 segundos.",
    "SMS_BAD_ARGUMENT_FORMAT": "Formato de telefone incorreto.",
    "SMS_COUNTRY_NOT_EXITS": "Este país não existe, verifique e tente novamente!",
    "SMS_DAY_LIMIT_PER_MOBILE": "Limite de envios para o mesmo número em 24 horas excedido",
    "SMS_DUP_IN_SHORT_TIME": "Conteúdo duplicado enviado para o mesmo número em menos de 30 segundos",
    "SMS_FAILURE": "Falha no envio",
    "SMS_HOUR_LIMIT_PER_MOBILE": "Limite de envios para o mesmo número em 1 hora excedido",
    "SMS_MARKET_FORBIDDEN": "Devido a restrições, mensagens promocionais não podem ser enviadas no momento",
    "SMS_NOT_SMS_PROVIDER": "Nenhum provedor de SMS disponível, adicione e tente novamente!",
    "SMS_NOT_SMS_PROVIDER_TEMPLATE": "Modelo para este provedor de SMS não encontrado, adicione e tente novamente!",
    "SMS_PHONE_OVER_COUNT": "Número de telefone excedeu o limite de envio, tente novamente em 24 horas!",
    "SMS_PHONE_PROHIBIT_SEND": "Este número proíbe o envio de SMS, tente novamente em 24 horas!",
    "SMS_SUCCESS": "Envio bem-sucedido",
    "SMS_TOO_MANY_TIME_IN_5": "Conteúdo duplicado enviado para o mesmo número mais de 3 vezes em 5 minutos",
    "SMS_UNKNOWN_EXCEPTION": "Exceção desconhecida no sistema",
    "SMS_code": "Código de verificação SMS",
    "Save picture": "Salvar imagem",
    "Scanning_to_obtain": "Escaneando para obter endereço de depósito",
    "See auto deleverage liquidation for details": "Veja os detalhes da liquidação automática",
    "Set_transaction_password": "Definir senha de transação",
    "Settlement1": "Próxima liquidação de custos",
    "Settlrment coin": "Moeda de liquidação",
    "Share time": "Hora de compartilhamento",
    "Shielding success": "Bloqueio bem-sucedido",
    "Short": "Posição curta",
    "Sign Up": "Registrar",
    "Sign in": "Entrar",
    "Simplified_Chinese": "Chinês simplificado",
    "StatusOrder": "Status da ordem",
    "Stop loss": "Definir Stop Loss",
    "Stop profit stop loss": "Defina pelo menos um Stop Profit ou Stop Loss para fazer uma ordem",
    "Sustainable account": "Conta perpétua",
    "Sustainable assets": "Ativos perpétuos",
    "Switch to PnL": "Alternar para lucro",
    "Switch to PnL ratio": "Alternar para taxa de lucro",
    "TP": "Take Profit",
    "TP order": "Ordem de Take Profit",
    "TP order price": "Preço da ordem de Take Profit",
    "TP order price can't be empty": "Preço da ordem de Take Profit não pode estar vazio",
    "TP trigger price": "Preço de gatilho de Take Profit",
    "TP trigger price can't be empty": "Preço de gatilho de Take Profit não pode estar vazio",
    "TP | SL": "Take Profit / Stop Loss",
    "TP | SL order": "Ordem de Take Profit / Stop Loss",
    "Take profit": "Definir Take Profit",
    "Telegraphic_transfer_address": "Endereço de transferência eletrônica",
    "The Calculator": "Calculadora",
    "The maximum flatable quantity is": "A quantidade máxima que pode ser liquidadas é",
    "The maximum leverage is": "A alavancagem máxima é",
    "The minimum flatable quantity is": "A quantidade mínima que pode ser liquidadas é",
    "The minimum leverage is": "A alavancagem mínima é",
    "The poster has been saved to the album": "O pôster foi salvo no álbum",
    "The_correct_sample": "Exemplo correto",
    "The_input_signal": "Por favor, deslize para inserir a senha",
    "The_new_address": "Novo endereço",
    "They_are_the_number_of": "Quantidade de ordens",
    "Tick size": "Tamanho mínimo da variação",
    "To_update": "Atualizar",
    "Toggle_front_camera": "Por favor, mude para a câmera frontal e mantenha a cabeça centralizada",
    "Total": "Quantidade total em posições",
    "Total coin": "Número total de moedas",
    "Total cont": "Número total de contratos",
    "Total value": "Valor total da posição",
    "Total_asset_valuation": "Avaliação total dos ativos",
    "Trade type": "Tipo de operação",
    "Trade_sector": "Por favor, selecione",
    "Transfer": "Transferir",
    "Transfer_to_remind": "Por favor, adicione uma nota ao transferir (caso contrário, a responsabilidade é sua)",
    "Trigger mark price": "Preço de marca para ativação",
    "Trigger price": "Preço de ativação",
    "Two-way": "Bidirecional",
    "UPL": "Lucro ou perda não realizada",
    "US_dollar": "Dólar americano",
    "Unblocked": "Desbloqueado",
    "Uneasy lies the head that wears a crown": "A coroa pesa sobre quem a usa",
    "Unfilled": "Não preenchido",
    "Unfilled11": "Aguardando preenchimento",
    "Upload_collection_code": "Por favor, faça o upload do código de recebimento",
    "Usdt-Margined": "Contrato em USDT",
    "Users who register": "Usuários que se registram pela primeira vez devem definir a senha de login",
    "Verification Code": "Código de verificação",
    "Videoing_requirement": "Requisitos de gravação",
    "View": "Ver",
    "View more": "Detalhes da posição",
    "Waiting effect": "Aguardando efeito",
    "Waiting_for_the_payment": "Aguardando pagamento",
    "Wechat": "WeChat",
    "WestUnion": "Western Union",
    "Without_her": "Sem entrar na imagem",
    "abandoned": "Abandonado",
    "abnormal_assets": "Ativos da conta anormais, operação temporariamente não permitida!",
    "about_us": "Sobre nós",
    "account": "Conta",
    "account_empty": "Conta inválida",
    "account_failure_0001": "Falha ao adicionar ID de transação ao REDIS!",
    "account_failure_0002": "Saldo insuficiente, não é possível processar!",
    "account_failure_0003": "Falha ao registrar aumento de congelamento do usuário!",
    "account_failure_0004": "Falha ao aumentar congelamento do usuário!",
    "account_failure_0005": "Falha ao registrar dedução de saldo do comerciante!",
    "account_failure_0006": "Falha na dedução do saldo do comerciante!",
    "account_failure_0007": "Falha ao registrar aumento de saldo do usuário!",
    "account_failure_0008": "Falha ao aumentar saldo do usuário!",
    "account_failure_0009": "Falha ao adicionar nova moeda ao comerciante, falha ao adicionar nova moeda ao usuário!",
    "account_failure_0010": "Falha ao adicionar nova moeda ao comerciante!",
    "account_failure_00100": "ID do comerciante não pode estar vazio!",
    "account_failure_00101": "ID do usuário não pode estar vazio",
    "account_failure_00102": "ID da moeda não pode estar vazio",
    "account_failure_00103": "Quantidade não pode estar vazia",
    "account_failure_00104": "Quantidade não pode ser menor que o volume mínimo de negociação",
    "account_failure_00105": "Senha de negociação não pode estar vazia",
    "account_failure_00106": "Senha de negociação incorreta",
    "account_failure_00107": "Preço não pode estar vazio",
    "account_failure_00108": "Preço unitário não pode ser menor que o preço mínimo",
    "account_failure_00109": "Usuário não existe!",
    "account_failure_0011": "O saldo da carteira do comerciante já contém esta moeda!",
    "account_failure_00110": "Falha ao iniciar PUSH de ativos!",
    "account_failure_00111": "PUSHID não pode estar vazio",
    "account_failure_00112": "Falha no pagamento de ativos PUSH!",
    "account_failure_00113": "Falha ao cancelar ativos PUSH!",
    "account_failure_00114": "Não foi possível obter o endereço de depósito",
    "account_failure_00115": "Endereço de retirada não pode estar vazio!",
    "account_failure_00116": "Falha ao adicionar endereço de retirada",
    "account_failure_00117": "ID não pode estar vazio",
    "account_failure_00118": "Falha ao excluir endereço de retirada",
    "account_failure_00119": "Erro de rede, por favor tente novamente mais tarde!",
    "account_failure_0012": "Congelamento insuficiente, não é possível processar!",
    "account_failure_00120": "Este endereço de retirada já existe",
    "account_failure_00121": "Não há moedas PUSH disponíveis",
    "account_failure_00122": "Código de verificação do telefone está vazio!",
    "account_failure_00123": "Código de verificação do e-mail está vazio!",
    "account_failure_00124": "Código de verificação do Google está vazio!",
    "account_failure_00125": "Código de verificação incorreto!",
    "account_failure_00126": "Auto-PUSH não é permitido!",
    "account_failure_00127": "Endereço de retirada inválido!",
    "account_failure_00129": "Modificações de senha não podem ser feitas em menos de 24 horas",
    "account_failure_0013": "Falha ao registrar dedução de congelamento do comerciante!",
    "account_failure_00130": "Ativos desequilibrados, operação não permitida",
    "account_failure_00131": "Ativos da conta anormais, status de negociação desabilitado",
    "account_failure_00132": "Etiqueta não corresponde",
    "account_failure_00133": "Tipo de moeda não existe ou foi desativado",
    "account_failure_0014": "Falha ao deduzir congelamento do comerciante!",
    "account_failure_0015": "Falha ao registrar aumento de juros disponíveis do usuário!",
    "account_failure_0016": "Falha ao aumentar juros disponíveis do usuário!",
    "account_failure_0017": "Falha ao registrar dedução de juros disponíveis do comerciante!",
    "account_failure_0018": "Falha na dedução de juros disponíveis do comerciante!",
    "account_failure_0019": "Falha ao transferir saldo disponível para congelamento!",
    "account_failure_0020": "Falha ao transferir saldo disponível para congelamento, falha no registro!",
    "account_failure_0021": "Falha ao registrar transferência de congelamento para saldo disponível do usuário!",
    "account_failure_0022": "Falha ao transferir congelamento para saldo disponível do usuário!",
    "account_failure_0023": "Falha ao registrar aumento de saldo do comerciante!",
    "account_failure_0024": "Falha ao aumentar saldo do comerciante!",
    "account_failure_0025": "Falha ao registrar dedução de congelamento do usuário!",
    "account_failure_0026": "Falha na dedução de congelamento do usuário!",
    "account_failure_0027": "Falha ao registrar dedução de saldo do usuário!",
    "account_failure_0028": "Falha na dedução do saldo do usuário!",
    "account_failure_0029": "Falha ao reverter operação, falha ao registrar recuperação de congelamento do usuário!",
    "account_failure_0030": "Falha ao reverter operação, falha na recuperação de congelamento do usuário!",
    "account_failure_0031": "Ativos insuficientes, não é possível reverter!",
    "account_failure_0032": "Falha ao reverter operação, falha ao registrar recuperação de congelamento do comerciante!",
    "account_failure_0033": "Falha ao reverter operação, falha na recuperação de ativos do comerciante",
    "account_failure_0034": "Falha ao registrar aumento de congelamento do comerciante!",
    "account_failure_0035": "Falha ao aumentar congelamento do comerciante!",
    "account_failure_0036": "Falha ao registrar congelamento de saldo do comerciante!",
    "account_failure_0037": "Falha ao congelar saldo do comerciante!",
    "account_failure_0038": "Falha ao registrar transferência de congelamento do comerciante!",
    "account_failure_0039": "Falha ao transferir congelamento para saldo disponível do comerciante!",
    "account_failure_0040": "Falha ao registrar aumento de saldo da plataforma!",
    "account_failure_0041": "Falha ao aumentar saldo da plataforma!",
    "account_failure_0042": "Esta transação apresenta anomalia!",
    "account_failure_0043": "Esta transação falhou!",
    "account_failure_0044": "Esta transação foi bem-sucedida!",
    "account_failure_0045": "Esta transação está em execução!",
    "account_failure_0046": "Processamento bem-sucedido!",
    "account_failure_0047": "Falha ao reverter operação, falha na recuperação de ativos da plataforma",
    "account_failure_0048": "Falha ao reverter operação, falha ao registrar recuperação de ativos da plataforma",
    "account_failure_0049": "Esta moeda já existe na plataforma!",
    "account_failure_0050": "Falha ao adicionar nova moeda à plataforma!",
    "account_failure_0051": "Esta moeda já existe na plataforma!",
    "account_failure_0053": "Tipo de operação incorreto!",
    "account_failure_0054": "Falha ao aumentar saldo do comerciante!",
    "account_failure_0055": "Falha ao registrar aumento de saldo do comerciante!",
    "account_failure_0056": "Falha na transferência de ganhos entre usuário e comerciante na plataforma!",
    "account_failure_0057": "Falha ao registrar transferência de ganhos entre usuário e comerciante na plataforma!",
    "account_failure_0058": "Falha ao transferir saldo disponível para investimento!",
    "account_failure_0059": "Falha ao registrar transferência de saldo disponível para investimento!",
    "account_failure_0060": "Investimento insuficiente, não é possível executar!",
    "account_failure_0061": "Falha ao transferir saldo de investimento para disponível!",
    "account_failure_0062": "Falha ao registrar transferência de saldo de investimento para disponível!",
    "account_failure_0063": "Falha ao reverter operação, não há dados disponíveis para operação!",
    "account_failure_0064": "Esta moeda não está disponível no momento",
    "account_failure_0065": "Sem permissão para visualizar esta moeda, entre em contato com o suporte oficial",
    "account_failure_0071": "Anomalia na contabilização de saldo do usuário!",
    "account_failure_0072": "Registro de depósito do usuário não existe ou foi alterado",
    "activitie_end": "Atividade encerrada",
    "activity_invalid": "Já expirou",
    "actual_amount": "Quantidade total recebida",
    "actual_exchange_amount": "Quantidade real trocada",
    "actual_to_account": "Valor efetivamente creditado",
    "addSuccess": "Adicionado com sucesso",
    "add_channel_function": "Adicionar função de canal de verificação",
    "add_charge_and_transfer_function": "Adicionar função de entrada para depósito e transferência",
    "added_alert_setting_function": "Adicionar função de configuração de alerta",
    "added_day_mode_toggle": "Adicionar função de alternância para modo diurno",
    "address_tag": "Etiqueta",
    "advanced_limit_order": "Ordem de limite avançada",
    "advertising": "Publicidade",
    "advertising_management": "Gerenciamento de publicidade",
    "affirm": "Confirmar",
    "affirm_pass": "Confirmar senha",
    "again_digits6": "Por favor, insira a nova senha novamente",
    "again_password": "Por favor, insira a senha de login novamente",
    "airdrop_coin": "Moeda de airdrop",
    "airdrop_date": "Data do airdrop",
    "airdrop_number": "Quantidade do airdrop",
    "alipay_account": "Conta do Alipay",
    "alipay_upload": "*Método para upload do código QR do Alipay: Abra a página inicial do Alipay > Receber > Salvar imagem, faça o upload do código de recebimento que está na galeria do celular.",
    "all": "Tudo",
    "all_buy": "Comprar tudo",
    "all_country": "Todos os países",
    "all_order": "Todos os pedidos",
    "all_sell": "Vender tudo",
    "already_use": "Já ativado",
    "amount_of_payout": "Valor a ser pago",
    "announcement_all": "Todos os anúncios",
    "announcement_center": "Centro de anúncios",
    "announcement_official": "Anúncio oficial",
    "annualized_rate": "Taxa anualizada",
    "appeal_cancel": "Apelação decidida, pedido cancelado",
    "appeal_complete": "Apelação decidida, pedido concluído",
    "appeal_dispose_time": "Tempo de processamento da apelação",
    "appeal_of_time": "Tempo de submissão da apelação",
    "apply_locked": "Solicitar bloqueio de saldo",
    "asset_allocatio": "Distribuição de ativos",
    "assets_asset_valuation": "Avaliação de ativos",
    "assets_can_transfer": "Pode ser transferido",
    "assets_cash_flow": "Fluxo de caixa",
    "assets_coin_funds_flow": "Fluxo de fundos de cripto",
    "assets_currency_assets": "Ativos em moeda",
    "assets_deposit_income_tip": "Este valor de rendimento é calculado com base na taxa de câmbio em tempo real, o rendimento específico será o valor liberado.",
    "assets_fiat_assets": "Ativos OTC",
    "assets_fiat_money_flow": "Fluxo de caixa OTC",
    "assets_flowing_details": "Detalhes do fluxo",
    "assets_prompt_001": "[Por favor, faça backup da chave para evitar perda]",
    "assets_reduced_asset": "Ativos convertidos",
    "assets_row_mining": "Mineração por ordem",
    "assets_swipe_direction": "Direção da transferência",
    "assets_transfer": "Transferir",
    "assets_transfer_coin": "Transferir moeda",
    "assets_transfer_count": "Quantidade a transferir",
    "assets_transfer_currency": "Transferência de fundos",
    "assets_transfer_dialog_bb": "Conta de cripto",
    "assets_transfer_dialog_fiat": "Conta em moeda fiduciária",
    "assets_transfer_dialog_from": "De",
    "assets_transfer_dialog_transfer_to": "Transferir para",
    "assets_transfer_dialog_wallet": "Conta da carteira",
    "assets_transfer_record": "Registro de transferência",
    "assets_transfer_to": "Para",
    "assets_wallet": "Carteira",
    "assets_wallet_assets": "Ativos da carteira",
    "assets_wallet_assets_transfer_tip": "*Só é possível negociar transferindo ativos da carteira para a conta correspondente. Transferências entre contas não têm taxas.",
    "assets_wallet_funds_flow": "Fluxo de fundos da carteira",
    "at_least_one": "Ative pelo menos um tipo de verificação de senha de transação",
    "attention_blacklist": "Seguir / Bloquear",
    "attention_to_see": "Se deseja me seguir, clique para ver!",
    "audit_result": "Resultado da auditoria",
    "authenticated": "Autenticado",
    "authentication": "Autenticação",
    "available_balance": "Saldo disponível",
    "average_purchase_price": "Preço médio de compra",
    "avoid_close_set": "Configuração sem senha",
    "back": "Voltar",
    "balance": "Saldo",
    "bank_card": "Cartão bancário",
    "bank_name": "Nome do banco",
    "bank_num": "Número do cartão bancário",
    "barcode_in_the_box_scan": "Posicione o código QR/código de barras na caixa para escanear automaticamente",
    "bb_assets": "Ativos em cripto",
    "bb_history_finished": "Concluído",
    "bb_time": "Hora",
    "bb_used": "Ativado",
    "be_usable": "Pode ser usado",
    "bear_consequences_your_investment": "Estou ciente e aceito os riscos de investimento. Meu código de autenticação é:",
    "become": "Tornar-se",
    "been_authenticated": "Você já está autenticado",
    "beginner_guide_title": "Plataforma de negociação de ativos digitais líder mundial",
    "belong_level": "Nível de pertencimento",
    "between_and": "Entre",
    "bill": "Fatura",
    "billing_details": "Detalhes da fatura",
    "bind_email": "Vincular e-mail",
    "bind_google": "Google Authenticator",
    "bind_google_authenticator": "Vincular Google Authenticator",
    "bind_phone": "Vincular celular",
    "branch_name": "Nome da agência",
    "bug_order": "Oferta de compra",
    "buy": "Comprar",
    "buy_1": "Comprar",
    "buy_now": "Comprar agora",
    "buy_quantity": "Quantidade a comprar",
    "buy_rate": "Taxa de compra",
    "buyer": "Comprador",
    "buyer_has_paid_attention": "Comprador já pagou, atenção",
    "buyer_payment": "Pagamento do comprador",
    "buyer_phone": "Número do celular do comprador",
    "buyer_uid": "ID do comprador",
    "calculate": "Calcular",
    "calculations are for your reference only": "Os resultados das cálculos são apenas para referência",
    "can_available": "Pode ser obtido",
    "canceled": "Cancelado",
    "cancellations": "Cancelamentos",
    "centre": "Centro",
    "change1": "Alterar",
    "change_gesture_password": "Alterar senha",
    "change_google": "Trocar Google Authenticator",
    "change_pay_style": "Trocar forma de pagamento",
    "change_phone": "Trocar celular",
    "charge_after_the_transaction": "Taxa cobrada após a transação",
    "charge_out": "A taxa deve ser paga em",
    "check_the_agree": "Marque para concordar",
    "china": "China",
    "choose_picture": "Escolher imagem",
    "clearing_time": "Hora de liquidação",
    "click_enter_batch_edit_page": "Clique para entrar na página de edição em massa",
    "click_on_the_copy": "Clique para copiar",
    "clinch_a_deal": "O parceiro deve ter concluído algumas transações (0 significa sem restrições)",
    "close": "Fechar",
    "closed_successfully": "Fechado com sucesso",
    "cloud_mining_pool": "Pool de mineração em nuvem",
    "coin_failure_0001": "Moeda não existe",
    "collect": "Favoritos",
    "collection_time": "Hora de recebimento",
    "commission": "Comissão obtida",
    "common_failure": "Falha!",
    "common_failure_0001": "Erro de rede",
    "common_problem": "Instruções de operação",
    "common_success": "Sucesso",
    "complete": "Completo",
    "completed": "Transação concluída",
    "computer_login": "Login no computador",
    "confirm": "Confirmar",
    "confirm_exit": "Confirmar saída?",
    "confirm_password": "Por favor, confirme a senha de login",
    "confirm_submission": "Confirmar envio",
    "confirm_the_purchase": "Confirmar compra",
    "confirm_to_give_up": "Confirmar desistência",
    "confirm_to_sell": "Confirmar venda",
    "confirm_upgrade": "Confirmar atualização",
    "confirm_vote": "Confirmar voto",
    "confirmation_information": "Informações de confirmação",
    "confrim_delete_warn": "Tem certeza de que deseja excluir o alerta?",
    "contact_service": "Contatar suporte",
    "contact_us": "Entre em contato conosco",
    "contract_Order": "Ordem de contrato",
    "contrct Agree Content": "Para proteger seus direitos, você deve ler atentamente o \"Acordo de Contrato Futuros BitMatrix\" antes de abrir um contrato.",
    "contrct Agree Content footer": "\"Acordo de Contrato Futuros BitMatrix\"",
    "contrct Agree Content header": "Para proteger seus direitos, você deve ler atentamente",
    "contrct_decimal": "Decimal",
    "convert_into": "Converter em",
    "converted_currency": "Moeda convertida",
    "copartner-upgrade-failure": "Falha na atualização",
    "copartner-upgrade-no": "Condições de atualização não atendidas, não é possível atualizar",
    "copartner-upgrade-ok": "Pode ser atualizado",
    "copartner-upgrade-success": "Atualização bem-sucedida",
    "copy_content": "Copiado para a área de transferência",
    "country": "País",
    "create_time": "Hora de criação",
    "cumulative_income": "Rendimento acumulado:",
    "cumulative_participation": "Participação acumulada",
    "currency": "Moeda",
    "currency_fee": "Taxa da moeda",
    "currency_information": "Detalhes da moeda",
    "currency_name": "Nome da moeda",
    "currency_not_found": "Tipo de moeda não encontrado",
    "currency_select": "Seleção de moeda",
    "current_day_return": "Retorno diário",
    "current_entrust": "Ordem atual",
    "current_identity": "Identidade atual",
    "current_polling_date": "Data da votação atual",
    "current_status": "Status atual",
    "currently_available": "Disponível atualmente",
    "date": "Data",
    "date_of_birth": "Data de nascimento",
    "day_highs_and_lows": "Variação diária",
    "day_highs_and_lows1": "Variação diária",
    "day_income": "Diário",
    "day_trading": "Negociação do dia",
    "daytime": "Durante o dia",
    "ddress_management": "Gerenciamento de endereços",
    "deal_amount": "Total da transação",
    "deal_done": "Transação concluída",
    "deal_gross": "Total/Quantidade",
    "deal_of_the_week": "Transações da semana",
    "deal_price": "Preço médio da transação",
    "deal_sum": "Valor da transação",
    "deal_the_detail": "Detalhes do pedido",
    "deal_volume": "Valor negociado",
    "deduct_coin": "Moeda deduzida",
    "default_settlement": "Liquidação por inadimplência",
    "defaults": "Padrão",
    "deposit": "Depósito rápido",
    "deposit_details": "Detalhes do depósito",
    "deposit_income": "Rendimento do depósito",
    "deposit_lock": "Bloqueio do depósito",
    "deposit_lock_details": "Detalhes do bloqueio do depósito",
    "deposit_returned_principal": "Capital retornado",
    "depth": "Profundidade",
    "detailed_rules": "Regras detalhadas",
    "details": "Detalhes",
    "digits6": "Por favor, insira 6 dígitos",
    "direct_market": "Promoção direta",
    "direct_reward": "Recompensa direta",
    "direction": "Direção",
    "discount_Rate": "Taxa de desconto",
    "discount_currency_introduction": "Introdução à moeda",
    "discount_dialog_tip1": "Parabéns! Você se qualifica",
    "discount_dialog_tip2": "para participar, e foi selecionado aleatoriamente como um usuário sortudo. Após obter a qualificação, você terá direito de participação!",
    "discount_dialog_tip3": "Você já possui",
    "discount_dialog_tip4": "qualificação para resgatar, a quantidade de participação é limitada, participe dentro do período do evento!",
    "discount_dialog_tip5": "Após desistir da qualificação, não poderá participar",
    "discount_dialog_tip6": "do evento e não poderá solicitar participação.",
    "discount_dialog_tip7": "Nota: A qualificação bem-sucedida resultará em uma dedução de",
    "discount_purchase": "Resgate com desconto",
    "discounted_or_original_price": "Preço com desconto/preço original",
    "dispose_result": "Resultado do processamento",
    "disqualification": "Desistência da qualificação",
    "divided_earnings": "Rendimento compartilhado",
    "drag": "Por favor, mantenha o botão pressionado e arraste até a direita",
    "each_input": "Verifique a senha de transação a cada negociação",
    "edt_selfchoose": "Editar favoritos",
    "effective": "Efetivo",
    "eligibility": "Qualificação para resgate",
    "email": "Registro de e-mail",
    "email_address_format": "Por favor, insira um e-mail válido",
    "email_code": "Código de verificação do e-mail",
    "empty_deal_pass": "A senha de transação não pode estar vazia",
    "end time": "Hora de término",
    "end_date": "Data de término",
    "end_time": "Hora de término",
    "enter_account": "Por favor, insira o celular/e-mail",
    "enter_cause_of_complaint": "Por favor, insira o motivo da reclamação",
    "enter_fb_buy_price": "Por favor, insira o preço de compra",
    "enter_fb_sell_price": "Por favor, insira o preço de venda",
    "enter_your_password": "Por favor, insira a senha de login",
    "enter_password": "Por favor, insira a senha",
    "entrust_amount": "Quantidade de ordem",
    "entrust_failure_0001": "Erro de rede",
    "entrust_failure_0002": "Tipo de consulta incorreto",
    "entrust_failure_0003": "Tipo de correspondência não pode estar vazio",
    "entrust_failure_0004": "ID do comerciante não pode estar vazio",
    "entrust_failure_0005": "ID do usuário não pode estar vazio",
    "entrust_failure_0006": "Usuário não encontrado",
    "entrust_failure_0007": "ID do par de negociação não pode estar vazio",
    "entrust_failure_0009": "Par de negociação não existe",
    "entrust_failure_0010": "A senha de transação não pode estar vazia",
    "entrust_failure_0011": "Senha de transação incorreta",
    "entrust_failure_0012": "O tipo de ordem não pode estar vazio",
    "entrust_failure_0013": "A quantidade da ordem não pode estar vazia",
    "entrust_failure_0014": "O preço da ordem limitada não pode estar vazio",
    "entrust_failure_0015": "O ID da ordem não pode estar vazio",
    "entrust_failure_0016": "A ordem não existe",
    "entrust_failure_0017": "Esta ordem foi excluída",
    "entrust_failure_0018": "Esta ordem não pode ser cancelada",
    "entrust_failure_0019": "O número da versão não pode estar vazio",
    "entrust_failure_0020": "A ordem de mercado não pode ser cancelada",
    "entrust_failure_0021": "Os dados atuais foram atualizados",
    "entrust_failure_0022": "Atualmente não há ordens, não é possível fazer uma ordem de mercado",
    "entrust_failure_0023": "O par de negociação está desabilitado ou a moeda relacionada está desabilitada",
    "entrust_failure_0024": "Este par de negociação não está aberto para transações",
    "entrust_failure_0025": "O preço da ordem está abaixo do preço mínimo ou acima do preço máximo",
    "entrust_failure_0026": "A quantidade da ordem está abaixo do mínimo ou acima do máximo",
    "entrust_failure_0027": "Esta conta foi proibida de negociar, consulte o suporte",
    "entrust_failure_0030": "Excedeu o aumento máximo diário de {} para este par de negociação, não é possível fazer a ordem",
    "entrust_failure_0031": "Excedeu a queda máxima diária de {} para este par de negociação, não é possível fazer a ordem",
    "entrust_msg_match_type_001": "Ordem limitada",
    "entrust_msg_match_type_002": "Ordem de mercado",
    "entrust_msg_status_001": "Não concluído",
    "entrust_msg_status_002": "Parcialmente concluído",
    "entrust_msg_status_003": "Completamente concluído",
    "entrust_msg_status_004": "Cancelado",
    "entrust_msg_type_001": "Ordem de venda",
    "entrust_msg_type_002": "Ordem de compra",
    "entrusted_price": "Preço da ordem",
    "entry_orders": "Ordens pendentes",
    "equal_principal": "Principal igual",
    "error_email": "E-mail incorreto",
    "error_network_anomaly": "Anomalia na rede",
    "error_phone": "Por favor, insira um número de celular válido",
    "error_unknow": "Erro na rede",
    "event_detail": "Detalhes do evento",
    "event_detail_warn_tip": "*Dica: ativos digitais são ativos inovadores com alta volatilidade de preços. Por favor, avalie racionalmente e escolha cuidadosamente as decisões de investimento!",
    "event_start_time": "Hora de início do evento",
    "every day": "Todos os dias",
    "everyday": "Diariamente",
    "exchange": "Troca rápida",
    "exchange_currency": "Moeda de troca",
    "exchange_details": "Detalhes da troca",
    "exchange_history": "Registro de trocas",
    "exchange_quantity": "Quantidade a ser trocada",
    "exchange_rules": "Regras de troca",
    "exchangeable": "Pode ser trocado",
    "exclusive_posters": "Gerar cartazes exclusivos",
    "exclusive_posters-Video": "Gerar cartaz",
    "exit": "Sair",
    "expected_return": "Retorno esperado",
    "expired": "Expirado",
    "express_area": "Área rápida",
    "face_recognition": "Reconhecimento facial",
    "failure": "Falha",
    "fb_lookup": "Clique para ver",
    "fb_order_number": "Número do pedido",
    "feeze_thaw_msg_status_0001": "Congelando",
    "feeze_thaw_msg_status_0002": "Descongelado",
    "fill_in_at_least_one": "Por favor, preencha pelo menos um campo",
    "fill_wrong": "Preenchimento incorreto pode levar a perda de ativos, verifique cuidadosamente",
    "filtrate": "Filtrar",
    "filtrate_content": "Filtrar métodos de pagamento, países, moedas fiduciárias, clique aqui!",
    "financial_management_is_cancel": "O rendimento do depósito foi cancelado! Por favor, não envie novamente!",
    "financial_management_is_delete": "Este tipo de investimento foi excluído",
    "financial_management_is_disable": "Este tipo de investimento foi desativado",
    "financial_management_not_cancel": "Investimento periódico não pode ser cancelado!",
    "financial_management_number_not_found": "A quantidade de investimento não pode estar vazia",
    "financial_management_record_add_failure": "Ativos insuficientes, por favor, faça um depósito antes de continuar com o investimento!",
    "financial_management_record_less_than_min_number": "A quantidade adicionada é menor que o mínimo definido de {}",
    "financial_management_type_not_found": "Falha ao mostrar informações do registro de investimento, tipo de investimento incorreto!",
    "financial_management_type_not_null": "O tipo de investimento não pode estar vazio",
    "find_password": "Recuperar senha",
    "fingerprint": "Impressão digital",
    "fingerprint_verification": "Verificação por impressão digital",
    "first_bind_phone": "Por favor, vincule seu número de celular primeiro",
    "first_period": "Primeiro período",
    "following_account": "Para a seguinte conta",
    "free": "Taxa",
    "Takerfree": "Taxa do Taker",
    "Makerfree": "Taxa do Maker",
    "freeze": "Congelar",
    "freeze_to_be_processed": "Congelamento em andamento, aguardando processamento",
    "fuc_use_discount": "Usar {} para pagar a taxa de transação * desconto",
    "futu": "Gráfico secundário",
    "gathering": "Recebimento",
    "gears": "Níveis",
    "generation1": "1ª geração",
    "gesture_error_limit": "Limite de tentativas de erro na senha de gesto atingido",
    "gesture_password": "Senha de gesto",
    "gesture_unlock": "Desbloqueio por gesto",
    "get_code": "Obter código de verificação",
    "goSign": "Ir para registro",
    "go_change": "Ir para modificar",
    "go_login": "Por favor, faça login primeiro",
    "go_pay": "Ir para pagamento",
    "go_setting": "Ir para configurações",
    "go_to_pick_up": "Ir para retirar",
    "go_to_receiving": "Ir para receber",
    "go_to_redeem": "Ir para resgatar",
    "google_verification_code": "Código de verificação do Google",
    "grievance_freeze": "Congelamento de reclamação",
    "has_been_on": "Já listado",
    "have_been_frozen": "Já congelado",
    "have_received": "Já recebido",
    "help_center": "Centro de ajuda",
    "hide_canceled": "Ocultar cancelados",
    "hide_other": "Mostrar todas as ordens pendentes",
    "high_frequency_partner": "Parceiro de alta frequência",
    "highest_single": "Maior valor único",
    "highfrequency_fee_sharing": "Compartilhamento de taxa de alta frequência",
    "hint": "Dica útil",
    "hint_password": "Por favor, insira a senha",
    "hint_phone_email": "Por favor, insira celular/e-mail",
    "historical_transaction": "Transação histórica",
    "history": "Histórico",
    "home_page": "Página inicial",
    "horizontal_version": "Versão horizontal",
    "hours": "Horas",
    "i18n_failure_0001": "Falha na consulta de idioma do sistema",
    "i18n_failure_0002": "Falha na consulta do dicionário de países",
    "iTo_draw_again": "Por favor, insira a senha novamente",
    "i_see": "Entendi",
    "i_see1": "Compreendi",
    "id_card_back_direction_failure": "Direção da imagem do verso do RG incorreta",
    "id_card_front_direction_failure": "Direção da imagem do frente do RG incorreta",
    "id_cord": "Número do documento",
    "id_type": "Tipo de documento",
    "identification": "Falha na identificação",
    "identity_authentication": "Autenticação de identidade",
    "identity_card": "Carteira de identidade",
    "if_at": "Se em",
    "ignore": "Ignorar",
    "immediately_invited": "Convidar imediatamente",
    "immediately_join": "Junte-se imediatamente",
    "in_trading": "Em negociação",
    "income": "Rendimento",
    "income_currency_type": "Tipo de moeda depositada",
    "income_deposit_principal": "Principal do depósito",
    "incomeing": "Ganhando rendimento",
    "increment quantity": "A quantidade inserida não pode exceder o aumento máximo permitido",
    "indirect_promotion": "Promoção indireta",
    "indirect_reward": "Recompensa indireta",
    "individual_selling_tips": "Não é possível vender para suas próprias ordens",
    "input_Branch_address": "Por favor, insira o endereço da agência",
    "input_address_labels": "Por favor, insira etiquetas",
    "input_alipay_account": "Por favor, insira a conta do Alipay",
    "input_bank_card_number": "Por favor, insira o número do cartão bancário",
    "input_bank_name": "Por favor, insira o nome do banco",
    "input_buyer_uid": "Por favor, insira o UID do comprador",
    "input_currency_referred": "Por favor, insira a sigla da moeda",
    "input_deposit_amount": "Por favor, insira a quantidade a depositar",
    "input_email": "Por favor, insira o e-mail",
    "input_email_code": "Por favor, insira o código de verificação do e-mail",
    "input_id_cord": "Por favor, insira o número do documento",
    "input_lock_amount": "Por favor, insira a quantidade a ser bloqueada",
    "input_mention_money_address": "Por favor, insira o endereço para retirada",
    "input_money": "Por favor, insira o valor",
    "input_name": "Por favor, insira o nome",
    "input_newphone_code": "Por favor, insira o novo número de celular",
    "input_nike_name": "Por favor, insira o apelido",
    "input_old_google_code": "Por favor, insira o código de verificação do Google antigo",
    "input_old_pass": "Por favor, insira a senha antiga",
    "input_paypal_acctount": "Por favor, insira a conta do PayPal",
    "input_phone": "Por favor, insira o número de celular",
    "input_phone_code": "Por favor, insira o código de verificação",
    "input_price": "Por favor, insira o preço",
    "input_quantity": "Por favor, insira a quantidade",
    "input_remark": "Por favor, insira uma observação",
    "input_service_charge": "Por favor, insira a taxa de serviço",
    "input_trading_volume": "Por favor, insira o volume de negociação",
    "input_transaction_password": "Por favor, insira a senha de transação",
    "input_wechat_account": "Por favor, insira a conta do WeChat",
    "input_wire_transfer": "Por favor, insira o endereço de transferência bancária",
    "inputs_numbers_greater_can_number": "A quantidade inserida é maior que a quantidade transferível",
    "insufficient_light": "Iluminação insuficiente",
    "interest": "Juros",
    "invitation_details": "Detalhes da recompensa por convite",
    "invitation_register_ad_not_found": "Anúncio de registro via convite não encontrado no app",
    "invitation_register_ad_tatle_not_found": "Título do anúncio de registro via convite não encontrado no app",
    "invitation_register_coin_not_found": "Moeda de recompensa por registro via convite não encontrada no app",
    "invitation_register_logo_not_found": "Logo do registro via convite não encontrado no app",
    "invitation_register_not_found": "Link de registro via convite não encontrado",
    "invite_code": "Código de convite (opcional)",
    "invite_code1": "Código de convite",
    "invite_commission": "Comissão",
    "invite_gift": "Recompensa por convite",
    "invite_reward": "Recompensa por convite",
    "invited_UID": "UID convidado",
    "invitees_are_available": "O convidador pode ganhar",
    "inviter-is-user": "O convidador não pode ser você mesmo",
    "inviter-not-exist": "O convidador não existe",
    "inviter_no_null": "O convidador não pode estar vazio",
    "ios_24小时排行榜": "Ranking de 24 horas",
    "ios_OTC交易须知": "Instruções para negociação OTC",
    "ios_PAYPAL": "PAYPAL",
    "ios_otc_order_prompt_001": "Se não receber o pagamento em minutos e não clicar no botão 'Confirmar pagamento', o sistema cancelará automaticamente este pedido após a contagem regressiva",
    "ios_otc_order_prompt_002": "Se o vendedor não receber o pagamento em minutos e não clicar no botão 'Confirmar pagamento', o sistema cancelará automaticamente este pedido após a contagem regressiva",
    "ios_万": "Milhares",
    "ios_上传身份证反面": "Carregar verso do RG",
    "ios_上传身份证正面": "Carregar frente do RG",
    "ios_两次密碼不一致请再次输入密碼": "As duas senhas não coincidem, por favor, insira a senha novamente",
    "ios_个确认大约需要": "Confirmações geralmente levam",
    "ios_中国大陆": "China continental",
    "ios_中级合伙人": "Parceiro intermediário",
    "ios_主版块": "Seção principal",
    "ios_之间": "Entre",
    "ios_买单": "Ordem de compra",
    "ios_买方必须成交过几次(0等于不限制)": "O comprador deve ter feito várias transações (0 significa sem restrições)",
    "ios_二维码/条形码": "Código QR/Código de barras",
    "ios_付款": "Pagamento",
    "ios_充币地址": "Endereço para depósito",
    "ios_先设置交易密码": "Defina a senha de transação primeiro",
    "ios_先设置交易密碼": "Defina a senha de transação primeiro",
    "ios_关闭照明": "Desligar iluminação",
    "ios_分享失败": "Falha ao compartilhar",
    "ios_分享成功": "Compartilhamento bem-sucedido",
    "ios_初级合伙人": "Parceiro júnior",
    "ios_卖单": "Ordem de venda",
    "ios_卖家已付款提示": "Aviso: vendedor já pagou",
    "ios_卖方必须成交过几次(0等于不限制)": "O vendedor deve ter realizado várias transações (0 significa sem restrições)",
    "ios_去解绑": "Desvincular",
    "ios_发布成功": "Publicação bem-sucedida",
    "ios_发送成功": "Envio bem-sucedido",
    "ios_各种费率说明": "Descrição das taxas",
    "ios_团队人数不足": "Número insuficiente de membros na equipe",
    "ios_复制QQ号": "Copiar número do QQ",
    "ios_复制地址": "Copiar endereço",
    "ios_如果你已完成线下付款，请点击下方已付款按钮。如果未完成付款，却点击已付款将会受到严厉惩罚，甚至永久冻结账户！": "Se você completou o pagamento offline, clique no botão 'Pagamento concluído' abaixo. Se clicar sem ter feito o pagamento, sofrerá penalidades severas, incluindo a possibilidade de congelamento permanente da conta!",
    "ios_客户端或在线钱包将您需要充值的": "O cliente ou carteira online mostrará o que precisa ser depositado",
    "ios_客户端或在线钱包将您需要充币的": "O cliente ou carteira online mostrará o que precisa ser depositado",
    "ios_客服": "Atendimento ao cliente",
    "ios_密碼设置成功！": "Senha configurada com sucesso!",
    "ios_将该地址设置为认证地址": "Defina este endereço como endereço de verificação",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充值，不影响到账。\n3、最小充值金额": "Tempo em horas, por favor, aguarde pacientemente.\n2. O mesmo endereço pode ser usado para múltiplos depósitos, sem afetar a confirmação.\n3. Valor mínimo para depósito",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充幣，不影响到账。\n3、最小充幣金额": "Tempo em horas, por favor, aguarde pacientemente.\n2. O mesmo endereço pode ser usado para múltiplos depósitos, sem afetar a confirmação.\n3. Valor mínimo para depósito",
    "ios_已付款": "Pagamento concluído",
    "ios_币币": "Troca de moedas",
    "ios_常见问题": "Perguntas frequentes",
    "ios_开启照明": "Ligar iluminação",
    "ios_意见反馈": "Feedback",
    "ios_成交": "Transação concluída",
    "ios_我的": "Meu perfil",
    "ios_手机号错误": "Número de celular incorreto",
    "ios_提交成功，等待认证": "Envio bem-sucedido, aguardando verificação",
    "ios_提币数量不能大于手续费": "A quantidade de retirada não pode exceder a taxa",
    "ios_提币数量减去手续费后不能": "A quantidade de retirada menos a taxa não pode",
    "ios_收款二维码": "Código QR para recebimento",
    "ios_收藏成功": "Salvo com sucesso",
    "ios_数目发送到该地址。发送完成后，系统会自动在此交易获得": "Quantidade enviada para este endereço. Após o envio, o sistema automaticamente registrará a transação",
    "ios_暂无网络": "Sem conexão à internet",
    "ios_最多可锁": "Máximo a ser bloqueado",
    "ios_最大限额": "Limite máximo",
    "ios_最小限额": "Limite mínimo",
    "ios_未解锁": "Não desbloqueado",
    "ios_没有中国大陆身份证的用户请通过网站版高级认证": "Usuários sem RG da China continental devem realizar a autenticação avançada pelo site",
    "ios_法币": "Moeda fiduciária",
    "ios_注册表示同意": "Registro implica aceitação",
    "ios_注册表示同意用户协议": "Registro implica aceitação do acordo do usuário",
    "ios_登录过期，请重新登录": "Sessão expirada, por favor, faça login novamente",
    "ios_直接推荐人uid": "UID do recomendador direto",
    "ios_直推人数不足": "Número insuficiente de recomendações diretas",
    "ios_绑定成功": "Vinculação bem-sucedida",
    "ios_请上传您的身份证": "Por favor, faça o upload da sua identidade",
    "ios_请上传身份证反面照": "Por favor, faça o upload da foto do verso do RG",
    "ios_请上传身份证正面照": "Por favor, faça o upload da foto da frente do RG",
    "ios_请先绑定收款方式": "Por favor, vincule um método de recebimento primeiro",
    "ios_请先设置支付方式": "Por favor, configure um método de pagamento primeiro",
    "ios_请输入价格和数量": "Por favor, insira o preço e a quantidade",
    "ios_请输入新邮箱": "Por favor, insira um novo e-mail",
    "ios_请输入正确的账号格式": "Por favor, insira o formato correto da conta",
    "ios_请输入身份证号": "Por favor, insira o número do RG",
    "ios_请输入限额": "Por favor, insira o limite",
    "ios_请通过": "Por favor, passe por",
    "ios_资产": "Ativos",
    "ios_超时": "Tempo esgotado",
    "ios_身份证号": "Número do RG",
    "ios_退出成功": "Saída bem-sucedida",
    "ios_邀请人可获得 * 的佣金": "O convidador pode receber uma comissão de *",
    "ios_锁仓数量不能为空": "A quantidade a ser bloqueada não pode estar vazia",
    "ios_间接接推荐人uid": "UID do recomendador indireto",
    "ios_隐藏其他挂单": "Ocultar outras ordens pendentes",
    "ios_高级合伙人": "Parceiro sênior",
    "is_bind": "Já vinculado",
    "is_delete_select": "Deseja excluir a seleção?",
    "issued": "Emitido",
    "issued_to_lock": "Emitido para bloqueio",
    "k_close": "Fechar",
    "k_max": "Máximo",
    "k_min": "Mínimo",
    "k_num": "Quantidade",
    "k_open": "Abrir",
    "kline_target": "Indicador",
    "language": "Idioma",
    "lead_content": "Consulta de pedidos, publicação de anúncios, configuração de recebimento, seguir/bloquear agora estão aqui!",
    "least_four": "Por favor, conecte pelo menos 4 pontos",
    "less-than-min": "Abaixo do limite mínimo de bloqueio",
    "lf there are pending": "Se houver ordens limitadas em sentido oposto, elas serão canceladas antes do fechamento total.",
    "limit": "Configuração de limite",
    "limit_orderAA": "Ordem de limite",
    "limit_set": "Configuração de limite (opcional)",
    "limited_price": "Preço limite",
    "liquidation": "Liquidação forçada",
    "liquidation AMT": "Quantidade de liquidação",
    "liquidation details": "Detalhes da liquidação",
    "loading": "Carregando",
    "lock-expire": "Bloqueio expirado",
    "lock_amount": "Quantidade bloqueada",
    "lock_duration": "Duração do bloqueio",
    "lock_person": "Pessoa que bloqueou",
    "lock_record": "Registro de bloqueio",
    "lock_remaining": "Quantidade restante bloqueada",
    "lock_reward": "Recompensa de bloqueio",
    "lock_time": "Hora do bloqueio",
    "locked": "Bloqueado",
    "locked_closed": "Encerrado",
    "locked_insufficient_balance": "Saldo insuficiente",
    "locked_settled": "Liquidado",
    "locked_unsettlement": "Não liquidado",
    "locked_warehouse": "Em bloqueio",
    "log_in": "Login",
    "login": "Login",
    "login_expired": "Sessão expirada, por favor, faça login novamente.",
    "login_forget_pwd": "Esqueceu a senha?",
    "login_name_txt": "Nome de usuário",
    "login_pass": "Senha de login",
    "logion_welcome_txt": "Bem-vindo ao login",
    "mail": "E-mail",
    "mailbox": "Verificação de e-mail",
    "mailbox_bind_success": "Vinculação de e-mail bem-sucedida",
    "mailbox_new": "Novo e-mail",
    "main_picture": "Imagem principal",
    "manage_reward_details": "Detalhes da recompensa de gerenciamento",
    "management_reward": "Recompensa de gerenciamento",
    "management_share": "Divisão de gerenciamento",
    "mandarin": "Por favor, leia os números em mandarim completo",
    "mandarin_reading_content": "Por favor, leia o conteúdo abaixo em linguagem padrão",
    "manual_recharge_msg_status_0001": "Aguardando depósito",
    "manual_recharge_msg_status_0002": "Aprovado",
    "manual_recharge_msg_status_0003": "Depósito bem-sucedido",
    "manual_recharge_msg_type_0001": "Depósito normal",
    "manual_recharge_msg_type_0002": "Processamento de fundos",
    "manual_recharge_msg_type_0003": "Bônus de atividade",
    "margin": "Margem",
    "market_failure_00001": "Falha ao obter dados de mercado na página inicial",
    "market_failure_00002": "ID do comerciante não existe",
    "market_failure_00003": "Nenhuma área de negociação disponível para o comerciante",
    "market_failure_00004": "Falha ao obter dados de negociação de moedas",
    "market_failure_00005": "Nome do par de negociação não pode estar vazio",
    "market_failure_00006": "Nenhuma informação de setor disponível para o comerciante",
    "market_failure_00007": "Falha ao obter informações do par de negociação",
    "market_failure_00008": "Falha ao obter dados de K-line",
    "market_failure_00009": "Falha ao obter dados de profundidade",
    "market_failure_00010": "Parâmetros de solicitação inválidos",
    "market_failure_00011": "Par de negociação inválido",
    "market_low_price": "Maior preço de compra de mercado",
    "market_order": "Ordem a mercado",
    "market_price": "Preço de mercado",
    "market_sell_price": "Menor preço de venda de mercado",
    "market_switch_moved_here": "A troca de ordens horizontais/verticais foi movida para cá",
    "match": "Correspondência",
    "match_the_number_of": "Quantidade correspondente",
    "max_lock_volume": "Máxima quantidade bloqueada",
    "maximum_30_characters": "Máximo de 30 caracteres",
    "maximum_coins": "A quantidade máxima para retirada é",
    "maximum_withdrawal": "Máximo por retirada",
    "mention_a_currency": "Quantidade de retirada",
    "min_lock_volume": "Mínima quantidade bloqueada",
    "min_recharge_amount": "Depósitos pequenos não contabilizados serão considerados bem-sucedidos após atingir a quantidade mínima {}*",
    "minhour": "Minutos",
    "minimum_coins": "A quantidade mínima para retirada é",
    "minimum_purchase_quantity": "A quantidade de compra deve ser maior que a quantidade restante {}",
    "minimum_quantity_sold": "A quantidade de venda deve ser maior que a quantidade restante {}",
    "minutes": "Minutos",
    "mobile phone": "Número de celular",
    "money": "Montante",
    "more": "Mais",
    "more Or than": "Posição longa: valor nominal * quantidade * preço médio de fechamento - valor nominal * quantidade * preço médio de abertura\n\nPosição curta: valor nominal * quantidade * preço médio de abertura - valor nominal * quantidade * preço médio de fechamento",
    "more-than-max": "Acima do limite máximo de bloqueio",
    "more11": "Mais",
    "more_can_save": "Máximo que pode ser depositado",
    "more_home": "Mais",
    "msg_camera_framework_bug": "Por favor, ative a permissão da câmera",
    "must_retain_one": "Celular, Google, e-mail devem manter pelo menos um",
    "my_income": "Minha renda",
    "my_prompt_001": "* Após a alteração do número de celular, será necessário usar o novo número para login.",
    "my_prompt_002": "* Aviso: por favor, use uma conta registrada em seu nome. Os métodos de pagamento ativados serão exibidos ao comprador durante a negociação OTC. É possível configurar até 5, mas ativar no máximo 3.",
    "my_prompt_003": "* Após a alteração do e-mail, será necessário usar o novo e-mail para login.",
    "my_vote": "Meu voto",
    "name": "Nome",
    "name_empty": "O nome não pode estar vazio",
    "nationality": "Nacionalidade",
    "need_me_record_video": "É necessário gravar um vídeo pessoalmente",
    "never_enter": "Nunca insira a senha de transação",
    "new_address": "Novo endereço",
    "new_pass": "Nova senha",
    "new_pass_empty": "Por favor, insira uma nova senha",
    "new_pass_minimum": "A senha não pode ter menos de 6 caracteres",
    "new_report_statistics": "Nova estatística de relatórios",
    "newest_versions": "A versão atual já é a mais recente!",
    "next": "Próximo",
    "nick_name": "Apelido",
    "nickname_des_txt": "O apelido suporta apenas caracteres em chinês, inglês e números, com um limite de 15 caracteres",
    "nighttime": "Noite",
    "ninety_days": "Últimos 90 dias",
    "no-lock-detail": "Nenhuma informação de bloqueio",
    "no_Senior_certification": "Por favor, complete a certificação avançada primeiro",
    "no_data": "Sem dados disponíveis",
    "no_flash": "O dispositivo atual não possui flash, não é possível ativar a iluminação",
    "no_more_data": "Sem mais dados",
    "no_photo_taking": "Seu dispositivo não suporta fotografia",
    "no_price": "O preço não pode estar vazio",
    "no_quantity": "Por favor, insira uma quantidade válida",
    "no_realname_auth": "Por favor, complete a autenticação de nome real primeiro",
    "no_use": "Não ativado",
    "none": "Nenhum",
    "not_bound": "Não vinculado",
    "not_frequency_partner": "Você ainda não é um parceiro",
    "not_redemption": "Sem qualificação para resgate",
    "not_started": "Não iniciado",
    "not_to_issue": "Não emitido",
    "not_trading": "Este tipo de moeda não está disponível para negociação no momento",
    "notpass": "Revisão não aprovada",
    "null_address_tag": "O rótulo do endereço não pode estar vazio",
    "null_code": "O código de verificação não pode estar vazio",
    "null_email": "O e-mail não pode estar vazio",
    "null_password": "A senha não pode estar vazia",
    "null_phone": "O número do celular não pode estar vazio",
    "number": "unidades",
    "number_empty": "A quantidade não pode ser 0",
    "number_of_arrivals": "Quantidade recebida",
    "number_of_issues": "Quantidade total emitida",
    "number_of_withdrawal": "Número de retiradas diárias",
    "obtained": "Retirado",
    "occlusion": "Obstrução do rosto",
    "occupy": "Ocupar",
    "official_community": "Comunidade oficial",
    "official_verification_channel": "Canal de verificação",
    "old_pass": "Senha antiga",
    "old_pass_empty": "Por favor, insira a senha original",
    "on_sale": "À venda",
    "online_service": "Atendimento online",
    "open XKD perpetual swap": "Ativar contrato perpétuo BitMatrix",
    "open perpetual swap": "Ativar contrato perpétuo",
    "open_app": "Abrir o aplicativo",
    "open_it": "Abrir",
    "open_successfully": "Abertura bem-sucedida",
    "open_touchID": "Deseja ativar o Touch ID?",
    "opposite_side_uid": "Outro lado",
    "option": "Opção",
    "optional": "Opcional",
    "optional_area": "Área opcional",
    "options": "Método de seleção",
    "order": "Ordem",
    "order time": "Por tempo de ordem",
    "order time1": "Hora da ordem",
    "order_a_complaint": "Reclamação de ordem",
    "order_details": "Detalhes da ordem",
    "order_form": "Formulário de ordem",
    "order_history": "Histórico de ordens",
    "order_inquiry": "Consulta de ordens",
    "order_number": "Número da ordem",
    "order_time": "Hora da solicitação",
    "ordinary_partner": "Parceiro comum",
    "otc_account": "Conta",
    "otc_confirm_Put_the_coin": "Confirme que você recebeu a transferência do outro lado! (Aviso: não clique em confirmar antes de receber o pagamento. Em caso de disputa, entre em contato com o atendimento ao cliente para reclamar)",
    "otc_confirm_the_payment": "Confirmar pagamento",
    "otc_confirm_the_receipt": "Confirmar recebimento",
    "otc_failure_0001": "Conta desativada, entre em contato com o atendimento ao cliente o mais rápido possível",
    "otc_failure_0002": "Conta não passou na certificação avançada, não pode realizar operações OTC",
    "otc_failure_0003": "Senha de transação da conta incorreta",
    "otc_failure_0004": "ID da ordem inválido",
    "otc_failure_0006": "Falha ao alterar o status",
    "otc_failure_0009": "Este tipo de ordem não existe",
    "otc_failure_0010": "Cancelamento falhou",
    "otc_failure_0011": "Esta ordem já foi concluída ou cancelada",
    "otc_failure_0012": "O usuário atual não é um comerciante OTC, sem permissão para usar esta função",
    "otc_failure_0013": "Falha ao desbloquear, falha ao cancelar",
    "otc_failure_0015": "Este tipo de ordem não existe",
    "otc_failure_0016": "A quantidade da ordem não pode ser menor ou igual a 0",
    "otc_failure_0017": "O preço da transação não pode ser menor ou igual a 0",
    "otc_failure_0018": "O limite mínimo por transação não pode ser menor que 0",
    "otc_failure_0019": "O limite máximo por transação não pode ser menor que o limite mínimo",
    "otc_failure_0020": "Moeda fiduciária não suportada",
    "otc_failure_0021": "Esta moeda fiduciária não está disponível no momento",
    "otc_failure_0022": "ID da moeda do comerciante incorreto",
    "otc_failure_0023": "O limite mínimo por transação não pode ser menor que o limite mínimo desta moeda {}",
    "otc_failure_0024": "O limite máximo por transação não pode ser maior que o limite máximo desta moeda {}",
    "otc_failure_0025": "A quantidade da ordem não pode ter mais de {} casas decimais",
    "otc_failure_0026": "O valor total da ordem não pode ser menor que o limite mínimo de pedido {}",
    "otc_failure_0027": "O valor total da ordem não pode ser maior que o limite do usuário {}",
    "otc_failure_0028": "O valor total da ordem não pode ser maior que o limite do comerciante {}",
    "otc_failure_0029": "O preço não pode ser menor que {}",
    "otc_failure_0030": "O preço não pode ser maior que {}",
    "otc_failure_0031": "O usuário atual não vinculou uma conta de pagamento",
    "otc_failure_0032": "Falha ao congelar, falha ao criar ordem",
    "otc_failure_0033": "O número máximo de pedidos a serem processados simultaneamente não pode ser menor que 0",
    "otc_failure_0034": "O número mínimo de transações do comprador não pode ser menor que 0",
    "otc_failure_0035": "Este usuário já é um comerciante certificado ou está em processo de verificação",
    "otc_failure_0036": "O número de transações bem-sucedidas do solicitante OTC é insuficiente",
    "otc_failure_0037": "Falha ao desbloquear, falha ao cancelar",
    "otc_failure_0038": "O saldo disponível atual do solicitante {} é insuficiente {}",
    "otc_failure_0039": "Nenhuma moeda disponível atualmente",
    "otc_failure_0040": "A moeda atual não está disponível",
    "otc_failure_0041": "Envio bem-sucedido, aguarde a verificação",
    "otc_failure_0042": "Desculpe, este usuário está atualmente em estado de verificação e não pode solicitar novamente",
    "otc_failure_0043": "Este tipo de transação não existe",
    "otc_failure_0044": "Autocompras e autovendas são proibidas",
    "otc_failure_0046": "O número máximo de pedidos a serem processados simultaneamente {} atingiu o limite, por favor, tente novamente mais tarde",
    "otc_failure_0047": "O comprador deve ter realizado pelo menos {} transações",
    "otc_failure_0048": "A quantidade de compra não pode ser menor ou igual a 0",
    "otc_failure_0049": "A quantidade de compra não pode ser maior que a quantidade restante da ordem atual",
    "otc_failure_0050": "ID da ordem inválido",
    "otc_failure_0051": "O valor total da compra não pode ser menor que o limite mínimo de negociação da moeda {}",
    "otc_failure_0052": "O valor da compra não pode ser maior que o limite máximo de negociação da moeda {}",
    "otc_failure_0053": "O tipo de ordem atual não é para venda",
    "otc_failure_0054": "O status da ordem atual está incorreto",
    "otc_failure_0055": "O valor da compra não pode ser menor que o limite mínimo por transação {}",
    "otc_failure_0056": "O valor da compra não pode ser maior que o limite máximo por transação {}",
    "otc_failure_0057": "Nenhum estado correspondente",
    "otc_failure_0058": "O número de registros de alteração de ordem está incorreto",
    "otc_failure_0059": "Falha ao congelar, falha ao cancelar a ordem",
    "otc_failure_0060": "Por favor, vincule uma conta de recebimento do tipo designado pelo comerciante nas configurações de pagamento!",
    "otc_failure_0061": "O vendedor deve ter concluído {} transações",
    "otc_failure_0062": "A quantidade de venda não pode ser menor ou igual a 0",
    "otc_failure_0063": "A quantidade de venda não pode ser maior que a quantidade restante da ordem atual",
    "otc_failure_0064": "O tipo de ordem atual não é para compra",
    "otc_failure_0065": "O valor total da venda não pode ser menor que o limite mínimo de negociação da moeda {}",
    "otc_failure_0066": "O valor da venda não pode ser maior que o limite máximo de negociação da moeda {}",
    "otc_failure_0067": "O valor da venda não pode ser menor que o limite mínimo por transação {}",
    "otc_failure_0068": "O valor da venda não pode ser maior que o limite máximo por transação {}",
    "otc_failure_0069": "ID da conta de pagamento inválido",
    "otc_failure_0070": "Falha ao confirmar o pagamento",
    "otc_failure_0073": "Falha ao alterar o status da ordem",
    "otc_failure_0074": "Falha ao transferir a moeda, falha ao confirmar o recebimento",
    "otc_failure_0075": "O método de pagamento não pode estar vazio",
    "otc_failure_0076": "Falha ao enviar SMS {}",
    "otc_failure_0077": "Esta moeda de transação não existe",
    "otc_failure_0078": "Esta moeda fiduciária não existe",
    "otc_failure_0079": "Falha ao alterar o status da ordem",
    "otc_failure_0080": "Esta moeda de transação está desativada",
    "otc_failure_0081": "Esta moeda fiduciária está desativada",
    "otc_failure_0082": "Esta ordem não existe",
    "otc_failure_0083": "OTC atualmente não tem moeda fiduciária configurada para negociação",
    "otc_failure_0084": "OTC atualmente não tem moeda configurada para negociação",
    "otc_failure_0085": "A quantidade restante da ordem é insuficiente",
    "otc_failure_0090": "Esta conta foi proibida de negociar OTC, consulte o atendimento ao cliente",
    "otc_failure_0091": "Usuários comuns não podem criar ordens OTC, por favor, solicite se tornar um comerciante primeiro",
    "otc_failure_0092": "O recebimento atual do pedido não expirou, o comprador não pode apresentar uma reclamação",
    "otc_failure_0093": "Esta ordem já foi reclamada, entre em contato com o atendimento ao cliente",
    "otc_failure_0094": "De acordo com as regulamentações relevantes do local de registro, não é possível realizar esta operação",
    "otc_failure_0095": "Esta ordem não pode ser negociada no momento",
    "otc_failure_0096": "Ativos da conta do vendedor anormais, não é possível realizar esta operação no momento",
    "otc_failure_0097": "O número de cancelamentos OTC no dia excedeu o limite, negociação proibida",
    "otc_failure_0098": "Você tem ordens pendentes, por favor, complete as ordens antes de continuar.",
    "otc_margin_certification": "Certificação de margem",
    "otc_mine_account_paid": "Eu já paguei",
    "otc_order_prompt_003": "Se o comprador não pagar e clicar no botão 'Confirmar pagamento' dentro do prazo especificado, o sistema cancelará automaticamente esta ordem após a contagem regressiva.",
    "otc_picture_no_null": "Por favor, faça o upload de pelo menos uma imagem",
    "otc_prompt_001": "1. O prazo de pagamento da ordem é de 30 minutos, por favor, pague a tempo e clique no botão (Eu já paguei).\n2. A moeda do vendedor é bloqueada e custodiada pelo sistema, fique tranquilo para fazer seu pedido.",
    "otc_rate": "Taxa",
    "otc_report_order": "Pedido concluído",
    "otc_success_0001": "Cancelamento bem-sucedido",
    "otc_success_0002": "Venda bem-sucedida",
    "otc_success_0003": "Compra bem-sucedida",
    "otc_success_0004": "Confirmação de pagamento bem-sucedida",
    "otc_success_0005": "Confirmação de recebimento bem-sucedida, ordem encerrada",
    "otc_success_0006": "Reclamação bem-sucedida",
    "otc_waiting_for_confirmation": "Aguardando confirmação do vendedor",
    "other_settings": "Outras configurações",
    "overtime_pay": "Pagamento não realizado dentro do prazo, ordem cancelada",
    "param-error": "Erro de parâmetro",
    "partner": "Parceiro",
    "partner_commission": "O parceiro pode receber comissões de taxas de seus subordinados",
    "partner_commission_fee": "Taxa de comissão",
    "partner_current_level": "Nível atual",
    "partner_get_all_subordinate": "Pode obter todos os subordinados",
    "partner_management": "Gerenciamento de parceiros",
    "partner_need_pay": "Pagamento necessário",
    "partner_need_recommend": "Recomendação direta necessária",
    "partner_recommended": "Já recomendado",
    "partner_start_and_end_time": "Período",
    "partner_team": "Equipe",
    "partner_team_member": "O número mínimo de membros da equipe deve ser alcançado",
    "partner_upgrade_immediately": "Atualizar imediatamente",
    "pass_muster": "Se não atender aos requisitos, clique aqui",
    "passed": "Aprovado",
    "passport": "Passaporte",
    "password_disagree": "As duas senhas não coincidem",
    "password_is_different": "As senhas inseridas não coincidem",
    "password_text": "Senha",
    "password_wrong": "Defina uma senha de 8 a 20 caracteres",
    "past_activities": "Atividades passadas",
    "pause_recharge": "Esta moeda não aceita depósitos",
    "pause_withdraw": "Esta moeda não aceita retiradas",
    "pay_number": "Quantidade a pagar",
    "pay_wey": "Método de pagamento",
    "payee": "Beneficiário",
    "payfor_type": "Método de pagamento",
    "payment": "Pagamento",
    "payment_amount": "Valor do pagamento",
    "payment_currency": "Moeda de pagamento",
    "payment_has_been": "(Eu já paguei)",
    "payment_term": "Método de recebimento",
    "paypal_account": "Conta PayPal",
    "pending_review": "Aguardando revisão",
    "people": "Pessoas",
    "period": "Período",
    "personal_detail": "Detalhes pessoais",
    "personal_purchase_tips": "Não é permitido comprar suas próprias ordens",
    "phone": "Celular",
    "phone_code": "Código de verificação do celular",
    "phone_register": "Registro pelo celular",
    "phone_verification": "Verificação por SMS",
    "phone_verify": "Verificação do celular",
    "photo_album": "Álbum de fotos",
    "please_enter": "Por favor, insira",
    "please_enter_abbreviation_or_letter": "Por favor, insira o conteúdo da pesquisa",
    "please_input_content": "Por favor, insira o conteúdo",
    "please_pay": "Por favor, efetue o pagamento",
    "please_verification": "Por favor, verifique",
    "pop_Date": "Data",
    "pop_reset": "Redefinir",
    "position at market price?": "Fechar a posição ao preço de mercado?",
    "post_only": "Apenas Maker (Post only)",
    "price": "Preço",
    "price_cannot_be_less": "O preço não pode ser menor que {}",
    "price_cannot_be_more": "O preço não pode ser maior que {}",
    "price_empty": "O preço não pode ser 0",
    "principal": "Capital",
    "promote_links": "Links de promoção",
    "promotion_statistical": "Estatísticas de promoção",
    "prtner_invalid": "Inválido",
    "publish_a_trade_order": "Publicar ordem de negociação",
    "purchase": "Comprar",
    "push": "PUSH ativos",
    "push_maximum_volume": "A quantidade de PUSH é maior que o saldo disponível {}",
    "push_minimum_trading_volume": "A quantidade de PUSH é menor que o volume mínimo de negociação {}",
    "quantity": "Quantidade",
    "quantity_deal": "Quantidade/Concluído",
    "quota": "Limite",
    "range": "Intervalo",
    "real_income": "Rendimento real",
    "real_name_authentication": "Autenticação de nome real",
    "reason": "Razão",
    "rebate_amount": "Valor do reembolso",
    "rebate_record": "Registro de reembolso",
    "rebate_reward": "Recompensa de reembolso",
    "receiving_set": "Configurações de recebimento",
    "receiving_set_title": "Por favor, configure o método de recebimento primeiro",
    "recently_deal1": "Negociações recentes",
    "recharge_notice": "Aviso de recarga\n1. Envie a quantidade de {} que você deseja recarregar para este endereço através do cliente {} ou carteira online. Após o envio, o sistema irá recarregar a moeda virtual em sua conta após {} confirmações.\n2. Você pode recarregar várias vezes usando o mesmo endereço, sem afetar a conta.\n3. O valor mínimo de recarga é {}.",
    "recharge_source": "Fonte da recarga",
    "recharge_withdraw_failure_0001": "Erro de rede",
    "recharge_withdraw_failure_0002": "A senha da transação não pode estar vazia",
    "recharge_withdraw_failure_0003": "O código de verificação não pode estar vazio",
    "recharge_withdraw_failure_0004": "O ID do comerciante não pode estar vazio",
    "recharge_withdraw_failure_0005": "O ID do usuário não pode estar vazio",
    "recharge_withdraw_failure_0006": "Usuário não existe",
    "recharge_withdraw_failure_0007": "O ID da moeda não pode estar vazio",
    "recharge_withdraw_failure_0009": "A moeda não existe",
    "recharge_withdraw_failure_0010": "O código de verificação do Google não pode estar vazio",
    "recharge_withdraw_failure_0011": "Senha de transação incorreta",
    "recharge_withdraw_failure_0012": "O tipo de recarga não pode estar vazio",
    "recharge_withdraw_failure_0013": "A quantidade de recarga não pode estar vazia",
    "recharge_withdraw_failure_0014": "A anotação de recarga não pode estar vazia",
    "recharge_withdraw_failure_0015": "O ID da recarga não pode estar vazio",
    "recharge_withdraw_failure_0016": "A ordem de recarga não existe",
    "recharge_withdraw_failure_0017": "Apenas ordens pendentes podem ser excluídas",
    "recharge_withdraw_failure_0018": "Apenas ordens pendentes podem ser revisadas",
    "recharge_withdraw_failure_0019": "Apenas ordens aprovadas podem ser liberadas para descongelamento",
    "recharge_withdraw_failure_0020": "O número da versão não pode estar vazio",
    "recharge_withdraw_failure_0021": "Erro de parâmetro",
    "recharge_withdraw_failure_0022": "Os dados atuais foram atualizados",
    "recharge_withdraw_failure_0023": "O ID não pode estar vazio",
    "recharge_withdraw_failure_0024": "Apenas ordens bloqueadas podem ser revisadas",
    "recharge_withdraw_failure_0025": "Apenas ordens pendentes podem ser bloqueadas",
    "recharge_withdraw_failure_0026": "Apenas ordens pendentes ou bloqueadas podem ser canceladas",
    "recharge_withdraw_failure_0027": "A quantidade a ser congelada não pode estar vazia",
    "recharge_withdraw_failure_0028": "O motivo do congelamento não pode estar vazio",
    "recharge_withdraw_failure_0029": "A anotação de descongelamento não pode estar vazia",
    "recharge_withdraw_failure_0030": "A quantidade de retirada não pode estar vazia",
    "recharge_withdraw_failure_0031": "O endereço de retirada não pode estar vazio",
    "recharge_withdraw_failure_0032": "A taxa não pode estar vazia",
    "recharge_withdraw_failure_0033": "Taxa anômala",
    "recharge_withdraw_failure_0034": "Dados não encontrados",
    "recharge_withdraw_failure_0035": "A ordem de ativos não pode ser descongelada",
    "recharge_withdraw_failure_0036": "O endereço de retirada está incorreto",
    "recharge_withdraw_failure_0041": "Esta conta foi proibida de retirar, consulte o atendimento ao cliente",
    "recharge_withdraw_failure_0042": "O número de retiradas atingiu o limite diário",
    "recharge_withdraw_failure_0043": "O volume total de retiradas excedeu o limite diário",
    "recharge_withdraw_failure_0044": "Erro ao obter informações do nível VIP",
    "recharge_withdraw_failure_0045": "Erro ao obter informações da moeda do comerciante",
    "recharge_withdraw_failure_0046": "Retiradas proibidas nas primeiras 24 horas após a redefinição da senha!",
    "recharge_withdraw_failure_0048": "Erro ao obter o valor máximo de retirada por transação",
    "recharge_withdraw_failure_0049": "Erro ao obter o valor mínimo de retirada por transação",
    "recharge_withdraw_failure_0050": "A quantidade inserida deve ser menor que o limite máximo",
    "recharge_withdraw_failure_0051": "A quantidade inserida deve ser maior que o limite mínimo",
    "recharge_withdraw_msg_status_0001": "Aguardando retirada",
    "recharge_withdraw_msg_status_0002": "Enviando",
    "recharge_withdraw_msg_status_0003": "Retirada bem-sucedida",
    "recharge_withdraw_msg_status_0004": "Cancelar",
    "recharge_withdraw_msg_status_0005": "Confirmando",
    "recharge_withdraw_msg_status_0006": "Recarga bem-sucedida",
    "recharge_withdraw_msg_status_0007": "Aprovado",
    "recharge_withdraw_msg_type_0001": "Recarga",
    "recharge_withdraw_msg_type_0002": "Retirada",
    "recharge_withdraw_msg_withdraw_type_0001": "Transferência interna",
    "recharge_withdraw_msg_withdraw_type_0002": "Retirada externa",
    "record": "Registro",
    "record_the_video": "Clique para gravar vídeo",
    "recording": "Gravando novamente",
    "redeem_now": "Resgatar agora",
    "redeemed": "Resgatado",
    "redeeming": "Resgatando",
    "redemption_amount": "Quantidade de resgate",
    "redemption_time": "Hora do resgate",
    "referrer": "Recomendador",
    "register": "Registrar",
    "registered_successfully": "Registro bem-sucedido",
    "registration_award_details": "Detalhes da recompensa de registro",
    "registration_incentives": "Recompensas de registro",
    "registration_time": "Hora do registro",
    "regular": "Regular",
    "regular_return": "Retorno mensal regular",
    "release": "Publicar",
    "release_operation": "Publicar ordem, clique aqui para operar!",
    "release_the_order": "Publicar ordem",
    "release_time": "Hora da liberação",
    "remark": "Observação",
    "remember_state": "Lembrar estado de login",
    "repeal": "Revogar",
    "reset": "Redefinir",
    "reset_login_password": "Alterar senha de login",
    "reset_pass": "Redefinir senha",
    "residue": "Restante",
    "retrieve_the_deposit": "Recuperar depósito",
    "return_amount": "Quantidade de retorno",
    "return_method": "Método de retorno",
    "return_the_principal": "Retornar o capital",
    "revenue_details": "Detalhes da receita",
    "revocation_all": "Revogar tudo",
    "reward_amount": "Quantidade de recompensa",
    "reward_locked": "Recompensa bloqueada",
    "reward_status": "Status da recompensa",
    "reward_type": "Tipo de recompensa",
    "rewards_record": "Registro de recompensas",
    "right_now_register": "Registro de novos usuários",
    "roll_out": "Transferir",
    "save": "Salvar",
    "save_coin": "Depositar",
    "save_coin_count": "Quantidade a ser depositada",
    "save_coin_days": "Dias de depósito",
    "save_coin_duration": "Duração do depósito",
    "save_currency": "Depositar moeda",
    "save_money_amount": "Quantidade total depositada",
    "save_successfully": "Salvo com sucesso",
    "save_the_image": "Baixar imagem",
    "scan_QR_code": "Por favor, não escaneie QR codes de fontes desconhecidas",
    "scan_result": "Resultado da digitalização",
    "search": "Pesquisar",
    "search_coin_placeholder": "Pesquise a moeda de seu interesse",
    "search_keyword": "Palavra-chave de pesquisa",
    "search_name": "Nome da pesquisa",
    "search_result": "Resultado da pesquisa",
    "search_trade_pair_placeholder": "Pesquise o par de negociação de seu interesse",
    "security_center": "Centro de segurança",
    "security_level": "Nível de segurança",
    "select_date_of_borth": "Por favor, selecione a data de nascimento",
    "select_delete_warn": "Por favor, selecione o alerta a ser excluído",
    "sell": "Vender",
    "sell_1": "Vender",
    "sell_order": "Ordem de venda",
    "sell_quantity": "Quantidade a ser vendida",
    "sell_rate": "Taxa de venda",
    "seller": "Vendedor",
    "seller_phone": "Telefone do vendedor",
    "send": "Enviar",
    "senior_certification": "Certificação avançada",
    "senior_certification1": "Certificação avançada",
    "service": "Atendimento ao cliente QQ:",
    "service_charge": "Taxa de serviço",
    "session_timeout": "Sessão expirada, por favor, faça o login novamente",
    "set_Password": "Alterar",
    "set_gesture_pass": "Por favor, defina a senha gestual",
    "set_login_password": "Definir senha de login",
    "setting": "Configuração",
    "setting_alipay": "Configurar Alipay",
    "setting_bank_card": "Configurar cartão bancário",
    "setting_message_warn_tip": "*Ao ativar, você receberá notificações de alerta, ao desativar não receberá notificações.",
    "setting_paypal": "Configurar PayPal",
    "setting_paypassword": "Por favor, defina a senha de transação primeiro",
    "setting_wechat": "Configurar WeChat",
    "setting_western_union": "Configurar Western Union",
    "share_invitation_link": "Compartilhar link de convite",
    "share_link": "Compartilhar link com seus amigos",
    "share_to_do": "Compartilhar para",
    "shift_to": "Transferir para",
    "single_least": "O limite mínimo por transação é menor que {}",
    "single_minimum": "Mínimo por transação",
    "single_more": "O limite mínimo por transação é maior que {}",
    "skip": "Pular",
    "small_exchange": "Pequena troca",
    "small_exchange_tip": "*Dica: Você pode trocar uma vez dentro de 24 horas ativos avaliados abaixo de {}{}. As moedas de negociação suspensas não podem ser trocadas. A taxa de troca provisória é de {}.",
    "small_exchange_tip-1": "*Dica: O preço de mercado muda em tempo real, considere o preço real. As moedas de negociação suspensas não podem ser trocadas. A taxa de troca provisória é de {0}%.",
    "small_exchange_valuation": "Avaliação",
    "small_exchange_valuation-1": "Avaliação em BTC",
    "smallest_unit_price": "O preço unitário do PUSH é menor que o preço mínimo {}",
    "so adjusted to": "de múltiplos inteiros será ajustado automaticamente para",
    "sold_out": "Indisponível",
    "start time": "Hora de início",
    "start_date": "Data de início",
    "start_time": "Hora de início",
    "statistics_report": "Relatório estatístico",
    "status": "Status",
    "submit": "Enviar vídeo",
    "submitBtn": "Enviar",
    "succeed": "Sucesso",
    "successful_invitation": "Convite bem-sucedido",
    "successfully_received": "Recebido com sucesso",
    "successfully_set": "Definido com sucesso",
    "super_partner": "Super parceiro",
    "switch_account": "Trocar conta",
    "system_top_up": "Recarga do sistema",
    "system_unlock": "Desbloqueio do sistema",
    "tab_assets": "Ativos",
    "tab_home": "Início",
    "tab_markets": "Mercados",
    "tab_swaps": "Contratos",
    "tab_trade": "Negociação",
    "taking_pictures": "Tirando fotos",
    "telegraph": "Grupo do Telegram:",
    "temporary_not_online_contact_service": "Não disponível no momento, por favor, contate o serviço ao cliente",
    "the_day": "No dia",
    "the_event_is_over": "O evento terminou",
    "the_latest_price": "Último preço",
    "the_password_is_correct": "A senha está correta",
    "theme": "Tema",
    "this_month": "Este mês",
    "this_week": "Esta semana",
    "ticket": "Bilhete",
    "time_of_payment": "Hora do pagamento",
    "time_the_input": "{0}h sem precisar inserir a senha de transação",
    "timeout": "Tempo esgotado",
    "timeout_affirm": "Não confirmado dentro do tempo",
    "timeout_deal": "Transação automática após tempo esgotado",
    "timeout_to_cancel": "Pagamento expirado, cancelamento automático",
    "to_confirm": "Confirmar",
    "total_buyer": "Total de compradores",
    "total_count": "Total",
    "total_exchange": "Total de trocas rápidas",
    "total_lock_amount": "Total bloqueado",
    "total_money": "Total de dinheiro",
    "total_return": "Retorno total previsto",
    "trade_fee": "Taxa da negociação",
    "trade_type": "Tipo de negociação",
    "trading_currency": "Moeda de negociação",
    "trading_fiat_currency": "Moeda fiduciária de negociação",
    "trading_instructions": "Instruções de negociação",
    "trading_on": "Negociação em",
    "trading_pair_abbreviation": "Por favor, insira a abreviação do par de negociação",
    "trading_this_month": "Negociações deste mês",
    "trading_volume": "Volume de negociação",
    "transaction_description": "Descrição da transação",
    "transaction_password": "Senha de transação",
    "transaction_price": "Preço da transação",
    "transfer_number": "Quantidade a ser transferida",
    "try_it_now": "Experimente agora",
    "type": "Tipo",
    "unable_to_access_album": "O dispositivo atual não suporta acesso ao álbum",
    "unaccalimed": "Não resgatado",
    "unavailable": "Indisponível",
    "under_review": "Em revisão",
    "univalence": "Preço unitário",
    "unlock_details": "Detalhes do desbloqueio",
    "unlock_mode": "Modo de desbloqueio",
    "unlock_period": "Período de desbloqueio",
    "unlock_plan": "Plano de desbloqueio",
    "unlock_plan_unlock": "Desbloquear",
    "unlock_record": "Registro de desbloqueio",
    "unlock_time": "Hora de desbloqueio",
    "unlocked": "Desbloqueado",
    "unredeemed": "Não resgatado",
    "unverified": "Não verificado",
    "up_to_three": "Até três ativados",
    "upgrade": "Atualizar",
    "upload_again": "Reenviar",
    "upload_front": "Enviar frente do documento",
    "upload_identity_card": "Por favor, envie seu documento de identidade",
    "upload_image_success": "Imagem enviada com sucesso",
    "upload_information_as_required": "Por favor, envie os dados conforme solicitado acima, caso contrário, a verificação falhará",
    "upload_passport": "Por favor, envie seu passaporte",
    "upload_passport_info_picture": "Enviar foto da página de informações do passaporte",
    "upload_reverse": "Enviar verso do documento",
    "ups_and_downs": "Variação do dia",
    "ups_downs_habit": "Hábito de variação",
    "usable": "Utilizável",
    "used": "Usado",
    "user-bind-failure": "Falha ao vincular recomendador",
    "user-bind-success": "Vinculação de recomendador bem-sucedida",
    "user-collection-error": "Falha",
    "user-country-code-not-empty": "O código internacional não pode estar vazio",
    "user-disable-trade": "A conta atual está proibida de negociar, entre em contato com o atendimento ao cliente para mais detalhes!",
    "user-down-notbind": "Este usuário não pode vincular usuários subordinados",
    "user-email-fail-often": "Envio de e-mail muito frequente, por favor, reenvie após 120 segundos",
    "user-email-not-empty": "O endereço de e-mail não pode estar vazio",
    "user-email-verification-code-out": "O código de verificação do e-mail expirou, por favor, reenvie",
    "user-fail-advanced-auth": "Falha na autenticação avançada",
    "user-fail-advanced-authed": "O usuário já passou pela autenticação avançada",
    "user-fail-assetnotenough-error": "Ativos insuficientes",
    "user-fail-bank-count-error": "No máximo, três métodos de pagamento podem ser ativados",
    "user-fail-bank-inotc": "O método de pagamento possui pedidos em OTC, não pode ser desativado",
    "user-fail-cardno-repeat-error": "O número do documento já foi utilizado",
    "user-fail-email-code": "Código de verificação do e-mail incorreto",
    "user-fail-google-binded": "Google já vinculado",
    "user-fail-google-code": "Código de verificação do Google incorreto",
    "user-fail-inviter-notexist": "Recomendador não existe",
    "user-fail-login-disable": "A conta atual está proibida de fazer login, entre em contato com o atendimento ao cliente para mais detalhes!",
    "user-fail-login-lock": "Conta bloqueada",
    "user-fail-login-password": "Nome de usuário ou senha incorretos, tentativas restantes: {}",
    "user-fail-mail-binded": "E-mail já vinculado",
    "user-fail-not-realname": "Por favor, faça a autenticação de nome real primeiro",
    "user-fail-oldpassword-error": "Senha original incorreta",
    "user-fail-partner-not-found": "Comerciante não encontrado",
    "user-fail-partnernotfound": "Comerciante não encontrado",
    "user-fail-password": "Nome de usuário ou senha incorretos",
    "user-fail-password-blank": "A senha não pode estar vazia",
    "user-fail-password-blank1": "A confirmação da senha não pode estar vazia",
    "user-fail-password-repeat": "A nova senha que você definiu é igual à antiga, por favor, redefina!",
    "user-fail-password-thin": "A força da senha é muito fraca",
    "user-fail-passwword-eq-paypassword": "A senha de transação é igual à senha de login, por favor, redefina",
    "user-fail-paypassword-error": "Senha de pagamento incorreta",
    "user-fail-paypassword-notset": "Por favor, defina a senha de pagamento primeiro",
    "user-fail-paypassword-set": "Senha de pagamento já definida",
    "user-fail-phone-binded": "Número de celular já vinculado",
    "user-fail-realname-auth": "Falha na autenticação de nome real do usuário",
    "user-fail-realname-authed": "O usuário já passou pela autenticação de nome real",
    "user-fail-realname-notset": "Por favor, faça a autenticação de nome real primeiro",
    "user-fail-reg": "Falha no registro, erro do servidor",
    "user-fail-reg-mail": "Formato de e-mail inválido",
    "user-fail-reg-mail-exist": "E-mail já registrado",
    "user-fail-reg-phone-exist": "Número de celular já registrado",
    "user-fail-securitycount": "É necessário manter pelo menos um método de verificação",
    "user-fail-sms-code": "Código de verificação por SMS incorreto",
    "user-fail-sms-code-new": "Código de verificação por SMS para o novo número de celular incorreto",
    "user-fail-sms-code-old": "Código de verificação por SMS para o número de celular antigo incorreto",
    "user-fail-usernameisblank": "O nome de usuário não pode estar vazio",
    "user-id-or-nationCode—is-null": "ID do usuário ou código internacional está vazio",
    "user-login-success": "Login bem-sucedido",
    "user-mail-fail": "Falha ao enviar e-mail",
    "user-mail-success": "E-mail enviado com sucesso",
    "user-not-exist": "Este usuário não existe",
    "user-notopen-success": "Ainda não ativado",
    "user-phone-not-empty": "O número de celular não pode estar vazio",
    "user-sms-fail": "Falha ao enviar, erro no servidor",
    "user-sms-fail-often": "Envio de SMS muito frequente",
    "user-sms-success": "SMS enviado com sucesso",
    "user-sms-verification-code-out": "Código de verificação expirado, por favor, reenvie",
    "user-success-advanced-auth": "Autenticação avançada bem-sucedida",
    "user-success-realname-auth": "Autenticação de nome real bem-sucedida",
    "user-validate-code-fail": "Código de verificação incorreto",
    "user-validate-fail": "Validação falhou",
    "user-vip-common-fail": "Falha na compra do VIP",
    "user_agreement": "Por favor, marque para concordar com o acordo de serviço do usuário",
    "user_collection_cencel_success": "Cancelado",
    "user_collection_insert_success": "Adicionado aos favoritos",
    "user_pay_disable_state": "Este número de conta foi bloqueado devido a tentativas excessivas de senha de transação, por favor, tente novamente após 2 horas",
    "user_pay_is_error": "Senha de transação incorreta, você ainda pode tentar {} vezes, após o limite, será bloqueado por 2 horas",
    "user_pay_password_not_setting": "Usuário não definiu senha de transação, por favor, defina a senha de transação primeiro",
    "user_protocol": "Acordo do usuário",
    "user_service_agreement": "Acordo de Serviço do Usuário",
    "user_vip_has_expire": "Este VIP está expirado e não pode ser renovado!",
    "user_vip_update_failure": "Este número de conta não suporta renovação e atualização, entre em contato com o atendimento ao cliente para mais detalhes!",
    "valid_identity_card": "Por favor, insira um número de documento válido",
    "valuation": "Avaliação de depósito",
    "verification_code_error": "Código de verificação incorreto",
    "verification_failed_001": "Validação falhou",
    "verification_finish": "Validação concluída",
    "version_updating": "Atualização de versão",
    "versions": "Versões",
    "vertical_version": "Versão vertical",
    "view_activity": "Ver atividade",
    "view_lock": "Ver bloqueio",
    "volamount": "O comprador deve ter completado {} transações",
    "volamount_sell": "O vendedor deve ter completado {} transações",
    "vote": "Votar",
    "vote_add_coin": "Votar para adicionar moeda",
    "vote_return": "Retornar",
    "voting_coin": "Moeda de votação",
    "voting_number": "Número de votos",
    "voting_time": "Hora da votação",
    "wait_buyer_payment": "Aguardando pagamento do comprador",
    "wait_for_payment": "Aguardando pagamento",
    "waiting_for_receiving": "Aguardando confirmação de recebimento do vendedor",
    "warm_prompt": "Dica amigável",
    "warning_setTitle": "Configuração de alerta",
    "wechat_account": "Conta WeChat",
    "wechat_upload": "*Método para upload de código QR no WeChat: Abra a página inicial do WeChat > Meu > Carteira > Receber pagamentos > Salvar imagem e faça upload do código de recebimento que está na galeria do celular.",
    "week": "Semana",
    "welcome": "Bem-vindo ao BitMatrix",
    "welcome_to": "Bem-vindo ao",
    "winning_record": "Registro de sorteio",
    "withdrawal_amount": "Limite de retirada diário",
    "withdrawal_charge": "Taxa de retirada",
    "xilian": "Western Union",
    "yesterday_income": "Rendimento de ontem",
    "you_have_selected": "Você selecionou",
    "Your closing number": "Sua quantidade de fechamento é menor que o mínimo de posição!",
    "Your have pending Cross orders": "Existem ordens em aberto de margem cruzada, por favor, cancele todas as ordens em aberto antes de modificar o multiplicador de alavancagem",
    "Your have pending isolated orders": "Existem ordens em aberto de margem isolada, por favor, cancele todas as ordens em aberto antes de modificar o multiplicador de alavancagem",
    "Home page apps": "Aplicativos da página inicial",
    "Home_Edit": "Editar",
    "recently_deal01": "Transações",
    "depth01": "Profundidade",
    "depth02": "Gráfico de profundidade",
    "discount_currency_introduction01": "Introdução à moeda",
    "Info001": "Introdução",
    "Avg cost": "Custo médio",
    "Long position": "Posição longa",
    "Short position": "Posição curta",
    "Hold on": "Aguarde",
    "Done": "Concluído",
    "new_version": "Nova versão disponível",
    "Announcement": "Anúncio",
    "View details": "Ver detalhes",
    "Option Assets": "Ativos de opções",
    "Option Account": "Conta de opções",
    "Option": "Opção",
    "1M": "1 min",
    "5M": "5 min",
    "15M": "15 min",
    "30M": "30 min",
    "1H": "1 hora",
    "Delivery Time": "Tempo até a entrega",
    "Call": "Alta",
    "Put": "Baixa",
    "Draw": "Neutro",
    "Draw2": "Neutro",
    "Call1": "Alta",
    "Put1": "Baixa",
    "Draw1": "Neutro",
    "Buy-2": "Comprar",
    "Rate of return": "Taxa de retorno",
    "Change": "Variação",
    "Buy Option": "Comprar opção",
    "Awaiting": "Aguardando entrega",
    "Delivery": "Minhas entregas",
    "Checkout": "Registro de entregas",
    "Front": "Período atual",
    "Back": "Próximo período",
    "Coming soon": "Em breve",
    "delivery settlement": "Liquidação em andamento",
    "Ongoing": "Comprando",
    "Pending purchase": "Aguardando compra",
    "Billing Amount": "Quantidade a liquidar",
    "Amount": "Quantidade de compra",
    "Delivery result": "Resultado da entrega",
    "Delivery time": "Hora da entrega",
    "Up": "Alta",
    "Down": "Baixa",
    "lookUp": "Expectativa de alta",
    "lookDown": "Expectativa de baixa",
    "Delivery details": "Detalhes da entrega",
    "Index Composition": "Composição do índice",
    "Exchange": "Bolsa",
    "Change2": "Variação",
    "Weights": "Pesos",
    "Order number": "Número do pedido",
    "Option Session": "Sessão de Opções",
    "Buy Time": "Hora da compra",
    "Opening time": "Hora de abertura",
    "Bet Type": "Tipo de aposta",
    "Delivery Price": "Preço de entrega",
    "Settlement Currency": "Moeda de liquidação",
    "K-line": "Gráfico K",
    "UpSelect": "Seleção de alta",
    "DownSelect": "Seleção de baixa",
    "Front has ended, please place another order": "Esta rodada terminou, por favor, faça um novo pedido",
    "Successfully ordered": "Pedido feito com sucesso",
    "Insufficient balance": "Saldo insuficiente",
    "Quantity purchased must be greater than the minimum limit": "A quantidade comprada deve ser maior que o limite mínimo",
    "Quantity purchased must be less than the maximum limit": "A quantidade comprada deve ser menor que o limite máximo",
    "The current time is not in the purchase time range": "O horário atual não está dentro do intervalo de compra",
    "expected": "Rendimento esperado",
    "To_a_game": "Jogar novamente",
    "Settlement Result": "Resultado da liquidação",
    "Delivery-1": "Entrega",
    "Ups and downs": "Variação",
    "duration": "Duração",
    "Option funds daybook": "Extrato de fundos de opções",
    "Open innovative options trading": "Ativar negociação de opções inovadoras",
    "Confirm Open": "Confirmar ativação",
    "Option Agree Content": "Para proteger seus direitos, leia atentamente o 'Acordo de Uso da Negociação de Opções Inovadoras' antes de ativar as opções",
    "Option Agree Content footer": "Acordo de Uso da Negociação de Opções Inovadoras",
    "Option Agree Content header": "Para proteger seus direitos, leia atentamente antes de ativar",
    "Service upgrade, please try again later!": "Atualização de serviço, por favor, tente novamente mais tarde!",
    "month": "Mês",
    "Invitation": "Convite",
    "Markets": "Mercados",
    "Swaps": "Contratos",
    "Trade": "Negociação",
    "Trade-1": "Ir para negociação",
    "Assets": "Ativos",
    "Buy": "Comprar moeda",
    "Help": "Ajuda",
    "Service": "Atendimento ao cliente",
    "Activity": "Atividade",
    "Communtiy": "Comunidade",
    "No search results": "Nenhum resultado encontrado",
    "The option account asset is 0, please transfer from the wallet account to the option account first.": "O ativo da conta de opções é 0, por favor, transfira do saldo da conta para a conta de opções primeiro.",
    "Perpetual account assets are 0, please transfer from wallet account to perpetual account first.": "Os ativos da conta perpétua são 0, por favor, transfira do saldo da conta para a conta perpétua primeiro.",
    "Financing": "Finanças",
    "Load": "Carregar",
    "Replay001": "Repetir",
    "Constellation Program": "Apresentação: Programa de Constellation",
    "Constellation Program2": "Apresentação: Finanças Constellation",
    "rate rankings": "Ranking de taxa de sucesso das instituições",
    "rate rankings2": "Instituições de investimento recomendadas desta semana",
    "CXT Quotes": "Cotações",
    "Digital asset trading tool service platform": "Plataforma de serviços de ferramentas de negociação de ativos digitais",
    "Service_home": "Serviços",
    "Strategic cooperation": "Cooperação estratégica",
    "Financing assets": "Ativos financeiros",
    "Remove 0 from the first number when registering!": "Remova o 0 do primeiro número ao registrar!",
    "Are you sure you want to quit": "Você tem certeza de que deseja sair?",
    "Opening Price": "Preço de abertura",
    "Closing Price": "Preço de fechamento",
    "Formula interpretation": "Interpretação da fórmula",
    "Note: If the input box is left blank": "Nota: Deixar o campo vazio significa que os dados da bolsa atual não foram obtidos",
    "Option Sessions": "Sessões de opções",
    "Result": "Resultado",
    "When data for an exchange is not available": "*Quando os dados de uma bolsa não estão disponíveis, seu peso é definido como 0%. Quando a fonte de dados válidos ≤ 2, é definido como empate",
    "Assume that the index is derived from the opening prices of five exchanges": "Suponha que o índice derive dos preços de abertura de cinco bolsas: A, B, C, D, E; e os preços de fechamento: A`, B`, C`, D`, E`",
    "The proportion of those five exchanges are": "Os pesos correspondentes das cinco bolsas são: a%, b%, c%, d%, e%",
    "Stream bureau": "Empate",
    "(valid data source ≤ 2)": "（Fonte de dados válida ≤ 2）",
    "Option calculator": "Calculadora de opções",
    "Round selection": "Seleção de rodada",
    "Select option": "Selecionar opção",
    "Average opening price": "Preço médio de abertura",
    "Average closing price": "Preço médio de fechamento",
    "Calculate now": "Calcular agora",
    "Calculation Results": "Resultados do cálculo",
    "Calculating Time": "Tempo de cálculo",
    "Management": "Gestão",
    "Other_home": "Outros",
    "All applications": "Todos os aplicativos",
    "Boon": "Benefícios",
    "Coin_Address_draw": "Endereço de retirada",
    "Congratulations": "Parabéns",
    "Profit": "Lucro",
    "Gainers": "Ganho",
    "Drop": "Queda",
    "Order Price": "Preço de compra",
    "Order Price1": "Preço de compra",
    "Sec Swap": "Contrato de segundos",
    "Expected Return": "Taxa de retorno esperada",
    "Sec Swap Assets": "Ativos do contrato de segundos",
    "Sec Swap Bill": "Extrato do contrato de segundos",
    "Sec Swap Account": "Conta de contrato de segundos",
    "Go Login": "Ir para login",
    "NSO Session": "Sessão NSO",
    "Wallet Value1": "Ativos da carteira",
    "Spot Value1": "Ativos de moeda a moeda",
    "PERP Value1": "Ativos perpétuos",
    "Option Value1": "Ativos de opções",
    "Fiat Value1": "Carteira OTC",
    "Pledge_Amount": "Quantidade de penhor",
    "total_return2": "Retorno acumulado",
    "Violation_Loss": "Multa por violação",
    "Cycle": "Ciclo",
    "Locked2": "Bloqueado",
    "Time_Left": "Tempo restante",
    "Cancel_Pledge": "Cancelar penhor",
    "countDown_day": "Dias",
    "Entrust2": "Ordens de confiança",
    "All2": "Todos",
    "Ongoing2": "Em andamento",
    "Near2": "Em breve",
    "Ended2": "Terminou",
    "Cancel the pledge?": "Tem certeza de que deseja cancelar o penhor?",
    "Completed2": "Concluído",
    "Cancelled2": "Cancelado",
    "Lock-up mining": "Mineração com bloqueio",
    "Rules Description": "Descrição das regras",
    "Today Expected": "Rendimento esperado para hoje",
    "Order Escrow": "Pedidos em custódia",
    "Minimum": "Limite mínimo",
    "Daily Return": "Taxa de retorno diária",
    "Buy-3": "Ir para compra",
    "Minimum Amount": "Valor mínimo do penhor",
    "Lockup Period": "Período de bloqueio",
    "Confirm Pledge": "Confirmar penhor",
    "Buy-4": "Comprar",
    "Conversion Starter": "Lançamento da conversão",
    "Listing": "Listagem",
    "Total Target": "Meta total",
    "Conversion Ratio": "Taxa de conversão",
    "Single Limited": "Limite por pessoa",
    "Participate Order": "Participar da subscrição",
    "Fundraised": "Fundos arrecadados",
    "Current Progress": "Progresso atual",
    "Time Left": "Tempo restante",
    "Start Time": "Hora de início",
    "End Time": "Hora de término",
    "Conversion Amount": "Quantidade de subscrição",
    "Conversion Now": "Subscrição imediata",
    "Conditions": "Condições de participação",
    "Preheating": "Pré-aquecendo",
    "Records": "Registros de subscrição",
    "Select": "Selecionar",
    "To": "Para",
    "Active Currency": "Moeda ativa",
    "Amount-3": "Montante",
    "Conversion Time": "Hora de subscrição",
    "Arrival Time": "Hora de chegada",
    "Not Arrived": "Ainda não chegou",
    "Hours-1": "Horas",
    "Risk warning: It is displayed": "Aviso de risco: Este é um ativo inovador com características de ativos digitais. Por favor, avalie cuidadosamente o preço e tome decisões de investimento com cautela!",
    "Week-1": "Semana",
    "S-1": "Segundos",
    "minutes-2": "Minutos",
    "You must pass basic KYC": "O usuário deve passar pela verificação de identidade básica",
    "You must pass advanced KYC": "O usuário deve passar pela verificação de identidade avançada",
    "Account order exceeds": "A posição da conta excede",
    "Suspend": "Suspender negociação",
    "Successfully": "Subscrição bem-sucedida",
    "Ongoing-1": "Subscrição em andamento",
    "Failed": "Subscrição falhou",
    "New_Token_Subscribe": "Subscrição de novo token",
    "More_awaits_you": "Mais riqueza espera por você",
    "Distance Start": "Tempo restante até o início",
    "day-2": "Dias",
    "Must Eligible": "Deve atender às condições de participação",
    "Custodial funds": "Fundos em custódia",
    "Default Ratio": "Taxa de inadimplência",
    "Wallet Available": "Saldo disponível na carteira",
    "Done-2": "Concluído",
    "Eligible Amount": "Quantidade aprovada",
    "Eligible Price": "Valor aprovado",
    "New Token Subscribe": "Subscrição de novo token",
    "Subscribe Now": "Subscrição agora",
    "Review Time": "Tempo de revisão",
    "Review Passed": "Revisão aprovada",
    "Review Failed": "Revisão não aprovada",
    "Not Reviewed": "Ainda não revisado",
    "Denial Reason": "Motivo da recusa",
    "If not received, please contact service": "Se não chegar, entre em contato com o atendimento ao cliente!",
    "Returned to wallet": "Retornado à conta da carteira",
    "From wallet assets": "Disponível da carteira",
    "Day-3": "Dias",
    "Sec Swap asset is 0, please transfer from wallet account to Sec Swap first": "Os ativos da conta de contrato de segundos são 0, por favor, transfira da conta da carteira para a conta de contrato de segundos primeiro.",
    "Settlement": "Liquidação em andamento",
    "Completed": "Liquidação concluída",
    "Password Setting": "Configuração de senha de login",
    "Set password and email": "Defina a senha de login e o e-mail",
    "Email Settings": "Configurações de e-mail",
    "First-time registered users mus": "Usuários registrados pela primeira vez devem completar a configuração de senha de login e a configuração de e-mail",
    "Driver ID": "Carteira de motorista",
    "Preview": "Consultar preços",
    "cancelTime": "Hora de cancelamento",
    "Total_assets_valuation": "Avaliação total dos ativos",
    "Invite Friends": "Convidar amigos",
    "RedGreen": "Vermelho alta, verde baixa",
    "GreenRed": "Verde alta, vermelho baixa",
    "Color Preference": "Preferência de cor",
    "Fuzzy Information": "Informação confusa",
    "user_bind_xilain_trade_pwd": "Por favor, insira a senha de negociação",
    "login_please_input1": "Por favor, insira o código de verificação por SMS",
    "user_please_input9": "Por favor, insira o código de verificação do Google",
    "user_link_name": "Nome da cadeia",
    "user_coin_bring_together": "Consolidar",
    "chongbi0": "Instruções para depósito:",
    "chongbi1": "1. É proibido depositar ativos que não sejam {0}; qualquer depósito de ativos que não sejam {1} não poderá ser recuperado.",
    "chongbi2": "2. Por favor, envie a quantidade de {1} que você deseja depositar para este endereço através do cliente {0} ou da carteira online. Após o envio, o sistema irá automaticamente creditar a quantia após {2} confirmações nesta transação, por favor, aguarde pacientemente.",
    "chongbi3": "3. O mesmo endereço pode ser usado para depósitos múltiplos, sem afetar o crédito.",
    "chongbi4": "4. O valor mínimo do depósito é {0}.",
    "currency_withdrawal_rate_rules": "Regras da taxa de retirada para {0}:",
    "currency_withdrawal_rate_rules1": "1. Para a segurança dos fundos dos usuários, a plataforma pode confirmar sua operação de retirada por telefone, por favor, esteja atento para atender;",
    "currency_withdrawal_rate_rules2": "2. O depósito de {0} é permitido após {1} confirmações;",
    "currency_withdrawal_rate_rules3": "3. O valor disponível para retirada ≤ ativos disponíveis da conta - ativos digitais não confirmados;",
    "currency_withdrawal_rate_rules4": "4. Máximo de {0} retiradas por dia, com um total máximo de {1}.",
    "currency_transaction_password": "Senha",
    "long_press_to_save_to_album": "Pressione e segure para salvar no álbum",
    "the_fee_is_outside_the_range": "A taxa não está dentro do intervalo",
    "start_and_end_date": "Data de início e término",
    "The_withdrawal_amount_cannot_be_less": "A quantidade de retirada não pode ser menor que {0}",
    "withdrawal_details": "Detalhes da retirada",
    "RegisterRemove0": "Remova o 0 do primeiro número ao registrar",
    "closeLockUpPopup": "Cancelar o penhor resultará em uma multa de {0} dias, o valor da multa é '{1}{2}', e o valor principal a ser creditado é '{3}{4}'.",
    "LockUpDuan": "Mineração com bloqueio",
    "NewTokenSubscribe": "Subscrição de novo token",
    "MoreWealthAwaitsYou": "Mais riqueza espera por você",
    "TopGainers": "Top ganhadores",
    "Newest": "Novos tokens",
    "Hot": "Mais populares",
    "DatChange": "Mudança diária",

    "SetPassword": "Definir senha",
    "comm_password": "Senha de negociação",
    "unraise_recovering_password": "Após recuperar a senha, não é possível retirar por 1 hora",
    "PDleaseUseSafariBrowser": "Por favor, use o navegador Safari",
    "ADddToDesktop": "Adicionar BitMatrix à área de trabalho",
    "CDlickBelow": "Clique abaixo",
    "SDelectAddToHomeScreen": "E escolha adicionar à tela inicial",
    "ADddToDesktop2": "Adicionar BitMatrix à área de trabalho",
    "CDommonbrowserAddMethod": "Método de adição para navegadores comuns",
    "user_senior_text1": "Atenção",
    "user_senior_text2": "O tamanho do arquivo da foto não pode exceder 5M! O formato do arquivo deve ser jpg, bmp, png, etc.! ",
    "user_senior_text3": "Por favor, certifique-se de que a foto não tenha marcas d'água, manchas, que as informações de identidade estejam claras, o rosto esteja completo e que não seja uma foto de texto invertido! Não edite a foto!",
    "user_senior_text4": "Segurando",
    "user_senior_text5": "foto do documento de identidade",
    "user_senior_text6": ": Você precisa segurar com uma mão seu",
    "user_senior_text7": "data do dia",
    "user_senior_text8": "e",
    "user_senior_text9": "UID",
    "user_senior_text10": "em um papel e tirar uma foto que não seja espelhada.",
    "user_senior_text11": ", com a outra mão segurando um papel em branco com sua escrita:",
    "user_senior_text12": "Por favor, escreva à mão o seguinte conteúdo, conteúdos que não corresponderem não serão aprovados:",
    "user_senior_text13": "“Eu estou ciente e aceito os riscos de investimento”",
    "user_senior_text14": ".",
    "user_ID_card": "Documento de identidade",
    "user_passport": "Passaporte",
    "Driver_ID": "Carteira de motorista",
    "user_senior_upload3": "Enviar foto segurando documento + UID + data",
    "ServiceUpgrade": "Atualização de serviço em andamento",
    "ReferencePrice": "Preço de referência",
    "referenceTips": "Não é o preço final de transação, apenas para referência",
    "NewCoinsLaunch": "Lançamento de novos tokens",
    "CurrentlyPurchased": "Subscrição atual",
    "TotalMarketValue": "Valor total de mercado",
    "IEORatio": "Taxa de subscrição",
    "CurrencyIntroduction": "Introdução à moeda",
    "ConvertNow": "Subscrição imediata",
    "RemainingAmount": "Montante restante",
    "referenceTips": "Com base no preço de compra real",
    "ServiceUpgrade": "Atualização de serviço em andamento",
    "LastDays7": "Últimos 7 dias",
    "LastDays30": "Últimos 30 dias",
    "LastDays90": "Últimos 90 dias",
    "LockUpTips": "Nota: O restante dos ativos não aprovados {0}{1} já foi devolvido à sua conta da carteira.",
    "PromotionalMining": "Mineração promocional",

    "hide_coin": "Ocultar moedas menores que {0} BTC",
    "GoldAssets": "Ativos em Ouro",
    "ModifyEmail": "Modificar e-mail",
    "NewEmail": "Novo e-mail",
    "NewEmailAddress": "Insira o novo endereço de e-mail",
    "VerifyNewEmail": "Verificar novo e-mail",
    "EnterNewEmailCode": "Insira o código de verificação do novo e-mail",
    "VerifyOldEmail": "Verificar e-mail antigo",
    "EnterOldEmailCode": "Insira o código de verificação do e-mail antigo",
    "VerifyLoginPassword": "Verificar senha de login",
    "EnterLoginPassword": "Insira a senha de login",
    "loan": "Centro de Empréstimos",
    "loanText": "Oferecemos um serviço seguro e confiável de empréstimos de criptomoedas, atendendo a vários propósitos com maior liquidez, sem a necessidade de vender seus ativos de criptomoeda existentes.",
    "loanQuery": "Consulta de empréstimos",
    "Stop_surplus_tips": "Quando o preço de mercado atingir {}, uma ordem de realização de lucros será acionada, com lucro estimado de {} após a execução",
    "Stop_loss_tips": "Quando o preço de mercado atingir {}, uma ordem de stop loss será acionada, com perda estimada de {} após a execução",
    "Stop_surplus_tips1": "Quando o preço de mercado atingir {}, uma ordem de realização de lucros será acionada, com perda estimada de {} após a execução",
    "Stop_loss_tips1": "Quando o preço de mercado atingir {}, uma ordem de stop loss será acionada, com lucro estimado de {} após a execução",
    "Countdown": "Contagem regressiva",
    "contract_After_adjustment": "Após o ajuste, serão transferidos {} USDT da conta como margem adicional",
    "contract_subject_to": "(Com base no valor realmente transferido)",
    "contract_the_used_margin": "Após o ajuste, a margem utilizada diminuirá em {} USDT, a parte reduzida permanecerá na posição,",
    "contract_transferred_to": "pode ser transferida para o saldo da conta",
    "contract_After_adjustment_q": "Após o ajuste, a margem ocupada pela posição aumentará em {} USDT",
    "contract_the_used_margin_q": "Após o ajuste, a margem ocupada pela posição diminuirá em {} USDT",
    "Please1": "Por favor, insira o preço de disparo da realização de lucros",
    "Please2": "Por favor, insira o preço da ordem de realização de lucros",
    "Please3": "Por favor, insira o preço de disparo do stop loss",
    "Please4": "Por favor, insira o preço da ordem de stop loss",
    "Stop profit6": "O preço de disparo da realização de lucros deve ser maior que o preço de execução mais recente",
    "stop loss7": "O preço de disparo do stop loss deve ser menor que o preço de execução mais recente",
    "Please8": "Por favor, insira a quantidade",
    "Please9": "Não há quantidade disponível para liquidação",
    "CountdownTime": "Tempo de contagem regressiva",
    "h1": "Solicitar parceria",
    "h2": "Ranking de recompensas",
    "h3": "Torne-se um parceiro e ganhe",
    "h4": "Condições de upgrade",
    "h5": "Para comprar o nível inicial de parceiro, é necessário pagar",
    "h6": "Saldo atual",
    "h7": "Parceiro fundador",
    "h8": "Recompensa",
    "h9": "Atualizar agora",
    "h10": "Já é o nível mais alto",
    "h11": "Bônus de recompensas",
    "h12": "Ranking de recompensas",
    "h13": "Número de membros da equipe",
    "h14": "Registro de recompensas",
    "h15": "Convidados",
    "h16": "Liquidado",
    "h17": "Não liquidado",
    "h18": "Possui subordinados",
    "h19": "Necessário pagar",
    "h20": "Atualizar",
    "h21": "Regras de recompensas",
    "h22": "Ranking",
    "h23": "Usuário",
    "h24": "Recompensa de recompensas",
    "h25": "Nível atual pode obter",
    "h26": "Obter todas as comissões convertidas em um valor acumulado em USDT.",
    "h27": "Necessário referir {} usuários comuns",
    "h28": "Já referiu {} usuários comuns válidos",
    "h29": "Necessário que o número da equipe atinja {} pessoas",
    "h30": "Número da equipe {} pessoas",
    "Stop profit10": "O preço de disparo da realização de lucros deve ser menor que o preço de execução mais recente",
    "stop loss10": "O preço de disparo do stop loss deve ser maior que o preço de execução mais recente",
    "Earn": "Investimentos",
    "Simple": "Ganhe dinheiro e gaste sem problemas",
    "Low1": "Baixo risco, retorno estável",
    "Low2": "Baixo risco, retorno seguro, resgates a qualquer momento",
    "CurrentAccount": "Conta de Saldo",
    "Stable": "Valorização estável, ferramenta de crescimento de riqueza",
    "EarnLocked": "Investimentos a Prazo",
    "Searchpopular": "Investimentos fáceis, desfrute dos lucros a qualquer momento",
    "Annualized": "Taxa anualizada de retorno {}%, ativos seguros, retorno elevado",
    "CoinsLC": "Moedas",
    "PurchaseLC": "Valor da compra",
    "PurchaseLC2": "({} valor mínimo)",
    "Maximum": "Máximo",
    "EstimatedInterest": "Juros estimados",
    "RuleLC": "Regras",
    "SubscriptionTime": "Tempo de subscrição",
    "Interestaccrualtime": "Tempo de acumulação de juros",
    "RevenueTime": "Tempo de retorno",
    "RedemptionCycle": "Ciclo de resgate",
    "ReturnNow": "Retornar agora",
    "BuyLC": "Comprar",
    "PurchaseSuccessful": "Compra bem-sucedida",
    "DepositLC": "Depositar moedas",
    "Yesterday": "Ganhos de ontem",
    "TotalLC": "Total de depósitos",
    "TotalReturn": "Retorno acumulado",
    "DepositValuation": "Valoração de depósitos",
    "DepositDetail": "Detalhes do depósito",
    "RevenueDetails": "Detalhes do retorno",
    "DepositType": "Tipo de depósito",
    "ReturnMethod": "Método de retorno",
    "DepositAmount": "Quantidade depositada",
    "APR": "Taxa anualizada",
    "Expected": "Retorno estimado",
    "DepositCycle": "Ciclo de depósito",
    "CreationTime": "Hora de criação",
    "EarnProfit": "Juros do período atual",
    "DepositCost": "Capital do depósito",
    "TotalRelease": "Quantidade total liberada",
    "ReleaseTime": "Hora de liberação",
    "Flexible": "Flexível",
    "Fixed": "A prazo",
    "Freeze": "Congelar",
    "Completed3": "Concluído",
    "Cancelled": "Cancelado",
    "DefaultSettlement": "Liquidação por inadimplência",
    "PurchaseAmount": "Valor da compra (sem limite)",
    "RetrieveDeposited": "Retornar moedas depositadas",
    "PreviewConversion": "Visualizar resultados da conversão",
    "ToEnsureThe": "Para garantir a segurança da sua conta e atender a requisitos de maior limite de transação, precisamos realizar uma verificação de identidade avançada.",
    "ToVerification": "Clique no botão para contatar o suporte para verificação.",
    "ToContact": "Contatar suporte para verificação agora",
    contract_entrust_failure_0080 : 'O valor do pedido não pode ser inferior a 100 USDT.',
    "comm_nickname_rule1": "Não pode exceder 7 caracteres chineses ou 15 letras do alfabeto inglês.",
    "comm_nickname_rule2": "Não pode conter espaços ou caracteres especiais.",
    "otc_upload_picture3":"As imagens carregadas devem ser nos formatos jpeg/jpg/png/bmp!",
    "CertificationDifficulties":'Há um problema com a verificação?',
}