<template>
  <div class="tradeLine container">
    <van-sticky>
      <div class="topBox">
        <div class="leftBox">
          <img src="../../assets/img/public/ic_back.png" class="backIcon" alt="" @click="closePage">
          <div class="shu"></div>
          <img src="../../assets/img/trade/img2.png" alt="" class="selectBorder" @click="tradeListShow = true">
          <div class="symbolName">
            {{symbol.tradeName}}
          </div>
        </div>
        <img v-if="!isCollectionFlag" @click="collectionCoin" src="../../assets/img/trade/img7.png" alt="" class="rightIcon">
        <img v-if="isCollectionFlag" @click="collectionCoin" src="../../assets/img/trade/star.png" alt="" class="rightIcon">
      </div>

      <div class="mainContant">
        <div class="left">
          <p class="bigTit" v-bind:class="[rose< 0 ? 'down': 'up']">{{latestDone}}</p>
          <div class="smallBox">
            <div class="much">≈{{latestDone}} USD</div>
            <div class="rose" v-bind:class="[rose< 0 ? 'down': 'up']">{{cutOutPointLength(rose,2)}}%</div>
            <img class="upIcon" v-if="rose <0" :src="downIcon[timeline]" alt="">
            <img class="upIcon" v-if="rose >0" :src="upIcon[timeline]" alt="">
            <img class="upIcon" v-if="rose == 0" src="../../assets/img/Kline/whiteRose.png" alt="">
          </div>
        </div>
        <div class="right">
          <div class="itemBox">
            <p class="tit">
              {{$t('M.24h High')}}
            </p>
            <p class="text">
              {{tradeData24.high}}
            </p>
          </div>
          <div class="itemBox">
            <p class="tit">
              {{ $t('24h Vol') }}({{ symbol.nowCoin }})
            </p>
            <p class="text">
              {{tradeData24.vol24hour}}
            </p>
          </div>
          <div class="itemBox itemBox1">
            <p class="tit">
              {{$t('M.24h Low')}}
            </p>
            <p class="text">
              {{tradeData24.low}}
            </p>
          </div>
        </div>
      </div>

    </van-sticky>

    <!-- K线图 -->
    <Kline ref="kLineDom" :symbol="symbol" @changeTimeArea='changeTimeArea'></Kline>

    <van-tabs v-model="active" class="tabHome" color="#00b897" line-width="16px" line-height="3px" title-active-color="#00b897">
      <van-tab :title="$t('M.depth')">
        <div class="dealItem dealTitle">
          <div class="time alignLeft">{{$t('M.quantity')}}({{symbol.nowCoin}})</div>
          <div class="direction">{{$t('M.price')}}({{symbol.targetCoin}})</div>
          <div class="num alignRight">{{$t('M.quantity')}}({{symbol.nowCoin}})</div>
        </div>
        <div class="dish">
          <div class="buys">
            <div class="item" v-for="(item,dix) in showBuyssList">
              <div class="num">{{item.amount}}</div>
              <div class="price">{{item.price}}</div>
              <div class="color-sell-bg" v-if="item.price != '--'" :style="`width:${item.progressiveTotal/buysProgressiveTotal*100}%`"></div>
            </div>
          </div>
          <div class="sells">
            <div class="item" v-for="(item,dix) in showSellsList">
              <div class="price">{{item.price}}</div>
              <div class="num">{{item.amount}}</div>
              <div class="color-sell-bg" v-if="item.price != '--'" :style="`width:${item.progressiveTotal/buysProgressiveTotal*100}%`"></div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab :title="$t('M.tab_trade')">
        <div class="dealItem dealTitle">
          <div class="time alignLeft">{{$t('M.bb_time')}}</div>
          <div class="direction">{{$t('M.direction')}}</div>
          <div class="price">{{$t('M.price')}}({{symbol.targetCoin}})</div>
          <div class="num alignRight">{{$t('M.quantity')}}({{symbol.nowCoin}})</div>
        </div>
        <div class="dealItem" v-for="(item,idx) in disTradeList" :key="idx">
          <div class="time alignLeft">{{$moment(new Date(Number(item.time))).format('HH:mm:ss')}}</div>
          <div class="direction" v-bind:class="item.direction == 'SELL' ? 'down':'up'">{{item.direction =="SELL" ? $t('M.sell'):$t('M.buy')}}</div>
          <div class="price" v-bind:class="item.direction == 'SELL' ? 'down':'up'">{{item.price}}</div>
          <div class="num alignRight">{{item.amount}}</div>
        </div>
      </van-tab>
      <van-tab :title="$t('M.Info001')">
        <div style="padding:5px 10px 90px 10px" v-html="htmlData"></div>
      </van-tab>
    </van-tabs>

    <TradeLeftPopup :tradeListShow="tradeListShow" :autoGetData="false" @isCollection="isCollection" @closeTradeList="closeTradeList"
      @changeSymbol="changeSymbol" />

    <div class="btnBox">
      <div class="box buy" @click="chageType(1)">{{$t('M.buy')}}</div>
      <div class="box sell" @click="chageType(2)">{{$t('M.sell')}}</div>
    </div>

  </div>
</template>

<script>
import TradeLeftPopup from "./tradeComponet/tradeLeftPopup.vue";
import Kline from "./tradeComponet/Kline.vue";
import {mapGetters} from 'vuex'
import {Toast} from 'vant';


export default {
  components: { TradeLeftPopup, Kline, Kline },
  computed: {
    ...mapGetters([
      'lang', 'isLogin'
    ]),

  },
  data() {
    return {
      upIcon: [
        require("@/assets/img/Kline/green_rose1.png"),
        require("@/assets/img/Kline/green_rose2.png"),
        require("@/assets/img/Kline/green_rose3.png"),
        require("@/assets/img/Kline/green_rose4.png"),
      ],
      downIcon: [
        require("@/assets/img/Kline/red_drop1.png"),
        require("@/assets/img/Kline/red_drop2.png"),
        require("@/assets/img/Kline/red_drop3.png"),
        require("@/assets/img/Kline/red_drop4.png"),
      ],
      timeline: 0,
      active: 0,
      tradeListShow: false,  //交易对列表是否显示
      isCollectionFlag: false,
      symbol: {},
      timeObj: null,
      dishDefault: {}, //基本数据
      latestDone: 0,//盘口最后的价格
      disTradeList: [], // 底部挂单买卖数据
      rose: "",//涨跌幅
      tradeData24: {
        high: "",
        low: "",
        vol24hour: "",
      },
      sells: [],//卖盘
      sellsProgressiveTotal: '',
      buys: [],//买盘
      buysProgressiveTotal: '',
      htmlData: '',
      pako: require("pako"),
      selectType: 'min15',
      selectKlinePopup: false,
      selectKlinePopupType: 1,
      showMoreText: this.$t('M.more'),
      isInitPage: true,
      tradeSymbol: '',
      dishSymbol: '',
      klineSymbol: '',
      depthrenderSymbol: '',
      rate: 1,
      ratelatestDone: 0,
      showSellsList: [],
      showBuyssList: [],
      pendingDisplayTimer: null,
    }
  },
  created() {
    this.symbol.symbol = this.$route.query.symbol;
  },
  watch: {
    latestDone: function (newQuestion, oldQuestion) {
      this.ratelatestDone = this.latestDone * this.rate
    },
    sells: {
      handler(newVal) {
        this.showSellsList = newVal
      },
      deep: true
    },
    buys: {
      handler(newVal) {
        this.showBuyssList = newVal
      },
      deep: true
    },
  },
  mounted() {
    /* 动画 */
    this.timeObj = setInterval(() => {
      if (this.timeline == 3) {
        this.timeline = 0;
      } else {
        this.timeline++;
      }
    }, 280);
    this.startFakeDataGeneration()
  },
  beforeMount() {
    clearInterval(this.timeObj)
  },
  methods: {

    startFakeDataGeneration() {
      this.dataUpdateTimer = setInterval(() => {
        if (this.sells.length == 0 || this.buys.length == 0) return

        let jiaData1 = this.sells
        for (let i = 0; i < jiaData1.length; i++) {
          if (i != 0 && i != jiaData1.length - 1) {
            let a = jiaData1[i - 1].progressiveTotal
            let b = jiaData1[i + 1].progressiveTotal
            let c = jiaData1[i].progressiveTotal
            jiaData1[i].progressiveTotal = this.getRandomAround(a, b, c)
          }
        }
        this.showSellsList = jiaData1

        let jiaData2 = this.buys
        for (let i = 0; i < jiaData2.length; i++) {
          if (i != 0 && i != jiaData2.length - 1) {
            let a = jiaData2[i - 1].progressiveTotal
            let b = jiaData2[i + 1].progressiveTotal
            let c = jiaData2[i].progressiveTotal
            jiaData2[i].progressiveTotal = this.getRandomAround(a, b, c)
          }
        }
        this.showBuyssList = jiaData2
      }, 1000)
    },
    getRandomAround(a, b, c) {
      const min = Math.min(a, b);
      const max = Math.max(a, b);
      // 确保c在合法范围内
      c = Math.max(min, Math.min(c, max));

      // 处理a等于b的情况，直接返回原值
      if (min === max) return c;

      // 计算当前值到边界的距离
      const distanceToMin = c - min;
      const distanceToMax = max - c;

      // 动态计算波动范围：取较小距离的10%，且至少为总范围的1%
      let delta;
      if (distanceToMin === 0 || distanceToMax === 0) {
        delta = (max - min) * 0.01; // 边界时用总范围的1%
      } else {
        delta = Math.min(distanceToMin, distanceToMax) * 0.3; // 正常波动范围
      }

      // 计算实际波动区间
      const lower = c - delta;
      const upper = c + delta;
      const adjustedLower = Math.max(min, lower);
      const adjustedUpper = Math.min(max, upper);
      const adjustedUpper2 = adjustedLower + Math.random() * (adjustedUpper - adjustedLower);

      // 生成随机数
      return adjustedUpper2.toFixed(4)
    },
    chageType(data) {
      this.$router.replace({
        name: `TradeCenter`,
        params: {
          symbol: this.symbol.symbol,
          buyType: data,
          showFlag: 'true'
        }
      })
    },
    /* 收藏或取消 */
    collectionCoin() {
      if (!this.isLogin) {
        this.$router.push("login")
        return
      }
      Toast.loading({
        message: this.$t('M.loading'),
        forbidClick: true,
        duration: 0,
      });
      this.$api.collection({
        partnerId: '472437831826935808',
        partnerNo: '1000010001',
        tradeId: this.symbol.symbol
      }).then((data) => {
        if (data.data.status == "cancel") {
          this.$notify(this.$t("M.user_collection_cencel_success"))
        } else {
          this.$notify(this.$t("M.user_collection_insert_success"))
        }
        this.isCollection()
        Toast.clear();
      })
    },
    closePage() {
      // this.$router.replace({
      //   path: `/TradeCenter`,
      //   params: {
      //     symbol: this.$route.query.symbol
      //   }
      // })
      this.$router.back()
    },
    /* 关闭弹出层 */
    closeTradeList() {
      this.tradeListShow = false;
    },
    /* 改变交易对 */
    changeSymbol(data) {
      this.symbol = data;
      this.symbol.targetCoin = this.symbol.tradeName.split('/')[1];
      this.symbol.nowCoin = this.symbol.tradeName.split('/')[0]
      /* 是否收藏 */
      this.isCollection()
      /* 基本信息 */
      this.defaultTrade()
      /* 简介 */
      this.TheCoinInformation()
      /* 盘口数据 */
      this.getPankouChengjiao()
      this.tradeAreaPrice()
    },
    /* 获取汇率 */
    tradeAreaPrice() {
      this.$api.tradeAreaPrice({ partnerId: "472437831826935808", currency: 'USDT' }).then((response) => {
        response.data.usd.findIndex(item => {
          if (item.coinName == this.symbol.targetCoin) {
            this.rate = item.coinPrice;
          }
          return item.coinName == "USDT"
        })
      })
    },
    /* 简介 */
    TheCoinInformation() {
      this.$api.TheCoinInformation({
        partnerId: '472437831826935808',
        partnerNo: '1000010001',
        language: this.lang,
        name: this.symbol.nowCoin,
      }).then((res) => {
        if (res.meta.success) {
          this.htmlData = res.data.information
        }
      })
    },
    /* 当前交易对是否收藏 */
    isCollection() {
      if (this.isLogin) {
        this.$api.isCollection({
          partnerId: '472437831826935808',
          partnerNo: '1000010001',
          tradeId: this.symbol.symbol
        }).then((res) => {
          this.isCollectionFlag = res.data;
        })
      }
    },
    /* 当前交易对基本信息 */
    defaultTrade() {
      this.$api.defaultTrade({
        partnerId: '472437831826935808',
        partnerNo: '1000010001',
        tradeName: this.symbol.symbol
      }).then((res) => {
        let data = res.data.tradeContent.content[0]
        this.tradeData24.high = data.high
        this.tradeData24.low = data.low
        this.tradeData24.vol24hour = data.vol24hour
        this.symbol.priceExchange = res.data.tradeContent.content[0].priceExchange
      })
    },
    /* 盘口数据 */
    getPankouChengjiao() {
      this.$api.getPankouChengjiao({
        partnerId: '472437831826935808',
        partnerNo: '1000010001',
        tradeName: this.symbol.symbol,
        depthStep: "STEP1"
      }).then((res) => {
        /* 盘口基本信息 */
        this.dishDefault = res.data.defaultBBTickerResult.content[0];
        /* 买卖价格 */
        this.latestDone = res.data.depthResult.latestDone.price;
        /* 最近成交 */
        this.disTradeList = res.data.tradeList;

        /* 涨跌幅 */
        this.rose = res.data.depthResult.latestDone.rose;

        /* 盘口显示的数据 */
        /* 卖盘 */
        // console.log(res.data.depthResult)
        this.sells = res.data.depthResult.sells.list;
        this.sellsProgressiveTotal = this.sells[0] ? this.sells[0].progressiveTotal : 0

        while (this.sells.length < 20) {
          this.sells.push({
            amount: "--",
            index: "--",
            price: "--",
            progressiveTotal: "--",
            total: "--",
          })
        }

        /* 买盘 */
        this.buys = res.data.depthResult.buys.list;
        this.buysProgressiveTotal = this.buys[this.buys.length - 1] ? this.buys[this.buys.length - 1].progressiveTotal : 0
        while (this.buys.length < 20) {
          this.buys.push({
            amount: "--",
            index: "--",
            price: "--",
            progressiveTotal: "--",
            total: "--",
          })
        }

        /* 初始化socket */
        if (!this.socket) {
          this.$refs.kLineDom.initKLineChart()
          this.$refs.kLineDom.initDeep()
          this.initSocket()
        } else {
          this.changeSocket()
          this.$refs.kLineDom.getMarketkline()
          this.$refs.kLineDom.initDeep()
        }
      })
    },

    /* 初始化socket */
    initSocket() {
      const BrowserWebSocket = window.WebSocket || window.MozWebSocket
      this.socket = new BrowserWebSocket(`wss://${this.$apiUrl}/v1/websocket/market`)
      this.socket.onopen = this.onopenWS
      this.socket.onmessage = this.onmessageWS
      this.socket.onerror = this.onerrorWS
      this.socket.onclose = this.oncloseWS
    },
    /* 打开时 */
    onopenWS() {
      /* 最近成交 */
      this.sendInitSymbol()

    },
    sendInitSymbol() {
      this.tradeSymbol = `market.${this.dishDefault.id}.trade`
      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: this.tradeSymbol,
        id: 'ios'
      }))
      /* 记录当前盘口的订阅值 */
      this.dishSymbol = `market.${this.dishDefault.id}.depth.step1`;
      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: this.dishSymbol,
        id: 'ios'
      }))
      this.klineSymbol = `market.${this.dishDefault.id}.kline.${this.selectType}.step5`;
      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: `market.${this.dishDefault.id}.kline.${this.selectType}.step5`,
        id: 'ios'
      }))
      this.depthrenderSymbol = `market.${this.dishDefault.id}.depthrender`
      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: `market.${this.dishDefault.id}.depthrender`,
        id: 'ios'
      }))
    },

    changeSocket() {
      this.socket.send(JSON.stringify({
        tag: 'CANCEL',
        content: this.tradeSymbol,
        id: 'ios'
      }))
      this.socket.send(JSON.stringify({
        tag: 'CANCEL',
        content: this.dishSymbol,
        id: 'ios'
      }))
      this.socket.send(JSON.stringify({
        tag: 'CANCEL',
        content: this.klineSymbol,
        id: 'ios'
      }))
      this.socket.send(JSON.stringify({
        tag: 'CANCEL',
        content: this.depthrenderSymbol,
        id: 'ios'
      }))
      this.sendInitSymbol()
    },

    /* 改变周期 */
    changeTimeArea(data) {
      this.selectType = data;
      this.socket.send(JSON.stringify({
        tag: 'CANCEL',
        content: this.klineSymbol,
        id: 'ios'
      }))
      this.klineSymbol = `market.${this.dishDefault.id}.kline.${this.selectType}.step5`;
      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: this.klineSymbol,
        id: 'ios'
      }))
    },
    /* 收到消息 */
    onmessageWS(data) {
      let blob = data.data;
      let reader = new FileReader();
      reader.readAsBinaryString(blob);
      reader.onload = (res) => {
        let text = this.pako.inflate(res.target.result, { to: "string" });
        let msg = JSON.parse(text);
        if (msg.type == 1) {
          /* 最近成交 */
          if (msg.tradeType == 'TRADE') {
            this.disTradeList = msg.data;
          }
          /* K线 */
          if (msg.tradeType == 'KLINE' && this.$refs.kLineDom) {
            this.$refs.kLineDom.UpKlineData(msg.data[0])
          }
          /* 卖盘 */
          if (msg.tradeType == "DEPTHRENDER" && this.$refs.kLineDom) {
            this.$refs.kLineDom.UpDeepData({
              sells: msg.data.sell,
              buys: msg.data.buy,
            })
          }
          /* 卖盘 */
          if (msg.tradeType == "DEPTH") {
            /* 需要截取多少位 */
            const sliceNum = 20;
            // console.log(msg.data)

            /* 卖盘 */
            if (msg.data.sells) {
              this.sells = msg.data.sells.list ? msg.data.sells.list : []
              this.sellsProgressiveTotal = this.sells.length == 0 ? 0 : this.sells[this.sells.length - 1].progressiveTotal
              while (this.sells.length < sliceNum) {
                this.sells.push({
                  amount: "--",
                  index: "--",
                  price: "--",
                  progressiveTotal: "--",
                  total: "--",
                })
              }
            }
            /* 买盘 */
            if (msg.data.buys) {
              this.buys = msg.data.buys.list ? msg.data.buys.list : []
              this.buysProgressiveTotal = this.buys.length == 0 ? 0 : this.buys[this.buys.length - 1].progressiveTotal
              while (this.buys.length < sliceNum) {
                this.buys.push({
                  amount: "--",
                  index: "--",
                  price: "--",
                  progressiveTotal: "--",
                  total: "--",
                })
              }
            }
            if (msg.data.latestDone) {
              this.latestDone = msg.data.latestDone.price;
              this.rose = msg.data.latestDone.rose;
            }
          }
        }
      };
    },
    /* 出现错误 */
    onerrorWS() {
      this.socket.close()
      if (this.socket.readyState !== 3) {
        this.socket = null
        this.initSocket()
      }
    },
    /* 关闭时 */
    oncloseWS() {
      if (this.socket.readyState !== 2 && this.closeType !== 1) {
        this.socket = null
        this.initSocket()
      }
    },
  },
  beforeDestroy() {
    this.closeType = 1;
    this.socket.close()
    if (this.pendingDisplayTimer) clearInterval(this.pendingDisplayTimer)
  },
}
</script>

<style lang="scss" scoped>
.tradeLine {
  font-size: 12px;
  padding-bottom: 100px;
  .topBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 18px;
    margin-top: 17px;
    .leftBox {
      display: flex;
      .backIcon {
        width: 10px;
        height: 18px;
        margin-left: 15px;
      }
      .shu {
        width: 1px;
        height: 15px;
        background-color: #9299a9;
        margin-left: 15px;
      }
      .selectBorder {
        width: 17px;
        height: 17px;
        margin-left: 12px;
        padding-top: 2px;
      }
      .symbolName {
        margin-left: 5px;
        line-height: 18px;
        font-weight: 600;
      }
    }
    .rightIcon {
      width: 16px;
      height: 15px;
      margin-right: 15px;
    }
  }

  .mainContant {
    padding: 0 15px;
    margin-top: 15px;
    display: flex;
    .left {
      width: 50%;
      height: 100%;
      .bigTit {
        height: 43px;
        font-size: 26px;
        line-height: 43px;
        margin-top: 10px;
        color: #ED0071;
      }
      .smallBox {
        display: flex;
        font-size: 11px;
        align-items: center;
        .rose {
          margin-left: 5px;
        }
        .upIcon {
          margin-left: 10px;
          width: 9px;
          height: 17px;
        }
      }
    }
    .right {
      width: 50%;
      display: flex;
      flex-flow: wrap;
      .itemBox {
        width: 50%;
        text-align: left;
        line-height: 16px;
        font-size: 11px;
        .tit {
          color: #9299a9;
        }
        .text {
          color: #191b27;
          font-weight: 600;
        }
      }
      .itemBox1 {
        margin-top: 10px;
      }
    }
  }
  .optionHome {
    display: flex;
    margin-top: 16px;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .item {
      display: flex;
      height: 17px;
      line-height: 17px;
      align-items: center;
      padding: 0px 4px;
    }
    .itemActive {
      color: #00b897;
    }
    .more {
      width: 16px;
      height: 13px;
    }
    .jiantou {
      width: 9px;
      height: 5px;
      margin-left: 3px;
    }
  }
  .tabHome {
    // margin-top: 400px;
  }
  .dealItem {
    width: 100%;
    padding: 0 15px;
    height: 16px;
    line-height: 16px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    > * {
      min-width: 20%;
    }
  }
  .dealTitle {
    height: 40px;
    padding: 0 15px;
    line-height: 40px;
    margin-bottom: 6px;
  }
  .alignLeft {
    text-align: left;
  }
  .alignRight {
    text-align: right;
  }
  .up {
    color: #0AA869 !important;
  }
  .down {
    color: #ED0071 !important;
  }
  .dish {
    width: 100%;
    padding: 0 10px;
    display: flex;
    .buys {
      flex: 1;
      .item {
        .price {
          color: #0AA869;
        }
        .color-sell-bg {
          background: #d6ede5 !important;
        }
      }
    }
    .sells {
      flex: 1;
      .item {
        .price {
          margin-left: 3px;
        }
        .color-sell-bg {
          left: 0;
        }
      }
    }
    .item {
      width: 100%;
      height: 20px;
      display: flex;
      font-size: 11px;
      line-height: 20px;
      position: relative;
      transition: all 0.5s;
      justify-content: space-between;
      .price {
        color: #ED0071;
        z-index: 10;
      }
      .num {
        color: #191b27;
        z-index: 10;
      }
      .color-sell-bg {
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        max-width: 100%;
        height: 100%;
        transition: all 0.5s;
        background: #f9dfde !important;
      }
    }
  }
  .selectKlinePopup {
    position: absolute;
    top: 165px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 99;
    .timeBox {
      width: 100%;
      background-color: #fff;
      //height: 45px;
      display: flex;
      justify-content: space-around;
      border-top: #f4f4f6;
      padding-top: 10px;
      flex-wrap: wrap;
      .item {
        padding: 0px 15px;
        background-color: #f4f4f6;
        height: 26px;
        line-height: 24px;
        font-size: 12px;
        text-align: center;
        border-radius: 3px;
        border: 1px solid #f4f4f6;
        margin-bottom: 10px;
      }
      .itemActive {
        border: 1px solid #00b897;
        background-color: #fff;
        color: #00b897;
      }
    }
    .klineTypeBox {
      width: 100%;
      background-color: #fff;
      height: 110px;
      padding-top: 15px;
      .box {
        text-align: center;
        align-items: center;
        .typeName {
          width: 80px;
        }
        display: flex;
        .typeList {
          line-height: 24px;
          display: flex;
          .item {
            width: 56px;
            background-color: #f4f4f6;
            height: 26px;
            line-height: 24px;
            font-size: 12px;
            text-align: center;
            border-radius: 3px;
            border: 1px solid #f4f4f6;
            margin-right: 15px;
          }
          .itemActive {
            border: 1px solid #00b897;
            background-color: #fff;
            color: #00b897;
          }
        }
      }
    }
  }
}

.btnBox {
  position: fixed;
  bottom: 0;
  display: flex;
  padding-bottom: 30px;
  padding-top: 10px;
  background-color: #fff;
  width: 100%;
  justify-content: space-around;
  z-index: 9999;
  .box {
    width: 165px;
    height: 40px;
    border-radius: 2px;
    line-height: 40px;
    text-align: center;
    color: #fff;
  }
  .sell {
    background: #ED0071;
  }
  .buy {
    background: #0AA869;
  }
}
/*页面头部*/
.name-box {
  flex: 1;
  text-align: center;
  position: relative;
}
.headTitle {
  display: flex;
  box-sizing: border-box;
  padding-top: 4px;
}
.nameWrap {
  text-align: center;
  line-height: 21px;
  font-family: 'PingFangSC-Light';
}
.stockName {
  /*font-family: "PingFang-SC-Heavy";*/
  margin-right: 1.2%;
}
.exchangeInfo {
  font-size: 12px;
  color: #666;
  height: 15px;
  line-height: 15px;
  font-weight: bold;
  text-align: center;
  font-family: 'PingFangSC-Light';
}
</style>
<style lang="scss" scoped>
::v-deep .van-tab {
  font-size: 16px;
  border-bottom: 1px solid #faf7fa;
}
</style>