<template>
    <div class="popupCertain2">
            <div class="title">
              <div class="titletop">{{$t('M.TP | SL')}}</div>
              <div class="titlebottom"><span>{{itemData.tradeName}}</span><span style="color: #00b897;">·{{tradeMode(itemData.openType)}}·{{ tradeMode(itemData.positionsType) }}·{{ itemData.lever }}X</span></div>
            </div>
            <div class="subTitle">
                <span>{{$t('M.Avg open price')}}：{{itemData.openPrice}}</span>
                <span style="margin-left:10px">{{$t('M.Latest Filled price')}}：{{latestDone}}{{itemData.buyCoinNickname}}</span>
            </div>
            <van-row v-if="lang!='es_ES' && lang!='fr_FR' && lang!='ro_RO'" class="row">
                <van-col span="13">
                    <van-checkbox-group @change="checkChange"  checked-color="#00b897" v-model="result" direction="horizontal">
                        <van-checkbox icon-size="14px" shape="square" name="a">{{$t('M.Take profit')}}</van-checkbox>
                        <van-checkbox icon-size="14px" shape="square" name="b">{{$t('M.Stop loss')}}</van-checkbox>
                    </van-checkbox-group>
                </van-col>
                <van-col class="right" span="11">
                    <span :class="showtab=='1'?'active':'Nactive'" @click="tabClick('1')">{{$t('M.market_price')}}</span>
                    <span :class="showtab=='2'?'active':'Nactive'" @click="tabClick('2')">{{$t('M.Limit price')}}</span>
                </van-col>
            </van-row>
            <template v-else>
                <van-row  class="row">
                    <van-col class="right" span="24">
                        <span :class="showtab=='1'?'active':'Nactive'" @click="tabClick('1')">{{$t('M.market_price')}}</span>
                        <span :class="showtab=='2'?'active':'Nactive'" @click="tabClick('2')">{{$t('M.Limit price')}}</span>
                    </van-col>
                </van-row>
                <van-row class="row">
                    <van-col span="24">
                        <van-checkbox-group @change="checkChange"  checked-color="#F10E38" v-model="result" direction="horizontal">
                            <van-checkbox icon-size="14px" shape="square" name="a">{{$t('M.Take profit')}}</van-checkbox>
                            <van-checkbox icon-size="14px" shape="square" name="b">{{$t('M.Stop loss')}}</van-checkbox>
                        </van-checkbox-group>
                    </van-col>
                </van-row>
            </template>
            <van-form >
            <div v-show="filtercheck('a')" class="pper">
                <van-field placeholder="0" class="pperinput"  v-model="number1" type="number" input-align="right" name="number1" >
                    <template #label>
                        <span class="inputLeft" >{{$t('M.TP trigger price')}}</span>
                    </template>
                    <template #extra>
                        <span class="inputRight">{{itemData.buyCoinNickname}}</span>
                    </template>
                </van-field>
            </div>
            <div v-show="showtab=='2' && filtercheck('a')" class="pper">
                <van-field placeholder="0" class="pperinput"  v-model="number2" type="number" input-align="right">
                    <template #label>
                        <span class="inputLeft">{{$t('M.TP order price')}}</span>
                    </template>
                    <template #extra>
                        <span class="inputRight">{{itemData.buyCoinNickname}}</span>
                    </template>
                </van-field>
            </div>
            <div v-if="number1>0 && number>0 && itemData.openType == 'OPEN_UP'" class="inputBottom">
                <span>{{itemData.openPrice-number1>0 ?
               $t("M.Stop_loss_tips").format([number1,((itemData.openPrice-number1)*number).toFixed(2)+itemData.buyCoinNickname])
               :$t("M.Stop_loss_tips1").format([number1,((number1-itemData.openPrice)*number).toFixed(2)+itemData.buyCoinNickname])
              }}</span>
            </div>
            <div v-if="number1>0 && number>0 && itemData.openType != 'OPEN_UP'" class="inputBottom">
                <span>{{  number1-itemData.openPrice<0?
                  $t("M.Stop_surplus_tips")
                .format([number1,((itemData.openPrice-number1)*form.count).toFixed(2)+itemData.buyCoinNickname])
                : $t("M.Stop_surplus_tips1")
                .format([number1,((number1-itemData.openPrice)*form.count).toFixed(2)+itemData.buyCoinNickname])
              }}</span>
            </div>
            <div v-show="filtercheck('b')" class="pper">
                <van-field placeholder="0" class="pperinput"  v-model="number3" type="number" input-align="right">
                    <template #label>
                        <span class="inputLeft">{{$t('M.SL trigger price')}}</span>
                    </template>
                    <template #extra>
                        <span class="inputRight">{{itemData.buyCoinNickname}}</span>
                    </template>
                </van-field>
            </div>
            <div v-show="showtab=='2' && filtercheck('b')" class="pper">
                <van-field placeholder="0" class="pperinput"  v-model="number4" type="number" input-align="right">
                    <template #label>
                        <span class="inputLeft">{{$t('M.SL order price')}}</span>
                    </template>
                    <template #extra>
                        <span class="inputRight">{{itemData.buyCoinNickname}}</span>
                    </template>
                </van-field>
            </div>
            <div v-if="number3>0 && number>0 && itemData.openType == 'OPEN_UP'" class="inputBottom">
                <span>{{itemData.openPrice-number3>0 ?
               $t("M.Stop_loss_tips").format([number3,((itemData.openPrice-number3)*number).toFixed(2)+itemData.buyCoinNickname])
               :$t("M.Stop_loss_tips1").format([number3,((number3-itemData.openPrice)*number3).toFixed(2)+itemData.buyCoinNickname])
              }}</span>
            </div>
            <div v-if="number3>0 && number>0 && itemData.openType != 'OPEN_UP'" class="inputBottom">
                <span>{{itemData.openPrice-number3<0 ?
               $t("M.Stop_loss_tips").format([number3,((number3-itemData.openPrice)*number).toFixed(2)+itemData.buyCoinNickname])
               :$t("M.Stop_loss_tips1").format([number3,((itemData.openPrice-number3)*number).toFixed(2)+itemData.buyCoinNickname])
              }}</span>
            </div>
            <van-field class="pperinput" placeholder="0" style="border: 1px solid #00b897;border-bottom: none;"  v-model="number" @change="activeNum = ''" type="number" input-align="right">
                    <template #label>
                        <span class="inputLeft">{{$t('M.quantity')}}</span>
                    </template>
                    <template #extra>
                        <span style="color: #a2a2a2;border-left: 1px solid #00b897;padding: 0 10px;margin-left: 10px;">{{itemData.sellCoinNickname}}</span>
                    </template>
                </van-field>
            <div class="activeNum">
              <div @click="changeNumber(activeNum = 0.1)" :class="activeNum=='0.1'?'active':''">10%</div>
              <div @click="changeNumber(activeNum = 0.2)" :class="activeNum=='0.2'?'active':''" >20%</div>
              <div @click="changeNumber(activeNum = 0.5)" :class="activeNum=='0.5'?'active':''" >50%</div>
              <div @click="changeNumber(activeNum = 0.8)" :class="activeNum=='0.8'?'active':''" >80%</div>
              <div @click="changeNumber(activeNum = 1)" :class="activeNum=='1'?'active':''" >100%</div>
            </div>
            <!-- <van-tabs class="tab2"  title-active-color="#fff" title-inactive-color="#5C6573" color="#F10E38"  type="card">
              <van-tab  name="10" title="10%"></van-tab>
              <van-tab  name="20" title="20%"></van-tab>
              <van-tab  name="30" title="50%"></van-tab>
              <van-tab  name="50" title="80%"></van-tab>
              <van-tab  name="100" title="100%"></van-tab>
            </van-tabs> -->
            <div class="sub">
                {{$t('M.Amount can flat')}}：{{itemData.enableUnwind}}{{itemData.sellCoinNickname}}
            </div>
            <div class="buttoncon">
              <van-button color="#00b897" size="small" round  block @click="submits">{{$t('M.confirm')}}</van-button>
            </div>
        </van-form>
        </div>
  </template>

  <script>
import { mapGetters } from "vuex";
  export default {

    props:['itemData','latestDone'],
    data(){
        return{
            showtab:'1',
            result:['a','b'],
            number:'',
            number1:'',
            number2:'',
            number3:'',
            number4:'',
            activeNum:'null',
            form: {
                tradeId: null,
                positionsType: null, //仓位类型(BY=逐仓, TOTAL=全仓)
                tradeMode: null, //交易模式(OPEN_UP=开多 OPEN_DOWN=开空，CLOSE_UP=平多 CLOSE_DOWN=平空 )
                matchType: "TP_SL",
                entrustType: "MARKET", //委托类型(LIMIT=限价, MARKET=市价)
                source: "IOS",
                tpslType: "",  //止盈止损订单需要传(ONE_WAY=单向止盈止损, ONE_WAY_TP=单向止盈, ONE_WAY_SL=单向止损, TWO_WAY=双向止盈止损)
                count: 0,
                tpTriggerPrice: null,
                tpSavePrice: null,
                slTriggerPrice: null,
                slSavePrice: null
            },
        }
    },
    computed:{
        ...mapGetters(["isLogin", "user", "lang"]),
        tpslType: function () {
            // showLoss (ONE_WAY=单向止盈止损, ONE_WAY_TP=单向止盈, ONE_WAY_SL=单向止损, TWO_WAY=双向止盈止损)
            if (this.filtercheck('a') && this.filtercheck('b')) return "TWO_WAY"
            if (this.filtercheck('a') && !this.filtercheck('b')) return "ONE_WAY_TP"
            if (!this.filtercheck('a') && this.filtercheck('b')) return "ONE_WAY_SL"
            return "ONE_WAY_TP"
        },
    },
    methods:{
        closestMultiple(n, x){
            if (x > n){
                return x;
            }else{
                n = n + parseInt(x / 2, 10);
                n = n - (n % x);
                return n;
            }
        },
        changeNumber(){
            let a = ((this.itemData.enableUnwind-0)*(this.activeNum-0))
            this.number = this.closestMultiple(a,this.itemData.faceValue-0)
           if(this.activeNum == 1){
            this.number = this.itemData.maxHoldAmount
           }

            // this.number = this.itemData.enableUnwind*this.activeNum
            // this.number = parseInt(this.number)
            // console.log(this.number);
            console.log(this.itemData);
            // this.number = this.closestMultiple(this.number,this.itemData.faceValue)
            // console.log(this.number % this.itemData.faceValue == 0);
            // console.log(this.isInteger(this.number,this.itemData.faceValue));

        },
        checkChange(value){
            console.log(value)
            if(value.length ==1){
            this.result.push(value[0])
            }
        },
        tradeMode(key){
            if(key =='OPEN_UP') return this.$t('M.Open long')
            if(key =='OPEN_DOWN') return this.$t('M.Open short')
            if(key =='CLOSE_UP') return this.$t('M.Close long')
            if(key =='CLOSE_DOWN') return this.$t('M.Close short')
            if(key =='BY') return this.$t('M.Isolated')
            if(key =='TOTAL') return this.$t('M.Cross')
        },
        tabClick(key){
            this.showtab = key
            this.number = ''
            this.number1 = ''
            this.number2 = ''
            this.number3 = ''
            this.number4 = ''
        },
        filtercheck(key){
            let a =false;
            this.result.forEach((v,i)=>{
                if(v == key){
                    a = true
                }
            })
            return a
        },
        submits(){
            if(this.itemData.openType == 'OPEN_UP'){
                if(this.showtab=='1'){
                    if(this.filtercheck('a') && this.filtercheck('b')){
                        if(this.number1 == '')return this.$toast(this.$t('M.Please1'));
                        if(this.number3 == '')return this.$toast(this.$t('M.Please3'));
                        if(this.number1-0<this.latestDone)return this.$toast(this.$t('M.Stop profit6'));
                        if(this.number3-0>this.latestDone)return this.$toast(this.$t('M.stop loss7'));
                        if(this.number == '')return this.$toast(this.$t('M.Please8'));
                        if(this.number-0>this.itemData.enableUnwind-0 ||this.itemData.enableUnwind == 0)return this.$toast(this.$t('M.Please9'));
                    }
                    if(this.filtercheck('a')){
                        if(this.number1 == '')return this.$toast(this.$t('M.Please1'));
                        if(this.number1-0<this.latestDone)return this.$toast(this.$t('M.Stop profit6'));
                        if(this.number == '')return this.$toast(this.$t('M.Please8'));
                        if(this.number-0>this.itemData.enableUnwind-0 ||this.itemData.enableUnwind == 0)return this.$toast(this.$t('M.Please9'));
                    }
                    if(this.filtercheck('b')){
                        if(this.number3 == '')return this.$toast(this.$t('M.Please3'));
                        if(this.number3-0>this.latestDone)return this.$toast(this.$t('M.stop loss7'));
                        if(this.number == '')return this.$toast(this.$t('M.Please9'));
                        if(this.number-0>this.itemData.enableUnwind-0 ||this.itemData.enableUnwind == 0)return this.$toast(this.$t('M.Please9'));
                    }
                    }
                    if(this.showtab=='2'){
                    if(this.filtercheck('a') && this.filtercheck('b')){
                        if(this.number1 == '')return this.$toast(this.$t('M.Please1'));
                        if(this.number2 == '')return this.$toast(this.$t('M.Please2'));
                        if(this.number3 == '')return this.$toast(this.$t('M.Please3'));
                        if(this.number4 == '')return this.$toast(this.$t('M.Please4'));
                        if(this.number1-0<this.latestDone)return this.$toast(this.$t('M.Stop profit6'));
                        if(this.number3-0>this.latestDone)return this.$toast(this.$t('M.stop loss7'));
                        if(this.number == '')return this.$toast(this.$t('M.Please9'));
                        if(this.number-0>this.itemData.enableUnwind-0 ||this.itemData.enableUnwind == 0)return this.$toast(this.$t('M.Please9'));
                    }
                    if(this.filtercheck('a')){
                        if(this.number1 == '')return this.$toast(this.$t('M.Please1'));
                        if(this.number2 == '')return this.$toast(this.$t('M.Please2'));
                        if(this.number1-0<this.latestDone)return this.$toast(this.$t('M.Stop profit6'));
                        if(this.number == '')return this.$toast(this.$t('M.Please9'));
                        if(this.number-0>this.itemData.enableUnwind-0 ||this.itemData.enableUnwind == 0)return this.$toast(this.$t('M.Please9'));
                    }
                    if(this.filtercheck('b')){
                        if(this.number3 == '')return this.$toast(this.$t('M.Please3'));
                        if(this.number4 == '')return this.$toast(this.$t('M.Please4'));
                        if(this.number3-0>this.latestDone)return this.$toast(this.$t('M.stop loss7'));
                        if(this.number == '')return this.$toast(this.$t('M.Please9'));
                        if(this.number-0>this.itemData.enableUnwind-0 ||this.itemData.enableUnwind == 0)return this.$toast(this.$t('M.Please9'));
                    }
                    }
            }else{
                if(this.showtab=='1'){
             if(this.filtercheck('a') && this.filtercheck('b')){
                if(this.number1 == '')return this.$toast(this.$t('M.Please1'));
                if(this.number3 == '')return this.$toast(this.$t('M.Please3'));
                if(this.number1-0>this.latestDone)return this.$toast(this.$t('M.Stop profit10'));
                if(this.number3-0<this.latestDone)return this.$toast(this.$t('M.stop loss10'));
                if(this.number == '')return this.$toast(this.$t('M.Please8'));
                if(this.number-0>this.itemData.enableUnwind-0 ||this.itemData.enableUnwind == 0)return this.$toast(this.$t('M.Please9'));
             }
             if(this.filtercheck('a')){
                if(this.number1 == '')return this.$toast(this.$t('M.Please1'));
                if(this.number1-0>this.latestDone)return this.$toast(this.$t('M.Stop profit10'));
                if(this.number == '')return this.$toast(this.$t('M.Please8'));
                if(this.number-0>this.itemData.enableUnwind-0 ||this.itemData.enableUnwind == 0)return this.$toast(this.$t('M.Please9'));
             }
             if(this.filtercheck('b')){
                if(this.number3 == '')return this.$toast(this.$t('M.Please3'));
                if(this.number3-0<this.latestDone)return this.$toast(this.$t('M.stop loss10'));
                if(this.number == '')return this.$toast(this.$t('M.Please9'));
                if(this.number-0>this.itemData.enableUnwind-0 ||this.itemData.enableUnwind == 0)return this.$toast(this.$t('M.Please9'));
             }
            }
            if(this.showtab=='2'){
             if(this.filtercheck('a') && this.filtercheck('b')){
                if(this.number1 == '')return this.$toast(this.$t('M.Please1'));
                if(this.number2 == '')return this.$toast(this.$t('M.Please2'));
                if(this.number3 == '')return this.$toast(this.$t('M.Please3'));
                if(this.number4 == '')return this.$toast(this.$t('M.Please4'));
                if(this.number1-0>this.latestDone)return this.$toast(this.$t('M.Stop profit10'));
                if(this.number3-0<this.latestDone)return this.$toast(this.$t('M.stop loss10'));
                if(this.number == '')return this.$toast(this.$t('M.Please9'));
                if(this.number-0>this.itemData.enableUnwind-0 ||this.itemData.enableUnwind == 0)return this.$toast(this.$t('M.Please9'));
             }
             if(this.filtercheck('a')){
                if(this.number1 == '')return this.$toast(this.$t('M.Please1'));
                if(this.number2 == '')return this.$toast(this.$t('M.Please2'));
                if(this.number1-0>this.latestDone)return this.$toast(this.$t('M.Stop profit10'));
                if(this.number == '')return this.$toast(this.$t('M.Please9'));
                if(this.number-0>this.itemData.enableUnwind-0 ||this.itemData.enableUnwind == 0)return this.$toast(this.$t('M.Please9'));
             }
             if(this.filtercheck('b')){
                if(this.number3 == '')return this.$toast(this.$t('M.Please3'));
                if(this.number4 == '')return this.$toast(this.$t('M.Please4'));
                if(this.number3-0<this.latestDone)return this.$toast(this.$t('M.stop loss10'));
                if(this.number == '')return this.$toast(this.$t('M.Please9'));
                if(this.number-0>this.itemData.enableUnwind-0 ||this.itemData.enableUnwind == 0)return this.$toast(this.$t('M.Please9'));
             }
            }
            }
            // console.log(this.isLogin);
            if (!this.isLogin) return
            this.form.tradeId = this.itemData.contractCoinId;
            this.form.positionsType = this.itemData.positionsType
            // this.form.positionsType = 'TOTAL'
            this.form.tradeMode = this.itemData.openType === "OPEN_UP" ? "CLOSE_UP" : "CLOSE_DOWN"
            this.form.entrustType = this.showtab=='1'?'MARKET':'LIMIT'
            this.form.count = this.number-0
            this.form.tpTriggerPrice = this.number1==''?0:this.number1-0
            this.form.tpSavePrice = this.number2==''?0:this.number2-0
            this.form.slTriggerPrice = this.number3==''?0:this.number3-0
            this.form.slSavePrice = this.number4==''?0:this.number4-0
            this.form.tpslType = this.tpslType
            this.$api.v1aveEntrust(this.form).then(res=>{
                this.$emit('dregs')
                this.$emit('dreg')
                this.$emit('update')
            })
        },
    }
  }
  </script>

  <style lang="scss">
  .popupCertain2{
    padding: 10px;
    .van-tabs__wrap{
        height: auto;
    }
    .subTitle{
        padding-bottom: 10px;
        color: #a2a2a2;
        border-bottom: 1px solid #eee;
    }
    .tab1{
        .van-tabs__nav--card{
            border: none;
            margin: 0;
            border-radius: 0;
            .van-tab{
                border: none;
            }
        }
    }
    .tab2{
        .van-tabs__nav--card{
            margin: 0;
            border-radius: 0;
        }
    }
    .row{
        padding-top: 10px;
        .Nactive{
            padding: 5px 10px;
            background: #f8f8f8;
            color: #a2a2a2;
            margin-left: 10px;
        }
        .active{
            padding: 5px 10px;
            background: #e7f7f2;
            color: #00b897;
            margin-left: 10px;
        }
        .right{
            text-align: right;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;

          span {
            white-space: nowrap;
          }
        }
    }
    .buttoncon{
      margin: 15px 0;
    }
    .describe{
      margin-top: 10px;
      padding: 5px;
      background-color: #f8f8f8;
      div{
        padding: 5px;
      }
    }
    .title{
      border-bottom: 1px solid #e3e5eb;
      margin-bottom: 10px;
      .titletop{
        color: #000;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 5px;
      }
      .titlebottom{
        // font-size: 16px;
        color: #000;
        padding-bottom: 5px;
      }
    }
    .sub{
      padding-top: 5px;
    }
    .pper{
        margin: 10px 0;
        .pperinput{
            background: #f8f8f8;
            .inputRight{
                color: #a2a2a2;border-left: 1px solid #ddd;padding: 0 10px;margin-left: 10px;
            }
            .inputLeft{
                color: #a2a2a2;
            }
        }
    }
    .inputBottom{
            padding: 3px 0;
            color: #00b897;
    }
    .activeNum{
      border: 1px solid #00b897;
      display: flex;
      div{
        flex: 1;
        height: 30px;
        border-right: 1px solid #00b897;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      div:last-child{
        border-right: none;
      }
      .active{
        color: #fff;
        background-color: #00b897;
      }
    }
  }
  
  </style>
