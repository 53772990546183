/*
 * @Author:  
 * @Date: 2022-06-25 18:01:35
 * @LastEditors:  
 * @LastEditTime: 2022-07-19 19:16:28
 * @FilePath: \exchange-h5\src\store\modules\app.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { GetCurrentBrowser, GetOs } from '@/utils/ststemTool'
const state = {
    partnerId: '472437831826935808',
    partnerNo: '1000010001',
    currencySymbol: 'USD', // 默认钱币符号
    whiteList: ['login', 'register', 'forgotPassword', 'setPassword', 'agreement', 'resetPassword',
        'index', 'home', 'newOption', 'userCenter', 'settings', 'serve', 'IEO', "IEOdetails",
        'details', "LockUp", "rules", "guide"
    ],
    clientType: process.env.VUE_APP_CLIENT_TYPE,
    langWY: { // 网易图形验证码语言
        'zh_CN': 'zh-CN',
        'zh_TW': 'zh-TW',
        'ba_BA': 'pt',
        'ko_KR': 'ko',
        'ja_JP': 'ja',
        'vi_VN': 'vi',
        'en_US': 'en-US',
    },
    // 设备基本信息
    information: {
        client: 'H5',
        terminal: GetOs() + ' ' + GetCurrentBrowser(),
        version: '1.0.2'
    },




}

const mutations = {

}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}