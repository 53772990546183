<!--
 * @Author:
 * @Date: 2022-06-25 18:01:35
 * @LastEditors:
 * @LastEditTime: 2022-07-18 13:51:50
 * @FilePath: \exchange-h5\src\views\Index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div class="index">
    <div class="content">
      <router-view />
    </div>
    <div class="tab-bar">
      <div @click="switchTabBarItem(tabBarImgItem.path)" v-for="(tabBarImgItem,index) in tabBarImgList" :key="index"
        :class="[tabBarImgItem.path===$route.path ?'active link':'link']">
        <div class="tab-bar-item">
          <img :src="$route.path === tabBarImgItem.path ? tabBarImgItem.selectedIconPath : tabBarImgItem.iconPath" />
          <span :class="$route.path === tabBarImgItem.path ? 'active' : ''">{{tabBarImgItem.title}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: "index",
  computed: {
    ...mapMutations(['SET_TOKEN']),
  },
  data() {
    return {
      tabBarImgList: [
        {
          path: '/home',
          title: this.$t('M.home_page'),
          iconPath: require('@/assets/img/public/home2.png'),
          selectedIconPath: require('@/assets/img/public/homed.png'),
        },
        {
          path: '/TradeCenter',
          title: this.$t("M.tab_trade"),
          iconPath: require('@/assets/img/public/trade2.png'),
          selectedIconPath: require('@/assets/img/public/tradeed.png'),
        },
        {
          path: '/contract',
          title: this.$t("M.Margined"),
          iconPath: require('@/assets/img/public/option2.png'),
          selectedIconPath: require('@/assets/img/public/optioned.png'),
        },
        {
          path: '/assets',
          title: this.$t("M.Assets"),
          iconPath: require('@/assets/img/public/assets2.png'),
          selectedIconPath: require('@/assets/img/public/assetsed.png'),
        },
      ],

    }
  },
  beforeMount() { },
  methods: {
    switchTabBarItem(path) {
      if (this.$route.path !== path) {
        // 切换tabBarItem
        this.$router.push(path);
      }
    }
  }
}
</script>

<style scoped src="../assets/style/index.css"></style>