<template>
  <div>
    <van-popup v-model="tradeListShow" position="left" :close-on-click-overlay="false" @click-overlay="close" @open="getList"
      :style="{ width: '243px',height:'100%' }">
      <div class="content">
        <div class="bar">
          <van-tabs v-model="active" line-width="16px" line-height="3px" title-active-color="#00b897" title-inactive-color="#5C6573" color="#00b897">
            <van-tab v-for="(item,idx) in tradeMetaList" :title="item.areaName" :key="idx">
            </van-tab>
          </van-tabs>
        </div>
        <van-search v-model="searchValue" />
        <div class="listBox">
          <div class="item" v-for="(item,idx) in tradeShowList" :key="idx" @click="selectSymbol(item)">
            <div class="name">{{item.tradeName}}</div>
            <img class="collect" @click.stop="collectionCoin(item)" v-if="!item.collect" src="../../../assets/img/trade/star0.png" alt="" srcset="">
            <img class="collect" @click.stop="collectionCoin(item)" v-if="item.collect" src="../../../assets/img/trade/star.png" alt="" srcset="">
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'lang', 'isLogin'
    ]),

  },
  props: {
    tradeListShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    /* 列表选项改变时 */
    active(newVal) {
      this.tradeShowList = this.tradeMetaList[newVal].tradeTypeInfoList
    },
    /* 搜索框改变时 */
    searchValue(newVal) {
      if (newVal == "") {
        this.tradeShowList = this.tradeMetaList[this.active].tradeTypeInfoList
      } else {
        let showArr = [];
        for (const iterator of this.tradeMetaList[this.active].tradeTypeInfoList) {
          let symbolText = iterator.symbol.toLowerCase()
          let newValText = newVal.toLowerCase()
          if (
              symbolText.indexOf(newValText) != -1
          ) {
            showArr.push(iterator);
          }
        }
        this.tradeShowList = showArr;
      }
    },

  },
  data() {
    return {
      active: 0,
      show: false,
      tradeList: [],
      tradeMetaList: [],
      tradeShowList: [],
      searchValue: '',
    }
  },
  mounted() {
    this.getList().then((data) => {
      let symbolText = data[0].tradeTypeInfoList[0].symbol
      if (this.$route.params.symbol) {
        symbolText = this.$route.params.symbol;
      } else {
        if (localStorage.getItem("tradeSymbol")) {
          symbolText = localStorage.getItem("tradeSymbol");
          localStorage.removeItem("tradeSymbol")
        }
      }
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        for (let i = 0; i < element.tradeTypeInfoList.length; i++) {
          const ele = element.tradeTypeInfoList[i];
          if (ele.symbol == symbolText) {
            this.$emit("changeSymbol", ele)
            break;
          }
        }
      }
    })
  },
  methods: {
    /* 选择交易对 */
    selectSymbol(data) {
      localStorage.setItem("tradeSymbol", data.symbol)
      this.$emit("changeSymbol", data)
      this.close()
    },
    /* 获取列表 */
    getList() {
      let that = this;
      return new Promise(function (resolve, reject) {
        that.$api.tradeList({
          partnerId: '472437831826935808',
          partnerNo: '1000010001'
        }).then((res) => {
          if (!res.meta.success) {
            reject(error);
            return
          };
          /* 重置元数组 */
          that.tradeMetaList = []
          that.tradeMetaList = JSON.parse(JSON.stringify(res.data));
          /* 自选数组 */
          let arr = []
          for (let j = 0; j < that.tradeMetaList.length; j++) {
            let tradeTypeInfoList = that.tradeMetaList[j].tradeTypeInfoList
            for (let index = 0; index < tradeTypeInfoList.length; index++) {
              const element = tradeTypeInfoList[index];
              if (element.collect) {
                arr.push(element)
              }
            }

          }
          that.tradeMetaList.unshift({
            areaName: that.$t('M.collect'),
            tradeTypeInfoList: arr
          })

          that.tradeShowList = that.tradeMetaList[that.active].tradeTypeInfoList
          resolve(res.data);
        })
      });
    },
    /* 关闭 */
    close() {
      this.searchValue = ''
      this.$emit("closeTradeList")
    },
    /* 收藏或取消 */
    collectionCoin(item) {
      if (!this.isLogin) {
        this.$router.push("login")
        return
      };
      Toast.loading({
        message: this.$t('M.loading'),
        forbidClick: true,
        duration: 0,
      });
      this.$api.collection({
        partnerId: '472437831826935808',
        partnerNo: '1000010001',
        tradeId: item.symbol
      }).then((data) => {
        if (data.data.status == "cancel") {
          this.$notify(this.$t("M.user_collection_cencel_success"))
        } else {
          this.$notify(this.$t("M.user_collection_insert_success"))
        }
        this.getList().then(() => {
          Toast.clear();
        })
        this.$emit("isCollection")
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  padding: 65px 10px 0 0;
  .bar {
    width: 100%;
  }
  .listBox {
    margin-top: 22px;
    padding: 0 15px;
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 29px;
      .name {
        font-size: 12px;
        font-weight: 400;
        color: #1a1d26;
        line-height: 17px;
      }
      .collect {
        width: 19px;
        height: 19px;
      }
    }
  }
}
</style>