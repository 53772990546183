<!--
 * @Author:   -------@outlook.com
 * @Date: 2022-06-14 09:19:47
 * @LastEditors:   -------@outlook.com
 * @LastEditTime: 2022-07-18 16:44:47
 * @FilePath: \exchange-h5\src\components\HelloWorld.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div :class="['newOption',]">

    <div class="topBox">
      <div class="topBar">
        <!-- <div class="barItem" v-bind:class="{'barItemActive':showFlag ==true}" @click="changeTab(true)">
          {{ $t("M.Perpetual01") }}
        </div> -->
        <!-- <div class="barItem" v-bind:class="{'barItemActive':showFlag ==false}" @click="changeTab(false)">
          {{ $t("M.Sec Swap") }}
        </div> -->
      </div>
    </div>
    <Contract v-if="showFlag"></Contract>
    <NewOption v-else></NewOption>

  </div>
</template>

<script>
import Contract from "@/views/contractTrade";
import NewOption from "@/views/newOption/NewOption.vue";
/* 引入图表 */

export default {
  name: "newOption",
  components: {
    Contract, NewOption
  },
  data() {
    return {
      showFlag: true
    }
  },
  created() {

  },
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  methods: {
    changeTab(showFlag) {
      if (this.showFlag == showFlag) {
        return
      } else {
        this.showFlag = showFlag
      }
    }
  },
  beforeDestroy() {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.topBox {
  background-color: #fff;
  padding: 9px 16px;
  display: flex;
  align-items: center;

  .topBar {
    flex: 1;
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-radius: 2px;
    padding: 1px;
    height: 30px;

    .barItem {
      border-radius: 2px;
      //flex: 1;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      color: #888888;
      line-height: 26px;
      padding: 0 20px;
      border: 1px solid #E5E5E5;
      height: 30px;
      line-height: 30px;
      margin-right: 10px;
    }

    //
    .barItemActive {
      background-color: #00b897;
      color: #fff;
    }
  }

  .server {
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-left: 6px;

    .icon_kefu {
      width: 20px;
    }
  }
}
</style>
