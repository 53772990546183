
<template>
  <div class="Kline">
    <div class="optionHome">
      <div @click="selectKlineType('min15')" class="item" v-bind:class="[selectType =='min15'?'itemActive':'']">15{{$t('M.minutes')}}</div>
      <div @click="selectKlineType('hour1')" class="item" v-bind:class="[selectType =='hour1'?'itemActive':'']">1{{$t('M.hours')}}</div>
      <div @click="selectKlineType('hour4')" class="item" v-bind:class="[selectType =='hour4'?'itemActive':'']">4{{$t('M.hours')}}</div>
      <div @click="selectKlineType('day')" class="item" v-bind:class="[selectType =='day'?'itemActive':'']">1{{$t('M.Day-3')}}</div>
      <div @click="selectKlineType('week')" class="item" v-bind:class="[selectType =='week'?'itemActive':'']">1{{$t('M.week')}}</div>
      <div @click="selectKlineType('more')" class="item"
        v-bind:class="[selectType =='min' ||selectType =='min1' ||selectType =='min5'||selectType =='min30'||selectType =='mon1' ?'itemActive':'']">
        {{showMoreText}}<img class="jiantou" src="../../../assets/img/Kline/jiantouxia.png" alt="">
      </div>
      <div @click="selectKlineType('deep')" class="item" v-bind:class="[selectType =='deep'?'itemActive':'']">{{$t('M.depth02')}}</div>
      <div @click="selectKlineType('option')" class="item" v-bind:class="[selectType =='option'?'itemActive':'']"><img class="more"
          src="../../../assets/img/Kline/more.png" alt=""></div>
    </div>

    <div v-show="KlineTableType == 1" id="technical-indicator-k-line" class="k-line-chart"></div>
    <div v-show="KlineTableType == 2" id="deepBox">
      <Deep ref="deep" />
    </div>

    <div class="selectKlinePopup" v-if="selectKlinePopup" @click="selectKlineType">
      <div class="timeBox" v-if="selectKlinePopupType == 1">
        <div class="item" v-bind:class="[selectType == 'min' ? 'itemActive':'']" @click.stop="selectKlineType('min')">{{$t('M.minhour')}}
        </div>
        <div class="item" v-bind:class="[selectType == 'min1' ? 'itemActive':'']" @click.stop="selectKlineType('min1')">1{{$t('M.minutes')}}
        </div>
        <div class="item" v-bind:class="[selectType == 'min5' ? 'itemActive':'']" @click.stop="selectKlineType('min5')">5{{$t('M.minutes')}}
        </div>
        <div class="item" v-bind:class="[selectType == 'min30' ? 'itemActive':'']" @click.stop="selectKlineType('min30')">
          30{{$t('M.minutes')}}
        </div>
        <div class="item" v-bind:class="[selectType == 'mon1' ? 'itemActive':'']" @click.stop="selectKlineType('mon1')">1{{$t('M.month')}}</div>
      </div>

      <div class="klineTypeBox" v-if="selectKlinePopupType == 2">
        <div class="box">
          <div class="typeName">
            {{$t('M.main_picture')}}
          </div>
          <div class="typeList">
            <div @click.stop="selectIndicators('MA',true)" class="item" v-bind:class="[ mainIndicators == 'MA'? 'itemActive':'']">MA</div>
            <div @click.stop="selectIndicators('BOLL',true)" class="item" v-bind:class="[ mainIndicators == 'BOLL'? 'itemActive':'']">BOLL</div>
          </div>
        </div>

        <div class="box" style="margin-top:30px;">
          <div class="typeName">
            {{$t('M.futu')}}
          </div>
          <div class="typeList">
            <div @click.stop="selectIndicators('MACD',false)" class="item" v-bind:class="[ viceIndicators == 'MACD'? 'itemActive':'']">MACD</div>
            <div @click.stop="selectIndicators('KDJ',false)" class="item" v-bind:class="[ viceIndicators == 'KDJ'? 'itemActive':'']">KDJ</div>
            <div @click.stop="selectIndicators('RSI',false)" class="item" v-bind:class="[ viceIndicators == 'RSI'? 'itemActive':'']">RSI</div>
            <div @click.stop="selectIndicators('WR',false)" class="item" v-bind:class="[ viceIndicators == 'WR'? 'itemActive':'']">WR</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { dispose, init } from 'klinecharts'
import Deep from "./Deep.vue";

export default {
  components: { Deep },
  props: {
    symbol: {
      type: Object,
      default:
        () => {
          return {
            collect: true,
            displayStatus: "visible",
            nowCoin: "BTC",
            partnerTradeId: "641201074438406144",
            sellCoinLogo: "https://fubt-3.oss-cn-hongkong.aliyuncs.com/cdbbdbdf-4307-4bf7-a7ee-ea77fca10b0f",
            symbol: "btcusdt",
            targetCoin: "USDT",
            tradeId: "480309422875213824",
            tradeName: "BTC/USDT",
          }
        },
    },
  },

  data() {
    return {
      selectType: '',
      /* 分钟线数据 */
      minutes: [],

      selectType: 'min15',
      selectKlinePopup: false,
      selectKlinePopupType: 1,
      showMoreText: this.$t('M.more'),

      mainTechnicalIndicatorTypes: ['MA', 'EMA', 'SAR'],
      subTechnicalIndicatorTypes: ['MA', 'EMA', 'SMA'],

      deepChart: null,
      deepChartData: null,
      KlineTableType: 1,
      mainIndicators: "MA",
      viceIndicators: "MACD",
    }
  },
  mounted() {

  },
  methods: {
    /* 切换指标 */
    selectIndicators(type, flag) {
      this.selectKlinePopup = false;
      if (flag) {
        this.kLineChart.createTechnicalIndicator(type, false, { id: 'candle_pane' })
        this.mainIndicators = type;
      } else {
        this.kLineChart.createTechnicalIndicator(type, false, { id: this.paneId2 })
        this.viceIndicators = type;
      }
    },
    initKLineChart() {
      /* 初始化 */
      this.kLineChart = init('technical-indicator-k-line')
      this.kLineChart.createTechnicalIndicator('MA', false, { id: 'candle_pane' })
      this.paneId = this.kLineChart.createTechnicalIndicator('VOL', false)
      this.paneId2 = this.kLineChart.createTechnicalIndicator('MACD', false)
      this.kLineChart.setOffsetRightSpace(50)
      this.kLineChart.setStyleOptions(this.initKlineData())
      this.kLineChart.setPriceVolumePrecision(this.symbol.priceExchange, 2)
      this.kLineChart.setTimezone("America/New_York")
      this.kLineChart.overrideTechnicalIndicator({
        name: 'MA',
        calcParams: [10, 30, 60]
      }, 'candle_pane')

      this.kLineChart.overrideTechnicalIndicator({
        name: 'VOL',
        calcParams: []
      }, this.paneId)
      this.getMarketkline()
    },
    getMarketkline(type) {
      /* 添加数据 */
      this.$api.contractMarketkline({
        partnerId: '472437831826935808',
        partnerNo: '1000010001',
        tradeName: this.symbol.symbol,
        KlineType: this.selectType == 'min1' || this.selectType == 'deep' ? 'min' : this.selectType,
        KlineStep: "step5",
      }).then((res) => {
        let arr = []
        if (res && res.data) {
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            arr.push({
              close: parseFloat(element.close),
              high: parseFloat(element.high),
              low: parseFloat(element.low),
              open: parseFloat(element.open),
              timestamp: parseFloat(element.time),
              turnover: null,
              volume: parseFloat(element.volume),
            })
          }
        }
        if (type) {
          this.kLineChart.setStyleOptions({
            candle: {
              type: type
            }
          })
        } else {
          this.kLineChart.setStyleOptions({
            candle: {
              type: 'candle_solid'
            }
          })
        }
        this.kLineChart.applyNewData(arr)
        this.kLineChart.setPriceVolumePrecision(this.symbol.priceExchange, 2)
      })
    },
    UpKlineData(data) {
      let klineData = JSON.parse(JSON.stringify(data));
      let obj = {
        close: parseFloat(klineData.close),
        high: parseFloat(klineData.high),
        low: parseFloat(klineData.low),
        open: parseFloat(klineData.open),
        timestamp: parseFloat(klineData.time),
        turnover: null,
        volume: parseFloat(klineData.volume),
      }
      if (this.kLineChart) {
        this.kLineChart.updateData(obj)
      }
    },
    async initDeep() {
      try {
        // 等待组件加载
        await this.$nextTick()
        
        // 检查组件是否存在
        if (!this.$refs.deep) {
          console.warn('Deep component not ready')
          return
        }

        const response = await this.$api.contractDepthRender({
          partnerId: '472437831826935808',
          partnerNo: '1000010001',
          tradeName: this.symbol.symbol,
        })
        
        if (this.$refs.deep && response.data) {
          this.$refs.deep.setWatchData(response.data.depthRender)
        }
      } catch (error) {
        console.error('Init deep chart failed:', error)
      }
    },

    async UpDeepData(data) {
      try {
        await this.$nextTick()
        
        if (!this.$refs.deep) {
          console.warn('Deep component not ready')
          return
        }

        let deepData = JSON.parse(JSON.stringify(data))
        for (let index = 0; index < deepData.buys.length; index++) {
          const element = deepData.buys[index]
          element[0] = parseFloat(element[0])
          element[1] = parseFloat(element[1])
        }

        for (let index = 0; index < deepData.sells.length; index++) {
          const element = deepData.sells[index]
          element[0] = parseFloat(element[0])
          element[1] = parseFloat(element[1])
        }
        
        this.deepChartData = deepData
        if (this.$refs.deep) {
          this.$refs.deep.setWatchData(this.deepChartData)
        }
      } catch (error) {
        console.error('Update deep chart failed:', error)
      }
    },

    /* 改变周期 */
    changeCycle() {
      this.getMarketkline()
      this.$emit("changeTimeArea", this.selectType == 'min1' ? 'min' : this.selectType)
    },
    /* 改变类型 */
    changeKlineType() {
      this.getMarketkline('area')
      this.$emit("changeTimeArea", this.selectType == 'min1' ? 'min' : this.selectType)
    },
    getTimeShow(timestamp) {
      let arr = ['min15', 'hour1', 'hour4', 'min', 'min1', 'min5', 'min30']
      let idx = arr.indexOf(this.selectType)
      if (idx != -1) {
        return this.$moment(new Date(Number(timestamp))).format('HH:mm')
      } else {
        return this.$moment(new Date(Number(timestamp))).format('MM-DD HH:mm')
      }
    },
    /* 初始化设置 */
    initKlineData() {
      let that = this;
      return {
        candle: {
          tooltip: {
            showType: "rect",
            showRule: "follow_cross",
            labels: [`${this.$t('M.bb_time')}：`, `${this.$t('M.k_open')}：`, `${this.$t('M.k_max')}：`, `${this.$t('M.k_min')}：`, `${this.$t('M.k_close')}：`, `${this.$t('M.Change')}：`, `${this.$t('M.trading_volume')}：`],
            values: kLineData => {
              const change = (kLineData.close - kLineData.open) / kLineData.open * 100
              return [
                { value: that.getTimeShow(kLineData.timestamp ) },
                { value: that.cutOutPointLength(kLineData.open, that.symbol.priceExchange) },
                { value: that.cutOutPointLength(kLineData.high, that.symbol.priceExchange) },
                { value: that.cutOutPointLength(kLineData.low, that.symbol.priceExchange)   },
                { value: that.cutOutPointLength(kLineData.close, that.symbol.priceExchange) },
                {
                  value: `${change.toFixed(2)}%`,
                  color: change < 0 ? '#EF5350' : '#26A69A'
                },
                {
                  value: kLineData.volume,
                },
              ]
            }
          },
          area: {
            lineSize: 1,
            lineColor: '#c8a972',
            value: 'close',
            backgroundColor: [{
              offset: 0,
              color: 'rgba(254, 207, 120, 0)'
            }, {
              offset: 1,
              color: 'rgba(250, 220, 164, 0.28)'
            }]
          },
          priceMark: {
            last: {
              show: true,
              upColor: '#F10E38',
              downColor: '#F10E38',
              noChangeColor: '#F10E38',
              line: {
                show: true,
                style: 'dash',
                dashValue: [4, 4],
                size: 1
              },
              text: {
                show: true,
                size: 12,
                paddingLeft: 2,
                paddingTop: 2,
                paddingRight: 2,
                paddingBottom: 2,
                color: '#FFFFFF',
                family: 'Helvetica Neue',
                weight: 'normal',
                borderRadius: 2
              }
            }
          },
        },

        technicalIndicator: {
          margin: {
            top: 80,
            bottom: 0.1
          },
          tooltip: {
            showRule: 'always'
          },
          line: {
            size: 1,
            colors: ['#e0df78', '#75af9b', '#c2ade6',]
          },
        },
        yAxis: {
          inside: true,
        }
      }
    },
    initDeepOption() {
      return {
        chart: {
          type: 'areaspline',
          zoomType: 'xy',
          marginLeft: -5,
          marginRight: -5,
          animation: false
        },
        credits: {
          enabled: false
        },
        spacing: [0, 0, 0, 0],
        margin: null,
        title: {
          text: null,
        },
        plotBorderColor: "#ffffff",
        xAxis: {
          lineWidth: 0,
          gridLineWidth: 1,
          title: null,
          tickWidth: 0,
          tickLength: 0,
          tickPosition: 'inside',
          labels: {
            align: 'left',
            x: 8
          },
          gridLineColor: "#fafafa",
          Labels: false,
          allowDecimals: false,
        },
        yAxis: [{
          lineWidth: 0,
          gridLineWidth: 1,
          title: null,
          tickWidth: 0,
          tickLength: 0,
          labels: {
            align: 'left',
            x: 8
          },
          gridLineColor: "#fafafa",
          Labels: false,
          visible: false,
        }, {
          opposite: true,
          linkedTo: 0,
          lineWidth: 1,
          gridLineWidth: 0,
          title: null,
          tickWidth: 0,
          tickLength: 0,
          tickPosition: 'inside',
          labels: {
            align: 'right',
            x: -8
          },
          gridLineColor: "#fafafa",
          Labels: false,
        }],
        legend: {
          enabled: true
        },
        plotOptions: {
          area: {
            fillOpacity: 0.2,
            lineWidth: 1,
            step: 'center',
            showInLegend: false
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size=10px;">Price: {point.key}</span><br/>',
          valueDecimals: 2
        },
        series: [{
          data: [
            [
              21644.2,
              12.4578
            ]
          ],
          color: '#54aa87',
          showInLegend: false
        }, {
          data: [
            [
              21654.1,
              0.1475
            ]
          ],
          color: '#ec6b66',
          showInLegend: false
        }]
      }
    },
    /* 选择K线类型 */
    selectKlineType(type) {
      if (this.selectType == type) return;
      if (typeof type != "string") {
        this.selectKlinePopup = false;
        return
      }
      if (type != "option") {
        if (type == "deep") {
          this.KlineTableType = 2;
        } else {
          this.KlineTableType = 1;
        }
      }
      switch (type) {
        /* 更多 */
        case "more":
          this.selectKlinePopupType = 1;
          this.selectKlinePopup = true;
          break;
        /* 分时 */
        case "min":
          this.selectKlinePopup = false;
          this.selectType = type;
          this.showMoreText = this.$t('M.minhour')
          this.changeKlineType()
          break;
        /* 1分钟 */
        case "min1":
          this.selectKlinePopup = false;
          this.selectType = type;
          this.showMoreText = '1' + this.$t('M.minutes')
          this.changeCycle()
          break;
        /* 5分钟 */
        case "min5":
          this.selectKlinePopup = false;
          this.selectType = type;
          this.showMoreText = '5' + this.$t('M.minutes')
          this.changeCycle()
          break;
        /* 15分钟 */
        case "min15":
          this.selectKlinePopup = false;
          this.selectType = type;
          this.showMoreText = this.$t('M.more')
          this.changeCycle()
          break;
        /* 30分钟 */
        case "min30":
          this.selectKlinePopup = false;
          this.selectType = type;
          this.showMoreText = '30' + this.$t('M.minutes')
          this.changeCycle()
          break;
        /* 1小时 */
        case "hour1":
          this.selectKlinePopup = false;
          this.selectType = type;
          this.showMoreText = this.$t('M.more')
          this.changeCycle()
          break;
        /* 4小时 */
        case "hour4":
          this.selectKlinePopup = false;
          this.selectType = type;
          this.showMoreText = this.$t('M.more')
          this.changeCycle()
          break;
        /* 1天 */
        case "day":
          this.selectKlinePopup = false;
          this.selectType = type;
          this.showMoreText = this.$t('M.more')
          this.changeCycle()
          break;
        /* 1周 */
        case "week":
          this.selectKlinePopup = false;
          this.selectType = type;
          this.showMoreText = this.$t('M.more')
          this.changeCycle()
          break;
        /* 1月 */
        case "mon1":
          this.selectKlinePopup = false;
          this.selectType = type;
          this.showMoreText = '1' + this.$t('M.month')
          this.changeCycle()
          break;
        /* 深度图 */
        case "deep":
          this.selectKlinePopup = false;
          this.selectType = type;
          break;
        /* 选项 */
        case "option":
          this.selectKlinePopupType = 2;
          this.selectKlinePopup = true;
          break;

        default:
          this.selectKlinePopup = false;
          break;
      }
    },

  }
}

</script>

<style lang="scss" scoped>
.selectKlinePopup {
  position: absolute;
  top: 160px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 99;
  .timeBox {
    width: 100%;
    background-color: #fff;
    //height: 45px;
    display: flex;
    justify-content: space-around;
    border-top: #f4f4f6;
    padding-top: 10px;
    flex-wrap: wrap;
    .item {
      padding: 0px 15px;
      background-color: #f4f4f6;
      height: 26px;
      line-height: 24px;
      font-size: 12px;
      text-align: center;
      border-radius: 3px;
      border: 1px solid #f4f4f6;
      margin-bottom: 10px;
    }
    .itemActive {
      border: 1px solid #00b897;
      background-color: #fff;
      color: #00b897;
    }
  }
  .klineTypeBox {
    width: 100%;
    background-color: #fff;
    height: 110px;
    padding-top: 15px;
    .box {
      text-align: center;
      align-items: center;
      .typeName {
        width: 80px;
      }
      display: flex;
      .typeList {
        line-height: 24px;
        display: flex;
        .item {
          width: 56px;
          background-color: #f4f4f6;
          height: 26px;
          line-height: 24px;
          font-size: 12px;
          text-align: center;
          border-radius: 3px;
          border: 1px solid #f4f4f6;
          margin-right: 15px;
        }
        .itemActive {
          border: 1px solid #00b897;
          background-color: #fff;
          color: #00b897;
        }
      }
    }
  }
}
.optionHome {
  display: flex;
  margin-top: 16px;
  justify-content: space-evenly;
  flex-wrap: wrap;
  .item {
    display: flex;
    height: 17px;
    line-height: 17px;
    align-items: center;
    padding: 0px 4px;
  }
  .itemActive {
    color: #00b897;
  }
  .more {
    width: 16px;
    height: 13px;
  }
  .jiantou {
    width: 9px;
    height: 5px;
    margin-left: 3px;
  }
}
/*k线部分*/
.blockBg {
  background: #fff;
  margin-top: 5px;
}
* {
  font-size: 12px;
}
.tabsTitle {
  height: 28px;
  line-height: 28px;
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
  padding: 0 4%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
}
.tabsTitle span {
  width: 15%;
  height: 27px;
  line-height: 27px;
  border-bottom: 2px solid transparent;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  font-size: 12px;
}
.tabsTitle span.active {
  border-bottom-color: #217cd9;
  color: #217cd9;
}
.klineWrap {
  float: left;
  position: relative;
  overflow: hidden;
}
.kLineTabs .tabsTitle {
  padding-left: 2%;
}
.kLineTabs .tabsTitle span {
  font-size: 12px;
  width: 16%;
}
.kLineTabs .tabsTitle span:last-child {
  width: 18%;
}
.indexWrap {
  width: 100%;
  height: 30px;
  background-color: #f6fbfe;
  font-size: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.indexWrap .indexItem {
  display: block;
  line-height: 30px;
  flex-grow: 1;
  text-align: center;
}
.indexWrap .indexItem.active {
  color: #333;
  background-color: #e1ecf2;
}
.highcharts-credits {
  display: none;
}
#technical-indicator-k-line {
  height: 500px;
}
#deepBox {
  // height: 500px;
}
</style>
