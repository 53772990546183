<template>
  <div class='container'>
    <div class="topBg">
      <!-- 顶部nav -->
      <nav-bar :showLeft="false" :showRight="false">
        <router-link slot="left" class="left" to="userCenter">
          <img alt="" class="icon_mine" src="@/assets/img/home/icon_mine.png">
        </router-link>
        <div slot="right" class="right">
          <a href="https://t.me/snowTGname" target="_blank" rel="noopener noreferrer">
            <img class="icon_kefu" src="@/assets/img/home/icon_kefu.png"
                 alt="">
          </a>
        </div>
      </nav-bar>
      <div v-if="isLogin" class="assets_total">
        <div class="row flexC">
          <span>{{ $t("M.Total_assets_valuation") }}</span>
          <img class="icon" v-show="showEye" src="@/assets/img/assets/icon_eyeoon.png" alt="" @click="onClickShow">
          <img class="icon icon_eyeoff" v-show="!showEye" src="@/assets/img/assets/icon_eyeoff.png" alt=""
               @click="onClickShow">
        </div>
        <div class="total">
          <span>
            {{ showEye ? totalCNY ? totalCNY : '0' : '******' }}
          </span>
          <span>{{ showEye ? currencySymbol : '' }}</span>
        </div>
      </div>
<!--      &lt;!&ndash; 公告轮播 &ndash;&gt;-->
<!--      <div v-if="listNewShow" class="listNew">-->
<!--        <img alt="" class="gonggao" src="../../assets/img/home/laba.png" srcset="">-->
<!--        <div class="new">-->
<!--          <van-swipe autoplay="3000" class="my-swipe-noties" indicator-color="white" vertical>-->
<!--            <van-swipe-item v-for="(item, i) in listNew" :key="i" @click="goContent(item.id)">-->
<!--              {{ item.title }}-->
<!--            </van-swipe-item>-->
<!--          </van-swipe>-->
<!--        </div>-->
<!--        <img alt="" class="right" src="../../assets/img/home/mianbao.png" srcset="" @click="gonews">-->
<!--      </div>-->


    </div>

    <!-- 行情 -->
    <market></market>

    <!--    &lt;!&ndash; 广告位 &ndash;&gt;-->
        <div class="advert">
          <div class="advert-item advert-item1">
            <router-link to="IEO">
              <p class="title">{{ $t("M.NewTokenSubscribe") }}</p>
            </router-link>
          </div>
          <div class="advert-item advert-item2">
            <router-link to="LockUp">
              <p class="title">{{ $t("M.Lock-up mining") }}</p>
            </router-link>
          </div>
        </div>
    <!-- 导航 -->
    <van-grid :border="false" :column-num="5">
      <van-grid-item v-for="(item, i) in navGrid" :key="i"
                     @click="onClickGrid(item.path)">
        <img :src="item.src"/>
        <span class="text"> {{ item.text }} </span>
      </van-grid-item>
    </van-grid>
    <!-- 轮播 -->
    <div class="banner">
      <van-swipe :autoplay="banner_time" class="my-swipe" indicator-color="white">
        <van-swipe-item v-for="(item, i) in bannerList" :key="i" @click="onClickBanner(item)">
          <img :src="item.appUrl" alt="">
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 排行榜 -->
    <ranking></ranking>

    <van-overlay :show="overlayShow" @click="overlayShow = false" class-name="overlayShow">
      <div class="wrapper" @click.stop>
        <div class="tipsBox">
          <div class="contant">
            <img class="logo" src="../../assets/img/home/logo.png" alt="">
            <div class="tipsTextBox">
              <p><span v-if="!safireFlag">{{ $t('M.PDleaseUseSafariBrowser') }}</span>{{ $t('M.ADddToDesktop') }}</p>
              <p class="icon">
                {{ $t('M.CDlickBelow') }}
                <img src="../../assets/img/home/share.png" alt="" srcset="">
              </p>
              <p>
                {{ $t('M.SDelectAddToHomeScreen') }}
              </p>
            </div>
          </div>
          <img class="jiantou" src="../../assets/img/home/jiantou.png" alt="">
          <img class="closeImg" @click="overlayShow = false" src="../../assets/img/home/close.png" alt="">
        </div>
      </div>
    </van-overlay>
    <!--  -->
    <!-- 新币认购弹窗 -->
    <van-dialog v-model="coinNewShow" :title="$t('M.New Token Subscribe')" :confirmButtonText="$t('M.Subscribe Now')"
                confirmButtonColor="#ffffff" @confirm="goIEO">
      <img src="../../assets/img/assets/icon_close.png" class="close" @click="coinNewShow = false" alt="">
      <div class="coinContent" v-html="coinNewData.content">


      </div>
    </van-dialog>
    <!-- 普通公告弹窗 -->
    <van-dialog v-model="noticeDialogVisible" :title="$t('M.Announcement')" :confirmButtonText="$t('M.View details')"
                :cancelButtonText="$t('M.i_see1')" showCancelButton confirmButtonColor="#ffffff" @confirm="lookContent"
                @cancel="iknow">
      <div class="coinContent">
        {{ noticeData.title }}
      </div>
    </van-dialog>

  </div>
</template>
<script>
import navBar from "@/components/navBar";
import ranking from "./components/ranking";
import {GetCurrentBrowser} from "@/utils/ststemTool";
import {mapGetters} from 'vuex'
import market from "./components/marketQuotations";
import { cutOutPointLength } from "@/utils/util.js";


//import {} from "@/api/Controller";
export default {
  components: {navBar, ranking, market},
  props: {},
  data() {
    return {
      navGrid: [{
        text: this.$t("M.Margined"),
        path: 'contract',
        src: require('@/assets/img/public/grid_licai.png'),
      },
      // {
      //   text: this.$t("M.Sec Swap"),
      //   path: 'contract',
      //   src: require('@/assets/img/public/grid_option.png')
      // },
       {
        text: this.$t("M.recharge_withdraw_msg_type_0001"),
        path: 'recharging?type=recharge',
        src: require('@/assets/img/public/grid_chongbi.png')
      },
        // {
        //   text: 'Fiat',
        //   path: 'IframePage',
        //   src: require('@/assets/img/public/grid_tibi.png'),
        // },
        // { // 客服
        //   text: this.$t("M.Service"),
        //   path: 'serve',
        //   src: require('@/assets/img/public/grid_kefu.png')
        // },
        { //交易
          text: this.$t("M.tab_trade"),
          path: 'TradeCenter',
          src: require('@/assets/img/public/grid_trade.png'),
        }, {
          text: this.$t("M.Assets"),
          path: 'assets',
          src: require('@/assets/img/public/grid_assets.png'),
        },
        // {// 邀请
        //   text: this.$t("M.Invitation"),
        //   path: 'invitation',
        //   src: require('@/assets/img/public/grid_invitation.png'),
        // },
      ],
      navBannerList: [
        {
          text: this.$t("M.NewTokenSubscribe"),
          smalltext: '',
          path: 'IEO',
          src: require('@/assets/img/home/IEO.png'),
        },
        {
          text: this.$t("M.LockUpDuan"),
          smalltext: this.$t("M.MoreWealthAwaitsYou"),
          path: 'LockUp',
          src: require('@/assets/img/home/Lockup.png'),
        }
      ],
      banner_time: 3000,
      bannerList: [],
      overlayShow: false,
      safireFlag: false,
      listNew: [],
      coinNewShow: false, //新币认购弹窗
      coinNewData: {}, //新币认购弹窗数据
      noticeDialogVisible: false, //普通弹窗显示
      noticeData: {},  //普通弹窗数据
      listNewShow: true,
      fatPayUrl: '',
      fiatShow: false,
      totalCNY: "--"
    };
  },
  computed: {
    ...mapGetters([
      'partnerId', 'lang', "isLogin",'currencySymbol', 'accountCNY', 'showEye', 'user'
    ]),
  },
  watch: {},
  beforeMount() {
    this.getBanner()
  },
  mounted() {
    console.log(this.user.remark);
    setTimeout(() => {
      this.getNewCarousel()
      this.getHomeMoney()
    }, 300);
  },
  methods: {
    // 资产隐藏显示
    onClickShow() {
      this.$store.dispatch('setShowEye')
    },
    /* 获取首页的资产 */
    getHomeMoney() {
      if (this.isLogin) {
        this.$api.exchangeRate({ currency: "USD" }).then((response) => {
          this.$api.queryAccountCNY().then((response2) => {
            const totalCNY = cutOutPointLength(
                response2.data.totalCNY / response.data,
                2
            );
            this.totalCNY = totalCNY;
          })
        })
      }
    },
    /* 点击banner */
    onClickBanner(item) {
      if (item.appRedirectUrl.length > 0) {
        if (item.content == "inner") {
          this.$router.push({
            name: 'newsContent', query: {id: item.appRedirectUrl.split("/").pop()}
          })
        } else {
          window.location.href = item.appRedirectUrl
        }
      }
    },
    /* 点击跳转新闻列表 */
    gonews() {
      this.$router.push('news')
    },
    /* 查看详情 */
    goContent(id) {
      this.$router.push({
        name: 'newsContent', query: {id: id}
      })
    },
    /* 点击新币跳转 */
    goIEO() {
      this.$router.push('IEO')
    },
    /* 查看详情 */
    lookContent() {
      this.$router.push({
        name: 'newsContent', query: {id: this.noticeData.id}
      })
    },
    /* 我知道了 */
    iknow() {
      this.$api.updatePopupStatus({id: this.noticeData.id}).then((response) => {
      })
    },
    yindao() {
      this.safireFlag = GetCurrentBrowser() == "Safari";

      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        this.overlayShow = true;
      } else {
        this.$router.push("guide")
      }
    },
    getBanner() {
      this.$api.withBannerTime({language: this.lang, partnerId: this.partnerId}).then((response) => {
        if (!response.meta.success) return
        const {banner, banner_time} = response.data
        this.bannerList = banner
        this.banner_time = banner_time * 1000
      })
    },
    /* 首页弹窗 */
    getNewCarousel() {
      /* 滚动公告 */
      this.$api.newCarousel({language: this.lang, partnerId: this.partnerId}).then((response) => {
        if (!response.meta.success) return
        this.listNew = response.data.newsList
        if (this.listNew.length <= 0) this.listNewShow = false;
      })
      /* 获取跳转窗口 */
      this.$api.getAllNewsNotices2({language: this.lang}).then((response) => {
        if (!response.meta.success) return
        /* 当登录状态,且没有新币认购数据的时候,去请求普通的弹窗 */
        if (Object.keys(response.data).length == 0) {
          if (this.isLogin) {
            /* 获取详情 */
            this.$api.selectPopupByUserId({language: this.lang}).then((response2) => {
              if (response2.data.isPopup == "true") {
                this.noticeDialogVisible = true;
                this.noticeData = response2.data.dataList[0];
              }
            })
          }
        } else {
          this.coinNewData = response.data;
          this.coinNewShow = true;
        }
      })
    },
    // 金刚区导航
    async onClickGrid(path) {
      if (path === 'IframePage') { // fiat导航
        const result = await this.getFiatShow()
        if (result == 'false') return this.$notify(this.$t('M.ServiceUpgrade'))
        return this.$router.push({path})
      }
      // 其他导航正常跳转
      this.$router.push(path)
    },

    /* 查询fiat 是否显示 */
    async getFiatShow() {
      const params = {
        partnerId: this.partnerId
      }
      const res = await this.$api.queryFiatShow(params)
      if (!res.data) return true
      this.fiatShow = res.data.FiatShow != 'false' ? true : false
      return res.data.FiatShow
    }


  },
};
</script>
<style lang="scss" scoped>
.right {
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin-left: 25px;
  }
}

.topBg {
  //background-image: url("../../assets/img/home/bg.png");
  background-size: 100% 100%;
  padding-bottom: 1px;
  background: linear-gradient(24deg, #fff 0%, #C8ECE9 100%);
}

::v-deep .topBg .van-nav-bar {
  background: transparent;
  position: relative !important;
}

.left .icon_mine {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  // background: #ccc;
}

.banner {
  padding: 0 15px;

  .my-swipe {
    border-radius: 5px;
    overflow: hidden;

    .van-swipe-item {
      color: #fff;
      font-size: 20px;
      height: 136px;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
      }
    }
  }
}

.listNew {
  width: 100%;
  height: 45px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  margin-top: 5px;

  .gonggao {
    height: 14px;
    margin-top: 4px;
  }

  .new {
    flex: 1;
    height: 22px;
    overflow: hidden;
    padding-left: 10px;
    transform: translateY(2px);
  }

  .right {
    height: 18px;
  }

  .van-swipe-item {
    font-size: 12px;
    line-height: 20px;
  }
}

.advert {
  width: 100%;
  padding: 0 15px;
  display: flex;

  .advert-item {
    width: 100%;
    height: 80px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;
    display: flex;

    .towleve {
      width: 98%;
      position: relative;

      .biao {
        position: absolute;
        right: 6px;
        width: 21px;
        height: 21px;
      }

      .title1 {
        top: 20px;
      }
    }

    > a {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .title {
      position: absolute;
      text-align: right;
      font-size: 18px;
      color: #ed6a20;
    }

    .title1 {
      position: absolute;
      right: 22px;
      top: 14px;
      text-align: right;
      font-size: 18px;
      color: #ad813d;
    }

    .smalltitle2 {
      position: absolute;
      text-align: left;
      color: #ad813d;
    }
  }
  .advert-item1{
    background-image: url("../../assets/img/home/wa1.png");
    background-size: 100% 100%;
    margin-right: 8px;
    .title{
      left: 10px;
      top: 16px;
      color: #000;
      font-size: 14px;
      line-height: 16px;
      width: 91px;
      text-align: left;
    }
  }
  .advert-item2{
    background-image: url("../../assets/img/home/wa2.png");
    background-size: 100% 100%;
    .title{
      left: 10px;
      top: 16px;
      color: #000;
      font-size: 14px;
      line-height: 16px;
      width: 91px;
      text-align: left;
    }
  }
}

.overlayShow {
  background-color: rgba(0, 0, 0, 0.5);
}

.tipsBox {
  width: 90%;
  position: absolute;
  bottom: 10px;
  left: 5%;
  padding-bottom: 7px;

  .contant {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;

    .logo {
      width: 66px;
      // height: 66px;
    }

    .tipsTextBox {
      margin-left: 12px;
      color: #191b27;
      font-size: 13px;
      line-height: 20px;
      padding-right: 30px;

      .icon {
        display: flex;
        align-items: center;

        img {
          width: 14px;
          height: 16px;
          margin-left: 5px;
        }
      }
    }
  }

  .jiantou {
    position: absolute;
    bottom: 0;
    width: 13px;
    height: 7px;
    left: 50%;
    transform: translateX(-50%);
  }

  .closeImg {
    position: absolute;
    right: 5px;
    width: 17px;
    height: 17px;
    top: 10px;
    transform: translateX(-50%);
  }
}

.close {
  position: absolute;
  right: 8px;
  top: 12px;
  width: 24px;
  height: 24px;
}

.coinContent {
  padding: 20px 20px;
  text-align: center;

  .close {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 24px;
    height: 24px;
  }
}
</style>

<style lang="scss" scoped>
::v-deep {
  .van-swipe__indicators {
    .van-swipe__indicator {
      width: 8px;
      height: 2px;
      border-radius: 2px;
    }
  }

  .van-grid {
    // margin: 10px 0;
    background-color: #fff;
    margin: 0 15px;
    margin-bottom: 10px;
    border-radius: 4px;

    .van-grid-item {
      img {
        width: 32px;
        height: 32px;
        margin-bottom: 7px;
      }
    }

    .text {
      font-size: 12px;
      color: #191b27;
    }

    .van-grid-item__content {
      padding-left: 0;
      padding-right: 0;
      background: transparent;
    }
  }

  .van-dialog__footer {
    .van-dialog__confirm {
      background-color: #dcb067;
    }

    .van-dialog__cancel {
      background-color: #f3f3f3;
      color: #191b27;
    }
  }
}

.assets_total {
  width: 90%;
  font-weight: 400;
  color: #000;
  padding: 25px 0 0 15px;
  word-wrap: break-word;
  box-sizing: border-box;
  background-color: #00B897;
  margin: 0 auto;
  border-radius: 8px 8px 8px 8px;
  color: rgba(255, 255, 255, 0.64);
  margin-top: 10px;

  .flexC {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 20px;
    // height: 13px;
    margin: 0 20px;
    transform: translateY(1px);
  }

  .icon_eyeoff {
    width: 18px;
    // height: 8px;
  }

  .total {
    font-weight: bold;
    line-height: 50px;

    >span:first-child {
      font-size: 30px;
      font-family: PingFang SC-Semibold, PingFang SC;
      color: #fff;
    }
  }
}
</style>
