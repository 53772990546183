<template>
  <div class="setPassword container">
    <van-nav-bar :title="$t('M.real_name_authentication')" left-arrow left-text="" @click-left="onClickLeft"/>

    <div class="content">

      <van-cell-group class="mb" @click="countriesListShow = true">
        <van-cell :title="`${$t('M.nationality')} - ${showCountrName}`" is-link/>
      </van-cell-group>
      <div class="xian"></div>
      <van-cell-group class="mb">
        <van-cell is-link @click="typeFlag = !typeFlag">
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <span class="custom-title">
              <span class="custom-title">{{ $t('M.id_type') }} - {{ typeName }}</span>
            </span>
          </template>
        </van-cell>
        <van-cell v-if="typeFlag" @click="selectType(3)">
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <span class="custom-title">
              <span class="custom-title"><span
                  style="color:#fff;">{{ $t('M.id_type') }}</span> - {{ $t('M.Driver ID') }}</span>
            </span>
          </template>
        </van-cell>
        <van-cell v-if="typeFlag" @click="selectType(1)">
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <span class="custom-title">
              <span class="custom-title"><span
                  style="color:#fff;">{{ $t('M.id_type') }}</span> - {{ $t('M.identity_card') }}</span>
            </span>
          </template>
        </van-cell>
        <van-cell v-if="typeFlag" @click="selectType(2)">
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <span class="custom-title">
              <span class="custom-title"><span style="color:#fff;">{{ $t('M.id_type') }}</span> - {{ $t('M.passport') }}</span>
            </span>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="xian"></div>

      <van-cell-group class="mb">
        <van-cell>
          <template>
            <span class="custom-title">
              <van-field v-model="nameValue" :label="$t('M.name')" :placeholder="$t('M.input_name')" maxlength="100"/>
            </span>

          </template>
        </van-cell>
      </van-cell-group>
      <div class="xian"></div>
      <van-cell-group class="mb">
        <van-cell>
          <!-- 使用 title 插槽来自定义标题 -->
          <template>
            <span class="custom-title">
              <van-field v-model="idvalue" :label="$t('M.id_cord')" :placeholder="$t('M.input_id_cord')" maxlength="30"
                         @input="idinputEv"/>
            </span>

          </template>
        </van-cell>
      </van-cell-group>

      <div class="xian"></div>

      <van-cell-group v-if="typeFlagNumber == 2" class="mb" @click="Dateshow = true">
        <van-cell :title="$t('M.date_of_birth')">
          <!-- 使用 title 插槽来自定义标题 -->
          <template>
            <span class="custom-title">
              {{ showDataText }} <img alt="" src="../../assets/img/user/date.png" srcset=""
                                      style="width:15px;height: 15px;">
            </span>

          </template>
        </van-cell>
      </van-cell-group>

      <van-popup v-model="Dateshow" position="bottom">
        <van-datetime-picker v-model="currentDate" :max-date="maxDate" :min-date="minDate"
                             type="date"
                             @cancel="Dateshow = false" @change="changeDate" @confirm="Dateshow  = false"/>
      </van-popup>

      <div class="next" @click="send">{{ $t('M.submitBtn') }}</div>
    </div>
    <van-popup v-model="countriesListShow" :style="{ height: '100%' }" position="bottom">
      <van-search v-model="inputCountrText" :action-text="$t('M.Cancel')" :autofocus="true"
                  :show-action="true"
                  placeholder="Please select country or region" @cancel="countriesListShow = false"
                  @input="changeInput"/>
      <div class="countriesListBox">
        <div v-for="(item, idx) in showCountriesListData" :key="idx" class="listItem" @click="selectItem(item)">
          <div class="name">{{ item.english }}</div>
          <div class="number">+{{ item.nationCode }}</div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      Dateshow: false,
      nameValue: '',
      idvalue: '',
      countriesListShow: false,
      countriesListData: [],
      showCountriesListData: [],
      inputCountrText: '',
      value: '',
      /* 国家代码 */
      country: '',
      /* 显示国家的名字 */
      showCountrName: 'USA',
      /* 显示证件泪飙 */
      typeFlag: false,
      /* 证件名字 */
      typeName: '',
      /* 证件类型 */
      typeFlagNumber: 3,
      /* 生日 */
      birthady: new Date(2022, 0, 1),
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      showDataText: '',
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
  },
  beforeMount() {

  },
  mounted() {
    this.$api.selectList().then((res) => {
      // this.link = res.data.link;
      this.countriesListData = res.data;
      this.showCountriesListData = res.data;
      this.country = this.user.country || '1';
      const countr = this.countriesListData.find(item => item.nationCode == this.country)
      this.showCountrName = countr && countr.english
    })
    this.typeName = this.$t('M.Driver ID')

    // let idx = this.countriesListData.findIndex((item) => {
    //   return item.symbol == this.country
    // })

    // this.showCountrName = this.countriesListData[idx] && this.countriesListData[idx].chinese
  },
  methods: {
    changeDate() {
      this.showDataText = this.currentDate.toLocaleDateString().replace(/\//g, '\-');
    },
    idinputEv() {
      this.idvalue = this.idvalue.replace(/[^\x00-\xff]/g, '')
    },
    send() {
      if (this.nameValue == '') {
        this.$notify(this.$t('M.input_name'))
        return
      }
      if (this.idvalue == '') {
        this.$notify(this.$t('M.valid_identity_card'))
        return
      }
      if (this.typeFlagNumber == 2 && this.nameValue == '') {
        this.$notify(this.$t('M.valid_identity_card'))
        return
      }

      let params = {
        cardType: this.typeFlagNumber,
        realname: this.nameValue,
        cardNo: this.idvalue,
        country: this.showCountrName,
        birthday: this.showDataText
      }

      this.$api.realNameAuth(params).then((res) => {
        /* 成功跳转 - 身份列表 */
        this.$store.dispatch('getUserData')
        this.$emit('changeData')
      })

    },
    /* 选择了证件类型 */
    selectType(num) {
      switch (num) {
        case 3:
          this.typeName = this.$t('M.Driver ID')
          break;
        case 1:
          this.typeName = this.$t('M.identity_card')
          break;
        case 2:
          this.typeName = this.$t('M.passport')
          break;

        default:
          break;
      }
      this.typeFlag = false;
      this.nameValue = ''
      this.idvalue = ''
      this.typeFlagNumber = num
    },
    /* 选中了某一个国家 */
    selectItem(data) {
      this.country = data.nationCode;
      this.showCountrName = data.english;
      this.countriesListShow = false;
    },
    /* 改变输入内容 */
    changeInput() {
      if (this.inputCountrText == "") {
        this.showCountriesListData = this.countriesListData;
      } else {
        let showArr = [];
        for (const iterator of this.countriesListData) {
          if (
              iterator.chinese.indexOf(this.inputCountrText) != -1 ||
              iterator.english.indexOf(this.inputCountrText) != -1 ||
              iterator.nationCode.indexOf(this.inputCountrText) != -1
          ) {
            showArr.push(iterator);
          }
        }
        this.showCountriesListData = showArr;
      }
    },
    copyCode() {

    },
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.setPassword {
  font-size: 12px;
}

.content {
  background-color: #f8f8f8;
  padding: 10px 10px;
  height: 100vh;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-field {
  padding: 0;
}

.xian {
  width: 100%;
  height: 15px;
  background-color: #f8f8f8;
}

.next {
  width: 100%;
  height: 46px;
  background: #00b897;
  border-radius: 4px;
  text-align: center;
  line-height: 46px;
  font-size: 18px;
  color: #fff;
  margin-top: 50px;
}

::v-deep .van-popup {
  .van-search--show-action {
    padding-right: 10px;
  }

  .van-search__content {
    border-radius: 5px;

    .van-icon {
      color: #8a8a8a;
    }
  }

  .van-search__action {
    color: #8a8a8a;
  }

  .countriesListBox {
    .listItem {
      font-size: 14px;
      line-height: 54px;
      margin: 0 19px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e5e5e5;
    }
  }
}
</style>
