<template>
  <div class="bar">
    <van-nav-bar :title="title" :left-text="leftText" :left-arrow="showLeft" :showTitle="showTitle" :showRight="showRight" :border="border" fixed
      @click-left="onClickLeft" @click-right="onClickRight">

      <slot v-if="!showLeft" slot="left" name="left" />

      <slot v-if="!showTitle" slot="title" name="title" />

      <slot v-if="!showRight" slot="right" name="right" />

    </van-nav-bar>
  </div>
</template>

<script>
export default {
  name: 'navBar',
  props: {
    title: {
      type: String,
      default: ''
    },
    leftText: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: false
    },
    showLeft: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showRight: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {
    onClickLeft() {
      // console.log('返回');
      if (!this.showLeft) return
      this.$router.back()
    },
    onClickRight() {
      // if (!this.showRight) return
      // console.log('按钮');

    }
  },
};
</script>

<style lang="scss" scoped>
.bar {
  height: 50px;
}
::v-deep {
  .van-nav-bar {
    height: 50px;
    line-height: 50px;
    .van-nav-bar__content {
      height: 100%;
    }
    a {
      display: flex;
      align-items: center;
    }
  }
  .van-nav-bar__arrow {
    font-size: 14px;
  }
  .van-icon-arrow-left {
    color: #191b27;
  }
  .van-nav-bar__right {
    // font-size: 14px;
    color: #5c6573;
    font-weight: 400;
    > a {
      color: #5c6573;
    }
  }
  .van-nav-bar__title {
    font-size: 16px;
    > a {
      display: flex;
      align-items: center;
    }
    img {
      width: 10px;
      height: 5px;
      margin-left: 5px;
    }
  }
}
</style>