import Vue from 'vue'
// import { resetRouter } from '@/router'
import {getToken, removeToken} from '@/utils/auth'
import {SET_LANG, SET_SELECT_ADDRESS, SET_WALLET_COIN, SET_WITHDRAW_ADDRESS} from './mutation-types'

const getDefaultState = () => {
    return {
        token: getToken(),
        lang: localStorage.getItem('lang') || 'en_US',
        user: {},
        rate: 7.2, // 汇率
        coinList: [], // 币种列表
        selectCoin: {},
        walletData: {}, // 钱包账户资产
        newOptionData: {}, // 秒合约账户资产
        newFinance: {},//永续资产
        isLogin: false, //是否已经登录
        walletFlowCoin: {},
        userWithdrawAddress: {},  // 地址
        selectAddress: {},
        otherUser: {},
        userPayPassConfig: {}, // 用户免密设置
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_RATE: (state, rate) => {
        state.rate = rate
    },
    SET_COIN_LIST: (state, coinList) => {
        state.coinList = coinList
    },
    SET_SELECT_COIN: (state, selectCoin) => {
        state.selectCoin = selectCoin
    },
    RESET_STATE_SELECT_COIN: (state) => {
        state.selectCoin = state.coinList[0] || {}
    },
    SET_WALLET_ASSETS: (state, walletData,) => {
        state.walletData = walletData
    },
    SET_NEW_OPTION_ASSETS: (state, data,) => {
        state.newOptionData = data
    },
    SET_NEW_Finance_ASSETS: (state, data,) => {
        state.newFinance = data
    },
    SET_USERDATA: (state, userData,) => {
        state.user = userData
    },
    SET_OTHERUSER: (state, otherUser,) => {
        state.otherUser = otherUser
    },
    SET_IS_LOGIN: (state, flag,) => {
        state.isLogin = flag
    },
    [SET_WALLET_COIN]: (state, data,) => {
        state.walletFlowCoin = data
    },
    [SET_WITHDRAW_ADDRESS]: (state, data,) => {
        state.userWithdrawAddress = data
    },
    [SET_SELECT_ADDRESS]: (state, data,) => {
        state.selectAddress = data
    },
    [SET_LANG]: (state, data,) => {
        state.lang = data
        localStorage.setItem('lang', data)
    },
    SET_USER_PAY_PASSWORD: (state, data,) => {
        state.userPayPassConfig = data
    },
    setHeadUrl: (state, data,) => {
        state.user.logo = data
    },
}

const actions = {
    setHeadUrl({ commit }, data) {
        data = data || ''
        commit('setHeadUrl', data)
    },
    setRate({ commit }, data) {
        data = data || ''
        commit('SET_RATE', data)
    },
    setCoinList({ commit }, data) {
        data = data || []
        commit('SET_COIN_LIST', data)
        commit('RESET_STATE_SELECT_COIN')
    },

    setSelectCoin({ commit }, data) {
        data = data || []
        commit('SET_SELECT_COIN', data)
    },
    resetSelectCoin({ commit }) {
        commit('RESET_STATE_SELECT_COIN')
    },

    /* 获取钱包信息 */
    getUserWalletAssets({ commit, state }, selectType = 'all') {
        return new Promise((resolve, reject) => {
            Vue.prototype.$api.getUserWalletAssets({ selectType: selectType,pageNum:1,pageSize:1000  }).then(response => {
                if (!response.meta.success) return resolve()
                const { data } = response
                //过滤数组
                let filtrationArray =['USDT','USDC','BTC','ETH','TRX','OXS']
                let getList = data.userCoinWalletVOPageInfo.list
                //筛选getList不包含filtrationArray的项目
                getList = getList.filter(item => filtrationArray.includes(item.coinName))
                const amountCNY = Vue.prototype.cutOutPointLength(data.amountCNY / state.rate, 2)
                commit('SET_WALLET_ASSETS', { ...data, amountCNY })
                commit('SET_COIN_LIST', getList)
                commit('RESET_STATE_SELECT_COIN')
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 秒合约资产
    getUserOptionFinanceList({ commit, state }) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$api.getUserOptionFinanceList().then(response => {
                if (!response.meta.success) return resolve()
                const { data } = response
                const totalSum = Vue.prototype.cutOutPointLength(data.totalSum / state.rate, 2)
                commit('SET_NEW_OPTION_ASSETS', { ...data, totalSum })
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })

    },
    // 永续资产
    getUserContractFinanceList({commit, state}) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$api.getUserContractFinanceList().then(response => {
                if (!response.meta.success) return resolve()
                const {data} = response
                const totalSum = Vue.prototype.cutOutPointLength(data.totalSum / state.rate, 2)
                commit('SET_NEW_Finance_ASSETS', {...data, totalSum})
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })

    },


    /* 获取用户的全部基本信息 */
    getUserData({ commit, state }) {
        if (state.token == 'undefined' || !state.token || state.token == 'null' || state.token == null) return;
        return new Promise((resolve, reject) => {
            Vue.prototype.$api.userRefreshUser({ token: state.token }).then(response => {
                if (response.meta.success) {
                    const { userInfo } = response.data
                    commit('SET_USERDATA', userInfo)
                    commit('SET_OTHERUSER', response.data)
                    commit('SET_IS_LOGIN', true)
                    resolve(response)
                } else {
                    commit('SET_IS_LOGIN', false)
                    reject(response)
                }
            }).catch(error => {
                // 添加错误对象检查
                if (error && error.meta && error.meta.code === 401) {
                    commit('SET_IS_LOGIN', false)
                    localStorage.setItem('token', '')
                    commit('SET_TOKEN', '')
                }
                reject(error)
            })
            Vue.prototype.$api.exchangeRate({ currency: 'USD' }).then(response => {
                if (response.meta.success) {
                    commit('SET_RATE', response.data)
                    resolve(response)
                } else {
                    reject(response)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$api.logout().then(() => {
                removeToken() // must remove  token  first
                commit('RESET_STATE')
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    },

    // clearUserta
    clearUserData({ commit }) {
        removeToken() // must remove  token  first
        commit('RESET_STATE')
    },

    // user password free setting
    userPayPasswordSet({ commit }) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$api.partnerConfigAndUserPayPassSet().then(response => {
                if (!response.meta.success) return resolve()
                // const { partnerPayPasswdTime, notInputPayPasswdTime } = response.data
                commit('SET_USER_PAY_PASSWORD', response.data)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })

    },

}

export default {
    state,
    mutations,
    actions
}

