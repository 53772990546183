<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-19 14:50:18
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="setPassword container">
    <van-nav-bar :title="$t('M.change_phone')" left-text="" left-arrow @click-left="onClickLeft" />

    <div class="content">
      <van-cell-group>
        <van-cell :title="user.phone.substr(0, 3) + '****' + user.phone.substr(-4)">
          <template #right-icon>
            <div style="color:#dcb069;" @click="sendMsg" v-if="downTimeNum <= 0">{{$t("M.get_code")}}</div>
            <div style="color:#dcb069;" v-if="downTimeNum > 0">{{downTimeNum}}s</div>
          </template>
        </van-cell>
        <van-cell title="">
          <template #right-icon>
            <van-field v-model="value" size="large" :placeholder="$t('M.login_please_input1')" style="padding:0;" />
          </template>
        </van-cell>
      </van-cell-group>

      <div class="tips">{{$t('M.my_prompt_001')}}</div>

      <div class="next" @click="next">
        {{$t("M.next")}}
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
export default {

  data() {
    return {
      link: '',
      value: '',
      downTimeNum: 0,
      timeObj: null,
      getMsg: false,
    }
  },
  computed: {
    ...mapGetters([
      'user', "otherUser"
    ]),
  },
  mounted() {
    console.log(this.otherUser)
    // this.$api.getShareLink().then((res) => {
    //   console.log(res)
    //   this.link = res.data.link;
    // })
  },
  methods: {
    /* 下一步 */
    next() {
      if (this.value == '') {
        this.$notify(this.$t('M.login_please_input1'))
        return
      }
      this.$api
        .validate({
          partnerId: "472437831826935808",
          phone: this.user.phone,
          phoneCode: this.value,
          action: "UPDATE_PHONE"
        })
        .then((data) => {
          console.log(data)
          if (data.meta.success) {
            this.$router.push({ name: "changePhoneNumber", query: { oldCode: this.value } })
          } else {
            this.$notify(data.meta.message)
          }
        });
    },
    /* 获取验证码 */
    sendMsg() {
      this.$api
        .sendSms({
          partnerId: "472437831826935808",
          phone: this.user.phone,
          nationCode: this.user.country,
          action: "UPDATE_PHONE"
        })
        .then((data) => {
          if (data.meta.success) {
            this.getMsg = true;
            this.downTimeNum = 59;
            this.timerDown();
            this.$notify(data.meta.message)
          } else {
            this.$notify(data.meta.message)
          }
        });
    },
    /* 倒计时开启 */
    timerDown() {
      this.downTimeNum;
      this.timeObj = setInterval(() => {
        this.downTimeNum--;
        if (this.downTimeNum <= 0) {
          this.downTimeNum = 0;
          clearInterval(this.timeObj);
        }
      }, 1000);
    },
    /* 更换手机 */
    goChangePhonePage() {
      this.$router.push("changePhone")
    },
    /* 开关切换 */
    onInputSwitch() {
      let num = 0;
      if (this.otherUser.isEnablePhone) {
        num++;
      }
      if (this.otherUser.isEnableMail) {
        num++;
      }
      if (this.otherUser.isEnableGoogle) {
        num++;
      }

      if (num <= 1) {
        this.$notify(this.$t('M.must_retain_one'))
      }

    },
    onClickLeft() {
      this.$router.back()
    }
  },
  destroyed() {
    clearInterval(this.timeObj)
  }
}
</script>

<style lang="scss" scoped>
.setPassword {
  font-size: 12px;
  background-color: #f8f8f8;
  .content {
    padding: 12px;
    border-radius: 8px;
    .van-cell-group {
      border-radius: 10px;
    }
  }
  .tips {
    margin-top: 20px;
    color: #5c6573;
  }
  .next {
    width: 345px;
    height: 46px;
    background: #00b897;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    line-height: 46px;
    margin: 0 auto;
    margin-top: 30px;
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
</style>