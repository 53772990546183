export const M = {
    "Slideright": "Veuillez maintenir le curseur et le glisser complètement à droite",
    "Pleaseverification": "Veuillez compléter la vérification de sécurité",
    "Pleaseorder": "Veuillez cliquer l'un après l'autre",
    "Verificationagain": "Échec de la vérification, veuillez réessayer",
    "10 orders": "10 lignes",
    "24h High": "24H haut",
    "24h Low": "24H bas",
    "24h Trading Vol": "Volume des transactions sur 24 heures",
    "24h Vol": "Volume de 24H",
    "ADL mechanism": "Mécanisme ADL",
    "AMT": "Quantité",
    "Activity _home": "Activité",
    "Actual transaction number": "Nombre de transactions réelles",
    "Actual transaction price": "Prix de transaction réel",
    "Add margin": "Ajouter une marge",
    "Adjust leverage": "Ajuster l'effet de levier",
    "Adjust success": "Ajustement réussi",
    "Advanced limit order offers 3": "Les ordres limites avancés offrent trois mécanismes d'activation par rapport aux ordres limites ordinaires : 'Post only', 'Tout remplir ou annuler immédiatement', 'Remplir immédiatement et annuler le reste'; tandis que le mécanisme d'activation des ordres limites ordinaires est par défaut 'Toujours valide'.",
    "Alipay": "Alipay",
    "All Closed": "Tout clôturé",
    "All Filled": "Totalement rempli",
    "AllDetails": "Voir les détails de la liquidation",
    "AllType": "Tous les types",
    "Amount can flat": "Quantité pouvant être soldée",
    "Appeal_reason": "Raison de l'appel",
    "Applet service": "Service de mini-programme",
    "Are you sure to cancel the order？": "Êtes-vous sûr de vouloir annuler l'ordre ?",
    "Asked_to_record": "Veuillez enregistrer le matériel vidéo selon les instructions ci-dessous",
    "Asset conversion": "Conversion d'actifs",
    "Asset_types": "Types d'actifs",
    "At the current price": "le prix maximal du marché",
    "Avail.": "Disponible",
    "Avail. margin": "Marge disponible",
    "Available": "Disponible",
    "Avg close price": "Prix moyen de clôture",
    "Avg open price": "Prix moyen d'ouverture",
    "Avg.price": "Prix moyen",
    "BBO": "Prix opposé",
    "Bankcard": "Carte bancaire",
    "Bankruptcy price": "Prix de faillite",
    "Batch Cancel": "Annuler par lots",
    "Branch_address": "Adresse de la succursale",
    "Buy crypto_home": "Acheter des cryptos",
    "Calculations are not based on": "Les résultats de calcul ne sont qu'à titre indicatif, ce prix de liquidation ne représente que les données d'une seule direction d'ouverture sous un contrat.",
    "Can't calculate": "Impossible de calculer",
    "Cancel": "Annuler",
    "Cancel all": "Annuler par lots",
    "Cancel order": "Annuler la commande",
    "Cancel_the_login": "Annuler la connexion",
    "Capital cost": "Coût du capital",
    "Capital cost - Expenditure": "Coût du capital - Dépenses",
    "Capital fee": "Frais de capital",
    "Capital fee - all": "Frais de capital - Tous",
    "Capital fee - income": "Frais de capital - Revenus",
    "Capital success": "Annulé",
    "Charge_money": "Recharge crypto",
    "Chinese_Yuan": "Yuan chinois",
    "Close all of": "Êtes-vous sûr de vouloir fermer",
    "Close long": "Clôturer long",
    "Close out time": "Temps de clôture complète",
    "Close price": "Prix de clôture",
    "Close short": "Clôturer court",
    "Closed": "Volume clôturé",
    "Closing PnL gains": "Gains de clôture",
    "Closing PnL ratio": "Taux de gains de clôture",
    "Closing profit": "Profit de clôture",
    "coin_failure_0002": "Les données sur la devise n'existent pas pour le moment",
    "Common functions": "Fonctions courantes",
    "Communtiy_home": "Communauté",
    "Conceal canceled orders": "Masquer les commandes annulées",
    "Confirm_the_login": "Confirmer la connexion",
    "Confirm_to_mention_money": "Confirmer le retrait",
    "Cont": "Contrat",
    "Copy link": "Copier le lien",
    "Cross": "Position totale",
    "Currency_rate_description": "Description des taux",
    "Current": "Actuel",
    "Delete_option": "Supprimer",
    "Determine_to_cancel": "Êtes-vous sûr de vouloir annuler ?",
    "Don't show me again": "Ne plus afficher",
    "Draw_inconsistency": "Les mots de passe saisis ne correspondent pas",
    "Draw_the_unlock": "Veuillez faire glisser pour définir un nouveau mot de passe",
    "Enter email address": "Veuillez entrer votre adresse e-mail",
    "Enter expected PnL": "Veuillez entrer le PnL attendu",
    "Enter expected PnL Ratio": "Veuillez entrer le taux de PnL attendu",
    "Enter leverage from 1 up to 125": "Veuillez entrer un effet de levier de 1 à 125",
    "Enter phone number": "Veuillez entrer votre numéro de téléphone",
    "Enter the verification code": "Veuillez entrer le code de vérification",
    "Equity": "Droits sur la devise",
    "Est liquidation price": "Prix de liquidation estimé",
    "Face value": "Valeur nominale",
    "Filed Value": "Valeur réalisée",
    "Fill or Kill": "Tout remplir ou annuler immédiatement",
    "Filled amt": "Montant rempli",
    "Filled time": "Par heure de transaction",
    "Flash_strong": "Éclair fort",
    "Funding rate": "Taux de financement",
    "Funding rate - 1": "Taux de financement",
    "Funding rate comparison": "Comparaison des taux de financement",
    "Funding rate time": "Temps du taux de financement",
    "Fuzzy_information": "Informations floues",
    "Gesture_error": "Erreur de saisie du mot de passe",
    "Google_code": "Code Google",
    "Help_home": "Aide",
    "Hide_no_asset_currency": "Masquer les devises sans actifs",
    "Highest PnL ratio": "Taux de PnL le plus élevé",
    "Historical record": "Historique",
    "I agree to": "Je coche pour accepter",
    "I have read and agree to this agreement": "J'ai lu et j'accepte cet accord",
    "If_the_buyer_is_at": "Si l'acheteur est à",
    "Immediately or Cancel": "Remplir immédiatement et annuler le reste",
    "In force": "En vigueur",
    "Index": "Indice au comptant",
    "Index Price": "Prix de l'indice",
    "Index_contrct": "Indice au comptant",
    "Info": "Présentation du contrat",
    "Initial margin": "Marge initiale",
    "Instrument": "Type de transaction actuel",
    "Insurance fund": "Solde du fonds de risque",
    "Interest bearing record": "Dossier d'intérêts",
    "Invalid_qr_code": "Code QR invalide",
    "Invite code(Optional)": "Veuillez entrer le code d'invitation (optionnel)",
    "InviteCodeMuster": "Veuillez entrer le code d'invitation (obligatoire)",
    "Invite_home": "Inviter",
    "Isolated": "Isolation",
    "Lack_of_edge": "Bord manquant",
    "Last Price": "Prix de marché",
    "Latest Filled price": "Dernier prix de transaction",
    "Latest Filled price01": "Dernier prix",
    "Latest price": "Dernier prix",
    "Leverage": "Effet de levier",
    "Leverage after increase": "Effet de levier après augmentation :",
    "Leverage11": "Effet de levier actuel",
    "Leverage_BB": "Ajuster l'effet de levier",
    "Limit order is an order": "Un ordre limite signifie que l'utilisateur définit la quantité et le prix acceptable. Lorsque le marché répond aux attentes de l'utilisateur, le système exécute au meilleur prix dans la plage des limites.",
    "Limit price": "Prix limite",
    "Limit/Market": "Limite/Marché",
    "LimitAndMarket": "Limite/Marché",
    "Liquidation price": "Prix de liquidation",
    "Liquidation price after increase": "Prix de liquidation après augmentation :",
    "Long": "Long",
    "Lowest PnL ratio": "Taux de PnL le plus bas",
    "MAIL_COUNTRY_NOT_EXITS": "Le pays actuel n'existe pas ! Veuillez l'ajouter puis renvoyer l'e-mail",
    "MAIL_NOT_MAIL_PROVIDER": "Aucun fournisseur de services de messagerie pour le moment, veuillez ajouter avant de renvoyer l'e-mail !",
    "MAIL_TEMPLATE_NOT_FOUND": "Le modèle d'e-mail actuel n'existe pas ! Veuillez l'ajouter puis renvoyer",
    "MAIL_TO_ADDRESS_NOT_FOUND": "L'adresse e-mail actuelle n'existe pas, veuillez vérifier puis renvoyer",
    "MJRefreshAutoFooterIdleText": "Cliquez ou faites glisser vers le haut pour charger plus",
    "MJRefreshAutoFooterNoMoreDataText": "Tout a été chargé",
    "MJRefreshAutoFooterRefreshingText": "Chargement de plus de données...",
    "MJRefreshBackFooterIdleText": "Faites glisser vers le haut pour charger plus",
    "MJRefreshBackFooterNoMoreDataText": "Tout a été chargé",
    "MJRefreshBackFooterPullingText": "Relâchez pour charger plus",
    "MJRefreshBackFooterRefreshingText": "Chargement de plus de données...",
    "MJRefreshHeaderDateTodayText": "Aujourd'hui",
    "MJRefreshHeaderIdleText": "Faites glisser vers le bas pour rafraîchir",
    "MJRefreshHeaderLastTimeText": "Dernière mise à jour :",
    "MJRefreshHeaderNoneLastDateText": "Pas d'enregistrement",
    "MJRefreshHeaderPullingText": "Relâchez pour rafraîchir",
    "MJRefreshHeaderRefreshingText": "Rafraîchissement des données...",
    "MKT Close ALL": "Clôturer au prix du marché",
    "Margin add": "Ajouter une marge",
    "Margin mode": "Mode de position totale",
    "Margin ratio": "Taux de marge",
    "Margin reduce": "Réduire la marge",
    "Margin transfer": "Transfert de marge",
    "Margin-1": "Solde de marge",
    "Margin-2": "Ajuster la marge",
    "Margined": "Contrat",
    "Mark price": "Prix marqué",
    "Market": "Prix du marché",
    "Market Order Dig": "Un ordre au prix du marché permet à l'utilisateur de passer une commande au meilleur prix actuel, atteignant ainsi un échange rapide.",
    "Market Overview": "Aperçu du marché",
    "Market_transaction": "Transaction au prix du marché",
    "Max": "Maximum annulable",
    "Max Open int": "Maximum de positions ouvertes",
    "Max buy": "Peut acheter",
    "Max long": "Peut ouvrir long",
    "Max position size at current leverage": "Maximum ouvrable à l'effet de levier actuel :",
    "Max sell": "Peut vendre",
    "Max short": "Peut ouvrir court",
    "Max position size at current leverage": "Maximum ouvrable à l'effet de levier actuel",
    "Mention_money": "Retrait",
    "Mention_money_address": "Veuillez entrer l'adresse correcte",
    "Mention_money_tips_ios": "Conseils\n1. Entrez directement l'adresse de retrait, une vérification du mot de passe de fonds, du SMS, de l'e-mail ou du code de vérification Google est requise.\n2. L'adresse ajoutée sera liée à votre adresse certifiée, vous n'aurez pas besoin de vérifier lors du prochain retrait.",
    "Messages_notification": "*Veuillez confirmer que votre carte bancaire a activé le service de notification par SMS",
    "Mini Programs": "Mini-programmes",
    "Minimum_handling_charge": "Le montant du retrait doit être supérieur aux frais de traitement",
    "Minimum_withdrawal": "Montant minimum de retrait par transaction",
    "MoreNot9": "Le module sélectionné ne peut pas dépasser 14",
    "Most increase": "Augmentation maximale",
    "Most reduce": "Réduction maximale",
    "Must be integer multiple of": "Doit être un multiple entier de",
    "Not Enabled": "Non activé",
    "Not_on": "Pas encore disponible",
    "Not_to_mention_money": "Pour la sécurité de vos fonds, les opérations sensibles comme le retrait et PUSH des actifs ne seront pas possibles pendant une heure après la réinitialisation du mot de passe de transaction",
    "OTC account": "Compte fiat",
    "OTC assets": "Portefeuille OTC",
    "OTC交易须知": "Instructions de trading OTC",
    "On Hold": "Gelé",
    "One-way": "Unidirectionnel",
    "One/Two-way": "Unidirectionnel/Bidirectionnel",
    "Open Time": "Heure d'ouverture",
    "Open amount": "Quantité ouverte",
    "Open int.": "Positions",
    "Open interest": "Intérêt ouvert",
    "Open long": "Ouvrir long",
    "Open orders": "Ordres actuels",
    "Open price": "Prix d'ouverture",
    "Open short": "Ouvrir court",
    "Order Details": "Détails de la commande",
    "Order Sorting": "Tri des commandes",
    "Order Value": "Valeur de la commande",
    "Order amt": "Total de la commande",
    "Order history": "hist. mandats",
    "Order price": "Prix de la commande",
    "Order trigger direction": "Direction de déclenchement",
    "Order type": "Type de commande",
    "PL": "Bénéfice",
    "PL Ratio": "Taux de bénéfice",
    "Pair01": "Type de contrat",
    "Parse_failure": "Échec de sauvegarde",
    "Partially Filled": "Partiellement rempli",
    "Partially closed": "Partiellement clôturé",
    "Password": "Mot de passe",
    "Payment_type": "Type de paiement",
    "Perpetual": "Contrat perpétuel",
    "Perpetual funds daybook": "Flux de fonds perpétuels",
    "Perpetual01": "Perpétuel",
    "Pingclose": "Clôturer",
    "Please input a password": "Veuillez entrer un mot de passe",
    "Position": "Position",
    "Position balance": "Solde de position",
    "Position history": "Historique des positions",
    "QR_code": "Code QR",
    "Quantity cannot exceed": "Quantité max : 125",
    "Quantity not less than": "Quantité min : 1",
    "Recent close": "Clôturer récemment",
    "Recording_requirements": "Exigences d'enregistrement",
    "Required position margin at current leverage": "Marge requise :",
    "Reset_transaction_password": "Réinitialiser le mot de passe",
    "Revoked": "Révoqué",
    "SL": "Stop loss",
    "SL order": "Commande de stop loss",
    "SL order price": "Prix stop loss",
    "SL order price can't be empty": "Prix stop loss requis",
    "SL trigger price": "Prix de déclenchement",
    "SL trigger price can't be empty": "Prix de déclenchement requis",
    "SMS_30_OUT_RESTRICTED": "Trop de demandes, 1 code toutes les 30 secondes.",
    "SMS_BAD_ARGUMENT_FORMAT": "Format de téléphone incorrect.",
    "SMS_COUNTRY_NOT_EXITS": "Pays inexistant, vérifiez avant d'envoyer !",
    "SMS_DAY_LIMIT_PER_MOBILE": "Limite dépassée en 24 heures",
    "SMS_DUP_IN_SHORT_TIME": "Soumission répétée en 30 secondes",
    "SMS_FAILURE": "Échec de l'envoi",
    "SMS_HOUR_LIMIT_PER_MOBILE": "Limite dépassée en 1 heure",
    "SMS_MARKET_FORBIDDEN": "SMS marketing temporairement interdit",
    "SMS_NOT_SMS_PROVIDER": "Aucun fournisseur de SMS, veuillez ajouter !",
    "SMS_NOT_SMS_PROVIDER_TEMPLATE": "Pas de modèle de SMS, veuillez ajouter !",
    "SMS_PHONE_OVER_COUNT": "Nombre de codes dépassé, réessayez après 24 heures !",
    "SMS_PHONE_PROHIBIT_SEND": "Numéro interdit d'envoi, réessayez après 24 heures !",
    "SMS_SUCCESS": "Envoi réussi",
    "SMS_TOO_MANY_TIME_IN_5": "Trop de soumissions en 5 minutes",
    "SMS_UNKNOWN_EXCEPTION": "Erreur système inconnue",
    "SMS_code": "Code SMS",
    "Save picture": "Enregistrer l'image",
    "Scanning_to_obtain": "Scanner pour obtenir l'adresse",
    "See auto deleverage liquidation for details": "Voir liquidation par déséchelonnement",
    "Set_transaction_password": "Définir le mot de passe",
    "Settlement1": "Jusqu'à la liquidation des frais",
    "Settlrment coin": "Monnaie de règlement",
    "Share time": "Temps de partage",
    "Shielding success": "Blocage réussi",
    "Short": "Vente à découvert",
    "Sign Up": "S'inscrire",
    "Sign in": "Se connecter",
    "Simplified_Chinese": "Chinois simplifié",
    "StatusOrder": "État de la commande",
    "Stop loss": "Configurer stop loss",
    "Stop profit stop loss": "Choisissez au moins un stop, sinon commande impossible",
    "Sustainable account": "Compte perpétuel",
    "Sustainable assets": "Actifs perpétuels",
    "Switch to PnL": "Changer vers les bénéfices",
    "Switch to PnL ratio": "Changer vers le taux de bénéfice",
    "TP": "Take profit",
    "TP order": "Commande de take profit",
    "TP order price": "Prix de la commande take profit",
    "TP order price can't be empty": "Prix take profit requis",
    "TP trigger price": "Prix de déclenchement take profit",
    "TP trigger price can't be empty": "Prix de déclenchement requis",
    "TP | SL": "Take profit / Stop loss",
    "TP | SL order": "Commande TP / SL",
    "Take profit": "Configurer take profit",
    "Telegraphic_transfer_address": "Adresse de virement",
    "The Calculator": "Calculatrice",
    "The maximum flatable quantity is": "Max. quantité pouvant être soldée :",
    "The maximum leverage is": "Max. effet de levier :",
    "The minimum flatable quantity is": "Min. quantité pouvant être soldée :",
    "The minimum leverage is": "Min. effet de levier :",
    "The poster has been saved to the album": "Affiche enregistrée dans l'album",
    "The_correct_sample": "Exemple correct",
   "The_input_signal": "Veuillez faire glisser pour entrer le mot de passe",
    "The_new_address": "Nouvelle adresse",
    "They_are_the_number_of": "Quantité de commandes",
    "Tick size": "Taille minimale de variation",
    "To_update": "Mettre à jour",
    "Toggle_front_camera": "Veuillez basculer vers la caméra avant et centrer votre tête",
    "Total": "Quantité totale",
    "Total coin": "Nombre total de pièces",
    "Total cont": "Nombre total de contrats",
    "Total value": "Valeur totale",
    "Total_asset_valuation": "Évaluation totale des actifs",
    "Trade type": "Type de transaction",
    "Trade_sector": "Veuillez choisir",
    "Transfer": "Transfert",
    "Transfer_to_remind": "Veuillez noter lors du transfert (sinon, vous en assumez la responsabilité)",
    "Trigger mark price": "Prix de déclenchement",
    "Trigger price": "Prix de déclenchement",
    "Two-way": "Bidirectionnel",
    "UPL": "Pertes et profits non réalisés",
    "US_dollar": "Dollar",
    "Unblocked": "Débloqué",
    "Uneasy lies the head that wears a crown": "Il est difficile de porter une couronne",
    "Unfilled": "Non exécuté",
    "Unfilled11": "En attente d'exécution",
    "Upload_collection_code": "Veuillez télécharger le code de paiement",
    "Usdt-Margined": "Contrat en USDT",
    "Users who register": "Les nouveaux utilisateurs doivent configurer le mot de passe",
    "Verification Code": "Code de vérification",
    "Videoing_requirement": "Exigences de tournage",
    "View": "Voir",
    "View more": "Détails de la position",
    "Waiting effect": "En attente d'effet",
    "Waiting_for_the_payment": "En attente de paiement",
    "Wechat": "WeChat",
    "WestUnion": "Western Union",
    "Without_her": "Hors du cadre",
    "abandoned": "Abandonné",
    "abnormal_assets": "Actifs anormaux, opération temporairement impossible !",
    "about_us": "À propos de nous",
    "account": "Compte",
    "account_empty": "Compte incorrect",
    "account_failure_0001": "Échec de l'ajout de l'ID de transaction à REDIS !",
    "account_failure_0002": "Fonds insuffisants, impossible à traiter !",
    "account_failure_0003": "Échec de l'enregistrement de l'augmentation du gel utilisateur !",
    "account_failure_0004": "Échec de l'augmentation du gel utilisateur !",
    "account_failure_0005": "Échec de l'enregistrement de la déduction des fonds utilisateur !",
    "account_failure_0006": "Échec de la déduction des fonds utilisateur !",
    "account_failure_0007": "Échec de l'enregistrement de l'augmentation des fonds utilisateur !",
    "account_failure_0008": "Échec de l'augmentation des fonds utilisateur !",
    "account_failure_0009": "Échec de l'ajout d'une nouvelle devise par le commerçant !",
    "account_failure_0010": "Échec de l'ajout d'une nouvelle devise par le commerçant !",
    "account_failure_00100": "L'ID du commerçant ne peut pas être vide !",
    "account_failure_00101": "L'ID de l'utilisateur ne peut pas être vide",
    "account_failure_00102": "L'ID de la devise ne peut pas être vide",
    "account_failure_00103": "La quantité ne peut pas être vide",
    "account_failure_00104": "La quantité ne peut pas être inférieure au montant minimum de transaction",
    "account_failure_00105": "Le mot de passe de transaction ne peut pas être vide",
    "account_failure_00106": "Mot de passe de transaction incorrect",
    "account_failure_00107": "Le prix ne peut pas être vide",
    "account_failure_00108": "Le prix unitaire ne peut pas être inférieur au prix unitaire minimum",
    "account_failure_00109": "Utilisateur non existant !",
    "account_failure_0011": "Cette devise existe déjà dans le portefeuille du commerçant !",
    "account_failure_00110": "Échec de l'initiation des actifs PUSH !",
    "account_failure_00111": "Le PUSHID ne peut pas être vide",
    "account_failure_00112": "Échec du paiement des actifs PUSH !",
    "account_failure_00113": "Échec de l'annulation des actifs PUSH !",
    "account_failure_00114": "Impossible d'obtenir l'adresse de dépôt",
    "account_failure_00115": "L'adresse de retrait ne peut pas être vide !",
    "account_failure_00116": "Échec de l'ajout d'une adresse de retrait",
    "account_failure_00117": "L'ID ne peut pas être vide",
    "account_failure_00118": "Échec de la suppression de l'adresse de retrait",
    "account_failure_00119": "Erreur réseau, veuillez réessayer plus tard !",
    "account_failure_0012": "Gel insuffisant, impossible à traiter !",
    "account_failure_00120": "Cette adresse de retrait existe déjà",
    "account_failure_00121": "Aucune devise PUSH disponible",
    "account_failure_00122": "Le code de vérification par SMS est vide !",
    "account_failure_00123": "Le code de vérification par e-mail est vide !",
    "account_failure_00124": "Le code de vérification Google est vide !",
    "account_failure_00125": "Code de vérification incorrect !",
    "account_failure_00126": "Auto-push interdit !",
    "account_failure_00127": "Adresse de retrait invalide !",
    "account_failure_00129": "Modification du mot de passe non autorisée dans les 24 heures",
    "account_failure_0013": "Échec de l'enregistrement de la déduction du gel commerçant !",
    "account_failure_00130": "Actifs déséquilibrés, opération impossible",
    "account_failure_00131": "Actifs anormaux, état de transaction désactivé",
    "account_failure_00132": "Étiquette non correspondante",
    "account_failure_00133": "Type de devise inexistant ou désactivé",
    "account_failure_0014": "Échec de la déduction du gel commerçant !",
    "account_failure_0015": "Échec de l'enregistrement de l'augmentation des intérêts disponibles de l'utilisateur !",
    "account_failure_0016": "Échec de l'augmentation des intérêts disponibles de l'utilisateur !",
    "account_failure_0017": "Échec de l'enregistrement de la déduction des intérêts disponibles du commerçant !",
    "account_failure_0018": "Échec de la déduction des intérêts disponibles du commerçant !",
    "account_failure_0019": "Échec de la conversion des fonds disponibles de l'utilisateur en gel !",
    "account_failure_0020": "Échec de la conversion des fonds disponibles de l'utilisateur en gel, échec d'enregistrement !",
    "account_failure_0021": "Échec de l'enregistrement de la conversion du gel en fonds disponibles de l'utilisateur !",
    "account_failure_0022": "Échec de la conversion du gel en fonds disponibles !",
    "account_failure_0023": "Échec de l'enregistrement de l'augmentation des fonds disponibles du commerçant !",
    "account_failure_0024": "Échec de l'augmentation des fonds disponibles du commerçant !",
    "account_failure_0025": "Échec de l'enregistrement de la déduction du gel de l'utilisateur !",
    "account_failure_0026": "Échec de la déduction du gel de l'utilisateur !",
    "account_failure_0027": "Échec de l'enregistrement de la déduction des fonds disponibles de l'utilisateur !",
    "account_failure_0028": "Échec de la déduction des fonds disponibles de l'utilisateur !",
    "account_failure_0029": "Échec de l'annulation de l'opération, échec de la récupération du gel de l'utilisateur !",
    "account_failure_0030": "Échec de l'annulation de l'opération, échec de la récupération du gel du commerçant !",
    "account_failure_0031": "Actifs insuffisants pour l'annulation !",
    "account_failure_0032": "Échec de l'annulation de l'opération, échec de la récupération des actifs du commerçant !",
    "account_failure_0033": "Échec de l'annulation de l'opération, échec de la restauration des actifs du commerçant",
    "account_failure_0034": "Échec de l'enregistrement de l'augmentation du gel du commerçant !",
    "account_failure_0035": "Échec de l'augmentation du gel du commerçant !",
    "account_failure_0036": "Échec de l'enregistrement de la conversion des fonds disponibles en gel du commerçant !",
    "account_failure_0037": "Échec de la conversion des fonds disponibles en gel du commerçant !",
    "account_failure_0038": "Échec de l'enregistrement de la conversion du gel en fonds disponibles du commerçant !",
    "account_failure_0039": "Échec de la conversion du gel en fonds disponibles du commerçant !",
    "account_failure_0040": "Échec de l'enregistrement de l'augmentation des fonds disponibles de la plateforme !",
    "account_failure_0041": "Échec de l'augmentation des fonds disponibles de la plateforme !",
    "account_failure_0042": "Cette transaction a échoué !",
    "account_failure_0043": "Cette transaction a déjà échoué !",
    "account_failure_0044": "Cette transaction a déjà réussi !",
    "account_failure_0045": "Cette transaction est en cours !",
    "account_failure_0046": "Traitement réussi !",
    "account_failure_0047": "Échec de l'annulation de l'opération, échec de la récupération des actifs de la plateforme",
    "account_failure_0048": "Échec de l'annulation de l'opération, échec de l'enregistrement de la récupération des actifs de la plateforme",
    "account_failure_0049": "Cette devise existe déjà sur la plateforme !",
    "account_failure_0050": "Échec de l'ajout d'une nouvelle devise sur la plateforme !",
    "account_failure_0051": "Cette devise existe déjà sur la plateforme !",
    "account_failure_0053": "Type de transaction incorrect !",
    "account_failure_0054": "Échec de l'augmentation des fonds disponibles du commerçant !",
    "account_failure_0055": "Échec de l'enregistrement de l'augmentation des fonds disponibles du commerçant !",
    "account_failure_0056": "Échec du transfert de bénéfices de la plateforme à l'utilisateur !",
    "account_failure_0057": "Échec de l'enregistrement du transfert de bénéfices de la plateforme à l'utilisateur !",
    "account_failure_0058": "Échec de la conversion des fonds disponibles en finances !",
    "account_failure_0059": "Échec de l'enregistrement de la conversion des fonds disponibles en finances !",
    "account_failure_0060": "Fonds insuffisants, opération impossible !",
    "account_failure_0061": "Échec de la conversion des finances en fonds disponibles de l'utilisateur !",
    "account_failure_0062": "Échec de l'enregistrement de la conversion des finances en fonds disponibles de l'utilisateur !",
    "account_failure_0063": "Échec de l'annulation de l'opération, aucune donnée opérationnelle disponible !",
    "account_failure_0064": "Cette devise n'est pas disponible pour le moment",
    "account_failure_0065": "Pas d'autorisation pour voir cette devise, contactez le service client officiel",
    "account_failure_0071": "Erreur lors du verrouillage des fonds de l'utilisateur !",
    "account_failure_0072": "Enregistrement de dépôt inexistant ou modifié",
    "activitie_end": "Activité terminée",
    "activity_invalid": "Déjà expiré",
    "actual_amount": "Montant réel",
    "actual_exchange_amount": "Quantité échangée",
    "actual_to_account": "Montant reçu",
    "addSuccess": "Ajout réussi",
    "add_channel_function": "Ajouter fonction de vérification",
    "add_charge_and_transfer_function": "Ajouter fonction de dépôt et transfert",
    "added_alert_setting_function": "Ajouter fonction de paramétrage d'alerte",
    "added_day_mode_toggle": "Ajouter fonction de mode jour",
    "address_tag": "Étiquette",
    "advanced_limit_order": "Ordre limite avancé",
    "advertising": "Publicité",
    "advertising_management": "Gestion des publicités",
    "affirm": "Confirmer",
    "affirm_pass": "Confirmer le mot de passe",
    "again_digits6": "Veuillez saisir à nouveau le nouveau mot de passe",
    "again_password": "Veuillez saisir à nouveau le mot de passe de connexion",
    "airdrop_coin": "Monnaie de l'airdrop",
    "airdrop_date": "Date de l'airdrop",
    "airdrop_number": "Quantité de l'airdrop",
    "alipay_account": "Compte Alipay",
    "alipay_upload": "*Pour télécharger le code QR Alipay : ouvrez Alipay > Recevoir > Enregistrer l'image, puis téléchargez le code de paiement.",
    "all": "Tout",
    "all_buy": "Tout acheter",
    "all_country": "Tous les pays",
    "all_order": "Toutes les commandes",
    "all_sell": "Tout vendre",
    "already_use": "Déjà activé",
    "amount_of_payout": "Montant du paiement",
    "announcement_all": "Toutes les annonces",
    "announcement_center": "Centre d'annonces",
    "announcement_official": "Annonce officielle",
    "annualized_rate": "Taux annualisé",
    "appeal_cancel": "Décision d'appel, commande annulée",
    "appeal_complete": "Décision d'appel, commande complétée",
    "appeal_dispose_time": "Temps de traitement de l'appel",
    "appeal_of_time": "Temps de soumission de l'appel",
    "apply_locked": "Demande de verrouillage",
    "asset_allocatio": "Répartition des actifs",
    "assets_asset_valuation": "Évaluation des actifs",
    "assets_can_transfer": "Transférable",
    "assets_cash_flow": "Flux de fonds",
    "assets_coin_funds_flow": "Flux de fonds en cryptomonnaies",
    "assets_currency_assets": "Actifs en cryptomonnaies",
    "assets_deposit_income_tip": "Ce montant est basé sur le taux de change en temps réel, le montant final sera celui distribué.",
    "assets_fiat_assets": "Actifs OTC",
    "assets_fiat_money_flow": "Flux de trésorerie OTC",
    "assets_flowing_details": "Détails des flux",
    "assets_prompt_001": "[Veuillez sauvegarder la clé pour éviter toute perte]",
    "assets_reduced_asset": "Actifs réduits",
    "assets_row_mining": "Mining par ordre",
    "assets_swipe_direction": "Direction du transfert",
    "assets_transfer": "Transfert",
    "assets_transfer_coin": "Monnaie à transférer",
    "assets_transfer_count": "Quantité à transférer",
    "assets_transfer_currency": "Transfert de fonds",
    "assets_transfer_dialog_bb": "Compte en cryptomonnaies",
    "assets_transfer_dialog_fiat": "Compte en monnaie fiduciaire",
    "assets_transfer_dialog_from": "De",
    "assets_transfer_dialog_transfer_to": "Transférer vers",
    "assets_transfer_dialog_wallet": "Compte de portefeuille",
    "assets_transfer_record": "Historique des transferts",
    "assets_transfer_to": "À",
    "assets_wallet": "Portefeuille",
    "assets_wallet_assets": "Actifs du portefeuille",
    "assets_wallet_assets_transfer_tip": "*Seuls les actifs du portefeuille transférés vers le compte correspondant peuvent être échangés. Aucun frais pour les transferts entre comptes.",
    "assets_wallet_funds_flow": "Flux de fonds du portefeuille",
    "at_least_one": "Au moins un état de vérification du mot de passe de transaction doit être activé",
    "attention_blacklist": "Suivre / Bloquer",
    "attention_to_see": "Pour me suivre, cliquez ici !",
    "audit_result": "Résultat de l'audit",
    "authenticated": "Certifié",
    "authentication": "Vérification d'identité",
    "available_balance": "Solde disponible",
    "average_purchase_price": "Prix d'achat moyen",
    "avoid_close_set": "Paramètre sans mot de passe",
    "back": "Retour",
    "balance": "Solde",
    "bank_card": "Carte bancaire",
    "bank_name": "Nom de la banque",
    "bank_num": "Numéro de carte bancaire",
    "barcode_in_the_box_scan": "Placez le code QR/barre dans la boîte pour le scanner automatiquement",
    "bb_assets": "Actifs d'échange",
    "bb_history_finished": "Terminé",
    "bb_time": "Temps",
    "bb_used": "Activé",
    "be_usable": "Utilisable",
    "bear_consequences_your_investment": "Je reconnais et accepte de prendre le risque d'investissement, mon code de vérification est :",
    "become": "Devenir",
    "been_authenticated": "Vous êtes certifié",
    "beginner_guide_title": "Plateforme de trading d'actifs numériques de premier plan",
    "belong_level": "Niveau d'appartenance",
    "between_and": "Entre",
    "bill": "Facture",
    "billing_details": "Détails de la facture",
    "bind_email": "Lier l'e-mail",
    "bind_google": "Google Authenticator",
    "bind_google_authenticator": "Lier Google Authenticator",
    "bind_phone": "Lier le téléphone",
    "branch_name": "Nom de l'agence",
    "bug_order": "achat",
    "buy": "Acheter",
    "buy_1": "Acheter",
    "buy_now": "Acheter maintenant",
    "buy_quantity": "Quantité à acheter",
    "buy_rate": "Frais d'achat",
    "buyer": "Acheteur",
    "buyer_has_paid_attention": "L'acheteur a payé, attention",
    "buyer_payment": "L'acheteur a payé",
    "buyer_phone": "Numéro de téléphone de l'acheteur",
    "buyer_uid": "Acheteur",
    "calculate": "Calculer",
    "calculations are for your reference only": "Les résultats des calculs sont à titre de référence uniquement",
    "can_available": "Disponible",
    "canceled": "Annulé",
    "cancellations": "Annulations",
    "centre": "Centre",
    "change1": "Modifier",
    "change_gesture_password": "Modifier le mot de passe",
    "change_google": "Changer Google Authenticator",
    "change_pay_style": "Changer de méthode",
    "change_phone": "Changer de téléphone",
    "charge_after_the_transaction": "Frais après la transaction",
    "charge_out": "Les frais doivent être dans",
    "check_the_agree": "Accepter les termes",
    "china": "Chine",
    "choose_picture": "Choisir une image",
    "clearing_time": "Temps de règlement",
    "click_enter_batch_edit_page": "Cliquez pour entrer dans la page d'édition en masse",
    "click_on_the_copy": "Clic et copie",
    "clinch_a_deal": "L'autre partie doit avoir conclu des transactions plusieurs fois (0 signifie aucune restriction)",
    "close": "Fermer",
    "closed_successfully": "Fermeture réussie",
    "cloud_mining_pool": "Pool de minage cloud",
    "coin_failure_0001": "La devise n'existe pas",
    "collect": "Sélectionner",
    "collection_time": "Temps de collecte",
    "commission": "Commission gagnée équivalente",
    "common_failure": "Échec !",
    "common_failure_0001": "Anomalie réseau",
    "common_problem": "Instructions d'utilisation",
    "common_success": "Succès",
    "complete": "Complété",
    "completed": "Transaction complétée",
    "computer_login": "Connexion sur ordinateur",
    "confirm": "Confirmer",
    "confirm_exit": "Confirmer la sortie ?",
    "confirm_password": "Veuillez confirmer le mot de passe de connexion",
    "confirm_submission": "Confirmer la soumission",
    "confirm_the_purchase": "Confirmer l'achat",
    "confirm_to_give_up": "Confirmer l'abandon",
    "confirm_to_sell": "Confirmer la vente",
    "confirm_upgrade": "Confirmer la mise à niveau",
    "confirm_vote": "Confirmer le vote",
    "confirmation_information": "Informations de confirmation",
    "confrim_delete_warn": "Êtes-vous sûr de vouloir supprimer l'avertissement ?",
    "contact_service": "Contacter le service client",
    "contact_us": "Contactez-nous",
    "contract_Order": "Ordre de contrat",
   "contrct Agree Content": "Pour protéger vos droits, avant d'ouvrir un contrat, vous devez d'abord lire attentivement l'« Accord sur les contrats perpétuels BitMatrix ».",
    "contrct Agree Content footer": "« Accord sur les contrats perpétuels BitMatrix »",
    "contrct Agree Content header": "Pour protéger vos droits, avant d'ouvrir un contrat, vous devez d'abord lire attentivement",
    "contrct_decimal": "Décimal",
    "convert_into": "Convertir en",
    "converted_currency": "Monnaie convertie",
    "copartner-upgrade-failure": "Échec de la mise à niveau",
    "copartner-upgrade-no": "Conditions de mise à niveau non remplies, mise à niveau impossible",
    "copartner-upgrade-ok": "Peut être mis à niveau",
    "copartner-upgrade-success": "Mise à niveau réussie",
    "copy_content": "Copié dans le presse-papiers",
    "country": "Pays",
    "create_time": "Heure de création",
    "cumulative_income": "Revenu cumulatif :",
    "cumulative_participation": "Participation cumulée",
    "currency": "Monnaie",
    "currency_fee": "Taux de la monnaie",
    "currency_information": "Détails de la monnaie",
    "currency_name": "Nom de la monnaie",
    "currency_not_found": "Type de monnaie introuvable",
    "currency_select": "Sélection de la monnaie",
    "current_day_return": "Retour quotidien",
    "current_entrust": "Ordre actuel",
    "current_identity": "Identité actuelle",
    "current_polling_date": "Date de vote actuelle",
    "current_status": "État actuel",
    "currently_available": "Actuellement disponible",
    "date": "Date",
    "date_of_birth": "Date de naissance",
    "day_highs_and_lows": "Plafonds et planchers",
    "day_highs_and_lows1": "Plafonds et planchers",
    "day_income": "Jour",
    "day_trading": "Trading du jour",
    "daytime": "Journée",
    "ddress_management": "Gestion des adresses",
    "deal_amount": "Montant total de la transaction",
    "deal_done": "Transaction réalisée",
    "deal_gross": "Transaction / Total",
    "deal_of_the_week": "Transactions de la semaine",
    "deal_price": "Prix moyen de la transaction",
    "deal_sum": "Montant de la transaction",
    "deal_the_detail": "Détails de la commande",
    "deal_volume": "Montant de la transaction",
    "deduct_coin": "Monnaie déduite",
    "default_settlement": "Règlement par défaut",
    "defaults": "Par défaut",
    "deposit": "Dépôt en un clic",
    "deposit_details": "Détails du dépôt",
    "deposit_income": "Revenu de dépôt",
    "deposit_lock": "Verrouillage du dépôt",
    "deposit_lock_details": "Détails du verrouillage du dépôt",
    "deposit_returned_principal": "Capital remboursé",
    "depth": "Profondeur",
    "detailed_rules": "Règles détaillées",
    "details": "Détails",
    "digits6": "Veuillez entrer 6 chiffres",
    "direct_market": "Promotion directe",
    "direct_reward": "Récompense directe",
    "direction": "Direction",
    "discount_Rate": "Taux de remise",
    "discount_currency_introduction": "Introduction à la monnaie",
    "discount_dialog_tip1": "Félicitations ! Vous remplissez les conditions",
    "discount_dialog_tip2": "de participation et avez été sélectionné au hasard par le système comme utilisateur chanceux. Après avoir obtenu la qualification, vous aurez le droit de participer !",
    "discount_dialog_tip3": "Vous avez déjà",
    "discount_dialog_tip4": "la qualification pour l'échange, le quota de l'événement est limité, veuillez participer pendant la période de l'événement !",
    "discount_dialog_tip5": "Vous ne pouvez pas participer après avoir abandonné la qualification",
    "discount_dialog_tip6": "à l'événement et ne pouvez pas demander à participer.",
    "discount_dialog_tip7": "Remarque : la réussite de l'obtention de la qualification entraînera une déduction de",
    "discount_purchase": "Échange de remise",
    "discounted_or_original_price": "Prix réduit / Prix d'origine",
    "dispose_result": "Résultat du traitement",
    "disqualification": "Abandon de la qualification",
    "divided_earnings": "Revenus partagés",
    "drag": "Veuillez maintenir le curseur et glisser vers la droite",
    "each_input": "Vérifiez le mot de passe de transaction pour chaque opération",
    "edt_selfchoose": "Modifier la sélection",
    "effective": "Efficace",
    "eligibility": "Qualification de réception",
    "email": "Enregistrement par e-mail",
    "email_address_format": "Veuillez entrer un e-mail valide",
    "email_code": "Code de vérification par e-mail",
    "empty_deal_pass": "Le mot de passe de transaction ne peut pas être vide",
    "end time": "Heure de fin",
    "end_date": "Date de fin",
    "end_time": "Heure de fin",
    "enter_account": "Veuillez entrer votre téléphone / e-mail",
    "enter_cause_of_complaint": "Veuillez entrer la raison de la plainte",
    "enter_fb_buy_price": "Veuillez entrer le prix d'achat",
    "enter_fb_sell_price": "Veuillez entrer le prix de vente",
    "enter_your_password": "Veuillez entrer votre mot de passe de connexion",
    "enter_password": "Veuillez entrer le mot de passe",
    "entrust_amount": "Montant de l'ordre",
    "entrust_failure_0001": "Anomalie réseau",
    "entrust_failure_0002": "Type de requête incorrect",
    "entrust_failure_0003": "Le type d'appariement ne peut pas être vide",
    "entrust_failure_0004": "L'ID du commerçant ne peut pas être vide",
    "entrust_failure_0005": "L'ID de l'utilisateur ne peut pas être vide",
    "entrust_failure_0006": "L'utilisateur n'existe pas",
    "entrust_failure_0007": "L'ID de la paire de trading ne peut pas être vide",
    "entrust_failure_0009": "La paire de trading n'existe pas",
   "entrust_failure_0010": "Le mot de passe de transaction ne peut pas être vide",
    "entrust_failure_0011": "Erreur de mot de passe",
    "entrust_failure_0012": "Le type d'ordre est requis",
    "entrust_failure_0013": "La quantité d'ordre est requise",
    "entrust_failure_0014": "Le prix d'ordre est requis",
    "entrust_failure_0015": "L'ID de l'ordre est requis",
    "entrust_failure_0016": "L'ordre n'existe pas",
    "entrust_failure_0017": "Cet ordre a été supprimé",
    "entrust_failure_0018": "Cet ordre ne peut pas être annulé",
    "entrust_failure_0019": "Le numéro de version est requis",
    "entrust_failure_0020": "L'ordre au prix du marché ne peut pas être annulé",
    "entrust_failure_0021": "Les données ont été mises à jour",
    "entrust_failure_0022": "Aucun ordre, impossible de passer un ordre au prix du marché",
    "entrust_failure_0023": "Paire de trading désactivée",
    "entrust_failure_0024": "Paire non ouverte au trading",
    "entrust_failure_0025": "Prix en dehors des limites",
    "entrust_failure_0026": "Quantité en dehors des limites",
    "entrust_failure_0027": "Compte interdit de trading",
    "entrust_failure_0030": "Dépassement de la hausse de {}",
    "entrust_failure_0031": "Dépassement de la baisse de {}",
    "entrust_msg_match_type_001": "Ordre à cours limité",
    "entrust_msg_match_type_002": "Ordre au prix du marché",
    "entrust_msg_status_001": "Non exécuté",
    "entrust_msg_status_002": "Partiellement exécuté",
    "entrust_msg_status_003": "Totalement exécuté",
    "entrust_msg_status_004": "Annulé",
    "entrust_msg_type_001": "Vente",
    "entrust_msg_type_002": "achat",
    "entrusted_price": "Prix d'ordre",
    "entry_orders": "Ordres en attente",
    "equal_principal": "Principal égal",
    "error_email": "Erreur d'e-mail",
    "error_network_anomaly": "Anomalie réseau",
    "error_phone": "Numéro de téléphone invalide",
    "error_unknow": "Erreur réseau",
    "event_detail": "Détails de l'événement",
    "event_detail_warn_tip": "*Remarque : Les actifs numériques sont très volatils. Investissez prudemment !",
    "event_start_time": "Heure de début",
    "every day": "Chaque jour",
    "everyday": "Chaque jour",
    "exchange": "Échange instantané",
    "exchange_currency": "Monnaie d'échange",
    "exchange_details": "Détails de l'échange",
    "exchange_history": "Historique des échanges",
    "exchange_quantity": "Quantité échangée",
    "exchange_rules": "Règles d'échange",
    "exchangeable": "Échangeable",
    "exclusive_posters": "Générer une affiche exclusive",
    "exclusive_posters-Video": "Générer une affiche",
    "exit": "Quitter",
    "expected_return": "Rendement attendu",
    "expired": "Expiré",
    "express_area": "Zone rapide",
    "face_recognition": "Reconnaissance faciale",
    "failure": "Échec",
    "fb_lookup": "Cliquez pour voir",
    "fb_order_number": "Numéro de commande",
    "feeze_thaw_msg_status_0001": "Gel en cours",
    "feeze_thaw_msg_status_0002": "Décongelé",
    "fill_in_at_least_one": "Veuillez remplir au moins un champ",
    "fill_wrong": "Erreur peut entraîner des pertes, vérifiez",
    "filtrate": "Filtrer",
    "filtrate_content": "Filtrer par méthode de paiement, pays, monnaie ici !",
    "financial_management_is_cancel": "Revenu de dépôt annulé ! Ne pas soumettre plusieurs fois !",
    "financial_management_is_delete": "Type de gestion financière supprimé",
    "financial_management_is_disable": "Type de gestion financière désactivé",
    "financial_management_not_cancel": "Investissements réguliers non annulables !",
    "financial_management_number_not_found": "Quantité d'investissement requise",
    "financial_management_record_add_failure": "Actifs insuffisants, veuillez déposer !",
    "financial_management_record_less_than_min_number": "Quantité ajoutée inférieure à la minimale {}",
    "financial_management_type_not_found": "Échec d'affichage des infos d'investissement, type incorrect !",
    "financial_management_type_not_null": "Type d'investissement requis",
    "find_password": "Retrouver le mot de passe",
    "fingerprint": "Empreinte digitale",
    "fingerprint_verification": "Vérification d'empreinte",
    "first_bind_phone": "Veuillez lier votre numéro de téléphone",
    "first_period": "Première période",
    "following_account": "Vers le compte suivant",
    "free": "Frais de transaction",
    "Takerfree": "Frais de Taker",
    "Makerfree": "Frais de Maker",
    "freeze": "Geler",
    "freeze_to_be_processed": "Gel de la plainte, en attente",
    "fuc_use_discount": "Utilisez {} pour payer les frais * réduction",
    "futu": "Graphique secondaire",
    "gathering": "Collecte",
    "gears": "Niveau",
    "generation1": "Génération 1",
    "gesture_error_limit": "Limite d'erreurs de mot de passe gestuel atteinte",
    "gesture_password": "Mot de passe gestuel",
    "gesture_unlock": "Déverrouillage gestuel",
    "get_code": "Obtenir le code",
    "goSign": "S'inscrire",
    "go_change": "Modifier",
    "go_login": "Veuillez vous connecter d'abord",
    "go_pay": "Payer",
    "go_setting": "Paramètres",
    "go_to_pick_up": "Aller chercher",
    "go_to_receiving": "Aller recevoir",
    "go_to_redeem": "Aller échanger",
    "google_verification_code": "Code de vérification Google",
    "grievance_freeze": "Gel de réclamation",
    "has_been_on": "Déjà en ligne",
    "have_been_frozen": "Déjà gelé",
    "have_received": "Déjà reçu",
    "help_center": "Centre d'aide",
    "hide_canceled": "Cacher les annulations",
    "hide_other": "Afficher tous les ordres",
    "high_frequency_partner": "Partenaire haute fréquence",
    "highest_single": "Montant maximum par transaction",
    "highfrequency_fee_sharing": "Partage des frais haute fréquence",
    "hint": "Conseil",
    "hint_password": "Veuillez entrer le mot de passe",
    "hint_phone_email": "Veuillez entrer le téléphone / e-mail",
    "historical_transaction": "Transactions historiques",
    "history": "Historique",
    "home_page": "Page d'accueil",
    "horizontal_version": "Version horizontale",
    "hours": "H",
    "i18n_failure_0001": "Échec de la recherche de la langue système",
    "i18n_failure_0002": "Échec de la recherche du dictionnaire des pays",
    "iTo_draw_again": "Veuillez entrer le mot de passe à nouveau",
    "i_see": "Je comprends",
    "i_see1": "Compris",
    "id_card_back_direction_failure": "Erreur d'orientation de l'image au dos de la carte d'identité",
    "id_card_front_direction_failure": "Erreur d'orientation de l'image devant la carte d'identité",
    "id_cord": "Numéro de document",
    "id_type": "Type de document",
    "identification": "Échec de l'identification",
    "identity_authentication": "Authentification d'identité",
    "identity_card": "Carte d'identité",
    "if_at": "Si à",
    "ignore": "Ignorer",
    "immediately_invited": "Inviter immédiatement",
    "immediately_join": "Rejoindre immédiatement",
    "in_trading": "En cours de transaction",
    "income": "Revenu",
    "income_currency_type": "Type de monnaie de dépôt",
    "income_deposit_principal": "Principal de dépôt",
    "incomeing": "En train de générer des revenus",
    "increment quantity": "La quantité saisie ne peut pas dépasser le maximum",
    "indirect_promotion": "Promotion indirecte",
    "indirect_reward": "Récompense indirecte",
    "individual_selling_tips": "Ne peut pas vendre à ses propres ordres",
    "input_Branch_address": "Veuillez entrer l'adresse de l'agence",
    "input_address_labels": "Veuillez entrer les étiquettes",
    "input_alipay_account": "Veuillez entrer le compte Alipay",
    "input_bank_card_number": "Veuillez entrer le numéro de carte bancaire",
    "input_bank_name": "Veuillez entrer le nom de la banque",
    "input_buyer_uid": "Veuillez entrer l'UID de l'acheteur",
    "input_currency_referred": "Veuillez entrer l'abréviation de la monnaie",
    "input_deposit_amount": "Veuillez entrer le montant du dépôt",
    "input_email": "Veuillez entrer l'adresse e-mail",
    "input_email_code": "Veuillez entrer le code de vérification par e-mail",
    "input_id_cord": "Veuillez entrer le numéro de document",
    "input_lock_amount": "Veuillez entrer la quantité verrouillée",
    "input_mention_money_address": "Veuillez entrer l'adresse de retrait",
    "input_money": "Veuillez entrer le montant",
    "input_name": "Veuillez entrer le nom",
    "input_newphone_code": "Veuillez entrer le nouveau numéro de téléphone",
    "input_nike_name": "Veuillez entrer le pseudonyme",
    "input_old_google_code": "Veuillez entrer l'ancien code de vérification Google",
    "input_old_pass": "Veuillez entrer l'ancien mot de passe",
    "input_paypal_acctount": "Veuillez entrer le compte PayPal",
    "input_phone": "Veuillez entrer le numéro de téléphone",
    "input_phone_code": "Veuillez entrer le code de vérification",
    "input_price": "Veuillez entrer le prix",
    "input_quantity": "Veuillez entrer la quantité",
    "input_remark": "Veuillez entrer une remarque",
    "input_service_charge": "Veuillez entrer les frais de service",
    "input_trading_volume": "Veuillez entrer le volume de la transaction",
    "input_transaction_password": "Veuillez entrer le mot de passe de transaction",
    "input_wechat_account": "Veuillez entrer le compte WeChat",
    "input_wire_transfer": "Veuillez entrer l'adresse de virement",
    "inputs_numbers_greater_can_number": "La quantité saisie dépasse le montant transférable",
    "insufficient_light": "Lumière insuffisante",
    "interest": "Intérêt",
    "invitation_details": "Détails de la récompense d'invitation",
    "invitation_register_ad_not_found": "Annonce d'inscription par invitation via l'application non trouvée",
    "invitation_register_ad_tatle_not_found": "Titre de l'annonce d'inscription par invitation via l'application non trouvé",
    "invitation_register_coin_not_found": "Monnaie de récompense d'inscription par invitation via l'application non trouvée",
    "invitation_register_logo_not_found": "Logo d'inscription par invitation via l'application non trouvé",
    "invitation_register_not_found": "Lien d'inscription par invitation non trouvé",
    "invite_code": "Code d'invitation (facultatif)",
    "invite_code1": "Code d'invitation",
    "invite_commission": "Commission",
    "invite_gift": "Cadeau d'invitation",
    "invite_reward": "Récompense d'invitation",
    "invited_UID": "UID invité",
    "invitees_are_available": "L'inviteur peut obtenir",
    "inviter-is-user": "L'inviteur ne peut pas être soi-même",
    "inviter-not-exist": "L'inviteur n'existe pas",
    "inviter_no_null": "L'inviteur ne peut pas être vide",
    "ios_24小时排行榜": "Classement toutes les 24 heures",
    "ios_OTC交易须知": "Informations sur les transactions OTC",
    "ios_PAYPAL": "PAYPAL",
    "ios_otc_order_prompt_001": "Si vous n'avez pas payé dans les minutes et cliqué sur 'Confirmer le paiement', la commande sera automatiquement annulée après le compte à rebours.",
    "ios_otc_order_prompt_002": "Si le vendeur n'a pas reçu le paiement dans les minutes et que vous n'avez pas cliqué sur 'Confirmer le paiement', la commande sera automatiquement annulée après le compte à rebours.",
    "ios_万": "Dix mille",
    "ios_上传身份证反面": "Télécharger le dos de la carte d'identité",
    "ios_上传身份证反面": "Télécharger le dos de la carte d'identité",
    "ios_上传身份证正面": "Télécharger le recto de la carte d'identité",
    "ios_两次密碼不一致请再次输入密碼": "Les mots de passe ne correspondent pas, veuillez réessayer",
    "ios_个确认大约需要": "Confirmation nécessaire",
    "ios_中国大陆": "Chine continentale",
    "ios_中级合伙人": "Partenaire intermédiaire",
    "ios_主版块": "Section principale",
    "ios_之间": "Entre",
    "ios_买单": "achat",
    "ios_买方必须成交过几次(0等于不限制)": "L'acheteur doit avoir effectué des transactions (0 pour aucune restriction)",
    "ios_二维码/条形码": "Code QR / Code-barres",
    "ios_付款": "Paiement",
    "ios_充币地址": "Adresse de dépôt",
    "ios_先设置交易密码": "Définir d'abord le mot de passe de transaction",
    "ios_关闭照明": "Éteindre l'éclairage",
    "ios_分享失败": "Échec du partage",
    "ios_分享成功": "Partage réussi",
    "ios_初级合伙人": "Partenaire débutant",
    "ios_卖单": "Vente",
    "ios_卖家已付款提示": "Alerte de paiement du vendeur",
    "ios_卖方必须成交过几次(0等于不限制)": "Le vendeur doit avoir effectué des transactions (0 pour aucune restriction)",
    "ios_去解绑": "Se désinscrire",
    "ios_发布成功": "Publication réussie",
    "ios_发送成功": "Envoi réussi",
    "ios_各种费率说明": "Explication des taux",
    "ios_团队人数不足": "Nombre insuffisant dans l'équipe",
    "ios_复制QQ号": "Copier le numéro QQ",
    "ios_复制地址": "Copier l'adresse",
    "ios_如果你已完成线下付款，请点击下方已付款按钮。如果未完成付款，却点击已付款将会受到严厉惩罚，甚至永久冻结账户！": "Si vous avez terminé le paiement hors ligne, cliquez sur 'Paiement effectué'. Sinon, des sanctions seront appliquées, pouvant aller jusqu'à la suspension permanente de votre compte !",
    "ios_客户端或在线钱包将您需要充值的": "Le client ou le portefeuille en ligne demandera de recharger",
    "ios_客户端或在线钱包将您需要充币的": "Le client ou le portefeuille en ligne demandera de déposer",
    "ios_客服": "Service client",
    "ios_密碼设置成功！": "Mot de passe défini avec succès !",
    "ios_将该地址设置为认证地址": "Définir cette adresse comme adresse de vérification",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充值，不影响到账。\n3、最小充值金额": "Veuillez patienter. La même adresse peut être rechargée plusieurs fois, sans affecter la réception. Montant minimum de recharge.",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充幣，不影响到账。\n3、最小充幣金额": "Veuillez patienter. La même adresse peut être rechargée plusieurs fois, sans affecter la réception. Montant minimum de recharge.",
    "ios_已付款": "Paiement effectué",
    "ios_币币": "au comptant",
    "ios_常见问题": "Questions fréquentes",
    "ios_开启照明": "Allumer l'éclairage",
   "ios_意见反馈": "Retour d'expérience",
    "ios_成交": "Transaction réussie",
    "ios_我的": "Mon compte",
    "ios_手机号错误": "Numéro de téléphone incorrect",
    "ios_提交成功，等待认证": "Soumission réussie, en attente de vérification",
    "ios_提币数量不能大于手续费": "La quantité de retrait ne peut pas dépasser les frais",
    "ios_提币数量减去手续费后不能": "La quantité de retrait moins les frais ne peut pas",
    "ios_收款二维码": "Code QR de réception",
    "ios_收藏成功": "Ajout aux favoris réussi",
    "ios_数目发送到该地址。发送完成后，系统会自动在此交易获得": "Montant envoyé à cette adresse. Une fois l'envoi terminé, le système obtiendra cette transaction.",
    "ios_暂无网络": "Pas de réseau",
    "ios_最多可锁": "Peut verrouiller jusqu'à",
    "ios_最大限额": "Limite maximale",
    "ios_最小限额": "Limite minimale",
    "ios_未解锁": "Non déverrouillé",
    "ios_没有中国大陆身份证的用户请通过网站版高级认证": "Les utilisateurs sans carte d'identité doivent passer par l'authentification avancée sur le site.",
    "ios_法币": "OTC",
    "ios_注册表示同意": "Inscription implique accord",
    "ios_注册表示同意用户协议": "Inscription implique accord des conditions",
    "ios_登录过期，请重新登录": "Session expirée, veuillez vous reconnecter",
    "ios_直接推荐人uid": "UID du parrain direct",
    "ios_直推人数不足": "Nombre insuffisant de parrains directs",
    "ios_绑定成功": "Lié avec succès",
    "ios_请上传您的身份证": "Veuillez télécharger votre carte d'identité",
    "ios_请上传身份证反面照": "Veuillez télécharger la photo du dos de la carte d'identité",
    "ios_请上传身份证正面照": "Veuillez télécharger la photo du recto de la carte d'identité",
    "ios_请先绑定收款方式": "Veuillez d'abord lier votre méthode de paiement",
    "ios_请先设置支付方式": "Veuillez d'abord configurer votre méthode de paiement",
    "ios_请输入价格和数量": "Veuillez entrer le prix et la quantité",
    "ios_请输入新邮箱": "Veuillez entrer un nouvel e-mail",
    "ios_请输入正确的账号格式": "Veuillez entrer un format de compte correct",
    "ios_请输入身份证号": "Veuillez entrer le numéro de carte d'identité",
    "ios_请输入限额": "Veuillez entrer la limite",
    "ios_请通过": "Veuillez passer par",
    "ios_资产": "Actifs",
    "ios_超时": "Délai dépassé",
    "ios_身份证号": "Numéro de carte d'identité",
    "ios_退出成功": "Déconnexion réussie",
    "ios_邀请人可获得 * 的佣金": "L'inviteur peut recevoir une commission de *",
    "ios_锁仓数量不能为空": "La quantité de verrouillage ne peut pas être vide",
    "ios_间接接推荐人uid": "UID du parrain indirect",
    "ios_隐藏其他挂单": "Cacher les autres ordres",
    "ios_高级合伙人": "Partenaire avancé",
    "is_bind": "Déjà lié",
    "is_delete_select": "Voulez-vous vraiment supprimer ?",
    "issued": "Émis",
    "issued_to_lock": "Émis pour verrouillage",
    "k_close": "Fermer",
    "k_max": "Max",
    "k_min": "Min",
    "k_num": "Quantité",
    "k_open": "Ouvrir",
    "kline_target": "Cible",
    "language": "Langue",
    "lead_content": "Recherche de commande, publication d'annonces, configuration de paiement ici !",
    "least_four": "Veuillez connecter au moins 4 points",
    "less-than-min": "Moins que le minimum",
    "lf there are pending": "Les ordres en attente seront annulés.",
    "limit": "Paramètres de limite",
    "limit_orderAA": "Ordre à prix limité",
   "limit_set": "Paramètres de limite (optionnel)",
    "limited_price": "Prix limité",
    "liquidation": "Liquidation",
    "liquidation AMT": "Quantité de liquidation",
    "liquidation details": "Détails de liquidation",
    "loading": "Chargement en cours",
    "lock-expire": "Le verrouillage a expiré",
    "lock_amount": "Quantité verrouillée",
    "lock_duration": "Durée du verrouillage",
    "lock_person": "Personne verrouillée",
    "lock_record": "Historique des verrouillages",
    "lock_remaining": "Quantité restante verrouillée",
    "lock_reward": "Récompense de verrouillage",
    "lock_time": "Temps de verrouillage",
    "locked": "Verrouillé",
    "locked_closed": "Clôturé",
    "locked_insufficient_balance": "Solde insuffisant",
    "locked_settled": "Règlement effectué",
    "locked_unsettlement": "Non réglé",
    "locked_warehouse": "En cours de verrouillage",
    "log_in": "Se connecter",
    "login": "Connexion",
    "login_expired": "Session expirée, veuillez vous reconnecter.",
    "login_forget_pwd": "Mot de passe oublié ?",
    "login_name_txt": "Nom d'utilisateur",
    "login_pass": "Mot de passe de connexion",
    "logion_welcome_txt": "Bienvenue sur la connexion",
    "mail": "E-mail",
    "mailbox": "Vérification par e-mail",
    "mailbox_bind_success": "Liaison de l'e-mail réussie",
    "mailbox_new": "Nouvel e-mail",
    "main_picture": "Image principale",
    "manage_reward_details": "Détails de gestion des récompenses",
    "management_reward": "Gestion des récompenses",
    "management_share": "Gestion des parts",
    "mandarin": "Veuillez lire les chiffres en mandarin",
    "mandarin_reading_content": "Veuillez lire le contenu suivant en langage standard",
    "manual_recharge_msg_status_0001": "En attente de recharge",
    "manual_recharge_msg_status_0002": "Révision approuvée",
    "manual_recharge_msg_status_0003": "Recharge réussie",
    "manual_recharge_msg_type_0001": "Recharge normale",
    "manual_recharge_msg_type_0002": "Traitement des fonds",
    "manual_recharge_msg_type_0003": "Cadeau promotionnel",
    "margin": "Marge",
    "market_failure_00001": "Échec de l'acquisition des données du marché",
    "market_failure_00002": "ID du commerçant non trouvé",
    "market_failure_00003": "Aucune zone de transaction disponible pour le commerçant",
    "market_failure_00004": "Échec de l'obtention des données de transaction de la monnaie",
    "market_failure_00005": "Le nom de la paire de trading ne peut pas être vide",
    "market_failure_00006": "Aucune information de section disponible pour le commerçant",
    "market_failure_00007": "Échec de l'obtention des informations de la paire de trading",
    "market_failure_00008": "Échec de l'obtention des données K",
    "market_failure_00009": "Échec de l'obtention des données de profondeur",
    "market_failure_00010": "Paramètres de requête incorrects",
    "market_failure_00011": "Paire de trading invalide",
    "market_low_price": "Prix d'achat maximum du marché",
    "market_order": "ordre au - marché",
    "market_price": "Prix du marché",
    "market_sell_price": "Prix de vente minimum du marché",
    "market_switch_moved_here": "Le commutateur horizontal/vertical a été déplacé ici",
    "match": "Correspondance",
    "match_the_number_of": "Quantité correspondante",
    "max_lock_volume": "Volume maximal de verrouillage",
    "maximum_30_characters": "Maximum de 30 caractères",
    "maximum_coins": "La quantité de retrait maximale est",
    "maximum_withdrawal": "Retrait maximum par transaction",
    "mention_a_currency": "Quantité de retrait",
    "min_lock_volume": "Volume minimal de verrouillage",
    "min_recharge_amount": "Le montant de recharge minimum doit atteindre {} pour être crédité automatiquement",
    "minhour": "Minutes",
    "minimum_coins": "La quantité de retrait minimale est",
    "minimum_purchase_quantity": "La quantité d'achat doit être supérieure au reste de la commande {}",
    "minimum_quantity_sold": "La quantité de vente doit être supérieure au reste de la commande {}",
    "minutes": "Min",
    "mobile phone": "Numéro de téléphone",
    "money": "Montant",
    "more": "Plus",
    "more Or than": "Position longue : valeur nominale * nombre de contrats * prix de clôture - valeur nominale * nombre de contrats * prix d'ouverture\n\nPosition courte : valeur nominale * nombre de contrats * prix d'ouverture - valeur nominale * nombre de contrats * prix de clôture",
    "more-than-max": "Dépassement de la limite maximale de verrouillage",
    "more11": "Plus",
    "more_can_save": "Peut enregistrer jusqu'à",
    "more_home": "Plus",
    "msg_camera_framework_bug": "Veuillez activer les autorisations de caméra",
    "must_retain_one": "Un des éléments suivants doit être conservé : téléphone, Google, e-mail",
    "my_income": "Mes revenus",
    "my_prompt_001": "*Après modification du numéro de téléphone, veuillez utiliser le nouveau numéro pour vous connecter.",
    "my_prompt_002": "*Remarque : veuillez utiliser un compte réel à votre nom, les méthodes de paiement activées seront affichées aux acheteurs lors des transactions OTC, il est possible de les configurer jusqu'à 5 mais de n'en activer que 3.",
    "my_prompt_003": "*Après modification de l'e-mail, veuillez utiliser le nouvel e-mail pour vous connecter.",
    "my_vote": "Mon vote",
    "name": "Nom",
    "name_empty": "Le nom ne peut pas être vide",
    "nationality": "Nationalité",
    "need_me_record_video": "Une vidéo de vous-même est requise",
    "never_enter": "Ne jamais entrer de mot de passe de transaction",
    "new_address": "Nouvelle adresse",
    "new_pass": "Nouveau mot de passe",
    "new_pass_empty": "Veuillez entrer un nouveau mot de passe",
    "new_pass_minimum": "Le mot de passe doit faire au moins 6 caractères",
    "new_report_statistics": "Nouveau rapport statistique",
    "newest_versions": "La version actuelle est à jour !",
    "next": "Étape suivante",
    "nick_name": "Surnom",
    "nickname_des_txt": "Le surnom ne prend en charge que le chinois, l'anglais et les chiffres, limité à 15 caractères",
    "nighttime": "Nuit",
    "ninety_days": "90 derniers jours",
    "no-lock-detail": "Aucune information de verrouillage",
    "no_Senior_certification": "Veuillez d'abord effectuer une certification avancée",
    "no_data": "Aucune donnée disponible",
    "no_flash": "L'appareil actuel n'a pas de flash, impossible d'activer la fonction d'éclairage",
    "no_more_data": "Aucune donnée supplémentaire",
    "no_photo_taking": "Votre appareil ne prend pas en charge la prise de photo",
    "no_price": "Le prix ne peut pas être vide",
    "no_quantity": "Veuillez entrer une quantité correcte",
    "no_realname_auth": "Veuillez d'abord effectuer une authentification en vrai nom",
    "no_use": "Non activé",
    "none": "Aucun",
    "not_bound": "Non lié",
    "not_frequency_partner": "Vous n'êtes pas encore partenaire",
    "not_redemption": "Pas de droit de rachat",
    "not_started": "Non commencé",
    "not_to_issue": "Non émis",
    "not_trading": "Cette monnaie ne peut pas être échangée actuellement",
    "notpass": "Examen non validé",
    "null_address_tag": "L'étiquette de l'adresse ne peut pas être vide",
    "null_code": "Le code de vérification ne peut pas être vide",
    "null_email": "L'e-mail ne peut pas être vide",
    "null_password": "Le mot de passe ne peut pas être vide",
    "null_phone": "Le numéro de téléphone ne peut pas être vide",
    "number": "Pièces",
    "number_empty": "La quantité ne peut pas être 0",
    "number_of_arrivals": "Quantité arrivée",
    "number_of_issues": "Quantité totale émise",
    "number_of_withdrawal": "Nombre de retraits par jour",
    "obtained": "Retiré",
    "occlusion": "Visage obstrué",
    "occupy": "Occupé",
    "official_community": "Communauté officielle",
    "official_verification_channel": "Canal de vérification",
    "old_pass": "Ancien mot de passe",
    "old_pass_empty": "Veuillez entrer l'ancien mot de passe",
    "on_sale": "À vendre",
    "online_service": "Service client en ligne",
    "open XKD perpetual swap": "Ouvrir le contrat perpétuel BitMatrix",
    "open perpetual swap": "Ouvrir le contrat perpétuel",
    "open_app": "Ouvrir l'application",
    "open_it": "Ouvrir",
    "open_successfully": "Ouvert avec succès",
    "open_touchID": "Activer TouchID ?",
    "opposite_side_uid": "Côté opposé",
    "option": "Option",
    "optional": "Optionnel",
    "optional_area": "Zone optionnelle",
    "options": "Méthodes de sélection",
    "order": "Commande",
    "order time": "Par temps de commande",
    "order time1": "Temps de commande",
    "order_a_complaint": "Déposer une plainte sur la commande",
    "order_details": "Détails de la commande",
    "order_form": "Formulaire de commande",
    "order_history": "hist. mandats",
    "order_inquiry": "Enquête sur la commande",
    "order_number": "Numéro de commande",
    "order_time": "Heure de la commande",
    "ordinary_partner": "Partenaire ordinaire",
    "otc_account": "Compte",
    "otc_confirm_Put_the_coin": "Confirmez que vous avez reçu le virement de l'autre partie ! (Avertissement : ne cliquez pas sur confirmer avant d'avoir reçu le paiement. En cas de litige, contactez le service client pour une plainte)",
    "otc_confirm_the_payment": "Confirmer le paiement",
    "otc_confirm_the_receipt": "Confirmer la réception",
    "otc_failure_0001": "Compte désactivé, veuillez contacter le service client",
    "otc_failure_0002": "Le compte n'a pas passé la certification avancée, impossible d'effectuer des opérations OTC",
    "otc_failure_0003": "Mot de passe de transaction incorrect",
    "otc_failure_0004": "ID de commande non valide",
    "otc_failure_0006": "Échec de la modification de l'état",
    "otc_failure_0009": "Ce type de commande n'existe pas",
    "otc_failure_0010": "Échec de l'annulation",
    "otc_failure_0011": "Cette commande a été complétée ou annulée",
    "otc_failure_0012": "L'utilisateur actuel n'est pas un commerçant OTC et n'a pas le droit d'utiliser cette fonction",
    "otc_failure_0013": "Échec du dégel, échec de l'annulation",
    "otc_failure_0015": "Ce type de commande n'existe pas",
    "otc_failure_0016": "La quantité de commande ne peut pas être inférieure ou égale à 0",
    "otc_failure_0017": "Le prix de transaction ne peut pas être inférieur ou égal à 0",
    "otc_failure_0018": "Le montant minimum par transaction ne peut pas être inférieur à 0",
    "otc_failure_0019": "Le montant maximum par transaction ne peut pas être inférieur au montant minimum par transaction",
    "otc_failure_0020": "Cette monnaie fiduciaire n'est pas supportée",
    "otc_failure_0021": "Cette monnaie fiduciaire n'est pas disponible actuellement",
    "otc_failure_0022": "ID de la monnaie du commerçant incorrect",
    "otc_failure_0023": "Le montant minimum par transaction ne peut pas être inférieur au minimum pour cette monnaie {}",
    "otc_failure_0024": "Le montant maximum par transaction ne peut pas être supérieur au maximum pour cette monnaie {}",
    "otc_failure_0025": "Le nombre de décimales pour la quantité de commande ne peut pas être supérieur à {}",
    "otc_failure_0026": "Le montant total de la commande ne peut pas être inférieur au montant minimum de commande {}",
    "otc_failure_0027": "Le montant total de la commande ne peut pas être supérieur à la limite de l'utilisateur {}",
    "otc_failure_0028": "Le montant total de la commande ne peut pas être supérieur à la limite du commerçant {}",
    "otc_failure_0029": "Le prix ne peut pas être inférieur à {}",
    "otc_failure_0030": "Le prix ne peut pas être supérieur à {}",
    "otc_failure_0031": "L'utilisateur actuel n'a pas lié de compte de paiement",
    "otc_failure_0032": "Échec du gel, échec de la commande",
    "otc_failure_0033": "Le nombre maximum de commandes traitées simultanément ne peut pas être inférieur à 0",
    "otc_failure_0034": "Le nombre de transactions réussies pour l'acheteur ne peut pas être inférieur à 0",
    "otc_failure_0035": "Cet utilisateur est un commerçant certifié ou est en cours d'examen",
    "otc_failure_0036": "Le nombre de transactions réussies de l'acheteur OTC est insuffisant",
    "otc_failure_0037": "Échec du dégel, échec de l'annulation",
    "otc_failure_0038": "Le solde disponible sur le compte {} est insuffisant pour {}",
    "otc_failure_0039": "Aucune monnaie actuelle",
    "otc_failure_0040": "La monnaie actuelle n'est pas disponible",
    "otc_failure_0041": "Soumission réussie, veuillez attendre la vérification",
    "otc_failure_0042": "Désolé, cet utilisateur est actuellement en attente de vérification et ne peut pas faire de demande répétée",
    "otc_failure_0043": "Ce type de transaction n'existe pas",
    "otc_failure_0044": "Auto-achat et auto-vente interdits",
    "otc_failure_0046": "Le nombre maximum de commandes traitées simultanément {} a atteint la limite, veuillez réessayer plus tard",
    "otc_failure_0047": "L'acheteur doit avoir effectué {} transactions",
    "otc_failure_0048": "La quantité d'achat ne peut pas être inférieure ou égale à 0",
    "otc_failure_0049": "La quantité d'achat ne peut pas être supérieure à la quantité restante de cette commande",
    "otc_failure_0050": "ID de commande incorrect",
    "otc_failure_0051": "Le montant total d'achat ne peut pas être inférieur à la limite minimum de la monnaie {}",
    "otc_failure_0052": "Le montant d'achat ne peut pas être supérieur à la limite maximum de la monnaie {}",
    "otc_failure_0053": "Le type de commande actuel n'est pas une vente",
    "otc_failure_0054": "L'état actuel de la commande n'est pas correct",
    "otc_failure_0055": "Le montant d'achat ne peut pas être inférieur à la limite minimum par commande {}",
    "otc_failure_0056": "Le montant d'achat ne peut pas être supérieur à la limite maximum par commande {}",
    "otc_failure_0057": "Aucun état trouvé",
    "otc_failure_0058": "Le nombre de modifications de commande est incorrect",
    "otc_failure_0059": "Échec du gel, échec de l'annulation",
    "otc_failure_0060": "Veuillez lier un compte de paiement du type désigné par le commerçant dans les paramètres de paiement !",
    "otc_failure_0061": "Le vendeur doit avoir effectué {} transactions",
    "otc_failure_0062": "La quantité de vente ne peut pas être inférieure ou égale à 0",
    "otc_failure_0063": "La quantité de vente ne peut pas être supérieure à la quantité restante de cette commande",
    "otc_failure_0064": "Le type de commande actuel n'est pas un achat",
    "otc_failure_0065": "Le montant total de vente ne peut pas être inférieur à la limite minimum de la monnaie {}",
    "otc_failure_0066": "Le montant de vente ne peut pas être supérieur à la limite maximum de la monnaie {}",
    "otc_failure_0067": "Le montant de vente ne peut pas être inférieur à la limite minimum par commande {}",
    "otc_failure_0068": "Le montant de vente ne peut pas être supérieur à la limite maximum par commande {}",
    "otc_failure_0069": "ID de compte de paiement incorrect",
    "otc_failure_0070": "Échec de la confirmation du paiement",
    "otc_failure_0073": "Échec du changement d'état de la commande",
    "otc_failure_0074": "Échec de la conversion de monnaie, échec de la confirmation de réception",
    "otc_failure_0075": "La méthode de paiement ne peut pas être vide",
    "otc_failure_0076": "Échec de l'envoi de SMS {}",
    "otc_failure_0077": "Cette monnaie de transaction n'existe pas",
    "otc_failure_0078": "Cette monnaie fiduciaire n'existe pas",
    "otc_failure_0079": "Échec du changement d'état de la commande",
    "otc_failure_0080": "Cette monnaie de transaction est désactivée",
    "otc_failure_0081": "Cette monnaie fiduciaire est désactivée",
    "otc_failure_0082": "Cette commande n'existe pas",
    "otc_failure_0083": "Aucune monnaie fiduciaire configurée pour le commerce OTC actuellement",
    "otc_failure_0084": "Aucune monnaie configurée pour le commerce OTC actuellement",
    "otc_failure_0085": "Quantité restante de la commande insuffisante",
    "otc_failure_0090": "Ce compte est interdit de trader OTC, veuillez consulter le service client",
    "otc_failure_0091": "Les utilisateurs ordinaires ne peuvent pas créer de commandes OTC, veuillez d'abord demander à devenir commerçant",
    "otc_failure_0092": "La réception de la commande actuelle n'a pas expiré, l'acheteur ne peut pas faire de plainte",
    "otc_failure_0093": "Cette commande a déjà été plainte, veuillez contacter le service client",
    "otc_failure_0094": "En raison des réglementations de votre lieu d'enregistrement, vous ne pouvez pas effectuer cette opération",
    "otc_failure_0095": "Cette commande n'est pas actuellement négociable",
    "otc_failure_0096": "Les actifs du compte du vendeur sont anormaux, impossible d'effectuer cette opération pour le moment",
    "otc_failure_0097": "Le nombre d'annulations OTC aujourd'hui a dépassé la limite, interdiction de trader",
    "otc_failure_0098": "Vous avez actuellement des commandes non finalisées, veuillez terminer les commandes avant de continuer.",
    "otc_margin_certification": "Certification de marge",
    "otc_mine_account_paid": "J'ai effectué le paiement",
    "otc_order_prompt_003": "Si l'acheteur ne vous paie pas et ne clique pas sur le bouton 'J'ai payé' dans le temps imparti, le système annulera automatiquement cette commande après le compte à rebours",
    "otc_picture_no_null": "Veuillez télécharger au moins une image",
    "otc_prompt_001": "1. La période de validité du paiement de la commande est de 30 minutes, veuillez payer à temps et cliquer sur le bouton (J'ai payé).\n2. Les crypto-monnaies du vendeur sont verrouillées et gérées par le système, veuillez passer votre commande en toute confiance.",
    "otc_rate": "Taux",
    "otc_report_order": "Commande réalisée",
    "otc_success_0001": "Annulation réussie",
    "otc_success_0002": "Vente réussie",
    "otc_success_0003": "Achat réussi",
    "otc_success_0004": "Confirmation de paiement réussie",
    "otc_success_0005": "Confirmation de réception réussie, commande terminée",
    "otc_success_0006": "Plainte réussie",
    "otc_waiting_for_confirmation": "En attente de confirmation du vendeur",
    "other_settings": "Autres paramètres",
    "overtime_pay": "Délai de paiement dépassé, commande annulée",
    "param-error": "Erreur de paramètre",
    "partner": "Partenaire",
    "partner_commission": "Les partenaires peuvent obtenir un remboursement des frais de leurs subordonnés",
    "partner_commission_fee": "Commission de frais",
    "partner_current_level": "Niveau actuel",
    "partner_get_all_subordinate": "Peut obtenir tous les subordonnés",
    "partner_management": "Gestion des partenaires",
    "partner_need_pay": "Un paiement est requis",
    "partner_need_recommend": "Besoin de recommandations directes",
    "partner_recommended": "Déjà recommandé directement",
    "partner_start_and_end_time": "Temps de début et de fin",
    "partner_team": "Équipe",
    "partner_team_member": "Le nombre d'équipe doit atteindre",
    "partner_upgrade_immediately": "Mettre à niveau immédiatement",
    "pass_muster": "Si vous ne remplissez pas les conditions, cliquez ici",
    "passed": "Approuvé",
    "passport": "Passeport",
    "password_disagree": "Les deux mots de passe ne correspondent pas",
    "password_is_different": "Les mots de passe ne correspondent pas",
    "password_text": "Mot de passe",
    "password_wrong": "Définissez un mot de passe de 8 à 20 caractères",
    "past_activities": "Activités passées",
    "pause_recharge": "Cette monnaie ne peut pas être rechargée",
    "pause_withdraw": "Cette monnaie ne peut pas être retirée",
    "pay_number": "Quantité de paiement",
    "pay_wey": "Méthode de paiement",
    "payee": "Bénéficiaire",
    "payfor_type": "Type de paiement",
    "payment": "Paiement",
   "payment_amount": "Montant du paiement",
    "payment_currency": "Monnaie de paiement",
    "payment_has_been": "(J'ai payé)",
    "payment_term": "Méthode de réception",
    "paypal_account": "Compte PayPal",
    "pending_review": "En attente de vérification",
    "people": "Personnes",
    "period": "Période",
    "personal_detail": "Détails personnels",
    "personal_purchase_tips": "Vous ne pouvez pas acheter vos propres commandes",
    "phone": "Téléphone",
    "phone_code": "Code de vérification par SMS",
    "phone_register": "Inscription par téléphone",
    "phone_verification": "Vérification par SMS",
    "phone_verify": "Vérification du téléphone",
    "photo_album": "Album photo",
    "please_enter": "Veuillez entrer",
    "please_enter_abbreviation_or_letter": "Veuillez entrer le contenu de recherche",
    "please_input_content": "Veuillez entrer le contenu",
    "please_pay": "Veuillez payer",
    "please_verification": "Veuillez vérifier",
    "pop_Date": "Date",
    "pop_reset": "Réinitialiser",
    "position at market price?": "Voulez-vous clôturer la position au prix du marché ?",
    "post_only": "Faire uniquement Maker (Post only)",
    "price": "Prix",
    "price_cannot_be_less": "Le prix ne peut pas être inférieur à {}",
    "price_cannot_be_more": "Le prix ne peut pas être supérieur à {}",
    "price_empty": "Le prix ne peut pas être 0",
    "principal": "Capital",
    "promote_links": "Liens de promotion",
    "promotion_statistical": "Statistiques de promotion",
    "prtner_invalid": "Invalide",
    "publish_a_trade_order": "Publier un ordre de transaction",
    "purchase": "Achat",
    "push": "PUSH actifs",
    "push_maximum_volume": "La quantité PUSH est supérieure au solde disponible {}",
    "push_minimum_trading_volume": "La quantité PUSH est inférieure au volume minimum de transaction {}",
    "quantity": "Quantité",
    "quantity_deal": "Quantité / Transaction",
    "quota": "Quota",
    "range": "Plage",
    "real_income": "Revenu réel",
    "real_name_authentication": "Vérification d'identité",
    "reason": "Raison",
    "rebate_amount": "Montant de la remise",
    "rebate_record": "Dossier de remise",
    "rebate_reward": "Récompense de remise",
    "receiving_set": "Paramètres de réception",
    "receiving_set_title": "Veuillez d'abord configurer la méthode de réception",
    "recently_deal1": "Transactions récentes",
    "recharge_notice": "Remarques sur le rechargement\n1. Veuillez envoyer le montant de {} que vous souhaitez recharger à cette adresse via {} client ou portefeuille en ligne. Une fois l'envoi terminé, le système créditera automatiquement votre compte sur ce site après {} confirmations de cette transaction, veuillez patienter.\n2. Vous pouvez recharger plusieurs fois à la même adresse, cela n'affecte pas le compte.\n3. Montant minimum de recharge {}.",
    "recharge_source": "Source de recharge",
    "recharge_withdraw_failure_0001": "Erreur de réseau",
    "recharge_withdraw_failure_0002": "Le mot de passe de transaction ne peut pas être vide",
    "recharge_withdraw_failure_0003": "Le code de vérification ne peut pas être vide",
    "recharge_withdraw_failure_0004": "L'ID du commerçant ne peut pas être vide",
    "recharge_withdraw_failure_0005": "L'ID utilisateur ne peut pas être vide",
    "recharge_withdraw_failure_0006": "L'utilisateur n'existe pas",
    "recharge_withdraw_failure_0007": "L'ID de la monnaie ne peut pas être vide",
    "recharge_withdraw_failure_0009": "La monnaie n'existe pas",
    "recharge_withdraw_failure_0010": "Le code de vérification Google ne peut pas être vide",
    "recharge_withdraw_failure_0011": "Le mot de passe de transaction est incorrect",
    "recharge_withdraw_failure_0012": "Le type de recharge ne peut pas être vide",
    "recharge_withdraw_failure_0013": "La quantité de recharge ne peut pas être vide",
    "recharge_withdraw_failure_0014": "La note de recharge ne peut pas être vide",
    "recharge_withdraw_failure_0015": "L'ID de recharge ne peut pas être vide",
    "recharge_withdraw_failure_0016": "L'ordre de recharge n'existe pas",
    "recharge_withdraw_failure_0017": "Seules les recharges en attente peuvent être supprimées",
    "recharge_withdraw_failure_0018": "Seules les recharges en attente peuvent être vérifiées",
    "recharge_withdraw_failure_0019": "Seules les vérifications réussies peuvent être dégelées",
    "recharge_withdraw_failure_0020": "Le numéro de version ne peut pas être vide",
    "recharge_withdraw_failure_0021": "Erreur de paramètre",
    "recharge_withdraw_failure_0022": "Les données actuelles ont été mises à jour",
    "recharge_withdraw_failure_0023": "L'ID ne peut pas être vide",
    "recharge_withdraw_failure_0024": "Seul l'état verrouillé peut être vérifié",
    "recharge_withdraw_failure_0025": "Seules les demandes de retrait en attente peuvent être verrouillées",
    "recharge_withdraw_failure_0026": "Seules les demandes de retrait ou d'état verrouillé peuvent être annulées",
    "recharge_withdraw_failure_0027": "La quantité de gel ne peut pas être vide",
    "recharge_withdraw_failure_0028": "La raison du gel ne peut pas être vide",
    "recharge_withdraw_failure_0029": "La note de dégel ne peut pas être vide",
    "recharge_withdraw_failure_0030": "La quantité de retrait ne peut pas être vide",
    "recharge_withdraw_failure_0031": "L'adresse de retrait ne peut pas être vide",
    "recharge_withdraw_failure_0032": "Les frais ne peuvent pas être vides",
    "recharge_withdraw_failure_0033": "Erreur de frais",
    "recharge_withdraw_failure_0034": "Les données n'existent pas",
    "recharge_withdraw_failure_0035": "Cet actif ne peut pas être dégelé",
    "recharge_withdraw_failure_0036": "L'adresse de retrait est incorrecte",
    "recharge_withdraw_failure_0041": "Ce compte a été interdit de retrait, veuillez contacter le service client",
    "recharge_withdraw_failure_0042": "Le nombre de retraits a atteint la limite quotidienne",
    "recharge_withdraw_failure_0043": "Le montant total des retraits dépasse la limite quotidienne",
    "recharge_withdraw_failure_0044": "Erreur lors de l'obtention des informations sur le niveau VIP",
    "recharge_withdraw_failure_0045": "Erreur lors de l'obtention des informations sur la monnaie du commerçant",
    "recharge_withdraw_failure_0046": "Interdiction de retirer de l'argent dans les 24 heures suivant la réinitialisation du mot de passe !",
    "recharge_withdraw_failure_0048": "Erreur lors de l'obtention de la valeur maximale de retrait par transaction",
    "recharge_withdraw_failure_0049": "Erreur lors de l'obtention de la valeur minimale de retrait par transaction",
    "recharge_withdraw_failure_0050": "La quantité entrée doit être inférieure à la limite maximale",
    "recharge_withdraw_failure_0051": "La quantité entrée doit être supérieure à la limite minimale",
    "recharge_withdraw_msg_status_0001": "En attente de retrait",
    "recharge_withdraw_msg_status_0002": "En cours d'envoi",
    "recharge_withdraw_msg_status_0003": "Retrait réussi",
    "recharge_withdraw_msg_status_0004": "Annuler",
    "recharge_withdraw_msg_status_0005": "En cours de confirmation",
    "recharge_withdraw_msg_status_0006": "Recharge réussie",
    "recharge_withdraw_msg_status_0007": "Vérifié",
    "recharge_withdraw_msg_type_0001": "Recharge",
    "recharge_withdraw_msg_type_0002": "Retrait",
    "recharge_withdraw_msg_withdraw_type_0001": "Transfert interne",
    "recharge_withdraw_msg_withdraw_type_0002": "Retrait externe",
    "record": "Enregistrement",
    "record_the_video": "Cliquez pour enregistrer la vidéo",
    "recording": "Réenregistrer",
    "redeem_now": "Échanger maintenant",
    "redeemed": "Échangé",
    "redeeming": "Échange en cours",
    "redemption_amount": "Montant de l'échange",
    "redemption_time": "Temps d'échange",
    "referrer": "Parrain",
    "register": "S'inscrire",
    "registered_successfully": "Inscription réussie",
    "registration_award_details": "Détails de la récompense d'inscription",
    "registration_incentives": "Récompense d'inscription",
    "registration_time": "Temps d'inscription",
    "regular": "Régulier",
    "regular_return": "Retour mensuel régulier",
    "release": "Publier",
    "release_operation": "Publier un ordre, cliquez ici pour agir !",
    "release_the_order": "Publier un ordre",
    "release_time": "Temps de publication",
    "remark": "Remarque",
    "remember_state": "Se souvenir de l'état de connexion",
    "repeal": "Annuler",
    "reset": "Réinitialiser",
    "reset_login_password": "Modifier le mot de passe de connexion",
    "reset_pass": "Réinitialiser le mot de passe",
    "residue": "Reste",
    "retrieve_the_deposit": "Récupérer le dépôt",
    "return_amount": "Montant de retour",
    "return_method": "Méthode de retour",
    "return_the_principal": "Rembourser le capital",
    "revenue_details": "Détails des revenus",
    "revocation_all": "Annuler tout",
    "reward_amount": "Montant de la récompense",
    "reward_locked": "Récompense verrouillée",
    "reward_status": "État de la récompense",
    "reward_type": "Type de récompense",
    "rewards_record": "Dossier de récompense",
    "right_now_register": "Inscription pour les nouveaux utilisateurs",
    "roll_out": "Transférer",
    "save": "Enregistrer",
    "save_coin": "Déposer",
    "save_coin_count": "Quantité de dépôt",
    "save_coin_days": "Jours de dépôt",
    "save_coin_duration": "Durée de dépôt",
    "save_currency": "Monnaie de dépôt",
    "save_money_amount": "Montant total du dépôt",
    "save_successfully": "Enregistrement réussi",
    "save_the_image": "Télécharger l'image",
    "scan_QR_code": "Veuillez ne pas scanner des codes QR d'origine inconnue",
    "scan_result": "Résultat de la numérisation",
    "search": "Rechercher",
    "search_coin_placeholder": "Rechercher la monnaie qui vous intéresse",
    "search_keyword": "Mot-clé de recherche",
    "search_name": "Nom de recherche",
    "search_result": "Résultat de la recherche",
    "search_trade_pair_placeholder": "Rechercher le pair de trading qui vous intéresse",
    "security_center": "Centre de sécurité",
    "security_level": "Niveau de sécurité",
    "select_date_of_borth": "Veuillez sélectionner la date de naissance",
    "select_delete_warn": "Veuillez sélectionner l'alerte à supprimer",
    "sell": "Vendre",
    "sell_1": "Vendre",
    "sell_order": "Vente",
    "sell_quantity": "Quantité à vendre",
    "sell_rate": "Frais de vente",
    "seller": "Vendeur",
    "seller_phone": "Numéro de téléphone du vendeur",
    "send": "Envoyer",
    "senior_certification": "Certification avancée",
    "senior_certification1": "Certification avancée",
    "service": "Service client QQ :",
    "service_charge": "Frais de service",
    "session_timeout": "Session expirée, veuillez vous reconnecter",
    "set_Password": "Modifier",
    "set_gesture_pass": "Veuillez définir le mot de passe gestuel",
    "set_login_password": "Définir le mot de passe de connexion",
    "setting": "Paramètres",
    "setting_alipay": "Configurer Alipay",
    "setting_bank_card": "Configurer la carte bancaire",
    "setting_message_warn_tip": "* Une fois l'option activée, vous recevrez des notifications d'alerte. Si elle est désactivée, aucune notification ne sera envoyée.",
    "setting_paypal": "Configurer PayPal",
    "setting_paypassword": "Veuillez d'abord définir le mot de passe de transaction",
    "setting_wechat": "Configurer WeChat",
    "setting_western_union": "Configurer Western Union",
    "share_invitation_link": "Partager le lien d'invitation",
    "share_link": "Partager le lien avec vos amis",
    "share_to_do": "Partager à",
    "shift_to": "Transférer vers",
    "single_least": "Le montant minimal par transaction est inférieur à {}",
    "single_minimum": "Montant minimum par transaction",
    "single_more": "Le montant minimum par transaction est supérieur à {}",
    "skip": "Passer",
    "small_exchange": "Petit échange",
    "small_exchange_tip": "*Conseil : Vous ne pouvez échanger qu'une seule fois des actifs d'une valeur inférieure à {}{} dans les 24 heures. Les monnaies retirées ne peuvent pas être échangées pour le moment, les frais d'échange provisoires sont de {}.",
    "small_exchange_tip-1": "*Conseil : Le prix du marché fluctue en temps réel, se baser sur le prix actuel. Les monnaies retirées ne peuvent pas être échangées pour le moment, les frais d'échange provisoires sont de {0}%.",
    "small_exchange_valuation": "Évaluation",
    "small_exchange_valuation-1": "Évaluation en BTC",
    "smallest_unit_price": "Le prix unitaire PUSH est inférieur au prix minimum {}",
    "so adjusted to": "des multiples entiers, sera automatiquement ajusté à",
    "sold_out": "Retiré",
    "start time": "Heure de début",
    "start_date": "Date de début",
    "start_time": "Heure de début",
    "statistics_report": "Rapport statistique",
    "status": "Statut",
    "submit": "Soumettre la vidéo",
    "submitBtn": "Soumettre",
    "succeed": "Réussi",
    "successful_invitation": "Invitation réussie",
    "successfully_received": "Réception réussie",
    "successfully_set": "Configuration réussie",
    "super_partner": "Super partenaire",
    "switch_account": "Changer de compte",
    "system_top_up": "Recharge système",
    "system_unlock": "Déverrouillage système",
    "tab_assets": "Actifs",
    "tab_home": "Accueil",
    "tab_markets": "Marchés",
    "tab_swaps": "Contrats",
    "tab_trade": "Échange",
    "taking_pictures": "Prendre des photos",
    "telegraph": "Groupe Telegram :",
    "temporary_not_online_contact_service": "Actuellement hors ligne, veuillez contacter le service client",
    "the_day": "Le jour",
    "the_event_is_over": "L'événement est terminé",
    "the_latest_price": "Dernier prix",
    "the_password_is_correct": "Le mot de passe est correct",
    "theme": "Thème",
    "this_month": "Ce mois",
    "this_week": "Cette semaine",
    "ticket": "Billet",
    "time_of_payment": "Heure de paiement",
    "time_the_input": "{0}h sans entrer le mot de passe de transaction",
    "timeout": "Délai de session dépassé",
    "timeout_affirm": "Délai de confirmation dépassé",
    "timeout_deal": "Transaction automatique après délai",
    "timeout_to_cancel": "Paiement dépassé, annulation automatique",
    "to_confirm": "À confirmer",
    "total_buyer": "Total des acheteurs",
    "total_count": "Total",
    "total_exchange": "Total des échanges flash",
    "total_lock_amount": "Montant total verrouillé",
    "total_money": "Montant total",
    "total_return": "Revenu total estimé",
    "trade_fee": "Taux de frais de transaction",
    "trade_type": "Type de transaction",
    "trading_currency": "Monnaie de trading",
    "trading_fiat_currency": "Monnaie fiat de trading",
    "trading_instructions": "Instructions de trading",
    "trading_on": "Sur le pair de trading",
    "trading_pair_abbreviation": "Veuillez entrer l'abréviation du pair de trading",
    "trading_this_month": "Transactions de ce mois",
    "trading_volume": "Volume de transactions",
    "transaction_description": "Description de la transaction",
    "transaction_password": "Mot de passe financier",
    "transaction_price": "Prix de transaction",
    "transfer_number": "Quantité transférée",
    "try_it_now": "Essayez-le maintenant",
    "type": "Type",
    "unable_to_access_album": "L'appareil actuel ne prend pas en charge l'accès à l'album",
    "unaccalimed": "Non réclamé",
    "unavailable": "Indisponible",
    "under_review": "En cours de vérification",
    "univalence": "Prix unitaire",
    "unlock_details": "Détails du déverrouillage",
    "unlock_mode": "Mode de déverrouillage",
    "unlock_period": "Période de déverrouillage",
    "unlock_plan": "Plan de déverrouillage",
    "unlock_plan_unlock": "Déverrouiller",
    "unlock_record": "Historique de déverrouillage",
    "unlock_time": "Heure de déverrouillage",
    "unlocked": "Déverrouillé",
    "unredeemed": "Non échangé",
    "unverified": "Non vérifié",
    "up_to_three": "Jusqu'à trois activés",
    "upgrade": "Mettre à niveau",
    "upload_again": "Télécharger à nouveau",
    "upload_front": "Télécharger la face avant de la pièce d'identité",
    "upload_identity_card": "Veuillez télécharger votre pièce d'identité",
    "upload_image_success": "Téléchargement d'image réussi",
    "upload_information_as_required": "Veuillez télécharger les informations strictement selon les exigences ci-dessus, sinon la vérification échouera.",
    "upload_passport": "Veuillez télécharger votre passeport",
    "upload_passport_info_picture": "Télécharger la photo de la page d'information du passeport",
    "upload_reverse": "Télécharger le verso de la pièce d'identité",
    "ups_and_downs": "Fluctuation du jour",
    "ups_downs_habit": "Habitude de fluctuation",
    "usable": "Utilisable",
    "used": "Utilisé",
    "user-bind-failure": "Échec de la liaison de l'inviteur",
    "user-bind-success": "Liaison de l'inviteur réussie",
    "user-collection-error": "Échec",
    "user-country-code-not-empty": "Le code international ne peut pas être vide",
    "user-disable-trade": "Le compte actuel est interdit de trading, veuillez consulter le service client pour plus de détails !",
    "user-down-notbind": "Cet utilisateur ne peut pas lier des utilisateurs inférieurs",
    "user-email-fail-often": "Envoi d'e-mails trop fréquent, veuillez réessayer après 120 secondes",
    "user-email-not-empty": "L'adresse e-mail ne peut pas être vide",
    "user-email-verification-code-out": "Le code de vérification par e-mail a expiré, veuillez le renvoyer",
    "user-fail-advanced-auth": "Échec de la certification avancée",
    "user-fail-advanced-authed": "L'utilisateur a déjà été certifié avancé",
    "user-fail-assetnotenough-error": "Fonds insuffisants",
    "user-fail-bank-count-error": "Un maximum de 3 méthodes de paiement peut être activé",
    "user-fail-bank-inotc": "La méthode de paiement a des commandes en OTC et ne peut pas être désactivée",
    "user-fail-cardno-repeat-error": "Le numéro de document a déjà été utilisé",
    "user-fail-email-code": "Le code de vérification par e-mail est incorrect",
    "user-fail-google-binded": "Google est déjà lié",
    "user-fail-google-code": "Le code de vérification Google est incorrect",
    "user-fail-inviter-notexist": "Le parrain n'existe pas",
    "user-fail-login-disable": "Le compte actuel est interdit de connexion, veuillez consulter le service client pour plus de détails !",
    "user-fail-login-lock": "Le compte est verrouillé",
    "user-fail-login-password": "Nom d'utilisateur ou mot de passe incorrect, il reste {} tentatives",
    "user-fail-mail-binded": "L'e-mail a déjà été lié",
    "user-fail-not-realname": "Veuillez d'abord vérifier votre identité",
    "user-fail-oldpassword-error": "Ancien mot de passe incorrect",
    "user-fail-partner-not-found": "Le commerçant n'existe pas",
    "user-fail-partnernotfound": "Le commerçant n'existe pas",
    "user-fail-password": "Nom d'utilisateur ou mot de passe incorrect",
    "user-fail-password-blank": "Le mot de passe ne peut pas être vide",
    "user-fail-password-blank1": "Le mot de passe de confirmation ne peut pas être vide",
    "user-fail-password-repeat": "Le nouveau mot de passe que vous avez défini est identique à l'ancien mot de passe, veuillez le définir à nouveau !",
    "user-fail-password-thin": "La force du mot de passe est trop faible",
    "user-fail-passwword-eq-paypassword": "Le mot de passe de transaction est identique au mot de passe de connexion, veuillez le définir à nouveau",
    "user-fail-paypassword-error": "Le mot de passe de paiement est incorrect",
    "user-fail-paypassword-notset": "Veuillez d'abord définir le mot de passe de paiement",
    "user-fail-paypassword-set": "Le mot de passe de paiement a été défini",
    "user-fail-phone-binded": "Le numéro de téléphone a déjà été lié",
    "user-fail-realname-auth": "Échec de la vérification d'identité de l'utilisateur",
    "user-fail-realname-authed": "L'utilisateur a déjà été vérifié",
    "user-fail-realname-notset": "Veuillez d'abord vérifier votre identité",
    "user-fail-reg": "Échec de l'inscription, erreur du serveur",
    "user-fail-reg-mail": "Format d'e-mail incorrect",
    "user-fail-reg-mail-exist": "L'e-mail a déjà été enregistré",
    "user-fail-reg-phone-exist": "Le numéro de téléphone a déjà été enregistré",
    "user-fail-securitycount": "Au moins un moyen de vérification doit être conservé",
    "user-fail-sms-code": "Le code de vérification par SMS est incorrect",
    "user-fail-sms-code-new": "Le code de vérification par SMS pour le nouveau numéro de téléphone est incorrect",
    "user-fail-sms-code-old": "Le code de vérification par SMS pour l'ancien numéro de téléphone est incorrect",
    "user-fail-usernameisblank": "Le nom d'utilisateur ne peut pas être vide",
    "user-id-or-nationCode—is-null": "L'ID utilisateur ou le code international est vide",
    "user-login-success": "Connexion réussie",
    "user-mail-fail": "Échec de l'envoi de l'e-mail",
    "user-mail-success": "E-mail envoyé avec succès",
    "user-not-exist": "Cet utilisateur n'existe pas",
    "user-notopen-success": "Pas encore activé",
    "user-phone-not-empty": "Le numéro de téléphone ne peut pas être vide",
    "user-sms-fail": "Échec de l'envoi, erreur du serveur",
    "user-sms-fail-often": "Envoi de SMS trop fréquent",
    "user-sms-success": "SMS envoyé avec succès",
    "user-sms-verification-code-out": "Le code de vérification a expiré, veuillez le renvoyer",
    "user-success-advanced-auth": "Certification avancée réussie",
    "user-success-realname-auth": "Vérification d'identité de l'utilisateur réussie",
    "user-validate-code-fail": "Le code de vérification est incorrect",
    "user-validate-fail": "Échec de la validation",
    "user-vip-common-fail": "Échec de l'achat de VIP",
    "user_agreement": "Veuillez cocher pour accepter l'accord de service utilisateur",
    "user_collection_cencel_success": "Annulé",
    "user_collection_insert_success": "Ajouté aux favoris",
    "user_pay_disable_state": "Ce compte a dépassé le nombre d'erreurs de mot de passe de transaction et est interdit de trading, veuillez réessayer après 2 heures",
    "user_pay_is_error": "Mot de passe de transaction incorrect, vous pouvez encore entrer {} fois, le dépassement entraînera un gel de 2 heures",
    "user_pay_password_not_setting": "L'utilisateur n'a pas défini de mot de passe de transaction, veuillez d'abord le définir",
    "user_protocol": "Accord utilisateur",
    "user_service_agreement": "Accord de service utilisateur",
    "user_vip_has_expire": "Le VIP de ce compte a expiré, impossible de passer à un niveau supérieur !",
    "user_vip_update_failure": "Ce compte ne prend pas en charge le renouvellement et l'élévation, veuillez consulter le service client pour plus de détails !",
    "valid_identity_card": "Veuillez entrer un numéro de document valide",
    "valuation": "Évaluation des dépôts",
    "verification_code_error": "Le code de vérification est incorrect",
    "verification_failed_001": "Échec de la vérification",
    "verification_finish": "Vérification terminée",
    "version_updating": "Mise à jour de la version",
    "versions": "Versions",
    "vertical_version": "Version verticale",
    "view_activity": "Voir l'activité",
    "view_lock": "Voir le verrouillage",
    "volamount": "L'acheteur doit avoir effectué {} transactions",
    "volamount_sell": "Le vendeur doit avoir effectué {} transactions",
    "vote": "Vote",
    "vote_add_coin": "Vote pour ajouter une monnaie",
    "vote_return": "Retour",
    "voting_coin": "Monnaie de vote",
    "voting_number": "Nombre de votes",
    "voting_time": "Temps de vote",
    "wait_buyer_payment": "En attente du paiement de l'acheteur",
    "wait_for_payment": "En attente de paiement",
    "waiting_for_receiving": "En attente de la confirmation de réception par le vendeur",
    "warm_prompt": "Conseil chaleureux",
    "warning_setTitle": "Paramètres d'alerte",
    "wechat_account": "Compte WeChat",
    "wechat_upload": "*Méthode d'envoi de code QR sur WeChat : ouvrez l'accueil de WeChat > Moi > Portefeuille > Recevoir > Enregistrer l'image, puis téléchargez le code de réception enregistré dans votre galerie.",
    "week": "Sem",
    "welcome": "Bienvenue sur BitMatrix",
    "welcome_to": "Bienvenue à",
    "winning_record": "Historique des gains",
    "withdrawal_amount": "Montant de retrait quotidien",
    "withdrawal_charge": "Frais de retrait",
    "xilian": "Western Union",
    "yesterday_income": "Revenu d'hier",
    "you_have_selected": "Vous avez sélectionné",
    "Your closing number": "Votre quantité de clôture est inférieure à la quantité minimale de position !",
    "Your have pending Cross orders": "Il existe des ordres en attente de position croisée, veuillez annuler tous les ordres en attente avant de modifier le multiplicateur de levier.",
    "Your have pending isolated orders": "Il existe des ordres en attente de position isolée, veuillez annuler tous les ordres en attente avant de modifier le multiplicateur de levier.",
    "Home page apps": "Applications de la page d'accueil",
    "Home_Edit": "Éditer",
    "recently_deal01": "Transactions",
    "depth01": "Profondeur",
    "depth02": "carte de profondeur",
    "discount_currency_introduction01": "Introduction à la monnaie",
    "Info001": "Introduction",
    "Avg cost": "Coût moyen",
    "Long position": "Position longue",
    "Short position": "Position courte",
    "Hold on": "Attendez un moment",
    "Done": "Terminé",
    "new_version": "Nouvelle version trouvée",
    "Announcement": "Annonce",
    "View details": "Voir les détails",
    "Option Assets": "Actifs d'options",
    "Option Account": "Compte d'options",
    "Option": "Options",
    "1M": "1 min",
    "5M": "5 min",
    "15M": "15 min",
    "30M": "30 min",
    "1H": "1 h",
    "Delivery Time": "Temps jusqu'à la livraison",
    "Call": "Appel",
    "Put": "Mise",
    "Draw": "Égalité",
    "Draw2": "Égalité",
    "Call1": "Long",
    "Put1": "Court",
    "Draw1": "Égal",
    "Buy-2": "Acheter",
    "Rate of return": "Taux de rendement",
    "Change": "Fluctuation",
    "Buy Option": "Acheter des options",
    "Awaiting": "En attente de livraison",
    "Delivery": "Ma livraison",
    "Checkout": "Historique de livraison",
    "Front": "Actuel",
    "Back": "Prochain",
    "Coming soon": "Prochaine livraison",
    "delivery settlement": "Règlement de livraison en cours",
    "Ongoing": "En cours d'achat",
    "Pending purchase": "Achat en attente",
    "Billing Amount": "Quantité de règlement",
    "Amount": "Quantité d'achat",
    "Delivery result": "Résultat de livraison",
    "Delivery time": "Heure de livraison",
    "Up": "Hausse",
    "Down": "Baisse",
    "lookUp": "Regarder en haut",
    "lookDown": "Regarder en bas",
    "Delivery details": "Détails de livraison",
    "Index Composition": "Composition de l'indice",
    "Exchange": "Échange",
    "Change2": "Fluctuation",
    "Weights": "Poids",
    "Order number": "Numéro de commande",
    "Option Session": "Session d'options",
    "Buy Time": "Heure d'achat",
    "Opening time": "Heure d'ouverture",
    "Bet Type": "Type d'achat",
    "Delivery Price": "Prix de livraison",
    "Settlement Currency": "Monnaie de règlement",
    "K-line": "Ligne K",
    "UpSelect": "Sélection de hausse",
    "DownSelect": "Sélection de baisse",
    "Front has ended, please place another order": "Cette période est terminée, veuillez passer une nouvelle commande",
    "Successfully ordered": "Commande réussie",
    "Insufficient balance": "Solde insuffisant",
    "Quantity purchased must be greater than the minimum limit": "La quantité achetée doit être supérieure à la limite minimale",
    "Quantity purchased must be less than the maximum limit": "La quantité achetée doit être inférieure à la limite maximale",
    "The current time is not in the purchase time range": "L'heure actuelle n'est pas dans la plage horaire d'achat",
    "expected": "Revenu prévu",
    "To_a_game": "Une autre partie",
    "Settlement Result": "Résultat du règlement",
    "Delivery-1": "Livraison",
    "Ups and downs": "Fluctuation",
    "duration": "Durée",
    "Option funds daybook": "Journal des fonds d'options",
    "Open innovative options trading": "Ouvrir le trading d'options innovantes",
    "Confirm Open": "Confirmer l'ouverture",
    "Option Agree Content": "Pour protéger vos droits, veuillez lire attentivement le « Contrat d'utilisation du trading d'options innovantes » avant d'ouvrir les options.",
    "Option Agree Content footer": "« Contrat d'utilisation du trading d'options innovantes »",
    "Option Agree Content header": "Pour protéger vos droits, veuillez lire attentivement",
    "Service upgrade, please try again later!": "Mise à niveau du service, veuillez réessayer plus tard !",
    "month": "Mois",
    "Invitation": "Invitation",
    "Markets": "Marchés",
    "Swaps": "Contrats",
    "Trade": "Commerce",
    "Trade-1": "Aller au commerce",
    "Assets": "Actifs",
    "Buy": "Acheter des crypto",
    "Help": "Aide",
    "Service": "Service client",
    "Activity": "Activité",
    "Communtiy": "Communauté",
    "No search results": "Aucun résultat de recherche",
    "The option account asset is 0, please transfer from the wallet account to the option account first.": "L'actif du compte d'options est de 0, veuillez d'abord transférer du compte de portefeuille vers le compte d'options.",
    "Perpetual account assets are 0, please transfer from wallet account to perpetual account first.": "Les actifs du compte perpétuel sont de 0, veuillez d'abord transférer du compte de portefeuille vers le compte perpétuel.",
    "Financing": "Financement",
    "Load": "Charger",
    "Replay001": "Rejouer",
    "Constellation Program": "Présentation spéciale - Programme des constellations",
    "Constellation Program2": "Présentation spéciale - Financement par constellation",
    "rate rankings": "Classement des taux de réussite des institutions",
    "rate rankings2": "Recommandation des étoiles de financement de cette semaine",
    "CXT Quotes": "Cotation",
    "Digital asset trading tool service platform": "Plateforme de service d'outils de trading d'actifs numériques",
    "Service_home": "Service",
    "Strategic cooperation": "Coopération stratégique",
    "Financing assets": "Actifs de financement",
    "Remove 0 from the first number when registering!": "Retirez le 0 du premier numéro lors de l'inscription !",
    "Are you sure you want to quit": "Êtes-vous sûr de vouloir quitter",
    "Opening Price": "Prix d'ouverture",
    "Closing Price": "Prix de clôture",
    "Formula interpretation": "Interprétation de la formule",
    "Note: If the input box is left blank": "Remarque : Si la boîte de saisie est laissée vide, cela signifie que les données de la bourse actuelle n'ont pas été obtenues.",
    "Option Sessions": "Sessions d'options",
    "Result": "Résultat",
    "When data for an exchange is not available": "*Lorsque les données d'une bourse ne sont pas disponibles, sa valeur de poids sera de 0 %. Lorsque le nombre de sources de données valides est ≤ 2, cela est défini comme une égalité.",
    "Assume that the index is derived from the opening prices of five exchanges": "Supposons que l'indice provienne des prix d'ouverture de cinq bourses : A, B, C, D, E ; les prix de clôture sont respectivement : A`, B`, C`, D`, E`.",
    "The proportion of those five exchanges are": "Les poids correspondants des cinq bourses sont : a %, b %, c %, d %, e %",
    "Stream bureau": "Égalité",
    "(valid data source ≤ 2)": "(source de données valide ≤ 2)",
    "Option calculator": "Calculateur d'options",
    "Round selection": "Sélectionner une session",
    "Select option": "Sélectionner une option",
    "Average opening price": "Prix d'ouverture moyen",
    "Average closing price": "Prix de clôture moyen",
    "Calculate now": "Calculer maintenant",
    "Calculation Results": "Résultats du calcul",
    "Calculating Time": "Temps de calcul",
    "Management": "Gestion",
    "Other_home": "Autre",
    "All applications": "Toutes les applications",
    "Boon": "Avantages",
    "Coin_Address_draw": "Adresse de retrait",
    "Congratulations": "Félicitations",
    "Profit": "Profit",
    "Gainers": "Gains",
    "Drop": "Baisse",
    "Order Price": "Prix d'achat",
    "Order Price1": "Prix d'achat",
    "Sec Swap": "Contrat à la seconde",
    "Expected Return": "Rendement attendu",
    "Sec Swap Assets": "Actifs de contrat à la seconde",
    "Sec Swap Bill": "Flux de fonds de contrat à la seconde",
    "Sec Swap Account": "Compte de contrat à la seconde",
    "Go Login": "Aller à la connexion",
    "NSO Session": "Session NSO",
    "Wallet Value1": "Actifs du portefeuille",
    "Spot Value1": "Actifs crypto",
    "PERP Value1": "Actifs perpétuels",
    "Option Value1": "Actifs d'options",
    "Fiat Value1": "Portefeuille OTC",
    "Pledge_Amount": "Montant du gage",
    "total_return2": "Rendement total",
    "Violation_Loss": "Pénalité",
    "Cycle": "Cycle",
    "Locked2": "Verrouillé",
    "Time_Left": "Temps restant",
    "Cancel_Pledge": "Annuler le gage",
    "countDown_day": "jours",
    "Entrust2": "Ordre de confiance",
    "All2": "Tout",
    "Ongoing2": "En cours",
    "Near2": "Prochainement",
    "Ended2": "Terminé",
    "Cancel the pledge?": "Êtes-vous sûr de vouloir annuler le gage ?",
    "Completed2": "Terminé",
    "Cancelled2": "Annulé",
    "Lock-up mining": "Lock-up Mining",
    "Rules Description": "Règle",
    "Today Expected": "Rendement prévu pour aujourd'hui",
    "Order Escrow": "Ordres en séquestre",
    "Minimum": "Montant minimum",
    "Daily Return": "Rendement quotidien",
    "Buy-3": "Acheter",
    "Minimum Amount": "Montant minimum de gage",
    "Lockup Period": "Période de gage",
    "Confirm Pledge": "Confirmer le gage",
    "Buy-4": "Acheter",
    "Conversion Starter": "Lancement de la souscription",
    "Listing": "Mise en ligne",
    "Total Target": "Montant total cible",
    "Conversion Ratio": "Ratio de souscription",
    "Single Limited": "Limite par personne",
    "Participate Order": "Souscription",
    "Fundraised": "Montant levé",
    "Current Progress": "Progrès actuel",
    "Time Left": "Temps restant",
    "Start Time": "Heure de début",
    "End Time": "Heure de fin",
    "Conversion Amount": "Montant de souscription",
    "Conversion Now": "Souscrire maintenant",
    "Conditions": "Conditions de participation",
    "Preheating": "Préparation en cours",
    "Records": "Historique de souscription",
    "Select": "Sélectionner",
    "To": "À",
    "Active Currency": "Monnaie active",
    "Amount-3": "Montant",
    "Conversion Time": "Heure de souscription",
    "Arrival Time": "Heure d'arrivée",
    "Not Arrived": "Pas encore arrivé",
    "Hours-1": "Heures",
    "Risk warning: It is displayed": "Avertissement de risque : il s'agit d'un actif innovant, avec des caractéristiques d'actifs numériques. Veuillez juger prudemment le prix et choisir soigneusement vos décisions d'investissement !",
    "Week-1": "Semaine",
    "S-1": "Secondes",
    "minutes-2": "Minutes",
    "You must pass basic KYC": "L'utilisateur doit passer la vérification KYC de base",
    "You must pass advanced KYC": "L'utilisateur doit passer la vérification KYC avancée",
    "Account order exceeds": "La position du compte dépasse",
    "Suspend": "Suspendre le trading",
    "Successfully": "Souscription réussie",
    "Ongoing-1": "En cours de souscription",
    "Failed": "Souscription échouée",
    "New_Token_Subscribe": "Souscription de nouveau token",
    "More_awaits_you": "Plus de richesses vous attendent",
    "Distance Start": "Temps restant avant le début",
    "day-2": "Jours",
    "Must Eligible": "Doit répondre aux conditions de participation",
    "Custodial funds": "Fonds sous séquestre",
    "Default Ratio": "Taux de pénalité",
    "Wallet Available": "Disponible dans le portefeuille",
    "Done-2": "Terminé",
    "Eligible Amount": "Quantité approuvée",
    "Eligible Price": "Montant approuvé",
    "New Token Subscribe": "Souscription de nouveau token",
    "Subscribe Now": "Souscrire maintenant",
    "Review Time": "Temps de révision",
    "Review Passed": "Révision approuvée",
    "Review Failed": "Révision non approuvée",
    "Not Reviewed": "Pas encore révisé",
    "Denial Reason": "Raison du refus",
    "If not received, please contact service": "Si non reçu, veuillez contacter le service client !",
    "Returned to wallet": "Renvoyé au compte du portefeuille",
    "From wallet assets": "Disponible depuis les actifs du portefeuille",
   "Day-3": "J",
    "Sec Swap asset is 0, please transfer from wallet account to Sec Swap first": "L'actif du compte de contrat à la seconde est de 0, veuillez d'abord transférer du compte de portefeuille vers le compte de contrat à la seconde.",
    "Settlement": "En cours de règlement",
    "Completed": "Règlement terminé",
    "Password Setting": "Paramétrage du mot de passe",
    "Set password and email": "Définir le mot de passe et l'email",
    "Email Settings": "Paramètres de l'email",
    "First-time registered users mus": "Les nouveaux utilisateurs doivent compléter le paramétrage du mot de passe et de l'email.",
    "Driver ID": "Permis de conduire",
    "Preview": "Demande de prix",
    "cancelTime": "Temps d'annulation",
    "Total_assets_valuation": "Évaluation totale des actifs",
    "Invite Friends": "Inviter des amis",
    "RedGreen": "Rouge pour la hausse, vert pour la baisse",
    "GreenRed": "Vert pour la hausse, rouge pour la baisse",
    "Color Preference": "Préférence de couleur",
    "Fuzzy Information": "Informations floues",
    "user_bind_xilain_trade_pwd": "Veuillez entrer le mot de passe de trading",
    "login_please_input1": "Veuillez entrer le code de vérification SMS",
    "user_please_input9": "Veuillez entrer le code de vérification Google",
    "user_link_name": "Nom de la chaîne",
    "user_coin_bring_together": "Fusionner",
    "chongbi0": "Instructions de dépôt :",
    "chongbi1": "1. Il est interdit de déposer d'autres actifs que {0}. Tout dépôt d'actifs non {1} ne pourra pas être récupéré.",
    "chongbi2": "2. Veuillez envoyer le montant de {1} que vous souhaitez déposer à cette adresse via le client {0} ou le portefeuille en ligne. Une fois l'envoi effectué, le système créditera automatiquement cette transaction après avoir reçu {2} confirmations. Veuillez patienter.",
    "chongbi3": "3. Une même adresse peut être utilisée plusieurs fois pour les dépôts sans impact sur le crédit.",
    "chongbi4": "4. Montant minimum de dépôt : {0}.",
    "currency_withdrawal_rate_rules": "Règles de taux de retrait pour {0} :",
    "currency_withdrawal_rate_rules1": "1. Pour la sécurité des fonds des utilisateurs, la plateforme peut confirmer votre opération de retrait par téléphone, veuillez être attentif à vos appels.",
    "currency_withdrawal_rate_rules2": "2. Les dépôts de {0} ne peuvent être retirés qu'après {1} confirmations.",
    "currency_withdrawal_rate_rules3": "3. Montant pouvant être retiré ≤ Actifs disponibles du compte - Actifs numériques non confirmés.",
    "currency_withdrawal_rate_rules4": "4. Maximum de {0} retraits par jour, montant maximum de {1} par jour.",
    "currency_transaction_password": "Mot de passe",
    "long_press_to_save_to_album": "Appuyez longuement pour enregistrer dans l'album",
    "the_fee_is_outside_the_range": "Les frais ne sont pas dans la plage autorisée",
    "start_and_end_date": "Date de début et de fin",
    "The_withdrawal_amount_cannot_be_less": "Le montant du retrait ne peut pas être inférieur à {0}",
    "withdrawal_details": "Détails du retrait",
    "RegisterRemove0": "Retirez le 0 du premier numéro lors de l'inscription",
    "closeLockUpPopup": "Annuler le gage entraînera une pénalité de {0} jours, le montant de la pénalité est de « {1}{2} » et le capital réel crédité sera de « {3}{4} ».",
    "LockUpDuan": "Lock-up Mining",
    "NewTokenSubscribe": "Souscription de nouveau token",
    "MoreWealthAwaitsYou": "Plus de richesses vous attendent",
    "TopGainers": "THR",
    "Newest": "TNM",
    "Hot": "CP",
    "DatChange": "Variation quotidienne",
    "SetPassword": "Définir le mot de passe",
    "comm_password": "Mot de passe de trading",
    "unraise_recovering_password": "Après la récupération du mot de passe, il ne sera pas possible de retirer pendant 1h",
    "PDleaseUseSafariBrowser": "Veuillez utiliser le navigateur Safari",
    "ADddToDesktop": "Ajouter BitMatrix au bureau",
    "CDlickBelow": "Cliquez ci-dessous",
    "SDelectAddToHomeScreen": "Puis sélectionnez Ajouter à l'écran d'accueil",
    "ADddToDesktop2": "Ajouter BitMatrix au bureau",
    "CDommonbrowserAddMethod": "Méthode d'ajout pour les navigateurs courants",
    "user_senior_text1": "Attention",
    "user_senior_text2": "La taille du fichier photo ne doit pas dépasser 5 Mo ! Le format du fichier doit être jpg, bmp, png, etc. !",
    "user_senior_text3": "Veuillez vous assurer que la photo est sans filigrane, sans taches, que les informations d'identité sont claires, que le visage est complet et qu'il ne s'agit pas d'une photo de texte inversée ! Ne retouchez pas la photo !",
    "user_senior_text4": "Tenir",
    "user_senior_text5": "Photo d'identité",
    "user_senior_text6": " : Vous devez tenir votre",
    "user_senior_text7": "Date du jour",
    "user_senior_text8": "et",
    "user_senior_text9": "UID",
    "user_senior_text10": "sur un papier et prendre une photo non miroir.",
    "user_senior_text11": "L'autre main doit tenir un papier blanc avec le texte écrit à la main suivant :",
    "user_senior_text12": "Veuillez écrire à la main le contenu suivant, toute discordance entraînera un refus :",
    "user_senior_text13": "« Je suis conscient et j'accepte de prendre des risques d'investissement »",
    "user_senior_text14": ".",
    "user_ID_card": "Carte d'identité",
    "user_passport": "Passeport",
    "Driver_ID": "Permis de conduire",
    "user_senior_upload3": "Télécharger photo d'identité + UID + date",
    "ServiceUpgrade": "Mise à niveau du service en cours",
    "ReferencePrice": "Prix de référence",
    "referenceTips": "Prix unitaire non final, à titre de référence uniquement",
    "NewCoinsLaunch": "Lancement de nouveaux tokens",
    "CurrentlyPurchased": "Souscription actuelle",
    "TotalMarketValue": "Valeur totale du marché",
    "IEORatio": "Ratio de souscription",
    "CurrencyIntroduction": "Introduction à la monnaie",
    "ConvertNow": "Souscrire maintenant",
    "RemainingAmount": "Montant restant",
    "referenceTips": "Basé sur le prix d'achat réel",
    "LastDays7": "7 derniers jours",
    "LastDays30": "30 derniers jours",
    "LastDays90": "90 derniers jours",
    "LockUpTips": "Remarque : Les actifs restants non approuvés {0}{1} ont été renvoyés à votre compte portefeuille.",
    "PromotionalMining": "Promo Minage",

    "hide_coin": "Cacher les cryptomonnaies inférieures à {0} BTC",
    "GoldAssets": "Actifs en or",
    "ModifyEmail": "Modifier l'email",
    "NewEmail": "Nouvel email",
    "NewEmailAddress": "Entrer la nouvelle adresse email",
    "VerifyNewEmail": "Vérifier le nouvel email",
    "EnterNewEmailCode": "Entrer le code de vérification du nouvel email",
    "VerifyOldEmail": "Vérifier l'ancien email",
    "EnterOldEmailCode": "Entrer le code de vérification de l'ancien email",
    "VerifyLoginPassword": "Vérifier le mot de passe de connexion",
    "EnterLoginPassword": "Entrer le mot de passe de connexion",
    "loan": "Centre de prêt",
    "loanText": "Offre un service de prêt sécurisé et fiable, pouvant servir divers objectifs, avec une plus grande liquidité, sans vendre les actifs cryptographiques existants.",
    "loanQuery": "Consultation de prêt",
    "Stop_surplus_tips": "Lorsque le prix du marché atteint {}, un ordre de prise de bénéfice sera déclenché, avec un bénéfice estimé de {} après exécution.",
    "Stop_loss_tips": "Lorsque le prix du marché atteint {}, un ordre de stop loss sera déclenché, avec une perte estimée de {} après exécution.",
    "Stop_surplus_tips1": "Lorsque le prix du marché atteint {}, un ordre de prise de bénéfice sera déclenché, avec une perte estimée de {} après exécution.",
    "Stop_loss_tips1": "Lorsque le prix du marché atteint {}, un ordre de stop loss sera déclenché, avec un bénéfice estimé de {} après exécution.",
    "Countdown": "Compte à rebours",
    "contract_After_adjustment": "Après ajustement, {} USDT seront transférés de votre compte comme nouvelle marge.",
    "contract_subject_to": "(Sous réserve du montant réellement transféré)",
    "contract_the_used_margin": "Après ajustement, la marge utilisée sera réduite de {} USDT, la partie réduite restant dans la position.",
    "contract_transferred_to": "Peut être transféré vers le solde du compte",
    "contract_After_adjustment_q": "Après ajustement, la marge occupée par la position augmentera de {} USDT.",
    "contract_the_used_margin_q": "Après ajustement, la marge occupée par la position sera réduite de {} USDT.",
    "Pleasrdre au - marchée1": "Veuillez entrer le prix de déclenchement de la prise de bénéfice",
    "Please2": "Veuillez entrer le prix de l'ordre de prise de bénéfice",
    "Please3": "Veuillez entrer le prix de déclenchement du stop loss",
    "Please4": "Veuillez entrer le prix de l'ordre de stop loss",
    "Stop profit6": "Le prix de déclenchement de la prise de bénéfice doit être supérieur au dernier prix de transaction",
    "stop loss7": "Le prix de déclenchement du stop loss doit être inférieur au dernier prix de transaction",
    "Please8": "Veuillez entrer la quantité",
    "Please9": "Aucune quantité à clôturer",
    "CountdownTime": "Compte à rebours",
   "h1": "Demande de partenariat",
    "h2": "Classement des commissions",
    "h3": "Devenir partenaire vous permet d'obtenir",
    "h4": "Conditions de promotion",
    "h5": "Pour acheter le statut de partenaire junior, vous devez payer",
    "h6": "Solde actuel",
    "h7": "Partenaire fondateur",
    "h8": "Commission",
    "h9": "Promouvoir maintenant",
    "h10": "Déjà au niveau maximum",
    "h11": "Bonus de commission",
    "h12": "Classement des commissions",
    "h13": "Nombre d'équipes",
    "h14": "Historique des commissions",
    "h15": "Personnes invitées",
    "h16": "Déjà réglé",
    "h17": "Non réglé",
    "h18": "Sous-alternatives",
    "h19": "Montant à payer",
    "h20": "Promotion",
    "h21": "Détails des commissions",
    "h22": "Classement",
    "h23": "Utilisateur",
    "h24": "Récompense de commission",
    "h25": "Le niveau actuel vous permet d'obtenir",
    "h26": "Obtenez la somme totale des commissions converties en USDT.",
    "h27": "Vous devez parrainer {} utilisateurs réguliers",
    "h28": "Vous avez parrainé {} utilisateurs réguliers valides",
    "h29": "Vous devez atteindre {} personnes dans l'équipe",
    "h30": "Nombre d'équipes {} personnes",
    "Stop profit10": "Le prix de déclenchement pour prendre des bénéfices doit être inférieur au dernier prix de transaction",
    "stop loss10": "Le prix de déclenchement pour le stop loss doit être supérieur au dernier prix de transaction",
    "Earn": "Gestion de patrimoine",
    "Simple": "Gagner de l'argent sans perdre",
    "Low1": "Faible risque, rendement stable",
    "Low2": "Risque faible, rendement solide, rachat à tout moment",
    "CurrentAccount": "Compte épargne",
    "Stable": "Valorisation stable, outil de croissance de richesse",
    "EarnLocked": "Gestion de patrimoine à terme",
    "Searchpopular": "Gestion de patrimoine facile, profitez des rendements à tout moment",
    "Annualized": "Taux de rendement annualisé de {}%, actifs sécurisés, rendements élevés",
    "CoinsLC": "Monnaies",
    "PurchaseLC": "Montant d'achat",
    "PurchaseLC2": "({} minimum)",
    "Maximum": "Maximum",
    "EstimatedInterest": "Intérêts estimés",
    "RuleLC": "Règles",
    "SubscriptionTime": "Temps de souscription",
    "Interestaccrualtime": "Temps d'accumulation des intérêts",
    "RevenueTime": "Temps de revenus",
    "RedemptionCycle": "Cycle de rachat",
    "ReturnNow": "Retourner maintenant",
    "BuyLC": "Acheter",
    "PurchaseSuccessful": "Achat réussi",
    "DepositLC": "Dépôt de cryptomonnaie",
    "Yesterday": "Revenus d'hier",
    "TotalLC": "Montant total déposé",
    "TotalReturn": "Revenus cumulés",
    "DepositValuation": "Évaluation du dépôt",
    "DepositDetail": "Détails du dépôt",
    "RevenueDetails": "Détails des revenus",
    "DepositType": "Type de dépôt",
    "ReturnMethod": "Méthode de retour des intérêts",
    "DepositAmount": "Montant du dépôt",
    "APR": "Taux annualisé",
    "Expected": "Revenus prévus",
    "DepositCycle": "Cycle de dépôt",
    "CreationTime": "Temps de création",
    "EarnProfit": "Intérêts de la période",
    "DepositCost": "Capital du dépôt",
    "TotalRelease": "Montant total libéré",
    "ReleaseTime": "Temps de libération",
    "Flexible": "À vue",
    "Fixed": "À terme",
    "Freeze": "Gelé",
    "Completed3": "Terminé",
    "Cancelled": "Annulé",
    "DefaultSettlement": "Règlement par défaut",
    "PurchaseAmount": "Montant d'achat (sans limite)",
    "RetrieveDeposited": "Récupérer le dépôt",
    "PreviewConversion": "Aperçu des résultats de conversion",
    "ToEnsureThe": "Pour garantir la sécurité de votre compte et répondre aux exigences de montants de transaction plus élevés, nous devons procéder à une vérification d'identité avancée.",
    "ToVerification": "Cliquez sur le bouton d'envoi pour contacter le service clientèle pour la vérification.",
    "ToContact": "Contactez immédiatement le service clientèle pour la vérification",
    contract_entrust_failure_0080 : 'Le montant de la commande ne doit pas être inférieur à 100 USDT.',
    "comm_nickname_rule1": "Ne peut pas dépasser  15 lettres de l'alphabet anglais.",
    "comm_nickname_rule2": "Ne peut pas contenir d'espaces ou de caractères spéciaux.",
    "otc_upload_picture3":"Les images doivent être au format jpeg/jpg/png/bmp !",
    "CertificationDifficulties":'Y a-t-il un problème avec la vérification ?',
}