<template>
  <div class="container chargingAddress">
    <nav-bar :showTitle="false" :showRight="false">
      <router-link slot="title" class="title" :to="{path:'recharging',query:{ type: query.type, back: true }}">
        {{ query.coinName }} {{ $t('M.Charge_money') }}
        <img src="@/assets/img/assets/icon_down.png" alt="">
      </router-link>
      <router-link slot="right" class="right" :to="{path:'withdrawalRecords',query:{ coinId: this.query.coinId}}">
        {{ $t('M.Historical record') }}
      </router-link>
    </nav-bar>

    <div class="main">
      <!-- <van-sticky :offset-top="50" v-if="chainData.chainList"> -->
      <div class="tabs cell" v-if="chainData.chainList">
        <div class="tit">{{ $t("M.user_link_name") }}</div>
        <div class="tabs_item">
          <div v-for="(item,i) in chainData.chainList" :key="i" :class="[active==i ?'active_btn':'','btn']"
               @click="onChange(i)">{{ item.chain }}
          </div>
        </div>
      </div>
      <!-- </van-sticky> -->

      <div class="qrcode_wrap">
        <div class="title">{{ $t("M.Scanning_to_obtain") }}</div>
        <div class="qrcode">
          <vue-qr :logoSrc="imageUrl" v-if="userRechargeAddress.address" :text="userRechargeAddress.address" :margin="0"
                  :size="100"/>
        </div>
        <div  style="padding:0px 20px;text-align:center">{{ $t("M.long_press_to_save_to_album") }}</div>
        <div class="address">{{ userRechargeAddress.address }}</div>
        <div class="btn_wrap">
          <a :data-clipboard-text="userRechargeAddress.address" class="iconfont icon-copy">
            <div class="btn">{{ $t("M.click_on_the_copy") }}</div>
          </a>
        </div>
      </div>

      <div class="desc" v-if="chainData.minRechargeAmount">
        <p>{{ $t("M.chongbi0") }}</p>
        <p>
          {{ $t("M.chongbi1") }}
        </p>
<!--        <p>-->
<!--          {{ $t("M.chongbi2", [query.coinName, query.coinName, 12]) }}-->
<!--        </p>-->
        <p>
          {{ $t("M.chongbi3") }}
        </p>
        <p>
          {{ $t("M.chongbi4") }}

        </p>
      </div>

    </div>
  </div>
</template>

<script>
import navBar from '@/components/navBar';
import vueQr from 'vue-qr'
import {mapGetters} from 'vuex'

export default {
  name: 'chargingAddress',
  components: {
    navBar, vueQr
  },
  computed: {
    ...mapGetters([
      'selectCoin', 'partnerId'
    ]),
  },
  data() {
    return {
      imageUrl: require('@/assets/img/assets/logo_48.png'),
      query: {},
      active: 0,
      chainData: {},
      userRechargeAddress: {}
    };
  },
  beforeMount() {
    this.initQuery()
  },
  mounted() {
    this.coinWallet()
    this.getRechargeAddress()
  },

  methods: {

    onChange(e) {
      this.active = e
      this.getRechargeAddress()
    },
    initQuery() {
      if (this.selectCoin.back) {
        return this.query = {...this.$route.query, ...this.selectCoin}
      }
      this.query = this.$route.query || this.selectCoin
    },
    coinWallet() {
      this.$api.coinWallet(this.query.coinId).then((response) => {
        if (!response.meta.success) return
        this.chainData = response.data
        console.log(response.data);
        if (response.data.coinName == 'USDT') {
          this.active = 1
        }
        this.getRechargeAddress()
      })
    },
    getRechargeAddress() {
      const params = {
        coinId: this.query.coinId,
        partnerId: this.partnerId,
        rechargeType: this.chainData.chainList ? this.chainData.chainList[this.active].chain : ''
      }
      this.$api.getRechargeAddress(params).then((response) => {
        if (!response.meta.success) return this.userRechargeAddress = {}
        this.userRechargeAddress = response.data.userRechargeAddress

      })
    }


  },
};
</script>

<style lang="scss" scoped>
.chargingAddress {
  .title {
    // font-size: 18px;
    font-weight: 400;
    color: #191b27;
  }

  .right {
     //font-size: 10px!important;
    font-weight: 400;
    color: #5c6573;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    max-width: 100px;
    line-height: 16px;
    text-align: right;
  }

  .main {
    width: 100%;
    padding: 0 15px;
    height: 376px;

    .tabs {
      width: 100%;
      background: #fff;

      .tabs_item {
        display: flex;
        align-items: center;

        .btn {
          width: 85px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 2px;
          font-size: 14px;
          font-weight: 400;
          color: #9299a9;
          border: 1px solid #9299a9;
          margin-right: 15px;
        }

        .active_btn {
          background: #00b897;
          font-weight: 600;
          color: #ffffff;
          border: none;
        }
      }
    }

    .cell {
      font-size: 14px;
      font-weight: 400;

      .tit {
        font-size: 14px;
        color: #191b27;
        padding: 20px 0 15px;
        font-weight: 600;
      }
    }

    .qrcode_wrap {
      width: 335px;
      margin: 0 auto;
      margin-top: 30px;
      height: 376px;
      background: url('~@/assets/img/assets/bg_qr.png') no-repeat center;
      background-size: cover;
      font-size: 14px;
      color: #5c6573;
      padding: 10px 0 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .title {
        font-size: 14px;
        color: #191b27;
        margin-bottom: 10px;
      }

      .qrcode {
        width: 120px;
        height: 120px;
        border: 2px solid rgba(0, 93, 177, 0.4);
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .address {
        width: 258px;
        height: 20px;
        font-size: 14px;
        color: #9299a9;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .btn_wrap {
        margin: 0;

        .btn {
          width: 130px;
          height: 30px;
          line-height: 30px;
          background: #00b897;
          border-radius: 2px;
          font-size: 14px;
          font-weight: 600;
          color: #ffffff;
          text-align: center;
        }
      }
    }

    .desc {
      font-size: 12px;
      color: #5c6573;
      padding: 10px 0;
      margin: 5px 0 30px;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep {
  .van-sticky--fixed {
    left: 15px;
  }
}
</style>
