<template>
  <div>
    <van-popup v-model="tradeListShow" position="left" :close-on-click-overlay="false" @click-overlay="close"
      @open="getList" :style="{ width: '243px', height: '100%' }">
      <div class="content">

        <p class="tie">{{ $t('M.Perpetual01') }}</p>
        <p class="smtie">USDT{{ $t('M.tab_swaps') }}</p>
        <van-search v-model="searchValue" />
        <div class="listBox">
          <div class="item" v-for="(item, idx) in tradeShowList" :key="idx" @click="selectSymbol(item)">
            <div class="name">{{ item.tradeName }}</div>
            <!-- <img class="collect" @click.stop="collectionCoin(item)" v-if="!item.collect"
              src="../../../assets/img/trade/star0.png" alt="" srcset="">
            <img class="collect" @click.stop="collectionCoin(item)" v-if="item.collect"
              src="../../../assets/img/trade/star.png" alt="" srcset=""> -->
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'lang', 'isLogin'
    ]),

  },
  props: {
    tradeListShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    /* 搜索框改变时 */
    searchValue(newVal) {
      if (newVal == "") {
        this.tradeShowList = this.tradeMetaList
      } else {
        let showArr = [];
        for (const iterator of this.tradeMetaList) {
          let tradeText = iterator.tradeName.toLowerCase()
          let newValText = newVal.toLowerCase()
          if (
            tradeText.indexOf(newValText) != -1
          ) {
            showArr.push(iterator);
          }
        }
        this.tradeShowList = showArr;
      }
    },

  },
  data() {
    return {
      active: 0,
      show: false,
      tradeList: [],
      tradeMetaList: [],
      tradeShowList: [],
      searchValue: '',
    }
  },
  mounted() {
    this.getList().then((data) => {
      let symbolText = data[0].symbol
      if (this.$route.params.symbol) {
        symbolText = this.$route.params.symbol;
      } else {
        if (localStorage.getItem("tradeSymbol")) {
          symbolText = localStorage.getItem("tradeSymbol");
          localStorage.removeItem("tradeSymbol")
        }
      }
      for (let index = 0; index < data.length; index++) {
        const ele = data[index];
        if (ele.symbol == symbolText) {
          this.$emit("changeSymbol", ele)
          break;
        }
      }
    })
  },
  methods: {
    /* 选择交易对 */
    selectSymbol(data) {
      localStorage.setItem("tradeSymbol", data.symbol)
      this.$emit("changeSymbol", data)
      this.close()
    },
    /* 获取列表 */
    getList() {
      let that = this;
      return new Promise(function (resolve, reject) {
        that.$api.getTradeTypeList({
          partnerId: '472437831826935808',
          partnerNo: '1000010001'
        }).then((res) => {
          if (!res.meta.success) {
            reject(error);
            return
          };
          /* 重置元数组 */
          that.tradeMetaList = []
          that.tradeMetaList = JSON.parse(JSON.stringify(res.data));
          that.tradeShowList = that.tradeMetaList
          //循环tradeShowList,将里面的symbol+@拼接成一个字符串
          let symbolString = []
          for (let index = 0; index < that.tradeShowList.length; index++) {
            const element = that.tradeShowList[index];
            symbolString.push(element.symbol)
          }
          that.$emit("listenTradeList",symbolString.join('@'))
          that.$emit("saveTradeList",res.data)
          resolve(res.data);
        })
      });
    },
    /* 关闭 */
    close() {
      this.searchValue = ''
      this.$emit("closeTradeList")
    },
    /* 收藏或取消 */
    collectionCoin(item) {
      if (!this.isLogin) {
        this.$router.push("login")
        return
      };
      Toast.loading({
        message: this.$t('M.loading'),
        forbidClick: true,
        duration: 0,
      });
      this.$api.collection({
        partnerId: '472437831826935808',
        partnerNo: '1000010001',
        tradeId: item.symbol
      }).then((data) => {
        if (data.data.status == "cancel") {
          this.$notify(this.$t("M.user_collection_cencel_success"))
        } else {
          this.$notify(this.$t("M.user_collection_insert_success"))
        }
        this.getList().then(() => {
          Toast.clear();
        })
        this.$emit("isCollection")
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tie {
  font-size: 20px;
  margin-left: 14px;
}

.smtie {
  line-height: 30px;
  margin-left: 14px;
}

.content {
  width: 100%;
  height: 100%;
  padding: 65px 10px 0 0;

  .bar {
    width: 100%;
  }

  .listBox {
    margin-top: 22px;
    padding: 0 15px;

    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 29px;

      .name {
        font-size: 12px;
        font-weight: 400;
        color: #1a1d26;
        line-height: 17px;
      }

      .collect {
        width: 19px;
        height: 19px;
      }
    }
  }
}
</style>
