<template>
  <div class="tradeList">
    <van-tabs v-model="active" line-width="16px" line-height="3px" title-active-color="#00b897" title-inactive-color="#5C6573" color="#00b897" sticky
      offset-top="2.48rem" @change="changeBar">
      <van-tab title-class="titItem" :title="$t('M.recently_deal1')">
        <!-- 最近成交 -->
        <div class="recentlyBox">
          <div class="titBox">
            <div class="item item1" style="color: #9299a9">
              {{ $t("M.bb_time") }}
            </div>
            <div class="item item2">{{ $t("M.price") }}</div>
            <div class="item item3" style="color: #9299a9">
              {{ $t("M.quantity") }}
            </div>
          </div>

          <div class="listBox">
            <div class="itemBox" v-for="(item, idx) in disTradeList" :key="idx">
              <div class="item item1">
                {{
                  $moment(new Date(Number(item.time))).format(
                    "HH:mm:ss"
                  )
                }}
              </div>
              <div class="item item2" v-bind:class="[item.direction == 'BUY' ? 'buy' : 'sell']">
                {{ item.price }}
              </div>
              <div class="item item3">{{ item.amount }}</div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title-class="titItem" :title="$t('M.Open orders')">
        <div class="nowEntrust">
          <van-sticky :offset-top="140">
            <div class="btnBox">
              <div class="allOrder">
                <van-switch v-model="checked" active-color="#00b897" inactive-color="#cfd2d9" size="14px" @change="getUpdata" />
                <span>{{ $t("M.hide_other") }}</span>
              </div>
              <div @click="allOrder">
                {{ $t("M.all_order") }}
                <!-- <router-link :to="{ name: 'TradeOrder' }" target="_blank">
              </router-link> -->
              </div>
            </div>
          </van-sticky>
          <div class="orderListBox">
            <div class="orderList">
              <van-list v-model="loading" :finished="finished" @load="onLoad" loading="loading">
                <div class="item" v-for="(item, idx) in list" :key="idx" @click="lookHurryDetail(item)">
                  <!-- 标题 -->
                  <div class="top">
                    <div class="tit">
                      {{ item.sellCoinName }}/{{ item.buyCoinName }}
                    </div>
                    <img class="cexiao" src="../../../assets/img/trade/cexiao.png" @click.stop="cexiao(item)" alt="" />
                  </div>
                  <!-- 中间信息 -->
                  <div class="mine">
                    <div v-if="item.type == 'SELL'" class="type">
                      {{ $t("M.sell") }}
                    </div>
                    <div v-if="item.type !== 'SELL'" class="type2">
                      {{ $t("M.buy") }}
                    </div>
                    <div class="progress">
                      <van-progress :percentage="item.jindu" :show-pivot="false" color="#00b897" />
                    </div>
                    <div class="num">{{cutOutPointLength(item.jindu,2)}}%</div>
                  </div>
                  <!-- 底部信息 -->
                  <div class="bottom">
                    <div class="item item1">
                      <p class="tit">{{ $t("M.quantity_deal") }}</p>
                      <p class="content">
                        {{ item.count }}/{{ item.completeCount }}
                      </p>
                    </div>
                    <div class="item item2">
                      <p class="tit">{{ $t("M.price") }}</p>
                      <p class="content">{{ item.price }}</p>
                    </div>
                    <div class="item item3">
                      <p class="tit">{{ $t("M.bb_time") }}</p>
                      <p class="content">{{ item.createTime }}</p>
                    </div>
                  </div>
                </div>

                <img v-if="list.length == 0 && finished" class="imgNoData" src="../../../assets/img/public/nodata.png" alt="" srcset="">

                <p v-if="list.length == 0 && finished" class="textNoData">{{$t('M.no_data')}}</p>
              </van-list>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["isLogin", "user", "lang"]),
  },
  props: {
    disTradeList: Array,
    symbol: {
      type: Object,
      default:
        () => {
          return {
            collect: true,
            displayStatus: "visible",
            nowCoin: "BTC",
            partnerTradeId: "641201074438406144",
            sellCoinLogo: "https://fubt-3.oss-cn-hongkong.aliyuncs.com/cdbbdbdf-4307-4bf7-a7ee-ea77fca10b0f",
            symbol: "btcusdt",
            targetCoin: "USDT",
            tradeId: "480309422875213824",
            tradeName: "BTC/USDT",
          }
        },
    },
  },
  data() {
    return {
      active: 0, //选中
      checked: false, //显示全部
      loading: false,
      finished: true,
      list: [],
      page: 1,
    };
  },
  mounted() { },
  methods: {
    /* 查看快速详情 */
    lookHurryDetail(item) {
      this.$router.push({
        path: `/TradeHurryDetail`,
        query: {
          id: item.id
        }
      })
    },
    changeBar() {
      if (this.active == 1) {
        if (!this.isLogin) {
          this.$router.push("login")
          return
        };
        this.list = [];
        this.page = 1;
        this.onLoad();
      }
    },
    allOrder() {
      if (this.isLogin) {
        this.$router.push({
          path: `/TradeOrder`,
        });

      } else {
        this.$router.push({
          name: "login",
        });
      }
    },
    onLoad() {
      if (!this.isLogin) return;
      let sendData = {
        currentPage: this.page,
        pageSize: 10,

      }
      if (!this.checked) {
        sendData.tradeId = this.symbol.partnerTradeId
        sendData.partnerId = "472437831826935808"
        sendData.partnerNo = "1000010001"
        sendData.userId = this.user.id
      }


      this.$api
        .queryMyEntrust(sendData)
        .then((response) => {
          if (response.data.list.length < 10) {
            this.finished = true;
          } else {
            this.finished = false;
          }
          this.loading = false;
          this.page++;
          this.list = this.list.concat(response.data.list);
          for (let i = 0; i < this.list.length; i++) {
            const element = this.list[i];
            element.jindu = (element.completeCount / element.count) * 100
          }
        });
    },
    getUpdata() {
      this.page = 1;
      this.list = [];
      this.onLoad();
    },
    cexiao(item) {
      console.log(item);
      this.$api
        .cancelEntrust({
          id: item.id,
          version: 1,
        })
        .then((response) => {
          console.log(response);
          this.$notify(response.meta.message);
          this.getUpdata();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.imgNoData {
  width: 170px;
  height: 105px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}
.textNoData {
  text-align: center;
  line-height: 100px;
}
.tradeList {
  margin-top: 8px;
  width: 100%;
  background-color: #fff;
  .titItem {
    font-size: 16px;
    font-weight: 600;
  }
  .recentlyBox {
    padding: 15px;
    .titBox {
      display: flex;
    }
    .listBox {
      margin-top: 11px;
      .itemBox {
        display: flex;
        margin-bottom: 15px;
        .buy {
          color: #0AA869;
        }
        .sell {
          color: #ED0071;
        }
      }
    }
    .item {
      flex: 1;
      font-size: 13px;
      color: #9299a9;
      line-height: 19px;
    }
    .item1 {
      text-align: left;
      color: #000;
    }
    .item2 {
      text-align: center;
    }
    .item3 {
      color: #000;
      text-align: right;
    }
  }
  .nowEntrust {
    .btnBox {
      width: 100%;
      background-color: #f8f8fb;
      display: flex;
      padding: 0 15px;
      line-height: 40px;
      height: 40px;
      color: #9299a9;
      justify-content: space-between;
      .allOrder {
        display: flex;
        align-items: center;
        span {
          margin-left: 8px;
        }
      }
    }
    .orderListBox {
      width: 100%;
      padding: 0 15px;
      background-color: #fff;
      .orderList {
        width: 100%;
        .item {
          width: 100%;
          margin-top: 15px;
          .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .tit {
              font-size: 16px;
              font-weight: 600;
              color: #191b27;
              line-height: 23px;
            }
            .cexiao {
              width: 16px;
              height: 16px;
            }
          }
          .mine {
            display: flex;
            align-items: center;
            margin-top: 10px;
            .type {
              // width: 36px;
              height: 20px;
              background: rgba(255, 96, 96, 0.1);
              border-radius: 2px;
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              color: #ED0071;
              line-height: 20px;
            }
            .type2 {
              // width: 36px;
              height: 20px;
              background: rgba(255, 96, 96, 0.1);
              border-radius: 2px;
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              color: #ED0071;
              line-height: 20px;
              background: #d6ede5 !important;
              color: #0AA869;
            }
            .progress {
              width: 100px;
              height: 4px;
              background: rgba(199, 204, 216, 0.2);
              border-radius: 2px;
              margin-left: 20px;
            }
            .num {
              margin-left: 10px;
            }
          }
          .bottom {
            display: flex;
            margin-top: 15px;
            .item {
              flex: 1;
              line-height: 19px;
              font-size: 13px;
              margin-top: 0;
              padding-bottom: 15px;
              border-bottom: 1px solid #ebebf0;
              .tit {
                font-weight: 400;
                color: #9299a9;
              }
              .content {
                font-weight: 600;
                color: #191b27;
                margin-top: 5px;
                white-space: nowrap;
                font-size: 10px;
              }
            }
            .item1 {
              text-align: left;
            }
            .item2 {
              text-align: center;
            }
            .item3 {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

::v-deep .van-tabs__wrap {
  border-bottom: 1px solid #f8f8fb;
}
</style>