<template>
  <div class="container">
    <nav-bar :showRight="false" :title="$t('M.assets_transfer_record')">
      <div slot="right" :class="['right']" @click="showType = !showType">{{$t('M.filtrate')}}</div>
    </nav-bar>
    <!-- 列表 -->
    <van-pull-refresh v-model="refreshing" :pulling-text="$t('M.MJRefreshHeaderIdleText')" :loosing-text="$t('M.MJRefreshHeaderPullingText')"
      :loading-text="$t('M.loading')" @refresh="onRefresh">
      <van-list class="list" v-model="loading" offset="10" :finished="finished" :loading-text="$t('M.loading')" @load="onLoad">
        <div v-if="refreshing" slot="loading"></div>
        <div class="item" v-for="(item,i) in list" :key="i" @click="to(item)">

          <div class="row">
            <div class="name">
              <span>{{item.coinName}}</span>
              <span>{{item.tradeDesc}}</span>
            </div>

            <div class="num">{{item.totalChange>0 ? '+'+ item.totalChange : item.totalChange}}
              <!-- <van-icon name="arrow" /> -->
            </div>
          </div>

          <div class="row">
            <div class="date">{{item.createTime}}</div>
          </div>

        </div>

        <div class="noData" v-if="!list.length && finished">
          <img class="imgNoData" src="@/assets/img/public/nodata.png" alt="" srcset="" />
          <p class="text">{{$t('M.no_data')}}</p>
        </div>
      </van-list>
    </van-pull-refresh>

    <!-- 类型 -->
    <van-action-sheet v-model="showType" :actions="actions" :cancel-text="$t('M.Cancel')" @select="onSelect" @cancel="onCancel" />
  </div>
</template>

<script>
import navBar from '@/components/navBar';
import {mapGetters} from 'vuex'

export default {
  name: 'transferRecord',
  components: {
    navBar
  },
  computed: {
    ...mapGetters([
      'partnerId'
    ]),
  },
  data() {
    return {

      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      finishedText: this.$t('M.no_more_data'),

      queryParams: {
        pageSize: 10,
        i18nTradeTypeCode: ''
      },
      pageNum: 1,

      showType: false,
      showTypevalue: '',

      actions: [{
        name: this.$t('M.all'),
        id: '0',
        color: '#00b897'
      }],
    };
  },

  watch: {
    queryParams: {
      handler() {
        this.pageNum = 1
        this.onLoad()
      },
      deep: true
    },

  },

  beforeMount() {
    this.getWalletTransferTradeType()
  },
  mounted() {

  },

  methods: {

    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.pageNum = 1
          this.list = [];
          this.refreshing = false;
        }
        this.getList()
      }, 500);
    },


    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },

    // 类型选择
    onSelect(item) {
      this.showType = false;
      this.showTypevalue = item.name
      this.queryParams.i18nTradeTypeCode = item.i18nCode
      this.actions.forEach(el => {
        el.color = ''
        if (el.id == item.id) {
          el.color = '#00b897'
        }
      });
    },
    onCancel() { this.showType = false; },

    // 获取类型
    getWalletTransferTradeType() {
      this.$api.getWalletTransferTradeType().then((response) => {
        if (!response.meta.success) return
        const option = response.data.map((item, i) => ({ id: i + 1, name: item.i18nValue, i18nCode: item.i18nCode, color: '' }))
        this.actions = this.actions.concat(option)
      })
    },


    getList() {
      const params = { pageNum: this.pageNum, ...this.queryParams }
      this.$api.getUserWalletTransferBill(params).then((response) => {
        if (!response.meta.success) return
        const { list, total } = response.data.walletTransferBill
        this.loading = false;
        if (this.pageNum == 1) {
          this.list = list
        } else {
          this.list = this.list.concat(list)
        }
        this.pageNum += 1
        if (this.list.length >= total) {
          this.finished = true;
        }
      })
    },

    to(item) {
      const params = {
        tradeDesc: item.tradeDesc,
        amount: item.amount,
        showChange: item.showChange,
        createTime: item.createTime,
        coinName: item.coinName,
        title: this.$t('M.assets_transfer_record'),
        type: 2
      }
      this.$router.push({
        path: '/detail', query: params
      })
    },

  },
};
</script>

<style lang="scss" scoped>
.right {
  font-family: PingFangSC-Regular, PingFang SC;
  color: #00b897;
}

.list {
  padding: 15px;
  .item {
    width: 100%;
    min-height: 70px;
    border-radius: 3px;
    // border-bottom: 1px solid #ebebf0;
    background: #f8f8fb;
    margin-bottom: 10px;
    padding: 10px 15px 10px 10px;
    .row {
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      .name {
        display: flex;
        align-items: center;
        > span:first-child {
          font-size: 16px;
          font-weight: 600;
          margin-right: 10px;
        }
        > span:last-child {
          flex: 1;
          font-size: 12px;
          color: #00b897;
        }
      }
      .num {
        font-size: 16px;
        color: #191b27;
        position: relative;
        i {
          position: absolute;
          right: -15px;
          top: 5px;
        }
      }
    }
    .row:last-child {
      font-size: 12px;
      color: #9299a9;
    }
  }
}
</style>
