export const M = {
    "Slideright": "Ấn giữ nút, kéo sang phải",
    "Pleaseverification": "Vui lòng hoàn tất xác minh bảo mật",
    "Pleaseorder": "Vui lòng bấm vào",
    "Verificationagain": "Không xác minh được, hãy thử lại",
    "10 orders": "10 Đơn hàng",
    "24h High": "Cao nhất 24h",
    "24h Low": "Tối thiểu 24h",
    "24h Trading Vol": "Giao dịch 24h Vol",
    "24h Vol": "Số lượng 24h",
    "ADL mechanism": "Cơ chế ADL",
    "AMT.": "Lượng",
    "Activity _home": "Hoạt động",
    "Actual transaction number": "Số giao dịch thực tế",
    "Actual transaction price": "Giá giao dịch thực tế",
    "Add margin": "Cuộc gọi ký quỹ",
    "Adjust leverage": "Điều chỉnh đòn bẩy",
    "Adjust success": "Điều chỉnh thành công",
    "Advanced limit order offers 3": "Lệnh giới hạn nâng cao cung cấp thêm 3 tùy chọn đặt hàng so với lệnh giới hạn thông thường, bao gồm “Chỉ đăng”, “Điền hoặc Kilô” và “Ngay lập tức hoặc Hủy”",
    "Alipay": "Alipay",
    "All Closed": "Đóng tất cả các vị trí",
    "All Filled": "Hoàn thành giao dịch",
    "AllDetails": "Xem chi tiết thanh lý",
    "AllType": "Các loại",
    "Amount can flat": "Có thể thanh toán",
    "Appeal_reason": "lí do đối phương khiếu nại",
    "Applet service": "Dịch vụ Applet",
    "Are you sure to cancel the order？": "Bạn có chắc chắn sẽ hủy đơn đặt hàng không？",
    "Asked_to_record": "mời cung cấp đúng yêu cầu của clip",
    "Asset conversion": "Giá trị tài sản",
    "Asset_types": "loại tài sản",
    "Assets": "Tài sản",
    "At the current price": "Với giá hiện tại",
    "Avail.": "Có sẵn",
    "Avail. margin": "Ký quỹ khả dụng",
    "Available": "Có sẵn",
    "Avg close price": "Giá đóng cửa trung bình",
    "Avg open price": "Giá mở cửa trung bình",
    "Avg.price": "Giá bình quân",
    "BBO": "BBO",
    "Bankcard": "ATM",
    "Bankruptcy price": "Giá phá sản",
    "Batch Cancel": "Hủy hàng loạt",
    "Branch_address": "địa chỉ chi nhánh",
    "Buy crypto_home": "Mua",
    "Calculations are not based on": "Kết quả tính toán chỉ mang tính chất tham khảo. Giá thanh lý chỉ thể hiện dữ liệu của duy nhất một vị thế mở theo một hợp đồng.",
    "Can't calculate": "Không thể tính toán",
    "Cancel": "Hủy",
    "Cancel all": "Hủy hàng loạt",
    "Cancel order": "Hủy đơn hàng",
    "Cancel_the_login": "hủy đăng nhập",
    "Capital cost": "Dòng chảy vốn",
    "Capital cost - Expenditure": "Chi phí tài trợ",
    "Capital fee": "Phí tài trợ",
    "Capital fee - all": "Phí tài trợ-Tất cả",
    "Capital fee - income": "Thu nhập từ phí quỹ",
    "Capital success": "Thu hồi",
    "Charge_money": "đặt cọc",
    "Chinese_Yuan": "Nhân dân tệ",
    "Close all of": "Bạn có chắc chắn",
    "Close long": "Đóng dài",
    "Close out time": "Thời gian kết thúc của tất cả thanh lý",
    "Close price": "Đóng giá",
    "Close short": "Đóng ngắn",
    "Closed": "Số lượng thanh lý",
    "Closing PnL gains": "Lợi nhuận",
    "Closing PnL ratio": "Tỷ suất lợi nhuận",
    "Closing profit": "Lợi nhuận cuối kỳ",
    "coin_failure_0002": "Dữ liệu tiền tệ không tồn tại",
    "Coming soon": "Giữ nguyên",
    "Common functions": "Các chức năng chung",
    "Communtiy_home": "Cộng đồng",
    "Conceal canceled orders": "Ẩn đơn đã huỷ",
    "Confirm_the_login": "xác nhận đăng nhập",
    "Confirm_to_mention_money": "xác nhận rút coin",
    "Cont": "Sheet",
    "Copy link": "Sao chép liên kết",
    "Cross": "Cross",
    "Currency_rate_description": "giải thích phí",
    "Current": "Hiện tại",
    "Delete_option": "Xóa",
    "Determine_to_cancel": "xác nhận hủy?",
    "Don't show me again": "Không có lời nhắc nào nữa",
    "Draw_inconsistency": "mật mã không khớp",
    "Draw_the_unlock": "trượt để thiết lập mật mã",
    "Enter email address": "Nhập địa chỉ email",
    "Enter expected PnL": "Nhập PnL mong đợi",
    "Enter expected PnL Ratio": "Nhập Tỷ lệ PnL mong đợi",
    "Enter leverage from 1 up to 125": "Nhập đòn bẩy từ 1 đến 125",
    "Enter phone number": "Nhập số điện thoại hoặc email của bạn",
    "Enter the verification code": "Nhập mã xác nhận",
    "Equity": "Quyền tiền tệ",
    "Est liquidation price": "Giá thanh lý bắt buộc ước tính",
    "Face value": "Giá trị hợp đồng",
    "Filed Value": "Giá trị giao dịch",
    "Fill or Kill": "Đã hoàn thành hoặc bị hủy ngay lập tức",
    "Filled amt": "Âm lượng",
    "Filled time": "đạt thời gian",
    "Flash_strong": "flash quá sáng",
    "Funding rate": "Tỷ lệ tài trợ",
    "Funding rate - 1": "Tỷ lệ tài trợ",
    "Funding rate comparison": "So sánh tỷ lệ tài trợ",
    "Funding rate time": "Tỷ lệ tài trợ thời gian",
    "Fuzzy_information": "thông tin mờ",
    "Gesture_error": "sai mật mã",
    "Google_code": "Mã xác minh của Google",
    "Help_home": "Cứu giúp",
    "Hide_no_asset_currency": "ẩn coin bằng 0",
    "Highest PnL ratio": "năng suất cao nhất",
    "Historical record": "kỷ lục lịch sử",
    "I agree to": "Chọn đồng ý",
    "I have read and agree to this agreement": "Tôi đã đọc và đồng ý với thỏa thuận này",
    "If_the_buyer_is_at": "nếu người mua tại",
    "Immediately or Cancel": "Đóng ngay lập tức và hủy phần còn lại",
    "In force": "Có hiệu lực",
    "Index": "Chỉ số giao ngay",
    "Index Price": "Giá chỉ mục",
    "Index_contrct": "Chỉ số giao ngay",
    "Info": "Giới thiệu về hợp đồng",
    "Initial margin": "Ký quỹ ban đầu",
    "Instrument": "Các giống giao dịch hiện tại",
    "Insurance fund": "Số dư dự phòng rủi ro",
    "Interest bearing record": "Hồ sơ tính lãi",
    "Invalid_qr_code": "QA vô hiệu",
    "Invite code(Optional)": "Mã mời (tùy chọn)",
    "InviteCodeMuster": "Hãy nhập mã mời (bắt buộc)",
    "Invite_home": "Mời",
    "Isolated": "Isolated",
    "Lack_of_edge": "góc thiếu",
    "Last Price": "Giá thị trường thành giao",
    "Latest Filled price": "Cuối cùng",
    "Latest Filled price01": "Giá mới nhất",
    "Latest price": "Giá mới nhất",
    "Leverage": "Tận dụng",
    "Leverage after increase": "Đòn bẩy sau khi tăng ：",
    "Leverage11": "Tận dụng",
    "Leverage_BB": "Điều chỉnh đòn bẩy",
    "Limit order is an order": "Lệnh giới hạn là lệnh giới hạn giá mua tối đa của người mua và giá bán tối thiểu của người định giá. Sau khi bạn đặt hàng, hệ thống của chúng tôi sẽ đăng nó lên sách và khớp nó với các đơn hàng có sẵn - với mức giá bạn đã chỉ định hoặc tốt hơn.",
    "Limit price": "Giới hạn",
    "Limit/Market": "Hạn / Thị",
    "LimitAndMarket": "Giá giới hạn / giá thị trường",
    "Liquidation price": "Giá thanh lý",
    "Liquidation price after increase": "Giá thanh lý sau khi tăng ：",
    "Long": "Long",
    "Lowest PnL ratio": "năng suất thấp nhất",
    "MAIL_COUNTRY_NOT_EXITS": "quốc gia không tồn tại！Add mail Sau khi thêm mới",
    "MAIL_NOT_MAIL_PROVIDER": "hiện không phuc vụ email,mời thử lại sau！",
    "MAIL_TEMPLATE_NOT_FOUND": "bản mail không tồn tại！Gửi lên sau khi thêm mới",
    "MAIL_TO_ADDRESS_NOT_FOUND": "mail không tồn tại，gửi lên sau khi kiểm tra",
    "MJRefreshAutoFooterIdleText": "nhấp hoặc kéo lên để tải thêm",
    "MJRefreshAutoFooterNoMoreDataText": "toàn bộ đã tải xong",
    "MJRefreshAutoFooterRefreshingText": "đang tải thêm số lượng...",
    "MJRefreshBackFooterIdleText": "kéo lên để tải thêm",
    "MJRefreshBackFooterNoMoreDataText": "đã tải xong toàn bộ",
    "MJRefreshBackFooterPullingText": "thả ra để tải thêm",
    "MJRefreshBackFooterRefreshingText": "đang tải thêm số liệu...",
    "MJRefreshHeaderDateTodayText": "hôm nay",
    "MJRefreshHeaderIdleText": "kéo xuống để làm mới",
    "MJRefreshHeaderLastTimeText": "làm mới sau cùng：",
    "MJRefreshHeaderNoneLastDateText": "không ghi chép",
    "MJRefreshHeaderPullingText": "thả ra để làm mới ngay",
    "MJRefreshHeaderRefreshingText": "đang làm mới...",
    "MKT Close ALL": "MKT Đóng TẤT CẢ",
    "Margin add": "Cuộc gọi ký quỹ",
    "Margin mode": "Chế độ tất cả các vị trí",
    "Margin ratio": "Tỷ lệ ký quỹ",
    "Margin reduce": "Giảm lợi nhuận",
    "Margin transfer": "Chuyển tiền ký quỹ",
    "Margin-1": "Số dư ký quỹ",
    "Margin-2": "Lề",
    "Margined": "hợp đồng",
    "Mark price": "Đánh dấu giá",
    "Market": "Giá",
    "Market Order Dig": "Lệnh thị trường cho phép người dùng đặt lệnh với giá tốt nhất thị trường hiện tại để đạt được mục đích giao dịch nhanh chóng.",
    "Market Overview": "Tổng quan thị trường",
    "Market_transaction": "giá thị trường",
    "Max": "Có thể hoàn tác tối đa",
    "Max Open int": "Lãi suất mở tối đa",
    "Max buy": "Mua tối đa",
    "Max long": "hướng tăng mua",
    "Max position size at current leverage": "Đòn bẩy hiện tại tối đa có thể được mở:",
    "Max sell": "Bán tối đa",
    "Max short": "Mua hướng giảm",
    "Max position size at current leverage": "Tối đa quy mô vị thế theo đòn bẩy hiện tại",
    "Mention_money": "rút coin",
    "Mention_money_address": "mời nhập đúng địa chỉ",
    "Mention_money_tips_ios": "nhắc\n1、nhập địa chỉ ví，cần mật mã rút,tin nhắn,code mail hoặc google authy。\n2、thêm ví mặc định,lần rút tiếp theo không cần xác nhận。",
    "Messages_notification": "*hãy xác nhận thẻ ngân hàng đã mở dịch vụ tin nhắn",
    "Mini Programs": "Các chương trình nhỏ",
    "Minimum_handling_charge": "lượng rút phải lớn hơn phí rút",
    "Minimum_withdrawal": "rút nhỏ nhất",
    "MoreNot9": "Các mô-đun đã chọn không được vượt quá 14",
    "Most increase": "Tăng nhiều nhất",
    "Most reduce": "Giảm tối đa amt",
    "Must be integer multiple of": "Phải là bội số nguyên của",
    "Not Enabled": "Không được kích hoạt",
    "Not_on": "Tạm thời chưa list",
    "Not_to_mention_money": "để đảm bảo an toàn,sau khi đặt lại mật mã 1h không được rút ,PUSH tài sản ",
    "OTC account": "Tài khoản pháp",
    "OTC assets": "Ví OTC",
    "OTC交易须知": "OTC cần biết",
    "On Hold": "Khoá",
    "One-way": "Một chiều",
    "One/Two-way": "Một / Hai chiều",
    "Open Time": "Giờ mở cửa",
    "Open amount": "Lượng mở",
    "Open int.": "Quan tâm mở",
    "Open interest": "Quan tâm mở",
    "Open long": "Mở dài",
    "Open orders": "Đơn đặt hàng mở",
    "Open price": "Mở giá",
    "Open short": "Mở ngắn",
    "Order Details": "Chi tiết đơn hàng",
    "Order Sorting": "Sắp xếp đơn hàng",
    "Order Value": "Giá trị đặt hàng",
    "Order amt": "Tổng đơn hàng",
    "Order history": "Lịch sử ủy thác",
    "Order price": "Giá đặt hàng",
    "Order trigger direction": "Đặt hàng hướng kích hoạt",
    "Order type": "Kiểu đơn hàng",
    "PL": "Lợi nhuận",
    "PL Ratio": "Tỷ lệ PL",
    "Pair01": "Tiền tệ hợp đồng",
    "Parse_failure": "lưu thất bại",
    "Partially Filled": "Thỏa thuận một phần",
    "Partially closed": "Thanh lý một phần",
    "Password": "mật khẩu mở khóa",
    "Payment_type": "loại nhận",
    "Perpetual": "Hợp đồng vĩnh viễn",
    "Perpetual funds daybook": "Hóa đơn vĩnh viễn",
    "Perpetual01": "PERP",
    "Pingclose": "đóng một vị trí",
    "Please input a password": "Vui lòng nhập",
    "Position": "Chức vụ",
    "Position balance": "Số dư vị trí",
    "Position history": "Vị trí lịch sử",
    "QR_code": "mã QA",
    "Quantity cannot exceed": "Số lượng không được vượt quá 125",
    "Quantity not less than": "Số lượng không ít hơn 1",
    "Recent close": "Đóng gần đây",
    "Recording_requirements": "yêu cầu clip ",
    "Required position margin at current leverage": "Tỷ lệ ký quỹ vị thế bắt buộc ở mức đòn bẩy hiện tại ：",
    "Reset_transaction_password": "đặt lại mã giao dịch",
    "Revoked": "Đã thu hồi",
    "SL": "SL",
    "SL order": "Lệnh cắt lỗ",
    "SL order price": "Giá lệnh cắt lỗ",
    "SL order price can't be empty": "Giá lệnh cắt lỗ không được để trống",
    "SL trigger price": "Giá kích hoạt cắt lỗ",
    "SL trigger price can't be empty": "Giá kích hoạt cắt lỗ không được để trống",
    "SMS_30_OUT_RESTRICTED": "lỗi gửi mã xác nhận,mỗi 30p chỉ gửi 1 lần。",
    "SMS_BAD_ARGUMENT_FORMAT": "định dạng dt không đúng。",
    "SMS_COUNTRY_NOT_EXITS": "quốc gia không tồn tại,xác nhận xong hãy gửi tin nhắn！",
    "SMS_DAY_LIMIT_PER_MOBILE": "trong 24h gửi quá số lần cùng 1 sdt",
    "SMS_DUP_IN_SHORT_TIME": "cùng 1 sdt trong 30s gửi cùng nội dung",
    "SMS_FAILURE": "gửi thất bại",
    "SMS_HOUR_LIMIT_PER_MOBILE": "trong 1h cùng 1sdt quá số lần gửi",
    "SMS_MARKET_FORBIDDEN": "do quy định nhà điều hành，tin nhắn không thể gửi",
    "SMS_NOT_SMS_PROVIDER": "không nhận dịch vụ sms,mời thử lại sau！",
    "SMS_NOT_SMS_PROVIDER_TEMPLATE": "không có dịch vụ sms，mời thử lại sau！",
    "SMS_PHONE_OVER_COUNT": "sdt nhiều lần gửi mã xác nhận，thử lại sau 24h！",
    "SMS_PHONE_PROHIBIT_SEND": "sdt không nhận tin nhắn，thử lại sau 24h！",
    "SMS_SUCCESS": "gửi thành công",
    "SMS_TOO_MANY_TIME_IN_5": "trong 5p cùng sdt nội dung gửi quá 3 lần",
    "SMS_UNKNOWN_EXCEPTION": "hệ thống xảy ra lỗi không xác định",
    "SMS_code": "Mã xác minh SMS",
    "Save picture": "Lưu hình ảnh",
    "Scanning_to_obtain": "quét địa chỉ ví nạp",
    "See auto deleverage liquidation for details": "Xem thanh lý tự động xóa để biết chi tiết",
    "Service_home": "Dịch vụ",
    "Set_transaction_password": "thiết lập mã giao dịch ",
    "Settlement1": "Giải quyết",
    "Settlrment coin": "Đồng xu thanh toán",
    "Share time": "Chia sẻ thời gian",
    "Shielding success": "Bảo vệ thành công",
    "Short": "Short",
    "Sign Up": "Đăng kí",
    "Sign in": "Đăng nhập",
    "Simplified_Chinese": "Tiếng trung",
    "StatusOrder": "Trạng thái",
    "Stop loss": "Chặn đứng tổn thất",
    "Stop profit stop loss": "Dừng lãi dừng lỗ ít nhất chọn một, nếu không không thể đặt hàng",
    "Sustainable account": "Tài khoản vĩnh viễn",
    "Sustainable assets": "Tài sản PERP",
    "Switch to PnL": "Chuyển sang Pln",
    "Switch to PnL ratio": "Chuyển sang tỷ lệ PnL",
    "TP": "Chốt lời",
    "TP order": "Chốt lời hoa hồng",
    "TP order price": "Chốt lời Giá hoa hồng",
    "TP order price can't be empty": "Giá của lệnh chốt lời không được để trống",
    "TP trigger price": "Giá kích hoạt chốt lời",
    "TP trigger price can't be empty": "Giá kích hoạt chốt lời không được để trống",
    "TP | SL": "Ngừng P / L",
    "TP | SL order": "Chốt lời và hoa hồng cắt lỗ",
    "Take profit": "Đặt lợi nhuận",
    "Telegraphic_transfer_address": "địa chỉ chuyển khoản",
    "The Calculator": "Máy tính",
    "The maximum flatable quantity is": "Số lượng san lấp mặt bằng tối đa là:",
    "The maximum leverage is": "Đòn bẩy tối đa là",
    "The minimum flatable quantity is": "Số tiền có thể cấp tối thiểu là",
    "The minimum leverage is": "Đòn bẩy tối thiểu là",
    "The poster has been saved to the album": "Áp phích đã được lưu vào album",
    "The_correct_sample": "ví dụ đúng",
    "The_input_signal": "trượt để nhập mật mã",
    "The_new_address": "tạo địa chỉ mới",
    "They_are_the_number_of": "số lệnh chờ",
    "Tick size": "Kích thước đánh dấu",
    "To_update": "làm mới",
    "Toggle_front_camera": "chuyển camera trước và giữ phần mặt ở giữa",
    "Total": "Toàn bộ",
    "Total coin": "Số lượng tiền tệ được nắm giữ",
    "Total cont": "Số lượng vị trí mở",
    "Total value": "Giá trị vị trí",
    "Total_asset_valuation": "Định giá tài sản",
    "Trade type": "Loại hình giao dịch",
    "Trade_sector": "mời chọn",
    "Transfer": "Truyền:",
    "Transfer_to_remind": "chuyển khoản mời chú thích(tự chịu trách nhiệm)",
    "Trigger mark price": "Giá đánh dấu kích hoạt",
    "Trigger price": "Giá kích hoạt",
    "Two-way": "Hai chiều",
    "UPL": "UPL",
    "US_dollar": "đô la Mỹ",
    "Unblocked": "Đã mở khóa",
    "Uneasy lies the head that wears a crown": "Anh ta buộc anh ta phải mạnh mẽ \ nTôi là vua hộ tống",
    "Unfilled": "Chưa được lấp đầy",
    "Unfilled11": "Đang chờ giao dịch",
    "Upload_collection_code": "tải lên mã nhận tiền",
    "Usdt-Margined": "Hợp đồng USDT",
    "Users who register": "Người dùng đăng ký lần đầu tiên phải hoàn thành cài đặt mật khẩu đăng nhập",
    "Verification Code": "Mã xác nhận",
    "Videoing_requirement": "yêu cầu chụp",
    "View": "Quan điểm",
    "View more": "Chi tiết vị trí",
    "Waiting effect": "Hiệu ứng chờ đợi",
    "Waiting_for_the_payment": "Đợi Thanh toán",
    "Wechat": "Wechat",
    "WestUnion": "WestUnion",
    "Without_her": "không có gương",
    "Your closing number": "Số lượng đóng của bạn ít hơn vị trí tối thiểu!",
    "Your have pending Cross orders": "Bạn có các lệnh Chéo đang chờ xử lý. Hãy hủy chúng trước khi điều chỉnh đòn bẩy của bạn.",
    "Your have pending isolated orders": "Bạn có các lệnh cô lập đang chờ xử lý. Hãy hủy chúng trước khi điều chỉnh đòn bẩy của bạn.",
    "abandoned": "Bị bỏ rơi",
    "abnormal_assets": "Tài sản tài khoản là bất thường, hoạt động này có thể được thực hiện tạm thời!",
    "about_us": "Về chúng tôi",
    "account": "Số tài khoản",
    "account_empty": "tài khoản sai",
    "account_failure_0001": "Lượng Giao Dịch ID Thêm REDIS Thất Bại！",
    "account_failure_0002": "Số Dư Không Đủ，Không Thể Xử Lý!",
    "account_failure_0003": "Người Dùng Đóng Băng Nhật Ký Mới Thất Bại！",
    "account_failure_0004": "Người Dùng Đóng Băng Thất Bại！",
    "account_failure_0005": "Người bán bị trừ hồ sơ nhật ký có sẵn thất bại！",
    "account_failure_0006": "Người dùng Khấu Trừ Thất bại！",
    "account_failure_0007": "người dùng tăng thêm nhật ký thất bại！",
    "account_failure_0008": "người dùng khả dụng tăng thêm thất bại！",
    "account_failure_0009": "người dùng thêm coin,người dùng thêm coin thất bại！",
    "account_failure_0010": "người dùng thêm coin mới thất bại！",
    "account_failure_00100": "người dùngIDKhông được để trống!",
    "account_failure_00101": "người dùngIDkhông được để trống",
    "account_failure_00102": "loại coinIDkhông được để trống",
    "account_failure_00103": "số lượng không được để trống",
    "account_failure_00104": "Số lượng không thể nhỏ hơn khối lượng giao dịch tối thiểu",
    "account_failure_00105": "mật mã giao dịch không được trống",
    "account_failure_00106": "sai mật mã",
    "account_failure_00107": "giá không được trống",
    "account_failure_00108": "đơn giá nhỏ hơn giá tối thiểu",
    "account_failure_00109": "người dùng không tồn tại！",
    "account_failure_0011": "ví đã tồn tại loại tiền này!",
    "account_failure_00110": "PUSHKhởi động tài sản thất bại！",
    "account_failure_00111": "PUSHIDkhông được trống",
    "account_failure_00112": "PUSHthanh toán thất bại！",
    "account_failure_00113": "PUSHhủy đơn thất bại！",
    "account_failure_00114": "không thể cập nhật địa chỉ ví",
    "account_failure_00115": "địa chỉ ví không được trống！",
    "account_failure_00116": "ví rút thêm mới thất bại",
    "account_failure_00117": "Idkhông được trống",
    "account_failure_00118": "xóa ví thất bại",
    "account_failure_00119": "mạng xấu ,thử lại sau!",
    "account_failure_0012": "không đủ đóng băng,không thể xử lý!",
    "account_failure_00120": "địa chỉ này đã tồn tại",
    "account_failure_00121": "không thểPUSHloại coin",
    "account_failure_00122": "mã code điện thoại trống！",
    "account_failure_00123": "mã code mail trống！",
    "account_failure_00124": "google code trống！",
    "account_failure_00125": "code sai！",
    "account_failure_00126": "cấm tự động push！",
    "account_failure_00127": "ví rút không hợp pháp！",
    "account_failure_00129": "đổi mật khẩu không quá 24 tiếng không thể thực hiện",
    "account_failure_0013": "người dùng đóng băng nhật ký thất bại！",
    "account_failure_00130": "tài sản không ngang bằng,không thể thực hiện",
    "account_failure_00131": "Tài sản bất thường，cấm giao dịch",
    "account_failure_00132": "Nhãn không hợp lệ",
    "account_failure_00133": "Loại tiền không tồn tại hoặc bị cấm",
    "account_failure_0014": "người dùng đóng băng khấu trừ thất bại!",
    "account_failure_0015": "nhật ký lãi suất người dùng tăng thêm thất bại！",
    "account_failure_0016": "lãi suất khả dụng tăng thêm thất bại！",
    "account_failure_0017": "nhật ký lãi suất khấu trừ thất bại！",
    "account_failure_0018": "khấu trừ lãi suất khả dụng thất bại！",
    "account_failure_0019": "đóng băng khả dụng thất bại！",
    "account_failure_0020": "đóng băng khả dụng thất bại,ghi lại nhật ký thất bại ！",
    "account_failure_0021": "khả dụng nhật ký đóng băng thất bại！",
    "account_failure_0022": "khả dụng đóng băng thất bại！",
    "account_failure_0023": "tăng thêm nhật kí khả dụng thất bại！",
    "account_failure_0024": "tăng thêm khả dụng thất bại！",
    "account_failure_0025": "đóng băng khấu trừ nhật ký thất bại！",
    "account_failure_0026": "đóng băng khấu trừ thất bại！",
    "account_failure_0027": "nhật ký khả dụng khấu trứ thất bại！",
    "account_failure_0028": "khả dụng khấu trừ thất bại！",
    "account_failure_0029": "Hồi lại thất bại,đóng băng hồi phục nhật kí thất bại！",
    "account_failure_0030": "hồi lại thất bại,đóng băng hồi phục thất bại！",
    "account_failure_0031": "lượng tiền không đủ không thể hồi lại！",
    "account_failure_0032": "Hồi lại thất bại,đóng băng hồi phục nhật kí thất bại！",
    "account_failure_0033": "hồi lại thất bại,đóng băng hồi phục thất bại",
    "account_failure_0034": "đóng băng tăng thêm nhật ký thất bại！",
    "account_failure_0035": "đóng băng tăng thêm thất bại！",
    "account_failure_0036": "khả dụng đến đóng băng nhật ký thất bại!",
    "account_failure_0037": "khả dụng đến đóng băng thất bại!",
    "account_failure_0038": "đóng băng đến khả dụng nhật ký thất bại!",
    "account_failure_0039": "đóng băng đến khả dụng thất bại!",
    "account_failure_0040": "nhật ký sàn tăng thêm khả dụng thất bại！",
    "account_failure_0041": "sàn khả dụng tăng thêm thất bại！",
    "account_failure_0042": "lệnh bất thường！",
    "account_failure_0043": "lệnh thất bại！",
    "account_failure_0044": "lệnh đã thành công！",
    "account_failure_0045": "lệnh đang tiến hành!",
    "account_failure_0046": "xử lý thành công！",
    "account_failure_0047": "thu hồi thất bại tài sản sàn hồi phục thất bại",
    "account_failure_0048": "thu hồi thất bại nhật kí phục hồi tài sản sàn thất bại",
    "account_failure_0049": "loại coin này đã tồn tại!",
    "account_failure_0050": "thêm mới coin thất bại！",
    "account_failure_0051": "loại coin này đã tồn tại!",
    "account_failure_0053": "loại giao dịch sai!",
    "account_failure_0054": "tăng thêm khả dụng thất bại！",
    "account_failure_0055": "tăng thêm khả dụng nhật ký thất bại！",
    "account_failure_0056": "Bảng người dùng,chuyển lợi nhuận sàn thất bại！",
    "account_failure_0057": "bảng người dùng,nhật ký chuyển lợi nhuận sàn thất bại！",
    "account_failure_0058": "tài chính khả dụng thất bại！",
    "account_failure_0059": "nhật ký tài chính khả dụng thất bại！",
    "account_failure_0060": "tài chính không đủ,không thể chấp hành！",
    "account_failure_0061": "tài chính đến khả dụng thất bại！",
    "account_failure_0062": "tài chính khả dụng,nhật ký thất bại！",
    "account_failure_0063": "thu hồi thất bại,không có số liệu thao tác ！",
    "account_failure_0064": "loại coin không khả dụng",
    "account_failure_0065": "không thể xem coin này,mời liên hệ cskh",
    "account_failure_0071": "Tài khoản khoá đóng băng bất thường!",
    "account_failure_0072": "Lịch sử nạp tiền không tồn tại hoặc đã chỉnh sửa",
    "activitie_end": "Kết thúc hoạt động",
    "activity_invalid": "Đã hết hạn",
    "actual_amount": "Lượng phát thực",
    "actual_exchange_amount": "Thực đổi",
    "actual_to_account": "khoản thực nhận",
    "addSuccess": "Thêm thành công",
    "add_channel_function": "Đã thêm chức năng kênh xác minh",
    "add_charge_and_transfer_function": "Đã thêm chức năng gửi tiền và chuyển mục",
    "added_alert_setting_function": "Đã thêm chức năng cài đặt cảnh báo",
    "added_day_mode_toggle": "Đã thêm chức năng chuyển đổi chế độ ngày",
    "address_tag": "Nhãn",
    "advanced_limit_order": "Nâng cao",
    "advertising": "Quảng cáo",
    "advertising_management": "quản lý quảng cáo",
    "affirm": "xác nhận",
    "affirm_pass": "xác nhận mật mã",
    "again_digits6": "nhập lại mật mã mới",
    "again_password": "nhập lại mã đăng nhập",
    "airdrop_coin": "Tiền tệ Airdrop",
    "airdrop_date": "Thời gian phát sóng",
    "airdrop_number": "Số lượng airdrops",
    "alipay_account": "ID Alipay",
    "alipay_upload": "*tải lên mã QA Alipay：trang chủ Aipay>nhận tiền>lưu ảnh，tải lên mã nhận tiền trong ảnh。",
    "all": "toàn bộ",
    "all_buy": "mua vào toàn bộ",
    "all_country": "toàn bộ quốc gia",
    "all_order": "Tất cả các đơn đặt hàng",
    "all_sell": "bán ra toàn bộ",
    "already_use": "đã kích hoạt",
    "amount_of_payout": "Số tiền chi",
    "announcement_all": "Tất cả các thông báo",
    "announcement_center": "trung tâm thông báo",
    "announcement_official": "Thông báo chính thức",
    "annualized_rate": "Tỉ lệ năm",
    "appeal_cancel": "quyết định khiếu nại,hủy đơn",
    "appeal_complete": "quyết định khiếu nại,đơn hoàn thành",
    "appeal_dispose_time": "time xử lí khiếu nại",
    "appeal_of_time": "thời gian gửi khiếu nại",
    "apply_locked": "Yêu cầu khóa",
    "asset_allocatio": "Phân chia tài sản",
    "assets_asset_valuation": "Đánh giá tài sản",
    "assets_can_transfer": "Truyền",
    "assets_cash_flow": "Dòng chảy vốn",
    "assets_coin_funds_flow": "Hóa đơn giao ngay",
    "assets_currency_assets": "Tài sản tiền tệ",
    "assets_deposit_income_tip": "Giá trị thu nhập được tính theo tỷ giá hối đoái thời gian thực và thu nhập cụ thể tùy thuộc vào giá trị vấn đề.",
    "assets_fiat_assets": "Tài sản bằng thầu hợp pháp",
    "assets_fiat_money_flow": "Hóa đơn fiat",
    "assets_flowing_details": "Chi tiết về nước chảy",
    "assets_prompt_001": "[Để an toàn xin lưu trữ private key]",
    "assets_reduced_asset": "Chuyển hóa tài sản",
    "assets_row_mining": "Khai thác hàng",
    "assets_swipe_direction": "Hướng quay",
    "assets_transfer": "Truyền:",
    "assets_transfer_coin": "Giao dịch tiền tệ",
    "assets_transfer_count": "Số chuyển",
    "assets_transfer_currency": "Chuyển khoản đầu",
    "assets_transfer_dialog_bb": "Tài khoản tiền",
    "assets_transfer_dialog_fiat": "Tài khoản pháp",
    "assets_transfer_dialog_from": "từ",
    "assets_transfer_dialog_transfer_to": "Chuyển sang",
    "assets_transfer_dialog_wallet": "Tài khoản ví",
    "assets_transfer_record": "Chuyển hồ sơ",
    "assets_transfer_to": "Để",
    "assets_wallet": "ví",
    "assets_wallet_assets": "Ví dụ",
    "assets_wallet_assets_transfer_tip": "* Giao dịch chỉ có thể được thực hiện bằng cách chuyển tài sản ví sang tài khoản truy cập. Không có phí chuyển nhượng được tính cho chuyển giữa các tài khoản.",
    "assets_wallet_funds_flow": "Chi tiết Ví",
    "at_least_one": "Bật ít nhất một trạng thái xác minh mật khẩu giao dịch",
    "attention_blacklist": "Theo dõi / kéo đen",
    "attention_to_see": "Muốn theo tôi, click để xem!",
    "audit_result": "kết quả xác thực",
    "authenticated": "đã xác nhận",
    "authentication": "Xác minh danh tính",
    "available_balance": "Khả dụng",
    "average_purchase_price": "Giá mua trung bình",
    "avoid_close_set": "Cài đặt miễn phí",
    "back": "trở về",
    "balance": "số dư",
    "bank_card": "Thẻ ngân hàng",
    "bank_name": "tên ngân hàng",
    "bank_num": "số ATM",
    "barcode_in_the_box_scan": "đặt mã QA/mã vạch vào khung,sẽ tự động quét",
    "bb_assets": "Tài sản tiền tệ",
    "bb_history_finished": "thực hiện",
    "bb_time": "time",
    "bb_used": "Kích hoạt",
    "be_usable": "Có sẵn",
    "bear_consequences_your_investment": "Tôi đã biết và tự nguyện chấp nhận rủi ro đầu tư. Mã chứng nhận của tôi là:",
    "become": "Trở thành",
    "been_authenticated": "bạn đã KYC",
    "beginner_guide_title": "Nền tảng giao dịch tài sản kỹ thuật số hàng đầu thế giới",
    "belong_level": "Cấp bật hiện có",
    "between_and": "Đến",
    "bill": "hóa đơn",
    "billing_details": "Sao kê",
    "bind_email": "liên kết mail ",
    "bind_google": "Trình xác thực google",
    "bind_google_authenticator": "liên kết google authy",
    "bind_phone": "liên kết điện thoại",
    "branch_name": "tên chi nhánh",
    "bug_order": "Mua",
    "buy": "mua vào",
    "buy_1": "Mua",
    "buy_now": "Mua ngay",
    "buy_quantity": "lượng mua",
    "buy_rate": "phí mua",
    "buyer": "người mua",
    "buyer_has_paid_attention": "Chú ý Người mua đã thanh toán",
    "buyer_payment": "người mua đã thanh toán",
    "buyer_phone": "SDT người mua",
    "buyer_uid": "bên mua",
    "calculate": "Tính toán",
    "calculations are for your reference only": "Tính toán chỉ để bạn tham khảo",
    "can_available": "Có sẵn",
    "canceled": "đã hủy",
    "cancellations": "hủy đơn",
    "centre": "Trung",
    "change1": "sửa",
    "change_gesture_password": "đổi mật mã",
    "change_google": "Đổi trình xác thực google",
    "change_pay_style": "cách đổi",
    "change_phone": "đổi Điện thoại",
    "charge_after_the_transaction": "phí sau giao dịch",
    "charge_out": "phí giao dịch phải tại",
    "check_the_agree": "đồng ý chọn",
    "china": "china",
    "choose_picture": "chọn hình",
    "clearing_time": "Thời gian giải quyết",
    "click_enter_batch_edit_page": "Nhấn vào đây để vào trang chỉnh sửa hàng loạt",
    "click_on_the_copy": "nhấp để coppy",
    "clinch_a_deal": "số lần Đối phương cần hoàn thành(0 nghĩa là không giới hạn)",
    "close": "Đóng",
    "closed_successfully": "Đóng thành công",
    "cloud_mining_pool": "Bể khai thác trên nền tảng đám mây",
    "coin_failure_0001": "coin không tồn tại",
    "collect": "Bộ sưu tập",
    "collection_time": "thời gian nhận",
    "commission": "đã nhận % tương đương",
    "common_failure": "thất bại",
    "common_failure_0001": "mạng bất thường",
    "common_problem": "hướng dẫn thao tác",
    "common_success": "thành công",
    "complete": "hoàn thành",
    "completed": "giao dịch hoàn thành",
    "computer_login": "úng dụng nền đăng nhập",
    "confirm": "xác nhận",
    "confirm_exit": "xác nhận out？",
    "confirm_password": "xin xác nhận mật mã",
    "confirm_submission": "xác nhận gửi",
    "confirm_the_purchase": "xác nhận mua",
    "confirm_to_give_up": "Xác nhận từ bỏ",
    "confirm_to_sell": "xác nhận bán",
    "confirm_upgrade": "Xác nhận nâng cấp",
    "confirm_vote": "Xác nhận phiếu bầu",
    "confirmation_information": "Thông tin xác nhận",
    "confrim_delete_warn": "Bạn có chắc chắn muốn xóa cảnh báo?",
    "contact_service": "liên hệ CSKH",
    "contact_us": "liên hệ chúng tôi",
    "contract_Order": "Ủy thác",
    "contrct Agree Content": "Để bảo vệ quyền lợi của mình, trước khi mở hợp đồng, bạn cần đọc kỹ “Điều khoản hợp đồng vĩnh viễn của “BitMatrix”",
    "contrct Agree Content footer": "《Thỏa thuận hoán đổi vĩnh viễn với BitMatrix》",
    "contrct Agree Content header": "Để bảo vệ R / I của bạn, bạn cần đọc kỹ trước khi mở giao dịch hoán đổi",
    "contrct_decimal": "Số thập phân",
    "convert_into": "tương đương",
    "converted_currency": "Chuyển đổi tiền tệ",
    "copartner-upgrade-failure": "Nâng cấp thất bại",
    "copartner-upgrade-no": "Không đủ điều kiện nâng cấp, không thể nâng cấp",
    "copartner-upgrade-ok": "Có thể nâng cấp",
    "copartner-upgrade-success": "Nâng cấp thành công",
    "copy_content": "đã coppy đến bản dán",
    "country": "Đất nước",
    "create_time": "Lập thời gian",
    "cumulative_income:": "Tích luỹ thu nhập：",
    "cumulative_participation": "Tích lũy",
    "currency": "loại coin",
    "currency_fee": "phí loại coin",
    "currency_information": "Tài liệu tiền tệ",
    "currency_name": "Tên coin",
    "currency_not_found": "Loại hình quản lí này đã bị xoá",
    "currency_select": "Chọn loại tiền",
    "current_day_return": "lãi theo ngày",
    "current_entrust": "Uỷ thác hiện tại",
    "current_identity": "Trạng thái hiện tại",
    "current_polling_date": "Thời gian bỏ phiếu hiện tại",
    "current_status": "Tình trạng hiện tại",
    "currently_available": "Hiện có sẵn",
    "date": "Ngày",
    "date_of_birth": "Ngày sinh",
    "day_highs_and_lows": "Lên xuống",
    "day_highs_and_lows1": "Thay đổi",
    "day_income": "ngày",
    "day_trading": "Giao dịch trong ngày",
    "daytime": "Ban ngày",
    "ddress_management": "Quản lý địa chỉ",
    "deal_amount": "tổng tiền khớp",
    "deal_done": "đã khớp",
    "deal_gross": "khớp/tổng lượng",
    "deal_of_the_week": "Giao dịch trong tuần",
    "deal_price": "Giá trung bình",
    "deal_sum": "mức giao dịch",
    "deal_the_detail": "chi tiết đơn",
    "deal_volume": "lượng tiền khớp",
    "deduct_coin": "Khấu trừ tiền tệ",
    "default_settlement": "Kết toán vi phạm",
    "defaults": "Mặc định",
    "deposit": "Gửi tiền nhanh",
    "deposit_details": "Tình trạng tiền gửi",
    "deposit_income": "Thu hồi tiền gửi",
    "deposit_lock": "Khóa tiền gửi",
    "deposit_lock_details": "Chi tiết khóa tiền gửi",
    "deposit_returned_principal": "Đã trả tiền vốn",
    "depth": "độ sâu",
    "detailed_rules": "Quy tắc chi tiết",
    "details": "Chi tiết",
    "digits6": "nhập 6 số",
    "direct_market": "sale trực tiếp",
    "direct_reward": "thưởng trực tiếp",
    "direction": "phương hướng",
    "discount_Rate": "Tỷ lệ chiết khấu",
    "discount_currency_introduction": "Giới thiệu tiền tệ",
    "discount_dialog_tip1": "Xin chúc mừng!",
    "discount_dialog_tip2": "Điều kiện tham gia và được hệ thống chọn ngẫu nhiên là người dùng may mắn, sau khi nhận được bằng cấp sẽ có quyền tham gia!",
    "discount_dialog_tip3": "Bạn đã có",
    "discount_dialog_tip4": "Tiêu chuẩn cho việc quy đổi, số lượng hoạt động bị hạn chế, vui lòng tham gia quy đổi trong thời gian sự kiện!",
    "discount_dialog_tip5": "Không thể tham gia sau khi từ bỏ",
    "discount_dialog_tip6": "Các hoạt động và không thể áp dụng để tham gia.",
    "discount_dialog_tip7": "Lưu ý: Khấu trừ thành công sẽ được khấu trừ",
    "discount_purchase": "Giảm giá",
    "discounted_or_original_price": "Giảm giá / giá gốc",
    "dispose_result": "kết quả xử lí",
    "disqualification": "Bị loại",
    "divided_earnings": "Nguồn chia sẻ",
    "drag": "ấn và kéo về bên phải",
    "each_input": "Xác minh mật khẩu giao dịch cho mỗi giao dịch",
    "edt_selfchoose": "Lựa chọn của người biên tập",
    "effective": "Hiệu quả",
    "eligibility": "Đủ điều kiện",
    "email": "đăng ký mail",
    "email_address_format": "mời nhập đúng mail",
    "email_code": "mã xác nhận mail",
    "empty_deal_pass": "mật mã giao dịch trống",
    "end time": "Thời gian kết thúc",
    "end_date": "Ngày kết thúc",
    "end_time": "time kết thúc",
    "enter_account": "nhập sdt hoặc tài khoản mail",
    "enter_cause_of_complaint": "mời nhập lí do tố cáo",
    "enter_fb_buy_price": "Vui lòng nhập giá mua",
    "enter_fb_sell_price": "Vui lòng nhập giá bán",
    "enter_your_password": "nhập mã đăng nhập",
    "enter_password": "Mật khẩu",
    "entrust_amount": "lượng gửi",
    "entrust_failure_0001": "mạng bất thường",
    "entrust_failure_0002": "tìm kiếm lỗi ",
    "entrust_failure_0003": "loại kết hợp không được trống",
    "entrust_failure_0004": "IDkhông được trống",
    "entrust_failure_0005": "IDkhông được trống",
    "entrust_failure_0006": "người dùng không tồn tại",
    "entrust_failure_0007": "cặp giao dichIDkhông được trống",
    "entrust_failure_0009": "cặp giao dịch không tồn tại",
    "entrust_failure_0010": "mật mã giao dịch không được trống",
    "entrust_failure_0011": "mật mã giao dịch sai",
    "entrust_failure_0012": "loại hóa đơn không được trống",
    "entrust_failure_0013": "lượng hóa đơn không được trống",
    "entrust_failure_0014": "giá giới hạn không được trống",
    "entrust_failure_0015": "hóa đơnIDkhông được trống",
    "entrust_failure_0016": "hóa đơn không tồn tại",
    "entrust_failure_0017": "hóa đơn này đã xóa",
    "entrust_failure_0018": "hóa đơn này không thể thu hồi",
    "entrust_failure_0019": "số phiên bản không được trống",
    "entrust_failure_0020": "đơn thị trường không thể thu hồi",
    "entrust_failure_0021": "số liệu đã làm mới",
    "entrust_failure_0022": "không có hóa đơn,không thể hủy đơn",
    "entrust_failure_0023": "cặp giao dịch đã cấm hoặc loại coin bị cấm",
    "entrust_failure_0024": "cặp giao dịch chưa được mở",
    "entrust_failure_0025": "đơn giá thấp hơn giá tối thiểu hoặc cao hơn giá tối đa",
    "entrust_failure_0026": "lượng đơn thấp hơn giá tối thiểu hoặc cao hơn giá tối đa",
    "entrust_failure_0027": "tài khoản bị cấm giao dịch,mời liên hệ cskh",
    "entrust_failure_0030": "ượt mức tăng cao nhất của cặp giao dịch{}，không thể đặt lệnh",
    "entrust_failure_0031": "Vượt mức giảm cao nhất của cặp giao dịch{}，không thể đặt lệnh",
    "entrust_msg_match_type_001": "giới hạn giá",
    "entrust_msg_match_type_002": "giá thị trường",
    "entrust_msg_status_001": "chưa khớp lệnh",
    "entrust_msg_status_002": "phần khớp lệnh",
    "entrust_msg_status_003": "toàn bộ lệnh khớp",
    "entrust_msg_status_004": "Đã thu hồi",
    "entrust_msg_type_001": "đơn bán",
    "entrust_msg_type_002": "đơn mua",
    "entrusted_price": "giá gữi",
    "entry_orders": "lệnh chờ",
    "equal_principal": "Vốn bằng nhau",
    "error_email": "mail không đúng",
    "error_network_anomaly": "mạng có vấn đề",
    "error_phone": "mời nhập đúng sdt",
    "error_unknow": "sai mạng",
    "event_detail": "Chi tiết sự kiện",
    "event_detail_warn_tip": "* Nhắc nhở: Tài sản kỹ thuật số là tài sản sáng tạo với đặc điểm biến động giá lớn. Hãy đưa ra những đánh giá hợp lý và lựa chọn quyết định đầu tư một cách cẩn thận!",
    "event_start_time": "Thời gian bắt đầu sự kiện",
    "every day": "Mỗi ngày",
    "everyday": "Mỗi ngày",
    "exchange": "Chuyển đổi",
    "exchange_currency": "Đổi tiền",
    "exchange_details": "Chi tiết",
    "exchange_history": "kỷ lục",
    "exchange_quantity": "Số lượng trao đổi",
    "exchange_rules": "Quy tắc trao đổi",
    "exchangeable": "Khả dụng",
    "exclusive_posters": "tạo quảng cáo độc quyền",
    "exclusive_posters-Video": "Tạo áp phích",
    "exit": "thoát",
    "expected_return": "Thu nhập dự tính",
    "expired": "Hết hạn",
    "express_area": "Vùng tắt",
    "face_recognition": "Nhận dạng khuôn mặt",
    "failure": "thất bại",
    "fb_lookup": "Bấm để xem",
    "fb_order_number": "Số đơn hàng",
    "feeze_thaw_msg_status_0001": "đang khóa",
    "feeze_thaw_msg_status_0002": "đã mở khóa",
    "fill_in_at_least_one": "Vui lòng điền vào ít nhất một",
    "fill_wrong": "nhập sai có thể dẫn đến tổn thất,xin kiểm tra",
    "filtrate": "sàng lọc",
    "filtrate_content": "Lọc phương thức thanh toán, quốc gia và điểm tiền tệ tại đây!",
    "financial_management_is_cancel": "Thu nhập tiền gửi đã huỷ, không gửi lại!",
    "financial_management_is_delete": "",
    "financial_management_is_disable": "Loại hình quản lí này đã bị cấm",
    "financial_management_not_cancel": "Không thể huỷ quản lí đầu tư định kì!",
    "financial_management_number_not_found": "Phải điền lượng đầu tư ",
    "financial_management_record_add_failure": "Tài sản không đủ, hãy nạp tiền để đầu tư quản lí tài sản!",
    "financial_management_record_less_than_min_number": "Số lượng thêm nhỏ hơn số lượng nhỏ nhất đã đặt {}",
    "financial_management_type_not_found": "Không thể hiển thị thông tin tài sản, chọn sai loại quản lí!",
    "financial_management_type_not_null": "Phải điền loại đầu tư ",
    "find_password": "tìm lại mật mã",
    "fingerprint": "vân tay",
    "fingerprint_verification": "xác minh vân tay",
    "first_bind_phone": "liên kết sdt trước",
    "first_period": "Kì đầu",
    "following_account": "từ tài khoản dưới",
    "free": "Phí thủ tục",
    "Takerfree": "Taker miễn phí",
    "Makerfree": "Maker miễn phí",
    "freeze": "khóa",
    "freeze_to_be_processed": "khiếu nại đóng băng,đang chờ xử lý",
    "fuc_use_discount": "Dùng {} để trả phí giao dịch * giảm giá",
    "futu": "ảnh phụ",
    "gathering": "Nhận tiền",
    "gears": "vị trí",
    "generation1": "Thế 1 hệ",
    "gesture_error_limit": "sai quá số lần nhập mật mã tay",
    "gesture_password": "mật mã tay",
    "gesture_unlock": "mở khóa mã tay",
    "get_code": "lấy mã xác minh",
    "goSign": "Đi đăng ký",
    "go_change": "đến thay đổi",
    "go_login": "đăng nhập trước",
    "go_pay": "đến thanh toán",
    "go_setting": "đến cài đặt",
    "go_to_pick_up": "Đi đón",
    "go_to_receiving": "đến khoản thu",
    "go_to_redeem": "Đi để đổi",
    "google_verification_code": "mã google authy",
    "grievance_freeze": "khiếu nại đóng băng",
    "has_been_on": "đã list sàn",
    "have_been_frozen": "đã khóa",
    "have_received": "Đã nhận",
    "help_center": "giúp đỡ",
    "hide_canceled": "ẩn đơn đã hủy",
    "hide_other": "Hiện toàn bộ đơn",
    "high_frequency_partner": "Đối tác tần suất cao",
    "highest_single": "Đơn cao nhất",
    "highfrequency_fee_sharing": "Phí dịch vụ chia sẻ tần số cao",
    "hint": "nhắc nhở",
    "hint_password": "nhập mật mã",
    "hint_phone_email": "nhập sdt/mail",
    "historical_transaction": "Giao dịch lịch sử",
    "history": "Lịch sử",
    "home_page": "trang chủ",
    "horizontal_version": "Bản ngang",
    "hours": "h",
    "i18n_failure_0001": "tìm kiếm ngôn ngữ hệ thống thất bại",
    "i18n_failure_0002": "tìm kiếm từ điển quốc gia thất bại",
    "iTo_draw_again": "nhập lại mật mã",
    "i_see": "Tôi biết",
    "i_see1": "Tôi biết",
    "id_card_back_direction_failure": "Hướng sai của hình ảnh trên mặt sau của thẻ ID",
    "id_card_front_direction_failure": "Định hướng sai của hình ảnh phía trước",
    "id_cord": "số tài liệu",
    "id_type": "Các loại",
    "identification": "xác minh thất bại",
    "identity_authentication": "xác minh danh tính",
    "identity_card": "CMND",
    "if_at": "nếu như tại",
    "ignore": "Làm lơ",
    "immediately_invited": "mời ngay",
    "immediately_join": "Tham gia ngay",
    "in_trading": "Đang giao dịch",
    "income": "Thu nhập",
    "income_currency_type": "Loại tiền gửi",
    "income_deposit_principal": "Vốn tiền gửi",
    "incomeing": "Thu nhập",
    "increment quantity": "Số lượng đầu vào không được vượt quá số lượng tăng tối đa",
    "indirect_promotion": "sale gián tiếp",
    "indirect_reward": "thưởng gián tiếp",
    "individual_selling_tips": "không thể tự bán đơn hàng của mình",
    "input_Branch_address": "nhập địa chỉ chi nhánh",
    "input_address_labels": "Vui lòng nhập nhãn",
    "input_alipay_account": "nhập ID Alipay",
    "input_bank_card_number": "nhập số tk ngân hàng",
    "input_bank_name": "nhập tên ngân hàng",
    "input_buyer_uid": "nhập UID bên mua",
    "input_currency_referred": "Nhập tên viết tắt loại tiền",
    "input_deposit_amount": "Nhập số lượng tiền gửi",
    "input_email": "nhập mail",
    "input_email_code": "nhập mã xác nhận mail",
    "input_id_cord": "nhập mã xác minh",
    "input_lock_amount": "Vui lòng nhập số lượng khóa",
    "input_mention_money_address": "nhập ví coin",
    "input_money": "nhập số tiền",
    "input_name": "nhập họ tên",
    "input_newphone_code": "Vui lòng nhập số điện thoại mới",
    "input_nike_name": "nhập biệt danh",
    "input_old_google_code": "nhập mã google authy cũ",
    "input_old_pass": "nhập mật mã cũ",
    "input_paypal_acctount": "nhập paypal ID",
    "input_phone": "nhập sdt",
    "input_phone_code": "nhập mã xác minh",
    "input_price": "nhập giá",
    "input_quantity": "Nhập số lượng",
    "input_remark": "nhập thêm chú thích",
    "input_service_charge": "nhập phí giao dịch",
    "input_trading_volume": "nhập lượng giao dịch",
    "input_transaction_password": "nhập mật mã quỹ ",
    "input_wechat_account": "nhập ID Wechat",
    "input_wire_transfer": "nhập đúng địa chỉ chuyển khoản",
    "inputs_numbers_greater_can_number": "Số lượng đầu vào lớn hơn số lượng chuyển",
    "insufficient_light": "thiếu sáng",
    "interest": "Lãi",
    "invitation_details": "Chi tiết tiền thưởng giới thiệu",
    "invitation_register_ad_not_found": "app quảng cáo chia sẽ đăng kí chưa tồn tại",
    "invitation_register_ad_tatle_not_found": "app tiêu đề chia sẽ quảng cáo chưa tồn tại",
    "invitation_register_coin_not_found": "app thưởng chia sẽ chưa tồn tại",
    "invitation_register_logo_not_found": "app chia sẽ mời đăng ký logo tạm thời chưa tồn tại",
    "invitation_register_not_found": "link ref hiện không tồn tại",
    "invite_code": "mã mời（tùy chọn）",
    "invite_commission": "Hoa hồng",
    "invite_gift": "mời có quà",
    "invite_reward": "Đã thanh toán",
    "invited_UID": "UID được mời",
    "invitees_are_available": "Người giới thiệu có sẵn",
    "inviter-is-user": "Người được mời hoàn thành không thể là của riêng tôi",
    "inviter-not-exist": "Lời mời được lấp đầy không tồn tại",
    "inviter_no_null": "Người giới thiệu không thể để trống",
    "ios_24小时排行榜": "Bảng xếp hạng 24h",
    "ios_OTC交易须知": "OTC  Giao dịch cần biết",
    "ios_PAYPAL": "PAYPAL",
    "ios_otc_order_prompt_001": "phút chưa thanh toán cho bạn nhưng đã 'xác nhận thanh toán'nút,khi đếm ngược xong hệ thống tự động hủy đơn",
    "ios_otc_order_prompt_002": "phút chưa thanh toán cho người bán nhưng đã 'xác nhận thanh toán'nút,khi đếm ngược xong hệ thống tự động hủy đơn",
    "ios_万": "vạn",
    "ios_上传身份证反面": "tải lên ID mặt sau",
    "ios_上传身份证正面": "tải lên ID mặt trước",
    "ios_两次密码不一致请再次输入密码": "mật mã không trùng,mời nhập lại",
    "ios_个确认大约需要": "xác nhận khoảng cần",
    "ios_中国大陆": "China",
    "ios_中级合伙人": "Đối tác trung gian",
    "ios_主版块": "Trang chính",
    "ios_之间": "khoảng",
    "ios_买单": "Thanh toán hóa đơn",
    "ios_买方必须成交过几次(0等于不限制)": "số lệnh người mua phải khớp(0nghĩa là không giới hạn)",
    "ios_二维码/条形码": "Mã QA/Mã vạch",
    "ios_付款": "thanh toán",
    "ios_充币地址": "Địa chỉ tiền tệ",
    "ios_先设置交易密码": "cài đặt mã giao dịch",
    "ios_关闭照明": "tắt đèn",
    "ios_分享失败": "chia sẽ thất bại",
    "ios_分享成功": "chia sẽ thành công",
    "ios_初级合伙人": "Đối tác cơ sở",
    "ios_卖单": "Bán lệnh",
    "ios_卖家已付款提示": "người bán đã thanh toán",
    "ios_卖方必须成交过几次(0等于不限制)": "số lệnh người bán phải khớp(0nghĩa là không giới hạn)",
    "ios_去解绑": "Đến mở khóa",
    "ios_发布成功": "phát đơn thành công",
    "ios_发送成功": "phát thành công",
    "ios_各种费率说明": "giải thích các loại phí",
    "ios_团队人数不足": "Nhóm không đủ người",
    "ios_复制QQ号": "coppy QQ",
    "ios_复制地址": "coppy địa chỉ",
    "ios_如果你已完成线下付款，请点击下方已付款按钮。如果未完成付款，却点击已付款将会受到严厉惩罚，甚至永久冻结账户！": "nếu bạn đã thanh toán,hãy ấn nút đã thanh toán.nếu chưa thanh toán nhưng đã xác nhận thanh toán sẽ dẫn đến có thể bị vĩnh viễn khóa tài khoản！",
    "ios_客户端或在线钱包将您需要充值的": "phần mềm hoặc ví tiền online và khoản nạp",
    "ios_客户端或在线钱包将您需要充幣的": "phần mềm hoặc ví tiền online và khoản nạp",
    "ios_客服": "CSKH",
    "ios_密码设置成功！": "cài mật mã thành công！",
    "ios_将该地址设置为认证地址": "cài đặt địa chỉ thành địa chỉ xác nhận",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充值，不影响到账。\n3、最小充值金额": "giờ thời gian,xin chờ đợi。\n2、cùng địa chỉ được nạp nhiều lần,không ảnh hưởng tài khoản。\n3、lượng nạp ít nhất",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充幣，不影响到账。\n3、最小充幣金额": "giờ thời gian,xin chờ đợi。\n2、cùng địa chỉ được nạp nhiều lần,không ảnh hưởng tài khoản。\n3、lượng nạp ít nhất",
    "ios_已付款": "đã thanh toán",
    "ios_币币": "coin coin",
    "ios_常见问题": "vấn đề hay gặp",
    "ios_开启照明": "mở đèn",
    "ios_意见反馈": "ý kiến phản hồi",
    "ios_成交": "Giao dịch gần nhất",
    "ios_我的": "của tôi",
    "ios_手机号错误": "sai số điện thoại",
    "ios_提交成功，等待认证": "nộp thành công,chờ duyệt",
    "ios_提币数量不能大于手续费": "lượng rút không được lớn hơn phí giao dịch",
    "ios_提币数量减去手续费后不能": "lượng rút trừ đi phí giao dịch không được",
    "ios_收款二维码": "mã QA nhận tiền",
    "ios_收藏成功": "thu thập xong",
    "ios_数目发送到该地址。发送完成后，系统会自动在此交易获得": "số liệu đến địa chỉ。Chuyển thành công，hệ thống tự động nhận giao dịch",
    "ios_暂无网络": "hiện không có mạng",
    "ios_最多可锁": "Khóa nhiều nhất",
    "ios_最大限额": "lượng tiền max",
    "ios_最小限额": "lượng tiền min",
    "ios_未解锁": "Chưa mở khóa",
    "ios_没有中国大陆身份证的用户请通过网站版高级认证": "người dùng không phải trung quốc phải thông qua KYC cao",
    "ios_法币": "OTC",
    "ios_注册表示同意": "đăng ký và đồng ý",
    "ios_注册表示同意用户协议": "đăng ký và đồng ý điều khoản",
    "ios_登录过期，请重新登录": "quá time,mời đăng nhập lại",
    "ios_直接推荐人uid": "uid tuyến trên",
    "ios_直推人数不足": "Người quảng bá không đủ",
    "ios_绑定成功": "liên kết thành công",
    "ios_请上传您的身份证": "mời tải lên chứng minh",
    "ios_请上传身份证反面照": "tải lên CMND 2 mặt",
    "ios_请上传身份证正面照": "tải lên CMND mặt trước",
    "ios_请先绑定收款方式": "liên kết mã nhận tiền trước",
    "ios_请先设置支付方式": "thiết lập phương thức thanh toán trước",
    "ios_请输入价格和数量": "nhập giá và số lượng",
    "ios_请输入新邮箱": "nhập mail mới",
    "ios_请输入正确的账号格式": "mời nhập đúng định dạng tài khoản",
    "ios_请输入身份证号": "nhập số CMND",
    "ios_请输入限额": "nhập hạn mức",
    "ios_请通过": "mời thông qua",
    "ios_资产": "tài sản",
    "ios_超时": "quá time",
    "ios_身份证号": "số CMND",
    "ios_退出成功": "thoát thành công",
    "ios_邀请人可获得 * 的佣金": "Giới thiệu có thể nhận được * hoa hồng ",
    "ios_锁仓数量不能为空": "Số lượng bị khóa không thể để trống",
    "ios_间接接推荐人uid": "uid giới thiệu gián tiếp",
    "ios_隐藏其他挂单": "ẩn đơn hàng khác",
    "ios_高级合伙人": "Đối tác cao cấp",
    "is_bind": "Đã liên kết",
    "is_delete_select": "Có xóa tùy chọn không",
    "issued": "đã phát",
    "issued_to_lock": "Phát hành để khóa",
    "k_close": "Nhận",
    "k_max": "Cao",
    "k_min": "Thấp",
    "k_num": "lượng",
    "k_open": "Mở",
    "kline_target": "chỉ tiêu",
    "language": "ngôn ngữ",
    "lead_content": "Yêu cầu đặt hàng, đăng quảng cáo, thiết lập thanh toán, chú ý / đen được chuyển đến đây!",
    "least_four": "nối ít nhất 4 điểm",
    "less-than-min": "Dưới giới hạn khóa tối thiểu",
    "lf there are pending": "Nếu có các lệnh đóng đang chờ xử lý (Giới hạn hoặc Dừng), chúng sẽ bị hủy trước khi đóng tất cả.",
    "limit": "mức cài đặt",
    "limit_orderAA": "Lệnh giới hạn",
    "limit_set": "mức cài đặt(tùy chọn)",
    "limited_price": "mức giá",
    "liquidation": "Buộc phải bán",
    "liquidation AMT.": "Số lượng thanh lý",
    "liquidation details": "Chi tiết thanh lý",
    "loading": "Đang load",
    "lock-expire": "Vị trí khóa đã hết hạn",
    "lock_amount": "Số lượng khóa",
    "lock_duration": "Thời gian khóa",
    "lock_person": "Người khóa",
    "lock_record": "Hồ sơ khoá",
    "lock_remaining": "Khóa số dư",
    "lock_reward": "Khóa phần thưởng",
    "lock_time": "Thời gian khóa",
    "locked": "khóa",
    "locked_closed": "Đã trả hết",
    "locked_insufficient_balance": "Số dư không đủ",
    "locked_settled": "Đã thanh toán",
    "locked_unsettlement": "Chưa thanh toán",
    "locked_warehouse": "Đang khoá",
    "log_in": "đăng nhập",
    "login": "đăng nhập",
    "login_expired": "đăng nhập thất bại,mời nhập lại。",
    "login_forget_pwd": "quên mật mã",
    "login_name_txt": "tên tài khoản",
    "login_pass": "mật mã đăng nhập",
    "logion_welcome_txt": "Vui lòng đăng nhập",
    "mail": "Email",
    "mailbox": "Xác nhận Mail",
    "mailbox_bind_success": "Liên kết hộp thư thành công",
    "mailbox_new": "Email",
    "main_picture": "ảnh chính",
    "manage_reward_details": "Quản lý chi tiết phần thưởng",
    "management_reward": "Phần thưởng quản lý",
    "management_share": "Quản lý chia sẻ",
    "mandarin": "sử dụng tiếng phổ thông đọc số",
    "mandarin_reading_content": "Hãy đọc phần sau bằng ngôn ngữ chuẩn",
    "manual_recharge_msg_status_0001": "chờ nạp",
    "manual_recharge_msg_status_0002": "xét duyệt thông qua",
    "manual_recharge_msg_status_0003": "nạp thành công",
    "manual_recharge_msg_type_0001": "nạp phổ thông",
    "manual_recharge_msg_type_0002": "xử lý tài sản",
    "manual_recharge_msg_type_0003": "tặng hoạt động",
    "margin": "Tiền ký quỹ",
    "market_failure_00001": "nhận trang chủ thất bại",
    "market_failure_00002": "Id người dùng không tồn tại",
    "market_failure_00003": "không có khu giao dịch",
    "market_failure_00004": "nhận cặp giao dịch thất bại",
    "market_failure_00005": "tên cặp giao dịch không được trống",
    "market_failure_00006": "tài khoản không có thị trường khả dụng",
    "market_failure_00007": "nhận thông tin cặp thất bại",
    "market_failure_00008": "nhận số liệu K thất bại",
    "market_failure_00009": "nhận số liệu thâm nhập thất bại ",
    "market_failure_00010": "nhận tham số sai",
    "market_failure_00011": "cặp giao dịch vô hiệu",
    "market_low_price": "giá mua cao nhất",
    "market_order": "Đặt hàng ở siêu thị",
    "market_price": "giá thị trường",
    "market_sell_price": "giá bán thấp nhất",
    "market_switch_moved_here": "Công tắc thị trường ngang / dọc đã được chuyển đến đây",
    "match": "phân phối",
    "match_the_number_of": "lượng phân phối",
    "max_lock_volume": "Lượng khóa tối đa",
    "maximum_30_characters": "nhiều nhất 30 ký tự",
    "maximum_coins": "lượng rút max",
    "maximum_withdrawal": "rút lớn nhất",
    "mention_a_currency": "lượng rút",
    "min_lock_volume": "Lượng khóa tối thiểu",
    "min_recharge_amount": "Số tiền nhỏ chưa được gửi, nghĩa là, việc nạp tiền chưa được hoàn thành thành công và số tiền gửi tích lũy đạt đến số tiền tối thiểu {} * sẽ được gửi tự động",
    "minhour": "chia giờ",
    "minimum_coins": "lượng rút min",
    "minimum_purchase_quantity": "Số lượng mua hàng lớn hơn số lượng còn lại của mệnh lệnh.",
    "minimum_quantity_sold": "Số lượng đã bán còn lớn hơn số lượng còn lại của đơn hàng.",
    "minutes": "m",
    "mobile phone": "Số di động",
    "money": "Tiền",
    "more": "Thêm",
    "more Or than": "Vị thế mua: Mệnh giá x Số lượng hợp đồng x Giá đóng cửa trung bình-Mệnh giá x Số lượng hợp đồng x Giá giao dịch trung bình \ n \ n Vị thế ngắn hạn: Mệnh giá x Số lượng hợp đồngx Giá mở cửa trung bình-Mệnh giá x số hợp đồng x Giá đóng cửa trung bình",
    "more-than-max": "Vượt quá giới hạn khóa tối đa",
    "more11": "Hơn",
    "more_can_save": "Lượng gửi lớn nhất",
    "more_home": "Thêm",
    "msg_camera_framework_bug": "chọn đồng bộ camera",
    "must_retain_one": "sdt、google、mail phải bảo lưu 1 loại",
    "my_income": "Thu nhập của tôi",
    "my_prompt_001": "*Đổi thành công số điện thoại mời dùng số mới để đăng nhập.",
    "my_prompt_002": "*nhắc nhở：người dùng nên sử dụng tên thật，đã kích hoạt OTC lúc giao dịch triển khai với bên bán ,có thể cài 5 loại kích hoạt tối đa 3 loại.",
    "my_prompt_003": "*Đổi thành công email mời dùng số mới để đăng nhập。",
    "my_vote": "Phiếu bầu của tôi",
    "name": "Họ Tên",
    "name_empty": "Họ Tên không được trống",
    "nationality": "Quốc tịch",
    "need_me_record_video": "Cần tôi quay video",
    "never_enter": "Không bao giờ nhập mật khẩu giao dịch",
    "new_address": "thêm địa chỉ",
    "new_pass": "mật mã mới",
    "new_pass_empty": "nhập mật mã mới",
    "new_pass_minimum": "mật mã không được ít hơn 6 kí tự",
    "new_report_statistics": "Thống kê báo cáo mới",
    "newest_versions": "bản mới nhất",
    "next": "kế tiếp",
    "nick_name": "biệt danh",
    "nickname_des_txt": "Nickname chỉ hỗ trợ tiếng Trung và tiếng Anh, số và độ dài được giới hạn trong 15 ký tự",
    "nighttime": "vào ban đêm",
    "ninety_days": "Gần 90 ngày",
    "no-lock-detail": "Không có thông tin vị trí khóa",
    "no_Senior_certification": "xác minh danh tính cấp cao trước",
    "no_data": "Không dữ liệu",
    "no_flash": "thiết bị không có led,không thể mở đèn",
    "no_more_data": "tạm không thêm",
    "no_photo_taking": "thiết bị không hổ trợ chụp ảnh",
    "no_price": "giá không được trống",
    "no_quantity": "mời nhập đúng số lượng",
    "no_realname_auth": "xác minh danh tính trước",
    "no_use": "Chưa kích hoạt",
    "none": "Không",
    "not_bound": "Chưa liên kết",
    "not_frequency_partner": "Anh vẫn chưa là cộng sự.",
    "not_redemption": "Không đủ điều kiện để chuộc lại",
    "not_started": "Chưa bắt đầu",
    "not_to_issue": "chưa phát",
    "not_trading": "loại coin này chưa giao dịch",
    "notpass": "xác thực không thông qua",
    "null_address_tag": "Nhãn địa chỉ không được để trống",
    "null_code": "mã xác minh không được trống",
    "null_email": "mail không được trống",
    "null_password": "mật mã không được trống",
    "null_phone": "số điện thoại không được trống",
    "number": "Cái",
    "number_empty": "Số lượng không thể",
    "number_of_arrivals": "Số lượng khách đến",
    "number_of_issues": "Tổng lượng phát",
    "number_of_withdrawal": "số lần rút mỗi ngày",
    "obtained": "Delist",
    "occlusion": "Che mặt",
    "occupy": "Chiếm",
    "official_community": "Cộng đồng chính thức",
    "official_verification_channel": "Kênh xác thực",
    "old_pass": "mật mã cũ",
    "old_pass_empty": "nhập mật mã cũ",
    "on_sale": "bán",
    "online_service": "Tư vấn online",
    "open XKD perpetual swap": "Mở hoán đổi vĩnh viễn BitMatrix",
    "open perpetual swap": "Mở hoán đổi vĩnh viễn BitMatrix",
    "open_app": "Mở chương trình",
    "open_it": "Mở",
    "open_successfully": "mở thành công",
    "open_touchID": "tắt mởTouchID?",
    "opposite_side_uid": "đối phương",
    "option": "Tùy chọn",
    "optional": "tự chọn",
    "optional_area": "Vùng tùy chọn",
    "options": "chọn phương thức",
    "order": "Đơn",
    "order time": "Thời gian phục vụ",
    "order time1": "Thời gian phục vụ",
    "order_a_complaint": "khiếu nại đơn",
    "order_details": "chi tiết đơn",
    "order_form": "Đơn hàng",
    "order_history": "lịch sử đơn",
    "order_inquiry": "kiểm tra đơn",
    "order_number": "mã đơn",
    "order_time": "time đặt hàng",
    "ordinary_partner": "Cộng sự chung",
    "otc_account": "tài khoản",
    "otc_confirm_Put_the_coin": "xác nhận đã nhận khoản chuyển!(cảnh báo:không xác nhận trước khi nhận được tiền,nếu có tranh chấp bạn có thể khiếu nại)",
    "otc_confirm_the_payment": "xác nhận thanh toán",
    "otc_confirm_the_receipt": "đã nhận thanh toán",
    "otc_failure_0001": "tài khoản bị cấm,mời liên hệ CSKH",
    "otc_failure_0002": "chưa KYC cấp cao,không thể thao tác OTC",
    "otc_failure_0003": "mật mã giao dịch không đúng",
    "otc_failure_0004": "đơn ID không hợp lý",
    "otc_failure_0006": "đổi trạng thái thất bại",
    "otc_failure_0009": "loại đơn không tồn tại",
    "otc_failure_0010": "hủy thất bại",
    "otc_failure_0011": "đơn hàng đã xong hoặc đã hủy",
    "otc_failure_0012": "tai khoản chưa phải OTC thương gia,không thể thao tác",
    "otc_failure_0013": "mở khóa,hủy thất bại",
    "otc_failure_0015": "không có loại đơn này",
    "otc_failure_0016": "đơn không được nhỏ hoặc bằng 0",
    "otc_failure_0017": "giá không được nhỏ hoặc bằng 0",
    "otc_failure_0018": "mức đơn giákhông được nhỏ hơn 0",
    "otc_failure_0019": "mức đơn lớn nhất không được nhỏ hơn mức đơn nhỏ nhất",
    "otc_failure_0020": "không thể sử dụng coin này",
    "otc_failure_0021": "không thể sử dụng coin này",
    "otc_failure_0022": "coin người dùng ID lỗi",
    "otc_failure_0023": "mức đơn nhỏ nhất không được nhỏ hơn mức giá nhỏ nhất của coin{}",
    "otc_failure_0024": "mức đơn lớn nhất không được lớn hơn mức giá lớn nhất của coin{}",
    "otc_failure_0025": "lượng lệnh chờ vị thập phân lớn hơn{}",
    "otc_failure_0026": "tổng tiền đơn không được nhỏ hơn mức định giá{}",
    "otc_failure_0027": "tổng tiền đơn không được lớn hơn mức tiền người dùng{}",
    "otc_failure_0028": "tổng tiền đơn không được lớn hơn hạn mức thương gia{}",
    "otc_failure_0029": "giá giao dịch không được nhỏ hơn mức thấp nhất{}",
    "otc_failure_0030": "giá giao dịch không đươc lớn hơn giá cao nhất{}",
    "otc_failure_0031": "người dùng chưa kết nối phương thức thanh toán",
    "otc_failure_0032": "khóa thất bại,lệnh chờ thất bại",
    "otc_failure_0033": "Số lượng đơn đặt hàng tối đa được xử lý cùng một lúc không thể ít hơn 0",
    "otc_failure_0034": "số hoàn thành giao dịch không được nhỏ hơn 0",
    "otc_failure_0035": "người dùng đã xác minh thương gia hoặc chờ duyệt",
    "otc_failure_0036": "OTC số giao dịch thành công không đủ",
    "otc_failure_0037": "mở khóa ,hủy ,thất bại",
    "otc_failure_0038": "tài khoản{}số dư không đủ{}",
    "otc_failure_0039": "không có loại coin này",
    "otc_failure_0040": "loại coin chưa dùng được",
    "otc_failure_0041": "gửi thành công,chờ duyệt",
    "otc_failure_0042": "xin lỗi,tài khoản này đang xét duyệt,không thể gửi ",
    "otc_failure_0043": "không thể thêm giao dịch",
    "otc_failure_0044": "cấm tự mua bán ",
    "otc_failure_0046": "lệnh chờ cùng lúc xử lý đơn hàng max{}đã đạt mức,thử lại sau",
    "otc_failure_0047": "người mua cần hoàn thành{}lần",
    "otc_failure_0048": "lượng mua vào không được nhỏ hơn 0",
    "otc_failure_0049": "lượng mua vào không được lớn hơn số dư",
    "otc_failure_0050": "lệnh ID sai",
    "otc_failure_0051": "tổng mua không được nhỏ hơn giá hạn mức{}",
    "otc_failure_0052": "lượng mua không được lớn hơn giá hạn mức{}",
    "otc_failure_0053": "loại lệnh chờ không phải bán ra",
    "otc_failure_0054": "trạng thái lệnh không đúng",
    "otc_failure_0055": "lượng mua vào không được nhỏ hơn mức nhỏ nhất{}",
    "otc_failure_0056": "lượng mua vào không được lớn hơn mức lớn nhất{}",
    "otc_failure_0057": "không có trạng thái",
    "otc_failure_0058": "thay đổi số liệu lệnh sai",
    "otc_failure_0059": "khóa,hủy,thất bại",
    "otc_failure_0060": "liên kết thanh toán chỉ định bởi người bán！",
    "otc_failure_0061": "người bán cần hoàn thành{}lần",
    "otc_failure_0062": "lượng bán ra không được nhỏ hơn 0",
    "otc_failure_0063": "lượng bán ra không được lớn hơn lượng số dư",
    "otc_failure_0064": "lệnh không phải mua vào",
    "otc_failure_0065": "tổng bán không được nhỏ hơn mức nhỏ nhất {}",
    "otc_failure_0066": "lượng bán không được lớn hơn mức lớn nhất{}",
    "otc_failure_0067": "lượng bán không được nhỏ hơn mức nhỏ nhất{}",
    "otc_failure_0068": "lượng bán không được lớn hơn mức lớn nhất{}",
    "otc_failure_0069": "lỗi Id thanh toán",
    "otc_failure_0070": "xác nhận thanh toán thất bại",
    "otc_failure_0073": "sửa trạng thái lệnh thất bại",
    "otc_failure_0074": "chuyển coin thất bại,xác nhận nhận tiền thất bại",
    "otc_failure_0075": "phương thức thanh toán không được trống",
    "otc_failure_0076": "gửi tin nhắn thất bại{}",
    "otc_failure_0077": "loại coin không thể giao dịch",
    "otc_failure_0078": "loại coin không tồn tại",
    "otc_failure_0079": "làm mới lệnh thất bại",
    "otc_failure_0080": "loại coin cấm giao dịch",
    "otc_failure_0081": "loại coin cấm dùng",
    "otc_failure_0082": "lệnh chờ không tồn tại",
    "otc_failure_0083": "OTC chưa hỗ trợ tiền pháp định",
    "otc_failure_0084": "OTC chưa hỗ trợ giao dịch coin",
    "otc_failure_0085": "số dư không đủ",
    "otc_failure_0090": "Tài khoản bị cấm giao dịch OTC，liên hệ CSKH",
    "otc_failure_0091": "tài khoản thường không thể tạo đơn OTC,mời KYC cấp thương gia",
    "otc_failure_0092": "đơn hàng chưa kết thúc,bên mua không thể khiếu nại",
    "otc_failure_0093": "đã tố cáo đơn hàng,mời liên hệ CSKH",
    "otc_failure_0094": "căn cứ quy định nước sở tại，không thể thực hiện thao tác này",
    "otc_failure_0095": "Lệnh này không có sẵn để giao dịch",
    "otc_failure_0096": "Tài sản của người bán bất thường, không thể thực hiện thao tác này.",
    "otc_failure_0097": "Số lần hủy OTC trong ngày đã quá giới hạn, cấm giao dịch",
    "otc_failure_0098": "Bạn hiện còn đơn chưa hoàn thành, vui lòng hoàn thành và tiếp tục",
    "otc_margin_certification": "Chứng nhận Lề",
    "otc_mine_account_paid": "tôi đã thanh toán",
    "otc_order_prompt_003": "nếu người mua không thanh toán nhưng đã ấn nút xác nhận thanh toán,hệ thống sẽ tự động hủy bỏ giao dịch",
    "otc_picture_no_null": "mời tải lên ít nhất 1 ảnh",
    "otc_prompt_001": "1、Thanh toán đơn hàng có hiệu lực trong 30 phút, vui lòng thanh toán đúng hạn và bấm vào nút (Tôi đã thanh toán).\n2、Đơn vị tiền tệ của người bán đã bị hệ thống khóa, bạn hãy yên tâm đặt hàng.",
    "otc_rate": "phí",
    "otc_report_order": "Đặt hàng",
    "otc_success_0001": "hủy thành công",
    "otc_success_0002": "bán thành công",
    "otc_success_0003": "mua thành công",
    "otc_success_0004": "xác nhận thanh toán thành công",
    "otc_success_0005": "xác nhận nhận tiền,đơn hàng kết thúc",
    "otc_success_0006": "khiếu nại thành công",
    "otc_waiting_for_confirmation": "chờ bên bán xác nhận",
    "other_settings": "Cài đặt khác",
    "overtime_pay": "quá giờ chưa thanh toán,hủy đơn",
    "param-error": "Lỗi tham số",
    "partner": "Đối tác",
    "partner_commission": "Bạn nhảy có thể nhận tiền hoa hồng từ cấp dưới",
    "partner_commission_fee": "Phí hoa hồng",
    "partner_current_level": "Cấp bậc hiện tại",
    "partner_get_all_subordinate": "Có thể lấy hết cấp dưới",
    "partner_management": "Quản lý đối tác",
    "partner_need_pay": "Cần thanh toán",
    "partner_need_recommend": "Quảng bá trực tiếp",
    "partner_recommended": "Đã quảng bá",
    "partner_start_and_end_time": "Bắt đầu và dừng thời gian",
    "partner_team": "Đội nhóm",
    "partner_team_member": "Phải có đủ người trong nhóm",
    "partner_upgrade_immediately": "Nâng cấp ngay",
    "pass_muster": "nếu không phù hợp,nhấp ở đây",
    "passed": "đã thông qua",
    "passport": "passport",
    "password_disagree": "mật mã không trùng",
    "password_is_different": "mật mã không trùng",
    "password_text": "mật mã",
    "password_wrong": "Đặt mật khẩu 8-20 kí tự",
    "past_activities": "Sự kiện trong quá khứ",
    "pause_recharge": "coin này không nạp được",
    "pause_withdraw": "coin này không rút được",
    "pay_number": "Số tiền thanh toán",
    "pay_wey": "cách thanh toán",
    "payee": "người nhận",
    "payfor_type": "cách thanh toán",
    "payment": "Thanh toán",
    "payment_amount": "lượng tiền thanh toán",
    "payment_currency": "Tiền tệ thanh toán",
    "payment_has_been": "(đã thanh toán)",
    "payment_term": "cách thanh toán",
    "paypal_account": "tài khoản paypal",
    "pending_review": "đợi xét duyệt",
    "people": "người",
    "period": "Kì",
    "personal_detail": "Thông tin cá nhân",
    "personal_purchase_tips": "không thể tự mua đơn hàng của mình",
    "phone": "điện thoại",
    "phone_code": "mã xác minh ",
    "phone_register": "đăng ký bằng SĐT",
    "phone_verification": "Xác minh SMS",
    "phone_verify": "Xác minh điện thoại",
    "photo_album": "ảnh",
    "please_enter": "mời nhập",
    "please_enter_abbreviation_or_letter": "nhập nội dung tìm",
    "please_input_content": "Vui lòng nhập nội dung",
    "please_pay": "thanh toán",
    "please_verification": "mời xác nhận",
    "pop_Date": "Ngày",
    "pop_reset": "Đặt lại",
    "position at market price?": "Vị thế có được đóng hoàn toàn theo giá thị trường không",
    "post_only": "Chỉ nhà sản xuất",
    "price": "giá",
    "price_cannot_be_less": "Giá cả không thể bị giảm",
    "price_cannot_be_more": "Giá cả không thể lớn hơn thế.",
    "price_empty": "Giá không thể chịu được",
    "principal": "Vốn",
    "promote_links": "link ref",
    "promotion_statistical": "tổng kết sale",
    "prtner_invalid": "Không hợp lệ",
    "publish_a_trade_order": "Tạo đơn hàng",
    "purchase": "mua",
    "push": "Tài sản PUSH",
    "push_maximum_volume": "Nhu cầu tăng cao hơn số lượng dự.",
    "push_minimum_trading_volume": "Chất lượng thúc đẩy ít hơn mức buôn bán tối thiểu.",
    "quantity": "số lượng",
    "quantity_deal": "lượng/khớp",
    "quota": "Giới hạn",
    "range": "phạm vi",
    "real_income": "Thu nhập thực",
    "real_name_authentication": "xác thực danh tính",
    "reason": "lý do",
    "rebate_amount": "Hợp số",
    "rebate_record": "Hồ sơ hoàn tiền",
    "rebate_reward": "Phần thưởng hoàn lại",
    "receiving_set": "cái đặt nhận",
    "receiving_set_title": "Cài đặt phương thức thanh toán trước.",
    "recently_deal1": "Giao dịch gần đây",
    "recharge_notice": "nạp cần biết\n1、mời thông qua{}App nền hoặc ví online điền vào lượng tiền {}chuyển tới địa chỉ.sau khi thành công,hệ thống sẽ nhận được xác nhận{} chuyển coin lên ví sàn của khách hàng,xin chờ giây lát。\n2、cùng ví có thể nạp nhiều lần,không ảnh hưởng。\n3、lượng nạp ít nhất{}。",
    "recharge_source": "nguồn nạp",
    "recharge_withdraw_failure_0001": "vấn đề mạng",
    "recharge_withdraw_failure_0002": "mã giao dịch không được trống",
    "recharge_withdraw_failure_0003": "mã xác minh không được trống",
    "recharge_withdraw_failure_0004": "ID người dùng không được trống",
    "recharge_withdraw_failure_0005": "ID người dùng không được trống",
    "recharge_withdraw_failure_0006": "tài khoản không tồn tại",
    "recharge_withdraw_failure_0007": "loại coin,ID không được trống",
    "recharge_withdraw_failure_0009": "coin không tốn tại",
    "recharge_withdraw_failure_0010": "mã authy không được trống",
    "recharge_withdraw_failure_0011": "mật mã giao dịch sai",
    "recharge_withdraw_failure_0012": "cách nạp không được trống",
    "recharge_withdraw_failure_0013": "lượng nạp không được trống",
    "recharge_withdraw_failure_0014": "chú thích nạp không được trống",
    "recharge_withdraw_failure_0015": "ID nạp không được trống",
    "recharge_withdraw_failure_0016": "đơn nạp không tồn tại",
    "recharge_withdraw_failure_0017": "chỉ đợi nạp mới được xóa",
    "recharge_withdraw_failure_0018": "chỉ đợi nạp mới được xét duyệt",
    "recharge_withdraw_failure_0019": "mở đóng băng sau khi duyệt thành công",
    "recharge_withdraw_failure_0020": "số phiên bản không được trống ",
    "recharge_withdraw_failure_0021": "sai số tham chiếu",
    "recharge_withdraw_failure_0022": "số liệu đã làm mới",
    "recharge_withdraw_failure_0023": "Id không được trống",
    "recharge_withdraw_failure_0024": "trạng thái khóa mới được duyệt",
    "recharge_withdraw_failure_0025": "đợi rút tiền mới được khóa",
    "recharge_withdraw_failure_0026": "đợi rút tiền hoặc đã khóa mới được hủy",
    "recharge_withdraw_failure_0027": "lượng đóng băng không được trống",
    "recharge_withdraw_failure_0028": "lý do đóng băng không được trống",
    "recharge_withdraw_failure_0029": "lý do giải băng không được trống",
    "recharge_withdraw_failure_0030": "lượng rút không được trống",
    "recharge_withdraw_failure_0031": "ví rút không được trống",
    "recharge_withdraw_failure_0032": "phí rút không được trống",
    "recharge_withdraw_failure_0033": "phí giao dịch bất thường",
    "recharge_withdraw_failure_0034": "số liệu không tồn tại",
    "recharge_withdraw_failure_0035": "tài sản không thể giải băng",
    "recharge_withdraw_failure_0036": "ví rút sai",
    "recharge_withdraw_failure_0041": "tài khoản bị cấm rút coin,mời liên hệ cskh",
    "recharge_withdraw_failure_0042": "số lần rút đã đạt mức ngày",
    "recharge_withdraw_failure_0043": "tổng lượng rút đã đạt mức ngày",
    "recharge_withdraw_failure_0044": "Đạt VIP thông tin bất thường",
    "recharge_withdraw_failure_0045": "thông tin coin đạt được bất thường",
    "recharge_withdraw_failure_0046": "sau khi đổi mật mã 24h không thể rút tiền!",
    "recharge_withdraw_failure_0048": "đơn rút lớn bất thường",
    "recharge_withdraw_failure_0049": "đơn rút nhỏ bất thường",
    "recharge_withdraw_failure_0050": "số lượng nhập cần nhỏ hơn hạn mức",
    "recharge_withdraw_failure_0051": "số lượng nhập cần lớn hơn hạn mức",
    "recharge_withdraw_msg_status_0001": "chờ rút",
    "recharge_withdraw_msg_status_0002": "Đang gửi",
    "recharge_withdraw_msg_status_0003": "rút thành công",
    "recharge_withdraw_msg_status_0004": "hủy",
    "recharge_withdraw_msg_status_0005": "đang xác nhận",
    "recharge_withdraw_msg_status_0006": "nạp thành công",
    "recharge_withdraw_msg_status_0007": "đã duyệt",
    "recharge_withdraw_msg_type_0001": "nạp",
    "recharge_withdraw_msg_type_0002": "rút",
    "recharge_withdraw_msg_withdraw_type_0001": "chuyển nội bộ",
    "recharge_withdraw_msg_withdraw_type_0002": "chuyển ra ngoài",
    "record": "lịch sử",
    "record_the_video": "nhấp tạo clip",
    "recording": "ghi âm lại",
    "redeem_now": "Mua lại ngay",
    "redeemed": "Mua lại",
    "redeeming": "Trao đổi",
    "redemption_amount": "Số tiền quy đổi",
    "redemption_time": "Thời gian quy đổi",
    "referrer": "Người giới thiệu",
    "register": "Đăng ký",
    "registered_successfully": "đăng ký thành công",
    "registration_award_details": "Chi tiết tiền thưởng đăng ký",
    "registration_incentives": "Thưởng đăng ký",
    "registration_time": "Time đăng ký",
    "regular": "Kì hạn",
    "regular_return": "Lãi theo tháng'",
    "release": "Phát hành",
    "release_operation": "Gửi một đơn đặt hàng, bấm vào đây để hoạt động!",
    "release_the_order": "Phát đơn",
    "release_time": "Thời gian phát",
    "remark": "Chú thích",
    "remember_state": "Nhớ tài khoản",
    "repeal": "Hủy bỏ",
    "reset": "đặt lại",
    "reset_login_password": "Đổi mã đăng nhập",
    "reset_pass": "Cài lại mật mã",
    "residue": "Số dư",
    "retrieve_the_deposit": "Thu hồi tiền gửi",
    "return_amount": "Lượng hoàn lãi",
    "return_method": "Cách hoàn lãi",
    "return_the_principal": "Hoàn trả vốn",
    "revenue_details": "Tình hình thu nhập",
    "revocation_all": "Hủy toàn bộ",
    "reward_amount": "Số phần thưởng",
    "reward_locked": "Phần thưởng khóa",
    "reward_status": "Trạng thái thưởng",
    "reward_type": "Loại thưởng",
    "rewards_record": "Lịch sử thưởng",
    "right_now_register": "Đăng ký mới",
    "roll_out": "Chuyển ra",
    "save": "Lưu",
    "save_coin": "Gửi",
    "save_coin_count": "Lượng tiền gửi",
    "save_coin_days": "Ngày gửi",
    "save_coin_duration": "Thời hạn gửi",
    "save_currency": "Gửi tiền",
    "save_money_amount": "Tổng tiền gửi",
    "save_successfully": "lưu thành công",
    "save_the_image": "tải ảnh",
    "scan_QR_code": "không quét những mã QA bất minh",
    "scan_result": "kết quả quét",
    "search": "Tìm",
    "search_coin_placeholder": "Tìm kiếm đơn vị tiền tệ mà bạn quan tâm",
    "search_keyword": "Tìm từ khoá",
    "search_name": "Tên tìm kiếm",
    "search_result": "Kết quả tìm",
    "search_trade_pair_placeholder": "Tìm kiếm cặp giao dịch mà bạn quan tâm",
    "security_center": "trung tâm an toàn",
    "security_level": "Cấp độ an toàn",
    "select_date_of_borth": "Vui lòng chọn ngày sinh",
    "select_delete_warn": "Vui lòng chọn một cảnh báo để xóa",
    "sell": "bán ra",
    "sell_1": "Bán",
    "sell_order": "Bán",
    "sell_quantity": "lượng bán",
    "sell_rate": "phí bán",
    "seller": "người bán",
    "seller_phone": "SĐT người bán",
    "send": "phát",
    "senior_certification": "xác minh cấp cao",
    "senior_certification1": "xác minh cấp cao",
    "service": "QQ CSKH：",
    "service_charge": "phí",
    "session_timeout": "quá giờ đăng nhập,mời nhập lại",
    "set_Password": "Sửa",
    "set_gesture_pass": "cài đặt mã tay",
    "set_login_password": "Cài đặt mật khẩu",
    "setting": "thiết lập",
    "setting_alipay": "thiết lập Alipay",
    "setting_bank_card": "thiết lập ATM",
    "setting_message_warn_tip": "* Sau khi lưu được bật, bạn có thể nhận được một thông báo cảnh báo và đóng nó mà không cần nhắc.",
    "setting_paypal": "thiết lập paypal",
    "setting_paypassword": "thiết lập mã giao dịch trước",
    "setting_wechat": "thiết lập Wechat",
    "setting_western_union": "thiết lập western union",
    "share_invitation_link": "chia sẽ link giới thệu",
    "share_link": "chia sẽ link",
    "share_to_do": "Chia sẻ với",
    "shift_to": "Chuyển vào",
    "single_least": "Điểm tối thiểu duy nhất là thấp hơn.",
    "single_minimum": "đơn thấp nhất",
    "single_more": "Chỉ số tối thiểu là lớn hơn nhiều.",
    "skip": "bỏ qua",
    "small_exchange": "đổi tiền nhỏ",
    "small_exchange_tip": "* Nhắc nhở: Bạn chỉ có thể đổi các tài sản có giá trị nhỏ hơn {} {} trong vòng 24 giờ. Tiền tệ của giao dịch hủy bỏ tạm thời không thể chuyển đổi.",
    "small_exchange_tip-1": "* Nhắc nhở: Giá thị trường thay đổi theo thời gian thực và giá thực tế sẽ được áp dụng.Đồng tiền bị hủy niêm yết không thể chuyển đổi, phí chuyển đổi hiện tại là {}",
    "small_exchange_valuation": "Định giá",
    "small_exchange_valuation-1": "Giá trị BTC",
    "smallest_unit_price": "Giá bán hàng cơ bản thấp hơn giá bán Robot tối thiểu.",
    "so adjusted to": "Vì vậy, đã điều chỉnh để",
    "sold_out": "đã delist",
    "start time": "Thời gian bắt đầu",
    "start_date": "Ngày bắt đầu",
    "start_time": "Thời gian bắt đầu",
    "statistics_report": "Báo cáo thống kê",
    "status": "Trạng thái",
    "submit": "gửi clip ",
    "submitBtn": "Gửi",
    "succeed": "thành công",
    "successful_invitation": "mời thành công",
    "successfully_received": "Nhận thành công",
    "successfully_set": "Thiết lập thành công",
    "super_partner": "Siêu đối tác",
    "switch_account": "đổi tài khoản",
    "system_top_up": "hệ thống nạp",
    "system_unlock": "Mở khóa hệ thống",
    "tab_assets": "Tài sản",
    "tab_home": "Trang chủ",
    "tab_markets": "Báo giá",
    "tab_swaps": "Hợp đồng",
    "tab_trade": "Giao Dịch",
    "taking_pictures": "chụp ảnh",
    "telegraph": "Telegram：",
    "temporary_not_online_contact_service": "Chưa trực tuyến, vui lòng liên hệ với dịch vụ khách hàng",
    "the_day": "Ngày",
    "the_event_is_over": "Sự kiện đã kết thúc",
    "the_latest_price": "giá mới nhất",
    "the_password_is_correct": "mật mã đúng",
    "theme": "Chủ đề",
    "this_month": "Tháng này",
    "this_week": "Tuần này",
    "ticket": "Vé",
    "time_of_payment": "Giờ thanh toán",
    "time_the_input": "Không cần nhập mật khẩu giao dịch trong vòng {} h",
    "timeout": "quá time",
    "timeout_affirm": "quá time chưa xác nhận",
    "timeout_deal": "quá time,tự động khớp lệnh ",
    "timeout_to_cancel": "quá time thanh toán,tự động hủy",
    "to_confirm": "đến xác nhận",
    "total_buyer": "lượng coin người bán",
    "total_count": "Toàn bộ",
    "total_exchange": "Tổng đổi",
    "total_lock_amount": "Tổng lượng khóa",
    "total_money": "tổng tiền",
    "total_return": "Dự tính thu nhập",
    "trade_fee": "phí cặp gd",
    "trade_type": "Loại giao dịch",
    "trading_currency": "Tiền tệ giao dịch",
    "trading_fiat_currency": "Giao dịch tiền tệ fiat",
    "trading_instructions": "giao dịch cần biết",
    "trading_on": "cặp giao dịch",
    "trading_pair_abbreviation": "Nhập tên viết tắt cặp giao dịch",
    "trading_this_month": "Giao dịch trong tháng này",
    "trading_volume": "lượng khớp",
    "transaction_description": "hướng dẫn giao dịch",
    "transaction_password": "mật mã tài sản",
    "transaction_price": "giá khớp",
    "transfer_number": "lượng chuyển ra",
    "try_it_now": "Thử ngay bây giờ",
    "type": "loại",
    "unable_to_access_album": "thiết bị không hổ trợ hình ảnh",
    "unaccalimed": "Không bị cản trở",
    "unavailable": "Không có sẵn",
    "under_review": "Đang duyệt",
    "univalence": "đơn giá",
    "unlock_details": "Chi tiết mở khoá",
    "unlock_mode": "Chế độ mở khóa",
    "unlock_period": "Thời gian mở khóa",
    "unlock_plan": "Kế hoạch mở khóa",
    "unlock_plan_unlock": "Mở khóa",
    "unlock_record": "Hồ sơ mở khóa ",
    "unlock_time": "Thời gian mở khóa",
    "unlocked": "Đã mở khóa",
    "unredeemed": "Vô ơn",
    "unverified": "chưa xác minh",
    "up_to_three": "max mở 3 loại",
    "upgrade": "Nâng cấp",
    "upload_again": "tải lên lại",
    "upload_front": "tải lên mặt trước",
    "upload_identity_card": "mời tải lên chứng từ ",
    "upload_image_success": "Tải ảnh thành công",
    "upload_information_as_required": "Dữ liệu phải được tải lên đúng theo các yêu cầu trên, nếu không việc xác thực sẽ thất bại",
    "upload_passport": "Vui lòng tải lên hộ chiếu của bạn",
    "upload_passport_info_picture": "Tải lên trang thông tin hộ chiếu ảnh",
    "upload_reverse": "tải lên mặt sau",
    "ups_and_downs": "Tăng giảm ngày",
    "ups_downs_habit": "Thói quen thăng trầm",
    "usable": "Có sẵn",
    "used": "Đã qua sử dụng",
    "user-bind-failure": "Mời kết nối thất bại",
    "user-bind-success": "Mời kết nối thành công",
    "user-collection-error": "thất bại",
    "user-country-code-not-empty": "mã quốc tế không được trống",
    "user-disable-trade": "Tài khoản hiện tại bị cấm giao dịch, vui lòng liên hệ tư vấn!",
    "user-down-notbind": "Người dùng không thể ràng buộc người dùng cấp dưới.",
    "user-email-fail-often": "mail gửi nhiều lần，120s sau thử lại",
    "user-email-not-empty": "địa chỉ mail không được trống",
    "user-email-verification-code-out": "mã xác nhận quá hạn,mời nhập mã mới",
    "user-fail-advanced-auth": "KYC cao thất bại",
    "user-fail-advanced-authed": "KYC cao thành công",
    "user-fail-assetnotenough-error": "tài sản không đủ",
    "user-fail-bank-count-error": "thêm nhiều nhất 3 phương thức thanh toán",
    "user-fail-bank-inotc": "ben thanh toán tại OTC có đơn,không thể cấm",
    "user-fail-cardno-repeat-error": "tài liệu đã sử dụng",
    "user-fail-email-code": "mã xác nhận mail sai",
    "user-fail-google-binded": "đã liên kết google",
    "user-fail-google-code": "mã authy sai",
    "user-fail-inviter-notexist": "người giới thiệu không có",
    "user-fail-login-disable": "Tài khoản hiện tại bị cấm đăng nhập, Vui lòng liên hệ tư vấn!",
    "user-fail-login-lock": "tài khoản bị khóa",
    "user-fail-login-password": "tài khoản hay mật mã sai,số lần nhập còn{}",
    "user-fail-mail-binded": "mail đã được liên kết",
    "user-fail-not-realname": "mời KYC trước",
    "user-fail-oldpassword-error": "mật mã cũ sai",
    "user-fail-partner-not-found": "người dùng không tồn tại",
    "user-fail-partnernotfound": "đại lý không tồn tại",
    "user-fail-password": "tài khoản hoặc mật khẩu sai",
    "user-fail-password-blank": "mật mã không được trống",
    "user-fail-password-blank1": "Xác nhận mật khẩu bắt buộc",
    "user-fail-password-repeat": "mật mã mới và cũ giống nhau,mời cài lại!",
    "user-fail-password-thin": "cường độ bảo mật kém",
    "user-fail-passwword-eq-paypassword": "mã giao dịch và mã đăng nhập giống nhau,mời đặt lại",
    "user-fail-paypassword-error": "mã thanh toán sai",
    "user-fail-paypassword-notset": "cài đặt mã thanh toán trước",
    "user-fail-paypassword-set": "đã cài mã thanh toán",
    "user-fail-phone-binded": "sdt đã liên kết",
    "user-fail-realname-auth": "KYC thất bại",
    "user-fail-realname-authed": "KYC thành công",
    "user-fail-realname-notset": "mời KYC trước",
    "user-fail-reg": "đăng kí thất bại,lỗi máy chủ",
    "user-fail-reg-mail": "lỗi định dạng mail",
    "user-fail-reg-mail-exist": "mail đã đăng ký",
    "user-fail-reg-phone-exist": "sdt đã đăng ký",
    "user-fail-securitycount": "bảo lưu tối thiểu 1 loại xác minh",
    "user-fail-sms-code": "xác minh tin nhắn sai",
    "user-fail-sms-code-new": "mã xác minh tin nhắn sai",
    "user-fail-sms-code-old": "mã xác minh tin nhắn cũ sai",
    "user-fail-usernameisblank": "tên người dùng không được trống",
    "user-id-or-nationCode—is-null": "id người dùng hoặc mã quốc tế trống",
    "user-login-success": "đăng nhập thành công",
    "user-mail-fail": "gửi mail thất bại",
    "user-mail-success": "gửi mail thành công",
    "user-not-exist": "Người dùng này không tồn tại",
    "user-notopen-success": "Chưa mở",
    "user-phone-not-empty": "sdt không được trống",
    "user-sms-fail": "gửi thất bại,lỗi máy chủ",
    "user-sms-fail-often": "gửi tin nhiễu",
    "user-sms-success": "tin gửi thành công",
    "user-sms-verification-code-out": "mã quá hạn,mời nhập mã mới",
    "user-success-advanced-auth": "xác minh cao thành công",
    "user-success-realname-auth": "KYC thành công",
    "user-validate-code-fail": "mã xác minh không đúng",
    "user-validate-fail": "kiểm tra thất bại",
    "user-vip-common-fail": "mua VIP thất bại",
    "user_agreement": "chọn đồng ý quy định người dùng",
    "user_collection_cencel_success": "đã hủy",
    "user_collection_insert_success": "đã nhận",
    "user_pay_disable_state": "mã giao dịch sai niều lần bị khóa giao dịch, thử lại sau 2h",
    "user_pay_is_error": "mã giao dịch sai,lần nhập còn{},quá số lần sẽ khóa 2h",
    "user_pay_password_not_setting": "Chưa đặt mật khẩu giao dịch, Vui lòng đặt mật khẩu.",
    "user_protocol": "điều khoản người dùng",
    "user_service_agreement": "《Hiệp định phục vụ》",
    "user_vip_has_expire": "vip tài khoản đã hết hạn không thể nâng cấp!",
    "user_vip_update_failure": "Tài khoản hiện không thể gia hạn hoặc nâng cấp, vui lòng liên hệ tư vấn viên!",
    "valid_identity_card": "mời nhập đúng chứng từ",
    "valuation": "Định giá tiền gửi",
    "verification_code_error": "mã xác minh sai",
    "verification_failed_001": "Xác minh không thành công",
    "verification_finish": "hoàn thành xác minh",
    "version_updating": "nâng cấp mới",
    "versions": "phiên bản",
    "vertical_version": "Bản dọc",
    "view_activity": "Xem hoạt động",
    "view_lock": "Xem vị trí khóa",
    "volamount": "người mua cần khớp{}lệnh",
    "volamount_sell": "người bán cần khớp{}lệnh",
    "vote": "Bình chọn",
    "vote_add_coin": "Bình chọn cho danh sách",
    "vote_return": "Trở về",
    "voting_coin": "Bỏ phiếu tiền tệ",
    "voting_number": "Phiếu bầu",
    "voting_time": "Thời gian bỏ phiếu",
    "wait_buyer_payment": "chờ bên mua thanh toán",
    "wait_for_payment": "chờ thanh toán",
    "waiting_for_receiving": "chờ bên bán xác nhận nhận tiền ",
    "warm_prompt": "Nhắc",
    "warning_setTitle": "Cài đặt cảnh báo sớm",
    "wechat_account": "Wechat ID",
    "wechat_upload": "*cách tải mã QA wechat:trang chủ Wechat>tôi>ví tiền>nhận gửi tiền>bảo lưu hình ảnh,Tải lên mã nhận của album điện thoại di động",
    "week": "Tuần",
    "welcome": "Chào mừng đến với BitMatrix",
    "welcome_to": "chào mừng đến",
    "winning_record": "Kỷ lục chiến thắng",
    "withdrawal_amount": "lượng rút mỗi ngày",
    "withdrawal_charge": "phí rút tiền",
    "xilian": "WestUnion",
    "yesterday_income": "Thu nhập hôm qua",
    "you_have_selected": "Bạn đã chọn",

    "Your closing number": "Số lượng đóng của bạn ít hơn vị trí tối thiểu!",
    "Your have pending Cross orders": "Bạn có các lệnh Chéo đang chờ xử lý. Hãy hủy chúng trước khi điều chỉnh đòn bẩy của bạn.",
    "Your have pending isolated orders": "Bạn có các lệnh cô lập đang chờ xử lý. Hãy hủy chúng trước khi điều chỉnh đòn bẩy của bạn.",
    "Home page apps": "Ứng dụng tại nhà",
    "Home_Edit": "chỉnh sửa",
    "recently_deal01": "Giao dịch",
    "depth01": "độ sâu",
    "depth02": "độ sâu",
    "discount_currency_introduction01": "Giới thiệu tiền tệ",
    "Info001": "Thông tin",



    "Avg cost": "Giá bình",

    "Long position": "Dài",
    "Short position": "Ngắn",
    "Hold on": "Giữ lấy",
    "Done": "Xong",
    "new_version": "phiên bản mới",
    "Announcement": "Thông báo",
    "View details": "Chi tiết",

    "Option Assets": "Tài sản Lựa chọn",
    "Option Account": "tài khoản Lựa chọn",
    "Option": "Lựa chọn",
    "1M": "1điểm",
    "5M": "5điểm",
    "15M": "15điểm",
    "30M": "30điểm",
    "1H": "1giờ",
    "Delivery Time": "Chuyển",
    "Call": "Hướng lên",
    "Put": "Đi xuống",
    "Draw": "Bằng phẳng",
    "Draw2": "Bằng\nphẳng",
    "Call1": "Trỗi dậy",
    "Put1": "từ chối",
    "Draw1": "Bằng",
    "Buy-2": "Mua",
    "Rate of return": "Trở lại",
    "Change": "Thay đổi",
    "Buy Option": "Mua",
    "Awaiting": "Đang chờ",
    "Delivery": "Riêng",
    "Checkout": "Chi tiết",
    "Front": "hiện hành",
    "Back": "lần tới",
    "Coming soon": "Sắp có buôn bán",
    "delivery settlement": "buôn bán Quyết toán",
    "Ongoing": "Đang mua",
    "Pending purchase": "Đang chờ mua",
    "Billing Amount": "Số lượng bán",
    "Amount": "Số lượng mua",
    "Delivery result": "buôn bán kết quả",
    "Delivery time": "buôn bán thời gian",
    "Up": "Trỗi dậy",
    "Down": "Từ chố",
    "Delivery details": "buôn bán chi tiết",
    "Index Composition": "Thành phần chỉ mục",
    "Exchange": "Trao đổi",
    "Change2": "Thay đổi",
    "Weights": "Trọng lượng",
    "Order number": "Số đơn hàng",
    "Option Session": "Option thời gian",
    "Buy Time": "Mua thời gian",
    "Opening time": "Thời gian mở cửa",
    "Bet Type": "Loại đặt cược",
    "Opening Price": "Giá mở cửa",
    "Delivery Price": "buôn bán Giá bán",
    "Settlement Currency": "Tiền tệ thanh toán",
    "K-line": "k Dòng",
    "UpSelect": "Trỗi dậy lựa chọn",
    "DownSelect": "Từ chối lựa chọn",
    "Front has ended, please place another order": "Mặt trận đã kết thúc, vui lòng đặt hàng khác",
    "Successfully ordered": "Đặt hàng",
    "Insufficient balance": "Thiếu cân bằng",
    "Quantity purchased must be greater than the minimum limit": "Số lượng mua phải lớn hơn giới hạn tối thiểu",
    "Quantity purchased must be less than the maximum limit": "Số lượng mua phải ít hơn giới hạn tối đa",
    "The current time is not in the purchase time range": "Thời gian hiện tại không nằm trong phạm vi thời gian mua hàng",
    "expected": "Kỳ vọng",
    "To_a_game": "Một trò chơi nữa",
    "Settlement Result": "Kết quả giải quyết",
    "Delivery-1": "buôn bán",
    "Ups and downs": "Thay đổi",
    "duration": "khoảng thời gian",
    "Option funds daybook": "Chi tiết",


    "Open innovative options trading": "OMở giao dịch quyền chọn sáng tạo",
    "Confirm Open": "Xác nhận mở",
    "Option Agree Content": "Để bảo vệ quyền lợi của mình, Trước khi mở Quyền chọn, bạn phải đọc kỹ 《Thỏa thuận Sử dụng Giao dịch Quyền chọn Sáng tạo》",
    "Option Agree Content footer": "《Thỏa thuận sử dụng giao dịch quyền chọn sáng tạo》",
    "Option Agree Content header": "Để bảo vệ quyền của bạn, Trước khi mở Tùy chọn, bạn phải đọc kỹ",

    "Service upgrade, please try again later!": "Nâng cấp dịch vụ, vui lòng thử lại sau!",
    "month": "M",


    "Invitation": "Mời",
    "Markets": "Báo giá",
    "Swaps": "Hợp đồng",
    "Trade": "Giao dịch",
    "Trade-1": "Giao dịch",
    "Assets": "Tài sản",
    "Buy": "Mua",
    "Help": "Cứu giúp",
    "Service": "Dịch vụ",
    "Activity": "Hoạt động",
    "Communtiy": "Cộng đồng",
    "No search results": "Không có kết quả tìm kiếm",
    "The option account asset is 0, please transfer from the wallet account to the option account first.": "Nội dung tài khoản quyền chọn là 0, trước tiên hãy chuyển từ tài khoản ví sang tài khoản quyền chọn.",
    "Perpetual account assets are 0, please transfer from wallet account to perpetual account first.": "Nội dung tài khoản quyền chọn là 0, trước tiên hãy chuyển từ tài khoản ví sang tài khoản quyền chọn.",
    "Financing": "Tài trợ",
    "Load": "trọng tải",
    "Replay001": "phát lại",
    "Constellation Program": "Phổ biến - Chương trình Chòm sao",
    "Constellation Program2": "Phổ biến - Tài chính chòm sao",
    "rate rankings": "Với bảng xếp hạng tỷ lệ thắng duy nhất",
    "rate rankings2": "Các tổ chức tài chính hàng đầu",

    "CXT Quotes": "Báo giá",
    "Digital asset trading tool service platform": "Nền tảng dịch vụ công cụ giao dịch tài sản kỹ thuật số",
    "Service_home": "Dịch vụ",
    "Strategic cooperation": "Chiến lược",

    "Financing assets": "Tài sản tài trợ",
    "Remove 0 from the first number when registering!": "Bỏ số 0 khỏi số đầu tiên khi đăng ký!",
    "Are you sure you want to quit": "Có thoát BitMatrix không",


    "Opening Price": "giá mở cửa",
    "Closing Price": "Giá đóng cửa",
    "Formula interpretation": "Giải thích công thức",
    "Note: If the input box is left blank": "Lưu ý: Nếu ô nhập liệu để trống, điều đó có nghĩa là dữ liệu trao đổi hiện tại chưa được lấy trong trường này",
    "Option Sessions": "Phiên tùy chọn",
    "Result": "Kết quả",
    "When data for an exchange is not available": "Khi dữ liệu cho một trao đổi không có sẵn, trọng số là 0%. Khi tính khả dụng của nguồn dữ liệu ≤2, nó được xác định là không hợp lệ",
    "Assume that the index is derived from the opening prices of five exchanges": "Giả sử rằng chỉ số được tính từ giá mở cửa của năm sàn giao dịch: A 、 B 、 C 、 D 、 E ； Giá đóng cửa là: A` 、 B` 、 C` 、 D` 、 E`",
    "The proportion of those five exchanges are": "Tỷ trọng của năm sàn giao dịch đó là: a% 、 b% 、 c% 、 d% 、 e% 、",
    "Stream bureau": "Cục phát trực tiếp",
    "(valid data source ≤ 2)": "(nguồn dữ liệu hợp lệ ≤ 2)",
    "Option calculator": "Máy tính tùy chọn",
    "Round selection": "Chọn một",
    "Select option": "Chọn tùy chọn",
    "Average opening price": "Giá mở cửa trung bình",
    "Average closing price": "Giá đóng cửa trung bình",
    "Calculate now": "Tính toán",
    "Calculation Results": "Kết quả tính toán",
    "Calculating Time": "Tính toán thời gian",
    "Management": "Quản lí",
    "Other_home": "Khác",
    "All applications": "Tất cả các ứng dụng",
    "Boon": "Phúc lợi",
    "Coin_Address_draw": "Địa chỉ rút",
    "Congratulations": "Xin chúc mừng",
    "Profit": "Lợi nhuận",
    "Gainers": "Gainers",
    "Drop": "Làm rơi",


    "Order Price": "Phí tổn",
    "Order Price1": "Phí tổn",
    "Sec Swap": "Sec Swap",
    "Expected Return": "Trở lại",
    "Sec Swap Assets": "Sec Swap Tài sản",
    "Sec Swap Bill": "Sec Swap Chi tiết",
    "Sec Swap Account": "Sec Swap Tài khoản",
    "Go Login": "Đăng nhập",
    "NSO Session": "NSO Phút",
    "Wallet Value1": "Giá trị Wallet",
    "Spot Value1": "Giá trị Spot",
    "PERP Value1": "Giá trị PERP",
    "Option Value1": "Giá trị lựa chọn",
    "Fiat Value1": "Giá trị Fiat",
    "Pledge_Amount": "Số lượng đặt",
    "total_return2": "Tổng lợi nhuận",
    "Violation_Loss": "Vi phạm",
    "Cycle": "Chu kỳ",
    "Locked2": "Đã khóa",
    "Time_Left": "Thời gian",
    "Cancel_Pledge": "Hủy bỏ đặt",
    "countDown_day": "D ",
    "Entrust2": "Đơn ủy thác",
    "All2": "Tất cả",
    "Ongoing2": "Xử lý",
    "Near2": "Sắp bắt",
    "Ended2": "Kết thúc",
    "Cancel the pledge?": "Hủy bỏ đặt?",
    "Lock-up mining": "khóa giá trị",
    "Rules Description": "Luật lệ",
    "Today Expected": "Kỳ vọng",
    "Order Escrow": "ký gửi",
    "Minimum": "Tối thiểu",
    "Daily Return": "Trở lại",
    "Buy-3": "Mua vào",
    "Active Currency": "Tiền tệ",
    "Minimum Amount": "Tối thiểu",
    "Lockup Period": "Khóa chu kỳ",
    "Confirm Pledge": "Xác nhận đặt",
    "Buy-4": "Mua vào",
    "Completed2": "Hoàn thành",
    "Cancelled2": "Đã hủy",
    "Conversion Starter": "Khởi động đổi",
    "Listing": "Danh sách",
    "Total Target": "Tổng số",
    "Conversion Ratio": "Tỷ Giá",
    "Single Limited": "Giới hạn đơn",
    "Participate Order": "Đã sửa đổi",
    "Fundraised": "Gây quỹ",
    "Current Progress": "Tiến độ",
    "Time Left": "Thời gian",
    "Start Time": "Giờ bắt đầu",
    "End Time": "Kết thúc",
    "Conversion Amount": "Số lượng",
    "Conversion Now": "Đứng chuyển đổi",
    "Conditions": "Đòi hỏi",
    "Preheating": "Làm nóng",
    "Records": "kỷ lục",
    "Select": "Lựa chọn",
    "To": "Đến",
    "Active Currency": "Tiền tệ",
    "Amount-3": "Tiền bạc",
    "Conversion Time": "Thời gian",
    "Arrival Time": "Thời gian đến",
    "Not Arrived": "Không đến",
    "Distance Start": "Hạn Bắt Đầu",
    "Hours-1": "Giờ",
    "Risk warning: It is displayed": "Cảnh báo rủi ro: Nó được hiển thị cho các tài sản đổi mới và có các đặc tính của tài sản kỹ thuật số. Hãy đánh giá kỹ lưỡng về giá cả và lựa chọn quyết định đầu tư một cách cẩn thận!",
    "Week-1": "Tuần",
    "S-1": "Giây",
    "minutes-2": "M",
    "You must pass basic KYC": "Bạn phải đạt KYC cơ bản",
    "You must pass advanced KYC": "Bạn phải đạt KYC nâng cao",
    "Account order exceeds": "Lệnh mở  vượt quá",
    "Suspend": "Đình chỉ",
    "Successfully": "Thành công",
    "Ongoing-1": "Xử lý",
    "Failed": "Thất bại",
    "New_Token_Subscribe": "Token Đăng ký",
    "More_awaits_you": "Giàu có hơn",
    "Distance Start": "Hạn Bắt Đầu",
    "day-2": "ngày",
    "Must Eligible": "Đạt chuẩn",
    "Custodial funds": "Quỹ lưu ký",
    "Default Ratio": "Tỷ lệ phạm",
    "Wallet Available": "Có sẵn",
    "Done-2": "Chắc",
    "Eligible Amount": "Đạt số lượng",
    "Eligible Price": "Giá phê duyệt",
    "New Token Subscribe": "Token Đăng ký",
    "Subscribe Now": "Đăng ký ngay",
    "Review Time": "Thời gian",
    "Review Passed": "Thành công",
    "Review Failed": "Thất bại",
    "Not Reviewed": "Chưa ôn lại",
    "Denial Reason": "lý trí",
    "If not received, please contact service": "Nếu không nhận được, vui lòng liên hệ với dịch vụ khách hàng",
    "Returned to wallet": "Quay lại ví",
    "From wallet assets": "Từ sản ví",
    "Day-3": "D",
    "Sec Swap asset is 0, please transfer from wallet account to Sec Swap first": "Tài sản của Sec Swap là 0, vui lòng chuyển từ tài khoản ví sang Sec Swap trước",
    "Settlement": "Thanh lý",
    "Completed": "Hoàn thành",
    "Password Setting": "Thiết lập",
    "Set password and email": "Đặt mật khẩu và email",
    "Email Settings": "Cài đặt email",
    "First-time registered users mus": "Người dùng đăng ký lần đầu tiên phải hoàn tất cài đặt mật khẩu đăng nhập và email",
    "Driver ID": "Bằng lái",
    "Minimum": "Tối thiểu",
    "Preview": "Xem trước",
    "cancelTime": "Hủy thời gian",
    "Enter wire transfer address (within 400 words)": "Nhập địa chỉ chuyển khoản (trong vòng 400 từ)",
    "Setting Pix": "Thiết lập Pix",
    "Bank Name": "Tên ngân hàng",
    "Company Name": "Tên công ty",
    "Please enter bank name": "Nhập tên ngân hàng",
    "Please enter company name": "Nhập tên công ty",
    "Tips:": "Mẹo:",
    "When you sell digital currency, your chosen payment method will be shown to the buyer. Please confirm that the information is filled in correctly.": "Khi bạn bán tiền kỹ thuật số, phương thức thanh toán bạn chọn sẽ được hiển thị cho người mua. Hãy xác nhận rằng thông tin được điền chính xác.",
    "Modify Pix": "Sửa đổi Pix",
    "Please bind Pix first": "Hãy ràng buộc Pix trước",
    "MentionMoneySend": "Đang gửi",
    "NewTokenSubscribe": "Token Đăng ký",
    "MoreWealthAwaitsYou": "Giàu có hơn",
    "TopGainers": "Lên xuống",
    "Newest": "Mới nhất",
    "Hot": "Danh sách nóng",
    "DatChange": "Thay đổi",

    'Total_assets_valuation': 'Tổng bộ đánh giá',
    'Invite Friends': 'Mời bạn tốt',
    'RedGreen': 'Đỏ+ Xanh-',
    'GreenRed': 'Xanh+ Đỏ-',
    'Color Preference': 'Màu sắc ưu thích',
    'Fuzzy Information': 'Thông tin mờ',
    'user_bind_xilain_trade_pwd': 'Nhập mật khẩu giao dịch',
    'login_please_input1': 'Nhập mã xác nhận SMS',
    'user_please_input9': 'Nhập mã xác nhận Google',
    'user_link_name': 'Tên chuỗi',
    'user_coin_bring_together': 'Tương đương',
    "chongbi0": 'Lưu ý :',
    "chongbi1": '1、Cấm thêm các tài sản không phải là {0} và không thể lấy bất kỳ đồng tiền tính phí nào {1}. ',
    "chongbi2": '2、gửi {0} bạn cần nạp tiền vào địa chỉ này thông qua {1} ứng dụng khách hoặc ví. Hệ thống sẽ tính phí {2} cho giao dịch này và sau đó điền vào tài khoản của bạn. Hãy kiên nhẫn.',
    "chongbi3": '2、cùng một địa chỉ có thể được sạc lại nhiều lần, không ảnh hưởng đến tài khoản. ',
    "chongbi4": '3、số tiền nạp tối thiểu {0}.',
    "currency_withdrawal_rate_rules": '{0}Quy tắc rút tiền:',
    "currency_withdrawal_rate_rules1": '1.Để đảm bảo an toàn tài sản khách hàng, cơ sở có thể gọi điện xác nhận thao tác rút tiền, vui lòng nghe máy',
    "currency_withdrawal_rate_rules2": '2.{0}Rút tiền được cho phép sau {1} lần xác nhận nạp tiền;',
    "currency_withdrawal_rate_rules3": '2.Số tiền có thể rút nhỏ hơn hoặc bằng tài sản khả dụng của tài khoản trừ đi số tài sản chưa xác nhận',
    "currency_withdrawal_rate_rules4": '3.Rút lên mỗi ngày{0}，Tổng hồi tối đa {1}。',
    "currency_transaction_password": "Mật khẩu",
    'long_press_to_save_to_album': 'Nhấn và giữ để lưu vào album',
    'the_fee_is_outside_the_range': 'Phí thủ tục không trong phạm vi',
    'start_and_end_date': 'Ngày tháng dừng lại',
    'The_withdrawal_amount_cannot_be_less': 'Số lượng tiền không thể ít hơn *{0}',
    'withdrawal_details': 'Chi tiết rút tiền',
    "RegisterRemove0": 'Xóa 0 khỏi số đầu tiên khi đăng ký',
    "closeLockUpPopup": 'Cancel Pledge sẽ tính Phí tổn thất do vi phạm trong {0} ngày, số tiền tổn thất do vi phạm là "{1}{2}", số tiền gốc thực tế nhận được là "{3}{4}".',
    "SetPassword": "Đặt mật khẩu",
    "comm_password": "Mật khẩu giao dịch",
    "unraise_recovering_password": "Sau khi khôi phục mật khẩu, 1 giờ không thể rút tiền",
    "LockUpDuan": "LUM",
    "PDleaseUseSafariBrowser": "Hãy sử dụng trình duyệt Safari.",
    "ADddToDesktop": "Thêm BitMatrix vào màn hình",
    "CDlickBelow": "Bấm vào bên dưới",
    "SDelectAddToHomeScreen": "Thêm vào màn hình chủ",
    "ADddToDesktop2": "Thêm BitMatrix vào màn hình",
    "CDommonbrowserAddMethod": "Trình duyệt thêm phương pháp",
    'user_senior_text1': 'Ghi chú',
    'user_senior_text2': 'Kích thước tệp ảnh không thể vượt quá 5M! Định dạng tệp phải là jpg, bmp, png, v.v.!',
    'user_senior_text3': 'Vui lòng đảm bảo hình ảnh không đánh dấu, sạch sẽ rõ ràng, hình hoàn chỉnh, không có chữ, hình không qua chỉnh sửa',
    'user_senior_text4': 'Giữ',
    'user_senior_text5': 'Ảnh ID',
    'user_senior_text6': ': Yêu cầu bạn giữ',
    'user_senior_text7': 'Ngày trong ngày',
    'user_senior_text8': 'Và',
    'user_senior_text9': 'UID',
    'user_senior_text10': 'Các dải được chụp dưới dạng ảnh không nhân đôi.',
    'user_senior_text11': ', Người kia cầm một bản viết tay bằng chữ viết tay của bạn',
    'user_senior_text12': 'Giữ giấy trắng và vui lòng viết những điều sau đây, và những người không phù hợp sẽ không vượt qua.',
    'user_senior_text13': 'Tôi đã biết và tự nguyện chấp nhận rủi ro đầu tư',
    'user_senior_text14': '.',
    'user_ID_card': 'Chứng minh thư',
    "user_passport": "Hộ chiếu",
    'Driver_ID': 'Bằng lái',
    "user_senior_upload3": "Tải lên ID ID cầm tay + UID + Ngày",
    "ServiceUpgrade": 'Nâng cấp dịch vụ',
    "ReferencePrice": "Giá tham khảo",
    "referenceTips": "Đơn giá giao dịch không phải là cuối cùng, chỉ để tham khảo",
    "NewCoinsLaunch": "Ra mắt tiền xu mới",
    "CurrentlyPurchased": "Hiện đã mua",
    "TotalMarketValue": "Tổng giá trị",
    "IEORatio": "Tỷ lệ",
    "CurrencyIntroduction": "Giới thiệu tiền tệ",
    "ConvertNow": "Đổi ngay",
    "RemainingAmount": "Hạn ngạch còn lại",
    "referenceTips": "Tùy theo giá mua thực tế",
    "ServiceUpgrade": 'Nâng cấp dịch vụ',
    "LastDays7": "Gần 7 ngày",
    "LastDays30": "Gần 30 ngày",
    "LastDays90": "Gần 90 ngày",
    'hide_coin': 'Ẩn ít hơn {0} BTC',
    "LockUpTips": "Lưu ý: Các tài sản {0}{1} chưa được kiểm toán còn lại đã được trả lại vào tài khoản ví của bạn.",
    "PromotionalMining": "Đang đào vàng",
    'GoldAssets': 'Tài sản vàng',
    'ModifyEmail': 'Sửa đổi email',
    'NewEmail': 'Thư mới',
    'NewEmailAddress': 'Nhập địa chỉ email mới',
    'VerifyNewEmail': 'Xác minh email mới',
    'EnterNewEmailCode': 'Nhập mã email mới',
    'VerifyOldEmail': 'Xác minh email cũ',
    'EnterOldEmailCode': 'Nhập mã email cũ',
    'VerifyLoginPassword': 'Mật khẩu đăng nhập',
    'EnterLoginPassword': 'Nhập mật khẩu đăng nhập',
    "loan": "Trung tâm cho vay",
    "loanText": "Cung cấp dịch vụ vay tiền tệ an toàn và đáng tin cậy, có thể phục vụ nhiều mục đích khác nhau với tính thanh khoản cao hơn mà không cần phải bán tài sản tiền điện tử hiện có.",
    "loanQuery": "Tư vấn vay tiền",
    'Stop_surplus_tips': "When the market price reaches {}, the profit stop Commission will be triggered, and the profit is expected to be {} after the transaction",
    'Stop_loss_tips': "The stop loss order will be triggered when the market price reaches {}, and the loss is expected to be {} after the transaction",
    'Stop_surplus_tips1': "When the market price reaches {}, the profit stop entrustment will be triggered, and the loss is expected to be {} after the transaction",
    'Stop_loss_tips1': "The stop loss order will be triggered when the market price reaches {}, and the profit is expected to be {} after the transaction",
    'Countdown': "Đếm ngược",
    'contract_After_adjustment': 'Sau khi điều chỉnh, {} USDT sẽ được chuyển từ tài khoản làm ký quỹ mới',
    'contract_subject_to': '(Lấy kim ngạch chuyển nhượng thực tế làm chuẩn)',
    'contract_the_used_margin': 'Sau khi điều chỉnh, ký quỹ đã sử dụng sẽ giảm {} USDT và phần giảm vẫn còn trong vị trí',
    'contract_transferred_to': 'Chuyển sang số dư tài khoản',
    'contract_After_adjustment_q': 'Sau khi điều chỉnh, ký quỹ chiếm giữ vị trí sẽ tăng {} USDT',
    'contract_the_used_margin_q': 'Sau khi điều chỉnh, ký quỹ chiếm giữ vị trí sẽ giảm {} USDT',
    'Please1': 'Vui lòng nhập giá kích hoạt chốt lời',
    'Please2': 'Vui lòng nhập giá ủy quyền chốt lời',
    'Please3': 'Vui lòng nhập giá kích hoạt dừng lỗ',
    'Please4': 'Vui lòng nhập giá ủy thác dừng lỗ',
    'Stop profit6': 'Giá khởi điểm phải cao hơn giá giao dịch mới nhất',
    'stop loss7': 'Giá kích hoạt dừng lỗ phải nhỏ hơn giá giao dịch mới nhất',
    'Please8': 'Vui lòng nhập số lượng',
    'Please9': 'Không có số lượng phẳng',
    'CountdownTime': 'Đếm ngược',
    'ModifyEmail': 'Sửa đổi email',
    'NewEmail': 'Thư mới',
    'NewEmailAddress': 'Nhập địa chỉ email mới',
    'VerifyNewEmail': 'Xác minh email mới',
    'EnterNewEmailCode': 'Nhập mã email mới',
    'VerifyOldEmail': 'Xác minh email cũ',
    'EnterOldEmailCode': 'Nhập mã email cũ',
    'VerifyLoginPassword': 'Mật khẩu đăng nhập',
    'EnterLoginPassword': 'Nhập mật khẩu đăng nhập',
    h1: 'Trở thành đối tác',
    h2: 'Bảng lợi nhuận',
    h3: 'Trở thành đối tác có thể nhận được',
    h4: 'Điều kiện nâng cấp',
    h5: 'Mua đối tác chính phải trả tiền',
    h6: 'Số dư hiện tại',
    h7: 'Đối tác Genesis',
    h8: 'Trả lại dịch vụ',
    h9: 'Nâng cấp ngay',
    h10: 'Đã là đẳng cấp cao nhất rồi.',
    h11: 'Tiền thưởng hoàn trả',
    h12: 'Trở lại bảng xếp hạng',
    h13: 'Số đội',
    h14: 'Hồ sơ hoàn trả',
    h15: 'Người được mời',
    h16: 'Giải quyết',
    h17: 'Chưa thanh toán',
    h18: 'Tận hưởng cấp dưới',
    h19: 'Cần thanh toán',
    h20: 'Nâng cấp',
    h21: 'Quy tắc hoàn trả',
    h22: 'Xếp hạng',
    h23: 'Người dùng',
    h24: 'Phần thưởng hoàn trả',
    h25: 'Cấp độ hiện tại có sẵn',
    h26: 'Tổng số tiền hoa hồng nhận được trở thành giá trị cộng thêm của USDT.',
    h27: 'Cần đẩy thẳng {} người dùng thông thường',
    h28: 'Đã đẩy {} người dùng thông thường hiệu quả',
    h29: 'Cần số lượng đội để đạt được {} người',
    h30: 'Số đội {} người',
    "loan": "Trung tâm cho vay",
    "loanText": "Cung cấp dịch vụ vay tiền tệ an toàn và đáng tin cậy, có thể phục vụ nhiều mục đích khác nhau với tính thanh khoản cao hơn mà không cần phải bán tài sản tiền điện tử hiện có.",
    "loanQuery": "Tư vấn vay tiền",
    'Stop profit10': 'Giá khởi điểm phải nhỏ hơn giá giao dịch mới nhất',
    'stop loss10': 'Giá khởi điểm dừng lỗ cần lớn hơn giá giao dịch mới nhất',
    Earn:"Earn",
    Simple:"Đơn giản & An toàn",
    Low1:"Rủi ro thấp và lợi nhuận ổn định",
    Low2:"Rủi ro thấp, lợi nhuận ổn định, có thể mua lại bất cứ lúc nào",
    CurrentAccount:"Tài khoản hiện tại",
    Stable:"Giá trị gia tăng ổn định, một công cụ mạnh mẽ để tăng trưởng của cải",
    EarnLocked:"Cố định Earn",
    Searchpopular:"Tìm kiếm các đồng tiền mã hóa phổ biến và bắt đầu tăng thu nhập",
    Annualized:"Tỷ lệ hoàn vốn hàng năm {}%, tài sản an toàn, lợi nhuận siêu cao",
    CoinsLC:"Tiền tệ",
    PurchaseLC:"Số tiền mua",
    PurchaseLC2:"(mua tối thiểu là {})",
    Maximum:"Tối đa",
    EstimatedInterest:"Lãi dự kiến",
    RuleLC:"Luật lệ",
    SubscriptionTime:"Thời gian",
    Interestaccrualtime:"Thời gian tính lãi",
    RevenueTime:"Thời điểm thu nhập",
    RedemptionCycle:"Chu kỳ chuộc lỗi",
    ReturnNow:"Trở về ngay",
    BuyLC:"Mua hàng",
    PurchaseSuccessful:"Mua thành công",
    DepositLC:"Trữ tiền",
    Yesterday:"Lợi nhuận hôm qua",
    TotalLC:"Tổng số tiền gửi",
    TotalReturn:"Tổng nhuận",
    DepositValuation:"Đánh giá giá trị trữ tiền",
    DepositDetail:"Tình hình trữ đồng tiền",
    RevenueDetails:"Chi tiết doanh thu",
    DepositType:"Loại tiền gửi",
    ReturnMethod:"Cách hoàn lãi",
    DepositAmount:"Lượng trữ tiền",
    APR:"APR",
    Expected:"Kỳ vọng",
    DepositCycle:"Chu kỳ gửi tiền xu",
    CreationTime:"Thời gian tạo",
    EarnProfit:"Thu lợi nhuận",
    DepositCost:"Chi phí đặt cọc",
    TotalRelease:"Tổng số tiền phát hành",
    ReleaseTime:"Giải phóng thời gian",
    Flexible:"Linh hoạt",
    Fixed:"Cố định",
    Freeze:"Đông cứng",
    Completed3:"Hoàn thành",
    Cancelled:"Đã hủy",
    DefaultSettlement:"Giải quyết mặc định",
    PurchaseAmount:"Số tiền mua (không giới hạn)",
    RetrieveDeposited:'Lấy lại tiền gửi',
    PreviewConversion:'Xem trước Chuyển đổi',
    ToEnsureThe:'Để đảm bảo an toàn cho tài khoản của bạn và đáp ứng yêu cầu hạn mức giao dịch cao hơn, chúng tôi cần thực hiện xác minh danh tính nâng cao.',
    ToVerification:'Nhấn nút bên dưới để liên hệ với dịch vụ khách hàng để xác minh.',
    ToContact:'Liên hệ ngay với dịch vụ khách hàng để xác minh.',
    "comm_nickname_rule1": "Không thể vượt quá 7 ký tự tiếng Trung hoặc 15 chữ cái tiếng Anh",
    "comm_nickname_rule2": "Không thể chứa dấu cách và ký tự đặc biệt",
    "otc_upload_picture3":"Tải ảnh dạng jpeg/jpg/png/bmp",
    "CertificationDifficulties":'Có vấn đề gì với việc xác thực không?',
    "CertifiedTipsP2P":'Tất cả các thương nhân trên đều được chứng nhận bởi nền tảng BitMatrix, vì vậy bạn có thể yên tâm mua hàng.',
    'OneClickBackhand':'Reverse',
    'EmptyReversal':'Đảo ngược rỗng',
    'MultipleReversalsEmpty':'Chuyển từ vị thế Long sang vị thế Short',
    'BackhandOpenLong':'Mở vị thế Long bằng tay trái',
    'BackhandOpenEmpty':'Chuyển từ Long sang Short',
    'DontShowMeAgain':' Không hiển thị lại',
    "TransferRequiresClosing":"Hợp đồng đang nắm giữ chưa thanh lý",
}