<template>
  <div class="trade container">
    <van-sticky>
<!--      <div class="headSelsec">-->
<!--        <div class="selectItem">{{$t("M.ios_币币")}}</div>-->
<!--        <div v-if="fiatShow" class="selectItem1" @click="gofiat">Fiat</div>-->
<!--      </div>-->
      <div class="topBar">
        <div class="titel">
          <img src="../../assets/img/trade/img2.png" @click="tradeListShow = true" alt="" srcset=""> {{symbol.tradeName}}<span
            v-bind:class="[rose>0 ? 'up':'down']">
            {{cutOutPointLength(rose,2)}}%
          </span>
        </div>
        <div class="marketBtnBox">

          <van-popover v-model="moreFlag" trigger="click" :actions="moreActions" placement="bottom-end" @select="onMoreSelect" overlay>
            <template #reference>
              <img src="../../assets/img/trade/img6.png" alt="" srcset="">
            </template>
          </van-popover>
          <router-link :to="{name:'TradeLine',params:{symbol: symbol.symbol,}}" style="padding-top:5px;"> <img src="../../assets/img/trade/img8.png"
              alt="" srcset=""></router-link>
          <img v-if="!isCollectionFlag" @click="collectionCoin" src="../../assets/img/trade/img7.png" alt="" srcset="">
          <img v-if="isCollectionFlag" @click="collectionCoin" src="../../assets/img/trade/star.png" alt="" srcset="">
        </div>
      </div>
    </van-sticky>

    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('M.MJRefreshHeaderIdleText')"
      :loosing-text="$t('M.MJRefreshHeaderPullingText')" :loading-text="$t('M.loading')">
      <div class="main">
        <!-- 左侧操作区 -->
        <div class="left">

          <!-- 切换按钮 -->
          <div class="buyTypeBtnBox">
            <div class="item" v-bind:class="[tradeType ? 'buyActive':'buy']" @click="changeTradeType(true)">
              {{$t('M.buy')}}
            </div>
            <div class="item item2" v-bind:class="[!tradeType ? 'sellActive':'sell']" @click="changeTradeType(false)">
              {{$t('M.sell')}}
            </div>
          </div>
          <div class="orderTypeSelect border" @click="entrustSelectShow = true">
            <span
              v-if="entrustSelectData.matchTypeList.length != 0">{{$t('M.'+ entrustSelectData.matchTypeList[entrustSelectActive].i18nName)}}</span>
            <img class="jiantou" src="../../assets/img/trade/img5.png" alt="" srcset="">
          </div>
          <!-- 价格 -->
          <div class="buyMoney border" v-if="entrustSelectActive ==0">
            <van-field v-model="buyMoney" @input="buyMoneyInput" :placeholder="$t('M.input_price')" type="number" style="padding-bottom:1px;" />
            <div class="box jiantou">
              <div class="itme itme1" @click="buyMoneyAdd(false)">-</div>
              <div class="itme">|</div>
              <div class="itme itme1" @click="buyMoneyAdd(true)">+</div>
            </div>
          </div>
          <!-- 时价 -->
          <div class="buyMoney border" v-if="entrustSelectActive == 1">
            <van-field  v-model="buyMoneyText" disabled style="padding-bottom:1px;" />
            <div class="box jiantou">
              <div class="itme itme1" style="color:#5c6573">-</div>
              <div class="itme" style="color:#5c6573">|</div>
              <div class="itme itme1" style="color:#5c6573">+</div>
            </div>
          </div>
          <!-- 换算 -->
          <div class="buyChange">≈{{entrustSelectActive ==0 ? ratebuyMoney:0}} USD</div>
          <!-- 购买数量 -->
          <div v-if="entrustSelectActive == 0" class="buyNumber border">
            <van-field v-model="buyNumber" @input="buyNumberInput" type="number" :placeholder="$t('M.quantity')" />
            <div class="coinnName jiantou">{{symbol.nowCoin}}</div>
          </div>

          <!-- 购买价格 -->
          <div class="buyNumber border" v-if="entrustSelectActive == 1">
            <van-field v-model="turnover" @input="turnoverInput" v-if="tradeType" type="number" :placeholder="$t('M.input_trading_volume')" />
            <van-field v-model="buyNumber" @input="buyNumberInput" v-if="!tradeType" type="number" :placeholder="$t('M.quantity')" />
            <div class="coinnName jiantou" v-if="tradeType">{{symbol.targetCoin}}</div>
            <div class="coinnName jiantou" v-if="!tradeType">{{symbol.nowCoin}}</div>
          </div>
          <!-- 拖拽条 -->
          <div class="buySlect">
            <div class="bar">
              <div class="btnBox">
                <div class="item">
                  <div class="itemChild" v-bind:class="[ barValue > 0? 'itemChildActive':'']"></div>
                </div>
                <div class="item">
                  <div class="itemChild" v-bind:class="[ barValue >= 25? 'itemChildActive':'']"></div>
                </div>
                <div class="item">
                  <div class="itemChild" v-bind:class="[ barValue >= 50? 'itemChildActive':'']"></div>
                </div>
                <div class="item">
                  <div class="itemChild" v-bind:class="[ barValue >= 75? 'itemChildActive':'']"></div>
                </div>
                <div class="item">
                  <div class="itemChild" v-bind:class="[ barValue >= 100? 'itemChildActive':'']"></div>
                </div>
              </div>
              <div class="vantBar">
                <van-slider v-model="barValue" active-color="#00b897" bar-height="1px" inactive-color="#c7ccd8"
                            @input="changeBarValue">
                  <template #button>
                    <div class="custombutton"></div>
                  </template>
                </van-slider>
              </div>
            </div>
            <div class="numBar">
              <div class="item" style="text-align:left">0%</div>
              <div class="item" style="text-align:center">50%</div>
              <div class="item" style="text-align:right">100%</div>
            </div>
          </div>
          <!-- 交易额 -->
          <div class="showBuyMoney" v-if="entrustSelectActive == 0">
            <p>{{$t('M.deal_sum')}}:</p>
            <p>{{betMoney}} {{symbol.targetCoin}}</p>
          </div>
          <!--交易按钮 -->
          <div class="buyBtnNow" v-bind:class="[tradeType ? 'buyBtnNowBuy':'buyBtnNowSell',entrustSelectActive==1 ? 'buyBtnNowM' : '']"
            @click="goBuy">
            <span v-if="tradeType && isLogin">{{$t('M.buy')}} {{symbol.nowCoin}}</span>
            <span v-if="!tradeType && isLogin">{{$t('M.sell')}} {{symbol.nowCoin}}</span>
            <span v-if="!isLogin">{{$t('M.log_in')}}</span>
          </div>
          <!-- 余额信息 -->
          <div class="showUserMoney border">
            <div class="item" v-if="tradeType"><span>{{!isLocked ? $t('M.usable') :  $t('M.locked_warehouse')}}</span><div><span>{{asset.buyCoin}} {{symbol.targetCoin}}</span></div></div>
            <div class="item" v-if="tradeType"><span>{{$t('M.On Hold')}}</span><div><span>{{asset.buyCoinFrozen}} {{symbol.targetCoin}}</span></div></div>
            <div class="item" v-if="!tradeType"><span>{{!isLocked ? $t('M.usable') :  $t('M.locked_warehouse')}}</span><div><span>{{asset.sellCoin}} {{symbol.nowCoin}}</span></div></div>
            <div class="item" v-if="!tradeType"><span>{{$t('M.On Hold')}}</span><div><span>{{asset.sellCoinFrozen}} {{symbol.nowCoin}}</span></div></div>
          </div>
        </div>
        <div class="right">

          <div class="markTit">
            <!-- 价格 -->
            <div>
              {{$t('M.price')}}({{symbol.targetCoin}})
            </div>
            <!-- 数量 -->
            <div>
              {{$t('M.quantity')}}({{symbol.nowCoin}})
            </div>
          </div>
          <div class="tradBox">
            <!-- 卖入柱状图 -->
            <div class="sellDataBox" v-if="selectDirectionActive.id != 1">
              <div class="item" v-for="(item,dix) in showSellsList" @click="selectMoney(item)">
                <div class="price">{{item.price}}</div>
                <div class="num">{{item.amount}}</div>
                <div class="color-sell-bg" v-if="item.price != '--'" :style="`width:${item.progressiveTotal/sellsProgressiveTotal*100}%`"></div>
              </div>
            </div>
            <!-- 价格 -->
            <p class="nowPrice" v-bind:class="[rose>0 ? 'up':'down']">{{latestDone}}{{symbol.targetCoin}}</p>
            <p class="changePrice">≈ {{ratelatestDone}}USD</p>
            <!-- 买入柱状图 -->
            <div class="buyDataBox" v-if="selectDirectionActive.id != 2">
              <div class="item" v-for="(item,dix) in showBuyssList" @click="selectMoney(item)">
                <div class="price">{{item.price}}</div>
                <div class="num">{{item.amount}}</div>
                <div class="color-sell-bg" v-if="item.price != '--'" :style="`width:${item.progressiveTotal/buysProgressiveTotal*100}%`">
                </div>
              </div>
            </div>
          </div>

          <div class="selectType">
            <van-popover v-model="selectDecimal" placement="top" trigger="click" :actions="disDepthDecimal" overlay
              style="width:100%;border-right: 1px solid #c7ccd8;">
              <div class="boxHome">
                <van-cell v-for="(item,idx) in disDepthDecimal" :key="idx" class="selectItem" :value="item.text" @click="selectDecimalEvn(item)"
                  v-bind:class="[item.id ==disDepthDecimalActive.id ? 'active':'' ]" />
              </div>
              <template #reference>
                <div class="item">{{disDepthDecimalActive.text}}<img class="jiantou" src="../../assets/img/trade/img5.png" alt="" srcset="" />
                </div>
              </template>
            </van-popover>

            <van-popover v-model="selectDirection" placement="top" trigger="click" overlay style="width:100%;">
              <div class="boxHome">
                <van-cell v-for="(item,idx) in selectDirectionArr" :key="idx" class="selectItem" :value="$t('M.'+item.text)"
                  @click="selectDirectionEvn(item)" v-bind:class="[item.id ==selectDirectionActive.id ? 'active':'' ]" />
              </div>
              <template #reference>
                <div class="item">{{$t('M.'+selectDirectionActive.text)}}<img class="jiantou" src="../../assets/img/trade/img5.png" alt=""
                    srcset="" />
                </div>
              </template>
            </van-popover>
          </div>

        </div>
      </div>
      <!-- 底部 list -->
      <TradeList ref="tradeList" :disTradeList="disTradeList" :symbol="symbol" />
    </van-pull-refresh>

    <TradeLeftPopup :tradeListShow="tradeListShow" @isCollection="isCollection" @closeTradeList="closeTradeList" @changeSymbol="changeSymbol" />
    <!-- 委托选择弹窗 -->
    <van-action-sheet class="entrustSelectBox" v-model="entrustSelectShow" :cancel-text="$t('M.Cancel')" close-on-click-action>
      <div class="content">
        <div class="item" v-for="(item,idx) in entrustSelectData.matchTypeList" :idx="idx" @click="selectEntrust(idx)"
          v-bind:class=" [idx ==entrustSelectActive ? 'item1' : '']">
          {{$t('M.'+ item.i18nName)}}
        </div>
      </div>
    </van-action-sheet>

    <popup-password :show="showPassword" :onlyEnablePassword="showPassword" @onClose="onClosePassword" @onSubmit="onSubmitPassword" />

  </div>
</template>

<script>
import TradeList from "./tradeComponet/tradeList.vue";
import TradeLeftPopup from "./tradeComponet/tradeLeftPopup.vue";
import popupPassword from '@/components/popupPassword';
import {Toast} from 'vant';
import {mapGetters} from 'vuex'

export default {
  components: { TradeList, TradeLeftPopup, popupPassword },
  data() {
    return {
      showPassword: false,
      rose: "",//涨跌幅
      betMoney: "",//交易额
      buyNumber: '', //购买数量
      buyMoney: 0, //购买价格
      buyMoneyText: this.$t('M.Market_transaction'),
      tradeType: true, //类型, true 买入 ,false卖出
      barValue: 0, //滑动数值
      tradeList: [],  //交易对信息
      tradeListShow: false,  //交易对列表是否显示
      turnover: '',//交易额
      symbol: {
        tradeName: '',  //交易对显示名称
        targetCoin: {}, //目标币种 "btn/USDT" 后面的
      }, //当前交易对信息
      isCollectionFlag: false,
      entrustSelectData: {//委托选择框数据
        matchTypeList: [],//选项
      },
      entrustSelectShow: false,
      entrustSelectActive: 0,
      asset: {
        buyCoin: 0,
        buyCoinFrozen: 0,
        sellCoin: 0,
        sellCoinFrozen: 0,
      },//钱包信息
      /* 盘口 */
      dishDefault: {}, //基本数据
      disDepthDecimal: [], //小数位
      selectDirectionArr: [
        { text: 'defaults', id: 0 },//默认
        { text: 'bug_order', id: 1 },//买盘
        { text: 'sell_order', id: 2 },//卖盘
      ],
      selectDirectionActive: { text: 'defaults', id: 0 },
      //买盘,卖盘
      disDepthResult: {
        buys: {
          list: []
        },
        sells: {
          list: []
        },
        latestDone: {
          price: 0,
          rose: 0
        }
      }, // 买卖数据
      allBuys: [],  //所有的盘口买入
      allSells: [], //所有的盘口卖出
      buys: [],  //显示的盘口买入
      sells: [], //显示的盘口卖出
      latestDone: 0,//盘口最后的价格
      disTradeList: [], // 底部挂单买卖数据
      selectDecimal: false, //选择小数位的选择框
      selectDecimalCss: {},//样式
      disDepthDecimalActive: {}, //小数位选择值
      socket: null,//socket对象
      pako: require("pako"),
      selectDirection: false,
      dishSymbol: "", //盘口当前的值
      sellsProgressiveTotal: "",//盘口买入 横柱的分母
      buysProgressiveTotal: "",//盘口买入 横柱的分母
      minBetAdd: 0,//最小加价幅度
      countExchange: 0,//最小加价幅度和最小下注数量
      getTradeAssetTime: null,
      listen: null,
      isLoading: false,
      rate: 1,
      ratebuyMoney: 0,
      ratelatestDone: 0,
      fiatShow: false,
      fatPayUrl: '',
      /* 三个点,更多 */
      moreFlag: false,
      moreActions: [
        { text: this.$t('M.Charge_money'), icon: require('@/assets/img/public/smallchong.png'), flag: 1 },
        { text: this.$t('M.Transfer'), icon: require('@/assets/img/public/smalltrade.png'), flag: 2 },
      ],
      showSellsList: [],
      showBuyssList: [],
      isLocked:false,
    }
  },
  computed: {
    ...mapGetters([
      'isLogin', 'user', "lang"
    ]),
  },
  watch: {
    buyMoney: function (newQuestion, oldQuestion) {
      this.ratebuyMoney = this.buyMoney * this.rate
      this.ratebuyMoney = this.cutOutPointLength(this.ratebuyMoney, 8)
    },
    latestDone: function (newQuestion, oldQuestion) {
      this.ratelatestDone = this.latestDone * this.rate
      this.ratelatestDone = this.cutOutPointLength(this.ratelatestDone, 8)
    },
    sells: {
      handler(newVal) {
        this.showSellsList = newVal
      },
      deep: true
    },
    buys: {
      handler(newVal) {
        this.showBuyssList = newVal
      },
      deep: true
    },
  },
  created() {

  },
  mounted() {
    this.queryEntrustSelectBox()
    const params = {
      partnerId: "472437831826935808"
    }
    // /* fiat 是否显示 */
    // this.$api.queryFiatShow(params).then((response) => {
    //   if (response.data.FiatShow != "false") {
    //     this.fiatShow = true
    //     // if (this.isLogin) {
    //     //   this.$api.queryfatPaySign(params).then((response1) => {
    //     //     this.fatPayUrl = response1.data.fatPayUrl
    //     //   })
    //     // }
    //   }
    // })

    if (this.$route.params.buyType) {
      this.tradeType = this.$route.params.buyType == 1 ? true : false;
    }
    this.startFakeDataGeneration()
  },
  methods: {
    async getIsLocked() {
      if (this.isLogin) {
        let userLock = await this.$api.checkCoinAndUserLock({tradeId: this.symbol.partnerTradeId})
        this.isLocked = userLock.data
      }
    },
    startFakeDataGeneration() {
      this.dataUpdateTimer = setInterval(() => {
        if (this.sells.length == 0 || this.buys.length == 0) return

        let jiaData1 = this.sells
        for (let i = 0; i < jiaData1.length; i++) {
          if (i != 0 && i != jiaData1.length - 1) {
            let a = jiaData1[i - 1].progressiveTotal
            let b = jiaData1[i + 1].progressiveTotal
            let c = jiaData1[i].progressiveTotal
            jiaData1[i].progressiveTotal = this.getRandomAround(a, b, c)
          }
        }
        this.showSellsList = jiaData1

        let jiaData2 = this.buys
        for (let i = 0; i < jiaData2.length; i++) {
          if (i != 0 && i != jiaData2.length - 1) {
            let a = jiaData2[i - 1].progressiveTotal
            let b = jiaData2[i + 1].progressiveTotal
            let c = jiaData2[i].progressiveTotal
            jiaData2[i].progressiveTotal = this.getRandomAround(a, b, c)
          }
        }
        this.showBuyssList = jiaData2
      }, 1000)
    },
    getRandomAround(a, b, c) {
      const min = Math.min(a, b);
      const max = Math.max(a, b);
      // 确保c在合法范围内
      c = Math.max(min, Math.min(c, max));

      // 处理a等于b的情况，直接返回原值
      if (min === max) return c;

      // 计算当前值到边界的距离
      const distanceToMin = c - min;
      const distanceToMax = max - c;

      // 动态计算波动范围：取较小距离的10%，且至少为总范围的1%
      let delta;
      if (distanceToMin === 0 || distanceToMax === 0) {
        delta = (max - min) * 0.01; // 边界时用总范围的1%
      } else {
        delta = Math.min(distanceToMin, distanceToMax) * 0.2; // 正常波动范围
      }

      // 计算实际波动区间
      const lower = c - delta;
      const upper = c + delta;
      const adjustedLower = Math.max(min, lower);
      const adjustedUpper = Math.min(max, upper);
      const adjustedUpper2 = adjustedLower + Math.random() * (adjustedUpper - adjustedLower);

      // 生成随机数
      return adjustedUpper2.toFixed(4)
    },
    /* 选择更多选项 */
    onMoreSelect(data) {
      let obj = null;
      this.entrustSelectData.coinList.find((item) => {
        if (item.name == this.symbol.targetCoin) obj = item;
      })
      if (data.flag == 1) {
        this.$router.push({ path: '/chargingAddress', query: { coinName: obj.name, coinId: obj.id, type: 'recharge' } })
      } else {
        this.$router.push({ path: '/transfer', query: { coinName: obj.name, coinId: obj.id, type: 'transfer' } })
      }
    },
    /* 改变交易对 */
    changeSymbol(data) {
      this.symbol = data;
      this.symbol.targetCoin = this.symbol.tradeName.split('/')[1];
      this.symbol.nowCoin = this.symbol.tradeName.split('/')[0]
      this.barValue = 0;
      this.changeBarValue()
      /* 是否收藏 */
      this.isCollection()
      /* 用户余额 */
      this.getTradeAsset()
      this.getTradeAssetTime = setInterval(() => {
        this.getTradeAsset()
      }, 8000);
      /* 盘口数据 */
      this.getPankouChengjiao()
      /* 汇率 */
      this.tradeAreaPrice()
      /*是否已锁仓*/
      this.getIsLocked()
    },
    /* 点击fiat */
    gofiat() {
      /* 是否登录 */
      if (this.isLogin) {
        const params = {
          partnerId: "472437831826935808"
        }
        // /* 是否关了 */
        // this.$api.queryFiatShow(params).then((response) => {
        //   if (response.data.FiatShow != "false") {
        //     this.$router.push({
        //       path: `/IframePage`,
        //       // query: { url: this.fatPayUrl, }
        //     });
        //   } else {
        //     this.$notify(this.$t('M.ServiceUpgrade'))
        //   }
        // })
      } else {
        this.$router.push("login")
      }
    },
    /* 下拉刷新 */
    onRefresh() {
      this.initDishData()
      this.getPankouChengjiao()
    },
    /* 改变购买类型 */
    changeTradeType(data) {
      this.tradeType = data
      this.initDishData()
    },
    /* 初始化盘口的下单数据 */
    initDishData() {
      this.buyNumber = '';
      this.turnover = '';
      this.betMoney = '';
      this.barValue = 0;
      if (this.tradeType) {
        if (this.sells && this.sells.length > 0 && this.sells[this.sells.length - 1] && this.sells[this.sells.length - 1].price != '--') {
          this.buyMoney = this.sells[this.sells.length - 1].price
        } else {
          this.buyMoney = ''
        }
      } else {
        if (this.buys && this.buys.length && this.buys[0].price != '--') {
          this.buyMoney = this.buys[0].price
        } else {
          this.buyMoney = ''
        }
      }
    },
    /* 点击购买-判断是否需要密码 */
    async goBuy() {
      if (!this.isLogin) {
        this.$router.push("login")
        return
      }
      if (!this.tradeType) {
        let userLock = await this.$api.checkCoinAndUserLock({tradeId: this.symbol.partnerTradeId})
        if(userLock.data){
          this.$notify(this.$t("M.locked_warehouse"))
          return
        }
      }
      /* 判断是否有足够的钱 */
      /* 限价单 */
      if (this.entrustSelectActive == 0) {
        /* 价格为空 */
        if (this.buyMoney == '') {
          this.$notify(this.$t('M.account_failure_00107'))
          return
        }
        /* 数量为空 */
        if (this.buyNumber == '') {
          this.$notify(this.$t('M.account_failure_00103'))
          return
        }
        /* 买入-判断余额 */
        if (this.tradeType) {
          if (this.betMoney - 0 > this.asset.buyCoin - 0) {
            this.$notify(this.$t('M.user-fail-assetnotenough-error'))
            return
          }
        } else {
          /* 卖出-是否有足够的数量 */
          if (this.buyNumber - 0 > this.asset.sellCoin - 0) {
            this.$notify(this.$t('M.user-fail-assetnotenough-error'))
            return
          }
        }
      } else {
        /* 买入-判断余额 */
        if (this.tradeType) {
          if (this.turnover == '' || this.turnover == 0) {
            this.$notify(this.$t('M.account_failure_00103'))
            return
          }
          if (Number(this.turnover) > Number(this.asset.buyCoin)) {
            this.$notify(this.$t('M.user-fail-assetnotenough-error'))
            return
          }
        } else {
          if (this.buyNumber == '' || this.buyNumber == 0) {
            this.$notify(this.$t('M.account_failure_00103'))
            return
          }
          if (this.buyNumber - 0 > this.asset.sellCoin - 0) {
            this.$notify(this.$t('M.user-fail-assetnotenough-error'))
            return
          }
        }
      }


      this.$api.isInputPayPasswd().then((response) => {
        if (!response.meta.success) return
        if (response.data) {
          this.showPassword = true;
          return
        }
        this.saveEntrust()
      })
    },
    /* 支付密码回调 */
    onSubmitPassword(payPassword) {
      this.saveEntrust(payPassword)
      this.onClosePassword()
    },
    /* 下单 */
    saveEntrust(payPwd) {
      let sendData = {
        matchType: this.entrustSelectActive == 0 ? "LIMIT" : "MARKET",
        price: this.entrustSelectActive == 0 ? this.buyMoney : '',
        source: "ios",
        tradeId: this.symbol.partnerTradeId,
        partnerId: "472437831826935808",
        partnerNo: "1000010001",
        type: this.tradeType ? "BUY" : "SELL",
        userId: this.user.id
      }
      if (this.entrustSelectActive == 0) {
        sendData.count = this.buyNumber
      } else {
        if (this.tradeType) {
          sendData.count = this.turnover
        } else {
          sendData.count = this.buyNumber
        }
      }
      if (payPwd) sendData.payPwd = payPwd;
      this.$api.saveEntrust(sendData).then((response) => {
        if (!response.meta.success) return
        this.$notify(response.meta.message)
        /* 重置 */
        this.initDishData()

        this.getPankouChengjiao()
      })
    },
    /* 关闭了支付 */
    onClosePassword() {
      this.showPassword = false
    },
    /* 下单金额变化 */
    buyMoneyInput(data) {
      const wei = data.toString().split(".");
      if (wei.length != 1 && wei[1].length >= this.dishDefault.priceExchange) {
        this.buyMoney = this.cutOutPointLength(this.buyMoney, this.dishDefault.priceExchange)
      }
      if (wei[0].length > 8) {
        wei[0] = wei[0].substr(0, 8)
        this.buyMoney = this.cutOutPointLength(wei.join('.'), this.dishDefault.priceExchange)
      }
      this.betMoney = this.cutOutPointLength(this.buyNumber * this.buyMoney, this.dishDefault.priceExchange);
    },
    /* 购买数量变化 */
    buyNumberInput() {
      const wei = this.buyNumber.toString().split(".");

      if (wei.length != 1 && wei[1].length >= this.dishDefault.countExchange) {
        this.buyNumber = this.cutOutPointLength(this.buyNumber, this.dishDefault.countExchange)
      }
      if (wei[0].length > 10) {
        wei[0] = wei[0].substr(0, 10)
        this.buyNumber = this.cutOutPointLength(wei.join('.'), this.dishDefault.countExchange)
      }
      this.betMoney = this.cutOutPointLength(this.buyNumber * this.buyMoney, this.dishDefault.priceExchange);
    },
    /* 购买额度变化 */
    turnoverInput() {
      const wei = this.turnover.toString().split(".");
      if (wei.length != 1 && wei[1].length >= this.dishDefault.countExchange) {
        this.turnover = this.cutOutPointLength(this.turnover, this.dishDefault.countExchange)
      }
      if (wei[0].length > 8) {
        wei[0] = wei[0].substr(0, 8)
        this.turnover = this.cutOutPointLength(wei.join('.'), this.dishDefault.countExchange)
      }
    },
    /* 滑动条改变 */
    changeBarValue() {
      if (this.entrustSelectActive == 0) {
        if (this.tradeType) {
          this.betMoney = this.cutOutPointLength(this.barValue / 100 * this.asset.buyCoin, this.dishDefault.priceExchange);
          this.buyNumber = this.cutOutPointLength(this.betMoney / this.buyMoney, this.dishDefault.countExchange);
        } else {
          this.buyNumber = this.cutOutPointLength(this.barValue / 100 * this.asset.sellCoin, this.dishDefault.countExchange);
        }
        if (this.buyNumber == 0) this.buyNumber = '';
      } else {
        if (this.tradeType) {
          this.turnover = this.cutOutPointLength(this.barValue / 100 * this.asset.buyCoin, this.dishDefault.countExchange);
        } else {
          this.buyNumber = this.cutOutPointLength(this.barValue / 100 * this.asset.sellCoin, this.dishDefault.countExchange);
        }
        if (this.buyNumber == 0) this.buyNumber = '';
      }

    },
    /* 选择小数点 */
    selectDecimalEvn(item) {
      this.selectDecimal = false;
      if (item.id == this.disDepthDecimalActive.id) return;

      /* 先取消掉 */
      this.socket.send(JSON.stringify({
        tag: 'CANCEL',
        content: this.dishSymbol,
        id: 'ios'
      }))
      /* 检测数据 */
      this.socket.send(JSON.stringify({
        tag: 'REQ',
        content: `market.${this.dishDefault.id}.depth.step1.${item.id}`,
        id: 'ios'
      }))
      /* 订阅 */
      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: `market.${this.dishDefault.id}.depth.step1.${item.id}`,
        id: 'ios'
      }))
      /* 记录当前盘口的订阅值 */
      this.dishSymbol = `market.${this.dishDefault.id}.depth.step1.${item.id}`;

      this.disDepthDecimalActive = item;
    },
    /* 选择看的盘 */
    selectDirectionEvn(item) {
      this.selectDirectionActive = item;
      /* 需要截取多少位 */
      const sliceNum = this.selectDirectionActive.id == 0 ? 7 : 14;
      /* 卖盘 */
      this.sells = this.allSells.slice(0, sliceNum).reverse()
      this.sellsProgressiveTotal = this.sells[0].progressiveTotal
      while (this.sells.length < sliceNum) {
        let addType = '';
        this.selectDirectionActive.id == 0 ? addType = "unshift" : addType = "push";
        this.sells[addType]({
          amount: "--",
          index: "--",
          price: "--",
          progressiveTotal: "--",
          total: "--",
        })
      }

      /* 买盘 */
      this.buys = this.allBuys.slice(0, sliceNum)
      this.buysProgressiveTotal = this.buys[this.buys.length - 1].progressiveTotal
      while (this.buys.length < sliceNum) {
        this.buys.push({
          amount: "--",
          index: "--",
          price: "--",
          progressiveTotal: "--",
          total: "--",
        })
      }

      this.selectDirection = false;
    },
    /* 选择价格 */
    selectMoney(item) {
      if (item.price != '--') {
        this.buyMoney = Number(item.price);
      }
    },
    /* 获取汇率 */
    tradeAreaPrice() {
      this.$api.tradeAreaPrice({ partnerId: "472437831826935808", shortName: "USD" }).then((response) => {
        for (let index = 0; index < response.data.usd.length; index++) {
          const element = response.data.usd[index];
          if (element.coinName == this.symbol.targetCoin) {
            this.rate = element.coinPrice - 0
          }
        }
        this.buyMoney = this.buyMoney - 0;
        this.latestDone = this.latestDone - 0;
      })
    },
    /* 初始化socket */
    initSocket() {
      const BrowserWebSocket = window.WebSocket || window.MozWebSocket
      this.socket = new BrowserWebSocket(`wss://${this.$apiUrl}/v1/websocket/market`)
      this.socket.onopen = this.onopenWS
      this.socket.onmessage = this.onmessageWS
      this.socket.onerror = this.onerrorWS
      this.socket.onclose = this.oncloseWS
    },
    /* 打开时 */
    onopenWS() {
      this.sendInitSymbol()
    },
    /* 订阅的socket */
    sendInitSymbol() {
      this.tradeSymbol = `market.${this.dishDefault.id}.trade`
      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: this.tradeSymbol,
        id: 'ios'
      }))
      /* 记录当前盘口的订阅值 */
      this.dishSymbol = `market.${this.dishDefault.id}.depth.step1`;
      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: this.dishSymbol,
        id: 'ios'
      }))
    },

    /* 收到消息 */
    onmessageWS(data) {
      let blob = data.data;
      let reader = new FileReader();
      reader.readAsBinaryString(blob);
      reader.onload = (res) => {
        let text = this.pako.inflate(res.target.result, { to: "string" });
        let msg = JSON.parse(text);
        if (msg.type == 1) {
          // console.log(msg.data)
          /* 最近成交 */
          if (msg.tradeType == 'TRADE') {
            this.disTradeList = msg.data;
            this.latestDone = this.disTradeList[0].price
          }
          /* 卖盘 */
          if (msg.tradeType == "DEPTH") {
            /* 需要截取多少位 */
            const sliceNum = this.selectDirectionActive.id == 0 ? 7 : 14;
            /* 卖盘 */
            if (msg.data.sells.list) {
              this.allSells = msg.data.sells.list;
            } else {
              this.allSells = [];
            }
            this.sells = this.allSells.slice(0, sliceNum).reverse()
            this.sellsProgressiveTotal = this.sells[0] ? this.sells[0].progressiveTotal : 0
            while (this.sells.length < sliceNum) {
              let addType = '';
              this.selectDirectionActive.id == 0 ? addType = "unshift" : addType = "push";
              this.sells[addType]({
                amount: "--",
                index: "--",
                price: "--",
                progressiveTotal: "--",
                total: "--",
              })
            }
            if (msg.data.buys.list) {
              this.allBuys = msg.data.buys.list;
            } else {
              this.allBuys = [];
            }
            /* 买盘 */
            this.buys = this.allBuys.slice(0, sliceNum)
            this.buysProgressiveTotal = this.buys[this.buys.length - 1] ? this.buys[this.buys.length - 1].progressiveTotal : 0
            while (this.buys.length < sliceNum) {
              this.buys.push({
                amount: "--",
                index: "--",
                price: "--",
                progressiveTotal: "--",
                total: "--",
              })
            }
            this.latestDone = msg.data.latestDone.price;
            this.rose = msg.data.latestDone.rose;
          }
        }
      };
    },
    /* 出现错误 */
    onerrorWS() {
      this.socket.close()
      if (this.socket.readyState !== 3) {
        this.socket = null
        this.initSocket()
      }
    },
    /* 关闭时 */
    oncloseWS() {
      if (this.socket.readyState !== 2 && this.closeType !== 1) {
        this.socket = null
        this.initSocket()
      }
    },
    /* 关闭弹出层 */
    closeTradeList() {
      this.tradeListShow = false;
    },
    /* 价格加减 */
    buyMoneyAdd(flag) {
      const buyMoney = Number(this.buyMoney);
      if (flag) {
        this.buyMoney = this.cutOutPointLength(this.accAdd(buyMoney, this.minBetAdd), this.dishDefault.priceExchange)
      } else {
        this.buyMoney = this.cutOutPointLength(this.Subtr(buyMoney, this.minBetAdd), this.dishDefault.priceExchange)
      }
    },
    accAdd(arg1, arg2) {
      var r1, r2, m, c;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      c = Math.abs(r1 - r2);
      m = Math.pow(10, Math.max(r1, r2));
      if (c > 0) {
        var cm = Math.pow(10, c);
        if (r1 > r2) {
          arg1 = Number(arg1.toString().replace(".", ""));
          arg2 = Number(arg2.toString().replace(".", "")) * cm;
        } else {
          arg1 = Number(arg1.toString().replace(".", "")) * cm;
          arg2 = Number(arg2.toString().replace(".", ""));
        }
      } else {
        arg1 = Number(arg1.toString().replace(".", ""));
        arg2 = Number(arg2.toString().replace(".", ""));
      }
      return (arg1 + arg2) / m;
    },
    Subtr(arg1, arg2) {
      var r1, r2, m, n;
      try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
      try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
      m = Math.pow(10, Math.max(r1, r2));
      n = (r1 >= r2) ? r1 : r2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    /* 盘口数据 */
    getPankouChengjiao() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      this.$api.getPankouChengjiao({
        partnerId: '472437831826935808',
        partnerNo: '1000010001',
        tradeName: this.symbol.symbol,
        depthStep: "APP"
      }).then((res) => {
        /* 盘口基本信息 */
        this.dishDefault = res.data.defaultBBTickerResult.content[0];
        /* 计算最小加价 */
        let num = ['0.'];
        for (let index = 0; index < this.dishDefault.priceExchange - 1; index++) {
          num.push(0)
        }
        num.push(1)
        this.minBetAdd = Number(num.join('') - 0)


        /* 小数点 */
        this.disDepthDecimal = res.data.depthDecimal
        /* 买卖价格 */
        this.latestDone = res.data.depthResult.latestDone.price;
        /* 最近成交 */
        this.disTradeList = res.data.tradeList;
        /* 默认交易价格 */
        this.buyMoney = Number(res.data.depthResult.latestDone.price);
        /* 显示文本 */
        this.disDepthDecimal.forEach(item => {
          if (this.lang == "zh_TW") {
            item.text = item.chineseName
          } else {
            item.text = item.englishName
          }
        })
        /* 默认小数位 */
        this.disDepthDecimalActive = this.disDepthDecimal.find(item => item.default == true)

        /* 涨跌幅 */
        this.rose = res.data.depthResult.latestDone.rose;

        /* 盘口显示的数据 */

        /* 需要截取多少位 */
        const sliceNum = this.selectDirectionActive.id == 0 ? 7 : 14;
        /* 卖盘 */
        this.sells = []
        this.buys = []
        try {
          if (res.data.depthResult.sells && res.data.depthResult.sells.list.length != 0) {
            this.allSells = res.data.depthResult.sells.list;
          } else {
            this.allSells = [];
          }

          this.sells = this.allSells.slice(0, sliceNum).reverse()
          this.sellsProgressiveTotal = this.sells[0] ? this.sells[0].progressiveTotal : 0

          while (this.sells.length < sliceNum) {
            let addType = '';
            this.selectDirectionActive.id == 0 ? addType = "unshift" : addType = "push";
            this.sells[addType]({
              amount: "--",
              index: "--",
              price: "--",
              progressiveTotal: "--",
              total: "--",
            })
          }

          /* 买盘 */
          if (res.data.depthResult.buys && res.data.depthResult.buys.list.length != 0) {
            this.allBuys = res.data.depthResult.buys.list;
          } else {
            this.allBuys = [];
          }

          this.buys = this.allBuys.slice(0, sliceNum)
          this.buysProgressiveTotal = this.buys[this.buys.length - 1] ? this.buys[this.buys.length - 1].progressiveTotal : 0
          while (this.buys.length < sliceNum) {
            this.buys.push({
              amount: "--",
              index: "--",
              price: "--",
              progressiveTotal: "--",
              total: "--",
            })
          }
        } catch (error) {
          console.log(error)
        }
        Toast.clear();
        this.isLoading = false
        /* 初始化socket */
        if (!this.socket) {
          this.initSocket()
        } else {
          this.changeSocket()
          this.$refs.tradeList.getUpdata()
        }
      })
    },
    /* 改变socket订阅-先取消订阅 */
    changeSocket() {
      this.socket.send(JSON.stringify({
        tag: 'CANCEL',
        content: this.tradeSymbol,
        id: 'ios'
      }))
      this.socket.send(JSON.stringify({
        tag: 'CANCEL',
        content: this.dishSymbol,
        id: 'ios'
      }))
      /* 订阅新的 */
      this.sendInitSymbol()
    },
    /* 当前交易对是否收藏 */
    isCollection() {
      if (this.isLogin) {
        this.$api.isCollection({
          partnerId: '472437831826935808',
          partnerNo: '1000010001',
          tradeId: this.symbol.symbol
        }).then((res) => {
          this.isCollectionFlag = res.data;
        })
      }
    },
    /* 当前交易对 用户余额信息 */
    getTradeAsset() {
      if (this.isLogin) {
        this.$api.getTradeAsset({
          partnerId: '472437831826935808',
          partnerNo: '1000010001',
          tradeId: this.symbol.partnerTradeId
        }).then((res) => {
          this.asset = res.data
        })
      }
    },
    /* 收藏或取消 */
    collectionCoin() {
      if (!this.isLogin) {
        this.$router.push("login")
        return
      }
      Toast.loading({
        message: this.$t('M.loading'),
        forbidClick: true,
        duration: 0,
      });
      this.$api.collection({
        partnerId: '472437831826935808',
        partnerNo: '1000010001',
        tradeId: this.symbol.symbol
      }).then((data) => {
        if (data.data.status == "cancel") {
          this.$notify(this.$t("M.user_collection_cencel_success"))
        } else {
          this.$notify(this.$t("M.user_collection_insert_success"))
        }
        this.isCollection()
        Toast.clear();
      })
    },
    /* 查询委托选择框 */
    queryEntrustSelectBox() {
      this.$api.queryEntrustSelectBox({
        partnerId: '472437831826935808',
        partnerNo: '1000010001'
      }).then((res) => {
        this.entrustSelectData = res.data;
      })
    },
    /* 选择委托类型*/
    selectEntrust(idx) {
      this.entrustSelectActive = idx;
      this.entrustSelectShow = false;
      this.initDishData()
    },
    /* 选择小数位显示 */
    onSelect() {
      this.selectDecimal = true;
    },
  },
  /* 销毁 */
  beforeDestroy() {
    var qc = setInterval(function () { }, 1)
    for (let i = 0; i < qc; i++) {
      clearInterval(i)
    }
    // 添加检查
    if (this.socket) {
      this.socket.close()
    }
    // 清除定时器
    if (this.dataUpdateTimer) {
      clearInterval(this.dataUpdateTimer)
    }
    if (this.getTradeAssetTime) {
      clearInterval(this.getTradeAssetTime)
    }
  },

}
</script>

<style lang="scss" scoped>
.trade {
  background: #f8f8fb;
  font-size: 12px;
  .headSelsec {
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ebebf0;
    position: relative;
    background-color: #fff;
    .selectItem {
      width: 85px;
      height: 30px;
      background: #ED0071;
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
    }
    .selectItem1 {
      width: 85px;
      height: 30px;
      background: #ffffff;
      border-radius: 2px;
      font-size: 14px;
      color: #a6abb5;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      border: 1px solid #a6abb5;
      margin-left: 15px;
    }
    .userCenter {
      position: absolute;
      left: 20px;
      width: 30px;
      height: 30px;
    }
  }
  .topBar {
    padding: 15px 15px 0 15px;
    background-color: #fff;
    display: flex;
    .titel {
      flex: 1;
      display: flex;
      height: 23px;
      font-size: 16px;
      font-weight: 600;
      color: #191b27;
      line-height: 23px;
      align-items: center;
      white-space: nowrap;
      img {
        width: 17px;
        height: 15px;
        margin-right: 10px;
      }
      .up {
        color: #0AA869;
        font-size: 12px;
        margin-left: 15px;
      }
      .down {
        color: #ED0071;
        font-size: 12px;
        margin-left: 15px;
      }
    }
    .marketBtnBox {
      flex: 1;
      display: flex;
      height: 23px;
      align-items: center;
      flex-direction: row-reverse;
      padding-right: 5px;
      img {
        width: 16px;
        height: 16px;
        margin-left: 25px;
      }
    }
  }
  .main {
    padding: 15px;
    padding-top: 0;
    background-color: #fff;
    display: flex;
    .left {
      flex: 1;
      padding-right: 7.5px;
      .titel {
        display: flex;
        height: 23px;
        font-size: 16px;
        font-weight: 600;
        color: #191b27;
        line-height: 23px;
        align-items: center;
        img {
          width: 17px;
          height: 15px;
          margin-right: 10px;
        }
        .up {
          color: #0AA869;
          font-size: 12px;
          margin-left: 15px;
        }
        .down {
          color: #ED0071;
          font-size: 12px;
          margin-left: 15px;
        }
      }
      .buyTypeBtnBox {
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        .item {
          width: 82px;
          height: 35px;
          text-align: center;
          line-height: 35px;
          font-size: 14px;
          background-size: 100% 100%;
        }

        .buyActive {
          color: #0AA869;
          background-image: url('../../assets/img/trade/buyActive.png');
        }
        .buy {
          color: #5c6573;
          background-image: url('../../assets/img/trade/buy.png');
        }
        .sellActive {
          color: #ED0071;
          background-image: url('../../assets/img/trade/sellActive.png');
        }
        .sell {
          color: #5c6573;
          background-image: url('../../assets/img/trade/sell.png');
        }
      }
      .orderTypeSelect {
        width: 165px;
        height: 30px;
        margin-top: 10px;
        position: relative;
        padding: 0px 10px;
        font-size: 13px;
        color: #191b27;
        line-height: 30px;
      }
      .buyMoney {
        width: 165px;
        // height: 40px;
        margin-top: 10px;
        position: relative;
        .box {
          display: flex;
          width: 61px;
          height: 40px;
          right: 0;
          .itme {
            height: 40px;
            line-height: 38px;
            text-align: center;
          }
          .itme1 {
            flex: 1;
          }
        }
      }
      .buyChange {
        margin-top: 1px;
        font-size: 12px;
        color: #9299a9;
        line-height: 17px;
      }
      .buyNumber {
        margin-top: 10px;
        position: relative;
        .coinnName {
          width: 25px;
          height: 18px;
          font-size: 13px;
          color: #9299a9;
          line-height: 18px;
        }
      }
      .buySlect {
        width: 100%;
        margin-top: 17px;
        position: relative;
        .bar {
          padding: 0 2.5px;
          .vantBar {
            padding: 0px 8px;
            .custombutton {
              width: 17px;
              height: 17px;
              background-image: url('../../assets/img/trade/img1.png');
              background-size: 100% 100%;
            }
          }
          .btnBox {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0 2.5px;
            top: -8px;
            .item {
              width: 17px;
              height: 17px;
              padding: 4.5px;
              .itemChild {
                width: 8px;
                height: 8px;
                background: #c7ccd8;
                border-radius: 50%;
                margin: 0 auto;
                font-size: 11px;
                color: #5c6573;
                line-height: 15px;
              }
              .itemChildActive {
                background: #00b897;
              }
            }
          }
        }
        .numBar {
          display: flex;
          width: 100%;
          justify-content: space-between;
          text-align: center;
          margin-top: 10px;
          .item {
            flex: 1;
            color: #5c6573;
          }
        }
      }
      .showBuyMoney {
        margin-top: 8px;
        font-size: 13px;
        font-weight: 400;
        color: #5c6573;
        line-height: 19px;
      }
      .buyBtnNow {
        width: 100%;
        height: 40px;
        margin-top: 16px;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 600;

        color: #ffffff;
        line-height: 40px;
        text-align: center;
      }
      .buyBtnNowBuy {
        background: #0AA869;
      }
      .buyBtnNowSell {
        background: #ED0071;
      }
      .buyBtnNowM {
        // margin-top: 62px;
      }
      .showUserMoney {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 400;
        color: #5c6573;
        line-height: 20px;
        padding: 10px;
        .item {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          span{
            text-align: right;
          }
        }
      }
    }
    .right {
      flex: 1;
      padding-left: 7.5px;
      .markTit {
        display: flex;
        margin-top: 13px;
        color: #5c6573;
        line-height: 22px;
        justify-content: space-between;
      }
      .buyDataBox {
        margin-top: 4px;
        min-height: 135px;
        .item {
          width: 100%;
          height: 20px;
          display: flex;
          font-size: 11px;
          line-height: 20px;
          position: relative;
          transition: all 0.5s;
          justify-content: space-between;
          .price {
            color: #0AA869;
            z-index: 10;
          }
          .num {
            color: #191b27;
            z-index: 10;
          }
          .color-sell-bg {
            position: absolute;
            z-index: 0;
            top: 0;
            right: 0;
            max-width: 100%;
            height: 100%;
            transition: all 0.5s;
            background: #d6ede5 !important;
          }
        }
      }
      .tradBox {
        height: 355px;
        overflow: hidden;
      }
      .sellDataBox {
        margin-top: 8px;
        min-height: 135px;
        .item {
          width: 100%;
          height: 20px;
          display: flex;
          font-size: 11px;
          line-height: 20px;
          position: relative;
          transition: all 0.5s;
          justify-content: space-between;
          .price {
            color: #ED0071;
            z-index: 10;
          }
          .num {
            color: #191b27;
            z-index: 10;
          }
          .color-sell-bg {
            position: absolute;
            z-index: 0;
            top: 0;
            right: 0;
            max-width: 100%;
            height: 100%;
            transition: all 0.5s;
            background: #f9dfde !important;
          }
        }
      }

      .nowPrice {
        margin-top: 6px;
        font-size: 16px;
        color: #0AA869;
        line-height: 23px;
      }
      .up {
        color: #0AA869;
      }
      .down {
        color: #ED0071;
      }
      .changePrice {
        font-size: 11px;
        color: #5c6573;
        line-height: 15px;
      }
      .selectType {
        display: flex;
        width: 100%;
        border-radius: 2px;
        border: 1px solid #c7ccd8;
        height: 30px;
        line-height: 30px;
        .item {
          flex: 1;
          position: relative;
          padding-left: 10px;
          font-size: 12px;
          color: #5c6573;
          line-height: 12px;
        }
        .item1 {
          border-right: 1px solid #c7ccd8;
        }
      }
    }
  }

  .border {
    border: 1px solid #c7ccd8;
    border-radius: 2px;
  }
  .jiantou {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 5px;
  }
  .entrustSelectBox {
    .content {
      .item {
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        border-bottom: 1px solid #eceef3;
      }
      .item1 {
        color: #ED0071;
      }
    }
  }
}
.selectDecimalPop {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: transparent;
  .selectDecimalPopShowBox {
    position: absolute;
    right: 54px;
    top: 100px;
    width: 92px;
    max-height: 240px;
    overflow-y: scroll;
    overflow-x: visible;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    border-radius: 3px;

    .jiantou {
      position: absolute;
      width: 20px;
      height: 10px;
      right: 8px;
      top: 0;
    }
    .contant {
      width: 100%;
      background-color: #fff;
      padding: 0 20px;
      .item {
        line-height: 51px;
        text-align: center;
      }
      .item1 {
        color: #ED0071;
      }
    }
  }
  .selectDecimalPopShowBox::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
.boxHome {
  width: 82px;
  max-height: 260px;
  overflow-y: scroll;
  text-align: center;
  .selectItem {
    text-align: center;
    width: 82px;
  }
  .active {
    .van-cell__value--alone {
      color: #00b897 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
::v-deep .trade {
  .van-cell::after {
    border-bottom: 0px;
  }
}
::v-deep .van-popover__wrapper{
  display: flex!important;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

::v-deep .van-cell {
  padding: 5px 10px;
}

::v-deep .buyMoney {
  .van-cell {
    padding: 8px 10px;
  }
}

::v-deep .van-divider {
  margin: 0;
}

.van-cell::after {
  border-bottom: 0px;
}
</style>