import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import _ from 'lodash'
import 'amfe-flexible'
import '@/assets/js/commonFunc.js'
import './utils/rem.js'
import enUS from 'vant/es/locale/lang/en-US'
import {copyUrl, cutOutPointLength, formatter, getMonth, parseTime, bigFun} from '@/utils/util'
import moment from 'moment'
import {api} from './request/api'
import Vant, {Locale, Notify, Toast} from 'vant'
import 'vant/lib/index.css'
import VueI18n from 'vue-i18n'
import * as Sentry from "@sentry/vue"

// 全局方法配置
const globalMethods = {
    $toast: Toast,
    $notify: (msg) => {
        Notify({
            message: msg,
            duration: 2000,
            color: '#FFF',
            background: '#00b897',
        })
    },
    copyUrl,
    cutOutPointLength,
    parseTime,
    formatter,
    getMonth,
    $big: bigFun,
    $moment: moment,
    $api: api
}

// 注册全局方法
Object.entries(globalMethods).forEach(([key, value]) => {
    Vue.prototype[key] = value
})

// String 格式化方法优化
String.prototype.format = function (args) {
    var result = this
    const arr = result.split('')
    let newArr = ''
    let count = 0
    if (args.length) {
      _.forEach(arr, (item) => {
        newArr += item
        if (item === '{') {
          newArr += `${count}`
          count++
        }
      })
    }
    result = newArr
    if (arguments.length > 0) {
      if (arguments.length == 1 && !Array.isArray(args)) {
        for (var key in args) {
          if (args[key] != undefined) {
            let reg = new RegExp('({' + key + '})', 'g')
            result = result.replace(reg, args[key])
          }
        }
      } else {
        for (var i = 0; i < arguments[0].length; i++) {
          if (arguments[0][i] != undefined) {
            let reg = new RegExp('({)' + i + '(})', 'g')
            result = result.replace(reg, arguments[0][i])
          }
        }
      }
    }
    return result
  }

// 插件注册
Vue.use(Vant)
Vue.use(VueI18n)
Locale.use('en-US', enUS)

// 多语言配置
const messages = {
    zh_CN: require('./assets/lang/zh_CN'),
    zh_TW: require('./assets/lang/zh_CN'),
    en_US: require('./assets/lang/en_US'),
    ba_BA: require('./assets/lang/ba_BA'),
    ko_KR: require('./assets/lang/ko_KR'),
    ja_JP: require('./assets/lang/ja_JP'),
    vi_VN: require('./assets/lang/vi_VN'),
    es_ES: require('./assets/lang/es_ES'),
    ro_RO: require('./assets/lang/ro_RO'),
    fr_FR: require('./assets/lang/fr_FR'),
    de_CH: require('./assets/lang/de_CH'),
    it_IT: require('./assets/lang/it_IT'),
    el_GR: require('./assets/lang/el_GR'),
}

const i18n = new VueI18n({
    locale: store.state.user?.lang || 'zh_CN',
    messages,
    silentTranslationWarn: true
})

// 环境配置
const isProd = process.env.NODE_ENV === 'production'
Vue.prototype.$apiUrl = isProd ? window.location.host : "m.transidex.com"
api.baseUrl = isProd ? `https://${window.location.host}/v1/rest/app` : "/devapi"
localStorage.setItem("UpFlagDev", isProd ? '0' : '1')

// Sentry 配置
if (isProd) {
    Sentry.init({
        Vue,
        dsn: "https://8f03b12483a7427fbb222e180c643526@o476175.ingest.us.sentry.io/4509120913932288",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration()
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    })
}

Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')