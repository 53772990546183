export const M = {
    "Slideright": "슬라이더 맨 오른쪽 이동",
    "Pleaseverification": "보안 확인을 완료하세요",
    "Pleaseorder": "클릭해주세요",
    "Verificationagain": "확인에 실패했습니다. 다시 시도해 주세요.",
    "10 orders": "10 주문",
    "24h High": "24시간 최고",
    "24h Low": "최소 24시간",
    "24h Trading Vol": "24시간 거래량",
    "24h Vol": "24시간 금액",
    "ADL mechanism": "ADL 메커니즘",
    "AMT.": "수량",
    "Activity _home": "활동",
    "Actual transaction number": "실제 거래 번호",
    "Actual transaction price": "실제 거래 가격",
    "Add margin": "마진콜",
    "Adjust leverage": "레버리지 조정",
    "Adjust success": "조정 성공",
    "Advanced limit order offers 3": "고급 지정가 주문은 “포스트 전용“, “필 또는 킬“ 및 “즉시 또는 취소“를 포함하여 일반 지정가 주문보다 3가지 더 많은 주문 옵션을 제공합니다. 일반 지정가 주문은 기본적으로 “취소될 때까지 유효“로 설정되어 있습니다.",
    "Alipay": "Alipay",
    "All Closed": "모든 포지션 닫기",
    "All Filled": "거래 완료",
    "AllDetails": "청산 세부정보 보기",
    "AllType": "모든 유형",
    "Amount can flat": "이용 가능",
    "Appeal_reason": "제소 원인",
    "Applet service": "애플릿 서비스",
    "Are you sure to cancel the order？": "주문을 취소하시겠습니까?",
    "Asked_to_record": "동영상 자료를 녹화하려면 아래 지침을 따르십시오.",
    "Asset conversion": "자산 평가",
    "Asset_types": "정보 유형",
    "Assets": "자산",
    "At the current price": "현재 가격으로",
    "Avail.": "사용 가능",
    "Avail. margin": "사용 가능한 마진",
    "Available": "사용 가능",
    "Avg close price": "평균 종가",
    "Avg open price": "평균 시가",
    "Avg.price": "거래 평균 가격",
    "BBO": "BBO",
    "Bankcard": "은행 카드",
    "Bankruptcy price": "파산 가격",
    "Batch Cancel": "일괄 취소",
    "Branch_address": "지점 주소",
    "Buy crypto_home": "구입",
    "Calculations are not based on": "계산 결과는 참고용입니다. 청산 가격은 하나의 계약에서 단 하나의 오픈 포지션 데이터만을 나타냅니다.",
    "Can't calculate": "계산할 수 없음",
    "Cancel": "취소",
    "Cancel all": "일괄 취소",
    "Cancel order": "주문 취소",
    "Cancel_the_login": "로그인 취소",
    "Capital cost": "자금 유수",
    "Capital cost - Expenditure": "자금 조달 비용",
    "Capital fee": "펀딩 수수료",
    "Capital fee - all": "펀딩 수수료 전체",
    "Capital fee - income": "펀드 수수료 수입",
    "Capital success": "해지",
    "Charge_money": "보증금",
    "Chinese_Yuan": "RMB",
    "Close all of": "당신은 확신합니까",
    "Close long": "롱 청산",
    "Close out time": "모든 청산 종료 시간",
    "Close price": "종가",
    "Close short": "숏 청산",
    "Closed": "청산 수량",
    "Closing PnL gains": "이익",
    "Closing PnL ratio": "이윤율",
    "Closing profit": "결산 이익",
    "coin_failure_0002": "통화 데이터가 없습니다",
    "Coming soon": "계속 지켜봐 주세요",
    "Common functions": "공통 기능",
    "Communtiy_home": "지역 사회",
    "Conceal canceled orders": "숨김이 취소되었습니다.",
    "Confirm_the_login": "로그인 확인",
    "Confirm_to_mention_money": "동전 확인",
    "Cont": "계속",
    "Copy link": "링크 복사",
    "Cross": "교차마진",
    "Currency_rate_description": "요금 설명",
    "Current": "현재",
    "Delete_option": "삭제",
    "Determine_to_cancel": "취소 확정?",
    "Don't show me again": "더 이상 알림이 없습니다.",
    "Draw_inconsistency": "일치하지 않는 암호 입력",
    "Draw_the_unlock": "스 와이프하여 새 비밀번호를 설정하십시오.",
    "Enter email address": "이메일 주소 입력",
    "Enter expected PnL": "예상 손익 입력",
    "Enter expected PnL Ratio": "예상 손익 비율 입력",
    "Enter leverage from 1 up to 125": "1에서 125까지 레버리지 입력",
    "Enter phone number": "전화번호 입력",
    "Enter the verification code": "인증 코드를 입력하십시오.",
    "Equity": "통화 자산",
    "Est liquidation price": "강제청산 예상가격",
    "Face value": "계약 가치",
    "Filed Value": "거래 가치",
    "Fill or Kill": "즉시 완료 또는 취소됨",
    "Filled amt": "용량",
    "Filled time": "정산 시간",
    "Flash_strong": "강렬한 플래시",
    "Funding rate": "펀딩 비율",
    "Funding rate - 1": "펀딩 비율",
    "Funding rate comparison": "펀딩 비율 비교",
    "Funding rate time": "펀딩 비율 시간",
    "Fuzzy_information": "정보 흐림",
    "Gesture_error": "비밀번호를 잘못 입력하십시오.",
    "Google_code": "Google 인증 코드",
    "Help_home": "돕다",
    "Hide_no_asset_currency": "무자산 코인 숨기기",
    "Highest PnL ratio": "최고 수율",
    "Historical record": "역사 기록",
    "I agree to": "동의 확인",
    "I have read and agree to this agreement": "나는 이 계약을 읽었으며 이에 동의합니다",
    "If_the_buyer_is_at": "구매자가에있는 경우",
    "Immediately or Cancel": "즉시 닫고 나머지는 취소",
    "In force": "시행 중",
    "Index": "스팟 인덱스",
    "Index Price": "지수 가격",
    "Index_contrct": "스팟 인덱스",
    "Info": "계약서 소개",
    "Initial margin": "개시 증거금",
    "Instrument": "현재 거래 품종",
    "Insurance fund": "위험 준비금 잔액",
    "Interest bearing record": "이자 계산 기록",
    "Invalid_qr_code": "잘못된 2 차원 사이즈",
    "Invite code(Optional)": "초청 코드 (선택 기입)",
    "InviteCodeMuster": "초대 코드 입력(필수)",
    "Invite_home": "초대",
    "Isolated": "고정 마진",
    "Lack_of_edge": "누락 된 구석",
    "Last Price": "시장 거래 가격",
    "Latest Filled price": "마지막",
    "Latest Filled price01": "최신 가격",
    "Latest price": "최신 가격",
    "Leverage": "영향력",
    "Leverage after increase": "증가 후 레버리지:",
    "Leverage11": "영향력",
    "Leverage_BB": "레버리지 조정",
    "Limit order is an order": "지정가 주문은 구매자의 최고 구매 가격과 정산자의 최저 판매 가격을 제한하는 주문입니다. 주문이 접수되면 당사 시스템이 해당 책을 책에 게시하고 귀하가 지정한 가격 이상으로 가능한 주문과 일치시킵니다.",
    "Limit price": "한계",
    "Limit/Market": "한계/시장",
    "LimitAndMarket": "제한 가격/시장 가격",
    "Liquidation price": "청산가격",
    "Liquidation price after increase": "인상 후 청산가격：",
    "Long": "많이",
    "Lowest PnL ratio": "최저 수율",
    "MAIL_COUNTRY_NOT_EXITS": "현재 국가가 존재하지 않습니다! 메시지를 추가하고 다시 보내주십시오.",
    "MAIL_NOT_MAIL_PROVIDER": "메일 서비스 제공 업체가 없으므로 메일을 보내기 전에 추가하세요!",
    "MAIL_TEMPLATE_NOT_FOUND": "현재 메일 템플리트가 존재하지 않습니다! 그것을 추가하고 다시 보내주십시오.",
    "MAIL_TO_ADDRESS_NOT_FOUND": "현재 이메일 주소가 없습니다. 다시 확인하고 다시 보내주십시오.",
    "MJRefreshAutoFooterIdleText": "클릭하거나 당겨 더 많은 것을로드하십시오.",
    "MJRefreshAutoFooterNoMoreDataText": "이미로드되었습니다.",
    "MJRefreshAutoFooterRefreshingText": "추가 데이터로드 중 ...",
    "MJRefreshBackFooterIdleText": "위로 당겨 더로드",
    "MJRefreshBackFooterNoMoreDataText": "이미로드되었습니다.",
    "MJRefreshBackFooterPullingText": "지금 출시 및 추가로드",
    "MJRefreshBackFooterRefreshingText": "추가 데이터로드 중 ...",
    "MJRefreshHeaderDateTodayText": "오늘",
    "MJRefreshHeaderIdleText": "풀다운을 새로 고침 할 수 있습니다.",
    "MJRefreshHeaderLastTimeText": "마지막 업데이트 :",
    "MJRefreshHeaderNoneLastDateText": "레코드 없음",
    "MJRefreshHeaderPullingText": "즉시 해제 및 새로 고침",
    "MJRefreshHeaderRefreshingText": "데이터 새로 고침 중 ...",
    "MKT Close ALL": "MKT 모두 닫기",
    "Margin add": "마진콜",
    "Margin mode": "모든 위치 모드",
    "Margin ratio": "마진율",
    "Margin reduce": "여백 줄이기",
    "Margin transfer": "증거금 이체",
    "Margin-1": "마진 잔고",
    "Margin-2": "여유",
    "Margined": "계약",
    "Mark price": "표시 가격",
    "Market": "시장 가격",
    "Market Order Dig": "시장가 주문은 빠른 거래의 목적을 달성하기 위해 사용자가 현재 시장에서 가장 좋은 가격으로 주문을 할 수 있습니다.",
    "Market Overview": "시장 개관",
    "Market_transaction": "시장 가격 거래",
    "Max": "최대 취소 가능",
    "Max Open int": "최대 미결제약정",
    "Max buy": "최대 구매",
    "Max long": "상승세 매수",
    "Max position size at current leverage": "최대 현재 레버리지를 열 수 있습니다.",
    "Max sell": "최대 판매",
    "Max short": "하락추세 매수",
    "Max position size at current leverage": "최대 현재 레버리지에서의 포지션 크기",
    "Mention_money": "코인 인출",
    "Mention_money_address": "올바른 주소를 입력하십시오.",
    "Mention_money_tips_ios": "힌트\n1. 철수 주소를 직접 입력하십시오. 펀드 암호, SMS, 이메일 또는 Google 인증 코드를 확인해야합니다.\n2. 자신의 인증 주소에 주소 바인딩을 추가합니다. 다음 번에 인증을 다시 입력 할 필요가 없습니다.",
    "Messages_notification": "* 은행 카드에 SMS 알림 서비스가 활성화되어 있는지 확인하십시오.",
    "Mini Programs": "미니 프로그램",
    "Minimum_handling_charge": "동전 수는 취급 수수료보다 커야합니다.",
    "Minimum_withdrawal": "단일 최소 인출",
    "MoreNot9": "선택한 모듈은 14를 초과할 수 없습니다.",
    "Most increase": "최대 증가",
    "Most reduce": "최대 감소량",
    "Must be integer multiple of": "의 정수배여야 합니다.",
    "Not Enabled": "활성화되지 않음",
    "Not_on": "선반에 놓을 수 없다.",
    "Not_to_mention_money": "자금 안전을 위해 거래 비밀번호를 재설정 한 후 1 시간 이내에 동전 및 PUSH 자산 철회와 같은 민감한 작업을 사용할 수 없습니다.",
    "OTC account": "법폐 계좌",
    "OTC assets": "장외 지갑",
    "OTC交易须知": "장외 시장 거래 메모",
    "On Hold": "동결",
    "One-way": "일방 통행",
    "One/Two-way": "편도/양방향",
    "Open Time": "오픈 시간",
    "Open amount": "미결제 금액",
    "Open int.": "미결제약정",
    "Open interest": "미결제약정",
    "Open long": "매수",
    "Open orders": "오픈 주문",
    "Open price": "공개 가격",
    "Open short": "매도",
    "Order Details": "주문 세부정보",
    "Order Sorting": "주문 정렬",
    "Order Value": "주문 금액",
    "Order amt": "총 주문",
    "Order history": "위임 기록",
    "Order price": "주문 가격",
    "Order trigger direction": "주문 방아쇠 방향",
    "Order type": "주문 유형",
    "PL": "이익",
    "PL Ratio": "PL 비율",
    "Pair01": "계약 통화",
    "Parse_failure": "저장 실패",
    "Partially Filled": "부분 거래",
    "Partially closed": "부분 청산",
    "Password": "비밀번호",
    "Payment_type": "영수증 종류",
    "Perpetual": "무기한 계약",
    "Perpetual funds daybook": "영구 청구서",
    "Perpetual01": "PERP",
    "Pingclose": "평 창",
    "Please input a password": "입력하십시오.",
    "Position": "위치",
    "Position balance": "포지션 밸런스",
    "Position history": "역사적 입장",
    "QR_code": "QR 코드",
    "Quantity cannot exceed": "수량은 125개를 초과할 수 없습니다.",
    "Quantity not less than": "수량 1 이상",
    "Recent close": "최근 닫기",
    "Recording_requirements": "동영상 요청 녹화",
    "Required position margin at current leverage": "현재 레버리지에서 요구되는 포지션 마진:",
    "Reset_transaction_password": "트랜잭션 비밀번호 재설정",
    "Revoked": "취소됨",
    "SL": "손절매",
    "SL order": "손절매 주문",
    "SL order price": "손절매 주문 가격",
    "SL order price can't be empty": "손절매 주문 가격은 비워둘 수 없습니다.",
    "SL trigger price": "손절매 발동 가격",
    "SL trigger price can't be empty": "손절매 발동 가격은 비워둘 수 없습니다.",
    "SMS_30_OUT_RESTRICTED": "인증 코드는 자주 전송되며 30 초마다 하나만 전송할 수 있습니다.",
    "SMS_BAD_ARGUMENT_FORMAT": "전화 형식이 잘못되었습니다.",
    "SMS_COUNTRY_NOT_EXITS": "해당 국가가 존재하지 않습니다. 문자 메시지를 확인하고 보내주십시오!",
    "SMS_DAY_LIMIT_PER_MOBILE": "동일한 휴대 전화 번호가 전송 된 횟수가 24 시간 이내에 한도를 초과했습니다.",
    "SMS_DUP_IN_SHORT_TIME": "동일한 휴대 전화 번호로 30 초 이내에 동일한 콘텐츠 반복",
    "SMS_FAILURE": "보내기 실패",
    "SMS_HOUR_LIMIT_PER_MOBILE": "동일한 휴대 전화 번호가 전송 된 횟수가 1 시간 이내에 한도를 초과했습니다.",
    "SMS_MARKET_FORBIDDEN": "운영자 제어로 인해 마케팅 SMS를 일시적으로 사용할 수 없음",
    "SMS_NOT_SMS_PROVIDER": "SMS 서비스 공급자가 없으므로 보내기 전에 메시지를 추가하십시오!",
    "SMS_NOT_SMS_PROVIDER_TEMPLATE": "아직 SMS 서비스 제공 업체 템플릿이 없으므로 SMS를 보내기 전에 추가하십시오!",
    "SMS_PHONE_OVER_COUNT": "이 휴대 전화 번호 확인 코드는 두 번 이상 전송됩니다. 24 시간 후에 사용해주세요!",
    "SMS_PHONE_PROHIBIT_SEND": "이 휴대 전화는 문자 메시지를 보낼 수 없습니다. 24 시간 후에 시도하십시오!",
    "SMS_SUCCESS": "성공적으로 보냈습니다.",
    "SMS_TOO_MANY_TIME_IN_5": "같은 휴대 전화 번호로 5 분 이내에 같은 내용을 3 번 이상 반복하십시오.",
    "SMS_UNKNOWN_EXCEPTION": "시스템에서 알 수없는 이상이 발생했습니다.",
    "SMS_code": "SMS 인증 코드",
    "Save picture": "그림 저장",
    "Scanning_to_obtain": "충전 주소를 얻으려면 스캔하십시오.",
    "See auto deleverage liquidation for details": "자세한 내용은 자동 디레버리지 청산 참조",
    "Service_home": "서비스",
    "Set_transaction_password": "거래 암호 설정",
    "Settlement1": "합의",
    "Settlrment coin": "결제 코인",
    "Share time": "공유 시간",
    "Shielding success": "차폐 성공",
    "Short": "짧게",
    "Sign Up": "등록",
    "Sign in": "로그인",
    "Simplified_Chinese": "한국어",
    "StatusOrder": "주문 상태",
    "Stop loss": "손절매",
    "Stop profit stop loss": "정지 이익 정지 손실 적어도 하나를 선택하십시오. 그렇지 않으면 주문할 수 없습니다.",
    "Sustainable account": "영구 계정",
    "Sustainable assets": "PERP 자산",
    "Switch to PnL": "Pln으로 전환",
    "Switch to PnL ratio": "PnL 비율로 전환",
    "TP": "이익을 취하다",
    "TP order": "테이크 프로핏 커미션",
    "TP order price": "이익 수수료 가격",
    "TP order price can't be empty": "이익실현 주문 가격은 비워둘 수 없습니다.",
    "TP trigger price": "이익 창출 트리거 가격",
    "TP trigger price can't be empty": "이익실현 트리거 가격은 비워둘 수 없습니다.",
    "TP | SL": "스톱 손익",
    "TP | SL order": "이익실현 및 손절매 수수료",
    "Take profit": "수익 설정",
    "Telegraphic_transfer_address": "은행 송금 주소",
    "The Calculator": "계산자",
    "The maximum flatable quantity is": "최대 레벨링 금액은 다음과 같습니다.",
    "The maximum leverage is": "최대 레버리지는",
    "The minimum flatable quantity is": "최소 레벨 가능 금액은",
    "The minimum leverage is": "최소 레버리지는",
    "The poster has been saved to the album": "포스터가 앨범에 저장되었습니다.",
    "The_correct_sample": "올바른 예",
    "The_input_signal": "스 와이프하여 비밀번호를 입력하십시오.",
    "The_new_address": "새 주소",
    "They_are_the_number_of": "대기중인 주문 수",
    "Tick size": "눈금 크기",
    "To_update": "업데이트",
    "Toggle_front_camera": "전면 카메라로 전환하여 헤드를 중앙에 유지하십시오.",
    "Total": "총",
    "Total coin": "보유 통화 수",
    "Total cont": "오픈 포지션 수",
    "Total value": "포지션 가치",
    "Total_asset_valuation": "총 자산 평가",
    "Trade type": "거래 유형",
    "Trade_sector": "선택해주세요.",
    "Transfer": "구획하다",
    "Transfer_to_remind": "돈을 이체 할 때 반드시 말하십시오 (그렇지 않으면 위험 부담으로)",
    "Trigger mark price": "트리거 마크 가격",
    "Trigger price": "트리거 가격",
    "Two-way": "양방향",
    "UPL": "UPL",
    "US_dollar": "USD",
    "Unblocked": "차단 해제",
    "Uneasy lies the head that wears a crown": "그는 그를 강하게 만듭니다\n나는 호위왕입니다",
    "Unfilled": "채워지지 않은",
    "Unfilled11": "거래를 기다리는 중",
    "Upload_collection_code": "영수증 코드를 업로드하십시오.",
    "Usdt-Margined": "USDT 계약",
    "Users who register": "처음 등록하는 사용자는 로그인 비밀번호 설정을 완료해야 합니다.",
    "Verification Code": "인증 코드",
    "Videoing_requirement": "촬영 요구 사항",
    "View": "보다",
    "View more": "직위 세부정보",
    "Waiting effect": "대기 효과",
    "Waiting_for_the_payment": "지급",
    "Wechat": "WeChat",
    "WestUnion": "웨스턴 유니온",
    "Without_her": "항목 없음",
    "Your closing number": "마감 수량이 최소 포지션보다 적습니다!",
    "Your have pending Cross orders": "보류 중인 교차 주문이 있습니다. 레버리지를 조정하기 전에 취소하세요.",
    "Your have pending isolated orders": "보류 중인 격리된 주문이 있습니다. 레버리지를 조정하기 전에 주문을 취소하십시오.",
    "abandoned": "버려진",
    "abnormal_assets": "계정 자산이 비정상이므로이 작업을 일시적으로 수행 할 수 없습니다!",
    "about_us": "회사 관련",
    "account": "계정",
    "account_empty": "계정 오류",
    "account_failure_0001": "트랜잭션 흐름 ID가 REDIS에 실패했습니다.",
    "account_failure_0002": "불충분 한 사용을 처리 할 수 ​​없습니다!",
    "account_failure_0003": "사용자 정지로 인해 로깅 실패가 증가합니다!",
    "account_failure_0004": "사용자 동결 실패!",
    "account_failure_0005": "판매자가 사용 가능한 로그 레코드를 차감했습니다!",
    "account_failure_0006": "상인 공제 실패!",
    "account_failure_0007": "사용자가 로깅 실패를 증가시킬 수 있습니다!",
    "account_failure_0008": "사용자가 사용할 수 없습니다.",
    "account_failure_0009": "판매자가 새 통화를 추가하면 사용자가 새 통화를 추가하지 못합니다.",
    "account_failure_0010": "판매자가 새 통화를 추가하지 못했습니다.",
    "account_failure_00100": "판매자 ID는 비워 둘 수 없습니다.",
    "account_failure_00101": "사용자 ID는 비워 둘 수 없습니다.",
    "account_failure_00102": "통화 ID는 비워 둘 수 없습니다.",
    "account_failure_00103": "수량은 비워 둘 수 없습니다.",
    "account_failure_00104": "수량은 최소 거래량보다 작을 수 없습니다.",
    "account_failure_00105": "거래 비밀번호는 비워 둘 수 없습니다.",
    "account_failure_00106": "거래 비밀번호가 잘못되었습니다.",
    "account_failure_00107": "가격은 비워 둘 수 없습니다.",
    "account_failure_00108": "단가는 최소 단가보다 낮을 수 없습니다.",
    "account_failure_00109": "사용자가 존재하지 않습니다!",
    "account_failure_0011": "판매자 월렛이 통화는 이미 있습니다!",
    "account_failure_00110": "PUSH 자산을 시작하지 못했습니다!",
    "account_failure_00111": "푸시는 비워 둘 수 없습니다.",
    "account_failure_00112": "PUSH 자산 지불에 실패했습니다!",
    "account_failure_00113": "PUSH 자산 취소에 실패했습니다!",
    "account_failure_00114": "통화 주소를 가져올 수 없습니다.",
    "account_failure_00115": "동전 주소는 비워 둘 수 없습니다!",
    "account_failure_00116": "동전 주소를 추가하지 못했습니다.",
    "account_failure_00117": "ID는 비워 둘 수 없습니다.",
    "account_failure_00118": "동전 주소를 삭제하지 못했습니다.",
    "account_failure_00119": "네트워크가 비정상적입니다. 잠시 후 다시 시도하십시오!",
    "account_failure_0012": "부족한 동결, 처리 할 수 ​​없습니다!",
    "account_failure_00120": "동전 주소가 이미 있습니다.",
    "account_failure_00121": "PUSH 통화 없음",
    "account_failure_00122": "전화 확인 코드가 비어 있습니다.",
    "account_failure_00123": "사서함 확인 코드가 비어 있습니다.",
    "account_failure_00124": "Google 인증 코드가 비어 있습니다!",
    "account_failure_00125": "확인 코드 오류!",
    "account_failure_00126": "셀프 푸시 금지!",
    "account_failure_00127": "동전 주소는 합법적이지 않습니다!",
    "account_failure_00129": "24 시간 이상 암호를 변경할 수 없습니다.",
    "account_failure_0013": "상인 동결 공제 기록이 실패했습니다!",
    "account_failure_00130": "자산의 균형이 맞지 않아 조작 할 수 없습니다.",
    "account_failure_00131": "계정 자산이 비정상이고 거래 상태가 비활성화되었습니다.",
    "account_failure_00132": "탭이 맞지 않습니다",
    "account_failure_00133": "화폐 종류가 존재하지 않거나 비활성화됨",
    "account_failure_0014": "상인 동결 공제 실패!",
    "account_failure_0015": "사용자는 관심과 함께 로깅 실패를 증가시킬 수 있습니다!",
    "account_failure_0016": "사용자가 사용할 수있는 관심 증가에 실패했습니다!",
    "account_failure_0017": "상인은이자 공제 로그 기록을 사용할 수 없습니다!",
    "account_failure_0018": "상인은이자 공제를 사용하여 실패 할 수 있습니다!",
    "account_failure_0019": "정지 할 수있는 사용자가 실패했습니다!",
    "account_failure_0020": "고정 할 수있는 사용자가 실패했습니다. 로깅에 실패했습니다!",
    "account_failure_0021": "사용 가능한 로깅에 대한 사용자 정지가 실패했습니다!",
    "account_failure_0022": "사용자가 사용 가능한 오류로 정지했습니다!",
    "account_failure_0023": "판매자는 사용 가능한 로깅을 늘리지 못했습니다!",
    "account_failure_0024": "가맹점 증가 가용성!",
    "account_failure_0025": "사용자 동결 공제 로그 레코드가 실패했습니다!",
    "account_failure_0026": "사용자 동결 공제에 실패했습니다!",
    "account_failure_0027": "사용자는 실패한 로그 레코드를 공제 할 수 있습니다!",
    "account_failure_0028": "사용자가 사용할 수있는 공제에 실패했습니다!",
    "account_failure_0029": "롤백 작업 실패 사용자 고정 복구 로그 레코드가 실패했습니다!",
    "account_failure_0030": "롤백 작업 실패 사용자 고정 복구가 실패했습니다!",
    "account_failure_0031": "자산이 롤백하기에는 충분하지 않습니다!",
    "account_failure_0032": "실패한 롤백 조작자 판매자 잠금 복구 로그 레코드가 실패했습니다!",
    "account_failure_0033": "롤백 작업에 실패했습니다. 판매자 애셋 복구에 실패했습니다.",
    "account_failure_0034": "상인 동결 로깅을 늘리지 못했습니다!",
    "account_failure_0035": "상인 동결이 증가했습니다!",
    "account_failure_0036": "판매자가 로그 레코드를 고정하지 못했습니다!",
    "account_failure_0037": "동결 가능한 판매자가 실패했습니다!",
    "account_failure_0038": "사용 가능한 로깅에 대한 판매자 동결에 실패했습니다!",
    "account_failure_0039": "판매자가 가동 중단으로 인해 정지합니다!",
    "account_failure_0040": "로깅 실패를 증가시킬 수있는 플랫폼!",
    "account_failure_0041": "실패를 증가시킬 수있는 플랫폼!",
    "account_failure_0042": "트랜잭션이 비정상적으로 실행되었습니다!",
    "account_failure_0043": "트랜잭션을 실행하지 못했습니다!",
    "account_failure_0044": "트랜잭션이 성공적으로 실행되었습니다!",
    "account_failure_0045": "트랜잭션이 실행 중입니다!",
    "account_failure_0046": "성공적인 처리!",
    "account_failure_0047": "롤백 작업에 실패한 플랫폼 자산 복구 실패",
    "account_failure_0048": "롤백 작업 실패 플랫폼 자산 복구 로깅 실패",
    "account_failure_0049": "플랫폼이 이미 존재합니다!",
    "account_failure_0050": "플랫폼이 새 통화를 추가하지 못했습니다!",
    "account_failure_0051": "플랫폼이 이미 존재합니다!",
    "account_failure_0053": "거래 유형이 잘못되었습니다.",
    "account_failure_0054": "상인들이 실패를 증가시킵니다!",
    "account_failure_0055": "상인은 로깅 실패를 증가시킬 수 있습니다!",
    "account_failure_0056": "사용자 테이블 판매자, 플랫폼 수익 이전이 실패했습니다!",
    "account_failure_0057": "사용자 테이블 판매자, 플랫폼 수익 이전 로그 레코드가 실패했습니다!",
    "account_failure_0058": "사용자는 재무 관리를 실패 할 수 있습니다!",
    "account_failure_0059": "재무 로깅에 사용할 수있는 사용자가 실패했습니다!",
    "account_failure_0060": "불충분 한 재무 관리, 실행할 수 없습니다!",
    "account_failure_0061": "사용자 파이낸싱을 사용할 수 없었습니다!",
    "account_failure_0062": "사용자 파이낸싱이 가능합니다. 로그인 실패!",
    "account_failure_0063": "실행에 실패 했습니다. 데이터 가 없습니다",
    "account_failure_0064": "당분간 사용 할 수 없다",
    "account_failure_0065": "권한이 없 으니 고객 님께 연락 주세요",
    "account_failure_0071": "사용자 잠금 이상!",
    "account_failure_0072": "사용자 충전 기록이 존재하지 않거나 수정됨",
    "activitie_end": "활동 종료",
    "activity_invalid": "만료",
    "actual_amount": "실발 총량",
    "actual_exchange_amount": "실제 변환",
    "actual_to_account": "실제 도착",
    "addSuccess": "성공적으로 추가됨",
    "add_channel_function": "확인 채널 기능 추가",
    "add_charge_and_transfer_function": "돈 입금 및 입출금 기능 추가",
    "added_alert_setting_function": "경고 설정 기능 추가",
    "added_day_mode_toggle": "주간 모드 전환 기능 추가",
    "address_tag": "레이블",
    "advanced_limit_order": "고급 지정가 주문",
    "advertising": "광고",
    "advertising_management": "광고 관리",
    "affirm": "확인",
    "affirm_pass": "암호 확인",
    "again_digits6": "새 암호를 다시 입력하십시오.",
    "again_password": "로그인 암호를 다시 입력하십시오.",
    "airdrop_coin": "에어 드롭 통화",
    "airdrop_date": "에어 드랍 시간",
    "airdrop_number": "에어 드롭 수",
    "alipay_account": "Alipay 계정",
    "alipay_upload": "* Alipay 업로드 QR 코드 방법 : 오픈 Alipay 홈 페이지> 돈을 모으> 사진을 저장 휴대 전화 앨범의 영수증을 업로드하십시오.",
    "all": "모두",
    "all_buy": "모두 구입",
    "all_country": "모든 국가",
    "all_order": "모든 주문",
    "all_sell": "모두 판매",
    "already_use": "활성화",
    "amount_of_payout": "지출 금액",
    "announcement_all": "모든 공지",
    "announcement_center": "발표 센터",
    "announcement_official": "공식 공고",
    "annualized_rate": "연화율",
    "appeal_cancel": "이의 제기 판결, 주문 취소",
    "appeal_complete": "불복 심판, 주문 완료",
    "appeal_dispose_time": "호소 처리 시간",
    "appeal_of_time": "항소 제출 시간",
    "apply_locked": "자물쇠를 신청하다",
    "asset_allocatio": "자산 분포",
    "assets_asset_valuation": "자산 평가",
    "assets_can_transfer": "가전",
    "assets_cash_flow": "자금 유수",
    "assets_coin_funds_flow": "현물 청구서",
    "assets_currency_assets": "화폐 자산",
    "assets_deposit_income_tip": "소득의 가치는 실시간 환율로 계산됩니다.",
    "assets_fiat_assets": "법폐 자산",
    "assets_fiat_money_flow": "법정 화폐",
    "assets_flowing_details": "유수 정보",
    "assets_prompt_001": "[손실을 막기 위해 키를 올바르게 백업하십시오]",
    "assets_reduced_asset": "자산 할인",
    "assets_row_mining": "행 마이닝",
    "assets_swipe_direction": "방향을 돌리다",
    "assets_transfer": "구획하다",
    "assets_transfer_coin": "화폐의 종류.",
    "assets_transfer_count": "수량을 돌리다",
    "assets_transfer_currency": "자금 회전",
    "assets_transfer_dialog_bb": "화폐 계좌",
    "assets_transfer_dialog_fiat": "법폐 계좌",
    "assets_transfer_dialog_from": "따르다",
    "assets_transfer_dialog_transfer_to": "전환하다",
    "assets_transfer_dialog_wallet": "지갑 계정",
    "assets_transfer_record": "양도 기록",
    "assets_transfer_to": "받는 사람",
    "assets_wallet": "지갑",
    "assets_wallet_assets": "지갑 자산",
    "assets_wallet_assets_transfer_tip": "* 거래는 지갑 자산을 카운터 계정으로 이전해야만 가능합니다. 계좌 간 이체에는 이체 수수료가 부과되지 않습니다.",
    "assets_wallet_funds_flow": "돈지갑",
    "at_least_one": "하나 이상의 거래 비밀번호 확인 상태를 켭니다.",
    "attention_blacklist": "따라 블랙",
    "attention_to_see": "나를 팔로우하고 싶으시면 클릭하여 확인하십시오!",
    "audit_result": "감사 결과",
    "authenticated": "공인",
    "authentication": "인증",
    "available_balance": "사용 가능",
    "average_purchase_price": "평균 구매 가격",
    "avoid_close_set": "무료 설정",
    "back": "돌아가기",
    "balance": "더 이상",
    "bank_card": "은행 카드",
    "bank_name": "은행 이름",
    "bank_num": "은행 카드 번호",
    "barcode_in_the_box_scan": "상자 안의 QR 코드 / 바코드를 자동으로 스캔합니다.",
    "bb_assets": "통화 자산",
    "bb_history_finished": "수행하다",
    "bb_time": "시간",
    "bb_used": "활성화",
    "be_usable": "가능",
    "bear_consequences_your_investment": "본인은 투자 위험을 알고 자발적으로 추정했으며 인증 코드는 다음과 같습니다.",
    "become": "되다",
    "been_authenticated": "실명 인증을 받았습니다.",
    "beginner_guide_title": "세계 최고의 디지털 자산 거래 플랫폼",
    "belong_level": "소속 등급",
    "between_and": "이르다",
    "bill": "계산서",
    "billing_details": "계산서 명세서",
    "bind_email": "편지함 바인딩",
    "bind_google": "구글인증",
    "bind_google_authenticator": "Google OTP 바인딩",
    "bind_phone": "바인딩 폰",
    "branch_name": "지점 이름",
    "bug_order": "매입",
    "buy": "매입",
    "buy_1": "구매",
    "buy_now": "지금 구입",
    "buy_quantity": "구매 수량",
    "buy_rate": "구매 수수료",
    "buyer": "구매자",
    "buyer_has_paid_attention": "구매자가주의를 기울였다.",
    "buyer_payment": "구매자가 지불했습니다.",
    "buyer_phone": "구매자 전화번호",
    "buyer_uid": "구매자",
    "calculate": "계산하다",
    "calculations are for your reference only": "계산은 참조용입니다.",
    "can_available": "얻을 수 있다",
    "canceled": "취소됨",
    "cancellations": "탈퇴",
    "centre": "중",
    "change1": "수정",
    "change_gesture_password": "비밀번호 변경",
    "change_google": "Google OTP 변경",
    "change_pay_style": "대체 방법",
    "change_phone": "전화 변경",
    "charge_after_the_transaction": "거래 후 요금",
    "charge_out": "수수료는 반드시 있어야합니다.",
    "check_the_agree": "확인 동의",
    "china": "중국",
    "choose_picture": "사진 선택",
    "clearing_time": "결제 시간",
    "click_enter_batch_edit_page": "일괄 편집 페이지로 들어가려면 클릭하십시오",
    "click_on_the_copy": "클릭 복제",
    "clinch_a_deal": "구매자는 여러 번 거래해야합니다 (0은 무제한 임).",
    "close": "닫기",
    "closed_successfully": "폐쇄 됨",
    "cloud_mining_pool": "클라우드 마이닝 풀",
    "coin_failure_0001": "통화가 존재하지 않습니다.",
    "collect": "컬렉션",
    "collection_time": "수집 시간",
    "commission": "위탁되었습니다",
    "common_failure": "실패했습니다!",
    "common_failure_0001": "네트워크 이상",
    "common_problem": "사용 설명서",
    "common_success": "성공",
    "complete": "완성",
    "completed": "거래 완료",
    "computer_login": "컴퓨터로 로그인 확인",
    "confirm": "결정",
    "confirm_exit": "출구를 확인 하시겠습니까?",
    "confirm_password": "로그인 암호를 확인하십시오.",
    "confirm_submission": "제출 확인",
    "confirm_the_purchase": "구매 확인",
    "confirm_to_give_up": "포기 확인",
    "confirm_to_sell": "판매 확인",
    "confirm_upgrade": "업그레이드 확인",
    "confirm_vote": "투표 확인",
    "confirmation_information": "정보 확인",
    "confrim_delete_warn": "경고를 삭제 하시겠습니까?",
    "contact_service": "지원 연락",
    "contact_us": "우리 연락",
    "contract_Order": "위임",
    "contrct Agree Content": "귀하의 권리를 보호하기 위해 계약을 열기 전에 “BitMatrix 무기한 계약 계약“을 주의 깊게 읽어야 합니다.",
    "contrct Agree Content footer": "《BitMatrix 영구 스왑 계약》",
    "contrct Agree Content header": "R/I를 보호하려면 스왑을 열기 전에 주의 깊게 읽어야 합니다.",
    "contrct_decimal": "소수",
    "convert_into": "환산",
    "converted_currency": "변환 된 통화",
    "copartner-upgrade-failure": "업그레이드 실패",
    "copartner-upgrade-no": "업그레이드 조건을 만족시키지 못하고, 업그레이드할 수 없다.",
    "copartner-upgrade-ok": "업그레이드 가능",
    "copartner-upgrade-success": "업그레이드 성공",
    "copy_content": "이미 대지에 복사되었습니다.",
    "country": "국가",
    "create_time": "생성 시간",
    "cumulative_income:": "누계 수익：",
    "cumulative_participation": "축적",
    "currency": "통화",
    "currency_fee": "환율",
    "currency_information": "통화 정보",
    "currency_name": "통화 이름",
    "currency_not_found": "화폐 유형은 존재하지 않습니다",
    "currency_select": "화폐 종류 선택",
    "current_day_return": "당일",
    "current_entrust": "현재 위임자",
    "current_identity": "현재 신분",
    "current_polling_date": "현재 투표 시간",
    "current_status": "현재 상태",
    "currently_available": "현재 이용 가능",
    "date": "날짜",
    "date_of_birth": "생년월일",
    "day_highs_and_lows": "기복",
    "day_highs_and_lows1": "변화",
    "day_income": "주간",
    "day_trading": "주간 거래",
    "daytime": "낮",
    "ddress_management": "주소 관리",
    "deal_amount": "총 매출액",
    "deal_done": "판매되었습니다",
    "deal_gross": "거래/총량",
    "deal_of_the_week": "금주의 거래",
    "deal_price": "평균 가격",
    "deal_sum": "거래 금액",
    "deal_the_detail": "주문 세부 정보",
    "deal_volume": "거래 금액",
    "deduct_coin": "공제 통화",
    "default_settlement": "위약 결산",
    "defaults": "기본값",
    "deposit": "버튼 하나",
    "deposit_details": "예금 정보",
    "deposit_income": "저폐 수익",
    "deposit_lock": "재고 창고",
    "deposit_lock_details": "재고 창고 상세 상황",
    "deposit_returned_principal": "원금 반환",
    "depth": "깊이",
    "detailed_rules": "자세한 규칙",
    "details": "자세한 사정",
    "digits6": "새로운 6 자리 암호를 입력하십시오.",
    "direct_market": "직접 프로모션",
    "direct_reward": "직접 보상",
    "direction": "방향",
    "discount_Rate": "할인율",
    "discount_currency_introduction": "통화 소개",
    "discount_dialog_tip1": "축하합니다!",
    "discount_dialog_tip2": "자격 조건을받은 후 참여 조건 및 시스템에 의해 운이 좋은 사용자로 무작위로 선택, 참여 권한이 있습니다!",
    "discount_dialog_tip3": "당신은 이미",
    "discount_dialog_tip4": "보너스 신청 자격, 액티비티 금액이 제한되어 있습니다. 이벤트 시간 내에 보너스 신청에 참여하십시오!",
    "discount_dialog_tip5": "포기 후 참여할 수 없습니다",
    "discount_dialog_tip6": "활동 및 참여 신청할 수 없습니다.",
    "discount_dialog_tip7": "참고 : 성공적인 공제액이 공제됩니다.",
    "discount_purchase": "할인 사용",
    "discounted_or_original_price": "할인 가격 / 원가",
    "dispose_result": "처리 결과",
    "disqualification": "실격",
    "divided_earnings": "수익 배당",
    "drag": "슬라이더 맨 오른쪽 이동",
    "each_input": "각 거래에 대한 거래 비밀번호 확인",
    "edt_selfchoose": "편집자의 선택",
    "effective": "효과적인",
    "eligibility": "자격",
    "email": "이메일 등록",
    "email_address_format": "올바른 이메일 주소를 입력하십시오.",
    "email_code": "이메일 인증 코드",
    "empty_deal_pass": "거래 비밀번호는 비워 둘 수 없습니다.",
    "end time": "종료 시간",
    "end_date": "종료일",
    "end_time": "종료 시간",
    "enter_account": "휴대 전화 또는 이메일 계정을 입력하십시오.",
    "enter_cause_of_complaint": "고소장을 기재하십시오.",
    "enter_fb_buy_price": "구매 가격을 입력하세요",
    "enter_fb_sell_price": "판매 가격을 입력하세요",
    "enter_your_password": "로그인 암호를 입력하십시오.",
    "enter_password": "비밀번호",
    "entrust_amount": "커미션 금액",
    "entrust_failure_0001": "네트워크 이상",
    "entrust_failure_0002": "검색어 유형 오류",
    "entrust_failure_0003": "검색 유형은 비워 둘 수 없습니다.",
    "entrust_failure_0004": "판매자 ID는 비워 둘 수 없습니다.",
    "entrust_failure_0005": "사용자 ID는 비워 둘 수 없습니다.",
    "entrust_failure_0006": "사용자가 존재하지 않습니다.",
    "entrust_failure_0007": "거래 ID는 비워 둘 수 없습니다.",
    "entrust_failure_0009": "거래 쌍이 없습니다.",
    "entrust_failure_0010": "거래 비밀번호는 비워 둘 수 없습니다.",
    "entrust_failure_0011": "거래 비밀번호가 잘못되었습니다.",
    "entrust_failure_0012": "주문 유형은 비워 둘 수 없습니다.",
    "entrust_failure_0013": "주문 수는 비워 둘 수 없습니다.",
    "entrust_failure_0014": "한도 주문의 가격은 비워 둘 수 없습니다.",
    "entrust_failure_0015": "주문 ID는 비워 둘 수 없습니다.",
    "entrust_failure_0016": "주문이 없습니다.",
    "entrust_failure_0017": "주문이 삭제되었습니다.",
    "entrust_failure_0018": "주문을 철회 할 수 없습니다.",
    "entrust_failure_0019": "버전 번호는 비워 둘 수 없습니다.",
    "entrust_failure_0020": "시장 질서를 철회 할 수 없다.",
    "entrust_failure_0021": "현재 데이터가 업데이트되었습니다.",
    "entrust_failure_0022": "현재 주문이 없으므로 시장 주문을 할 수 없습니다.",
    "entrust_failure_0023": "거래가 사용 중지되거나 관련 통화가 사용 중지되었습니다.",
    "entrust_failure_0024": "트랜잭션이 트랜잭션에 열려 있습니다.",
    "entrust_failure_0025": "수수료 가격이 최저 가격보다 낮거나 최고 가격보다 높습니다.",
    "entrust_failure_0026": "주문 수는 최소 한도를 초과하거나 최대 한도를 초과합니다.",
    "entrust_failure_0027": "이 계정은 거래가 금지되어 있으므로 고객 서비스에 문의하십시오.",
    "entrust_failure_0030": "현재 거래에서 가장 높은 일일 증가율 {}을 초과하여 주문할 수 없습니다.",
    "entrust_failure_0031": "현재 거래에서 가장 높은 일일 하락률 {}을 초과하여 주문할 수 없음",
    "entrust_msg_match_type_001": "주문 제한",
    "entrust_msg_match_type_002": "시장 질서",
    "entrust_msg_status_001": "채워지지 않은",
    "entrust_msg_status_002": "부분 거래",
    "entrust_msg_status_003": "거래 완료",
    "entrust_msg_status_004": "없애다",
    "entrust_msg_type_001": "주문 판매",
    "entrust_msg_type_002": "청구서 지불",
    "entrusted_price": "수수료 가격",
    "entry_orders": "주문",
    "equal_principal": "등액 원금",
    "error_email": "사서함 오류",
    "error_network_anomaly": "네트워크 이상",
    "error_phone": "정확한 휴대 전화 번호를 입력하십시오.",
    "error_unknow": "네트워크 오류",
    "event_detail": "이벤트 상세",
    "event_detail_warn_tip": "알림 : 디지털 자산은 가격 변동이 큰 특성을 가진 혁신적인 자산입니다. 합리적인 판단을 내리고 투자 결정을 신중하게 선택하십시오!",
    "event_start_time": "이벤트 시작 시간",
    "every day": "매일",
    "everyday": "매일",
    "exchange": "변환",
    "exchange_currency": "환전 통화",
    "exchange_details": "변환세부",
    "exchange_history": "교환 내역",
    "exchange_quantity": "교환 수량",
    "exchange_rules": "교환 규칙",
    "exchangeable": "변환",
    "exclusive_posters": "독점 포스터 생성",
    "exclusive_posters-Video": "포스터 생성",
    "exit": "출구",
    "expected_return": "예상 수익",
    "expired": "만료",
    "express_area": "단축구",
    "face_recognition": "얼굴 인식",
    "failure": "실패",
    "fb_lookup": "보려면 클릭하세요",
    "fb_order_number": "주문 번호",
    "feeze_thaw_msg_status_0001": "냉동",
    "feeze_thaw_msg_status_0002": "해동",
    "fill_in_at_least_one": "하나 이상 입력하십시오",
    "fill_wrong": "오류가 있으면 자산이 손실 될 수 있으므로 신중하게 확인하십시오.",
    "filtrate": "상영",
    "filtrate_content": "지불 방법, 국가 및 통화 포인트를 필터링하십시오!",
    "financial_management_is_cancel": "예금 수익이 이미 취소되었습니다! 중복 제출하지 마세요!",
    "financial_management_is_delete": "이 재테크 유형은 이미 삭제되었다",
    "financial_management_is_disable": "이 재테크 유형은 이미 금지되어 있다",
    "financial_management_not_cancel": "정기 투자 재테크 취소!",
    "financial_management_number_not_found": "투자 수량이 헛되이 해서는 안 된다.",
    "financial_management_record_add_failure": "사용자 개인 자산이 부족하니, 충전하고 재테크 투자를 하시오!",
    "financial_management_record_less_than_min_number": "설정의 최소 수량 추가하기 {}",
    "financial_management_type_not_found": "재테크 기록 정보 표시 실패, 재테크 형식 선택 오류!",
    "financial_management_type_not_null": "투자 유형은 비워서는 안 된다.",
    "find_password": "비밀번호 검색",
    "fingerprint": "지문",
    "fingerprint_verification": "지문 인증",
    "first_bind_phone": "먼저 전화 번호를 묶어주세요.",
    "first_period": "제1기",
    "following_account": "다음 계정으로",
    "free": "수수료",
    "Takerfree": "테이커 무료",
    "Makerfree": "메이커 무료",
    "freeze": "동결",
    "freeze_to_be_processed": "동결 호소,처리 대기",
    "fuc_use_discount": "{} 교역 수수료 지급",
    "futu": "보조지도",
    "gathering": "수금",
    "gears": "레벨",
    "generation1": "제1대",
    "gesture_error_limit": "패턴비번 오류 횟수 상한 초과",
    "gesture_password": "제스처 암호",
    "gesture_unlock": "제스처 잠금 해제",
    "get_code": "코드 취득",
    "goSign": "등록하십시오",
    "go_change": "수정하기",
    "go_login": "먼저 로그인하십시오.",
    "go_pay": "지불하기",
    "go_setting": "설정으로 이동",
    "go_to_pick_up": "픽업하러 가기",
    "go_to_receiving": "돈을 모으기 위해서",
    "go_to_redeem": "사용으로 이동",
    "google_verification_code": "Google 인증 코드",
    "grievance_freeze": "민원 고사",
    "has_been_on": "선반 위에 있습니다",
    "have_been_frozen": "냉동",
    "have_received": "받았습니다",
    "help_center": "도움말 센터",
    "hide_canceled": "숨겨진 취소",
    "hide_other": "보류중인 모든 주문 표시",
    "high_frequency_partner": "고주파 파트너",
    "highest_single": "단일 최고",
    "highfrequency_fee_sharing": "고주파 수수료 배당",
    "hint": "알림",
    "hint_password": "비밀번호를 입력하십시오.",
    "hint_phone_email": "핸드폰/메일함 입력하세요.",
    "historical_transaction": "역사적 거래",
    "history": "연혁",
    "home_page": "홈",
    "horizontal_version": "가로 버전",
    "hours": "h",
    "i18n_failure_0001": "시스템 언어 쿼리가 실패했습니다.",
    "i18n_failure_0002": "국가 사전 쿼리에 실패했습니다.",
    "iTo_draw_again": "비밀번호를 다시 입력하십시오.",
    "i_see": "알아",
    "i_see1": "알아",
    "id_card_back_direction_failure": "ID 카드 뒷면의 이미지 방향이 잘못되었습니다.",
    "id_card_front_direction_failure": "ID 카드의 앞면 이미지 방향이 잘못되었습니다.",
    "id_cord": "ID 번호",
    "id_type": "유형",
    "identification": "식별 실패",
    "identity_authentication": "신분 인증",
    "identity_card": "신분증",
    "if_at": "에서",
    "ignore": "무시하다",
    "immediately_invited": "지금 초대하기",
    "immediately_join": "지금 가입하십시오",
    "in_trading": "거래에서",
    "income": "수익",
    "income_currency_type": "유형",
    "income_deposit_principal": "저금",
    "incomeing": "수익",
    "increment quantity": "입력 수량은 최대 증가 수량을 초과할 수 없습니다.",
    "indirect_promotion": "간접 프로모션",
    "indirect_reward": "간접 보상",
    "individual_selling_tips": "주문을 자기에게 팔 수 없다.",
    "input_Branch_address": "지점 주소를 입력하십시오.",
    "input_address_labels": "라벨을 입력하십시오.",
    "input_alipay_account": "Alipay 계정을 입력하십시오.",
    "input_bank_card_number": "은행 카드 번호를 입력하십시오.",
    "input_bank_name": "은행 이름을 입력하십시오.",
    "input_buyer_uid": "구매자 UID를 입력하십시오.",
    "input_currency_referred": "화폐의 약칭을 입력하십시오",
    "input_deposit_amount": "예금 수량을 입력하십시오",
    "input_email": "이메일 번호를 입력하십시오.",
    "input_email_code": "이메일 인증 코드를 입력하십시오.",
    "input_id_cord": "ID 번호를 입력하십시오.",
    "input_lock_amount": "잠금 수를 입력하십시오",
    "input_mention_money_address": "동전 주소를 입력하십시오.",
    "input_money": "금액을 입력하십시오.",
    "input_name": "이름을 입력하십시오.",
    "input_newphone_code": "새 전화 번호를 입력하세요",
    "input_nike_name": "닉네임을 입력하십시오.",
    "input_old_google_code": "이전 Google 인증 코드를 입력하십시오.",
    "input_old_pass": "이전 암호를 입력하십시오.",
    "input_paypal_acctount": "페이팔 계정을 입력하십시오.",
    "input_phone": "휴대 전화 번호를 입력하십시오.",
    "input_phone_code": "인증 코드를 입력하십시오.",
    "input_price": "가격을 입력하십시오.",
    "input_quantity": "수량 입력",
    "input_remark": "메모를 입력하십시오.",
    "input_service_charge": "수수료를 입력하십시오.",
    "input_trading_volume": "거래 금액을 입력하십시오",
    "input_transaction_password": "펀드 비밀번호를 입력하십시오.",
    "input_wechat_account": "WeChat 계정을 입력하십시오.",
    "input_wire_transfer": "계좌 이체 주소를 입력하십시오.",
    "inputs_numbers_greater_can_number": "입력 수가 전송 횟수보다 큽니다.",
    "insufficient_light": "불충분 한 빛",
    "interest": "이자",
    "invitation_details": "상 장려 자세한 상황을 초청하다",
    "invitation_register_ad_not_found": "앱 공유 초대장 등록 광고가 일시적으로 존재하지 않습니다.",
    "invitation_register_ad_tatle_not_found": "앱 공유 초대장 등록 광고 제목이 당분간 존재하지 않습니다.",
    "invitation_register_coin_not_found": "앱 공유 초대장 보상 통화가 일시적으로 존재하지 않습니다.",
    "invitation_register_logo_not_found": "앱 공유 초대장 등록 로고가 일시적으로 존재하지 않습니다.",
    "invitation_register_not_found": "공유 초대 등록 연결이 일시적으로 존재하지 않습니다.",
    "invite_code": "초대 코드 (선택 사항)",
    "invite_commission": "커미션",
    "invite_gift": "초대 요청",
    "invite_reward": "장려하다",
    "invited_UID": "초대 UID",
    "invitees_are_available": "초청자가 받을 수 있다",
    "inviter-is-user": "백필 된 초대받은 사람은 내 자신이 될 수 없습니다.",
    "inviter-not-exist": "백업 된 초대 대상자가 존재하지 않습니다.",
    "inviter_no_null": "사람을 공짜로 추천하면 안 돼요",
    "ios_24小时排行榜": "24 시간 리더 보드",
    "ios_OTC交易须知": "장외 시장 거래 메모",
    "ios_PAYPAL": "페이팔",
    "ios_otc_order_prompt_001": "1 분 이내에 지불하지 않고 '지불 확인'버튼을 클릭하면 카운트 다운이 끝난 후 시스템에서 자동으로 주문을 취소합니다.",
    "ios_otc_order_prompt_002": "몇 분 안에 판매자에게 지불이 이루어지지 않고 '지불 확인'버튼을 클릭하면 카운트 다운이 끝난 후 시스템에서 자동으로 주문을 취소합니다.",
    "ios_万": "만",
    "ios_上传身份证反面": "뒷면에 ID 카드 업로드",
    "ios_上传身份证正面": "ID 카드 앞면 업로드",
    "ios_两次密码不一致请再次输入密码": "암호가 두 번 일치하지 않으면 암호를 다시 입력하십시오.",
    "ios_个确认大约需要": "확인이 필요하다.",
    "ios_中国大陆": "중국 대륙",
    "ios_中级合伙人": "중간 파트너",
    "ios_主版块": "주요 섹션",
    "ios_之间": "사이",
    "ios_买单": "청구서 지불",
    "ios_买方必须成交过几次(0等于不限制)": "매수자는 몇번 체결완료해야 합니다. (0는 제한 없음)",
    "ios_二维码/条形码": "QR 코드 / 바코드",
    "ios_付款": "지불",
    "ios_充币地址": "통화 주소",
    "ios_先设置交易密码": "트랜잭션 암호를 먼저 설정하십시오.",
    "ios_关闭照明": "조명 끄기",
    "ios_分享失败": "공유 실패",
    "ios_分享成功": "나눔의 성공",
    "ios_初级合伙人": "주니어 파트너",
    "ios_卖单": "주문 판매",
    "ios_卖家已付款提示": "판매자가 팁을 지불했습니다.",
    "ios_卖方必须成交过几次(0等于不限制)": "매도자는 몇번 체결완료해야 합니다.(0는 제한 없음)",
    "ios_去解绑": "끄르다 묶",
    "ios_发布成功": "성공적으로 출시 됨",
    "ios_发送成功": "성공적으로 보냈습니다.",
    "ios_各种费率说明": "다양한 요금 설명",
    "ios_团队人数不足": "팀수가 부족하다",
    "ios_复制QQ号": "QQ 번호 복사",
    "ios_复制地址": "주소 복사",
    "ios_如果你已完成线下付款，请点击下方已付款按钮。如果未完成付款，却点击已付款将会受到严厉惩罚，甚至永久冻结账户！": "오프라인 지불을 완료 한 경우 아래의 유료 버튼을 클릭하십시오. 지불을 완료하지 않으면 지불을 클릭하거나 영구적으로 계정을 정지 시키면 심각한 처벌을 받게됩니다!",
    "ios_客户端或在线钱包将您需要充值的": "클라이언트 또는 온라인 지갑을 충전해야합니다.",
    "ios_客户端或在线钱包将您需要充幣的": "클라이언트 또는 온라인 지갑을 충전해야합니다.",
    "ios_客服": "고객 서비스",
    "ios_密码设置成功！": "암호가 성공적으로 설정되었습니다!",
    "ios_将该地址设置为认证地址": "이 주소를 인증 주소로 설정하십시오.",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充值，不影响到账。\n3、最小充值金额": "시간, 잠시 기다려주십시오.\n2, 동일한 주소는 계정에 영향을주지 않습니다 여러 번 재충전 할 수 있습니다.\n3. 최소 충전 금액",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充幣，不影响到账。\n3、最小充幣金额": "시간, 잠시 기다려주십시오.\n2, 동일한 주소는 계정에 영향을주지 않습니다 여러 번 재충전 할 수 있습니다.\n3. 최소 충전 금액",
    "ios_已付款": "유료",
    "ios_币币": "코인-코인",
    "ios_常见问题": "자주 묻는 질문",
    "ios_开启照明": "조명 켜기",
    "ios_意见反馈": "피드백",
    "ios_成交": "최근 거래",
    "ios_我的": "내",
    "ios_手机号错误": "휴대 전화 번호 오류",
    "ios_提交成功，等待认证": "제출 완료, 인증 대기 중",
    "ios_提币数量不能大于手续费": "동전의 금액은 취급 수수료보다 클 수 없습니다.",
    "ios_提币数量减去手续费后不能": "처리 비용을 지불 한 후에는 동전의 양을 줄일 수 없습니다.",
    "ios_收款二维码": "영수증 QR 코드",
    "ios_收藏成功": "성공적인 수집",
    "ios_数目发送到该地址。发送完成后，系统会自动在此交易获得": "번호가이 주소로 전송됩니다. 전송이 완료되면 시스템이 자동으로 트랜잭션을 가져옵니다.",
    "ios_暂无网络": "네트워크 없음",
    "ios_最多可锁": "최대 자물쇠",
    "ios_最大限额": "최대 한도",
    "ios_最小限额": "최소 한도",
    "ios_未解锁": "잠그지 않음",
    "ios_没有中国大陆身份证的用户请通过网站版高级认证": "대륙 신분증이 있는 사용 자는 사이트 판을 통해 고급 인증을 받 으세요",
    "ios_法币": "OTC",
    "ios_注册表示同意": "레지스트리 동의",
    "ios_注册表示同意用户协议": "등록은 사용자 계약에 동의합니다.",
    "ios_登录过期，请重新登录": "로그인 만료,재 로그인하십시오.",
    "ios_直接推荐人uid": "직접 추천자 UID",
    "ios_直推人数不足": "인원수가 부족하다",
    "ios_绑定成功": "바인딩 성공",
    "ios_请上传您的身份证": "ID 카드를 업로드하십시오.",
    "ios_请上传身份证反面照": "신분증을 업로드하십시오.",
    "ios_请上传身份证正面照": "신분증 사진을 업로드하십시오.",
    "ios_请先绑定收款方式": "먼저 결제 수단을 바인딩하십시오.",
    "ios_请先设置支付方式": "먼저 지불 방법을 설정하십시오.",
    "ios_请输入价格和数量": "가격과 수량을 입력하십시오.",
    "ios_请输入新邮箱": "새 이메일 주소를 입력하십시오.",
    "ios_请输入正确的账号格式": "올바른 계정 형식을 입력하십시오.",
    "ios_请输入身份证号": "ID 번호를 입력하십시오.",
    "ios_请输入限额": "한도를 입력하십시오.",
    "ios_请通过": "제발 패스",
    "ios_资产": "자산",
    "ios_超时": "제한 시간",
    "ios_身份证号": "ID 번호",
    "ios_退出成功": "성공적으로 종료",
    "ios_邀请人可获得 * 的佣金": "초대인은 * 커미션을 받을 수 있다",
    "ios_锁仓数量不能为空": "창고 수량을 비울 수 없다",
    "ios_间接接推荐人uid": "간접 참조 용 UID",
    "ios_隐藏其他挂单": "기타 주문 숨기기",
    "ios_高级合伙人": "상급 파트너",
    "is_bind": "바인딩",
    "is_delete_select": "선택 삭제 여부",
    "issued": "발행 됨",
    "issued_to_lock": "잠금을 위해 발행 됨",
    "k_close": "수신",
    "k_max": "높음",
    "k_min": "낮음",
    "k_num": "금액",
    "k_open": "열기",
    "kline_target": "지표",
    "language": "언어",
    "lead_content": "주문 문의, 우편 광고, 결제 설정,주의 / 블랙이 여기로 이동!",
    "least_four": "적어도 4 포인트를 연결하십시오.",
    "less-than-min": "최소 잠금 한도 제한",
    "lf there are pending": "보류 중인 닫기 주문(Limit 또는 Stop)이 있는 경우 모두 닫기 전에 취소됩니다.",
    "limit": "설정제한",
    "limit_orderAA": "지정가 주문",
    "limit_set": "한도 설정 (선택 사항)",
    "limited_price": "제한 가격",
    "liquidation": "강제 판매",
    "liquidation AMT.": "청산 건수",
    "liquidation details": "청산내역",
    "loading": "로드 중",
    "lock-expire": "창고 잠그는 이미 기한이 지났다.",
    "lock_amount": "창고 수량",
    "lock_duration": "창고를 잠그다",
    "lock_person": "창고를 파는 사람",
    "lock_record": "저장소 기록",
    "lock_remaining": "창고 여량",
    "lock_reward": "창고를 채우다.",
    "lock_time": "창고 잠금 시간",
    "locked": "창고를 잠그다",
    "locked_closed": "이미 결산하다",
    "locked_insufficient_balance": "잔액이 부족하다",
    "locked_settled": "결제",
    "locked_unsettlement": "미결제",
    "locked_warehouse": "창고",
    "log_in": "로그인",
    "login": "로그인",
    "login_expired": "로그인 무효,재 로그인하십시",
    "login_forget_pwd": "비밀번호 잊음",
    "login_name_txt": "사용자 이름",
    "login_pass": "로그인 비밀번호",
    "logion_welcome_txt": "로그인하세요",
    "mail": "사서함",
    "mailbox": "사서함 인증",
    "mailbox_bind_success": "사서함 바인딩 성공",
    "mailbox_new": "사서함",
    "main_picture": "주요 사진",
    "manage_reward_details": "관리 상장 정보",
    "management_reward": "관리 장려",
    "management_share": "관리 나누기",
    "mandarin": "북경어의 숫자를 읽으십시오.",
    "mandarin_reading_content": "다음을 표준어로 읽어주세요",
    "manual_recharge_msg_status_0001": "재충전을 기다리는 중",
    "manual_recharge_msg_status_0002": "승인 됨",
    "manual_recharge_msg_status_0003": "충전 성공",
    "manual_recharge_msg_type_0001": "일반 재충전",
    "manual_recharge_msg_type_0002": "자금 처리",
    "manual_recharge_msg_type_0003": "이벤트 선물",
    "margin": "여유",
    "market_failure_00001": "실패한 홈페이지 가져 오기",
    "market_failure_00002": "판매자 ID가 없습니다.",
    "market_failure_00003": "판매자가 사용할 수있는 거래 지역이 없습니다.",
    "market_failure_00004": "통화 거래를 가져 오지 못함",
    "market_failure_00005": "거래 쌍 이름은 비워 둘 수 없습니다.",
    "market_failure_00006": "판매자가 사용할 수있는 섹션 정보가 없습니다.",
    "market_failure_00007": "거래 정보를 가져 오는 데 실패했습니다.",
    "market_failure_00008": "K 회선 데이터를 가져 오는 데 실패했습니다.",
    "market_failure_00009": "깊이 데이터를 가져 오지 못했습니다.",
    "market_failure_00010": "요청 매개 변수 오류",
    "market_failure_00011": "잘못된 거래 쌍",
    "market_low_price": "시장 최고 구매 가격",
    "market_order": "시장가 주문",
    "market_price": "시장 가격",
    "market_sell_price": "시장 최소 판매 가격",
    "market_switch_moved_here": "수평 / 수직 시장 스위치가 여기로 이동되었습니다.",
    "match": "경기",
    "match_the_number_of": "경기 수",
    "max_lock_volume": "최대 사물량",
    "maximum_30_characters": "최대 30 글자",
    "maximum_coins": "동전의 최대 금액은",
    "maximum_withdrawal": "단일 최대 인출",
    "mention_a_currency": "동전 수",
    "min_lock_volume": "최소 창고",
    "min_recharge_amount": "소액이 입금되지 않았습니다. 즉, 재충전이 성공적으로 완료되지 않고 누적 입금액이 최소 금액 {}에 도달합니다. * 자동 입금됩니다",
    "minhour": "시분할",
    "minimum_coins": "동전의 최소 금액은",
    "minimum_purchase_quantity": "구매 수량이 이 목록 잉여 수량보다 높다 {}",
    "minimum_quantity_sold": "판매량은 이 목록 잉여 수량보다 크다 {} 이다",
    "minutes": "m",
    "mobile phone": "핸드폰 번호",
    "money": "금액",
    "more": "기타",
    "more Or than": "롱 포지션: 액면가 x 계약 수 x 평균 종가 x 액면가 x 계약 수 x 평균 온닝 가격\n\n숏 포지션: 액면가 x 계약 수 x 평균 시가-액면가 x 계약 수 x 평균 종가",
    "more-than-max": "최대 잠금 한도를 초과하다",
    "more11": "더",
    "more_can_save": "최대 예금",
    "more_home": "더많이",
    "msg_camera_framework_bug": "카메라 사용 권한을 설정하십시오.",
    "must_retain_one": "휴대 전화, Google 및 편지함에는",
    "my_income": "나의 수익",
    "my_prompt_001": "* 휴대 전화 번호가 성공적으로 수정 된 후에는 새 휴대 전화 번호로 로그인해야합니다.",
    "my_prompt_002": "* 팁 : 실명 계좌를 사용해야하며, OTC 거래시 활성화 된 결제 방법이 구매자에게 표시됩니다. 5 가지 유형을 설정할 수 있지만 3 가지 유형을 활성화 할 수 있습니다.",
    "my_prompt_003": "* 사서함이 성공적으로 수정 된 후에는 새 사서함으로 로그인해야합니다.",
    "my_vote": "내 투표",
    "name": "이름",
    "name_empty": "이름은 비워 둘 수 없습니다.",
    "nationality": "시민권",
    "need_me_record_video": "비디오를 녹화해야합니다",
    "never_enter": "거래 비밀번호를 입력하지 마십시오",
    "new_address": "주소 추가",
    "new_pass": "새 암호",
    "new_pass_empty": "새 암호를 입력하십시오.",
    "new_pass_minimum": "비밀번호는 6 자리 이상이어야합니다.",
    "new_report_statistics": "새로운 보고서 통계",
    "newest_versions": "최신 버전",
    "next": "다음 단계",
    "nick_name": "별명",
    "nickname_des_txt": "닉네임은 중국어, 영어, 숫자 만 지원하며 길이는 15 자로 제한됩니다.",
    "nighttime": "밤에",
    "ninety_days": "거의 90 일",
    "no-lock-detail": "저장소 정보 없음",
    "no_Senior_certification": "먼저 고급 인증을 수행하십시오.",
    "no_data": "데이터 없음",
    "no_flash": "현재 장치에는 플래시가 없으므로 조명을 켤 수 없습니다.",
    "no_more_data": "데이터 없음",
    "no_photo_taking": "장치가 사진 촬영을 지원하지 않습니다.",
    "no_price": "가격은 비워 둘 수 없습니다.",
    "no_quantity": "올바른 수량을 입력하십시오.",
    "no_realname_auth": "실명 인증 미진행",
    "no_use": "활성화되지 않음",
    "none": "없다",
    "not_bound": "언 바운드",
    "not_frequency_partner": "넌 아직 파트너 가 아니 야.",
    "not_redemption": "보너스 신청 자격이 없습니다",
    "not_started": "시작되지 않음",
    "not_to_issue": "발행되지 않음",
    "not_trading": "그 화폐는 현재 거래할 수 없다",
    "notpass": "감사가 실패했습니다.",
    "null_address_tag": "주소 라벨은 비워 둘 수 없습니다.",
    "null_code": "인증 코드는 비워 둘 수 없습니다.",
    "null_email": "사서함은 비워 둘 수 없습니다.",
    "null_password": "비밀번호는 비워 둘 수 없습니다.",
    "null_phone": "전화 번호는 비워 둘 수 없습니다.",
    "number": "개",
    "number_empty": "수량",
    "number_of_arrivals": "도착 수",
    "number_of_issues": "총량을 배출하다",
    "number_of_withdrawal": "일일 인출",
    "obtained": "선반 아래",
    "occlusion": "오 클루 젼 페이스",
    "occupy": "점령하다",
    "official_community": "공식 커뮤니티",
    "official_verification_channel": "인증 채널",
    "old_pass": "이전 암호",
    "old_pass_empty": "원래 비밀번호를 입력하십시오.",
    "on_sale": "판매",
    "online_service": "선상 고객",
    "open XKD perpetual swap": "BitMatrix 영구 스왑 열기",
    "open perpetual swap": "영구 스왑 열기",
    "open_app": "앱 열기",
    "open_it": "열기",
    "open_successfully": "성공적으로 열기",
    "open_touchID": "TouchID가 켜져 있습니까?",
    "opposite_side_uid": "서로",
    "option": "옵션",
    "optional": "선택 사항",
    "optional_area": "자선구",
    "options": "선택 방법",
    "order": "주문서",
    "order time": "주문 시간",
    "order time1": "주문 시간",
    "order_a_complaint": "주문 항소",
    "order_details": "주문 세부 정보",
    "order_form": "주문서",
    "order_history": "주문 역사",
    "order_inquiry": "주문 조회",
    "order_number": "주문 번호",
    "order_time": "주문 시간",
    "ordinary_partner": "일반 파트너",
    "otc_account": "계정",
    "otc_confirm_Put_the_coin": "상대방의 송금을 받았는지 확인하십시오 (경고 : 지불을 확인하기 전에 클릭하지 마십시오. 분쟁이있는 경우 고객 서비스에 신고하십시오)",
    "otc_confirm_the_payment": "지불 확인",
    "otc_confirm_the_receipt": "영수증 확인",
    "otc_failure_0001": "계정이 사용 중지되어 있으므로 가능한 한 빨리 고객 서비스 팀에 문의하십시오.",
    "otc_failure_0002": "계정이 고급 인증을 통과하지 못했으며 OTC 작업을 수행 할 수 없습니다.",
    "otc_failure_0003": "계정 거래 비밀번호가 잘못되었습니다.",
    "otc_failure_0004": "주문 ID가 잘못되었습니다.",
    "otc_failure_0006": "상태 변경 실패",
    "otc_failure_0009": "보류중인 주문 유형이 없습니다.",
    "otc_failure_0010": "실행 취소 실패",
    "otc_failure_0011": "보류중인 주문이 현재 완료되었거나 취소되었습니다.",
    "otc_failure_0012": "현재 사용자는 OTC 판매자가 아니며이 기능에 액세스 할 수 없습니다.",
    "otc_failure_0013": "해빙 실패, 실행 취소 실패",
    "otc_failure_0015": "보류중인 주문 유형이 없습니다.",
    "otc_failure_0016": "대기중인 주문 수는 0보다 작거나 같을 수 없습니다.",
    "otc_failure_0017": "거래 가격은 0보다 작거나 같을 수 없습니다.",
    "otc_failure_0018": "단일 최소 한도는 0보다 작을 수 없습니다.",
    "otc_failure_0019": "최대 한도는 최소 한도보다 작을 수 없습니다",
    "otc_failure_0020": "통화를 지원하지 않습니다.",
    "otc_failure_0021": "통화를 현재 사용할 수 없습니다.",
    "otc_failure_0022": "잘못된 비즈니스 통화 ID",
    "otc_failure_0023": "하나의 최소 한도는 통화 {}의 최소 통화보다 작을 수 없습니다.",
    "otc_failure_0024": "하나의 최대 한도는 통화 {}의 최대 크기보다 클 수 없습니다.",
    "otc_failure_0025": "보류중인 주문의 소수 자릿수가 {}보다 큽니다.",
    "otc_failure_0026": "보류중인 주문의 총 금액은 주문 한도 인 {}보다 낮을 수 없습니다.",
    "otc_failure_0027": "보류중인 주문의 총액은 사용자 한도 인 {}을 초과 할 수 없습니다.",
    "otc_failure_0028": "보류중인 주문의 총액은 판매자 한도 {}보다 클 수 없습니다.",
    "otc_failure_0029": "거래 가격은 최저 가격 {}보다 낮을 수 없습니다.",
    "otc_failure_0030": "거래 가격은 최대 가격 {}보다 클 수 없습니다.",
    "otc_failure_0031": "현재 사용자는 결제 계좌에 묶여 있지 않습니다.",
    "otc_failure_0032": "고정 실패, 보류중인 주문 실패",
    "otc_failure_0033": "동시에 처리 할 수있는 최대 주문 수는 0보다 작을 수 없습니다.",
    "otc_failure_0034": "구매자는 0보다 작아서는 안됩니다.",
    "otc_failure_0035": "사용자가 인증되었거나 검토 중입니다.",
    "otc_failure_0036": "성공적인 OTC 거래 횟수가 충분하지 않습니다.",
    "otc_failure_0037": "해빙 실패, 실행 취소 실패",
    "otc_failure_0038": "신청자의 현재 계정 {} 잔액이 부족합니다 {}",
    "otc_failure_0039": "현재 통화 없음",
    "otc_failure_0040": "현재 통화를 사용할 수 없습니다.",
    "otc_failure_0041": "성공적으로 제출되었습니다. 검토 대기 중입니다.",
    "otc_failure_0042": "죄송합니다. 사용자는 현재 검토 대기 중이며 다시 신청할 수 없습니다.",
    "otc_failure_0043": "그러한 거래 유형 없음",
    "otc_failure_0044": "자기 판매 금지",
    "otc_failure_0046": "이 보류중인 주문이 최대 주문 수를 처리했으며 {}이 (가) 한도에 도달했습니다. 나중에 다시 시도하십시오.",
    "otc_failure_0047": "구매자가 {} 번 팔아야 함",
    "otc_failure_0048": "구매 횟수는 0보다 작거나 같을 수 없습니다.",
    "otc_failure_0049": "구매 횟수는 대기중인 주문의 현재 남은 수량보다 클 수 없습니다.",
    "otc_failure_0050": "보류중인 주문 ID가 잘못되었습니다.",
    "otc_failure_0051": "총 구매 금액은 최소 통화 거래 {}보다 낮을 수 없습니다.",
    "otc_failure_0052": "구매 금액은 최대 통화 거래 한도 {}보다 클 수 없습니다.",
    "otc_failure_0053": "현재 보류중인 주문 유형은 판매되지 않습니다.",
    "otc_failure_0054": "현재 보류중인 주문 상태가 잘못되었습니다.",
    "otc_failure_0055": "구매 금액은 최소 주문 한도 {}보다 낮을 수 없습니다.",
    "otc_failure_0056": "구매 금액은 최대 주문 수 제한 {}을 초과 할 수 없습니다.",
    "otc_failure_0057": "그런 지위가 없다.",
    "otc_failure_0058": "보류중인 주문 레코드의 수를 변경하십시오. 올바르지 않습니다.",
    "otc_failure_0059": "정지 실패, 주문 실패",
    "otc_failure_0060": "상품 지정 타입에 바인딩을 해 주는 상품 계정을 설정해 주십시오!",
    "otc_failure_0061": "판매자가 {} 회 처리되었을 것임에 틀림 없다.",
    "otc_failure_0062": "판매 수량은 0보다 작거나 같을 수 없습니다.",
    "otc_failure_0063": "판매 수량은 보류중인 주문의 현재 남은 수량보다 클 수 없습니다.",
    "otc_failure_0064": "현재 보류중인 주문 유형은 구매하지 않습니다.",
    "otc_failure_0065": "총 판매 금액은 최소 통화 거래 {}보다 낮을 수 없습니다.",
    "otc_failure_0066": "판매 금액은 최대 통화 거래 한도 {}보다 클 수 없습니다.",
    "otc_failure_0067": "판매 금액은 최소 주문 한도보다 낮을 수 없습니다 {}",
    "otc_failure_0068": "판매 한도는 최대 주문 한도 인 {}을 초과 할 수 없습니다.",
    "otc_failure_0069": "지불 계정 ID가 잘못되었습니다.",
    "otc_failure_0070": "결제 실패 확인",
    "otc_failure_0073": "주문 변경 상태가 실패했습니다.",
    "otc_failure_0074": "통화가 실패하여 지불에 실패했음을 확인했습니다.",
    "otc_failure_0075": "결제 방법은 비워 둘 수 없습니다.",
    "otc_failure_0076": "SMS를 보내지 못했습니다. {}",
    "otc_failure_0077": "거래 통화가 존재하지 않습니다.",
    "otc_failure_0078": "통화가 존재하지 않습니다.",
    "otc_failure_0079": "주문 상태 변경에 실패했습니다.",
    "otc_failure_0080": "거래 통화가 사용 중지되었습니다.",
    "otc_failure_0081": "통화가 사용 중지되었습니다.",
    "otc_failure_0082": "주문이 존재하지 않습니다.",
    "otc_failure_0083": "OTC는 현재 거래 통화를 설정하지 않습니다.",
    "otc_failure_0084": "OTC는 현재 거래 통화를 설정하지 않습니다.",
    "otc_failure_0085": "대기중인 주문 수가 충분하지 않습니다.",
    "otc_failure_0090": "이 계정은 OTC 거래 금지되었습니다. 고객 서비스 팀에 문의하십시오.",
    "otc_failure_0091": "보통사용자는 OTC 주문서를 접수할수 없습니다. 우선 상가로 신청하십시오",
    "otc_failure_0092": "현재 주문 영수증에 시간이 초과되지 않아 구매자가 항소 할 수 없습니다.",
    "otc_failure_0093": "주문에 대한 이의가 제기되었으므로 고객 서비스에 문의하십시오.",
    "otc_failure_0094": "이 작업은 등록한 장소의 규정에 따라 수행 할 수 없습니다.",
    "otc_failure_0095": "이 주문은 거래에 사용할 수 없습니다.",
    "otc_failure_0096": "판매자의 계정 저작물이 비정상이고 작업을 일시적으로 수행 할 수 없습니다.",
    "otc_failure_0097": "당일의 OTC 취소 건수가 한도를 초과하여 거래를 금지했습니다.",
    "otc_failure_0098": "현재 주문서를 완성하지 못했으니 주문서를 완성한 후 계속 주문서를 주십시오.",
    "otc_margin_certification": "보증금 인증",
    "otc_mine_account_paid": "나는 돈을 지불했다.",
    "otc_order_prompt_003": "구매자가 지불 및 결제 '버튼을 확인하기 위해 규정 된 시간 '이내에 클릭하지 않는 경우, 시스템이 자동으로 카운트 다운의 끝에서 주문을 취소합니다",
    "otc_picture_no_null": "하나 이상의 이미지를 업로드하십시오.",
    "otc_prompt_001": "1, 주문 결제는 30분 동안 유효하며, 제시간에 결제하신 후 (결제 완료) 버튼을 클릭해주세요.\n2, 판매자의 통화는 시스템에 잠겨 있으므로 안심하고 주문하세요.",
    "otc_rate": "평가",
    "otc_report_order": "주문",
    "otc_success_0001": "성공적인 취소",
    "otc_success_0002": "성공적인 판매",
    "otc_success_0003": "성공적인 구매",
    "otc_success_0004": "지불 성공 확인",
    "otc_success_0005": "영수증을 확인하고 주문이 끝났습니다.",
    "otc_success_0006": "성공적인 호소",
    "otc_waiting_for_confirmation": "판매자 확인 대기중",
    "other_settings": "다른 설정",
    "overtime_pay": "지불 시간 만료,주문 취소",
    "param-error": "인자 오류",
    "partner": "파트너",
    "partner_commission": "파트너 는 하급 수수료 의 리베이트 를 받 을 수 있다",
    "partner_commission_fee": "수수료 수수료",
    "partner_current_level": "현재 등급",
    "partner_get_all_subordinate": "모든 부하를 가지고 갈 수 있다",
    "partner_management": "파트너 관리",
    "partner_need_pay": "지불이 필요하다",
    "partner_need_recommend": "곧추 밀어야 한다",
    "partner_recommended": "밀다",
    "partner_start_and_end_time": "시작 시간",
    "partner_team": "팀",
    "partner_team_member": "팀수가 필요합니다",
    "partner_upgrade_immediately": "즉시 업그레이드",
    "pass_muster": "요건을 충족하지 못하면 여기를 클릭하십시오.",
    "passed": "합격",
    "passport": "여권",
    "password_disagree": "두 개의 암호가 일치하지 않습니다.",
    "password_is_different": "일치하지 않는 암호 입력",
    "password_text": "비밀 번호",
    "password_wrong": "8-20 로그인 암호 설정",
    "past_activities": "지난 이벤트",
    "pause_recharge": "일시적 충전",
    "pause_withdraw": "일시적 인출",
    "pay_number": "지불 금액",
    "pay_wey": "지불 방법",
    "payee": "수취인",
    "payfor_type": "지불 방법",
    "payment": "지불",
    "payment_amount": "지불 금액",
    "payment_currency": "결제 통화",
    "payment_has_been": "(나는 지불했다)",
    "payment_term": "수집 방법",
    "paypal_account": "페이팔 계정",
    "pending_review": "검토 대기 중",
    "people": "사람들",
    "period": "기간",
    "personal_detail": "개인 정보",
    "personal_purchase_tips": "나만의 주문을 살 수 없다.",
    "phone": "휴대 전화",
    "phone_code": "휴대 전화 인증 코드",
    "phone_register": "모바일 등록",
    "phone_verification": "SMS 확인",
    "phone_verify": "전화 확인",
    "photo_album": "앨범",
    "please_enter": "입력",
    "please_enter_abbreviation_or_letter": "검색을 입력하십시오.",
    "please_input_content": "내용을 입력하십시오",
    "please_pay": "지불하십시오.",
    "please_verification": "확인해주세요.",
    "pop_Date": "날짜",
    "pop_reset": "재설정",
    "position at market price?": "포지션이 시장 가격으로 완전히 마감되었습니까?",
    "post_only": "메이커만",
    "price": "가격",
    "price_cannot_be_less": "가격은 {} 보다 작으면 안 된다",
    "price_cannot_be_more": "가격은 {} 못합니다",
    "price_empty": "가격은 0 이 안 됩니다.",
    "principal": "원금",
    "promote_links": "프로모션 링크",
    "promotion_statistical": "프로모션 통계",
    "prtner_invalid": "실효",
    "publish_a_trade_order": "주문 생성",
    "purchase": "구매",
    "push": "PUSH 자산",
    "push_maximum_volume": "PUSH 수량은 잔액보다 많습니다 {}",
    "push_minimum_trading_volume": "PUSH 수량이 최소 거래량보다 적다 {}",
    "quantity": "수량",
    "quantity_deal": "수량 / 거래",
    "quota": "한도",
    "range": "범위",
    "real_income": "실발 수익",
    "real_name_authentication": "실명 인증",
    "reason": "이유",
    "rebate_amount": "리베이트 금액",
    "rebate_record": "복귀 기록",
    "rebate_reward": "리베이트 보상",
    "receiving_set": "컬렉션 설정",
    "receiving_set_title": "먼저 지불 방법을 설정하십시오.",
    "recently_deal1": "최근 거래",
    "recharge_notice": "충전 숙지 사항\n1、필요한 {} 충전 금액은 {} 클라이언트 또는 온라인 지갑을 통해서 해당 어드레스로 전송됩니다. 전송 완료 후 시스템은 자동으로 이번 거래서의 {}번의 확인 후 상응한 가상 화폐를 고객님의 본 사이트 계(정)에 충전되오니 인내성 있게 기다려 주시길 바랍니다。\n2、동일한 어드레스로 여러 번 충전이 가능하며 입금에도 영향이 없습니다。\n3、최소 충전 금액은 {} 입니다。",
    "recharge_source": "충전 공급원",
    "recharge_withdraw_failure_0001": "네트워크 이상",
    "recharge_withdraw_failure_0002": "거래 비밀번호는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0003": "인증 코드는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0004": "판매자 ID는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0005": "사용자 ID는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0006": "사용자가 존재하지 않습니다.",
    "recharge_withdraw_failure_0007": "통화 ID는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0009": "통화가 존재하지 않습니다.",
    "recharge_withdraw_failure_0010": "Google 인증 코드는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0011": "거래 비밀번호가 잘못되었습니다.",
    "recharge_withdraw_failure_0012": "충전 유형은 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0013": "재충전 횟수는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0014": "충전 메모는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0015": "청구 ID는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0016": "충전 목록이 존재하지 않습니다.",
    "recharge_withdraw_failure_0017": "충전 대기 만 삭제할 수 있습니다.",
    "recharge_withdraw_failure_0018": "충전을 검토하기 만 기다립니다.",
    "recharge_withdraw_failure_0019": "승인 승인 만 동결이 가능합니다.",
    "recharge_withdraw_failure_0020": "버전 번호는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0021": "매개 변수 오류",
    "recharge_withdraw_failure_0022": "현재 데이터가 업데이트되었습니다.",
    "recharge_withdraw_failure_0023": "ID는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0024": "잠긴 상태 만 검토 할 수 있습니다.",
    "recharge_withdraw_failure_0025": "철수 상태가 잠길 때까지 기다립니다.",
    "recharge_withdraw_failure_0026": "현금 인출 또는 잠금 상태를 기다리는 경우에만 취소",
    "recharge_withdraw_failure_0027": "고정 수는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0028": "동결 이유는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0029": "해동 한 메모는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0030": "동전의 양은 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0031": "동전 주소는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0032": "수수료는 비워 둘 수 없습니다.",
    "recharge_withdraw_failure_0033": "비정상적인 수수료",
    "recharge_withdraw_failure_0034": "데이터가 존재하지 않습니다.",
    "recharge_withdraw_failure_0035": "자산 목록을 해동 할 수 없습니다.",
    "recharge_withdraw_failure_0036": "동전 주소가 잘못되었습니다.",
    "recharge_withdraw_failure_0041": "계정에서 동전 회수를 금지했습니다. 고객 서비스에 문의하십시오.",
    "recharge_withdraw_failure_0042": "동전 수는 하루의 한도에 도달했습니다.",
    "recharge_withdraw_failure_0043": "제기 된 동전의 총 금액이 당일 한도를 초과합니다.",
    "recharge_withdraw_failure_0044": "비정상적인 VIP 레벨 정보 얻기",
    "recharge_withdraw_failure_0045": "비정상적인 통화 정보 얻기",
    "recharge_withdraw_failure_0046": "암호를 재설정 한 후 24 시간 이내에 동전을 인출하지 마십시오!",
    "recharge_withdraw_failure_0048": "단일 값 최대 값 예외 가져 오기",
    "recharge_withdraw_failure_0049": "동전 최소값 예외 하나 받기",
    "recharge_withdraw_failure_0050": "입력 수가 최대 제한보다 작아야합니다.",
    "recharge_withdraw_failure_0051": "입력 수가 최소 한도보다 커야합니다",
    "recharge_withdraw_msg_status_0001": "철수를 기다리는 중",
    "recharge_withdraw_msg_status_0002": "보내는",
    "recharge_withdraw_msg_status_0003": "현금 인출 성공",
    "recharge_withdraw_msg_status_0004": "취소",
    "recharge_withdraw_msg_status_0005": "확인",
    "recharge_withdraw_msg_status_0006": "충전 성공",
    "recharge_withdraw_msg_status_0007": "검토 된",
    "recharge_withdraw_msg_type_0001": "재충전",
    "recharge_withdraw_msg_type_0002": "탈퇴",
    "recharge_withdraw_msg_withdraw_type_0001": "플랫폼 교환",
    "recharge_withdraw_msg_withdraw_type_0002": "외부 코인",
    "record": "기록",
    "record_the_video": "클릭하여 비디오 녹화",
    "recording": "레 코드",
    "redeem_now": "지금 사용하십시오",
    "redeemed": "사용됨",
    "redeeming": "교환",
    "redemption_amount": "상환 금액",
    "redemption_time": "사용 시간",
    "referrer": "추천자",
    "register": "등록",
    "registered_successfully": "성공적인 등록",
    "registration_award_details": "등록 장려 자세한 상황",
    "registration_incentives": "등록 보상",
    "registration_time": "등록 시간",
    "regular": "정기",
    "regular_return": "정기적으로 돌아오다",
    "release": "발포",
    "release_operation": "주문을 게시하려면 여기를 클릭하십시오!",
    "release_the_order": "출시 명령",
    "release_time": "발송 시간",
    "remark": "비고",
    "remember_state": "계정 기억하기",
    "repeal": "취소",
    "reset": "재설정",
    "reset_login_password": "로그인 암호 수정",
    "reset_pass": "비밀번호 재설정",
    "residue": "남은",
    "retrieve_the_deposit": "저금 을 되찾다",
    "return_amount": "귀환 이자 수량",
    "return_method": "이자를 되돌리는 방식",
    "return_the_principal": "원금을 반환하다",
    "revenue_details": "수익 상세 상황",
    "revocation_all": "모두 취소",
    "reward_amount": "장려 수량",
    "reward_locked": "창고를 장려하다",
    "reward_status": "보상 상태",
    "reward_type": "보상 유형",
    "rewards_record": "보상 기록",
    "right_now_register": "새 사용자 등록",
    "roll_out": "전송",
    "save": "보존하다",
    "save_coin": "넣다",
    "save_coin_count": "예금 수량",
    "save_coin_days": "입금일",
    "save_coin_duration": "재고 기간",
    "save_currency": "저금하다",
    "save_money_amount": "저폐 총량",
    "save_successfully": "성공적으로 저장되었습니다.",
    "save_the_image": "다운로드",
    "scan_QR_code": " 알 수없는 소스의 QR 코드를 스캔하지 마십시오.",
    "scan_result": "스캔 결과",
    "search": "검색",
    "search_coin_placeholder": "관심있는 통화를 검색하십시오",
    "search_keyword": "검색 키워드",
    "search_name": "검색 이름",
    "search_result": "검색 결과",
    "search_trade_pair_placeholder": "관심있는 거래 쌍을 검색하십시오",
    "security_center": "보안 센터",
    "security_level": "보안 수준",
    "select_date_of_borth": "생년월일을 선택하십시오",
    "select_delete_warn": "삭제할 알림을 선택하십시오",
    "sell": "매출",
    "sell_1": "판매",
    "sell_order": "매각",
    "sell_quantity": "판매 수량",
    "sell_rate": "판매 수수료",
    "seller": "판매자",
    "seller_phone": "반매자 전화번호",
    "send": "보내기",
    "senior_certification": "고급 인증",
    "senior_certification1": "고급 인증",
    "service": "지원QQ :",
    "service_charge": "수수료",
    "session_timeout": "로그인이 만료되었습니다. 다시 로그인하십시오.",
    "set_Password": "수정하기",
    "set_gesture_pass": "제스처 암호를 설정하십시오",
    "set_login_password": "로그인 암호를 설정하십시오.",
    "setting": "설정",
    "setting_alipay": "Alipay 설정",
    "setting_bank_card": "은행 카드 설정",
    "setting_message_warn_tip": "* 저장이 활성화되면 경고 메시지가 표시되고 메시지없이 닫을 수 있습니다.",
    "setting_paypal": "페이팔 설정",
    "setting_paypassword": "트랜잭션 암호를 먼저 설정하십시오.",
    "setting_wechat": "WeChat 설정",
    "setting_western_union": "웨스턴 유니언 (Western Union) 설정",
    "share_invitation_link": "초대 링크 공유",
    "share_link": "친구와 링크 공유",
    "share_to_do": "공유",
    "shift_to": "전송",
    "single_least": "한 펜 최소한도는 {} 보다 적다",
    "single_minimum": "최소 한도",
    "single_more": "단필의 최소한도는 {} 보다 크다",
    "skip": "건너 뛰기",
    "small_exchange": "소액 환전",
    "small_exchange_tip": "* 알림 : 24 시간 이내에 평가가 {} {} 미만인 자산 만 사용할 수 있습니다. 상장 해제 거래의 통화는 일시적으로 전환 할 수 없습니다. 임시 교환 수수료는 {}입니다.",
    "small_exchange_tip-1": "* 알림 : 시장 가격은 실시간으로 변경되며 실제 가격이 우선합니다.상장폐지된 통화는 전환할 수 없으며 현재 전환 수수료는{}",
    "small_exchange_valuation": "평가",
    "small_exchange_valuation-1": "BTC 가치",
    "smallest_unit_price": "PUSH 단가가 최소 단가 {} 보다 작다",
    "so adjusted to": "그래서 조정",
    "sold_out": "제거되었습니다",
    "start time": "시작 시간",
    "start_date": "시작일",
    "start_time": "시작 시간",
    "statistics_report": "통계보고",
    "status": "상태",
    "submit": "비디오 제출",
    "submitBtn": "제출",
    "succeed": "성공",
    "successful_invitation": "성공적인 초대장",
    "successfully_received": "성공적으로 받았습니다",
    "successfully_set": "성공적으로 설정",
    "super_partner": "슈퍼 파트너",
    "switch_account": "계정 전환",
    "system_top_up": "시스템 충전",
    "system_unlock": "시스템 잠금",
    "tab_assets": "자산",
    "tab_home": "홈페지",
    "tab_markets": "인용문",
    "tab_swaps": "계약",
    "tab_trade": "거래",
    "taking_pictures": "사진 찍기",
    "telegraph": "Telegraph:",
    "temporary_not_online_contact_service": "아직 온라인 상태가 아닌 경우 고객 서비스에 문의하십시오",
    "the_day": "일",
    "the_event_is_over": "이벤트가 종료되었습니다",
    "the_latest_price": "최신 가격",
    "the_password_is_correct": "암호가 맞습니다.",
    "theme": "테마",
    "this_month": "이번 달",
    "this_week": "이번 주",
    "ticket": "티켓",
    "time_of_payment": "지불 시간",
    "time_the_input": "{} h 내에 거래 비밀번호를 입력 할 필요가 없습니다.",
    "timeout": "시간 초과 됨",
    "timeout_affirm": "시간 초과가 확인되지 않았습니다.",
    "timeout_deal": "시간 초과 자동 거래",
    "timeout_to_cancel": "지불 시간 만료,주문 취소",
    "to_confirm": "확인하려면",
    "total_buyer": "총 판매자",
    "total_count": "합계",
    "total_exchange": "총 전환",
    "total_lock_amount": "총쇄량",
    "total_money": "총 금액",
    "total_return": "총수익 예상",
    "trade_fee": "거래율",
    "trade_type": "거래 유형",
    "trading_currency": "거래 통화",
    "trading_fiat_currency": "화폐 거래",
    "trading_instructions": "거래 지시",
    "trading_on": "거래쌍",
    "trading_pair_abbreviation": "거래에 대한 약칭을 입력하십시오",
    "trading_this_month": "이번 달 거래",
    "trading_volume": "볼륨",
    "transaction_description": "거래 설명",
    "transaction_password": "기금 비밀 번호",
    "transaction_price": "거래 가격",
    "transfer_number": "금 양도",
    "try_it_now": "지금 사용해보십시오",
    "type": "종류",
    "unable_to_access_album": "현재 기기는 앨범에 대한 액세스를 지원하지 않습니다.",
    "unaccalimed": "미수금",
    "unavailable": "사용할 수 없습니다",
    "under_review": "검토중",
    "univalence": "단가",
    "unlock_details": "자세한 상황을 풀다",
    "unlock_mode": "잠금 해제 방식",
    "unlock_period": "잠금 주기",
    "unlock_plan": "계획 해제",
    "unlock_plan_unlock": "자물쇠를 풀다",
    "unlock_record": "기록 해제",
    "unlock_time": "잠금 해제 시간",
    "unlocked": "잠금 풀기",
    "unredeemed": "미사용",
    "unverified": "인증되지 않음",
    "up_to_three": "최대 3 개 사용",
    "upgrade": "업그레이드",
    "upload_again": "다시 업로드",
    "upload_front": "문서 전면 업로드",
    "upload_identity_card": "ID를 업로드하십시오.",
    "upload_image_success": "이미지 업로드 성공",
    "upload_information_as_required": "위의 요구 사항에 따라 데이터를 엄격하게 업로드해야합니다. 그렇지 않으면 인증이 실패합니다.",
    "upload_passport": "여권을 업로드하십시오",
    "upload_passport_info_picture": "여권 정보 페이지 사진 업로드",
    "upload_reverse": "문서의 뒷면을 업로드하십시오.",
    "ups_and_downs": "당일 등락",
    "ups_downs_habit": "기복 습관",
    "usable": "사용 가능",
    "used": "중고",
    "user-bind-failure": "초청자 납치 실패",
    "user-bind-success": "사람을 초청하여 납치해 성공하였다.",
    "user-collection-error": "실패",
    "user-country-code-not-empty": "국제 코드는 비워 둘 수 없습니다.",
    "user-disable-trade": "당좌 계좌는 거래가 금지되어 있으므로 자세한 내용은 고객 서비스 팀에 문의하십시오!",
    "user-down-notbind": "사용자는 종속 사용자를 바인드 할 수 없습니다.",
    "user-email-fail-often": "메일이 자주 전송되므로 120 초 후에 다시 보내주십시오.",
    "user-email-not-empty": "이메일 주소는 비워 둘 수 없습니다.",
    "user-email-verification-code-out": "사서함 인증 코드가 시간 초과되었습니다. 다시 보내주십시오.",
    "user-fail-advanced-auth": "고급 인증 실패",
    "user-fail-advanced-authed": "사용자는 수석의 인증을 받았습니다.",
    "user-fail-assetnotenough-error": "불충분 한 자산",
    "user-fail-bank-count-error": "최대 3 가지 결제 수단 열기",
    "user-fail-bank-inotc": "결제 수단의 주문이 OTC이며 사용 중지 할 수 없습니다.",
    "user-fail-cardno-repeat-error": "ID 번호가 사용되었습니다.",
    "user-fail-email-code": "이메일 인증 코드 오류",
    "user-fail-google-binded": "Google은 바운드했다.",
    "user-fail-google-code": "Google 인증 코드 오류",
    "user-fail-inviter-notexist": "추천인이 존재하지 않습니다.",
    "user-fail-login-disable": "당좌 계정은 로그인 할 수 없습니다. 자세한 내용은 고객 서비스에 문의하십시오!",
    "user-fail-login-lock": "계정이 잠겼습니다.",
    "user-fail-login-password": "'아이디 혹은 비번 잘못 입력, 입력시도 가능회수 {}",
    "user-fail-mail-binded": "사서함이 바인딩되었습니다.",
    "user-fail-not-realname": "제 1 실명 인증",
    "user-fail-oldpassword-error": "원래 암호가 잘못되었습니다.",
    "user-fail-partner-not-found": "판매자가 존재하지 않습니다.",
    "user-fail-partnernotfound": "판매자가 존재하지 않습니다.",
    "user-fail-password": "사용자 이름 또는 비밀번호가 잘못되었습니다.",
    "user-fail-password-blank": "비밀번호는 비워 둘 수 없습니다.",
    "user-fail-password-blank1": "확인 암호 비울수 없습니다.",
    "user-fail-password-repeat": "사용자가 설정 한 새 암호가 이전 암호와 동일하므로 재설정하십시오!",
    "user-fail-password-thin": "암호 강도가 너무 약함",
    "user-fail-passwword-eq-paypassword": "거래 비밀번호는 로그인 비밀번호와 동일합니다. 재설정하십시오.",
    "user-fail-paypassword-error": "지불 비밀번호 오류",
    "user-fail-paypassword-notset": "먼저 지불 비밀번호를 설정하십시오.",
    "user-fail-paypassword-set": "지불 비밀번호가 설정되었습니다.",
    "user-fail-phone-binded": "전화 번호가 바운드되었습니다.",
    "user-fail-realname-auth": "사용자 실명 인증에 실패했습니다.",
    "user-fail-realname-authed": "사용자가 실제 이름으로 인증되었습니다.",
    "user-fail-realname-notset": "제 1 실명 인증",
    "user-fail-reg": "등록 실패, 서버 오류",
    "user-fail-reg-mail": "사서함 형식이 잘못되었습니다.",
    "user-fail-reg-mail-exist": "사서함이 등록되었습니다.",
    "user-fail-reg-phone-exist": "전화 번호가 등록되었습니다.",
    "user-fail-securitycount": "적어도 하나의 확인 방법을 유지하십시오.",
    "user-fail-sms-code": "SMS 인증 코드 오류",
    "user-fail-sms-code-new": "새 휴대 전화 번호 SMS 인증 코드 오류",
    "user-fail-sms-code-old": "이전 휴대 전화 번호 SMS 인증 코드 오류",
    "user-fail-usernameisblank": "사용자 이름은 비워 둘 수 없습니다.",
    "user-id-or-nationCode—is-null": "사용자 ID 또는 국제 코드가 비어 있습니다.",
    "user-login-success": "로그인 성공",
    "user-mail-fail": "메일을 보내지 못했습니다.",
    "user-mail-success": "성공적인 메일 전달",
    "user-not-exist": "해당 사용자가 존재하지 않습니다.",
    "user-notopen-success": "잠정 개통",
    "user-phone-not-empty": "전화 번호는 비워 둘 수 없습니다.",
    "user-sms-fail": "보내기 실패, 서버 오류",
    "user-sms-fail-often": "자주 SMS 보내기",
    "user-sms-success": "SMS가 성공적으로 전송되었습니다.",
    "user-sms-verification-code-out": "인증 코드 시간이 초과되었습니다. 다시 보내주십시오.",
    "user-success-advanced-auth": "고급 인증 성공",
    "user-success-realname-auth": "사용자 실제 이름 인증이 성공했습니다.",
    "user-validate-code-fail": "인증 코드가 잘못되었습니다.",
    "user-validate-fail": "인증에 실패했습니다.",
    "user-vip-common-fail": "구매 VIP 실패",
    "user_agreement": "합의한 사용자 서비스 계약을 선택하십시오.",
    "user_collection_cencel_success": "취소됨",
    "user_collection_insert_success": "수집 됨",
    "user_pay_disable_state": "이 계정의 거래 비밀번호가 잘못되어 거래가 금지되었습니다. 2시간 후에 다시 시도해 주십시오",
    "user_pay_is_error": "거래 비밀번호가 잘못되었습니다. {}를 2시간 이상 사용할 수 있습니다",
    "user_pay_password_not_setting": "사용자가 거래 비밀번호를 설정하지 않았습니다. 먼저 거래 비밀번호를 설정하십시오.",
    "user_protocol": "사용자 동의서",
    "user_service_agreement": "사용자 서비스 계약",
    "user_vip_has_expire": "이 계정 vip 기한이 업그레이드되지 않습니다!",
    "user_vip_update_failure": "이 계정은 속비 및 업그레이드 지원하지 않습니다, 자세한 사정, update 서비스!",
    "valid_identity_card": "올바른 ID 번호를 입력하십시오.",
    "valuation": "재고 평가",
    "verification_code_error": "인증 코드 오류",
    "verification_failed_001": "확인 실패",
    "verification_finish": "확인 완료",
    "version_updating": "버전 업데이트",
    "versions": "버전",
    "vertical_version": "수직 다이얼",
    "view_activity": "활동보기",
    "view_lock": "창고를 살피다.",
    "volamount": "매수자는 {}번 체결완료해야 합니다.",
    "volamount_sell": "매도자는 {}번 체결완료해야 합니다.",
    "vote": "투표",
    "vote_add_coin": "상장 투표",
    "vote_return": "귀국",
    "voting_coin": "투표 통화",
    "voting_number": "투표",
    "voting_time": "투표 시간",
    "wait_buyer_payment": "구매자 지불 대기 중",
    "wait_for_payment": "지불 대기 중",
    "waiting_for_receiving": "판매자 수금확인 대기중",
    "warm_prompt": "훈훈한 힌트",
    "warning_setTitle": "조기 경고 설정",
    "wechat_account": "WeChat 계정",
    "wechat_upload": "* WeChat 업로드 QR 코드 방법 : WeChat 홈 열기> 내> 월렛> 선금> 사진을 저장하고 휴대 전화 앨범의 영수증 코드를 업로드하십시오.",
    "week": "주",
    "welcome": "BitMatrix 에 오신 것을 환영합니다.",
    "welcome_to": "에 오신 것을 환영합니다.",
    "winning_record": "우승 기록",
    "withdrawal_amount": "일일 출금 금액",
    "withdrawal_charge": "인출 수수료",
    "xilian": "웨스턴 유니온",
    "yesterday_income": "어제 수익",
    "you_have_selected": "선택하셨습니다",

    "Your closing number": "마감 수량이 최소 포지션보다 적습니다!",
    "Your have pending Cross orders": "보류 중인 교차 주문이 있습니다. 레버리지를 조정하기 전에 취소하세요.",
    "Your have pending isolated orders": "보류 중인 격리된 주문이 있습니다. 레버리지를 조정하기 전에 주문을 취소하십시오.",
    "Home page apps": "홈 애플리케이션",
    "Home_Edit": "편집하다",
    "recently_deal01": "거래",
    "depth01": "깊이",
    "depth02": "깊이",
    "discount_currency_introduction01": "통화 소개",
    "Info001": "정보",



    "Avg cost": "평균 원가",

    "Long position": "긴",
    "Short position": "짧은",
    "Hold on": "나중에 얘기해",
    "Done": "완료",
    "new_version": "새 버전 발견",
    "Announcement": "발표",
    "View details": "세부",

    "Option Assets": "옵션 자산",
    "Option Account": "옵션 계정",
    "Option": "옵션",
    "1M": "1분",
    "5M": "5분",
    "15M": "15분",
    "30M": "30분",
    "1H": "1시간",
    "Delivery Time": "매매까지",
    "Call": "상승 경향",
    "Put": "하락 경향",
    "Draw": "보합",
    "Draw2": "보합",
    "Call1": "많다",
    "Put1": "공",
    "Draw1": "보합",
    "Buy-2": "구매",
    "Rate of return": "수익률",
    "Change": "등락폭",
    "Buy Option": "옵션 구매",
    "Awaiting": "매매 대기",
    "Delivery": "나의 매매",
    "Checkout": "매매 기록",
    "Front": "이번 기",
    "Back": "다음 기",
    "Coming soon": "곧 매매",
    "delivery settlement": "매매 결산 중",
    "Ongoing": "구매 중",
    "Pending purchase": "구매 대기",
    "Billing Amount": "결산 수량",
    "Amount": "구매 수량",
    "Delivery result": "매매 결과",
    "Delivery time": "매매 시간",
    "Up": "오르다",
    "Down": "락",
    "Delivery details": "매매 상세 정보",
    "Index Composition": "지수 구성",
    "Exchange": "거래소",
    "Change2": "등락",
    "Weights": "비중",
    "Order number": "주문 번호",
    "Option Session": "Option 시분할",
    "Buy Time": "구매 시간",
    "Opening time": "개장 시간",
    "Bet Type": "구매 유형",
    "Opening Price": "개점 가격",
    "Delivery Price": "매매 가격",
    "Settlement Currency": "결산 코인",
    "K-line": "k 라인",
    "UpSelect": "상승폭 선택",
    "DownSelect": "하락 폭 선택",
    "Front has ended, please place another order": "주문이 완료되었습니다, 다른 주문을 해주세요",
    "Successfully ordered": "주문 성공",
    "Insufficient balance": "잔액 불충분",
    "Quantity purchased must be greater than the minimum limit": "구매 수량은 최소 한도보다 커야 합니다",
    "Quantity purchased must be less than the maximum limit": "구매 수량은 최대 한도 미만이어야 합니다",
    "The current time is not in the purchase time range": "현재 시간이 구매 시간 범위에 속하지 않습니다.",
    "expected": "예상 수익",
    "To_a_game": "게임 하나 더",
    "Settlement Result": "정산 결과",
    "Delivery-1": "매매",
    "Ups and downs": "변동",
    "duration": "지속",
    "Option funds daybook": "옵션어음",

    "Open innovative options trading": "혁신적인 옵션 거래 열기",

    "Confirm Open": "열기 확인",
    "Option Agree Content": "귀하의 권리를 보호하기 위해 옵션을 열기 전에 《혁신적인 옵션 거래 사용 동의서》를 주의 깊게 읽어야 합니다.",
    "Option Agree Content footer": "《혁신적인 옵션 거래 이용 동의서》",
    "Option Agree Content header": "귀하의 권리를 보호하기 위해 옵션을 열기 전에 주의 깊게 읽어야 합니다",

    "Service upgrade, please try again later!": "서비스 업그레이드, 나중에 다시 시도해 주세요!",
    "month": "M",

    "Invitation": "초대",
    "Markets": "인용문",
    "Swaps": "계약",
    "Trade": "거래",
    "Trade-1": "거래",
    "Assets": "자산",
    "Buy": "구입",
    "Help": "돕다",
    "Service": "서비스",
    "Activity": "활동",
    "Communtiy": "지역 사회",
    "No search results": "검색 결과가 없습니다",
    "The option account asset is 0, please transfer from the wallet account to the option account first.": "옵션 계정 자산은 0입니다. 먼저 지갑 계정에서 옵션 계정으로 이체하십시오. ",
    "Perpetual account assets are 0, please transfer from wallet account to perpetual account first.": "옵션 계정 자산은 0입니다. 먼저 지갑 계정에서 옵션 계정으로 이체하십시오.",
    "Financing": "재무 관리",
    "Load": "짐",
    "Replay001": "다시 하다",
    "Constellation Program": "인기 - 별자리 프로그램",
    "Constellation Program2": "인기 - 별자리 금융",
    "rate rankings": "단일 승률 순위를 가진 기관",
    "rate rankings2": "최고의 금융 기관",

    "CXT Quotes": "지수",
    "Digital asset trading tool service platform": "디지털 자산 거래 도구 서비스 플랫폼",
    "Service_home": "서비스",
    "Strategic cooperation": "전략적 협력",

    "Financing assets": "금융 자산",
    "Remove 0 from the first number when registering!": "등록시 첫 번째 숫자에서 0을 제거하십시오!",
    "Are you sure you want to quit": "BitMatrix를 종료 하시겠습니까?",

    "Opening Price": "개봉 가격",
    "Closing Price": "종가",
    "Formula interpretation": "공식 해독",
    "Note: If the input box is left blank": "주:입력창을 비워두는 것은 해당 횟수에 현재 거래소의 데이터를 가져오지 못했음을 의미합니다",
    "Option Sessions": "옵션 세션",
    "Result": "결과",
    "When data for an exchange is not available": "*어떤 거래소의 데이터를 가져오지 못했을 때 그 비중은0%를 취합니다. 유효 데이터 송신부≤2일 때 플로우 스테이션으로 정의합니다.",
    "Assume that the index is derived from the opening prices of five exchanges": "지수 출처가 5개 거래소에서 받은 시가로 각각：A、B、C、D、E；이고 종가가 각각 ：A`、B`、C`、D`、E`라면",
    "The proportion of those five exchanges are": "5개 거래소의 상응한 비중은 각각：a%、b%、c%、d%、e%",
    "Stream bureau": "스트림 뷰로",
    "(valid data source ≤ 2)": "(유효한 데이터 소스 ≤ 2)",
    "Option calculator": "옵션 계산기",
    "Round selection": "횟수 선택",
    "Select option": "옵션 선택",
    "Average opening price": "개장 평균가",
    "Average closing price": "폐장 평균가",
    "Calculate now": "계산 시작",
    "Calculation Results": "계산 결과",
    "Calculating Time": "시간 계산",
    "Management": "관리",
    "Other_home": "다른",
    "All applications": "모든 애플리케이션",
    "Boon": "혜택",
    "Coin_Address_draw": "코인 인출 주소",
    "Congratulations": "축하합니다",
    "Profit": "이익",
    "Gainers": "승자",
    "Drop": "하락",



    "Order Price": "비용",
    "Order Price1": "비용",
    "Sec Swap": "초 스왑",
    "Expected Return": "수익률",
    "Sec Swap Assets": "초 스왑 자산",
    "Sec Swap Bill": "초 스왑 기록",
    "Sec Swap Account": "초 스왑 계정",
    "Go Login": "로그인",
    "NSO Session": "NSO 분시",
    "Wallet Value1": "지갑 평가",
    "Spot Value1": "Spot 평가",
    "PERP Value1": "PERP 평가",
    "Option Value1": "옵션 평가",
    "Fiat Value1": "명령평가",
    "Pledge_Amount": "서약 수량",
    "total_return2": "총계 이익",
    "Violation_Loss": "위반 손실",
    "Cycle": "주기",
    "Locked2": "잠김",
    "Time_Left": "남은 시간",
    "Cancel_Pledge": "서약 취소",
    "countDown_day": "D ",
    "Entrust2": "맡기다",
    "All2": "모두",
    "Ongoing2": "진행 중",
    "Near2": "예정",
    "Ended2": "종료",
    "Cancel the pledge?": "서약 취소?",
    "Lock-up mining": "잠금 채굴",
    "Rules Description": "규칙",
    "Today Expected": "예상 수익",
    "Order Escrow": "위탁 주문",
    "Minimum": "최저한의",
    "Daily Return": "일일 수익",
    "Buy-3": "매수",
    "Active Currency": "활성 통화",
    "Minimum Amount": "최저한의",
    "Lockup Period": "락업 기간",
    "Confirm Pledge": "서약 확인",
    "Buy-4": "매수",
    "Completed2": "완료",
    "Cancelled2": "취소",
    "Conversion Starter": "전환 시작",
    "Listing": "목록",
    "Total Target": "총 대상",
    "Conversion Ratio": "환율",
    "Single Limited": "단일 제한",
    "Participate Order": "참가 주문",
    "Fundraised": "모금",
    "Current Progress": "현재 진행",
    "Time Left": "남은 시간",
    "Start Time": "시작 시간",
    "End Time": "종료 시간",
    "Conversion Amount": "교환 수량",
    "Conversion Now": "지금 변환",
    "Conditions": "참가조건",
    "Preheating": "예열",
    "Records": "교환 내역",
    "Select": "선택",
    "To": "까지",
    "Active Currency": "활성 통화",
    "Amount-3": "금액",
    "Conversion Time": "교환 시간",
    "Arrival Time": "도착 시간",
    "Not Arrived": "받지 못한",
    "Distance Start": "거리 시작",
    "Hours-1": "시",
    "Risk warning: It is displayed": "위험 경고: 혁신 자산에 대해 표시되며 디지털 자산의 특성이 있습니다. 가격을 잘 판단하시고 투자 결정을 잘 하시기 바랍니다!",
    "Week-1": "주",
    "S-1": "초",
    "minutes-2": "M",
    "You must pass basic KYC": "기본 KYC를 통과해야 합니다",
    "You must pass advanced KYC": "고급 KYC를 통과해야 합니다",
    "Account order exceeds": "계정 주문 초과",
    "Suspend": "거래 중단",
    "Successfully": "교환 성공",
    "Ongoing-1": "진행 중",
    "Failed": "교환 실패",
    "New_Token_Subscribe": "토큰 구독",
    "More_awaits_you": "더 많은 부",
    "Distance Start": "거리 시작",
    "day-2": "낮",
    "Must Eligible": "필요 적격",
    "Custodial funds": "보관 자금",
    "Default Ratio": "기본 비율",
    "Wallet Available": "사용 가능",
    "Done-2": "완료",
    "Eligible Amount": "적격 수량",
    "Eligible Price": "적격 금액",
    "New Token Subscribe": "토큰 구독",
    "Subscribe Now": "지금 구독",
    "Review Time": "자격 시간",
    "Review Passed": "검토 통과",
    "Review Failed": "검토 실패",
    "Not Reviewed": "검토 안 됨",
    "Denial Reason": "거부 사유",
    "If not received, please contact service": "받지 못하신 경우 고객센터로 연락주세요",
    "Returned to wallet": "반환 지갑",
    "From wallet assets": "부터 지갑",
    "Day-3": "D",
    "Sec Swap asset is 0, please transfer from wallet account to Sec Swap first": "초 스왑 계정 자산은 0이며, 먼저 지갑 계정에서 두 번째 계약 계정으로 이체하십시오",
    "Settlement": "청산",
    "Completed": "완료",
    "Password Setting": "설정",
    "Set password and email": "비밀번호 및 이메일 설정",
    "Email Settings": "메일 설정",
    "First-time registered users mus": "최초 등록 사용자는 로그인 비밀번호와 이메일 설정을 완료해야 합니다",
    "Driver ID": "운전면허",
    "Minimum": "최저한의",
    "Preview": "시사",
    "cancelTime": "취소 시간",
    "Enter wire transfer address (within 400 words)": "전신 송금 주소 입력(400자 이내)",
    "Setting Pix": "설정 Pix",
    "Bank Name": "은행 이름",
    "Company Name": "회사 이름",
    "Please enter bank name": "은행 이름 입력",
    "Please enter company name": "회사 이름 입력",
    "Tips:": "팁:",
    "When you sell digital currency, your chosen payment method will be shown to the buyer. Please confirm that the information is filled in correctly.": "디지털 화폐를 판매할 때 선택한 결제 수단이 구매자에게 표시됩니다. 정보가 올바르게 입력되었는지 확인하십시오.",
    "Modify Pix": "Pix 수정",
    "Please bind Pix first": "Pix를 먼저 바인딩",
    "MentionMoneySend": "보내는",
    "NewTokenSubscribe": "토큰 구독",
    "MoreWealthAwaitsYou": "더 많은 부",
    "TopGainers": "기복",
    "Newest": "최신",
    "Hot": "인기 목록",
    "DatChange": "변화",

    'Total_assets_valuation': '총자산',
    'Invite Friends': '친구 초대',
    'RedGreen': '빨간+ 녹색-',
    'GreenRed': '녹색+ 빨간-',
    'Color Preference': '색상 설정',
    'Fuzzy Information': '흐림',
    'user_bind_xilain_trade_pwd': '암호를 입력',
    'login_please_input1': 'SMS 인증 코드를 입력하십시오.',
    'user_please_input9': 'Google 인증 코드를 입력하십시오.',
    'user_link_name': '체인 이름',
    'user_coin_bring_together': '환산',
    "chongbi0": '코인 사용에 대한 참고 사항:',
    "chongbi1": '1、{0} 이외의 코인의 전환을 금지하면 비 {1} 자산은 복구되지 않습니다.',
    "chongbi2": '2、{0} 고객 또는 온라인 지갑을 통해이 주소로 재충전해야하는 {1} 번호를 보내 주시기 바랍니다. 송금이 완료되면이 거래에 대한 {2} 확인을받은 후 시스템에서 귀하의 계좌로 가상 통화를 자동으로 충전합니다. 동일한 주소를 여러 번 재충전 할 수있는',
    "chongbi3": '2、은 계정에 영향을 미치지 않습니다.',
    "chongbi4": '3、최소 충전량 {0}.',
    "currency_withdrawal_rate_rules": '{0}출금 률 규칙:',
    "currency_withdrawal_rate_rules1": '1.사용자 자금 보안을 위해 플랫폼이 동전 처리 작업을 확인하기 위해 전화 할 수 있습니다. 응답에주의하십시오.',
    "currency_withdrawal_rate_rules2": '2.{0}{1} 회 충전 확인 후 출금이 가능합니다.',
    "currency_withdrawal_rate_rules3": '2.가능 제출 현금 전액 ≤ 계좌를 자산으로 쓸 수 있다 - 계좌는 자산으로 쓸 수 있다',
    "currency_withdrawal_rate_rules4": '3.매일 가장 많은 지폐 {0}，"매일 최대 지폐 {1}。',
    "currency_transaction_password": "비밀번호",
    'long_press_to_save_to_album': '길게 누르면 앨범에 저장',
    'the_fee_is_outside_the_range': '수수료가범위를 벗어났습니다.',
    'start_and_end_date': '시작일 및 종료일',
    'The_withdrawal_amount_cannot_be_less': '동전의 양은 *보다 작을 수 없습니다{0}',
    'withdrawal_details': '인출 세부 사항',
    "RegisterRemove0": '등록 시 첫 번째 숫자에서 0 제거',
    "closeLockUpPopup": '"취소 서약은{0}일 동안 위반 손실을 청구하며 위반 손실 금액은 "{1}{2}"이며 실제 수령한 원금은 "{3}{4}"입니다."',
    "SetPassword": "비밀번호 설정",
    "comm_password": "거래 비밀번호",
    "unraise_recovering_password": "비밀번호 복구 후 1시간 출금 불가",
    "LockUpDuan": "LUM",
    "PDleaseUseSafariBrowser": "Safari 브라우저를 사용하십시오",
    "ADddToDesktop": "데스크탑에 BitMatrix 추가",
    "CDlickBelow": "아래를 클릭",
    "SDelectAddToHomeScreen": "홈 화면에 추가 선택",
    "ADddToDesktop2": "데스크탑에 BitMatrix 추가",
    "CDommonbrowserAddMethod": "공통 브라우저 추가 방법",
    'user_senior_text1': '주의',
    'user_senior_text2': '사진 파일 크기는 5M을 초과 할 수 없습니다! 파일 형식은 jpg, bmp, png 등이어야합니다!',
    'user_senior_text3': '사진에 워터 마크가없고, 얼룩이없고, 신원 정보가 분명하고, 아바타가 완전하고, 텍스트가 아니도록 확인바람 ! PS처리한 사진 사용하지 마십시오!',
    'user_senior_text4': '개최',
    'user_senior_text5': '증명 사진',
    'user_senior_text6': '：당신이 당신을 개최해야합니다',
    'user_senior_text7': '오늘의 날짜',
    'user_senior_text8': '그리고',
    'user_senior_text9': 'UID',
    'user_senior_text10': '스트립은 미러되지 않은 사진으로 촬영됩니다.',
    'user_senior_text11': ', 다른 하나는 필기로 필기 한 것을 들고',
    'user_senior_text12': '백지를 들고 다음을 쓰십시오. 일치하지 않는 사람들은 승인되지 않습니다 :',
    'user_senior_text13': '투자 위험을 알고 자발적으로 추정했습니다',
    'user_senior_text14': '.',
    'user_ID_card': '신분증',
    "user_passport": "여권 3.",
    'Driver_ID': '운전면허',
    "user_senior_upload3": "핸드 헬드 신분증 사진 + UID + 날짜 업로드",
    "ServiceUpgrade": '서비스 업그레이드',
    "ReferencePrice": "참조 가격",
    "referenceTips": "비 최종 거래 단가, 참조 용",
    "NewCoinsLaunch": "신규 코인 출시",
    "CurrentlyPurchased": "구입했다",
    "TotalMarketValue": "총 시가 총액",
    "IEORatio": "비율",
    "CurrencyIntroduction": "통화 소개",
    "ConvertNow": "지금 변환",
    "RemainingAmount": "남은 할당량",
    "referenceTips": "구매 가격 우선 순위",
    "ServiceUpgrade": '서비스 업그레이드',
    "LastDays7": "거의 7 일",
    "LastDays30": "거의 30 일",
    "LastDays90": "거의 90 일",
    'hide_coin': '{0} BTC 미만 숨기기',
    "LockUpTips": "참고: 감사하지 않은 나머지 {0}{1} 자산은 지갑 계정으로 반환되었습니다.",
    "PromotionalMining": "홍보마이닝",
    'GoldAssets': '골드 자산',
    'ModifyEmail': '이메일 수정',
    'NewEmail': '새 우편함',
    'NewEmailAddress': '새 이메일 주소',
    'VerifyNewEmail': '새 이메일 확인',
    'EnterNewEmailCode': '새 이메일 코드 입력',
    'VerifyOldEmail': '이전 이메일 확인',
    'EnterOldEmailCode': '이전 이메일 코드',
    'VerifyLoginPassword': '로그인 패스워드',
    'EnterLoginPassword': '로그인 비밀번호 입력',
    "loan": "렌딩 센터",
    "loanText": "기존 암호화폐 자산을 판매할 필요 없이 더 큰 유동성으로 다양한 목적에 부합할 수 있는 안전하고 신뢰할 수 있는 통화 차용 서비스를 제공합니다.",
    "loanQuery": "대출상담",
    'Stop_surplus_tips': "When the market price reaches {}, the profit stop Commission will be triggered, and the profit is expected to be {} after the transaction",
    'Stop_loss_tips': "The stop loss order will be triggered when the market price reaches {}, and the loss is expected to be {} after the transaction",
    'Stop_surplus_tips1': "When the market price reaches {}, the profit stop entrustment will be triggered, and the loss is expected to be {} after the transaction",
    'Stop_loss_tips1': "The stop loss order will be triggered when the market price reaches {}, and the profit is expected to be {} after the transaction",
    'Countdown': "카운트다운",
    'contract_After_adjustment': '조정 후 신규 보증금으로 계좌에서 {}개 USDT로 이체',
    'contract_subject_to': '(실제 전입금액 기준)',
    'contract_the_used_margin': '조정 후, 이미 사용한 보증금은 {} 개의 USDT가 감소하고, 감소분은 창고에 남는다,',
    'contract_transferred_to': '계좌 잔액으로 이체 가능',
    'contract_After_adjustment_q': '조정 후, 창고 점용 보증금은 {}개 USDT 증가',
    'contract_the_used_margin_q': '조정 후, 창고 점용 보증금은 {}개 USDT 감소',
    'Please1': '이익 정지 트리거 가격을 입력하십시오',
    'Please2': '이윤 정지 위탁 가격을 입력하십시오.',
    'Please3': '손상 방지 트리거 가격을 입력하십시오.',
    'Please4': '손실 방지 위탁 가격을 입력하십시오.',
    'Stop profit6': '이윤 정지 촉발 가격은 최신 거래 가격보다 커야 한다.',
    'stop loss7': '손실 방지 트리거 가격은 최신 거래 가격보다 작아야 한다',
    'Please8': '수량을 입력하십시오.',
    'Please9': '가평 이 없다',
    'CountdownTime': '카운트다운',
    'ModifyEmail': 'Modify email',
    'NewEmail': 'New email',
    'NewEmailAddress': 'New email address',
    'VerifyNewEmail': 'Verify new email',
    'EnterNewEmailCode': 'Enter new email code',
    'VerifyOldEmail': 'Verify old email',
    'EnterOldEmailCode': 'Enter old email code',
    'VerifyLoginPassword': 'Verify login password',
    'EnterLoginPassword': 'Enter login password',
    h1: '자격신청',
    h2: '순위 목록',
    h3: '파트너가 되면 획득 가능',
    h4: '프로모션 기준',
    h5: '초급 동업자를 구매하려면 지불해야 한다',
    h6: '현재 잔액',
    h7: '창세 동업자',
    h8: '커미션',
    h9: '지금 업그레이드',
    h10: '이미 최고 레벨',
    h11: '커미션 보너스',
    h12: '커미션 반환 순위',
    h13: '팀 수',
    h14: '커미션 반환 기록',
    h15: '초대받은 사람',
    h16: '결제 완료',
    h17: '미결제',
    h18: '부하를 향유하다',
    h19: '지불 필요',
    h20: '업그레이드',
    h21: '커미션 반환 세칙',
    h22: '순위',
    h23: '사용자',
    h24: '리베이트 보상',
    h25: '현재 레벨 획득 가능',
    h26: '획득 커미션은 모두 USDT의 누적 값으로 환산됩니다.',
    h27: '일반 사용자 {} 명 직접 밀어넣기 필요',
    h28: '유효한 일반 사용자 {} 명이 직접 밀었습니다.',
    h29: '필요한 팀 수 {} 명',
    h30: '팀 수 {} 명',
    "loan": "렌딩 센터",
    "loanText": "기존 암호화폐 자산을 판매할 필요 없이 더 큰 유동성으로 다양한 목적에 부합할 수 있는 안전하고 신뢰할 수 있는 통화 차용 서비스를 제공합니다.",
    "loanQuery": "대출상담",
    'Stop profit10': '흑자 정지 촉발 가격은 최신 거래 가격보다 작아야 한다',
    'stop loss10': '손실 방지 트리거 가격은 최신 거래 가격보다 커야 한다.',
    Earn:"부의 성장",
    Simple:"간단하고 안전함",
    Low1:"낮은 위험과 안정적인 수익",
    Low2:"낮은 위험, 안정적인 수익, 언제든지 상환 가능",
    CurrentAccount:"당좌 계정",
    Stable:"부의 성장을 위한 강력한 도구인 꾸준한 부가가치",
    EarnLocked:"잠긴 적립",
    Searchpopular:"인기 코인을 검색하고 적립을 시작하세요",
    Annualized:"연환산율 {}%, 안전자산, 초고수익률",
    CoinsLC:"동전",
    PurchaseLC:"구매금액",
    PurchaseLC2:"(최소 구매금액은 {})",
    Maximum:"최대",
    EstimatedInterest:"예상 이자",
    RuleLC:"규칙",
    SubscriptionTime:"교환 시간",
    Interestaccrualtime:"이자 발생 시간",
    RevenueTime:"수익 시간",
    RedemptionCycle:"환매 주기",
    ReturnNow:"즉시 반납",
    BuyLC:"매수",
    PurchaseSuccessful:"구매 성공",
    DepositLC:"코인 저축",
    Yesterday:"어제 수익",
    TotalLC:"합계",
    TotalReturn:"총계 이익",
    DepositValuation:"예금 평가치",
    DepositDetail:"코인 저축 세부 정보",
    RevenueDetails:"수익 내역",
    DepositType:"예금 유형",
    ReturnMethod:"이자환급방식",
    DepositAmount:"코인 저축수량",
    APR:"APR",
    Expected:"예상 수익",
    DepositCycle:"코인 입금 주기",
    CreationTime:"창립 시간",
    EarnProfit:"이번 수입",
    DepositCost:"예금 비용",
    TotalRelease:"출시된 총 금액",
    ReleaseTime:"발급 시간",
    Flexible:"フレキシブル",
    Fixed:"フィックス",
    Freeze:"얼다",
    Completed3:"완료",
    Cancelled:"취소",
    DefaultSettlement:"기본 결제",
    PurchaseAmount:"구매 금액(제한 없음)",
    RetrieveDeposited:'예금 화폐를 되찾다.',
    PreviewConversion:'미리보기 변환"입니다',
    ToEnsureThe:'계정 보안을 보장하고 더 높은 거래 한도를 충족하기 위해 고급 인증이 필요합니다.',
    ToVerification:'버튼을 클릭하여 고객 서비스에 연락해 인증을 진행하세요.',
    ToContact:'즉시 고객 서비스에 연락하여 인증하세요.',
    "comm_nickname_rule1": "7中国語文字また15英語文字を超えできない",
    "comm_nickname_rule2": "スペースや特殊文字を含めるできない",
    "otc_upload_picture3":"이미지 업로드는 jpeg / jpg / png / bmp 형식에서만 가능합니다!",
    "CertificationDifficulties":'인증에 문제가 발생했나요?',
    "CertifiedTipsP2P":'위의 상점들은 모두 BitMatrix 플랫폼 인증을 받았으니 안심하고 구매하세요.',
    'OneClickBackhand':'원클릭 반전',
    'EmptyReversal':'빈 반전',
    'MultipleReversalsEmpty':'롱 포지션을 숏 포지션으로 전환',
    'BackhandOpenLong':'백핸드 롱 포지션 열기',
    'BackhandOpenEmpty':'롱에서 숏으로 전환',
    'DontShowMeAgain':' 다시 보지 않기',
    "TransferRequiresClosing":"현재 보유한 계약 미청산",
}