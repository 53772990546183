<template>
  <div class='container htmlData'>
    <nav-bar :title="htmlData.title || ''"> </nav-bar>
    <div v-if="htmlData.content" v-html="htmlData.content"></div>

    <!-- <section>
      <div class="wrap">
        <div class="box">
          <img class="slogan" src="@/assets/img/lock/slogan.png" alt="">
          <p>锁仓挖矿是通过将usdt托管给平台超算力矿机在平台矿池中进行挖矿收益</p>
        </div>
        <div class="card-box1">
          <div class="title">产品亮点</div>
          <div class="row">
            <div>随存随取 <br> 派息周期</div>
            <div>每日下发 <br> 活期利息</div>
          </div>
          <div class="row">
            <div class="col">
              <img src="" alt="">
              <span>100% 资金安全保障</span>
            </div>
            <div class="col">
              <img src="" alt="">
              <span>节假日收益不间断</span>
            </div>
            <div class="col">
              <img src="" alt="">
              <span>成功存入后当天起息</span>
            </div>

          </div>

        </div>
        <div class="card-box2">
          <div class="title">举个例子</div>
          <div>
            <div class="h3">收益计算</div>
            <p>会员在平台锁仓10000U，选择了周期为5天，
              日产出为锁仓金额的0.3%~0.5%的理财产品，则每天产出如下：:<br>
              最低：10000U x 0.3%=30 U <br>
              最高：10000U x 0.5%=50 U <br>
              即5天后可以获得150U~250U的收益，收益每日下发，下发的收益可随时存取，锁仓本金到期后，自动转入您的钱包账户。</p>
          </div>
          <div>
            <div class="h3">关于违约金</div>
            <p>
              若您希望转出未到期的本金，则会产生违约金，违约金=违约结算比例*剩余天数*锁仓数量。<br>
              举例：该锁仓挖矿的违约结算比例为1%，剩余3天到期，
              锁仓数量为1000，则违约金=1%*3*1000=30U，实际退还本金为1000U-30U=970U
            </p>
          </div>
        </div>

      </div>
    </section> -->
  </div>
</template>
<script>
import navBar from "@/components/navBar";
import { mapGetters } from 'vuex'

//import {} from "@/api/Controller";
export default {
  name: 'Rules',
  components: { navBar },
  props: {},
  data() {
    return { htmlData: {} };
  },
  computed: {
    ...mapGetters([
      'partnerId',
    ]),
  },
  watch: {},
  beforeMount() {
    this.selectAll()
  },
  mounted() { },
  methods: {
    selectAll() {
      const lang = localStorage.getItem("lang") || 'en_US'
      this.$api.selectAll({ termsTypeIds: '21', language: lang, partnerId: this.partnerId }).then((response) => {
        if (!response.data || !response.meta.success) return
        this.htmlData = response.data[0]
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0 15px;
  //   background-color: #131520;
  //   color: #fff;
  //   section {
  //     width: 100%;
  //     height: 100%;
  //     background: url('~@/assets/img/lock/bg.png') no-repeat;
  //     background-size: 110%;
  //     .wrap {
  //       width: 100%;
  //       padding: 0 15px 20px;

  //       .box {
  //         height: 140px;
  //         padding: 25px 0;
  //         display: flex;
  //         flex-direction: column;
  //         align-items: center;
  //         justify-content: space-between;
  //         .slogan {
  //           width: 230px;
  //           height: 50px;
  //         }
  //         p {
  //           width: 282px;
  //           font-size: 13px;
  //           text-align: center;
  //           line-height: 1.5;
  //         }
  //       }
  //       .card-box1 {
  //         width: 100%;
  //         min-height: 240px;
  //         background: url('~@/assets/img/lock/card_bg1.png') no-repeat;
  //         background-size: cover;
  //         position: relative;
  //         padding: 20px 15px 0;
  //         .row {
  //           display: flex;
  //           align-items: center;
  //           justify-content: space-around;
  //           font-size: 14px;
  //           margin-top: 30px;
  //           > div {
  //             // width: 100px;
  //           }
  //           .col {
  //             display: flex;
  //             flex-direction: column;
  //             align-items: center;
  //             justify-content: center;
  //             > span {
  //               text-align: center;
  //             }
  //           }
  //           img {
  //             width: 50px;
  //             height: 50px;
  //             margin-bottom: 10px;
  //           }
  //         }
  //       }
  //       .card-box2 {
  //         width: 100%;
  //         min-height: 380px;
  //         background: url('~@/assets/img/lock/card_bg2.png') no-repeat;
  //         background-size: cover;
  //         position: relative;
  //         margin-top: 20px;
  //         padding: 30px 15px 0;
  //         p {
  //           font-size: 13px;
  //           line-height: 1.5;
  //           color: #9299a9;
  //         }
  //         .h3 {
  //           margin-top: 10px;
  //           line-height: 1.5;
  //           font-size: 15px;
  //           color: #ffffff;
  //         }
  //       }
  //       .title {
  //         position: absolute;
  //         top: 3px;
  //         left: 50%;
  //         transform: translateX(-50%);
  //         font-size: 18px;
  //         font-weight: 600;
  //         color: #131520;
  //       }
  //     }
  //   }
}
// ::v-deep {
//   .van-nav-bar {
//     background: #131520;
//     .van-icon-arrow-left {
//       color: #fff;
//     }
//     .van-nav-bar__title {
//       color: #fff;
//     }
//   }
// }
</style>

<style>
.htmlData img {
  max-width: 100%;
  height: auto;
}
</style>