export const M = {
    "Slideright": "スライダを押したまま右端にドラッグしてください",
    "Pleaseverification": "セキュリティ検証を完了してください",
    "Pleaseorder": "クリックして下さい",
    "Verificationagain": "検証に失敗しました。もう一度お試しください",
    "10 orders": "10注文",
    "24h High": "24時間高",
    "24h Low": "低24時間",
    "24h Trading Vol": "24時間トレーディングVol",
    "24h Vol": "24時間量",
    "ADL mechanism": "ADLメカニズム",
    "AMT.": "数量",
    "Activity _home": "アクティビティ",
    "Actual transaction number": "実際のトランザクション数",
    "Actual transaction price": "実際の取引価格",
    "Add margin": "マージンコール",
    "Adjust leverage": "レバレッジを調整する",
    "Adjust success": "成功を調整する",
    "Advanced limit order offers 3": "高度な指値注文は、「投稿のみ」、「約定またはキル」、「即時またはキャンセル」など、通常の指値注文よりも3つ多くの注文オプションを提供します。通常の指値注文はデフォルトで「キャンセルされるまで有効」です。",
    "Alipay": "アリペイ",
    "All Closed": "すべてのポジションを閉じる",
    "All Filled": "トランザクションを完了する",
    "AllDetails": "清算の詳細を見る",
    "AllType": "いろんなタイプ",
    "Amount can flat": "決済可能",
    "Appeal_reason": "原因を申し立てる",
    "Applet service": "アプレットサービス",
    "Are you sure to cancel the order？": "注文をキャンセルしてもよろしいですか？",
    "Asked_to_record": "ビデオ素材を録画するには、以下の手順に従ってください",
    "Asset conversion": "資産額",
    "Asset_types": "資産の種類",
    "Assets": "資産",
    "At the current price": "現在の価格で",
    "Avail.": "利用可能",
    "Avail. margin": "利用可能なマージン",
    "Available": "使える",
    "Avg close price": "平均終値",
    "Avg open price": "平均始値",
    "Avg.price": "取引平均価",
    "BBO": "BBO",
    "Bankcard": "銀行カード",
    "Bankruptcy price": "破産価格",
    "Batch Cancel": "バッチキャンセル",
    "Branch_address": "支店アドレス",
    "Buy crypto_home": "買う",
    "Calculations are not based on": "計算結果は参考用です。 清算価格は、1つの契約に基づく1つのオープンポジションのデータのみを表します。",
    "Can't calculate": "計算できません",
    "Cancel": "キャンセル",
    "Cancel all": "バッチキャンセル",
    "Cancel order": "注文をキャンセルする",
    "Cancel_the_login": "ログインをキャンセルする",
    "Capital cost": "キャッシュフロー",
    "Capital cost - Expenditure": "資金調達費用",
    "Capital fee": "資金調達手数料",
    "Capital fee - all": "資金調達手数料-すべて",
    "Capital fee - income": "資金手数料収入",
    "Capital success": "取り消されました",
    "Charge_money": "デポジット",
    "Chinese_Yuan": "人民元",
    "Close all of": "よろしいですか",
    "Close long": "クローズトング",
    "Close out time": "すべての清算の終了時間",
    "Close price": "終値",
    "Close short": "クローズショート",
    "Closed": "清算数量",
    "Closing PnL gains": "利益",
    "Closing PnL ratio": "利益率",
    "Closing profit": "決算利益",
    "coin_failure_0002": "通貨データが存在しません",
    "Coming soon": "乞うご期待",
    "Common functions": "共通機能",
    "Communtiy_home": "コミュニティ",
    "Conceal canceled orders": "隠された失効",
    "Confirm_the_login": "ログインを確認する",
    "Confirm_to_mention_money": "出金を確認",
    "Cont": "続き",
    "Copy link": "リンクをコピー",
    "Cross": "フルポジション",
    "Currency_rate_description": "料金の説明",
    "Current": "現在",
    "Delete_option": "削除する",
    "Determine_to_cancel": "キャンセル确定?",
    "Don't show me again": "これ以上のリマインダーはありません",
    "Draw_inconsistency": "不一致のパスワード入力",
    "Draw_the_unlock": "スワイプして新しいパスワードを設定してください",
    "Enter email address": "メールアドレスを入力してください",
    "Enter expected PnL": "期待されるPnLを入力します",
    "Enter expected PnL Ratio": "期待されるPnL比率を入力します",
    "Enter leverage from 1 up to 125": "1から125までのレバレッジを入力します",
    "Enter phone number": "電話番号を入力してください",
    "Enter the verification code": "確認コードを入力",
    "Equity": "通貨の権利",
    "Est liquidation price": "推定強制清算価格",
    "Face value": "契約額",
    "Filed Value": "取引額",
    "Fill or Kill": "すぐに完了またはキャンセル",
    "Filled amt": "音量",
    "Filled time": "決済時間",
    "Flash_strong": "激しいフラッシュ",
    "Funding rate": "資金調達率",
    "Funding rate - 1": "資金調達率",
    "Funding rate comparison": "資金調達率の比較",
    "Funding rate time": "資金調達率の時間",
    "Fuzzy_information": "情報のぼかし",
    "Gesture_error": "パスワードを間違って入力する",
    "Google_code": "Google確認コード",
    "Help_home": "ヘルプ",
    "Hide_no_asset_currency": "資産ゼロは隠す",
    "Highest PnL ratio": "最高の収益率",
    "Historical record": "歴史記録",
    "I agree to": "同意を確認",
    "I have read and agree to this agreement": "私はこの契約を読み、同意します",
    "If_the_buyer_is_at": "購入者が",
    "Immediately or Cancel": "すぐに閉じて、残りをキャンセルします",
    "In force": "有効",
    "Index": "スポットインデックス",
    "Index Price": "指数価格",
    "Index_contrct": "スポットインデックス",
    "Info": "契約の紹介",
    "Initial margin": "初期マージン",
    "Instrument": "現在の取引品種",
    "Insurance fund": "リスク準備金残高",
    "Interest bearing record": "利息計算記録",
    "Invalid_qr_code": "二次元コードを無効にする",
    "Invite code(Optional)": "招待コード（任意記入）",
    "InviteCodeMuster": "招待コードを入力（必須）",
    "Invite_home": "インビテーション",
    "Isolated": "分離ポ",
    "Lack_of_edge": "行方不明",
    "Last Price": "成行取引価格",
    "Latest Filled price": "最新の取引価格",
    "Latest Filled price01": "最終価格",
    "Latest price": "最終価格",
    "Leverage": "てこの作用",
    "Leverage after increase": "増加後のレバレッジ：",
    "Leverage11": "てこの作用",
    "Leverage_BB": "レバレッジを調整",
    "Limit order is an order": "指値注文は、買い手の最大買い価格と決済者の最小売り価格を制限する注文です。 あなたの注文が出された後、私たちのシステムはそれを本に掲載し、あなたが指定した価格以上で利用可能な注文と一致させます。",
    "Limit price": "制限",
    "Limit/Market": "制限/市場",
    "LimitAndMarket": "制限価格/市場価格",
    "Liquidation price": "清算価格",
    "Liquidation price after increase": "値上げ後の清算価格：",
    "Long": "ロング",
    "Lowest PnL ratio": "最低の収益率",
    "MAIL_COUNTRY_NOT_EXITS": "現在の国は存在しません！メッセージを追加してもう一度送信してください。",
    "MAIL_NOT_MAIL_PROVIDER": "メールサービスプロバイダはありません。メールを送信する前に追加してください",
    "MAIL_TEMPLATE_NOT_FOUND": "現在のメールテンプレートは存在しません！それを追加してもう一度送信してください",
    "MAIL_TO_ADDRESS_NOT_FOUND": "現在のメールアドレスは存在しません。もう一度確認してもう一度送信してください。",
    "MJRefreshAutoFooterIdleText": "クリックまたはプルアップしてもっと読み込む",
    "MJRefreshAutoFooterNoMoreDataText": "すべてすでにロードされています",
    "MJRefreshAutoFooterRefreshingText": "データをさらに読み込み中...",
    "MJRefreshBackFooterIdleText": "アップロードにもっと読み込むことができます",
    "MJRefreshBackFooterNoMoreDataText": "すべてすでにロードされています",
    "MJRefreshBackFooterPullingText": "もっと負荷を解放してください",
    "MJRefreshBackFooterRefreshingText": "データをさらに読み込み中...",
    "MJRefreshHeaderDateTodayText": "今日",
    "MJRefreshHeaderIdleText": "ドロップダウンを更新できます",
    "MJRefreshHeaderLastTimeText": "最終更新日",
    "MJRefreshHeaderNoneLastDateText": "レコードなし",
    "MJRefreshHeaderPullingText": "即時リフレッシュを解除する",
    "MJRefreshHeaderRefreshingText": "データを更新中...",
    "MKT Close ALL": "MKTすべて閉じる",
    "Margin add": "マージンコール",
    "Margin mode": "全ポジションモード",
    "Margin ratio": "証拠金率",
    "Margin reduce": "マージンを減らす",
    "Margin transfer": "証拠金振替",
    "Margin-1": "証拠金残高",
    "Margin-2": "マージン",
    "Margined": "契約する",
    "Mark price": "マークプライス",
    "Market": "市場価格",
    "Market Order Dig": "成行注文により、ユーザーは現在の市場で最高の価格で注文して、迅速な取引の目的を達成することができます。",
    "Market Overview": "市場概況",
    "Market_transaction": "市場価格取引",
    "Max": "せいぜい元に戻すことができます",
    "Max Open int": "最大建玉",
    "Max buy": "マックスバイ",
    "Max long": "強気の買い",
    "Max position size at current leverage": "最大の現在のレバレッジを開くことができます：",
    "Max sell": "マックスセル",
    "Max short": "弱気で買う",
    "Max position size at current leverage": "最大 現在のレバレッジでのポジションサイズ",
    "Mention_money": "出金",
    "Mention_money_address": "正しい住所を入力",
    "Mention_money_tips_ios": "ヒント\n1.出金アドレスを直接入力し、ファンドのパスワード、SMS、電子メール、またはGoogle認証コードを確認する必要があります。\n2.あなた自身の認証アドレスにアドレスバインディングを追加します。次回は再度認証を入力する必要はありません。",
    "Messages_notification": "*銀行カードにSMS通知サービスが有効になっていることを確認してください",
    "Mini Programs": "ミニプログラム",
    "Minimum_handling_charge": "コインの数は手数料よりも多くなければなりません",
    "Minimum_withdrawal": "毎回最小出金",
    "MoreNot9": "選択したモジュールは14を超えることはできません",
    "Most increase": "せいぜい増加",
    "Most reduce": "最大減少量",
    "Must be integer multiple of": "の整数倍である必要があります",
    "Not Enabled": "有効になっていません",
    "Not_on": "棚には置けません",
    "Not_to_mention_money": "あなたの資金の安全のために、そのようなコインやプッシュ資産を引き出すなどの慎重な操作は、トランザクションパスワードをリセットした後1時間以内にご利用いただけません。",
    "OTC account": "フィアットアカウント",
    "OTC assets": "OTCウォレット",
    "OTC交易须知": "OTCトレーディングノート",
    "On Hold": "フリーズ",
    "One-way": "一方向",
    "One/Two-way": "一方向/双方向",
    "Open Time": "営業時間",
    "Open amount": "オープン金額",
    "Open int.": "建玉",
    "Open interest": "建玉",
    "Open long": "ロング",
    "Open orders": "現在注文",
    "Open price": "始値",
    "Open short": "ショート",
    "Order Details": "注文詳細",
    "Order Sorting": "注文の並べ替え",
    "Order Value": "オーダー値",
    "Order amt": "全順序",
    "Order history": "履歴オーダー",
    "Order price": "注文価格",
    "Order trigger direction": "注文トリガーの方向",
    "Order type": "注文タイプ",
    "PL": "利益",
    "PL Ratio": "PL比率",
    "Pair01": "契約通貨",
    "Parse_failure": "保存に失敗しました",
    "Partially Filled": "部分取引",
    "Partially closed": "部分清算",
    "Password": "パスワード",
    "Payment_type": "領収書タイプ",
    "Perpetual": "永久契約",
    "Perpetual funds daybook": "永久明細書",
    "Perpetual01": "PERP",
    "Pingclose": "清算",
    "Please input a password": "入力してください",
    "Position": "ポジション",
    "Position balance": "ポジションバランス",
    "Position history": "歴史的地位",
    "QR_code": "QRコード",
    "Quantity cannot exceed": "数量は125を超えることはできません",
    "Quantity not less than": "1以上の数量",
    "Recent close": "最近のクローズ",
    "Recording_requirements": "録画ビデオリクエスト",
    "Required position margin at current leverage": "現在のレバレッジで必要なポジションマージン：",
    "Reset_transaction_password": "取引パスワードをリセットする",
    "Revoked": "取り消されました",
    "SL": "ストップロス",
    "SL order": "ロスオーダーの停止",
    "SL order price": "ストップロス注文価格",
    "SL order price can't be empty": "ストップロス注文価格を空にすることはできません",
    "SL trigger price": "ストップロストリガー価格",
    "SL trigger price can't be empty": "ストップロストリガー価格を空にすることはできません",
    "SMS_30_OUT_RESTRICTED": "認証コードは頻繁に送信され、30秒ごとに1つだけ送信されます。",
    "SMS_BAD_ARGUMENT_FORMAT": "電話の形式が正しくありません。",
    "SMS_COUNTRY_NOT_EXITS": "この国は存在しません。確認して、テキストメッセージを送信してください！",
    "SMS_DAY_LIMIT_PER_MOBILE": "同じ携帯電話番号が送信された回数が24時間以内に制限を超えました",
    "SMS_DUP_IN_SHORT_TIME": "同じ携帯電話番号から30秒以内に同じコンテンツを繰り返します",
    "SMS_FAILURE": "送信に失敗しました",
    "SMS_HOUR_LIMIT_PER_MOBILE": "同じ携帯電話番号が1時間以内に送信された回数",
    "SMS_MARKET_FORBIDDEN": "オペレーターの制御のためにマーケティングSMSが一時的に利用できない",
    "SMS_NOT_SMS_PROVIDER": "SMSサービスプロバイダはありません。送信する前にメッセージを追加してください！",
    "SMS_NOT_SMS_PROVIDER_TEMPLATE": "まだSMSサービスプロバイダテンプレートはありません。SMSを送信する前に追加してください！",
    "SMS_PHONE_OVER_COUNT": "この携帯電話番号確認コードは複数回送信されますので、24時間後にお試しください！",
    "SMS_PHONE_PROHIBIT_SEND": "この携帯電話は、テキストメッセージを送信することは禁じられています.24時間後に試してみてください！",
    "SMS_SUCCESS": "正常に送信されました",
    "SMS_TOO_MANY_TIME_IN_5": "同じ携帯電話番号の5分以内に同じ内容を3回以上繰り返す",
    "SMS_UNKNOWN_EXCEPTION": "システムに不明な異常が発生しました",
    "SMS_code": "SMS確認コード",
    "Save picture": "写真を保存する",
    "Scanning_to_obtain": "スキャンしてアドレスを取得る",
    "See auto deleverage liquidation for details": "詳細については、自動デレバレッジ清算を参照してください",
    "Service_home": "サービス",
    "Set_transaction_password": "取引パスワードを設定する",
    "Settlement1": "決済",
    "Settlrment coin": "決済コイン",
    "Share time": "共有時間",
    "Shielding success": "シールドの成功",
    "Short": "ショート",
    "Sign Up": "登録",
    "Sign in": "ログイン",
    "Simplified_Chinese": "日本语",
    "StatusOrder": "状態",
    "Stop loss": "ストップロス",
    "Stop profit stop loss": "ストッププロフィットストップロス少なくとも1つを選択してください。それ以外の場合は注文できません",
    "Sustainable account": "パーペチュアルアカウント",
    "Sustainable assets": "PERPアセット",
    "Switch to PnL": "Plnに切り替えます",
    "Switch to PnL ratio": "PnL比に切り替えます",
    "TP": "利益を得る",
    "TP order": "利益委員会を取る",
    "TP order price": "利益委員会の価格を取る",
    "TP order price can't be empty": "テイクプロフィット注文価格を空にすることはできません",
    "TP trigger price": "利益トリガー価格を取る",
    "TP trigger price can't be empty": "テイクプロフィットトリガー価格を空にすることはできません",
    "TP | SL": "P/Lを停止",
    "TP | SL order": "利益を取り、損失を止める委員会",
    "Take profit": "利益を設定する",
    "Telegraphic_transfer_address": "アドレス",
    "The Calculator": "電卓",
    "The maximum flatable quantity is": "最大レベリング量は次のとおりです。",
    "The maximum leverage is": "最大レバレッジは",
    "The minimum flatable quantity is": "最小の平準化可能な量は",
    "The minimum leverage is": "最小レバレッジは",
    "The poster has been saved to the album": "ポスターはアルバムに保存されました",
    "The_correct_sample": "正しい例",
    "The_input_signal": "スワイプしてパスワードを入力",
    "The_new_address": "新しい住所",
    "They_are_the_number_of": "注文の数",
    "Tick size": "ティックサイズ",
    "To_update": "更新",
    "Toggle_front_camera": "フロントカメラに切り替えてヘッドを中央に置いてください",
    "Total": "合計",
    "Total coin": "保有通貨数",
    "Total cont": "オープンポジションの数",
    "Total value": "位置値",
    "Total_asset_valuation": "総資産見積り",
    "Trade type": "取引タイプ",
    "Trade_sector": "選択してください",
    "Transfer": "乗り換え",
    "Transfer_to_remind": "振込時には必ず注意してください（それ以外は、自己責任で行ってください）",
    "Trigger mark price": "トリガーマーク価格",
    "Trigger price": "トリガー価格",
    "Two-way": "双方向",
    "UPL": "UPL",
    "US_dollar": "米ドル",
    "Unblocked": "ブロックを解除",
    "Uneasy lies the head that wears a crown": "彼は彼を強くするように強制します\ n私は護衛の王です",
    "Unfilled": "未記入",
    "Unfilled11": "取引を待っています",
    "Upload_collection_code": "アプロード受取コード",
    "Usdt-Margined": "USDT契約",
    "Users who register": "初めて登録するユーザーは、ログインパスワードの設定を完了する必要があります",
    "Verification Code": "確認コード",
    "Videoing_requirement": "撮影条件",
    "View": "チェック",
    "View more": "ポジションの詳細",
    "Waiting effect": "待機効果",
    "Waiting_for_the_payment": "支払い待ち",
    "Wechat": "WeChat",
    "WestUnion": "Western Union",
    "Without_her": "エントリなし",
    "Your closing number": "成約数量が最小ポジションを下回っています！",
    "Your have pending Cross orders": "保留中のクロスオーダーがあります。レバレッジを調整する前にキャンセルしてください。",
    "Your have pending isolated orders": "保留中の孤立した注文があります。レバレッジを調整する前にキャンセルしてください。",
    "abandoned": "放棄された",
    "abnormal_assets": "アカウント資産が異常です。この操作は一時的に実行できません！",
    "about_us": "私たちについて",
    "account": "口座",
    "account_empty": "アカウントエラー",
    "account_failure_0001": "トランザクションフローIDがREDISに失敗しました。",
    "account_failure_0002": "使用するのに不十分で、処理できません！",
    "account_failure_0003": "ユーザーのフリーズによりロギング障害が増加します。",
    "account_failure_0004": "ユーザーのフリーズの増加に失敗しました！",
    "account_failure_0005": "商人が使用可能なログレコードを控除しました！",
    "account_failure_0006": "失敗した商人控除！",
    "account_failure_0007": "ユーザーはロギングの失敗を増やすことができます！",
    "account_failure_0008": "ユーザーの利用可能な増加に失敗しました！",
    "account_failure_0009": "加盟店は新しい通貨を追加します。ユーザーは新しい通貨を追加できません。",
    "account_failure_0010": "商人は新しい通貨を追加できませんでした。",
    "account_failure_00100": "マーチャントIDは空にできません。",
    "account_failure_00101": "ユーザーIDは空にできません",
    "account_failure_00102": "通貨IDは空白にすることはできません",
    "account_failure_00103": "数量は空にできません",
    "account_failure_00104": "数量は最小取引量より少なくすることはできません",
    "account_failure_00105": "トランザクションパスワードを空にすることはできません",
    "account_failure_00106": "トランザクションパスワードが間違っています",
    "account_failure_00107": "価格は空ではありません",
    "account_failure_00108": "単価は最低単価より低くすることはできません",
    "account_failure_00109": "ユーザーは存在しません！",
    "account_failure_0011": "Merchant Walletこの通貨はすでに存在しています！",
    "account_failure_00110": "PUSHアセットが起動できませんでした。",
    "account_failure_00111": "プッシュIDは空にできません",
    "account_failure_00112": "PUSH資産の支払いに失敗しました！",
    "account_failure_00113": "プッシュアセットのキャンセルに失敗しました！",
    "account_failure_00114": "通貨アドレスを取得できません",
    "account_failure_00115": "コインアドレスは空にできません！",
    "account_failure_00116": "コインアドレスを追加できませんでした",
    "account_failure_00117": "IDは空ではありません",
    "account_failure_00118": "コインアドレスを削除できませんでした",
    "account_failure_00119": "ネットワークが異常です。後でもう一度お試しください！",
    "account_failure_0012": "不十分な凍結、処理できません！",
    "account_failure_00120": "コインアドレスは既に存在します",
    "account_failure_00121": "プッシュ通貨なし",
    "account_failure_00122": "電話の確認コードは空です！",
    "account_failure_00123": "メールボックス認証コードが空です！",
    "account_failure_00124": "Googleの認証コードが空です！",
    "account_failure_00125": "確認コードのエラー！",
    "account_failure_00126": "自己押しを禁止！",
    "account_failure_00127": "コインアドレスは合法ではありません！",
    "account_failure_00129": "24時間を超えてパスワードを変更することは許可されていません。",
    "account_failure_0013": "Merchant Freeze控除ログレコードに失敗しました！",
    "account_failure_00130": "資産のバランスが崩れていて操作できません",
    "account_failure_00131": "アカウント資産が異常で、取引状態が無効です。",
    "account_failure_00132": "タグが正当ない",
    "account_failure_00133": "トークンが存在しないまたは禁止",
    "account_failure_0014": "商人フリーズ控除に失敗しました！",
    "account_failure_0015": "ユーザは興味のあるロギングエラーを増やすことができます！",
    "account_failure_0016": "利用可能な利子の増加に失敗しました！",
    "account_failure_0017": "商人は、利息控除ログレコードを使用することができます失敗しました！",
    "account_failure_0018": "加盟店は利息控除を使って失敗することができます！",
    "account_failure_0019": "フリーズするユーザーが失敗しました！",
    "account_failure_0020": "フリーズするユーザーが失敗し、ログに失敗しました！",
    "account_failure_0021": "利用可能なログへのユーザーのフリーズに失敗しました！",
    "account_failure_0022": "ユーザーが利用可能な障害に凍結！",
    "account_failure_0023": "商人は利用可能なログを増やすことに失敗しました！",
    "account_failure_0024": "商人が利用可能な可用性を高める！",
    "account_failure_0025": "ユーザフリーズ控除ログレコードに失敗しました！",
    "account_failure_0026": "ユーザフリーズ控除に失敗しました！",
    "account_failure_0027": "ユーザーは失敗したログレコードを差し引くことができます。",
    "account_failure_0028": "ユーザー利用可能な控除に失敗しました！",
    "account_failure_0029": "ロールバック操作が失敗しましたユーザーのフリーズ回復ログ・レコードが失敗しました！",
    "account_failure_0030": "ロールバック操作が失敗しましたユーザーのフリーズ復旧に失敗しました！",
    "account_failure_0031": "資産はロールバックするには十分ではありません！",
    "account_failure_0032": "失敗したロールバック操作業者のフリーズ回復ログ・レコードが失敗しました！",
    "account_failure_0033": "ロールバック操作が失敗し、マーチャント資産のリカバリに失敗しました",
    "account_failure_0034": "商人のフリーズがログの増加に失敗しました！",
    "account_failure_0035": "商人フリーズが増加しました！",
    "account_failure_0036": "加盟店はログ記録をフリーズできませんでした。",
    "account_failure_0037": "フリーズできる商人は失敗しました！",
    "account_failure_0038": "利用可能なロギングへの商人フリーズが失敗しました！",
    "account_failure_0039": "商人が空き容量不足に陥る！",
    "account_failure_0040": "ロギングの失敗を増やすために利用できるプラットフォーム！",
    "account_failure_0041": "使用可能なプラットフォームの増加に失敗しました！",
    "account_failure_0042": "トランザクションが異常に実行されました！",
    "account_failure_0043": "トランザクションは実行に失敗しました。",
    "account_failure_0044": "トランザクションは正常に実行されました！",
    "account_failure_0045": "トランザクションが実行中です！",
    "account_failure_0046": "成功した処理！",
    "account_failure_0047": "プラットフォーム資産の復元に失敗したロールバック操作が失敗しました",
    "account_failure_0048": "ロールバック操作に失敗しましたプラットフォーム資産の回復ログに失敗しました",
    "account_failure_0049": "プラットフォームはすでに存在します！",
    "account_failure_0050": "プラットフォームが新しい通貨を追加できませんでした。",
    "account_failure_0051": "プラットフォームはすでに存在します！",
    "account_failure_0053": "取引タイプが間違っています！",
    "account_failure_0054": "商人は失敗を増やす！",
    "account_failure_0055": "マーチャントはロギングの失敗を増やすことができます！",
    "account_failure_0056": "ユーザーテーブルの販売者、プラットフォームの収益の転送に失敗しました。",
    "account_failure_0057": "ユーザーテーブルの販売者、プラットフォームの収益転送ログレコードに失敗しました。",
    "account_failure_0058": "ユーザーは財務管理に失敗することができます！",
    "account_failure_0059": "金融ロギングに利用できるユーザーが失敗しました！",
    "account_failure_0060": "不十分な財務管理、実行することができません！",
    "account_failure_0061": "ユーザーファイナンスが利用できませんでした。",
    "account_failure_0062": "ユーザーファイナンスが利用可能で、ロギングに失敗しました！",
    "account_failure_0063": "リターンオペレーション失败、データがない!",
    "account_failure_0064": "この货币种は当分使用できない。",
    "account_failure_0065": "无権に见るため、公人服に连络してください",
    "account_failure_0071": "ユーザのロック・ビンが冻结異常!",
    "account_failure_0072": "ユーザデポジット記録は存在しない、または修正された",
    "activitie_end": "活動の終わり",
    "activity_invalid": "期限切れ",
    "actual_amount": "実発総量",
    "actual_exchange_amount": "実際変換",
    "actual_to_account": "実際の到着",
    "addSuccess": "正常に追加されました",
    "add_channel_function": "検証チャンネル機能を追加",
    "add_charge_and_transfer_function": "入金とエントリの転送のための機能が追加されました",
    "added_alert_setting_function": "アラート設定機能を追加",
    "added_day_mode_toggle": "デイモード切り替え機能を追加",
    "address_tag": "ラベル",
    "advanced_limit_order": "高度な指値注文",
    "advertising": "広告",
    "advertising_management": "広告管理",
    "affirm": "確認",
    "affirm_pass": "確認",
    "again_digits6": "もう一度入力",
    "again_password": "もう一度入力",
    "airdrop_coin": "空中投下通貨",
    "airdrop_date": "空中投下時間",
    "airdrop_number": "エアドロップの数",
    "alipay_account": "アカウント",
    "alipay_upload": "* AlipayアップロードQRコードメソッド：開いてAlipayホームページ>お金を集める>写真を保存し、携帯電話のアルバムの領収書コードをアップロードします。",
    "all": "すべて",
    "all_buy": "すべてを購入",
    "all_country": "すべての国",
    "all_order": "すべての注文",
    "all_sell": "すべてを売る",
    "already_use": "有効化",
    "amount_of_payout": "支出額",
    "announcement_all": "すべての発表",
    "announcement_center": "通告中心",
    "announcement_official": "お知らせ",
    "annualized_rate": "年化率",
    "appeal_cancel": "アピール判断、注文取消",
    "appeal_complete": "アピール判断、注文完了",
    "appeal_dispose_time": "アピール処理時間",
    "appeal_of_time": "アピールの提出時間",
    "apply_locked": "ロックを申請する",
    "asset_allocatio": "資産配分",
    "assets_asset_valuation": "資産評価",
    "assets_can_transfer": "ストローク",
    "assets_cash_flow": "キャッシュフロー",
    "assets_coin_funds_flow": "スポット明細書",
    "assets_currency_assets": "通貨資産",
    "assets_deposit_income_tip": "収入値はリアルタイムの為替レートで計算され、特定の収入は発行値の対象となります。",
    "assets_fiat_assets": "フィアット資産",
    "assets_fiat_money_flow": "フィアット明細書",
    "assets_flowing_details": "流れる詳細",
    "assets_prompt_001": "[紛失を防ぐために鍵を正しくバックアップしてください]",
    "assets_reduced_asset": "資産変換",
    "assets_row_mining": "行マイニング",
    "assets_swipe_direction": "スワイプの方向",
    "assets_transfer": "乗り換え",
    "assets_transfer_coin": "送金通貨",
    "assets_transfer_count": "ストローク数",
    "assets_transfer_currency": "資金移動",
    "assets_transfer_dialog_bb": "コインアカウント",
    "assets_transfer_dialog_fiat": "フィアットアカウント",
    "assets_transfer_dialog_from": "から",
    "assets_transfer_dialog_transfer_to": "転送に",
    "assets_transfer_dialog_wallet": "ウォレットアカウント",
    "assets_transfer_record": "振替記録",
    "assets_transfer_to": "に",
    "assets_wallet": "ウォレット",
    "assets_wallet_assets": "ウォレット資産",
    "assets_wallet_assets_transfer_tip": "*ウォレット資産をカウンターアカウントに転送することによってのみ取引を行うことができますアカウント間の送金には送金手数料はかかりません。",
    "assets_wallet_funds_flow": "財布明細書",
    "at_least_one": "少なくとも1つのトランザクションパスワード検証ステータスをオンにします",
    "attention_blacklist": "フォロー/ブラック",
    "attention_to_see": "私にフォローいたいのは、クリックして見ます！",
    "audit_result": "監査結果",
    "authenticated": "認定",
    "authentication": "ID認証",
    "available_balance": "利用可能額",
    "average_purchase_price": "平均購入価格",
    "avoid_close_set": "無料設定",
    "back": "戻る",
    "balance": "残高",
    "bank_card": "銀行カード",
    "bank_name": "銀行名",
    "bank_num": "銀行カード番号",
    "barcode_in_the_box_scan": "ボックス内のQRコード/バーコードを自動的にスキャンする",
    "bb_assets": "通貨資産",
    "bb_history_finished": "実施する",
    "bb_time": "時間",
    "bb_used": "有効にする",
    "be_usable": "利用可能",
    "bear_consequences_your_investment": "私は投資リスクを知っており、自発的に引き受けました。",
    "become": "となる",
    "been_authenticated": "実名認証完成",
    "beginner_guide_title": "世界をリードするデジタル資産取引プラットフォーム",
    "belong_level": "所属レベル",
    "between_and": "から",
    "bill": "伝票",
    "billing_details": "勘定詳細",
    "bind_email": "メールバインド",
    "bind_google": "Google 認証",
    "bind_google_authenticator": "Google をバインドする",
    "bind_phone": "バインディングフォン",
    "branch_name": "支店名",
    "bug_order": "買い相場",
    "buy": "購入",
    "buy_1": "買う",
    "buy_now": "今すぐ購入",
    "buy_quantity": "購入数量",
    "buy_rate": "買手費用続け",
    "buyer": "買い手",
    "buyer_has_paid_attention": "バイヤーは注意を払っている",
    "buyer_payment": "買い手が支払った",
    "buyer_phone": "電話番号",
    "buyer_uid": "買い手",
    "calculate": "計算する",
    "calculations are for your reference only": "計算は参考用です",
    "can_available": "取得可能",
    "canceled": "キャンセル",
    "cancellations": "引退",
    "centre": "中",
    "change1": "変更",
    "change_gesture_password": "パスワードを変更する",
    "change_google": "Google認証システムを変更する",
    "change_pay_style": "置換方法",
    "change_phone": "電話を変更する",
    "charge_after_the_transaction": "取引後の請求",
    "charge_out": "料金は",
    "check_the_agree": "チェックすることに同意する",
    "china": "中国",
    "choose_picture": "画像を選択",
    "clearing_time": "決済時間",
    "click_enter_batch_edit_page": "クリックして一括編集ページに入ります",
    "click_on_the_copy": "コピー",
    "clinch_a_deal": "相手は数回取引必要（0は無制限に等しい）",
    "close": "閉じる",
    "closed_successfully": "閉鎖された",
    "cloud_mining_pool": "クラウドマイニングプール",
    "coin_failure_0001": "通貨は存在しません",
    "collect": "コレクション",
    "collection_time": "収集時間",
    "commission": "委託",
    "common_failure": "失敗！",
    "common_failure_0001": "ネットワーク異常",
    "common_problem": "操作説明",
    "common_success": "成功",
    "complete": "完成",
    "completed": "トランザクションが完了しました",
    "computer_login": "コンピュータログイン",
    "confirm": "確認",
    "confirm_exit": "出口を確認しますか？",
    "confirm_password": "ログインパスワードを確認してください",
    "confirm_submission": "提出を確認する",
    "confirm_the_purchase": "購入を確認する",
    "confirm_to_give_up": "権利放棄を確認する",
    "confirm_to_sell": "販売を確認する",
    "confirm_upgrade": "アップグレード確認",
    "confirm_vote": "投票を確認",
    "confirmation_information": "メッセージ確認",
    "confrim_delete_warn": "アラートを削除してもよろしいですか？",
    "contact_service": "サービス",
    "contact_us": "お問い合わせ",
    "contract_Order": "手数料",
    "contrct Agree Content": "あなたの権利を保護するために、あなたは契約を開く前に「BitMatrix永久契約契約」を注意深く読む必要があります",
    "contrct Agree Content footer": "《BitMatrix永久スワップ契約》",
    "contrct Agree Content header": "R / Iを保護するために、スワップを開く前にそれを注意深く読む必要があります",
    "contrct_decimal": "10進数",
    "convert_into": "等価",
    "converted_currency": "換算通貨",
    "copartner-upgrade-failure": "アップグレード失敗しました",
    "copartner-upgrade-no": "アップグレード条件を満たしていないと、アップグレードできません。",
    "copartner-upgrade-ok": "アップグレードできます",
    "copartner-upgrade-success": "アップグレードは成功する",
    "copy_content": "既にペーストボードにコピーされています",
    "country": "国",
    "create_time": "作成時間",
    "cumulative_income:": "累計収益：",
    "cumulative_participation": "蓄積する",
    "currency": "トークン",
    "currency_fee": "トークン手数料",
    "currency_information": "トークン資料",
    "currency_name": "トークン",
    "currency_not_found": "通貨タイプは存在しません。",
    "currency_select": "通貨選択",
    "current_day_return": "普通日に帰る",
    "current_entrust": "現在注文",
    "current_identity": "現在の個人情報",
    "current_polling_date": "現在の投票時間",
    "current_status": "現状",
    "currently_available": "現在利用可能",
    "date": "日付",
    "date_of_birth": "生年月日",
    "day_highs_and_lows": "浮き沈み",
    "day_highs_and_lows1": "変化する",
    "day_income": "日",
    "day_trading": "デイトレード",
    "daytime": "昼間",
    "ddress_management": "アドレス管理",
    "deal_amount": "総売上高",
    "deal_done": "完了しました",
    "deal_gross": "取引/合計",
    "deal_of_the_week": "今週の取引",
    "deal_price": "平均価格",
    "deal_sum": "取引金額",
    "deal_the_detail": "注文の詳細",
    "deal_volume": "取引金額",
    "deduct_coin": "控除通貨",
    "default_settlement": "違約決算",
    "defaults": "デフォルト",
    "deposit": "一押しデポジット",
    "deposit_details": "デポジット詳細",
    "deposit_income": "貨幣積立金の収益",
    "deposit_lock": "ロック預ける",
    "deposit_lock_details": "デポジットロック詳細",
    "deposit_returned_principal": "元金を返しました",
    "depth": "深さ",
    "detailed_rules": "詳細なルール",
    "details": "詳細",
    "digits6": "6桁の純数を入力してください",
    "direct_market": "直接プロモーション",
    "direct_reward": "直接報酬",
    "direction": "方向",
    "discount_Rate": "割引率",
    "discount_currency_introduction": "通貨紹介",
    "discount_dialog_tip1": "おめでとうございます！",
    "discount_dialog_tip2": "参加条件は、資格を取得後、システムにより抽選でラッキーユーザーに選ばれ、参加権が付与されます！",
    "discount_dialog_tip3": "あなたはすでに持っています",
    "discount_dialog_tip4": "特典交換の資格、活動量に限りがありますので、イベント時間内にご利用ください！",
    "discount_dialog_tip5": "あきらめて参加できない",
    "discount_dialog_tip6": "活動に参加することはできません。",
    "discount_dialog_tip7": "注：成功した控除は控除されます",
    "discount_purchase": "割引利用",
    "discounted_or_original_price": "割引価格/定価",
    "dispose_result": "処理結果",
    "disqualification": "失格",
    "divided_earnings": "収益に分ける",
    "drag": "スライダを押したまま右端にドラッグしてください",
    "each_input": "各トランザクションのトランザクションパスワードを確認する",
    "edt_selfchoose": "編集者の選択",
    "effective": "有効",
    "eligibility": "適格性",
    "email": "メール登録",
    "email_address_format": "正しいメールアドレスを入力",
    "email_code": "電子メール認証コード",
    "empty_deal_pass": "取引パスワードは空ではありません",
    "end time": "終了時刻",
    "end_date": "終了日",
    "end_time": "終了時刻",
    "enter_account": "電話またはメールアカウントを入力",
    "enter_cause_of_complaint": "クレームの理由を入力してください",
    "enter_fb_buy_price": "購入価格を入力してください",
    "enter_fb_sell_price": "販売価格を入力してください",
    "enter_your_password": "ログインパスワードを入力",
    "enter_password": "パスワード",
    "entrust_amount": "手数料額",
    "entrust_failure_0001": "ネットワーク異常",
    "entrust_failure_0002": "クエリタイプエラー",
    "entrust_failure_0003": "マッチタイプは空にできません",
    "entrust_failure_0004": "販売者IDは空欄にはできません",
    "entrust_failure_0005": "ユーザーIDは空にできません",
    "entrust_failure_0006": "ユーザーは存在しません",
    "entrust_failure_0007": "トランザクションIDは空ではありません",
    "entrust_failure_0009": "取引ペアが存在しません",
    "entrust_failure_0010": "トランザクションパスワードを空にすることはできません",
    "entrust_failure_0011": "トランザクションパスワードが間違っています",
    "entrust_failure_0012": "注文タイプは空ではありません",
    "entrust_failure_0013": "注文の数は空にできません",
    "entrust_failure_0014": "指値注文の価格は空ではありません",
    "entrust_failure_0015": "注文IDは空白にすることはできません",
    "entrust_failure_0016": "注文は存在しません",
    "entrust_failure_0017": "注文が削除されました",
    "entrust_failure_0018": "注文を取り下げることはできません",
    "entrust_failure_0019": "バージョン番号は空にできません",
    "entrust_failure_0020": "市場の注文を取り下げることはできません",
    "entrust_failure_0021": "現在のデータが更新されました",
    "entrust_failure_0022": "現在のところ注文はなく、マーケット注文には載せられません。",
    "entrust_failure_0023": "取引が無効になっているか、関連通貨が無効になっている",
    "entrust_failure_0024": "トランザクションはトランザクションに開かれています",
    "entrust_failure_0025": "手数料の価格が最低価格よりも低いか、最高価格よりも高い",
    "entrust_failure_0026": "注文数が最小限度を超えているか、または最大限度を超えています",
    "entrust_failure_0027": "このアカウントは取引が禁止になって、サービスへお問い合わせ下さい",
    "entrust_failure_0030": "現在取引一日の最高値上げ{}を超え、注文できません。",
    "entrust_failure_0031": "現在取引一日の最高値下げ{}を超え、注文できません。",
    "entrust_msg_match_type_001": "指値注文",
    "entrust_msg_match_type_002": "市場の順序",
    "entrust_msg_status_001": "未充填",
    "entrust_msg_status_002": "部分的な取引",
    "entrust_msg_status_003": "完全な取引",
    "entrust_msg_status_004": "撤去単",
    "entrust_msg_type_001": "売り注文",
    "entrust_msg_type_002": "手形を支払う",
    "entrusted_price": "コミッション価格",
    "entry_orders": "注文",
    "equal_principal": "同額元金",
    "error_email": "メールボックスエラー",
    "error_network_anomaly": "ネットワーク異常",
    "error_phone": "正しい携帯番号を入力",
    "error_unknow": "ネットワークエラー",
    "event_detail": "イベント詳細",
    "event_detail_warn_tip": "*注意：デジタル資産は、価格変動が大きいという特徴を持つ革新的な資産です。合理的な判断と投資判断を慎重に行ってください！",
    "event_start_time": "イベント開始時間",
    "every day": "毎日",
    "everyday": "毎日",
    "exchange": "変換",
    "exchange_currency": "両替通貨",
    "exchange_details": "変換詳細",
    "exchange_history": "交換記録",
    "exchange_quantity": "交換数量",
    "exchange_rules": "交換ルール",
    "exchangeable": "利用可能",
    "exclusive_posters": "専用ポスターを作成する",
    "exclusive_posters-Video": "ポスターを生成する",
    "exit": "退出",
    "expected_return": "予想収益",
    "expired": "期限切れ",
    "express_area": "特急エリア",
    "face_recognition": "顔の識別",
    "failure": "失敗",
    "fb_lookup": "クリックして表示",
    "fb_order_number": "注文番号",
    "feeze_thaw_msg_status_0001": "冷凍",
    "feeze_thaw_msg_status_0002": "解凍",
    "fill_in_at_least_one": "少なくとも1つ記入してください",
    "fill_wrong": "記入エラーと資産が失われる、慎重確認する",
    "filtrate": "選択",
    "filtrate_content": "ここで支払い方法、国、通貨ポイントをフィルタリングします！",
    "financial_management_is_cancel": "コインの収益はキャンセルされました。重複提出しないでください。",
    "financial_management_is_delete": "この投資信託のタイプはすでに削除されました。",
    "financial_management_is_disable": "この投資信託のタイプは無効になりました。",
    "financial_management_not_cancel": "定期投資はキャンセルできません。",
    "financial_management_number_not_found": "投資数量は空ではいけません。",
    "financial_management_record_add_failure": "ユーザー資産が足りないので、チャージしてから投資信託をしてください。",
    "financial_management_record_less_than_min_number": "添加した通貨の数が設定した最小の数量より小さいです{}",
    "financial_management_type_not_found": "投資信託の記録情報は失敗を表示して、投資信託のタイプは誤りを選択します！",
    "financial_management_type_not_null": "投資のタイプは空ではいけません。",
    "find_password": "パスワードを取り戻す",
    "fingerprint": "指紋",
    "fingerprint_verification": "指紋認証",
    "first_bind_phone": "最初に電話番号をバインドしてください",
    "first_period": "第一期",
    "following_account": "次のアカウント",
    "free": "手数料",
    "Takerfree": "テイカーフリー",
    "Makerfree": "メーカー無料",
    "freeze": "フリーズ",
    "freeze_to_be_processed": "申し立ては凍結され、処理待ちです",
    "fuc_use_discount": "{}を使用して取引手数料を支払います*",
    "futu": "サブピクチャ",
    "gathering": "受け取り",
    "gears": "タイプ",
    "generation1": "第1世代",
    "gesture_error_limit": "ジェスチャーパスワード入力エーラ次数は上限になる",
    "gesture_password": "ジェスチャーのパスワード",
    "gesture_unlock": "ジェスチャーロック解除",
    "get_code": "送信",
    "goSign": "登録しましょう",
    "go_change": "修正する",
    "go_login": "最初にログインしてください",
    "go_pay": "支払う",
    "go_setting": "セットに行く",
    "go_to_pick_up": "迎えに行く",
    "go_to_receiving": "受取",
    "go_to_redeem": "利用する",
    "google_verification_code": "Google認証コード",
    "grievance_freeze": "苦情の凍結",
    "has_been_on": "すでに棚に",
    "have_been_frozen": "フリーズ",
    "have_received": "受け取りました",
    "help_center": "ヘルプセンター",
    "hide_canceled": "隠された失効",
    "hide_other": "全部シングル",
    "high_frequency_partner": "高周波パートナー",
    "highest_single": "一番高い",
    "highfrequency_fee_sharing": "高周波手数料は分けます。",
    "hint": "ヒント",
    "hint_password": "パスワード",
    "hint_phone_email": "電話/メール",
    "historical_transaction": "履歴トランザクション",
    "history": "歴史",
    "home_page": "ホーム",
    "horizontal_version": "横糸口",
    "hours": "h",
    "i18n_failure_0001": "システム言語のクエリに失敗しました",
    "i18n_failure_0002": "国辞書のクエリに失敗しました",
    "iTo_draw_again": "もう一度入力",
    "i_see": "知ってる",
    "i_see1": "知ってる",
    "id_card_back_direction_failure": "IDカード裏面の画像の向きが間違っている",
    "id_card_front_direction_failure": "IDカードの正面画像の向きが間違っている",
    "id_cord": "ID番号",
    "id_type": "タイプ",
    "identification": "識別失敗",
    "identity_authentication": "ID認証",
    "identity_card": "IDカード",
    "if_at": "もし",
    "ignore": "無視する",
    "immediately_invited": "今すぐ招待",
    "immediately_join": "すぐ参加",
    "in_trading": "取引中",
    "income": "利益",
    "income_currency_type": "コインの種類",
    "income_deposit_principal": "元金を預ける",
    "incomeing": "収益中",
    "increment quantity": "入力数量は最大増加数量を超えることはできません",
    "indirect_promotion": "間接プロモーション",
    "indirect_reward": "間接報酬",
    "individual_selling_tips": "注文を自分に売ることはできません",
    "input_Branch_address": "支店の住所を入力",
    "input_address_labels": "ラベルを入力してください",
    "input_alipay_account": "アリペイアカウントを入力",
    "input_bank_card_number": "番号を入力",
    "input_bank_name": "入力",
    "input_buyer_uid": "購入者のUIDを入力してください",
    "input_currency_referred": "通貨の略称を入力してください。",
    "input_deposit_amount": "コインの数を入力してください。",
    "input_email": "メールアドレスを入力",
    "input_email_code": "メール確認コードを入力",
    "input_id_cord": "あなたのID番号を入力",
    "input_lock_amount": "招待者は獲得できます",
    "input_mention_money_address": "コインアドレスを入力",
    "input_money": "金額を入力",
    "input_name": "名前を入力",
    "input_newphone_code": "新しい電話番号を入力してください",
    "input_nike_name": "ニックネームを入力",
    "input_old_google_code": "古いGoogle認証コードを入力",
    "input_old_pass": "古いパスワードを入力",
    "input_paypal_acctount": "アカウントを入力",
    "input_phone": "電話番号を入力",
    "input_phone_code": "確認コードを入力",
    "input_price": "価格",
    "input_quantity": "数量を入力",
    "input_remark": "メモを入力",
    "input_service_charge": "料金を入力",
    "input_trading_volume": "取引金額を入力してください",
    "input_transaction_password": "ファンドのパスワードを入力",
    "input_wechat_account": "アカウントを入力",
    "input_wire_transfer": "アドレスを入力",
    "inputs_numbers_greater_can_number": "入力の数が転送の数より多い",
    "insufficient_light": "不十分な光",
    "interest": "利息",
    "invitation_details": "招待奨励詳細",
    "invitation_register_ad_not_found": "アプリ共有招待登録広告が一時的に存在しません",
    "invitation_register_ad_tatle_not_found": "アプリ共有の招待登録広告のタイトルは、現時点では存在しません",
    "invitation_register_coin_not_found": "アプリ共有の招待状登録報酬の通貨は一時的に存在しません",
    "invitation_register_logo_not_found": "アプリ共有の招待登録ロゴが一時的に存在しません",
    "invitation_register_not_found": "共有招待登録接続が一時的に存在しません",
    "invite_code": "招待コード（オプション）",
    "invite_commission": "コミッション",
    "invite_gift": "インビテーション",
    "invite_reward": "奨励を招く",
    "invited_UID": "招待者UID",
    "invitees_are_available": "招待者は獲得できます",
    "inviter-is-user": "埋め尽くされた招待者は私自身ではあり得ない",
    "inviter-not-exist": "埋められた招待者は存在しません",
    "inviter_no_null": "推薦人は暇ではない。",
    "ios_24小时排行榜": "24時間リーダーボード",
    "ios_OTC交易须知": "OTCトレーディングノート",
    "ios_PAYPAL": "ペイパル",
    "ios_otc_order_prompt_001": "あなたが1分以内にお支払いをしていない場合に、[支払いの確認]ボタンをクリックすると、カウントダウンが終了した後、注文が自動的にキャンセルされます。",
    "ios_otc_order_prompt_002": "数分以内に売り手に支払いが行われず、[支払いの確認]ボタンがクリックされた場合、カウントダウンが終了した後、注文は自動的にキャンセルされます。",
    "ios_万": "一万",
    "ios_上传身份证反面": "逆IDカードをアップロードする",
    "ios_上传身份证正面": "IDカードを前面にアップロード",
    "ios_两次密码不一致请再次输入密码": "パスワードが2回不一致の場合は、再度パスワードを入力。",
    "ios_个确认大约需要": "確認が必要です",
    "ios_中国大陆": "中国本土",
    "ios_中级合伙人": "中間パートナー",
    "ios_主版块": "メインセクション",
    "ios_之间": "〜の間",
    "ios_买单": "請求書を支払う",
    "ios_买方必须成交过几次(0等于不限制)": "買い手完成取引回数(ゼロは制限ないにイコール)",
    "ios_二维码/条形码": "QRコード/バーコード",
    "ios_付款": "お支払い",
    "ios_充币地址": "デポジットアドレス",
    "ios_先设置交易密码": "取引パスワードを最初に設定する",
    "ios_关闭照明": "照明をオフにする",
    "ios_分享失败": "共有障害",
    "ios_分享成功": "共有の成功",
    "ios_初级合伙人": "ジュニアパートナー",
    "ios_卖单": "売り注文",
    "ios_卖家已付款提示": "売り手はヒントを支払っている",
    "ios_卖方必须成交过几次(0等于不限制)": "売りて完成取引回数(ゼロは制限ないにイコール)",
    "ios_去解绑": "解を束ねて",
    "ios_发布成功": "正常にリリースされました",
    "ios_发送成功": "正常に送信されました",
    "ios_各种费率说明": "さまざまなレートの説明",
    "ios_团队人数不足": "チーム人数が足りない",
    "ios_复制QQ号": "QQ番号をコピー",
    "ios_复制地址": "住所をコピーする",
    "ios_如果你已完成线下付款，请点击下方已付款按钮。如果未完成付款，却点击已付款将会受到严厉惩罚，甚至永久冻结账户！": "オフライン支払いを完了した場合は、下の有料ボタンをクリックしてください。支払いを完了しなかった場合は、支払いをクリックするか、アカウントを永久に凍結しても、厳しい罰則が適用されます。",
    "ios_客户端或在线钱包将您需要充值的": "クライアントまたはオンラインウォレットを再充電する必要があります",
    "ios_客户端或在线钱包将您需要充幣的": "クライアントまたはオンラインウォレットを再充電する必要があります",
    "ios_客服": "カスタマーサービス",
    "ios_密码设置成功！": "パスワードは正常に設定されています！",
    "ios_将该地址设置为认证地址": "このアドレスを認証アドレスとして設定する",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充值，不影响到账。\n3、最小充值金额": "時間は、しばらくお待ちください。\n2、同じアドレスは複数回充電することができますが、アカウントには影響しません。\n3.最小充電量",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充幣，不影响到账。\n3、最小充幣金额": "時間は、しばらくお待ちください。\n2、同じアドレスは複数回充電することができますが、アカウントには影響しません。\n3.最小充電量",
    "ios_已付款": "有料",
    "ios_币币": "取引",
    "ios_常见问题": "よくある質問",
    "ios_开启照明": "照明をオンにする",
    "ios_意见反馈": "フィードバック",
    "ios_成交": "最近の取引",
    "ios_我的": "アカウント",
    "ios_手机号错误": "モバイル番号エラー",
    "ios_提交成功，等待认证": "提出されました、認証を待っています",
    "ios_提币数量不能大于手续费": "コインの額は手数料よりも大きくすることはできません",
    "ios_提币数量减去手续费后不能": "手数料を払ってコインの額を減らすことはできません",
    "ios_收款二维码": "領収書QRコード",
    "ios_收藏成功": "コレクションの成功",
    "ios_数目发送到该地址。发送完成后，系统会自动在此交易获得": "番号がこのアドレスに送信されます。送信が完了すると、システムは自動的にトランザクションを取得します。",
    "ios_暂无网络": "ネットワークなし",
    "ios_最多可锁": "最大ロック",
    "ios_最大限额": "最大限",
    "ios_最小限额": "最小限",
    "ios_未解锁": "アンロック",
    "ios_没有中国大陆身份证的用户请通过网站版高级认证": "中国の身分証明書をお持ちの方はウェブサイト版による高度認証をご利用下さい",
    "ios_法币": "OTC",
    "ios_注册表示同意": "同意を確認",
    "ios_注册表示同意用户协议": "登録は利用規約に同意します",
    "ios_登录过期，请重新登录": "ログインが切れました。もう一度ログインしてください。",
    "ios_直接推荐人uid": "直接推薦のUID",
    "ios_直推人数不足": "直進人数が足りない",
    "ios_绑定成功": "バインディングの成功",
    "ios_请上传您的身份证": "IDカードをアップロードしてください",
    "ios_请上传身份证反面照": "IDカードをアップロードしてください。",
    "ios_请上传身份证正面照": "IDカードの肯定的な写真をアップロードしてください",
    "ios_请先绑定收款方式": "まず、お支払い方法をバインドしてください。",
    "ios_请先设置支付方式": "まず支払方法を設定してください。",
    "ios_请输入价格和数量": "価格と数量を入力",
    "ios_请输入新邮箱": "新しいメールアドレスを入力",
    "ios_请输入正确的账号格式": "正しいアカウント形式を入力",
    "ios_请输入身份证号": "あなたのID番号を入力",
    "ios_请输入限额": "制限を入力",
    "ios_请通过": "パスしてください",
    "ios_资产": "資産",
    "ios_超时": "タイムアウト",
    "ios_身份证号": "ID番号",
    "ios_退出成功": "正常に終了する",
    "ios_邀请人可获得 * 的佣金": "招待者は＊のコミッションをもらえます。",
    "ios_锁仓数量不能为空": "ロック数量は空いてはいけません。",
    "ios_间接接推荐人uid": "間接参照UID",
    "ios_隐藏其他挂单": "保留中の他の注文を隠す",
    "ios_高级合伙人": "シニアパートナー",
    "is_bind": "バンド",
    "is_delete_select": "オプションを削除するかどうか",
    "issued": "発行済み",
    "issued_to_lock": "ロックするために発行された",
    "k_close": "受け取る",
    "k_max": "高い",
    "k_min": "低い",
    "k_num": "24H出来高",
    "k_open": "開く",
    "kline_target": "指標",
    "language": "言語",
    "lead_content": "注文問い合わす、広告掲載、支払い設定、フォロー/ブラックは全部ここに移動します！",
    "least_four": "少なくとも4つのポイントを接続してください",
    "less-than-min": "最小ロック限度を下回る",
    "lf there are pending": "保留中のクローズ注文（指値またはストップ）がある場合、それらはすべてクローズする前にキャンセルされます。",
    "limit": "設定制限",
    "limit_orderAA": "指値注文",
    "limit_set": "制限設定（オプション）",
    "limited_price": "限界価格",
    "liquidation": "販売を余儀なくされた",
    "liquidation AMT.": "清算件数",
    "liquidation details": "清算の詳細",
    "loading": "読み込み中",
    "lock-expire": "ロック期限が切れています",
    "lock_amount": "ロック数量",
    "lock_duration": "ロック時間",
    "lock_person": "ロックマン",
    "lock_record": "ロック記録",
    "lock_remaining": "ロック残量",
    "lock_reward": "ロックボーナス",
    "lock_time": "ロック時間",
    "locked": "ロック",
    "locked_closed": "勘定済み",
    "locked_insufficient_balance": "残高が足りない",
    "locked_settled": "勘定済み",
    "locked_unsettlement": "未決済",
    "locked_warehouse": "ロック中",
    "log_in": "ログイン",
    "login": "ログイン",
    "login_expired": "ログインが無効です。再度ログインしてください。",
    "login_forget_pwd": "パスワードを忘れた",
    "login_name_txt": "ユーザー名",
    "login_pass": "ログインパスワード",
    "logion_welcome_txt": "ログインしてください",
    "mail": "メールボックス",
    "mailbox": "Mail 認証",
    "mailbox_bind_success": "メールボックスのバインドに成功しました",
    "mailbox_new": "メールボックス",
    "main_picture": "メイン写真",
    "manage_reward_details": "管理奨励詳細",
    "management_reward": "奨励管理",
    "management_share": "管理セグメント",
    "mandarin": "北京の数字をお読みください",
    "mandarin_reading_content": "以下を標準語でお読みください",
    "manual_recharge_msg_status_0001": "再充電を待っている",
    "manual_recharge_msg_status_0002": "承認済み",
    "manual_recharge_msg_status_0003": "充電成功",
    "manual_recharge_msg_type_0001": "通常の充電",
    "manual_recharge_msg_type_0002": "資金処理",
    "manual_recharge_msg_type_0003": "イベントギフト",
    "margin": "マージン",
    "market_failure_00001": "失敗したホームページを入手する",
    "market_failure_00002": "販売者IDは存在しません",
    "market_failure_00003": "商人の下で利用可能な取引エリアはありません",
    "market_failure_00004": "通貨取引を取得できませんでした",
    "market_failure_00005": "トランザクションペア名は空にできません",
    "market_failure_00006": "マーチャントのセクション情報はありません",
    "market_failure_00007": "取引情報を取得できませんでした",
    "market_failure_00008": "K行のデータを取得できませんでした",
    "market_failure_00009": "深度データの取得に失敗しました",
    "market_failure_00010": "要求パラメータエラー",
    "market_failure_00011": "無効なトランザクションペア",
    "market_low_price": "市場最高購入価格",
    "market_order": "成行注文",
    "market_price": "市場価格",
    "market_sell_price": "市場最低販売価格",
    "market_switch_moved_here": "水平/垂直市場切り替えはここに移動されました",
    "match": "マッチ",
    "match_the_number_of": "マッチの数",
    "max_lock_volume": "最大ロック量",
    "maximum_30_characters": "最大30文字まで",
    "maximum_coins": "コインの最大額は",
    "maximum_withdrawal": "毎回最大出金",
    "mention_a_currency": "出金数量",
    "min_lock_volume": "最小ロック量",
    "min_recharge_amount": "少額が計算されていない場合、リチャージは完了、累積デポジット額が最低額に達した場合{} {}が自動的に計算されます",
    "minhour": "時分割",
    "minimum_coins": "コインの最小額は",
    "minimum_purchase_quantity": "購入した数量がこの注文の残りの数量より多い{}",
    "minimum_quantity_sold": "販売した数量がこの注文の残りの数量よりも多い{}",
    "minutes": "m",
    "mobile phone": "携帯番号",
    "money": "金額",
    "more": "もっと",
    "more Or than": "ロングポジション：額面価格x契約数x平均終値-額面価格x契約数x平均開始価格\ n \ nショートポジション：額面価格x契約数x平均始値-額面価格x契約数x平均終値",
    "more-than-max": "最大ロック限度を超える",
    "more11": "もっと",
    "more_can_save": "最大デポジット可能",
    "more_home": "もっと",
    "msg_camera_framework_bug": "カメラの権限を有効にしてください",
    "must_retain_one": "携帯電話、Google、メールボックスは、",
    "my_income": "私の収益",
    "my_prompt_001": "*携帯電話番号が正常に変更されたら、新しい携帯電話番号でログインする必要があります。",
    "my_prompt_002": "*ヒント：実際の口座を使用することを忘れないでください。お支払い方法は、OTC取引中にバイヤーに表示されます。",
    "my_prompt_003": "*メールボックスが正常に変更されたら、新しいメールボックスでログインする必要があります。",
    "my_vote": "私の投票",
    "name": "名前",
    "name_empty": "名前は空ではありません",
    "nationality": "市民権",
    "need_me_record_video": "ビデオを録画する必要があります",
    "never_enter": "取引パスワードを入力しないでください",
    "new_address": "アドレスを追加する",
    "new_pass": "新しいパスワード",
    "new_pass_empty": "新しいパスワードを入力",
    "new_pass_minimum": "パスワードは6桁を超えることはできません",
    "new_report_statistics": "新しいレポート統計",
    "newest_versions": "最新バージョン",
    "next": "次のステップ",
    "nick_name": "ニックネーム",
    "nickname_des_txt": "ニックネームは中国語と英語、数字のみをサポートし、長さは15文字に制限されています",
    "nighttime": "夜に",
    "ninety_days": "ほぼ90日",
    "no-lock-detail": "ロック情報がありません",
    "no_Senior_certification": "最初に高級認証を行ってください",
    "no_data": "データなし",
    "no_flash": "現在のデバイスにはフラッシュがなく、照明をオンにすることはできません。",
    "no_more_data": "もはや",
    "no_photo_taking": "お使いの端末は写真の撮影をサポートしていません",
    "no_price": "価格は空ではありません",
    "no_quantity": "正しい数量を入力",
    "no_realname_auth": "最初に本名の証明書を実行してください",
    "no_use": "有効化されていません",
    "none": "なし",
    "not_bound": "未バンド",
    "not_frequency_partner": "あなたはまだパートナーではありません。",
    "not_redemption": "償還の対象外",
    "not_started": "開始されていません",
    "not_to_issue": "未発行",
    "not_trading": "この貨幣の種は現在取引できない",
    "notpass": "監査は失敗しました",
    "null_address_tag": "住所ラベルは空にできません",
    "null_code": "確認コードは空白にすることはできません",
    "null_email": "メールボックスを空にすることはできません",
    "null_password": "パスワードを空にすることはできません",
    "null_phone": "電話番号は空にできません",
    "number": "個",
    "number_empty": "数量を0にすることはできません",
    "number_of_arrivals": "到着数",
    "number_of_issues": "発行総量",
    "number_of_withdrawal": "毎日出金回",
    "obtained": "得た",
    "occlusion": "オクルージョンフェイス",
    "occupy": "占める",
    "official_community": "公式コミュニティ",
    "official_verification_channel": "認証チャネル",
    "old_pass": "古いパスワード",
    "old_pass_empty": "元のパスワードを入力",
    "on_sale": "販売",
    "online_service": "オンラインサービス",
    "open XKD perpetual swap": "BitMatrixパーペチュアルスワップを開く",
    "open perpetual swap": "永久スワップを開く",
    "open_app": "アプリを開く",
    "open_it": "開始",
    "open_successfully": "正常に開く",
    "open_touchID": "TouchIDはオンになっていますか？",
    "opposite_side_uid": "お互いに",
    "option": "オプション",
    "optional": "自選",
    "optional_area": "オプションエリア",
    "options": "選択方法",
    "order": "ビル",
    "order time": "注文時間",
    "order time1": "注文時間",
    "order_a_complaint": "注文控訴",
    "order_details": "注文の詳細",
    "order_form": "注文",
    "order_history": "歴史注文",
    "order_inquiry": "注文問い合わせ",
    "order_number": "注文番号",
    "order_time": "注文タイム",
    "ordinary_partner": "一般パートナー",
    "otc_account": "アカウント",
    "otc_confirm_Put_the_coin": "相手方の送金を受けたことを確認してください（警告：支払いを確認する前にクリックしないでください。紛争が発生した場合は、カスタマーサービスの苦情に連絡してください）",
    "otc_confirm_the_payment": "支払いを確認する",
    "otc_confirm_the_receipt": "領収書を確認する",
    "otc_failure_0001": "アカウントが無効になっているため、できるだけ早くカスタマーサービスにご連絡ください",
    "otc_failure_0002": "アカウントは高度な認証を通過せず、OTC操作を実行できません。",
    "otc_failure_0003": "アカウントのトランザクションパスワードが間違っています",
    "otc_failure_0004": "注文IDが不正です",
    "otc_failure_0006": "状態の変更に失敗しました",
    "otc_failure_0009": "保留中の注文タイプは存在しません",
    "otc_failure_0010": "取り消しに失敗しました",
    "otc_failure_0011": "保留中の注文は現在完了しているか、取り消されています",
    "otc_failure_0012": "現在のユーザーは店頭業者ではなく、この機能にアクセスすることはできません。",
    "otc_failure_0013": "解凍に失敗し、取り消しに失敗しました",
    "otc_failure_0015": "そのような保留中の注文タイプはありません",
    "otc_failure_0016": "保留中の注文の数は0以下にできません",
    "otc_failure_0017": "取引価格は0以下であってはならない",
    "otc_failure_0018": "単一の最小制限は0より小さくはできません",
    "otc_failure_0019": "最大単一限度は単一最小限度を下回ることはできません",
    "otc_failure_0020": "通貨をサポートしていません",
    "otc_failure_0021": "通貨は現在利用できません",
    "otc_failure_0022": "不適切なビジネス通貨ID",
    "otc_failure_0023": "単一の最小限は、通貨の最小通貨{}より小さくすることはできません",
    "otc_failure_0024": "単一の最大制限は、通貨の最大サイズより大きくすることはできません{}",
    "otc_failure_0025": "保留中の注文の小数点以下の桁数は{}より大きい",
    "otc_failure_0026": "保留中の注文の合計金額は、注文の上限{}未満にはできません",
    "otc_failure_0027": "保留中のオーダーの合計金額は、ユーザーの上限{}より大きくすることはできません",
    "otc_failure_0028": "保留中の注文の合計金額は、販売者の上限{}を上回ることはできません",
    "otc_failure_0029": "取引価格は最低価格{}より低くすることはできません",
    "otc_failure_0030": "取引価格は上限価格{}を上回ることはできません",
    "otc_failure_0031": "現在のユーザーは支払いアカウントにバインドされていません",
    "otc_failure_0032": "フリーズできませんでした。保留中の注文は失敗しました",
    "otc_failure_0033": "同時に処理されるオーダーの最大数は0より小さくすることはできません。",
    "otc_failure_0034": "バイヤーは0未満であってはならない",
    "otc_failure_0035": "ユーザーは認定されているか、レビュー中です",
    "otc_failure_0036": "OTCトランザクションの成功回数が不十分",
    "otc_failure_0037": "解凍に失敗し、取り消しに失敗しました",
    "otc_failure_0038": "申請者の現在のアカウント{}残高が不十分{}",
    "otc_failure_0039": "現在の通貨はありません",
    "otc_failure_0040": "現在の通貨は利用できません",
    "otc_failure_0041": "提出されました。審査待ちです",
    "otc_failure_0042": "申し訳ありませんが、ユーザーは現在審査待ちですので、再度申し込むことはできません。",
    "otc_failure_0043": "そのような取引タイプはありません",
    "otc_failure_0044": "自己売却を禁止する",
    "otc_failure_0046": "この保留中のオーダーは、{}が上限に達したオーダーの最大数を処理しました。後でやり直してください",
    "otc_failure_0047": "買い手は{}回売れた",
    "otc_failure_0048": "購入数は0以下ではありません",
    "otc_failure_0049": "購入数は、保留中の注文の現在の残量より大きくすることはできません",
    "otc_failure_0050": "保留中の注文IDが間違っています。",
    "otc_failure_0051": "購入総額は最小通貨取引{}より少なくすることはできません",
    "otc_failure_0052": "購入金額は、最大通貨取引限度{}より大きくすることはできません",
    "otc_failure_0053": "現在保留中の注文タイプは販売されていません",
    "otc_failure_0054": "現在保留中の注文状況が正しくありません。",
    "otc_failure_0055": "購入金額は最小注文限度{}未満にはできません",
    "otc_failure_0056": "購入金額は最大注文限度{}より大きくすることはできません",
    "otc_failure_0057": "そのようなステータスはありません",
    "otc_failure_0058": "保留中のオーダーレコードの数が正しくない",
    "otc_failure_0059": "フリーズできませんでした。注文は失敗しました",
    "otc_failure_0060": "支払い設定におけるバインディング業者指定のタイプの入金口座を!",
    "otc_failure_0061": "売り手は{}回対処されているに違いありません",
    "otc_failure_0062": "販売数量は0以下ではありません",
    "otc_failure_0063": "販売数量は、保留中の注文の現在の残量より大きくすることはできません",
    "otc_failure_0064": "現在保留中の注文タイプは購入していません",
    "otc_failure_0065": "販売された合計金額は、最小通貨取引{}より少なくすることはできません",
    "otc_failure_0066": "販売額は、最大通貨取引限度{}より大きくすることはできません",
    "otc_failure_0067": "売り上げ額は最小注文限度{}未満にはできません",
    "otc_failure_0068": "売り上げ額は最大注文限度{}を上回ることはできません",
    "otc_failure_0069": "支払い口座IDが間違っています",
    "otc_failure_0070": "支払いの失敗を確認する",
    "otc_failure_0073": "注文変更ステータスが失敗しました",
    "otc_failure_0074": "通貨が失敗し、支払いが失敗したことが確認されました",
    "otc_failure_0075": "お支払い方法を空にすることはできません",
    "otc_failure_0076": "SMSの送信に失敗しました{}",
    "otc_failure_0077": "取引通貨が存在しません",
    "otc_failure_0078": "通貨は存在しません",
    "otc_failure_0079": "注文状況の変更に失敗しました",
    "otc_failure_0080": "取引通貨は無効です",
    "otc_failure_0081": "通貨は無効です",
    "otc_failure_0082": "注文は存在しません",
    "otc_failure_0083": "OTCは現在、取引通貨を設定していません",
    "otc_failure_0084": "OTCは現在取引通貨を設定していません",
    "otc_failure_0085": "保留中の注文の数が不十分です",
    "otc_failure_0090": "このアカウントはOTCでの取引が禁止されています、カスタマーサービスにご連絡ください",
    "otc_failure_0091": "一般ユーザはOTCリストを行うことができないので、事業者として先に申し込んでください",
    "otc_failure_0092": "現在の注文受付はタイムアウトしておらず、購入者は不服を申し立てることができません。",
    "otc_failure_0093": "申し立てが上訴されました。カスタマーサービスにご連絡ください。",
    "otc_failure_0094": "登録の規則によっては、この操作はできません。",
    "otc_failure_0095": "この注文は取引できません",
    "otc_failure_0096": "売り手のアカウント資産が異常であり、一時的に操作を実行できません。",
    "otc_failure_0097": "当日のOTCキャンセル数が制限を超え、取引が禁止されています",
    "otc_failure_0098": "「未完成注文があるので、完成したあと注文を続けてください。",
    "otc_margin_certification": "マージン認証",
    "otc_mine_account_paid": "私は支払った",
    "otc_order_prompt_003": "購入者が指定した時間内に支払いを行わず、[支払いの確認]ボタンをクリックすると、カウントダウンが終了した後、注文が自動的にキャンセルされます。",
    "otc_picture_no_null": "少なくとも1枚の画像をアップロードしてください。",
    "otc_prompt_001": "1、注文の支払いは30分間有効です。時間内に支払い、（支払い済み）ボタンをクリックしてください。\n2、販売者の通貨はシステムによってロックされていますので、ご注文ください。",
    "otc_rate": "レート",
    "otc_report_order": "ご注文",
    "otc_success_0001": "キャンセルの成功",
    "otc_success_0002": "成功した販売",
    "otc_success_0003": "成功した購入",
    "otc_success_0004": "支払いの成功を確認する",
    "otc_success_0005": "領収書が成功したことを確認し、注文が終了しました",
    "otc_success_0006": "成功した訴え",
    "otc_waiting_for_confirmation": "受取を待つ",
    "other_settings": "他の設定",
    "overtime_pay": "タイムアウト、キャンセル",
    "param-error": "パラメータエラー",
    "partner": "パートナー",
    "partner_commission": "パートナーは下級の手数料をもらって返納します。",
    "partner_commission_fee": "手数料コミッション",
    "partner_current_level": "現在のレベル",
    "partner_get_all_subordinate": "すべての部下を持つことができます。",
    "partner_management": "パートナー管理",
    "partner_need_pay": "支払い",
    "partner_need_recommend": "直進が必要",
    "partner_recommended": "直進しました　",
    "partner_start_and_end_time": "開始時間と終了時間",
    "partner_team": "チーム",
    "partner_team_member": "チーム人数が必要",
    "partner_upgrade_immediately": "即時アップグレード",
    "pass_muster": "要件を満たしていない場合は、ここをクリックしてください",
    "passed": "合格",
    "passport": "パスポート",
    "password_disagree": "2つのパスワードが矛盾している",
    "password_is_different": "不一致のパスワード入力",
    "password_text": "パスワード",
    "password_wrong": "8〜20桁のログインパスワード",
    "past_activities": "過去のイベント",
    "pause_recharge": "入金できない",
    "pause_withdraw": "出金できない",
    "pay_number": "お支払い金額",
    "pay_wey": "お支払い方法",
    "payee": "受取人",
    "payfor_type": "お支払い方法",
    "payment": "お支払い",
    "payment_amount": "お支払い金額",
    "payment_currency": "支払い通貨",
    "payment_has_been": "（私は支払った）",
    "payment_term": "コレクションメソッド",
    "paypal_account": "アカウント",
    "pending_review": "レビュー待ち",
    "people": "人",
    "period": "期",
    "personal_detail": "個人情報",
    "personal_purchase_tips": "あなた自身の注文を購入することはできません",
    "phone": "携帯電話",
    "phone_code": "電話認証コード",
    "phone_register": "モバイル登録",
    "phone_verification": "SMS 認証",
    "phone_verify": "携帯電話認証",
    "photo_album": "アルバム",
    "please_enter": "入力",
    "please_enter_abbreviation_or_letter": "検索内容を入力する",
    "please_input_content": "コンテンツを入力してください",
    "please_pay": "お支払いください",
    "please_verification": "確認してください",
    "pop_Date": "日付",
    "pop_reset": "リセット",
    "position at market price?": "ポジションは市場価格で完全にクローズされていますか",
    "post_only": "メーカーのみ",
    "price": "価格",
    "price_cannot_be_less": "価格は{}より小さくすることはできません",
    "price_cannot_be_more": "価格は{}より大きくすることはできません",
    "price_empty": "価格を0にすることはできません",
    "principal": "元金",
    "promote_links": "プロモーションリンク",
    "promotion_statistical": "プロモーション統計",
    "prtner_invalid": "効力を失う",
    "publish_a_trade_order": "注文作成",
    "purchase": "購入",
    "push": "PUSH資産",
    "push_maximum_volume": "PUSHの数量が利用可能な残高よりも大きい{}",
    "push_minimum_trading_volume": "PUSHの数量が最小取引額{}より少ない",
    "quantity": "額",
    "quantity_deal": "数量/取引",
    "quota": "制限",
    "range": "範囲",
    "real_income": "実発収益",
    "real_name_authentication": "本名認証",
    "reason": "理由",
    "rebate_amount": "還付金",
    "rebate_record": "返上記録",
    "rebate_reward": "リベート報酬",
    "receiving_set": "受取設置",
    "receiving_set_title": "先に入金方法を設定してください。",
    "recently_deal1": "最近の取引",
    "recharge_notice": "リチャッジお知らせ\n1、{}クライアントまたはオンライン財布を通じて必要なリチャッジ{}数量はこのアドレスへ送信してください。発送が完了システムはこの取引で{}つの确认を得た后仮想货币を当駅にチャージする、お待ちください。\n2、同じアドレスは何度もリチャッジすることができて、入金に影响しません。\n3、最小リチャッジ金額{}。",
    "recharge_source": "그 화폐는 현재 거래할 수 없다",
    "recharge_withdraw_failure_0001": "ネットワーク異常",
    "recharge_withdraw_failure_0002": "トランザクションパスワードを空にすることはできません",
    "recharge_withdraw_failure_0003": "確認コードは空白にすることはできません",
    "recharge_withdraw_failure_0004": "販売者IDは空欄にはできません",
    "recharge_withdraw_failure_0005": "ユーザーIDは空にできません",
    "recharge_withdraw_failure_0006": "ユーザーは存在しません",
    "recharge_withdraw_failure_0007": "通貨IDは空白にすることはできません",
    "recharge_withdraw_failure_0009": "通貨は存在しません",
    "recharge_withdraw_failure_0010": "Google認証コードは空欄にはできません",
    "recharge_withdraw_failure_0011": "トランザクションパスワードが間違っています",
    "recharge_withdraw_failure_0012": "再充電タイプは空にできません",
    "recharge_withdraw_failure_0013": "再充電回数は空欄にはできません",
    "recharge_withdraw_failure_0014": "充電メモを空にすることはできません",
    "recharge_withdraw_failure_0015": "再チャージIDは空にできません",
    "recharge_withdraw_failure_0016": "充電リストは存在しません",
    "recharge_withdraw_failure_0017": "再充電待ちのみを削除できます",
    "recharge_withdraw_failure_0018": "再充電が審査されるまで待ちます",
    "recharge_withdraw_failure_0019": "承認の承認のみが凍結を発行することができます。",
    "recharge_withdraw_failure_0020": "バージョン番号は空にできません",
    "recharge_withdraw_failure_0021": "パラメータエラー",
    "recharge_withdraw_failure_0022": "現在のデータが更新されました",
    "recharge_withdraw_failure_0023": "IDは空ではありません",
    "recharge_withdraw_failure_0024": "ロックされたステータスのみを確認できます",
    "recharge_withdraw_failure_0025": "引き出しステータスがロックされるのを待つだけです",
    "recharge_withdraw_failure_0026": "現金引き出しまたはロック状態を待っているときにのみキャンセル",
    "recharge_withdraw_failure_0027": "フリーズ回数は空ではありません",
    "recharge_withdraw_failure_0028": "凍結の理由は空ではありません",
    "recharge_withdraw_failure_0029": "解凍されたメモは空ではありません",
    "recharge_withdraw_failure_0030": "コインの額は空ではありません",
    "recharge_withdraw_failure_0031": "コインアドレスは空欄にできません",
    "recharge_withdraw_failure_0032": "料金は空ではありません",
    "recharge_withdraw_failure_0033": "異常処理手数料",
    "recharge_withdraw_failure_0034": "データが存在しません",
    "recharge_withdraw_failure_0035": "資産リストを解凍することはできません",
    "recharge_withdraw_failure_0036": "コインアドレスが正しくありません",
    "recharge_withdraw_failure_0041": "このアカウントは出金が禁止になって、サービスへお問い合わせ下さい",
    "recharge_withdraw_failure_0042": "コインの数がその日の限界に達しました",
    "recharge_withdraw_failure_0043": "集められたコインの総量が当日の制限を超えています",
    "recharge_withdraw_failure_0044": "異常なVIPレベル情報の取得",
    "recharge_withdraw_failure_0045": "異常な通貨情報の入手",
    "recharge_withdraw_failure_0046": "パスワードをリセットしてから24時間以内に硬貨を引き出さないでください！",
    "recharge_withdraw_failure_0048": "一枚のコインの最大値を取得します",
    "recharge_withdraw_failure_0049": "一枚のコインの最小値を取得します",
    "recharge_withdraw_failure_0050": "入力数は最大制限より少なくなければなりません",
    "recharge_withdraw_failure_0051": "入力数は最小制限よりも大きくなければなりません",
    "recharge_withdraw_msg_status_0001": "撤退を待っている",
    "recharge_withdraw_msg_status_0002": "送信",
    "recharge_withdraw_msg_status_0003": "現金引き出しの成功",
    "recharge_withdraw_msg_status_0004": "キャンセル",
    "recharge_withdraw_msg_status_0005": "確認",
    "recharge_withdraw_msg_status_0006": "充電成功",
    "recharge_withdraw_msg_status_0007": "レビュー済み",
    "recharge_withdraw_msg_type_0001": "再充電する",
    "recharge_withdraw_msg_type_0002": "引退",
    "recharge_withdraw_msg_withdraw_type_0001": "プラットフォーム交換",
    "recharge_withdraw_msg_withdraw_type_0002": "外部硬貨",
    "record": "レコード",
    "record_the_video": "クリックしてビデオを録画する",
    "recording": "レコー",
    "redeem_now": "今すぐ交換",
    "redeemed": "利用済み",
    "redeeming": "交換",
    "redemption_amount": "償還額",
    "redemption_time": "償還時間",
    "referrer": "紹介者",
    "register": "登録",
    "registered_successfully": "成功した登録",
    "registration_award_details": "登録奨励詳細",
    "registration_incentives": "登録報酬",
    "registration_time": "登録時間",
    "regular": "定期",
    "regular_return": "定期月帰り",
    "release": "リリース",
    "release_operation": "注文を発表、ここをクリックして操作してください！",
    "release_the_order": "リリース注文",
    "release_time": "配布時間",
    "remark": "備考",
    "remember_state": "口座を覚え",
    "repeal": "キャンセル",
    "reset": "リセット",
    "reset_login_password": "ログインパスワードを変更する",
    "reset_pass": "パスワード再設定",
    "residue": "残り",
    "retrieve_the_deposit": "貨幣を取り戻す",
    "return_amount": "戻り量",
    "return_method": "戻り方法",
    "return_the_principal": "元金を返す",
    "revenue_details": "収益詳細",
    "revocation_all": "すべてを取り消す",
    "reward_amount": "奨励数量",
    "reward_locked": "ロックを奨励",
    "reward_status": "報酬ステータス",
    "reward_type": "報酬タイプ",
    "rewards_record": "報酬記録",
    "right_now_register": "新規登録",
    "roll_out": "転送する",
    "save": "保存",
    "save_coin": "保存",
    "save_coin_count": "貨幣の数",
    "save_coin_days": "入金日",
    "save_coin_duration": "貨幣を預ける時は長いです",
    "save_currency": "デポジット",
    "save_money_amount": "デポジット総量",
    "save_successfully": "保存しました",
    "save_the_image": "保存する",
    "scan_QR_code": "不明なソースのQRコードをスキャンしないでください",
    "scan_result": "スキャン結果",
    "search": "検索",
    "search_coin_placeholder": "興味のある通貨を検索してください",
    "search_keyword": "検索キーワード",
    "search_name": "検索名",
    "search_result": "検索結果",
    "search_trade_pair_placeholder": "興味のあるトレーディングペアを検索します",
    "security_center": "安全中心",
    "security_level": "安全レベル",
    "select_date_of_borth": "生年月日を選択してください",
    "select_delete_warn": "削除するアラートを選択してください",
    "sell": "販売",
    "sell_1": "売る",
    "sell_order": "売り相場",
    "sell_quantity": "販売数量",
    "sell_rate": "売手費用続け",
    "seller": "売り手",
    "seller_phone": "電話番号",
    "send": "送信",
    "senior_certification": "高級認証",
    "senior_certification1": "高級認証",
    "service": "カスタマーサービスQQ：",
    "service_charge": "手数料",
    "session_timeout": "ログインが切れました。もう一度ログインしてください。",
    "set_Password": "変更",
    "set_gesture_pass": "ジェスチャーパスワードを設定してください。",
    "set_login_password": "ログインパスワードを設定する",
    "setting": "設定",
    "setting_alipay": "アリペイをセットアップする",
    "setting_bank_card": "銀行カードを設定する",
    "setting_message_warn_tip": "*保存を有効にすると、警告メッセージが表示され、プロンプトなしで閉じることができます。",
    "setting_paypal": "ペイパルを設定する",
    "setting_paypassword": "トランザクションパスワードを最初に設定してください。",
    "setting_wechat": "WeChatを設定する",
    "setting_western_union": "Western unionを設置",
    "share_invitation_link": "招待状の共有リンク",
    "share_link": "友達とのリンクを共有する",
    "share_to_do": "共有する",
    "shift_to": "転送",
    "single_least": "単一の最小制限は{}未満です",
    "single_minimum": "最小単価",
    "single_more": "単一の最小制限が{}よりも大きい",
    "skip": "スキップ",
    "small_exchange": "小額交換",
    "small_exchange_tip": "*注意：24時間以内に、評価が{} {}未満の資産のみを償還できます。上場廃止取引の通貨は一時的に交換できません。暫定為替手数料は{}です",
    "small_exchange_tip-1": "*注意：時価はリアルタイムで変動し、実際の価格が優先されます。上場廃止された通貨は変換できません。現在の変換手数料は{}",
    "small_exchange_valuation": "評価",
    "small_exchange_valuation-1": "BTC 価値",
    "smallest_unit_price": "PUSH単価が最小単価{}より低い",
    "so adjusted to": "に調整",
    "sold_out": "削除されました",
    "start time": "開始時間",
    "start_date": "開始日",
    "start_time": "開始時間",
    "statistics_report": "レポート統計",
    "status": "状態",
    "submit": "ビデオを投稿する",
    "submitBtn": "提出する",
    "succeed": "成功",
    "successful_invitation": "招待状",
    "successfully_received": "正常に受信されました",
    "successfully_set": "正常にセットアップしました",
    "super_partner": "スーパーパートナー",
    "switch_account": "アカウントの切り替え",
    "system_top_up": "システムチャージ",
    "system_unlock": "システムロック解除",
    "tab_assets": "資産",
    "tab_home": "ホーム",
    "tab_markets": "マーケット",
    "tab_swaps": "契約する",
    "tab_trade": "取引",
    "taking_pictures": "写真を撮る",
    "telegraph": "Telegraph：",
    "temporary_not_online_contact_service": "まだオンラインではありません。カスタマーサービスにお問い合わせください",
    "the_day": "日",
    "the_event_is_over": "イベントは終了しました",
    "the_latest_price": "最新価格",
    "the_password_is_correct": "パスワードは正しい",
    "theme": "テーマ",
    "this_month": "今月",
    "this_week": "今週",
    "ticket": "チケット",
    "time_of_payment": "お支払い時間",
    "time_the_input": "{} h以内に取引パスワードを入力する必要はありません",
    "timeout": "タイムアウト",
    "timeout_affirm": "タイムアウト未確認",
    "timeout_deal": "タイムアウト取引",
    "timeout_to_cancel": "タイムアウト、キャンセル",
    "to_confirm": "確認する",
    "total_buyer": "総売り手",
    "total_count": "合計",
    "total_exchange": "合計変換",
    "total_lock_amount": "総ロック量",
    "total_money": "合計金額",
    "total_return": "総利益を見込む",
    "trade_fee": "取引ペア手数料",
    "trade_type": "取引タイプ",
    "trading_currency": "取引通貨",
    "trading_fiat_currency": "フィアット通貨の取引",
    "trading_instructions": "取引の指示",
    "trading_on": "ペア",
    "trading_pair_abbreviation": "取引ペアの略称を入力してください。",
    "trading_this_month": "今月の取引",
    "trading_volume": "取引量",
    "transaction_description": "取引の説明",
    "transaction_password": "ファンドパスワード",
    "transaction_price": "取引価格",
    "transfer_number": "数量を入力",
    "try_it_now": "やってみよう",
    "type": "タイプ",
    "unable_to_access_album": "現在のデバイスはアルバムへのアクセスをサポートしていません",
    "unaccalimed": "未回収",
    "unavailable": "利用できません",
    "under_review": "レビュー中",
    "univalence": "単価",
    "unlock_details": "ロック解除詳細",
    "unlock_mode": "ロック解除方式",
    "unlock_period": "ロック解除サイクル",
    "unlock_plan": "ロック解除計画",
    "unlock_plan_unlock": "ロック解除",
    "unlock_record": "ロック解除記録",
    "unlock_time": "ロック解除時間",
    "unlocked": "ロック解除済み",
    "unredeemed": "未使用",
    "unverified": "証明されていない",
    "up_to_three": "3つまで有効にする",
    "upgrade": "アップグレード",
    "upload_again": "再アップロード",
    "upload_front": "ドキュメントをアップロードする",
    "upload_identity_card": "IDをアップロードしてください",
    "upload_image_success": "画像を正常にアップロード",
    "upload_information_as_required": "上記の要件に厳密に従ってデータをアップロードする必要があります。そうしないと、認証が失敗します",
    "upload_passport": "パスポートをアップロードしてください",
    "upload_passport_info_picture": "パスポート情報ページの写真をアップロードする",
    "upload_reverse": "文書の裏面をアップロードする",
    "ups_and_downs": "当日の上がり下がり",
    "ups_downs_habit": "浮き沈みの習慣",
    "usable": "使える",
    "used": "中古",
    "user-bind-failure": "招待者のバインディングが失敗しました。",
    "user-bind-success": "招待者のバインディングが成功しました。",
    "user-collection-error": "失敗",
    "user-country-code-not-empty": "国際コードは空ではありません",
    "user-disable-trade": "当座預金口座は取引が禁止されています。詳細についてはカスタマーサービスにお問い合わせください。",
    "user-down-notbind": "ユーザーは下位ユーザーをバインドできません",
    "user-email-fail-often": "メールが頻繁に送信され、120秒後に再送信してください",
    "user-email-not-empty": "メールアドレスは空にできません",
    "user-email-verification-code-out": "メールボックス確認コードがタイムアウトしました。再送信してください",
    "user-fail-advanced-auth": "高度な認証に失敗しました",
    "user-fail-advanced-authed": "ユーザーはシニアによって認定されています",
    "user-fail-assetnotenough-error": "不十分な資産",
    "user-fail-bank-count-error": "最大3つの支払い方法を開く",
    "user-fail-bank-inotc": "お支払い方法にはオーダーがあり、無効にすることはできません",
    "user-fail-cardno-repeat-error": "ID番号が使用されています",
    "user-fail-email-code": "メール確認コードのエラー",
    "user-fail-google-binded": "Googleが縛っている",
    "user-fail-google-code": "Google認証コードのエラー",
    "user-fail-inviter-notexist": "推薦者は存在しません",
    "user-fail-login-disable": "現在アカウントログインを禁じられ、詳細カスタマーサービスに連絡してください！",
    "user-fail-login-lock": "アカウントがロックされています",
    "user-fail-login-password": "ユーザー名またはパスワードが誤り、再試行余回数{}",
    "user-fail-mail-binded": "メールボックスがバインドされました",
    "user-fail-not-realname": "最初の本名の証明をしてください",
    "user-fail-oldpassword-error": "元のパスワードが間違っています",
    "user-fail-partner-not-found": "商人は存在しません",
    "user-fail-partnernotfound": "商人は存在しません",
    "user-fail-password": "ユーザー名またはパスワードが正しくありません",
    "user-fail-password-blank": "パスワードを空にすることはできません",
    "user-fail-password-blank1": "パスワードの確認は空にできません",
    "user-fail-password-repeat": "設定した新しいパスワードは古いパスワードと同じです。再設定してください。",
    "user-fail-password-thin": "パスワードの強度が弱すぎます",
    "user-fail-passwword-eq-paypassword": "トランザクションパスワードはログインパスワードと同じです。リセットしてください。",
    "user-fail-paypassword-error": "支払いパスワードのエラー",
    "user-fail-paypassword-notset": "最初に支払いパスワードを設定してください。",
    "user-fail-paypassword-set": "支払いパスワードが設定されています",
    "user-fail-phone-binded": "電話番号がバインドされました",
    "user-fail-realname-auth": "ユーザーの実名認証に失敗しました",
    "user-fail-realname-authed": "ユーザーは実名で認証されています",
    "user-fail-realname-notset": "最初の本名の証明をしてください",
    "user-fail-reg": "登録に失敗しました。サーバーエラー",
    "user-fail-reg-mail": "メールボックスの形式が正しくありません",
    "user-fail-reg-mail-exist": "メールボックスが登録されました",
    "user-fail-reg-phone-exist": "電話番号が登録されています",
    "user-fail-securitycount": "少なくとも1つの確認方法を維持する",
    "user-fail-sms-code": "SMS認証コードエラー",
    "user-fail-sms-code-new": "新しい携帯電話番号SMS認証コードエラー",
    "user-fail-sms-code-old": "旧携帯電話番号SMS認証コードエラー",
    "user-fail-usernameisblank": "ユーザー名は空にできません",
    "user-id-or-nationCode—is-null": "ユーザーIDまたは国際コードが空です",
    "user-login-success": "ログインの成功",
    "user-mail-fail": "メールの送信に失敗しました",
    "user-mail-success": "メール配信の成功",
    "user-not-exist": "このユーザーは存在しません",
    "user-notopen-success": "まだ開通していません。",
    "user-phone-not-empty": "電話番号は空にできません",
    "user-sms-fail": "送信失敗、サーバーエラー",
    "user-sms-fail-often": "頻繁にSMSを送信する",
    "user-sms-success": "SMSが正常に送信された",
    "user-sms-verification-code-out": "確認コードがタイムアウトしました。再送信してください",
    "user-success-advanced-auth": "高度な認証が成功しました",
    "user-success-realname-auth": "ユーザーの実名認証に成功しました",
    "user-validate-code-fail": "確認コードが間違っています",
    "user-validate-fail": "確認に失敗しました",
    "user-vip-common-fail": "VIPの購入に失敗しました",
    "user_agreement": "同意したユーザーサービス契約にチェックを入れてください",
    "user_collection_cencel_success": "キャンセル",
    "user_collection_insert_success": "収集済み",
    "user_pay_disable_state": "このアカウント取引はパスワードエラーの制限が禁止されたので、2時間後に再テストしてください",
    "user_pay_is_error": "取引パスワードのエラー、あなたは{}を入力することができて、期限を2时间冻结します",
    "user_pay_password_not_setting": "ユーザーが取引パスワードを設定しない。先ず取引パスワードを設定してください。",
    "user_protocol": "利用規約",
    "user_service_agreement": "ユーザーサービス契約",
    "user_vip_has_expire": "このアカウントのvipは期限が切れていますので、アップグレードできません。",
    "user_vip_update_failure": "このアカウントは続き料とアップグレードをサポートしていません。詳しくはカスタマーサービスにお問い合わせください。",
    "valid_identity_card": "正しいID番号を入力",
    "valuation": "デポジット試算",
    "verification_code_error": "確認コードエラー",
    "verification_failed_001": "検証失敗",
    "verification_finish": "確認が完了しました",
    "version_updating": "バージョンの更新",
    "versions": "バージョン",
    "vertical_version": "縦糸口",
    "view_activity": "アクティビティを表示",
    "view_lock": "ロックを見る",
    "volamount": "買い手完成取引回数{}になければならない",
    "volamount_sell": "売りて完成取引回数{}になければならない",
    "vote": "投票する",
    "vote_add_coin": "リストに投票する",
    "vote_return": "戻る",
    "voting_coin": "投票通貨",
    "voting_number": "投票",
    "voting_time": "投票時間",
    "wait_buyer_payment": "支払いを待つ。",
    "wait_for_payment": "保留中の支払い",
    "waiting_for_receiving": "受取を待つ",
    "warm_prompt": "暖かいリマインダー",
    "warning_setTitle": "早期警告設定",
    "wechat_account": "アカウント",
    "wechat_upload": "* WeChatのアップロードQRコードの方法：オープンWeChatのホーム>私>財布>前払い>写真を保存し、携帯電話のアルバムの領収書コードをアップロードします。",
    "week": "週",
    "welcome": "BitMatrixへようこそ",
    "welcome_to": "へようこそ",
    "winning_record": "受賞歴",
    "withdrawal_amount": "毎日出金額",
    "withdrawal_charge": "出金手数料",
    "xilian": "ウェスタンユニオン",
    "yesterday_income": "昨日の収益",
    "you_have_selected": "選択しました",

    "Your closing number": "成約数量が最小ポジションを下回っています！",
    "Your have pending Cross orders": "保留中のクロスオーダーがあります。レバレッジを調整する前にキャンセルしてください。",
    "Your have pending isolated orders": "保留中の孤立した注文があります。レバレッジを調整する前にキャンセルしてください。",
    "Home page apps": "ホームアプリケーション",
    "Home_Edit": "編集",
    "recently_deal01": "取引",
    "depth01": "深さ",
    "depth02": "深さ",
    "discount_currency_introduction01": "通貨紹介",
    "Info001": "情報",
    "Avg cost": "平均費用",
    "Long position": "長さ",
    "Short position": "短い",
    "Hold on": "後で",
    "Done": "終わり",
    "new_version": "新しいバージョン",
    "Announcement": "告知",
    "View details": "詳細をチェック",

    "Option Assets": "オプションアセット",
    "Option Account": "オプションアカウント",
    "Option": "オプション",
    "1M": "1分",
    "5M": "5分",
    "15M": "15分",
    "30M": "30分",
    "1H": "1時間",
    "Delivery Time": "秒読み",
    "Call": "買い場",
    "Put": "売り場",
    "Draw": "平場",
    "Draw2": "平場",
    "Call1": "上昇",
    "Put1": "下降",
    "Draw1": "平",
    "Buy-2": "購入",
    "Rate of return": "利益率",
    "Change": "上下幅",
    "Buy Option": "購入",
    "Awaiting": "待ち",
    "Delivery": "マイ",
    "Checkout": "记录",
    "Front": "当期",
    "Back": "次期",
    "Coming soon": "もラすぐクロージング",
    "delivery settlement": "クロージング清算中",
    "Ongoing": " 購入中",
    "Pending purchase": "未購入",
    "Billing Amount": "決済数量",
    "Amount": "購入数量",
    "Delivery result": "クロージング結果",
    "Delivery time": "クロージング時間",
    "Up": "高",
    "Down": "安",
    "Delivery details": "クロージング詳細",
    "Index Composition": "指数構成",
    "Exchange": "取引所",
    "Change2": "上下",
    "Weights": "加重",
    "Order number": "注文番号",
    "Option Session": "オプションチャート",
    "Buy Time": "購入時間",
    "Opening time": "寄付時間",
    "Bet Type": "購入タイプ",
    "Opening Price": "始値",
    "Delivery Price": "クロージング価格",
    "Settlement Currency": "決済通貨",
    "K-line": "kライン",
    "UpSelect": "上幅選択",
    "DownSelect": "下幅選択",
    "Front has ended, please place another order": "注文が完了しました。別の注文をしてください",
    "Successfully ordered": "注文の成功",
    "Insufficient balance": "残高不足です",
    "Quantity purchased must be greater than the minimum limit": "購入数量は最小制限を超えている必要があります",
    "Quantity purchased must be less than the maximum limit": "購入数量は上限未満である必要があります",
    "The current time is not in the purchase time range": "現在の時刻が購入時間の範囲内にありません",
    "expected": "予想収益",
    "To_a_game": "もう1つのゲーム",
    "Settlement Result": "決済結果",
    "Delivery-1": "クロージング",
    "Ups and downs": "変更",
    "duration": "間隔",
    "Option funds daybook": "明細書",


    "Open innovative options trading": "革新的なオプション取引を開く",
    "Confirm Open": "オープンを確認",
    "Option Agree Content": "あなたの権利を保護するために、オプションを開く前に、《革新的なオプション取引使用契約》を注意深く読む必要があります。",
    "Option Agree Content footer": "《革新的なオプション取引使用契約》",
    "Option Agree Content header": "あなたの権利を保護するために、オプションを開く前に、あなたは注意深く読む必要があります",

    "Service upgrade, please try again later!": "サービスのアップグレード、後でもう一度やり直してください！",
    "month": "M",

    "Invitation": "招待",
    "Markets": "市場",
    "Swaps": "契約",
    "Trade": "取引",
    "Trade-1": "取引",
    "Assets": "資產",
    "Buy": "買う",
    "Help": "ヘルプ",
    "Service": "サービス",
    "Activity": "活動",
    "Communtiy": "コミュニティ",
    "No search results": "検索結果なし",
    "The option account asset is 0, please transfer from the wallet account to the option account first.": "オプション口座の資産は0です。最初にウォレット口座からオプション口座に送金してください。",
    "Perpetual account assets are 0, please transfer from wallet account to perpetual account first.": "オプション口座の資産は0です。最初にウォレット口座からオプション口座に送金してください。",
    "Financing": "財務管理",
    "Load": "ロード",
    "Replay001": "リプレイ",
    "Constellation Program": "人気-コンステレーションプログラム",
    "Constellation Program2": "人気-コンステレーコンステレーションファイナンス",
    "rate rankings": "単一の勝率ランキングを持つ機関",
    "rate rankings2": "トップ金融機関",

    "CXT Quotes": "の引用",
    "Digital asset trading tool service platform": "デジタル資産取引ツールサービスプラットフォーム",
    "Service_home": "サービス",
    "Strategic cooperation": "戦略協力",

    "Financing assets": "金融資産",
    "Remove 0 from the first number when registering!": "登録時に最初の番号から0を削除してください！",
    "Are you sure you want to quit": "あなたはBitMatrixを終了してもよろしいですか？",


    "Opening Price": "始値",
    "Closing Price": "終値",
    "Formula interpretation": "公式説明",
    "Note: If the input box is left blank": "注：入力バーにデータなしの場合、現在取引所のデータを獲得できなかったことです",
    "Option Sessions": "オプションセッション",
    "Result": "結果",
    "When data for an exchange is not available": "*何れかの取引所のデータを獲得できなかった場合、加重は0%となります。有効データ元≤2の場合、流局と定義されます。",
    "Assume that the index is derived from the opening prices of five exchanges": "例えば、指数源である5つの取引所の始値がそれぞれA、B、C、D、Eで、引け値がそれぞれA`、B`、C`、D`、E`である場合、",
    "The proportion of those five exchanges are": "5つの取引所の相応加重はそれぞれ：a%、b%、c%、d%、e%です。",
    "Stream bureau": "ストリームビューロー",
    "(valid data source ≤ 2)": "（有効なデータソース≤2）",
    "Option calculator": "オプション電卓",
    "Round selection": "場を選択",
    "Select option": "オプション選択",
    "Average opening price": "平均始値",
    "Average closing price": "平均終値",
    "Calculate now": "計算開始",
    "Calculation Results": "計算結果",
    "Calculating Time": "時間の計算",
    "Management": "管理",
    "Other_home": "他の",
    "All applications": "すべてのアプリケーション",
    "Boon": "ブーン",
    "Coin_Address_draw": "出金アドレス",
    "Congratulations": "おめでとう",
    "Profit": "利益",
    "Gainers": "ゲイン",
    "Drop": "落とす",


    "Order Price": "費用",
    "Order Price1": "費用",
    "Sec Swap": "Sec Swap",
    "Expected Return": "期待収益率",
    "Sec Swap Assets": "Sec Swap Assets",
    "Sec Swap Bill": "Sec Swap明細書",
    "Sec Swap Account": "Sec Swap 口座",
    "Go Login": "ログイン",
    "NSO Session": "NSO 分",
    "Wallet Value1": "財布資産",
    "Spot Value1": "スポット資産",
    "PERP Value1": "永久資産",
    "Option Value1": "オプション資産",
    "Fiat Value1": "法定資産",
    "Pledge_Amount": "質権量",
    "total_return2": "累計収入",
    "Violation_Loss": "違反損失",
    "Cycle": "周期",
    "Locked2": "ロック済み",
    "Time_Left": "残り時間",
    "Cancel_Pledge": "質権取消",
    "countDown_day": "D ",
    "Entrust2": "委託注文",
    "All2": "全て",
    "Ongoing2": "進行中",
    "Near2": "近々",
    "Ended2": "終了",
    "Cancel the pledge?": "質権取消?",
    "Lock-up mining": "付加価値",
    "Rules Description": "規則",
    "Today Expected": "今日推定収益",
    "Order Escrow": "委託注文",
    "Minimum": "最低限",
    "Daily Return": "日利益率",
    "Buy-3": "購入",
    "Active Currency": "活動通貨",
    "Minimum Amount": "最低限",
    "Lockup Period": "付加周期",
    "Confirm Pledge": "質権確認",
    "Buy-4": "購入",
    "Completed2": "完成",
    "Cancelled2": "既取消",
    "Conversion Starter": "開始交換",
    "Listing": "リスト",
    "Total Target": "総目標",
    "Conversion Ratio": "引換比率",
    "Single Limited": "個人限定",
    "Participate Order": "注文参加",
    "Fundraised": "募金",
    "Current Progress": "現在進歩",
    "Time Left": "残り時間",
    "Start Time": "開始時間",
    "End Time": "終了時間",
    "Conversion Amount": "交換数量",
    "Conversion Now": "今す交換",
    "Conditions": "参加条件",
    "Preheating": "予熱",
    "Records": "交換記録",
    "Select": "選択",
    "To": "から",
    "Active Currency": "活動通貨",
    "Amount-3": "金額",
    "Conversion Time": "交換時間",
    "Arrival Time": "到着時間",
    "Not Arrived": "未到着",
    "Distance Start": "距離開始",
    "Hours-1": "Hours",
    "Risk warning: It is displayed": "リスク警告：革新的な資産に対して表示され、デジタル資産の特性を備えています。 価格を慎重に判断し、投資の決定を慎重に選択してください！",
    "Week-1": "週間",
    "S-1": "Sec",
    "minutes-2": "M",
    "You must pass basic KYC": "基本なKYCに合格する必要",
    "You must pass advanced KYC": "高度なKYCに合格する必要",
    "Account order exceeds": "注文超過",
    "Suspend": "取引停止",
    "Successfully": "交換成功",
    "Ongoing-1": "交换中",
    "Failed": "交換失敗",
    "New_Token_Subscribe": "トークン購読",
    "More_awaits_you": "より多くの富",
    "Distance Start": "距離開始",
    "day-2": "日",
    "Must Eligible": "適格必要",
    "Custodial funds": "保管資金",
    "Default Ratio": "デフォ率",
    "Wallet Available": "利用可能",
    "Done-2": "完成",
    "Eligible Amount": "合格量",
    "Eligible Price": "合格額",
    "New Token Subscribe": "トークン購読",
    "Subscribe Now": "今す購読",
    "Review Time": "予選時間",
    "Review Passed": "監査成功",
    "Review Failed": "監査失敗",
    "Not Reviewed": "未審査",
    "Denial Reason": "拒否理由",
    "If not received, please contact service": "届かない場合は、カスタマーサービスまでご連絡ください",
    "Returned to wallet": "財布に戻",
    "From wallet assets": "から財布資産",
    "Day-3": "D",
    "Sec Swap asset is 0, please transfer from wallet account to Sec Swap first": "SecSwapアセットは0です。最初にウォレットアカウントからSecSwapに転送してください",
    "Settlement": "決済",
    "Completed": "決済完了",
    "Password Setting": "パスワード設定",
    "Set password and email": "パスワードとメールを設定",
    "Email Settings": "メール設定",
    "First-time registered users mus": "初回登録ユーザーは、ログインパスワードとメール設定を完了する必要があります",
    "Driver ID": "運転証",
    "Minimum": "最小",
    "Preview": "変換",
    "cancelTime": "キャンセル時間",
    "Enter wire transfer address (within 400 words)": "電信送金先住所を入力（400ワード以内）",
    "Setting Pix": "設定Pix",
    "Bank Name": "銀行名",
    "Company Name": "会社名",
    "Please enter bank name": "銀行名を入力",
    "Please enter company name": "会社名を入力",
    "Tips:": "ヒント:",
    "When you sell digital currency, your chosen payment method will be shown to the buyer. Please confirm that the information is filled in correctly.": "デジタル通貨を販売すると、選択したお支払い方法が購入者に表示されます。 情報が正しく入力されていることを確認してください。",
    "Modify Pix": "Pix変更",
    "Please bind Pix first": "Pixを最初にバインドする",
    "MentionMoneySend": "送信",
    "NewTokenSubscribe": "トークン購読",
    "MoreWealthAwaitsYou": "より多くの富",
    "TopGainers": "浮き沈み",
    "Newest": "最新",
    "Hot": "人気リスト",
    "DatChange": "浮き沈み",

    'Total_assets_valuation': '総資産評価',
    'Invite Friends': '友達招待',
    'RedGreen': '赤+ 緑-',
    'GreenRed': '緑+ 赤-',
    'Color Preference': '色の設定',
    'Fuzzy Information': 'ぼかし',
    'user_bind_xilain_trade_pwd': 'パスワードを入力',
    'login_please_input1': 'SMS認証コードを入力',
    'user_please_input9': 'Googleの認証コードを入力してください',
    'user_link_name': 'チェーン名',
    'user_coin_bring_together': '等価',
    "chongbi0": 'コインの使用に関する注意事項:',
    "chongbi1": '1、{0}以外のコインの変換を禁止しています。{1}以外のアセットは復元されません。 ',
    "chongbi2": '2、{0}クライアントまたはオンラインウォレットを介して、このアドレスにリチャージする必要がある{1}の番号を送信してください。送信が完了すると、システムは、このトランザクションの{2}確認を取得した後、サイト上のアカウントに仮想通貨を自動的に再請求します。しばらくお待ちください。 ',
    "chongbi3": '2、同じアドレスを複数回充電できますが、アカウントには影響しません。 ',
    "chongbi4": '3、最小充電量{0}。',
    "currency_withdrawal_rate_rules": '{0}出金レート規則:',
    "currency_withdrawal_rate_rules1": '1.ユーザーの資金を確保するために、プラットフォームは電話であなたのコイン操作の操作を確認することがあります、答えに注意を払ってください。',
    "currency_withdrawal_rate_rules2": '2.{0}リチャージの{1}回の確認後、引き出しが許可されます。',
    "currency_withdrawal_rate_rules3": '2.現金引き出し額≤使用可能資産 - 未確認のデジタル資産。',
    "currency_withdrawal_rate_rules4": '3.1日最大{0}回，1日あたりの最大硬貨{1}。',
    "currency_transaction_password": "パスワード",
    'long_press_to_save_to_album': '長押ししてアルバムに保存',
    'the_fee_is_outside_the_range': '料金が範囲外です',
    'start_and_end_date': '開始終了日付',
    'The_withdrawal_amount_cannot_be_less': '出金量は*より少なくできない{0}',
    'withdrawal_details': '出金詳細',
    "RegisterRemove0": '登録時に最初の番号から0を削除します',
    "closeLockUpPopup": 'キャンセル誓約は違反損失を{0}日間請求します。違反損失額は「{1}{2}」、実際に受け取った元本は「{3}{4}」です。',
    "SetPassword": "パスワード設定",
    "comm_password": "取引パスワード",
    "unraise_recovering_password": "パスワードを取り戻したら、1 hはお金を受け取れません。",
    "LockUpDuan": "LUM",
    "PDleaseUseSafariBrowser": "Safariブラウザをご利用ください",
    "ADddToDesktop": "デスクトップにBitMatrixを追加する",
    "CDlickBelow": "下記をクリック",
    "SDelectAddToHomeScreen": "ホーム画面に追加を選択",
    "ADddToDesktop2": "デスクトップにBitMatrixを追加する",
    "CDommonbrowserAddMethod": "一般的なブラウザの追加方法",
    'user_senior_text1': '注意',
    'user_senior_text2': '写真ファイルのサイズは5Mを超えることはできません！ファイル形式はjpg、bmp、pngなどである必要があります！',
    'user_senior_text3': '写真にウォーターマーク、汚れ、明確な身元情報、完全なアバター、テキスト以外の逆写真がないことを確認してください！ PSを写真処理に使用しないでください！',
    'user_senior_text4': '開催',
    'user_senior_text5': '証明写真',
    'user_senior_text6': '：保持する必要があります',
    'user_senior_text7': '日付',
    'user_senior_text8': 'そして',
    'user_senior_text9': 'UID',
    'user_senior_text10': 'ストリップは、ミラー化されていない写真として撮影されます。',
    'user_senior_text11': '、もう一方は、手書きで手書きのものを保持しています',
    'user_senior_text12': '手持白空白の紙を持って、次のことを書いてください。一致しない人は承認されません。纸请手写以下内容，内容不符者不予通过：',
    'user_senior_text13': '「投資リスクを知っており、自発的に引き受けた」',
    'user_senior_text14': '。',
    'user_ID_card': '身分証',
    "user_passport": "パスポート",
    'Driver_ID': '運転証',
    "user_senior_upload3": "ハンドヘルド証明写真+ UID +日付をアップロード",
    "ServiceUpgrade": 'サービスのアップグレード',
    "ReferencePrice": "参考価格",
    "referenceTips": "参照のみのための非最終取引単価",
    "NewCoinsLaunch": "新コイン発売",
    "CurrentlyPurchased": "現在購入",
    "TotalMarketValue": "時価総額",
    "IEORatio": "比率",
    "CurrencyIntroduction": "通貨の紹介",
    "ConvertNow": "今す交換",
    "RemainingAmount": "残りのクォータ",
    "referenceTips": "実際購入価格対象",
    "ServiceUpgrade": 'サービスのアップグレード',
    "LastDays7": "ほぼ7日",
    "LastDays30": "ほぼ30日",
    "LastDays90": "ほぼ90日",
    'hide_coin': '{0} BTC未満を隠す',
    "LockUpTips": "注: 残りの未監査の {0}{1} 資産は、ウォレット アカウントに返還されました。",
    "PromotionalMining": "促進鉱業",
    'GoldAssets': 'ゴールド資産',
    'ModifyEmail': 'メールの変更',
    'NewEmail': '新規メール',
    'NewEmailAddress': '新しいメールアドレス',
    'VerifyNewEmail': '新しいメールの確認',
    'EnterNewEmailCode': '新しいコードを入力',
    'VerifyOldEmail': '古いメールの確認',
    'EnterOldEmailCode': '古いコードを入力',
    'VerifyLoginPassword': 'ログインパスワード',
    'EnterLoginPassword': 'ログインパスワードを入力',
    "loan": "融資センター",
    "loanText": "既存の暗号通貨資産を売却することなく、より高い流動性でさまざまな目的に対応できる、安全で信頼性の高い通貨借入サービスを提供します。",
    "loanQuery": "ローンのご相談",
    'Stop_surplus_tips': "When the market price reaches {}, the profit stop Commission will be triggered, and the profit is expected to be {} after the transaction",
    'Stop_loss_tips': "The stop loss order will be triggered when the market price reaches {}, and the loss is expected to be {} after the transaction",
    'Stop_surplus_tips1': "When the market price reaches {}, the profit stop entrustment will be triggered, and the loss is expected to be {} after the transaction",
    'Stop_loss_tips1': "The stop loss order will be triggered when the market price reaches {}, and the profit is expected to be {} after the transaction",
    'Countdown': "秒読み",
    'contract_After_adjustment': '調整後、口座から新たな保証金として{}個のUSDTを振り込む',
    'contract_subject_to': '（実際の繰越金額に準ずる）',
    'contract_the_used_margin': '調整後、使用済み保証金はUSDTを{}個減らし、減少分は倉庫に残す,',
    'contract_transferred_to': '口座残高への転出可能',
    'contract_After_adjustment_q': '調整後、倉庫占用保証金は{}個のUSDT増加する',
    'contract_the_used_margin_q': '調整後、倉庫占用保証金は{}個のUSDTを削減する',
    'Please1': '収益停止トリガ価格を入力してください',
    'Please2': '収益停止依頼価格を入力してください',
    'Please3': 'ストップロストリガ価格を入力してください',
    'Please4': 'ストップロス委託価格を入力してください',
    'Stop profit6': '収益停止トリガ価格は最新の取引価格より大きい必要がある',
    'stop loss7': 'ストップロストリガ価格は最新の成約価格よりも小さい必要がある',
    'Please8': '数量を入力してください',
    'Please9': '平らな量がない',
    'CountdownTime': '秒読み',
    'ModifyEmail': 'メールの変更',
    'NewEmail': '新規メール',
    'NewEmailAddress': '新しいメールアドレス',
    'VerifyNewEmail': '新しいメールの確認',
    'EnterNewEmailCode': '新しいコードを入力',
    'VerifyOldEmail': '古いメールの確認',
    'EnterOldEmailCode': '古いコードを入力',
    'VerifyLoginPassword': 'ログインパスワード',
    'EnterLoginPassword': 'ログインパスワードを入力',
    h1: '資格を取得',
    h2: 'ランキング一覧',
    h3: 'パートナーになって取得可能',
    h4: 'アップグレード条件',
    h5: 'プライマリパートナーの購入には支払いが必要',
    h6: '現在の残高',
    h7: '創世パートナー',
    h8: 'に返金',
    h9: '今すぐアップグレード',
    h10: 'すでに最高レベル',
    h11: 'コミッションボーナス',
    h12: '差し戻しランキング',
    h13: 'チーム人数',
    h14: 'コールバック記録',
    h15: '招待された人',
    h16: '決済済',
    h17: '未決済',
    h18: '部下を持つ',
    h19: '支払いが必要',
    h20: 'アップグレード',
    h21: 'コミッション返却細則',
    h22: 'ランキング',
    h23: 'ユーザー',
    h24: '奉還奨励',
    h25: '現在のレベルで取得可能',
    h26: '取得手数料はすべてUSDTの累積値に換算されます。',
    h27: '通常のユーザ{}を直接プッシュする必要があります',
    h28: '有効な一般ユーザー{}が直接プッシュされました',
    h29: 'チームが必要な人数は{}人',
    h30: 'チーム人数{}人',
    "loan": "融資センター",
    "loanText": "既存の暗号通貨資産を売却することなく、より高い流動性でさまざまな目的に対応できる、安全で信頼性の高い通貨借入サービスを提供します。",
    "loanQuery": "ローンのご相談",
    'Stop profit10': '収益停止トリガ価格は最新の取引価格より小さい必要がある',
    'stop loss10': 'ストップロストリガ価格は最新の成約価格より大きい必要がある',
    Earn:"運用",
    Simple:"シンプル、しかも安全",
    Low1:"低リスクで安定したリターン",
    Low2:"低リスク、安定した収益、いつでも償還可能",
    CurrentAccount:"当座預金",
    Stable:"安定した付加価値、資産拡大の強力なツール",
    EarnLocked:"固定収益",
    Searchpopular:"人気の暗号資産を検索、運用で利益を獲得",
    Annualized:"年率{}％、安全資産、超高利回り",
    CoinsLC:"通貨",
    PurchaseLC:"購入金額",
    PurchaseLC2:"（最低購入額は{}）",
    Maximum:"最大",
    EstimatedInterest:"推定利息",
    RuleLC:"規則",
    SubscriptionTime:"購読時間",
    Interestaccrualtime:"利息発生期間",
    RevenueTime:"収益時間",
    RedemptionCycle:"償還サイクル",
    ReturnNow:"すぐに返却",
    BuyLC:"購入",
    PurchaseSuccessful:"購入成功",
    DepositLC:"デポジット",
    Yesterday:"昨日の稼ぎ",
    TotalLC:"入金総量",
    TotalReturn:"累計収入",
    DepositValuation:"見積",
    DepositDetail:"デポジット詳細",
    RevenueDetails:"収入詳細",
    DepositType:"入金種類",
    ReturnMethod:"利息還付方式",
    DepositAmount:"入金額",
    APR:"APR",
    Expected:"予想収益",
    DepositCycle:"入金周期",
    CreationTime:"作成時間",
    EarnProfit:"利益を得る",
    DepositCost:"預金費用",
    TotalRelease:"放出総額",
    ReleaseTime:"リリス時間",
    Flexible:"유연한",
    Fixed:"고정",
    Freeze:"氷結",
    Completed3:"完成",
    Cancelled:"既取消",
    DefaultSettlement:"デフォルト決済",
    PurchaseAmount:"購入金額（制限なし）",
    RetrieveDeposited:'預金を取り戻す',
    PreviewConversion:'プレビュ一変換',
    ToEnsureThe:'お客様のアカウントの安全を確保し、より高い取引限度を満たすために、高度な本人確認が必要です。',
    ToVerification:'ボタンをクリックして、カスタマーサポートに連絡し、認証を行ってください。',
    ToContact:'すぐにカスタマーサポートに連絡して認証を行ってください。',
    "comm_nickname_rule1": "한글 7 자 또는 영문 15자를 초과 할 수 없음",
    "comm_nickname_rule2": "공백과 특수 문자를 포함 할 수 없습니다",
    "otc_upload_picture3":"アップロード写真はjpeg/jpg/png/bmpフォーマットのみです！",
    "CertificationDifficulties":'認証に問題がありますか？',
    "CertifiedTipsP2P":'上記の商人はすべてBitMatrixプラットフォームによって認証されていますので、安心して購入してください。',
    'OneClickBackhand':'ック反転',
    'EmptyReversal':'エンプティリバーサル',
    'MultipleReversalsEmpty':'ロングポジションをショートポジションに切り替える',
    'BackhandOpenLong':'バックハンド・オープンロング',
    'BackhandOpenEmpty':'ショートへ切り替え',
    'DontShowMeAgain':' もう表示しない',
    "TransferRequiresClosing":"現在保有している契約の未決済",
}