<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-13 17:28:43
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="ioan container">
    <img class="back" src="../../assets/img/userCenter/ic_back.png" alt="" srcset="" @click="onClickLeft">
    <div class="topBox">
      <p class="xuan">BitMatrix {{ $t("M.loan") }}</p>
    </div>
    <div class="contant">
      <div class="tit">{{ $t("M.loan") }}</div>
      <div class="text">{{ $t("M.loanText") }}</div>
      <div class="tit">{{ t1 }}</div>
      <div class="text " v-html="c1"></div>
      <div class="tit">{{ t2 }}</div>
      <div class="text " v-html="c2"></div>
    </div>
    <div class="btn" @click="onClickBottom">{{ $t("M.loanQuery") }}</div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      t1: "",
      t2: "",
      c1: "",
      c2: "",
    }
  },
  computed: {
    ...mapGetters([
      'user', 'lang', 'partnerId'
    ]),
  },
  mounted() {
    this.$api.queryBorrowCenter({ partnerId: this.partnerId, language: this.lang }).then((res) => {
      console.log(res);
      this.t1 = res.data.configName
      this.t2 = res.data.remark
      this.c1 = res.data.configValue
      this.c2 = res.data.image
    })


  },
  methods: {

    onClickLeft() {
      this.$router.back()
    },
    onClickBottom() {
      this.$router.push({ name: 'serve' })
    },

  },

}
</script>

<style lang="scss" scoped>
.ioan {
  width: 100%;
  position: relative;

  .back {
    position: fixed;
    top: 10px;
    left: 15px;
    width: 14px;
    z-index: 99;
  }

  .topBox {
    position: relative;
    background-image: url("../../assets/img/userCenter/icon.png");
    height: 170px;
    width: 100%;
    background-size: 100% 100%;

    .xuan {
      position: absolute;
      top: 63px;
      left: 24px;
      color: #fff;
      font-weight: 600;
      line-height: 23px;
      font-size: 20px;
    }
  }

  .contant {
    margin-top: -18px;
    border-radius: 18px;
    z-index: 99;
    position: relative;
    background-color: #fff;
    width: 100%;
    padding: 20px 15px;
    font-size: 16px;

    .text {
      margin-top: 10px;
      font-size: 12px;
      color: #888888;
      margin-bottom: 12px;
    }
  }

  .btn {
    position: fixed;
    bottom: 40px;
    height: 44px;
    background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
    border-radius: 22px 22px 22px 22px;
    color: #FFFFFF;
    font-size: 15px;
    line-height: 44px;
    width: 80%;
    left: 10%;
    text-align: center;
    z-index: 99;
  }
}
</style>