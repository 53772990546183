<template>
  <div v-if="user.realNameAuth == 'y'" class="setPassword container">
    <!-- <van-nav-bar :title="$t('M.identity_authentication')" left-text="" left-arrow @click-left="onClickLeft" /> -->
    <nav-bar :title="$t('M.identity_authentication')" class="nav_bar"></nav-bar>
    <div class="content">
      <div class="shenfenBox">
        <img alt="" class="touxiang" src="../../assets/img/userCenter/touiang.png">
        <div class="textBox">
          <p>{{ $t("M.been_authenticated") }}</p>
          <p style="line-height:15px;word-break:break-all">{{ showName }}</p>
          <p> {{ showId }}</p>
        </div>
      </div>

      <div v-if="user.advancedAuth == 'pass'" class="shenfenBox">
        <img alt="" class="touxiang" src="../../assets/img/userCenter/touiang.png">
        <div class="textBox">
          <p>{{ $t("M.senior_certification") }}</p>
          <p>{{ $t("M.user-success-advanced-auth") }}</p>
        </div>
      </div>
      <!-- 高级认证 - 未提交 -->
      <div v-if="user.advancedAuth == ''" class="shenfenBox shenfenBox1 " @click="goSenior">
        <img alt="" class="touxiang" src="../../assets/img/userCenter/add.png">
        <div class="textBox">
          <p>{{ $t("M.senior_certification") }}</p>
          <p style="color:#FF605D;">{{ $t("M.unverified") }} {{ $t("M.upload_identity_card") }}</p>
        </div>
      </div>

      <div v-if="user.advancedAuth == 'notPass'" class="shenfenBox shenfenBox1 " @click="goSenior">
        <img alt="" class="touxiang" src="../../assets/img/userCenter/add.png">
        <div class="textBox">
          <p>{{ $t("M.senior_certification") }}</p>
          <p style="color:#FF605D;">{{ $t("M.Review Failed") }}:{{ reason }}</p>
        </div>
      </div>

      <div v-if="user.advancedAuth == 'waitVeritfy'" class="shenfenBox shenfenBox1 ">
        <img alt="" class="touxiang" src="../../assets/img/userCenter/add.png">
        <div class="textBox">
          <p>{{ $t("M.senior_certification") }}</p>
          <p style="color:#FF605D;">{{ $t("M.under_review") }}</p>
        </div>
      </div>

    </div>

  </div>
  <real-name v-else @changeData="changeData"></real-name>

</template>

<script>
import {mapGetters} from 'vuex'
import RealName from './RealName.vue';
import navBar from '@/components/navBar';

export default {
  components: {
    RealName, navBar
  },
  data() {
    return {
      link: '',
      showId: '*** ***',
      showName: '',
      reason: ''
    }
  },
  computed: {
    ...mapGetters([
      'user', 'realNameAuth'
    ]),
  },
  mounted() {
    this.$store.dispatch('getUserData')
    this.changeData()
    this.$api.getVerityInformation().then((res) => {
      if (res.data.authInfo.status == "notPass") {
        this.reason = res.data.authInfo.reason
      }
    })
  },
  methods: {
    goSenior() {
      this.$router.replace({name: 'authentication'})
    },
    copyCode() {
    },
    onClickLeft() {
      this.$router.push({name: 'userCenter'})
    },

    changeData() {
      this.showId = this.user.cardNo.slice(0, 2) + '*** ***' + this.user.cardNo.substring(this.user.cardNo.length - 2)
      this.showName = this.user.realname;
      if (this.showName.length) {
        this.showName = this.showName.slice(0, 40);
      }
    }
  },
  watch: {
    realNameAuth(n) {
      this.changeData()
    }
  }
}
</script>

<style lang="scss" scoped>
.setPassword {
  font-size: 12px;
}

.content {
  height: 100vh;
  width: 100%;
  background: #f8f7fc;
  padding: 15px;

  .shenfenBox {
    width: 100%;
    border-radius: 5px;
    background: #00b897;
    height: 122px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 10px;
    padding-right: 10px;

    .touxiang {
      width: 58px;
      height: 58px;
      margin-left: 15px;
    }

    .textBox {
      margin-left: 28px;
    }
  }

  .shenfenBox1 {
    background: #fff;
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
</style>
