<template>
  <div class="container">
    <nav-bar :showRight="false" class="nav_bar">
      <span slot="right" class="right" @click="onClickRight">{{$t('M.register')}}</span>
    </nav-bar>

    <div class="login">
      <div class="top">
        <!-- <img class="bg_logo" src="@/assets/img/login/bg_top.png" alt=""> -->
        <img class="logo" src="@/assets/img/register/logo.png" alt="">
      </div>
      <div class="loginForm">
        <div class="tabs">
          <div :class="['tab-item' ,active == i? 'tab-item_active':'']" v-for="(item,i) in tabs" :key="i" @click="onClickTab(i)">
            {{item.text}}
          </div>
        </div>

        <van-field class="userName" v-model="loginForm.userName" center clearable maxlength="50" :placeholder="$t('M.enter_account')">
        </van-field>

        <van-field v-if="active==1" v-model="loginForm.checkCode" center :placeholder="$t('M.input_phone_code')">
          <van-button class="button" slot="button" @click="onClickSend" :disabled="disabledCode">{{valiBtn}}</van-button>
        </van-field>

        <van-field v-if="active==0" v-model="loginForm.password" :type="showPassword?'text':'password'" center :placeholder="$t('M.enter_password')">

          <div class="icon" slot="button">
            <img :src="showPassword?icon_eye:icon_eyevis" alt="" @click="showPassword= !showPassword">
          </div>

        </van-field>

        <div class="status_box">
          <div class="checkbox">
            <van-checkbox v-model="loginForm.rememberMe" icon-size="16px" checked-color="#00b897" shape="square">{{$t('M.remember_state')}}
            </van-checkbox>
          </div>
          <!-- 忘记密码 -->
          <router-link v-if="active==0" class="fpsd" to="forgotPassword">{{$t('M.login_forget_pwd')}}</router-link>
        </div>

        <div class="button_box">
          <van-button class="button" :loading="loading" :disabled="subLoading" @click="handleLogin">{{$t('M.Sign in')}}</van-button>
        </div>

      </div>

      <div class="footer">
      </div>

      <!-- 网易 -->
      <div ref="captcha"></div>

      <!-- 异常验证码 -->
      <popup-code :show="showCode" :params="params" @onClose="onClosedCode" @onSubmit="onSubmitCode" />

      <!-- 谷歌验证 -->
      <popup-password :show="show" :onlyEnableGoogle="show" @onClose="onClosed" @onSubmit="onSubmitPass" />
      <Verify ref="verify" :captcha-type="'blockPuzzle'" :img-size="{ width: '250px', height: '200px' }" @success="login" />


    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapGetters, mapMutations } from 'vuex'
import { setToken } from '@/utils/auth'
import { encrypt } from '@/utils/encrypt'
import navBar from '@/components/navBar';
import popupPassword from '@/components/popupPassword';
import popupCode from '@/components/popupPassword/popupCode';
import Verify from '@/components/verifition/Verify'

export default {
  name: 'login',
  components: {
    navBar, popupPassword, popupCode,Verify
  },
  computed: {
    ...mapGetters([
      'partnerId', 'clientType', 'partnerNo', 'lang', 'langWY'
    ]),
    ...mapMutations(['SET_TOKEN']),

    subLoading() {
      const { userName, checkCode, password } = this.loginForm
      if (userName && checkCode && this.active == 1) return false
      if (userName && password && this.active == 0) return false
      return true
    },
    validateEmail() {
      const { userName } = this.loginForm
      if (userName && userName.indexOf("@") == -1) return false
      return true
    }
  },
  inject: ['reload'],
  data() {
    return {
      active: 0,
      tabs: [
        {
          text: this.$t('M.Password'),
          value: 1,
        },
        {
          text: this.$t('M.Verification Code'),
          value: 0,
        },
      ],
      loginForm: {
        checkCode: '',
        userName: '',
        password: '',
        rememberMe: false,
        email: ''

      },
      loginFlag: 1,
      loading: false,
      valiBtn: this.$t('M.get_code'),
      disabledCode: false,
      showPassword: false,

      icon_eye: require('@/assets/img/login/icon_eye.png'),
      icon_eyevis: require('@/assets/img/login/icon_eyeInvisible.png'),

      instance: null, // 网易空间

      show: false,
      isEnableGoogle: false,
      tempToken: '',

      // 异常登录
      showCode: false,
      params: {
        isEnablePhone: false,
        isEnableMail: false
      }
    };
  },

  created() {
    this.getCookie()
  },
  mounted() { },

  methods: {
    login(params) {
      console.log("验证成功")
      this.sendSmsAndGeeTest();
    },
    onClickRight() {
      this.$router.push({ name: 'register' })
    },
    formatterNum(value) {
      return value.replace(/[^0-9]/g, '')
    },

    reSetForm() {
      this.loginForm = {
        userName: '',
        password: '',
        checkCode: '',
      }
    },
    getCookie() {
      const userName = Cookies.get("userName");
      // const password = Cookies.get("password");
      const rememberMe = Cookies.get('rememberMe')
      this.loginForm = {
        userName: userName === undefined ? this.loginForm.userName : userName,
        // password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },

    // 验证码倒数60秒
    tackBtn() {
      let time = 59
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.valiBtn = this.$t('M.get_code')
          this.disabledCode = false
        } else {
          this.disabledCode = true
          if (time < 10) {
            this.valiBtn = '0' + time + 's'
          } else {
            this.valiBtn = time + 's'
          }
          time--
        }
      }, 1000)

    },
    onClickSend() {
      if (this.loginForm.userName.indexOf('@') != '-1' && new RegExp(/\s+/g).test(this.loginForm.userName)) {
        this.$notify(this.$t("M.user-fail-reg-mail"));
        return;
      }
      if (!this.loginForm.userName.trim()) return this.$notify(this.$t('M.enter_account'))
      this.$api.sliderSwitch().then(data => {
        if (data.data.sliderSwitch) {
          /* 获取验证 */
          // this.initNECaptcha()
          this.$refs.verify.show()
        } else {
          this.sendSmsAndGeeTest()
        }
      })
    },

    onClickTab(i) {
      this.active = i
    },

    handleLogin() {
      const { userName, rememberMe } = this.loginForm
      this.loading = true
      if (rememberMe) {
        Cookies.set("userName", userName.trim(), { expires: 30 });
        // Cookies.set("password", encrypt(password), { expires: 30 });
        Cookies.set('rememberMe', rememberMe, { expires: 30 });
      } else {
        Cookies.remove("userName");
        // Cookies.remove("password");
        Cookies.remove('rememberMe');
      }

      if (this.active == 1) {
        // 验证码登录
        this.loginFlag = 1
        this.userLoginForStep2()

      }
      if (this.active == 0) {
        // 密码登录
        this.loginFlag = 2
        this.userLoginForStep1()

      }
    },

    // 密码登录1
    userLoginForStep1() {
      const params = {
        ...this.loginForm,
        partnerId: this.partnerId,
        clientType: this.clientType,
        partnerNo: this.partnerNo,
      }
      params.password = encrypt(params.password)
      params.userName = params.userName.trim()
      delete params.rememberMe
      this.$api.userLoginForStep1(params).then((response) => {
        this.loading = false
        if (!response.meta.success) return
        const { token, firstLogin, isEnableGoogle, isEnablePhone, isEnableMail, failNum, loginIpEquals, countryCode, phone, email } = response.data
        this.tempToken = token
        this.isEnableGoogle = isEnableGoogle
        if (firstLogin) {
          this.loginForStep2({ loginFlag: this.loginFlag, token: token, userName: params.userName })
          return
        }

        if (!loginIpEquals || failNum >= 3) {  // 异常验证
          const params = {
            countryCode,
            phone,
            email,
            isEnablePhone,
            isEnableMail: !isEnablePhone && isEnableMail,
            action: 'LOGIN_CODE'
          }
          this.params = params
          this.showCode = true
          return
        }

        if (isEnableGoogle) {   // 谷歌验证
          this.show = true
          return
        }

        this.loginForStep2({ loginFlag: this.loginFlag, token: token, userName: params.userName })
      })

    },

    // 验证码登录 
    userLoginForStep2() {
      const params = {
        ...this.loginForm,
        timestamp: new Date().getTime(),
        partnerId: this.partnerId,
        partnerNo: this.partnerNo
      }
      params.userName = params.userName.trim()
      delete params.rememberMe
      this.$api.userLoginForStep2(params).then((response) => {
        this.loading = false
        if (!response.meta.success) return
        const { token, firstLogin, isEnableGoogle } = response.data
        if (firstLogin) {
          // 首次登录
          this.loginForStep2({ loginFlag: this.loginFlag, token: token, userName: params.userName })
          return
        }
        if (isEnableGoogle) {
          // 谷歌验证
          this.show = true
          this.tempToken = token
          return
        }

        this.loginForStep2({ loginFlag: this.loginFlag, token: token, userName: params.userName })

      })

    },
    // 确认登录-获取token
    loginForStep2(params) {
      this.$api.loginForStep2(params).then((response) => {
        if (!response.meta.success) return
        this.onClosed()
        const { token, userInfo: { password, userName, id } } = response.data
        setToken(token)
        this.$store.commit('SET_TOKEN', token)
        this.$store.dispatch('getUserData')
        if (!password) return this.$router.push({ name: 'setPassword', query: { id: id, type: 1, userName: userName, } })
        this.$router.push('index')
      })

    },
    // 异常登录
    codeAuth(params) {
      this.$api.codeAuth(params).then((response) => {
        if (!response.meta.success) return
        if (this.isEnableGoogle) {   // 谷歌验证
          this.show = true
        } else {
          this.loginForStep2({ loginFlag: this.loginFlag, token: this.tempToken, userName: this.loginForm.userName, })
        }

      })
    },
    // 验证码
    sendSmsAndGeeTest() {
      const params = {
        action: 'LOGIN_CODE',
        partnerId: this.partnerId,
        clientType: this.clientType,
        partnerNo: '1000010001'
      }
      const { userName } = this.loginForm
      if (this.validateEmail) {
        params.email = userName.trim()
        this.$api.sendEmailAndGeeTest(params).then((response) => {
          if (!response.meta.success) return
          this.$notify(response.meta.message)
          this.tackBtn()
        })

      } else {
        params.phone = userName.trim()
        this.$api.sendSmsAndGeeTest(params).then((response) => {
          if (!response.meta.success) return
          this.$notify(response.meta.message)
          this.tackBtn()
        })
      }


    },

    /* 初始化网易易盾 */
    initNECaptcha() {

      /* 获取验证码的ID */
      this.$api.verify({
        "timestamp": new Date().getTime(),
        "partnerNo": 1000010001,
        "type": 'wyTouch',
        "partnerId": this.partnerId,
      }).then((data) => {
        let verify = data.data.verify;
        let that = this;
        if (!verify) {
          this.initNECaptcha();
        }

        let sendLang = this.langWY[this.lang];

        let options = {
          element: that.$refs.captcha,
          captchaId: verify,
          mode: "popup",
          lang: sendLang,
          feedbackEnable: false,
          onVerify: function (err, data) {
            if (!err) {

              that.sendSmsAndGeeTest();
            }
          },
        };

        initNECaptcha(
          options,
          function onload(instance) {
            that.instance = instance;
            that.instance.popUp()
          },
          function onerror(err) {
            console.log(err);
          }
        );
      });
    },

    // 确认谷歌验证码的回调
    onSubmitPass(googleCode) {
      this.loginForStep2({ loginFlag: this.loginFlag, token: this.tempToken, userName: this.loginForm.userName, googleCode: googleCode })
    },

    onClosed() {
      this.loading = false
      this.show = false
    },

    // 确认异常验证码的回调
    onSubmitCode({ msgCode, emailCode }) {
      console.log(msgCode, emailCode);
      this.codeAuth({ userName: this.loginForm.userName, code: msgCode || emailCode, token: this.tempToken, })
      this.onClosedCode()
    },
    onClosedCode() {
      this.showCode = false
    },

  },

  beforeRouteLeave(to, form, next) {
    // 进入子页面缓存login 退出重载login
    if (to.name !== 'forgotPassword') {
      this.reload();
    }
    next()
  }
};
</script>

<style lang="scss" scoped>
.nav_bar {
  height: 0;
  .right {
    display: block;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #131520;
  }
}
.login {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top {
    height: 200px;
    position: relative;
    background: url('~@/assets/img/login/bg_top.png') no-repeat center;
    background-size: cover;
    .bg_logo {
      width: 100%;
      height: 100%;
    }
    .logo {
      position: absolute;
      top: 115px;
      left: 50%;
      transform: translateX(-50%);
      width: 67px;
    }
  }
  .loginForm {
    flex: 1;
    width: 290px;
    margin: 0 auto;

    .van-field {
      height: 44px;
      background: #f8f8fb;
      border-radius: 3px;
      margin-bottom: 22px;
      padding-right: 0;
      .button {
        background: transparent;
        font-size: 12px;
        color: #00b897;
      }

      .icon {
        height: 100%;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .userName {
      padding-right: 16px;
    }
    .tabs {
      height: 40px;
      display: flex;
      align-items: center;
      margin: 20px 0;
      .tab-item {
        margin-right: 5px;
        white-space: nowrap;
        // overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        // font-weight: 600;
        color: #9299a9;
      }
      .tab-item_active {
        font-size: 22px;
        color: #00b897;
      }
    }
    .status_box {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #131520;
      .checkbox {
        height: 100%;
        display: flex;
        align-items: center;
        .van-checkbox {
          height: 100%;
          margin-right: 8px;
          font-size: 12px;
        }
      }
      .fpsd {
        color: #00b897;
      }
    }
  }

  .footer {
    width: 100%;
    height: 150px;
    // background: url('~@/assets/img/login/bg_bottom.png') no-repeat center;
    // background-size: cover;
  }
}
</style>

<style scoped lang="scss">
::v-deep {
  .van-nav-bar {
    background: transparent;
    .van-nav-bar__arrow {
      font-size: 24px;
    }
  }
}
</style>