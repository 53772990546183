/*
 * @Author:  
 * @Date: 2022-05-11 14:36:04
 * @LastEditors: LAPTOP-7587T4F6\Bohan  
 * @LastEditTime: 2022-08-28 23:58:58
 * @FilePath: \cxexh5\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { Notify } from 'vant';
import Index from "@/views/Index";
/* 登录 */
import Login from "@/views/login/index";
import Agreement from "@/views/login/Agreement";
import Register from "@/views/login/register";
import ForgotPassword from "@/views/login/forgotPassword";
import ResetPassword from "@/views/login/resetPassword";

import Home from "@/views/home";
import Guide from "@/views/home/Guide";
import News from "@/views/home/News";
import NewsContent from "@/views/home/NewsContent";


/* 秒合约 */
import ContracTradeLine from "@/views/contractTrade/TradeLine";
/* 币币交易 */
import TradeCenter from "@/views/TradeCenter";
import TradeLine from "@/views/TradeCenter/TradeLine";
import TradeOrder from "@/views/TradeCenter/TradeOrder";
import TradeDetail from "@/views/TradeCenter/TradeDetail";
import TradeHurryDetail from "@/views/TradeCenter/TradeHurryDetail";
import IframePage from "@/components/IframePage";
/* 资产相关 */
import Assets from "@/views/assets/assets";
import AssetRecords from "@/views/assets/assetRecords";
import Recharging from "@/views/assets/recharging";
import Transfer from "@/views/assets/transfer";
import TransferRecord from "@/views/assets/transferRecord";
import WithdrawalRecords from "@/views/assets/withdrawalRecords";
import ChargingAddress from "@/views/assets/chargingAddress";
import Withdrawal from "@/views/assets/withdrawal";
import Detail from "@/views/assets/detail";
import AddressList from "@/views/assets/addressList";
import AdressAdd from "@/views/assets/adressAdd";
import WalletFlow from "@/views/assets/walletFlow";
import SecondContract from "@/views/assets/secondContract";
import SecondContractFlow from "@/views/assets/secondContractFlow";
import FlashCash from "@/views/assets/flashCash";
import FlashCashRecords from "@/views/assets/flashCashRecords";
import PerpetualAssets from "@/views/assets/PerpetualAssets";
import PerpetualAssetsFlow from "@/views/assets/PerpetualAssetsFlow";


/* 个人中心-相关页面 */
import Invitation from "@/views/mine/Invitation";
import UserCenter from "@/views/mine/UserCenter";
import Authentication from "@/views/mine/Authentication";
import SecurityCenter from "@/views/mine/SecurityCenter";
import PhoneOption from "@/views/mine/PhoneOption";
import SetPassword from "@/views/mine/SetPassword";
import SetPhone from "@/views/mine/SetPhone";
import BindEmail from "@/views/mine/BindEmail";
import ChangeEmail from "@/views/mine/ChangeEmail";
import RealName from "@/views/mine/RealName";
import Identity from "@/views/mine/Identity";
import Settings from "@/views/mine/Settings";
import SetExchangePwd from "@/views/mine/SetExchangePwd";
import NoPsdSetting from "@/views/mine/noPsdSetting";
import AuthenticationVideo from "@/views/mine/AuthenticationVideo";
import Serve from "@/views/mine/Serve";
import PhoneContent from "@/views/mine/securityCom/PhoneContent";
import EmailContent from "@/views/mine/securityCom/EmailContent";
import ChangePhone from "@/views/mine/securityCom/ChangePhone";
import ChangePhoneNumber from "@/views/mine/securityCom/ChangePhoneNumber";
import Ioan from "@/views/mine/Ioan";
/* IEO申购 */
import IEO from "@/views/IEO";
import Details from "@/views/IEO/Details";
import IEOlog from "@/views/IEO/IEOlog";
import NewToken from "@/views/IEO/NewToken";
/* 锁仓挖矿 */
import LockUp from "@/views/LockUp";
import LockUpLog from "@/views/LockUp/LockUpLog";
import LockUpAd from "@/views/LockUp/LockUpAd";
import Rules from "@/views/LockUp/rules";
import P2P from "@/views/LockUp/P2P";
/* 币币资产 */
import BBRouter from './bbAssets';
/* 合约 */
import Contract from "@/views/contract";


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: ForgotPassword
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: ResetPassword
  },

  {
    path: '/agreement',
    name: 'agreement',
    component: Agreement
  },
  {
    path: '*',
    name: 'index',
    redirect: '/home',
    component: Index,
    children: [
      { // 首页
        path: '/home',
        name: 'home',
        component: Home,
      },
      { // 秒合约
        path: '/contract',
        name: 'contract',
        component: Contract,
      },
      { // 交易
        path: '/TradeCenter',
        name: 'TradeCenter',
        component: TradeCenter,
      },

      { // 资产
        path: '/assets',
        name: 'assets',
        component: Assets,
      },
    ]
  },
  { // 安卓引导页面
    path: '/guide',
    name: 'guide',
    component: Guide,
  },
  { // 币币交易
    path: '/TradeCenter',
    name: 'TradeCenter',
    component: TradeCenter,
  },
  { // 币币交易K线
    path: '/TradeLine',
    name: 'TradeLine',
    component: TradeLine,
  },
  { // 币币交易订单
    path: '/TradeOrder',
    name: 'TradeOrder',
    component: TradeOrder,
  },
  { // 详情
    path: '/TradeDetail',
    name: 'TradeDetail',
    component: TradeDetail,
  },
  { // 快速详情
    path: '/TradeHurryDetail',
    name: 'TradeHurryDetail',
    component: TradeHurryDetail,
  },
  { // 快速详情
    path: '/IframePage',
    name: 'IframePage',
    component: IframePage,
  },
  { // 新闻列表
    path: '/news',
    name: 'news',
    component: News,
  },
  { // 新闻列表 详情
    path: '/newsContent',
    name: 'newsContent',
    component: NewsContent,
  },
  { // 钱包资产
    path: '/assetRecords',
    name: 'assetRecords',
    component: AssetRecords,
  },
  { // 钱包资金流水
    path: '/walletFlow',
    name: 'walletFlow',
    component: WalletFlow
  },

  { // 秒合约资产
    path: '/secondContract',
    name: 'secondContract',
    component: SecondContract,
  },
  { //秒合约资金流水
    path: '/secondContractFlow',
    name: 'secondContractFlow',
    component: SecondContractFlow
  },
  { // 永续资产
    path: '/PerpetualAssets',
    name: 'PerpetualAssets',
    component: PerpetualAssets,
  },
  { // 永续资产
    path: '/PerpetualAssetsFlow',
    name: 'PerpetualAssetsFlow',
    component: PerpetualAssetsFlow,
  },
  { //充币
    path: '/recharging',
    name: 'recharging',
    component: Recharging,
  },
  { // 划转
    path: '/transfer',
    name: 'transfer',
    component: Transfer,
  },
  { // 划转记录
    path: '/transferRecord',
    name: 'transferRecord',
    component: TransferRecord,
  },
  { // 提币/充币记录
    path: '/withdrawalRecords',
    name: 'withdrawalRecords',
    component: WithdrawalRecords,
  },

  { // 充币
    path: '/chargingAddress',
    name: 'chargingAddress',
    component: ChargingAddress
  },
  { // 提币
    path: '/withdrawal',
    name: 'withdrawal',
    component: Withdrawal,
    meta: {
      keepAlive: true
    }
  },
  { // 详情
    path: '/detail',
    name: 'detail',
    component: Detail
  },
  { // 地址管理 
    path: '/addressList',
    name: 'addressList',
    component: AddressList
  },
  { // 新增地址
    path: '/adressAdd',
    name: 'adressAdd',
    component: AdressAdd
  },
  { // 闪兑
    path: '/flashCash',
    name: 'flashCash',
    component: FlashCash
  },
  { // 闪兑记录
    path: '/flashCashRecords',
    name: 'flashCashRecords',
    component: FlashCashRecords
  },





  { // 个人中心
    path: '/userCenter',
    name: 'userCenter',
    component: UserCenter,
  },
  { // 邀请好友
    path: '/invitation',
    name: 'invitation',
    component: Invitation,
  },
  { // 实名认证
    path: '/authentication',
    name: 'authentication',
    component: Authentication,
  },
  { // 安全中心
    path: '/securityCenter',
    name: 'securityCenter',
    component: SecurityCenter,
  },
  { // 手機驗證
    path: '/phoneOption',
    name: 'phoneOption',
    component: PhoneOption,
  },
  { // 手機驗證
    path: '/setPassword',
    name: 'setPassword',
    component: SetPassword,
  },
  { // 绑定手机
    path: '/setPhone',
    name: 'setPhone',
    component: SetPhone,
  },
  { // 绑定邮箱
    path: '/bindEmail',
    name: 'bindEmail',
    component: BindEmail,
  },
  { // 修改邮箱
    path: '/changeEmail',
    name: 'changeEmail',
    component: ChangeEmail,
  },
  { // 实名
    path: '/realName',
    name: 'realName',
    component: RealName,
  },
  { // 身份认证
    path: '/identity',
    name: 'identity',
    component: Identity,
  },
  { // 设置
    path: '/settings',
    name: 'settings',
    component: Settings,
  },
  { // 设置交易密码
    path: '/setExchangePwd',
    name: 'setExchangePwd',
    component: SetExchangePwd,
  },
  { // 免密设置
    path: '/noPsdSetting',
    name: 'noPsdSetting',
    component: NoPsdSetting,
  },

  { // 上传视频
    path: '/authenticationVideo',
    name: 'authenticationVideo',
    component: AuthenticationVideo,
  },
  { // 客服
    path: '/serve',
    name: 'serve',
    component: Serve,
  },
  { // 手机绑定信息
    path: '/phoneContent',
    name: 'phoneContent',
    component: PhoneContent,
  },
  { // 邮箱绑定信息
    path: '/emailContent',
    name: 'emailContent',
    component: EmailContent,
  },
  { // 改变手机号码
    path: '/changePhone',
    name: 'changePhone',
    component: ChangePhone,
  },
  { // 改变手机号码-最后一步
    path: '/changePhoneNumber',
    name: 'changePhoneNumber',
    component: ChangePhoneNumber,
  },


  { // IEO申购 
    path: '/IEO',
    name: 'IEO',
    component: IEO,
  },
  /* IEO申购详情 */
  {
    path: '/IEOdetails',
    name: 'IEOdetails',
    component: Details,
  },
  /* IEO申购详情 */
  {
    path: '/IEOlog',
    name: 'IEOlog',
    component: IEOlog,
  },
  /* IEO申购活动  落地页 */
  {
    path: '/newToken',
    name: 'newToken',
    component: NewToken,
  },
  /* 锁仓挖矿 */
  {
    path: '/LockUp',
    name: 'LockUp',
    component: LockUp,
  },
  /* 锁仓挖矿- 历史记录 */
  {
    path: '/lockUpLog',
    name: 'lockUpLog',
    component: LockUpLog
  },
  /* 锁仓挖矿- 历史记录 */
  {
    path: '/lockUpAd',
    name: 'lockUpAd',
    component: LockUpAd
  },
  { // 锁仓挖矿规则说明
    path: '/rules',
    name: 'rules',
    component: Rules,
  },
  { // P2P
    path: '/P2P',
    name: 'P2P',
    component: P2P,
  },
  { // 合约 走势图
    path: '/ioan',
    name: 'ioan',
    component: Ioan,
  },
  { // 合约 走势图
    path: '/contracTradeLine',
    name: 'contracTradeLine',
    component: ContracTradeLine,
  },


].concat(BBRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const { user: { isLogin } } = store.state
  if (isLogin) {
    // 存在currentUser
    if (to.name === 'login') {
      // 访问login页面,跳转 index 页面
      next({ path: "/index" });
    } else {
      next();
    }
  } else {
    // 不存在currentUser
    const whiteList = ['login', 'register', 'forgotPassword', 'setPassword', 'agreement', 'resetPassword',
      'index', 'home', 'newOption', 'userCenter', 'settings', 'serve', 'IEO', "IEOdetails",
      'details', "LockUp", "rules", "guide", "news", "newsContent", "newToken", "TradeCenter", "TradeLine", 'lockUpAd',
      "contract",'contracTradeLine'
    ]
    if (whiteList.indexOf(to.name) > -1) {
      // 访问白名单页面，则进行默认行为
      next();
    } else {
      // 访问其他页面,则跳转到login页面
      next({ path: "/login" });
    }
  }
});

router.afterEach(() => {
  setTimeout(() => {
    Notify.clear();
  }, 2000);
});

export default router
