/*
 * @Author:  
 * @Date: 2022-06-25 18:01:35
 * @LastEditors:  
 * @LastEditTime: 2022-07-19 10:44:42
 * @FilePath: \exchange-h5\src\store\getters.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const getters = {
    currencySymbol: state => state.app.currencySymbol,
    partnerId: state => state.app.partnerId,
    partnerNo: state => state.app.partnerNo,
    whiteList: state => state.app.whiteList,
    clientType: state => state.app.clientType,
    langWY: state => state.app.langWY,
    information: state => state.app.information,


    token: state => state.user.token,
    rate: state => state.user.rate,
    coinList: state => state.user.coinList,
    selectCoin: state => state.user.selectCoin,
    walletData: state => state.user.walletData,
    newOptionData: state => state.user.newOptionData,
    newFinance: state => state.user.newFinance,
    user: state => state.user.user,
    otherUser: state => state.user.otherUser,
    isLogin: state => state.user.isLogin,
    walletFlowCoin: state => state.user.walletFlowCoin,
    userWithdrawAddress: state => state.user.userWithdrawAddress,
    selectAddress: state => state.user.selectAddress,
    password: state => state.user.user.password,
    realNameAuth: state => state.user.user.realNameAuth,
    lang: state => state.user.lang,
    userPayPassConfig: state => state.user.userPayPassConfig,
}
export default getters