<template>
  <div class="ADPage container">
    <van-nav-bar :title="$t('M.PromotionalMining')" left-text="" left-arrow @click-left="onClickLeft" />
    <div class="contant" v-html="configValue"></div>
    <div class="contant contant1" v-html="configValue1"></div>
    <div class="contant contant1" v-html="configValue2"></div>
    <router-link to="LockUp?flag=true">
      <div class="get">{{ $t('M.immediately_join') }}</div>
    </router-link>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { mapGetters, } from 'vuex'
export default {
  data() {
    return {
      chart: null,
      configValue: "",
      configValue1: "",
      configValue2: "",
    }
  },
  created() {
    window.ocSetDeviceCode = this.ocSetDeviceCode;
  },
  computed: {
    ...mapGetters([
      'lang', 'partnerId'
    ])
  },
  mounted() {
    this.$api.queryMiningActivity({ partnerId: this.partnerId, language: "en_US" }).then((res) => {
      this.configValue = res.data[0].configValue
      this.configValue1 = res.data[1].configValue
      this.configValue2 = res.data[2].configValue
    })
  },
  methods: {
    ocSetDeviceCode(data) {

    },
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style  lang="scss">
.ADPage {
  font-size: 12px;
  background-color: #fff;
  height: 98vh;
  overflow-y: scroll;
  padding-bottom: 100px;
}

.contant {
  width: 90%;
  margin: 0 auto;

  table {
    border-collapse: collapse;
  }

  tr,
  td,
  th {
    /*设置边框线  border：边框线大小 边框线样式 边框线的颜色 */
    border: 1px solid #191b27;
  }
}


.contant1 {
  background-color: #F8F8F8;
  border-radius: 6px;
  margin-top: 20px;
  padding: 10px;
}

.get {
  margin: 0 auto;
  text-align: center;
  height: 30px;
  background-color: #00b897;
  width: 80%;
  border-radius: 20px;
  line-height: 30px;
  color: #fff;
  margin-top: 10px;
  position: fixed;
  bottom: 30px;
  left: 10%;
}

::v-deep .van-nav-bar__right .van-nav-bar__text {
  color: #5c6573;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
</style>