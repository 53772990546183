<template>
  <div class="assets container">

    <div class="assets_top">

      <div class="assets_total">
        <div class="row flexC">
          <span>{{$t("M.Total_assets_valuation")}} {{currencySymbol}} </span>
          <img class="icon" v-show="show" src="@/assets/img/assets/icon_eyeoon.png" alt="" @click="onClickShow">
          <img class="icon icon_eyeoff" v-show="!show" src="@/assets/img/assets/icon_eyeoff.png" alt="" @click="onClickShow">
        </div>
        <div class="total">{{show? (accountCNY.totalCNY / rate) ? cutOutPointLength(accountCNY.totalCNY / rate, 2) : '0':'******'}}</div>

      </div>

      <div class="assets_distributed">
        <div class="assets_distributed_title row">
          <span>{{$t("M.asset_allocatio")}}</span>
          <img class="icon" src="@/assets/img/assets/icon_echarts.png" alt="">
        </div>

        <div id="chart" class="chart"></div>
        <div class="legend">
          <div class="legend_item" v-for="(item,i) in accountList" :key="i">
            <div>
              <i :style="colorList[i]"></i>
              <span>{{item.name}}</span>
            </div>
            <span>{{item.totalRate}}%</span>
          </div>

        </div>
      </div>
    </div>

    <div class="assets_list">

      <van-cell :is-link="item.disable? false : true" v-for="(item,i) in accountList" :key="i" :to="{path: item.path, query: { title: item.name }}">
        <div class="assets_list_title" slot="title">
          {{item.name}}
          <div>≈{{item.totalRate}}%</div>
        </div>
        <span>
          {{show?item.value + ' ' + (currencySymbol) :'******'}}
        </span>
      </van-cell>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
// import { getUserContractFinanceList } from '@/request/assets'
import {mapGetters} from 'vuex'

export default {
  name: 'assets',
  computed: {
    ...mapGetters([
      'currencySymbol'
    ]),
  },
  data() {
    return {
      activeName: '0',
      show: true,

      rate: 1,
      accountCNY: {},
      accountList: [
        { // 钱包资产
          id: 'walletTotalCNY',
          name: this.$t('M.assets_wallet_assets'),
          value: 0,
          totalRate: 0,
          path: 'assetRecords'
        },
        // { // 秒合约资产
        //   id: 'optionNewTotalCNY',
        //   name: this.$t('M.Sec Swap Assets'),
        //   value: 0,
        //   totalRate: 0,
        //   path: 'secondContract'
        // },
        {// 币币资产
          id: '',
          name: this.$t('M.Spot Value1'),
          value: 0,
          totalRate: 0,
          path: 'bbAssets',
          disable: false
        },
        { // 永续资产
          id: '',
          name: this.$t('M.Sustainable assets'),
          value: 0,
          totalRate: 0,
          path: 'PerpetualAssets',
          disable: false
        },
        // { // 期权资产
        //   id: '',
        //   name: this.$t('M.Option Assets'),
        //   value: 0,
        //   totalRate: 0,
        //   path: '',
        //   disable: true
        // },
        // { // otc资产
        //   id: '',
        //   name: this.$t('M.assets_fiat_assets'),
        //   value: 0,
        //   totalRate: 0,
        //   path: '',
        //   disable: true
        // }
      ],
      colorList: [
        'background:#FFAB54',
        'background:#FD788A',
        'background:#68E998',
        'background:#FBF277',
        'background:#77B3FB',
      ]
    };
  },
  beforeMount() {
    this.exchangeRate()
    this.$store.dispatch('getUserWalletAssets')

  },
  mounted() {
    this.graphInit()
  },

  methods: {
    onClickShow() {
      this.show = !this.show
    },

    // 汇率
    exchangeRate() {
      this.$api.exchangeRate({ currency: 'USD' }).then((response) => {
        this.rate = response.data
        this.$store.dispatch('setRate', response.data)
        this.queryAccountCNY()
      })
    },
    //查询用户总资产
    queryAccountCNY() {
      this.$api.queryAccountCNY().then((response) => {
        if (!response.meta.success) return

        this.accountCNY = response.data
        this.accountList[0].value = this.cutOutPointLength(response.data.walletTotalCNY / this.rate, 2)
        this.accountList[0].totalRate = this.cutOutPointLength(response.data.walletTotalCNY / response.data.totalCNY * 100, 2)
        // this.accountList[1].value = this.cutOutPointLength(response.data.optionNewTotalCNY / this.rate, 2)
        // this.accountList[1].totalRate = this.cutOutPointLength(response.data.optionNewTotalCNY / response.data.totalCNY * 100, 2)
        this.accountList[1].value = this.cutOutPointLength(response.data.bbTotalCNY / this.rate, 2)
        this.accountList[1].totalRate = this.cutOutPointLength(response.data.bbTotalCNY / response.data.totalCNY * 100, 2)
        this.accountList[2].value = this.cutOutPointLength(response.data.contractTotalCNY / this.rate, 2)
        this.accountList[2].totalRate = this.cutOutPointLength(response.data.contractTotalCNY / response.data.totalCNY * 100, 2)
        // this.accountList[4].value = this.cutOutPointLength(response.data.optionTotalCNY / this.rate, 2)
        // this.accountList[4].totalRate = this.cutOutPointLength(response.data.optionTotalCNY / response.data.totalCNY * 100, 2)
        // this.accountList[5].value = this.cutOutPointLength(response.data.otcTotalCNY / this.rate, 2)
        // this.accountList[5].totalRate = this.cutOutPointLength(response.data.otcTotalCNY / response.data.totalCNY * 100, 2)
        this.graphInit()
      })
    },


    // 订单统计图表
    graphInit() {
      const dataArry = this.accountList
      const colorList = ['#FFAB54', '#FD788A', '#68E998', '#FBF277', '#77B3FB',];

      const option = {
        // tooltip: {
        //   trigger: 'item'
        // },
        // legend: {
        //   orient: 'vertical',
        //   type: 'plain',
        //   icon: 'circle',
        //   itemWidth: 6,
        //   itemGap: 14,
        //   top: '8%',
        //   left: '45%',
        //   textStyle: {
        //     color: '#5C6573',
        //     fontSize: 13,
        //   },
        //   formatter: (e) => {
        //     const res = dataArry.find(item => item.name == e)
        //     if (e == '秒合约资产') return `${e}                  ${res.totalRate ? res.totalRate + '%' : '0.00%'}`;
        //     return `${e}                     ${res.totalRate ? res.totalRate + '%' : '0.00%'}`;
        //   },
        // },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            center: ['22%', '50%'],
            radius: ['50%', '75%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            itemStyle: {
              color: (params) => {
                if (params.data.value > 0) return colorList[params.dataIndex];
                return '#9299A9';
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },

            data: dataArry
          }
        ]
      };

      this.$nextTick(() => {
        const chartDom = document.getElementById('chart')
        if (chartDom) {
          const myChart = echarts.init(chartDom)
          myChart.setOption(option)
          window.addEventListener('resize', function () {
            myChart.resize()
          })
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.assets {
  font-size: 16px;

  .assets_top {
    position: relative;
    height: 230px;
    // background: url('~@/assets/img/assets/bg.png') no-repeat center;
    // background-size: cover;
    background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
    .asset_records {
      width: 100%;
      text-align: right;
      height: 30px;
      line-height: 30px;
      padding-right: 15px;
    }
    .assets_total {
      width: 100%;
      font-weight: 400;
      color: #fff;
      line-height: 23px;
      padding: 60px 0 0 15px;
      word-wrap: break-word;
      .icon {
        width: 20px;
        height: 13px;
        margin-right: 40px;
        margin-left: 10px;
      }
      .icon_eyeoff {
        width: 18px;
        height: 8px;
      }
      .total {
        font-size: 36px;
        font-weight: bold;
        color: #fff;
        line-height: 48px;

        font-family: DINCondensed-Bold, DINCondensed;
      }
    }
  }

  .assets_distributed {
    position: absolute;
    top: 145px;
    left: 50%;
    transform: translateX(-50%);
    width: 345px;
    height: 196px;
    background: #ffffff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    .assets_distributed_title {
      height: 45px;
      line-height: 45px;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
    }
    .chart {
      height: 146px;
      width: 345px;
    }
    .legend {
      position: absolute;
      right: 0;
      bottom: 8%;
      width: 60%;
      min-height: 60%;
      padding: 0 10px 0 5px;
      .legend_item {
        height: 20px;
        margin-bottom: 5px;
        color: #5c6573;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        i {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #ccc;
          margin-right: 6px;
        }
      }
    }
  }

  .assets_list {
    margin-top: 140px;
    .assets_list_title {
      flex: 1;
      font-size: 15.5px;
      font-weight: 400;
      color: #191b27;

      > div {
        font-size: 12px;
        color: #9299a9;
        padding: 2px 0;
      }
    }

    .van-cell__value,
    .van-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      height: auto;
    }
    .van-icon {
      font-size: 0.36rem;
      color: #9299a9;
    }
    .van-cell__value {
      flex: 2;
      font-size: 0.34rem;
      color: #191b27;
      margin-right: 5px;
      justify-content: flex-end;
    }
  }
  .row {
    display: flex;
  }
  .flexC {
    align-items: center;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
}
::v-deep .van-cell__title {
  flex: 2;
}
</style>
