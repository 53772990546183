<template>
<div>
      <van-nav-bar :title="$t('M.Order Details')" left-arrow @click-left="$emit('dreg')">
        <template #left>
          <van-icon name="arrow-left" size="1.2em" />
        </template>
      </van-nav-bar>
      <div style="display: flex;border-bottom: 1px solid #f8f8f8;">
        <div class="box" style="flex: 4;">
          <van-row class="row"  gutter="5">
            <van-col span="24"><span class="Sub title">{{itemData.tradeName}}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col  span="24">
              <van-tag v-if="itemData.tradeMode ==='CLOSE_UP' || itemData.tradeMode ==='OPEN_UP'" color="#e7f7f2" text-color="#00B897">{{filtersData(itemData.tradeMode)}}</van-tag>
              <van-tag v-else color="#ffefef" text-color="#e9697e">{{filtersData(itemData.tradeMode)}}</van-tag>
              <van-tag color="#f8f8f8" text-color="#5C6573">{{filtersData(itemData.positionsType)}}</van-tag>
              <span class="Sub">{{itemData.lever}}X</span>
            </van-col>
          </van-row>
        </div>
        <div @click="$emit('dreg')" style="flex: 1;display: flex;justify-content: center;align-items: center;color: #F10E38;">
          {{$t('M.Trade-1')}}<van-icon  name="arrow" />
        </div>
      </div>
      <div v-if="keytype!='TP_SL'" class="box" style="border-bottom: 1px solid #f8f8f8;">
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.StatusOrder')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{filtersData(itemData.status)}}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.Order type')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{filtersData(itemData.entrustType)}}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.Order amt')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.count}}{{ itemData.sellCoinName }}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.Order price')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.price}}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.deal_done')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.completeCount}}{{ itemData.sellCoinName }}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.deal_price')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.completePrice}}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.Order Value')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.amount}}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.Filed Value')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.completePrice}}</span></van-col>
          </van-row>
        </div>
        <div v-else class="box" style="border-bottom: 1px solid #f8f8f8;">
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.StatusOrder')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{filtersData(itemData.status)}}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.Order type')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{filtersData(itemData.entrustType)}}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.One/Two-way')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{filtersData(itemData.entrustMode)}}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.Order amt')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.count}}{{ itemData.sellCoinName }}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.TP trigger price')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.tpSavePrice}}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.TP order price')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.tpSavePrice!=0?itemData.tpSavePrice:'市价'}}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.SL trigger price')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.slTriggerPrice}}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.SL order price')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.slSavePrice!=0?itemData.slSavePrice:'市价'}}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.Order Value')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.amount}}</span></van-col>
          </van-row>
        </div>
        <div v-if="keytype!='TP_SL'" class="box" style="border-bottom: 1px solid #f8f8f8;">
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.free')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.usedFees}}{{ itemData.buyCoinName }}</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.order time')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.entrustTime}}</span></van-col>
          </van-row>
        </div>
        <div v-else class="box" style="border-bottom: 1px solid #f8f8f8;">
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.Order trigger direction')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >--</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.Actual transaction number')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >--</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.Actual transaction price')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >--</span></van-col>
          </van-row>
          <van-row class="row"  gutter="5">
            <van-col class="left" span="12"><span >{{$t('M.order time')}}</span></van-col>
            <van-col class="right" span="12"><span class="Sub" >{{itemData.createTime}}</span></van-col>
          </van-row>
        </div>
        <div v-if="!history" class="box" style="position: absolute;bottom: 0;width: 100%;">
          <van-button @click="YcancelEntrust" color="#F10E38" round  block>{{$t('M.Cancel order')}}</van-button>
        </div>
</div>
</template>

<script>
import { Toast } from 'vant';
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["isLogin", "user", "lang"]),
    },
  props:['itemData','keytype','history'],
  mounted(){
    console.log(this.itemData);
    console.log(this.keytype);
  },
  methods:{
    YcancelEntrust(){
      if (!this.isLogin)return
      this.$api.YcancelEntrust({entrustId:this.itemData.id,type:this.keytype}).then(res=>{
        if(res.meta.success){
          Toast(this.$t('M.common_success'))
          this.$emit('dreg')
          }

      })
    },
    filtersData(key){
      if(key =='OPEN_UP') return this.$t('M.Open long')
      if(key =='OPEN_DOWN') return this.$t('M.Open short')
      if(key =='CLOSE_UP') return this.$t('M.Close long')
      if(key =='CLOSE_DOWN') return this.$t('M.Close short')
      if(key =='BY') return this.$t('M.Isolated')
      if(key =='TOTAL') return this.$t('M.Cross')

      if(key =='LIMIT') return this.$t('M.Limit price')
      if(key =='MARKET') return this.$t('M.Market')
      if(key =='FULL_STOP') return this.$t('M.TP | SL')
      // if(key =='PLAN_FULL_STOP') return '计划委托'
      if(key =='NO-TRADE') return this.$t('M.entrust_msg_status_001')
      if(key =='SOME-TRADE') return this.$t('M.entrust_msg_status_002')
      if(key =='COMPLE-TRADE') return this.$t('M.entrust_msg_status_003')
      if(key =='WITHDRAWN') return this.$t('M.entrust_msg_status_004')
      if(key =='NOT_TRIGGERED') return this.$t('M.Waiting effect')
      if(key =='TRIGGERED') return this.$t('M.In force')
      if(key =='TRIGGERED_FAIL') return this.$t('M.failure')
      // if(key =='NOT_SUPPORT_TRIGGER') return '不支持触发'
      if(key =='ONE_WAY') return this.$t('M.One-way')
      if(key =='TWO_WAY') return this.$t('M.Two-way')
    },
  }
}
</script>

<style lang="scss" scoped>
.box{
    padding: 10px;
    .van-row{
      margin-bottom: 10px;
      span{
        color: #5C6573;
      }
      .Sub{
        color: #000;
      }
      .title{
        font-size: 16px;
      }
      .left{text-align: left;}
      .right{text-align: right;}
    }
  }

</style>
