<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-19 14:50:25
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="setPassword container">
    <van-nav-bar :title="$t('M.change_phone')" left-text="" left-arrow @click-left="onClickLeft" />

    <div class="content">
      <van-cell-group>
        <van-cell>
          <template #right-icon>
            <div class="country">
              +{{country}}
            </div>
            <van-field v-model="phoneNum" size="large" :placeholder="$t('M.input_newphone_code')" style="padding:0;" />

            <div style="color:#dcb069;white-space: nowrap;" @click="sendMsg" v-if="downTimeNum <= 0"> {{$t("M.get_code")}}</div>
            <div style="color:#dcb069;white-space: nowrap;" v-if="downTimeNum > 0">{{downTimeNum}}s</div>
          </template>
        </van-cell>
        <van-cell title="">
          <template #right-icon>
            <van-field v-model="codeNum" size="large" :placeholder="$t('M.login_please_input1')" style="padding:0;" />
          </template>
        </van-cell>
      </van-cell-group>

      <div class="tips">{{$t('M.my_prompt_001')}}</div>

      <div class="next" @click="next">
        {{$t("M.affirm")}}
      </div>
    </div>

    <van-popup v-model="countriesListShow" position="bottom" :style="{ height: '100%' }">
      <van-search v-model="inputCountrText" placeholder="Please select country or region" :autofocus="true" :show-action="true"
        @cancel="countriesListShow = false" @input="changeInput" :action-text="$t('M.Cancel')" />
      <div class="countriesListBox">
        <div class="listItem" v-for="(item, idx) in showCountriesListData" :key="idx" @click="selectItem(item)">
          <div class="name" v-if="item.nationCode != '855'">{{ item.english.replace(/\(China\)/g, '') }}</div>
          <div class="name" v-if="item.nationCode == '855'">Cambodia</div>
          <div class="number">+{{ item.nationCode }}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      phoneNum: '',
      codeNum: '',
      link: '',
      value: '',
      downTimeNum: 0,
      timeObj: null,
      getMsg: false,
      countriesListShow: false,
      countriesListData: [],
      showCountriesListData: [],
      showCountrName: '',
      inputCountrText: '',
      country: ''
    }
  },
  computed: {
    ...mapGetters([
      'user', "otherUser"
    ]),
  },
  mounted() {
    /* 获取国家列表 */
    this.$api.selectList().then((res) => {
      this.countriesListData = res.data;
      this.showCountriesListData = res.data;
      const countr = this.countriesListData.find(item => item.nationCode == this.user.country)
      this.showCountrName = countr;
      this.country = this.showCountrName.nationCode;
    })
  },
  methods: {
    /* 选中了某一个国家 */
    selectItem(data) {
      this.showCountrName = data.nationCode;
      this.countriesListShow = false;
    },
    /* 改变输入内容 */
    changeInput() {
      if (this.inputCountrText == "") {
        this.showCountriesListData = this.countriesListData;
      } else {
        let showArr = [];
        for (const iterator of this.countriesListData) {
          if (
            iterator.chinese.indexOf(this.inputCountrText) != -1 ||
            iterator.english.indexOf(this.inputCountrText) != -1 ||
            iterator.nationCode.indexOf(this.inputCountrText) != -1
          ) {
            showArr.push(iterator);
          }
        }
        this.showCountriesListData = showArr;
      }
    },
    /* 下一步 */
    next() {
      if (this.phoneNum == '') {
        this.$notify(this.$t('M.input_newphone_code'))
        return
      }
      if (this.codeNum == '') {
        this.$notify(this.$t('M.login_please_input1'))
        return
      }
      this.$api
        .changePhoneAndCheckOldCode({
          partnerId: "472437831826935808",
          phone: this.phoneNum,
          newCode: this.codeNum,
          oldCode: this.$route.query.oldCode,
          partnerNo: "1000010001",
        })
        .then((data) => {
          if (data.meta.success) {
            this.$store.dispatch('clearUserData')
            this.$router.replace("login")
          } else {
            this.$notify(data.meta.message)
          }
        });
    },
    /* 获取验证码 */
    sendMsg() {

      if (this.phoneNum == '') {
        this.$notify(this.$t('M.input_newphone_code'))
        return
      }
      this.$api
        .sendSms({
          partnerId: "472437831826935808",
          phone: this.phoneNum,
          nationCode: this.showCountrName,
          action: "UPDATE_PHONE"
        })
        .then((data) => {
          if (data.meta.success) {
            this.getMsg = true;
            this.downTimeNum = 59;
            this.timerDown();
            this.$notify(data.meta.message)
          } else {
            this.$notify(data.meta.message)
          }
        });
    },
    /* 倒计时开启 */
    timerDown() {
      this.downTimeNum;
      this.timeObj = setInterval(() => {
        this.downTimeNum--;
        if (this.downTimeNum <= 0) {
          this.downTimeNum = 0;
          clearInterval(this.timeObj);
        }
      }, 1000);
    },
    /* 开关切换 */
    onInputSwitch() {
      let num = 0;
      if (this.otherUser.isEnablePhone) {
        num++;
      }
      if (this.otherUser.isEnableMail) {
        num++;
      }
      if (this.otherUser.isEnableGoogle) {
        num++;
      }

      if (num <= 1) {
        this.$notify(this.$t('M.must_retain_one'))
      }

    },
    onClickLeft() {
      this.$router.back()
    }
  },
  destroyed() {
    clearInterval(this.timeObj)
  }
}
</script>

<style lang="scss" scoped>
.setPassword {
  font-size: 12px;
  background-color: #f8f8f8;
  .content {
    padding: 12px;
    border-radius: 8px;
    .van-cell-group {
      border-radius: 10px;
    }
  }
  .tips {
    margin-top: 20px;
    color: #5c6573;
  }
  .next {
    width: 345px;
    height: 46px;
    background: #00b897;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    line-height: 46px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .jiantou {
    width: 7px;
    height: 4px;
    margin-left: 4px;
  }

  .country {
    display: flex;
    min-width: 55px;
    align-items: center;
  }
}

.countriesListBox {
  .listItem {
    font-size: 14px;
    line-height: 54px;
    margin: 0 19px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
  }
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}
::v-deep .van-cell::after {
  border: 0;
}
</style>