<template>
  <div class="home">
    <van-nav-bar :title="$t('M.Open orders')" left-arrow @click-left="leftArrow" @click-right="$emit('dialog',actionsKey)">
        <template #left>
          <van-icon name="arrow-left" size="1.2em" />
        </template>
        <template #right>
          <span v-if="activePopup == 0" style="color: #F10E38;">{{$t('M.Cancel all')}}</span>
        </template>
      </van-nav-bar>
      <van-tabs  v-model="activePopup" line-width="16px" line-height="3px" title-active-color="#F10E38"
              title-inactive-color="#5C6573" color="#F10E38" @change="changeBarPopup">
          <van-tab title-class="titItem"  :title="$t('M.Open orders')+'('+EntCount+')'">
            <van-row style="padding: 5px 15px;background-color: #f7f7f7;">
              <van-col style="text-align: right;" offset="12" span="12">
                <van-popover
                  v-model="showPopover"
                  trigger="click"
                  :actions="actions"
                  @select="onSelect"
                  placement="bottom-end"
                  overlay
                >
                  <template #reference><span>{{actionsContent}}</span><van-icon name="arrow-down" /></template>
                </van-popover>
              </van-col>
            </van-row>
            <div style="overflow-y: scroll;height: calc(100vh - 130px);">
            <van-list offset="0"  :immediate-check="false" style="height:100%"  v-model="loading" :finished="finished" @load="onLoad" loading="loading">
              <template v-if="actionsKey!='TP_SL'">
                <div class="box" @click="SelectPopup(item,'DEFAULT')" v-for="(item,index) in defaultList" :key="index">
                    <van-row class="row"  gutter="5">
                      <van-col span="22"><span class="Sub title">{{item.tradeName}}</span></van-col>
                      <van-col span="2"><span @click.stop="YcancelEntrust(item,'DEFAULT')" class="Sub title"><van-icon color="#F10E38" name="share" /></span></van-col>
                    </van-row>
                    <van-row gutter="5">
                      <van-col span="24" class="margin">
                        <van-tag :color="item.tradeMode === 'OPEN_UP' ? '#e5f8f5' :'#FEE7EB'"  :text-color="item.tradeMode === 'OPEN_UP' ? '#00B897' :'#F10E38'" >{{filtersData(item.tradeMode)}}</van-tag>
                        <span class="Sub">{{item.lever}}X</span>
                        <span>{{item.entrustTime}}</span>
                      </van-col>
                    </van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.Order amt')}}：</span><span class="Sub">{{item.count}}{{ item.sellCoinName }}</span></van-col>
                      <van-col span="12"><span>{{$t('M.Order price')}}：</span><span class="Sub">{{item.price}}</span></van-col></van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.deal_done')}}：</span> <span class="Sub">{{item.completeCount}}{{ item.sellCoinName }}</span></van-col>
                      <van-col span="12"><span>{{$t('M.deal_price')}}：</span><span class="Sub">{{item.completePrice | completePrice}}</span></van-col>
                    </van-row>
                    <van-divider />
                  </div>
              </template>
              <template v-else>
                  <div class="box" @click="SelectPopup(item,'TP_SL')" v-for="(item,index) in dTP_SLList" :key="index">
                    <van-row class="row"  gutter="5">
                      <van-col span="22"><span class="Sub title">{{item.tradeName}}</span></van-col>
                      <van-col span="2"><span @click.stop="YcancelEntrust(item,'TP_SL')" class="Sub title"><van-icon color="#F10E38" name="share" /></span></van-col>
                    </van-row>
                    <van-row gutter="5">
                      <van-col  span="24" class="margin">
                        <van-tag v-if="item.tradeMode!='CLOSE_DOWN'" color="#e7f7f2" text-color="#F10E38">{{filtersData(item.tradeMode)}}</van-tag>
                        <van-tag v-else color="#ffefef" text-color="#e9697e">{{filtersData(item.tradeMode)}}</van-tag>
                        <van-tag color="#f8f8f8" text-color="#5C6573">{{filtersData(item.positionsType)}}</van-tag>
                        <span class="Sub">{{item.lever}}X</span>
                        <span>{{item.createTime}}</span>
                      </van-col>
                    </van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.TP trigger price')}}：</span><span class="Sub">{{item.tpTriggerPrice}}</span></van-col>
                      <van-col span="12"><span>{{$t('M.TP order price')}}：</span><span class="Sub">{{item.tpSavePrice!=0?item.tpSavePrice:$t('M.market_price')}}</span></van-col></van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.SL trigger price')}}：</span> <span class="Sub">{{item.slTriggerPrice}}</span></van-col>
                      <van-col span="12"><span>{{$t('M.SL order price')}}：</span><span class="Sub">{{item.slSavePrice!=0?item.slSavePrice:$t('M.market_price')}}</span></van-col>
                    </van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.Order amt')}}：</span><span class="Sub">{{item.count}}{{ item.sellCoinName }}</span></van-col>
                    </van-row>
                    <van-divider />
                  </div>
              </template>
            </van-list>
            </div>
          </van-tab>
          <van-tab title-class="titItem" :title="$t('M.Order history')">
            <van-row style="padding: 5px 15px;background-color: #f7f7f7;">
              <van-col style="text-align: right;" offset="12" span="12">
                <van-popover
                  v-model="showPopover1"
                  trigger="click"
                  :actions="actions"
                  @select="onSelect1"
                  placement="bottom-end"
                  overlay
                >
                  <template #reference><span>{{actionsContent1}}</span><van-icon name="arrow-down" /></template>
                </van-popover>
              </van-col>
            </van-row>
            <div style="overflow-y: scroll;height: calc(100vh - 130px);">
            <van-list offset="0"  :immediate-check="false" style="height:100%" v-model="loading" :finished="finished" @load="onLoad" loading="loading">
              <template v-if="actionsKey1!='TP_SL'">
                  <div class="box" v-for="(item,index) in historyData" :key="index" @click="SelectPopup(item,'DEFAULT',true)" >
                    <van-row class="row" justify="space-between"  gutter="5">
                      <van-col span="18"><span class="Sub title">{{item.tradeName}}</span></van-col>
                      <van-col span="6" class="right"><span >{{filtersData(item.status)}}</span></van-col>
                    </van-row>
                    <van-row gutter="5">
                      <van-col span="24" class="margin"  >
                        <van-tag v-if="item.tradeMode ==='CLOSE_UP' || item.tradeMode ==='OPEN_UP'" color="#e7f7f2" text-color="#00B897">{{filtersData(item.tradeMode)}}</van-tag>
                        <van-tag v-else color="#ffefef" text-color="#e9697e">{{filtersData(item.tradeMode)}}</van-tag>
                        <van-tag color="#f8f8f8" text-color="#5C6573">{{filtersData(item.positionsType)}}</van-tag>
                        <span class="Sub">{{item.lever}}X</span>
                        <span>{{item.completeTime}}</span>
                      </van-col>
                    </van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.Order amt')}} </span><span class="Sub">{{item.count}}{{ item.sellCoinName }}</span></van-col>
                      <van-col span="12"><span>{{$t('M.Order price')}} </span><span class="Sub">{{item.price}}</span></van-col></van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.Filled amt')}} </span> <span class="Sub">{{item.completeCount}}{{ item.sellCoinName }}</span></van-col>
                      <van-col span="12"><span>{{$t('M.deal_price')}} </span><span class="Sub">{{item.completePrice}}</span></van-col>
                    </van-row>
                    <van-row gutter="20" v-if="item.incomeAmount!='0'">
                      <van-col span="12"><span>{{$t('M.PL')}} </span> <span class="Sub">{{item.incomeAmount}}</span></van-col>
                      <van-col span="12"><span>{{$t('M.PL Ratio')}} </span><span class="Sub">{{ cutOutPointLength((parseFloat(item.incomeRate) * 100),2) }}%</span></van-col>
                    </van-row>
                    <van-divider />
                    <!-- <van-row gutter="20">
                      <van-col span="12"><span>委托总量 </span> <span class="Sub">{{item.incomeAmount}}</span></van-col>
                    </van-row> -->
                  </div>
              </template>
              <template v-else>
                  <div class="box" @click="SelectPopup(item,'TP_SL',true)" v-for="(item,index) in historyData" :key="index">
                    <van-row class="row"  gutter="5">
                      <van-col span="20"><span class="Sub title">{{item.tradeName}}</span></van-col>
                      <van-col span="4" class="right"><span >{{filtersData(item.status)}}</span></van-col>
                    </van-row>
                    <van-row gutter="5">
                      <van-col span="24" class="margin">
                        <van-tag color="#e7f7f2" text-color="#F10E38">{{filtersData(item.tradeMode)}}</van-tag>
                        <van-tag color="#f8f8f8" text-color="#5C6573">{{positionsType(item.positionsType)}}</van-tag>
                        <span class="Sub">{{item.lever}}X</span>
                        <span>{{item.createTime}}</span>
                      </van-col>
                    </van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.TP trigger price')}}：</span><span class="Sub">{{(item.tpTriggerPrice-0).toFixed(2)}}</span></van-col>
                      <van-col span="12"><span>{{$t('M.TP order price')}}：</span><span class="Sub">{{item.tpSavePrice!=0?item.tpSavePrice:$t('M.market_price')}}</span></van-col></van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.SL trigger price')}}：</span> <span class="Sub">{{(item.slTriggerPrice-0).toFixed(2)}}</span></van-col>
                      <van-col span="12"><span>{{$t('M.SL order price')}}：</span><span class="Sub">{{item.slSavePrice!=0?item.slSavePrice:$t('M.market_price')}}</span></van-col>
                    </van-row>
                    <van-row gutter="20">
                      <van-col span="12"><span>{{$t('M.Order amt')}}：</span><span class="Sub">{{item.count}}{{ item.sellCoinName }}</span></van-col>
                    </van-row>
                    <van-divider />
                  </div>
              </template>
            </van-list>
            </div>
          </van-tab>
          <van-tab title-class="titItem" :title="$t('M.assets_cash_flow')">
             <van-row style="padding: 5px 15px;background-color: #f7f7f7;" type="flex" justify="space-between">
                <van-col style="text-align: left;"  span="6">
                    <!-- <van-dropdown-menu class="dropdown">
                        <van-dropdown-item title-class="dropdownTitle"  ref="item">
                            <template #title>
                                <span style="font-size: 0.32rem;">{{$t('M.all')}}</span>
                            </template>
                            <van-search
                                v-model="value"
                                shape="round"
                                background="#ffffff"
                                placeholder="搜索"
                            />
                            <van-cell class="cell" center>
                                <template #title>
                                    <span style="color: #F10E38;">全部</span>
                                </template>
                            </van-cell>
                            <van-cell class="cell" center>
                                <template #title>
                                    <span style="color: #F10E38;">yongxu</span>
                                </template>
                            </van-cell>
                        </van-dropdown-item>
                    </van-dropdown-menu> -->
              </van-col>
              <van-col style="text-align: right;"  offset="12" span="12">
                <van-popover
                  v-model="showPopover2"
                  trigger="click"
                  :actions="actions2"
                  @select="onSelect2"
                  placement="bottom-end"
                  overlay
                >
                  <template #reference><span>{{actionsContent2}}</span><van-icon name="arrow-down" /></template>
                </van-popover>
              </van-col>
            </van-row>
            <div style="overflow-y: scroll;height: calc(100vh - 130px);">
              <van-list offset="10"  :immediate-check="false" style="height:100%"  v-model="loading" :finished="finished" @load="onLoad" >
                <div class="box" v-for="(item,index) in fundList" :key="index">
                  <div v-if="item.tradeType==146">
                      <van-row class="row"  gutter="5">
                        <van-col span="8"><span class="Sub title">{{item.buyCoinNickname}}</span></van-col>
                        <van-col span="16" class="right"><span>{{$t('M.income')}}<span :class="item.totalChange<0?'gre':'red'">{{item.totalChange<0?'':'+'}}{{ item.totalChange }}</span></span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span>{{ item.createTime }}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.service_charge')}}</span>0</van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><van-tag color="#f8f8f8" text-color="#5C6573">{{tradeMode(item.positionsType)}}</van-tag></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.balance')}}{{item.total}}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span class="Sub">{{item.tradeDesc}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.Position balance')}}{{item.margin}}</span></van-col>
                      </van-row>
                    </div>
                    <div v-if="item.tradeType==150||item.tradeType==151">
                      <van-row class="row"  gutter="5">
                        <van-col span="20"><span class="Sub title">{{item.buyCoinNickname}}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span>{{ item.createTime }}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><van-tag color="#f8f8f8" text-color="#5C6573">{{filtersData(item.positionsType)}}</van-tag><span>{{item.coinName}}</span></van-col>
                        <van-col span="12" class="right"><span :class="item.totalChange.slice(0,1) == '-'?'gre':'red'">{{ item.totalChange.slice(0,1) == '-'?item.totalChange:'+'+item.totalChange }}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span class="Sub">{{item.tradeDesc}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.balance')}}{{item.total}}</span></van-col>
                      </van-row>
                    </div>
                    <div v-if="item.tradeType==147||item.tradeType==148">
                      <van-row class="row"  gutter="5">
                        <van-col span="20"><span class="Sub title">{{item.buyCoinNickname}}</span></van-col>
                        <!-- <van-col span="4" class="right"><span >{{filtersData(item.status)}}</span></van-col> -->
                      </van-row>
                      <van-row>
                        <van-col span="12"><span>{{ item.createTime }}</span></van-col>
                        <van-col span="12" class="right"><span :class="item.tradeType==147?'gre':'red'">{{ (item.totalChange-0)>0?'+'+item.totalChange:item.totalChange }}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><van-tag color="#f8f8f8" text-color="#5C6573">{{filtersData(item.positionsType)}}</van-tag><span>{{item.coinName}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.balance')}}{{item.total}}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span class="Sub">{{item.tradeDesc}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.Position balance')}}{{item.margin}}</span></van-col>
                      </van-row>
                    </div>
                    <div v-if="item.tradeType==156||item.tradeType==157||item.tradeType==158||item.tradeType==159">
                      <van-row class="row"  gutter="5">
                        <van-col span="12"><span class="Sub title">{{item.buyCoinNickname}}</span></van-col>
                        <van-col span="12" class="right"><span>{{$t('M.income')}}<span :class="item.incomeAmount<0?'gre':'red'">{{item.incomeAmount<0?'':'+'}}{{ item.incomeAmount }}</span></span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span>{{ item.createTime }}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.service_charge')}}</span><span :class="fundColor(item.paTotalChange,item.adTotalChange)?'gre':'red'">{{item.completeTime<0?'-':'+'}}{{ item.completeTime }}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><van-tag color="#f8f8f8" text-color="#5C6573">{{filtersData(item.positionsType)}}</van-tag><span>{{item.coinName}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.balance')}}{{item.total}}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span class="Sub">{{item.tradeDesc}}</span><span>{{ item.positionNum }}{{$t('M.Cont')}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.Position balance')}}{{item.margin}}</span></van-col>
                      </van-row>
                    </div>
                    <div v-if="item.tradeType==152||item.tradeType==153||item.tradeType==154||item.tradeType==155">
                      <van-row class="row"  gutter="5">
                        <van-col span="12"><span class="Sub title">{{item.buyCoinNickname}}</span></van-col>
                        <van-col span="12" class="right"><span>{{$t('M.income')}}<span :class="item.incomeAmount<0?'gre':'red'">{{item.incomeAmount<0?'':'+'}}{{ item.incomeAmount }}</span></span></van-col>

                      </van-row>
                      <van-row>
                        <van-col span="12"><span>{{ item.createTime }}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.service_charge')}}</span><span :class="fundColor(item.paTotalChange,item.adTotalChange)?'red':'gre'">{{fundNum(item.paTotalChange,item.adTotalChange) }}</span></van-col>

                      </van-row>
                      <van-row>
                        <van-col span="12"><van-tag color="#f8f8f8" text-color="#5C6573">{{filtersData(item.positionsType)}}</van-tag><span>{{item.coinName}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.balance')}}{{item.total}}</span></van-col>

                      </van-row>
                      <van-row>
                        <van-col span="12"><span class="Sub">{{item.tradeDesc}}</span><span>{{ item.positionNum }}{{$t('M.Cont')}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.Position balance')}}{{item.margin}}</span></van-col>
                      </van-row>
                    </div>
                    <div v-if="item.tradeType==142||item.tradeType==143">
                      <van-row class="row"  gutter="5">
                        <van-col span="20"><span class="Sub title">{{item.buyCoinNickname}}</span></van-col>
                        <!-- <van-col span="4" class="right"><span >{{filtersData(item.status)}}</span></van-col> -->
                      </van-row>
                      <van-row>
                        <van-col span="12"><span>{{ item.createTime }}</span></van-col>
                        <van-col span="12" class="right"><span :class="item.tradeType==142?'gre':'red'">{{ item.totalChange }}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span class="Sub">{{item.tradeDesc}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.balance')}}{{item.total}}</span></van-col>
                      </van-row>
                    </div>
                </div>
              </van-list>
            </div>

          </van-tab>

      </van-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Toast } from 'vant';
export default {
  props:{
    latestDone:{
      type:Number|null,
      default: 0
    },
  },
  watch: {
    latestDone:{
      handler(newQuestion, oldQuestion) {
        this.MonitoringPrice()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["isLogin", "user", "lang"]),
    },
    data(){
        return{
            loading: false,
            finished: false,
            loading1: false,
            finished1: false,
            activeSubsetShow2:false,
            orderShow:false,
            showPopover:false,
            showPopover1:false,
            showPopover2:false,
            actions:[{text: this.$t('M.LimitAndMarket'),key:'DEFAULT'},{text: this.$t('M.advanced_limit_order'),key:'ADVANCED_PRICE_LIMIT'},{text: this.$t('M.TP | SL'),key:'TP_SL'}],
            actions2:[{text: this.$t('M.all'),key:'ALL'},{text: this.$t('M.Transfer'),key:'CONTRACT_TRANSFER'},{text: this.$t('M.tab_trade'),key:'CONTRACT_DEAL'},{text: this.$t('M.liquidation'),key:'CONTRACT_FORCE_CLOSE'},{text: this.$t('M.Margin transfer'),key:'CONTRACT_EARNEST_MONEY_TRANSFER'},{text: this.$t('M.Capital fee'),key:'CONTRACT_FUND_RATE'}],
            actionsContent: this.$t('M.LimitAndMarket'),
            actionsContent1: this.$t('M.LimitAndMarket'),
            actionsContent2: this.$t('M.all'),
            actionsKey:'DEFAULT',
            actionsKey1:'DEFAULT',
            actionsKey2:'ALL',
            activePopup:0,
            value:'',
            EntCount: 0,
            defaultList:[],
            dTP_SLList:[],
            fundList:[],
            params:{
              pageNum:1,
              pageSize:10,
              requestMatchType:''
            },
            historyData:[],
            historyParams:{
              pageNum:1,
              pageSize:10,
              requestMatchType:''
            },
            fundParams:{
              pageNumber:1,
              pageSize:10,
              contractType:''
            }
        }
    },
    filters:{
      completePrice(str){
        return str!='0'?str:'--'
      }
    },
    mounted(){
      this.currentEntrust('DEFAULT')
      this.currentEntrustCount()
      this.historyEntrust('DEFAULT')
      this.fundBill('ALL')

    },
    methods:{
      MonitoringPrice(){
      if(this.defaultList.length!=0){
        this.defaultList.forEach((v,i)=>{
        if(this.latestDone > (v.price-0)){
          this.currentEntrust(this.actionsKey)
        }
      })
      }
      if(this.dTP_SLList.length==0)return
      this.dTP_SLList.forEach((v,i)=>{
        if(this.latestDone > (v.tpTriggerPrice-0)){
          this.currentEntrust('TP_SL')
        }
        if(this.latestDone < (v.slTriggerPrice-0)){
          this.currentEntrust('TP_SL')
        }
      })
    },
    tradeMode(key){
            if(key =='OPEN_UP') return this.$t('M.Open long')
            if(key =='OPEN_DOWN') return this.$t('M.Open short')
            if(key =='CLOSE_UP') return this.$t('M.Close long')
            if(key =='CLOSE_DOWN') return this.$t('M.Close short')
            if(key =='BY') return this.$t('M.Isolated')
            if(key =='TOTAL') return this.$t('M.Cross')
            if(key =='NO_UNWIND') return this.$t('M.Unfilled')
            if(key =='PART_UNWIND') return this.$t('M.Partially closed')
            if(key =='ALL_UNWIND') return this.$t('M.All Closed')
            return '-'
        },
        YcancelEntrust(item,key){
          if (!this.isLogin)return
        this.$api.YcancelEntrust({entrustId:item.id,type:this.actionsKey}).then(res=>{
          if(res.meta.success){
            Toast(this.$t('M.common_success'))
            this.currentEntrust(this.actionsKey)
            this.currentEntrustCount()
            }
            // console.log('chedanchenggong',res);
        })
      },
      //资金流水
      fundBill(key){
        if (!this.isLogin)return
        this.fundParams.contractType = key
        this.$api.fundBill(this.fundParams).then(res=>{
          this.fundList = res.data.fundBill.list
          console.log(res);
        })
      },
      fundColor(a,b){
        let num = (a-0)+(b-0)
        if(num < 0)return true
        return false
      },
      fundNum(a,b){
        let num = (a-0)+(b-0)
        if(num < 0)return '+'+num
         return '-'+num
      },
      filtersData(key){
      if(key =='OPEN_UP') return this.$t('M.Open long')
      if(key =='OPEN_DOWN') return this.$t('M.Open short')
      if(key =='CLOSE_UP') return this.$t('M.Close long')
      if(key =='CLOSE_DOWN') return this.$t('M.Close short')
      if(key =='BY') return this.$t('M.Isolated')
      if(key =='TOTAL') return this.$t('M.Cross')
      if(key =='LIMIT') return this.$t('M.Limit price')
      if(key =='MARKET') return this.$t('M.Market')
      if(key =='FULL_STOP') return this.$t('M.TP | SL')
      // if(key =='PLAN_FULL_STOP') return '计划委托'
      if(key =='NO-TRADE') return this.$t('M.entrust_msg_status_001')
      if(key =='SOME-TRADE') return this.$t('M.entrust_msg_status_002')
      if(key =='COMPLE-TRADE') return this.$t('M.entrust_msg_status_003')
      if(key =='WITHDRAWN') return this.$t('M.entrust_msg_status_004')
      if(key =='NOT_TRIGGERED') return this.$t('M.Waiting effect')
      if(key =='TRIGGERED') return this.$t('M.In force')
      if(key =='TRIGGERED_FAIL') return this.$t('M.failure')
      // if(key =='NOT_SUPPORT_TRIGGER') return '不支持触发'
      if(key =='ONE_WAY') return this.$t('M.One-way')
      if(key =='TWO_WAY') return this.$t('M.Two-way')
    },

      historyEntrust(key){
        if (!this.isLogin)return
        this.historyParams.requestMatchType = key
        if(key == 'TP_SL'){
            this.$api.historyTpslEntrust(this.historyParams).then(res=>{
                // console.log(res);
                this.historyData = res.data.list
            })
            return
          }
        this.$api.historyEntrust(this.historyParams).then(res=>{
          this.historyData = res.data.list
        })
      },
      //列表
      currentEntrust(key){
        if (!this.isLogin)return
        this.params.requestMatchType = key
          if(key == 'TP_SL'){
            this.$api.currentTpslEntrust(this.params).then(res=>{
                console.log(res);
                this.dTP_SLList = res.data.list
            })
            return
          }
          this.$api.currentEntrust(this.params).then((res) => {
            console.log(res.data);
              if(key == 'DEFAULT' || key == 'ADVANCED_PRICE_LIMIT'){
                this.defaultList = res.data.list
              }
          })
        },
        currentEntrustCount(){
          if (!this.isLogin)return
          this.$api.currentEntrustCount().then(res=>{
              this.EntCount = res.data.count
          })
        },
        leftArrow(){
            this.$emit('dreg')
        },
        onSelect(e){
            console.log(e);
            this.actionsContent = e.text
            this.actionsKey = e.key
            this.params.pageNum =1
            this.currentEntrust(e.key)
        },
        onSelect1(e){
            console.log(e);
            this.actionsContent1 = e.text
            this.actionsKey1 = e.key
            this.historyParams.pageNum =1
            this.historyEntrust(e.key)
        },
        onSelect2(e){
            console.log(e);
            this.actionsContent2 = e.text
            this.actionsKey2 = e.key
            this.fundParams.pageNumber = 1
            this.fundBill(e.key)
        },
        //批量撤单确认按键
        orderConfirm(){
            this.orderShow=false
        },
         //点击品种弹窗
        SelectPopup(item,key,history){
            this.$emit('popup',item,key,history)
            this.activeSubsetShow1 = true
        },
        onLoad() {
          if (!this.isLogin)return
          console.log('ss');
          if(this.activePopup==0){
            this.params.pageNum++;
            if(this.actionsKey == 'TP_SL'){
            this.$api.currentTpslEntrust(this.params).then(res=>{
                if(res.data.list.length != 0) {
                  res.data.list.forEach(element => {
                    this.dTP_SLList.push(element)
                  });
                    this.loading = false
                }else{
                    this.loading = false
                    this.finished = true
                }
              })
            }else{
              this.$api.currentEntrust(this.params).then((res) => {
                  if(res.data.list.length != 0) {
                    res.data.list.forEach(element => {
                      this.defaultList.push(element)
                    });
                    this.loading = false
                  }else{
                    this.loading = false
                    this.finished = true
                  }
              })
            }
          }
          if(this.activePopup==1){
            this.historyParams.pageNum++;
              if(this.actionsKey1 == 'TP_SL'){
                  this.$api.historyTpslEntrust(this.historyParams).then(res=>{
                      if(res.data.list.length != 0) {
                        res.data.list.forEach(element => {
                          this.historyData.push(element)
                        });
                        this.loading = false
                      }else{
                        this.loading = false
                        this.finished = true
                      }
                  })
                }else{
                  this.$api.historyEntrust(this.historyParams).then(res=>{
                      if(res.data.list.length != 0) {
                        res.data.list.forEach(element => {
                          this.historyData.push(element)
                        });
                        this.loading = false
                      }else{
                        this.loading = false
                        this.finished = true
                      }
                  })
                }
          }
          console.log(this.activePopup);
          if(this.activePopup==2){
            this.fundParams.pageNumber++;
            this.$api.fundBill(this.fundParams).then(res=>{
                      if(res.data.fundBill.list.length != 0) {
                        res.data.fundBill.list.forEach(element => {
                        this.fundList.push(element)
                        });
                        this.loading = false
                      }else{
                        this.loading = false
                        this.finished = true
                      }

            })
          }

        },
        onLoad1(){
          this.fundBill()
          // this.loading1 = true
          setTimeout(()=>{
            this.loading1 = false
          },3000)
        },
        changeBarPopup(key){
          // console.log(key);
          // this.list= []
          // this.loading = true
          // this.finished = false
          // this.onLoad()
        }

    }
}
</script>
<style lang="scss">
.dropdown {
    .van-dropdown-menu__bar{
        background: none;
        height: auto;
        box-shadow: none;
    }
    .cell{
        padding: 10px 10px!important;
    }
    .dropdownTitle{

    }
    .van-dropdown-menu__item{
        justify-content: left;
    }
}
</style>
<style lang="scss" scoped>

.home{
  height: 100vh;
}
.box{
  padding: 10px;
  .van-row{
    margin-bottom: 10px;
    .margin{
      span{
        margin-right: 10px;
      }
    }
    span{
      color: #5C6573;
    }
    .Sub{
      color: #000;
    }
    .title{
      font-size: 16px;
    }
    .left{text-align: left;}
    .right{text-align: right;}
    .red{
      color: #e54d68;
    }
    .gre{
      color: #1baf84;
    }
  }
}
.titItem {
    font-size: 16px;
    font-weight: 600;
  }
::v-deep .van-nav-bar__right{
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-width: 100px;
  line-height: 16px;
  text-align: right;
  word-break: break-all;
}
</style>
