export const M = {
    "Slideright": "請按住滑塊，拖動到最右邊",
    "Pleaseverification": "請完成安全驗證",
    "Pleaseorder": "請依次點擊",
    "Verificationagain": "驗證失敗，請重試",
    "10 orders": "10條",
    "24h High": "24H高",
    "24h Low": "24H低",
    "24h Trading Vol": "24小時成交量",
    "24h Vol": "24H量",
    "ADL mechanism": "ADL機制",
    "AMT": "數量",
    "Activity _home": "活動",
    "Actual transaction number": "實際成交數量",
    "Actual transaction price": "實際成交價格",
    "Add margin": "追加保證金",
    "Adjust leverage": "調整槓桿",
    "Adjust success": "調整成功",
    "Advanced limit order offers 3": "高級限價委託相對於普通限價單可選擇三種生效機制，“只做Maker（Post only）”、“全部成交或立即取消（FillOrKill）”、“立即成交並取消剩餘（ImmediateIOrCancel）”；而普通限價單生效機制默認是“一直有效”。",
    "Alipay": "支付寶",
    "All Closed": "全部平倉",
    "All Filled": "完全成交",
    "AllDetails": "查看強平詳情",
    "AllType": "全部類型",
    "Amount can flat": "可平量",
    "Appeal_reason": "對方申訴原因",
    "Applet service": "小程序服務",
    "Are you sure to cancel the order？": "確認撤銷該委託單嗎？",
    "Asked_to_record": "請按照下方要求錄製視頻材料",
    "Asset conversion": "資產折合",
    "Asset_types": "資產類型",
    "At the current price": "以當前市場最優價格下單",
    "Avail.": "可用",
    "Avail. margin": "可用保證金",
    "Available": "可用",
    "Avg close price": "平倉均價",
    "Avg open price": "開倉均價",
    "Avg.price": "成交均價",
    "BBO": "對手價",
    "Bankcard": "銀行卡",
    "Bankruptcy price": "破產價格",
    "Batch Cancel": "批量撤單",
    "Branch_address": "支行地址",
    "Buy crypto_home": "買幣",
    "Calculations are not based on": "計算結果僅供參考，此強平價格只代表一個合約下的只有一個開倉方向的數據。",
    "Can't calculate": "無法計算",
    "Cancel": "取消",
    "Cancel all": "批量撤單",
    "Cancel order": "撤銷訂單",
    "Cancel_the_login": "取消登錄",
    "Capital cost": "資金流水",
    "Capital cost - Expenditure": "資金費-支出",
    "Capital fee": "資金費",
    "Capital fee - all": "資金費-全部",
    "Capital fee - income": "資金費-收入",
    "Capital success": "已撤銷",
    "Charge_money": "充幣",
    "Chinese_Yuan": "人民幣",
    "Close all of": "您確認對",
    "Close long": "平多",
    "Close out time": "全部平完時間",
    "Close price": "平倉價格",
    "Close short": "平空",
    "Closed": "已平倉量",
    "Closing PnL gains": "平倉收益額",
    "Closing PnL ratio": "平倉收益率",
    "Closing profit": "平倉收益",
    "coin_failure_0002": "幣種資料暫不存在",
    "Common functions": "常用功能",
    "Communtiy_home": "社群",
    "Conceal canceled orders": "隱藏已撤銷",
    "Confirm_the_login": "確認登錄",
    "Confirm_to_mention_money": "確認提幣",
    "Cont": "張",
    "Copy link": "複製鏈接",
    "Cross": "全倉",
    "Currency_rate_description": "費率說明",
    "Current": "當前",
    "Delete_option": "刪除",
    "Determine_to_cancel": "確定取消?",
    "Don't show me again": "不再提醒",
    "Draw_inconsistency": "再次密碼輸入不一致",
    "Draw_the_unlock": "請滑動設置新密碼",
    "Enter email address": "請輸入您的郵箱地址",
    "Enter expected PnL": "請輸入預期收益",
    "Enter expected PnL Ratio": "請輸入預期收益率",
    "Enter leverage from 1 up to 125": "請輸入1-125倍槓桿",
    "Enter phone number": "請輸入您的手機號碼",
    "Enter the verification code": "請輸入驗證碼",
    "Equity": "幣種權益",
    "Est liquidation price": "預估強平價",
    "Face value": "合約價值",
    "Filed Value": "已成交價值",
    "Fill or Kill": "全部成交或立即取消",
    "Filled amt": "已成交量",
    "Filled time": "按成交時間",
    "Flash_strong": "閃光強烈",
    "Funding rate": "資金費率",
    "Funding rate - 1": "資金費率",
    "Funding rate comparison": "資金費率對比",
    "Funding rate time": "資金費率時間",
    "Fuzzy_information": "資訊模糊",
    "Gesture_error": "輸入密碼錯誤",
    "Google_code": "谷歌驗證碼",
    "Help_home": "幫助",
    "Hide_no_asset_currency": "隱藏沒有資產的幣種",
    "Highest PnL ratio": "收益率最高",
    "Historical record": "歷史記錄",
    "I agree to": "勾選同意",
    "I have read and agree to this agreement": "我已閱讀並同意該協議",
    "If_the_buyer_is_at": "如果買家在",
    "Immediately or Cancel": "立即成交並取消剩餘",
    "In force": "已生效",
    "Index": "現貨指數",
    "Index Price": "指數價格",
    "Index_contrct": "現貨指數",
    "Info": "合約簡介",
    "Initial margin": "初始保證金",
    "Instrument": "當前交易品種",
    "Insurance fund": "風險準備金餘額",
    "Interest bearing record": "計息記錄",
    "Invalid_qr_code": "無效二維碼",
    "Invite code(Optional)": "請輸入邀請碼（選填）",
    "InviteCodeMuster": "請輸入邀請碼（必填）",
    "Invite_home": "邀請",
    "Isolated": "逐倉",
    "Lack_of_edge": "邊角缺失",
    "Last Price": "市場成交價",
    "Latest Filled price": "最新成交價",
    "Latest Filled price01": "最新價格",
    "Latest price": "最新價格",
    "Leverage": "槓桿倍數",
    "Leverage after increase": "追加後的槓桿倍數：",
    "Leverage11": "當前實際槓桿",
    "Leverage_BB": "調整杠杆",
    "Limit order is an order": "限價委託是指用戶設置委託數量以及可接受的價格，當市場符合用戶預期時，系統會以限價範圍內的最優價格進行成交。",
    "Limit price": "限價",
    "Limit/Market": "限價/市價",
    "LimitAndMarket": "限價/市價",
    "Liquidation price": "強平價格",
    "Liquidation price after increase": "追加後的強平價格：",
    "Long": "做多",
    "Lowest PnL ratio": "收益率最低",
    "MAIL_COUNTRY_NOT_EXITS": "當前國家不存在！請添加過後再次發送郵件",
    "MAIL_NOT_MAIL_PROVIDER": "暫無郵件服務商，請添加過後再發送郵件！",
    "MAIL_TEMPLATE_NOT_FOUND": "當前郵件範本不存在！請添加過後再次發送",
    "MAIL_TO_ADDRESS_NOT_FOUND": "當前郵件地址不存在，請檢查過後再次發送",
    "MJRefreshAutoFooterIdleText": "點擊或上拉加載更多",
    "MJRefreshAutoFooterNoMoreDataText": "已經全部加載完畢",
    "MJRefreshAutoFooterRefreshingText": "正在加載更多的數據...",
    "MJRefreshBackFooterIdleText": "上拉可以加載更多",
    "MJRefreshBackFooterNoMoreDataText": "已經全部加載完畢",
    "MJRefreshBackFooterPullingText": "松開立即加載更多",
    "MJRefreshBackFooterRefreshingText": "正在加載更多的數據...",
    "MJRefreshHeaderDateTodayText": "今天",
    "MJRefreshHeaderIdleText": "下拉可以刷新",
    "MJRefreshHeaderLastTimeText": "最後更新：",
    "MJRefreshHeaderNoneLastDateText": "無記錄",
    "MJRefreshHeaderPullingText": "松開立即刷新",
    "MJRefreshHeaderRefreshingText": "正在刷新數據中...",
    "MKT Close ALL": "市價全平",
    "Margin add": "追加保證金",
    "Margin mode": "全部持倉模式",
    "Margin ratio": "保證金率",
    "Margin reduce": "減少保證金",
    "Margin transfer": "保證金劃轉",
    "Margin-1": "保證金餘額",
    "Margin-2": "調整保證金",
    "Margined": "合約",
    "Mark price": "標記價格",
    "Market": "市價",
    "Market Order Dig": "市價委託允許用戶以當前市場最優價下單，達到快速交易的目的。",
    "Market Overview": "市場總覽",
    "Market_transaction": "市場價交易",
    "Max": "最多可撤銷",
    "Max Open int": "最大持倉量",
    "Max buy": "可買",
    "Max long": "可開多",
    "Max position size at current leverage": "當前杠桿倍數最大可開：",
    "Max sell": "可賣",
    "Max short": "可開空",
    "Max position size at current leverage": "當前杠桿倍數最大可開",
    "Mention_money": "提幣",
    "Mention_money_address": "請輸入正確的地址",
    "Mention_money_tips_ios": "提示\n1、直接輸入提幣地址，需要驗證資金密碼、手機短信、郵箱或穀歌驗證碼。\n2、添加地址綁定的為自己的認證地址，下次提幣不用再輸入驗證。",
    "Messages_notification": "*請確認您的銀行卡已開啟短信通知服務",
    "Mini Programs": "小程序",
    "Minimum_handling_charge": "提幣數量必須大於手續費",
    "Minimum_withdrawal": "單筆最小提幣",
    "MoreNot9": "選中的模塊不能超過14個",
    "Most increase": "最多增加",
    "Most reduce": "最多減少",
    "Must be integer multiple of": "必須是整數倍",
    "Not Enabled": "未啟用",
    "Not_on": "暫不能上架",
    "Not_to_mention_money": "為了您的資金安全，重置交易密碼後1小時內將不可進行提幣、PUSH資產等敏感操作",
    "OTC account": "法幣賬戶",
    "OTC assets": "OTC錢包",
    "OTC交易须知": "OTC交易須知",
    "On Hold": "凍結",
    "One-way": "單向",
    "One/Two-way": "單向/雙向",
    "Open Time": "開倉時間",
    "Open amount": "開倉數量",
    "Open int.": "持倉量",
    "Open interest": "持倉量",
    "Open long": "開多",
    "Open orders": "當前委託",
    "Open price": "開倉價格",
    "Open short": "開空",
    "Order Details": "委託詳情",
    "Order Sorting": "訂單排序",
    "Order Value": "委託價值",
    "Order amt": "委托總量",
    "Order history": "歷史委托",
    "Order price": "委託價格",
    "Order trigger direction": "委託觸發方向",
    "Order type": "委託類型",
    "PL": "收益",
    "PL Ratio": "收益率",
    "Pair01": "合約幣種",
    "Parse_failure": "保存失敗",
    "Partially Filled": "部分成交",
    "Partially closed": "部分平倉",
    "Password": "密碼",
    "Payment_type": "收款類型",
    "Perpetual": "永續合約",
    "Perpetual funds daybook": "永續資金流水",
    "Perpetual01": "永續",
    "Pingclose": "平倉",
    "Please input a password": "請輸入",
    "Position": "倉位",
    "Position balance": "倉位餘額",
    "Position history": "歷史倉位",
    "QR_code": "二維碼",
    "Quantity cannot exceed": "數量不能超過125",
    "Quantity not less than": "數量不低於1",
    "Recent close": "按最近平倉",
    "Recording_requirements": "錄製視頻要求",
    "Required position margin at current leverage": "當前倉位所需保證金：",
    "Reset_transaction_password": "重置交易密碼",
    "Revoked": "已撤銷",
    "SL": "止損",
    "SL order": "止損委託",
    "SL order price": "止損委託價格",
    "SL order price can't be empty": "止損委託價格不能為空",
    "SL trigger price": "止損觸發價格",
    "SL trigger price can't be empty": "止損觸發價格不能為空",
    "SMS_30_OUT_RESTRICTED": "驗證碼發送頻繁,每30秒只能發送一條。",
    "SMS_BAD_ARGUMENT_FORMAT": "手機格式不正確。",
    "SMS_COUNTRY_NOT_EXITS": "此國家不存在，請檢查過後再發送短信！",
    "SMS_DAY_LIMIT_PER_MOBILE": "24小時內同一手機號發送次數超過限制",
    "SMS_DUP_IN_SHORT_TIME": "同一手機號30秒內重複提交相同的內容",
    "SMS_FAILURE": "發送失敗",
    "SMS_HOUR_LIMIT_PER_MOBILE": "1小時內同一手機號發送次數超過限制",
    "SMS_MARKET_FORBIDDEN": "由於運營商管制，行銷短信暫時不能發送",
    "SMS_NOT_SMS_PROVIDER": "暫無短信服務商，請添加過後再發送短信！",
    "SMS_NOT_SMS_PROVIDER_TEMPLATE": "暫無此短信服務商範本，請添加過後再發送短信！",
    "SMS_PHONE_OVER_COUNT": "此手機號驗證碼發送超過次數，請24小時以後在試！",
    "SMS_PHONE_PROHIBIT_SEND": "此手機禁止發送短信，請24小時以後在試！",
    "SMS_SUCCESS": "發送成功",
    "SMS_TOO_MANY_TIME_IN_5": "同一手機號5分鐘內重複提交相同的內容超過3次",
    "SMS_UNKNOWN_EXCEPTION": "系統出現未知的異常情況",
    "SMS_code": "短信驗證碼",
    "Save picture": "保存圖片",
    "Scanning_to_obtain": "掃描獲取充幣地址",
    "See auto deleverage liquidation for details": "詳見自動減倉機制",
    "Set_transaction_password": "設置交易密碼",
    "Settlement1": "距費用結算",
    "Settlrment coin": "結算貨幣",
    "Share time": "分享時間",
    "Shielding success": "拉黑成功",
    "Short": "做空",
    "Sign Up": "註冊",
    "Sign in": "登錄",
    "Simplified_Chinese": "繁體中文",
    "StatusOrder": "訂單狀態",
    "Stop loss": "設置止損",
    "Stop profit stop loss": "止盈止損至少選擇一個，否則無法下單",
    "Sustainable account": "永續賬戶",
    "Sustainable assets": "永續資產",
    "Switch to PnL": "切換收益",
    "Switch to PnL ratio": "切換收益率",
    "TP": "止盈",
    "TP order": "止盈委託",
    "TP order price": "止盈委託價格",
    "TP order price can't be empty": "止盈委託價格不能為空",
    "TP trigger price": "止盈觸發價格",
    "TP trigger price can't be empty": "止盈觸發價格不能為空",
    "TP | SL": "止盈止損",
    "TP | SL order": "止盈止損委託",
    "Take profit": "設置止盈",
    "Telegraphic_transfer_address": "電匯地址",
    "The Calculator": "計算器",
    "The maximum flatable quantity is": "最大可平量為",
    "The maximum leverage is": "最大槓桿倍數為",
    "The minimum flatable quantity is": "最小可平量為",
    "The minimum leverage is": "最小槓桿倍數為",
    "The poster has been saved to the album": "海報已保存到相冊",
    "The_correct_sample": "正確示例",
    "The_input_signal": "請滑動輸入密碼",
    "The_new_address": "新建地址",
    "They_are_the_number_of": "掛單數量",
    "Tick size": "最小變動價位",
    "To_update": "更新",
    "Toggle_front_camera": "請切換為前置攝像頭並保持頭部居中",
    "Total": "持倉總量",
    "Total coin": "持倉幣數",
    "Total cont": "持倉張數",
    "Total value": "持倉價值",
    "Total_asset_valuation": "總資產估值",
    "Trade type": "業務類型",
    "Trade_sector": "請選擇",
    "Transfer": "劃轉",
    "Transfer_to_remind": "轉賬時請務必備註(否則後果自負)",
    "Trigger mark price": "觸發標記價格",
    "Trigger price": "觸發價格",
    "Two-way": "雙向",
    "UPL": "未實現盈虧",
    "US_dollar": "美元",
    "Unblocked": "已解除拉黑",
    "Uneasy lies the head that wears a crown": "他強任他強\n我是陪跑王",
    "Unfilled": "未平倉",
    "Unfilled11": "等待成交",
    "Upload_collection_code": "請上傳收款碼",
    "Usdt-Margined": "USDT合約",
    "Users who register": "首次註冊用戶須完成登錄密碼設置",
    "Verification Code": "驗證碼",
    "Videoing_requirement": "拍攝要求",
    "View": "查看",
    "View more": "倉位詳情",
    "Waiting effect": "等待生效",
    "Waiting_for_the_payment": "待支付",
    "Wechat": "微信",
    "WestUnion": "西聯匯款",
    "Without_her": "沒有入鏡",
    "abandoned": "已放棄",
    "abnormal_assets": "帳戶資產異常，暫時不能進行該操作！",
    "about_us": "關於我們",
    "account": "賬號",
    "account_empty": "帳號錯誤",
    "account_failure_0001": "事務流水ID添加REDIS失敗！",
    "account_failure_0002": "可用不足，無法處理!",
    "account_failure_0003": "用戶凍結增加日誌記錄失敗！",
    "account_failure_0004": "用戶凍結增加失敗！",
    "account_failure_0005": "商戶扣除可用日誌記錄失敗！",
    "account_failure_0006": "商戶扣除可用失敗！",
    "account_failure_0007": "用戶可用增加日誌記錄失敗！",
    "account_failure_0008": "用戶可用增加失敗！",
    "account_failure_0009": "商戶添加新幣種,用戶添加新幣種失敗！",
    "account_failure_0010": "商戶添加新幣種失敗！",
    "account_failure_00100": "商戶ID不能為空!",
    "account_failure_00101": "用戶ID不能為空",
    "account_failure_00102": "幣種ID不能為空",
    "account_failure_00103": "數量不能為空",
    "account_failure_00104": "數量不能小於最小交易量",
    "account_failure_00105": "交易密碼不能為空",
    "account_failure_00106": "交易密碼錯誤",
    "account_failure_00107": "價格不能為空",
    "account_failure_00108": "單價不能小於最小單價",
    "account_failure_00109": "用戶不存在！",
    "account_failure_0011": "商戶錢包該幣種已存在!",
    "account_failure_00110": "PUSH資產發起失敗！",
    "account_failure_00111": "PUSHID不能為空",
    "account_failure_00112": "PUSH資產支付失敗！",
    "account_failure_00113": "PUSH資產取消失敗！",
    "account_failure_00114": "無法獲取充幣地址",
    "account_failure_00115": "提幣地址不能為空！",
    "account_failure_00116": "新增提幣地址失敗",
    "account_failure_00117": "ID不能為空",
    "account_failure_00118": "刪除提幣地址失敗",
    "account_failure_00119": "網路異常,請稍後再試!",
    "account_failure_0012": "凍結不足，無法處理!",
    "account_failure_00120": "該提幣地址已存在",
    "account_failure_00121": "無可PUSH幣種",
    "account_failure_00122": "手機驗證碼為空！",
    "account_failure_00123": "郵箱驗證碼為空！",
    "account_failure_00124": "穀歌驗證碼為空！",
    "account_failure_00125": "驗證碼錯誤！",
    "account_failure_00126": "禁止自我push！",
    "account_failure_00127": "提幣地址不合法！",
    "account_failure_00129": "修改密碼不超過24小時不允許操作",
    "account_failure_0013": "商戶凍結扣除日誌記錄失敗！",
    "account_failure_00130": "資產不平衡，不能操作",
    "account_failure_00131": "賬戶資產異常，交易狀態禁用",
    "account_failure_00132": "標籤不符",
    "account_failure_00133": "貨幣種類不存在或已被禁用",
    "account_failure_0014": "商戶凍結扣除失敗!",
    "account_failure_0015": "用戶可用利息增加日誌記錄失敗！",
    "account_failure_0016": "用戶可用利息增加失敗！",
    "account_failure_0017": "商戶可用利息扣除日誌記錄失敗！",
    "account_failure_0018": "商戶可用利息扣除失敗！",
    "account_failure_0019": "用戶可用到凍結失敗！",
    "account_failure_0020": "用戶可用到凍結失敗,日誌記錄失敗！",
    "account_failure_0021": "用戶凍結到可用日誌記錄失敗！",
    "account_failure_0022": "用戶凍結到可用失敗！",
    "account_failure_0023": "商戶增加可用日誌記錄失敗！",
    "account_failure_0024": "商戶增加可用失敗！",
    "account_failure_0025": "用戶凍結扣除日誌記錄失敗！",
    "account_failure_0026": "用戶凍結扣除失敗！",
    "account_failure_0027": "用戶可用扣除日誌記錄失敗！",
    "account_failure_0028": "用戶可用扣除失敗！",
    "account_failure_0029": "回滾操作失敗用戶凍結恢復日誌記錄失敗！",
    "account_failure_0030": "回滾操作失敗用戶凍結恢復失敗！",
    "account_failure_0031": "資產不夠無法回滾！",
    "account_failure_0032": "回滾操作失敗商戶凍結恢復日誌記錄失敗！",
    "account_failure_0033": "回滾操作失敗商戶資產恢復失敗",
    "account_failure_0034": "商戶凍結增加日誌記錄失敗！",
    "account_failure_0035": "商戶凍結增加失敗！",
    "account_failure_0036": "商戶可用到凍結日誌記錄失敗!",
    "account_failure_0037": "商戶可用到凍結失敗!",
    "account_failure_0038": "商戶凍結到可用日誌記錄失敗!",
    "account_failure_0039": "商戶凍結到可用失敗!",
    "account_failure_0040": "平臺可用增加日誌記錄失敗！",
    "account_failure_0041": "平臺可用增加失敗！",
    "account_failure_0042": "該事務執行異常！",
    "account_failure_0043": "該事務已執行失敗！",
    "account_failure_0044": "該事務已執行成功！",
    "account_failure_0045": "該事務正在執行!",
    "account_failure_0046": "處理成功！",
    "account_failure_0047": "回滾操作失敗平臺資產恢復失敗",
    "account_failure_0048": "回滾操作失敗平臺資產恢復日誌記錄失敗",
    "account_failure_0049": "平臺該幣種已存在!",
    "account_failure_0050": "平臺添加新幣種失敗！",
    "account_failure_0051": "平臺該幣種已存在!",
    "account_failure_0053": "交易類型錯誤!",
    "account_failure_0054": "商戶可用增加失敗！",
    "account_failure_0055": "商戶可用增加日誌記錄失敗！",
    "account_failure_0056": "用戶表商戶，平臺收益轉移失敗！",
    "account_failure_0057": "用戶表商戶，平臺收益轉移日誌記錄失敗！",
    "account_failure_0058": "用戶可用到理財失敗！",
    "account_failure_0059": "用戶可用到理財日誌記錄失敗！",
    "account_failure_0060": "理財不足，無法執行！",
    "account_failure_0061": "用戶理財到可用失敗！",
    "account_failure_0062": "用戶理財到可用,日誌記錄失敗！",
    "account_failure_0063": "回滾操作失敗,沒有可操作數據 ！",
    "account_failure_0064": "該幣種暫不可用",
    "account_failure_0065": "無權查看該幣種，請聯系官方客服",
    "account_failure_0071": "用戶鎖倉到賬异常！",
    "account_failure_0072": "用戶充幣記錄不存在或已修改",
    "activitie_end": "活動結束",
    "activity_invalid": "已失效",
    "actual_amount": "實發總量",
    "actual_exchange_amount": "實兌數量",
    "actual_to_account": "實際到賬",
    "addSuccess": "添加成功",
    "add_channel_function": "新增驗證通道功能",
    "add_charge_and_transfer_function": "新增充幣、劃轉入口功能",
    "added_alert_setting_function": "新增預警設置功能",
    "added_day_mode_toggle": "新增日間模式切換功能",
    "address_tag": "標簽",
    "advanced_limit_order": "高級限價委託",
    "advertising": "廣告",
    "advertising_management": "廣告管理",
    "affirm": "確認",
    "affirm_pass": "確認密碼",
    "again_digits6": "請再次輸入新密碼",
    "again_password": "請再次輸入登錄密碼",
    "airdrop_coin": "空投幣種",
    "airdrop_date": "空投時間",
    "airdrop_number": "空投數量",
    "alipay_account": "支付寶帳號",
    "alipay_upload": "*支付寶上傳二維碼方法：打開支付寶首頁>收錢>保存圖片，將存在手機相冊的收款碼上傳即可。",
    "all": "全部",
    "all_buy": "全部買入",
    "all_country": "全部國家",
    "all_order": "全部訂單",
    "all_sell": "全部賣出",
    "already_use": "已啟用",
    "amount_of_payout": "支出金額",
    "announcement_all": "全部公告",
    "announcement_center": "公告中心",
    "announcement_official": "官方公告",
    "annualized_rate": "年化率",
    "appeal_cancel": "申訴判定，訂單取消",
    "appeal_complete": "申訴判定，訂單完成",
    "appeal_dispose_time": "申訴處理時間",
    "appeal_of_time": "申訴提交時間",
    "apply_locked": "申請鎖倉",
    "asset_allocatio": "資產分佈",
    "assets_asset_valuation": "資產評估",
    "assets_can_transfer": "可劃轉",
    "assets_cash_flow": "資金流水",
    "assets_coin_funds_flow": "幣幣資金流水",
    "assets_currency_assets": "幣幣資產",
    "assets_deposit_income_tip": "該收益數值由實時匯率計算，具體收益以發放值為準。",
    "assets_fiat_assets": "OTC資產",
    "assets_fiat_money_flow": "OTC資金流水",
    "assets_flowing_details": "流水詳情",
    "assets_prompt_001": "[請妥善備份秘鑰以防止丟失]",
    "assets_reduced_asset": "資產折合",
    "assets_row_mining": "挂單挖礦",
    "assets_swipe_direction": "劃轉方向",
    "assets_transfer": "劃轉",
    "assets_transfer_coin": "劃轉幣種",
    "assets_transfer_count": "劃轉數量",
    "assets_transfer_currency": "資金劃轉",
    "assets_transfer_dialog_bb": "幣幣賬戶",
    "assets_transfer_dialog_fiat": "法幣賬戶",
    "assets_transfer_dialog_from": "從",
    "assets_transfer_dialog_transfer_to": "劃轉到",
    "assets_transfer_dialog_wallet": "錢包賬戶",
    "assets_transfer_record": "劃轉記錄",
    "assets_transfer_to": "到",
    "assets_wallet": "錢包",
    "assets_wallet_assets": "錢包資產",
    "assets_wallet_assets_transfer_tip": "*只有將錢包資產劃轉到對用賬戶才可進行交易。賬戶間的劃轉不收取手續費。",
    "assets_wallet_funds_flow": "錢包資金流水",
    "at_least_one": "至少開啟一種交易密碼驗證狀態",
    "attention_blacklist": "關注 / 拉黑",
    "attention_to_see": "想要關注我,點擊查看!",
    "audit_result": "審核結果",
    "authenticated": "已認證",
    "authentication": "身份驗證",
    "available_balance": "可用餘額",
    "average_purchase_price": "購買均價",
    "avoid_close_set": "免密設置",
    "back": "返回",
    "balance": "餘額",
    "bank_card": "銀行卡",
    "bank_name": "銀行名稱",
    "bank_num": "銀行卡號",
    "barcode_in_the_box_scan": "將二維碼/條碼放入框內，即可自動掃描",
    "bb_assets": "幣幣資產",
    "bb_history_finished": "完成",
    "bb_time": "時間",
    "bb_used": "啟用",
    "be_usable": "可使用",
    "bear_consequences_your_investment": "我已知曉並自願承擔投資風險，我的認證碼是：",
    "become": "成為",
    "been_authenticated": "您已實名認證",
    "beginner_guide_title": "全球領先的數字資產交易平台",
    "belong_level": "所屬層級",
    "between_and": "至",
    "bill": "賬單",
    "billing_details": "帳單明細",
    "bind_email": "綁定郵箱",
    "bind_google": "谷歌驗證器",
    "bind_google_authenticator": "綁定穀歌驗證器",
    "bind_phone": "綁定手機",
    "branch_name": "支行名稱",
    "bug_order": "買盤",
    "buy": "買入",
    "buy_1": "買",
    "buy_now": "立即購買",
    "buy_quantity": "購買數量",
    "buy_rate": "買手續費",
    "buyer": "買家",
    "buyer_has_paid_attention": "買家已付款注意",
    "buyer_payment": "買家已付款",
    "buyer_phone": "買家手機號",
    "buyer_uid": "買方",
    "calculate": "計算",
    "calculations are for your reference only": "計算結果僅供參考",
    "can_available": "可獲得",
    "canceled": "已取消",
    "cancellations": "撤單",
    "centre": "中",
    "change1": "修改",
    "change_gesture_password": "修改密碼",
    "change_google": "更換谷歌驗證器",
    "change_pay_style": "更換方式",
    "change_phone": "更換手機",
    "charge_after_the_transaction": "成交後收取手續費",
    "charge_out": "手續費必須在",
    "check_the_agree": "同意勾選",
    "china": "中國",
    "choose_picture": "選擇圖片",
    "clearing_time": "結算時間",
    "click_enter_batch_edit_page": "點擊進入批量編輯頁",
    "click_on_the_copy": "點擊複製",
    "clinch_a_deal": "對方必須成交過幾次(0等於不限制)",
    "close": "關閉",
    "closed_successfully": "關閉成功",
    "cloud_mining_pool": "雲端礦池",
    "coin_failure_0001": "幣種不存在",
    "collect": "自選",
    "collection_time": "收款時間",
    "commission": "已獲得傭金折合",
    "common_failure": "失敗!",
    "common_failure_0001": "網路異常",
    "common_problem": "操作說明",
    "common_success": "成功",
    "complete": "已完成",
    "completed": "交易已完成",
    "computer_login": "電腦端登錄",
    "confirm": "確定",
    "confirm_exit": "確認退出？",
    "confirm_password": "請確認登錄密碼",
    "confirm_submission": "確認提交",
    "confirm_the_purchase": "確認購買",
    "confirm_to_give_up": "確認放棄",
    "confirm_to_sell": "確認出售",
    "confirm_upgrade": "確認升級",
    "confirm_vote": "確認投票",
    "confirmation_information": "確認資訊",
    "confrim_delete_warn": "確定刪除預警?",
    "contact_service": "聯繫客服",
    "contact_us": "聯繫我們",
    "contract_Order": "委托",
    "contrct Agree Content": "為保障您的權益，開通合約之前，您需要先仔細閱讀《BitMatrix永續合約協議》",
    "contrct Agree Content footer": "《BitMatrix永續合約協議》",
    "contrct Agree Content header": "為保障您的權益，開通合約之前，您需要先仔細閱讀",
    "contrct_decimal": "小數",
    "convert_into": "摺合",
    "converted_currency": "折算貨幣",
    "copartner-upgrade-failure": "升級失敗",
    "copartner-upgrade-no": "未滿足升級條件, 不能升級",
    "copartner-upgrade-ok": "可以升級",
    "copartner-upgrade-success": "升級成功",
    "copy_content": "已經複製到粘貼板",
    "country": "國家",
    "create_time": "創建時間",
    "cumulative_income:": "累計收益：",
    "cumulative_participation": "累計參加",
    "currency": "幣種",
    "currency_fee": "幣種費率",
    "currency_information": "幣種詳情",
    "currency_name": "幣種名稱",
    "currency_not_found": "貨幣類型不存在",
    "currency_select": "幣種選擇",
    "current_day_return": "活期日返",
    "current_entrust": "當前委托",
    "current_identity": "當前身份",
    "current_polling_date": "本期投票時間",
    "current_status": "當前狀態",
    "currently_available": "當前可用",
    "date": "日期",
    "date_of_birth": "出生日期",
    "day_highs_and_lows": "漲跌幅",
    "day_highs_and_lows1": "漲跌幅",
    "day_income": "天",
    "day_trading": "當日交易",
    "daytime": "日間",
    "ddress_management": "地址管理",
    "deal_amount": "成交總額",
    "deal_done": "已成交",
    "deal_gross": "成交/總量",
    "deal_of_the_week": "本周交易",
    "deal_price": "成交均價",
    "deal_sum": "交易額",
    "deal_the_detail": "訂單明細",
    "deal_volume": "成交金額",
    "deduct_coin": "扣除幣種",
    "default_settlement": "違約結算",
    "defaults": "默認",
    "deposit": "一鍵存幣",
    "deposit_details": "存幣詳情",
    "deposit_income": "存幣收益",
    "deposit_lock": "存幣鎖倉",
    "deposit_lock_details": "存幣鎖倉詳情",
    "deposit_returned_principal": "已返還本金",
    "depth": "深度",
    "detailed_rules": "詳細規則",
    "details": "詳情",
    "digits6": "請輸入6位純數字",
    "direct_market": "直接推廣",
    "direct_reward": "直接獎勵",
    "direction": "方向",
    "discount_Rate": "折扣率",
    "discount_currency_introduction": "幣種簡介",
    "discount_dialog_tip1": "恭喜!您符合",
    "discount_dialog_tip2": "參與條件，並被系統隨機選為幸運用戶，領取資格後將有參與權限！",
    "discount_dialog_tip3": "您已具備",
    "discount_dialog_tip4": "搶兌資格，活動額度有限，請在活動時間內參與搶兌！",
    "discount_dialog_tip5": "放棄資格後不能參加",
    "discount_dialog_tip6": "活動且不可申請參與。",
    "discount_dialog_tip7": "注:領取資格成功將扣除",
    "discount_purchase": "折扣兌換",
    "discounted_or_original_price": "折扣價/原價",
    "dispose_result": "處理結果",
    "disqualification": "放棄資格",
    "divided_earnings": "分成收益",
    "drag": "請按住滑塊兒，拖動到最右邊",
    "each_input": "每次交易驗證交易密碼",
    "edt_selfchoose": "編輯自選",
    "effective": "有效",
    "eligibility": "領取資格",
    "email": "郵箱註冊",
    "email_address_format": "請輸入正確的郵箱",
    "email_code": "郵箱驗證碼",
    "empty_deal_pass": "交易密碼不可為空",
    "end time": "結束時間",
    "end_date": "結束日期",
    "end_time": "結束時間",
    "enter_account": "請輸入手機/郵箱",
    "enter_cause_of_complaint": "請輸入投訴原因",
    "enter_fb_buy_price": "請輸入購買價格",
    "enter_fb_sell_price": "請輸入出售價格",
    "enter_your_password": "請輸入登錄密碼",
    "enter_password": "請輸入密碼",
    "entrust_amount": "委托量",
    "entrust_failure_0001": "網路異常",
    "entrust_failure_0002": "查詢類型錯誤",
    "entrust_failure_0003": "撮合類型不能為空",
    "entrust_failure_0004": "商戶ID不能為空",
    "entrust_failure_0005": "用戶ID不能為空",
    "entrust_failure_0006": "用戶不存在",
    "entrust_failure_0007": "交易對ID不能為空",
    "entrust_failure_0009": "交易對不存在",
    "entrust_failure_0010": "交易密碼不能為空",
    "entrust_failure_0011": "交易密碼錯誤",
    "entrust_failure_0012": "委單類型不能為空",
    "entrust_failure_0013": "委單數量不能為空",
    "entrust_failure_0014": "限價單委託單價不能為空",
    "entrust_failure_0015": "委單ID不能為空",
    "entrust_failure_0016": "委託單不存在",
    "entrust_failure_0017": "該委託單已被刪除",
    "entrust_failure_0018": "該委託單不能被撤單",
    "entrust_failure_0019": "版本號不能為空",
    "entrust_failure_0020": "市價單不能被撤單",
    "entrust_failure_0021": "當前數據已更新",
    "entrust_failure_0022": "當前沒有委託單,不能下市價單",
    "entrust_failure_0023": "交易對被禁用或相關幣種被禁用",
    "entrust_failure_0024": "該交易對未開啟交易",
    "entrust_failure_0025": "委託價格低於最低限價或高於最高限價",
    "entrust_failure_0026": "委託數量低於最低限量或高於最高限量",
    "entrust_failure_0027": "該帳號已被禁止交易，請諮詢客服",
    "entrust_failure_0030": "超過當前交易對單日最高漲幅{}，無法下單",
    "entrust_failure_0031": "超過當前交易對單日最高跌幅{}，無法下單",
    "entrust_msg_match_type_001": "限價單",
    "entrust_msg_match_type_002": "市價單",
    "entrust_msg_status_001": "未成交",
    "entrust_msg_status_002": "部分成交",
    "entrust_msg_status_003": "完全成交",
    "entrust_msg_status_004": "已撤單",
    "entrust_msg_type_001": "賣單",
    "entrust_msg_type_002": "買單",
    "entrusted_price": "委托價",
    "entry_orders": "掛單",
    "equal_principal": "等額本金",
    "error_email": "郵箱錯誤",
    "error_network_anomaly": "網路異常",
    "error_phone": "請輸入正確的手機號",
    "error_unknow": "網路錯誤",
    "event_detail": "活動詳情",
    "event_detail_warn_tip": "*溫馨提示：數字資產為創新資產,具有價格波動較大的特性。請理性判斷,慎重選擇投資決策！",
    "event_start_time": "活動開始時間",
    "every day": "每天",
    "everyday": "每天",
    "exchange": "閃兌",
    "exchange_currency": "兌換幣種",
    "exchange_details": "閃兌明細",
    "exchange_history": "閃兌記錄",
    "exchange_quantity": "兌換數量",
    "exchange_rules": "兌換規則",
    "exchangeable": "可兌換",
    "exclusive_posters": "生成專屬海報",
    "exclusive_posters-Video": "生成海報",
    "exit": "退出",
    "expected_return": "預計收益",
    "expired": "已過期",
    "express_area": "快捷區",
    "face_recognition": "面部識別",
    "failure": "失敗",
    "fb_lookup": "點擊查看",
    "fb_order_number": "訂單編號",
    "feeze_thaw_msg_status_0001": "凍結中",
    "feeze_thaw_msg_status_0002": "已解凍",
    "fill_in_at_least_one": "請至少填寫一項",
    "fill_wrong": "填寫錯誤可能導致資產損失，請仔細核對",
    "filtrate": "篩選",
    "filtrate_content": "篩選支付方式、國家、法幣點這裏!",
    "financial_management_is_cancel": "存幣收益已被取消!請勿重複提交！",
    "financial_management_is_delete": "此理財類型已經被刪除",
    "financial_management_is_disable": "此理財類型已經被禁用",
    "financial_management_not_cancel": "定期投資理財不能取消！",
    "financial_management_number_not_found": "投資數量不能為空",
    "financial_management_record_add_failure": "用戶個人資產不夠，請充幣過後再進行理財投資！",
    "financial_management_record_less_than_min_number": "添加的幣數量小於設置的最小的數量{}",
    "financial_management_type_not_found": "理財記錄資訊顯示失敗,理財類型選擇錯誤！",
    "financial_management_type_not_null": "投資類型不能為空",
    "find_password": "找回密碼",
    "fingerprint": "指紋",
    "fingerprint_verification": "指紋認證",
    "first_bind_phone": "請先綁定手機號",
    "first_period": "首期",
    "following_account": "至以下帳戶",
    "free": "手續費",
    "Takerfree": "Taker手續費",
    "Makerfree": "Maker手續費",
    "freeze": "凍結",
    "freeze_to_be_processed": "申訴凍結，等待處理",
    "fuc_use_discount": "使用{}支付交易手續費*折",
    "futu": "副圖",
    "gathering": "收款",
    "gears": "檔位",
    "generation1": "第1代",
    "gesture_error_limit": "手勢密碼輸入錯誤次數已達上限",
    "gesture_password": "手勢密碼",
    "gesture_unlock": "手勢解鎖",
    "get_code": "獲取驗證碼",
    "goSign": "去註冊",
    "go_change": "去修改",
    "go_login": "請先登錄",
    "go_pay": "去付款",
    "go_setting": "去設置",
    "go_to_pick_up": "前往領取",
    "go_to_receiving": "去收款",
    "go_to_redeem": "前往兌換",
    "google_verification_code": "穀歌驗證碼",
    "grievance_freeze": "申訴凍結",
    "has_been_on": "已上架",
    "have_been_frozen": "已凍結",
    "have_received": "已領取",
    "help_center": "幫助中心",
    "hide_canceled": "隱藏已撤銷",
    "hide_other": "顯示全部掛單",
    "high_frequency_partner": "高頻合夥人",
    "highest_single": "單筆最高",
    "highfrequency_fee_sharing": "高頻手續費分成",
    "hint": "溫馨提示",
    "hint_password": "請輸入密碼",
    "hint_phone_email": "請輸入手機/郵箱",
    "historical_transaction": "歷史交易",
    "history": "歷史",
    "home_page": "首頁",
    "horizontal_version": "橫版盤口",
    "hours": "時",
    "i18n_failure_0001": "系統語言查詢失敗",
    "i18n_failure_0002": "國家字典查詢失敗",
    "iTo_draw_again": "請再次輸入密碼",
    "i_see": "我知道了",
    "i_see1": "知道了",
    "id_card_back_direction_failure": "身份證反面圖像方向錯誤",
    "id_card_front_direction_failure": "身份證正面圖像方向錯誤",
    "id_cord": "證件號",
    "id_type": "證件類型",
    "identification": "識別失敗",
    "identity_authentication": "身份認證",
    "identity_card": "身份證",
    "if_at": "如果在",
    "ignore": "忽略",
    "immediately_invited": "立即邀請",
    "immediately_join": "立即加入",
    "in_trading": "交易中",
    "income": "收益",
    "income_currency_type": "存幣類型",
    "income_deposit_principal": "存幣本金",
    "incomeing": "收益中",
    "increment quantity": "輸入數量不能超過最多增加數量",
    "indirect_promotion": "間接推廣",
    "indirect_reward": "間接獎勵",
    "individual_selling_tips": "不能出售給自己的訂單",
    "input_Branch_address": "請輸入支行地址",
    "input_address_labels": "請輸入標簽",
    "input_alipay_account": "請輸入支付寶帳號",
    "input_bank_card_number": "請輸入銀行卡號",
    "input_bank_name": "請輸入銀行名稱",
    "input_buyer_uid": "請輸入買方UID",
    "input_currency_referred": "請輸入幣種的簡稱",
    "input_deposit_amount": "請輸入存幣數量",
    "input_email": "請輸入郵箱號",
    "input_email_code": "請輸入郵箱驗證碼",
    "input_id_cord": "請輸入證件號",
    "input_lock_amount": "請輸入鎖倉數量",
    "input_mention_money_address": "請輸入提幣地址",
    "input_money": "請輸入金額",
    "input_name": "請輸入姓名",
    "input_newphone_code": "請輸入新手機號",
    "input_nike_name": "請輸入昵稱",
    "input_old_google_code": "請輸入舊的穀歌驗證碼",
    "input_old_pass": "請輸入舊密碼",
    "input_paypal_acctount": "請輸入paypal帳戶",
    "input_phone": "請輸入手機號碼",
    "input_phone_code": "請輸入驗證碼",
    "input_price": "請輸入價格",
    "input_quantity": "請輸入數量",
    "input_remark": "請輸入備註",
    "input_service_charge": "請輸入手續費",
    "input_trading_volume": "請輸入交易額",
    "input_transaction_password": "請輸入資金密碼",
    "input_wechat_account": "請輸入微信帳號",
    "input_wire_transfer": "請輸入電匯地址",
    "inputs_numbers_greater_can_number": "輸入數量大於可劃轉數量",
    "insufficient_light": "光線不足",
    "interest": "利息",
    "invitation_details": "邀請獎勵詳情",
    "invitation_register_ad_not_found": "app分享邀請註冊廣告暫時不存在",
    "invitation_register_ad_tatle_not_found": "app分享邀請註冊廣告標題暫時不存在",
    "invitation_register_coin_not_found": "app分享邀請註冊獎勵幣種暫時不存在",
    "invitation_register_logo_not_found": "app分享邀請註冊logo暫時不存在",
    "invitation_register_not_found": "分享邀請註冊連接暫時不存在",
    "invite_code": "邀請碼（選填）",
    "invite_code1": "邀請碼",
    "invite_commission": "傭金",
    "invite_gift": "邀請有禮",
    "invite_reward": "邀請獎勵",
    "invited_UID": "被邀請UID",
    "invitees_are_available": "邀請人可獲得",
    "inviter-is-user": "回填的邀請人不能是本人",
    "inviter-not-exist": "回填的邀請人不存在",
    "inviter_no_null": "推薦人不能為空",
    "ios_24小时排行榜": "24小時排行榜",
    "ios_OTC交易须知": "OTC交易須知",
    "ios_PAYPAL": "PAYPAL",
    "ios_otc_order_prompt_001": "分鐘內未向你付款並點擊'確認付款'按鈕，系統將會在倒計時結束後自動取消此訂單",
    "ios_otc_order_prompt_002": "分鐘內未向賣家付款並點擊'確認付款'按鈕，系統將會在倒計時結束後自動取消此訂單",
    "ios_万": "萬",
    "ios_上传身份证反面": "上傳身份證反面",
    "ios_上传身份证正面": "上傳身份證正面",
    "ios_两次密碼不一致请再次输入密碼": "兩次密碼不一致請再次輸入密碼",
    "ios_个确认大约需要": "個確認大約需要",
    "ios_中国大陆": "中國大陸",
    "ios_中级合伙人": "中級合夥人",
    "ios_主版块": "主版塊",
    "ios_之间": "之間",
    "ios_买单": "買單",
    "ios_买方必须成交过几次(0等于不限制)": "買方必須成交過幾次(0等於不限制)",
    "ios_二维码/条形码": "二維碼/條碼",
    "ios_付款": "付款",
    "ios_充币地址": "充幣地址",
    "ios_先设置交易密码" : "先设置交易密码",
    "ios_先设置交易密碼": "先設置交易密碼",
    "ios_关闭照明": "關閉照明",
    "ios_分享失败": "分享失敗",
    "ios_分享成功": "分享成功",
    "ios_初级合伙人": "初級合夥人",
    "ios_卖单": "賣單",
    "ios_卖家已付款提示": "賣家已付款提示",
    "ios_卖方必须成交过几次(0等于不限制)": "賣方必須成交過幾次(0等於不限制)",
    "ios_去解绑": "去解綁",
    "ios_发布成功": "發佈成功",
    "ios_发送成功": "發送成功",
    "ios_各种费率说明": "各種費率說明",
    "ios_团队人数不足": "團隊人數不足",
    "ios_复制QQ号": "複製QQ號",
    "ios_复制地址": "複製地址",
    "ios_如果你已完成线下付款，请点击下方已付款按钮。如果未完成付款，却点击已付款将会受到严厉惩罚，甚至永久冻结账户！": "如果你已完成線下付款，請點擊下方已付款按鈕。如果未完成付款，卻點擊已付款將會受到嚴厲懲罰，甚至永久凍結帳戶！",
    "ios_客户端或在线钱包将您需要充值的": "客戶端或在線錢包將您需要充值的",
    "ios_客户端或在线钱包将您需要充幣的": "客戶端或線上錢包將您需要充幣的",
    "ios_客服": "客服",
    "ios_密碼设置成功！": "密碼設置成功！",
    "ios_将该地址设置为认证地址": "將該地址設置為認證地址",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充值，不影响到账。\n3、最小充值金额": "小時時間，請耐心等待。\n2、同一個地址可多次充幣，不影響到賬。\n3、最小充幣金額",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充幣，不影响到账。\n3、最小充幣金额": "小時時間，請耐心等待。\n2、同一個地址可多次充幣，不影響到賬。\n3、最小充幣金額",
    "ios_已付款": "已付款",
    "ios_币币": "幣幣",
    "ios_常见问题": "常見問題",
    "ios_开启照明": "開啟照明",
    "ios_意见反馈": "意見回饋",
    "ios_成交": "成交",
    "ios_我的": "我的",
    "ios_手机号错误": "手機號錯誤",
    "ios_提交成功，等待认证": "提交成功，等待認證",
    "ios_提币数量不能大于手续费": "提幣數量不能大於手續費",
    "ios_提币数量减去手续费后不能": "提幣數量減去手續費後不能",
    "ios_收款二维码": "收款二维码",
    "ios_收藏成功": "收藏成功",
    "ios_数目发送到该地址。发送完成后，系统会自动在此交易获得": "數目發送到該地址。發送完成後，系統會自動在此交易獲得",
    "ios_暂无网络": "暫無網路",
    "ios_最多可锁": "最多可鎖",
    "ios_最大限额": "最大限額",
    "ios_最小限额": "最小限額",
    "ios_未解锁": "未解鎖",
    "ios_没有中国大陆身份证的用户请通过网站版高级认证": "沒有大陸身份證的用戶請通過網站版高級認證",
    "ios_法币": "OTC",
    "ios_注册表示同意": "註冊表同意",
    "ios_注册表示同意用户协议": "註冊表示同意用戶協議",
    "ios_登录过期，请重新登录": "登錄過期，請重新登錄",
    "ios_直接推荐人uid": "直接推薦人uid",
    "ios_直推人数不足": "直推人數不足",
    "ios_绑定成功": "綁定成功",
    "ios_请上传您的身份证": "請上傳您的身份證",
    "ios_请上传身份证反面照": "請上傳身份證反面照",
    "ios_请上传身份证正面照": "請上傳身份證正面照",
    "ios_请先绑定收款方式": "請先綁定收款方式",
    "ios_请先设置支付方式": "請先設置支付方式",
    "ios_请输入价格和数量": "請輸入價格和數量",
    "ios_请输入新邮箱": "請輸入新郵箱",
    "ios_请输入正确的账号格式": "請輸入正確的帳號格式",
    "ios_请输入身份证号": "請輸入身份證號",
    "ios_请输入限额": "請輸入限額",
    "ios_请通过": "請通過",
    "ios_资产": "資產",
    "ios_超时": "超時",
    "ios_身份证号": "身份證號",
    "ios_退出成功": "退出成功",
    "ios_邀请人可获得 * 的佣金": "邀請人可獲得 * 的傭金",
    "ios_锁仓数量不能为空": "鎖倉數量不能為空",
    "ios_间接接推荐人uid": "間接接推薦人uid",
    "ios_隐藏其他挂单": "隱藏其他掛單",
    "ios_高级合伙人": "高級合夥人",
    "is_bind": "已綁定",
    "is_delete_select": "是否刪除自選",
    "issued": "已發放",
    "issued_to_lock": "已發放到鎖倉",
    "k_close": "收",
    "k_max": "高",
    "k_min": "低",
    "k_num": "量",
    "k_open": "開",
    "kline_target": "指標",
    "language": "語言",
    "lead_content": "訂單查詢,發佈廣告,收款設置,關注/拉黑都移到這裏啦！",
    "least_four": "請連接至少4個點",
    "less-than-min": "低於最小鎖倉額度限制",
    "lf there are pending": "如果存在倉位方向相反的限價掛單，將會在全平前被撤單。",
    "limit": "限制設置",
    "limit_orderAA": "限價委託",
    "limit_set": "限制設置(選填)",
    "limited_price": "限價",
    "liquidation": "強平",
    "liquidation AMT": "強平數量",
    "liquidation details": "強平詳情",
    "loading": "加載中",
    "lock-expire": "鎖倉已過期",
    "lock_amount": "鎖倉數量",
    "lock_duration": "鎖倉時長",
    "lock_person": "鎖倉人",
    "lock_record": "鎖倉記錄",
    "lock_remaining": "鎖倉餘量",
    "lock_reward": "鎖倉獎勵",
    "lock_time": "鎖倉時間",
    "locked": "鎖倉",
    "locked_closed": "已結清",
    "locked_insufficient_balance": "餘額不足",
    "locked_settled": "已結算",
    "locked_unsettlement": "未結算",
    "locked_warehouse": "鎖倉中",
    "log_in": "登錄",
    "login": "登錄",
    "login_expired": "登錄失效，請重新登錄。",
    "login_forget_pwd": "忘記密碼?",
    "login_name_txt": "用戶名",
    "login_pass": "登錄密碼",
    "logion_welcome_txt": "歡迎登錄",
    "mail": "郵箱",
    "mailbox": "郵箱驗證",
    "mailbox_bind_success": "郵箱綁定成功",
    "mailbox_new": "郵箱",
    "main_picture": "主圖",
    "manage_reward_details": "管理獎勵詳情",
    "management_reward": "管理獎勵",
    "management_share": "管理分成",
    "mandarin": "請使用普通話完整朗讀數字",
    "mandarin_reading_content": "请使用标准语言读以下内容",
    "manual_recharge_msg_status_0001": "等待充幣",
    "manual_recharge_msg_status_0002": "審核通過",
    "manual_recharge_msg_status_0003": "充幣成功",
    "manual_recharge_msg_type_0001": "普通充幣",
    "manual_recharge_msg_type_0002": "資金處理",
    "manual_recharge_msg_type_0003": "活動贈送",
    "margin": "保證金",
    "market_failure_00001": "獲取首頁行情失敗",
    "market_failure_00002": "商戶ID不存在",
    "market_failure_00003": "商戶下沒有可用的交易區",
    "market_failure_00004": "獲得幣幣交易行情失敗",
    "market_failure_00005": "交易對名稱不能為空",
    "market_failure_00006": "商戶下沒有可用的板塊資訊",
    "market_failure_00007": "獲取交易對資訊失敗",
    "market_failure_00008": "獲取K線數據失敗",
    "market_failure_00009": "獲取深度數據失敗",
    "market_failure_00010": "請求參數錯誤",
    "market_failure_00011": "無效的交易對",
    "market_low_price": "市場最高收購價",
    "market_order": "市價委託",
    "market_price": "市價",
    "market_sell_price": "市場最低出售價",
    "market_switch_moved_here": "橫/豎盤口切換移到這裏了",
    "match": "匹配",
    "match_the_number_of": "匹配數量",
    "max_lock_volume": "最大鎖倉量",
    "maximum_30_characters": "最多30個字符",
    "maximum_coins": "提幣數量最大為",
    "maximum_withdrawal": "單筆最大提幣",
    "mention_a_currency": "提幣數量",
    "min_lock_volume": "最小鎖倉量",
    "min_recharge_amount": "小額未上賬即充值成功未上賬，累計充幣數量達到最小數量{}*將自動上賬",
    "minhour": "分時",
    "minimum_coins": "提幣數量最小為",
    "minimum_purchase_quantity": "購買數量大於該單剩餘數量{}",
    "minimum_quantity_sold": "出售數量大於該單剩餘數量{}",
    "minutes": "分",
    "mobile phone": "手機號",
    "money": "金額",
    "more": "更多",
    "more Or than": "多倉：面值*張數*平倉均價-面值*張數*開倉均價\n\n空倉：面值*張數*開倉均價 - 面值*張數*平倉均價",
    "more-than-max": "超過最大鎖倉額度限制",
    "more11": "更多",
    "more_can_save": "最多可存入",
    "more_home": "更多",
    "msg_camera_framework_bug": "請開啟相機許可權",
    "must_retain_one": "手機、谷歌、郵箱必須保留壹種",
    "my_income": "我的收益",
    "my_prompt_001": "*手機號修改成功後需要使用新的手機號進行登錄。",
    "my_prompt_002": "*溫馨提示：請務必使用您本人的實名帳戶，被啟動的支付方式將在OTC交易時向買方展示，可設置5種但最多啟動三種。",
    "my_prompt_003": "*郵箱修改成功後需要使用新的郵箱進行登錄。",
    "my_vote": "我的投票",
    "name": "姓名",
    "name_empty": "姓名不能為空",
    "nationality": "國籍",
    "need_me_record_video": "需本人錄製視頻",
    "never_enter": "永不輸入交易密碼",
    "new_address": "新增地址",
    "new_pass": "新密碼",
    "new_pass_empty": "請輸入新密碼",
    "new_pass_minimum": "密碼不能小於6位",
    "new_report_statistics": "新增報表統計",
    "newest_versions": "当前版本已是最新版本！",
    "next": "下一步",
    "nick_name": "昵稱",
    "nickname_des_txt": "暱稱僅支持中英文、數字，長度限制15個字符",
    "nighttime": "夜間",
    "ninety_days": "近90天",
    "no-lock-detail": "沒有鎖倉資訊",
    "no_Senior_certification": "請先進行高級認證",
    "no_data": "暫無數據",
    "no_flash": "當前設備沒有閃光燈，無法開啟照明功能",
    "no_more_data": "暫無更多",
    "no_photo_taking": "您的設備不支持拍照",
    "no_price": "價格不能為空",
    "no_quantity": "請輸入正確的數量",
    "no_realname_auth": "請先進行實名認證",
    "no_use": "未啟用",
    "none": "無",
    "not_bound": "未綁定",
    "not_frequency_partner": "您當前還不是合夥人",
    "not_redemption": "無兌換資格",
    "not_started": "未開始",
    "not_to_issue": "未發放",
    "not_trading": "該幣種當前無法交易",
    "notpass": "審核未通過",
    "null_address_tag": "地址標簽不能為空",
    "null_code": "驗證碼不能為空",
    "null_email": "郵箱不能為空",
    "null_password": "密碼不能為空",
    "null_phone": "手機號不能為空",
    "number": "個",
    "number_empty": "數量不能為0",
    "number_of_arrivals": "到賬數量",
    "number_of_issues": "發放總量",
    "number_of_withdrawal": "每日提幣次數",
    "obtained": "下架",
    "occlusion": "遮擋臉部",
    "occupy": "佔用",
    "official_community": "官方社區",
    "official_verification_channel": "驗證通道",
    "old_pass": "舊密碼",
    "old_pass_empty": "請輸入原密碼",
    "on_sale": "出售",
    "online_service": "線上客服",
    "open XKD perpetual swap": "開通BitMatrix永續合約",
    "open perpetual swap": "開通永續合約",
    "open_app": "打開應用",
    "open_it": "開啟",
    "open_successfully": "開啟成功",
    "open_touchID": "是否開啟TouchID?",
    "opposite_side_uid": "對方",
    "option": "選項",
    "optional": "自選",
    "optional_area": "自選區",
    "options": "選擇方式",
    "order": "委單",
    "order time": "按委託時間",
    "order time1": "委託時間",
    "order_a_complaint": "訂單申訴",
    "order_details": "訂單詳情",
    "order_form": "訂單",
    "order_history": "歷史訂單",
    "order_inquiry": "訂單查詢",
    "order_number": "訂單號",
    "order_time": "下單時間",
    "ordinary_partner": "普通合夥人",
    "otc_account": "帳戶",
    "otc_confirm_Put_the_coin": "確認您已收到對方匯款!(警告：勿在確認收款前點擊確認如遇糾紛可聯繫客服申訴)",
    "otc_confirm_the_payment": "確認付款",
    "otc_confirm_the_receipt": "確認收款",
    "otc_failure_0001": "帳戶被禁用，請儘快聯繫客服",
    "otc_failure_0002": "帳戶未通過高級認證，不能進行OTC操作",
    "otc_failure_0003": "帳戶交易密碼不正確",
    "otc_failure_0004": "訂單ID不合法",
    "otc_failure_0006": "更改狀態失敗",
    "otc_failure_0009": "該掛單類型不存在",
    "otc_failure_0010": "撤銷失敗",
    "otc_failure_0011": "該掛單當前已完成或已撤銷",
    "otc_failure_0012": "當前用戶不是OTC商家，無權使用該功能",
    "otc_failure_0013": "解凍失敗,撤銷失敗",
    "otc_failure_0015": "沒有該掛單類型",
    "otc_failure_0016": "掛單數量不能小於等於0",
    "otc_failure_0017": "交易價格不能小於等於0",
    "otc_failure_0018": "單筆最小限額不能小於0",
    "otc_failure_0019": "單筆最大限額不能小於單筆最小限額",
    "otc_failure_0020": "不支持該法幣",
    "otc_failure_0021": "該法幣當前不可用",
    "otc_failure_0022": "商戶幣種ID有誤",
    "otc_failure_0023": "單筆最小限額不能小於該幣種最小限額{}",
    "otc_failure_0024": "單筆最大限額不能大於該幣種最大限額{}",
    "otc_failure_0025": "掛單數量小數位大於{}",
    "otc_failure_0026": "掛單總金額不能小於起訂限額{}",
    "otc_failure_0027": "掛單總金額不能大於用戶限額{}",
    "otc_failure_0028": "掛單總金額不能大於商家限額{}",
    "otc_failure_0029": "價格不能小於{}",
    "otc_failure_0030": "價格不能大於{}",
    "otc_failure_0031": "當前用戶未綁定支付帳戶",
    "otc_failure_0032": "凍結失敗，掛單失敗",
    "otc_failure_0033": "同時處理最大訂單數不能小於0",
    "otc_failure_0034": "買家必須成交次數不能小於0",
    "otc_failure_0035": "該用戶已認證商家或者正在審核",
    "otc_failure_0036": "申請人OTC成功交易次數不足",
    "otc_failure_0037": "解凍失敗,撤銷失敗",
    "otc_failure_0038": "申請人當前帳戶{}可用餘額不足｛｝",
    "otc_failure_0039": "無當前幣種",
    "otc_failure_0040": "當前幣種不可用",
    "otc_failure_0041": "提交成功，請等待審核",
    "otc_failure_0042": "對不起，該用戶當前正處於待審核狀態，不能重複申請",
    "otc_failure_0043": "沒有該交易類型",
    "otc_failure_0044": "禁止自買自賣",
    "otc_failure_0046": "此掛單同時處理最大訂單數{}已達上限，請稍後重試",
    "otc_failure_0047": "買家必須成交過{}次",
    "otc_failure_0048": "買入數量不能小於等於0",
    "otc_failure_0049": "買入數量不能大於該掛單當前剩餘數量",
    "otc_failure_0050": "掛單ID有誤",
    "otc_failure_0051": "買入總金額不能小於幣種交易最小限額{}",
    "otc_failure_0052": "買入金額不能大於幣種交易最大限額{}",
    "otc_failure_0053": "當前掛單類型不是賣出",
    "otc_failure_0054": "當前掛單狀態不正確",
    "otc_failure_0055": "買入金額不能小於摘單單筆最小限額{}",
    "otc_failure_0056": "買入金額不能大於摘單單筆最大限額{}",
    "otc_failure_0057": "沒有該狀態",
    "otc_failure_0058": "更改掛單記錄數不正確",
    "otc_failure_0059": "凍結失敗，摘單失敗",
    "otc_failure_0060": "請在支付設置中綁定商家指定類型的收款帳戶！",
    "otc_failure_0061": "賣家必須成交過{}次",
    "otc_failure_0062": "賣出數量不能小於等於0",
    "otc_failure_0063": "賣出數量不能大於該掛單當前剩餘數量",
    "otc_failure_0064": "當前掛單類型不是買入",
    "otc_failure_0065": "賣出總金額不能小於幣種交易最小限額{}",
    "otc_failure_0066": "賣出金額不能大於幣種交易最大限額{}",
    "otc_failure_0067": "賣出金額不能小於摘單單筆最小限額{}",
    "otc_failure_0068": "賣出金額不能大於摘單單筆最大限額{}",
    "otc_failure_0069": "支付帳戶ID有誤",
    "otc_failure_0070": "確認付款失敗",
    "otc_failure_0073": "訂單更改狀態失敗",
    "otc_failure_0074": "轉幣失敗，確認收款失敗",
    "otc_failure_0075": "支付方式不能為空",
    "otc_failure_0076": "短信發送失敗{}",
    "otc_failure_0077": "該交易幣種不存在",
    "otc_failure_0078": "該法幣不存在",
    "otc_failure_0079": "訂單狀態更改失敗",
    "otc_failure_0080": "該交易幣種禁用",
    "otc_failure_0081": "該法幣禁用",
    "otc_failure_0082": "該委託單不存在",
    "otc_failure_0083": "OTC當前未設置交易法幣",
    "otc_failure_0084": "OTC當前未設置交易幣種",
    "otc_failure_0085": "掛單剩餘數量不足",
    "otc_failure_0090": "該賬號已被禁止交易OTC，請咨詢客服",
    "otc_failure_0091": "普通用戶不能進行OTC掛單，請先申請爲商家",
    "otc_failure_0092": "當前訂單收款未超時，買方不能申訴",
    "otc_failure_0093": "該訂單已申訴，請聯繫客服",
    "otc_failure_0094": "根據您註冊所在地的相關規定，無法進行此操作",
    "otc_failure_0095": "此訂單暫不可交易",
    "otc_failure_0096": "賣方帳戶資產異常，暫時不能進行該操作",
    "otc_failure_0097": "當日OTC取消次數已超限，禁止交易",
    "otc_failure_0098": "您當前有未完成訂單，請完成訂單後繼續下單。",
    "otc_margin_certification": "保證金認證",
    "otc_mine_account_paid": "我已付款",
    "otc_order_prompt_003": "如果買家在規定時間內未向你付款並點擊'確認付款'按鈕，系統將會在倒計時結束後自動取消此訂單",
    "otc_picture_no_null": "請至少上傳壹張圖片",
    "otc_prompt_001": "1、訂單支付有效期為30分鐘，請及時支付並點擊(我已付款)按鈕。\n2、賣家幣由系統鎖定託管，請放心下單。",
    "otc_rate": "費率",
    "otc_report_order": "成交訂單",
    "otc_success_0001": "撤銷成功",
    "otc_success_0002": "出售成功",
    "otc_success_0003": "買入成功",
    "otc_success_0004": "確認付款成功",
    "otc_success_0005": "確認收款成功，訂單結束",
    "otc_success_0006": "申訴成功",
    "otc_waiting_for_confirmation": "等待賣家確認",
    "other_settings": "其他設置",
    "overtime_pay": "超時未支付，訂單取消",
    "param-error": "參數錯誤",
    "partner": "合夥人",
    "partner_commission": "合夥人可獲得下級的手續費返傭",
    "partner_commission_fee": "手續費傭金",
    "partner_current_level": "當前等級",
    "partner_get_all_subordinate": "可以拿所有下屬",
    "partner_management": "合夥人管理",
    "partner_need_pay": "需要支付",
    "partner_need_recommend": "需要直推",
    "partner_recommended": "已直推",
    "partner_start_and_end_time": "起止時間",
    "partner_team": "團隊",
    "partner_team_member": "需要團隊人數達到",
    "partner_upgrade_immediately": "立即升級",
    "pass_muster": "若不符合要求，點此",
    "passed": "已通過",
    "passport": "護照",
    "password_disagree": "兩次密碼不一致",
    "password_is_different": "密碼輸入不一致",
    "password_text": "密碼",
    "password_wrong": "設置8-20位登錄密碼",
    "past_activities": "往期活動",
    "pause_recharge": "該幣種不可充幣",
    "pause_withdraw": "該幣種不可提幣",
    "pay_number": "支付數量",
    "pay_wey": "支付方式",
    "payee": "收款人",
    "payfor_type": "付款方式",
    "payment": "支付",
    "payment_amount": "付款金額",
    "payment_currency": "支付幣種",
    "payment_has_been": "(我已付款)",
    "payment_term": "收款方式",
    "paypal_account": "paypal帳號",
    "pending_review": "待審核",
    "people": "人",
    "period": "期",
    "personal_detail": "個人資料",
    "personal_purchase_tips": "不能購買自己的訂單",
    "phone": "手機",
    "phone_code": "手機驗證碼",
    "phone_register": "手機註冊",
    "phone_verification": "短信驗證",
    "phone_verify": "手機驗證",
    "photo_album": "相冊",
    "please_enter": "請輸入",
    "please_enter_abbreviation_or_letter": "請輸入搜索內容",
    "please_input_content": "請輸入內容",
    "please_pay": "請付款",
    "please_verification": "請驗證",
    "pop_Date": "日期",
    "pop_reset": "重置",
    "position at market price?": "倉位按市價進行全部平倉嗎?",
    "post_only": "只做Maker（Post only）",
    "price": "價格",
    "price_cannot_be_less": "價格不能小於{}",
    "price_cannot_be_more": "價格不能大於{}",
    "price_empty": "價格不能為0",
    "principal": "本金",
    "promote_links": "推廣鏈接",
    "promotion_statistical": "推廣統計",
    "prtner_invalid": "失效",
    "publish_a_trade_order": "發佈交易訂單",
    "purchase": "購買",
    "push": "PUSH資產",
    "push_maximum_volume": "PUSH數量大於可用餘額{}",
    "push_minimum_trading_volume": "PUSH數量小於最小交易量{}",
    "quantity": "數量",
    "quantity_deal": "數量/成交",
    "quota": "限額",
    "range": "範圍",
    "real_income": "實發收益",
    "real_name_authentication": "實名認證",
    "reason": "原因",
    "rebate_amount": "返傭金額",
    "rebate_record": "返傭記錄",
    "rebate_reward": "返佣獎勵",
    "receiving_set": "收款設置",
    "receiving_set_title": "請先設置收款方式",
    "recently_deal1": "最近成交",
    "recharge_notice": "充幣須知\n1、請通過{}客戶端或在線錢包將您需要充幣的{}數目發送到該地址。發送完成後，系統會自動在此交易獲得{}個確認後將該筆虛擬幣充幣到您在本站的賬戶，請耐心等待。\n2、同壹個地址可多次充幣，不影響到賬。\n3、最小充幣金額{}。",
    "recharge_source": "充幣來源",
    "recharge_withdraw_failure_0001": "網路異常",
    "recharge_withdraw_failure_0002": "交易密碼不能為空",
    "recharge_withdraw_failure_0003": "驗證碼不能為空",
    "recharge_withdraw_failure_0004": "商戶ID不能為空",
    "recharge_withdraw_failure_0005": "用戶ID不能為空",
    "recharge_withdraw_failure_0006": "用戶不存在",
    "recharge_withdraw_failure_0007": "幣種ID不能為空",
    "recharge_withdraw_failure_0009": "幣種不存在",
    "recharge_withdraw_failure_0010": "穀歌驗證碼不能為空",
    "recharge_withdraw_failure_0011": "交易密碼錯誤",
    "recharge_withdraw_failure_0012": "充幣類型不能為空",
    "recharge_withdraw_failure_0013": "充幣數量不能為空",
    "recharge_withdraw_failure_0014": "充幣備註不能為空",
    "recharge_withdraw_failure_0015": "充幣ID不能為空",
    "recharge_withdraw_failure_0016": "充幣單不存在",
    "recharge_withdraw_failure_0017": "只有等待充幣的才能被刪除",
    "recharge_withdraw_failure_0018": "只有等待充幣的才能審核",
    "recharge_withdraw_failure_0019": "只有審核通過的才能發放凍結",
    "recharge_withdraw_failure_0020": "版本號不能為空",
    "recharge_withdraw_failure_0021": "參數錯誤",
    "recharge_withdraw_failure_0022": "當前數據已更新",
    "recharge_withdraw_failure_0023": "ID不能為空",
    "recharge_withdraw_failure_0024": "只有鎖定狀態才能審核",
    "recharge_withdraw_failure_0025": "只有等待提幣狀態才能鎖定",
    "recharge_withdraw_failure_0026": "只有等待提幣或鎖定狀態時才能取消",
    "recharge_withdraw_failure_0027": "凍結數量不能為空",
    "recharge_withdraw_failure_0028": "凍結原因不能為空",
    "recharge_withdraw_failure_0029": "解凍備註不能為空",
    "recharge_withdraw_failure_0030": "提幣數量不能為空",
    "recharge_withdraw_failure_0031": "提幣地址不能為空",
    "recharge_withdraw_failure_0032": "手續費不能為空",
    "recharge_withdraw_failure_0033": "手續費異常",
    "recharge_withdraw_failure_0034": "數據不存在",
    "recharge_withdraw_failure_0035": "該資產單不能解凍",
    "recharge_withdraw_failure_0036": "提幣地址不正確",
    "recharge_withdraw_failure_0041": "該帳號已被禁止提幣，請諮詢客服",
    "recharge_withdraw_failure_0042": "提幣次數已達當日上限",
    "recharge_withdraw_failure_0043": "提幣總量超過當日上限",
    "recharge_withdraw_failure_0044": "獲取VIP等級資訊異常",
    "recharge_withdraw_failure_0045": "獲取商戶幣種資訊異常",
    "recharge_withdraw_failure_0046": "重置密碼之後24小時之內禁止提幣!",
    "recharge_withdraw_failure_0048": "獲取單筆提幣最大值異常",
    "recharge_withdraw_failure_0049": "獲取單筆提幣最小值異常",
    "recharge_withdraw_failure_0050": "輸入數量必須小於最大限額",
    "recharge_withdraw_failure_0051": "輸入數量必須大於最小限額",
    "recharge_withdraw_msg_status_0001": "等待提幣",
    "recharge_withdraw_msg_status_0002": "發送中",
    "recharge_withdraw_msg_status_0003": "提幣成功",
    "recharge_withdraw_msg_status_0004": "取消",
    "recharge_withdraw_msg_status_0005": "確認中",
    "recharge_withdraw_msg_status_0006": "充幣成功",
    "recharge_withdraw_msg_status_0007": "已審核",
    "recharge_withdraw_msg_type_0001": "充幣",
    "recharge_withdraw_msg_type_0002": "提幣",
    "recharge_withdraw_msg_withdraw_type_0001": "平臺互轉",
    "recharge_withdraw_msg_withdraw_type_0002": "外部提幣",
    "record": "記錄",
    "record_the_video": "點擊錄製視頻",
    "recording": "重新錄製",
    "redeem_now": "立即兌換",
    "redeemed": "已兌換",
    "redeeming": "兌換中",
    "redemption_amount": "兌入數量",
    "redemption_time": "兌換時間",
    "referrer": "推薦人",
    "register": "註冊",
    "registered_successfully": "註冊成功",
    "registration_award_details": "註冊獎勵詳情",
    "registration_incentives": "註冊獎勵",
    "registration_time": "註冊時間",
    "regular": "定期",
    "regular_return": "定期月返",
    "release": "發佈",
    "release_operation": "發佈訂單,點這裏可操作!",
    "release_the_order": "發佈訂單",
    "release_time": "發放時間",
    "remark": "備註",
    "remember_state": "記住登錄狀態",
    "repeal": "撤銷",
    "reset": "重設",
    "reset_login_password": "修改登錄密碼",
    "reset_pass": "重置密碼",
    "residue": "剩餘",
    "retrieve_the_deposit": "取回存幣",
    "return_amount": "返息數量",
    "return_method": "返息方式",
    "return_the_principal": "返還本金",
    "revenue_details": "收益詳情",
    "revocation_all": "撤銷全部",
    "reward_amount": "獎勵數量",
    "reward_locked": "獎勵鎖倉",
    "reward_status": "獎勵狀態",
    "reward_type": "獎勵類型",
    "rewards_record": "獎勵記錄",
    "right_now_register": "新用戶註冊",
    "roll_out": "轉出",
    "save": "保存",
    "save_coin": "存入",
    "save_coin_count": "存幣數量",
    "save_coin_days": "存幣天數",
    "save_coin_duration": "存幣時長",
    "save_currency": "存幣",
    "save_money_amount": "存幣總量",
    "save_successfully": "保存成功",
    "save_the_image": "下載圖片",
    "scan_QR_code": "請不要掃描來源不明的二維碼",
    "scan_result": "掃描結果",
    "search": "搜索",
    "search_coin_placeholder": "搜索您感興趣的幣種",
    "search_keyword": "搜索關鍵詞",
    "search_name": "搜索名稱",
    "search_result": "搜索結果",
    "search_trade_pair_placeholder": "搜索您感興趣的交易對",
    "security_center": "安全中心",
    "security_level": "安全級別",
    "select_date_of_borth": "請選擇出生日期",
    "select_delete_warn": "請選擇要刪除的預警",
    "sell": "賣出",
    "sell_1": "賣",
    "sell_order": "賣盤",
    "sell_quantity": "出售數量",
    "sell_rate": "賣手續費",
    "seller": "賣家",
    "seller_phone": "賣家手機號",
    "send": "發送",
    "senior_certification": "高級認證",
    "senior_certification1": "高級認證",
    "service": "客服QQ：",
    "service_charge": "手續費",
    "session_timeout": "登錄過期，請重新登錄",
    "set_Password": "修改",
    "set_gesture_pass": "請設置手勢密碼",
    "set_login_password": "設置登錄密碼",
    "setting": "設置",
    "setting_alipay": "設置支付寶",
    "setting_bank_card": "設置銀行卡",
    "setting_message_warn_tip": "*開啟保存生效之後，則可收到預警消息提示，關閉則不提示。",
    "setting_paypal": "設置paypal",
    "setting_paypassword": "請先設置交易密碼",
    "setting_wechat": "設置微信",
    "setting_western_union": "設置西聯匯款",
    "share_invitation_link": "分享邀請鏈接",
    "share_link": "分享鏈接給您好友",
    "share_to_do": "分享至",
    "shift_to": "轉入",
    "single_least": "單筆最小限額小於{}",
    "single_minimum": "單筆最低",
    "single_more": "單筆最小限額大於{}",
    "skip": "跳過",
    "small_exchange": "小額兌換",
    "small_exchange_tip": "*溫馨提示:24小時內只能兌換一次估值小於{}{}資產，下架交易的幣種暫不可兌換，暫定兌換手續費為{}",
    "small_exchange_tip-1": "*溫馨提示:市場價格實時變動，以實際價格為準。下架交易的幣種暫不可兌換，暫定兌換手續費為{0}%",
    "small_exchange_valuation": "估值",
    "small_exchange_valuation-1": "BTC估值",
    "smallest_unit_price": "PUSH單價小於最小單價{}",
    "so adjusted to": "的整數倍，將自動調整為",
    "sold_out": "已下架",
    "start time": "開始時間",
    "start_date": "開始日期",
    "start_time": "開始時間",
    "statistics_report": "報表統計",
    "status": "狀態",
    "submit": "提交視頻",
    "submitBtn": "提交",
    "succeed": "成功",
    "successful_invitation": "成功邀請",
    "successfully_received": "領取成功",
    "successfully_set": "設置成功",
    "super_partner": "超級合夥人",
    "switch_account": "切換賬號",
    "system_top_up": "系統充幣",
    "system_unlock": "系統解鎖",
    "tab_assets": "資產",
    "tab_home": "首頁",
    "tab_markets": "行情",
    "tab_swaps": "合約",
    "tab_trade": "交易",
    "taking_pictures": "拍照",
    "telegraph": "電報群：",
    "temporary_not_online_contact_service": "暫未上線，請聯繫客服",
    "the_day": "當日",
    "the_event_is_over": "活動已結束",
    "the_latest_price": "最新價",
    "the_password_is_correct": "密碼正確",
    "theme": "主題",
    "this_month": "本月",
    "this_week": "本周",
    "ticket": "票",
    "time_of_payment": "付款時間",
    "time_the_input": "{0}h內免輸入交易密碼",
    "timeout": "已超時",
    "timeout_affirm": "超時未確認",
    "timeout_deal": "超時自動成交",
    "timeout_to_cancel": "超時支付，自動取消",
    "to_confirm": "去確認",
    "total_buyer": "賣家總量",
    "total_count": "總量",
    "total_exchange": "閃兌總量",
    "total_lock_amount": "總鎖倉量",
    "total_money": "總金額",
    "total_return": "預計總收益",
    "trade_fee": "交易對費率",
    "trade_type": "交易類型",
    "trading_currency": "交易幣種",
    "trading_fiat_currency": "交易法幣",
    "trading_instructions": "交易須知",
    "trading_on": "交易對",
    "trading_pair_abbreviation": "請輸入交易對的簡稱",
    "trading_this_month": "本月交易",
    "trading_volume": "成交量",
    "transaction_description": "交易說明",
    "transaction_password": "資金密碼",
    "transaction_price": "成交價",
    "transfer_number": "轉出數量",
    "try_it_now": "立即體驗",
    "type": "類型",
    "unable_to_access_album": "當前設備不支持訪問相冊",
    "unaccalimed": "未領取",
    "unavailable": "不可用",
    "under_review": "審覈中",
    "univalence": "單價",
    "unlock_details": "解鎖詳情",
    "unlock_mode": "解鎖方式",
    "unlock_period": "解鎖週期",
    "unlock_plan": "解鎖計畫",
    "unlock_plan_unlock": "解鎖",
    "unlock_record": "解鎖記錄",
    "unlock_time": "解鎖時間",
    "unlocked": "已解鎖",
    "unredeemed": "未兌換",
    "unverified": "未認證",
    "up_to_three": "最多啟用三種",
    "upgrade": "升級",
    "upload_again": "重新上傳",
    "upload_front": "上傳證件正面",
    "upload_identity_card": "請上傳您的證件",
    "upload_image_success": "上傳圖片成功",
    "upload_information_as_required": "需嚴格按照上述要求上傳資料，否則會造成認證失敗",
    "upload_passport": "請上傳您的護照",
    "upload_passport_info_picture": "上傳護照信息頁照片",
    "upload_reverse": "上傳證件反面",
    "ups_and_downs": "當日漲跌",
    "ups_downs_habit": "漲跌習慣",
    "usable": "可用",
    "used": "已使用",
    "user-bind-failure": "邀請人綁定失敗",
    "user-bind-success": "邀請人綁定成功",
    "user-collection-error": "失敗",
    "user-country-code-not-empty": "國際代碼不能為空",
    "user-disable-trade": "當前帳戶禁止交易，詳情請諮詢客服!",
    "user-down-notbind": "該用戶不能綁定下級用戶",
    "user-email-fail-often": "郵件發送頻繁，請過120秒後重新發送",
    "user-email-not-empty": "郵箱地址不能為空",
    "user-email-verification-code-out": "郵箱驗證碼超時，請重新發送",
    "user-fail-advanced-auth": "高級認證失敗",
    "user-fail-advanced-authed": "用戶已高級認證過",
    "user-fail-assetnotenough-error": "資產不足",
    "user-fail-bank-count-error": "最多開啟3種支付方式",
    "user-fail-bank-inotc": "支付方式在OTC中有訂單，不能被禁用",
    "user-fail-cardno-repeat-error": "證件號碼已被使用",
    "user-fail-email-code": "郵件驗證碼錯誤",
    "user-fail-google-binded": "穀歌已綁定",
    "user-fail-google-code": "穀歌驗證碼錯誤",
    "user-fail-inviter-notexist": "推薦人不存在",
    "user-fail-login-disable": "當前帳戶禁止登錄，詳情請諮詢客服!",
    "user-fail-login-lock": "帳號被鎖定",
    "user-fail-login-password": "用戶名或密碼錯誤,剩餘重試次數{}",
    "user-fail-mail-binded": "郵箱已被綁定",
    "user-fail-not-realname": "請先實名認證",
    "user-fail-oldpassword-error": "原密碼錯誤",
    "user-fail-partner-not-found": "商戶不存在",
    "user-fail-partnernotfound": "商戶不存在",
    "user-fail-password": "用戶名或密碼錯誤",
    "user-fail-password-blank": "密碼不能為空",
    "user-fail-password-blank1": "確認密碼不能爲空",
    "user-fail-password-repeat": "您設置的新密碼與舊密碼相同,請重新設置!",
    "user-fail-password-thin": "密碼強度太弱",
    "user-fail-passwword-eq-paypassword": "交易密碼與登錄密碼一致，請重新設置",
    "user-fail-paypassword-error": "支付密碼錯誤",
    "user-fail-paypassword-notset": "請先設置支付密碼",
    "user-fail-paypassword-set": "支付密碼已設置",
    "user-fail-phone-binded": "手機號已被綁定",
    "user-fail-realname-auth": "用戶實名認證失敗",
    "user-fail-realname-authed": "用戶已實名認證過",
    "user-fail-realname-notset": "請先實名認證",
    "user-fail-reg": "註冊失敗，伺服器錯誤",
    "user-fail-reg-mail": "郵箱格式不正確",
    "user-fail-reg-mail-exist": "郵箱已被註冊",
    "user-fail-reg-phone-exist": "手機號已被註冊",
    "user-fail-securitycount": "最少保留一種驗證方式",
    "user-fail-sms-code": "短信驗證碼錯誤",
    "user-fail-sms-code-new": "新手機號短信驗證碼錯誤",
    "user-fail-sms-code-old": "舊手機號短信驗證碼錯誤",
    "user-fail-usernameisblank": "用戶名不能為空",
    "user-id-or-nationCode—is-null": "用戶id或者國際碼為空",
    "user-login-success": "登錄成功",
    "user-mail-fail": "郵件發送失敗",
    "user-mail-success": "郵件發送成功",
    "user-not-exist": "該用戶不存在",
    "user-notopen-success": "暫未開通",
    "user-phone-not-empty": "手機號不能為空",
    "user-sms-fail": "發送失敗，伺服器錯誤",
    "user-sms-fail-often": "短信發送頻繁",
    "user-sms-success": "短信發送成功",
    "user-sms-verification-code-out": "驗證碼超時，請重新發送",
    "user-success-advanced-auth": "高級認證成功",
    "user-success-realname-auth": "用戶實名認證成功",
    "user-validate-code-fail": "驗證碼不正確",
    "user-validate-fail": "校驗失敗",
    "user-vip-common-fail": "購買VIP失敗",
    "user_agreement": "請勾選同意用戶服務協議",
    "user_collection_cencel_success": "已取消",
    "user_collection_insert_success": "已收藏",
    "user_pay_disable_state": "該帳號交易密碼錯誤超限已被禁止交易,請過2個小時之後重試",
    "user_pay_is_error": "交易密碼錯誤,您還可以輸入{}次,超限將被凍結2個小時",
    "user_pay_password_not_setting": "用戶未設置交易密碼，請先設置交易密碼",
    "user_protocol": "用戶協議",
    "user_service_agreement": "《用戶服務協議》",
    "user_vip_has_expire": "此帳號vip已到期限無法陞級！",
    "user_vip_update_failure": "此帳號暫不支持續費及陞級，詳情請諮_客服務！",
    "valid_identity_card": "請輸入正確的證件號",
    "valuation": "存幣估值",
    "verification_code_error": "驗證碼錯誤",
    "verification_failed_001": "驗證失敗",
    "verification_finish": "驗證完成",
    "version_updating": "版本更新",
    "versions": "版本",
    "vertical_version": "豎版盤口",
    "view_activity": "查看活動",
    "view_lock": "查看鎖倉",
    "volamount": "買家必須成交過{}次",
    "volamount_sell": "賣家必須成交過{}次",
    "vote": "投票",
    "vote_add_coin": "投票上幣",
    "vote_return": "返還",
    "voting_coin": "投票幣種",
    "voting_number": "投票數",
    "voting_time": "投票時間",
    "wait_buyer_payment": "等待買方付款",
    "wait_for_payment": "待付款",
    "waiting_for_receiving": "等待賣方確認收款",
    "warm_prompt": "溫馨提示",
    "warning_setTitle": "預警設置",
    "wechat_account": "微信帳號",
    "wechat_upload": "*微信上傳二維碼方法：打開微信首頁>我的>錢包>收付款>保存圖片，將存在手機相冊的收款碼上傳即可。",
    "week": "周",
    "welcome": "歡迎來到BitMatrix",
    "welcome_to": "歡迎來到",
    "winning_record": "中籤記錄",
    "withdrawal_amount": "每日提幣額度",
    "withdrawal_charge": "提幣手續費",
    "xilian": "西聯",
    "yesterday_income": "昨日收益",
    "you_have_selected": "您已選擇",
    "Your closing number": "您的平倉數量小於最少持倉量!",
    "Your have pending Cross orders": "當前存在全倉掛單,請撤銷所有全倉掛單後迸行槓桿倍數修改",
    "Your have pending isolated orders": "當前存在逐倉掛單,請撤銷所有逐倉掛單後迸行槓桿倍數修改",
    "Home page apps": "首頁應用",
    "Home_Edit": "編輯",
    "recently_deal01": "成交",
    "depth01": "深度",
    "depth02": "深度图",
    "discount_currency_introduction01": "幣種簡介",
    "Info001": "簡介",
    "Avg cost": "成本均價",
    "Long position": "多倉",
    "Short position": "空倉",
    "Hold on": "稍後再說",
    "Done": "完成",
    "new_version": "發現新版本",
    "Announcement": "公告",
    "View details": "查看詳情",
    "Option Assets": "期權資產",
    "Option Account": "期權賬戶",
    "Option": "期權",
    "1M": "1分",
    "5M": "5分",
    "15M": "15分",
    "30M": "30分",
    "1H": "1時",
    "Delivery Time": "距離交割",
    "Call": "看多",
    "Put": "看空",
    "Draw": "看平",
    "Draw2": "看平",
    "Call1": "多",
    "Put1": "空",
    "Draw1": "平",
    "Buy-2": "購買",
    "Rate of return": "收益率",
    "Change": "漲跌幅",
    "Buy Option": "購買期權",
    "Awaiting": "等待交割",
    "Delivery": "我的交割",
    "Checkout": "交割記錄",
    "Front": "當期",
    "Back": "下期",
    "Coming soon": "即將交割",
    "delivery settlement": "交割結算中",
    "Ongoing": " 購買中",
    "Pending purchase": "待購買",
    "Billing Amount": "結算數量",
    "Amount": "購買數量",
    "Delivery result": "交割結果",
    "Delivery time": "交割時間",
    "Up": "漲",
    "Down": "跌",
    "lookUp": "看多",
    "lookDown": "看空",
    "Delivery details": "交割詳情",
    "Index Composition": "指數構成",
    "Exchange": "交易所",
    "Change2": "漲跌",
    "Weights": "權重",
    "Order number": "訂單號",
    "Option Session": "Option 分時",
    "Buy Time": "購買時間",
    "Opening time": "開盤時間",
    "Bet Type": "購買類型",
    "Delivery Price": "交割價格",
    "Settlement Currency": "結算幣",
    "K-line": "k線",
    "UpSelect": "漲幅選擇",
    "DownSelect": "跌幅選擇",
    "Front has ended, please place another order": "本期已結束，請重新下單",
    "Successfully ordered": "下單成功",
    "Insufficient balance": "餘額不足",
    "Quantity purchased must be greater than the minimum limit": "購買數量必須大於最小限制",
    "Quantity purchased must be less than the maximum limit": "購買數量必須小於最大限制",
    "The current time is not in the purchase time range": "當前時間不在購買時間範圍內",
    "expected": "預計收益",
    "To_a_game": "再來一局",
    "Settlement Result": "結算結果",
    "Delivery-1": "交割",
    "Ups and downs": "漲跌額",
    "duration": "時長",
    "Option funds daybook": "期權資金流水",
    "Open innovative options trading": "開通創新型期權交易",
    "Confirm Open": "確定開通",
    "Option Agree Content": "為保障您的權益，開通期權之前您要仔細閱讀《創新型期權交易使用協議》",
    "Option Agree Content footer": "《創新型期權交易使用協議》",
    "Option Agree Content header": "為保障您的權益，開通期權之前您要仔細閱讀",
    "Service upgrade, please try again later!": "服務升級，請稍後重試！",
    "month": "月",
    "Invitation": "邀請",
    "Markets": "行情",
    "Swaps": "合約",
    "Trade": "交易",
    "Trade-1": "去交易",
    "Assets": "資產",
    "Buy": "買幣",
    "Help": "幫助",
    "Service": "客服",
    "Activity": "活動",
    "Communtiy": "社群",
    "No search results": "沒有搜索結果",
    "The option account asset is 0, please transfer from the wallet account to the option account first.": "期權賬戶資產為0，請先從錢包賬戶劃轉至期權賬戶。",
    "Perpetual account assets are 0, please transfer from wallet account to perpetual account first.": "永續賬戶資產為0，請先從錢包賬戶劃轉至永續賬戶。",
    "Financing": "理財",
    "Load": "加載",
    "Replay001": "重播",
    "Constellation Program": "隆重介紹-星座計劃",
    "Constellation Program2": "隆重介紹-星座理財",
    "rate rankings": "機構帶單勝率排行榜",
    "rate rankings2": "推薦本週理財明星機構",
    "CXT Quotes": "行情",
    "Digital asset trading tool service platform": "數字資產交易工具服務平台",
    "Service_home": "服務",
    "Strategic cooperation": "戰略合作",
    "Financing assets": "理財資產",
    "Remove 0 from the first number when registering!": "註冊時首位號碼去掉0！",
    "Are you sure you want to quit": "確定要退出嗎",
    "Opening Price": "開盤價",
    "Closing Price": "收盤價",
    "Formula interpretation": "公式解讀",
    "Note: If the input box is left blank": "注：輸入框留空代表該場未獲取到當前交易所的數據",
    "Option Sessions": "期權場次",
    "Result": "結果",
    "When data for an exchange is not available": "*當未獲取到某個交易所的數據時，其權重值取0%。當有效數據源≤2時，定義為流局",
    "Assume that the index is derived from the opening prices of five exchanges": "假設指數來源於5個交易所的開盤價格分別為：A、B、C、D、E；收盤價格分別為：A`、B`、C`、D`、E`",
    "The proportion of those five exchanges are": "5個交易所對應的權重分別為：a%、b%、c%、d%、e%、",
    "Stream bureau": "流局",
    "(valid data source ≤ 2)": "（有效數據源≤2）",
    "Option calculator": "期權計算器",
    "Round selection": "選擇場次",
    "Select option": "選擇期權",
    "Average opening price": "開盤均價",
    "Average closing price": "收盤均價",
    "Calculate now": "開始計算",
    "Calculation Results": "計算結果",
    "Calculating Time": "計算時間",
    "Management": "管理",
    "Other_home": "其他",
    "All applications": "全部應用",
    "Boon": "福利",
    "Coin_Address_draw": "提幣地址",
    "Congratulations": "恭喜您",
    "Profit": "盈利",
    "Gainers": "涨幅",
    "Drop": "跌幅",
    "Order Price": "購買價",
    "Order Price1": "購買價格",
    "Sec Swap": "秒合約",
    "Expected Return": "預計收益率",
    "Sec Swap Assets": "秒合約資產",
    "Sec Swap Bill": "秒合約資金流水",
    "Sec Swap Account": "秒合約賬戶",
    "Go Login": "去登錄",
    "NSO Session": "NSO分時",
    "Wallet Value1": "錢包資產",
    "Spot Value1": "幣幣資產",
    "PERP Value1": "永續資產",
    "Option Value1": "期權資產",
    "Fiat Value1": "OTC錢包",
    "Pledge_Amount": "質押數量",
    "total_return2": "累計收益",
    "Violation_Loss": "違約金",
    "Cycle": "週期",
    "Locked2": "已鎖倉",
    "Time_Left": "剩餘時間",
    "Cancel_Pledge": "取消質押",
    "countDown_day": "天",
    "Entrust2": "委托訂單",
    "All2": "全部",
    "Ongoing2": "進行中",
    "Near2": "即將開始",
    "Ended2": "已結束",
    "Cancel the pledge?": "確定要取消質押嗎？",
    "Completed2": "已完成",
    "Cancelled2": "已取消",
    "Lock-up mining": "鎖倉挖礦",
    "Rules Description": "規則說明",
    "Today Expected": "預計今日收益",
    "Order Escrow": "託管中的訂單",
    "Minimum": "最小限額",
    "Daily Return": "日收益率",
    "Buy-3": "去買入",
    "Minimum Amount": "最低質押金額",
    "Lockup Period": "鎖倉週期",
    "Confirm Pledge": "確認質押",
    "Buy-4": "買入",
    "Conversion Starter": "申購首發",
    "Listing": "上線",
    "Total Target": "目標總額",
    "Conversion Ratio": "申購比例",
    "Single Limited": "單人限量",
    "Participate Order": "參與認購",
    "Fundraised": "已籌款",
    "Current Progress": "當前進度",
    "Time Left": "距離結束還剩",
    "Start Time": "開始時間",
    "End Time": "結束時間",
    "Conversion Amount": "申購數量",
    "Conversion Now": "立即申購",
    "Conditions": "參與條件",
    "Preheating": "預熱中",
    "Records": "申購記錄",
    "Select": "選擇",
    "To": "至",
    "Active Currency": "活動幣種",
    "Amount-3": "金額",
    "Conversion Time": "申購時間",
    "Arrival Time": "到賬時間",
    "Not Arrived": "暫未到賬",
    "Hours-1": "时",
    "Risk warning: It is displayed": "風險提示：為創新型資產顯示，具有數字資產的特性。請謹慎判斷價格，慎重選擇投資決策！",
    "Week-1": "周",
    "S-1": "秒",
    "minutes-2": "分",
    "You must pass basic KYC": "用戶必須通過實名認證",
    "You must pass advanced KYC": "用戶必須通過高級認證",
    "Account order exceeds": "賬戶持倉大於",
    "Suspend": "暫停交易",
    "Successfully": "申購成功",
    "Ongoing-1": "申購中",
    "Failed": "申購失敗",
    "New_Token_Subscribe": "新幣申購",
    "More_awaits_you": "更多財富等你來拿",
    "Distance Start": "距離開始還剩",
    "day-2": "日",
    "Must Eligible": "需滿足參與條件",
    "Custodial funds": "正在託管的資金",
    "Default Ratio": "違約金比例",
    "Wallet Available": "錢包可用",
    "Done-2": "完成",
    "Eligible Amount": "審核通過數量",
    "Eligible Price": "審核通過金額",
    "New Token Subscribe": "新幣認購",
    "Subscribe Now": "立即認購",
    "Review Time": "審核時間",
    "Review Passed": "審核已通過",
    "Review Failed": "審核未通過",
    "Not Reviewed": "暫未審核",
    "Denial Reason": "拒絕原因",
    "If not received, please contact service": "如未到賬請聯繫客服!",
    "Returned to wallet": "已返還到錢包賬戶",
    "From wallet assets": "可用來自錢包資產",
    "Day-3": "天",
    "Sec Swap asset is 0, please transfer from wallet account to Sec Swap first": "秒合約賬戶資產為0，請先從錢包賬戶劃轉至秒合約賬戶",
    "Settlement": "結算中",
    "Completed": "結算完成",
    "Password Setting": "登錄密碼設置",
    "Set password and email": "設置登錄密碼及郵箱",
    "Email Settings": "郵箱設置",
    "First-time registered users mus": "首次註冊用戶須完成登錄密碼設置及郵箱設置",
    "Driver ID": "駕駛證",
    "Preview": "詢價",
    "cancelTime": "取消時間",
    'Total_assets_valuation': '總資產估值',
    'Invite Friends': '邀请好友',
    'RedGreen': '紅漲綠跌',
    'GreenRed': '綠漲紅跌',
    'Color Preference': '漲跌偏好',
    'Fuzzy Information': '信息模糊',
    'user_bind_xilain_trade_pwd': '请输入交易密碼',
    'login_please_input1': '请输入短信验证码',
    'user_please_input9': '请输入谷歌验证码',
    'user_link_name': '鏈名稱',
    'user_coin_bring_together': '摺合',
    "chongbi0": '充幣須知:',
    "chongbi1": '1、禁止充幣除{0}之外的其他資產，任何非{1}資產充幣將不可找回。',
    "chongbi2": '2、請通過{0}客戶端或線上錢包將您需要充值的{1}數目發送到該地址。發送完成後，系統會自動在此交易獲得 {2} 個確認後將該筆虛擬幣充值到您在本站的帳戶，請耐心等待。',
    "chongbi3": '2、同一個地址可多次充值，不影響到賬。',
    "chongbi4": '3、最小充值金額 {0}。',
    "currency_withdrawal_rate_rules": '{0}提幣費率規則：',
    "currency_withdrawal_rate_rules1": '1.為了用戶資金安全，平台可能會電話確認您的提幣操作，請注意接聽；',
    "currency_withdrawal_rate_rules2": '2.{0} 充值經過 {1} 個確認後，才允許提幣；',
    "currency_withdrawal_rate_rules3": '2.可提現金額≤ 賬戶可用資產- 未確認的數字資產；',
    "currency_withdrawal_rate_rules4": '3.每日最多提幣 {0} 次，每日最多提幣 {1}。',
    "currency_transaction_password": "密碼",
    'long_press_to_save_to_album': '長按保存至相册',
    'the_fee_is_outside_the_range': '手续费不在区间范围内',
    'start_and_end_date': '起止日期',
    'The_withdrawal_amount_cannot_be_less': '提币数量不能小于{0}',
    'withdrawal_details': '提幣詳情',
    "RegisterRemove0": '注册时首位号码去掉0',
    "closeLockUpPopup": '取消質押將產生{0}天違約金，違約金金額是“{1}{2}”，實際到賬本金是“{3}{4}”。',
    "LockUpDuan": "鎖倉挖礦",
    "NewTokenSubscribe": "新幣申購",
    "MoreWealthAwaitsYou": "更多財富等你來拿",
    "TopGainers": "漲跌榜",
    "Newest": "新幣榜",
    "Hot": "人氣榜",
    "DatChange": "日漲跌",

    "SetPassword": "设置密碼",
    "comm_password": "交易密碼",
    "unraise_recovering_password": "找回密碼後，1h無法提幣",
    "PDleaseUseSafariBrowser": "請使用Safari瀏覽器",
    "ADddToDesktop": "添加BitMatrix至桌面",
    "CDlickBelow": "點擊下方",
    "SDelectAddToHomeScreen": "然後選擇添加到主屏幕",
    "ADddToDesktop2": "將BitMatrix添加到桌面",
    "CDommonbrowserAddMethod": "常用瀏覽器添加方式",
    'user_senior_text1': '注意',
    'user_senior_text2': '照片文件大小不能超过5M！文件格式须为jpg、bmp、png等！',
    'user_senior_text3': '请确保照片无水印，无污渍，身份信息清晰，头像完整，非文字反向照片！照片请勿进行PS处理！',
    'user_senior_text4': '手持',
    'user_senior_text5': '身份证照片',
    'user_senior_text6': '：需要您本人一只手持您的',
    'user_senior_text7': '当天日期',
    'user_senior_text8': '及',
    'user_senior_text9': 'UID',
    'user_senior_text10': '的纸条并拍摄成非镜像照片。',
    'user_senior_text11': '，另外一只手持一张有您手写的',
    'user_senior_text12': '手持白纸请手写以下内容，内容不符者不予通过：',
    'user_senior_text13': '“我已知晓并自愿承担投资风险”',
    'user_senior_text14': '。',
    'user_ID_card': '身份证',
    "user_passport": "護照",
    'Driver_ID': '駕駛證',
    "user_senior_upload3": "上傳手持證件照+UID+日期",
    "ServiceUpgrade": '服務升級中',
    "ReferencePrice": "參考價",
    "referenceTips": "非最終成交單價，僅供參考",
    "NewCoinsLaunch": "新幣首發",
    "CurrentlyPurchased": "當前申購",
    "TotalMarketValue": "總市值",
    "IEORatio": "申購比例",
    "CurrencyIntroduction": "幣種介紹",
    "ConvertNow": "立即申購",
    "RemainingAmount": "剩餘額度",
    "referenceTips": "以實際購買價為準",
    "ServiceUpgrade": '服務升級中',
    "LastDays7": "近7天",
    "LastDays30": "近30天",
    "LastDays90": "近90天",
    "LockUpTips": "注：剩餘未審核通過{0}{1}資產已經返還您的錢包賬戶。",
    "PromotionalMining": "促销挖矿",

    'hide_coin': '隱藏小於{0} BTC幣種',
    'GoldAssets': '黃金資產',
    'ModifyEmail': '修改信箱',
    'NewEmail': '新信箱',
    'NewEmailAddress': '輸入新郵件地址',
    'VerifyNewEmail': '驗證新郵件',
    'EnterNewEmailCode': '輸入新郵件驗證碼',
    'VerifyOldEmail': '驗證舊郵件',
    'EnterOldEmailCode': '輸入舊郵件驗證碼',
    'VerifyLoginPassword': '驗證登入密碼',
    'EnterLoginPassword': '輸入登入密碼',
    "loan": "借貸中心",
    "loanText": "提供安全可靠的借幣服務，可服務多種目的，且流動性更強，無需出售現有加密貨幣資產。",
    "loanQuery": "借幣諮詢",
    'Stop_surplus_tips': "市場價格至{}時將觸發止盈委託，成交後預計盈利{}",
    'Stop_loss_tips': "市場價格至{}時將觸發止損委託，成交後預計虧損{}",
    'Stop_surplus_tips1': "市場價格至{}時將觸發止盈委託，成交後預計虧損{}",
    'Stop_loss_tips1': "市場價格至{}時將觸發止損委託，成交後預計盈利{}",
    'Countdown': "倒計時",
    'contract_After_adjustment': '調整後，將從帳戶中轉入{}個USDT作為新增保證金',
    'contract_subject_to': '（以實際轉入金額為准）',
    'contract_the_used_margin': '調整後，已用保證金將减少{}個USDT，减少部分留在倉位裏,',
    'contract_transferred_to': '可轉出至帳戶餘額',
    'contract_After_adjustment_q': '調整後，倉位佔用保證金將增加{}個USDT',
    'contract_the_used_margin_q': '調整後，倉位佔用保證金將减少{}個USDT',
    'Please1': '請輸入止盈觸發價格',
    'Please2': '請輸入止盈委託價格',
    'Please3': '請輸入止損觸發價格',
    'Please4': '請輸入止損委託價格',
    'Stop profit6': '止盈觸發價需大於最新成交價',
    'stop loss7': '止損觸發價需小於最新成交價',
    'Please8': '請輸入數量',
    'Please9': '沒有可平量',
    'CountdownTime': '倒計時',
    h1: '申請合夥人',
    h2: '返傭排行榜',
    h3: '成為合夥人可獲取',
    h4: '陞級條件',
    h5: '購買初級合夥人需要支付',
    h6: '當前餘額',
    h7: '創世合夥人',
    h8: '返傭',
    h9: '立即陞級',
    h10: '已是最高等級',
    h11: '返傭獎金',
    h12: '返傭排行',
    h13: '團隊人數',
    h14: '返傭記錄',
    h15: '被邀請人',
    h16: '已結算',
    h17: '未結算',
    h18: '享有下屬',
    h19: '需要支付',
    h20: '陞級',
    h21: '返傭細則',
    h22: '排名',
    h23: '用戶',
    h24: '返傭獎勵',
    h25: '當前等級可獲得',
    h26: '獲取傭金全部折合成為USDT的累加值。',
    h27: '需要直推{}個普通用戶',
    h28: '已直推{}個有效普通用戶',
    h29: '需要團隊人數達到{}人',
    h30: '團隊人數{}人',
    'Stop profit10': '止盈觸發價需小於最新成交價',
    'stop loss10': '止損觸發價需大於最新成交價',
    Earn:'理財',
    Simple:'賺錢花錢兩不誤',
    Low1:'低風險 收益穩定',
    Low2:'風險小 收益穩健 隨時贖回',
    CurrentAccount:'餘額寶',
    Stable:'穩健增值，財富增長利器',
    EarnLocked:'定期理財',
    Searchpopular:'輕鬆理財，隨時享受收益',
    Annualized:'年化報酬率{}%，安心資產，超高回報',
    CoinsLC:'幣種',
    PurchaseLC:'購買金額',
    PurchaseLC2:'({}起購)',
    Maximum:'最大',
    EstimatedInterest:'預估利息',
    RuleLC:'規則',
    SubscriptionTime:'申購時間',
    Interestaccrualtime:'計息時間',
    RevenueTime:'收益時間',
    RedemptionCycle:'贖回週期',
    ReturnNow:'立即返回',
    BuyLC:'購買',
    PurchaseSuccessful:'購買成功',
    DepositLC:'存幣',
    Yesterday:'昨日收益',
    TotalLC:'存幣總量',
    TotalReturn:'累計收益',
    DepositValuation:'存幣估值',
    DepositDetail:'存幣詳情',
    RevenueDetails:'收益詳情',
    DepositType:'存幣類型',
    ReturnMethod:'返息方式',
    DepositAmount:'存幣數量',
    APR:'年化率',
    Expected:'預計收益',
    DepositCycle:'存幣週期',
    CreationTime:'創建時間',
    EarnProfit:'當期利息',
    DepositCost:'存幣本金',
    TotalRelease:'發放總量',
    ReleaseTime:'發放時間',
    Flexible:'活期',
    Fixed:'定期',
    Freeze:'凍結',
    Completed3:'已完成',
    Cancelled:'已取消',
    DefaultSettlement:'違約結算',
    PurchaseAmount:'購買金額(無限制)',
    RetrieveDeposited:'取回存幣',
    PreviewConversion:'預覽兌換結果',
    ToEnsureThe:'为了确保您的账户安全和满足更高交易额度的要求，我们需要对您的身份进行高级认证。',
    ToVerification:'点击下发按钮，联系客服进行认证。',
    ToContact:'立即联系客服认证',
    "comm_nickname_rule1": "不可超過7個漢字或15個英文字母",
    "comm_nickname_rule2": "不可包含空格與特殊字符",
    "otc_upload_picture3":"上傳圖片只能是 jpeg/jpg/png/bmp 格式!",
    "CertificationDifficulties":'認證出現問題？',
    "CertifiedTipsP2P":'以上商家均經過BitMatrix平台認證，請放心購買。',
    'OneClickBackhand':'一鍵反仓',
    'EmptyReversal':'空頭反轉',
    'MultipleReversalsEmpty':'從多單反向操作為空單',
    'BackhandOpenLong':'反手開多',
    'BackhandOpenEmpty':'從做多變成做空',
    'DontShowMeAgain':'不再顯示',
    "TransferRequiresClosing":"您當前存在持倉，需平倉後轉出"
}