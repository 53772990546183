<template>
    <div class="popupCertain1">
            <div class="title">
              <div class="titletop">{{$t('M.Margin-2')}}</div>
              <div class="titlebottom">{{itemData.tradeName}}</div>
            </div>
            <van-tabs class="tab1" v-model="active" @change="changeBar"  title-active-color="#fff" title-inactive-color="#5C6573" color="#F10E38" background="#f8f8f8"  type="card">
              <van-tab :title-style="{borderRadius:'5px'}"  name="add" :title="$t('M.Add margin')"></van-tab>
              <van-tab :title-style="{borderRadius:'5px'}"  name="sub" :title="$t('M.Margin reduce')"></van-tab>
            </van-tabs>
            <div class="pper">
                <van-field class="pperinput" @input="getPreForcePrice"  v-model="number" type="number" >
                    <template #extra>
                        <span style="color: #a2a2a2;border-left: 1px solid #ddd;padding: 0 10px;margin-left: 10px;">{{itemData.sellCoinName}}</span>
                    </template>
                </van-field>
            </div>
            <div class="activeNum">
              <div @click="changeNumber(activeNum = 0.1)" :class="activeNum=='0.1'?'active':''">10%</div>
              <div @click="changeNumber(activeNum = 0.2)" :class="activeNum=='0.2'?'active':''" >20%</div>
              <div @click="changeNumber(activeNum = 0.5)" :class="activeNum=='0.5'?'active':''" >50%</div>
              <div @click="changeNumber(activeNum = 0.8)" :class="activeNum=='0.8'?'active':''" >80%</div>
              <div @click="changeNumber(activeNum = 1)" :class="activeNum=='1'?'active':''" >100%</div>
            </div>
            <!-- <van-tabs class="tab2" v-model="activeNum" @change="changeNumber"  title-active-color="#fff" title-inactive-color="#5C6573" color="#F10E38"  type="card">
              <van-tab  name="0.1" title="10%"></van-tab>
              <van-tab  name="0.2" title="20%"></van-tab>
              <van-tab  name="0.5" title="50%"></van-tab>
              <van-tab  name="0.8" title="80%"></van-tab>
              <van-tab  name="1" title="100%"></van-tab>
            </van-tabs> -->
            <div class="sub">
               <span v-if="active=='add'">{{$t('M.Most increase')}}：</span> <span v-else>{{$t('M.Most reduce')}}：</span> <span>{{MAXEnable}}{{ itemData.buyCoinNickname }}</span>
            </div>
            <div class="describe">
              <div>
                <span>{{$t('M.Liquidation price after increase')}}：</span><span style="color: #F10E38;">{{Qiangping}}</span>
              </div>
              <div>
                <span>{{$t('M.Leverage after increase')}}：</span><span style="color: #F10E38;">{{itemData.lever}}X</span>
              </div>
            </div>
            <div class="buttoncon">
              <van-button color="#F10E38" size="small" @click="adjust" round block>{{$t('M.confirm')}}</van-button>
            </div>
        </div>
  </template>

  <script>
  export default {
    props:['itemData'],
    data(){
      return{
        active:'add',
        number:'',
        Qiangping:'-',
        MAXEnable:'',
        activeNum:'0.1',
        markLast:''
      }
    },
    mounted(){
      this.getUserContractEnable()
      this.$api.getTradeTypeTicker({partnerId:this.itemData.partnerId,tradeName:this.itemData.symbol}).then(res=>{
              if(!res.success){
                this.markLast = 0
              }else{
                this.markLast = res.data.markLast
              }
      })
    },
    methods:{
      getUserContractEnable(){
        this.$api.getUserContractEnable(this.itemData).then(res=>{
            this.MAXEnable = (res.data.EnablePosition-0).toFixed(4)
            this.changeNumber()
        })
      },
      adjust(){
        // if(this.active == 'add'){
        //   if(this.number>this.MAXEnable)
        //   params['toatl'] = this.number
        //   params['adjusted'] = this.active
        // }else{
        //   if(this.number>this.MAXEnable)
        //   params['toatl'] = '-'+this.number
        //   params['adjusted'] = this.active
        // }
        let params = {
        adjusted: this.active, //add表示增加保证金，sub表示减少保证金， 当前只有增加
        toatl:
          this.active === "add"
            ? Number(this.number)
            : 0 - Number(this.number), //调整后总金额
        partnerId: this.itemData.partnerId,
        coinId: this.itemData.coinId,
        contractType: this.itemData.contractType,
        positionsType: this.itemData.positionsType,
        openType: this.itemData.openType,
        swiftId: this.itemData.swiftId,
        sellCoinId: this.itemData.sellCoinId
      };

        this.$api.adjust(params).then(res=>{
          this.$emit('dreg')
          this.$emit('upMoney')
          this.$emit('update')
        })
      },
      getPreForcePrice(){
        let params = this.itemData
        if(this.active == 'add'){
          params['toatl'] = this.number
        }else{
          params['toatl'] = '-'+0
        }
        this.$api.getPreForcePrice(params).then(res=>{
          this.Qiangping = res.data.preForcePrice
        })
      },
      changeBar(){
        console.log(this.itemData);
        this.Qiangping ='-'
        if(this.active == 'add'){
          this.activeNum= '0.1'
          this.getUserContractEnable()
          this.getPreForcePrice()
        }else{
          this.activeNum= null
          if((this.itemData.realizationIncome-0+this.itemData.unRealizationIncome-0)>0){
            //盈亏为正时
            this.MAXEnable = (this.itemData.margin-0)-((this.markLast-0)*(this.itemData.holdAmount-0)/(this.itemData.lever-0))
          }else{
            //盈亏为负时
            this.MAXEnable = (this.itemData.margin-0)-((this.markLast-0)*(this.itemData.holdAmount-0)/(this.itemData.lever-0))+((this.itemData.realizationIncome-0)+(this.itemData.unRealizationIncome-0))
          }
          console.log(this.MAXEnable);
          this.number=this.MAXEnable
          if(this.MAXEnable<0) this.MAXEnable=0
          this.getPreForcePrice()
        }
      },
      changeNumber(){
        this.number = ((this.MAXEnable-0)*(this.activeNum-0)).toFixed(4)+''
        this.getPreForcePrice()
      }
    }
  }
  </script>

  <style lang="scss">
  .popupCertain1{
    padding: 10px;
    .van-tabs__wrap{
        height: auto;
    }
    .tab1{
        .van-tabs__nav--card{
            border: none;
            margin: 0;
            .van-tab{
                border: none;
            }
        }
    }
    .tab2{
        .van-tabs__nav--card{
            margin: 0;
        }
    }
    .buttoncon{
      margin: 15px 0;
    }
    .describe{
      margin-top: 10px;
      padding: 5px;
      background-color: #f8f8f8;
      div{
        padding: 5px;
      }
    }
    .title{
      border-bottom: 1px solid #e3e5eb;
      margin-bottom: 10px;
      .titletop{
        color: #000;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 5px;
      }
      .titlebottom{
        // font-size: 16px;
        color: #000;
        padding-bottom: 5px;
      }
    }
    .sub{
      padding-top: 5px;
    }
    .pper{
        margin: 10px 0;
        .pperinput{
            background: #f8f8f8;
        }
    }
    .activeNum{
      border: 1px solid #F10E38;
      display: flex;
      div{
        flex: 1;
        height: 30px;
        border-right: 1px solid #F10E38;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      div:last-child{
        border-right: none;
      }
      .active{
        color: #fff;
        background-color: #F10E38;
      }
    }
  }
  </style>
