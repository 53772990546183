export const M = {
    "Slideright": "Te rog apasă și trage glisorul până la dreapta",
    "Pleaseverification": "Te rog finalizează verificarea de securitate",
    "Pleaseorder": "Te rog apasă în ordine",
    "Verificationagain": "Verificarea a eșuat, te rog încearcă din nou",
    "10 orders": "10 comenzi",
    "24h High": "Max 24H",
    "24h Low": "Min 24H",
    "24h Trading Vol": "Volum tranzacționat 24H",
    "24h Vol": "Vol 24H",
    "ADL mechanism": "Mecanism ADL",
    "AMT": "Cantitate",
    "Activity _home": "Activitate",
    "Actual transaction number": "Numărul de tranzacții efective",
    "Actual transaction price": "Prețul tranzacției efective",
    "Add margin": "Adaugă marjă",
    "Adjust leverage": "Ajustează levierul",
    "Adjust success": "Ajustare reușită",
    "Advanced limit order offers 3": "Comanda limită avansată oferă 3 mecanisme, inclusiv „Doar Maker”, „Toate tranzacționate sau anulate imediat”, „Transacționează imediat și anulează restul”; comanda standard este „Valabilă întotdeauna”.",
    "Alipay": "Alipay",
    "All Closed": "Toate închise",
    "All Filled": "Complet tranzacționat",
    "AllDetails": "Vezi detaliile lichidării",
    "AllType": "Toate tipurile",
    "Amount can flat": "Cantitate disponibilă",
    "Appeal_reason": "Motivul apelului",
    "Applet service": "Serviciu mini-aplicație",
    "Are you sure to cancel the order？": "Ești sigur că vrei să anulezi comanda?",
    "Asked_to_record": "Te rog să înregistrezi conform cerințelor de mai jos",
    "Asset conversion": "Conversia activelor",
    "Asset_types": "Tipuri de active",
    "At the current price": "La prețul de piață curent",
    "Avail.": "Disponibil",
    "Avail. margin": "Marjă disponibilă",
    "Available": "Disponibil",
    "Avg close price": "Preț mediu de închidere",
    "Avg open price": "Preț mediu de deschidere",
    "Avg.price": "Preț mediu",
    "BBO": "Prețul opus",
    "Bankcard": "Card bancar",
    "Bankruptcy price": "Prețul falimentului",
    "Batch Cancel": "Anulare masivă",
    "Branch_address": "Adresa sucursalei",
    "Buy crypto_home": "Cumpără cripto",
    "Calculations are not based on": "Rezultatele calculelor sunt orientative, acest preț de lichidare reprezintă datele doar pentru o singură direcție de deschidere a contractului.",
    "Can't calculate": "Nu se poate calcula",
    "Cancel": "Anulare",
    "Cancel all": "Anulare masivă",
    "Cancel order": "Anulează comanda",
    "Cancel_the_login": "Anulează autentificarea",
    "Capital cost": "Flux de capital",
    "Capital cost - Expenditure": "Cost de capital - Cheltuieli",
    "Capital fee": "Taxa de capital",
    "Capital fee - all": "Taxa de capital - Tot",
    "Capital fee - income": "Taxa de capital - Venituri",
    "Capital success": "Anulată cu succes",
    "Charge_money": "Adaugă fonduri",
    "Chinese_Yuan": "Yuan chinezesc",
    "Close all of": "Ești sigur că vrei să",
    "Close long": "Închide lung",
    "Close out time": "Timp total de închidere",
    "Close price": "Preț de închidere",
    "Close short": "Închide scurt",
    "Closed": "Cantitate închisă",
    "Closing PnL gains": "Câștiguri din închidere",
    "Closing PnL ratio": "Rata câștigurilor din închidere",
    "Closing profit": "Profit din închidere",
    "coin_failure_0002": "Informațiile despre monedă nu există temporar",
    "Common functions": "Funcții comune",
    "Communtiy_home": "Comunitate",
    "Conceal canceled orders": "Ascunde comenzile anulate",
    "Confirm_the_login": "Confirmă autentificarea",
    "Confirm_to_mention_money": "Confirmă retragerea fondurilor",
    "Cont": "Buc.",
    "Copy link": "Copiază linkul",
    "Cross": "Cross margin",
    "Currency_rate_description": "Descrierea ratei de schimb",
    "Current": "Curent",
    "Delete_option": "Șterge",
    "Determine_to_cancel": "Ești sigur că vrei să anulezi?",
    "Don't show me again": "Nu-mi mai arăta",
    "Draw_inconsistency": "Contrasignarea parolei nu se potrivește",
    "Draw_the_unlock": "Te rog să glisezi pentru a seta o nouă parolă",
    "Enter email address": "Te rog introdu adresa ta de email",
    "Enter expected PnL": "Te rog introdu câștigul așteptat",
    "Enter expected PnL Ratio": "Te rog introdu rata câștigului așteptat",
    "Enter leverage from 1 up to 125": "Te rog introdu un levier de la 1 la 125",
    "Enter phone number": "Te rog introdu numărul tău de telefon",
    "Enter the verification code": "Te rog introdu codul de verificare",
    "Equity": "Drepturile asupra monedei",
    "Est liquidation price": "Preț estimat de lichidare",
    "Face value": "Valoarea nominală",
    "Filed Value": "Valoarea tranzacționată",
    "Fill or Kill": "Toate tranzacționate sau anulate imediat",
    "Filled amt": "Cantitate tranzacționată",
    "Filled time": "După timpul tranzacționării",
    "Flash_strong": "Strălucire puternică",
    "Funding rate": "Rata de finanțare",
    "Funding rate - 1": "Rata de finanțare",
    "Funding rate comparison": "Compararea ratei de finanțare",
    "Funding rate time": "Timpul ratei de finanțare",
    "Fuzzy_information": "Informație neclară",
    "Gesture_error": "Parolă greșită",
    "Google_code": "Cod Google",
    "Help_home": "Ajutor",
    "Hide_no_asset_currency": "Ascunde monedele fără active",
    "Highest PnL ratio": "Cea mai mare rată de câștig",
    "Historical record": "Istoric",
    "I agree to": "Accept",
    "I have read and agree to this agreement": "Am citit și sunt de acord cu acest acord",
    "If_the_buyer_is_at": "Dacă cumpărătorul este la",
    "Immediately or Cancel": "Transacționează imediat și anulează restul",
    "In force": "În vigoare",
    "Index": "Indice",
    "Index Price": "Prețul indicelui",
    "Index_contrct": "Contract indice",
    "Info": "Informații contract",
    "Initial margin": "Marja inițială",
    "Instrument": "Produsul de tranzacționare actual",
    "Insurance fund": "Fond de rezervă",
    "Interest bearing record": "Înregistrare cu dobândă",
    "Invalid_qr_code": "Cod QR invalid",
    "Invite code(Optional)": "Te rog introdu codul de invitație (opțional)",
    "InviteCodeMuster": "Te rog introdu codul de invitație (obligatoriu)",
    "Invite_home": "Invită",
    "Isolated": "Isolat",
    "Lack_of_edge": "Lipsă colț",
    "Last Price": "Prețul de piață",
    "Latest Filled price": "Ultimul preț tranzacționat",
    "Latest Filled price01": "Ultimul preț",
    "Latest price": "Ultimul preț",
    "Leverage": "Levier",
    "Leverage after increase": "Levierul după augmentare:",
    "Leverage11": "Levierul actual",
    "Leverage_BB": "Ajustează levierul",
    "Limit order is an order": "O comandă limită este o comandă în care utilizatorul stabilește cantitatea și prețul acceptabil; când piața îndeplinește așteptările utilizatorului, sistemul va executa comanda la cel mai bun preț din intervalul de limită.",
    "Limit price": "Preț limită",
    "Limit/Market": "Limită/Piață",
    "LimitAndMarket": "Limită/Piață",
    "Liquidation price": "Preț de lichidare",
    "Liquidation price after increase": "Prețul de lichidare după augmentare:",
    "Long": "Long",
    "Lowest PnL ratio": "Cea mai mică rată de câștig",
    "MAIL_COUNTRY_NOT_EXITS": "Țara curentă nu există! Te rog adaugă-o și trimite din nou emailul",
    "MAIL_NOT_MAIL_PROVIDER": "Nu există furnizor de email, te rog adaugă-l și trimite din nou emailul!",
    "MAIL_TEMPLATE_NOT_FOUND": "Șablonul de email curent nu există! Te rog adaugă-l și trimite din nou",
    "MAIL_TO_ADDRESS_NOT_FOUND": "Adresa de email curentă nu există, te rog verifică și trimite din nou",
    "MJRefreshAutoFooterIdleText": "Apasă sau trage pentru a încărca mai multe",
    "MJRefreshAutoFooterNoMoreDataText": "Toate datele au fost încărcate",
    "MJRefreshAutoFooterRefreshingText": "Se încarcă mai multe date...",
    "MJRefreshBackFooterIdleText": "Trage pentru a încărca mai multe",
    "MJRefreshBackFooterNoMoreDataText": "Toate datele au fost încărcate",
    "MJRefreshBackFooterPullingText": "Eliberează pentru a încărca mai multe",
    "MJRefreshBackFooterRefreshingText": "Se încarcă mai multe date...",
    "MJRefreshHeaderDateTodayText": "Astăzi",
    "MJRefreshHeaderIdleText": "Trage pentru a reîmprospăta",
    "MJRefreshHeaderLastTimeText": "Ultima actualizare:",
    "MJRefreshHeaderNoneLastDateText": "Fără înregistrări",
    "MJRefreshHeaderPullingText": "Eliberează pentru a reîmprospăta",
    "MJRefreshHeaderRefreshingText": "Se reîmprospătează datele...",
    "MKT Close ALL": "Închidere totală la preț de piață",
    "Margin add": "Adaugă marjă",
    "Margin mode": "Mod de marjă totală",
    "Margin ratio": "Rata marjei",
    "Margin reduce": "Reducerea marjei",
    "Margin transfer": "Transfer de marjă",
    "Margin-1": "Sold de marjă",
    "Margin-2": "Ajustare marjă",
    "Margined": "Contract",
    "Mark price": "Preț marcat",
    "Market": "Piață",
    "Market Order Dig": "Comanda de piață permite utilizatorilor să plaseze comenzi la cel mai bun preț de piață curent, pentru a atinge o tranzacționare rapidă.",
    "Market Overview": "Prezentare generală a pieței",
    "Market_transaction": "Tranzacționare la preț de piață",
    "Max": "Maxim anulabil",
    "Max Open int": "Maxim deschis",
    "Max buy": "Maxim de cumpărat",
    "Max long": "Deschidere lungă",
    "Max position size at current leverage": "Maxim deschis la levierul curent:",
    "Max sell": "Maxim de vândut",
    "Max short": "Deschidere scurtă",
    "Max position size at current leverage": "Maxim deschis la levierul curent",
    "Mention_money": "Retragere fonduri",
    "Mention_money_address": "Te rog introdu adresa corectă",
    "Mention_money_tips_ios": "Sugestii\n1. Introducerea adresei de retragere necesită verificarea parolei de fonduri, SMS, email sau cod Google.\n2. Adresele adăugate ca legături sunt adresele tale de autentificare, iar la următoarea retragere nu mai e nevoie de verificare.",
    "Messages_notification": "*Te rog confirmă că cardul tău bancar are activată notificarea prin SMS",
    "Mini Programs": "Mini-aplicații",
    "Minimum_handling_charge": "Cantitatea de retragere trebuie să fie mai mare decât taxa",
    "Minimum_withdrawal": "Retragerea minimă pe comanda",
    "MoreNot9": "Numărul de module selectate nu poate depăși 14",
    "Most increase": "Maxim de creștere",
    "Most reduce": "Maxim de reducere",
    "Must be integer multiple of": "Trebuie să fie un multiplu întreg de",
    "Not Enabled": "Neactivat",
    "Not_on": "Momentan indisponibil",
    "Not_to_mention_money": "Pentru siguranța fondurilor tale, retragerile și operațiunile sensibile sunt interzise timp de 1 oră după resetarea parolei de tranzacționare",
    "OTC account": "Cont de monedă fiat",
    "OTC assets": "Portofel OTC",
    "OTC交易须知": "Informații despre tranzacțiile OTC",
    "On Hold": "Înghețat",
    "One-way": "Unidirecțional",
    "One/Two-way": "Unidirecțional/Bidirecțional",
    "Open Time": "Timp de deschidere",
    "Open amount": "Cantitate deschisă",
    "Open int.": "Interes deschis",
    "Open interest": "Interes deschis",
    "Open long": "Deschide lung",
    "Open orders": "Comenzi deschise",
    "Open price": "Preț de deschidere",
    "Open short": "Deschide scurt",
    "Order Details": "Detalii comanda",
    "Order Sorting": "Sortare comenzi",
    "Order Value": "Valoarea comenzii",
    "Order amt": "Cantitate totală a comenzii",
    "Order history": "Ordine istorice",
    "Order price": "Prețul comenzii",
    "Order trigger direction": "Direcția declanșării comenzii",
    "Order type": "Tip de comandă",
    "PL": "Profit și pierdere",
    "PL Ratio": "Rata profitului",
    "Pair01": "Moneda contractului",
    "Parse_failure": "Salvare eșuată",
    "Partially Filled": "Parțial tranzacționat",
    "Partially closed": "Parțial închis",
    "Password": "Parolă",
    "Payment_type": "Tip de plată",
    "Perpetual": "Contract perpetuu",
    "Perpetual funds daybook": "Flux de capital perpetuu",
    "Perpetual01": "Perpetuu",
    "Pingclose": "Închidere",
    "Please input a password": "Te rog introdu o parolă",
    "Position": "Poziție",
    "Position balance": "Soldul poziției",
    "Position history": "Istoricul poziției",
    "QR_code": "Cod QR",
    "Quantity cannot exceed": "Cantitatea nu poate depăși",
    "Quantity not less than": "Cantitatea nu trebuie să fie mai mică de",
    "Recent close": "După închiderea recentă",
    "Recording_requirements": "Cerințe pentru înregistrare video",
    "Required position margin at current leverage": "Marja necesară pentru poziția curentă:",
    "Reset_transaction_password": "Resetează parola de tranzacționare",
    "Revoked": "Anulat",
    "SL": "Stop loss",
    "SL order": "Comandă stop loss",
    "SL order price": "Prețul comenzii stop loss",
    "SL order price can't be empty": "Prețul comenzii stop loss nu poate fi gol",
    "SL trigger price": "Prețul declanșator pentru stop loss",
    "SL trigger price can't be empty": "Prețul declanșator pentru stop loss nu poate fi gol",
    "SMS_30_OUT_RESTRICTED": "Codul de verificare este trimis prea frecvent, poți trimite doar o dată la 30 de secunde.",
    "SMS_BAD_ARGUMENT_FORMAT": "Formatul telefonului nu este corect.",
    "SMS_COUNTRY_NOT_EXITS": "Această țară nu există, te rog verifică și trimite din nou mesajul!",
    "SMS_DAY_LIMIT_PER_MOBILE": "Numărul de mesaje trimise de la același număr a depășit limita în 24 de ore",
    "SMS_DUP_IN_SHORT_TIME": "Același număr a trimis același conținut în 30 de secunde",
    "SMS_FAILURE": "Trimiterea a eșuat",
    "SMS_HOUR_LIMIT_PER_MOBILE": "Numărul de mesaje trimise de la același număr a depășit limita în 1 oră",
    "SMS_MARKET_FORBIDDEN": "Din cauza reglementărilor operatorului, mesajele de marketing nu pot fi trimise temporar",
    "SMS_NOT_SMS_PROVIDER": "Nu există furnizor de SMS, te rog adaugă unul și trimite din nou mesajul!",
    "SMS_NOT_SMS_PROVIDER_TEMPLATE": "Nu există acest șablon pentru furnizorul de SMS, te rog adaugă-l și trimite din nou mesajul!",
    "SMS_PHONE_OVER_COUNT": "Acest număr a depășit numărul de coduri de verificare trimise, te rog încearcă din nou după 24 de ore!",
    "SMS_PHONE_PROHIBIT_SEND": "Acest număr este interzis să primească SMS-uri, te rog încearcă din nou după 24 de ore!",
    "SMS_SUCCESS": "Trimiterea a reușit",
    "SMS_TOO_MANY_TIME_IN_5": "Același număr a trimis același conținut de mai mult de 3 ori în 5 minute",
    "SMS_UNKNOWN_EXCEPTION": "A apărut o excepție necunoscută în sistem",
    "SMS_code": "Cod de verificare SMS",
    "Save picture": "Salvează imaginea",
    "Scanning_to_obtain": "Scanează pentru a obține adresa de retragere",
    "See auto deleverage liquidation for details": "Vezi detalii despre lichidarea automată",
    "Set_transaction_password": "Setează parola de tranzacționare",
    "Settlement1": "Timp până la decontare",
    "Settlrment coin": "Moneda de decontare",
    "Share time": "Timp de partajare",
    "Shielding success": "Blocat cu succes",
    "Short": "Short",
    "Sign Up": "Înscriere",
    "Sign in": "Autentificare",
    "Simplified_Chinese": "Chineză simplificată",
    "StatusOrder": "Starea comenzii",
    "Stop loss": "Setează stop loss",
    "Stop profit stop loss": "Trebuie să alegi cel puțin unul dintre stop profit sau stop loss pentru a plasa comanda",
    "Sustainable account": "Cont perpetuu",
    "Sustainable assets": "Active perpetue",
    "Switch to PnL": "Comută pe profit și pierdere",
    "Switch to PnL ratio": "Comută pe rata profitului și pierderii",
    "TP": "Take profit",
    "TP order": "Comandă take profit",
    "TP order price": "Prețul comenzii take profit",
    "TP order price can't be empty": "Prețul comenzii take profit nu poate fi gol",
    "TP trigger price": "Prețul declanșator pentru take profit",
    "TP trigger price can't be empty": "Prețul declanșator pentru take profit nu poate fi gol",
    "TP | SL": "Take profit și stop loss",
    "TP | SL order": "Comandă take profit și stop loss",
    "Take profit": "Setează take profit",
    "Telegraphic_transfer_address": "Adresa de transfer telegrafic",
    "The Calculator": "Calculator",
    "The maximum flatable quantity is": "Maximul cantității care poate fi închis este",
    "The maximum leverage is": "Maximul levierului este",
    "The minimum flatable quantity is": "Minimul cantității care poate fi închis este",
    "The minimum leverage is": "Minimul levierului este",
    "The poster has been saved to the album": "Posterul a fost salvat în album",
    "The_correct_sample": "Exemplu corect",
    "The_input_signal": "Glisați pentru a introduce parola",
    "The_new_address": "Adresă nouă",
    "They_are_the_number_of": "Numărul de comenzi",
    "Tick size": "Dimensiune minimă",
    "To_update": "Actualizare",
    "Toggle_front_camera": "Comutați la camera frontală și centrați capul",
    "Total": "Total poziție",
    "Total coin": "Număr total de monede",
    "Total cont": "Număr total de contracte",
    "Total value": "Valoare totală",
    "Total_asset_valuation": "Evaluare totală a activelor",
    "Trade type": "Tip de tranzacție",
    "Trade_sector": "Selectați",
    "Transfer": "Transfer",
    "Transfer_to_remind": "Notați la transfer (consecințe pe cont propriu)",
    "Trigger mark price": "Preț de activare",
    "Trigger price": "Preț de declanșare",
    "Two-way": "Bidirecțional",
    "UPL": "P&L nerealizat",
    "US_dollar": "Dolar american",
    "Unblocked": "Deblocat",
    "Uneasy lies the head that wears a crown": "Cu puterea vine și responsabilitatea",
    "Unfilled": "Neexecutat",
    "Unfilled11": "Așteptând executare",
    "Upload_collection_code": "Încărcați codul de plată",
    "Usdt-Margined": "Contract USDT",
    "Users who register": "Utilizatorii noi trebuie să seteze parola",
    "Verification Code": "Cod de verificare",
    "Videoing_requirement": "Cerințe de filmare",
    "View": "Vizualizare",
    "View more": "Detalii poziție",
    "Waiting effect": "Așteptând activare",
    "Waiting_for_the_payment": "Așteptând plată",
    "Wechat": "WeChat",
    "WestUnion": "Western Union",
    "Without_her": "Fără ea",
    "abandoned": "Abandonat",
    "abnormal_assets": "Active anormale, operațiune interzisă!",
    "about_us": "Despre noi",
    "account": "Cont",
    "account_empty": "Cont invalid",
    "account_failure_0001": "Eșec la adăugarea ID-ului de tranzacție!",
    "account_failure_0002": "Fonduri insuficiente!",
    "account_failure_0003": "Eșec la logarea înghețării utilizatorului!",
    "account_failure_0004": "Eșec la înghețarea utilizatorului!",
    "account_failure_0005": "Eșec la logarea deducerii comercianților!",
    "account_failure_0006": "Eșec la deducerea comercianților!",
    "account_failure_0007": "Eșec la logarea creșterii utilizatorului!",
    "account_failure_0008": "Eșec la creșterea utilizatorului!",
    "account_failure_0009": "Eșec la adăugarea de monede noi!",
    "account_failure_0010": "Eșec la adăugarea de monede comercianților!",
    "account_failure_00100": "ID-ul comercianților nu poate fi gol!",
    "account_failure_00101": "ID-ul utilizatorului nu poate fi gol",
    "account_failure_00102": "ID-ul monedei nu poate fi gol",
    "account_failure_00103": "Cantitatea nu poate fi goală",
    "account_failure_00104": "Cantitatea nu poate fi sub minimul de tranzacționare",
    "account_failure_00105": "Parola de tranzacționare nu poate fi goală",
    "account_failure_00106": "Parola de tranzacționare greșită",
    "account_failure_00107": "Prețul nu poate fi gol",
    "account_failure_00108": "Prețul nu poate fi sub minimul de preț",
    "account_failure_00109": "Utilizatorul nu există!",
    "account_failure_0011": "Această monedă există deja!",
    "account_failure_00110": "Eșec la inițierea PUSH de active!",
    "account_failure_00111": "ID-ul PUSH nu poate fi gol",
    "account_failure_00112": "Eșec la plata activelor PUSH!",
    "account_failure_00113": "Eșec la anularea activelor PUSH!",
    "account_failure_00114": "Eșec la obținerea adresei de depunere",
    "account_failure_00115": "Adresa de retragere nu poate fi goală!",
    "account_failure_00116": "Eșec la adăugarea adresei de retragere",
    "account_failure_00117": "ID-ul nu poate fi gol",
    "account_failure_00118": "Eșec la ștergerea adresei de retragere",
    "account_failure_00119": "Eroare de rețea, vă rugăm să încercați din nou!",
    "account_failure_0012": "Înghețare insuficientă, nu se poate procesa!",
    "account_failure_00120": "Această adresă de retragere există deja",
    "account_failure_00121": "Niciun tip de monedă PUSH disponibil",
    "account_failure_00122": "Codul de verificare SMS este gol!",
    "account_failure_00123": "Codul de verificare email este gol!",
    "account_failure_00124": "Codul de verificare Google este gol!",
    "account_failure_00125": "Codul de verificare greșit!",
    "account_failure_00126": "Auto-push-ul este interzis!",
    "account_failure_00127": "Adresa de retragere este invalidă!",
    "account_failure_00129": "Modificarea parolei nu este permisă în următoarele 24 de ore",
    "account_failure_0013": "Eșec la logarea deducerii comercianților!",
    "account_failure_00130": "Activele sunt dezechilibrate, nu se poate opera",
    "account_failure_00131": "Activele contului sunt anormale, tranzacția este dezactivată",
    "account_failure_00132": "Eticheta nu corespunde",
    "account_failure_00133": "Tipul de monedă nu există sau este dezactivat",
    "account_failure_0014": "Eșec la deducerea comercianților!",
    "account_failure_0015": "Eșec la logarea creșterii utilizatorului!",
    "account_failure_0016": "Eșec la deducerea dobânzii utilizatorului!",
    "account_failure_0017": "Eșec la deducerea dobânzii comercianților!",
    "account_failure_0018": "Eșec la deducerea dobânzii comercianților!",
    "account_failure_0019": "Eșec la înghețarea utilizatorului!",
    "account_failure_0020": "Eșec la înghețarea utilizatorului, logare eșuată!",
    "account_failure_0021": "Eșec la logarea înghețării utilizatorului!",
    "account_failure_0022": "Eșec la înghețarea utilizatorului!",
    "account_failure_0023": "Eșec la logarea creșterii comercianților!",
    "account_failure_0024": "Eșec la creșterea comercianților!",
    "account_failure_0025": "Eșec la logarea deducerii utilizatorului!",
    "account_failure_0026": "Eșec la deducerea utilizatorului!",
    "account_failure_0027": "Eșec la logarea deducerii utilizatorului!",
    "account_failure_0028": "Eșec la deducerea utilizatorului!",
    "account_failure_0029": "Eșec la revenirea operațiunii, logare eșuată!",
    "account_failure_0030": "Eșec la revenirea operațiunii, logare eșuată!",
    "account_failure_0031": "Active insuficiente pentru a reveni!",
    "account_failure_0032": "Eșec la revenirea operațiunii, logare eșuată!",
    "account_failure_0033": "Eșec la revenirea operațiunii, active comercianților eșuate",
    "account_failure_0034": "Eșec la logarea creșterii comercianților!",
    "account_failure_0035": "Eșec la creșterea comercianților!",
    "account_failure_0036": "Eșec la logarea înghețării comercianților!",
    "account_failure_0037": "Eșec la înghețarea comercianților!",
    "account_failure_0038": "Eșec la logarea înghețării comercianților!",
    "account_failure_0039": "Eșec la înghețarea comercianților!",
    "account_failure_0040": "Eșec la logarea creșterii platformei!",
    "account_failure_0041": "Eșec la creșterea platformei!",
    "account_failure_0042": "Eșec la execuția acestei tranzacții!",
    "account_failure_0043": "Această tranzacție a eșuat!",
    "account_failure_0044": "Această tranzacție a reușit!",
    "account_failure_0045": "Această tranzacție este în curs de desfășurare!",
    "account_failure_0046": "Procesare reușită!",
    "account_failure_0047": "Eșec la revenirea operațiunii, activele platformei au eșuat",
    "account_failure_0048": "Eșec la revenirea operațiunii, logare eșuată",
    "account_failure_0049": "Această monedă există deja!",
    "account_failure_0050": "Eșec la adăugarea de monede noi la platformă!",
    "account_failure_0051": "Această monedă există deja pe platformă!",
    "account_failure_0053": "Tip de tranzacție greșit!",
    "account_failure_0054": "Eșec la creșterea comercianților!",
    "account_failure_0055": "Eșec la logarea creșterii comercianților!",
    "account_failure_0056": "Eșec la transferul de profit între utilizatori și platformă!",
    "account_failure_0057": "Eșec la logarea transferului de profit!",
    "account_failure_0058": "Eșec la înghețarea utilizatorului!",
    "account_failure_0059": "Eșec la logarea înghețării utilizatorului!",
    "account_failure_0060": "Fonduri insuficiente pentru a executa!",
    "account_failure_0061": "Eșec la înghețarea utilizatorului!",
    "account_failure_0062": "Eșec la logarea înghețării utilizatorului!",
    "account_failure_0063": "Eșec la revenirea operațiunii, fără date de operat!",
    "account_failure_0064": "Această monedă nu este disponibilă momentan",
    "account_failure_0065": "Nu aveți permisiunea de a vizualiza această monedă, contactați suportul",
    "account_failure_0071": "Eroare la înregistrarea utilizatorului!",
    "account_failure_0072": "Înregistrarea depunerii utilizatorului nu există sau a fost modificată",
    "activitie_end": "Activitate încheiată",
    "activity_invalid": "Invalida",
    "actual_amount": "Sumă efectivă",
    "actual_exchange_amount": "Sumă efectivă schimbată",
    "actual_to_account": "Sumă efectivă în cont",
    "addSuccess": "Adăugare reușită",
    "add_channel_function": "Adăugare funcție de verificare",
    "add_charge_and_transfer_function": "Adăugare funcție de depunere și transfer",
    "added_alert_setting_function": "Adăugare funcție de alerte",
    "added_day_mode_toggle": "Adăugare comutator mod zi",
    "address_tag": "Etichetă",
    "advanced_limit_order": "Comandă limită avansată",
    "advertising": "Publicitate",
    "advertising_management": "Gestionare publicitate",
    "affirm": "Confirmare",
    "affirm_pass": "Confirmare parolă",
    "again_digits6": "Reintroduceți noua parolă",
    "again_password": "Reintroduceți parola de conectare",
    "airdrop_coin": "Monedă airdrop",
    "airdrop_date": "Data airdrop",
    "airdrop_number": "Număr airdrop",
    "alipay_account": "Cont Alipay",
    "alipay_upload": "*Metodă de încărcare a codului QR Alipay: deschideți Alipay > Receivers > Salvați imaginea, apoi încărcați codul de plată.",
    "all": "Toate",
    "all_buy": "Toate achizițiile",
    "all_country": "Toate țările",
    "all_order": "Toate comenzile",
    "all_sell": "Toate vânzările",
    "already_use": "Activat",
    "amount_of_payout": "Sumă plătită",
    "announcement_all": "Toate anunțurile",
    "announcement_center": "Centru de anunțuri",
    "announcement_official": "Anunț oficial",
    "annualized_rate": "Rată anualizată",
    "appeal_cancel": "Decizie apel, comandă anulată",
    "appeal_complete": "Decizie apel, comandă completă",
    "appeal_dispose_time": "Timp de procesare apel",
    "appeal_of_time": "Timp de trimitere apel",
    "apply_locked": "Aplicare înghețare",
    "asset_allocatio": "Distribuție active",
    "assets_asset_valuation": "Evaluare active",
    "assets_can_transfer": "Transferabil",
    "assets_cash_flow": "Flux de numerar",
    "assets_coin_funds_flow": "Flux de fonduri în monede",
    "assets_currency_assets": "Active în monede",
    "assets_deposit_income_tip": "Această valoare este calculată pe baza ratei de schimb în timp real.",
    "assets_fiat_assets": "Active fiat",
    "assets_fiat_money_flow": "Flux de numerar fiat",
    "assets_flowing_details": "Detalii flux",
    "assets_prompt_001": "[Vă rugăm să salvați cheia pentru a preveni pierderea]",
    "assets_reduced_asset": "Active reduse",
    "assets_row_mining": "Minerit prin comenzi",
    "assets_swipe_direction": "Direcția transferului",
    "assets_transfer": "Transfer",
    "assets_transfer_coin": "Transfer monedă",
    "assets_transfer_count": "Cantitate de transfer",
    "assets_transfer_currency": "Transfer de fonduri",
    "assets_transfer_dialog_bb": "Cont în monede",
    "assets_transfer_dialog_fiat": "Cont fiat",
    "assets_transfer_dialog_from": "De la",
 "assets_transfer_dialog_transfer_to": "Transfer la",
    "assets_transfer_dialog_wallet": "Cont portofel",
    "assets_transfer_record": "Înregistrare transfer",
    "assets_transfer_to": "Către",
    "assets_wallet": "Portofel",
    "assets_wallet_assets": "Active portofel",
    "assets_wallet_assets_transfer_tip": "*Transferul activelor portofelului către contul corespunzător este necesar pentru a efectua tranzacții. Transferurile între conturi nu implică taxe.",
    "assets_wallet_funds_flow": "Flux de fonduri portofel",
    "at_least_one": "Cel puțin un tip de verificare a parolei de tranzacționare trebuie activat",
    "attention_blacklist": "Urmăriți / Blocați",
    "attention_to_see": "Dacă doriți să mă urmăriți, faceți clic pentru a vedea!",
    "audit_result": "Rezultatul auditului",
    "authenticated": "Autentificat",
    "authentication": "Autentificare",
    "available_balance": "Sold disponibil",
    "average_purchase_price": "Preț mediu de achiziție",
    "avoid_close_set": "Setare fără parolă",
    "back": "Înapoi",
    "balance": "Sold",
    "bank_card": "Card bancar",
    "bank_name": "Numele băncii",
    "bank_num": "Numărul cardului bancar",
    "barcode_in_the_box_scan": "Plasați codul QR/barcode în cadru pentru a scana automat",
    "bb_assets": "Active în monede",
    "bb_history_finished": "Finalizat",
    "bb_time": "Timp",
    "bb_used": "Activat",
    "be_usable": "Utilizabil",
    "bear_consequences_your_investment": "Am înțeles și accept riscurile investiției, codul meu de autentificare este:",
    "become": "Devino",
    "been_authenticated": "Ați fost autentificat",
    "beginner_guide_title": "Platformă de tranzacționare a activelor digitale de vârf la nivel mondial",
    "belong_level": "Nivelul de apartenență",
    "between_and": "Între",
    "bill": "Factură",
    "billing_details": "Detalii factură",
    "bind_email": "Leagă email",
    "bind_google": "Google Authenticator",
    "bind_google_authenticator": "Leagă Google Authenticator",
    "bind_phone": "Leagă telefon",
    "branch_name": "Numele sucursalei",
    "bug_order": "Cumpărare",
    "buy": "Cumpără",
    "buy_1": "Cumpără",
    "buy_now": "Cumpără acum",
    "buy_quantity": "Cantitate de cumpărare",
    "buy_rate": "Taxa de cumpărare",
    "buyer": "Cumpărător",
    "buyer_has_paid_attention": "Cumpărătorul a efectuat plata, atenție",
    "buyer_payment": "Cumpărătorul a efectuat plata",
    "buyer_phone": "Numărul de telefon al cumpărătorului",
    "buyer_uid": "Cumpărător",
    "calculate": "Calculează",
    "calculations are for your reference only": "Calculările sunt doar pentru referință",
    "can_available": "Disponibil",
    "canceled": "Anulat",
    "cancellations": "Anulări",
    "centre": "Centru",
    "change1": "Schimbă",
    "change_gesture_password": "Schimbă parola",
    "change_google": "Schimbă Google Authenticator",
    "change_pay_style": "Schimbă metoda",
    "change_phone": "Schimbă telefonul",
    "charge_after_the_transaction": "Taxa se percepe după tranzacție",
    "charge_out": "Taxa trebuie să fie în",
    "check_the_agree": "Acceptați selecția",
    "china": "China",
    "choose_picture": "Alegeți imaginea",
    "clearing_time": "Timp de decontare",
    "click_enter_batch_edit_page": "Faceți clic pentru a accesa pagina de editare în masă",
    "click_on_the_copy": "Click copiere",
    "clinch_a_deal": "Cumpărătorul trebuie să aibă un anumit număr de tranzacții (0 înseamnă fără limită)",
    "close": "Închide",
    "closed_successfully": "Închis cu succes",
    "cloud_mining_pool": "Piscina de minerit în cloud",
    "coin_failure_0001": "Moneda nu există",
    "collect": "Preferințe",
    "collection_time": "Timp de colectare",
    "commission": "Comision obținut",
    "common_failure": "Eșec!",
    "common_failure_0001": "Anomalie de rețea",
    "common_problem": "Instrucțiuni de operare",
    "common_success": "Succes",
    "complete": "Complet",
    "completed": "Tranzacția a fost finalizată",
    "computer_login": "Autentificare pe computer",
    "confirm": "Confirmare",
    "confirm_exit": "Sigur doriți să ieșiți?",
    "confirm_password": "Vă rugăm să confirmați parola de conectare",
    "confirm_submission": "Confirmare trimitere",
    "confirm_the_purchase": "Confirmare cumpărare",
    "confirm_to_give_up": "Confirmare abandonare",
    "confirm_to_sell": "Confirmare vânzare",
    "confirm_upgrade": "Confirmare upgrade",
    "confirm_vote": "Confirmare vot",
    "confirmation_information": "Informații de confirmare",
    "confrim_delete_warn": "Sigur doriți să ștergeți avertizarea?",
    "contact_service": "Contactați serviciul clienți",
    "contact_us": "Contactați-ne",
    "contract_Order": "Comandă de contract",
    "contrct Agree Content": "Pentru a vă proteja drepturile, înainte de a activa contractul, trebuie să citiți cu atenție \"Acordul de Contracte Continue BitMatrix\"",
    "contrct Agree Content footer": "\"Acordul de Contracte Continue BitMatrix\"",
    "contrct Agree Content header": "Pentru a vă proteja drepturile, înainte de a activa contractul, trebuie să citiți cu atenție",
    "contrct_decimal": "Zecimale",
    "convert_into": "Convertit în",
    "converted_currency": "Monedă convertită",
    "copartner-upgrade-failure": "Upgrade eșuat",
    "copartner-upgrade-no": "Condițiile de upgrade nu sunt îndeplinite, nu se poate face upgrade",
    "copartner-upgrade-ok": "Poate fi upgradat",
    "copartner-upgrade-success": "Upgrade reușit",
    "copy_content": "Copiat pe clipboard",
    "country": "Țară",
    "create_time": "Timp de creare",
    "cumulative_income:": "Venit cumulativ:",
    "cumulative_participation": "Participare cumulativă",
    "currency": "Monedă",
    "currency_fee": "Taxa pentru monedă",
    "currency_information": "Informații despre monedă",
    "currency_name": "Numele monedei",
    "currency_not_found": "Tipul de monedă nu există",
    "currency_select": "Selectare monedă",
    "current_day_return": "Returnare zilnică",
    "current_entrust": "Comandă curentă",
    "current_identity": "Identitate curentă",
    "current_polling_date": "Data votului curent",
    "current_status": "Stare curentă",
    "currently_available": "Disponibil în prezent",
    "date": "Data",
    "date_of_birth": "Data nașterii",
    "day_highs_and_lows": "Maxime și minime",
    "day_highs_and_lows1": "Maxime și minime",
    "day_income": "Zile",
    "day_trading": "Tranzacționare zilnică",
    "daytime": "Zi",
    "ddress_management": "Gestionare adresă",
    "deal_amount": "Suma totală",
    "deal_done": "Finalizat",
    "deal_gross": "Tranzacționat / Total",
    "deal_of_the_week": "Tranzacții săptămânale",
    "deal_price": "Preț mediu",
    "deal_sum": "Suma totală",
    "deal_the_detail": "Detalii comandă",
    "deal_volume": "Volum tranzacționat",
    "deduct_coin": "Deductibilă",
    "default_settlement": "Decontare prin default",
    "defaults": "Implicit",
    "deposit": "Depunere rapidă",
    "deposit_details": "Detalii depunere",
    "deposit_income": "Venit din depunere",
    "deposit_lock": "Blochează depunerea",
    "deposit_lock_details": "Detalii blocare depunere",
    "deposit_returned_principal": "Principal returnat",
    "depth": " adâncime",
    "detailed_rules": "Reguli detaliate",
    "details": "Detalii",
    "digits6": "Introduceți 6 cifre pure",
    "direct_market": "Promovare directă",
    "direct_reward": "Recompensă directă",
    "direction": "Direcție",
    "discount_Rate": "Rata de discount",
    "discount_currency_introduction": "Introducerea monedei",
    "discount_dialog_tip1": "Felicitări! Sunteți eligibil",
    "discount_dialog_tip2": "Pentru a participa, și a fost ales aleatoriu de sistem ca utilizator norocos, după obținerea statutului veți avea dreptul să participați!",
    "discount_dialog_tip3": "Sunteți deja eligibil",
    "discount_dialog_tip4": "Pentru a obține statutul, având în vedere că fondurile participării sunt limitate, vă rugăm să participați în timpul evenimentului!",
    "discount_dialog_tip5": "După renunțare, nu puteți participa",
    "discount_dialog_tip6": "La eveniment și nu puteți aplica pentru participare.",
    "discount_dialog_tip7": "Notă: Succesul obținerii statutului va implica o deducere de",
    "discount_purchase": "Răscumpărare cu discount",
    "discounted_or_original_price": "Preț redus / Preț original",
    "dispose_result": "Rezultatul procesării",
    "disqualification": "Renunțare la calificare",
    "divided_earnings": "Venituri împărțite",
    "drag": "Vă rugăm să țineți apăsat sliderul și să-l trageți la dreapta",
    "each_input": "Verificare parolă de tranzacționare pentru fiecare tranzacție",
    "edt_selfchoose": "Editează preferințe",
    "effective": "Eficace",
    "eligibility": "Calificare",
    "email": "Email înregistrat",
    "email_address_format": "Vă rugăm să introduceți un email corect",
    "email_code": "Cod de verificare email",
    "empty_deal_pass": "Parola de tranzacționare nu poate fi goală",
    "end time": "Timp de încheiere",
    "end_date": "Data încheierii",
    "end_time": "Timp de încheiere",
    "enter_account": "Vă rugăm să introduceți telefonul/emailul",
    "enter_cause_of_complaint": "Vă rugăm să introduceți motivul plângerii",
    "enter_fb_buy_price": "Vă rugăm să introduceți prețul de cumpărare",
    "enter_fb_sell_price": "Vă rugăm să introduceți prețul de vânzare",
    "enter_your_password": "Vă rugăm să introduceți parola de conectare",
    "enter_password": "Vă rugăm să introduceți parola",
    "entrust_amount": "Cantitate de comandă",
    "entrust_failure_0001": "Anomalie de rețea",
    "entrust_failure_0002": "Tip de interogare greșit",
    "entrust_failure_0003": "Tipul de potrivire nu poate fi gol",
    "entrust_failure_0004": "ID-ul comerciantului nu poate fi gol",
    "entrust_failure_0005": "ID-ul utilizatorului nu poate fi gol",
    "entrust_failure_0006": "Utilizatorul nu există",
    "entrust_failure_0007": "ID-ul perechii de tranzacționare nu poate fi gol",
    "entrust_failure_0009": "Perechea de tranzacționare nu există",
    "entrust_failure_0010": "Parola de tranzacționare nu poate fi goală",
    "entrust_failure_0011": "Parola de tranzacționare este greșită",
    "entrust_failure_0012": "Tipul de comandă nu poate fi gol",
    "entrust_failure_0013": "Cantitatea de comandă nu poate fi goală",
    "entrust_failure_0014": "Prețul comenzii limitate nu poate fi gol",
    "entrust_failure_0015": "ID-ul comenzii nu poate fi gol",
    "entrust_failure_0016": "Comanda nu există",
    "entrust_failure_0017": "Această comandă a fost ștearsă",
    "entrust_failure_0018": "Această comandă nu poate fi anulată",
    "entrust_failure_0019": "Numărul versiunii nu poate fi gol",
    "entrust_failure_0020": "Comanda de piață nu poate fi anulată",
    "entrust_failure_0021": "Datele curente au fost actualizate",
    "entrust_failure_0022": "Nu există comenzi curente, nu se poate plasa o comandă de piață",
    "entrust_failure_0023": "Perechea de tranzacționare este dezactivată sau moneda asociată este dezactivată",
    "entrust_failure_0024": "Această pereche de tranzacționare nu este activată",
    "entrust_failure_0025": "Prețul comenzii este sub limita minimă sau peste limita maximă",
    "entrust_failure_0026": "Cantitatea comenzii este sub limita minimă sau peste limita maximă",
    "entrust_failure_0027": "Acest cont a fost interzis pentru tranzacționare, vă rugăm să contactați serviciul clienți",
    "entrust_failure_0030": "A depășit maximul zilnic de creștere pentru această pereche de tranzacționare {}",
    "entrust_failure_0031": "A depășit maximul zilnic de scădere pentru această pereche de tranzacționare {}",
    "entrust_msg_match_type_001": "Comandă limitată",
    "entrust_msg_match_type_002": "Comandă de piață",
    "entrust_msg_status_001": "Netranzacționată",
    "entrust_msg_status_002": "Parțial tranzacționată",
    "entrust_msg_status_003": "Complet tranzacționată",
    "entrust_msg_status_004": "Anulată",
    "entrust_msg_type_001": "Vânzare",
    "entrust_msg_type_002": "Cumpărare",
    "entrusted_price": "Preț de încredere",
    "entry_orders": "Comenzi suspendate",
    "equal_principal": "Capital egal",
    "error_email": "Email greșit",
    "error_network_anomaly": "Anomalie de rețea",
    "error_phone": "Vă rugăm să introduceți un număr de telefon corect",
    "error_unknow": "Eroare de rețea",
    "event_detail": "Detalii eveniment",
    "event_detail_warn_tip": "*Atenție: Activele digitale sunt active inovatoare, cu o volatilitate mare a prețurilor. Vă rugăm să judecați rațional și să alegeți cu grijă deciziile de investiție!",
    "event_start_time": "Timp de început al evenimentului",
    "every day": "În fiecare zi",
    "everyday": "În fiecare zi",
    "exchange": "Schimb",
    "exchange_currency": "Monedă de schimb",
    "exchange_details": "Detalii de schimb",
    "exchange_history": "Istoricul schimburilor",
    "exchange_quantity": "Cantitate de schimb",
    "exchange_rules": "Reguli de schimb",
    "exchangeable": "Convertibil",
    "exclusive_posters": "Generați postere exclusive",
    "exclusive_posters-Video": "Generați poster",
    "exit": "Ieșire",
    "expected_return": "Randament estimat",
    "expired": "A expirat",
    "express_area": "Zonă rapidă",
    "face_recognition": "Recunoaștere facială",
    "failure": "Eșec",
    "fb_lookup": "Faceți clic pentru a vizualiza",
    "fb_order_number": "Numărul comenzii",
    "feeze_thaw_msg_status_0001": "Înghețat",
    "feeze_thaw_msg_status_0002": "Dezghețat",
    "fill_in_at_least_one": "Vă rugăm să completați cel puțin un câmp",
    "fill_wrong": "Completarea greșită poate duce la pierderi de active, vă rugăm să verificați cu atenție",
    "filtrate": "Filtrare",
    "filtrate_content": "Filtrați metodele de plată, țările, moneda aici!",
    "financial_management_is_cancel": "Venitul din depozit a fost anulat! Vă rugăm să nu trimiteți din nou!",
    "financial_management_is_delete": "Acest tip de gestionare financiară a fost șters",
    "financial_management_is_disable": "Acest tip de gestionare financiară a fost dezactivat",
    "financial_management_not_cancel": "Investiția periodică nu poate fi anulată!",
    "financial_management_number_not_found": "Cantitatea de investiție nu poate fi goală",
    "financial_management_record_add_failure": "Activele personale ale utilizatorului sunt insuficiente, vă rugăm să depuneți fonduri înainte de a investi!",
    "financial_management_record_less_than_min_number": "Numărul de monede adăugate este mai mic decât numărul minim setat {}",
    "financial_management_type_not_found": "Informațiile despre înregistrarea investiției au eșuat, tipul de gestionare financiară este greșit!",
    "financial_management_type_not_null": "Tipul de investiție nu poate fi gol",
    "find_password": "Recuperați parola",
    "fingerprint": "Amprentă",
    "fingerprint_verification": "Verificare prin amprentă",
    "first_bind_phone": "Vă rugăm să legați mai întâi numărul de telefon",
    "first_period": "Prima perioadă",
    "following_account": "Către următoarele conturi",
    "free": "Comision gratuit",
    "Takerfree": "Comision Taker",
    "Makerfree": "Comision Maker",
    "freeze": "Înghețare",
    "freeze_to_be_processed": "Înghețare pentru procesare, așteptând",
    "fuc_use_discount": "Folosind {} pentru a plăti comisionul de tranzacționare * discount",
    "futu": "Grafic secundar",
    "gathering": "Încasare",
    "gears": "Viteze",
    "generation1": "Generația 1",
    "gesture_error_limit": "Numărul de încercări greșite pentru parola gestuală a fost atins",
    "gesture_password": "Parola gestuală",
    "gesture_unlock": "Deblocare prin gest",
    "get_code": "Obțineți codul",
    "goSign": "Mergeți să vă înregistrați",
    "go_change": "Mergeți să modificați",
    "go_login": "Vă rugăm să vă conectați mai întâi",
    "go_pay": "Mergeți să plătiți",
    "go_setting": "Mergeți la setări",
    "go_to_pick_up": "Mergeți să ridicați",
    "go_to_receiving": "Mergeți să încaseze",
    "go_to_redeem": "Mergeți să răscumpărați",
    "google_verification_code": "Cod de verificare Google",
    "grievance_freeze": "Înghețare pentru plângere",
    "has_been_on": "A fost listat",
    "have_been_frozen": "A fost înghețat",
    "have_received": "A fost încasat",
    "help_center": "Centrul de ajutor",
    "hide_canceled": "Ascundeți anulatele",
    "hide_other": "Arătați toate comenzile suspendate",
    "high_frequency_partner": "Partener de înaltă frecvență",
    "highest_single": "Cel mai mare single",
    "highfrequency_fee_sharing": "Partajare comision înaltă frecvență",
    "hint": "Atenție",
    "hint_password": "Vă rugăm să introduceți parola",
    "hint_phone_email": "Vă rugăm să introduceți telefonul/emailul",
    "historical_transaction": "Tranzacții istorice",
    "history": "Istoric",
    "home_page": "Pagina principală",
    "horizontal_version": "Versiune orizontală",
    "hours": "Ore",
    "i18n_failure_0001": "Eșec de interogare a limbii sistemului",
    "i18n_failure_0002": "Eșec de interogare a dicționarului de țări",
    "iTo_draw_again": "Vă rugăm să introduceți din nou parola",
    "i_see": "Am înțeles",
    "i_see1": "Am înțeles",
    "id_card_back_direction_failure": "Direcția imaginii pe spatele cărții de identitate este greșită",
    "id_card_front_direction_failure": "Direcția imaginii pe fața cărții de identitate este greșită",
    "id_cord": "Număr de identificare",
    "id_type": "Tip de identificare",
    "identification": "Identificare eșuată",
    "identity_authentication": "Autentificare identitate",
    "identity_card": "Carte de identitate",
    "if_at": "Dacă este la",
    "ignore": "Ignoră",
    "immediately_invited": "Invitați imediat",
    "immediately_join": "Alăturați-vă imediat",
    "in_trading": "În tranzacționare",
    "income": "Venit",
    "income_currency_type": "Tipul monedei de depozit",
    "income_deposit_principal": "Principalul de depozit",
    "incomeing": "Venituri în curs",
    "increment quantity": "Cantitatea introdusă nu poate depăși cantitatea maximă permisă",
    "indirect_promotion": "Promovare indirectă",
    "indirect_reward": "Recompensă indirectă",
    "individual_selling_tips": "Nu puteți vinde comenzile dvs.",
    "input_Branch_address": "Vă rugăm să introduceți adresa sucursalei",
    "input_address_labels": "Vă rugăm să introduceți eticheta",
    "input_alipay_account": "Vă rugăm să introduceți contul Alipay",
    "input_bank_card_number": "Vă rugăm să introduceți numărul cardului bancar",
    "input_bank_name": "Vă rugăm să introduceți numele băncii",
    "input_buyer_uid": "Vă rugăm să introduceți UID-ul cumpărătorului",
    "input_currency_referred": "Vă rugăm să introduceți abrevierea monedei",
    "input_deposit_amount": "Vă rugăm să introduceți cantitatea de depozit",
    "input_email": "Vă rugăm să introduceți adresa de email",
    "input_email_code": "Vă rugăm să introduceți codul de verificare prin email",
    "input_id_cord": "Vă rugăm să introduceți numărul de identificare",
    "input_lock_amount": "Vă rugăm să introduceți cantitatea de blocare",
    "input_mention_money_address": "Vă rugăm să introduceți adresa de retragere",
    "input_money": "Vă rugăm să introduceți suma",
    "input_name": "Vă rugăm să introduceți numele",
    "input_newphone_code": "Vă rugăm să introduceți noul număr de telefon",
    "input_nike_name": "Vă rugăm să introduceți un nume de utilizator",
    "input_old_google_code": "Vă rugăm să introduceți codul vechi Google",
    "input_old_pass": "Vă rugăm să introduceți parola veche",
    "input_paypal_acctount": "Vă rugăm să introduceți contul PayPal",
    "input_phone": "Vă rugăm să introduceți numărul de telefon",
    "input_phone_code": "Vă rugăm să introduceți codul de verificare",
    "input_price": "Vă rugăm să introduceți prețul",
    "input_quantity": "Vă rugăm să introduceți cantitatea",
    "input_remark": "Vă rugăm să introduceți o notă",
    "input_service_charge": "Vă rugăm să introduceți comisionul",
    "input_trading_volume": "Vă rugăm să introduceți volumul tranzacționării",
    "input_transaction_password": "Vă rugăm să introduceți parola de tranzacționare",
    "input_wechat_account": "Vă rugăm să introduceți contul WeChat",
    "input_wire_transfer": "Vă rugăm să introduceți adresa de transfer bancar",
    "inputs_numbers_greater_can_number": "Numărul introdus depășește suma transferabilă",
    "insufficient_light": "Lumina este insuficientă",
    "interest": "Interes",
    "invitation_details": "Detalii recompensă invitație",
    "invitation_register_ad_not_found": "Reclama invitației la înregistrare nu există temporar",
    "invitation_register_ad_tatle_not_found": "Titlul reclamei invitației la înregistrare nu există temporar",
    "invitation_register_coin_not_found": "Tipul de monedă pentru invitația la înregistrare nu există temporar",
    "invitation_register_logo_not_found": "Logo-ul invitației la înregistrare nu există temporar",
    "invitation_register_not_found": "Linkul pentru invitația la înregistrare nu există temporar",
    "invite_code": "Cod de invitație (opțional)",
    "invite_code1": "Cod de invitație",
    "invite_commission": "Comision de invitație",
    "invite_gift": "Recompensă pentru invitație",
    "invite_reward": "Recompensă pentru invitație",
    "invited_UID": "UID invitat",
    "invitees_are_available": "Invitații pot obține",
    "inviter-is-user": "Invitatorul nu poate fi același cu utilizatorul",
    "inviter-not-exist": "Invitatorul specificat nu există",
    "inviter_no_null": "Invitatorul nu poate fi gol",
    "ios_24小时排行榜": "Clasament pe 24 de ore",
    "ios_OTC交易须知": "Informații despre tranzacțiile OTC",
    "ios_PAYPAL": "PAYPAL",
    "ios_otc_order_prompt_001": "Dacă nu ați efectuat plata în minute și nu ați apăsat butonul 'Confirmare plată', sistemul va anula automat această comandă după expirarea numărătorii inverse",
    "ios_otc_order_prompt_002": "Dacă nu ați efectuat plata vânzătorului în minute și nu ați apăsat butonul 'Confirmare plată', sistemul va anula automat această comandă după expirarea numărătorii inverse",
    "ios_万": "Mii",
    "ios_上传身份证反面": "Încărcați spatele cărții de identitate",
    "ios_上传身份证正面": "Încărcați fața actului de identitate",
    "ios_两次密碼不一致请再次输入密碼": "Cele două parole nu se potrivesc, vă rugăm să introduceți din nou parola",
    "ios_个确认大约需要": "Confirmarea durează aproximativ",
    "ios_中国大陆": "China continentală",
    "ios_中级合伙人": "Partener intermediar",
    "ios_主版块": "Bloc principal",
    "ios_之间": "între",
    "ios_买单": "Plătește",
    "ios_买方必须成交过几次(0等于不限制)": "Cumpărătorul trebuie să fi finalizat câteva tranzacții (0 înseamnă fără limită)",
    "ios_二维码/条形码": "Cod QR/Cod de bare",
    "ios_付款": "Plată",
    "ios_充币地址": "Adresă de încărcare",
    "ios_先设置交易密码": "Setați mai întâi parola de tranzacție",
    "ios_先设置交易密碼": "Setați mai întâi parola de tranzacție",
    "ios_关闭照明": "Opriți iluminarea",
    "ios_分享失败": "Partajarea a eșuat",
    "ios_分享成功": "Partajarea a avut succes",
    "ios_初级合伙人": "Partener începător",
    "ios_卖单": "Vinde",
    "ios_卖家已付款提示": "Notificare că vânzătorul a plătit",
    "ios_卖方必须成交过几次(0等于不限制)": "Vânzătorul trebuie să fi finalizat câteva tranzacții (0 înseamnă fără limită)",
    "ios_去解绑": "Dezlegați",
    "ios_发布成功": "Publicare reușită",
    "ios_发送成功": "Trimitere reușită",
    "ios_各种费率说明": "Explicații despre diverse rate",
    "ios_团队人数不足": "Numărul echipei este insuficient",
    "ios_复制QQ号": "Copiați numărul QQ",
    "ios_复制地址": "Copiați adresa",
    "ios_如果你已完成线下付款，请点击下方已付款按钮。如果未完成付款，却点击已付款将会受到严厉惩罚，甚至永久冻结账户！": "Dacă ați finalizat plata offline, faceți clic pe butonul 'Plătit' de mai jos. Dacă nu ați finalizat plata și faceți clic pe 'Plătit', veți fi sancționat sever, chiar cu înghețarea permanentă a contului!",
    "ios_客户端或在线钱包将您需要充值的": "Clientul sau portofelul online va necesita încărcarea de",
    "ios_客户端或在线钱包将您需要充幣的": "Clientul sau portofelul online va necesita încărcarea de",
    "ios_客服": "Asistență clienți",
    "ios_密碼设置成功！": "Parola a fost setată cu succes!",
    "ios_将该地址设置为认证地址": "Setați această adresă ca adresă de verificare",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充值，不影响到账。\n3、最小充值金额": "Timp estimat: ore. Vă rugăm să aveți răbdare.\n2. Aceeași adresă poate fi folosită pentru mai multe încărcări, fără a afecta livrarea.\n3. Suma minimă de încărcare",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充幣，不影响到账。\n3、最小充幣金额": "Timp estimat: ore. Vă rugăm să aveți răbdare.\n2. Aceeași adresă poate fi folosită pentru mai multe încărcări, fără a afecta livrarea.\n3. Suma minimă de încărcare",
    "ios_已付款": "Plătit",
    "ios_币币": "Monedă pe monedă",
    "ios_常见问题": "Întrebări frecvente",
    "ios_开启照明": "Activare iluminare",
    "ios_意见反馈": "Feedback",
    "ios_成交": "Tranzacție finalizată",
    "ios_我的": "Al meu",
    "ios_手机号错误": "Număr de telefon greșit",
    "ios_提交成功，等待认证": "Trimitere reușită, așteptând verificarea",
    "ios_提币数量不能大于手续费": "Cantitatea de retragere nu poate fi mai mare decât taxa",
    "ios_提币数量减去手续费后不能": "Cantitatea de retragere minus taxa nu poate fi",
    "ios_收款二维码": "Cod QR de încasare",
    "ios_收藏成功": "Adăugat cu succes la favorite",
    "ios_数目发送到该地址。发送完成后，系统会自动在此交易获得": "Suma a fost trimisă la această adresă. După trimitere, sistemul va obține automat în această tranzacție",
    "ios_暂无网络": "Fără rețea",
    "ios_最多可锁": "Se pot bloca maxim",
    "ios_最大限额": "Limita maximă",
    "ios_最小限额": "Limita minimă",
    "ios_未解锁": "Neînregistrat",
    "ios_没有中国大陆身份证的用户请通过网站版高级认证": "Utilizatorii fără carte de identitate din China continentală, vă rugăm să treceți prin verificarea avansată pe site",
    "ios_法币": "OTC",
    "ios_注册表示同意": "Înregistrarea implică acordul",
    "ios_注册表示同意用户协议": "Înregistrarea implică acordul cu termenii utilizatorului",
    "ios_登录过期，请重新登录": "Autentificarea a expirat, vă rugăm să vă reconectați",
    "ios_直接推荐人uid": "UID recomandat direct",
    "ios_直推人数不足": "Numărul de recomandări directe este insuficient",
    "ios_绑定成功": "Legare reușită",
    "ios_请上传您的身份证": "Vă rugăm să încărcați cartea de identitate",
    "ios_请上传身份证反面照": "Vă rugăm să încărcați fotografia spatelui cărții de identitate",
    "ios_请上传身份证正面照": "Vă rugăm să încărcați fotografia feței cărții de identitate",
    "ios_请先绑定收款方式": "Vă rugăm să legați mai întâi metoda de plată",
    "ios_请先设置支付方式": "Vă rugăm să setați mai întâi metoda de plată",
    "ios_请输入价格和数量": "Vă rugăm să introduceți prețul și cantitatea",
    "ios_请输入新邮箱": "Vă rugăm să introduceți o nouă adresă de email",
    "ios_请输入正确的账号格式": "Vă rugăm să introduceți un format corect al contului",
    "ios_请输入身份证号": "Vă rugăm să introduceți numărul de identificare",
    "ios_请输入限额": "Vă rugăm să introduceți limita",
    "ios_请通过": "Vă rugăm să treceți",
    "ios_资产": "Active",
    "ios_超时": "Timpul a expirat",
    "ios_身份证号": "Numărul de identificare",
    "ios_退出成功": "Dezautentificare reușită",
    "ios_邀请人可获得 * 的佣金": "Recomandatorul poate primi un comision de *",
    "ios_锁仓数量不能为空": "Cantitatea de blocare nu poate fi goală",
    "ios_间接接推荐人uid": "UID recomandat indirect",
    "ios_隐藏其他挂单": "Ascunde alte ordine",
    "ios_高级合伙人": "Partener avansat",
    "is_bind": "Legat",
    "is_delete_select": "Doriți să ștergeți selecția?",
    "issued": "Emis",
    "issued_to_lock": "Emis pentru blocare",
    "k_close": "Închide",
    "k_max": "Maxim",
    "k_min": "Minim",
    "k_num": "Cantitate",
    "k_open": "Deschide",
    "kline_target": "Indicator",
    "language": "Limba",
    "lead_content": "Căutare de ordine, publicitate, setări de primire, urmărește/blochează aici!",
    "least_four": "Vă rugăm să conectați cel puțin 4 puncte",
    "less-than-min": "Sub limita minimă de blocare",
    "lf there are pending": "Dacă există ordine limită cu direcție opusă, acestea vor fi anulate înainte de închiderea totală.",
    "limit": "Setare limită",
    "limit_orderAA": "Ordine limită",
    "limit_set": "Setare limită (opțional)",
    "limited_price": "Preț limitat",
    "liquidation": "Lichidare",
    "liquidation AMT": "Cantitate lichidată",
    "liquidation details": "Detalii lichidare",
    "loading": "Se încarcă",
    "lock-expire": "Perioada de blocare a expirat",
    "lock_amount": "Cantitate blocată",
    "lock_duration": "Durata blocării",
    "lock_person": "Persoana care blochează",
    "lock_record": "Istoricul blocării",
    "lock_remaining": "Cantitate rămasă blocată",
    "lock_reward": "Recompensă pentru blocare",
    "lock_time": "Timp de blocare",
    "locked": "Blocat",
    "locked_closed": "Închis",
    "locked_insufficient_balance": "Sold insuficient",
    "locked_settled": "Stabilit",
    "locked_unsettlement": "Nesettled",
    "locked_warehouse": "În blocare",
    "log_in": "Conectare",
    "login": "Conectare",
    "login_expired": "Autentificarea a expirat, vă rugăm să vă reconectați.",
    "login_forget_pwd": "Ați uitat parola?",
    "login_name_txt": "Nume utilizator",
    "login_pass": "Parola de conectare",
    "logion_welcome_txt": "Bine ați venit!",
    "mail": "Email",
    "mailbox": "Verificare email",
    "mailbox_bind_success": "Legare email reușită",
    "mailbox_new": "Email nou",
    "main_picture": "Imagine principală",
    "manage_reward_details": "Detalii gestionare recompense",
    "management_reward": "Recompensă de management",
    "management_share": "Partajare de management",
    "mandarin": "Vă rugăm să citiți numerele în mandarină",
    "mandarin_reading_content": "Vă rugăm să citiți următorul conținut în limbaj standard",
    "manual_recharge_msg_status_0001": "Așteptând încărcare",
    "manual_recharge_msg_status_0002": "Verificare aprobată",
    "manual_recharge_msg_status_0003": "Încărcare reușită",
    "manual_recharge_msg_type_0001": "Încărcare normală",
    "manual_recharge_msg_type_0002": "Procesare fonduri",
    "manual_recharge_msg_type_0003": "Cadou de activitate",
    "margin": "Marjă",
    "market_failure_00001": "Eșec la obținerea pieței de start",
    "market_failure_00002": "ID comerciant inexistent",
    "market_failure_00003": "Nu există zone de tranzacționare disponibile pentru comerciant",
    "market_failure_00004": "Eșec la obținerea pieței de schimb",
    "market_failure_00005": "Numele perechii de tranzacționare nu poate fi gol",
    "market_failure_00006": "Nu există informații disponibile despre secțiuni pentru comerciant",
    "market_failure_00007": "Eșec la obținerea informațiilor despre perechea de tranzacționare",
    "market_failure_00008": "Eșec la obținerea datelor K-line",
    "market_failure_00009": "Eșec la obținerea datelor de adâncime",
    "market_failure_00010": "Parametrii cererii sunt greșiți",
    "market_failure_00011": "Pereche de tranzacționare invalidă",
    "market_low_price": "Prețul maxim de cumpărare pe piață",
    "market_order": "Ordine piață",
    "market_price": "Preț de piață",
    "market_sell_price": "Prețul minim de vânzare pe piață",
    "market_switch_moved_here": "Comutarea între orizontal/vertical s-a mutat aici",
    "match": "Potrivire",
    "match_the_number_of": "Cantitate de potrivire",
    "max_lock_volume": "Volumul maxim de blocare",
    "maximum_30_characters": "Maxim 30 de caractere",
    "maximum_coins": "Cantitatea maximă de retragere este",
    "maximum_withdrawal": "Retragerea maximă pe o singură tranzacție",
    "mention_a_currency": "Cantitate de retragere",
    "min_lock_volume": "Volumul minim de blocare",
    "min_recharge_amount": "Încărcare mică. Dacă suma totală de încărcare ajunge la minimul {}*, va fi creditată automat",
    "minhour": "Minut",
    "minimum_coins": "Cantitatea minimă de retragere este",
    "minimum_purchase_quantity": "Cantitatea de cumpărare trebuie să fie mai mare decât cantitatea rămasă {}",
    "minimum_quantity_sold": "Cantitatea de vânzare trebuie să fie mai mare decât cantitatea rămasă {}",
    "minutes": "Min",
    "mobile phone": "Număr de telefon",
    "money": "Sumă",
    "more": "Mai mult",
    "more Or than": "Long: Valoare * număr * preț mediu de închidere - valoare * număr * preț mediu de deschidere\n\nShort: Valoare * număr * preț mediu de deschidere - valoare * număr * preț mediu de închidere",
    "more-than-max": "Peste limita maximă de blocare",
    "more11": "Mai multe",
    "more_can_save": "Maxim de depozitat",
    "more_home": "Mai multe",
    "msg_camera_framework_bug": "Vă rugăm să activați permisiunea camerei",
    "must_retain_one": "Trebuie să păstrați cel puțin una: telefon, Google, email",
    "my_income": "Venitul meu",
    "my_prompt_001": "*După modificarea numărului de telefon, va trebui să vă conectați cu noul număr.",
    "my_prompt_002": "*Notă: Vă rugăm să folosiți un cont real pe numele dvs., metodele de plată activate vor fi afișate cumpărătorului în tranzacțiile OTC, puteți seta 5, dar activați maximum 3.",
    "my_prompt_003": "*După modificarea adresei de email, va trebui să vă conectați cu noua adresă.",
    "my_vote": "Votul meu",
    "name": "Nume",
    "name_empty": "Numele nu poate fi gol",
    "nationality": "Naționalitate",
    "need_me_record_video": "Trebuie să înregistrez un videoclip personal",
    "never_enter": "Nu introduceți niciodată parola de tranzacție",
    "new_address": "Adresă nouă",
    "new_pass": "Parola nouă",
    "new_pass_empty": "Vă rugăm să introduceți parola nouă",
    "new_pass_minimum": "Parola nu poate fi mai mică de 6 caractere",
    "new_report_statistics": "Statistici raport noi",
    "newest_versions": "Versiunea curentă este cea mai recentă!",
    "next": "Pasul următor",
    "nick_name": "Pseudonim",
    "nickname_des_txt": "Pseudonimul suportă doar caractere în chineză și engleză, numere, limită de lungime 15 caractere",
    "nighttime": "Noaptea",
    "ninety_days": "Ultimele 90 de zile",
    "no-lock-detail": "Nu există informații despre blocare",
    "no_Senior_certification": "Vă rugăm să efectuați întâi certificarea avansată",
    "no_data": "Nu există date disponibile",
    "no_flash": "Dispozitivul curent nu are blitz, nu se poate activa funcția de iluminare",
    "no_more_data": "Nu există mai multe date",
    "no_photo_taking": "Dispozitivul dumneavoastră nu suportă fotografierea",
    "no_price": "Prețul nu poate fi gol",
    "no_quantity": "Vă rugăm să introduceți o cantitate corectă",
    "no_realname_auth": "Vă rugăm să efectuați întâi certificarea cu nume real",
    "no_use": "Neutilizat",
    "none": "Niciunul",
    "not_bound": "Nelegat",
    "not_frequency_partner": "Nu sunteți partener în prezent",
    "not_redemption": "Fără calificare de răscumpărare",
    "not_started": "Nu a început",
    "not_to_issue": "Neemis",
    "not_trading": "Această criptomonedă nu poate fi tranzacționată în prezent",
    "notpass": "Verificarea nu a fost aprobată",
    "null_address_tag": "Eticheta adresei nu poate fi goală",
    "null_code": "Codul de verificare nu poate fi gol",
    "null_email": "Emailul nu poate fi gol",
    "null_password": "Parola nu poate fi goală",
    "null_phone": "Numărul de telefon nu poate fi gol",
    "number": "bucăți",
    "number_empty": "Cantitatea nu poate fi 0",
    "number_of_arrivals": "Cantitate primită",
    "number_of_issues": "Cantitate totală emisă",
    "number_of_withdrawal": "Numărul de retrageri pe zi",
    "obtained": "Retirat",
    "occlusion": "Obstrucție a feței",
    "occupy": "Ocupație",
    "official_community": "Comunitate oficială",
    "official_verification_channel": "Canal de verificare",
    "old_pass": "Parola veche",
    "old_pass_empty": "Vă rugăm să introduceți parola originală",
    "on_sale": "De vânzare",
    "online_service": "Serviciu clienți online",
    "open XKD perpetual swap": "Deschideți contractul perpetuu BitMatrix",
    "open perpetual swap": "Deschideți contractul perpetuu",
    "open_app": "Deschide aplicația",
    "open_it": "Deschideți",
    "open_successfully": "Deschidere reușită",
    "open_touchID": "Doriți să activați TouchID?",
    "opposite_side_uid": "Partea opusă",
    "option": "Opțiune",
    "optional": "Opțional",
    "optional_area": "Zona opțională",
    "options": "Modalitate de selecție",
    "order": "Comandă",
    "order time": "După timpul comenzii",
    "order time1": "Timpul comenzii",
    "order_a_complaint": "Plângere pentru comandă",
    "order_details": "Detalii comandă",
    "order_form": "Formular de comandă",
    "order_history": "Ordine istorice",
    "order_inquiry": "Întrebare despre comandă",
    "order_number": "Numărul comenzii",
    "order_time": "Timpul plasării comenzii",
    "ordinary_partner": "Partener obișnuit",
    "otc_account": "Cont",
    "otc_confirm_Put_the_coin": "Confirmați că ați primit transferul de la partea opusă! (Atenție: Nu faceți clic pe confirmare înainte de a primi banii; în caz de dispută, contactați serviciul clienți pentru plângeri)",
    "otc_confirm_the_payment": "Confirmați plata",
    "otc_confirm_the_receipt": "Confirmați primirea",
    "otc_failure_0001": "Contul a fost dezactivat, vă rugăm să contactați serviciul clienți cât mai repede",
    "otc_failure_0002": "Contul nu a trecut certificarea avansată, nu poate efectua operațiuni OTC",
    "otc_failure_0003": "Parola contului de tranzacționare este incorectă",
    "otc_failure_0004": "ID-ul comenzii este invalid",
    "otc_failure_0006": "Eșec la schimbarea stării",
    "otc_failure_0009": "Tipul de comandă nu există",
    "otc_failure_0010": "Anularea a eșuat",
    "otc_failure_0011": "Această comandă a fost deja finalizată sau anulată",
    "otc_failure_0012": "Utilizatorul curent nu este comerciant OTC, nu are dreptul să folosească această funcție",
    "otc_failure_0013": "Dezghețarea a eșuat, anularea a eșuat",
    "otc_failure_0015": "Nu există acest tip de comandă",
    "otc_failure_0016": "Cantitatea comenzii nu poate fi mai mică sau egală cu 0",
    "otc_failure_0017": "Prețul de tranzacționare nu poate fi mai mic sau egal cu 0",
    "otc_failure_0018": "Limita minimă pe tranzacție nu poate fi mai mică decât 0",
    "otc_failure_0019": "Limita maximă pe tranzacție nu poate fi mai mică decât limita minimă",
    "otc_failure_0020": "Această monedă fiat nu este acceptată",
    "otc_failure_0021": "Această monedă fiat nu este disponibilă în prezent",
    "otc_failure_0022": "ID-ul criptomonedei comerciantului este greșit",
    "otc_failure_0023": "Limita minimă pe tranzacție nu poate fi mai mică decât limita minimă a criptomonedei {}",
    "otc_failure_0024": "Limita maximă pe tranzacție nu poate fi mai mare decât limita maximă a criptomonedei {}",
    "otc_failure_0025": "Numărul de zecimale în comandă este mai mare decât {}",
    "otc_failure_0026": "Suma totală a comenzii nu poate fi mai mică decât limita de început {}",
    "otc_failure_0027": "Suma totală a comenzii nu poate fi mai mare decât limita utilizatorului {}",
    "otc_failure_0028": "Suma totală a comenzii nu poate fi mai mare decât limita comerciantului {}",
    "otc_failure_0029": "Prețul nu poate fi mai mic decât {}",
    "otc_failure_0030": "Prețul nu poate fi mai mare decât {}",
    "otc_failure_0031": "Utilizatorul curent nu a legat un cont de plată",
    "otc_failure_0032": "Înghețarea a eșuat, comanda a eșuat",
    "otc_failure_0033": "Numărul maxim de comenzi procesate simultan nu poate fi mai mic decât 0",
    "otc_failure_0034": "Numărul de tranzacții ale cumpărătorului nu poate fi mai mic decât 0",
    "otc_failure_0035": "Acest utilizator a fost certificat ca comerciant sau este în curs de verificare",
    "otc_failure_0036": "Numărul de tranzacții reușite ale solicitantului OTC este insuficient",
    "otc_failure_0037": "Dezghețarea a eșuat, anularea a eșuat",
    "otc_failure_0038": "Soldul disponibil al contului solicitantului {} este insuficient {}",
    "otc_failure_0039": "Nu există criptomonedă curentă",
    "otc_failure_0040": "Criptomoneda curentă nu este disponibilă",
    "otc_failure_0041": "Trimitere reușită, vă rugăm să așteptați verificarea",
    "otc_failure_0042": "Ne pare rău, acest utilizator se află în prezent în stare de așteptare pentru verificare, nu poate solicita din nou",
    "otc_failure_0043": "Nu există acest tip de tranzacție",
    "otc_failure_0044": "Auto-cumpărarea și auto-vânzarea sunt interzise",
    "otc_failure_0046": "Numărul maxim de comenzi procesate simultan {} a atins limita, vă rugăm să încercați din nou mai târziu",
    "otc_failure_0047": "Cumpărătorul trebuie să aibă cel puțin {} tranzacții finalizate",
    "otc_failure_0048": "Cantitatea de cumpărare nu poate fi mai mică sau egală cu 0",
    "otc_failure_0049": "Cantitatea de cumpărare nu poate fi mai mare decât cantitatea rămasă a comenzii",
    "otc_failure_0050": "ID-ul comenzii este greșit",
    "otc_failure_0051": "Suma totală de cumpărare nu poate fi mai mică decât limita minimă de tranzacționare {}",
    "otc_failure_0052": "Suma de cumpărare nu poate fi mai mare decât limita maximă de tranzacționare {}",
    "otc_failure_0053": "Tipul curent al comenzii nu este vânzare",
    "otc_failure_0054": "Starea curentă a comenzii nu este corectă",
    "otc_failure_0055": "Suma de cumpărare nu poate fi mai mică decât limita minimă de extragere {}",
    "otc_failure_0056": "Suma de cumpărare nu poate fi mai mare decât limita maximă de extragere {}",
    "otc_failure_0057": "Nu există acest statut",
    "otc_failure_0058": "Numărul de modificări ale comenzii nu este corect",
    "otc_failure_0059": "Înghețarea a eșuat, extragerea a eșuat",
    "otc_failure_0060": "Vă rugăm să legați un cont de plată de tip comerciant în setările de plată!",
    "otc_failure_0061": "Vânzătorul trebuie să aibă cel puțin {} tranzacții finalizate",
    "otc_failure_0062": "Cantitatea de vânzare nu poate fi mai mică sau egală cu 0",
    "otc_failure_0063": "Cantitatea de vânzare nu poate fi mai mare decât cantitatea rămasă a comenzii",
    "otc_failure_0064": "Tipul curent al comenzii nu este cumpărare",
    "otc_failure_0065": "Suma totală de vânzare nu poate fi mai mică decât limita minimă de tranzacționare {}",
    "otc_failure_0066": "Suma de vânzare nu poate fi mai mare decât limita maximă de tranzacționare {}",
    "otc_failure_0067": "Suma de vânzare nu poate fi mai mică decât limita minimă de extragere {}",
    "otc_failure_0068": "Suma de vânzare nu poate fi mai mare decât limita maximă de extragere {}",
    "otc_failure_0069": "ID-ul contului de plată este greșit",
    "otc_failure_0070": "Eșec la confirmarea plății",
    "otc_failure_0073": "Eșec la schimbarea stării comenzii",
    "otc_failure_0074": "Eșec la conversia criptomonedelor, eșec la confirmarea primirii",
    "otc_failure_0075": "Metoda de plată nu poate fi goală",
    "otc_failure_0076": "Eșec la trimiterea mesajului {}",
    "otc_failure_0077": "Această criptomonedă nu există",
    "otc_failure_0078": "Această monedă fiat nu există",
    "otc_failure_0079": "Eșec la schimbarea stării comenzii",
    "otc_failure_0080": "Această criptomonedă este dezactivată",
    "otc_failure_0081": "Această monedă fiat este dezactivată",
    "otc_failure_0082": "Această comandă nu există",
    "otc_failure_0083": "OTC nu are setată o monedă fiat pentru tranzacționare",
    "otc_failure_0084": "OTC nu are setată o criptomonedă pentru tranzacționare",
    "otc_failure_0085": "Cantitatea rămasă a comenzii este insuficientă",
    "otc_failure_0090": "Acest cont a fost interzis de la tranzacționarea OTC, vă rugăm să consultați serviciul clienți",
    "otc_failure_0091": "Utilizatorii obișnuiți nu pot plasa ordine OTC, vă rugăm să aplicați mai întâi pentru a deveni comerciant",
    "otc_failure_0092": "Comanda curentă nu a depășit timpul de primire, cumpărătorul nu poate depune plângeri",
    "otc_failure_0093": "Această comandă a fost deja contestată, vă rugăm să contactați serviciul clienți",
    "otc_failure_0094": "Conform reglementărilor din zona dumneavoastră de înregistrare, nu este posibilă această operațiune",
    "otc_failure_0095": "Această comandă nu poate fi tranzacționată în prezent",
    "otc_failure_0096": "Activele contului vânzătorului sunt anormale, nu se poate efectua această operațiune",
    "otc_failure_0097": "Numărul de anulări OTC în aceeași zi a depășit limita, tranzacționarea este interzisă",
    "otc_failure_0098": "Aveți comenzi nefinalizate, vă rugăm să finalizați comenzile înainte de a continua",
    "otc_margin_certification": "Certificare garanție",
    "otc_mine_account_paid": "Am efectuat plata",
    "otc_order_prompt_003": "Dacă cumpărătorul nu efectuează plata și nu apasă butonul 'Confirmare plată' în termenul stabilit, sistemul va anula automat această comandă după expirarea cronometrajului",
    "otc_picture_no_null": "Vă rugăm să încărcați cel puțin o imagine",
    "otc_prompt_001": "1. Valabilitatea plății pentru comandă este de 30 de minute, vă rugăm să efectuați plata la timp și să apăsați butonul (Am efectuat plata).\n2. Moneda vânzătorului este blocată de sistem, vă rugăm să plasați comanda fără îngrijorare.",
    "otc_rate": "Rata",
    "otc_report_order": "Comandă finalizată",
    "otc_success_0001": "Anulare reușită",
    "otc_success_0002": "Vânzare reușită",
    "otc_success_0003": "Cumpărare reușită",
    "otc_success_0004": "Confirmare plată reușită",
    "otc_success_0005": "Confirmare primire reușită, comandă finalizată",
    "otc_success_0006": "Plângere reușită",
    "otc_waiting_for_confirmation": "Așteptând confirmarea vânzătorului",
    "other_settings": "Alte setări",
    "overtime_pay": "Plată întârziată, comanda a fost anulată",
    "param-error": "Eroare de parametru",
    "partner": "Partener",
    "partner_commission": "Partenerii pot obține comision din taxele subordonaților",
    "partner_commission_fee": "Comision",
    "partner_current_level": "Nivel curent",
    "partner_get_all_subordinate": "Puteți obține toate subordonații",
    "partner_management": "Gestionarea partenerilor",
    "partner_need_pay": "Necesită plată",
    "partner_need_recommend": "Necesită recomandări directe",
    "partner_recommended": "Recomandat direct",
    "partner_start_and_end_time": "Perioada",
    "partner_team": "Echipă",
    "partner_team_member": "Numărul de membri ai echipei trebuie să atingă",
    "partner_upgrade_immediately": "Upgrade imediat",
    "pass_muster": "Dacă nu corespunde cerințelor, faceți clic aici",
    "passed": "Aprobat",
    "passport": "Pașaport",
    "password_disagree": "Cele două parole nu se potrivesc",
    "password_is_different": "Parolele introduse nu se potrivesc",
    "password_text": "Parola",
    "password_wrong": "Setați o parolă de 8-20 caractere",
    "past_activities": "Activități anterioare",
    "pause_recharge": "Această criptomonedă nu poate fi alimentată",
    "pause_withdraw": "Această criptomonedă nu poate fi retrasă",
    "pay_number": "Cantitate de plată",
    "pay_wey": "Metoda de plată",
    "payee": "Beneficiar",
    "payfor_type": "Tip de plată",
    "payment": "Plată",
    "payment_amount": "Sumă de plată",
    "payment_currency": "Moneda de plată",
    "payment_has_been": "(Am efectuat plata)",
    "payment_term": "Metoda de primire",
    "paypal_account": "Cont PayPal",
    "pending_review": "În așteptarea revizuirii",
    "people": "Persoane",
    "period": "Perioadă",
    "personal_detail": "Detalii personale",
    "personal_purchase_tips": "Nu puteți cumpăra comenzile proprii",
    "phone": "Telefon",
    "phone_code": "Cod de verificare telefonic",
    "phone_register": "Înregistrare telefonică",
    "phone_verification": "Verificare SMS",
    "phone_verify": "Verificare telefonică",
    "photo_album": "Album foto",
    "please_enter": "Vă rugăm să introduceți",
    "please_enter_abbreviation_or_letter": "Vă rugăm să introduceți conținutul căutat",
    "please_input_content": "Vă rugăm să introduceți conținutul",
    "please_pay": "Vă rugăm să efectuați plata",
    "please_verification": "Vă rugăm să verificați",
    "pop_Date": "Dată",
    "pop_reset": "Resetare",
    "position at market price?": "Doriți să închideți poziția la prețul de piață?",
    "post_only": "Doar pentru Maker (Post only)",
    "price": "Preț",
    "price_cannot_be_less": "Prețul nu poate fi mai mic decât {}",
    "price_cannot_be_more": "Prețul nu poate fi mai mare decât {}",
    "price_empty": "Prețul nu poate fi 0",
    "principal": "Capital",
    "promote_links": "Linkuri de promovare",
    "promotion_statistical": "Statistici promovare",
    "prtner_invalid": "Invalide",
    "publish_a_trade_order": "Publicați o comandă de tranzacționare",
    "purchase": "Cumpărare",
    "push": "PUSH active",
    "push_maximum_volume": "Cantitatea PUSH este mai mare decât soldul disponibil {}",
    "push_minimum_trading_volume": "Cantitatea PUSH este mai mică decât volumul minim de tranzacționare {}",
    "quantity": "Cantitate",
    "quantity_deal": "Cantitate/Finalizat",
    "quota": "Limita",
    "range": "Interval",
    "real_income": "Venit real",
    "real_name_authentication": "Certificare cu nume real",
    "reason": "Motiv",
    "rebate_amount": "Sumă de rambursare",
    "rebate_record": "Raport de rambursare",
    "rebate_reward": "Recompensă rambursare",
    "receiving_set": "Setări de primire",
    "receiving_set_title": "Vă rugăm să configurați metoda de primire",
    "recently_deal1": "Tranzacții recente",
    "recharge_notice": "Informații despre alimentare\n1. Vă rugăm să trimiteți cantitatea de {} pe care doriți să o alimentați la această adresă prin intermediul {} clientului sau a unui portofel online. După ce trimiteți, sistemul va alimenta criptomoneda în contul dumneavoastră de pe acest site după ce obține {} confirmații. Vă rugăm să așteptați cu răbdare.\n2. Aceeași adresă poate fi utilizată pentru alimentări multiple, fără a afecta contul.\n3. Suma minimă pentru alimentare este {}.",
    "recharge_source": "Sursa alimentării",
    "recharge_withdraw_failure_0001": "Anomalie de rețea",
    "recharge_withdraw_failure_0002": "Parola de tranzacție nu poate fi goală",
    "recharge_withdraw_failure_0003": "Codul de verificare nu poate fi gol",
    "recharge_withdraw_failure_0004": "ID-ul comerciantului nu poate fi gol",
    "recharge_withdraw_failure_0005": "ID-ul utilizatorului nu poate fi gol",
    "recharge_withdraw_failure_0006": "Utilizatorul nu există",
    "recharge_withdraw_failure_0007": "ID-ul criptomonedei nu poate fi gol",
    "recharge_withdraw_failure_0009": "Criptomoneda nu există",
    "recharge_withdraw_failure_0010": "Codul Google nu poate fi gol",
    "recharge_withdraw_failure_0011": "Parola de tranzacție este greșită",
    "recharge_withdraw_failure_0012": "Tipul de alimentare nu poate fi gol",
    "recharge_withdraw_failure_0013": "Cantitatea de alimentare nu poate fi goală",
    "recharge_withdraw_failure_0014": "Notițele pentru alimentare nu pot fi goale",
    "recharge_withdraw_failure_0015": "ID-ul alimentării nu poate fi gol",
    "recharge_withdraw_failure_0016": "Comanda de alimentare nu există",
    "recharge_withdraw_failure_0017": "Numai comenzile așteptând alimentarea pot fi șterse",
    "recharge_withdraw_failure_0018": "Numai comenzile așteptând alimentarea pot fi verificate",
    "recharge_withdraw_failure_0019": "Numai comenzile verificate pot fi deblocate",
    "recharge_withdraw_failure_0020": "Numărul versiunii nu poate fi gol",
    "recharge_withdraw_failure_0021": "Eroare de parametru",
    "recharge_withdraw_failure_0022": "Datele curente au fost actualizate",
    "recharge_withdraw_failure_0023": "ID-ul nu poate fi gol",
    "recharge_withdraw_failure_0024": "Numai comenzile blocate pot fi verificate",
    "recharge_withdraw_failure_0025": "Numai comenzile așteptând retragerea pot fi blocate",
    "recharge_withdraw_failure_0026": "Numai comenzile așteptând retragerea sau blocate pot fi anulate",
    "recharge_withdraw_failure_0027": "Cantitatea de blocare nu poate fi goală",
    "recharge_withdraw_failure_0028": "Motivul blocării nu poate fi gol",
    "recharge_withdraw_failure_0029": "Notițele pentru deblocare nu pot fi goale",
    "recharge_withdraw_failure_0030": "Cantitatea de retragere nu poate fi goală",
    "recharge_withdraw_failure_0031": "Adresa de retragere nu poate fi goală",
    "recharge_withdraw_failure_0032": "Taxa de procesare nu poate fi goală",
    "recharge_withdraw_failure_0033": "Anomalie în taxa de procesare",
    "recharge_withdraw_failure_0034": "Datele nu există",
    "recharge_withdraw_failure_0035": "Această comandă nu poate fi deblocată",
    "recharge_withdraw_failure_0036": "Adresa de retragere este incorectă",
    "recharge_withdraw_failure_0041": "Acest cont a fost interzis de la retragere, vă rugăm să contactați suportul",
    "recharge_withdraw_failure_0042": "Numărul de retrageri a atins limita zilnică",
    "recharge_withdraw_failure_0043": "Cantitatea totală de retragere depășește limita zilnică",
    "recharge_withdraw_failure_0044": "Anomalie în obținerea informațiilor despre nivelul VIP",
    "recharge_withdraw_failure_0045": "Anomalie în obținerea informațiilor despre criptomoneda comerciantului",
    "recharge_withdraw_failure_0046": "Retragerile sunt interzise timp de 24 de ore după resetarea parolei!",
    "recharge_withdraw_failure_0048": "Anomalie în obținerea valorii maxime per retragere",
    "recharge_withdraw_failure_0049": "Anomalie în obținerea valorii minime per retragere",
    "recharge_withdraw_failure_0050": "Cantitatea introdusă trebuie să fie mai mică decât limita maximă",
    "recharge_withdraw_failure_0051": "Cantitatea introdusă trebuie să fie mai mare decât limita minimă",
    "recharge_withdraw_msg_status_0001": "Așteptând retragerea",
    "recharge_withdraw_msg_status_0002": "În curs de trimitere",
    "recharge_withdraw_msg_status_0003": "Retragerea a avut succes",
    "recharge_withdraw_msg_status_0004": "Anulată",
    "recharge_withdraw_msg_status_0005": "În curs de confirmare",
    "recharge_withdraw_msg_status_0006": "Alimentare reușită",
    "recharge_withdraw_msg_status_0007": "Verificată",
    "recharge_withdraw_msg_type_0001": "Alimentare",
    "recharge_withdraw_msg_type_0002": "Retragere",
    "recharge_withdraw_msg_withdraw_type_0001": "Transfer între platforme",
    "recharge_withdraw_msg_withdraw_type_0002": "Retragere externă",
    "record": "Înregistrare",
    "record_the_video": "Faceți clic pentru a înregistra video",
    "recording": "Reînregistrare",
    "redeem_now": "Răscumpărați acum",
    "redeemed": "Răscumpărat",
    "redeeming": "În proces de răscumpărare",
    "redemption_amount": "Cantitate de răscumpărare",
    "redemption_time": "Timp de răscumpărare",
    "referrer": "Recomandator",
    "register": "Înregistrare",
    "registered_successfully": "Înregistrare reușită",
    "registration_award_details": "Detalii despre recompensele de înregistrare",
    "registration_incentives": "Recompense de înregistrare",
    "registration_time": "Timp de înregistrare",
    "regular": "Regulat",
    "regular_return": "Returnare lunară regulată",
    "release": "Publicare",
    "release_operation": "Publicați comanda, faceți clic aici pentru a acționa!",
    "release_the_order": "Publicați comanda",
    "release_time": "Timp de eliberare",
    "remark": "Notă",
    "remember_state": "Păstrați starea de conectare",
    "repeal": "Anulați",
    "reset": "Resetare",
    "reset_login_password": "Modificați parola de conectare",
    "reset_pass": "Resetați parola",
    "residue": "Rămășițe",
    "retrieve_the_deposit": "Recuperați criptomoneda",
    "return_amount": "Cantitate de returnare",
    "return_method": "Metodă de returnare",
    "return_the_principal": "Returnați capitalul",
    "revenue_details": "Detalii despre venituri",
    "revocation_all": "Anulați tot",
    "reward_amount": "Cantitate de recompensă",
    "reward_locked": "Recompensă blocată",
    "reward_status": "Starea recompensei",
    "reward_type": "Tip de recompensă",
    "rewards_record": "Înregistrare recompense",
    "right_now_register": "Înregistrare utilizator nou",
    "roll_out": "Transfer",
    "save": "Salvați",
    "save_coin": "Depunere",
    "save_coin_count": "Cantitate de depunere",
    "save_coin_days": "Zile de depunere",
    "save_coin_duration": "Durata depunerii",
    "save_currency": "Depunere criptomonedă",
    "save_money_amount": "Total depunere",
    "save_successfully": "Salvare reușită",
    "save_the_image": "Descarcă imaginea",
    "scan_QR_code": "Vă rugăm să nu scanați coduri QR de la surse necunoscute",
    "scan_result": "Rezultatul scanării",
    "search": "Căutare",
    "search_coin_placeholder": "Căutați criptomoneda de interes",
    "search_keyword": "Cuvânt cheie de căutare",
    "search_name": "Căutați numele",
    "search_result": "Rezultatul căutării",
    "search_trade_pair_placeholder": "Căutați perechea de tranzacționare de interes",
    "security_center": "Centru de securitate",
    "security_level": "Nivel de securitate",
    "select_date_of_birth": "Vă rugăm să selectați data nașterii",
    "select_delete_warn": "Vă rugăm să selectați alertă de șters",
    "sell": "Vinde",
    "sell_1": "Vânzare",
    "sell_order": "Vânzare",
    "sell_quantity": "Cantitate de vânzare",
    "sell_rate": "Comision de vânzare",
    "seller": "Vânzător",
    "seller_phone": "Număr de telefon al vânzătorului",
    "send": "Trimite",
    "senior_certification": "Certificare avansată",
    "senior_certification1": "Certificare avansată",
    "service": "Serviciu clienți QQ:",
    "service_charge": "Taxă de procesare",
    "session_timeout": "Conexiune expirat, vă rugăm să vă reconectați",
    "set_Password": "Modificare",
    "set_gesture_pass": "Vă rugăm să setați parola cu gesturi",
    "set_login_password": "Setați parola de conectare",
    "setting": "Setări",
    "setting_alipay": "Setați Alipay",
    "setting_bank_card": "Setați cardul bancar",
    "setting_message_warn_tip": "*Activarea salvării permite primirea notificărilor de alertă, dezactivarea nu va trimite notificări.",
    "setting_paypal": "Setați PayPal",
    "setting_paypassword": "Vă rugăm să setați mai întâi parola de tranzacție",
    "setting_wechat": "Setați WeChat",
    "setting_western_union": "Setați Western Union",
    "share_invitation_link": "Distribuie linkul de invitație",
    "share_link": "Distribuie linkul cu prietenii tăi",
    "share_to_do": "Distribuie la",
    "shift_to": "Transferă la",
    "single_least": "Limita minimă pe tranzacție este mai mică decât {}",
    "single_minimum": "Minim pe tranzacție",
    "single_more": "Limita minimă pe tranzacție este mai mare decât {}",
    "skip": "Sari",
    "small_exchange": "Schimb mic",
    "small_exchange_tip": "*Notă: Puteți face schimb doar o dată în 24 de ore pentru active sub {}{}, criptomonedele suspendate nu pot fi schimbate, taxa de schimb estimată este {}",
    "small_exchange_tip-1": "*Notă: Prețul pieței fluctuează în timp real, prețul efectiv se aplică. Criptomonedele suspendate nu pot fi schimbate, taxa de schimb estimată este {0}%",
    "small_exchange_valuation": "Evaluare",
    "small_exchange_valuation-1": "Evaluare BTC",
    "smallest_unit_price": "Prețul unitar PUSH este mai mic decât prețul minim {}",
    "so adjusted to": "multiplu întreg, se va ajusta automat la",
    "sold_out": "Epuizat",
    "start time": "Timp de început",
    "start_date": "Data de început",
    "start_time": "Timp de început",
    "statistics_report": "Raport statistic",
    "status": "Stare",
    "submit": "Trimite video",
    "submitBtn": "Trimite",
    "succeed": "Reușit",
    "successful_invitation": "Invitație reușită",
    "successfully_received": "Recepționat cu succes",
    "successfully_set": "Setat cu succes",
    "super_partner": "Super partener",
    "switch_account": "Schimbă contul",
    "system_top_up": "Alimentare sistem",
    "system_unlock": "Deblocare sistem",
    "tab_assets": "Active",
    "tab_home": "Acasă",
    "tab_markets": "Piețe",
    "tab_swaps": "Contracte",
    "tab_trade": "Tranzacții",
    "taking_pictures": "Fotografiere",
    "telegraph": "Grup Telegram:",
    "temporary_not_online_contact_service": "Nu este online, vă rugăm să contactați serviciul clienți",
    "the_day": "În ziua",
    "the_event_is_over": "Evenimentul s-a încheiat",
    "the_latest_price": "Prețul actual",
    "the_password_is_correct": "Parola este corectă",
    "theme": "Temă",
    "this_month": "Luna aceasta",
    "this_week": "Săptămâna aceasta",
    "ticket": "Bilet",
    "time_of_payment": "Timp de plată",
    "time_the_input": "{0}h fără a introduce parola de tranzacție",
    "timeout": "A expirat",
    "timeout_affirm": "Expirare fără confirmare",
    "timeout_deal": "Expirare automată a tranzacției",
    "timeout_to_cancel": "Expirare plată, anulare automată",
    "to_confirm": "Pentru a confirma",
    "total_buyer": "Total vânzători",
    "total_count": "Total",
    "total_exchange": "Total schimburi rapide",
    "total_lock_amount": "Cantitate totală blocată",
    "total_money": "Sumă totală",
    "total_return": "Estimare totală a veniturilor",
    "trade_fee": "Taxă pe pereche de tranzacționare",
    "trade_type": "Tip de tranzacție",
    "trading_currency": "Criptomonedă de tranzacționare",
    "trading_fiat_currency": "Monedă fiat de tranzacționare",
    "trading_instructions": "Informații de tranzacționare",
    "trading_on": "Pereche de tranzacționare",
    "trading_pair_abbreviation": "Vă rugăm să introduceți abrevierea perechii de tranzacționare",
    "trading_this_month": "Tranzacții luna aceasta",
    "trading_volume": "Volum tranzacționat",
    "transaction_description": "Descrierea tranzacției",
    "transaction_password": "Parola de tranzacție",
    "transaction_price": "Preț de tranzacționare",
    "transfer_number": "Cantitate transferată",
    "try_it_now": "Începeți acum",
    "type": "Tip",
    "unable_to_access_album": "Dispozitivul curent nu suportă accesul la album",
    "unaccalimed": "Neutilizat",
    "unavailable": "Indisponibil",
    "under_review": "În curs de revizuire",
    "univalence": "Preț unitar",
    "unlock_details": "Detalii de deblocare",
    "unlock_mode": "Mod de deblocare",
    "unlock_period": "Perioada de deblocare",
    "unlock_plan": "Plan de deblocare",
    "unlock_plan_unlock": "Deblocare",
    "unlock_record": "Înregistrare de deblocare",
    "unlock_time": "Timp de deblocare",
    "unlocked": "Deblocat",
    "unredeemed": "Neutilizat",
    "unverified": "Nerestricționat",
    "up_to_three": "Cel mult trei activări",
    "upgrade": "Upgrade",
    "upload_again": "Reîncărcați",
    "upload_front": "Încărcați fața documentului",
    "upload_identity_card": "Vă rugăm să încărcați actul de identitate",
    "upload_image_success": "Încărcare imagine reușită",
    "upload_information_as_required": "Informațiile trebuie încărcate conform cerințelor de mai sus, altfel va fi un eșec la certificare",
    "upload_passport": "Vă rugăm să încărcați pașaportul",
    "upload_passport_info_picture": "Încărcați fotografia paginii de informații a pașaportului",
    "upload_reverse": "Încărcați verso documentului",
    "ups_and_downs": "Creșteri și scăderi din ziua curentă",
    "ups_downs_habit": "Obiceiuri de creștere și scădere",
    "usable": "Utilizabil",
    "used": "Folositor",
    "user-bind-failure": "Eșec la legarea recomandatorului",
    "user-bind-success": "Legare recomandator reușită",
    "user-collection-error": "Eșec",
    "user-country-code-not-empty": "Codul internațional nu poate fi gol",
    "user-disable-trade": "Contul curent nu permite tranzacții, pentru detalii vă rugăm să contactați serviciul clienți!",
    "user-down-notbind": "Acest utilizator nu poate lega utilizatori de nivel inferior",
    "user-email-fail-often": "Trimiterea email-ului a fost prea frecventă, vă rugăm să așteptați 120 de secunde înainte de a retrimite",
    "user-email-not-empty": "Adresa de email nu poate fi goală",
    "user-email-verification-code-out": "Codul de verificare al email-ului a expirat, vă rugăm să retrimiteți",
    "user-fail-advanced-auth": "Eșec la certificarea avansată",
    "user-fail-advanced-authed": "Utilizatorul a fost deja certificat avansat",
    "user-fail-assetnotenough-error": "Active insuficiente",
    "user-fail-bank-count-error": "Puteți activa maximum 3 metode de plată",
    "user-fail-bank-inotc": "Metoda de plată are comenzi în OTC și nu poate fi dezactivată",
    "user-fail-cardno-repeat-error": "Numărul de identificare a fost deja utilizat",
    "user-fail-email-code": "Codul de verificare al email-ului este greșit",
    "user-fail-google-binded": "Google este deja legat",
    "user-fail-google-code": "Codul de verificare Google este greșit",
    "user-fail-inviter-notexist": "Recomandatorul nu există",
    "user-fail-login-disable": "Contul curent nu permite conectarea, pentru detalii vă rugăm să contactați serviciul clienți!",
    "user-fail-login-lock": "Contul a fost blocat",
    "user-fail-login-password": "Nume de utilizator sau parolă greșite, mai aveți {} încercări",
    "user-fail-mail-binded": "Email-ul a fost deja legat",
    "user-fail-not-realname": "Vă rugăm să efectuați mai întâi certificarea identității",
    "user-fail-oldpassword-error": "Parola veche este greșită",
    "user-fail-partner-not-found": "Partenerul nu există",
    "user-fail-partnernotfound": "Partenerul nu există",
    "user-fail-password": "Nume de utilizator sau parolă greșite",
    "user-fail-password-blank": "Parola nu poate fi goală",
    "user-fail-password-blank1": "Confirmarea parolei nu poate fi goală",
    "user-fail-password-repeat": "Noua parolă setată este aceeași cu cea veche, vă rugăm să o setați din nou!",
    "user-fail-password-thin": "Parola are o putere prea mică",
    "user-fail-passwword-eq-paypassword": "Parola de tranzacție este aceeași cu parola de conectare, vă rugăm să o setați din nou",
    "user-fail-paypassword-error": "Parola de plată este greșită",
    "user-fail-paypassword-notset": "Vă rugăm să setați mai întâi parola de plată",
    "user-fail-paypassword-set": "Parola de plată a fost setată",
    "user-fail-phone-binded": "Numărul de telefon a fost deja legat",
    "user-fail-realname-auth": "Eșec la certificarea identității utilizatorului",
    "user-fail-realname-authed": "Utilizatorul a fost deja certificat",
    "user-fail-realname-notset": "Vă rugăm să efectuați mai întâi certificarea identității",
    "user-fail-reg": "Înregistrare eșuată, eroare de server",
    "user-fail-reg-mail": "Formatul email-ului este incorect",
    "user-fail-reg-mail-exist": "Email-ul a fost deja înregistrat",
    "user-fail-reg-phone-exist": "Numărul de telefon a fost deja înregistrat",
    "user-fail-securitycount": "Trebuie să păstrați cel puțin o metodă de verificare",
    "user-fail-sms-code": "Codul de verificare SMS este greșit",
    "user-fail-sms-code-new": "Codul de verificare SMS pentru noul număr de telefon este greșit",
    "user-fail-sms-code-old": "Codul de verificare SMS pentru vechiul număr de telefon este greșit",
    "user-fail-usernameisblank": "Numele de utilizator nu poate fi gol",
    "user-id-or-nationCode—is-null": "ID-ul utilizatorului sau codul internațional este gol",
    "user-login-success": "Conectare reușită",
    "user-mail-fail": "Eșec la trimiterea email-ului",
    "user-mail-success": "Email trimis cu succes",
    "user-not-exist": "Acest utilizator nu există",
    "user-notopen-success": "Încă nu este activat",
    "user-phone-not-empty": "Numărul de telefon nu poate fi gol",
    "user-sms-fail": "Eșec la trimiterea SMS-ului, eroare de server",
    "user-sms-fail-often": "Trimiterea SMS-urilor a fost prea frecventă",
    "user-sms-success": "SMS trimis cu succes",
    "user-sms-verification-code-out": "Codul de verificare a expirat, vă rugăm să retrimiteți",
    "user-success-advanced-auth": "Certificare avansată reușită",
    "user-success-realname-auth": "Certificare identitate utilizator reușită",
    "user-validate-code-fail": "Codul de verificare este greșit",
    "user-validate-fail": "Verificare eșuată",
    "user-vip-common-fail": "Achiziție VIP eșuată",
    "user_agreement": "Vă rugăm să bifați pentru a accepta acordul de servicii pentru utilizatori",
    "user_collection_cencel_success": "Anulat cu succes",
    "user_collection_insert_success": "Salvat cu succes",
    "user_pay_disable_state": "Contul a fost interzis de a efectua tranzacții din cauza erorilor repetate ale parolei de tranzacție, vă rugăm să așteptați 2 ore înainte de a încerca din nou",
    "user_pay_is_error": "Parola de tranzacție este greșită, mai aveți {} încercări, depășirea limitelor va bloca contul timp de 2 ore",
    "user_pay_password_not_setting": "Utilizatorul nu a setat parola de tranzacție, vă rugăm să o setați mai întâi",
    "user_protocol": "Acord utilizator",
    "user_service_agreement": "Acord de servicii pentru utilizatori",
    "user_vip_has_expire": "Contul VIP a expirat și nu poate fi actualizat!",
    "user_vip_update_failure": "Contul nu suportă reînnoirea sau actualizarea, pentru detalii vă rugăm să contactați serviciul clienți!",
    "valid_identity_card": "Vă rugăm să introduceți un număr de identificare valid",
    "valuation": "Evaluarea depunerii",
    "verification_code_error": "Codul de verificare este greșit",
    "verification_failed_001": "Verificare eșuată",
    "verification_finish": "Verificare completă",
    "version_updating": "Actualizare versiune",
    "versions": "Versiuni",
    "vertical_version": "Versiune verticală",
    "view_activity": "Vizualizați activitatea",
    "view_lock": "Vizualizați blocajul",
    "volamount": "Cumpărătorul trebuie să aibă cel puțin {} tranzacții finalizate",
    "volamount_sell": "Vânzătorul trebuie să aibă cel puțin {} tranzacții finalizate",
    "vote": "Vot",
    "vote_add_coin": "Vot pentru adăugarea unei monede",
    "vote_return": "Returnare",
    "voting_coin": "Moneda de vot",
    "voting_number": "Număr de voturi",
    "voting_time": "Timp de votare",
    "wait_buyer_payment": "Așteptând plata cumpărătorului",
    "wait_for_payment": "Așteptând plată",
    "waiting_for_receiving": "Așteptând confirmarea plății de la vânzător",
    "warm_prompt": "Notificare amicală",
    "warning_setTitle": "Setări de alertă",
    "wechat_account": "Cont WeChat",
    "wechat_upload": "*Metodă de încărcare a codului QR pe WeChat: deschideți pagina principală WeChat > Eu > Portofel > Plăți > Salvați imaginea, apoi încărcați codul QR de plată din albumul foto.",
    "week": "săpt",
    "welcome": "Bine ați venit la BitMatrix",
    "welcome_to": "Bine ați venit la",
    "winning_record": "Înregistrare câștigătoare",
    "withdrawal_amount": "Limită de retragere zilnică",
    "withdrawal_charge": "Taxă de retragere",
    "xilian": "Western Union",
    "yesterday_income": "Venituri din ziua precedentă",
    "you_have_selected": "Ați selectat",
    "Your closing number": "Cantitatea de închidere este mai mică decât limita minimă!",
    "Your have pending Cross orders": "Există comenzi de tip cross nefinalizate, vă rugăm să anulați toate comenzile cross înainte de a modifica multiplii de levier",
    "Your have pending isolated orders": "Există comenzi izolate nefinalizate, vă rugăm să anulați toate comenzile izolate înainte de a modifica multiplii de levier",
    "Home page apps": "Aplicații pe pagina principală",
    "Home_Edit": "Editează",
    "recently_deal01": "Tranzacționat",
    "depth01": "Adâncime",
    "depth02": "Grafic de adâncime",
    "discount_currency_introduction01": "Introducere în moneda",
    "Info001": "Introducere",
    "Avg cost": "Cost mediu",
    "Long position": "Poziție lungă",
    "Short position": "Poziție scurtă",
    "Hold on": "Așteptați",
    "Done": "Finalizat",
    "new_version": "A fost descoperită o versiune nouă",
    "Announcement": "Anunț",
    "View details": "Vizualizați detaliile",
    "Option Assets": "Active opțiuni",
    "Option Account": "Cont opțiuni",
    "Option": "Opțiune",
    "1M": "1 min",
    "5M": "5 min",
    "15M": "15 min",
    "30M": "30 min",
    "1H": "1 oră",
    "Delivery Time": "Timp până la livrare",
    "Call": "Apel",
    "Put": "Vânzare",
    "Draw": "Neutral",
    "Draw2": "Neutral",
    "Call1": "Long",
    "Put1": "Short",
    "Draw1": "Neutral",
    "Buy-2": "Cumpărare",
    "Rate of return": "Rata de returnare",
    "Change": "Schimbare",
    "Buy Option": "Cumpărați opțiune",
    "Awaiting": "Așteptând livrarea",
    "Delivery": "Livrarea mea",
    "Checkout": "Istoricul livrării",
    "Front": "Perioada curentă",
    "Back": "Perioada următoare",
   "Coming soon": "În curând",
    "delivery settlement": "Reglare a livrării",
    "Ongoing": "În proces de cumpărare",
    "Pending purchase": "Așteptând cumpărarea",
    "Billing Amount": "Cantitate de reglare",
    "Amount": "Cantitate de cumpărare",
    "Delivery result": "Rezultatul livrării",
    "Delivery time": "Timpul livrării",
    "Up": "Creștere",
    "Down": "Scădere",
    "lookUp": "Așteptări de creștere",
    "lookDown": "Așteptări de scădere",
    "Delivery details": "Detalii despre livrare",
    "Index Composition": "Compoziția indicelui",
    "Exchange": "Bursă",
    "Change2": "Schimbare",
    "Weights": "Greutate",
    "Order number": "Numărul comenzii",
    "Option Session": "Sesiune de opțiuni",
    "Buy Time": "Timp de cumpărare",
    "Opening time": "Timp de deschidere",
    "Bet Type": "Tip de pariu",
    "Delivery Price": "Prețul livrării",
    "Settlement Currency": "Moneda de reglare",
    "K-line": "Liniile K",
    "UpSelect": "Selecție de creștere",
    "DownSelect": "Selecție de scădere",
    "Front has ended, please place another order": "Această sesiune s-a încheiat, vă rugăm să plasați o altă comandă",
    "Successfully ordered": "Comandă plasată cu succes",
    "Insufficient balance": "Sold insuficient",
    "Quantity purchased must be greater than the minimum limit": "Cantitatea cumpărată trebuie să fie mai mare decât limita minimă",
    "Quantity purchased must be less than the maximum limit": "Cantitatea cumpărată trebuie să fie mai mică decât limita maximă",
    "The current time is not in the purchase time range": "Timpul curent nu este în intervalul de timp pentru cumpărare",
    "expected": "Venituri estimate",
    "To_a_game": "O altă rundă",
    "Settlement Result": "Rezultatul reglarii",
    "Delivery-1": "Livrare",
    "Ups and downs": "Fluctuații",
    "duration": "Durată",
    "Option funds daybook": "Jurnal de fonduri pentru opțiuni",
    "Open innovative options trading": "Deschideți tranzacționarea inovativă a opțiunilor",
    "Confirm Open": "Confirmare deschidere",
    "Option Agree Content": "Pentru a vă proteja drepturile, vă rugăm să citiți cu atenție \"Acordul de utilizare a tranzacționării opțiunilor inovative\" înainte de a deschide opțiunile",
    "Option Agree Content footer": "\"Acordul de utilizare a tranzacționării opțiunilor inovative\"",
    "Option Agree Content header": "Pentru a vă proteja drepturile, vă rugăm să citiți cu atenție",
    "Service upgrade, please try again later!": "Actualizare a serviciului, vă rugăm să încercați din nou mai târziu!",
    "month": "Lună",
    "Invitation": "Invitație",
    "Markets": "Piețe",
    "Swaps": "Swap-uri",
    "Trade": "Tranzacționare",
    "Trade-1": "Mergi la tranzacționare",
    "Assets": "Active",
    "Buy": "Cumpără monede",
    "Help": "Ajutor",
    "Service": "Serviciu clienți",
    "Activity": "Activitate",
    "Communtiy": "Comunitate",
    "No search results": "Nu au fost găsite rezultate",
    "The option account asset is 0, please transfer from the wallet account to the option account first.": "Activele contului de opțiuni sunt 0, vă rugăm să transferați mai întâi din contul de portofel în contul de opțiuni.",
    "Perpetual account assets are 0, please transfer from wallet account to perpetual account first.": "Activele contului perpetuu sunt 0, vă rugăm să transferați mai întâi din contul de portofel în contul perpetuu.",
    "Financing": "Finanțare",
    "Load": "Încărcare",
    "Replay001": "Repetare",
    "Constellation Program": "Prezentare - Programul Constellation",
    "Constellation Program2": "Prezentare - Finanțare Constellation",
    "rate rankings": "Clasament rate de succes ale instituțiilor",
    "rate rankings2": "Recomandarea instituțiilor de finanțare celebre de săptămâna aceasta",
    "CXT Quotes": "Cote",
    "Digital asset trading tool service platform": "Platformă de servicii pentru instrumente de tranzacționare a activelor digitale",
    "Service_home": "Serviciu",
    "Strategic cooperation": "Cooperare strategică",
    "Financing assets": "Active de finanțare",
    "Remove 0 from the first number when registering!": "Îndepărtați 0 de la primul număr la înregistrare!",
    "Are you sure you want to quit": "Sigur doriți să ieșiți?",
    "Opening Price": "Preț de deschidere",
    "Closing Price": "Preț de închidere",
    "Formula interpretation": "Interpretarea formulei",
    "Note: If the input box is left blank": "Notă: Lăsarea câmpului de input gol înseamnă că datele de la bursa curentă nu au fost obținute",
    "Option Sessions": "Sesiuni de opțiuni",
    "Result": "Rezultat",
    "When data for an exchange is not available": "*Când nu sunt disponibile date pentru o anumită bursă, valoarea greutății va fi 0%. Dacă sursele de date valide ≤ 2, se definește ca o situație de egalitate",
    "Assume that the index is derived from the opening prices of five exchanges": "Presupunem că indexul provine din prețurile de deschidere ale cinci burse: A, B, C, D, E; prețurile de închidere sunt: A`, B`, C`, D`, E`",
    "The proportion of those five exchanges are": "Proporțiile celor cinci burse sunt: a%, b%, c%, d%, e%",
    "Stream bureau": "Egalitate",
    "(valid data source ≤ 2)": "(sursă de date valide ≤ 2)",
    "Option calculator": "Calculator de opțiuni",
    "Round selection": "Selectare sesiune",
    "Select option": "Selectați opțiunea",
    "Average opening price": "Preț mediu de deschidere",
    "Average closing price": "Preț mediu de închidere",
    "Calculate now": "Începeți calculul",
    "Calculation Results": "Rezultatele calculului",
    "Calculating Time": "Timp de calcul",
    "Management": "Management",
    "Other_home": "Altele",
    "All applications": "Toate aplicațiile",
    "Boon": "Beneficiu",
    "Coin_Address_draw": "Adresă de retragere",
    "Congratulations": "Felicitări",
    "Profit": "Profit",
    "Gainers": "Crestere",
    "Drop": "Scădere",
    "Order Price": "Prețul comenzii",
    "Order Price1": "Prețul comenzii",
    "Sec Swap": "Contract pe secunde",
    "Expected Return": "Rata de returnare estimată",
    "Sec Swap Assets": "Active contract pe secunde",
    "Sec Swap Bill": "Jurnal de fonduri pentru contracte pe secunde",
    "Sec Swap Account": "Cont contract pe secunde",
    "Go Login": "Mergi la conectare",
    "NSO Session": "Sesiune NSO",
    "Wallet Value1": "Active portofel",
    "Spot Value1": "Active monede",
    "PERP Value1": "Active perpetue",
    "Option Value1": "Active opțiuni",
    "Fiat Value1": "Portofel OTC",
    "Pledge_Amount": "Cantitate de gaj",
    "total_return2": "Returnare cumulată",
    "Violation_Loss": "Penalizare",
    "Cycle": "Ciclul",
    "Locked2": "Blocat",
    "Time_Left": "Timp rămas",
    "Cancel_Pledge": "Anulați gajul?",
    "countDown_day": "Zile",
    "Entrust2": "Comandă de încredere",
    "All2": "Toate",
    "Ongoing2": "În curs",
    "Near2": "În curând",
    "Ended2": "Finalizat",
    "Cancel the pledge?": "Sigur doriți să anulați gajul?",
    "Completed2": "Finalizat",
    "Cancelled2": "Anulat",
    "Lock-up mining": "Minerit cu blocare",
    "Rules Description": "Regulă",
    "Today Expected": "Venit estimat pentru astăzi",
    "Order Escrow": "Comandă în depozit",
    "Minimum": "Limită minimă",
    "Daily Return": "Rata de returnare zilnică",
    "Buy-3": "Mergi la cumpărare",
    "Minimum Amount": "Cantitate minimă de gaj",
    "Lockup Period": "Perioada de blocare",
    "Confirm Pledge": "Confirmați gajul",
    "Buy-4": "Cumpărați",
    "Conversion Starter": "Înscriere inițială",
    "Listing": "Listare",
    "Total Target": "Obiectiv total",
    "Conversion Ratio": "Rata de conversie",
    "Single Limited": "Limită pe persoană",
    "Participate Order": "Subscriere",
    "Fundraised": "Fonduri strânse",
    "Current Progress": "Progres actual",
    "Time Left": "Timp rămas",
    "Start Time": "Timp de început",
    "End Time": "Timp de sfârșit",
    "Conversion Amount": "Cantitate de subscriere",
    "Conversion Now": "Subscrieți acum",
    "Conditions": "Condiții de participare",
    "Preheating": "Preîncălzire",
    "Records": "Înregistrări de subscriere",
    "Select": "Selectați",
    "To": "Către",
    "Active Currency": "Monedă activă",
    "Amount-3": "Sumă",
    "Conversion Time": "Timp de subscriere",
    "Arrival Time": "Timp de sosire",
    "Not Arrived": "Nu a sosit încă",
    "Hours-1": "Ore",
    "Risk warning: It is displayed": "Atenție la risc: Acesta este un activ inovativ, cu caracteristici digitale. Vă rugăm să evaluați cu atenție prețul și să luați decizii de investiție cu prudență!",
    "Week-1": "săpt",
    "S-1": "Secunde",
    "minutes-2": "Minute",
    "You must pass basic KYC": "Utilizatorul trebuie să treacă de verificarea KYC de bază",
    "You must pass advanced KYC": "Utilizatorul trebuie să treacă de verificarea KYC avansată",
    "Account order exceeds": "Ordinea contului depășește",
    "Suspend": "Suspendare tranzacții",
    "Successfully": "Subscriere reușită",
    "Ongoing-1": "În curs de subscriere",
    "Failed": "Subscriere eșuată",
    "New_Token_Subscribe": "Subscriere token nou",
    "More_awaits_you": "Mai multe bogății te așteaptă",
    "Distance Start": "Timp rămas până la început",
    "day-2": "Zile",
    "Must Eligible": "Trebuie să îndeplinească condițiile de participare",
    "Custodial funds": "Fonduri în custodie",
    "Default Ratio": "Procent de penalizare",
    "Wallet Available": "Portofel disponibil",
    "Done-2": "Finalizat",
    "Eligible Amount": "Cantitate aprobată",
    "Eligible Price": "Preț aprobat",
    "New Token Subscribe": "Subscriere token nou",
    "Subscribe Now": "Subscrieți acum",
    "Review Time": "Timp de revizuire",
    "Review Passed": "Revizuirea a fost aprobată",
    "Review Failed": "Revizuirea nu a fost aprobată",
    "Not Reviewed": "Încă nu a fost revizuit",
    "Denial Reason": "Motiv de respingere",
    "If not received, please contact service": "Dacă nu a sosit, vă rugăm să contactați serviciul de asistență!",
    "Returned to wallet": "Returnat în contul de portofel",
    "From wallet assets": "Disponibil din activele portofelului",
    "Day-3": "Zile",
    "Sec Swap asset is 0, please transfer from wallet account to Sec Swap first": "Activele contului de swap pe secunde sunt 0, vă rugăm să transferați mai întâi din contul de portofel în contul de swap pe secunde",
    "Settlement": "Reglare în curs",
    "Completed": "Reglare completă",
    "Password Setting": "Setare parolă de conectare",
    "Set password and email": "Setați parola de conectare și emailul",
    "Email Settings": "Setări email",
    "First-time registered users mus": "Utilizatorii care se înregistrează pentru prima dată trebuie să finalizeze setările parolei de conectare și ale emailului",
    "Driver ID": "Permis de conducere",
    "Preview": "Cerere de ofertă",
    "cancelTime": "Timp de anulare",
    "Total_assets_valuation": "Estimarea totală a activelor",
    "Invite Friends": "Invită prieteni",
    "RedGreen": "Roșu pentru creștere, verde pentru scădere",
    "GreenRed": "Verde pentru creștere, roșu pentru scădere",
    "Color Preference": "Preferință de culoare",
    "Fuzzy Information": "Informații neclare",
    "user_bind_xilain_trade_pwd": "Vă rugăm să introduceți parola de tranzacționare",
    "login_please_input1": "Vă rugăm să introduceți codul de verificare SMS",
    "user_please_input9": "Vă rugăm să introduceți codul de verificare Google",
    "user_link_name": "Numele lanțului",
    "user_coin_bring_together": "Îmbinare",
    "chongbi0": "Informații despre reîncărcare:",
 "chongbi1": "1. Este interzisă reîncărcarea altor active în afară de {0}; orice reîncărcare a activelor non-{1} nu va putea fi recuperată.",
    "chongbi2": "2. Vă rugăm să trimiteți numărul necesar de {1} la această adresă prin clientul {0} sau portofelul online. După finalizarea trimiterii, sistemul va adăuga automat {2} de confirmări înainte de a creditat moneda virtuală în contul dumneavoastră, vă rugăm să aveți răbdare.",
    "chongbi3": "3. Aceeași adresă poate fi reîncărcată de mai multe ori, fără a afecta creditarea.",
    "chongbi4": "4. Suma minimă de reîncărcare este de {0}.",
    "currency_withdrawal_rate_rules": "Reguli pentru rata de retragere {0}:",
    "currency_withdrawal_rate_rules1": "1. Pentru siguranța fondurilor utilizatorului, platforma poate confirma telefonic operațiunea de retragere, vă rugăm să răspundeți;",
    "currency_withdrawal_rate_rules2": "2. Retragerea {0} este permisă numai după {1} confirmări;",
    "currency_withdrawal_rate_rules3": "3. Suma maximă de retragere ≤ active disponibile în cont - active digitale neconfirmate;",
    "currency_withdrawal_rate_rules4": "4. Se pot efectua maximum {0} retrageri pe zi, cu o limită maximă de retragere de {1} pe zi.",
    "currency_transaction_password": "Parolă",
    "long_press_to_save_to_album": "Apăsați lung pentru a salva în album",
    "the_fee_is_outside_the_range": "Taxa nu se află în intervalul permis",
    "start_and_end_date": "Data de început și sfârșit",
    "The_withdrawal_amount_cannot_be_less": "Suma de retragere nu poate fi mai mică de {0}",
    "withdrawal_details": "Detalii despre retragere",
    "RegisterRemove0": "Îndepărtați 0 de la primul număr la înregistrare",
    "closeLockUpPopup": "Anularea gajului va genera o penalizare de {0} zile, suma penalizării este „{1}{2}”, suma principală creditată va fi „{3}{4}”.",
    "LockUpDuan": "Minerit cu blocare",
    "NewTokenSubscribe": "Subscriere token nou",
    "MoreWealthAwaitsYou": "Mai multe bogății te așteaptă",
    "TopGainers": "Variații",
    "Newest": "Noi",
    "Hot": "Popularitate",
    "DatChange": "Schimbări zilnice",

    "SetPassword": "Setare parolă",
    "comm_password": "Parolă de tranzacționare",
    "unraise_recovering_password": "După recuperarea parolei, nu se poate retrage timp de 1 oră",
    "PDleaseUseSafariBrowser": "Vă rugăm să folosiți browserul Safari",
    "ADddToDesktop": "Adăugați BitMatrix pe desktop",
    "CDlickBelow": "Faceți clic mai jos",
    "SDelectAddToHomeScreen": "Apoi selectați adăugarea la ecranul principal",
    "ADddToDesktop2": "Adăugați BitMatrix pe desktop",
    "CDommonbrowserAddMethod": "Metoda de adăugare pentru browsere comune",
    "user_senior_text1": "Atenție",
    "user_senior_text2": "Dimensiunea fișierului foto nu poate depăși 5M! Formatul fișierului trebuie să fie jpg, bmp, png etc.! ",
    "user_senior_text3": "Vă rugăm să vă asigurați că fotografia nu are filigran, nu este murdară, informațiile de identificare sunt clare, fotografia de profil este completă, nu este o fotografie text inversată! Vă rugăm să nu editați fotografia!",
    "user_senior_text4": "Ținând",
    "user_senior_text5": "fotografia actului de identitate",
    "user_senior_text6": "：Trebuie să țineți cu o mână",
    "user_senior_text7": "data curentă",
    "user_senior_text8": "și",
    "user_senior_text9": "UID",
    "user_senior_text10": "pe o bucată de hârtie și să faceți o fotografie non-mirroare.",
    "user_senior_text11": "În cealaltă mână, țineți o bucată de hârtie pe care să scrieți",
    "user_senior_text12": "Vă rugăm să scrieți următorul conținut pe o bucată de hârtie albă; conținutul care nu corespunde nu va fi acceptat:",
    "user_senior_text13": "„Am fost informat și îmi asum riscurile de investiție”",
    'user_senior_text14': '。',
    "user_ID_card": "Carte de identitate",
    "user_passport": "Pașaport",
    "Driver_ID": "Permis de conducere",
    "user_senior_upload3": "Încărcați fotografia cu actul de identitate + UID + dată",
    "ServiceUpgrade": "Upgrade de serviciu în curs",
    "ReferencePrice": "Preț de referință",
    "referenceTips": "Nu este prețul final de tranzacționare, doar pentru referință",
    "NewCoinsLaunch": "Lansare de monede noi",
    "CurrentlyPurchased": "Subscriere curentă",
    "TotalMarketValue": "Valoare totală de piață",
    "IEORatio": "Proporția de subscriere",
    "CurrencyIntroduction": "Introducerea monedei",
    "ConvertNow": "Subscrieți acum",
    "RemainingAmount": "Suma rămasă",
    "referenceTips": "Se va lua în considerare prețul de achiziție efectiv",
    "LastDays7": "Ultimele 7 zile",
    "LastDays30": "Ultimele 30 de zile",
    "LastDays90": "Ultimele 90 de zile",
    "LockUpTips": "Notă: Suma rămasă neaprobată {0}{1} a fost returnată în contul dumneavoastră de portofel.",
    "PromotionalMining": "Minerit promoțional",

    "hide_coin": "Ascunde monedele mai mici de {0} BTC",
    "GoldAssets": "Active de aur",
    "ModifyEmail": "Modificare email",
    "NewEmail": "Email nou",
    "NewEmailAddress": "Introduceți noua adresă de email",
    "VerifyNewEmail": "Verificare email nou",
    "EnterNewEmailCode": "Introduceți codul de verificare pentru emailul nou",
    "VerifyOldEmail": "Verificare email vechi",
    "EnterOldEmailCode": "Introduceți codul de verificare pentru emailul vechi",
    "VerifyLoginPassword": "Verificare parolă de login",
    "EnterLoginPassword": "Introduceți parola de login",
    "loan": "Centru de împrumut",
    "loanText": "Oferim servicii de împrumut de monede sigure și fiabile, pentru diverse scopuri, cu lichiditate mai mare, fără a fi necesar să vindeți activele criptografice existente.",
    "loanQuery": "Consultanță pentru împrumuturi",
    "Stop_surplus_tips": "Când prețul de piață ajunge la {} va activa comanda de profit, se estimează un câștig de {} după finalizare",
    "Stop_loss_tips": "Când prețul de piață ajunge la {} va activa comanda de stop-loss, se estimează o pierdere de {} după finalizare",
    "Stop_surplus_tips1": "Când prețul de piață ajunge la {} va activa comanda de profit, se estimează o pierdere de {} după finalizare",
    "Stop_loss_tips1": "Când prețul de piață ajunge la {} va activa comanda de stop-loss, se estimează un câștig de {} după finalizare",
    "Countdown": "Timp rămas",
    "contract_After_adjustment": "După ajustare, vor fi transferați {} USDT din cont ca marjă suplimentară",
    "contract_subject_to": "（Se va lua în considerare suma efectiv transferată）",
    "contract_the_used_margin": "După ajustare, marja utilizată va scădea cu {} USDT, iar suma scăzută va rămâne în poziție,",
    "contract_transferred_to": "poate fi transferată în soldul contului",
    "contract_After_adjustment_q": "După ajustare, marja ocupată de poziție va crește cu {} USDT",
    "contract_the_used_margin_q": "După ajustare, marja ocupată de poziție va scădea cu {} USDT",
    "Please1": "Vă rugăm să introduceți prețul de activare pentru profit",
    "Please2": "Vă rugăm să introduceți prețul comenzii de profit",
    "Please3": "Vă rugăm să introduceți prețul de activare pentru stop-loss",
    "Please4": "Vă rugăm să introduceți prețul comenzii de stop-loss",
    "Stop profit6": "Prețul de activare pentru profit trebuie să fie mai mare decât prețul actual de tranzacționare",
    "stop loss7": "Prețul de activare pentru stop-loss trebuie să fie mai mic decât prețul actual de tranzacționare",
    "Please8": "Vă rugăm să introduceți cantitatea",
    "Please9": "Nu există cantitate disponibilă de închidere",
    "CountdownTime": "Timp rămas",
    "h1": "Solicitare partener",
    "h2": "Clasament comisioane",
    "h3": "Deveniți partener pentru a obține",
    "h4": "Condiții de promovare",
    "h5": "Achiziționarea partenerului de bază necesită o plată de",
    "h6": "Sold curent",
    "h7": "Partener fondator",
    "h8": "Comision",
    "h9": "Promovare imediată",
    "h10": "Deja la cel mai înalt nivel",
    "h11": "Bonus de comision",
    "h12": "Clasament comisioane",
    "h13": "Număr de membri în echipă",
    "h14": "Istoric comisioane",
    "h15": "Persoane invitate",
    "h16": "Finalizat",
    "h17": "Neefinalizat",
    "h18": "Beneficii din subordonați",
    "h19": "Necesită plată de",
    "h20": "Promovare",
    "h21": "Detalii despre comision",
    "h22": "Clasament",
    "h23": "Utilizator",
    "h24": "Recompensă comision",
    "h25": "Nivelul curent poate obține",
    "h26": "Suma totală a comisionului convertită în USDT.",
    "h27": "Necesită promovarea a {} utilizatori obișnuiți",
    "h28": "A promovat {} utilizatori obișnuiți valizi",
    "h29": "Necesită numărul echipei să ajungă la {} persoane",
    "h30": "Număr de membri în echipă {} persoane",
    "Stop profit10": "Prețul de activare pentru profit trebuie să fie mai mic decât prețul actual de tranzacționare",
    "stop loss10": "Prețul de activare pentru stop-loss trebuie să fie mai mare decât prețul actual de tranzacționare",
    "Earn": "Investiții",
    "Simple": "Câștigă și cheltuie fără probleme",
    "Low1": "Risc scăzut, randament stabil",
    "Low2": "Risc mic, randament stabil, răscumpărare oricând",
    "CurrentAccount": "Cont de economii",
    "Stable": "Creștere stabilă, unelte pentru creșterea averii",
    "EarnLocked": "Investiții pe termen fix",
    "Searchpopular": "Investiții ușoare, beneficiați de câștiguri oricând",
    "Annualized": "Rata anualizată de returnare {}%, active sigure, randamente foarte mari",
    "CoinsLC": "Monede",
    "PurchaseLC": "Sumă de achiziție",
    "PurchaseLC2": "({} minim)",
    "Maximum": "Maxim",
    "EstimatedInterest": "Interes estimat",
    "RuleLC": "Reguli",
    "SubscriptionTime": "Timp de subscriere",
    "Interestaccrualtime": "Timp de acumulare a dobânzii",
    "RevenueTime": "Timp de câștig",
    "RedemptionCycle": "Ciclul de răscumpărare",
    "ReturnNow": "Întoarceți acum",
    "BuyLC": "Cumpărați",
    "PurchaseSuccessful": "Achiziție reușită",
    "DepositLC": "Depunere de monede",
    "Yesterday": "Câștiguri de ieri",
    "TotalLC": "Total depus",
    "TotalReturn": "Câștig total",
    "DepositValuation": "Evaluare depunere",
    "DepositDetail": "Detalii depunere",
    "RevenueDetails": "Detalii câștiguri",
    "DepositType": "Tip de depunere",
    "ReturnMethod": "Metodă de returnare a dobânzii",
    "DepositAmount": "Cantitate depusă",
    "APR": "Rată anualizată",
    "Expected": "Câștig estimat",
    "DepositCycle": "Ciclul de depunere",
    "CreationTime": "Timp de creare",
    "EarnProfit": "Dobândă pentru perioada curentă",
    "DepositCost": "Capitalul depus",
    "TotalRelease": "Total distribuit",
    "ReleaseTime": "Timp de distribuire",
    "Flexible": "Pe termen scurt",
    "Fixed": "Pe termen lung",
    "Freeze": "Înghețat",
    "Completed3": "Finalizat",
    "Cancelled": "Anulat",
    "DefaultSettlement": "Compensare pentru neîndeplinire",
    "PurchaseAmount": "Sumă de achiziție (fără limită)",
    "RetrieveDeposited": "Retragere depuneri",
    "PreviewConversion": "Previzualizare rezultat schimb",
    "ToEnsureThe": "Pentru a asigura securitatea contului dumneavoastră și a îndeplini cerințele de valoare mai mare a tranzacțiilor, trebuie să efectuăm o verificare avansată a identității.",
    "ToVerification": "Faceți clic pe buton pentru a contacta serviciul clienți pentru verificare.",
    "ToContact": "Contactați imediat serviciul clienți pentru verificare",
    contract_entrust_failure_0080 : 'Valoarea comenzii nu poate fi mai mică de 100 USDT.',
    "comm_nickname_rule1": "Nu poate depăși  15 litere din alfabetul englez.",
    "comm_nickname_rule2": "Nu poate conține spații sau caractere speciale.",
    "otc_upload_picture3":"Imaginile UUpload pot fi doar în format jpeg/jpg/png/bmp!",
    "CertificationDifficulties":'Există o problemă cu verificarea?',
}