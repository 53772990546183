<template>
  <div class="container assetRecords">
    <nav-bar :title="query.title" :showRight="false">
      <router-link class="" slot="right" to="bbCapitalFlow">
        {{$t('M.assets_cash_flow')}}
      </router-link>
    </nav-bar>
    <!-- <van-sticky> -->
    <div class="capital_top">
      <div class="capital_top_title">
        <span>{{$t('M.assets_reduced_asset')}}</span>
        <img class="icon" v-show="show" src="@/assets/img/assets/icon_eyeoon.png" alt="" @click="onClickShow">
        <img class="icon icon_eyeoff" v-show="!show" src="@/assets/img/assets/icon_eyeoff.png" alt="" @click="onClickShow">
      </div>
      <div class="total row" :style="totalStyle">
        <span>{{show?totalSum || '0':'******'}}</span>
        <span v-show="show" class="info">{{currencySymbol}}</span>
      </div>

    </div>
    <!-- </van-sticky> -->

    <div class="capital_list">
      <!-- 隐藏小额币种 -->
      <hide-search-coin @onChecked="onChecked" @onSearch="onSearch" :top="4.267" :searchCoinList="searchCoinList" />

      <div :class="[active == i && activeShow?'capital_list_item_active' :'' , 'capital_list_item']" v-for="(item,i) in coinList" :key="i"
        @click="onClickItem(i)">
        <div class="row list_item_tit">
          <div class="row">
            <img :src="item.logo" alt="">
            {{item.coinName}}
          </div>
        </div>
        <div class="row num_wrap">
          <div>
            <span>{{filterArr.includes(item.coinId) ? $t('M.locked_warehouse') : $t('M.Available')}}</span>
            <span>{{show? item.total || '0':'****'}}</span>

          </div>
          <div>
            <span>{{$t('M.freeze')}}</span>
            <span>{{show?item.frozen || '0':'****'}}</span>
          </div>
          <div>
            <span>{{$t("M.user_coin_bring_together")}}</span>
            <span style="text-align: right;">
              {{show?cutOutPointLength( cutOutPointLength( item.cnyValue,10) /rate,2,true) || '0.00' :'****'}}
              {{currencySymbol}}
            </span>
          </div>
        </div>
        <div v-show="activeShow && active == i" class="button_wrap_active">
          <div class="button_wrap row">
            <div class="button" @click.stop="onClickHZ(item)">{{$t('M.Transfer')}}</div>
            <div class="button" @click.stop="getBBTradeUrl(item.coinId)">{{$t('M.tab_trade')}}</div>
          </div>
        </div>

      </div>

      <!-- <Dialog :show="dialog.showDialog" :message="dialog.showMessage" :confirmButtonText="$t('M.go_setting')" @closed="onClosed" @confirm="onConfirm">
      </Dialog> -->

    </div>
    <!-- 划转 -->
    <transfer :showTransfer="showTransfer" :coinId="coinId" account="BB" @onClose="onCloseTrans" @onConfirm="onConfirmTrans">
    </transfer>

    <!-- 交易列表 -->
    <van-action-sheet v-model="showType" :actions="actions" :cancel-text="$t('M.Cancel')" @select="onSelect" @cancel="onCancel" />

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import navBar from '@/components/navBar';
// import Dialog from '@/components/dialog';
import transfer from '@/components/transfer';
import hideSearchCoin from '@/components/hideAndSearchCoin';
import {GetOs} from '@/utils/ststemTool'

export default {
  name: 'bbAsset',
  components: {
    navBar, transfer,
    // Dialog,
    hideSearchCoin
  },
  computed: {
    ...mapGetters([
      'user', 'currencySymbol'
    ]),
    totalStyle() {
      if (GetOs() == 'iPhone') {
        return 'align-items: center;'
      }
      return 'align-items: flex-end;'
    }
  },

  data() {
    return {
      query: {},
      show: true,
      active: '',
      activeShow: true,
      totalSum: 0,
      rate: 1,
      coinList: [],
      showType: false,
      showTypevalue: this.$t('M.all'),
      actions: [],


      dialog: {
        showDialog: false,
        showMessage: '',
        path: ''
      },
      showTransfer: false,
      coinId: '',


      isTotalGtZero: false,
      searchCoinList: [],
      filterArr:[]
    };
  },
  created() {
    this.getBBData()

  },
  beforeMount() {
    Object.assign(this.query, this.$route.query);
  },

  async mounted() {
    const filterArr = await this.$api.filterLockCoin()
    this.filterArr = filterArr.data
  },

  methods: {

    // 显示/隐藏
    onClickShow() {
      this.show = !this.show
    },

    onClickItem(i) {

      if (this.active == i) {
        this.activeShow = !this.activeShow
      } else {
        this.activeShow = true
        this.active = i
      }

    },

    // onClickStop() { },


    // onClosed() {
    //   this.dialog.showDialog = false
    //   setTimeout(() => {
    //     this.dialog = {
    //       showDialog: false,
    //       showMessage: '',
    //       path: ''
    //     }
    //   }, 500);
    // },
    // onConfirm() {
    //   this.onClosed()
    //   setTimeout(() => {
    //     if (this.dialog.path) {
    //       this.$router.push(this.dialog.path)
    //     }
    //   }, 100);

    // },

    // 汇率
    getBBRate() {
      this.$api.bbRate({ currency: this.currencySymbol }).then((response) => {
        if (!response.meta.success) return
        this.rate = response.data
      })

    },
    // 获取币币资产
    async getBBData() {
      await this.getBBRate()
      this.$api.bbFinanceList({ isTotalGtZero: this.isTotalGtZero }).then((response) => {
        if (!response.meta.success) return
        const { totalSum, userCoinWalletVOPageInfo } = response.data
        this.totalSum = this.cutOutPointLength(this.cutOutPointLength(totalSum, 10) / this.rate, 2, true)
        this.coinList = userCoinWalletVOPageInfo.list || []
        this.searchCoinList = userCoinWalletVOPageInfo.list || []
      })
    },




    // 划转按钮
    onClickHZ(item) {
      if(item.coinId && this.filterArr.includes(item.coinId)){
        this.$notify(this.$t("M.locked_warehouse"))
        return
      }
      this.coinId = item && item.coinId
      this.showTransfer = item && true
    },
    // 划转弹窗关闭
    onCloseTrans() {
      this.showTransfer = false
    },
    // 划转弹窗确认
    onConfirmTrans() {
      this.getBBData()
      this.onCloseTrans()
    },


    // 获取币种可交易列表
    getBBTradeUrl(coinId) {
      this.$api.getBBTradeUrl({ coinId }).then((response) => {
        if (!response.meta.success) return
        const { entrustTrade, entrustTradeList } = response.data
        if (!entrustTrade) return this.$notify(this.$t('M.not_trading'))
        this.actions = entrustTradeList.map((item, i) => ({
          id: item.tradeId,
          name: item.tradeName,
          symbol: item.symbol,
          color: i == 0 ? '#00b897' : ''
        }))
        this.showType = true;
      })

    },

    // 选择可交易列表
    onSelect(item) {
      this.onCancel()
      this.$router.push({
        name: `TradeCenter`,
        params: {
          symbol: item.symbol,
        }
      })
    },


    // 交易列表取消
    onCancel() { this.showType = false; },


    // 隐藏小于0.001币种
    onChecked(e) {
      this.isTotalGtZero = e
      this.getBBData()
    },
    // 币种搜索
    onSearch(list) {
      this.coinList = list
    }

  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
}
.flexC {
  justify-content: center;
  align-items: center;
}
.assetRecords {
  height: 100%;
  overflow-y: scroll;
}
.button_wrap {
  justify-content: space-between;
  align-items: center;
  .button {
    width: 165px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #fff;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
  }
}
.capital_top {
  position: fixed;
  top: 50px;
  z-index: 999;
  width: 100%;
  height: 110px;
  padding: 20px 15px;
  // background: url('~@/assets/img/assets/bg_capital.png') no-repeat center;
  // background-size: cover;
  background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  .capital_top_title {
    width: 100%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
      margin-right: 20px;
    }
    .icon {
      width: 18px;
    }
    .icon_eyeoff {
      width: 18px;
      height: 8px;
    }
  }

  .total {
    height: 35px;
    font-weight: bold;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 20px;
    > span:first-child {
      font-size: 30px;
      line-height: 1;
      font-family: DINCondensed-Bold, DINCondensed;
    }
    > span.info {
      font-size: 13px;
      font-weight: 600;
      margin-left: 5px;
    }
  }
}

.capital_list {
  // margin-top: 118px;
  margin-top: 178px;
  margin-bottom: 10px;
  padding-bottom: 50px;
  .capital_list_item {
    padding: 15px;
    border-bottom: 1px solid #ebebf0;
    .list_item_tit {
      margin: 2px 0 10px;
      justify-content: space-between;
      .col {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 12px;
        font-weight: 400;
        color: #5c6573;
      }
    }
    .row {
      font-size: 16px;
      font-weight: 400;
      color: #191b27;
      align-items: center;
    }
    img {
      width: 18px;
      height: 18px;
      border: 1px solid;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 4px;
    }
    .num_wrap {
      justify-content: space-around;
      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: #5c6573;
        height: 40px;
      }
      > div:nth-child(2) {
        align-items: center;
      }
      > div:nth-child(3) {
        align-items: flex-end;
      }
    }
    .button_wrap {
      .button {
        background: #00b897;
      }
      .button_disable {
        background: #ccc;
      }
    }
  }
  .capital_list_item_active {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    background: #f8f8fb;
    .button_wrap_active {
      border-top: 1px solid #ebebf0;
      margin-top: 15px;
      padding-top: 15px;
      .matchmaking {
        height: 30px;
        font-size: 10px;
        color: #999;
        justify-content: space-between;
        margin-bottom: 15px;
      }
      .button_wrap {
        .button {
          color: #fff;
          border: none;
          opacity: 1;
        }
      }
    }
  }
}
::v-deep .van-sticky--fixed {
  top: 50px;
}
</style>
