<template>
  <div class="container">
    <nav-bar class="nav_bar" :title="$t('M.avoid_close_set')" />
    <div class="cell_box">
      <van-cell center :title="$t('M.never_enter')">
        <template #right-icon>
          <van-switch :value="checkedNever" size="24" active-color="#00b897" inactive-color="#D5D5D5" @input="onInput($event,'never')" />
        </template>
      </van-cell>
      <van-cell center :title="$t('M.time_the_input',[userPayPassConfig.partnerPayPasswdTime])">
        <template #right-icon>
          <van-switch :value="checkedUserset" size="24" active-color="#00b897" inactive-color="#D5D5D5" @input="onInput($event,'userset')" />
        </template>
      </van-cell>
      <van-cell center :title="$t('M.each_input')">
        <template #right-icon>
          <van-switch :value="checkedEvery" size="24" active-color="#00b897" inactive-color="#D5D5D5" @input="onInput($event,'everytime')" />
        </template>
      </van-cell>
    </div>

    <popup-password :show="show" :onlyEnablePassword="show" @onClose="onClose" @onSubmit="onSubmit" />

  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import navBar from '@/components/navBar';
import popupPassword from '@/components/popupPassword';

export default {
  name: 'noPsdSetting',
  components: {
    navBar, popupPassword
  },
  data() {
    return {
      type: '',
      time: '',
      status: '',
      show: false
    };
  },

  computed: {
    ...mapGetters([
      'userPayPassConfig'
    ]),
    checkedNever() {
      if (this.userPayPassConfig.notInputPayPasswdTime == "never") return true
      return false
    },
    checkedUserset() {
      if (this.userPayPassConfig.notInputPayPasswdTime == "userset") return true
      return false
    },
    checkedEvery() {
      if (this.userPayPassConfig.notInputPayPasswdTime == "everytime") return true
      return false
    }
  },
  mounted() {
    this.$store.dispatch('userPayPasswordSet')

  },

  methods: {
    // 是否在免密时间内
    isInputPayPasswd() {
      this.$api.isInputPayPasswd().then((response) => {
        if (!response.meta.success) return
        if (response.data) {
          this.show = true
          return
        }
        this.notInputPayPasswd('6666')

      })
    },
    // 免密配置信息
    // getConfigAndUserPayPassSet() {
    //   this.$api.partnerConfigAndUserPayPassSet().then((response) => {
    //     if (!response.meta.success) return
    //     const { partnerPayPasswdTime, notInputPayPasswdTime } = response.data
    //     this.type = notInputPayPasswdTime && notInputPayPasswdTime
    //     this.time = partnerPayPasswdTime

    //   })
    // },
    // switch事件
    onInput(checked, e) {
      if (!checked) return this.$notify(this.$t('M.at_least_one'))
      this.status = e
      this.isInputPayPasswd()

    },
    //  确认修改
    notInputPayPasswd(payPassword) {
      const params = { status: this.status, payPassword }
      this.$api.notInputPayPasswd(params).then((response) => {
        if (!response.meta.success) return
        this.$notify(response.meta.message)
        // this.getConfigAndUserPayPassSet()
        this.$store.dispatch('userPayPasswordSet')
      })
    },

    // 支付密码回调
    onSubmit(payPassword) {
      this.notInputPayPasswd(payPassword)
      this.onClose()
    },

    onClose() {
      this.show = false
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #f8f8f8;
  .cell_box {
    padding: 0 15px;
    margin-top: 10px;
  }
}
</style>