<template>
  <div class="container">
    <nav-bar :showTitle="false" :border="true">
      <div slot="title" class="title">
        <div :class="[active==1?'active_btn':'',  'btn']" @click="onChange(1)">{{$t('M.Charge_money')}}</div>
        <div :class="[active==2?'active_btn':'',  'btn']" @click="onChange(2)">{{$t('M.recharge_withdraw_msg_type_0002')}}</div>

      </div>
    </nav-bar>
    <!-- 充值列表 -->
    <van-pull-refresh v-if="active==1" v-model="refreshing" :pulling-text="$t('M.MJRefreshHeaderIdleText')"
      :loosing-text="$t('M.MJRefreshHeaderPullingText')" :loading-text="$t('M.loading')" @refresh="onRefresh">

      <van-list class="list" v-model="loading" offset="10" :finished="finished" :loading-text="$t('M.loading')" @load="onLoad">
        <div v-if="refreshing" slot="loading"></div>
        <div class="item" v-for="(item,i) in list" :key="i">
          <div class="row">
            <div class="name">
              <span>{{item.coinName}}</span>
              <span :class="[item.status=='LOCK'?'LOCK':'']">{{$t('M.' + item.i18nStatusName)}}</span>
            </div>
            <div v-if="active==1" class="num">{{'+'+ Number(item.amount)}}</div>
          </div>
          <div class="row">
            <div class="date">{{item.createTime}}</div>
          </div>
          <div class="row">
            <div class="address">{{$t('M.ios_充币地址')}}: {{item.rechargeAddress || $t('M.Charge_money')}}</div>
            <div class="totalMassage">{{$t('M.balance')}}：{{item.totalMassage}}</div>
          </div>
          <div class="row cancelReason" v-if="item.cancelReason"> {{item.cancelReason}} </div>
        </div>

        <div class="noData" v-if="!list.length && finished">
          <img class="imgNoData" src="@/assets/img/public/nodata.png" alt="" srcset="" />
          <p class="text">{{$t('M.no_data')}}</p>
        </div>
      </van-list>
    </van-pull-refresh>

    <!-- 提币列表 -->

    <van-pull-refresh v-if="active==2" v-model="refreshingWR" :pulling-text="$t('M.MJRefreshHeaderIdleText')"
      :loosing-text="$t('M.MJRefreshHeaderPullingText')" :loading-text="$t('M.loading')" @refresh="onRefreshWR">

      <van-list class="list" v-model="loadingWR" offset="10" :finished="finishedWR" :loading-text="$t('M.loading')" @load="onLoadWR">
        <div v-if="refreshingWR" slot="loading"></div>
        <div class="item" v-for="(item,i) in listWR" @click="to(item)" :key="i">
          <div class="row">
            <div class="name">
              <span>{{item.coinName}}</span>
              <span :class="[item.status=='LOCK'?'LOCK':'']">{{ statusOptions[item.status] }}</span>
            </div>
            <div class="num">
              {{(item.status == 'CANCEL' ||  item.status == 'USER_CANCEL')?'+' : '-'}}
              {{ Number(item.amount) }}
            </div>

          </div>
          <div class="row">
            <div class="date">{{item.createTime}}</div>
          </div>
          <div class="row">
            <div class="address">{{$t('M.Coin_Address_draw')}}: {{item.withdrawAddress}}</div>
            <div class="totalMassage">{{$t('M.balance')}}：{{item.totalMassage}}
              <div v-if="item.status== 'WAIT-WITHDRAW'" class="button_cancel" @click.stop="onClickCancel(item.id,item.version,i)">
                {{$t('M.repeal')}}</div>
            </div>
          </div>
          <div class="row cancelReason" v-if="item.cancelReason"> {{item.cancelReason}} </div>
        </div>
      </van-list>
      <div class="noData" v-if="!listWR.length && finishedWR">
        <img class="imgNoData" src="@/assets/img/public/nodata.png" alt="" srcset="" />
        <p class="text">{{$t('M.no_data')}}</p>
      </div>
    </van-pull-refresh>

  </div>
</template>

<script>
import navBar from '@/components/navBar';
import {mapGetters} from 'vuex'

export default {
  name: 'withdrawalRecords',
  components: {
    navBar
  },
  computed: {
    ...mapGetters([
      'partnerId'
    ]),
  },
  data() {
    return {
      active: 1,
      finishedText: this.$t('M.no_more_data'),
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      queryParams: {
        coinId: '',
        type: '',
        pageSize: 10
      },
      currentPage: 1,

      listWR: [],
      loadingWR: false,
      finishedWR: false,
      refreshingWR: false,
      queryParamsWR: {
        coinId: '',
        type: '',
        pageSize: 10
      },
      currentPageWR: 1,

      coinId: '',

      typeOption: {
        1: 'RECHARGE',
        2: 'WITHDRAW'
      },

      statusOptions: {
        'WAIT-WITHDRAW': this.$t('M.recharge_withdraw_msg_status_0001'), // 等待提幣
        'LOCK': this.$t('M.recharge_withdraw_msg_status_0002'),// 發送中
        'WITHDRAW-SUCCESS': this.$t('M.recharge_withdraw_msg_status_0003'), // 提幣成功
        'CANCEL': this.$t('M.Cancel'), // 取消
        'USER_CANCEL': this.$t('M.Cancel'), // 取消
        "AUDITED": this.$t('M.recharge_withdraw_msg_status_0002'), // 發送中
      },
    };
  },

  watch: {


  },
  beforeMount() {
    const { coinId = '', active = 1 } = this.$route.query
    this.active = active
    this.coinId = coinId
  },
  mounted() {
    if (this.active == 1) {
      this.getListWR()
    } else {
      this.getList()
    }
  },

  methods: {
    onChange(e) {
      this.active = e
    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.currentPage = 1
          this.list = [];
          this.refreshing = false;
        }
        this.getList()
      }, 500);

    },


    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },

    getList() {
      this.queryParams.type = this.typeOption[1]
      const params = { ...this.queryParams, currentPage: this.currentPage, partnerId: this.partnerId, coinId: this.coinId }
      this.$api.queryWithdrawRecording(params).then((response) => {
        if (!response.meta.success) return
        const { list, total } = response.data
        this.loading = false;
        if (this.currentPage == 1) {
          this.list = list
        } else {
          this.list = this.list.concat(list)
        }
        this.currentPage += 1
        if (this.list.length >= total) {
          this.finished = true;
        }
      })
    },



    // 提币
    onLoadWR() {

      setTimeout(() => {
        if (this.refreshingWR) {
          this.currentPageWR = 1
          this.listWR = [];
          this.refreshingWR = false;
        }
        this.getListWR()
      }, 500);

    },


    onRefreshWR() {
      // 清空列表数据
      this.finishedWR = false;
      this.loadingWR = true;
      this.onLoadWR();
    },

    getListWR() {
      this.queryParamsWR.type = this.typeOption[2]
      const params = { ...this.queryParamsWR, currentPage: this.currentPageWR, partnerId: this.partnerId, coinId: this.coinId }
      console.log(params);
      this.$api.queryWithdrawRecording(params).then((response) => {
        if (!response.meta.success) return
        const { list, total } = response.data
        this.loadingWR = false;
        if (this.currentPageWR == 1) {
          this.listWR = list
        } else {
          this.listWR = this.listWR.concat(list)
        }
        this.currentPageWR += 1
        if (this.listWR.length >= total) {
          this.finishedWR = true;
        }
      })
    },



    // 提币详情
    to(item) {
      const params = {
        statusName: this.statusOptions[item.status],
        withdrawAddress: item.withdrawAddress,
        networkFees: Number(item.networkFees),
        amount: ((item.status == 'CANCEL' || item.status == 'USER_CANCEL') ? '+' : '-') + Number(item.amount),
        cancelReason: item.cancelReason,
        createTime: item.createTime,
        coinName: item.coinName,
        title: this.$t('M.withdrawal_details'),
        type: 1
      }
      this.$router.push({
        path: '/detail', query: params
      })
    },

    // 提币取消
    onClickCancel(id, version, i) {
      this.$api.cancelWithdraw({ orderId: id, version: version, }).then((response) => {
        if (!response.meta.success) return
        this.$notify(response.meta.message)
        this.currentPageWR = 1
        this.listWR[i].status = 'USER_CANCEL'
        this.$store.dispatch('getUserWalletAssets')
      })

    }
  },
};
</script>

<style lang="scss" scoped>
div {
  font-size: 16px;
}
.title {
  display: flex;
  align-items: center;
  > div:first-child {
    margin-right: 15px;
  }
  .btn {
    //width: 85px;
    padding: 0 4px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 2px;
    font-size: 14px;
    color: #9299a9;
    border: 1px solid #9299a9;
  }
  .active_btn {
    background: #00b897;
    font-weight: 600;
    color: #ffffff;
    border: none;
  }
}
.list {
  height: 100%;
  padding: 10px;
  .item {
    width: 100%;
    min-height: 100px;
    border-radius: 3px;
    border-bottom: 1px solid #ebebf0;
    margin-bottom: 10px;
    padding: 0 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;

      > div {
        font-size: 13px;
      }
      .name {
        display: flex;
        align-items: center;
        > span:first-child {
          font-size: 16px;
          font-weight: 600;
          margin-right: 10px;
        }
        > span:last-child {
          font-size: 13px;
          color: #9299a9;
        }
        > span.LOCK {
          color: #00b897;
        }
      }
      .num {
        color: #18ae83;
      }
      .date {
        color: #9299a9;
      }
      .address {
        flex: 2;
        // width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .totalMassage {
        margin-left: 10px;
        text-align: right;
      }
    }
    .cancelReason {
      font-size: 12px;
      color: #00b897;
    }
    .button_cancel {
      background: #00b897;
      text-align: center;
      font-size: 14px;
      color: #fff;
      padding: 4px 0;
      border-radius: 2px;
      margin-top: 10px;
    }
  }
}
.van-pull-refresh {
  // height: calc(100% - 50px);
  // overflow-y: scroll;
}
</style>
