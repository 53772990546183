<!--
 * @Author:
 * @Date: 2022-07-19 19:17:12
 * @LastEditors:
 * @LastEditTime: 2022-07-19 19:30:10
 * @FilePath: \exchange-h5\src\views\home\Guide.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="guide container">
    <van-nav-bar :title="$t('M.ADddToDesktop')" left-text="" left-arrow @click-left="onClickLeft" />
    <div class="content">
      <h1>{{$t('M.ADddToDesktop2')}}</h1>
      <p>{{$t('M.CDommonbrowserAddMethod')}}</p>
      <img src="../../assets/img/home/1.png" alt="" srcset="">
      <img src="../../assets/img/home/2.png" alt="" srcset="">
      <img src="../../assets/img/home/3.png" alt="" srcset="">
      <img src="../../assets/img/home/4.png" alt="" srcset="">
      <img src="../../assets/img/home/5.png" alt="" srcset="">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onClickLeft() {
      this.$router.push("/home")
    }
  }
}
</script>

<style lang="scss" scoped>
.guide {
  background-color: #f7f7f7;
  .content {
    padding: 18px;
    h1 {
      font-size: 20px;
      color: #211f1f;
      line-height: 40px;
    }
    p {
      color: #949494;
      margin-bottom: 20px;
    }
    img {
      margin-bottom: 8px;
    }
  }
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}
</style>