<!--
 * @Author:
 * @Date: 2022-06-19 11:53:24
 * @LastEditors:
 * @FilePath: \exchange-h5\src\page\userCenter.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="userCenter container">
    <!-- 用户信息头部内容 -->
    <div class="user-top">
      <div class="bgcolor"></div>
      <div class="top-back">
        <div @click="back" class="btn-back">
          <img class="ic-back" src="../../assets/img/userCenter/ic_back.png" />
        </div>
      </div>
      <img class="user-bck" src="../../assets/img/userCenter/user_infobg.png" />
      <van-uploader :after-read="afterRead" class="user-head" v-if="isLogin">
        <img :src="user.logo" v-if="user.logo" />
        <img v-else src="../../assets/img/userCenter/user_headbg.png" />
      </van-uploader>
      <img v-if="!isLogin" src="../../assets/img/userCenter/user_headbg.png" class="user-head" @click="clickme" />
      <div class="user-rframe" v-if="isLogin" @click="clickme">
        <span class="user-name" @click="show = true">
          {{ user.nickName }}
          <!--          <img class="tag-v" src="../../assets/img/userCenter/ic_v.png" />-->
          <!--          <span class="v-val">{{ user.level.split("VIP")[1] }}</span>-->
          <van-icon name="edit" color="#00b897"/>
        </span>

        <span class="user-numb" style="line-height:24px;">
          <!-- <span class="numb-val"> {{$t('M.welcome')}}</span> -->
          <span class="numb-val">{{ $t('M.account') }}： {{ user.userName | splice }}</span>

        </span>

        <span class="user-uid">
          <span class="uid-val">UID: {{ user.showId }}</span>
          <a :data-clipboard-text="user.showId" class="iconfont icon-copy">
            <img class="ic-copy" src="../../assets/img/userCenter/ic_copy.png" />
          </a>
        </span>
      </div>
      <div class="user-rframe" v-if="!isLogin" @click="clickme">
        <span class="user-name">
          {{ $t('M.Sign in') }}
        </span>

        <span class="user-numb" style="line-height:24px;">
          <span class="numb-val"> {{ $t('M.welcome') }}</span>
        </span>
      </div>
    </div>

    <!-- 菜单item -->
    <van-cell-group>
      <!--      <van-cell @click="toIoanPage" class="usercenter-cell" size="large" is-link>-->
      <!--        <template #icon>-->
      <!--          <img class="ic-cellicon" src="../../assets/img/userCenter/IC_icon.png" />-->
      <!--        </template>-->
      <!--        <template #title>-->
      <!--          <span class="cell-title">{{ $t("M.loan") }}</span>-->
      <!--        </template>-->
      <!--      </van-cell>-->
      <!-- <van-cell @click="toSharePage" class="usercenter-cell" size="large" is-link>
        <template #icon>
          <img class="ic-cellicon" src="../../assets/img/userCenter/ic_invite.png" />
        </template>
        <template #title>
          <span class="cell-title">{{ $t("M.Invite Friends") }}</span>
        </template>
      </van-cell> -->
      <van-cell @click="goSecurityCenter" class="usercenter-cell" size="large" is-link>
        <template #icon>
          <img class="ic-cellicon" src="../../assets/img/userCenter/ic_safe.png" />
        </template>
        <template #title>
          <span class="cell-title">{{ $t("M.security_center") }}</span>
        </template>
      </van-cell>
      <van-cell @click="goAuthentication" class="usercenter-cell" size="large" is-link>
        <template #icon>
          <img class="ic-cellicon" src="../../assets/img/userCenter/ic_id.png" />
        </template>
        <template #title>
          <span class="cell-title">{{ $t("M.identity_authentication") }}</span>
        </template>
      </van-cell>
      <!-- <van-cell @click="toRecords" class="usercenter-cell" size="large" is-link>
          <template #icon>
            <img class="ic-cellicon" src="../assets/img/userCenter/ic_help.png" />
          </template>
          <template #title>
            <span class="cell-title">帮助中心</span>
          </template>
        </van-cell> -->
      <van-cell @click="toServe" class="usercenter-cell" size="large" is-link>
        <template #icon>
          <img class="ic-cellicon" src="../../assets/img/userCenter/ic_service.png" />
        </template>
        <template #title>
          <span class="cell-title">{{ $t('M.contact_service') }}</span>
        </template>
      </van-cell>
      <!-- <van-cell @click="toRecords" class="usercenter-cell" size="large" is-link>
          <template #icon>
            <img class="ic-cellicon" src="../assets/img/userCenter/ic_msg.png" />
          </template>
          <template #title>
            <span class="cell-title">消息</span>
          </template>
        </van-cell> -->
      <!-- <van-cell @click="goYanzheng" class="usercenter-cell" size="large" is-link>
        <template #icon>
          <img class="ic-cellicon" src="../../assets/img/userCenter/ic_check.png" />
        </template>
        <template #title>
          <span class="cell-title">{{ $t("M.official_verification_channel") }}</span>
        </template>
      </van-cell> -->
      <van-cell @click="gosettings" class="usercenter-cell" size="large" is-link>
        <template #icon>
          <img class="ic-cellicon" src="../../assets/img/userCenter/ic_setting.png" />
        </template>
        <template #title>
          <span class="cell-title">{{ $t("M.setting") }}</span>
        </template>
      </van-cell>
    </van-cell-group>

    <van-dialog v-model="show" :title="$t('M.change1') +' '+ $t('M.nick_name')"
                :confirmButtonText="$t('M.affirm')"
                :cancelButtonText="$t('M.Cancel')" confirmButtonColor="#00b897" show-cancel-button
                @confirm="changeName" @open="openShow">
      <div>
        <van-field v-model="nameValue" placeholder="" @input="changNameValue"/>
        <p v-if="errTips" class="errTips">{{ $t('M.comm_nickname_rule1') }}</p>
        <p v-if="errTips" class="errTips">{{ $t('M.comm_nickname_rule2') }}</p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {Toast} from 'vant';
import heic2any from 'heic2any';

export default {
  filters: {
    splice(string) {
      if (!string) return
      return string.substring(0, 3) + '****' + string.substring(string.length - 4, string.length);
    }
  },
  data() {
    return {
      usernumb: this.$t("M.welcome_to"),
      uid: '',
      show: false,
      nameValue: '',
      errTips: false,
    }
  },
  computed: {
    ...mapGetters([
      'user', 'isLogin'
    ])
  },
  mounted() {
    localStorage.setItem("UpFlag", "1")
  },
  beforeDestroy() {
    localStorage.setItem("UpFlag", "0")
  },
  methods: {
    openShow() {
      this.errTips = false
      this.nameValue = this.user.nickName
    },
    changNameValue() {
      const regex = /^[\u4e00-\u9fa5a-zA-Z]+$/;
      let kong = this.nameValue.length > 0
      let stringType = !regex.test(this.nameValue)
      let stringLength = true
      let iLength = 0 // 记录字符的字节数
      for (let i of this.nameValue) {
        if (this.nameValue.charCodeAt(i) > 255) { // 判斷字節數
          iLength += 2
        } else {
          iLength += 1
        }
      }
      stringLength = iLength > 15;
      this.errTips = kong && (stringType || stringLength);
    },
    changeName() {
      if (this.errTips) {
        this.$notify(this.$t('M.common_failure'))
      } else {
        this.$api.changeNickName({
          nickName: this.nameValue
        }).then((res) => {
          this.$notify(res.meta.message)
          this.$store.dispatch('getUserData')
        })
      }
    },
    afterRead(fileObj) {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      if (fileObj.file.name.includes("heic")) {
        heic2any({
          blob: fileObj.file,
          toType: 'image/jpg',
        }).then((pngBlob) => {
          const pngFile = new File([pngBlob], 'converted.jpg', { type: 'image/jpg' });
          this.changeImgFile(pngFile)
        });

      } else {
        this.changeImgFile(fileObj.file)
      }
    },
    changeImgFile(file) {
      let that = this;
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          const maxSize = Math.min(image.width, image.height);
          const startX = (image.width - maxSize) / 2;
          const startY = (image.height - maxSize) / 2;
          canvas.width = maxSize;
          canvas.height = maxSize;
          context.drawImage(
            image,
            startX,
            startY,
            maxSize,
            maxSize,
            0,
            0,
            maxSize,
            maxSize
          );
          canvas.toBlob((blob) => {
            let croppedFile = new File([blob], file.name)
            let name = croppedFile.name.split(".");
            let fileType = name[name.length - 1].toLowerCase();
            let isImg = ["jpg", "jpeg", "png", "gif"].includes(fileType);
            let copyFile = new File([croppedFile], file.name);
            if (isImg) {
              let form = new FormData();
              form.append("file", copyFile);
              form.append("type", "other");
              that.$api.uploadfile(form).then((res) => {
                that.$api.changeUserLogo({ logo: res.data.fileUrl }).then(() => {
                  that.$store.dispatch('setHeadUrl', res.data.fileUrl)
                  Toast.clear();
                })
              })
            }
          }, file.type, 0.5);
        };
      };
      reader.readAsDataURL(file);
    },
    /* 去客服页面 */
    toServe() {
      this.$router.push({ name: 'serve' })
    },
    clickme() {
      if (!this.isLogin) {
        this.$router.push({ name: 'login' })
        return;
      }
    },
    back() {
      this.$router.push({ name: 'home' })
      // this.$router.back()

    },
    gosettings() {
      this.$router.push({ name: 'settings' })
    },
    goYanzheng() {
      window.location.assign("https://voenl.com/verifyPipe/m?language=en_US");
    },
    goAuthentication() {
      this.$router.push({ name: 'identity' })
    },
    goSecurityCenter() {
      this.$router.push({ name: 'securityCenter' })
    },
    /* 去邀请好友页面 */
    toSharePage() {
      this.$router.push({ name: 'invitation' })
    },
    /* 去邀请好友页面 */
    toIoanPage() {
      this.$router.push({ name: 'ioan' })
    },
    copyCode() {
      var url = this.user.showId;
      var input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', url);
      input.select();
      document.execCommand("copy"); // 执行浏览器复制命令
      if (document.execCommand('copy')) {
        document.execCommand('copy');
      }
      document.body.removeChild(input)
    },
    toRecords() { }
  },
}
</script>

<style lang="scss" scoped>
.userCenter {
  padding: 0;
}

.top-back {
  width: 100%;
  height: 80px;
  padding-top: 24px;

  .btn-back {
    width: 34px;
    height: 34px;
    text-align: center;
  }

  .ic-back {
    height: 17px;
    width: 10px;
  }
}

.user-top {
  position: relative;
  width: 100%;
  height: 240px;

  // background-image: url('../../assets/img/userCenter/topbg.png');
  // background-repeat: no-repeat;
  // background-size: 100%;
  .bgcolor {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 150px;
    background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
  }
}

.user-bck {
  width: 94%;
  margin-left: 3%;
}

.user-head {
  position: absolute;
  width: 64px;
  height: 64px;
  z-index: 10;
  left: 30px;
  top: 118px;

  img {
    border-radius: 50%;
  }
}

.user-rframe {
  position: absolute;
  padding: 0;
  height: 80px;
  left: 110px;
  top: 120px;
  color: #00b897;
}

.user-name {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  float: left;
}

.user-numb {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  float: left;
}

.user-uid {
  display: flex;
  width: 100%;
  font-size: 12px;
  float: left;
}

.ic-copy {
  display: inline-block;
  position: absolute;
  width: 15px;
  margin-left: 10px;
}

.tag-v {
  margin-left: 6px;
  width: 18px;
}

.tag-score {
  width: 44px;
  margin-top: -4px;
}

.v-val {
  color: #d2993e;
  font-size: 14px;
}

.study-time {
  position: absolute;
  width: 100%;
  height: 200px;
  top: 210px;
}

.vline {
  position: absolute;
  width: 2px;
  height: 100px;
  background: rgb(162, 209, 253);
  top: 50px;
  left: 50%;
  z-index: 10;
}

.time-item {
  width: 50%;
  height: 100%;
  float: left;
  text-align: center;
}

.time-tit {
  display: inline-block;
  width: 100%;
  margin-top: 50px;
  color: #becce9;
  font-size: 3.8vw;
}

.time-val {
  font-size: 5.8vw;
  font-weight: 600;
  color: #fff;
}

.time-unit {
  font-weight: 400;
  font-size: 4vw;
}

.usercenter-cell .van-cell__left-icon {
  font-size: 60px;
}

.usercenter-cell .van-cell__title {
  padding-left: 20px;
}

.contact-us {
  position: relative;
  width: 100%;
  height: 220px;
  float: left;
  background: #fff;
  padding-left: 120px;
  padding-top: 10px;
  font-size: 30px;
  color: #6e6e6e;
}

.contact-p {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
}

.div-exit {
  position: relative;
  width: 100%;
  height: 150px;
  margin-top: 15px;
  background: #fff;
  float: left;
}

.exit-btn {
  position: absolute;
  width: 60%;
  height: 80px;
  line-height: 80px;
  border: 1px solid #62697c;
  text-align: center;
  border-radius: 50px;
  color: #62697c;
  top: 30px;
  left: 50%;
  margin-left: -30%;
}

.ic-cellicon {
  margin-top: 5px;
  width: 16px;
  height: 16px;
}

.cell-title {
  color: #191b27;
  font-size: 14px;
  font-weight: 600;
}

.errTips {
  font-size: 12px;
  color: red;
  padding-left: 15px;
  margin-bottom: 4px;
}
</style>