<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-19 16:03:17
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="setPassword container">
    <van-nav-bar :title="$t('M.bind_email')" left-text="" left-arrow @click-left="onClickLeft" />

    <div class="content">

      <van-cell-group style="padding:0;">
        <van-field v-model="phoneNum" :placeholder="$t('M.input_email')" />
        <div class="getmgs" v-if="downTimeNum <= 0 " @click="sendMsg">{{$t('M.get_code')}}</div>
        <div class="getmgs" v-if="downTimeNum > 0 ">{{downTimeNum}}</div>
        <van-field v-model="codeNum" :placeholder="$t('M.Enter the verification code')" />
      </van-cell-group>

      <div class="next" @click="sur"> {{$t('M.confirm')}}</div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      link: '',
      phoneNum: '',
      codeNum: '',
      value: '',
      countriesListShow: false,
      downTimeNum: 0,
      timeObj: null
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
  },
  mounted() {

  },
  methods: {
    /* 确定 */
    sur() {
      if (this.phoneNum == '') {
        this.$notify(this.$t('M.input_email'))
        return
      }
      if (this.codeNum == '') {
        this.$notify(this.$t('M.Enter the verification code'))
        return
      }
      this.$api
        .bindMail({
          partnerId: "472437831826935808",
          email: this.phoneNum,
          code: this.codeNum,
          partnerNo: "1000010001",
        })
        .then((data) => {
          if (data.meta.success) {
            this.$notify(data.meta.message)
            this.$store.dispatch('getUserData')
            this.$router.replace("securityCenter")
          } else {
            this.$notify(data.meta.message)
          }
        });
    },
    /* 获取验证码 */
    sendMsg() {

      if (this.phoneNum == '') {
        this.$notify(this.$t('M.input_email'))
        return
      }
      this.$api
        .sendMail({
          partnerId: "472437831826935808",
          email: this.phoneNum,
          nationCode: this.user.country,
          action: "BINDING_EMAIL"
        })
        .then((data) => {
          if (data.meta.success) {
            this.downTimeNum = 59;
            this.timerDown();
            this.$notify(data.meta.message)
          } else {
            this.$notify(data.meta.message)
          }
        });
    },
    /* 倒计时开启 */
    timerDown() {
      this.downTimeNum;
      this.timeObj = setInterval(() => {
        this.downTimeNum--;
        if (this.downTimeNum <= 0) {
          this.downTimeNum = 0;
          clearInterval(this.timeObj);
        }
      }, 1000);
    },
    onClickLeft() {
      this.$router.back()
    }
  },
  destroyed() {
    clearInterval(this.timeObj)
  }
}
</script>

<style lang="scss" scoped>
.setPassword {
  font-size: 12px;
}

.getmgs {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 14px;
  color: #00b897;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
::v-deep .van-cell {
  background-color: #f8f8f8;
  padding: 10px 10px;
  margin-top: 10px;
  border-radius: 5px;
}
::v-deep .van-button--primary {
  background-color: #f8f8f8;
  border: 0;
  color: #00b897;
}

.content {
  padding: 0 20px;
  .area {
    background-color: #f8f8f8;
    padding: 5px 5px;
    width: 70px;
    text-align: center;
    border-radius: 20px;
    margin-top: 20px;
  }
  .next {
    width: 100%;
    height: 46px;
    background: #00b897;
    border-radius: 4px;
    text-align: center;
    line-height: 46px;
    font-size: 18px;
    color: #fff;
    margin-top: 50px;
  }
}
::v-deep .van-popup {
  .van-search--show-action {
    padding-right: 10px;
  }
  .van-search__content {
    border-radius: 5px;
    .van-icon {
      color: #8a8a8a;
    }
  }
  .van-search__action {
    color: #8a8a8a;
  }
  .countriesListBox {
    .listItem {
      font-size: 14px;
      line-height: 54px;
      margin: 0 19px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e5e5e5;
    }
  }
}
</style>