export const M = {
   "Slideright": "Arrastre el control deslizante a la derecha.",
    "Pleaseverification": "Complete la verificación.",
    "Pleaseorder": "Haga clic secuencialmente.",
    "Verificationagain": "Verificación fallida, intente de nuevo.",
    "10 orders": "10 órdenes.",
    "24h High": "Máx. 24H.",
    "24h Low": "Mín. 24H.",
    "24h Trading Vol": "Volumen 24H.",
    "24h Vol": "Vol. 24H.",
    "ADL mechanism": "Mecanismo ADL.",
    "AMT": "Cantidad.",
    "Activity _home": "Actividad.",
    "Actual transaction number": "Número de transacciones.",
    "Actual transaction price": "Precio de transacción.",
    "Add margin": "Añadir margen.",
    "Adjust leverage": "Ajustar apalancamiento.",
    "Adjust success": "Ajuste exitoso.",
    "Advanced limit order offers 3": "Las órdenes límite avanzadas ofrecen tres mecanismos de activación en comparación con las órdenes límite normales: 'Post only', 'FillOrKill' y 'ImmediateOrCancel'; mientras que el mecanismo de activación de las órdenes límite normales es por defecto 'Siempre válido'.",
    "Alipay": "Alipay.",
    "All Closed": "Todo cerrado.",
    "All Filled": "Todo lleno.",
    "AllDetails": "Ver detalles.",
    "AllType": "Todos los tipos.",
    "Amount can flat": "Cantidad a cerrar.",
    "Appeal_reason": "Razón de apelación.",
    "Applet service": "Servicio de applet.",
    "Are you sure to cancel the order？": "¿Confirmar cancelación?",
    "Asked_to_record": "Grabe el video solicitado.",
    "Asset conversion": "Conversión de activos.",
    "Asset_types": "Tipos de activos.",
    "At the current price": "Ordenar al mejor precio.",
    "Avail.": "Disponible.",
    "Avail. margin": "Margen disponible.",
    "Available": "Disponible.",
    "Avg close price": "Precio promedio de cierre.",
    "Avg open price": "Precio promedio de apertura.",
    "Avg.price": "Precio promedio.",
    "BBO": "Mejor oferta.",
    "Bankcard": "Tarjeta bancaria.",
    "Bankruptcy price": "Precio de quiebra.",
    "Batch Cancel": "Cancelar en lote.",
    "Branch_address": "Dirección de sucursal.",
    "Buy crypto_home": "Comprar criptomonedas.",
    "Calculations are not based on": "Cálculos solo de referencia.",
    "Can't calculate": "No se puede calcular.",
    "Cancel": "Cancelar.",
    "Cancel all": "Cancelar todo.",
    "Cancel order": "Cancelar orden.",
    "Cancel_the_login": "Cancelar inicio de sesión.",
    "Capital cost": "Costo de capital.",
    "Capital cost - Expenditure": "Costo - Gastos.",
    "Capital fee": "Tarifa de capital.",
    "Capital fee - all": "Tarifa - Todo.",
    "Capital fee - income": "Tarifa - Ingresos.",
    "Capital success": "Cancelado.",
    "Charge_money": "Recargar.",
    "Chinese_Yuan": "Renminbi.",
    "Close all of": "¿Cerrar todo?",
    "Close long": "Cerrar largo.",
    "Close out time": "Tiempo de cierre.",
    "Close price": "Precio de cierre.",
    "Close short": "Cerrar corto.",
    "Closed": "Cerrado.",
    "Closing PnL gains": "Ganancias de cierre.",
    "Closing PnL ratio": "Ratio de cierre.",
    "Closing profit": "Beneficio de cierre.",
    "coin_failure_0002": "Datos de moneda no disponibles.",
    "Common functions": "Funciones comunes.",
    "Communtiy_home": "Comunidad.",
    "Conceal canceled orders": "Ocultar órdenes canceladas.",
    "Confirm_the_login": "Confirmar inicio de sesión.",
    "Confirm_to_mention_money": "Confirmar retiro.",
    "Cont": "Contratos.",
    "Copy link": "Copiar enlace.",
    "Cross": "Margen cruzado.",
    "Currency_rate_description": "Descripción de tarifas.",
    "Current": "Actual.",
    "Delete_option": "Eliminar.",
    "Determine_to_cancel": "¿Confirmar cancelación?",
    "Don't show me again": "No mostrar de nuevo.",
    "Draw_inconsistency": "Contraseña no coincide.",
    "Draw_the_unlock": "Deslice para establecer nueva contraseña.",
    "Enter email address": "Ingrese su correo.",
    "Enter expected PnL": "Ingrese ganancias esperadas.",
    "Enter expected PnL Ratio": "Ingrese ratio de ganancias.",
    "Enter leverage from 1 up to 125": "Ingrese apalancamiento de 1 a 125.",
    "Enter phone number": "Ingrese su teléfono.",
    "Enter the verification code": "Ingrese el código.",
    "Equity": "Equidad.",
    "Est liquidation price": "Precio de liquidación estimado.",
    "Face value": "Valor nominal.",
    "Filed Value": "Valor realizado.",
    "Fill or Kill": "Todo o nada.",
    "Filled amt": "Cantidad realizada.",
    "Filled time": "Hora de transacción.",
    "Flash_strong": "Fulgor intenso.",
    "Funding rate": "Tasa de financiamiento.",
    "Funding rate - 1": "Tasa de financiamiento.",
    "Funding rate comparison": "Comparación de tasas.",
    "Funding rate time": "Tiempo de tasa.",
    "Fuzzy_information": "Información confusa.",
    "Gesture_error": "Contraseña incorrecta.",
    "Google_code": "Código de Google.",
    "Help_home": "Ayuda.",
    "Hide_no_asset_currency": "Ocultar monedas sin activos.",
    "Highest PnL ratio": "Mayor ratio de ganancias.",
    "Historical record": "Historia",
    "I agree to": "Acepto.",
    "I have read and agree to this agreement": "He leído y acepto.",
    "If_the_buyer_is_at": "Si el comprador está en.",
    "Immediately or Cancel": "Ejecutar y cancelar.",
    "In force": "En vigor.",
    "Index": "Índice de mercado.",
    "Index Price": "Precio del índice.",
    "Index_contrct": "Contrato de índice.",
    "Info": "Descripción del contrato.",
    "Initial margin": "Margen inicial.",
    "Instrument": "Instrumento actual.",
    "Insurance fund": "Fondo de seguro.",
    "Interest bearing record": "Registro de intereses.",
    "Invalid_qr_code": "Código QR inválido.",
    "Invite code(Optional)": "Código de invitación (opcional).",
    "InviteCodeMuster": "Código de invitación (requerido).",
    "Invite_home": "Invitar.",
    "Isolated": "Aislado.",
    "Lack_of_edge": "Falta de margen.",
    "Last Price": "Último precio.",
    "Latest Filled price": "Último precio realizado.",
    "Latest Filled price01": "Último precio.",
    "Latest price": "Último precio.",
    "Leverage": "Apalancamiento.",
    "Leverage after increase": "Apalancamiento tras aumento.",
    "Leverage11": "Apalancamiento actual.",
    "Leverage_BB": "Ajustar apalancamiento.",
    "Limit order is an order": "Una orden límite establece cantidad y precio.",
    "Limit price": "Precio límite.",
    "Limit/Market": "Límite/Mercado.",
    "LimitAndMarket": "Límite/Mercado.",
    "Liquidation price": "Precio de liquidación.",
    "Liquidation price after increase": "Precio de liquidación tras aumento.",
    "Long": "Posición larga.",
    "Lowest PnL ratio": "Menor ratio de ganancias.",
    "MAIL_COUNTRY_NOT_EXITS": "¡País no disponible!",
    "MAIL_NOT_MAIL_PROVIDER": "Sin proveedor de correo.",
    "MAIL_TEMPLATE_NOT_FOUND": "¡Plantilla de correo no encontrada!",
    "MAIL_TO_ADDRESS_NOT_FOUND": "¡Dirección de correo no encontrada!",
    "MJRefreshAutoFooterIdleText": "Haga clic para cargar más.",
    "MJRefreshAutoFooterNoMoreDataText": "Todo cargado.",
    "MJRefreshAutoFooterRefreshingText": "Cargando datos...",
    "MJRefreshBackFooterIdleText": "Deslice para cargar más.",
    "MJRefreshBackFooterNoMoreDataText": "Todo cargado.",
    "MJRefreshBackFooterPullingText": "Suelte para cargar más.",
    "MJRefreshBackFooterRefreshingText": "Cargando datos...",
    "MJRefreshHeaderDateTodayText": "Hoy.",
    "MJRefreshHeaderIdleText": "Deslice para refrescar.",
    "MJRefreshHeaderLastTimeText": "Última actualización:",
    "MJRefreshHeaderNoneLastDateText": "Sin registros.",
    "MJRefreshHeaderPullingText": "Suelte para refrescar.",
    "MJRefreshHeaderRefreshingText": "Refrescando datos...",
    "MKT Close ALL": "Cerrar todo a mercado.",
    "Margin add": "Añadir margen.",
    "Margin mode": "Modo de margen.",
    "Margin ratio": "Ratio de margen.",
    "Margin reduce": "Reducir margen.",
    "Margin transfer": "Transferir margen.",
    "Margin-1": "Saldo de margen.",
    "Margin-2": "Ajustar margen.",
    "Margined": "Contratado.",
    "Mark price": "Precio de marcado.",
    "Market": "Mercado.",
    "Market Order Dig": "Orden de mercado para transacciones rápidas.",
    "Market Overview": "Resumen del mercado.",
    "Market_transaction": "Precio de mercado",
    "Max": "Máximo cancelable.",
    "Max Open int": "Máxima apertura.",
    "Max buy": "Máximo a comprar.",
    "Max long": "Máximo largo.",
    "Max sell": "Máximo a vender.",
    "Max short": "Máximo corto.",
    "Max position size at current leverage": "Máximo a abrir con apalancamiento.",
    "Mention_money": "Retirar fondos.",
    "Mention_money_address": "Ingrese dirección correcta.",
    "Mention_money_tips_ios": "\n1.Ingrese dirección, verifique código.\n2. Añada dirección para próximos retiros.",
    "Messages_notification": "Confirme que su tarjeta tiene notificaciones.",
    "Mini Programs": "Mini programas.",
    "Minimum_handling_charge": "Cantidad mayor que tarifa.",
    "Minimum_withdrawal": "Retiro mínimo.",
    "MoreNot9": "Máximo 14 módulos.",
    "Most increase": "Máximo incremento.",
    "Most reduce": "Máximo reducción.",
    "Must be integer multiple of": "Debe ser múltiplo entero.",
    "Not Enabled": "No habilitado.",
    "Not_on": "No disponible.",
    "Not_to_mention_money": "No se puede retirar por 1 hora tras reajuste.",
    "OTC account": "Cuenta OTC.",
    "OTC assets": "Activos OTC.",
    "OTC交易须知": "Notas sobre el comercio OTC",
    "On Hold": "Congelado",
    "One-way": "Unidireccional",
    "One/Two-way": "Unidireccional/Bidireccional",
    "Open Time": "Hora de apertura",
    "Open amount": "Cantidad de apertura",
    "Open int.": "Interés abierto",
    "Open interest": "Interés abierto",
    "Open long": "Abrir largo",
    "Open orders": "Órdenes abiertas",
    "Open price": "Precio de apertura",
    "Open short": "Abrir corto",
    "Order Details": "Detalles de la orden",
    "Order Sorting": "Ordenar órdenes",
    "Order Value": "Valor de la orden",
    "Order amt": "Cantidad total de órdenes",
    "Order history": "Historial de órdenes",
    "Order price": "Precio de la orden",
    "Order trigger direction": "Dirección de activación",
    "Order type": "Tipo de orden",
    "PL": "Ganancia",
    "PL Ratio": "Tasa de ganancia",
    "Pair01": "Moneda del contrato",
    "Parse_failure": "Error al guardar",
    "Partially Filled": "Llenado parcial",
    "Partially closed": "Cierre parcial",
    "Password": "Contraseña",
    "Payment_type": "Tipo de pago",
    "Perpetual": "Contrato perpetuo",
    "Perpetual funds daybook": "Flujo de fondos perpetuos",
    "Perpetual01": "Perpetuo",
    "Pingclose": "Cerrar posición",
    "Please input a password": "Ingrese contraseña",
    "Position": "Posición",
    "Position balance": "Saldo de posición",
    "Position history": "Historial de posiciones",
    "QR_code": "Código QR",
    "Quantity cannot exceed": "Cantidad máxima 125",
    "Quantity not less than": "Cantidad mínima 1",
    "Recent close": "Cierre reciente",
    "Recording_requirements": "Requisitos de grabación",
    "Required position margin at current leverage": "Margen requerido:",
    "Reset_transaction_password": "Restablecer contraseña de transacción",
    "Revoked": "Revocado",
    "SL": "Stop loss",
    "SL order": "Orden de stop loss",
    "SL order price": "Precio de stop loss",
    "SL order price can't be empty": "Precio de stop loss no puede estar vacío",
    "SL trigger price": "Precio de activación stop loss",
    "SL trigger price can't be empty": "Precio de activación no puede estar vacío",
    "SMS_30_OUT_RESTRICTED": "Código enviado con frecuencia, solo 1 cada 30s.",
    "SMS_BAD_ARGUMENT_FORMAT": "Formato de teléfono incorrecto.",
    "SMS_COUNTRY_NOT_EXITS": "País no disponible, revise antes de enviar.",
    "SMS_DAY_LIMIT_PER_MOBILE": "Límite de envíos en 24h alcanzado.",
    "SMS_DUP_IN_SHORT_TIME": "Repetición en 30s no permitida.",
    "SMS_FAILURE": "Envío fallido",
    "SMS_HOUR_LIMIT_PER_MOBILE": "Límite de envíos en 1h alcanzado.",
    "SMS_MARKET_FORBIDDEN": "Envío de SMS restringido por operador.",
    "SMS_NOT_SMS_PROVIDER": "Sin proveedor de SMS, añada antes de enviar.",
    "SMS_NOT_SMS_PROVIDER_TEMPLATE": "Falta plantilla de proveedor, añada antes de enviar.",
    "SMS_PHONE_OVER_COUNT": "Número de envío de códigos excedido, intente en 24h.",
    "SMS_PHONE_PROHIBIT_SEND": "Este número no puede enviar SMS, intente en 24h.",
    "SMS_SUCCESS": "Envío exitoso",
    "SMS_TOO_MANY_TIME_IN_5": "Repetición excesiva en 5 minutos.",
    "SMS_UNKNOWN_EXCEPTION": "Excepción desconocida en el sistema.",
    "SMS_code": "Código SMS",
    "Save picture": "Guardar imagen",
    "Scanning_to_obtain": "Escanear para obtener dirección",
    "See auto deleverage liquidation for details": "Ver liquidación automática",
    "Set_transaction_password": "Establecer contraseña de transacción",
    "Settlement1": "Próxima liquidación",
    "Settlrment coin": "Moneda de liquidación",
    "Share time": "Tiempo de compartir",
    "Shielding success": "Bloqueo exitoso",
    "Short": "Vender corto",
    "Sign Up": "Registrarse",
    "Sign in": "Iniciar sesión",
    "Simplified_Chinese": "Chino simplificado",
    "StatusOrder": "Estado de la orden",
    "Stop loss": "Establecer stop loss",
    "Stop profit stop loss": "Seleccione al menos uno para ordenar",
    "Sustainable account": "Cuenta perpetua",
    "Sustainable assets": "Activos perpetuos",
    "Switch to PnL": "Cambiar a ganancias",
    "Switch to PnL ratio": "Cambiar a tasa de ganancias",
    "TP": "Take profit",
    "TP order": "Orden de take profit",
    "TP order price": "Precio de take profit",
    "TP order price can't be empty": "Precio de take profit no puede estar vacío",
    "TP trigger price": "Precio de activación take profit",
    "TP trigger price can't be empty": "Precio de activación no puede estar vacío",
    "TP | SL": "Take profit y stop loss",
    "TP | SL order": "Orden de take profit y stop loss",
    "Take profit": "Establecer take profit",
    "Telegraphic_transfer_address": "Dirección de transferencia",
    "The Calculator": "Calculadora",
    "The maximum flatable quantity is": "Cantidad máxima de cierre:",
    "The maximum leverage is": "Máximo apalancamiento:",
    "The minimum flatable quantity is": "Cantidad mínima de cierre:",
    "The minimum leverage is": "Mínimo apalancamiento:",
    "The poster has been saved to the album": "Póster guardado en álbum",
    "The_correct_sample": "Ejemplo correcto",
    "The_input_signal": "Deslice para ingresar contraseña",
    "The_new_address": "Nueva dirección",
    "They_are_the_number_of": "Número de órdenes",
    "Tick size": "Tamaño mínimo de tick",
    "To_update": "Actualizar",
    "Toggle_front_camera": "Cambie a cámara frontal y centre su rostro",
    "Total": "Cantidad total",
    "Total coin": "Cantidad total de monedas",
    "Total cont": "Total de contratos",
    "Total value": "Valor total",
    "Total_asset_valuation": "Valor total de activos",
    "Trade type": "Tipo de operación",
    "Trade_sector": "Seleccione",
    "Transfer": "Transferir",
    "Transfer_to_remind": "Anote en la transferencia (responsabilidad propia)",
    "Trigger mark price": "Precio de activación marcado",
    "Trigger price": "Precio de activación",
    "Two-way": "Bidireccional",
    "UPL": "Pérdidas y ganancias no realizadas",
    "US_dollar": "Dólar estadounidense",
    "Unblocked": "Desbloqueado",
    "Uneasy lies the head that wears a crown": "Reina sin paz",
    "Unfilled": "Sin cerrar",
    "Unfilled11": "Esperando llenado",
    "Upload_collection_code": "Suba código de pago",
    "Usdt-Margined": "Contrato USDT",
    "Users who register": "Usuarios nuevos deben establecer contraseña",
    "Verification Code": "Código de verificación",
    "Videoing_requirement": "Requisitos de video",
    "View": "Ver",
    "View more": "Detalles de la posición",
    "Waiting effect": "Esperando activación",
    "Waiting_for_the_payment": "Esperando pago",
    "Wechat": "WeChat",
    "WestUnion": "Western Union",
    "Without_her": "Sin aparecer",
    "abandoned": "Abandonado",
    "abnormal_assets": "Activos anormales, operación no permitida.",
    "about_us": "Sobre nosotros",
    "account": "Cuenta",
    "account_empty": "Cuenta incorrecta",
    "account_failure_0001": "Error al añadir ID de transacción en REDIS.",
    "account_failure_0002": "Fondos insuficientes, no se puede procesar.",
    "account_failure_0003": "Error al registrar aumento de congelación de usuario.",
    "account_failure_0004": "Error al aumentar congelación de usuario.",
    "account_failure_0005": "Error al deducir fondos disponibles del comerciante.",
    "account_failure_0006": "Error al deducir fondos del comerciante.",
    "account_failure_0007": "Error al registrar aumento de fondos disponibles.",
    "account_failure_0008": "Error al aumentar fondos disponibles.",
    "account_failure_0009": "Error al añadir nueva moneda para el usuario.",
    "account_failure_0010": "Error al añadir nueva moneda para el comerciante.",
    "account_failure_00100": "ID del comerciante no puede estar vacío.",
    "account_failure_00101": "ID del usuario no puede estar vacío.",
    "account_failure_00102": "ID de moneda no puede estar vacío.",
    "account_failure_00103": "Cantidad no puede estar vacía.",
    "account_failure_00104": "Cantidad no puede ser menor que el mínimo.",
    "account_failure_00105": "Contraseña no puede estar vacía.",
    "account_failure_00106": "Contraseña incorrecta.",
    "account_failure_00107": "Precio no puede estar vacío.",
    "account_failure_00108": "Precio unitario no puede ser menor que el mínimo.",
    "account_failure_00109": "Usuario no existe.",
    "account_failure_0011": "Moneda ya existe en la billetera del comerciante.",
    "account_failure_00110": "Error al iniciar PUSH de activos.",
    "account_failure_00111": "PUSH ID no puede estar vacío.",
    "account_failure_00112": "Error al pagar activos por PUSH.",
    "account_failure_00113": "Error al cancelar activos por PUSH.",
    "account_failure_00114": "No se puede obtener dirección de carga.",
    "account_failure_00115": "Dirección de retiro no puede estar vacía.",
    "account_failure_00116": "Error al añadir dirección de retiro.",
    "account_failure_00117": "ID no puede estar vacío.",
    "account_failure_00118": "Error al eliminar dirección de retiro.",
    "account_failure_00119": "Error de red, intente más tarde.",
    "account_failure_0012": "Congelación insuficiente, no se puede procesar.",
    "account_failure_00120": "Dirección de retiro ya existe.",
    "account_failure_00121": "No hay monedas disponibles para PUSH.",
    "account_failure_00122": "Código de verificación móvil vacío.",
    "account_failure_00123": "Código de verificación de correo vacío.",
    "account_failure_00124": "Código de verificación de Google vacío.",
    "account_failure_00125": "Código de verificación incorrecto.",
    "account_failure_00126": "No se permite auto-PUSH.",
    "account_failure_00127": "Dirección de retiro no válida.",
    "account_failure_00129": "No se permite cambiar contraseña en 24h.",
    "account_failure_0013": "Error al registrar deducción de congelación del comerciante.",
    "account_failure_00130": "Activos desbalanceados, no se puede operar.",
    "account_failure_00131": "Activos anormales, estado de transacción deshabilitado.",
    "account_failure_00132": "Etiqueta no coincide.",
    "account_failure_00133": "Tipo de moneda no existe o está deshabilitado.",
    "account_failure_0014": "Error al deducir congelación del comerciante.",
    "account_failure_0015": "Error al registrar aumento de interés disponible.",
    "account_failure_0016": "Error al aumentar interés disponible.",
    "account_failure_0017": "Error al registrar deducción de interés del comerciante.",
    "account_failure_0018": "Error al deducir interés del comerciante.",
    "account_failure_0019": "Error al congelar fondos disponibles del usuario.",
    "account_failure_0020": "Error al congelar fondos disponibles, registro fallido.",
    "account_failure_0021": "Error al registrar deducción de congelación del usuario.",
    "account_failure_0022": "Error al deducir congelación del usuario.",
    "account_failure_0023": "Error al registrar aumento de fondos disponibles del comerciante.",
    "account_failure_0024": "Error al aumentar fondos disponibles del comerciante.",
    "account_failure_0025": "Error al registrar deducción de congelación del usuario.",
    "account_failure_0026": "Error al deducir congelación del usuario.",
    "account_failure_0027": "Fallo en el registro de deducción de usuario.",
    "account_failure_0028": "Fallo en la deducción de usuario.",
    "account_failure_0029": "Fallo en el registro de recuperación de usuario.",
    "account_failure_0030": "Fallo en la recuperación de usuario.",
    "account_failure_0031": "¡Sin fondos suficientes!",
    "account_failure_0032": "Fallo en el registro de recuperación de comerciante.",
    "account_failure_0033": "Fallo en la recuperación de activos del comerciante.",
    "account_failure_0034": "Fallo en el registro de congelación del comerciante.",
    "account_failure_0035": "Fallo en la congelación del comerciante.",
    "account_failure_0036": "Fallo en el registro de congelación de comerciante.",
    "account_failure_0037": "Fallo en la congelación de comerciante.",
    "account_failure_0038": "Fallo en el registro de congelación a disponible.",
    "account_failure_0039": "Fallo en la congelación a disponible.",
    "account_failure_0040": "Fallo en el registro de aumento de disponible.",
    "account_failure_0041": "Fallo en el aumento de disponible.",
    "account_failure_0042": "¡Error en la ejecución de la transacción!",
    "account_failure_0043": "¡Transacción ya fallida!",
    "account_failure_0044": "¡Transacción exitosa!",
    "account_failure_0045": "¡Transacción en curso!",
    "account_failure_0046": "¡Procesamiento exitoso!",
    "account_failure_0047": "Fallo en la recuperación de activos de la plataforma.",
    "account_failure_0048": "Fallo en el registro de recuperación de activos.",
    "account_failure_0049": "¡Moneda ya existe!",
    "account_failure_0050": "Fallo en la adición de nueva moneda.",
    "account_failure_0051": "¡Moneda ya existe!",
    "account_failure_0053": "¡Tipo de transacción incorrecto!",
    "account_failure_0054": "Fallo en el aumento de disponible de comerciante.",
    "account_failure_0055": "Fallo en el registro de aumento de disponible.",
    "account_failure_0056": "Fallo en la transferencia de ingresos de la plataforma.",
    "account_failure_0057": "Fallo en el registro de transferencia de ingresos.",
    "account_failure_0058": "Fallo en la transferencia a inversión.",
    "account_failure_0059": "Fallo en el registro de transferencia a inversión.",
    "account_failure_0060": "¡Inversión insuficiente!",
    "account_failure_0061": "Fallo en la conversión de inversión a disponible.",
    "account_failure_0062": "Fallo en el registro de conversión a disponible.",
    "account_failure_0063": "Fallo en la operación, sin datos disponibles.",
    "account_failure_0064": "Moneda temporalmente no disponible.",
    "account_failure_0065": "Sin permiso para ver, contactar soporte.",
    "account_failure_0071": "¡Error en el bloqueo de usuario!",
    "account_failure_0072": "Registro de carga no existe o fue modificado.",
    "activitie_end": "Actividad finalizada.",
    "activity_invalid": "Expirado.",
    "actual_amount": "Cantidad total entregada.",
    "actual_exchange_amount": "Cantidad intercambiada.",
    "actual_to_account": "Total acreditado.",
    "addSuccess": "Añadido con éxito.",
    "add_channel_function": "Nueva función de canal de verificación.",
    "add_charge_and_transfer_function": "Nueva función de carga y transferencia.",
    "added_alert_setting_function": "Nueva función de configuración de alertas.",
    "added_day_mode_toggle": "Nueva función de cambio a modo diurno.",
    "address_tag": "Etiqueta.",
    "advanced_limit_order": "Orden límite avanzada.",
    "advertising": "Publicidad.",
    "advertising_management": "Gestión de publicidad.",
    "affirm": "Confirmar.",
    "affirm_pass": "Confirmar contraseña.",
    "again_digits6": "Reingrese la nueva contraseña.",
    "again_password": "Reingrese la contraseña de inicio de sesión.",
    "airdrop_coin": "Moneda de airdrop.",
    "airdrop_date": "Fecha de airdrop.",
    "airdrop_number": "Cantidad de airdrop.",
    "alipay_account": "Cuenta de Alipay.",
    "alipay_upload": "*Método para subir código QR: abrir Alipay > cobrar > guardar imagen.",
    "all": "Todo.",
    "all_buy": "Todo comprado.",
    "all_country": "Todos los países.",
    "all_order": "Todos los pedidos.",
    "all_sell": "Todo vendido.",
    "already_use": "Activado.",
    "amount_of_payout": "Monto de salida.",
    "announcement_all": "Todos los anuncios.",
    "announcement_center": "Centro de anuncios.",
    "announcement_official": "Anuncios oficiales.",
    "annualized_rate": "Tasa anualizada.",
    "appeal_cancel": "Apelación cancelada.",
    "appeal_complete": "Apelación completada.",
    "appeal_dispose_time": "Tiempo de resolución de apelación.",
    "appeal_of_time": "Hora de envío de apelación.",
    "apply_locked": "Solicitar bloqueo.",
    "asset_allocatio": "Distribución de activos.",
    "assets_asset_valuation": "Valoración de activos.",
    "assets_can_transfer": "Transferible.",
    "assets_cash_flow": "TRE",
    "assets_coin_funds_flow": "Flujo de fondos de criptomonedas.",
    "assets_currency_assets": "Activos de criptomonedas.",
    "assets_deposit_income_tip": "El valor se calcula al tipo de cambio actual.",
    "assets_fiat_assets": "Activos OTC.",
    "assets_fiat_money_flow": "Flujo de fondos OTC.",
    "assets_flowing_details": "Detalles de flujo.",
    "assets_prompt_001": "[Backup claves para evitar pérdida.]",
    "assets_reduced_asset": "Activos reducidos.",
    "assets_row_mining": "Minería de órdenes.",
    "assets_swipe_direction": "Dirección de transferencia.",
    "assets_transfer": "Transferir.",
    "assets_transfer_coin": "Transferir moneda.",
    "assets_transfer_count": "Cantidad a transferir.",
    "assets_transfer_currency": "Transferencia de fondos.",
    "assets_transfer_dialog_bb": "Cuenta de criptomonedas.",
    "assets_transfer_dialog_fiat": "Cuenta fiat.",
    "assets_transfer_dialog_from": "De.",
    "assets_transfer_dialog_transfer_to": "Transferir a.",
    "assets_transfer_dialog_wallet": "Cuenta de billetera.",
    "assets_transfer_record": "Registro de transferencia.",
    "assets_transfer_to": "A.",
    "assets_wallet": "Billetera.",
    "assets_wallet_assets": "Activos de billetera.",
    "assets_wallet_assets_transfer_tip": "*Solo se puede transferir a cuentas correspondientes.",
    "assets_wallet_funds_flow": "Flujo de fondos de billetera.",
    "at_least_one": "Activar al menos un método de verificación.",
    "attention_blacklist": "Seguir / Bloquear.",
    "attention_to_see": "¡Quiere seguirme, haga clic aquí!",
    "audit_result": "Resultado de auditoría.",
    "authenticated": "Ya autenticado.",
    "authentication": "Autenticación.",
    "available_balance": "Saldo disponible.",
    "average_purchase_price": "Precio promedio de compra.",
    "avoid_close_set": "Configuración sin contraseña.",
    "back": "Regresar.",
    "balance": "Saldo.",
    "bank_card": "Tarjeta bancaria.",
    "bank_name": "Nombre del banco.",
    "bank_num": "Número de tarjeta.",
    "barcode_in_the_box_scan": "Coloque el código QR en el marco para escanear.",
    "bb_assets": "Activos de criptomonedas.",
    "bb_history_finished": "Completado.",
    "bb_time": "Hora.",
    "bb_used": "Activado.",
    "be_usable": "Utilizable.",
    "bear_consequences_your_investment": "Reconozco y asumo los riesgos de inversión.",
    "become": "Convertirse.",
    "been_authenticated": "Ya autenticado.",
    "beginner_guide_title": "Plataforma líder en activos digitales.",
    "belong_level": "Nivel correspondiente.",
    "between_and": "Entre.",
    "bill": "Factura.",
    "billing_details": "Detalles de factura.",
    "bind_email": "Vincular correo.",
    "bind_google": "Vincular Google.",
    "bind_google_authenticator": "Vincular autenticador de Google.",
    "bind_phone": "Vincular teléfono.",
    "branch_name": "Nombre de sucursal.",
    "bug_order": "Oferta",
    "buy": "Comprar.",
    "buy_1": "Comprar.",
    "buy_now": "Comprar ahora.",
    "buy_quantity": "Cantidad a comprar.",
    "buy_rate": "Tasa de compra.",
    "buyer": "Comprador.",
    "buyer_has_paid_attention": "Comprador ha pagado.",
    "buyer_payment": "Pago del comprador.",
    "buyer_phone": "Teléfono del comprador.",
    "buyer_uid": "Comprador.",
    "calculate": "Calcular.",
    "calculations are for your reference only": "Cálculos solo de referencia.",
    "can_available": "Disponible.",
    "canceled": "Cancelado.",
    "cancellations": "Cancelaciones.",
    "centre": "Centro.",
    "change1": "Cambiar.",
    "change_gesture_password": "Cambiar contraseña.",
    "change_google": "Cambiar Google.",
    "change_pay_style": "Cambiar método de pago.",
    "change_phone": "Cambiar teléfono.",
    "charge_after_the_transaction": "Comisión tras transacción.",
    "charge_out": "Comisión debe estar en.",
    "check_the_agree": "Aceptar términos.",
    "china": "China.",
    "choose_picture": "Elegir imagen.",
    "clearing_time": "Hora de liquidación.",
    "click_enter_batch_edit_page": "Haga clic para editar en lote.",
    "click_on_the_copy": "Haga clic para copiar.",
    "clinch_a_deal": "El contrario debe haber negociado varias veces.",
    "close": "Cerrar.",
    "closed_successfully": "Cerrado con éxito.",
    "cloud_mining_pool": "Minería en la nube.",
    "coin_failure_0001": "Moneda no existe.",
    "collect": "Seleccionar.",
    "collection_time": "Hora de recolección.",
    "commission": "Comisión obtenida.",
    "common_failure": "¡Fallo!",
    "common_failure_0001": "Error de red.",
    "common_problem": "Instrucciones de operación.",
    "common_success": "Éxito.",
    "complete": "Completado.",
    "completed": "Transacción completada.",
    "computer_login": "Inicio de sesión en computadora.",
    "confirm": "Confirmar.",
    "confirm_exit": "¿Confirmar salida?",
    "confirm_password": "Confirme la contraseña.",
    "confirm_submission": "Confirmar envío.",
    "confirm_the_purchase": "Confirmar compra.",
    "confirm_to_give_up": "Confirmar abandono.",
    "confirm_to_sell": "Confirmar venta.",
    "confirm_upgrade": "Confirmar actualización.",
    "confirm_vote": "Confirmar voto.",
    "confirmation_information": "Información de confirmación.",
    "confrim_delete_warn": "¿Eliminar alerta?",
    "contact_service": "Contactar soporte.",
    "contact_us": "Contáctenos.",
    "contract_Order": "Encargar.",
    "contrct Agree Content": "Para proteger sus derechos, lea el 'Acuerdo de Contrato BitMatrix' antes de abrir.",
    "contrct Agree Content footer": "'Acuerdo de Contrato BitMatrix'",
    "contrct Agree Content header": "Para proteger sus derechos, lea antes de abrir.",
    "contrct_decimal": "Decimal",
    "convert_into": "Convertir",
    "converted_currency": "Moneda convertida",
    "copartner-upgrade-failure": "Fallo en la actualización",
    "copartner-upgrade-no": "No cumple requisitos para actualizar",
    "copartner-upgrade-ok": "Puede actualizar",
    "copartner-upgrade-success": "Actualización exitosa",
    "copy_content": "Copiado al portapapeles",
    "country": "País",
    "create_time": "Hora de creación",
    "cumulative_income:": "Ingreso acumulado:",
    "cumulative_participation": "Participación acumulada",
    "currency": "Moneda",
    "currency_fee": "Tarifa de moneda",
    "currency_information": "Detalles de moneda",
    "currency_name": "Nombre de la moneda",
    "currency_not_found": "Moneda no encontrada",
    "currency_select": "Seleccionar moneda",
    "current_day_return": "Retorno diario",
    "current_entrust": "Orden actual",
    "current_identity": "Identidad actual",
    "current_polling_date": "Fecha de votación actual",
    "current_status": "Estado actual",
    "currently_available": "Actualmente disponible",
    "date": "Fecha",
    "date_of_birth": "Fecha de nacimiento",
    "day_highs_and_lows": "Altos y bajos del día",
    "day_highs_and_lows1": "Altos y bajos del día",
    "day_income": "Día",
    "day_trading": "Trading diario",
    "daytime": "Día",
    "ddress_management": "Gestión de direcciones",
    "deal_amount": "Monto total",
    "deal_done": "Transacción completada",
    "deal_gross": "Transacción/Total",
    "deal_of_the_week": "Transacciones de la semana",
    "deal_price": "Precio promedio",
    "deal_sum": "Monto de la transacción",
    "deal_the_detail": "Detalles de la orden",
    "deal_volume": "Monto de transacción",
    "deduct_coin": "Moneda deducida",
    "default_settlement": "Liquidación por defecto",
    "defaults": "Por defecto",
    "deposit": "Depositar",
    "deposit_details": "Detalles del depósito",
    "deposit_income": "Ingreso por depósito",
    "deposit_lock": "Bloqueo de depósito",
    "deposit_lock_details": "Detalles de bloqueo de depósito",
    "deposit_returned_principal": "Capital devuelto",
    "depth": "Profundidad",
    "detailed_rules": "Reglas detalladas",
    "details": "Detalles",
    "digits6": "Ingrese 6 dígitos",
    "direct_market": "Promoción directa",
    "direct_reward": "Recompensa directa",
    "direction": "Dirección",
    "discount_Rate": "Tasa de descuento",
    "discount_currency_introduction": "Introducción a la moneda",
    "discount_dialog_tip1": "¡Felicidades! Cumple con los requisitos.",
    "discount_dialog_tip2": "Ha sido seleccionado como usuario afortunado.",
    "discount_dialog_tip3": "Ya posee",
    "discount_dialog_tip4": "calificación para canjear.",
    "discount_dialog_tip5": "No puede participar tras renunciar.",
    "discount_dialog_tip6": "No puede solicitar participación.",
    "discount_dialog_tip7": "Nota: Se deducirá",
    "discount_purchase": "Canje de descuento",
    "discounted_or_original_price": "Precio con descuento/Original",
    "dispose_result": "Resultado de procesamiento",
    "disqualification": "Renuncia a la calificación",
    "divided_earnings": "Ganancias compartidas",
    "drag": "Mantenga presionado y deslice a la derecha",
    "each_input": "Verifique contraseña en cada transacción",
    "edt_selfchoose": "Editar selección",
    "effective": "Válido",
    "eligibility": "Calificación para recibir",
    "email": "Registro de correo",
    "email_address_format": "Ingrese un correo válido",
    "email_code": "Código de verificación",
    "empty_deal_pass": "La contraseña no puede estar vacía",
    "end time": "Hora de finalización",
    "end_date": "Fecha de finalización",
    "end_time": "Hora de finalización",
    "enter_account": "Ingrese teléfono/correo",
    "enter_cause_of_complaint": "Ingrese motivo de la queja",
    "enter_fb_buy_price": "Ingrese precio de compra",
    "enter_fb_sell_price": "Ingrese precio de venta",
    "enter_your_password": "Ingrese su contraseña",
    "enter_password": "Ingrese la contraseña",
    "entrust_amount": "Cantidad de encargo",
    "entrust_failure_0001": "Error de red",
    "entrust_failure_0002": "Tipo de consulta incorrecto",
    "entrust_failure_0003": "Tipo de emparejamiento vacío",
    "entrust_failure_0004": "ID de comerciante vacío",
    "entrust_failure_0005": "ID de usuario vacío",
    "entrust_failure_0006": "Usuario no encontrado",
    "entrust_failure_0007": "ID de par vacío",
    "entrust_failure_0009": "Par no encontrado",
    "entrust_failure_0010": "Contraseña de transacción vacía",
    "entrust_failure_0011": "Contraseña incorrecta",
    "entrust_failure_0012": "Tipo de orden vacío",
    "entrust_failure_0013": "Cantidad de orden vacía",
    "entrust_failure_0014": "Precio de orden vacío",
    "entrust_failure_0015": "ID de orden vacío",
    "entrust_failure_0016": "Orden no encontrada",
    "entrust_failure_0017": "Orden ya eliminada",
    "entrust_failure_0018": "Orden no puede ser cancelada",
    "entrust_failure_0019": "Número de versión vacío",
    "entrust_failure_0020": "Orden de mercado no cancelable",
    "entrust_failure_0021": "Datos actualizados",
    "entrust_failure_0022": "No hay órdenes, no se puede hacer una de mercado",
    "entrust_failure_0023": "Par deshabilitado o moneda deshabilitada",
    "entrust_failure_0024": "Par no abierto para trading",
    "entrust_failure_0025": "Precio fuera de rango",
    "entrust_failure_0026": "Cantidad fuera de rango",
    "entrust_failure_0027": "Cuenta prohibida para trading, contacte soporte",
    "entrust_failure_0030": "Sobrepasa el límite diario de {}",
    "entrust_failure_0031": "Sobrepasa la caída diaria de {}",
    "entrust_msg_match_type_001": "Orden límite",
    "entrust_msg_match_type_002": "Orden de mercado",
    "entrust_msg_status_001": "No ejecutada",
    "entrust_msg_status_002": "Parcialmente ejecutada",
    "entrust_msg_status_003": "Totalmente ejecutada",
    "entrust_msg_status_004": "Cancelada",
    "entrust_msg_type_001": "Orden de venta",
    "entrust_msg_type_002": "Orden de compra",
    "entrusted_price": "Precio de encargo",
    "entry_orders": "Órdenes pendientes",
    "equal_principal": "Capital igual",
    "error_email": "Correo incorrecto",
    "error_network_anomaly": "Error de red",
    "error_phone": "Ingrese un teléfono válido",
    "error_unknow": "Error desconocido",
    "event_detail": "Detalles del evento",
    "event_detail_warn_tip": "*Advertencia: Los activos digitales son innovadores y tienen alta volatilidad. Invierta con cuidado.",
    "event_start_time": "Hora de inicio del evento",
    "every day": "Cada día",
    "everyday": "Diario",
    "exchange": "Intercambio",
    "exchange_currency": "Moneda para intercambiar",
    "exchange_details": "Detalles del intercambio",
    "exchange_history": "Historial de intercambios",
    "exchange_quantity": "Cantidad a intercambiar",
    "exchange_rules": "Reglas de intercambio",
    "exchangeable": "Intercambiable",
    "exclusive_posters": "Generar póster exclusivo",
    "exclusive_posters-Video": "Generar póster",
    "exit": "Salir",
    "expected_return": "Retorno esperado",
    "expired": "Expirado",
    "express_area": "Área rápida",
    "face_recognition": "Reconocimiento facial",
    "failure": "Fallo",
    "fb_lookup": "Haga clic para ver",
    "fb_order_number": "Número de pedido",
    "feeze_thaw_msg_status_0001": "Congelado",
    "feeze_thaw_msg_status_0002": "Descongelado",
    "fill_in_at_least_one": "Complete al menos uno",
    "fill_wrong": "Errores pueden causar pérdida de activos, revise cuidadosamente",
    "filtrate": "Filtrar",
    "filtrate_content": "Filtrar métodos de pago, país, moneda aquí!",
    "financial_management_is_cancel": "Ingreso de depósito cancelado! No envíe de nuevo!",
    "financial_management_is_delete": "Este tipo de inversión ha sido eliminado",
    "financial_management_is_disable": "Este tipo de inversión ha sido deshabilitado",
    "financial_management_not_cancel": "Inversión no puede ser cancelada!",
    "financial_management_number_not_found": "Cantidad de inversión no puede estar vacía",
    "financial_management_record_add_failure": "Fondos insuficientes, deposite antes de invertir!",
    "financial_management_record_less_than_min_number": "Cantidad inferior al mínimo establecido {}",
    "financial_management_type_not_found": "Error al mostrar información de inversión, tipo incorrecto!",
    "financial_management_type_not_null": "Tipo de inversión no puede estar vacío",
    "find_password": "Recuperar contraseña",
    "fingerprint": "Huella digital",
    "fingerprint_verification": "Verificación de huella",
    "first_bind_phone": "Primero vincule su número",
    "first_period": "Primera fase",
    "following_account": "A la siguiente cuenta",
    "free": "Sin comisiones",
    "Takerfree": "Comisión Taker",
    "Makerfree": "Comisión Maker",
    "freeze": "Congelar",
    "freeze_to_be_processed": "Congelación en espera de procesamiento",
    "fuc_use_discount": "Utilizar {} para pagar la comisión*descuento",
    "futu": "Futuros",
    "gathering": "Recaudación",
    "gears": "Niveles",
    "generation1": "Primera generación",
    "gesture_error_limit": "Límite de errores en contraseña de gestos alcanzado",
    "gesture_password": "Contraseña de gestos",
    "gesture_unlock": "Desbloquear con gesto",
    "get_code": "Obtener código",
    "goSign": "Registrarse",
    "go_change": "Modificar",
    "go_login": "Primero inicie sesión",
    "go_pay": "Ir a pagar",
    "go_setting": "Ir a ajustes",
    "go_to_pick_up": "Ir a recoger",
    "go_to_receiving": "Ir a recibir",
    "go_to_redeem": "Ir a canjear",
    "google_verification_code": "Código de verificación de Google",
    "grievance_freeze": "Congelación de quejas",
    "has_been_on": "Listada",
    "have_been_frozen": "Congelada",
    "have_received": "Recibido",
    "help_center": "Centro de ayuda",
    "hide_canceled": "Ocultar cancelados",
    "hide_other": "Mostrar todas las órdenes",
    "high_frequency_partner": "Socio de alta frecuencia",
    "highest_single": "Máxima única",
    "highfrequency_fee_sharing": "Compartir tarifas de alta frecuencia",
    "hint": "Consejo",
    "hint_password": "Ingrese contraseña",
    "hint_phone_email": "Ingrese teléfono/correo",
    "historical_transaction": "Transacción histórica",
    "history": "Historia",
    "home_page": "Página principal",
    "horizontal_version": "Versión horizontal",
    "hours": "h",
    "i18n_failure_0001": "Fallo en consulta de idioma",
    "i18n_failure_0002": "Fallo en consulta de país",
    "iTo_draw_again": "Ingrese la contraseña nuevamente",
    "i_see": "Entendido",
    "i_see1": "Comprendido",
    "id_card_back_direction_failure": "Dirección de imagen del reverso incorrecta",
    "id_card_front_direction_failure": "Dirección de imagen del frente incorrecta",
    "id_cord": "Número de documento",
    "id_type": "Tipo de documento",
    "identification": "Fallo de identificación",
    "identity_authentication": "Autenticación de identidad",
    "identity_card": "Documento de identidad",
    "if_at": "Si está en",
    "ignore": "Ignorar",
    "immediately_invited": "Invitar ahora",
    "immediately_join": "Unirse ahora",
    "in_trading": "En trading",
    "income": "Ingreso",
    "income_currency_type": "Tipo de moneda",
    "income_deposit_principal": "Capital de depósito",
    "incomeing": "Generando ingresos",
    "increment quantity": "Cantidad ingresada excede el máximo permitido",
    "indirect_promotion": "Promoción indirecta",
    "indirect_reward": "Recompensa indirecta",
    "individual_selling_tips": "No puede vender sus propias órdenes",
    "input_Branch_address": "Ingrese dirección de sucursal",
    "input_address_labels": "Ingrese etiquetas",
    "input_alipay_account": "Ingrese cuenta de Alipay",
    "input_bank_card_number": "Ingrese número de tarjeta bancaria",
    "input_bank_name": "Ingrese nombre del banco",
    "input_buyer_uid": "Ingrese UID del comprador",
    "input_currency_referred": "Ingrese abreviatura de moneda",
    "input_deposit_amount": "Ingrese cantidad a depositar",
    "input_email": "Ingrese correo electrónico",
    "input_email_code": "Ingrese código de verificación de correo",
    "input_id_cord": "Ingrese número de documento",
    "input_lock_amount": "Ingrese cantidad a bloquear",
    "input_mention_money_address": "Ingrese dirección de retiro",
    "input_money": "Ingrese monto",
    "input_name": "Ingrese nombre",
    "input_newphone_code": "Ingrese nuevo número de teléfono",
    "input_nike_name": "Ingrese apodo",
    "input_old_google_code": "Ingrese código de Google antiguo",
    "input_old_pass": "Ingrese contraseña antigua",
    "input_paypal_acctount": "Ingrese cuenta de PayPal",
    "input_phone": "Ingrese número de teléfono",
    "input_phone_code": "Ingrese código de verificación",
    "input_price": "Ingrese precio",
    "input_quantity": "Ingrese cantidad",
    "input_remark": "Ingrese observaciones",
    "input_service_charge": "Ingrese cargo por servicio",
    "input_trading_volume": "Ingrese monto",
    "input_transaction_password": "Ingrese contraseña de transacción",
    "input_wechat_account": "Ingrese cuenta de WeChat",
    "input_wire_transfer": "Ingrese dirección de transferencia",
    "inputs_numbers_greater_can_number": "Cantidad ingresada mayor que la transferencia permitida",
    "insufficient_light": "Iluminación insuficiente",
    "interest": "Interés",
    "invitation_details": "Detalles de invitación",
    "invitation_register_ad_not_found": "Anuncio de registro no encontrado",
    "invitation_register_ad_tatle_not_found": "Título de anuncio no encontrado",
    "invitation_register_coin_not_found": "Moneda de registro no encontrada",
    "invitation_register_logo_not_found": "Logo de registro no encontrado",
    "invitation_register_not_found": "Enlace de registro no encontrado",
    "invite_code": "Código de invitación (opcional)",
    "invite_code1": "Código de invitación",
    "invite_commission": "Comisión",
    "invite_gift": "Invitación con regalo",
    "invite_reward": "Recompensa de invitación",
    "invited_UID": "UID invitado",
    "invitees_are_available": "El invitador puede recibir",
    "inviter-is-user": "El invitador no puede ser uno mismo",
    "inviter-not-exist": "El invitador no existe",
    "inviter_no_null": "El invitador no puede estar vacío",
    "ios_24小时排行榜": "Ranking de 24 horas",
    "ios_OTC交易须知": "Guía de OTC",
    "ios_PAYPAL": "PAYPAL",
    "ios_otc_order_prompt_001": "Si no recibe pago en minutos y no hace clic en 'Confirmar pago', la orden se cancelará automáticamente.",
    "ios_otc_order_prompt_002": "Si no paga al vendedor en minutos y no hace clic en 'Confirmar pago', la orden se cancelará automáticamente.",
    "ios_万": "Mil",
    "ios_上传身份证反面": "Subir reverso de ID",
    "ios_上传身份证正面": "Subir frente de ID",
    "ios_两次密碼不一致请再次输入密碼": "Las contraseñas no coinciden, ingrese nuevamente",
    "ios_个确认大约需要": "Confirmación requiere aproximadamente需要",
    "ios_中国大陆": "China continental",
    "ios_中级合伙人": "Socio intermedio",
    "ios_主版块": "Sección principal",
    "ios_之间": "Entre",
    "ios_买单": "Orden de compra",
    "ios_买方必须成交过几次(0等于不限制)": "El comprador debe haber completado X transacciones (0 es ilimitado)",
    "ios_二维码/条形码": "Código QR/Código de barras",
    "ios_付款": "Pagar",
    "ios_充币地址": "Dirección de depósito",
    "ios_先设置交易密码" : "Primero configure la contraseña de trading",
    "ios_先设置交易密碼": "Primero configure la contraseña de trading",
    "ios_关闭照明": "Apagar iluminación",
    "ios_分享失败": "Compartir falló",
    "ios_分享成功": "Compartido con éxito",
    "ios_初级合伙人": "Socio principiante",
    "ios_卖单": "Orden de venta",
    "ios_卖家已付款提示": "El vendedor ha pagado",
    "ios_卖方必须成交过几次(0等于不限制)": "El vendedor debe haber completado X transacciones (0 es ilimitado)",
    "ios_去解绑": "Desvincular",
    "ios_发布成功": "Publicado con éxito",
    "ios_发送成功": "Enviado con éxito",
    "ios_各种费率说明": "Descripción de tarifas",
    "ios_团队人数不足": "Número insuficiente de miembros en el equipo",
    "ios_复制QQ号": "Copiar número de QQ",
    "ios_复制地址": "Copiar dirección",
    "ios_如果你已完成线下付款，请点击下方已付款按钮。如果未完成付款，却点击已付款将会受到严厉惩罚，甚至永久冻结账户！": "Si ha completado el pago, haga clic en 'Pagado'. Si no, podría ser penalizado severamente.",
    "ios_客户端或在线钱包将您需要充值的": "El cliente o billetera en línea requerirá recarga de",
   "ios_客户端或在线钱包将您需要充币的": "El cliente o billetera en línea requerirá recarga de",
    "ios_客服": "Soporte al cliente",
    "ios_密码设置成功！": "¡Contraseña configurada con éxito!",
    "ios_将该地址设置为认证地址": "Establecer esta dirección como dirección de verificación",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充值，不影响到账。\n3、最小充值金额": "Tiempo en horas, por favor espere.\n2. Se puede recargar múltiples veces con la misma dirección.\n3. Monto mínimo de recarga",
    "ios_已付款": "Pagado",
    "ios_币币": "Spot",
    "ios_常见问题": "Preguntas frecuentes",
    "ios_开启照明": "Encender iluminación",
    "ios_意见反馈": "Comentarios",
    "ios_成交": "Transacción completada",
    "ios_我的": "Mi cuenta",
    "ios_手机号错误": "Número de teléfono incorrecto",
    "ios_提交成功，等待认证": "Enviado con éxito, esperando verificación",
    "ios_提币数量不能大于手续费": "La cantidad de retiro no puede exceder la tarifa",
    "ios_提币数量减去手续费后不能": "La cantidad de retiro menos la tarifa no puede ser",
    "ios_收款二维码": "Código QR de recepción",
    "ios_收藏成功": "Guardado con éxito",
    "ios_数目发送到该地址。发送完成后，系统会自动在此交易获得": "La cantidad se enviará a esta dirección. Después de completar el envío, el sistema obtendrá automáticamente esta transacción.",
    "ios_暂无网络": "Sin conexión",
    "ios_最多可锁": "Máximo bloqueable",
    "ios_最大限额": "Límite máximo",
    "ios_最小限额": "Límite mínimo",
    "ios_未解锁": "No desbloqueado",
    "ios_没有中国大陆身份证的用户请通过网站版高级认证": "Usuarios sin ID de China continental deben verificar en la versión web.",
    "ios_法币": "Moneda fiduciaria",
    "ios_注册表示同意": "Registro implica aceptación",
    "ios_注册表示同意用户协议": "Registro implica aceptación del acuerdo de usuario",
    "ios_登录过期，请重新登录": "Sesión expirada, inicie sesión nuevamente",
    "ios_直接推荐人uid": "UID del recomendador directo",
    "ios_直推人数不足": "Número insuficiente de recomendaciones directas",
    "ios_绑定成功": "Vinculación exitosa",
    "ios_请上传您的身份证": "Por favor, suba su ID",
    "ios_请上传身份证反面照": "Por favor, suba la foto del reverso del ID",
    "ios_请上传身份证正面照": "Por favor, suba la foto del frente del ID",
    "ios_请先绑定收款方式": "Primero vincule un método de pago",
    "ios_请先设置支付方式": "Primero configure un método de pago",
    "ios_请输入价格和数量": "Por favor, ingrese precio y cantidad",
    "ios_请输入新邮箱": "Por favor, ingrese un nuevo correo",
    "ios_请输入正确的账号格式": "Por favor, ingrese un formato de cuenta correcto",
    "ios_请输入身份证号": "Por favor, ingrese el número de ID",
    "ios_请输入限额": "Por favor, ingrese el límite",
    "ios_请通过": "Por favor, pase por",
    "ios_资产": "Activos",
    "ios_超时": "Tiempo excedido",
    "ios_身份证号": "Número de ID",
    "ios_退出成功": "Salida exitosa",
    "ios_邀请人可获得 * 的佣金": "El invitador puede recibir * de comisión",
    "ios_锁仓数量不能为空": "La cantidad de bloqueo no puede estar vacía",
    "ios_间接接推荐人uid": "UID del recomendador indirecto",
    "ios_隐藏其他挂单": "Ocultar otras órdenes",
    "ios_高级合伙人": "Socio avanzado",
    "is_bind": "Vinculado",
    "is_delete_select": "¿Eliminar selección?",
    "issued": "Emitido",
    "issued_to_lock": "Emitido para bloqueo",
    "k_close": "Cerrar",
    "k_max": "Máximo",
    "k_min": "Mínimo",
    "k_num": "Número",
    "k_open": "Abrir",
    "kline_target": "Objetivo del gráfico",
    "language": "Idioma",
    "lead_content": "Consulta de pedidos, publicación de anuncios, configuración de recepción, seguir/bloquear aquí.",
    "least_four": "Conectar al menos 4 puntos",
    "less-than-min": "Por debajo del límite mínimo de bloqueo",
    "lf there are pending": "Si hay órdenes limitadas opuestas, serán canceladas antes de cerrar.",
    "limit": "Configuración de límites",
    "limit_orderAA": "Orden limitada",
    "limit_set": "Límite (opcional)",
    "limited_price": "Precio límite",
    "liquidation": "Liquidación",
    "liquidation AMT": "Cantidad de liquidación",
    "liquidation details": "Detalles de liquidación",
    "loading": "Cargando",
    "lock-expire": "Bloqueo expirado",
    "lock_amount": "Cantidad bloqueada",
    "lock_duration": "Duración del bloqueo",
    "lock_person": "Persona que bloquea",
    "lock_record": "Registro de bloqueo",
    "lock_remaining": "Cantidad restante",
    "lock_reward": "Recompensa de bloqueo",
    "lock_time": "Tiempo de bloqueo",
    "locked": "Bloqueado",
    "locked_closed": "Cerrado",
    "locked_insufficient_balance": "Saldo insuficiente",
    "locked_settled": "Liquidado",
    "locked_unsettlement": "No liquidado",
    "locked_warehouse": "En bloqueo",
    "log_in": "Iniciar sesión",
    "login": "Iniciar sesión",
    "login_expired": "Sesión expirada, inicie sesión de nuevo.",
    "login_forget_pwd": "¿Olvidó su contraseña?",
    "login_name_txt": "Usuario",
    "login_pass": "Contraseña",
    "logion_welcome_txt": "Bienvenido",
    "mail": "Correo",
    "mailbox": "Verificación de correo",
    "mailbox_bind_success": "Vinculación exitosa",
    "mailbox_new": "Correo",
    "main_picture": "Imagen principal",
    "manage_reward_details": "Detalles de recompensas",
    "management_reward": "Gestión de recompensas",
    "management_share": "Gestión de participación",
    "mandarin": "Lea los números en mandarín",
    "mandarin_reading_content": "Lea el siguiente contenido en estándar",
    "manual_recharge_msg_status_0001": "Esperando recarga",
    "manual_recharge_msg_status_0002": "Aprobado",
    "manual_recharge_msg_status_0003": "Recarga exitosa",
    "manual_recharge_msg_type_0001": "Recarga normal",
    "manual_recharge_msg_type_0002": "Procesamiento de fondos",
    "manual_recharge_msg_type_0003": "Regalo",
    "margin": "Margen",
    "market_failure_00001": "Error al obtener el mercado",
    "market_failure_00002": "ID de comerciante no existe",
    "market_failure_00003": "Sin área de transacción",
    "market_failure_00004": "Error en el mercado de criptomonedas",
    "market_failure_00005": "Nombre del par vacío",
    "market_failure_00006": "Sin información disponible",
    "market_failure_00007": "Error al obtener información del par",
    "market_failure_00008": "Error al obtener datos de K-line",
    "market_failure_00009": "Error en datos de profundidad",
    "market_failure_00010": "Parámetros incorrectos",
    "market_failure_00011": "Par de inválido",
    "market_low_price": "Precio más alto de compra",
    "market_order": "Orden de mercado",
    "market_price": "Precio de mercado",
    "market_sell_price": "Precio mínimo de venta",
    "market_switch_moved_here": "Cambio de interfaz aquí",
    "match": "Coincidencia",
    "match_the_number_of": "Cantidad coincidente",
    "max_lock_volume": "Máximo bloqueado",
    "maximum_30_characters": "Máximo 30 caracteres",
    "maximum_coins": "Máximo de retiro",
    "maximum_withdrawal": "Máximo por transacción",
    "mention_a_currency": "Cantidad de retiro",
    "min_lock_volume": "Mínimo bloqueado",
    "min_recharge_amount": "Monto mínimo para recarga",
    "minhour": "Minuto por hora",
    "minimum_coins": "Mínimo de retiro",
    "minimum_purchase_quantity": "Compra mayor que saldo restante",
    "minimum_quantity_sold": "Venta mayor que saldo restante",
    "minutes": "min",
    "mobile phone": "Teléfono",
    "money": "Monto",
    "more": "Más",
    "more Or than": "Largo: valor nominal * cantidad * precio de cierre promedio - valor nominal * cantidad * precio de apertura promedio\n\nCorta: valor nominal * cantidad * precio de apertura promedio - valor nominal * cantidad * precio de cierre promedio",
    "more-than-max": "Excede el límite máximo",
    "more11": "Más",
    "more_can_save": "Se puede almacenar hasta",
    "more_home": "Más",
    "msg_camera_framework_bug": "Active permisos de cámara",
    "must_retain_one": "Mantenga al menos uno: teléfono, Google, correo",
    "my_income": "Mis ingresos",
    "my_prompt_001": "* Cambie el número para iniciar sesión con el nuevo.",
    "my_prompt_002": "* Use su cuenta real. Métodos de pago activados se mostrarán a compradores en OTC.",
    "my_prompt_003": "* Cambie el correo para iniciar sesión con el nuevo.",
    "my_vote": "Mi voto",
    "name": "Nombre",
    "name_empty": "Nombre no puede estar vacío",
    "nationality": "Nacionalidad",
    "need_me_record_video": "Grabe un video",
    "never_enter": "Nunca ingrese la contraseña de transacción",
    "new_address": "Nueva dirección",
    "new_pass": "Nueva contraseña",
    "new_pass_empty": "Ingrese nueva contraseña",
    "new_pass_minimum": "Contraseña mínima de 6 caracteres",
    "new_report_statistics": "Nuevas estadísticas",
    "newest_versions": "¡Ya es la última versión!",
    "next": "Siguiente",
    "nick_name": "Apodo",
    "nickname_des_txt": "El apodo solo admite caracteres en chino, inglés y números (máx. 15)",
    "nighttime": "Noche",
    "ninety_days": "Últimos 90 días",
    "no-lock-detail": "Sin información de bloqueo",
    "no_Senior_certification": "Realice la certificación avanzada primero",
    "no_data": "Sin datos disponibles",
    "no_flash": "El dispositivo no tiene flash",
    "no_more_data": "No hay más datos",
    "no_photo_taking": "El dispositivo no soporta fotografía",
    "no_price": "El precio no puede estar vacío",
    "no_quantity": "Ingrese una cantidad válida",
    "no_realname_auth": "Realice la verificación de identidad primero",
    "no_use": "No activado",
    "none": "Ninguno",
    "not_bound": "No vinculado",
    "not_frequency_partner": "No es socio actualmente",
    "not_redemption": "Sin derecho a canje",
    "not_started": "No iniciado",
    "not_to_issue": "No emitido",
    "not_trading": "Este tipo no se puede negociar",
    "notpass": "Verificación no aprobada",
    "null_address_tag": "La etiqueta de la dirección no puede estar vacía",
    "null_code": "El código no puede estar vacío",
    "null_email": "El correo no puede estar vacío",
    "null_password": "La contraseña no puede estar vacía",
    "null_phone": "El teléfono no puede estar vacío",
    "number": "Unidades",
    "number_empty": "La cantidad no puede ser 0",
    "number_of_arrivals": "Cantidad recibida",
    "number_of_issues": "Total emitido",
    "number_of_withdrawal": "Número de retiros diarios",
    "obtained": "Retirado",
    "occlusion": "Cara bloqueada",
    "occupy": "Ocupado",
    "official_community": "Comunidad oficial",
    "official_verification_channel": "Canal de verificación",
    "old_pass": "Contraseña antigua",
    "old_pass_empty": "Ingrese la contraseña anterior",
    "on_sale": "En venta",
    "online_service": "Servicio en línea",
    "open XKD perpetual swap": "Activar contrato perpetuo BitMatrix",
    "open perpetual swap": "Activar contrato perpetuo",
    "open_app": "Abrir aplicación",
    "open_it": "Abrir",
    "open_successfully": "Abierto con éxito",
    "open_touchID": "¿Activar TouchID?",
    "opposite_side_uid": "Contraparte",
    "option": "Opción",
    "optional": "Opcional",
    "optional_area": "Área seleccionada",
    "options": "Métodos de selección",
    "order": "Orden",
    "order time": "Por tiempo de orden",
    "order time1": "Hora de orden",
    "order_a_complaint": "Queja de pedido",
    "order_details": "Detalles del pedido",
    "order_form": "Formulario de pedido",
    "order_history": "Historial de pedidos",
    "order_inquiry": "Consulta de pedidos",
    "order_number": "Número de orden",
    "order_time": "Hora de pedido",
    "ordinary_partner": "Socio ordinario",
    "otc_account": "Cuenta",
    "otc_confirm_Put_the_coin": "Confirme que recibió el pago (¡Advertencia: no confirme antes de recibir el pago!)",
    "otc_confirm_the_payment": "Confirmar pago",
    "otc_confirm_the_receipt": "Confirmar recepción",
    "otc_failure_0001": "Cuenta deshabilitada, contacte al soporte",
    "otc_failure_0002": "Cuenta no aprobada para OTC",
    "otc_failure_0003": "Contraseña de transacción incorrecta",
    "otc_failure_0004": "ID de orden inválido",
    "otc_failure_0006": "Error al cambiar estado",
    "otc_failure_0009": "Tipo de orden no existe",
    "otc_failure_0010": "Revocación fallida",
    "otc_failure_0011": "La orden ya está completada o revocada",
    "otc_failure_0012": "Usuario no es comerciante OTC, sin acceso",
    "otc_failure_0013": "Desbloqueo fallido, revocación fallida",
    "otc_failure_0015": "Tipo de orden no existe",
    "otc_failure_0016": "Cantidad de orden debe ser > 0",
    "otc_failure_0017": "Precio debe ser > 0",
    "otc_failure_0018": "Límite mínimo no puede ser < 0",
    "otc_failure_0019": "Límite máximo no puede ser < mínimo",
    "otc_failure_0020": "Moneda fiduciaria no soportada",
    "otc_failure_0021": "Moneda fiduciaria no disponible",
    "otc_failure_0022": "ID de moneda del comerciante incorrecto",
    "otc_failure_0023": "Límite mínimo no puede ser < mínimo de la moneda {}",
    "otc_failure_0024": "Límite máximo no puede ser > máximo de la moneda {}",
    "otc_failure_0025": "Decimales de orden > {}",
    "otc_failure_0026": "Monto total no puede ser < límite inicial {}",
    "otc_failure_0027": "Monto total no puede ser > límite del usuario {}",
    "otc_failure_0028": "Monto total no puede ser > límite del comerciante {}",
    "otc_failure_0029": "Precio no puede ser < {}",
    "otc_failure_0030": "Precio no puede ser > {}",
    "otc_failure_0031": "Usuario no ha vinculado cuenta de pago",
    "otc_failure_0032": "Congelación fallida, orden fallida",
    "otc_failure_0033": "Máximo de órdenes simultáneas debe ser > 0",
    "otc_failure_0034": "El número de transacciones del comprador debe ser > 0",
    "otc_failure_0035": "Usuario ya es comerciante o está en revisión",
    "otc_failure_0036": "Transacciones OTC exitosas del solicitante insuficientes",
    "otc_failure_0037": "Desbloqueo fallido, revocación fallida",
    "otc_failure_0038": "Saldo disponible insuficiente en la cuenta {}",
    "otc_failure_0039": "Sin moneda actual",
    "otc_failure_0040": "Moneda actual no disponible",
    "otc_failure_0041": "Envío exitoso, espere revisión",
    "otc_failure_0042": "Usuario en revisión, no puede volver a solicitar",
    "otc_failure_0043": "Tipo de transacción no existe",
    "otc_failure_0044": "Auto-compra/venta prohibida",
    "otc_failure_0046": "Máximo de órdenes alcanzado, intente más tarde",
    "otc_failure_0047": "El comprador debe tener al menos {} transacciones",
    "otc_failure_0048": "Cantidad de compra debe ser > 0",
    "otc_failure_0049": "Cantidad de compra no puede exceder la cantidad restante",
    "otc_failure_0050": "ID de orden incorrecto",
    "otc_failure_0051": "Total de compra < mínimo de transacción {}",
    "otc_failure_0052": "Monto de compra > máximo de transacción {}",
    "otc_failure_0053": "Tipo de orden no es de venta",
    "otc_failure_0054": "Estado de la orden incorrecto",
    "otc_failure_0055": "Monto de compra < mínimo de la orden {}",
    "otc_failure_0056": "Monto de compra > máximo de la orden {}",
    "otc_failure_0057": "Estado no existente",
    "otc_failure_0058": "Número de registros de cambio incorrecto",
    "otc_failure_0059": "Congelación fallida, orden fallida",
    "otc_failure_0060": "Vincule una cuenta de pago del tipo requerido en la configuración",
    "otc_failure_0061": "El vendedor debe tener al menos {} transacciones",
    "otc_failure_0062": "Cantidad de venta debe ser > 0",
    "otc_failure_0063": "Cantidad de venta no puede exceder la cantidad restante",
    "otc_failure_0064": "Tipo de orden no es de compra",
    "otc_failure_0065": "Total de venta < mínimo de transacción {}",
    "otc_failure_0066": "Monto de venta > máximo de transacción {}",
    "otc_failure_0067": "Monto de venta < mínimo de la orden {}",
    "otc_failure_0068": "Monto de venta > máximo de la orden {}",
    "otc_failure_0069": "ID de cuenta de pago incorrecto",
    "otc_failure_0070": "Fallo al confirmar pago",
    "otc_failure_0073": "Fallo al cambiar estado de la orden",
    "otc_failure_0074": "Fallo al transferir moneda, fallo al confirmar recepción",
    "otc_failure_0075": "Método de pago no puede estar vacío",
    "otc_failure_0076": "Fallo al enviar SMS: {}",
    "otc_failure_0077": "Esta moneda no existe",
    "otc_failure_0078": "Esta moneda fiduciaria no existe",
    "otc_failure_0079": "Fallo al cambiar estado de la orden",
    "otc_failure_0080": "Esta moneda está deshabilitada",
    "otc_failure_0081": "Esta moneda fiduciaria está deshabilitada",
    "otc_failure_0082": "Esta orden no existe",
    "otc_failure_0083": "OTC no ha configurado moneda fiduciaria",
    "otc_failure_0084": "OTC no ha configurado moneda",
    "otc_failure_0085": "Cantidad restante insuficiente",
    "otc_failure_0090": "Esta cuenta está prohibida para OTC, consulte al soporte",
    "otc_failure_0091": "Usuarios normales no pueden hacer órdenes OTC, solicite ser comerciante",
    "otc_failure_0092": "Orden actual no ha superado el tiempo de recepción, el comprador no puede apelar",
    "otc_failure_0093": "Esta orden ya ha sido apelada, contacte al soporte",
    "otc_failure_0094": "No puede realizar esta acción según su ubicación de registro",
    "otc_failure_0095": "Esta orden no está disponible para negociación",
    "otc_failure_0096": "Activos de la cuenta del vendedor anormales, operación no permitida",
    "otc_failure_0097": "Número máximo de cancelaciones OTC alcanzado, prohibido negociar",
    "otc_failure_0098": "Tiene órdenes pendientes, complete antes de continuar.",
    "otc_margin_certification": "Certificación de margen",
    "otc_mine_account_paid": "He pagado",
    "otc_order_prompt_003": "Si el comprador no paga y no confirma dentro del tiempo, la orden se cancelará automáticamente",
    "otc_picture_no_null": "Suba al menos una imagen",
    "otc_prompt_001": "1. La validez del pago de la orden es de 30 minutos, pague a tiempo y haga clic en 'He pagado'.\n2. Las monedas del vendedor están bloqueadas por el sistema, puede confiar en hacer el pedido.",
    "otc_rate": "Tasa",
    "otc_report_order": "Orden completada",
    "otc_success_0001": "Revocación exitosa",
    "otc_success_0002": "Venta exitosa",
    "otc_success_0003": "Compra exitosa",
    "otc_success_0004": "Confirmación de pago exitosa",
    "otc_success_0005": "Confirmación de recepción exitosa, orden finalizada",
    "otc_success_0006": "Apelación exitosa",
    "otc_waiting_for_confirmation": "Esperando confirmación del vendedor",
    "other_settings": "Otras configuraciones",
    "overtime_pay": "Pago no realizado a tiempo, orden cancelada",
    "param-error": "Error de parámetro",
    "partner": "Socio",
    "partner_commission": "Los socios pueden recibir comisiones de sus subordinados",
    "partner_commission_fee": "Comisión por servicio",
    "partner_current_level": "Nivel actual",
    "partner_get_all_subordinate": "Puede recibir de todos sus subordinados",
    "partner_management": "Gestión de socios",
    "partner_need_pay": "Necesita pagar",
    "partner_need_recommend": "Necesita referir",
    "partner_recommended": "Referido",
    "partner_start_and_end_time": "Fecha de inicio y fin",
    "partner_team": "Equipo",
    "partner_team_member": "Necesita alcanzar el número de miembros del equipo",
    "partner_upgrade_immediately": "Actualizar ahora",
    "pass_muster": "Si no cumple, haga clic aquí",
    "passed": "Aprobado",
    "passport": "Pasaporte",
    "password_disagree": "Las contraseñas no coinciden",
    "password_is_different": "Las contraseñas son diferentes",
    "password_text": "Contraseña",
    "password_wrong": "Establecer contraseña 8-20 dígitos",
    "past_activities": "Actividades anteriores",
    "pause_recharge": "Esta moneda no admite recargas",
    "pause_withdraw": "Esta moneda no admite retiros",
    "pay_number": "Cantidad a pagar",
    "pay_wey": "Método de pago",
    "payee": "Beneficiario",
    "payfor_type": "Tipo de pago",
    "payment": "Pago",
   "payment_amount": "Monto del pago",
    "payment_currency": "Moneda del pago",
    "payment_has_been": "(He pagado)",
    "payment_term": "Método de recepción",
    "paypal_account": "Cuenta de PayPal",
    "pending_review": "Pendiente de revisión",
    "people": "Personas",
    "period": "Período",
    "personal_detail": "Detalles personales",
    "personal_purchase_tips": "No puede comprar su propia orden",
    "phone": "Teléfono",
    "phone_code": "Código de verificación del teléfono",
    "phone_register": "Registro con teléfono",
    "phone_verification": "Verificación por SMS",
    "phone_verify": "Verificación del teléfono",
    "photo_album": "Álbum de fotos",
    "please_enter": "Por favor ingrese",
    "please_enter_abbreviation_or_letter": "Por favor ingrese contenido de búsqueda",
    "please_input_content": "Por favor ingrese contenido",
    "please_pay": "Por favor pague",
    "please_verification": "Por favor verifique",
    "pop_Date": "Fecha",
    "pop_reset": "Restablecer",
    "position at market price?": "¿Cerrar posición al precio de mercado?",
    "post_only": "Solo Maker (Post only)",
    "price": "Precio",
    "price_cannot_be_less": "El precio no puede ser menor que {}",
    "price_cannot_be_more": "El precio no puede ser mayor que {}",
    "price_empty": "El precio no puede ser 0",
    "principal": "Principal",
    "promote_links": "Enlaces de promoción",
    "promotion_statistical": "Estadísticas de promoción",
    "prtner_invalid": "Inválido",
    "publish_a_trade_order": "Publicar orden de comercio",
    "purchase": "Compra",
    "push": "PUSH de activos",
    "push_maximum_volume": "La cantidad PUSH es mayor que el saldo disponible {}",
    "push_minimum_trading_volume": "La cantidad PUSH es menor que el volumen mínimo de comercio {}",
    "quantity": "Cantidad",
    "quantity_deal": "Cantidad/Transacción",
    "quota": "Límite",
    "range": "Rango",
    "real_income": "Ingreso real",
    "real_name_authentication": "Autenticación de nombre real",
    "reason": "Razón",
    "rebate_amount": "Monto de reembolso",
    "rebate_record": "Registro de reembolsos",
    "rebate_reward": "Recompensa de reembolso",
    "receiving_set": "Configuración de recepción",
    "receiving_set_title": "Por favor configure el método de recepción primero",
    "recently_deal1": "Transacciones recientes",
    "recharge_notice": "Notificación de recarga\n1. Envíe la cantidad de {} que necesita recargar a esta dirección a través de {} cliente o billetera en línea. Después de enviar, el sistema recargará automáticamente su cuenta en este sitio después de {} confirmaciones. Por favor, tenga paciencia.\n2. Puede recargar varias veces a la misma dirección, no afectará a la cuenta.\n3. Monto mínimo de recarga {}.",
    "recharge_source": "Fuente de recarga",
    "recharge_withdraw_failure_0001": "Error de red",
    "recharge_withdraw_failure_0002": "La contraseña de transacción no puede estar vacía",
    "recharge_withdraw_failure_0003": "El código de verificación no puede estar vacío",
    "recharge_withdraw_failure_0004": "El ID de comerciante no puede estar vacío",
    "recharge_withdraw_failure_0005": "El ID de usuario no puede estar vacío",
    "recharge_withdraw_failure_0006": "El usuario no existe",
    "recharge_withdraw_failure_0007": "El ID de moneda no puede estar vacío",
    "recharge_withdraw_failure_0009": "La moneda no existe",
    "recharge_withdraw_failure_0010": "El código de Google no puede estar vacío",
    "recharge_withdraw_failure_0011": "La contraseña de transacción es incorrecta",
    "recharge_withdraw_failure_0012": "El tipo de recarga no puede estar vacío",
    "recharge_withdraw_failure_0013": "La cantidad de recarga no puede estar vacía",
    "recharge_withdraw_failure_0014": "La nota de recarga no puede estar vacía",
    "recharge_withdraw_failure_0015": "El ID de recarga no puede estar vacío",
    "recharge_withdraw_failure_0016": "La solicitud de recarga no existe",
    "recharge_withdraw_failure_0017": "Solo se pueden eliminar solicitudes de recarga pendientes",
    "recharge_withdraw_failure_0018": "Solo se pueden revisar solicitudes de recarga pendientes",
    "recharge_withdraw_failure_0019": "Solo se pueden liberar las solicitudes aprobadas",
    "recharge_withdraw_failure_0020": "La versión no puede estar vacía",
    "recharge_withdraw_failure_0021": "Error de parámetro",
    "recharge_withdraw_failure_0022": "Los datos actuales han sido actualizados",
    "recharge_withdraw_failure_0023": "El ID no puede estar vacío",
    "recharge_withdraw_failure_0024": "Solo el estado bloqueado puede ser revisado",
    "recharge_withdraw_failure_0025": "Solo las solicitudes pendientes pueden ser bloqueadas",
    "recharge_withdraw_failure_0026": "Solo las solicitudes pendientes o bloqueadas pueden ser canceladas",
    "recharge_withdraw_failure_0027": "La cantidad congelada no puede estar vacía",
    "recharge_withdraw_failure_0028": "La razón de la congelación no puede estar vacía",
    "recharge_withdraw_failure_0029": "La nota de descongelación no puede estar vacía",
    "recharge_withdraw_failure_0030": "La cantidad de retiro no puede estar vacía",
    "recharge_withdraw_failure_0031": "La dirección de retiro no puede estar vacía",
    "recharge_withdraw_failure_0032": "La tarifa no puede estar vacía",
    "recharge_withdraw_failure_0033": "Error en la tarifa",
    "recharge_withdraw_failure_0034": "Los datos no existen",
    "recharge_withdraw_failure_0035": "Este activo no puede ser descongelado",
    "recharge_withdraw_failure_0036": "La dirección de retiro es incorrecta",
    "recharge_withdraw_failure_0041": "Esta cuenta ha sido prohibida para retiros, consulte al soporte",
    "recharge_withdraw_failure_0042": "Número máximo de retiros diarios alcanzado",
    "recharge_withdraw_failure_0043": "Cantidad total de retiros excede el límite diario",
    "recharge_withdraw_failure_0044": "Error al obtener información de nivel VIP",
    "recharge_withdraw_failure_0045": "Error al obtener información de moneda del comerciante",
    "recharge_withdraw_failure_0046": "Prohibido retirar dentro de las 24 horas posteriores al restablecimiento de la contraseña",
    "recharge_withdraw_failure_0048": "Error al obtener el valor máximo de un solo retiro",
    "recharge_withdraw_failure_0049": "Error al obtener el valor mínimo de un solo retiro",
    "recharge_withdraw_failure_0050": "La cantidad ingresada debe ser menor que el límite máximo",
    "recharge_withdraw_failure_0051": "La cantidad ingresada debe ser mayor que el límite mínimo",
    "recharge_withdraw_msg_status_0001": "Esperando retiro",
    "recharge_withdraw_msg_status_0002": "Enviando",
   "recharge_withdraw_msg_status_0003": "Retiro exitoso",
    "recharge_withdraw_msg_status_0004": "Cancelado",
    "recharge_withdraw_msg_status_0005": "En confirmación",
    "recharge_withdraw_msg_status_0006": "Recarga exitosa",
    "recharge_withdraw_msg_status_0007": "Revisado",
    "recharge_withdraw_msg_type_0001": "Recarga",
    "recharge_withdraw_msg_type_0002": "Retiro",
    "recharge_withdraw_msg_withdraw_type_0001": "Transferencia entre plataformas",
    "recharge_withdraw_msg_withdraw_type_0002": "Retiro externo",
    "record": "Registro",
    "record_the_video": "Grabar video",
    "recording": "Regrabando",
    "redeem_now": "Canjear ahora",
    "redeemed": "Canjeado",
    "redeeming": "Canjeando",
    "redemption_amount": "Cantidad canjeada",
    "redemption_time": "Hora de canje",
    "referrer": "Referido",
    "register": "Registrarse",
    "registered_successfully": "Registro exitoso",
    "registration_award_details": "Detalles del premio por registro",
    "registration_incentives": "Incentivos por registro",
    "registration_time": "Hora de registro",
    "regular": "Regular",
    "regular_return": "Devolución mensual",
    "release": "Publicar",
    "release_operation": "Publicar orden, haga clic aquí!",
    "release_the_order": "Publicar orden",
    "release_time": "Hora de emisión",
    "remark": "Notas",
    "remember_state": "Recordar estado",
    "repeal": "Revocar",
    "reset": "Restablecer",
    "reset_login_password": "Modificar contraseña",
    "reset_pass": "Restablecer contraseña",
    "residue": "Residuo",
    "retrieve_the_deposit": "Recuperar depósito",
    "return_amount": "Cantidad de devolución",
    "return_method": "Método de devolución",
    "return_the_principal": "Devolver el principal",
    "revenue_details": "Detalles de ingresos",
    "revocation_all": "Revocar todo",
    "reward_amount": "Cantidad de recompensa",
    "reward_locked": "Recompensa bloqueada",
    "reward_status": "Estado de recompensa",
    "reward_type": "Tipo de recompensa",
    "rewards_record": "Registro de recompensas",
    "right_now_register": "Registro de nuevos usuarios",
    "roll_out": "Retirar",
    "save": "Guardar",
    "save_coin": "Depositar",
    "save_coin_count": "Cantidad a depositar",
    "save_coin_days": "Días de depósito",
    "save_coin_duration": "Duración del depósito",
    "save_currency": "Depositar moneda",
    "save_money_amount": "Cantidad total depositada",
    "save_successfully": "Guardado exitosamente",
    "save_the_image": "Descargar imagen",
    "scan_QR_code": "No escanee códigos QR desconocidos",
    "scan_result": "Resultado del escaneo",
    "search": "Buscar",
    "search_coin_placeholder": "Buscar moneda de interés",
    "search_keyword": "Palabra clave",
    "search_name": "Nombre",
    "search_result": "Resultados",
    "search_trade_pair_placeholder": "Buscar par de comercio",
    "security_center": "Centro de seguridad",
    "security_level": "Nivel de seguridad",
    "select_date_of_birth": "Seleccione fecha de nacimiento",
    "select_delete_warn": "Seleccione alerta a eliminar",
    "sell": "Vender",
    "sell_1": "Vender",
    "sell_order": "Demanda",
    "sell_quantity": "Cantidad a vender",
    "sell_rate": "Comisión de venta",
    "seller": "Vendedor",
    "seller_phone": "Teléfono del vendedor",
    "send": "Enviar",
    "senior_certification": "Certificación avanzada",
    "senior_certification1": "Certificación avanzada",
    "service": "Servicio al cliente QQ:",
    "service_charge": "Cargo por servicio",
    "session_timeout": "Sesión expirada, inicie sesión de nuevo",
    "set_Password": "Modificar",
    "set_gesture_pass": "Establezca contraseña de gesto",
    "set_login_password": "Establecer contraseña",
    "setting": "Configuraciones",
    "setting_alipay": "Configurar Alipay",
    "setting_bank_card": "Configurar tarjeta bancaria",
    "setting_message_warn_tip": "* Al activar, recibirá alertas. Si se desactiva, no recibirá notificaciones.",
    "setting_paypal": "Configurar PayPal",
    "setting_paypassword": "Establezca la contraseña de transacción primero",
    "setting_wechat": "Configurar WeChat",
    "setting_western_union": "Configurar Western Union",
    "share_invitation_link": "Compartir enlace de invitación",
    "share_link": "Compartir enlace con amigos",
    "share_to_do": "Compartir a",
    "shift_to": "Transferir a",
    "single_least": "Monto mínimo menor que {}",
    "single_minimum": "Mínimo por transacción",
    "single_more": "Monto mínimo mayor que {}",
    "skip": "Omitir",
    "small_exchange": "Intercambio pequeño",
    "small_exchange_tip": "* Solo se puede canjear una vez en 24 horas por valor menor que {}{}. Monedas no listadas no pueden canjearse, tarifa provisional de {}.",
    "small_exchange_tip-1": "* Precio del mercado cambia en tiempo real, se aplica el precio actual. Monedas no listadas no pueden canjearse, tarifa provisional de {0}%.",
    "small_exchange_valuation": "Valoración",
    "small_exchange_valuation-1": "Valoración en BTC",
    "smallest_unit_price": "Precio unitario de PUSH menor que {}",
    "so_adjusted_to": "ajustado a múltiplo entero de",
    "sold_out": "Agotado",
    "start time": "Hora de inicio",
    "start_date": "Fecha de inicio",
    "start_time": "Hora de inicio",
   "statistics_report": "Informe",
    "status": "Estado",
    "submit": "Enviar video",
    "submitBtn": "Enviar",
    "succeed": "Éxito",
    "successful_invitation": "Invitación exitosa",
    "successfully_received": "Reclamo exitoso",
    "successfully_set": "Configuración exitosa",
    "super_partner": "Súper socio",
    "switch_account": "Cambiar cuenta",
    "system_top_up": "Recarga del sistema",
    "system_unlock": "Desbloqueo del sistema",
    "tab_assets": "Activos",
    "tab_home": "Inicio",
    "tab_markets": "Mercados",
    "tab_swaps": "Contratos",
    "tab_trade": "Comercio",
    "taking_pictures": "Tomando fotos",
    "telegraph": "Grupo de Telegram:",
    "temporary_not_online_contact_service": "No disponible, contacte al servicio",
    "the_day": "El día",
    "the_event_is_over": "Evento terminado",
    "the_latest_price": "Último precio",
    "the_password_is_correct": "Contraseña correcta",
    "theme": "Tema",
    "this_month": "Este mes",
    "this_week": "Esta semana",
    "ticket": "Boleto",
    "time_of_payment": "Hora de pago",
    "time_the_input": "Sin ingresar contraseña en {0}h",
    "timeout": "Tiempo agotado",
    "timeout_affirm": "No confirmado a tiempo",
    "timeout_deal": "Transacción automática",
    "timeout_to_cancel": "Pago agotado, cancelado",
    "to_confirm": "Confirmar",
    "total_buyer": "Total de compradores",
    "total_count": "Cantidad total",
    "total_exchange": "Total de intercambios",
    "total_lock_amount": "Cantidad bloqueada",
    "total_money": "Total",
    "total_return": "Total estimado",
    "trade_fee": "Tarifa de comercio",
    "trade_type": "Tipo de comercio",
    "trading_currency": "Moneda de comercio",
    "trading_fiat_currency": "Moneda fiat",
    "trading_instructions": "Instrucciones de comercio",
    "trading_on": "Comercio en",
    "trading_pair_abbreviation": "Ingrese abreviatura del par",
    "trading_this_month": "Comercio este mes",
    "trading_volume": "Volumen",
    "transaction_description": "Descripción de transacción",
    "transaction_password": "Contraseña de fondos",
    "transaction_price": "Precio de transacción",
    "transfer_number": "Cantidad a transferir",
    "try_it_now": "Prueba ahora",
    "type": "Tipo",
    "unable_to_access_album": "No se puede acceder a la galería",
    "unaccalimed": "No reclamado",
    "unavailable": "No disponible",
    "under_review": "En revisión",
    "univalence": "Precio unitario",
    "unlock_details": "Detalles de desbloqueo",
    "unlock_mode": "Modo de desbloqueo",
    "unlock_period": "Período de desbloqueo",
    "unlock_plan": "Plan de desbloqueo",
    "unlock_plan_unlock": "Desbloquear",
    "unlock_record": "Registro de desbloqueo",
    "unlock_time": "Hora de desbloqueo",
    "unlocked": "Desbloqueado",
    "unredeemed": "No canjeado",
    "unverified": "No verificado",
    "up_to_three": "Máximo tres",
    "upgrade": "Actualizar",
    "upload_again": "Subir de nuevo",
    "upload_front": "Subir frente del documento",
    "upload_identity_card": "Suba su documento",
    "upload_image_success": "Imagen subida con éxito",
    "upload_information_as_required": "Suba documentos según indicaciones",
    "upload_passport": "Suba su pasaporte",
    "upload_passport_info_picture": "Subir foto de pasaporte",
    "upload_reverse": "Subir reverso del documento",
    "ups_and_downs": "Variación del día",
    "ups_downs_habit": "Hábitos de variación",
    "usable": "Utilizable",
    "used": "Usado",
    "user-bind-failure": "Error de vinculación",
    "user-bind-success": "Vinculación exitosa",
    "user-collection-error": "Error",
    "user-country-code-not-empty": "Código no puede estar vacío",
    "user-disable-trade": "Cuenta prohibida para comerciar, consulte servicio",
    "user-down-notbind": "Usuario no puede vincular inferiores",
    "user-email-fail-often": "Envio frecuente, espere 120s para reenviar",
    "user-email-not-empty": "El correo no puede estar vacío",
    "user-email-verification-code-out": "Código de verificación expirado, reenvíe",
    "user-fail-advanced-auth": "Error de certificación avanzada",
    "user-fail-advanced-authed": "Usuario ya certificado",
    "user-fail-assetnotenough-error": "Activos insuficientes",
    "user-fail-bank-count-error": "Máximo 3 métodos de pago",
    "user-fail-bank-inotc": "Método con pedido en OTC no puede ser deshabilitado",
    "user-fail-cardno-repeat-error": "Número ya usado",
    "user-fail-email-code": "Código de verificación incorrecto",
   "user-fail-google-binded": "Google ya vinculado",
    "user-fail-google-code": "Código de Google incorrecto",
    "user-fail-inviter-notexist": "Recomendador no existe",
    "user-fail-login-disable": "Cuenta bloqueada, consulte al servicio!",
    "user-fail-login-lock": "Cuenta bloqueada",
    "user-fail-login-password": "Usuario o contraseña incorrectos, intentos restantes {}",
    "user-fail-mail-binded": "Correo ya vinculado",
    "user-fail-not-realname": "Complete verificación de identidad",
    "user-fail-oldpassword-error": "Contraseña anterior incorrecta",
    "user-fail-partner-not-found": "Comerciante no encontrado",
    "user-fail-partnernotfound": "Comerciante no encontrado",
    "user-fail-password": "Usuario o contraseña incorrectos",
    "user-fail-password-blank": "La contraseña no puede estar vacía",
    "user-fail-password-blank1": "Confirmar contraseña no puede estar vacía",
    "user-fail-password-repeat": "Nueva contraseña igual a la antigua, reintente",
    "user-fail-password-thin": "Contraseña débil",
    "user-fail-passwword-eq-paypassword": "Contraseña de transacción igual a la de inicio, reintente",
    "user-fail-paypassword-error": "Contraseña de pago incorrecta",
    "user-fail-paypassword-notset": "Configure la contraseña de pago primero",
    "user-fail-paypassword-set": "Contraseña de pago configurada",
    "user-fail-phone-binded": "Número de teléfono ya vinculado",
    "user-fail-realname-auth": "Verificación de identidad fallida",
    "user-fail-realname-authed": "Ya verificado",
    "user-fail-realname-notset": "Complete la verificación de identidad",
    "user-fail-reg": "Registro fallido, error del servidor",
    "user-fail-reg-mail": "Formato de correo incorrecto",
    "user-fail-reg-mail-exist": "Correo ya registrado",
    "user-fail-reg-phone-exist": "Número de teléfono ya registrado",
    "user-fail-securitycount": "Mantenga al menos un método de verificación",
    "user-fail-sms-code": "Código SMS incorrecto",
    "user-fail-sms-code-new": "Código SMS para nuevo número incorrecto",
    "user-fail-sms-code-old": "Código SMS para antiguo número incorrecto",
    "user-fail-usernameisblank": "El nombre de usuario no puede estar vacío",
    "user-id-or-nationCode—is-null": "ID o código internacional vacío",
    "user-login-success": "Inicio de sesión exitoso",
    "user-mail-fail": "Error al enviar correo",
    "user-mail-success": "Correo enviado con éxito",
    "user-not-exist": "Usuario no existe",
    "user-notopen-success": "No activado aún",
    "user-phone-not-empty": "El número de teléfono no puede estar vacío",
    "user-sms-fail": "Error al enviar, error del servidor",
    "user-sms-fail-often": "Envío de SMS frecuente",
    "user-sms-success": "SMS enviado con éxito",
    "user-sms-verification-code-out": "Código expirado, reenvíe",
    "user-success-advanced-auth": "Certificación avanzada exitosa",
    "user-success-realname-auth": "Verificación de identidad exitosa",
    "user-validate-code-fail": "Código incorrecto",
    "user-validate-fail": "Validación fallida",
    "user-vip-common-fail": "Compra de VIP fallida",
    "user_agreement": "Marque para aceptar el acuerdo",
    "user_collection_cencel_success": "Cancelado",
    "user_collection_insert_success": "Añadido a favoritos",
    "user_pay_disable_state": "Cuenta bloqueada por errores en la contraseña, reintente en 2 horas",
    "user_pay_is_error": "Contraseña de transacción incorrecta, {} intentos restantes",
    "user_pay_password_not_setting": "Configure la contraseña de transacción primero",
    "user_protocol": "Acuerdo de usuario",
    "user_service_agreement": "Acuerdo de servicio al usuario",
    "user_vip_has_expire": "VIP expirado, no puede actualizar",
    "user_vip_update_failure": "No se admite renovación ni actualización, consulte servicio!",
    "valid_identity_card": "Ingrese un número de documento válido",
    "valuation": "Valoración de moneda",
    "verification_code_error": "Código incorrecto",
    "verification_failed_001": "Verificación fallida",
    "verification_finish": "Verificación completada",
    "version_updating": "Actualizando versión",
    "versions": "Versiones",
    "vertical_version": "Versión vertical",
    "view_activity": "Ver actividad",
    "view_lock": "Ver bloqueo",
    "volamount": "Comprador debe haber completado {} transacciones",
    "volamount_sell": "Vendedor debe haber completado {} transacciones",
    "vote": "Votar",
    "vote_add_coin": "Votar por añadir moneda",
    "vote_return": "Devolución",
    "voting_coin": "Moneda de votación",
    "voting_number": "Número de votos",
    "voting_time": "Tiempo de votación",
    "wait_buyer_payment": "Esperando pago del comprador",
    "wait_for_payment": "Esperando pago",
    "waiting_for_receiving": "Esperando confirmación de pago",
    "warm_prompt": "Consejo",
    "warning_setTitle": "Configuración de advertencias",
    "wechat_account": "Cuenta de WeChat",
    "wechat_upload": "*Método para subir código QR: Abra WeChat > Mi > Wallet > Recibir > Guardar imagen y subir.",
    "week": "Semana",
    "welcome": "Bienvenido a BitMatrix",
    "welcome_to": "Bienvenido a",
    "winning_record": "Registro de ganadores",
    "withdrawal_amount": "Límite diario de retiro",
    "withdrawal_charge": "Tarifa de retiro",
    "xilian": "Western Union",
    "yesterday_income": "Ingresos de ayer",
    "you_have_selected": "Ha seleccionado",
    "Your closing number": "Su cantidad de cierre es menor que el mínimo!",
    "Your have pending Cross orders": "Existen órdenes de margen cruzado, retire todas antes de modificar",
    "Your have pending isolated orders": "Existen órdenes aisladas, retire todas antes de modificar",
    "Home page apps": "Aplicaciones de inicio",
    "Home_Edit": "Editar",
    "recently_deal01": "Transacciones",
    "depth01": "Profundidad",
    "depth02": "Gráfica de profundidad",
    "discount_currency_introduction01": "Introducción a la moneda",
    "Info001": "Descripción",
    "Avg cost": "Costo promedio",
    "Long position": "Posición larga",
    "Short position": "Posición corta",
    "Hold on": "Espere",
    "Done": "Hecho",
    "new_version": "Nueva versión disponible",
    "Announcement": "Anuncio",
    "View details": "Ver detalles",
    "Option Assets": "Activos de opciones",
    "Option Account": "Cuenta de opciones",
    "Option": "Opción",
    "1M": "1 min",
    "5M": "5 min",
    "15M": "15 min",
    "30M": "30 min",
    "1H": "1 hora",
    "Delivery Time": "Tiempo hasta entrega",
    "Call": "Compra",
    "Put": "Venta",
    "Draw": "Empate",
    "Draw2": "Empate",
    "Call1": "Compra",
    "Put1": "Venta",
    "Draw1": "Empate",
    "Buy-2": "Comprar",
    "Rate of return": "Tasa de retorno",
    "Change": "Cambio",
    "Buy Option": "Comprar opción",
    "Awaiting": "Esperando entrega",
    "Delivery": "Mis entregas",
    "Checkout": "Registro de entrega",
    "Front": "Actual",
    "Back": "Próximo",
    "Coming soon": "Próxima entrega",
    "delivery settlement": "Liquidación en curso",
    "Ongoing": "En compra",
    "Pending purchase": "Compra pendiente",
    "Billing Amount": "Cantidad de liquidación",
    "Amount": "Cantidad de compra",
    "Delivery result": "Resultado de entrega",
    "Delivery time": "Hora de entrega",
    "Up": "Sube",
    "Down": "Baja",
    "lookUp": "Ver alza",
    "lookDown": "Ver baja",
    "Delivery details": "Detalles de entrega",
    "Index Composition": "Composición del índice",
    "Exchange": "Intercambio",
    "Change2": "Cambio",
    "Weights": "Pesos",
    "Order number": "Número de orden",
    "Option Session": "Sesión de opciones",
    "Buy Time": "Hora de compra",
    "Opening time": "Hora de apertura",
    "Bet Type": "Tipo de apuesta",
    "Delivery Price": "Precio de entrega",
    "Settlement Currency": "Moneda de liquidación",
    "K-line": "Gráfica K",
    "UpSelect": "Selección de subida",
    "DownSelect": "Selección de bajada",
    "Front has ended, please place another order": "Este periodo ha terminado, haga un nuevo pedido",
    "Successfully ordered": "Pedido realizado con éxito",
    "Insufficient balance": "Saldo insuficiente",
    "Quantity purchased must be greater than the minimum limit": "La cantidad debe ser mayor que el mínimo",
    "Quantity purchased must be less than the maximum limit": "La cantidad debe ser menor que el máximo",
    "The current time is not in the purchase time range": "El tiempo actual no está en el rango de compra",
    "expected": "Ingreso esperado",
    "To_a_game": "Jugar de nuevo",
    "Settlement Result": "Resultado de liquidación",
    "Delivery-1": "Entrega",
    "Ups and downs": "Variación",
    "duration": "Duración",
    "Option funds daybook": "Registro de fondos de opciones",
    "Open innovative options trading": "Activar trading de opciones innovadoras",
    "Confirm Open": "Confirmar activación",
    "Option Agree Content": "Para proteger sus derechos, lea el acuerdo de uso antes de activar",
    "Option Agree Content footer": "Acuerdo de uso de opciones",
    "Option Agree Content header": "Para proteger sus derechos, lea lo siguiente",
    "Service upgrade, please try again later!": "Actualización de servicio, intente más tarde!",
    "month": "Mes",
    "Invitation": "Invitación",
    "Markets": "Mercados",
    "Swaps": "Intercambios",
   "Trade": "Comercio",
    "Trade-1": "Ir a comerciar",
    "Assets": "Activos",
    "Buy": "Comprar moneda",
    "Help": "Ayuda",
    "Service": "Servicio al cliente",
    "Activity": "Actividad",
    "Communtiy": "Comunidad",
    "No search results": "Sin resultados de búsqueda",
    "The option account asset is 0, please transfer from the wallet account to the option account first.": "El activo de la cuenta de opciones es 0, transfiera desde la cuenta de billetera.",
    "Perpetual account assets are 0, please transfer from wallet account to perpetual account first.": "El activo de la cuenta perpetua es 0, transfiera desde la cuenta de billetera.",
    "Financing": "Finanzas",
    "Load": "Cargar",
    "Replay001": "Repetir",
    "Constellation Program": "Presentamos: Programa de constelaciones",
    "Constellation Program2": "Presentamos: Finanzas estelares",
    "rate rankings": "Ranking de tasa de éxito",
    "rate rankings2": "Estrellas de finanzas de esta semana",
    "CXT Quotes": "Citas",
    "Digital asset trading tool service platform": "Plataforma de herramientas de comercio de activos digitales",
    "Service_home": "Servicio",
    "Strategic cooperation": "Cooperación estratégica",
    "Financing assets": "Activos financieros",
    "Remove 0 from the first number when registering!": "¡Elimine el 0 del primer número al registrarse!",
    "Are you sure you want to quit": "¿Está seguro de que desea salir?",
    "Opening Price": "Precio de apertura",
    "Closing Price": "Precio de cierre",
    "Formula interpretation": "Interpretación de fórmula",
    "Note: If the input box is left blank": "Nota: Dejar vacío significa que no se obtuvo datos",
    "Option Sessions": "Sesiones de opciones",
    "Result": "Resultado",
    "When data for an exchange is not available": "*Si no se obtienen datos de un intercambio, su peso es 0%.",
    "Assume that the index is derived from the opening prices of five exchanges": "Suponga que el índice se deriva de los precios de apertura de cinco intercambios.",
    "The proportion of those five exchanges are": "Los pesos de los cinco intercambios son: a%, b%, c%, d%, e%",
    "Stream bureau": "Empate",
    "(valid data source ≤ 2)": "（fuentes de datos válidas ≤ 2）",
    "Option calculator": "Calculadora de opciones",
    "Round selection": "Seleccionar ronda",
    "Select option": "Seleccionar opción",
    "Average opening price": "Precio medio de apertura",
    "Average closing price": "Precio medio de cierre",
    "Calculate now": "Calcular ahora",
    "Calculation Results": "Resultados de cálculo",
    "Calculating Time": "Tiempo de cálculo",
    "Management": "Gestión",
    "Other_home": "Otros",
    "All applications": "Todas las aplicaciones",
    "Boon": "Beneficio",
    "Coin_Address_draw": "Dirección de retiro",
    "Congratulations": "Felicidades",
    "Profit": "Ganancia",
    "Gainers": "Aumentos",
    "Drop": "Caída",
    "Order Price": "Precio de compra",
    "Order Price1": "Precio de compra",
    "Sec Swap": "Contrato de segundos",
    "Expected Return": "Tasa de retorno esperada",
    "Sec Swap Assets": "Activos de contrato de segundos",
    "Sec Swap Bill": "Registro de fondos de contrato de segundos",
    "Sec Swap Account": "Cuenta de contrato de segundos",
    "Go Login": "Ir a iniciar sesión",
    "NSO Session": "Sesión NSO",
    "Wallet Value1": "Valor de billetera",
    "Spot Value1": "Valor de moneda",
    "PERP Value1": "Valor perpetuo",
    "Option Value1": "Valor de opción",
    "Fiat Value1": "Billetera OTC",
    "Pledge_Amount": "Cantidad de garantía",
    "total_return2": "Retorno acumulado",
    "Violation_Loss": "Multa por incumplimiento",
    "Cycle": "Ciclo",
    "Locked2": "Bloqueado",
    "Time_Left": "Tiempo restante",
    "Cancel_Pledge": "Cancelar garantía",
    "countDown_day": "días",
    "Entrust2": "Orden de confianza",
    "All2": "Todo",
    "Ongoing2": "En curso",
    "Near2": "Próximamente",
    "Ended2": "Finalizado",
    "Cancel the pledge?": "¿Desea cancelar la garantía?",
    "Completed2": "Completado",
    "Cancelled2": "Cancelado",
    "Lock-up mining": "Minería en bloqueo",
    "Rules Description": "Reglas",
    "Today Expected": "Ingreso esperado hoy",
    "Order Escrow": "Custodia",
    "Minimum": "Mínimo",
    "Daily Return": "Tasa de retorno diario",
    "Buy-3": "Ir a comprar",
    "Minimum Amount": "Monto mínimo de garantía",
    "Lockup Period": "Período de bloqueo",
    "Confirm Pledge": "Confirmar garantía",
    "Buy-4": "Comprar",
    "Conversion Starter": "Inicio de conversión",
    "Listing": "Listado",
    "Total Target": "Total objetivo",
    "Conversion Ratio": "Tasa de conversión",
    "Single Limited": "Límite por persona",
    "Participate Order": "Orden de participación",
   "Fundraised": "Fondos recaudados",
    "Current Progress": "Progreso",
    "Time Left": "Tiempo restante",
    "Start Time": "Inicio",
    "End Time": "Fin",
    "Conversion Amount": "Cantidad",
    "Conversion Now": "Suscribirse ahora",
    "Conditions": "Condiciones",
    "Preheating": "Precalentamiento",
    "Records": "Registros",
    "Select": "Seleccionar",
    "To": "A",
    "Active Currency": "Moneda activa",
    "Amount-3": "Monto",
    "Conversion Time": "Hora de suscripción",
    "Arrival Time": "Hora de llegada",
    "Not Arrived": "No ha llegado",
    "Hours-1": "Horas",
    "Risk warning: It is displayed": "Advertencia: Evalúe los riesgos cuidadosamente.",
    "Week-1": "Semana",
    "S-1": "Segundos",
    "minutes-2": "Minutos",
    "You must pass basic KYC": "Debe completar KYC básico",
    "You must pass advanced KYC": "Debe completar KYC avanzado",
    "Account order exceeds": "Saldo de cuenta excede",
    "Suspend": "Suspender",
    "Successfully": "Suscripción exitosa",
    "Ongoing-1": "En curso",
    "Failed": "Suscripción fallida",
    "New_Token_Subscribe": "Suscripción de nuevo token",
    "More_awaits_you": "Más riqueza te espera",
    "Distance Start": "Tiempo hasta inicio",
    "day-2": "Días",
    "Must Eligible": "Debe cumplir condiciones",
    "Custodial funds": "Fondos en custodia",
    "Default Ratio": "Tasa de incumplimiento",
    "Wallet Available": "Billetera disponible",
    "Done-2": "Hecho",
    "Eligible Amount": "Cantidad aprobada",
    "Eligible Price": "Precio aprobado",
    "New Token Subscribe": "Suscripción de nuevo token",
    "Subscribe Now": "Suscribirse ahora",
    "Review Time": "Tiempo de revisión",
    "Review Passed": "Revisión aprobada",
    "Review Failed": "Revisión fallida",
    "Not Reviewed": "No revisado",
    "Denial Reason": "Razón de rechazo",
    "If not received, please contact service": "Si no ha llegado, contáctese con servicio.",
    "Returned to wallet": "Devuelto a billetera",
    "From wallet assets": "Desde billetera",
    "Day-3": "Días",
    "Sec Swap asset is 0, please transfer from wallet account to Sec Swap first": "Activo de contrato de segundos es 0, transfiera desde billetera.",
    "Settlement": "Liquidación en curso",
    "Completed": "Liquidación completa",
    "Password Setting": "Configuración de contraseña",
    "Set password and email": "Configurar contraseña y correo",
    "Email Settings": "Configuración de correo",
    "First-time registered users mus": "Nuevos usuarios deben configurar contraseña y correo",
    "Driver ID": "Licencia de conducir",
    "Preview": "Consulta",
    "cancelTime": "Hora de cancelación",
    "Total_assets_valuation": "Valor total de activos",
    "Invite Friends": "Invitar amigos",
    "RedGreen": "Rojo sube, verde baja",
    "GreenRed": "Verde sube, rojo baja",
    "Color Preference": "Preferencia de color",
    "Fuzzy Information": "Información difusa",
    "user_bind_xilain_trade_pwd": "Introduzca contraseña de comercio",
    "login_please_input1": "Introduzca\ncódigo de verificación",
    "user_please_input9": "Introduzca código de Google",
    "user_link_name": "Nombre de la cadena",
    "user_coin_bring_together": "Combinar",
    "chongbi0": "Instrucciones de depósito:",
    "chongbi1": "1. No deposite activos diferentes a {0}; cualquier no {1} no podrá recuperarse.",
    "chongbi2": "2. Envíe {1} a esta dirección. Después de {2} confirmaciones, los fondos se acreditarán.",
    "chongbi3": "3. Puede depositar múltiples veces a la misma dirección.",
    "chongbi4": "4. Monto mínimo de depósito {0}.",
    "currency_withdrawal_rate_rules": "{0} Reglas de tarifa de retiro:",
    "currency_withdrawal_rate_rules1": "1. Para seguridad, el sistema puede confirmar su retiro por teléfono.",
    "currency_withdrawal_rate_rules2": "2. {0} debe tener {1} confirmaciones antes de permitir retiro.",
    "currency_withdrawal_rate_rules3": "3. Monto a retirar ≤ Activos disponibles - Activos no confirmados;",
    "currency_withdrawal_rate_rules4": "4. Máximo de {0} retiros por día, con un máximo de {1}.",
    "currency_transaction_password": "Contraseña",
    "long_press_to_save_to_album": "Mantenga presionado para guardar en álbum",
    "the_fee_is_outside_the_range": "La tarifa está fuera del rango",
    "start_and_end_date": "Fechas de inicio y fin",
    "The_withdrawal_amount_cannot_be_less": "El monto de retiro no puede ser menor que {0}",
    "withdrawal_details": "Detalles de retiro",
    "RegisterRemove0": "Elimine el 0 del primer número al registrarse",
    "closeLockUpPopup": "Cancelar garantía generará {0} días de penalización, monto: “{1}{2}”，real: “{3}{4}”。",
    "LockUpDuan": "Minería en bloqueo",
    "NewTokenSubscribe": "Suscripción de nuevo token",
    "MoreWealthAwaitsYou": "Más riqueza te espera",
    "TopGainers": "Mejores aumentos",
    "Newest": "Nuevos tokens",
    "Hot": "Más populares",
    "DatChange": "Variación diaria",

    "SetPassword": "Configurar contraseña",
    "comm_password": "Contraseña de comercio",
    "unraise_recovering_password": "Recuperar contraseña, no se puede retirar en 1h",
    "PDleaseUseSafariBrowser": "Use el navegador Safari",
    "ADddToDesktop": "Agregar BitMatrix al escritorio",
    "CDlickBelow": "Haga clic a continuación",
    "SDelectAddToHomeScreen": "y seleccione agregar a la pantalla de inicio",
    "ADddToDesktop2": "Agregar BitMatrix al escritorio",
    "CDommonbrowserAddMethod": "Método de adición para navegadores comunes",
    "user_senior_text1": "Nota",
    "user_senior_text2": "El tamaño del archivo no debe exceder 5 MB. Formatos permitidos: jpg, bmp, png.",
    "user_senior_text3": "Asegúrese de que la imagen esté clara y sin marcas.",
    "user_senior_text4": "Sosteniendo",
    "user_senior_text5": "Foto del documento de identidad",
    "user_senior_text6": "Sostenga su",
    "user_senior_text7": "fecha de hoy",
    "user_senior_text8": "y",
    "user_senior_text9": "UID",
    "user_senior_text10": "en un papel sin reflejos.",
    "user_senior_text11": "Con la otra mano, sostenga un papel con el siguiente texto:",
    "user_senior_text12": "Escriba: “Estoy consciente de los riesgos de inversión”.",
    "user_senior_text13": "。",
    "user_ID_card": "Documento de identidad",
    "user_passport": "Pasaporte",
    "Driver_ID": "Licencia de conducir",
    "user_senior_upload3": "Subir documento + UID + fecha",
    "ReferencePrice": "Precio de referencia",
    "NewCoinsLaunch": "Lanzamiento de nuevos tokens",
    "CurrentlyPurchased": "Suscripción actual",
    "TotalMarketValue": "Valor total de mercado",
    "IEORatio": "Tasa de suscripción",
    "CurrencyIntroduction": "Introducción a la moneda",
    "ConvertNow": "Suscribirse ahora",
    "RemainingAmount": "Monto restante",
    "referenceTips": "Según el precio de compra real",
    "ServiceUpgrade": "Actualización de servicio",
    "LastDays7": "Últimos 7 días",
    "LastDays30": "Últimos 30 días",
    "LastDays90": "Últimos 90 días",
    "LockUpTips": "Nota: Activos no aprobados de {0}{1} han sido devueltos.",
    "PromotionalMining": "Minería promocional",

    "hide_coin": "Ocultar monedas < {0} BTC",
    "GoldAssets": "Activos en oro",
    "ModifyEmail": "Modificar correo",
    "NewEmail": "Nuevo correo",
    "NewEmailAddress": "Introduzca nueva dirección de \ncorreo",
    "VerifyNewEmail": "Verificar nuevo correo",
    "EnterNewEmailCode": "Introduzca\ncódigo de verificación",
    "VerifyOldEmail": "Verificar correo antiguo",
    "EnterOldEmailCode": "Introducir\ncódigo de verificación del correo antiguo",
    "VerifyLoginPassword": "Verificar contraseña de inicio de sesión",
    "EnterLoginPassword": "Introduzca la contraseña",
    "loan": "Centro de préstamos",
    "loanText": "Ofrecemos servicios de préstamo seguros y confiables para diversos propósitos, con mayor liquidez y sin necesidad de vender activos de criptomonedas.",
    "loanQuery": "Consulta de préstamo",
    "Stop_surplus_tips": "Cuando el precio del mercado llegue a {} se activará la orden de toma de ganancias, se espera una ganancia de {} ",
    "Stop_loss_tips": "Cuando el precio del mercado llegue a {} se activará la orden de stop loss, se espera una pérdida de {} ",
    "Stop_surplus_tips1": "Cuando el precio del mercado llegue a {} se activará la orden de toma de ganancias, se espera una pérdida de {} ",
    "Stop_loss_tips1": "Cuando el precio del mercado llegue a {} se activará la orden de stop loss, se espera una ganancia de {} ",
    "Countdown": "Cuenta regresiva",
    "contract_After_adjustment": "Después del ajuste, se transferirán {} USDT como margen adicional",
    "contract_subject_to": "(sujeto al monto transferido)",
    "contract_the_used_margin": "Después del ajuste, el margen usado disminuirá en {} USDT, el saldo restante se quedará en la posición",
    "contract_transferred_to": "Se puede transferir al saldo de la cuenta",
    "contract_After_adjustment_q": "Después del ajuste, el margen ocupado en la posición aumentará en {} USDT",
    "contract_the_used_margin_q": "Después del ajuste, el margen ocupado en la posición disminuirá en {} USDT",
    "Please1": "Por favor, introduzca el precio de activación de la toma de ganancias",
    "Please2": "Por favor, introduzca el precio de la orden de toma de ganancias",
    "Please3": "Por favor, introduzca el precio de activación del stop loss",
    "Please4": "Por favor, introduzca el precio de la orden de stop loss",
    "Stop profit6": "El precio de activación de la toma de ganancias debe ser mayor que el precio de la última transacción",
    "stop loss7": "El precio de activación del stop loss debe ser menor que el precio de la última transacción",
    "Please8": "Por favor, introduzca la cantidad",
    "Please9": "No hay cantidad para cerrar",
    "CountdownTime": "Tiempo de cuenta regresiva",
   "h1": "Solicitud de Socio",
    "h2": "Ranking de Reembolsos",
    "h3": "Conviértete en socio y obtén",
    "h4": "Condiciones de Ascenso",
    "h5": "Pago para socio inicial",
    "h6": "Saldo Actual",
    "h7": "Socio Fundador",
    "h8": "Reembolso",
    "h9": "Ascender Ahora",
    "h10": "Nivel Máximo Alcanzado",
    "h11": "Bonificación de Reembolso",
    "h12": "Ranking de Reembolsos",
    "h13": "Número en el Equipo",
    "h14": "Registro de Reembolsos",
    "h15": "Persona Invitada",
    "h16": "Liquidado",
    "h17": "No Liquidado",
    "h18": "Beneficios de Subordinados",
    "h19": "Pago Requerido",
    "h20": "Ascender",
    "h21": "Detalles del Reembolso",
    "h22": "Ranking",
    "h23": "Usuario",
    "h24": "Recompensas de Reembolso",
    "h25": "Nivel Actual de Ganancias",
    "h26": "Total de comisiones en USDT.",
    "h27": "Requiere {} usuarios normales",
    "h28": "Has referido {} usuarios válidos",
    "h29": "Se requiere un equipo de {} personas",
    "h30": "Equipo de {} personas",
    "Stop profit10": "La toma de ganancias debe ser menor que el último precio",
    "stop loss10": "El stop loss debe ser mayor que el último precio",
    "Earn": "Inversiones",
    "Simple": "Ganar y gastar sin problemas",
    "Low1": "Bajo riesgo, ingresos estables",
    "Low2": "Bajo riesgo, reembolso en cualquier momento",
    "CurrentAccount": "Cuenta de Ahorros",
    "Stable": "Crecimiento seguro",
    "EarnLocked": "Inversiones a Plazo",
    "Searchpopular": "Inversiones fáciles, ingresos inmediatos",
    "Annualized": "Tasa anualizada {}%, alto retorno",
    "CoinsLC": "Monedas",
    "PurchaseLC": "Monto de Compra",
    "PurchaseLC2": "({} mínimo)",
    "Maximum": "Máximo",
    "EstimatedInterest": "Interés Estimado",
    "RuleLC": "Reglas",
    "SubscriptionTime": "Tiempo de Suscripción",
    "Interestaccrualtime": "Tiempo de Intereses",
    "RevenueTime": "Tiempo de Ingresos",
    "RedemptionCycle": "Ciclo de Reembolso",
    "ReturnNow": "Regresar Ahora",
    "BuyLC": "Comprar",
    "PurchaseSuccessful": "Compra Exitosa",
    "DepositLC": "Depositar",
    "Yesterday": "Ingresos Ayer",
    "TotalLC": "Total Depositado",
    "TotalReturn": "Ingresos Totales",
    "DepositValuation": "Valor de Depósito",
    "DepositDetail": "Detalles del Depósito",
    "RevenueDetails": "Detalles de Ingresos",
    "DepositType": "Tipo de Depósito",
    "ReturnMethod": "Método de Reembolso",
    "DepositAmount": "Cantidad de Depósito",
    "APR": "Tasa Anual",
    "Expected": "Ingresos Esperados",
    "DepositCycle": "Ciclo de Depósito",
    "CreationTime": "Hora de Creación",
    "EarnProfit": "Interés Actual",
    "DepositCost": "Capital de Depósito",
    "TotalRelease": "Total Liberado",
    "ReleaseTime": "Hora de Liberación",
    "Flexible": "Ahorro Flexible",
    "Fixed": "Ahorro a Plazo",
    "Freeze": "Congelar",
    "Completed3": "Completado",
    "Cancelled": "Cancelado",
    "DefaultSettlement": "Liquidación por Incumplimiento",
    "PurchaseAmount": "Monto de Compra (sin límite)",
    "RetrieveDeposited": "Retirar Depositados",
    "PreviewConversion": "Vista Previa de Conversión",
    "ToEnsureThe": "Para asegurar la seguridad de su cuenta y cumplir con mayores límites, necesitamos verificación avanzada.",
    "ToVerification": "Haga clic para contactar al servicio al cliente.",
    "ToContact": "Contacte al servicio al cliente",
    contract_entrust_failure_0080 : 'El monto del pedido no debe ser inferior a 100 USDT.',
    "comm_nickname_rule1": "No puede exceder de  15 letras del alfabeto inglés.",
    "comm_nickname_rule2": "No puede contener espacios o caracteres especiales.",
    "otc_upload_picture3":"Formato imagen: jpeg/jpg/png/bmp",
    "CertificationDifficulties":'¿Hay un problema con la verificación',
}