<!-- home -->
<template>
  <div class="securityCenter container">
    <nav-bar class="nav_bar" :title="$t('M.security_center')" />

  <!-- <div id="commonhead" class="commonhead comheadmenu " style="display: block;">
      <img @click="back" class="commonhead-icon" src="../../assets/img/public/ic_back.png" /> 
      <span class="commonhead-tit">{{$t('M.security_center')}}</span>
      </div> -->
    <div class="main-content">
      <div class="top-functional">
        <van-cell class="item-cell top-cell">
          <!-- Use the title slot to customize the title -->
          <template #title>
            <div class="cell-level">
              <div class="level-bx">
                <span class="level-tit">{{ $t('M.security_level') }}</span>
                <span class="level-tip" v-bind:class="colorFont" v-if="securityNum == 1">{{ $t('M.k_min') }}</span>
                <span class="level-tip" v-bind:class="colorFont" v-if="securityNum == 2">{{ $t('M.centre') }}</span>
                <span class="level-tip" v-bind:class="colorFont" v-if="securityNum == 3">{{ $t('M.k_max') }}</span>
              </div>
              <div class="level-bx">
                <span v-bind:class="[securityNum >= 1 ? colorBg : '']" class="level-bar "></span>
                <span v-bind:class="[securityNum >= 2 ? colorBg : '']" class="level-bar mg-lft"></span>
                <span v-bind:class="[securityNum >= 3 ? colorBg : '']" class="level-bar mg-lft"></span>
              </div>
            </div>

          </template>
        </van-cell>
      <!-- 
        <van-cell class="item-cell" is-link>
          <template #title>
            <span class="item-title">{{$t("M.phone")}}</span>
            <span v-if="!isPhoneBind" @click="goSetPhone" class="item-custom-bx"><img class="ic-notice"
                src="../../assets/img/userCenter/ic_notice.png" />{{$t("M.setting")}}</span>
            <span v-if="isPhoneBind && isPhoneEnable " @click="goLookPhone" class="item-custom-bx">{{$t("M.open_it")}}</span>
            <span v-if="isPhoneBind && !isPhoneEnable " @click="goLookPhone" class="item-custom-bx">{{$t("M.close")}}</span>
          </template>
          </van-cell> -->

        <van-cell class="item-cell" is-link>
          <template #title>
            <span class="item-title">{{ $t("M.mailbox_new") }}</span>
            <span v-if="!isMailBind" @click="goSetEmail" class="item-custom-bx"><img class="ic-notice"
                src="../../assets/img/userCenter/ic_notice.png" />{{ $t("M.setting") }}</span>
            <span v-if="isMailBind && isMailEnable" @click="goLookEmail" class="item-custom-bx">{{ $t("M.open_it") }}</span>
            <span v-if="isMailBind && !isMailEnable" @click="goLookEmail" class="item-custom-bx">{{ $t("M.close") }}</span>
          </template>
        </van-cell>

      <!-- <van-cell class="item-cell" is-link>
          <template #title>
            <span class="item-title">谷歌验证</span>
            <span v-if="!isGoogleBind" class="item-custom-bx"><img class="ic-notice" src="../../assets/img/userCenter/ic_notice.png" />设置</span>
            <span v-if="isGoogleBind" class="item-custom-bx">开启</span>
          </template>
          </van-cell> -->
      </div>
      <div class="tip-txt">
        {{ $t('M.other_settings') }}
      </div>
      <!-- 登录密码 -->
      <div class="sec-functional">
        <van-cell class="item-cell" is-link>
          <template #title>
            <span class="item-title">{{ $t('M.login_pass') }}</span>
            <span @click="goSetPassword" class="item-custom-bx">{{ $t('M.set_Password') }}</span>
          </template>
        </van-cell>
        <van-cell class="item-cell" is-link>
          <template #title>
            <span class="item-title">{{ $t('M.mail') }}</span>
            <span @click="goChangeEmail" class="item-custom-bx">{{ $t('M.change1') }}</span>
          </template>
        </van-cell>
        <!-- 交易密码 -->
        <van-cell class="item-cell" is-link>
          <template #title>
            <span class="item-title">{{ $t('M.Set_transaction_password') }}</span>
            <router-link to="setExchangePwd" class="item-custom-bx">
              {{ user.payPassword ? $t('M.set_Password') : $t('M.setting') }}
            </router-link>
          </template>
        </van-cell>
        <!-- 免密设置 -->
        <van-cell class="item-cell" is-link>
          <template #title>
            <span class="item-title">{{ $t('M.avoid_close_set') }}</span>
            <span @click="noPsdSetting" class="item-custom-bx">
              {{ noPsdSettingText }}
            </span>
          </template>
        </van-cell>

      </div>
    </div>

    <Dialog :show="dialog.showDialog" :message="dialog.showMessage" :confirmButtonText="$t('M.confirm')"
      @closed="onClosed" @confirm="onConfirm" />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import navBar from '@/components/navBar';
import Dialog from '@/components/dialog';

export default {
  components: {
    navBar, Dialog
  },
  data() {
    return {
      isBankEnable: false,
      isAlipayEnable: false,
      isWeixinEnable: false,
      isXilianEnable: false,
      isPaypalEnable: false,
      isAlipayBind: false,
      isPaypalBind: false,
      isXilianBind: false,
      isWeixinBind: false,
      isBankBind: false,
      isGoogleBind: false,
      isGoogleEnable: false,
      isMailBind: false,
      isMailEnable: false,
      isPhoneBind: false,
      isPhoneEnable: false,
      payPassword: true,
      securityNum: 0,


      dialog: {
        showDialog: false,
        showMessage: this.$t('M.setting_paypassword'),
        path: 'setExchangePwd'
      }
    }
  },

  computed: {
    ...mapGetters([
      'user', "otherUser", 'userPayPassConfig'
    ]),
    noPsdSettingText() {
      if (this.userPayPassConfig.notInputPayPasswdTime == "never") return this.$t('M.never_enter')
      if (this.userPayPassConfig.notInputPayPasswdTime == "userset") return this.$t('M.time_the_input', [this.userPayPassConfig.partnerPayPasswdTime])
      if (this.userPayPassConfig.notInputPayPasswdTime == "everytime") return this.$t('M.each_input')
      return ''
    },
    colorFont: function () {
      return {
        levelTip1: this.securityNum == 1,
        levelTip2: this.securityNum == 2,
        levelTip3: this.securityNum == 3,
      }
    },
    colorBg: function () {
      return {
        selected1: this.securityNum == 1,
        selected2: this.securityNum == 2,
        selected3: this.securityNum == 3,
      }
    },
  },

  mounted() {
    this.isGoogleBind = this.user.googleAccount != '';
    this.isGoogleEnable = this.otherUser.isEnableGoogle;
    this.isMailBind = this.user.email != '';
    this.isMailEnable = this.otherUser.isEnableMail;
    this.isPhoneBind = this.user.phone != '';
    this.isPhoneEnable = this.otherUser.isEnablePhone;
    this.payPassword = this.user.payPassword != '';
    if (this.isGoogleBind) {
      this.securityNum++
    }
    if (this.isPhoneBind) {
      this.securityNum++
    }
    if (this.isMailBind) {
      this.securityNum++
    }

    this.getReceiptMethods()
    this.$store.dispatch('userPayPasswordSet')

  },

  methods: {
    goPhoneOption() {
      this.$router.push({ name: 'phoneOption' })
    },
    // gosetExchangePwd() {
    // if (this.payPassword) return
    // this.$router.push({ name: 'setExchangePwd' })
    // },
    goSetPhone() {
      this.$router.push({ name: 'setPhone' })
    },
    goSetEmail() {
      this.$router.push({ name: 'bindEmail' })
    },
    goLookPhone() {
      this.$router.push({ name: 'phoneContent' })
    },
    goLookEmail() {
      this.$router.push({ name: 'emailContent' })
    },
    goSetPassword() {
      this.$router.push({
        name: 'setPassword', query: { type: 2, }
      })
    },
    goChangeEmail() {
      this.$router.push({ name: 'changeEmail' })
    },


    getReceiptMethods() {
      this.securityNum = 1;
      this.$api.statusSecurityCenter().then((res) => {
        this.isGoogleBind = res.data.isGoogleBind;
        this.isGoogleEnable = res.data.isGoogleEnable == 'enable';
        this.isMailBind = res.data.isMailBind;
        this.isMailEnable = res.data.isMailEnable == 'enable';
        this.isPhoneBind = res.data.isPhoneBind;
        this.isPhoneEnable = res.data.isPhoneEnable == 'enable';
        this.payPassword = res.data.payPassword;

        if (this.isGoogleBind) {
          this.securityNum++
        }
        if (this.isPhoneBind) {
          this.securityNum++
        }
        if (this.isMailBind) {
          this.securityNum++
        }
      })
    },

    // 免密设置点击事件
    noPsdSetting() {
      if (!this.user.payPassword) {
        this.dialog.showDialog = true
        return
      }
      this.$router.push({ name: 'noPsdSetting' })
    },

    // dialog回调事件
    onClosed() {
      this.dialog.showDialog = false
      setTimeout(() => {
        this.dialog = {
          showDialog: false,
          showMessage: '',
        }
      }, 500);
    },
    onConfirm() {
      this.onClosed()
      setTimeout(() => {
        if (this.dialog.path) {
          this.$router.push(this.dialog.path)
        }
      }, 100);

    }



  }
}
</script>
<style lang="scss" scoped>
.securityCenter {
  min-height: 100vh;
  width: 100vw;
  background-color: #f8f8f8;

  .navbar {
    position: fixed;
    width: 100vw;
    background-color: #ffffff;
    // /deep/ .van-ellipsis{
    //   color: #ffffff !important;
    // }

    // /deep/ .van-icon {
    //   color: #ffffff !important;
    // }
  }

  .commonhead {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 44px;
    line-height: 44px;
    font-size: 2.8vw;
    background: #ffffff;
    color: #191b27;
    text-align: center;
    border-bottom: solid 1px #ebebf0;
  }

  .commonhead-icon {
    width: 10px;
    margin-top: 14px;
    margin-left: 20px;
    float: left;
  }

  .commonhead-tit {
    font-size: 4.2vw;
    margin-left: -10vw;
  }

  .commonhead-user {
    position: absolute;
    width: 6.8vw;
    top: 1.8vw;
    right: 28px;
  }

  .navbar-handle {
    width: 18px;
  }

  .van-hairline--bottom::after {
    border-bottom-width: 0rem;
  }

  .main-content {
    width: 100vw;
    height: 100vh;
    // padding: 30px;
    display: flex;
    display: -webkit-flex;
    /* Safari */
    flex-direction: column;
  }

  .top-functional {
    width: 92vw;
    // height: 178px;
    margin-left: 4vw;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .sec-functional {
    width: 92vw;
    // height: 108px;
    margin-left: 4vw;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .item-cell {
    height: 52.5px;
    background-color: transparent;
    padding: 14.5px;
    display: flex;
    align-items: center;

    .receipt-icon {
      width: 14px;
      height: 14px;
      margin-top: 5px;
    }

    .receipt-title {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    .receipt-content {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    .van-cell__title {
      display: flex;
      align-items: center;

      .item-title {
        color: #191b27;
        flex: 1;
      }

      .item-custom-bx {
        position: relative;
        // display: inline-block;
        // float: right;
        color: #9299a9;
        line-height: 1.2;
        text-align: right;
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }

    .ic-notice {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }

    .van-cell__value {
      color: #9299a9;
    }
  }

  .item-cell.top-cell {
    height: 70px;
  }

  .tip-txt {
    margin: 12px 15px;
    font-size: 12px;
    color: #5c6573;
  }

  .item-cell::after {
    border-bottom: 1.5px solid #ebebf0;
  }

  .cell-level {
    width: 100%;

    .level-bx {
      width: 100%;
    }

    .level-tit {
      display: inline-block;
      font-size: 15px;
    }

    .level-tip {
      display: inline-block;
      font-size: 12px;
      color: #00b897;
      float: right;
    }

    .levelTip1 {
      color: #00b897;
    }

    .levelTip2 {
      color: #e99c22;
    }

    .levelTip3 {
      color: #0AA869;
    }

    .level-bar {
      display: inline-block;
      width: 98.33px;
      height: 4px;
      background-color: #d5d5d5;
    }

    .selected {
      background-color: #00b897;
    }

    .selected1 {
      background-color: #00b897;
    }

    .selected2 {
      background-color: #e99c22;
    }

    .selected3 {
      background-color: #0AA869;
    }

    .mg-lft {
      margin-left: 10px;
    }
  }
}

// /deep/ {
//   /* 滚动条隐藏 */
//   ::-webkit-scrollbar {
//     width: 0px;
//   }
// }</style>
