<template>
  <div class="container">
    <nav-bar :title="$t('M.assets_coin_funds_flow')" />
    <van-sticky :offset-top="50">

      <div class="top">
        <div @click="showDatetime = !showDatetime">{{date? date : $t('M.start_and_end_date')}} <img src="@/assets/img/assets/icon_down.png" alt="">
        </div>
        <router-link :to="{path:'recharging',query:{ type: 'walletFlow'}}">
          {{$t('M.currency')}}:
          <span style="color:#00b897;margin-left:5px"> {{walletFlowCoin.coinName || $t('M.all')}}</span>
          <img src="@/assets/img/assets/icon_down.png" alt="">
        </router-link>
        <div @click="showType = !showType">
          {{$t('M.type')}}:
          <span style="color:#00b897;margin-left:5px">{{showTypevalue}}</span>
          <img src="@/assets/img/assets/icon_down.png" alt="">
        </div>

      </div>
    </van-sticky>

    <!-- 列表 -->
    <van-pull-refresh v-model="refreshing" :pulling-text="$t('M.MJRefreshHeaderIdleText')" :loosing-text="$t('M.MJRefreshHeaderPullingText')"
      :loading-text="$t('M.loading')" @refresh="onRefresh">
      <van-list class="list" v-model="loading" offset="10" :finished="finished" :loading-text="$t('M.loading')" @load="onLoad">
        <div v-if="refreshing" slot="loading"></div>

        <div class="item" v-for="(item,i) in list" :key="i">
          <div class="row">
            <div class="name"><span>{{item.coinName}}</span> <span>{{item.tradeDesc}}</span></div>
            <div class="num">{{item.totalChange>0? '+':''}}{{item.totalChange}}</div>
          </div>
          <div class="row">
            <div class="date">{{item.createTime}}</div>
            <div class="balance">{{$t('M.available_balance')}} {{item.total}}</div>
          </div>
        </div>

        <div class="noData" v-if="!list.length && finished">
          <img class="imgNoData" src="@/assets/img/public/nodata.png" alt="" srcset="" />
          <p class="text">{{$t('M.no_data')}}</p>
        </div>
      </van-list>
    </van-pull-refresh>

    <!-- 日期 -->
    <van-popup v-model="showDatetime" position="bottom" :overlay="true">
      <van-datetime-picker v-show="!statusStart" :title="$t('M.start_time')" :cancel-button-text="$t('M.Cancel')"
        :confirm-button-text="$t('M.affirm')" v-model="currentDateS" type="date" :min-date="minDate" :max-date="maxDate"
        @confirm="onDatetimeStartConfirm" @cancel="onDatetimeCancel" @change="onChange" />
      <van-datetime-picker v-show="statusStart" :title="$t('M.end_time')" v-model="currentDateE" type="date" :min-date="currentDateS"
        :max-date="maxDate" @confirm="onDatetimeEndConfirm" @cancel="onDatetimeCancel" @change="onChange" :cancel-button-text="$t('M.Cancel')"
        :confirm-button-text="$t('M.affirm')" />
    </van-popup>
    <!-- 币种 -->
    <van-action-sheet v-model="showCoin" :actions="actionsCoin" :cancel-text="$t('M.Cancel')" @select="onSelectCoin" @cancel="onCancelCoin" />
    <!-- 类型 -->
    <van-action-sheet v-model="showType" :actions="actions" :cancel-text="$t('M.Cancel')" @select="onSelect" @cancel="onCancel" />
  </div>
</template>

<script>
import navBar from '@/components/navBar';
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'bbCapitalFlow',
  components: {
    navBar
  },
  computed: {
    ...mapGetters([
      'walletFlowCoin'
    ]),
    ...mapMutations(['SET_WALLET_COIN'])
  },
  data() {
    return {
      query: this.$route.query,

      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      finishedText: this.$t('M.no_more_data'),

      queryParams: {
        pageSize: 10,
        endTime: '',
        startTime: '',
        type: ''
      },
      pageNum: 1,

      showDatetime: false,

      date: '',
      statusStart: false,

      currentDateS: new Date(),
      currentDateE: new Date(),
      minDate: this.getMonth(),
      maxDate: new Date(),

      showCoin: false,
      showCoinvalue: '',
      actionsCoin: [
        {
          name: this.$t('M.all'),
          id: '0'
        },
        {
          name: 'TTT',
          id: '1'
        },
        {
          name: 'EEE',
          id: '2'
        },


      ],

      showType: false,
      showTypevalue: this.$t('M.all'),
      actions: [],

    };
  },
  watch: {
    queryParams: {
      handler() {
        this.refreshing = false;
        this.pageNum = 1
        this.onLoad()
      },
      deep: true
    },
    walletFlowCoin: {
      handler() {
        this.refreshing = false;
        this.pageNum = 1
        this.onLoad()
      },
      deep: true
    }
  },
  beforeMount() {
    this.getWalletTradeType()

  },

  mounted() {

  },

  methods: {

    // 全部类型
    getWalletTradeType() {
      this.$api.bbLogType().then((response) => {
        if (!response.meta.success) return
        this.actions = response.data.i18nCodes.map((item, i) => ({ id: i + 1, name: item.i18nValue, i18nCode: item.i18nCode, color: '' }))
        this.actions.unshift({
          id: 0,
          i18nCode: "",
          name: this.$t('M.all'),
          color: '#00b897'
        })
      })

    },


    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.pageNum = 1
          this.list = [];
          this.refreshing = false;
        }
        this.getList()
      }, 500);
    },


    onRefresh() {

      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },

    getList() {
      const params = { ...this.queryParams, pageNum: this.pageNum, coinId: this.walletFlowCoin.coinId }
      this.$api.bbLog(params).then((response) => {
        if (!response.meta.success) return
        const { list, total } = response.data
        this.loading = false;
        if (this.pageNum == 1) {
          this.list = list
        } else {
          this.list = this.list.concat(list)
        }
        this.pageNum += 1
        if (this.list.length >= total) {
          this.finished = true;
        }

      })
    },



    // 日期
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onDatetimeCancel() {
      this.showDatetime = false
      this.statusStart = false

    },
    onDatetimeStartConfirm(e) {
      // this.queryParams.startTime = this.parseTime(e)
      this.showDatetime = false
      setTimeout(() => {
        this.showDatetime = true
        this.statusStart = true
      }, 500);

    },

    onDatetimeEndConfirm(e) {
      const arr = this.parseTime(e).trim().split(/\s+/)
      arr[arr.length - 1] = '23:59:59'
      const newValue = arr.join(' ')
      this.queryParams.startTime = this.parseTime(this.currentDateS)
      this.queryParams.endTime = this.parseTime(newValue)
      this.showDatetime = false
      this.statusStart = false
      this.date = `${this.formatDate(this.currentDateS)} - ${this.formatDate(this.currentDateE)}`;
    },
    onChange() { // 重置日期滑动函数

    },



    // 币种
    onSelectCoin(item) {
      this.showCoin = false;
      this.showCoinvalue = item.name
    },
    onCancelCoin() {

      this.showCoin = false;
    },

    // 类型
    onSelect(item) {
      this.onCancel()
      this.showTypevalue = item.name
      this.queryParams.type = item.i18nCode
      this.actions.forEach(el => {
        el.color = ''
        if (el.id == item.id) {
          el.color = '#00b897'
        }
      });
    },



    onCancel() { this.showType = false; },



  },
  destroyed() {
    this.$store.commit('SET_WALLET_COIN', {})
  }

};
</script>

<style lang="scss" scoped>
.container {
  font-size: 14px;
  height: 100%;
  overflow-y: scroll;

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: 400;
    color: #5c6573;
    background: #fff;
    > div,
    > a {
      display: flex;
      align-items: center;
    }
    img {
      width: 10px;
      height: 5px;
      margin-left: 2px;
    }
  }
  .list {
    padding: 15px;
    .item {
      width: 100%;
      min-height: 69px;
      background: #f8f8fb;
      border-radius: 3px;
      margin-bottom: 10px;
      padding: 10px;
      .row {
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
          display: flex;
          align-items: center;
          > span:first-child {
            font-size: 16px;
            color: #191b27;
            font-weight: 600;
            margin-right: 10px;
          }
          > span:last-child {
            flex: 1;
            // width: 200px;
            font-size: 12px;
            color: #00b897;
          }
        }
        .num {
          font-size: 18px;
          color: #191b27;
          // letter-spacing: 1px;
          text-align: right;
        }
        .date,
        .balance {
          font-size: 12px;
          color: #9299a9;
        }
      }
      .row:last-child {
        > div {
          flex: 1;
        }
        > div:last-child {
          text-align: right;
        }
      }
    }
  }
}
</style>
