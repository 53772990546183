<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-18 17:49:45
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="IEOlog container">
    <van-sticky>
      <van-nav-bar :title="$t('M.Records')" left-text="" left-arrow @click-left="onClickLeft" />
      <div class="dateBox" @click="showDatetime = !showDatetime">
        <img src="../../assets/img/IEO/time2.png" alt="">
        <div class="time">
          {{startTime}}
        </div>
        <div class="zhi">
          {{$t('M.To')}}
        </div>
        <div class="time">
          {{endTime}}
        </div>

        <div class="select"> {{$t('M.Select')}}</div>
      </div>
    </van-sticky>
    <van-pull-refresh v-model="pullLoading" @refresh="onPull" :pulling-text="$t('M.MJRefreshHeaderIdleText')"
      :loosing-text="$t('M.MJRefreshHeaderPullingText')" :loading-text="$t('M.loading')" style="min-height:50%;">
      <van-list v-model="listLoading" :finished="finished" finished-text="" @load="loadList" loading-text="loading...">
        <div class="listBox">
          <div class="listItem" v-for="(item,idx) in logList" :key="idx">
            <div class="itemLine title">
              <div class="left">{{item.projectName}}</div>
              <div class="right" v-if="item.checkFlag == '0'"> {{$t('M.Not Reviewed')}}</div>
              <div class="right" v-if="item.checkFlag == '1'" style="color:#00b897;">{{$t('M.Review Passed')}}</div>
              <div class="right" v-if="item.checkFlag == '2'">{{$t('M.Review Failed')}}</div>
            </div>
            <div class="itemLine">
              <div class="left"><span>{{$t('M.Active Currency')}}:</span>{{item.coinName}}</div>
              <div class="right"><span>{{$t('M.price')}}:</span>1{{item.coinName}}={{item.price}}{{item.priceCoinName}}</div>
            </div>
            <div class="itemLine">
              <div class="left"><span>{{$t('M.quantity')}}:</span>{{item.coinNum}}{{item.coinName}}</div>
              <div class="right"><span>{{$t('M.Eligible Amount')}}:</span>{{item.checkPassNum}}{{item.coinName}}</div>
            </div>
            <div class="itemLine">
              <span>{{$t('M.money')}}：</span>{{item.priceCoinNum}}{{item.priceCoinName}}
              <van-popover v-model="item.moneyActionsPopover" trigger="click" :actions="item.moneyActions" placement="top"
                v-if="item.checkFlag == '2'">
                <template #reference>
                  <img src="../../assets/img/IEO/why.png" alt="" srcset="" style="width:14px;">
                </template>
              </van-popover>
            </div>
            <div class="itemLine">
              <span>{{$t('M.Eligible Price')}}:</span>{{item.priceCheckPassNum	}}{{item.priceCoinName}}
            </div>
            <div class="itemLine">
              <span>{{$t('M.Conversion Time')}}:</span>{{item.createTime}}
            </div>
            <div class="itemLine">
              <span>{{$t('M.Review Time')}}:</span>{{item.checkTime ? item.checkTime : $t('M.Not Reviewed')}}
            </div>
            <div class="itemLine">
              <span>{{$t('M.Arrival Time')}}:</span>{{item.accountTime ? item.accountTime :  $t('M.Not Arrived')}}
              <van-popover v-model="item.accountActionsPopover" trigger="click" :actions="item.accountActions" placement="top"
                v-if="item.checkFlag == 1">
                <template #reference>
                  <img src="../../assets/img/IEO/why.png" alt="" srcset="" style="width:14px;">
                </template>
              </van-popover>
            </div>
            <div class="itemLine err" v-if="item.checkFlag == '2'">
              {{$t('M.Denial Reason')}}:{{item.refuseReason}}
            </div>
            <div class="line"></div>
          </div>
        </div>
      </van-list>
      <div class="noData" v-if="logList.length == 0 && finished == true && pullLoading == false">
        <img class="imgNoData" src="../../assets/img/public/nodata.png" alt="" srcset="" />
        <p class="text">{{$t('M.no_data')}}</p>
      </div>
    </van-pull-refresh>

    <!-- 日期 -->
    <van-popup v-model="showDatetime" position="bottom" :overlay="true">
      <van-datetime-picker v-show="!statusStart" :title="$t('M.start_time')" :cancel-button-text="$t('M.Cancel')"
        :confirm-button-text="$t('M.affirm')" v-model="currentDateS" type="date" @confirm="onDatetimeStartConfirm" @cancel="onDatetimeCancel" />
      <van-datetime-picker v-show="statusStart" :title="$t('M.end_time')" v-model="currentDateE" type="date" :min-date="currentDateS"
        @confirm="onDatetimeEndConfirm" @cancel="onDatetimeCancel" :cancel-button-text="$t('M.Cancel')" :confirm-button-text="$t('M.affirm')" />
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      currentPage: 0,
      createStartTime: '',
      createEndTime: '',
      pullLoading: false,
      listLoading: false,
      finished: false,
      logList: [],
      showPopover: false,
      actions: [{text: this.$t('M.From wallet assets')}],
      showDatetime: false,
      statusStart: false,
      currentDateS: this.$moment().add(-10, 'd').toDate(),
      currentDateE: this.$moment().toDate(),
      maxDate: this.$moment().toDate(),
      startTime: this.$moment().add(-10, 'd').format("YYYY-MM-DD"),
      endTime: this.$moment().format('YYYY-MM-DD'),
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
  },
  mounted() {
    // this.getlog()
  },
  methods: {
    onDatetimeStartConfirm(e) {
      this.showDatetime = false
      setTimeout(() => {
        this.showDatetime = true
        this.statusStart = true
      }, 500);
    },
    onDatetimeCancel() {
      this.showDatetime = false
      this.statusStart = false
    },
    onDatetimeEndConfirm(e) {
      this.showDatetime = false
      this.startTime = this.$moment(this.currentDateS).format('YYYY-MM-DD')
      this.endTime = this.$moment(this.currentDateE).format('YYYY-MM-DD')
      this.statusStart = false
      this.logList = []
      this.currentPage = 1;
      this.getlog()
    },
    /* 列表组件加载 */
    loadList() {
      this.currentPage++;
      this.getlog()
    },
    /* 下拉 */
    onPull() {
      this.logList = []
      this.currentPage = 1;
      this.getlog()
    },
    getlog() {

      let sendData = {
        partnerId: '472437831826935808',
        createStartTime: this.$moment(this.startTime).format('YYYY-MM-DD HH:mm:ss'),
        createEndTime: this.$moment(this.endTime).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        pageSize: 10,
        currentPage: this.currentPage,
      }

      this.$api.pageListOrder(sendData).then((res) => {
        if (res.data.list.length < 10) {
          this.finished = true;
        }
        for (let index = 0; index < res.data.list.length; index++) {
          res.data.list[index].accountActionsPopover = false;
          res.data.list[index].accountActions = [{ text: this.$t('M.If not received, please contact service') }];
          res.data.list[index].moneyActionsPopover = false;
          res.data.list[index].moneyActions = [{ text: this.$t('M.Returned to wallet') }];
        }
        this.logList = this.logList.concat(res.data.list);
        console.log(this.logList)
        this.listLoading = false;
        this.pullLoading = false;
      })

    },
    copyCode() {

    },
    onClickLeft() {
      this.$router.replace("IEO")
    }
  }
}
</script>

<style lang="scss" scoped>
.IEOlog {
  font-size: 12px;
  background-color: #fff;
  .dateBox {
    width: 100%;
    height: 50px;
    background-color: #f8f8fb;
    display: flex;
    align-items: center;
    padding: 0 15px;
    position: relative;
    img {
      width: 13px;
      height: 13px;
      margin-right: 10px;
    }
    .time {
      color: #9299a9;
    }
    .zhi {
      margin: 0 15px;
    }
    .select {
      position: absolute;
      right: 15px;
      top: 10px;
      width: auto;
      height: 30px;
      color: #fff;
      background: #00b897;
      padding: 0 12px;
      line-height: 30px;
      text-align: center;
      border-radius: 4px;
    }
  }
  .listBox {
    padding: 12px 15px;
    background-color: #fff;
    .listItem {
      color: #000;
      font-size: 13px;
      line-height: 29px;
      .itemLine {
        display: flex;
        span {
          color: #9299a9;
        }
        .left {
          flex: 0.9;
        }
        .right {
          flex: 1;
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background-color: #ebebf0;
      }
      .err {
        color: #00b897;
      }
      .tips {
        color: #00b897;
      }
      .title {
        justify-content: space-between;
        .left {
          font-weight: 600;
        }
        .right {
          color: #5c6573;
          text-align: right;
        }
      }
    }
  }
}

::v-deep .van-popover__wrapper {
  display: flex;
  align-items: center;
  margin-left: 5px;
  img {
    height: 14px;
    width: 14px;
  }
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}
</style>