<template>
  <div class="home">
    <div class="log">
      <router-link class="right" to="flashCashRecords">
        {{ $t('M.exchange_history') }}
      </router-link>
    </div>
    <div v-if="!detailVoList.length && finished === true" class="noData">
      <img alt="" class="imgNoData" src="@/assets/img/public/nodata.png" srcset=""/>
      <p class="text">{{ $t('M.no_data') }}</p>
    </div>

    <div v-else class="changBox">
      <div class="changItemBox bottomBorder">
        <div class="topBox">
          <div class="itemTitle"> {{ $t('M.assets_transfer_dialog_from') }}</div>
          <div class="balance">
            {{ $t('M.available_balance') }}: <span
              v-if="activeFromDate"> {{ activeFromDate.total }} {{ activeFromDate.exchangeCoinName }}</span> <span
              v-else>--</span>
          </div>
        </div>
        <div v-if="activeFromDate" class="bottomBox">
          <div class="available">
            <van-field v-model="conversionAmount" label="" size="small" type="number" @input="handleInput"/>
          </div>
          <div class="maxNumber" @click="allIn">{{ $t('M.all') }}</div>
          <div class="selectFromMoney">
            <div class="selectFormBtn" @click="actionFormShow = true">
              {{ activeFromDate.exchangeCoinName }}
              <img alt="" class="jiantou" src="../../assets/img/register/jiantou.png"/>
            </div>
          </div>
        </div>

        <div v-else class="bottomBox">
          <div class="available">
            --
          </div>
          <div class="selectFromMoney">
            --
          </div>
        </div>

      </div>
      <div class="changItemBox bottomBorder">
        <div class="topBox">
          <div class="itemTitle">{{ $t('M.assets_transfer_to') }}</div>

        </div>
        <div v-if="activeToDate" class="bottomBox">
          <van-loading v-if="loadingFlag" color="#FFB800" size="20"/>
          <div class="available">
            {{ activeToDate.targetAmount }}
          </div>
          <div class="selectFromMoney">
            <div class="selectFormBtn" @click="actionToShow = true">
              {{ activeToDate.targetCoinName }}
              <img alt="" class="jiantou" src="../../assets/img/register/jiantou.png"/>
            </div>
          </div>
        </div>

        <div v-else class="bottomBox">
          <div class="available">
            --
          </div>
          <div class="selectFromMoney">
            --
          </div>
        </div>
      </div>
      <div class="changItemBox">
        <router-link v-if="!isLogin" class="loginBtn" to="login">{{ $t("M.log_in") }}</router-link>
        <div v-else class="loginBtn" @click="onShowDialog">
          {{ $t('M.PreviewConversion') }}
        </div>
      </div>
    </div>


    <div class="desc">
      {{ $t('M.small_exchange_tip-1', ["0.2"]) }}
    </div>

    <!--动作面板 选项- from-->
    <van-action-sheet
        v-model="actionFormShow"
        :actions="detailVoList"
        :cancel-text="$t('M.Cancel')"
        close-on-click-action
        @select="selectFromItem"
    />
    <!--动作面板 选项- to-->
    <van-action-sheet
        v-model="actionToShow"
        :actions="toActions"
        :cancel-text="$t('M.Cancel')"
        close-on-click-action
        @select="selectToItem"
    />

    <Dialog :confirmButtonText="$t('M.exchange')" :show="showDialog" :title="$t('M.exchange_details')"
            @closed="onClosed"
            @confirm="onConfirm">
      <div slot="content" class="contentDialog">
        <div class="cell">
          <span>{{ $t('M.total_exchange') }} </span>
          <span>{{ targetCount }} {{ targetCoinName }}</span>
        </div>
        <div class="cell">
          <span>{{ $t('M.free') }} </span>
          <span>{{ cutOutPointLength(targetCount * exchangeRate / 100, 8) }} {{ targetCoinName }}</span>
        </div>
        <div class="cell">
          <span>{{ $t('M.actual_exchange_amount') }} </span>
          <span>{{ cutOutPointLength(targetCount - cutOutPointLength(targetCount * exchangeRate / 100, 8), 8) }}
            {{ targetCoinName }}</span>
        </div>
      </div>
    </Dialog>

    <popup-password :show="show" type="flashCash" @onClose="onClosedPass" @onSubmit="onSubmitPass"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {Toast} from 'vant';
import Dialog from "@/components/dialog/index.vue";
import popupPassword from '@/components/popupPassword';
import {cutOutPointLength} from "../../utils/util";

export default {
  components: {popupPassword, Dialog},
  data() {
    return {
      detailVoList: [],
      activeType: 2,
      exchangeRate: 7.2,
      showPopover: false,
      show: false,
      radio: '1',
      target: {},
      ids: [],
      select: [],
      showDialog: false,
      showOverlay: false,
      finished: false,
      targetSum: 0,
      isInputPayPasswd: false,
      actions: [],
      maxNum: null,
      targetCount: 0,
      seletData: [],
      actionFormShow: false,
      actionToShow: false,
      formActions: [],
      toActions: [],
      activeFromDate: null,
      conversionAmount: 0,
      activeToDate: null,
      dialogData: null,
      loadingFlag: false,
      canGetApi: true,
      timeObject: null,
    };
  },
  mounted() {
    if (this.isLogin) {
      this.getWaitExchangeResult()
    }
  },
  computed: {
    ...mapGetters([
      'user', 'isLogin'
    ]),
    targetCoinName() {
      if (this.activeToDate && this.activeToDate.targetCoinName) return this.activeToDate.targetCoinName
      return ''
    }
  },
  watch: {
    detailVoList: {
      handler(n) {
        if (n.length) {
          this.activeFromDate = n[0]
        }
      },
      deep: true
    },
    activeFromDate: {
      handler(n) {
        this.conversionAmount = n.total
        if (n.targetVoList.length) {
          this.activeToDate = n.targetVoList.find(obj => obj.targetCoinId !== this.activeFromDate.exchangeCoinId);
          this.toActions = n.targetVoList
        }
      },
      deep: true
    }
  },
  methods: {
    cutOutPointLength,
    allIn() {
      this.conversionAmount = this.activeFromDate.total
      this.fetchData()
    },
    onClosedPass() {
      this.show = false
    },
    onSubmitPass(bolb) {
      this.onClosedPass()
      if (bolb.payCode) {
        this.exchange(bolb.payCode)
      }
    },
    onClosed() {
      this.showDialog = false
    },
    onConfirm() {
      this.onClosed()
      this.getIsInputPayPasswd()
    },
    getIsInputPayPasswd() {
      this.$api.isInputPayPasswd().then((response) => {
        if (!response.meta.success) return
        this.isInputPayPasswd = response.data
        if (this.isInputPayPasswd) {
          this.show = true
        } else {
          this.exchange('1234')
        }
      })
    },
    // 确认闪兑
    exchange(password) {
      this.showOverlay = true
      setTimeout(() => {
        this.showOverlay = false
      }, 1500);
      let list = []
      this.seletData.forEach((item) => {
        list.push(item.coinId + '@' + item.amount)
      })
      const params = {
        targetCoinId: this.activeToDate.targetCoinId,
        password: password,
        coinIds: `${this.activeFromDate.exchangeCoinId}`,
        list: `${this.activeFromDate.exchangeCoinId}@${this.conversionAmount}`
      }

      this.$api.exchange(params).then((response) => {
        if (!response.data) return
        if (!response.meta.success) return
        this.$notify(response.meta.message)
        setTimeout(() => {
          this.getWaitExchangeResult()
        }, 500);
      })
    },
    getCount() {
      let arr = [{
        coinId: this.activeFromDate.exchangeCoinId,
        amount: parseFloat(this.conversionAmount),
      }]
      this.$api.queryExchangeResult(arr).then((res) => {
        let sum = 0
        res.data.detailVoList.forEach(item => {
          item.targetVoList.forEach(el => {
            if (el.targetCoinId == this.activeToDate.targetCoinId) {
              sum += Number(el.targetAmount)
            }
          })
        })
        this.targetCount = this.cutOutPointLength(sum, 8)
      })
    },
    onShowDialog() {
      this.getCount()
      this.showDialog = true
    },
    handleInput(event) {
      const inputValue = event;
      // 使用正则表达式验证输入是否合法
      const regex = /^\d*\.?\d*$/; // 只能包含数字和小数点
      if (!regex.test(inputValue)) {
        this.conversionAmount = '';
      } else if (inputValue.startsWith('-')) {
        this.conversionAmount = '';
      } else {
        if (inputValue > parseFloat(this.activeFromDate.total)) {
          this.conversionAmount = this.activeFromDate.total;
        } else {
          this.conversionAmount = inputValue;
        }

        this.fetchData()
      }
    },
    fetchData() {
      clearTimeout(this.timeObject)
      this.timeObject = setTimeout(() => {
        if (this.conversionAmount > 0) {
          this.loadingFlag = true
          let arr = [{
            coinId: this.activeFromDate.exchangeCoinId,
            amount: parseFloat(this.conversionAmount),
          }]
          this.$api.queryExchangeResult(arr).then((res) => {
            this.loadingFlag = false
            let targetVoList = res.data.detailVoList[0].targetVoList
            targetVoList.forEach(item => {
              item.text = item.targetCoinName
              item.name = item.targetCoinName
            })
            this.toActions = targetVoList
            this.activeToDate = res.data.detailVoList[0].targetVoList.find(obj => obj.targetCoinId === this.activeToDate.targetCoinId)
          })

        }
      }, 500)
    },
    selectFromItem(action) {
      this.activeFromDate = action;
    },
    selectToItem(action) {
      this.activeToDate = action
    },
    // 获取闪兑信息
    getWaitExchangeResult() {
      Toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      });
      this.$api.getWaitExchangeResult().then((response) => {
        this.finished = true
        Toast.clear();
        if (!response.meta.success) return
        const {detailVoList, exchangeRate} = response.data
        this.exchangeRate = exchangeRate
        if (!detailVoList.length) {
          this.detailVoList = []
          return
        }
        this.maxNum = null

        detailVoList.forEach(element => {
          element.name = element.exchangeCoinName;
          element.targetVoList.forEach(item => {
            item.text = item.targetCoinName
            item.name = item.targetCoinName
            if (item.targetCoinName === 'USDT') {
              this.target = item
            }
          })
        });
        this.detailVoList = detailVoList
        //
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  color: #888888;

  .log {
    flex-direction: row-reverse;
    display: flex;
    margin-top: 14px;

    .right {
      color: #888888;

    }
  }

  .changBox {
    border: 1px solid #D1D4DD;
    border-radius: 6px 6px 6px 6px;
    margin-top: 14px;

    .changItemBox {
      height: 100px;
      padding: 15px;

      .topBox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .itemTitle {
          font-size: 15px;
          color: #000000;
          line-height: 21px;
        }

        .balance {
          font-size: 12px;
          color: #888888;
          line-height: 21px;
        }
      }

      .bottomBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        color: #000;
        font-size: 14px;

        .available {
          flex: 1;
        }

        .maxNumber {
          padding-right: 14px;
          border-right: 1px solid #EBEBF0;

        }

        .selectFromMoney {
          display: flex;
          align-items: center;
          padding-left: 15px;

          .selectFormBtn {
            display: flex;
            align-items: center;

            .jiantou {
              width: 10px;
              margin-left: 4px;
            }
          }
        }
      }

      .loginBtn {
        display: block;
        width: 315px;
        height: 40px;
        background: #00b897;
        border-radius: 6px 6px 6px 6px;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        margin-top: 14px;
      }

    }

    .bottomBorder {
      border-bottom: 1px solid #D1D4DD;
    }

  }

  .desc {
    margin-top: 20px;
  }
}

.contentDialog {
  padding: 15px;

  .cell {
    height: 40px;
    font-size: 13px;
    font-weight: 400;
    color: #131520;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}


.van-cell {
  padding: 0;
}
</style>
