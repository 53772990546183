<template>
  <van-popup class="popup_transfer" v-model="showTransfer" position="bottom" :style="{ height: '435px' }" round safe-area-inset-bottom
    :close-on-click-overlay="false" @click-overlay="onClose" @click-close-icon="onClose">

    <div class="title">{{$t('M.assets_transfer_currency')}}</div>
    <div class="icon_close" @click="onClose">
      <img src="@/assets/img/assets/icon_close.png" alt="">
    </div>

    <div class="tit">{{$t('M.assets_transfer_coin')}}</div>
    <div class="transfer_coin">
      <img :src="tradeData.logo" alt="">
      <span>{{tradeData.coinName}}</span>
    </div>

    <div class="tit">{{$t('M.assets_swipe_direction')}}</div>
    <div class="transfer_direction">
      <div class="transfer_cell">
        <span>{{$t('M.assets_transfer_dialog_from')}}</span>&nbsp;
        <span class="fromAccount"> {{accountOptions[fromAccount]}}</span>&nbsp;
        <span>{{$t('M.assets_transfer_to')}}</span>&nbsp;
        <span class="toAccount">{{accountOptions[toAccount]}}</span>
      </div>
      <div class="button" @click="onClickChange">
        <img src="@/assets/img/assets/icon_huaz2.png" alt="">
      </div>
    </div>

    <div class="tit">{{$t('M.assets_transfer_count')}}</div>
    <div class="transfer_num">
      <van-field v-model="totalStr" center :placeholder="$t('M.input_quantity')" :formatter="formatter">
        <div class="button" slot="button" @click="onClickAll">{{$t('M.all')}}</div>
      </van-field>
    </div>

    <div class="desc_num">{{$t('M.assets_can_transfer')}}{{tradeData.coinName}} {{total}}</div>
    <div class="sub_btn" @click="onConfirm">{{$t('M.affirm')}}</div>

  </van-popup>
</template>

<script>

import { mapGetters } from 'vuex'

import { debounce } from '@/utils/util'
import {Toast} from "vant";
export default {

  props: {
    showTransfer: {
      type: Boolean,
      default: false
    },
    coinId: {
      type: String,
      default: '473153241685688320'
    },
    account: { // 接收钱包划转的账户，默认秒合约账户
      type: String,
      default: 'OPTION_NEW'
    }

  },
  data() {

    return {
      totalStr: '',
      fromAccount: 'ACCOUNT',
      toAccount: '', // 动态接收
      // type: '',// ACCOUNT_TO_CONTRACT:钱包划转至合约   
      total: 0,
      tradeData: {},
      accountOptions: {
        ACCOUNT: this.$t('M.assets_transfer_dialog_wallet'),
        CONTRACT: this.$t('M.Sustainable account'),
        OPTION_NEW: this.$t('M.Sec Swap Account'),
        BB: this.$t('M.assets_transfer_dialog_bb'),
        OPTION: this.$t('M.Option Account'),
        OTC: this.$t('M.OTC account'),
      },
      // changeState: true,
      clickFlag: true

    };
  },
  computed: {
    ...mapGetters([
      'rate', 'partnerId', 'newOptionData', 'coinList'
    ]),

    type() {
      return this.fromAccount + '_TO' + '_' + this.toAccount
    }

  },
  watch: {
    showTransfer(n) {
      if (n) {
        this.$store.dispatch('getUserWalletAssets')
        this.getTransferCoin(this.coinId)
        const list = JSON.parse(JSON.stringify(this.coinList))
        const res = list.filter(item => item.coinId == this.coinId * 1)
        this.tradeData = res && res[0] || {}

      }
    },
    account: {
      handler(value) {
        this.toAccount = JSON.parse(JSON.stringify(value))
      },
      immediate: true
    }
  },
  mounted() {

  },

  methods: {

    onClickAll() {
      if (this.total >= 0) {
        this.totalStr = this.total
      }
    },

    onClickChange: debounce(async function () {
      if (this.toAccount === "CONTRACT") {
        Toast.loading({
          forbidClick: true,
          duration: 0,
        });
        const res = await this.$api.currentPosition({positionsType: ''})
        Toast.clear();
        if (res.data.list.length > 0) {
          this.$notify(this.$t("M.TransferRequiresClosing"))
          return
        }
      }

      if (!this.clickFlag) return;
      this.clickFlag = false;
      // this.changeState = !this.changeState;
      // if (this.changeState) {
      //   this.fromAccount = 'ACCOUNT'
      //   this.toAccount = 'OPTION_NEW'
      // } else {
      //   this.fromAccount = 'OPTION_NEW'
      //   this.toAccount = 'ACCOUNT'
      // }
      this.fromAccount = [this.toAccount, this.toAccount = this.fromAccount][0]; // 交换变量值
      this.totalStr = ''
      this.getTransferCoin()
    }),



    onClose() {
      this.totalStr = ''
      this.fromAccount = 'ACCOUNT'
      this.toAccount = this.account
      this.$emit('onClose')
    },
    onConfirm() {
      // let type = ''
      // if (this.changeState) {
      //   type = 'ACCOUNT_TO_OPTION_NEW'
      // } else {
      //   type = 'OPTION_NEW_TO_ACCOUNT'
      // }
      const params = {
        type: this.type,
        totalStr: this.totalStr,
        coinId: this.coinId,
        partnerId: this.partnerId
      }
      if (!this.totalStr || this.totalStr <= 0) return this.$notify(this.$t("M.account_failure_00103"))
      if (this.totalStr * 1 > this.total * 1) return this.$notify(this.$t("M.inputs_numbers_greater_can_number"))
      this.$api.accountTransfer(params).then((response) => {
        if (response.meta.success) {
          this.$notify(response.meta.message)
          this.$emit('onConfirm')
          this.totalStr = ''
          this.fromAccount = 'ACCOUNT'
          this.toAccount = this.account
          this.$store.dispatch('getUserOptionFinanceList')
        }
      })
    },

    getTransferCoin() {
      this.clickFlag = true;
      this.$api.transferCoin(this.coinId, this.fromAccount, this.partnerId).then((response) => {
        if (!response.meta.success) return
        const { total } = response.data
        this.total = total
      })

    }

  },
};
</script>

<style lang="scss" scoped>
.popup_transfer {
  padding: 0 15px;
  font-size: 14px;
  .title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    color: #191b27;
    letter-spacing: 1px;
  }
  .tit {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #191b27;
  }
  .transfer_coin,
  .transfer_num,
  .transfer_direction {
    width: 100%;
    height: 43px;
    line-height: 43px;
    border-radius: 4px;
    overflow: hidden;
    .button {
      width: 53px;
      height: 43px;
      line-height: 43px;
      text-align: center;
      background: #00b897;
      border-radius: 0px 4px 4px 0px;
      font-size: 15px;
      color: #ffffff;
      img {
        width: 100%;
        height: 100%;
        background-color: #fff;
      }
    }
    .transfer_cell {
      flex: 1;
      display: flex;
      align-items: center;
      // justify-content: space-around;
      line-height: 1.4;
      font-size: 12px;
      padding-left: 12px;
      > span {
        margin-right: 4px;
      }
      .fromAccount {
        color: #00b897;
        text-align: center;
      }
      .toAccount {
        text-align: center;
        color: #00b897;
      }
    }
  }
  .transfer_coin {
    width: 342px;
    background: #f6f7fa;
    border-radius: 4px;
    font-size: 18px;
    color: #333333;
    padding: 0 15px;
    display: flex;
    align-items: center;
    img {
      width: 23px;
      height: 23px;
      border-radius: 50%;
      overflow: hidden;
      background: #ccc;
      margin-right: 10px;
    }
  }
  .transfer_direction {
    border: 1px solid #dcdfe6;
    display: flex;
    justify-content: space-between;
  }
  .transfer_num {
    border: 1px solid #dcdfe6;
    .van-field {
      padding: 0;
      height: 100%;
      width: 100%;
      padding: 0 0 0 12px;
    }
  }
  .desc_num {
    height: 30px;
    font-size: 14px;
    color: #999999;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .sub_btn {
    width: 345px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    background: #00b897;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 auto;
  }
}
.icon_close {
  right: 12px;
  top: 12px;
}
</style>