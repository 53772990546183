<template>
  <div class="assetRecords container">
    <nav-bar :title="query.title" :showRight="false">
      <router-link slot="right" :to="{path:'/PerpetualAssetsFlow',query: { title:$t('M.Perpetual funds daybook')  }}"
                   class="">
        {{ $t('M.assets_cash_flow') }}
      </router-link>
    </nav-bar>
    <div class="capital_top">
      <div class="capital_top_title">
        <span>{{ $t('M.assets_reduced_asset') }}</span>
        <img class="icon" v-show="show" src="@/assets/img/assets/icon_eyeoon.png" alt="" @click="onClickShow">
        <img v-show="!show" alt="" class="icon icon_eyeoff" src="@/assets/img/assets/icon_eyeoff.png"
             @click="onClickShow">
      </div>
      <div class="total row" :style="totalStyle">
        <span>{{ show ? newFinance.totalSum || 0 : '******' }}</span>
        <span v-show="show" class="info">{{ currencySymbol }}</span>
      </div>
      <div class="button_wrap row">
        <div class="button" @click="showTransfer = true">{{ $t('M.Transfer') }}</div>
      </div>

    </div>

    <div class="capital_list">
      <div v-for="(item,i) in newFinance.accountList"
           :key="i"
           :class="[active == i && activeShow?'capital_list_item_active' :'' , 'capital_list_item']">
        <div class="row list_item_tit">
          <img :src="newFinance.coinlogs[i]" alt="">
          {{ item.coinName }}
        </div>
        <div class="row num_wrap">
          <div>
            <span>{{ $t('M.Available') }}</span>
            <span>{{ show ? cutOutPointLength(item.total, 2) || '0' : '****' }}</span>
          </div>
          <div>
            <span>{{ $t('M.freeze') }}</span>
            <span>{{ show ? cutOutPointLength(item.frozen, 2) || '0' : '****' }}</span>
          </div>
          <div>
            <span>{{ $t('M.convert_into') }}</span>
            <span>{{ show ? (item.cnyValue && cutOutPointLength(item.cnyValue / rate, 2)) || '0' : '****' }}</span>
          </div>
        </div>

      </div>
    </div>

    <transfer :account="'CONTRACT'" :coinId="coinId" :showTransfer="showTransfer" @onClose="onClose"
              @onConfirm="onConfirm">
    </transfer>
  </div>
</template>

<script>

import navBar from '@/components/navBar';
import transfer from '@/components/transfer';
import {mapGetters} from 'vuex'
import {GetOs} from '@/utils/ststemTool'

export default {
  name: 'assetRecords',
  components: {
    navBar, transfer
  },
  computed: {
    ...mapGetters([
      'rate', 'newFinance', 'currencySymbol'
    ]),
    totalStyle() {
      if (GetOs() == 'iPhone') {
        return 'align-items: center;'
      }
      return 'align-items: flex-end;'
    }
  },
  data() {
    return {
      query: this.$route.query,
      show: true,
      showTransfer: false,
      active: '',
      activeShow: false,

      totalSum: 0,
      accountList: [],
      coinlogs: [],
      coinId: ''
    };
  },
  created() {
    // 确保在获取数据之前先初始化
    if (!this.newFinance?.accountList?.length) {
      this.$store.dispatch('getUserContractFinanceList')
    }
  },
  mounted() {
  },

  methods: {
    unperData() {
      this.$store.dispatch('getUserContractFinanceList')
      // 添加更严格的空值判断
      this.coinId = this.newFinance?.accountList?.length ? this.newFinance.accountList[0].coinId : ''
    },
    onClickShow() {
      this.show = !this.show
    },

    onClose() {
      console.log('close');
      this.showTransfer = false
    },
    onConfirm() {
      console.log('submit');
      this.unperData()
      this.showTransfer = false
    },


  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
}

.flexC {
  justify-content: center;
  align-items: center;
}

.assetRecords {
  height: 100%;
  overflow-y: scroll;
  font-size: 14px;
}

.button_wrap {
  justify-content: space-around;
  align-items: center;

  .button {
    width: 170px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #fff;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
}

.capital_top {
  height: 160px;
  padding: 20px 15px;
  // background: url('~@/assets/img/assets/bg_capital.png') no-repeat center;
  // background-size: cover;
  background: linear-gradient(90deg, #87c5fc 0%, #00b897 100%);
  font-size: 13px;
  font-weight: 400;
  color: #fff;

  .capital_top_title {
    width: 100%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      margin-right: 20px;
    }

    .icon {
      width: 18px;
    }

    .icon_eyeoff {
      width: 18px;
      height: 8px;
    }
  }

  .total {
    height: 40px;
    font-weight: bold;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 20px;

    > span:first-child {
      font-size: 28px;
      line-height: 1;
      font-family: DINCondensed-Bold, DINCondensed;
    }

    > span.info {
      font-size: 13px;
      font-weight: 600;
      margin-left: 5px;
    }
  }
}

.capital_list {
  .capital_list_item {
    padding: 15px;
    border-bottom: 1px solid #ebebf0;

    .list_item_tit {
      margin: 2px 0 10px;
    }

    .row {
      font-size: 18px;
      font-weight: 400;
      color: #191b27;
      align-items: center;
    }

    img {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 4px;
    }

    .num_wrap {
      justify-content: space-around;

      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 14px;
        font-weight: 400;
        color: #5c6573;
      }

      > div:nth-child(2) {
        align-items: center;
      }

      > div:nth-child(3) {
        align-items: flex-end;
      }
    }
  }

  .capital_list_item_active {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);

    .button_wrap_active {
      border-top: 1px solid #ebebf0;
      margin-top: 15px;

      .matchmaking {
        height: 30px;
        font-size: 10px;
        color: #999;
        justify-content: space-between;
        margin-bottom: 15px;
      }

      .button_wrap {
        .button {
          background: #00b897;
          color: #fff;
          border: none;
          opacity: 1;
        }
      }
    }
  }
}
</style>
