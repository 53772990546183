/*
 * @Author:  
 * @Date: 2022-06-25 18:01:35
 * @LastEditors:  
 * @LastEditTime: 2022-06-25 21:31:45
 * @FilePath: \exchange-h5\src\utils\auth.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
  let token = localStorage.getItem(TokenKey);
  if(token == null){
    token = ''
  }
  return token
}

export function setToken(token) {
  // return Cookies.set(TokenKey, token)
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  // return Cookies.remove(TokenKey)
  return localStorage.removeItem(TokenKey)
}
