<template>
  <div class="container">
    <nav-bar :title="query.title" :border="true" />
    <!-- 提币记录 -->
    <div v-if="query.type==1" class="main main_b">

      <div class="tit">
        {{ query.amount }} {{query.coinName}}
      </div>
      <div class="cell">
        <span>{{$t('M.status')}}</span>
        <span>{{query.statusName}}</span>

      </div>
      <div class="cell">
        <span>{{$t('M.Coin_Address_draw')}}</span>
        <span class="ellipsis">{{query.withdrawAddress}}</span>
        <a :data-clipboard-text="query.withdrawAddress" class="iconfont icon-copy">
          <img class="icon_copy" src="@/assets/img/userCenter/ic_copy.png" alt="">
        </a>
      </div>
      <div class="cell">
        <span>{{$t('M.free')}}</span>
        <span>{{query.networkFees}} {{query.coinName}}</span>
      </div>
      <div class="cell">
        <span>{{$t('M.bb_time')}}</span>
        <span>{{query.createTime}}</span>
      </div>

      <div class="cell desc">
        {{query.cancelReason}}
      </div>

    </div>

    <!-- 划转记录 -->
    <div v-if="query.type==2" class="main main_t">

      <div class="tit">
        {{ query.showChange }} {{query.coinName}}
      </div>
      <div class="cell ">
        <span>{{$t('M.type')}}</span>
        <span class="tradeDesc">{{query.tradeDesc}}</span>
      </div>

      <div class="cell">
        <span>{{$t('M.balance')}}</span>
        <span>{{query.amount}} {{query.coinName}}</span>
      </div>

      <div class="cell">
        <span>{{$t('M.bb_time')}}</span>
        <span>{{query.createTime}}</span>
      </div>

    </div>

  </div>
</template>

<script>
import navBar from '@/components/navBar';

export default {
  name: 'detail',
  components: {
    navBar,
  },
  data() {
    return {
      query: {},
    };
  },
  beforeMount() {
    Object.assign(this.query, this.$route.query);

  },
  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>
.main {
  padding: 30px 20px 0;
  .tit {
    font-weight: 600;
  }
  .cell {
    margin-top: 15px;
    > span {
      display: inline-block;
      font-size: 14px;
      color: #888888;
    }

    .ellipsis {
      width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.main_b {
  .cell {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
      flex: 2;
    }
    > span:first-child {
      flex: 1;
      width: 110px;
    }
    .ellipsis {
      width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.main_t {
  .cell {
    display: block;
    font-size: 13px;
    .tradeDesc {
      color: #00b897;
    }
    > span:first-child {
      margin-right: 10px;
    }
  }
}
.icon_copy {
  width: 17px;
  height: 17px;
}
</style>