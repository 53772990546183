<!--
 * @Author:
 * @Date: 2022-06-27 20:07:35
 * @LastEditors:
 * @LastEditTime: 2022-06-27 21:59:37
 * @FilePath: exchange-h5srcviewsloginagreement.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="font-size:12px;" class="container">
    <nav-bar :showRight="false" :title="$t('M.user_protocol')" class="nav_bar">
    </nav-bar>
    <div style="padding:20px;">
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">The purpose of this website is to provide professional
          international-level trading platforms and services to the majority of digital asset enthusiasts and investors around the world without
          violating relevant laws and regulations. It is prohibited to use this website to engage in all illegal transactions such as money
          laundering,
          smuggling, commercial bribery, etc. If such incidents are discovered, this website will freeze the account and immediately report it to the
          competent authority for processing.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;"><span
          style="font-size: 16px;"></span>Microcloud Technology LLC (&quot;BitMatrix&quot; or the &quot;Company&quot;), a company incorporated under the
          laws of the United States, operates the website voenl.com (the &quot;Website&quot; or the &quot;Website&quot;), a website dedicated to A
          platform for users to conduct digital asset transactions and provide related services (hereinafter referred to as &quot;the service&quot; or
          &quot;service&quot;). For the convenience of expression in this agreement, the company and this website collectively use &quot;we&quot; or
          other first-person pronouns in this agreement. As long as the natural person or other subject who logs on the website is the user of this
          website. For the convenience of expression in this agreement, &quot;you&quot; or other second-person pronouns are used below. For the
          convenience of expression in this Agreement, we and you are collectively referred to as the &quot;Parties&quot; in this Agreement, and we or
          you are individually referred to as the &quot;Party&quot;.<span style="font-size: 16px;"></span></span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">important hint:</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">We hereby remind you:</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1. The digital asset itself is not issued by any financial
          institution;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">2. The digital asset market is new, unconfirmed, and may not
          grow;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">3. Digital assets are mainly used by speculators in large numbers,
          retail and commercial markets are relatively less used, digital asset transactions are extremely risky, and prices are easily affected by
          the
          market and policies and fluctuate greatly;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4. Due to the formulation or modification of laws, regulations and
          regulatory documents of various countries, digital asset transactions may be suspended or prohibited at any time.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">Digital asset trading has extremely high risks and is not suitable
          for most people. You understand and understand that this investment may cause partial or total loss, so you should decide the amount of
          investment based on the degree of loss that you can bear. You understand and understand that digital assets will generate derivative risks,
          so
          if you have any questions, it is recommended to seek the assistance of a licensed financial advisor first. In addition, in addition to the
          risks mentioned above, there will also be unpredictable risks. You should carefully consider and use clear judgment to evaluate your
          financial
          situation and the above-mentioned risks before making any decision to buy or sell digital assets, and bear all losses arising therefrom. We
          are not responsible for this.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">Warmly tell you:</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1. You understand that this website is only a place for you to
          obtain
          digital asset information, find trading parties, negotiate and carry out transactions on digital asset transactions. This website does not
          participate in any of your transactions, so you should carefully determine the relevant digital assets by yourself And/or the authenticity,
          legitimacy and validity of the information, and bear the responsibilities and losses arising therefrom.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">2. Any opinions, news, discussions, analysis, prices, suggestions
          and
          other information on this website are general market comments and do not constitute investment advice. We are not responsible for any losses
          arising directly or indirectly from reliance on this information, including but not limited to any financial losses.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp; &nbsp; &nbsp; 3. The content of this website will be changed
          at any time without notice. We have taken reasonable measures to ensure the accuracy of the information on the website, but we cannot
          guarantee its accuracy, and will not be responsible for any information on this website or failure to Loss directly or indirectly caused by
          delays or failures in linking to the Internet, transmitting or receiving any notices and information.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp; &nbsp; &nbsp; 4. There are risks associated with the use of
          Internet-based trading systems, including but not limited to the failure of software, hardware, and Internet links. Since we cannot control
          the reliability and availability of the Internet, we will not take any responsibility for distortions, delays and link failures.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;"><span
            style="font-family: Calibri; font-size: 16px;">&nbsp; &nbsp; &nbsp; 5. </span><span
          style="font-family: 宋体; font-size: 16px;">BitMatrix</span><span style="font-family: Calibri; font-size: 16px;">.com is the only official
            platform
            for publishing external information on this website;</span></span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp; &nbsp; &nbsp; 6. No credit card payment is accepted for any
          service on this website;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp; &nbsp; &nbsp; 7. It is forbidden to use this website to
          engage
          in all illegal transactions such as money laundering, smuggling, commercial bribery, etc. If such incidents are found, this website will
          take
          various available methods, including but not limited to freezing accounts, notifying relevant authorities, etc. We do not assume all the
          responsibilities arising therefrom and reserve the right to hold relevant parties accountable.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1. General</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1.1 The &quot;User Agreement&quot; (hereinafter referred to as
          &quot;this agreement&quot; or &quot;the terms and conditions&quot;) consists of the main text, the &quot;Privacy Policy&quot; and various
          rules, statements, instructions, etc. that have been published or may be published on this website in the future.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1.2 You should read this agreement carefully before using the
          services provided on this website. If you do not understand anything or other necessary, please consult a professional lawyer. If you do not
          agree to this agreement and/or modify it at any time, please immediately stop using the services provided by this website or stop logging in
          to this website. Once you log in to this website, use any service of this website or any other similar behavior, it means that you have
          understood and fully agreed to the contents of this agreement, including any modification of this agreement made by this website at any
          time.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;"><span
            style="font-family: Calibri; font-size: 16px;">1.3 You can become a member of this website (hereinafter referred to as &quot;member&quot;)
            by filling in relevant information in accordance with the requirements of this website and successfully registering after other relevant
            procedures. During the registration process, click &quot;I have read and agree to &quot;</span><span
          style="font-family: 宋体; font-size: 16px;">BitMatrix</span><span style="font-family: Calibri; font-size: 16px;">&nbsp;User Agreement&quot;&quot;
            button means that you have reached an agreement with the company in the form of electronic signature; or you click on any button marked
            with
            &quot;agree&quot; or similar meaning during the use of this website, or actually use other methods permitted by this website When using
            the
            services provided by this website, it means that you fully understand, agree and accept all the terms and conditions of this agreement.
            Without your handwritten written signature, this agreement will have no effect on your legal binding force.</span></span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1.4 After you become a member of this website, you will get a
          member
          account and corresponding password. The member account and password are kept by the member; the member shall be legally responsible for all
          activities and events conducted with his account.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1.5 Only by becoming a member of this website can you use the
          digital
          asset trading platform provided by this website to conduct transactions and enjoy other services that are only available to members as
          stipulated by this website; other than members, you can only log in to the website, browse the website and other websites Provision of
          available services.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1.6 By registering and using any of the services and functions
          provided by this website, you will be deemed to have read, understood and:</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1.6.1 Accept all terms and conditions of this agreement.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1.6.2 You confirm that you have reached the age of 16 or have a
          legal
          age for entering into contracts according to different applicable laws, and have sufficient ability to accept these terms, enter into
          transactions, and use this website to conduct digital asset transactions.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1.6.3 You guarantee that the digital assets belonging to you
          involved
          in the transaction are legally acquired and owned.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1.6.4 You agree that you bear full responsibility and any gains or
          losses for your own trading or non-trading activities.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1.6.5 You confirm that the information provided during registration
          is true and accurate.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1.6.6 You agree to abide by any relevant laws and any regulations
          of
          jurisdictional territories, including reporting any trading profits.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">1.6.7 This agreement only restricts the rights and obligations
          between you and us, and does not involve legal relationships and legal disputes between users of this website and other websites and you due
          to digital asset transactions.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">2. Agreement revision</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">We reserve the right to revise this agreement from time to time,
          and
          display it in the form of website publicity, and no longer notify you separately. Once the changed agreement is published on the website, it
          will automatically take effect immediately. You should browse and pay attention to the update time and content of this agreement from time
          to
          time. If you do not agree to the relevant changes, you should immediately stop using the services of this website; your continued use of the
          services of this website means that you accept and agree to the constraints of the revised agreement .</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">Three, registration</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">3.1 Registration qualification</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">You confirm and promise that: when you complete the registration
          process or actually use the services provided by this website in other ways allowed by this website, you should have the ability to sign
          this
          agreement and use the services of this website as required by applicable laws Of natural persons, legal persons or other organizations. Once
          you click the agree to register button, it means that you or your authorized agent have agreed to the content of the agreement and the agent
          will register and use the services of this website. If you do not have the aforementioned subject qualifications, you and your authorized
          agent shall bear all the consequences resulting therefrom, and the company reserves the right to cancel or permanently freeze your account,
          and to hold you and your authorized agent accountable .</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">3.2 Purpose of Registration</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">You confirm and promise that your registration on this website is
          not
          for the purpose of violating laws and regulations or disrupting the order of digital asset transactions on this website.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">3.3 Registration process</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">3.3.1 You agree to provide valid e-mail address, mobile phone
          number
          and other information in accordance with the requirements of the user registration page of this website. You can use the e-mail address,
          mobile phone number you provided or confirmed or other methods allowed by this website to enter this website as a means of login. If
          necessary, in accordance with the relevant laws and regulations of different jurisdictions, you must provide your real name, ID card and
          other
          relevant information as required by the laws and regulations and privacy clauses and anti-money laundering clauses, and constantly update
          the
          registration information to comply with timely, detailed and accurate Require. All the original information entered will be cited as
          registration information. You are responsible for the authenticity, completeness and accuracy of such information, and bear any direct or
          indirect losses and adverse consequences arising therefrom.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">3.3.2 If the laws, regulations, rules, and orders of your sovereign
          country or region have real-name requirements for mobile phone numbers, you agree that the registered mobile phone number is registered
          through real-name registration. If you do not provide it in accordance with the regulations, you will bring you Any direct or indirect
          losses
          and adverse consequences should be borne by you.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">3.3.3 You are legal, complete and effective to provide the
          information required for registration and after verification, you have the right to obtain the account and password of this website. When
          you
          obtain the account and password of this website, it is deemed that the registration is successful, and you can log in as a member on this
          website.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">3.3.4 You agree to receive emails and/or short messages sent by
          this
          website related to the management and operation of this website.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">Four, service</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">This website only provides online trading platform services for
          you,
          including but not limited to digital asset transactions, through this website. This website does not act as a buyer or seller to participate
          in the buying and selling of digital assets itself.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.1 Service content</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.1.1 You have the right to browse the real-time market quotations
          and transaction information of various digital asset products on this website, and have the right to submit digital asset transaction
          instructions and complete digital asset transactions through this website.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.1.2 You have the right to view the information under your member
          account on this website, and you have the right to operate using the functions provided by this website.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.1.3 You have the right to participate in the activities that
          apply
          to you organized by this website in accordance with the activity rules published on this website.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.1.4 This website promises to provide you with other
          services.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.2. Service Rules</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">You promise to abide by the following service rules of this
          website:</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.2.1 You should abide by the requirements of laws, regulations,
          and
          policies, ensure the legitimacy of all digital asset sources in your account, and must not engage in illegal or other activities that damage
          the rights and interests of this website or third parties on this website or using the services of this website. Such as sending or
          receiving
          any information that is illegal, illegal, or infringing on the rights of others, sending or receiving pyramid schemes or other harmful
          information or speech, using or forging the email header information of this website without authorization of this website, etc.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.2.2 You should abide by laws and regulations, and properly use
          and
          keep your account on this website, login password, fund password, and mobile phone number bound to you during registration, as well as the
          security of the mobile phone verification code received by your mobile phone. You are fully responsible for any operation and consequences
          of
          using your account and login password on this website, fund password, and mobile phone verification code. When you find that this website
          account, login password, or fund password, verification code is used by a third party without its authorization, or there are other account
          security issues, you should immediately notify this website effectively and request this website to suspend your account on this website
          Service. This website has the right to take action on your request within a reasonable time, but this website does not bear any
          responsibility
          for the consequences (including but not limited to any loss of you) that have occurred before the action is taken. You are not allowed to
          give, borrow, rent, transfer or otherwise dispose of this website account to others without the consent of this website.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.2.3 You agree that you are responsible for all activities that
          occur under your account and password on this website (including but not limited to information disclosure, information release, online
          click
          to agree or submit various rules and agreements, online renewal agreements or purchase services, etc.) responsibility.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.2.4 When you conduct digital asset transactions on this website,
          you must not maliciously interfere with the normal progress of digital asset transactions or disrupt the transaction order; you must not
          interfere with the normal operation of this website or interfere with other users&#39; use of the services of this website by any technical
          means or other means; The goodwill of this website shall not be maliciously slandered by means of fictitious facts, etc.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.2.5 If you have disputes with other users due to online
          transactions, you must not request this website to provide relevant information through channels other than judicial or
          administrative.</span>
      </p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.2.6 During your use of the services provided on this website, you
          will be solely responsible for all taxable taxes, as well as all hardware, software, service and other expenses incurred.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.2.7 You shall abide by this agreement and other terms of service
          and operating rules published and updated by this website from time to time, and have the right to terminate the use of the services
          provided
          by this website at any time.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.3. Product rules</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.3.1 Rules for currency trading products</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">You promise to comply with the following transaction rules in the
          process of entering into this website and conducting currency transactions with other users through this website.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.3.1.1 Browse transaction information</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">When you browse the currency transaction information on this
          website,
          you should carefully read all the content contained in the transaction information, including but not limited to price, commission amount,
          handling fee, buying or selling direction, and you fully accept all the information contained in the transaction information After the
          content, you can click the button to trade.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.3.1.2 Submit an order</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">After browsing the transaction information and confirming that it
          is
          correct, you can submit a transaction order. After you submit a transaction entrustment, you authorize this website to act on your behalf
          for
          the corresponding transaction matching. This website will automatically complete the matching transaction when there is a transaction that
          meets your entrusted price without notifying you in advance.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.3.1.3 View transaction details</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">You can check the corresponding transaction records in the
          entrusted
          management and transaction records to confirm your detailed transaction records.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">4.3.1.4 Revocation/modification of delegation</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">You have the right to revoke or modify the entrustment at any time
          before the transaction is concluded.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">5. Rights and obligations of this website</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">5.1 If you do not have the registration qualifications stipulated
          in
          this agreement, this website has the right to refuse your registration. For those who have been registered, this website has the right to
          cancel your member account, and this website reserves the right to pursue you or your authorized agent. The right to responsibility. At the
          same time, this website reserves the right to decide whether to accept your registration under any other circumstances.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">5.2 When this website finds that the account user is not the
          initial
          registrant of the account, it has the right to suspend or terminate the use of the account.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">5.3 This website has the right to notify you to correct or update
          the
          information or suspend or terminate the provision of services of this website when it reasonably suspects that the information you provide
          is
          wrong, untrue, invalid or incomplete through technical testing, manual sampling and other testing methods.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">5.4 Home network the site has the right to correct the information
          when it finds any obvious errors in any information displayed on this website.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">5.5 This website reserves the right to modify, suspend or terminate
          the service of this website at any time. This website exercises the right to modify or suspend the service without prior notice to you; if
          this website terminates one or more of the services of this website, the termination of this website Effective on the date of the
          announcement
          of termination.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">5.6 This website shall adopt necessary technical means and
          management
          measures to ensure the normal operation of this website, and provide necessary and reliable trading environment and trading services to
          maintain the order of digital asset transactions.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">5.7 If you have not used the member account and password of this
          website to log in to this website for one year in a row, this website has the right to cancel your account on this website. After the
          account
          is cancelled, this website has the right to open the corresponding member name to other users for registration.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">5.8 This website protects the security of your digital assets
          through
          measures such as strengthening technical investment and improving security precautions. It is obliged to notify you in advance when there
          are
          foreseeable security risks in your account.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">5.9 This website has the right to delete all kinds of content and
          information on this website that do not comply with laws and regulations or the provisions of this website at any time, and this website
          does
          not need to notify you in advance to exercise such rights.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">5.10 This website has the right to request more information or
          materials from you in accordance with the laws, regulations, rules, and orders of the sovereign country or region to which you belong, and
          to
          take reasonable measures to meet the requirements of local regulations. You are obliged to cooperate; this website has the right to suspend
          or
          permanently stop opening this website and some or all of its services to you in accordance with the laws, regulations, rules, and orders of
          your sovereign country or region.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">6. Compensation</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">6.1 In any case, our liability for your direct losses will not
          exceed
          the total cost of three (3) months incurred by you from using the services of this website.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">6.2 If you violate this agreement or other laws and regulations,
          you
          must compensate us at least US$200,000 and bear all the expenses (including attorney fees, etc.) incurred. If it is not enough to cover the
          actual loss, you must make up.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">7. The right to seek injunctive relief</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">We and you both acknowledge that common law relief measures for
          breach of contract or possible breach of contract may not be sufficient to make up for all the losses we have suffered, so the
          non-defaulting
          party has the right to seek injunctive relief and common law permission in the case of the other party&#39;s breach or possible breach of
          contract All other remedies.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8. Limitation of Liability and Exemption</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.1 You understand and agree that, under any circumstances, we are
          not responsible for the following matters:</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.1.1 Loss of income;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.1.2 Trading profits or contract losses;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.1.3 Business interruption;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.1.4 Loss of assets expected to be saved;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.1.5 Loss of information;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.1.6 Loss of opportunity, goodwill or reputation;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.1.7 Damage or loss of data;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.1.8 The cost of purchasing alternative products or
          services;</span>
      </p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.1.9 Any indirect, special or incidental loss or damage caused by
          infringement (including negligence), breach of contract or any other reason, regardless of whether such loss or damage can be reasonably
          foreseen by us; regardless of whether we have been notified in advance There is a possibility of such loss or damage.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">Articles 8.1.10 to 8.1.9 are independent of each other.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.2 You understand and agree that we are not liable for any damages
          caused by any of the following circumstances:</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.2.1 We have reasonable grounds to believe that your specific
          transaction may have a major violation of the law or breach of contract.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.2.2 We have reasonable grounds to believe that your behavior on
          this website is suspected of being illegal or improper.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.2.3 Costs and losses incurred by the purchase or acquisition of
          any
          data, information, or transactions through the services of this website or alternative behaviors.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.2.4 Your misunderstanding of the services of this website.</span>
      </p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.2.5 Any other losses related to the services provided on this
          website that are not caused by us.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.3 We are responsible for the maintenance of information network
          equipment, information network connection failures, computer, communication or other system failures, power failures, weather reasons,
          accidents, strikes, labor disputes, riots, uprisings, riots, insufficient productivity or production materials, fires , Floods, storms,
          explosions, wars, bank or other partner reasons, digital asset market collapse, government actions, judicial or administrative orders, other
          actions that are not within our control or we are unable to control, or causes of third parties We do not assume any responsibility for the
          inability to service or delay in service, as well as the losses caused to you.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.4 We cannot guarantee that all the information, programs, texts,
          etc. contained in this website are completely safe, free from any interference and damage by malicious programs such as viruses, Trojan
          horses, etc., so you log in, use any service on this website or download and use any downloaded program , Information, data, etc. are your
          personal decision and you bear your own risks and possible losses.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.5 We do not make any guarantees and promises for any information,
          products and services of any third-party websites linked to this website, and any other forms of content that are not our main body. If you
          use any services and information provided by third-party websites And products, etc. are your personal decision and bear all the
          responsibilities arising therefrom.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.6 We do not make any express or implied guarantees for your use
          of
          the services on this website, including but not limited to the applicability of the services provided on this website, no errors or
          omissions,
          continuity, accuracy, reliability, and suitability for a specific purpose. At the same time, we do not make any promises and guarantees for
          the validity, accuracy, correctness, reliability, quality, stability, completeness and timeliness of the technology and information involved
          in the services provided on this website. Whether to log in or use the services provided by this website is your personal decision and at
          your
          own risk and possible losses. We do not make any express or implied guarantees regarding the market, value and price of digital assets. You
          understand and understand that the digital asset market is unstable, and prices and values may fluctuate or collapse at any time. Trading
          digital assets is your personal freedom Choose and decide at your own risk and possible losses.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.7 Our guarantees and promises stipulated in this agreement are
          our
          only guarantees and statements regarding the services provided by this agreement and this website, and replace any guarantees and promises
          generated by other means and methods, whether written or oral, Explicit or implied. All these guarantees and statements only represent our
          own
          commitments and guarantees, and do not guarantee that any third party will comply with the guarantees and promises in this agreement.</span>
      </p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.8 We do not waive any rights that we have not mentioned in this
          agreement to limit, exempt or offset our liability for damages to the maximum extent of the applicable law.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">8.9 After you register, you acknowledge that we carry out any
          operations in accordance with the rules stipulated in this agreement, and any risks arising therefrom shall be borne by you.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">9. Termination of the agreement</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">9.1 This website has the right to cancel your account on this
          website
          in accordance with this agreement, and this agreement terminates on the date of account cancellation.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">9.2 This website has the right to terminate all the services of
          this
          website in accordance with this agreement, and this agreement terminates on the day when all services of this website are terminated.</span>
      </p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">9.3 After the termination of this agreement, you have no right to
          require this website to continue to provide you with any services or perform any other obligations, including but not limited to requiring
          this website to retain or disclose to you any information in the original website account, to you or the third party. The three parties
          forward any information that you have not read or sent.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">9.4 The termination of this agreement does not affect the observant
          party&#39;s request for other responsibilities from the breaching party.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">10. Intellectual Property</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">10.1 All intellectual achievements contained in this website
          include,
          but are not limited to, website logos, databases, website design, text and graphics, software, photos, videos, music, sounds and their
          combinations, software compilation, related source codes and software (including small The intellectual property rights of applications and
          scripts are owned by this website. You may not copy, change, copy, send or use any of the foregoing materials or content for commercial
          purposes.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">10.2 All rights contained in the name of this website (including
          but
          not limited to goodwill and trademarks, logos) belong to the company.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">10.3 Your acceptance of this agreement shall be deemed as your
          initiative to take the copyright of any form of information published on this website, including but not limited to: reproduction rights,
          distribution rights, rental rights, exhibition rights, performance rights, projection rights, broadcasting rights, Information network
          dissemination rights, filming rights, adaptation rights, translation rights, compilation rights and other transferable rights that should be
          enjoyed by the copyright owner are exclusively transferred to this website for free, and this website has the right to file a separate
          lawsuit
          against any subject&#39;s infringement and obtain full compensation . The validity of this agreement applies to the content of any works
          protected by copyright law that you publish on this website, regardless of whether the content was formed before or after the signing of
          this
          agreement.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">10.4 You shall not illegally use or dispose of the intellectual
          property rights of this website or others in the process of using the services of this website. You must not publish the information
          published
          on this website in any form or authorize other websites (and media) to use it.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">10.5 Your logging on to this website or using any service provided
          by
          this website shall not be deemed as our transfer of any intellectual property rights to you.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11. Information Protection</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.1 Scope of application</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.1.1 When you register a website account or use an account, the
          personal registration information you provide in accordance with the requirements of this website, including but not limited to mobile phone
          number, email information, and ID information.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.1.2 When you use the services of this website, or when you visit
          the web pages of this website, this website automatically receives and records the server values on your browser, including but not limited
          to
          data such as IP addresses and webpage records you request to access.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.1.3 The relevant data collected by this website about your
          transactions on this website, including but not limited to transaction records.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.1.4 Other personal information about you obtained by this
          website
          through legal means.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.2 Use of Information</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.2.1 Without your additional consent, your successful
          registration
          on this website is deemed to be your consent to the collection and use of various information on this website by this website. As listed in
          Section 11.1, you understand and agree that this website can The collected information about you is used for the following purposes
          including
          but not limited to:</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.2.1.1 To provide you with the services of this website;</span>
      </p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.2.1.2 Report to relevant authorities based on the requirements
          of
          relevant authorities in sovereign countries or regions;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.2.1.3 When you use the services of this website, this website
          uses
          your information for identity verification, customer service, security prevention, fraud monitoring, archiving and backup purposes to ensure
          the safety of the products and services provided by this website to you;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.2.1.4 Help this website design new products and services, and
          improve the existing services of this website;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.2.1.5 In order to enable you to understand the specific
          situation
          of the services of this website, you agree that this website will send you marketing notices, commercial electronic information and provide
          you with relevant advertisements to replace commonly placed advertisements;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.2.1.6 This website transfers or discloses your information to
          any
          unrelated third party in order to complete the merger, division, acquisition or asset transfer;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.2.1.9 Software certification or management software
          upgrade;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.2.1.10 You are invited to participate in surveys about the
          services of this website;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.2.1.11 Data analysis for cooperation with government agencies,
          public affairs agencies, associations, etc.;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.2.1.12 For all other legal purposes and other purposes
          authorized
          by you.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.2.2 This website will not sell or lend your personal information
          to anyone else unless you have obtained your permission in advance. This website also does not allow any third party to collect, edit, sell
          or
          disseminate your personal information by any means.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">11.3 This website keeps confidential the customer identity
          information and transaction information obtained, and shall not provide customer identity information and transaction information to any
          unit
          or individual. The website shall be required to provide information in accordance with relevant sovereign country or regional laws and
          regulations, government decrees, orders, etc. except.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">12. Calculation</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">All transaction calculation results have been verified by us, but
          we
          cannot guarantee that the use of the website will not be disturbed or error-free.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">13. Export control</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">You understand and acknowledge that, in accordance with relevant
          national laws, you are not allowed to export, re-export, import or transfer any materials (including software) on this website, so you
          guarantee that you will not actively implement or assist or participate in any of the above-mentioned export or Regarding transfers or other
          violations of applicable laws and regulations; if such situations are discovered, they will actively report to us and assist us in handling
          them.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">14. Transfer</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">The rights and obligations stipulated in this agreement also
          restrict
          the assignees, heirs, executors and administrators of the parties who obtain benefits from the rights and obligations. You may not transfer
          to
          any third party without our consent, but we can transfer our rights and obligations in this agreement to any third party at any time.</span>
      </p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">15. Severability</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">If any clause of this agreement is deemed unenforceable, invalid or
          illegal by any court with jurisdiction, it will not affect the validity of the remaining clauses of this agreement.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">16. Non-agent relationship</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">Nothing in this agreement shall be deemed to have created, implied
          or
          otherwise treats us as your agent, trustee or other representative, unless there are other provisions in this agreement.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">17. Abstention</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">We or any of your party’s waiver of the pursuit of liability for
          breach of contract or other liabilities stipulated in this agreement shall not be deemed or interpreted as a waiver of other liabilities for
          breach of contract; failure to exercise any rights or remedies shall not be interpreted as a waiver of such rights or remedies in any way Of
          giving up.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">18. Title</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">All titles are for the convenience of expressing the agreement
          only,
          and are not used to expand or limit the content or scope of the terms of the agreement.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">19. Applicable Law</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">The entire content of this agreement is a contract made in
          accordance
          with the laws of the People&#39;s Singapore, and its establishment, interpretation, content and execution are subject to relevant Singapore
          laws and regulations; any claims or litigation related to the services agreed in this agreement shall be in accordance with Singapore laws
          Administer, interpret and enforce. For the avoidance of doubt, this clause clearly applies to any infringement claims against us. If there
          is
          a dispute between users and this website, they should first be resolved through negotiation based on the principle of good faith. If the
          negotiation fails, you can file a lawsuit in the court where this website is located.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">20. Entry into force and interpretation of the agreement</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">20.1 This agreement becomes effective when you click on the
          registration page of this website to agree to register and complete the registration process, and obtain the account number and password of
          this website, and it is binding on this website and you.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">20.2 The final interpretation right of this agreement belongs to
          this
          website within the scope permitted by law.</span></p>
      <p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0"><span
          style="font-size: 16px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p><span style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">&nbsp;</span></p>
      <p><br /></p>
    </div>
  </div>
</template>

<script>
import navBar from '@/components/navBar';

export default {
  components: {
    navBar
  },
}
</script>

<style>
</style>