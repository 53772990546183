<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-19 15:57:38
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="setPassword container">
    <van-nav-bar :title="$t('M.bind_phone')" left-text="" left-arrow @click-left="onClickLeft" />

    <div class="content">
      <!-- <div class="area" @click="countriesListShow = true">
        +{{country}}
        <van-icon name="arrow-down" />
      </div> -->

      <van-cell-group class="inputPhone" style="padding:0;margin-top: 10px;">
        <van-field v-model="phoneNum" :placeholder="$t('M.input_newphone_code')" />
        <div class="getmgs" v-if="downTimeNum <= 0 " @click="sendMsg">{{$t('M.get_code')}}</div>
        <div class="getmgs" v-if="downTimeNum > 0 ">{{downTimeNum}}</div>
        <div class="country">+{{country}}</div>
      </van-cell-group>
      <van-field style="margin-top: 10px;" v-model="codeNum" :placeholder="$t('M.input_phone_code')" />

      <div class="next" @click="sur">{{$t('M.confirm')}}</div>

    </div>

    <van-popup v-model="countriesListShow" position="bottom" :style="{ height: '100%' }">
      <van-search v-model="inputCountrText" placeholder="Please select country or region" :autofocus="true" :show-action="true"
        @cancel="countriesListShow = false" @input="changeInput" :action-text="$t('M.Cancel')" />
      <div class="countriesListBox">
        <div class="listItem" v-for="(item, idx) in showCountriesListData" :key="idx" @click="selectItem(item)">
          <div class="name" v-if="item.nationCode != '855'">{{ item.english.replace(/\(China\)/g, '') }}</div>
          <div class="name" v-if="item.nationCode == '855'">Cambodia</div>
          <div class="number">+{{ item.nationCode }}</div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      link: '',
      phoneNum: '',
      codeNum: '',
      value: '',
      countriesListShow: false,
      inputCountrText: '',
      /* 国家代码 */
      country: 1,
      /* 显示国家的名字 */
      showCountrName: 'US',
      showCountriesListData: [],
      downTimeNum: 0,
      timeObj: null
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
  },
  mounted() {
    this.$api.selectList().then((res) => {
      console.log(res)
      // this.link = res.data.link;
      this.countriesListData = res.data;
      this.showCountriesListData = res.data;
      const countr = this.countriesListData.find(item => item.nationCode == this.user.country)
      this.showCountrName = countr;
      this.country = this.showCountrName.nationCode;
    })
  },
  methods: {
    /* 确定 */
    sur() {
      if (this.phoneNum == '') {
        this.$notify(this.$t('M.input_newphone_code'))
        return
      }
      if (this.codeNum == '') {
        this.$notify(this.$t('M.login_please_input1'))
        return
      }
      this.$api
        .bindPhone({
          partnerId: "472437831826935808",
          phone: this.phoneNum,
          code: this.codeNum,
          partnerNo: "1000010001",
        })
        .then((data) => {
          if (data.meta.success) {
            this.$notify(data.meta.message)
            this.$store.dispatch('getUserData')
            this.$router.replace("securityCenter")
          } else {
            this.$notify(data.meta.message)
          }
        });
    },
    /* 获取验证码 */
    sendMsg() {

      if (this.phoneNum == '') {
        this.$notify(this.$t('M.input_newphone_code'))
        return
      }
      this.$api
        .sendSms({
          partnerId: "472437831826935808",
          phone: this.phoneNum,
          nationCode: this.country,
          action: "BINDING_PHONE"
        })
        .then((data) => {
          if (data.meta.success) {
            this.downTimeNum = 59;
            this.timerDown();
            this.$notify(data.meta.message)
          } else {
            this.$notify(data.meta.message)
          }
        });
    },
    /* 倒计时开启 */
    timerDown() {
      this.downTimeNum;
      this.timeObj = setInterval(() => {
        this.downTimeNum--;
        if (this.downTimeNum <= 0) {
          this.downTimeNum = 0;
          clearInterval(this.timeObj);
        }
      }, 1000);
    },
    /* 改变输入内容 */
    changeInput() {
      if (this.inputCountrText == "") {
        this.showCountriesListData = this.countriesListData;
      } else {
        let showArr = [];
        for (const iterator of this.countriesListData) {
          if (
            iterator.chinese.indexOf(this.inputCountrText) != -1 ||
            iterator.english.indexOf(this.inputCountrText) != -1 ||
            iterator.nationCode.indexOf(this.inputCountrText) != -1
          ) {
            showArr.push(iterator);
          }
        }
        this.showCountriesListData = showArr;
      }
    },
    /* 选中了某一个国家 */
    selectItem(data) {
      this.country = data.nationCode;
      this.showCountrName = data.english;
      this.countriesListShow = false;
    },
    /* 改变输入内容 */
    changeInput() {
      if (this.inputCountrText == "") {
        this.showCountriesListData = this.countriesListData;
      } else {
        let showArr = [];
        for (const iterator of this.countriesListData) {
          if (
            iterator.chinese.indexOf(this.inputCountrText) != -1 ||
            iterator.english.indexOf(this.inputCountrText) != -1 ||
            iterator.nationCode.indexOf(this.inputCountrText) != -1
          ) {
            showArr.push(iterator);
          }
        }
        this.showCountriesListData = showArr;
      }
    },
    copyCode() {

    },
    onClickLeft() {
      this.$router.back()
    }
  },
  destroyed() {
    clearInterval(this.timeObj)
  }
}
</script>

<style lang="scss" scoped>
.setPassword {
  font-size: 12px;
}

.getmgs {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 14px;
  color: #00b897;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
::v-deep .van-cell {
  background-color: #f8f8f8;
  padding: 10px 10px;
  // padding-left: 50px;
  border-radius: 5px;
}
::v-deep .van-button--primary {
  background-color: #f8f8f8;
  border: 0;
  color: #00b897;
}

.content {
  padding: 0 20px;

  .inputPhone {
    display: flex;
    .country {
      background-color: #f8f8f8;
      position: absolute;
      left: 10px;
      top: 13px;
    }
  }
  .area {
    background-color: #f8f8f8;
    padding: 5px 5px;
    width: 70px;
    text-align: center;
    border-radius: 20px;
    margin-top: 20px;
  }
  .next {
    width: 100%;
    height: 46px;
    background: #00b897;
    border-radius: 4px;
    text-align: center;
    line-height: 46px;
    font-size: 18px;
    color: #fff;
    margin-top: 50px;
  }
}
::v-deep .van-popup {
  .van-search--show-action {
    padding-right: 10px;
  }
  .van-search__content {
    border-radius: 5px;
    .van-icon {
      color: #8a8a8a;
    }
  }
  .van-search__action {
    color: #8a8a8a;
  }
  .countriesListBox {
    .listItem {
      font-size: 14px;
      line-height: 54px;
      margin: 0 19px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e5e5e5;
    }
  }
}

::v-deep .inputPhone {
  .van-cell {
    padding-left: 50px;
  }
}
</style>
