<template>
  <div class="Incitation">
    <van-nav-bar :title="$t('M.Invite Friends')" left-text="" left-arrow @click-left="onClickLeft" />

    <div class="topbox">
      <div class="top">
        <div class="content">
          <div class="text">{{$t("M.invite_code1")}}</div>
          <div class="num">{{user.showId}}</div>
        </div>
        <a :data-clipboard-text="user.showId" class="iconfont icon-copy">
          <img class="copy" src="../../assets/img/user/copy.png" alt="" srcset="">
        </a>
      </div>
    </div>

    <div class="shareBtn">
      <a :data-clipboard-text="this.link" class="iconfont icon-copy" style="display:block;width:100%;height: 100%;">
        {{$t("M.share_invitation_link")}}
      </a>

    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      link: '',
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    // 计算属性的 getter
    earningsMoney: function () {
      return this.lookShapeFlag ? cutOutPointLength(this.openData.upRatio * this.betNum, 2) : cutOutPointLength(this.openData.downRatio * this.betNum, 2);
    }
  },
  mounted() {
    this.$api.getShareLink().then((res) => {
      console.log(res)
      this.link = res.data.link;
    })
  },
  methods: {
    copyCode() {
      var url = this.user.showId;
      var input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', url);
      input.select();
      document.execCommand("copy"); // 执行浏览器复制命令
      if (document.execCommand('copy')) {
        document.execCommand('copy');
      }
      document.body.removeChild(input)
    },
    copyLink() {
      var url = this.link;
      var input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', url);
      input.select();
      document.execCommand("copy"); // 执行浏览器复制命令
      if (document.execCommand('copy')) {
        document.execCommand('copy');
      }
      document.body.removeChild(input)
    },
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.Incitation {
  font-size: 12px;
}
.topbox {
  width: 345px;
  height: 138px;
  // background: linear-gradient(315deg, #e9ba6c 0%, #f4dab0 100%);
  background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
  border-radius: 5px;
  margin: 10px auto;
  box-sizing: border-box;
  padding: 0 15px;
  .top {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    color: #fff;
    height: 60px;
    align-items: center;
    .content {
      display: flex;
      align-items: center;
      .text {
        font-size: 16px;
      }
      .num {
        font-size: 21px;
        font-weight: 600;
        margin-left: 10px;
      }
    }
    .copy {
      width: 15px;
      height: 15px;
    }
  }
}

.shareBtn {
  position: fixed;
  bottom: 20px;
  width: 88%;
  height: 46px;
  background: #00b897;
  border-radius: 4px;
  color: #fff;
  line-height: 46px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);

  a {
    color: #fff;
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
</style>