<template>
  <div class="recharging container">
    <nav-bar :showLeft="false" :showRight="false" class="nav_bar">
      <div slot="left" class="left">
        <van-field v-model="value" clearable :placeholder="$t('M.input_currency_referred')">

          <img class="icon_search" slot="left-icon" src="@/assets/img/assets/icon_search.png" alt="">
        </van-field>
      </div>
      <span slot="right" class="right" @click="onClickRight">{{$t('M.Cancel')}}</span>
    </nav-bar>
    <div class="recharging_list">

      <div class="recharging_list_item" v-for="(item,i) in list" :key="i" @click="onClickItem(item)">
        <img v-if="query.type != 'walletFlow'" :src="item.logo" alt="">
        <span> {{item.coinName}}</span>
      </div>
    </div>
    <Dialog :show="dialog.showDialog" :message="dialog.showMessage" :confirmButtonText="$t('M.go_setting')" @closed="onClosed" @confirm="onConfirm">
    </Dialog>

  </div>
</template>

<script>
import navBar from '@/components/navBar';
import {mapGetters, mapMutations} from 'vuex'
import Dialog from '@/components/dialog';

// import eventBus from '@/utils/eventbus.js';
export default {
  name: 'recharging',
  components: {
    navBar, Dialog
  },
  computed: {
    ...mapGetters([
      'coinList', 'user',
    ]),
    ...mapMutations(['SET_WALLET_COIN'])
  },
  watch: {
    value(n) {
      if (n) {
        this.list = this.list.filter(item => item.coinName.toUpperCase().indexOf(n.toUpperCase()) > -1)
      } else {
        this.list = JSON.parse(JSON.stringify(this.coinList))
      }

    }
  },
  data() {
    return {
      query: this.$route.query,
      value: '',
      list: [],
      dialog: {
        showDialog: false,
        showMessage: '',
        path: ''
      },
    };
  },
  async created() {
    await this.$store.dispatch('getUserWalletAssets')
    this.list = JSON.parse(JSON.stringify(this.coinList))
    if (this.query.type == 'walletFlow') {
      this.list.unshift({
        coinName: this.$t('M.all'),
        id: '',
        coinId: ''
      })
    }
    const { type, back } = this.query
    if(type == 'withdraw'){
      const filterArr = await this.$api.filterLockCoin()
      let arr = filterArr.data
      this.list = this.list.filter(item => !arr.includes(item.coinId));
    }
  },
  mounted() {


  },

  methods: {

    onClickRight() {
      this.$router.back()
    },
    getStatus(type, item) {
      if (type === 'recharge' && !item.recharge) return this.$t('M.pause_recharge')
      if (type === 'withdraw' && !item.withdraw) return this.$t('M.pause_withdraw')
      return false
    },

    onClickItem(item) {
      const { type, back } = this.query
      if (this.getStatus(type, item)) return this.$notify(this.getStatus(type, item))
      if (back) {
        item.back = back
        this.$store.dispatch('setSelectCoin', item)
        this.$router.back()
        return
      }
      if (type == 'walletFlow') {
        this.$store.commit('SET_WALLET_COIN', { coinName: item.coinName, coinId: item.coinId })
        this.$router.back()
        return
      }

      this.$store.dispatch('resetSelectCoin')
      if (type === 'recharge') {
        this.$router.push({ path: '/chargingAddress', query: { coinName: item.coinName, coinId: item.coinId, type: type } })
      }
      if (type === 'withdraw') {
        // if (this.user.realNameAuth != "y") {
        //   this.dialog.showDialog = true
        //   this.dialog.showMessage = this.$t('M.user-fail-not-realname')
        //   this.dialog.path = "/identity"
        //   return
        // }
        if (!this.user.payPassword) {
          this.dialog.showDialog = true
          this.dialog.showMessage = this.$t('M.setting_paypassword')
          this.dialog.path = "/setExchangePwd"
          return
        }
        if (item.realNameWithdrawAmount > 0) {
          return this.$router.push({ path: '/withdrawal', query: { coinName: item.coinName, coinId: item.coinId, type: type } })
        }
        // if (item.realNameWithdrawAmount <= 0 || this.user.advancedAuth != "pass") {
        //   this.dialog.showDialog = true
        //   this.dialog.showMessage = this.$t('M.no_Senior_certification')
        //   this.dialog.path = "/identity"
        //   return
        // }
        this.$router.push({ path: '/withdrawal', query: { coinName: item.coinName, coinId: item.coinId, type: type } })
      }
    },

    onClosed() {
      this.dialog.showDialog = false
      setTimeout(() => {
        this.dialog = {
          showDialog: false,
          showMessage: '',
          path: ''
        }
      }, 500);
    },
    onConfirm() {
      this.onClosed()
      setTimeout(() => {
        if (this.dialog.path) {
          this.$router.push(this.dialog.path)
        }
      }, 100);

    }

  },
};
</script>

<style lang="scss" scoped>
.recharging {
  height: 100%;
  overflow-y: scroll;
}
.nav_bar {
  .left {
    width: 295px;
    height: 38px;
    background: #f8f8fb;
    border-radius: 4px;
    .van-cell {
      padding: 0 10px;
      height: 38px;
      line-height: 38px;
    }
  }
  .icon_search {
    width: 17px;
    height: 17px;
  }
}

.recharging_list {
  padding: 20px 15px 0;
  .recharging_list_item {
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    border-bottom: 1px solid #c7ccd8;
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #ccc;
      margin-right: 10px;
    }
  }
}
::v-deep .nav_bar .van-cell {
  background: transparent;
  .van-field__left-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .van-icon-clear {
    color: #c7ccd8;
  }
}
</style>
