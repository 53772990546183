<template>
  <div class="container">
    <nav-bar :title="query.title" />
    <van-sticky :offset-top="50" class="sticky">
      <!-- <div class="top_dropdown">
        <van-popover v-model="showTrade" trigger="click" :actions="popoverTrade" @select="onSelect" get-container=".van-popover__wrapper">
          <template #reference>
            <div class="popover_item_value">{{popoverTrade[defaultTrade].text}}</div>
          </template>
        </van-popover>
        <van-popover v-model="showTrade" trigger="click" :actions="popoverTrade" @select="onSelect" get-container=".van-popover__wrapper">
          <template #reference>
            <div class="popover_item_value">{{popoverTrade[defaultTrade].text}}</div>
          </template>
        </van-popover>
        <van-popover v-model="showTrade" trigger="click" :actions="popoverTrade" @select="onSelect" get-container=".van-popover__wrapper">
          <template #reference>
            <div class="popover_item_value">{{popoverTrade[defaultTrade].text}}</div>
          </template>
        </van-popover>
      </div> -->

      <van-dropdown-menu active-color="#00b897">
        <van-dropdown-item v-model="queryParams.tradeId" :options="optionTrade" />
        <van-dropdown-item v-model="queryParams.term" :options="option1" />
        <van-dropdown-item v-model="queryParams.tradeType" :options="option2" />
      </van-dropdown-menu>

      <!-- <dropdown :show="true">
        <div slot="item" class="dropdown_cell">
          <dropdown-item v-model="queryParams.tradeId" :options="optionTrade" />
        </div>
        <div slot="item" class="dropdown_cell"> 时长</div>
      </dropdown> -->

    </van-sticky>

    <!-- 列表 -->
    <van-pull-refresh v-model="refreshing" :pulling-text="$t('M.MJRefreshHeaderIdleText')" :loosing-text="$t('M.MJRefreshHeaderPullingText')"
      :loading-text="$t('M.loading')" @refresh="onRefresh">
      <van-list class="list" v-model="loading" offset="10" :finished="finished" :loading-text="$t('M.loading')" @load="onLoad">
        <div v-if="refreshing" slot="loading"></div>
        <div class="item" v-for="(item,i) in list" :key="i">
          <div class="row">
            <div class="date">{{item.createTime}}</div>
            <div v-if="item.tradeType== 207" class="return">+{{item.totalChange}}</div>
          </div>
          <div class="row item_bottom">
            <div class="name"> <span>{{item.tradeDesc}}</span></div>
            <div class="">{{item.sellCoinNickname}} {{item.term}}</div>

            <div v-if="item.tradeType== 200 || item.tradeType== 201">{{$t('M.balance')}} {{item.total}} </div>
            <div v-else-if="item.tradeType== 207">{{$t('M.balance')}} {{item.total}} </div>
            <div v-else>{{item.tradeType== 203?'+':''}}{{item.totalChange}} {{item.coinName}} </div>

            <!-- tradeType -->
          </div>

        </div>
        <div class="noData" v-if="!list.length && finished">
          <img class="imgNoData" src="@/assets/img/public/nodata.png" alt="" srcset="" />
          <p class="text">{{$t('M.no_data')}}</p>
        </div>
      </van-list>
    </van-pull-refresh>

  </div>
</template>


<script>
import navBar from '@/components/navBar';
// import dropdown from '@/components/dropdown';
// import dropdownItem from '@/components/dropdown/dropdownItem.vue';

export default {
  name: 'secondContractFlow',
  components: {
    navBar
  },
  data() {
    return {
      query: this.$route.query,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      finishedText: this.$t('M.no_more_data'),
      queryParams: {
        pageSize: 10,
        tradeId: '',
        term: '',
        tradeType: '',
      },
      pageNumber: 1,


      optionTrade: [{ text: this.$t('M.Sec Swap'), value: '' }],
      option1: [{ text: this.$t('M.duration'), value: '' }],
      option2: [{ text: this.$t('M.AllType'), value: '' }],
      date: '',


      // showTrade: false,
      // defaultTrade: 0,
      // popoverTrade: [
      //   { text: '秒合约', value: 0, className: 'popover_item_active' },
      //   { text: 'ETH/USDT', value: 1 },
      // ],



    };
  },
  watch: {
    queryParams: {
      handler() {
        this.refreshing = false;
        this.pageNumber = 1
        this.onLoad()
      },
      deep: true
    }
  },
  beforeMount() {
    this.getTransactionPair()
    this.getTerm()
    this.getTradeType()
  },

  mounted() {
  },

  methods: {

    // 交易对
    getTransactionPair() {
      this.$api.getTransactionPair().then((response) => {
        if (!response.meta.success) return
        const option = response.data.map((item) => ({ text: item.name, value: item.value })) || []
        this.optionTrade = this.optionTrade.concat(option)
      })
    },
    // 时长
    getTerm() {
      this.$api.getTerm().then((response) => {
        if (!response.meta.success) return
        const option = response.data.map((item) => ({ text: item.name, value: item.value })) || []
        this.option1 = this.option1.concat(option)
      })
    },
    // 类型
    getTradeType() {
      this.$api.getTradeType().then((response) => {
        if (!response.meta.success) return
        const option = response.data.map((item) => ({ text: item.name, value: item.value })) || []
        this.option2 = this.option2.concat(option)
      })
    },

    getList() {
      const params = { ...this.queryParams, pageNumber: this.pageNumber }
      this.$api.optionLog(params).then((response) => {
        if (!response.meta.success) return
        const { list, total } = response.data
        this.loading = false;
        if (this.pageNumber == 1) {
          this.list = list
        } else {
          this.list = this.list.concat(list)

        }
        this.pageNumber += 1
        if (this.list.length >= total) {
          this.finished = true;
        }
      })
    },

    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.pageNumber = 1
          this.list = [];
          this.refreshing = false;
        }
        this.getList()
      }, 500);

    },


    onRefresh() {

      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      this.loading = true;
      this.onLoad();
    },

    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.showDate = false;
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    onSelect(e) {
      console.log(e);

    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .van-dropdown-menu__bar {
    box-shadow: 0 0 0 !important;
  }
  .van-dropdown-item__option {
    .van-cell__value {
      display: none;
    }
  }
  .van-popup--top {
    // width: 100px;
  }
}
</style>
<style lang="scss" scoped>
.list {
  padding: 15px;
  .item {
    width: 100%;
    height: 69px;
    border-radius: 3px;
    border-bottom: 1px solid #ebebf0;
    margin-bottom: 10px;
    padding: 0 10px 5px;
    .row {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      .date {
        font-size: 12px;
        color: #9299a9;
      }
    }
    .item_bottom {
      > div {
        flex: 1;
        font-size: 13px;
        color: #131520;
      }
      > div:nth-child(2) {
        text-align: center;
      }
      > div:nth-child(3) {
        text-align: right;
      }
    }
  }
  .return {
    font-size: 13px;
    color: #18ae83;
  }
}
.top_dropdown {
  width: 100%;
  height: 42px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  // justify-content: space-around;
  > span {
    position: relative;
    display: inline-block;
    flex: 1;
  }
}
</style>
