<template>
  <div class="news container">
    <van-nav-bar :title="$t('M.announcement_center')" left-text="" left-arrow @click-left="onClickLeft">
    </van-nav-bar>

    <van-pull-refresh class="content" v-model="refreshing" @refresh="onRefresh" :pulling-text="$t('M.MJRefreshHeaderIdleText')"
      :loosing-text="$t('M.MJRefreshHeaderPullingText')" :loading-text="$t('M.loading')">
      <div class="listItem" v-for="item in listNew" :key="item" :title="item" @click="lookContent(item)">
        <div class="box"></div>
        <div class="showcontent">
          <p class="tit">{{item.title}}</p>
          <p class="date">{{item.createTime}}</p>
        </div>
        <img src="../../assets/img/public/right.png" class="right" alt="" srcset="">
      </div>
    </van-pull-refresh>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      listNew: [],
      refreshing: false,
      finished: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters([
      'partnerId', 'lang', "isLogin"
    ]),
  },
  mounted() {
    this.loadList()
  },
  methods: {
    loadList() {
      this.$api.pageQueryNew({ language: this.lang, partnerId: this.partnerId }).then((response) => {
        if (!response.meta.success) return
        this.listNew = response.data.list
        this.refreshing = false;
      })
    },
    lookContent(item) {
      this.$router.push({
        name: 'newsContent', query: { id: item.id }
      })
    },
    /* 刷新事件 */
    onRefresh() {
      this.loadList();
    },
    onClickLeft() {
      this.$router.back()
    },
  }
}
</script>

<style lang="scss" scoped>
.news {
  background: #f8f7fc;
  .content {
    padding: 15px;
    padding-bottom: 50px;
    .listItem {
      width: 345px;
      height: 66px;
      background: #ffffff;
      border-radius: 4px;
      margin-bottom: 10px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 15px;
      .box {
        width: 5px;
        height: 29px;
        background: #00b897;
        border-radius: 3px;
      }
      .showcontent {
        flex: 1;
        padding-left: 10px;
        font-size: 15px;
        font-weight: 400;
        color: #191b27;
        line-height: 22px;
        .date {
          font-size: 12px;
        }
      }
      .right {
        width: 15px;
        height: 15px;
      }
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
</style>