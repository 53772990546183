<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-18 10:58:17
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="setPassword container">
    <van-nav-bar :title="!user.password ? $t('M.set_login_password') : $t('M.reset_login_password')" left-text="" :left-arrow="setType != 1"
      @click-left="onClickLeft" />
    <div class="contentHome">
      <div class="inputBox">
        <van-cell-group>
          <van-field v-model="oldPassword" v-if="setType != 1" :label="$t('M.old_pass')" type="password" :placeholder="$t('M.input_old_pass')" />
          <van-field v-model="password" :label="setType == 1 ? $t('M.Password') : $t('M.new_pass')" type="password"
            :placeholder="$t('M.password_wrong')" />
          <van-field v-model="passwordAgain" :label="$t('M.affirm_pass')" type="password" :placeholder="$t('M.again_digits6')" />
        </van-cell-group>
      </div>
      <div class="fistTips" v-if="setType == 1">
        <img src="../../assets/img/userCenter/ic_notice.png" alt="">
        <p> {{ $t('M.Users who register') }}</p>
      </div>
      <div class="yesBtn" @click="send">{{ $t('M.confirm') }}</div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { encrypt } from "@/utils/util.js";
import { setToken } from '@/utils/auth'
export default {
  data() {
    return {
      link: '',
      oldPassword: '',
      password: '',
      passwordAgain: '',
      /* 1,第一次设置 */
      setType: '1'
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
  },
  mounted() {
    // this.$api.getShareLink().then((res) => {
    //   console.log(res)
    //   this.link = res.data.link;
    // })
    this.setType = this.$route.query.type;
  },
  methods: {
    // oldPasswordBlur() {
    //   if (this.oldPassword == '') {
    //     this.$notify(this.$t('M.input_old_pass'))
    //   }
    // },
    // passwordBlur() {
    //   const PWD_REG = /^(?=.*[\d])(?=.*[a-zA-Z])(?=.*[`~!@#$%^&*\(\)_\-\+=<>?:"\{\}\|,.\/;'\\[\]])?.{8,20}$/
    //   if (this.password == ' ') {
    //     this.$notify(this.$t('M.enter_password'))
    //   } else if (!PWD_REG.test(this.password)) {
    //     this.$notify(this.$t('M.password_wrong'))
    //   }
    // },
    // passwordAgainBlur() {
    //   if (this.password !== this.passwordAgain) {
    //     this.$notify(this.$t('M.ios_两次密码不一致请再次输入密码'))
    //     this.password = '';
    //     this.passwordAgain = '';
    //   }
    // },
    send() {
      const PWD_REG = /^(?=.*[\d])(?=.*[a-zA-Z])(?=.*[`~!@#$%^&*\(\)_\-\+=<>?:"\{\}\|,.\/;'\\[\]])?.{8,20}$/

      if (this.setType == 0) {
        if (this.oldPassword == ' ') {
          this.$notify(this.$t('M.input_old_pass'))
          return
        }
      }
      if (this.password == ' ') {
        this.$notify(this.$t('M.enter_password'))
        return
      // } else if (!PWD_REG.test(this.password)) {
      } else if (this.password== ' ') {
        this.$notify(this.$t('M.password_wrong'))
        return
      } else if (this.password !== this.passwordAgain) {
        this.$notify(this.$t('M.ios_两次密码不一致请再次输入密码'))
        this.password = '';
        this.passwordAgain = '';
        return
      } else {

        let params = {
          partnerNo: '1000010001',
          id: this.$route.query.id,
          confirmPassword: encrypt(this.password), // 新登录密码
          email: '',
          timestamp: new Date().getTime(),
          checkCode: '',
          userName: this.$route.query.userName,
          newPassword: encrypt(this.password),
          partnerId: '472437831826935808'
        }
        if (this.setType == 1) {
          this.$api.setPwd(params).then((res) => {
            if (res.meta.success) {
              this.$notify(res.meta.message)
              // this.$router.replace("login")
              this.userLoginForStep1(params)
            }
          })
        } else {
          params.oldPassword = encrypt(this.oldPassword);
          params.newPassword = encrypt(this.password);
          this.$api.setPassword(params).then((res) => {
            if (res.meta.success) {
              this.$store.dispatch('clearUserData')
              this.$notify(res.meta.message)
              this.$router.push("login")
            }
          })
        }
      }
    },
    // 密码登录1
    userLoginForStep1(params) {
      let sendData = {
        userName: params.userName,
        password: params.confirmPassword,
        partnerId: params.partnerId,
        clientType: "native",
        partnerNo: 1000010001
      }
      this.$api.userLoginForStep1(sendData).then((response) => {
        if (!response.meta.success) return
        const { token, firstLogin, isEnableGoogle, isEnablePhone, isEnableMail, failNum, loginIpEquals, countryCode, phone, email } = response.data
        this.tempToken = token
        this.isEnableGoogle = isEnableGoogle

        if (!loginIpEquals || failNum >= 3) {  // 异常验证
          this.$router.replace("login")
          return
        }

        this.loginForStep2({ token: token, userName: sendData.userName })
      })

    },
    // 确认登录-获取token
    loginForStep2(params) {
      params.loginFlag = 2;
      this.$api.loginForStep2(params).then((response) => {
        if (!response.meta.success) return
        const { token, userInfo: { password, userName, id } } = response.data
        setToken(token)
        this.$store.commit('SET_TOKEN', token)
        this.$store.dispatch('getUserData')
        this.$router.push('index')
      })

    },
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.setPassword {
  font-size: 12px;
  background: #f8f8f8;
}

.contentHome {
  padding: 10px;
  height: 100vh;

  .inputBox {
    background-color: #fff;
    border-radius: 5px;
  }

  .fistTips {
    margin-top: 10px;
    display: flex;
    line-height: 20px;
    align-items: center;

    img {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }
}

.yesBtn {
  width: 88%;
  height: 46px;
  background: #00b897;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  line-height: 46px;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;
}

::v-deep {
  .van-nav-bar .van-icon {
    color: #000;
  }

  .van-field__control {
    text-align: right;
  }
}
</style>