export let Socket = ''
let setIntervalWesocketPush = null
let urls = ''
let merchantIds = ''
let oneName = localStorage.getItem('oneName')
let coinName = JSON.parse(localStorage.getItem('coinObject')) ? JSON.parse(localStorage.getItem('coinObject')).sellCoinName : 'btc'


/**
 * 建立websocket连接
 * @param {string} url ws地址
 */
export const createSocket = (url, merchantId) => {
  urls = url
  merchantIds = merchantId
  const BrowserWebSocket = window.WebSocket || window.MozWebSocket
  Socket = new BrowserWebSocket(urls)
  Socket.onopen = onopenWS
  Socket.onmessage = onmessageWS
  Socket.onerror = onerrorWS
  Socket.onclose = oncloseWS

}

/**打开WS之后发送心跳 */
const onopenWS = () => {
  // let ct_Type = JSON.parse(localStorage.getItem('coinObject')) ? JSON.parse(localStorage.getItem('coinObject')).sellCoinName : 'btc';
  let ct_Type = JSON.parse(localStorage.getItem('newOptionTrad')) ? JSON.parse(localStorage.getItem('newOptionTrad')).sellCoinName : 'btc';
  
  Socket.send(JSON.stringify({
    tag: 'SUB',
    content: `market.${merchantIds}.OPTIONDATA`,
    id: 'app'
  }))
  //实时价格表
  Socket.send(JSON.stringify({
    tag: 'SUB',
    content: `market.${ct_Type ? ct_Type.toLowerCase() : localStorage.getItem('oneName')}.option_kline_last_price_V2.min5`,
    id: 'app'
  }))
  sendPing(5000)
}

/**连接失败重连 */
const onerrorWS = () => {
  Socket.close()
  Socket = null;
  if (!Socket || Socket.readyState !== 3) {
    Socket = null
  }
}

/**WS数据接收统一处理 */
export const onmessageWS = e => {
  window.dispatchEvent(new CustomEvent('onmessageWS', {
    detail: {
      data: e.data
    }
  }))
}

/**
 * 发送数据但连接未建立时进行处理等待重发
 * @param {any} message 需要发送的数据
 */
const connecting = message => {
  setTimeout(() => {
    if (Socket.readyState === 0) {
      connecting(message)
    } else {
      Socket.send(JSON.stringify(message))
    }
  }, 1000)
}


/**
 * 发送数据
 * @param {any} message 需要发送的数据
 */
export const sendWSPush = message => {
  if (Socket !== null && Socket.readyState === 3) {
    Socket.close()
    createSocket(urls, merchantIds)
  } else if (Socket.readyState === 1) {
    Socket.send(JSON.stringify(message))
  } else if (Socket.readyState === 0) {
    connecting(message)
  }
}

/**断开重连 */
export const oncloseWS = () => {
  // coinName = ''
  // oneName = ''
  // 1 主动断开  3 断开重连
  if (Socket && Socket.readyState == 1) {
    Socket.close()
  }
  if (!Socket || Socket.readyState == 3) {
    let urlss = window.location.href.split('/');
    let url_length = urlss.length - 1;
    if (urlss[url_length] == 'newOption') {
      setTimeout(() => {
        if (Socket) {
          Socket.close()
        }
        createSocket(urls, merchantIds)
        sessionStorage.setItem('boltLINE', '0')
      }, 500);
    }
  }
}
/**发送心跳
 * @param {number} time 心跳间隔毫秒 默认5000
 * @param {string} ping 心跳名称 默认字符串ping
 */
export const sendPing = (time = 1000, ping = 'ping') => {
  clearInterval(setIntervalWesocketPush)
}