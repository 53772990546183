<!--
 * @Author:
 * @Date: 2022-07-04 14:59:08
 * @LastEditors:
 * @LastEditTime: 2022-07-18 17:49:29
 * @FilePath: \exchange-h5\src\views\IEO\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="ieo container">
    <van-sticky>
      <div>
        <van-nav-bar :title="$t('M.Conversion Starter')" left-text="" left-arrow @click-left="onClickLeft" @click-right="onClickRight">
          <template #right>
            <img src="../../assets/img/IEO/logICON.png" alt="" srcset="" style="width:20px;height:20px;">
          </template>
        </van-nav-bar>

        <div class="banner">
          <img :src="langbanner" alt="" srcset="" style="width:100%;">
        </div>
        <van-tabs v-model="active" class="tabBar" line-width="16px" line-height="3px" color="#00b897" title-active-color="#00b897"
          @change="tabBarChange">
          <van-tab>
            <template #title>
              {{$t('M.All2')}}
              <span class="titleSmallNum">{{count.allCount > 99 ? "99+":count.allCount}}</span>
            </template>
          </van-tab>
          <van-tab>
            <template #title>
              {{$t('M.Ongoing2')}}
              <span class="titleSmallNum">{{count.ongoingCount}}</span>
            </template>
          </van-tab>
          <van-tab>
            <template #title>
              {{$t('M.Near2')}}
              <span class="titleSmallNum">{{count.comingCount}}</span>
            </template>
          </van-tab>
          <van-tab>
            <template #title>
              {{$t('M.Ended2')}}
              <span class="titleSmallNum">{{ count.endedCount > 99 ? "99+":count.endedCount}}</span>
            </template>
          </van-tab>
        </van-tabs>
      </div>
    </van-sticky>

    <!-- 列表页面 -->
    <div class="listBox" style="min-height:50%;">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :pulling-text="$t('M.MJRefreshHeaderIdleText')"
        :loosing-text="$t('M.MJRefreshHeaderPullingText')" :loading-text="$t('M.loading')">
        <van-list v-model="listLoading" :finished="finished" finished-text="" @load="loadList" loading-text="loading...">
          <Card v-for=" (item,idx) in listData" :key="idx" :cardData="item" @updata="onRefresh" />
        </van-list>
        <div class="noData" v-if="listData.length == 0 && finished == true">
          <img class="imgNoData" src="../../assets/img/public/nodata.png" alt="" srcset="" />
          <p class="text">{{$t('M.no_data')}}</p>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Card from "./Card.vue"
export default {
  components: { Card },
  data() {
    return {
      listLoading: false,
      finished: false,
      active: '',
      currentPage: 1,
      status: '',
      listData: [],
      refreshing: false,
      count: {
        allCount: "0",
        comingCount: "0",
        endedCount: "0",
        ongoingCount: "0",
      },
    }
  },
  computed: {
    ...mapGetters([
      'user', 'lang'
    ]),
    langbanner() {
      // ba_BA zh_TW ko_KR ja_JP vi_VN en_US
      if (this.lang) {
        return require(`@/assets/img/IEO/banner_${this.lang}.png`)
      }
      return require('@/assets/img/IEO/banner_en_US.png')
    }
  },
  watch: {},
  mounted() {
    this.getCountNum()
    // setTimeout(() => {
    //   this.listLoading = true;
    // }, 50);
  },
  methods: {
    /* 获取综合数量 */
    getCountNum() {
      this.$api.count({ partnerId: '472437831826935808' }).then((res) => {
        this.count = res.data;
      })
    },
    /* 下拉刷新组件 */
    onRefresh() {
      this.finished = false;
      this.listData = []
      this.currentPage = 1;
      this.loadList()
      this.getCountNum()
    },
    /* 切换tab */
    tabBarChange() {
      this.finished = false;
      this.listData = []
      switch (this.active) {
        case 0:
          this.status = ''
          break;
        case 1:
          this.status = 'ongoing'
          break;
        case 2:
          this.status = 'coming'
          break;
        case 3:
          this.status = 'ended'
          break;
      }

      this.currentPage = 1;
      this.loadList()
    },
    /* 加载列表 */
    loadList() {
      let parame = {
        pageSize: 10,
        currentPage: this.currentPage,
        partnerId: '472437831826935808',
        status: this.status,
      }
      this.$api.pageListProject(parame).then((res) => {
        if (res.data.list.length < 10) {
          this.finished = true;
        }
        this.listLoading = false;
        this.currentPage = res.data.pageNum + 1;
        if (res.data.pageNum == 1) {
          this.listData = []
        }
        this.listData = this.listData.concat(res.data.list);
        this.refreshing = false;
      })
    },

    onClickLeft() {
      this.$router.replace({ name: 'home' })
    },
    onClickRight() {
      this.$router.replace({ name: 'IEOlog' })
    }
  }
}
</script>

<style lang="scss" scoped>
.ieo {
  font-size: 12px;
  background-color: #f8f8f8;
  position: relative;
  width: 100%;
  height: 100%;
  // display: flex;
  /* 设置子元素的排列方向 */
  // flex-direction: column;
  /* 设置子元素在该方向上的对齐方式 */
  // justify-content: space-between;
  min-height: 100vh;
  .banner {
    width: 100%;
    height: 141px;
  }
  .tabBar {
    font-size: 16px;
    .titleSmallNum {
      font-size: 10px;
      line-height: 10px;
      //   vertical-align: text-bottom;
      position: absolute;
      top: 0px;
      right: -20px;
      width: 18px;
      text-align: left;
    }
  }
  .listBox {
    padding: 12px 15px;
    padding-bottom: 70px;
    // padding-top: 0;
    // overflow-y: scroll;
    // position: absolute;
    // top: 235px;
    // bottom: 0;
    // left: 0;
    // right: 0;
    .listItem {
      background-color: #fff;
      border-radius: 5px;
      padding: 10px 10px;
      line-height: 24px;
      margin-bottom: 15px;
      .line1 {
        display: flex;
        .itemTotal {
          flex: 1.2;
        }
        .itemOver {
          flex: 1;
        }
      }

      .timeBox {
        line-height: 36px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 50px;
}
::v-deep .van-tab {
  flex: auto;
  font-size: 14px;
}
::v-deep .van-tab__text--ellipsis {
  position: relative;
  overflow: visible;
  white-space: nowrap;
}
</style>