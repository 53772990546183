<template>
  <div class='container'>
    <nav-bar :title="$t('M.exchange_history')"> </nav-bar>
    <!-- 列表 -->
    <van-pull-refresh v-model="refreshing" :pulling-text="$t('M.MJRefreshHeaderIdleText')" :loosing-text="$t('M.MJRefreshHeaderPullingText')"
      :loading-text="$t('M.loading')" @refresh="onRefresh">
      <van-list class="list" v-model="loading" offset="10" :finished="finished" :loading-text="$t('M.loading')" @load="onLoad">
        <div v-if="refreshing" slot="loading"></div>
        <div v-if="list.length" class="tabs">
          <span>{{$t('M.free')}} </span>
          <span>{{$t('M.total_exchange')}} </span>
        </div>

        <div class="item" v-for="(item,i) in list" :key="i">
          <div class="row">
            <div class="name">
              <span>{{item.exchangeFee}} {{item.targetCoinName}}</span>
              <div class="date">{{item.createTime}}</div>
            </div>
          </div>
          <div class="row">
            <div class="num">{{item.exchangeAmount}} {{item.targetCoinName}}</div>
          </div>
        </div>

        <div class="noData" v-if="!list.length && finished">
          <img class="imgNoData" src="@/assets/img/public/nodata.png" alt="" srcset="" />
          <p class="text">{{$t('M.no_data')}}</p>
        </div>
      </van-list>
    </van-pull-refresh>

  </div>
</template>
<script>
import navBar from '@/components/navBar';

//import {} from "@/api/Controller";
export default {
  components: { navBar },
  props: {},
  data() {
    return {

      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      finishedText: this.$t('M.no_more_data'),
      pageNumber: 1,
    };
  },
  computed: {},
  watch: {},
  beforeMount() { },
  mounted() { },
  methods: {


    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.pageNumber = 1
          this.list = [];
          this.refreshing = false;
        }
        this.getList()
      }, 500);

    },


    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },

    getList() {
      const params = { pageNumber: this.pageNumber, }
      this.$api.findPage(params).then((response) => {
        if (!response.meta.success) return
        const { list, total } = response.data
        this.loading = false;
        if (this.pageNumber == 1) {
          this.list = list
        } else {
          this.list = this.list.concat(list)
        }
        this.pageNumber += 1
        if (this.list.length >= total) {
          this.finished = true;
        }
      })
    },

  },
};
</script>
<style lang="scss" scoped>
.list {
  height: 100%;
  padding: 10px;
  .item {
    width: 100%;
    border-radius: 3px;
    // border-bottom: 1px solid #ebebf0;
    margin-bottom: 10px;
    padding: 0 10px 10px;
    display: flex;
    justify-content: space-between;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name,
      .num {
        font-size: 12px;
        color: #191b27;
      }
      .date {
        margin-top: 8px;
        font-size: 10px;
        font-family: HarmonyOS_Sans;
        color: #9299a9;
      }
    }
  }
  .tabs {
    width: 100%;
    padding: 0 10px;
    height: 40px;
    font-size: 13px;
    color: #9299a9;
    display: flex;
    justify-content: space-between;
  }
}
</style>