<template>
  <div class="ranking tabs">
    <van-tabs v-model="active" border color="#00b897" line-width='16px' @change="changTabs">
      <van-tab v-for="(item1,idx) in tabList" :key="idx" :title="item1.tie" :disabled="disabled">
        <div class="cell">
          <div class=""> {{$t('M.trading_volume')}} </div>
          <div class=""> {{$t('M.the_latest_price')}} </div>
          <div class="" @click="changeUp"> {{$t('M.DatChange')}}
<!--            <div class="typeBox" v-if="rankType == true && active == 0">-->
<!--              <img src="../../../../assets/img/home/triangleNew.png" alt="">-->
<!--              <img src="../../../../assets/img/home/triangleActiveNew.png" alt="">-->
<!--            </div>-->
<!--            <div class="typeBox typeBox2" v-if="rankType == false && active == 0 ">-->
<!--              <img src="../../../../assets/img/home/triangleActiveNew.png" alt="">-->
<!--              <img src="../../../../assets/img/home/triangleNew.png" alt="">-->
<!--            </div>-->
            <div class="typeBox" v-if="rankType == true && active == 0">
              <img src="../../../../assets/img/home/triangleActiveJiantou.png" alt="">
            </div>
            <div class="typeBox typeBox2" v-if="rankType == false && active == 0 ">
              <img src="../../../../assets/img/home/triangleActiveJiantou.png" alt="">
            </div>
          </div>
        </div>
        <div class="list">
          <div class="item" v-for="(item,i) in list" :key="i" @click="goKline(item)">
            <div class="sum">
              <div>
                <span class="coinName">{{item.sellSymbol}}</span>
                <span class=""> / {{item.buySymbol}}</span>
              </div>
              <div class="">{{item.buy}}</div>
            </div>
            <div class="price">
              <div class="">{{item.last}}</div>
              <div class="">USD {{cutOutPointLength(item.last * rate,2)}}</div>
            </div>
            <div :class="['button',item.chg == 0 ? 'button2':'',item.chg < 0 ? 'button3':'',]">{{cutOutPointLength(Number(item.chg),2)}}%</div>
          </div>
        </div>
        <div class="noData" v-if="list.length == 0">
          <img class="imgNoData" src="../../../../assets/img/public/nodata.png" alt="" srcset="" />
          <p class="text">{{$t('M.no_data')}}</p>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// import Socket from './socket'

import {mapGetters} from 'vuex'

export default {
  name: 'ranking',

  data() {
    return {
      active: 0,
      list: [],
      tabList: [
        {
          tie: this.$t("M.TopGainers"),
          type: 'GO_UP_RANKING',
          type1: 'TO_FALL_RANKING',
        },
        {
          tie: this.$t("M.Newest"),
          type: 'NEW_COIN_RANKING'
        },
        {
          tie: this.$t("M.Hot"),
          type: 'VOL_RANKING'
        },
      ],
      rankType: true,
      socket: null,
      pako: require("pako"),
      socketType: '',
      disabled: false,
      closeType: 0,
      rate: 0
    };
  },
  computed: {
    ...mapGetters([
    ]),
  },

  mounted() {
    this.tradeAreaPrice()
    this.initPage()
    this.initSocket()
  },

  methods: {
    /* 去K线页面 */
    goKline(item) {
      this.$router.push({
        name: `TradeLine`,
        params: {
          symbol: item.tradeName,
        }
      })
    },
    // 汇率
    tradeAreaPrice() {
      this.$api.tradeAreaPrice({ partnerId: "472437831826935808", currency: 'USD' }).then((response) => {
        response.data.usd.findIndex(item => {
          if (item.coinName == "USDT") {
            this.rate = item.coinPrice;
          }
          return item.coinName == "USDT"
        })
      })
    },

    /* 改变涨跌幅方向 */
    changeUp() {
      this.rankType = !this.rankType;
      this.loadList()
      this.onopenWS()
    },
    initPage() {
      this.loadList()
    },
    changTabs() {
      this.disabled = true
      this.loadList()
      this.onopenWS()
    },
    loadList() {

      let type = this.tabList[this.active].type;
      if (this.active == 0) {
        if (this.rankType) {
          type = "GO_UP_RANKING"
        } else {
          type = "TO_FALL_RANKING"
        }
      }
      this.$api.homeRanking({
        partnerNo: "1000010001",
        type: type,
        partnerId: "472437831826935808",
      }).then((res) => {
        this.list = []
        this.list = res.data;
        this.list && this.list.forEach(item => { item.last = this.cutOutPointLength(item.last), item.buy = this.cutOutPointLength(item.buy) })
        this.disabled = false
      })
    },
    initSocket() {
      const BrowserWebSocket = window.WebSocket || window.MozWebSocket
      this.socket = new BrowserWebSocket(`wss://${this.$apiUrl}/v1/websocket/market`)
      this.socket.onopen = this.onopenWS
      this.socket.onmessage = this.onmessageWS
      this.socket.onerror = this.onerrorWS
      this.socket.onclose = this.oncloseWS
    },
    onopenWS() {

      if (this.socketType != '') {
        this.socket.send(JSON.stringify({
          tag: 'CANCEL',
          content: `market.0.${this.socketType}`,
          id: 'ios',
          domain: '472437831826935808',
        }))

      }

      if (this.active == 0) {
        if (this.rankType) {
          this.socketType = "go_up_ranking"
        } else {
          this.socketType = "to_fall_ranking"
        }
      } else if (this.active == 1) {
        this.socketType = "new_coin_ranking"
      } else if (this.active == 2) {
        this.socketType = "vol_ranking"
      }


      this.socket.send(JSON.stringify({
        tag: 'SUB',
        content: `market.0.${this.socketType}`,
        id: 'ios',
        domain: '472437831826935808',
      }))
    },
    onmessageWS(data) {
      let blob = data.data;
      let reader = new FileReader();
      reader.readAsBinaryString(blob);
      reader.onload = (res) => {
        let text = this.pako.inflate(res.target.result, { to: "string" });
        let msg = JSON.parse(text);
        if (msg.type == 1) {
          this.list = msg.data;
          this.list && this.list.forEach(item => { item.last = this.cutOutPointLength(item.last), item.buy = this.cutOutPointLength(item.buy) })
        }
      };
    },
    onerrorWS() {
      this.socket.close()
      if(setIntervalWesocketPush){clearInterval(setIntervalWesocketPush)}
      console.log('连接失败重连中')
      if (this.socket.readyState !== 3) {
        this.socket = null
        this.initSocket()
      }
    },
    oncloseWS() {
      console.log('websocket已断开....正在尝试重连')
      if (this.socket.readyState !== 2 && this.closeType !== 1) {
        this.socket = null
        this.initSocket()
      }
    },
  },
  beforeDestroy() {
    this.closeType = 1;
    this.socket.close()
  },
};
</script>

<style lang="scss" scoped>
.ranking {
  .cell {
    width: 100%;
    display: flex;
    align-items: center;
    > div {
      height: 40px;
      flex: 1;
      font-size: 12px;
      color: #9299a9;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > div:first-child {
      justify-content: flex-start;
    }
    > div:last-child {
      justify-content: flex-end;
    }
  }
  .list {
    .item {
      height: 60px;
      font-size: 11px;
      color: #9299a9;
      border-bottom: 1px solid #ebebf0;
      display: flex;
      align-items: center;
      .sum {
        flex: 1;
        > div:first-child {
          margin-bottom: 5px;
          > span:first-child {
            font-size: 16px;
            font-weight: 600;
            color: #191b27;
          }
        }
      }
      .price {
        flex: 0.8;
        > div:first-child {
          font-size: 16px;
          font-weight: 600;
          color: #191b27;
          margin-bottom: 5px;
        }
      }
      .button {
        padding: 0 10px;
        min-width: 73px;
        height: 33px;
        line-height: 33px;
        text-align: center;
        border-radius: 5px;
        background: rgba(24, 174, 131, 1);
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
      }
      .button2 {
        background: #a8a8ac;
      }
      .button3 {
        background: #EC0070;
      }
    }
  }
  .typeBox {
    display: flex;
    flex-direction: column;
    img {
      width: 5px;
    }
    img:first-child {
      margin-bottom: 2px;
    }
  }
  .typeBox2 {
    img {
      transform: rotateX(180deg);
    }
  }
}
::v-deep {
  .van-tabs {
    .van-tabs__content {
      padding: 0 15px;
    }
  }
}
::v-deep .van-tab__text--ellipsis {
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  -webkit-box-orient: horizontal;
}
</style>