<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-15 15:54:21
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="Card">
    <div class="listItem">
      <div class="box">
        <!-- 质押数量： -->
        <div><span class="name">{{$t('M.Pledge_Amount')}}：</span><span class="value">
            <!-- 不需要审核 -->
            <span v-if="cardData.checkFlag == '-1'">{{cardData.buyCount}}</span>
            <!-- 未审核 -->
            <span v-if="cardData.checkFlag == 0 || cardData.checkFlag == 2">{{cardData.buyCount}}</span>
            <!-- 审核通过 -->
            <span v-if="cardData.checkFlag == 1">{{cardData.checkPassNum}}</span>
            {{cardData.coinName}}
          </span></div>
        <!-- 累计收益： -->
        <div><span class="name">{{$t('M.cumulative_income:')}}</span><span class="value">{{cardData.alreadyIncome}}{{cardData.coinName}}</span></div>
        <!-- 违约金比例： -->
        <div v-if="!showquxiaoBtn"><span class="name">{{$t('M.Default Ratio')}}：</span><span class="value">{{cardData.breakRate}}%</span></div>
        <!-- 违约金： -->
        <div v-if="cardData.status == 3"><span class="name">{{$t('M.Violation_Loss')}}：</span><span
            class="value">{{cardData.breakCount}}{{cardData.coinName}}</span></div>
        <!-- 周期： -->
        <div><span class="name">{{$t('M.Cycle')}}：</span><span class="value">{{cardData.period}}{{$t('M.day_income')}}</span></div>
        <!-- 已锁仓： -->
        <div><span class="name">{{$t('M.Locked2')}}：</span><span class="value">{{cardData.lockedDays}}{{$t('M.day_income')}}</span></div>
        <!-- 违约金： -->
        <div v-if="cardData.status == 1 && cardData.checkFlag == 0"><span class="value" style="color:#00b897;">{{$t('M.Not Reviewed')}}</span></div>
      </div>
      <!-- 开始时间 -->
      <div><span class="name">{{$t('M.Start Time')}}：</span><span class="value">{{cardData.checkTime ? cardData.checkTime : "--"}}</span></div>
      <!-- 取消时间 -->
      <div v-if="cardData.status == 3"><span class="name">{{$t('M.cancelTime')}}：</span><span class="value">{{cardData.updateTime}}</span></div>
      <!-- 到账时间 -->
      <div v-if="cardData.status == 2"><span class="name">{{$t('M.Arrival Time')}}：</span><span class="value">{{cardData.endSettlementDate}}</span>
      </div>
      <!-- 审核未通过的提示 -->
      <div v-if="cardData.status==1 && cardData.walletReturnNum>0" style="color:#E54C67;">{{$t('M.LockUpTips',[cardData.walletReturnNum,cardData.coinName])}}</div>

      <!-- 取消质押 -->
      <div class="closeBox" v-if="cardData.status == 1 && (cardData.checkFlag==-1 || cardData.checkFlag == 1)">
        <div class="left">
          <img src="../../assets/img/IEO/time.png" alt="">
          {{$t('M.Time_Left')}}: {{Days}}{{$t('M.Day-3')}}{{Hours}}:{{Minutes}}:{{Seconds}}
        </div>
        <div class="closeBtn" v-if="!showquxiaoBtn" @click="closefun">
          {{$t('M.Cancel_Pledge')}}
        </div>
      </div>
      <div v-if="cardData.status==3 && cardData.checkFlag==2" style="color:#E54C67;word-break: break-all;">
        <div v-if="lang == 'zh_TW'"> {{$t('M.Denial Reason')}} {{cardData.refuseReasonTw}}</div>
        <div v-if="lang == 'en_US'"> {{$t('M.Denial Reason')}} {{cardData.refuseReasonEn}}</div>
        <div v-if="lang == 'ba_BA'"> {{$t('M.Denial Reason')}} {{cardData.refuseReasonBr}}</div>
        <div v-if="lang == 'ko_KR'"> {{$t('M.Denial Reason')}} {{cardData.refuseReasonKo}}</div>
        <div v-if="lang == 'ja_JP'"> {{$t('M.Denial Reason')}} {{cardData.refuseReasonJa}}</div>
        <div v-if="lang == 'vi_VN'"> {{$t('M.Denial Reason')}} {{cardData.refuseReasonVi}}</div>
      </div>
      <!-- 已取消质押 -->
      <div class="over" v-if="cardData.status == 3">
        {{$t('M.Cancelled2')}}
      </div>
    </div>
    <van-dialog v-model="closeWindow" :title="$t('M.Cancel the pledge?')" show-cancel-button confirm-button-color="#ffffff" @confirm="closeConfirm"
      :confirm-button-text="$t('M.affirm')" :cancel-button-text="$t('M.Cancel')">
      {{$t('M.closeLockUpPopup',[closeData.breakDays,closeData.breakCount,closeData.coinName,closeData.principal,closeData.coinName])}}
    </van-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Dialog } from 'vant';
export default {
  data() {
    return {
      link: '',
      showTimeDown: '',
      state: '',
      closeData: {},
      closeWindow: false,
      endCountDown: 0,
      showquxiaoBtn:false
    }
  },
  props: {
    cardData: Object,
  },
  computed: {
    ...mapGetters([
      'user', 'lang'
    ]),
    Days() {
      return `${this.formatSeconds(this.endCountDown)}`.split("_")[0];
    },
    // 倒计时 小时
    Hours() {
      return `${this.formatSeconds(this.endCountDown)}`.split("_")[1];
    },
    Minutes() {
      return `${this.formatSeconds(this.endCountDown)}`.split("_")[2];
    },
    Seconds() {
      return `${this.formatSeconds(this.endCountDown)}`.split("_")[3];
    },
  },
  destroyed() {
    clearInterval(this.timeObj)
  },
  mounted() {
    this.showquxiaoBtn = localStorage.getItem("lockUpFlag") == "true";
    this.endCountDown = this.cardData.endCountDown
    if (this.endCountDown > 0) {
      this.timeObj = setInterval(() => {
        if (this.endCountDown < 0) {
          clearInterval(this.timeObj)
          setTimeout(() => {
            this.$emit('updata')
          }, 1000);
        }
        this.endCountDown = this.endCountDown - 1000
      }, 1000);
    }
  },
  methods: {
    closefun() {
      this.$api.cancelOrderAlert({ orderId: this.cardData.id }).then((res) => {
        this.closeData = res.data
        this.closeWindow = true
      })
    },
    closeConfirm() {
      this.$api.cancelOrder({ orderId: this.cardData.id }).then((res) => {
        if (res.meta.success) {
          this.$notify(this.$t('M.' + res.meta.i18n_code))
          this.$emit('updata')
        } else {
          this.$notify(res.meta.message)
        }
      })
    },
    formatSeconds(date) {
      function formatTime(time) {
        if (time < 10) time = `0${parseInt(time)}`
        return time
      }
      const timeObj = this.getDateTime(date)
      let {
        dayTime,
        hourTime,
        minuteTime,
        secondTime
      } = timeObj
      let result
      result = `${formatTime(dayTime)}_${formatTime(hourTime)}_${formatTime(minuteTime)}_${formatTime(secondTime)}`
      return result
    },
    getDateTime(date) {
      // 获取总秒数
      let secondTime = parseInt(date / 1000)
      let dayTime = 0 // 天
      let minuteTime = 0 // 分
      let hourTime = 0 // 小时

      // 如果秒数大于60，将秒数转换成整数
      if (secondTime >= 60) {
        // 获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60)
        // 获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60)
        // 如果分钟大于60，将分钟转换成小时
        if (minuteTime >= 60) {
          // 获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60)
          // 获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60)
        }
        if (hourTime >= 24) {
          dayTime = parseInt(hourTime / 24)
          hourTime = parseInt(hourTime % 24)
        }
      }
      return {
        dayTime,
        hourTime,
        minuteTime,
        secondTime
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.Card {
  font-size: 12px;
  .listItem {
    width: 100%;
    background: #ffffff;
    border-radius: 5px;
    padding: 15px 10px;
    margin-bottom: 12px;
    line-height: 25px;
    .box {
      display: flex;
      flex-wrap: wrap;
      div {
        width: 50%;
      }
    }
    .name {
      color: #9299a9;
    }
    .value {
      color: #191b27;
    }
    .closeBox {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 13px;
          height: 13px;
          margin-right: 5px;
        }
      }
      .closeBtn {
        padding: 0 20px;
        height: 30px;
        background: #00b897;
        border-radius: 4px;
        color: #fff;
        text-align: center;
        line-height: 30px;
      }
    }
    .over {
      width: 260px;
      height: 30px;
      background: rgba(0, 184, 151, 0.5);
      border-radius: 4px;
      color: #fff;
      line-height: 30px;
      text-align: center;
      margin: 0 auto;
      margin-top: 10px;
    }
  }
}
::v-deep .van-dialog__content {
  padding: 20px 20px;
}
::v-deep .van-dialog__confirm,
.van-dialog__confirm:active {
  background-color: #00b897;
}
</style>