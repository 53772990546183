<!--
 * @Author: LAPTOP-7587T4F6\Bohan
 * @Date: 2022-08-28 23:53:04
 * @LastEditors: LAPTOP-7587T4F6\Bohan
 * @LastEditTime: 2022-08-29 00:54:28
 * @FilePath: \exchange-h5\src\views\TradeCenter\TradeOrder.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div class="tradeOrder container">
    <!-- <nav-bar class="nav_bar" :title="$t('M.order_history')" @click-left="onClickLeft" /> -->
    <van-sticky>
      <van-nav-bar :title="$t('M.order_history')" left-text="" left-arrow @click-left="onClickLeft" @click-right="onClickRight">
        <template #right>
          <img src="../../assets/img/userCenter/saixuan.png" alt="" srcset="" style="width:0.6rem;height:0.6rem;">
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="listHome">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :pulling-text="$t('M.MJRefreshHeaderIdleText')"
        :loosing-text="$t('M.MJRefreshHeaderPullingText')" :loading-text="$t('M.loading')">
        <van-list v-model="loading" :finished="finished" @load="onLoad" loading="loading">
          <div class="item" v-for="(item, idx) in list" :key="idx" v-bind:class="item.status == 'WITHDRAWN' ? 'itemhui':''" @click="goDetail(item)">
            <div class="top">
              <div class="left">
                <div class="type2" v-if="item.type == 'BUY'">
                  {{ $t("M.buy") }}
                </div>
                <div class="type" v-if="item.type !== 'BUY'">
                  {{ $t("M.sell") }}
                </div>
                <div class="duiming">
                  {{ item.sellCoinName }}/{{ item.buyCoinName }}
                </div>
              </div>
              <div class="right">
                <div class="rightText" v-if="item.status !== 'WITHDRAWN'">
                  {{  $t(`M.${item.i18nStatusName}`)
                }}<img src="../../assets/img/public/righthuang.png" alt="" srcset="" />
                </div>
                <div class="" v-if="item.status == 'WITHDRAWN'">
                  {{ $t(`M.${item.i18nStatusName}`) }}
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="itembottom item1">
                <p class="tit">{{ $t("M.bb_time") }}</p>
                <p class="content">{{ item.createTime }}</p>
              </div>
              <div class="itembottom item2">
                <p class="tit">
                  {{ $t("M.price") }}({{ item.buyCoinName }})
                </p>
                <p class="content">{{ item.price }}</p>
              </div>
              <div class="itembottom item3">
                <p class="tit">{{ $t("M.entrust_amount") }}({{ item.sellCoinName }})</p>
                <p class="content">{{ item.count }}</p>
              </div>
            </div>
            <div class="bottom">
              <div class="itembottom item1">
                <p class="tit">{{ $t("M.deal_amount") }}({{ item.buyCoinName }})</p>
                <p class="content">{{ item.completeAmount }}</p>
              </div>
              <div class="itembottom item2">
                <p class="tit">
                  {{ $t("M.deal_price") }}({{ item.buyCoinName }})
                </p>
                <p class="content">{{ item.completePrice }}</p>
              </div>
              <div class="itembottom item3">
                <p class="tit">{{ $t("M.trading_volume") }}({{ item.sellCoinName }})</p>
                <p class="content">{{ item.completeCount }}</p>
              </div>
            </div>
          </div>
          <img v-if="list.length == 0 && finished" class="imgNoData" src="../../assets/img/public/nodata.png" alt="" srcset="">

          <p v-if="list.length == 0 && finished" class="textNoData">{{$t('M.no_data')}}</p>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-popup v-model="popupshow" :style="{ height: '100%',width: '76%' }" position="right">
      <div class="popupHome">
        <p class="typeTit" style="margin-top:0;">{{$t('M.trading_on')}}</p>
        <div class="coinBox">
          <van-field class="inputBox" v-model="selectDataObj.sellCoinName" :placeholder="$t('M.currency_name')" />
          <span class="xie">/</span>
          <van-popover v-model="showSlectPopover" trigger="click" :actions="selectArr" @select="onSelect" overlay>
            <template #reference>
              <div class="coinTypeBox">{{selectCoinTypeObj ? selectCoinTypeObj.text : $t('M.currency')}}<img class="jiantou"
                  src="../../assets/img/userCenter/down.png" alt="" srcset="">
              </div>
            </template>
          </van-popover>
        </div>
        <p class="typeTit">{{$t('M.type')}}</p>
        <div class="boxHome">
          <div class="itemBox" v-bind:class="[selectDataObj.type == 'BUY'? 'selectBox' :'']" @click="changeSelectData('type','BUY')">{{$t('M.buy')}}
          </div>
          <div class="itemBox" v-bind:class="[selectDataObj.type == 'SELL'? 'selectBox' :'']" @click="changeSelectData('type','SELL')">
            {{$t('M.sell')}}</div>
        </div>
        <p class="typeTit">{{$t('M.bb_time')}}</p>
        <div class="boxHome">
          <div class="itemBox" v-bind:class="[selectDataObj.dateType == '1'? 'selectBox' :'']" @click="changeSelectData('dateType','1')">
            {{$t('M.LastDays7')}}
          </div>
          <div class="itemBox" v-bind:class="[selectDataObj.dateType == '2'? 'selectBox' :'']" @click="changeSelectData('dateType','2')">
            {{$t('M.LastDays30')}}
          </div>
          <div class="itemBox" v-bind:class="[selectDataObj.dateType == '3'? 'selectBox' :'']" @click="changeSelectData('dateType','3')">
            {{$t('M.LastDays90')}}
          </div>
        </div>
        <p class="typeTit">{{$t('M.status')}}</p>
        <div class="boxHome">
          <div class="itemBox" v-bind:class="[selectDataObj.status == 'COMPLE-TRADE'? 'selectBox' :'']"
            @click="changeSelectData('status','COMPLE-TRADE')">
            {{$t('M.entrust_msg_status_003')}}</div>
          <div class="itemBox" v-bind:class="[selectDataObj.status == 'SOME-TRADE'? 'selectBox' :'']"
            @click="changeSelectData('status','SOME-TRADE')">
            {{$t('M.entrust_msg_status_002')}}</div>
          <div class="itemBox" v-bind:class="[selectDataObj.status == 'WITHDRAWN'? 'selectBox' :'']" @click="changeSelectData('status','WITHDRAWN')">
            {{$t('M.entrust_msg_status_004')}}</div>
        </div>
        <div class="bottomBox">
          <div class="itemBtn itemBtn1" @click="reset">{{$t('M.pop_reset')}}</div>
          <div class="itemBtn itemBtn2" @click="changeFinished">{{$t('M.bb_history_finished')}}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
export default {
  components: {
    navBar,
  },
  data() {
    return {
      loading: false,
      finished: true,
      list: [],
      page: 1,
      refreshing: false,
      popupshow: false,
      selectCoinName: "",
      selectCoinType: "",
      showPopover: false,
      selectArr: [],
      showSlectPopover: false,
      selectCoinTypeObj: null,
      selectDataObj: {
        /* 兑换币种 */
        sellCoinName: '',
        /* 目标币种 */
        buyCoinName: '',
        /* 买还是卖 */
        type: '',
        /* 最近时间 */
        dateType: '',
        /* 状态 */
        status: '',
      },
      sendSelectData: {}
    };
  },
  mounted() {
    /* 获取列表 */
    this.getList();
    /* 获取筛选项 */
    this.queryEntrustSelectBox()
  },
  methods: {
    reset() {
      this.selectDataObj = {
        sellCoinName: '',
        buyCoinName: '',
        type: '',
        dateType: '',
        status: '',
      }

      this.selectCoinTypeObj = null;
      this.filterSendData()
      this.popupshow = false;
      this.getList()
    },
    changeFinished() {
      this.filterSendData()
      this.popupshow = false;
      this.getList()
    },
    filterSendData() {
      let sendData = JSON.parse(JSON.stringify(this.selectDataObj));
      for (const key in sendData) {
        if (Object.hasOwnProperty.call(sendData, key)) {
          const element = sendData[key];
          if (element == '') {
            delete sendData[key];
          }
        }
      }
      this.sendSelectData = sendData;
    },
    /* 筛选 改变条件 */
    changeSelectData(key, data) {
      console.log(key, data)
      if (data == this.selectDataObj[key]) {
        this.selectDataObj[key] = ""
      } else {
        this.selectDataObj[key] = data;
      }
    },
    queryEntrustSelectBox() {
      this.$api
        .queryEntrustSelectBox({
          partnerId: "472437831826935808",
          partnerNo: "1000010001"
        })
        .then((response) => {
          response.data.coinList.forEach((element, index) => {
            element.text = element.name
          });
          this.selectArr = response.data.coinList
          this.selectCoinTypeObj = this.selectArr[0]
          this.selectDataObj.buyCoinName = this.selectArr[0].text;
        });
    },
    onSelect(data) {
      this.selectCoinTypeObj = data
      this.selectDataObj.buyCoinName = data.text;
      this.showSlectPopover = false;
    },
    goDetail(item) {
      if (item.status !== 'WITHDRAWN') {
        this.$router.push({
          path: `/TradeDetail`,
          query: {
            id: item.id
          }
        });
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.loading = true;
      this.getList();
    },
    onClickLeft() {
      this.$router.back()
    },
    onClickRight() {
      this.popupshow = true
    },
    getList() {
      this.list = [];
      this.page = 1;
      this.onLoad();
    },
    onLoad() {
      this.$api
        .queryMyHisEntrust({
          ...this.sendSelectData,
          currentPage: this.page,
          pageSize: 10,
          partnerId: "472437831826935808",
          partnerNo: "1000010001"
        })
        .then((response) => {
          this.refreshing = false;
          this.list = this.list.concat(response.data.list);
          this.page++;
          this.loading = false;
          if (response.data.list.length < 10) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.imgNoData {
  width: 170px;
  height: 105px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}
.textNoData {
  text-align: center;
  line-height: 100px;
}
.tradeOrder {
  .item {
    padding: 15px;
    border-bottom: 1px solid #ebebf0;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .right {
        font-size: 12px;
        display: flex;
        align-items: center;
        img {
          width: 6px;
          height: 10px;
        }
        .rightText {
          color: #00b897;
        }
      }
      .left {
        display: flex;
        align-items: center;
      }
      .duiming {
        font-size: 16px;
        font-weight: 600;
        color: #191b27;
        margin-left: 15px;
      }
      .type {
        // width: 36px;
        height: 20px;
        background: rgba(255, 96, 96, 0.1);
        border-radius: 2px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #ED0071;
        line-height: 20px;
        padding: 0 4px;
      }
      .type2 {
        // width: 36px;
        height: 20px;
        background: rgba(255, 96, 96, 0.1);
        border-radius: 2px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #ED0071;
        line-height: 20px;
        background: #d6ede5 !important;
        color: #0AA869;
        padding: 0 2px;
      }
    }
    .bottom {
      display: flex;
      margin-top: 15px;
      justify-content: space-between;
      align-items: center;
      .itembottom {
        line-height: 19px;
        font-size: 13px;
        margin-top: 0;


        .tit {
          font-weight: 400;
          color: #9299a9;
        }
        .content {
          color: #191b27;
          margin-top: 5px;
          white-space: nowrap;
          font-size: 10px;
        }
      }
      .item1 {
        text-align: left;
      }
      .item2 {
        text-align: center;
      }
      .item3 {
        text-align: right;
      }
    }
  }

  .itemhui {
    color: #a5adbf;
    .top {
      .right {
        color: #a5adbf;
      }

      .duiming {
        color: #a5adbf;
      }
    }
    .bottom {
      .itembottom {
        .tit {
          font-weight: 400;
          color: #a5adbf;
        }
        .content {
          color: #a5adbf;
          margin-top: 5px;
          white-space: nowrap;
          font-size: 10px;
        }
      }
    }
  }
  .popupHome {
    padding: 0 17px;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    .typeTit {
      font-size: 16px;
      font-weight: 600;
      color: #191b27;
      line-height: 53px;
      margin-top: 12px;
    }
    .coinBox {
      display: flex;
      align-items: center;
      .inputBox {
        max-width: 100px;
        border: 1px solid #9299a9;
        height: 34px;
        border-radius: 4px;
      }
      .coinTypeBox {
        width: 90px;
        border: 1px solid #9299a9;
        height: 34px;
        border-radius: 4px;
        line-height: 32px;
        font-size: 14px;
        padding-left: 15px;
        position: relative;
        .jiantou {
          position: absolute;
          right: 10px;
          top: 15px;
          width: 10px;
          height: 5px;
        }
      }
      .xie {
        margin: 0 15px;
      }
    }
    .boxHome {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .itemBox {
        height: 34px;
        background: #f6f6f6;
        border-radius: 4px;
        color: #9299a9;
        line-height: 32px;
        padding: 0 8px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        box-sizing: border-box;
        border: 1px solid #f6f6f6;
      }
      .selectBox {
        background: rgba(0, 184, 151, 0.15);
        border: 1px solid #00b897;
        color: #191b27;
        box-sizing: border-box;
      }
    }
    .bottomBox {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      height: 46px;
      text-align: center;
      line-height: 46px;
      color: #fff;
      font-size: 18px;
      left: 0;
    }
    .itemBtn {
      width: 50%;
    }
    .itemBtn1 {
      background: #272a3b;
    }
    .itemBtn2 {
      background: #00b897;
    }
  }
}
::v-deep .van-nav-bar .van-icon {
  color: #000000;
}
::v-deep .van-cell {
  padding: 0px 8px;
}
::v-deep .van-field__control {
  line-height: 32px;
}
.boxHomeList {
  width: 82px;
  // max-height: 190px;
  overflow-y: scroll;
  text-align: center;
  .selectItem {
    text-align: center;
    width: 82px;
    height: 40px;
    line-height: 40px;
    .van-cell__value--alone {
      text-align: center;
    }
  }
}
</style>

<style>
.van-popover__content {
  overflow-y: scroll;
  /* max-height: 190px; */
}
.van-popover__action-text {
  min-width: 60px;
}
</style>