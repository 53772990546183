<template>
    <div class="container">
      <nav-bar :title="query.title" />
      <van-sticky :offset-top="50" class="sticky">
        <!-- <div class="top_dropdown">
          <van-popover v-model="showTrade" trigger="click" :actions="popoverTrade" @select="onSelect" get-container=".van-popover__wrapper">
            <template #reference>
              <div class="popover_item_value">{{popoverTrade[defaultTrade].text}}</div>
            </template>
          </van-popover>
          <van-popover v-model="showTrade" trigger="click" :actions="popoverTrade" @select="onSelect" get-container=".van-popover__wrapper">
            <template #reference>
              <div class="popover_item_value">{{popoverTrade[defaultTrade].text}}</div>
            </template>
          </van-popover>
          <van-popover v-model="showTrade" trigger="click" :actions="popoverTrade" @select="onSelect" get-container=".van-popover__wrapper">
            <template #reference>
              <div class="popover_item_value">{{popoverTrade[defaultTrade].text}}</div>
            </template>
          </van-popover>
        </div> -->
        <van-row style="padding: 5px 15px;background-color: #f7f7f7;" type="flex" justify="space-between">
                <van-col style="text-align: left;"  span="6">
                </van-col>
                <van-col style="text-align: right;font-size: 14px;"   span="12">
                  <van-popover
                    v-model="showPopover2"
                    trigger="click"
                    :actions="actions2"
                    @select="onSelect2"
                    placement="bottom-end"
                    overlay
                  >
                    <template #reference><span>{{actionsContent2}}</span><van-icon name="arrow-down" /></template>
                  </van-popover>
                </van-col>
        </van-row>
        <!-- <van-dropdown-menu active-color="#00b897">
          <van-dropdown-item v-model="queryParams.tradeId" :options="optionTrade" />
          <van-dropdown-item v-model="queryParams.term" :options="option1" />
          <van-dropdown-item v-model="queryParams.tradeType" :options="option2" />
        </van-dropdown-menu> -->

        <!-- <dropdown :show="true">
          <div slot="item" class="dropdown_cell">
            <dropdown-item v-model="queryParams.tradeId" :options="optionTrade" />
          </div>
          <div slot="item" class="dropdown_cell"> 时长</div>
        </dropdown> -->

      </van-sticky>

      <!-- 列表 -->
      <van-pull-refresh v-model="refreshing" :pulling-text="$t('M.MJRefreshHeaderIdleText')" :loosing-text="$t('M.MJRefreshHeaderPullingText')"
        :loading-text="$t('M.loading')" @refresh="onRefresh">
        <van-list class="list" v-model="loading" offset="10" :finished="finished" :loading-text="$t('M.loading')" @load="onLoad">
          <div v-if="refreshing" slot="loading"></div>
          <div class="box" v-for="(item,index) in list" :key="index">
                    <div v-if="item.tradeType==146">
                      <van-row class="row"  gutter="5">
                        <van-col span="8"><span class="Sub title">{{item.buyCoinNickname}}</span></van-col>
                        <van-col span="16" class="right"><span>{{$t('M.income')}}<span :class="item.totalChange<0?'gre':'red'">{{item.totalChange<0?'':'+'}}{{ item.totalChange }}</span></span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span>{{ item.createTime }}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.service_charge')}}</span>0</van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><van-tag color="#f8f8f8" text-color="#5C6573">{{tradeMode(item.positionsType)}}</van-tag></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.balance')}}{{item.total}}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span class="Sub">{{item.tradeDesc}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.Position balance')}}{{item.margin}}</span></van-col>
                      </van-row>
                    </div>
                    <div v-if="item.tradeType==150||item.tradeType==151">
                      <van-row class="row"  gutter="5">
                        <van-col span="20"><span class="Sub title">{{item.buyCoinNickname}}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span>{{ item.createTime }}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><van-tag color="#f8f8f8" text-color="#5C6573">{{tradeMode(item.positionsType)}}</van-tag><span>{{item.coinName}}</span></van-col>
                        <van-col span="12" class="right"><span :class="item.totalChange.slice(0,1) == '-'?'gre':'red'">{{ item.totalChange.slice(0,1) == '-'?item.totalChange:'+'+item.totalChange }}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span class="Sub">{{item.tradeDesc}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.balance')}}{{item.total}}</span></van-col>
                      </van-row>
                    </div>
                    <div v-if="item.tradeType==147||item.tradeType==148">
                      <van-row class="row"  gutter="5">
                        <van-col span="20"><span class="Sub title">{{item.buyCoinNickname}}</span></van-col>
                        <!-- <van-col span="4" class="right"><span >{{filtersData(item.status)}}</span></van-col> -->
                      </van-row>
                      <van-row>
                        <van-col span="12"><span>{{ item.createTime }}</span></van-col>
                        <van-col span="12" class="right"><span :class="item.tradeType==147?'gre':'red'">{{ (item.totalChange-0)>0?'+'+item.totalChange:item.totalChange }}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><van-tag color="#f8f8f8" text-color="#5C6573">{{tradeMode(item.positionsType)}}</van-tag><span>{{item.coinName}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.balance')}}{{item.total}}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span class="Sub">{{item.tradeDesc}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.Position balance')}}{{item.margin}}</span></van-col>
                      </van-row>
                    </div>
                    <div v-if="item.tradeType==156||item.tradeType==157||item.tradeType==158||item.tradeType==159">
                      <van-row class="row"  gutter="5">
                        <van-col span="12"><span class="Sub title">{{item.buyCoinNickname}}</span></van-col>
                        <van-col span="12" class="right"><span>{{$t('M.income')}}<span :class="item.incomeAmount<0?'gre':'red'">{{item.incomeAmount<0?'':'+'}}{{ item.incomeAmount }}</span></span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span>{{ item.createTime }}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.service_charge')}}</span><span :class="fundColor(item.paTotalChange,item.adTotalChange)?'gre':'red'">{{item.completeTime<0?'-':'+'}}{{ item.completeTime }}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><van-tag color="#f8f8f8" text-color="#5C6573">{{tradeMode(item.positionsType)}}</van-tag><span>{{item.coinName}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.balance')}}{{item.total}}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span class="Sub">{{item.tradeDesc}}</span><span>{{ item.positionNum }}{{$t('M.Cont')}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.Position balance')}}{{item.margin}}</span></van-col>
                      </van-row>
                    </div>
                    <div v-if="item.tradeType==152||item.tradeType==153||item.tradeType==154||item.tradeType==155">
                      <van-row class="row"  gutter="5">
                        <van-col span="12"><span class="Sub title">{{item.buyCoinNickname}}</span></van-col>
                        <van-col span="12" class="right"><span>{{$t('M.income')}}<span :class="item.incomeAmount<0?'gre':'red'">{{item.incomeAmount<0?'':'+'}}{{ item.incomeAmount }}</span></span></van-col>

                      </van-row>
                      <van-row>
                        <van-col span="12"><span>{{ item.createTime }}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.service_charge')}}</span><span :class="fundColor(item.paTotalChange,item.adTotalChange)?'red':'gre'">{{fundNum(item.paTotalChange,item.adTotalChange) }}</span></van-col>

                      </van-row>
                      <van-row>
                        <van-col span="12"><van-tag color="#f8f8f8" text-color="#5C6573">{{tradeMode(item.positionsType)}}</van-tag><span>{{item.coinName}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.balance')}}{{item.total}}</span></van-col>

                      </van-row>
                      <van-row>
                        <van-col span="12"><span class="Sub">{{item.tradeDesc}}</span><span>{{ item.positionNum }}{{$t('M.Cont')}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.Position balance')}}{{item.margin}}</span></van-col>
                      </van-row>
                    </div>
                    <div v-if="item.tradeType==142||item.tradeType==143||item.tradeType==11">
                      <van-row class="row"  gutter="5">
                        <van-col span="20"><span class="Sub title">{{item.buyCoinNickname}}</span></van-col>
                        <!-- <van-col span="4" class="right"><span >{{filtersData(item.status)}}</span></van-col> -->
                      </van-row>
                      <van-row>
                        <van-col span="12"><span>{{ item.createTime }}</span></van-col>
                        <van-col span="12" class="right"><span :class="item.tradeType==142?'gre':'red'">{{ item.totalChange }}</span></van-col>
                      </van-row>
                      <van-row>
                        <van-col span="12"><span class="Sub">{{item.tradeDesc}}</span></van-col>
                        <van-col span="12" class="right"><span class="Sub">{{$t('M.balance')}}{{item.total}}</span></van-col>
                      </van-row>
                    </div>
                    <van-divider style="margin: 0;" />
                  </div>
          <div class="noData" v-if="!list.length && finished">
            <img class="imgNoData" src="@/assets/img/public/nodata.png" alt="" srcset="" />
            <p class="text">{{$t('M.no_data')}}</p>
          </div>
        </van-list>
      </van-pull-refresh>

    </div>
  </template>


  <script>
  import navBar from '@/components/navBar';
  // import dropdown from '@/components/dropdown';
  // import dropdownItem from '@/components/dropdown/dropdownItem.vue';

  export default {
    name: 'secondContractFlow',
    components: {
      navBar
    },
    data() {
      return {
        query: this.$route.query,
        list: [],
        loading: false,
        finished: false,
        refreshing: false,
        showPopover2:false,
        actions2:[{text: this.$t('M.all'),key:'ALL'},{text: this.$t('M.Transfer'),key:'CONTRACT_TRANSFER'},{text: this.$t('M.tab_trade'),key:'CONTRACT_DEAL'},{text: this.$t('M.liquidation'),key:'CONTRACT_FORCE_CLOSE'},{text: this.$t('M.Margin transfer'),key:'CONTRACT_EARNEST_MONEY_TRANSFER'},{text: this.$t('M.Capital fee'),key:'CONTRACT_FUND_RATE'}],
        finishedText: this.$t('M.no_more_data'),
        queryParams: {
          pageSize: 10,
          tradeId: '',
          term: '',
          contractType: '',
        },
        pageNumber: 1,
        actionsContent2: this.$t('M.all'),

        optionTrade: [{ text: this.$t('M.Sec Swap'), value: '' }],
        option1: [{ text: this.$t('M.duration'), value: '' }],
        option2: [{ text: this.$t('M.AllType'), value: '' }],
        date: '',


        // showTrade: false,
        // defaultTrade: 0,
        // popoverTrade: [
        //   { text: '秒合约', value: 0, className: 'popover_item_active' },
        //   { text: 'ETH/USDT', value: 1 },
        // ],



      };
    },
    watch: {
      queryParams: {
        handler() {
          this.refreshing = false;
          this.pageNumber = 1
          this.onLoad()
        },
        deep: true
      }
    },
    beforeMount() {
      this.getTransactionPair()
      this.getTerm()
      this.getTradeType()
    },

    mounted() {
    },

    methods: {
        onSelect2(e){
              this.actionsContent2 = e.text
              this.actionsKey2 = e.key
              this.onLoad()
          },
        fundColor(a,b){
            let num = (a-0)+(b-0)
            if(num < 0)return true
            return false
          },
          fundNum(a,b){
            let num = (a-0)+(b-0)
            if(num < 0)return '+'+num
            return '-'+num
          },
          tradeMode(key){
            if(key =='OPEN_UP') return this.$t('M.Open long')
            if(key =='OPEN_DOWN') return this.$t('M.Open short')
            if(key =='CLOSE_UP') return this.$t('M.Close long')
            if(key =='CLOSE_DOWN') return this.$t('M.Close short')
            if(key =='BY') return this.$t('M.Isolated')
            if(key =='TOTAL') return this.$t('M.Cross')
            if(key =='NO_UNWIND') return this.$t('M.Unfilled')
            if(key =='PART_UNWIND') return this.$t('M.Partially closed')
            if(key =='ALL_UNWIND') return this.$t('M.All Closed')
            return '-'
        },
      // 交易对
      getTransactionPair() {
        this.$api.getTransactionPair().then((response) => {
          if (!response.meta.success) return
          const option = response.data.map((item) => ({ text: item.name, value: item.value })) || []
          this.optionTrade = this.optionTrade.concat(option)
        })
      },
      // 时长
      getTerm() {
        this.$api.getTerm().then((response) => {
          if (!response.meta.success) return
          const option = response.data.map((item) => ({ text: item.name, value: item.value })) || []
          this.option1 = this.option1.concat(option)
        })
      },
      // 类型
      getTradeType() {
        this.$api.getTradeType().then((response) => {
          if (!response.meta.success) return
          const option = response.data.map((item) => ({ text: item.name, value: item.value })) || []
          this.option2 = this.option2.concat(option)
        })
      },

      getList() {
        this.queryParams.contractType = this.actionsKey2
        const params = { ...this.queryParams, pageNumber: this.pageNumber }
        this.$api.fundBill(params).then((response) => {
            console.log(response);
          if (!response.meta.success) return
          const { list, total } = response.data.fundBill
          console.log(list);
          this.loading = false;
          if (this.pageNumber == 1) {
            this.list = list
          } else {
            this.list = this.list.concat(list)

          }
          this.pageNumber += 1
          if (this.list.length >= total) {
            this.finished = true;
          }
        })
      },

      onLoad() {
        setTimeout(() => {
          if (this.refreshing) {
            this.pageNumber = 1
            this.list = [];
            this.refreshing = false;
          }
          this.getList()
        }, 500);

      },


      onRefresh() {

        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        this.loading = true;
        this.onLoad();
      },

      formatDate(date) {
        return `${date.getMonth() + 1}/${date.getDate()}`;
      },
      onConfirm(date) {
        const [start, end] = date;
        this.showDate = false;
        this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      },
      onSelect(e) {
        console.log(e);

      }
    },
  };
  </script>
  <style lang="scss" scoped>
  ::v-deep {
    .van-dropdown-menu__bar {
      box-shadow: 0 0 0 !important;
    }
    .van-dropdown-item__option {
      .van-cell__value {
        display: none;
      }
    }
    .van-popup--top {
      // width: 100px;
    }
  }
  </style>
  <style lang="scss" scoped>
  .list {
    padding: 15px;
    .item {
      width: 100%;
      height: 69px;
      border-radius: 3px;
      border-bottom: 1px solid #ebebf0;
      margin-bottom: 10px;
      padding: 0 10px 5px;
      .row {
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        .date {
          font-size: 12px;
          color: #9299a9;
        }
      }
      .item_bottom {
        > div {
          flex: 1;
          font-size: 13px;
          color: #131520;
        }
        > div:nth-child(2) {
          text-align: center;
        }
        > div:nth-child(3) {
          text-align: right;
        }
      }
    }
    .return {
      font-size: 13px;
      color: #18ae83;
    }
  }
  .box{
    padding: 10px;
    font-size: 14px;
    .van-row{
      margin-bottom: 10px;
      .margin{
        span{
            margin-right: 10px;
          }
      }
      span{
        color: #5C6573;
      }
      .Sub{
        color: #000;
        word-break: break-all;
      }
      .title{
        font-size: 14px;
      }
      .left{text-align: left;}
      .right{text-align: right;}
      .center{text-align: center;}
      .red{
        color: #e54d68;
      }
      .gre{
        color: #1baf84;
      }
    }
  }
  .top_dropdown {
    width: 100%;
    height: 42px;
    background: #fff;
    padding: 0 15px;
    display: flex;
    align-items: center;
    // justify-content: space-around;
    > span {
      position: relative;
      display: inline-block;
      flex: 1;
    }
  }
  </style>
