<!--
 * @Author:
 * @Date: 2022-06-22 16:34:18
 * @LastEditors:
 * @LastEditTime: 2022-07-15 10:01:16
 * @FilePath: \exchange-h5\src\page\setPassword.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="setPassword container">
    <van-sticky>
      <van-nav-bar :title="$t('M.Entrust2')" left-text="" left-arrow @click-left="onClickLeft" />
      <van-tabs v-model="active" class="tabBar" line-width="16px" line-height="3px" color="#00b897" title-active-color="#00b897"
        @change="tabBarChange">
        <van-tab>
          <template #title>
            {{ $t('M.All2') }}
            <span class="titleSmallNum">{{ totalNum > 99 ? "99+" : totalNum }}</span>
          </template>
        </van-tab>
        <van-tab>
          <template #title>
            {{ $t('M.Ongoing2') }}
            <span class="titleSmallNum">{{ purchasingNum }}</span>
          </template>
        </van-tab>
        <van-tab>
          <template #title>
            {{ $t('M.Completed2') }}
            <span class="titleSmallNum">{{ completeNum }}</span>
          </template>
        </van-tab>
        <van-tab>
          <template #title>
            {{ $t('M.Cancelled2') }}
            <span class="titleSmallNum">{{ cancelNum > 99 ? "99+" : cancelNum }}</span>
          </template>
        </van-tab>
      </van-tabs>
    </van-sticky>

    <div class="listBox">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :pulling-text="$t('M.MJRefreshHeaderIdleText')"
        :loosing-text="$t('M.MJRefreshHeaderPullingText')" :loading-text="$t('M.loading')">
        <van-list v-model="listLoading" :finished="finished" @load="loadList" :loading-text="$t('M.loading')">
          <Card v-for="(item, idx) in listData" :key="idx" :cardData="item" @updata="onRefresh" />
        </van-list>
        <div class="noData" v-if="listData.length == 0 && finished == true">
          <img class="imgNoData" src="../../assets/img/public/nodata.png" alt="" srcset="" />
          <p class="text">{{ $t('M.no_data') }}</p>
        </div>
      </van-pull-refresh>
    </div>

  </div>
</template>

<script>
import { contractPositionSocketUrl } from '@/utils/env'
import { mapGetters } from 'vuex'
import Card from "./Card.vue"
export default {
  components: { Card },
  data() {
    return {
      link: '',
      active: 0,
      count: {
        allCount: "0",
        comingCount: "0",
        endedCount: "0",
        ongoingCount: "0",
      },
      finishedText: this.$t('M.no_more_data'),
      refreshing: false,
      listLoading: false,
      finished: false,
      listData: [],
      pageNum: 1,
      totalNum: 0,
      purchasingNum: 0,
      completeNum: 0,
      cancelNum: 0,
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
  },
  mounted() {

  },
  methods: {
    /* 切换tab */
    tabBarChange() {
      this.finished = false;
      this.listData = []
      this.pageNum = 1;
      this.listLoading = true;
      this.loadList()
    },
    /* 刷新 */
    onRefresh() {
      this.finished = false;
      this.listData = []
      this.pageNum = 1;
      this.listLoading = true;
      this.loadList()
    },
    /* 加载列表 */
    loadList() {
      let parame = {
        pageSize: 10,
        pageNum: this.pageNum,
        status: this.active == 0 ? '' : this.active,
        isNew: localStorage.getItem('lockUpFlag') == 'true' ? 1 : 0
      }
      this.$api.listOrders(parame).then((res) => {
        if (this.pageNum == 1) {
          this.listData = []
        }
        if (res.data.dataList == null) {
          this.finished = true;
        } else {
          if (res.data.dataList.length < 10) {
            this.finished = true;
          }
          this.pageNum = res.data.pageNum + 1;
          this.listData = this.listData.concat(res.data.dataList);
        }

        this.listLoading = false;
        this.refreshing = false;

        this.totalNum = res.data.totalNum;
        this.purchasingNum = res.data.purchasingNum;
        this.completeNum = res.data.completeNum;
        this.cancelNum = res.data.cancelNum;

      })

    },
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.setPassword {
  font-size: 12px;
  background-color: #f8f8fb;

  .listBox {
    padding: 13px 15px;
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
::v-deep .van-tab__text--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>