/*
 * @Author:
 * @Date: 2022-06-14 16:10:39
 * @LastEditors: LAPTOP-7587T4F6\Bohan  
 * @LastEditTime: 2022-08-29 00:26:07
 * @FilePath: \exchange-h5\src\request\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {get, post, postUrl, put} from './http'

export const api = {
    baseUrl: '',
    /**
     * 公共接口
     *
     */
    userRefreshUser(params) {
        return get(this.baseUrl + '/user/refreshUser', params);
    },
    /* 滑块验证开关 */
    sliderSwitch(params) {
        return get(this.baseUrl + '/sliderSwitch', params);
    },
    /* 滑块图片 */
    captchaGet(params) {
        return post(this.baseUrl + '/captcha/get', params);
    },
    /* 滑块验证 */
    captchacheck(params) {
        return post(this.baseUrl + '/captcha/check', params);
    },
    /**
     * 以下是秒合约接口
     *
     */
    /* 获取秒和约交易对 */
    getnewOptionHanderSectleData(params) {
        return get(this.baseUrl + '/new/option/init', params);
    },
    /* 获取time 价格列表 */
    getOptionPriceList(params) {
        return get(this.baseUrl + '/new/option/market/getOptionPriceList', params);
    },
    /* 秒合于获取商户ID */
    getMerchantId(params) {
        return get(this.baseUrl + '/queryPartner', params);
    },
    /* 获取下单可用余额 */
    getAvailableBalance(params) {
        return get(this.baseUrl + '/new/personal/option/getAvailableBalance', params);
    },
    // 下单
    addOrder(params) {
        console.log("params", params)
        return postUrl(this.baseUrl + '/new/option/order/addOrder', params);
    },
    // 期权下单弹窗数据
    optionOrderPopup(params) {
        return get(this.baseUrl + '/new/option/order/getCurrentOrder', params);
    },
    // 期权下单弹窗结果
    getPopupInfo(params) {
        return get(this.baseUrl + '/new/option/order/getPopupInfo', params);
    },
    // 历史下单数据
    findOptionOrder(params) {
        return get(this.baseUrl + '/new/option/order/findOptionOrder', params);
    },
    // K线图
    klineByNum(params) {
        return get(this.baseUrl + '/new/option/market/klineByNum', params);
    },


    /**
     * 以下是资产部分接口
     *
     */

    // 汇率
    exchangeRate(params) {
        return get(this.baseUrl + '/exchangeRate/price', params);
    },
    // 获取所有交易区的主币种价格
    tradeAreaPrice(params) {
        return get(this.baseUrl + '/exchangeRate/tradeAreaPrice', params);
    },
    //查询用户总资产
    queryAccountCNY(params) {
        return get(this.baseUrl + '/personal/queryAccountCNY', params);
    },

    //查询钱包资产
    getUserWalletAssets(params) {
        return get(this.baseUrl + '/personal/getUserWalletAssets', params);
    },

    // 获取钱包划转类型
    transferCoin(coinId, type, partnerId) {
        return get(this.baseUrl + `/transferCoin/${coinId}?type=${type}&partnerId=${partnerId}`);
    },
    // 获取钱包资金流水
    getUserWalletBill(params) {
        return get(this.baseUrl + '/personal/getUserWalletBill', params);
    },

    // 获取钱包资金流水类型
    getWalletTradeType(params) {
        return get(this.baseUrl + '/personal/getWalletTradeType', params);
    },

    // 获取币种列表
    queryPartnerCoinList(params) {
        return get(this.baseUrl + '/personal/queryPartnerCoinList', params);
    },
    // 获取划转记录分类列表
    getWalletTransferTradeType(params) {
        return get(this.baseUrl + '/personal/getWalletTransferTradeType', params);
    },
    // 获取划转记录
    getUserWalletTransferBill(params) {
        return get(this.baseUrl + '/personal/getUserWalletTransferBill', params);
    },
    // 获取提币信息
    getAssets(params) {
        return get(this.baseUrl + '/assets', params);
    },
    // -验证地址
    validateAddress(params) {
        return get(this.baseUrl + '/personal/validateAddress', params);
    },

    // -密码验证信息
    getSecurity(params) {
        return get(this.baseUrl + '/user/security/indexNew', params);
    },
    // 确认提币
    addWithdraw(params) {
        return post(this.baseUrl + '/addWithdraw', params);
    },

    // 发送短信验证码
    sendSms(params) {
        return get(this.baseUrl + '/sendSms', params);
    },
    // 发送邮箱验证码
    sendMail(params) {
        return get(this.baseUrl + '/sendMail', params);
    },

    // 确认新增地址
    addWithdrawAddress(params) {
        return post(this.baseUrl + '/personal/addWithdrawAddress', params);
    },
    // 删除地址
    deleteUserWithdrawAddress(params) {
        return post(this.baseUrl + '/personal/deleteUserWithdrawAddress', params);
    },
    // 关闭验证
    enable(params) {
        return postUrl(this.baseUrl + '/user/security/enable', params);
    },


    // 获取链信息
    coinWallet(coinId) {
        return get(this.baseUrl + `/coin/coinWallet/${coinId}`);
    },
    // 获取充值地址
    getRechargeAddress(params) {
        return get(this.baseUrl + '/personal/getRechargeAddress', params);
    },
    // 获取充值/提币历史记录
    queryWithdrawRecording(params) {
        return get(this.baseUrl + '/queryWithdrawRecording', params);
    },
    // 撤销提币申请
    cancelWithdraw(params) {
        return post(this.baseUrl + `/cancelWithdraw?orderId=${params.orderId}`, params);
    },


    // 秒合约资产
    getUserOptionFinanceList(params) {
        return get(this.baseUrl + '/new/personal/option/getUserOptionFinanceList', params);
    },
    // 秒合约资金流水
    optionLog(params) {
        return get(this.baseUrl + '/new/personal/option/optionLog', params);
    },
    // 秒合约交易对下拉框数据
    getTransactionPair(params) {
        return get(this.baseUrl + '/new/personal/option/getTransactionPair', params);
    },
    // 秒合约交割时长下拉框数据
    getTerm(params) {
        return get(this.baseUrl + '/new/personal/option/getTerm', params);
    },
    // 秒合约交易类型下拉框数据
    getTradeType(params) {
        return get(this.baseUrl + '/new/personal/option/getTradeType', params);
    },
    // 资产划转-秒合约
    accountTransfer(params) {
        return postUrl(this.baseUrl + '/personal/accountTransfer', params);
    },


    // 闪兑币种信息
    getWaitExchangeResult(params) {
        return get(this.baseUrl + '/user/smailExchange/getWaitExchangeResult', params);
    },
    // 闪兑
    exchange(params) {
        return get(this.baseUrl + '/user/smailExchange/exchange', params);
    },
    /* 查询 */
    queryExchangeResult(params) {
        return post(this.baseUrl + '/user/smailExchange/queryExchangeResult', params);
    },

    // 闪兑记录
    findPage(params) {
        return get(this.baseUrl + '/user/smailExchange/findPage', params);
    },


    /**
     * 以下是个人中心部分接口
     *
     */



    // 获取邀请链接
    getShareLink(params) {
        return get(this.baseUrl + '/sysconfig/new', params);
    },
    // 安全中心状态
    statusSecurityCenter(params) {
        return get(this.baseUrl + '/user/security/index', params);
    },
    // 设置登录密码
    setPwd(params) {
        return postUrl(this.baseUrl + '/login/setPW', params);
    },
    // 修改登录密码
    setPassword(param) {
        return postUrl(this.baseUrl + '/user/security/password', param);
    },
    // 获取国家列表
    selectList(params) {
        return get(this.baseUrl + '/country/selectList', params);
    },
    // 提交实名制
    realNameAuth(params) {
        return postUrl(this.baseUrl + '/user/center/realNameAuth', params);
    },
    // 获取认证信息
    getVerityInformation(params) {
        return get(this.baseUrl + '/user/center/userauth', params);
    },
    // 获取验证码
    URL_SendSms(params) {
        return get(this.baseUrl + '/sendSms', params);
    },
    // 退出登录
    logout(params) {
        return post(this.baseUrl + '/user/logout', params);
    },
    // 设置交易密码
    payPassword(payPassword, nickName) {
        return post(this.baseUrl + `/user/security/payPassword?payPassword=${payPassword}&nickName=${nickName}`);
    },
    // 修改交易密码
    updatePayPassword(params) {
        return postUrl(this.baseUrl + `/user/security/updatePayPassword`, params);
    },
    // 免密信息
    partnerConfigAndUserPayPassSet(params) {
        return get(this.baseUrl + '/user/partnerConfigAndUserPayPassSet', params);
    },
    // 设置免密
    notInputPayPasswd(params) {
        return postUrl(this.baseUrl + '/user/notInputPayPasswd', params);
    },
    // 上传
    uploadfile(params) {
        return post(this.baseUrl + '/uploadfile', params);
    },
    // 提交高级认证
    advancedAuthH5(params) {
        return postUrl(this.baseUrl + '/user/center/advancedAuthH5', params);
    },
    // 获取客服连接
    selectConfigInfo(params) {
        return get(this.baseUrl + '/dynamicCsConfig/selectConfigInfoH5', params);
    },
    // 获取多语言
    getlanguage(params) {
        return get(this.baseUrl + '/lan/selectList', params);
    },
    // 身份认证
    centerrealAndAdvancedAuth(params) {
        return postUrl(this.baseUrl + '/user/center/realAndAdvancedAuth', params);
    },

    /**
     * 以下是登录注册部分接口
     *
     */


    // 密码登录第一步
    userLoginForStep1(params) {
        return postUrl(this.baseUrl + '/userGeeTest/userLoginForStep1', params);
    },
    // 验证码登录第一步
    userLoginForStep2(params) {
        return postUrl(this.baseUrl + '/userGeeTest/userLoginForStep2', params);
    },

    // 登录
    loginForStep2(params) {
        return postUrl(this.baseUrl + '/login/userLoginForStep2', params);
    },

    // 短信验证码
    sendSmsAndGeeTest(params) {
        return get(this.baseUrl + '/sendSmsAndGeeTest', params);
    },
    // 邮箱验证码
    sendEmailAndGeeTest(params) {
        return get(this.baseUrl + '/sendEmailAndGeeTest', params);
    },
    // 获取邮箱验证码
    bindMail(params) {
        return postUrl(this.baseUrl + '/user/security/bindMail', params);
    },

    // 网易验证第一步
    verify(params) {
        return postUrl(this.baseUrl + '/verify', params);
    },
    // 去注册
    registerUser(params) {
        return postUrl(this.baseUrl + '/userGeeTest/registerUser', params);
    },

    // 忘记密码-获取验证类型
    forgetPasswordNext(params) {
        return get(this.baseUrl + '/userGeeTest/forgetPasswordNext', params);
    },
    // 忘记密码-密码重置
    forgetPasswordValidate(params) {
        return get(this.baseUrl + '/login/forgetPasswordValidate', params);
    },
    // 忘记密码-设置新密码
    setNewPassword(params) {
        return postUrl(this.baseUrl + '/login/setNewPassword', params);
    },
    // 验证验证码
    validate(params) {
        return postUrl(this.baseUrl + '/user/validate', params);
    },
    // 换绑手机
    changePhoneAndCheckOldCode(params) {
        return postUrl(this.baseUrl + '/user/security/changePhoneAndCheckOldCode', params);
    },
    // 绑定手机号
    bindPhone(params) {
        return postUrl(this.baseUrl + '/user/security/bindPhone', params);
    },
    // 密码登录异常情况验证码验证
    codeAuth(params) {
        return postUrl(this.baseUrl + '/login/codeAuth', params);
    },

    /* IEO接口 */

    /* 获取列表 */
    pageListProject(params) {
        return get(this.baseUrl + '/pre/pageListProject', params);
    },
    /* 新币认购 */
    newEntrance(params) {
        return get(this.baseUrl + '/pre/newEntrance', params);
    },
    /* 新币认购 */
    queryMiningActivity(params) {
        return get(this.baseUrl + '/miningActivityApp/queryMiningActivity', params);
    },

    /* 获取综合数量 */
    count(params) {
        return get(this.baseUrl + '/pre/count', params);
    },
    /* 获取综合数量 */
    getProjectDetails(params) {
        return get(this.baseUrl + '/pre/getProjectDetails', params);
    },
    /* 校验用户是否满足条件 */
    getJointTermCoin(params) {
        return get(this.baseUrl + '/pre/getJointTermCoin', params);
    },
    /* 是否需要密码 */
    isInputPayPasswd(params) {
        return get(this.baseUrl + '/user/isInputPayPasswd', params);
    },
    /* 立即兑换 */
    rushToPurchase(params) {
        return post(this.baseUrl + '/pre/rushToPurchase', params);
    },
    /* 查询队列标识 */
    checkQueueFlag(params) {
        return get(this.baseUrl + '/pre/checkQueueFlag', params);
    },
    /* 历史记录 */
    pageListOrder(params) {
        return get(this.baseUrl + '/pre/pageListOrder', params);
    },

    /* 轮询请求结果 */
    getRushResult(params) {
        return get(this.baseUrl + '/pre/getRushResult', params);
    },


    /* 用户托管订单统计 */
    orderStatistics(params) {
        return get(this.baseUrl + '/lumProject/orderStatistics', params);
    },
    /* 项目列表查询 */
    listProjects(params) {
        return get(this.baseUrl + '/lumProject/listProjects', params);
    },
    /* 项目列表查询 */
    listProjects2(params) {
        return get(this.baseUrl + '/lumProject/listNewProjects', params);
    },
    /* 下单 */
    lumProjectAddOrder(params) {
        return postUrl(this.baseUrl + '/lumProject/addOrder', params);
    },
    /* 下单记录 */
    listOrders(params) {
        return get(this.baseUrl + '/lumProject/listOrders', params);
    },
    /*  取消质押提醒 */
    cancelOrderAlert(params) {
        return get(this.baseUrl + '/lumProject/cancelOrderAlert', params);
    },
    /*  取消质押 */
    cancelOrder(params) {
        return postUrl(this.baseUrl + '/lumProject/cancelOrder', params);
    },
    // 查询协议内容
    selectAll(params) {
        return get(this.baseUrl + '/cms/terms/selectAll', params);
    },

    /* 首页部分api */
    // 首页轮播banner
    withBannerTime(params) {
        return get(this.baseUrl + '/banner/withBannerTime', params);
    },
    /* 首页 排行版 */
    homeRanking(params) {
        return get(this.baseUrl + '/market/homeRanking', params);
    },
    /* 首页 新闻公告 */
    newCarousel(params) {
        return get(this.baseUrl + '/newsBulletin/newCarousel', params);
    },
    /* 首页 弹窗详情 */
    selectPopupByUserId(params) {
        return get(this.baseUrl + '/newsBulletin/selectPopupByUserId', params);
    },
    /* 首页 新币认购 */
    getAllNewsNotices2(params) {
        return get(this.baseUrl + '/newsBulletin/all2', params);
    },
    /* 首页 请求个人中心新闻列表 */
    pageQueryNew(params) {
        return get(this.baseUrl + '/newsBulletin/pageQueryNew', params);
    },
    /* 首页 请求个人中心新闻列表 - 详情 */
    newsBulletin(params) {
        return get(this.baseUrl + '/newsBulletin', params);
    },
    /* 首页 请求个人中心新闻列表 - 更新状态 */
    updatePopupStatus(params) {
        return postUrl(this.baseUrl + '/newsBulletin/updatePopupStatus', params);
    },


    // 币币资产部分接口

    // 币币资金流水-类型
    bbLogType(params) {
        return get(this.baseUrl + '/personal/bbLogType', params);
    },
    // 币币资金流水-列表
    bbLog(params) {
        return get(this.baseUrl + '/personal/bbLog', params);
    },
    // 币币-汇率
    bbRate(params) {
        return get(this.baseUrl + '/exchangeRate/price', params);
    },
    // 币币资产-币种列表
    bbFinanceList(params) {
        return get(this.baseUrl + '/personal/getUserBBFinanceList', params);
    },
    // 币币资产-币种可交易列表
    getBBTradeUrl(params) {
        return get(this.baseUrl + '/personal/v1/getTradeUrl', params);
    },
    // 隐藏小于0.001的币种
    accountHideLessCoin(params) {
        return get(this.baseUrl + '/personal/getAccountHideLessConfigure', params);
    },


    /* 币币交易区 */

    /* 所有的交易对信息 */
    tradeList(params) {
        return get(this.baseUrl + '/tradeType/list', params);
    },
    /* 当前币种是否收藏 */
    isCollection(params) {
        return get(this.baseUrl + '/isCollection', params);
    },
    /* 币种收藏 */
    collection(params) {
        return postUrl(this.baseUrl + '/collection', params);
    },
    /* 查询币币交易框的数据 */
    queryEntrustSelectBox(params) {
        return get(this.baseUrl + '/queryEntrustSelectBox', params);
    },
    /* 获取基本数据 */
    getTradeAsset(params) {
        return get(this.baseUrl + '/v1/asset', params);
    },
    /* 获取盘口数据 */
    getPankouChengjiao(params) {
        return get(this.baseUrl + '/market/moveBBMarket', params);
    },
    /* 获取交易对数据 */
    defaultTrade(params) {
        return get(this.baseUrl + '/market/defaultTrade', params);
    },
    /* 获取交易对简介 */
    TheCoinInformation(params) {
        return get(this.baseUrl + '/cms/TheCoinInformation', params);
    },
    /* 深度图 */
    depthRender(params) {
        return get(this.baseUrl + '/market/depthRender', params);
    },
    /* Kline */
    marketkline(params) {
        return get(this.baseUrl + '/market/kline', params);
    },
    /* 委托 */
    saveEntrust(params) {
        return post(this.baseUrl + '/saveEntrust', params);
    },
    /* 查询前委托 */
    queryMyEntrust(params) {
        return get(this.baseUrl + '/queryMyEntrust', params);
    },
    /* 查询前委托 */
    cancelEntrust(params) {
        return put(this.baseUrl + '/cancelEntrust', params);
    },
    /* 查询前委托 */
    queryMyHisEntrust(params) {
        return get(this.baseUrl + '/queryMyHisEntrust', params);
    },
    /* 查询前委托 */
    queryMyEntrustOrder(params) {
        return get(this.baseUrl + '/queryMyEntrustOrder', params);
    },
    /* 查询前委托 */
    queryFiatShow(params) {
        return get(this.baseUrl + '/personal/FiatShow', params);
    },
    /* 查询前委托 */
    queryfatPaySign(params) {
        return get(this.baseUrl + '/personal/fatPaySign', params);
    },
    /* 订单筛选项 */
    queryEntrustSelectBox(params) {
        return get(this.baseUrl + '/queryEntrustSelectBox', params);
    },

    /* 永续-委托列表 */
    currentEntrust(params) {
        return post(this.baseUrl + '/contract/entrust/currentEntrust', params);
    },
    /* 永续-止盈止损当前委托 */
    currentTpslEntrust(params) {
        return post(this.baseUrl + '/contract/entrust/currentTpslEntrust', params);
    },
    /* 永续-撤销委托 */
    YcancelEntrust(params) {
        return postUrl(this.baseUrl + '/contract/entrust/cancelEntrust', params);
    },
    /* 永续-批量撤销委托 */
    batchCancelEntrustByIds(params) {
        return postUrl(this.baseUrl + '/contract/entrust/batchCancelEntrustByIds', params);
    },
    /* 永续-当前委托数量 */
    currentEntrustCount(params) {
        return post(this.baseUrl + '/contract/entrust/currentEntrustCount', params);
    },
    /* 永续-历史委托单 */
    historyEntrust(params) {
        return post(this.baseUrl + '/contract/entrust/historyEntrust', params);
    },
    /* 永续-历史委托单止盈止损 */
    historyTpslEntrust(params) {
        return post(this.baseUrl + '/contract/entrust/historyTpslEntrust', params);
    },
    /* 永续-资金流水 */
    fundBill(params) {
        return get(this.baseUrl + '/contract/entrust/FundRate/fundBill', params);
    },
    /* 永续-仓位查询 */
    currentPosition(params) {
        return get(this.baseUrl + '/contract/entrust/Storage/currentPosition', params);
    },
    /* 永续-历史仓位查询 */
    historyPosition(params) {
        return get(this.baseUrl + '/contract/entrust/Storage/historyPosition', params);
    },
    /* 永续-个人资产 */
    getUserContractFinanceList(params) {
        return get(this.baseUrl + '/personal/getUserContractFinanceList', params);
    },
    /* 永续-调整保证金 */
    adjust(params) {
        return get(this.baseUrl + '/contract/entrust/Storage/adjust', params);
    },
    /* 永续-调整保证金前获取强平价格 */
    getPreForcePrice(params) {
        return get(this.baseUrl + '/contract/entrust/Storage/getPreForcePrice', params);
    },
    /* 永续-可用保证金 */
    getUserContractEnable(params) {
        return get(this.baseUrl + '/personal/getUserContractEnable', params);
    },
    /* 永续-合约实时行情 */
    getTradeTypeTicker(params) {
        return get(this.baseUrl + '/contract/market/getTradeTypeTicker', params);
    },
    /* 永续-开仓下单止盈止损 */
    v1aveEntrust(params) {
        return post(this.baseUrl + '/contract/entrust/v1/saveEntrust', params);
    },
    /* 永续-查询杠杆调整结果 */
    selectChange(params) {
        return get(this.baseUrl + '/contract/lever/selectChange', params);
    },
    /* 永续-杠杆调整 */
    changeLever(params) {
        return postUrl(this.baseUrl + '/contract/lever/changeLever', params);
    },
    /* 永续-平仓 */
    saveCloseEntrust(params) {
        return post(this.baseUrl + '/contract/entrust/saveCloseEntrust', params);
    },


    /* 永续-币种列表 */
    getTradeTypeList(params) {
        return get(this.baseUrl + '/contract/market/getTradeTypeList', params);
    },
    /* 永续-盘口数据 */
    contractmoveBBMarket(params) {
        return get(this.baseUrl + '/contract/market/moveBBMarket', params);
    },
    /* 永续-盘口数据 */
    contractGetTradeTypeTicker(params) {
        return get(this.baseUrl + '/contract/market/getTradeTypeTicker', params);
    },
    /* 永续-合约资产 */
    getUserContractFinanceList(params) {
        return get(this.baseUrl + '/personal/getUserContractFinanceList', params);
    },
    /* 永续- 获取列表 */
    holdMarginRatio(params) {
        return get(this.baseUrl + '/contract/leverSetting/list', params);
    },
    /* 永续- 获取可开多开空数量 */
    getLongShortNum(params) {
        return postUrl(this.baseUrl + '/contract/lever/openAmount', params);
    },
    /* 永续- 获取当前杠杆 */
    currentLever(params) {
        return get(this.baseUrl + '/contract/lever/selectCurrent', params);
    },
    /* 永续- 开通合约协议 */
    queryFuturesBindStatus(params) {
        return get(this.baseUrl + '/contract/agreement', params);
    },
    /* 永续- 开通合约协议 */
    agreementyes(params) {
        return postUrl(this.baseUrl + '/contract/agreement', params);
    },
    /* 永续- 下单 */
    contractsaveEntrust(params) {
        return post(this.baseUrl + '/contract/entrust/saveEntrust', params);
    },
    /* 获取交易对数据 */
    contractDefaultTrade(params) {
        return get(this.baseUrl + '/contract/market/ticker', params);
    },
    /* Kline */
    contractMarketkline(params) {
        return get(this.baseUrl + '/contract/market/kline', params);
    },
    /* 深度图 */
    contractDepthRender(params) {
        return get(this.baseUrl + '/contract/market/depthRender', params);
    },
    /* 获取改变邮箱的规则 */
    getUpChangeEmailRules(params) {
        return get(this.baseUrl + '/user/security/getUpChangeEmailRules', params);
    },
    /* 改变邮箱 */
    changeEmail(params) {
        return get(this.baseUrl + '/user/security/changeEmail', params);
    },
    /* 合伙人升级信息接口 */
    getUpdateInfoAJAX() {
        return get(this.baseUrl + '/copartner/findGroup');
    },
    /* 合伙人排行榜列表 */
    getPartnerRebateTopListAJAX(params) {
        return get(this.baseUrl + '/copartnerRebateTop/listRebateTopAuth', params);
    },
    /* 返佣列表 */
    findRebateLogUser(params) {
        return get(this.baseUrl + '/copartnerRebateLogUser/findRebateLogUser', params);
    },
    /* 是否满足条件 */
    getIsEnoughUpdateConditionAJAX(params) {
        return get(this.baseUrl + '/copartner/isOk', params);
    },
    /* 免密升级合伙人 */
    upgradeCopartnerNoUpgradeFee(params) {
        return get(this.baseUrl + '/copartner/upgradeCopartnerNoUpgradeFee', params);
    },
    /* 不免密升级合伙人 */
    upgradeCopartner(params) {
        return get(this.baseUrl + '/copartner/upgradeCopartner', params);
    },
    /* 根据币种获取可用余额 */
    getTotalByCoinId(params) {
        return get(this.baseUrl + '/push/getTotalByCoinId', params);
    },
    /* 根据币种获取可用余额 */
    changeUserLogo(params) {
        return get(this.baseUrl + '/user/changeUserLogo', params);
    },
    /* 查询借贷配置 */
    queryBorrowCenter(params) {
        return get(this.baseUrl + '/borrowCenter/queryBorrowCenter', params);
    },
    /* 存币理财下单页面 */
    newFinances(params) {
        return get(this.baseUrl + '/financialManagement/newFinances', params);
    },
    /* 存币记录/收益 */
    newFinancesDetailAndHistory(params) {
        return get(this.baseUrl + '/financialManagement/newFinancesDetailAndHistory', params);
    },
    /* 币种列表 */
    getCoinList(params) {
        return get(this.baseUrl + '/financialManagement/getCoinList', params);
    },
    /* 存币详情 */
    record(params) {
        return get(this.baseUrl + '/financialManagement/record/'+params);
    },
    /* 存币购买 */
    financialManagement(params) {
        return post(this.baseUrl + '/financialManagement',params);
    },
    /* 存币取消 */
    cancelInvestment(params) {
        return get(this.baseUrl + '/financialManagement/'+params);
    },
    /* 修改昵称 */
    changeNickName(params) {
        return post(this.baseUrl + '/user/changeNickName?nickName=' + params.nickName,);
    },
    /* 币币是否可以卖出 */
    checkCoinAndUserLock(params) {
        return get(this.baseUrl + '/checkCoinAndUserLock?tradeId=' + params.tradeId,);
    },
    /* 锁仓的列表 */
    filterLockCoin(params) {
        return get(this.baseUrl + '/transferCoin/filterLockCoin');
    },
    /* p2p */
    projectListP2p(params) {
        return get(this.baseUrl + '/p2p/projectList');
    },
    /* 永续-一键反手 */
    oneClickReverseOpen(params) {
        return post(this.baseUrl + '/contract/entrust/oneClickReverseOpen', params);
    },
    /* 永续-一键反手是否再提示 */
    confirmReverseOpen(params) {
        return get(this.baseUrl + '/user/confirmReverseOpen?noReminder='+ params.noReminder);
    },
    /* 下载链接 */
    appUrl() {
        return get(this.baseUrl + '/appUrl');
    },
    
}
