<template>
  <div class="iframe">
    <van-sticky>
      <!-- <van-nav-bar title="Fiat" left-text="" left-arrow @click-left="onClickLeft" /> -->
      <nav-bar title="Fiat" />
    </van-sticky>

    <iframe id="iframe" v-if="url!=''" scrolling="yes" :src="url" style="height: 84vh;" width="100%" frameborder="0"></iframe>
  </div>
</template>

<script>
import navBar from '@/components/navBar';
import { mapGetters } from 'vuex'

export default {
  components: {
    navBar
  },
  computed: {
    ...mapGetters([
      'partnerId',
    ]),
  },
  data() {
    return {
      url: ''
    }
  },
  created() {
    // console.log(this.$route.query.url)
    // this.url = this.$route.query.url;
    this.getFiatUrl()
  },
  methods: {
    // onClickLeft() {
    //   this.$router.replace("TradeCenter")
    // },

    /* 查询fiat 是否显示 */
    async getFiatUrl() {
      const params = {
        partnerId: this.partnerId
      }
      const resUrl = await this.$api.queryfatPaySign(params)
      this.url = resUrl.data.fatPayUrl
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar .van-icon {
  color: #000000;
}
</style>