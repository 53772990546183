<template>
  <div class='container'>

    <nav-bar class="nav_bar"> </nav-bar>
    <div class="title_box">
      <div class="title">{{$t('M.SetPassword')}}</div>
      <div class="tips">{{$t('M.unraise_recovering_password')}} </div>
    </div>

    <div class="section">

      <div class="form">
        <div class="cell">
          <van-field class="newPassword" type="password" v-model="form.newPassword" center clearable :placeholder="$t('M.password_wrong')">
          </van-field>
        </div>
        <div class="cell">
          <van-field class="newPassword" type="password" v-model="form.confirmPassword" center clearable :placeholder="$t('M.again_password')">
          </van-field>
        </div>
      </div>
      <van-button class="button" :disabled="disabled" @click="onClickSubmit">{{$t('M.affirm')}}</van-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import navBar from '@/components/navBar';
import { encrypt } from '@/utils/encrypt'

export default {
  name: 'resetPassword',
  components: {
    navBar,
  },
  computed: {
    ...mapGetters([
      'partnerId', 'partnerNo',
    ]),
    disabled() {
      const { newPassword, confirmPassword } = this.form
      if (newPassword && confirmPassword) return false
      return true
    },
  },
  data() {
    return {
      form: {
        newPassword: '',
        confirmPassword: '',
        userName: '',
        code: ''
      },

    };
  },

  mounted() {
    const { userName, code } = this.$route.params
    this.form.userName = userName && userName
    this.form.code = code && code
  },

  methods: {

    onClickSubmit() {
      const params = {
        ...this.form,
        newPassword: encrypt(this.form.newPassword),
        confirmPassword: encrypt(this.form.confirmPassword),
        code: encrypt(this.form.code),
        partnerId: this.partnerId,
        partnerNo: this.partnerNo,
      }
      this.$api.setNewPassword(params).then((response) => {
        if (!response.meta.success) return
        this.$notify(response.meta.message)
        this.$router.push({ name: 'login' })
      })
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar__arrow {
  font-size: 24px;
}
.title_box {
  margin: 30px 0;
  padding: 0 35px;

  .title {
    font-size: 16px;
    font-weight: 600;
  }
  .tips {
    font-size: 12px;
    color: #e95252;
    margin-top: 10px;
    display: flex;
    // align-items: center;
    padding-left: 20px;
    position: relative;
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: '!';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1.5px solid;
      font-weight: 600;
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.section {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .form {
    margin: 56px 0 36px;
  }
  .cell {
    width: 300px;
    margin-bottom: 20px;
    .newPassword {
      background: rgba(204, 204, 204, 0.2);
      border-radius: 4px;
    }
  }
  .button {
    width: 300px;
    height: 35px;
    background: #00b897;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: Adobe Heiti Std;
    color: #fff;
  }
}
</style>