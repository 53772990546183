<template>
  <div class="authentication container">
    <div id="commonhead" class="commonhead comheadmenu " style="display: block;">
      <img class="commonhead-icon" src="../../assets/img/public/ic_back.png" @click="back"/>
      <!-- ../../assets/img/back.png -->
      <span class="commonhead-tit">{{ $t("M.identity_authentication") }}</span>
    </div>

    <div class="auth-title">
      {{ $t("M.upload_identity_card") }}
    </div>

    <div class="idcard-box">

      <van-uploader :after-read="afterRead" @click-upload="upload(1)">
        <img :src="img1" class="idcard-f" @load='loadImg'/>
      </van-uploader>
      <div class="upload-tip"> {{ $t("M.upload_front") }}</div>
      <van-uploader :after-read="afterRead" @click-upload="upload(2)">
        <img :src="img2" class="idcard-f" @load='loadImg'/>
      </van-uploader>
      <div class="upload-tip"> {{ $t("M.upload_reverse") }}</div>
    </div>

    <div class="auth-title">
      {{ $t("M.Videoing_requirement") }}
    </div>
    <div class="bottom-sample">
      <img class="sp-img" src="../../assets/img/userCenter/sp_ok.png"/>
      <img class="sp-img" src="../../assets/img/userCenter/sp_err1.png"/>
      <img class="sp-img" src="../../assets/img/userCenter/sp_err2.png"/>
      <img class="sp-img" src="../../assets/img/userCenter/sp_err3.png"/>
    </div>
    <div class="bottom-sample bottom-sample1">
      <span class="sp-img">{{ $t("M.The_correct_sample") }}</span>
      <span class="sp-img">{{ $t("M.Lack_of_edge") }}</span>
      <span class="sp-img">{{ $t("M.Fuzzy Information") }}</span>
      <span class="sp-img">{{ $t("M.Flash_strong") }}</span>
    </div>
    <van-button class="btn-commit" color="#00b897" @click="next">{{ $t("M.next") }}</van-button>
  </div>
</template>

<script>
import {Toast} from 'vant';
import Compressor from 'compressorjs';
/**
 * Authentication组件
 */
export default {
  name: 'Authentication',
  data() {
    return {
      img1: require("@/assets/img/userCenter/idcard_f.png"),
      img2: require("@/assets/img/userCenter/idcard_b.png"),
      addImg1: '',
      addImg2: '',
      addFlag: ''
    }
  },

  mounted() {

  },

  methods: {
    upload(flag) {
      this.addFlag = flag;
    },
    loadImg() {
      Toast.clear();
    },
    next() {
      console.log(this.addImg1, this.addImg2)
      if (this.addImg1 && this.addImg2) {
        localStorage.setItem("addImg1", this.addImg1)
        localStorage.setItem("addImg2", this.addImg2)
        this.$router.replace({name: 'authenticationVideo'})
      }
    },
    // 将base64转换为file文件
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, {
        type: mime
      })
    },
    /* 读取文件后 */
    afterRead(file) {
      let isIMage = this.isImageFile(file)
      if (!isIMage) {
        this.$notify(this.$t("M.otc_upload_picture3"))
        return
      }
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });


      let that = this;
      let yFile = file.file
      if (yFile) {
        new Compressor(yFile, {
          quality: 0.6,
          success: (compressedResult) => {
            let formData = new FormData()
            formData.append('file', compressedResult)
            formData.append('type', 'other')
            that.$api.uploadfile(formData).then((res) => {
              if (res.meta.success) {
                if (this.addFlag == '1') {
                  that.img1 = res.data.fileUrl
                  that.addImg1 = res.data.fileUrl
                } else {
                  that.img2 = res.data.fileUrl
                  that.addImg2 = res.data.fileUrl
                }
                Toast.clear();
              } else {
                Toast.clear();
                that.$notify(that.$t('M.upload_again'))
              }
            })
          },
          error(err) {
            Toast.clear();
            that.$notify(that.$t("M.common_failure"))
          },
        });
      } else {
        Toast.clear();
        that.$notify(that.$t("M.common_failure"))
      }
    },
    isImageFile(file) {
      const acceptedImageTypes = ['image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/webp',
        'image/jpeg',
        'image/heif',
        'image/heic',
      ];
      return file && acceptedImageTypes.includes(file.file.type);
    },
    back() {
      this.$router.go(-1);//返回上一层
    },
  },
}
</script>

<style lang="scss" scoped>
.authentication {
  padding-top: 44px;
  background-color: #f8f8f8;
  padding-bottom: 40px;
  // min-height: 100vh;
}

.commonhead {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 44px;
  line-height: 44px;
  font-size: 2.8vw;
  background: #ffffff;
  color: #191b27;
  text-align: center;
  border-bottom: solid 1px #ebebf0;
}

.commonhead-icon {
  width: 10px;
  margin-top: 14px;
  margin-left: 20px;
  float: left;
}

.commonhead-tit {
  font-size: 4.2vw;
  margin-left: -10vw;
}

.commonhead-user {
  position: absolute;
  width: 6.8vw;
  top: 1.8vw;
  right: 28px;
}

.auth-title {
  color: #5c6573;
  font-size: 14px;
  margin: 15px;
}

.loading-img {
  margin-left: 10%;
  margin-top: 32vw;
  width: 80vw;
}

.loading-sp {
  position: absolute;
  width: 30vw;
  top: 50vw;
  left: 50%;
  margin-left: -15vw;
}

.custom-image .van-empty__image {
  width: 120px;
  height: 120px;
}

.idcard-box {
  width: 345px;
  height: 434px;
  background-color: #ededed;
  border-radius: 4px;
  margin-left: 15px;
}

.idcard-f {
  width: 248px;
  height: 152px;
  margin: 20px 48.5px;
}

.upload-tip {
  width: 100%;
  text-align: center;
  color: #00b897;
  font-size: 14px;
  margin-top: -10px;
  padding: 0;
}

.bottom-sample {
  margin-left: 5px;
  font-size: 12px;
  color: #9299a9;
}

.bottom-sample1 {
  display: flex;
  text-align: center;
}

.sp-img {
  width: 78.5px;
  margin-left: 10px;
}

.btn-commit {
  width: 345px;
  height: 46px;
  margin-left: 15px;
  margin-top: 10px;
}
</style>
