<!--
 * @Author:
 * @Date: 2022-06-26 09:28:58
 * @LastEditTime: 2023-08-16 15:02:07
 * @FilePath: \exchange-h5\src\views\login\register.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="container">

    <img class="topImg" src="../../assets/img/register/top.png" alt="" srcset="">
    <!-- <nav-bar :showLeft="false" :showRight="false" class="nav_bar">
      <router-link slot="left" class="left" to="login">
        <van-icon name="arrow-left" size="24px" />
      </router-link>
      <span slot="right" class="right" @click="onClickRight">{{$t('M.log_in')}}</span>
    </nav-bar> -->
    <nav-bar :showRight="false" class="nav_bar">
      <span slot="right" class="right" @click="onClickRight">{{$t('M.log_in')}}</span>
    </nav-bar>
    <img class="logo" src="../../assets/img/register/logo.png" alt="" srcset="">

    <div class="content">
      <div class="tabsBar">
        <div @click="changeTap('phone')" v-bind:class="formData.regType == 'phone' ? 'activeBtn' : ''">
          {{ $t("M.phone") }}
        </div>
        <div @click="changeTap('email')" v-bind:class="formData.regType == 'email' ? 'activeBtn' : ''">
          {{ $t("M.mail") }}
        </div>
      </div>

      <!-- 输入手机号 -->
      <div class="item" v-if="formData.regType == 'phone'">
        <div @click="countriesListShow = true">+{{formData.country}}</div> <img @click="countriesListShow = true" class="jiantou"
          src="../../assets/img/register/jiantou.png" alt="" srcset="">
        <van-field v-model="formData.userName" maxlength="50" :placeholder="$t('M.Enter phone number')" type="number" clearable />
      </div>
      <p v-if="formData.regType == 'phone' && formData.country == '855' " style="font-size:12px;color:red;line-height: 25px;padding-left: 10px;">
        {{$t('M.RegisterRemove0')}}</p>
      <!-- 地区 -->
      <!-- <div class="item emailC" v-if="formData.regType == 'email'" @click="countriesListShow = true">
        <div>
          {{showCountrName}}
        </div>
        <van-icon name="arrow" />
      </div> -->
      <!-- 输入邮箱 -->
      <div class="item" v-if="formData.regType == 'email'">
        <van-field v-model="formData.userName" maxlength="50" :placeholder="$t('M.Enter email address')" clearable />
      </div>
      <!-- 输入验证码 -->
      <div class="item">
        <van-field v-model="formData.checkCode" :placeholder="$t('M.input_phone_code')" type="number" clearable />
        <div class="sendMsg" @click="getValidationState" v-if="downTimeNum == 0">{{$t('M.get_code')}}</div>
        <div class="sendMsg" v-if="downTimeNum != 0"> {{ downTimeNum }}s</div>
      </div>
      <!-- 输入邀请码 -->
      <div class="item">
        <van-field v-model="formData.inviter" :placeholder="$t('M.InviteCodeMuster')" clearable type="number"/>
      </div>
      <!-- 同一 -->
      <div class="tongyiBox">
        <van-checkbox class="agree" v-model="checked" checked-color="#00b897" shape="square" icon-size="16px">{{ $t("M.I agree to") }} &nbsp;
        </van-checkbox>

        <!-- TODO 用户协议 -->
        <router-link :to="{ name: 'agreement'}">
          <span>{{ $t("M.user_service_agreement") }}</span>
        </router-link>
      </div>

      <div class="reginsterBtn" @click="onSubmit">
        {{ $t("M.register") }}
      </div>
    </div>

    <!-- <img class="bottomImg" src="../../assets/img/register/bottom.png" alt="" srcset=""> -->

    <van-popup v-model="countriesListShow" position="bottom" :style="{ height: '100%' }">
      <van-search v-model="inputCountrText" placeholder="Please select country or region" :autofocus="true" :show-action="true"
        @cancel="countriesListShow = false" @input="changeInput" :action-text="$t('M.Cancel')" />
      <div class="countriesListBox">
        <div class="listItem" v-for="(item, idx) in showCountriesListData" :key="idx" @click="selectItem(item)">
          <div class="name" v-if="item.nationCode != '855'">{{ item.english.replace(/\(China\)/g, '') }}</div>
          <div class="name" v-if="item.nationCode == '855'">Cambodia</div>
          <div class="number">+{{ item.nationCode }}</div>
        </div>
      </div>
    </van-popup>
    <!-- 网易 -->
    <div ref="captcha"></div>
    <Verify ref="verify" :captcha-type="'blockPuzzle'" :img-size="{ width: '300px', height: '200px' }" @success="login" />

  </div>
</template>

<script>
import navBar from '@/components/navBar';
import Verify from '@/components/verifition/Verify'
import {mapGetters} from 'vuex'

export default {
  name: 'register',
  components: {
    navBar, Verify
  },
  computed: {
    ...mapGetters([
      'lang', 'langWY'
    ]),
  },
  data() {
    return {
      active: 0,
      emailNum: '',
      checked: '',
      msgcode: '',
      invitation: '',
      countriesListShow: false,
      inputCountrText: '',
      showCountrName: '',
      country: '',
      countriesListData: [],
      showCountriesListData: [],
      formData: {
        country: "1", //区号
        userName: "", //用户名
        checkCode: "", //验证码
        regType: "phone", //注册类型
        partnerId: "472437831826935808", //商户id
        partnerNo: "1000010001", //商户编号
        inviter: "", //邀请码
      },
      instance: null, // 网易空间
      checkCodeState: '',
      downTimeNum: '',
      timeObj: null,
    };
  },

  mounted() {
    window.test = this;
    this.$api.selectList().then((res) => {
      this.countriesListData = res.data;
      this.showCountriesListData = res.data;
      const countr = this.countriesListData.find(item => item.nationCode == this.formData.country)
      this.showCountrName = countr && countr.english;
    })
  },

  methods: {
    login(params) {
      console.log("验证成功")
      this.getVerificationCode();
    },
    onSubmit() {
      let that = this;
      const { userName, checkCode } = this.formData;
      /* 验证用户 */
      if (userName == "") {
        if (this.formData.regType == 'phone') {
          this.$notify(this.$t("M.Enter phone number"));
        } else {
          this.$notify(this.$t("M.Enter email address"));
        }
        return;
      }
      /* 验证码 */
      if (!this.checkCodeState) {
        this.$notify(this.$t("M.get_code"));
        return;
      }
      /* 验证码 */
      if (checkCode == "") {
        this.$notify(this.$t("M.input_phone_code"));
        return;
      }
      /* 验证码 */
      if (!this.checked) {
        this.$notify(this.$t("M.I agree to"));
        return;
      }
      /* 验证码 */
      if (this.formData.inviter == '') {
        this.$notify(this.$t("M.InviteCodeMuster"));
        return;
      }
      let sendData = {
        "password": '',
        "partnerNo": '1000010001',
        "country": this.formData.country,
        "regType": this.formData.regType,
        "nickname": '',
        "inviter": this.formData.inviter,
        "timestamp": new Date().getTime(),
        "checkCode": this.formData.checkCode,
        "userName": this.formData.userName,
        "partnerId": '472437831826935808',
      }
      this.$api
        .registerUser({
          ...sendData,
        })
        .then((data) => {
          if (data.meta.success) {
            this.$notify(this.$t("M.registered_successfully"))
            this.$router.push({
              name: 'setPassword', query: { id: data.data.id, type: 1, userName: data.data.userName, },
            })
          } else {
            this.$notify(data.meta.message)
          }
        });
    },

    /* 倒计时开启 */
    timerDown() {
      this.downTimeNum;
      this.timeObj = setInterval(() => {
        this.downTimeNum--;
        if (this.downTimeNum <= 0) {
          this.downTimeNum = 0;
          clearInterval(this.timeObj);
        }
      }, 1000);
    },
    /* 获取验证码第一步 */
    getValidationState() {
      if (this.formData.userName == "") {
        if (this.formData.regType == 'phone') {
          this.$notify(this.$t("M.Enter phone number"));
        } else {
          this.$notify(this.$t("M.Enter email address"));
        }
        return;
      }
      if (this.formData.regType !== 'phone' && new RegExp(/\s+/g).test(this.formData.userName)) {
        this.$notify(this.$t("M.user-fail-reg-mail"));
        return;
      }
      this.$api.sliderSwitch().then(data => {
        console.log(data)
        if (data.data.sliderSwitch) {
          /* 获取验证 */
          // this.initNECaptcha()
          this.$refs.verify.show()
        } else {
          this.getVerificationCode()
        }
      })
    },
    /* 初始化网易易盾 */
    initNECaptcha() {
      /* 获取验证码的ID */
      this.$api.verify({
        "timestamp": new Date().getTime(),
        "partnerNo": 1000010001,
        "type": 'wyTouch',
        "partnerId": 472437831826935808,
      }).then((data) => {
        let verify = data.data.verify;
        let that = this;
        if (!verify) {
          this.initNECaptcha();
        }

        // let sendLang = "en-US";
        let sendLang = this.langWY[this.lang];

        let options = {
          element: that.$refs.captcha,
          captchaId: verify,
          mode: "popup",
          lang: sendLang,
          feedbackEnable: false,
          onVerify: function (err, data) {
            if (!err) {
              that.getVerificationCode();
            }
          },
        };

        initNECaptcha(
          options,
          function onload(instance) {
            that.instance = instance;
            that.instance.popUp()
          },
          function onerror(err) {
            console.log(err);
          }
        );

      });
    },
    getVerificationCode() {
      this.checkCodeState = true;
      if (this.formData.regType == "phone") {
        this.$api
          .sendSms({
            partnerId: "472437831826935808",
            phone: this.formData.userName,
            nationCode: this.formData.country,
          })
          .then((data) => {
            if (data.meta.code == 200) {
              this.downTimeNum = 59;
              this.timerDown();
              this.$notify(data.meta.message)
            } else {
              this.$notify(data.meta.message)
            }
          });
      } else {
        this.$api
          .sendMail({
            partnerId: "472437831826935808",
            email: this.formData.userName,
            nationCode: this.formData.country,
          })
          .then((data) => {
            if (data.meta.code == 200) {
              this.downTimeNum = 59;
              this.timerDown();
              this.$notify(data.meta.message)
            } else {
              this.$notify(data.meta.message)
            }
          });
      }
    },
    onClickRight() {
      this.$router.replace({ name: 'login' })
    },
    changeTap(num) {

      this.formData.regType = num;
      this.formData.userName = '';
      this.checked = '';
      this.formData.checkCode = '';
      this.formData.inviter = '';
      this.checkCodeState = false;
      clearInterval(this.timeObj);
      this.downTimeNum = 0;
    },
    /* 改变输入内容 */
    changeInput() {
      if (this.inputCountrText == "") {
        this.showCountriesListData = this.countriesListData;
      } else {
        let showArr = [];
        for (const iterator of this.countriesListData) {
          if (
            iterator.chinese.indexOf(this.inputCountrText) != -1 ||
            iterator.english.indexOf(this.inputCountrText) != -1 ||
            iterator.nationCode.indexOf(this.inputCountrText) != -1
          ) {
            showArr.push(iterator);
          }
        }
        this.showCountriesListData = showArr;
      }
    },
    /* 选中了某一个国家 */
    selectItem(data) {
      this.formData.country = data.nationCode;
      this.showCountrName = data.english;
      this.countriesListShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  position: relative;
  .topImg {
    width: 100%;
  }
  .logo {
    position: absolute;
    top: 115px;
    left: 50%;
    transform: translateX(-50%);
    width: 67px;
    // height: 75px;
  }
  .bottomImg {
    // position: fixed;
    // bottom: 0;
    width: 100%;
    // z-index: 1;
  }
  .content {
    padding: 0 45px;
    z-index: 99;
    margin-top: -25px;
    .item {
      width: 100%;
      border-radius: 3px;
      height: 44px;
      background: #f8f8fb;
      margin-top: 23px;
      display: flex;
      line-height: 44px;
      padding: 0 13px;
      font-size: 14px;
      align-items: center;
      .sendMsg {
        width: 100px;
        color: #00b897;
        text-align: right;
        line-height: 16px;
      }
    }
    .emailC {
      display: flex;
      justify-content: space-between;
    }
    .reginsterBtn {
      width: 100%;
      background: #00b897;
      border-radius: 3px;
      height: 44px;
      margin-top: 30px;
      color: #fff;
      text-align: center;
      line-height: 44px;
    }
  }
}
.jiantou {
  width: 7px;
  height: 4px;
  margin-left: 4px;
}
.tabsBar {
  font-size: 17px;
  display: flex;
  padding-left: 15px;
  line-height: 20px;
  color: #9299a9;
  div {
    margin-right: 20px;
  }
  .activeBtn {
    font-size: 21px;
    color: #00b897;
  }
}

.tongyiBox {
  display: flex;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  margin-top: 30px;
  .agree {
    padding-left: 15px;
    ::v-deep .van-checkbox__label {
      color: #c2c2c2;
      line-height: 14px;
    }
  }
  a {
    line-height: 16px;
    margin-left: 5px;
  }
  span {
    color: #000000;
    line-height: 14px;
  }
}
.right {
  display: block;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #131520;
}

::v-deep .van-nav-bar {
  background-color: transparent;
  .van-nav-bar__arrow {
    font-size: 24px;
  }
}
::v-deep .van-cell {
  background-color: transparent;
  padding: 0 5px;
  line-height: 44px;
}
::v-deep .van-field__body {
  display: flex;
}
::v-deep .van-popup {
  .van-search--show-action {
    padding-right: 10px;
  }
  .van-search__content {
    border-radius: 5px;
    .van-icon {
      color: #8a8a8a;
    }
  }
  .van-search__action {
    color: #8a8a8a;
  }
  .countriesListBox {
    .listItem {
      font-size: 14px;
      line-height: 54px;
      margin: 0 19px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e5e5e5;
    }
  }
}
</style>