/* eslint-disable */
/**
 * 配置编译环境和线上环境之间的切换
 * apiCommonUrl: 域名地址
 * routerMode: 路由模式
 * imgapiCommonUrl: 图片所在域名地址
 */
// 后端专递headers
let xDomainUrl = window.location.host.split(':')[0]
xDomainUrl = xDomainUrl.startsWith('www') ? xDomainUrl.slice(4) : xDomainUrl
    // 项目域名
let domainUrl = window.location.origin
let targetConfig = {
    domain: domainUrl,
    // xDomain: xDomainUrl
    xDomain: 'xkd.com'
}

// 修改globalHost
let globalHost = 'www.voenl.com'
const curLine = window.localStorage.getItem('curLinkLine') // 当前线路
const devTestConfig = {
    apiCommonUrl: 'http://www.voenl.com/v1/rest/pc/',
    // apiCommonUrl: 'http://192.168.50.100:8888/',
    // apiCommonUrl: !!curLine ? `http://${curLine}/v1/rest/pc/` : `http://${globalHost}/v1/rest/pc/`,
    // 行情 socket
    socketUrl: `ws://${globalHost}/v1/websocket/market`,
    // 扫码登录 socket
    loginSocketUrl: `ws://${globalHost}/v1/websocket/qrcode/qrcodeLogin/`,
    // OTC即时通讯、闪兑专区 详情 socket 推送
    OTCIMSocketUrl: `ws://${globalHost}/v1/websocket/websoc/`,
    // 存币宝 socket 推送
    IEOSocketUrl: `ws://${globalHost}/v1/websocket/ieoPush`,
    // 合约socket1
    contractMarketUrl: `ws://${globalHost}/v1/websocket/market`,
    // GMEX合约
    contractTradeUrl: `ws://${globalHost}/v1/websocket/trade`,
    // 合约 socket
    contractTradeSocketUrl: `ws://${globalHost}/v1/websocket/contract/market`,
    // 合约仓位socket
    // contractPositionSocketUrl: 'ws://www.xkd.com/v1/websocket/contract/socket'
    contractPositionSocketUrl: 'wss://www.voenl.com/v1/websocket/market',
    // 期权订单socket
    contractOptionSocketUrl: `ws://${globalHost}/v1/websocket/contract/option`,
    // 秒合约订单socket
    contractNewOptionSocketUrl: `ws://www.voenl.com/v1/websocket/new/contract/option`,
    contractKlineSocketUrl: 'ws://www.voenl.com/v1/websocket/contract/option',
}

const testingConfig = {
    // apiCommonUrl: window.location.host? `http://${ window.location.host}/v1/rest/pc/`: 'http://www.xkd.com/v1/rest/pc/',
    // socketUrl: window.g.socketUrl || `${wssApi}/market`,
    // loginSocketUrl: window.g.loginSocketUrl || `ws://${globalHost}/v1/rest/pc/qrcodeLogin/`,
    // OTCIMSocketUrl: window.g.OTCIMSocketUrl || `${wssApi}/ims`,
    // IEOSocketUrl: window.g.IEOSocketUrl || `ws://${globalHost}/v1/rest/pc/ieoPush`,
    // contractMarketUrl: `${wssApi}/futures_market`,
    // contractTradeUrl: `${wssApi}/futures_trade`,
    // contractTradeSocketUrl : window.location.host? `ws://${ window.location.host}/v1/websocket/contract/market`: `ws://www.xkd.com/v1/websocket/contract/market`,
    // contractPositionSocketUrl: window.location.host? `ws://${ window.location.host}/v1/websocket/contract/socket`: 'ws://www.xkd.com/v1/websocket/contract/socket'
    apiCommonUrl: !!curLine ? `http://${curLine}/v1/rest/pc/` : `http://${globalHost}/v1/rest/pc/`,
    socketUrl: !!curLine ? `ws://${curLine}/v1/websocket/market` : `ws://${globalHost}/v1/websocket/market`,
    loginSocketUrl: !!curLine ? `ws://${curLine}/v1/rest/pc/qrcodeLogin/` : `ws://${globalHost}/v1/rest/pc/qrcodeLogin/`,
    OTCIMSocketUrl: !!curLine ? `ws://${curLine}/v1/websocket/ims` : `ws://${globalHost}/v1/websocket/ims`,
    IEOSocketUrl: !!curLine ? `ws://${curLine}/v1/rest/pc/ieoPush` : `ws://${globalHost}/v1/rest/pc/ieoPush`,
    contractMarketUrl: !!curLine ? `ws://${curLine}/v1/websocket/futures_market` : `ws://${globalHost}/v1/websocket/futures_market`,
    contractTradeUrl: !!curLine ? `ws://${curLine}/v1/websocket/futures_trade` : `ws://${globalHost}/v1/websocket/futures_trade`,
    contractTradeSocketUrl: !!curLine ? `ws://${curLine}/v1/websocket/contract/market` : `ws://${globalHost}/v1/websocket/contract/market`,
    contractPositionSocketUrl: !!curLine ? `ws://${curLine}/v1/websocket/contract/socket` : `ws://${globalHost}/v1/websocket/contract/socket`,
    contractOptionSocketUrl: !!curLine ? `ws://${curLine}/v1/websocket/contract/option` : `ws://${globalHost}/v1/websocket/contract/option`,
    contractNewOptionSocketUrl: !!curLine ? `ws://${curLine}/v1/websocket/new/contract/option` : `ws://${globalHost}/v1/websocket/new/contract/option`,
    contractKlineSocketUrl: !!curLine ? `ws://${curLine}/v1/websocket/contract/option` : `ws://${globalHost}/v1/websocket/contract/option`,
}

// 修改globalHost
const prodConfig = {
    // apiCommonUrl: newApi,
    // socketUrl: window.g.socketUrl || `${wssApi}/market`,
    // loginSocketUrl: window.g.loginSocketUrl || `wss://${globalHost}/v1/rest/pc/qrcodeLogin/`,
    // OTCIMSocketUrl: window.g.OTCIMSocketUrl || `${wssApi}/ims`,
    // IEOSocketUrl: window.g.IEOSocketUrl || `wss://${globalHost}/v1/rest/pc/ieoPush`,
    // contractMarketUrl: `${wssApi}/futures_market`,
    // contractTradeUrl: `${wssApi}/futures_trade`,
    // contractTradeSocketUrl: window.location.host ? `wss://${window.location.host}/v1/websocket/contract/market` : `wss://www.xkd.com/v1/websocket/contract/market`,
    // contractPositionSocketUrl: window.location.host ? `wss://${window.location.host}/v1/websocket/contract/socket` : 'wss://www.xkd.com/v1/websocket/contract/socket'
    apiCommonUrl: !!curLine ? `https://${curLine}/v1/rest/pc/` : `https://${globalHost}/v1/rest/pc/`,
    socketUrl: !!curLine ? `wss://${curLine}/v1/websocket/market` : `wss://${globalHost}/v1/websocket/market`,
    loginSocketUrl: !!curLine ? `wss://${curLine}/v1/rest/pc/qrcodeLogin/` : `wss://${globalHost}/v1/rest/pc/qrcodeLogin/`,
    OTCIMSocketUrl: !!curLine ? `wss://${curLine}/v1/websocket/ims` : `wss://${globalHost}/v1/websocket/ims`,
    IEOSocketUrl: !!curLine ? `wss://${curLine}/v1/rest/pc/ieoPush` : `wss://${globalHost}/v1/rest/pc/ieoPush`,
    contractMarketUrl: !!curLine ? `wss://${curLine}/v1/websocket/futures_market` : `wss://${globalHost}/v1/websocket/futures_market`,
    contractTradeUrl: !!curLine ? `wss://${curLine}/v1/websocket/futures_trade` : `wss://${globalHost}/v1/websocket/futures_trade`,
    contractTradeSocketUrl: !!curLine ? `wss://${curLine}/v1/websocket/contract/market` : `wss://${globalHost}/v1/websocket/contract/market`,
    contractPositionSocketUrl: !!curLine ? `wss://${curLine}/v1/websocket/contract/socket` : `wss://${globalHost}/v1/websocket/contract/socket`,
    contractOptionSocketUrl: !!curLine ? `wss://${curLine}/v1/websocket/contract/option` : `wss://${globalHost}/v1/websocket/contract/option`,
    contractNewOptionSocketUrl: !!curLine ? `wss://${curLine}/v1/websocket/new/contract/option` : `wss://${globalHost}/v1/websocket/new/contract/option`,
    contractKlineSocketUrl: !!curLine ? `wss://${curLine}/v1/websocket/contract/option` : `wss://${globalHost}/v1/websocket/contract/option`,

}

switch (process.env.NODE_ENV) {
    case 'development':
        // 本地开发
        // targetConfig = {...targetConfig, ...devTestConfig}
        targetConfig = {
                ...targetConfig,
                ...devTestConfig,
                xDomain: 'xkd.com'
            }
            // targetConfig = {...targetConfig, ...testingConfig, xDomain: 'web.test.com'}
        break
        // 外网测试环境
    case 'testing':
        targetConfig = {...targetConfig, ...testingConfig }
        break
        // 生产环境
    case 'production':
        targetConfig = {...targetConfig, ...prodConfig }
        break
}

const {
    apiCommonUrl,
    socketUrl,
    loginSocketUrl,
    xDomain,
    domain,
    OTCIMSocketUrl,
    IEOSocketUrl,
    contractMarketUrl,
    contractTradeUrl,
    contractTradeSocketUrl,
    contractPositionSocketUrl,
    contractOptionSocketUrl,
    contractNewOptionSocketUrl,
    contractKlineSocketUrl
} = targetConfig
export {
    apiCommonUrl,
    socketUrl,
    loginSocketUrl,
    xDomain,
    domain,
    OTCIMSocketUrl,
    IEOSocketUrl,
    contractMarketUrl,
    contractTradeUrl,
    contractTradeSocketUrl,
    contractPositionSocketUrl,
    contractOptionSocketUrl,
    contractNewOptionSocketUrl,
    contractKlineSocketUrl
}
// 小程序运营方后台地址
// 测试地址
export const textMiniProgram = 'http://mini.xkd.com/';
// 线上地址
export const miniProgram = 'https://m.voenl.com/';