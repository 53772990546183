/* 币币资产 */
import BBAssets from "@/views/assets/bbAssets";
import BBCapitalFlow from "@/views/assets/bbAssets/bbCapitalFlow";
export default [

    { // bb资产
        path: '/bbAssets',
        name: 'bbAssets',
        component: BBAssets,
    },
    { // 币币资金流水
        path: '/bbCapitalFlow',
        name: 'bbCapitalFlow',
        component: BBCapitalFlow,
    },
]