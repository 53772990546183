<template>
  <div class="container">
    <nav-bar :title="$t('M.new_address')"></nav-bar>

    <div class="main">

      <div class="tabs cell" v-if="chainData.chainList">
        <div class="tit">{{$t("M.user_link_name")}}</div>

        <div class="tabs_item">
          <div v-for="(item,i) in chainData.chainList" :key="i" :class="[active==i ?'active_btn':'','btn']" @click="onChange(i)">{{item.chain}}
          </div>
        </div>
      </div>

      <div class="cell">
        <div class="tit">{{$t('M.remark')}}</div>
        <van-field v-model="remark" center :placeholder="$t('M.input_remark')">
        </van-field>
      </div>

      <div class="cell">
        <div class="tit">{{$t('M.Coin_Address_draw')}}</div>
        <van-field v-model="address" center :placeholder="$t('M.input_mention_money_address')">
          <div class="slot_button" slot="button">
            <!-- <img src="@/assets/img/assets/icon_scanning.png" alt=""> -->
          </div>
        </van-field>
      </div>

      <div class="btn_wrap">
        <div class="button submit_button" @click="onSubmit">{{$t('M.affirm')}}</div>
      </div>

    </div>
    <popup-password :show="show" @onClose="onClosed" @onSubmit="onSubmitPass" type="address" :params="params" />

  </div>
</template>

<script>
import navBar from '@/components/navBar';
import popupPassword from '@/components/popupPassword';

export default {
  name: 'adressAdd',
  components: {
    navBar, popupPassword
  },
  computed: {

  },
  data() {
    return {
      query: {},
      active: 0,
      chainData: {},
      remark: '',
      address: '',

      params: {},
      show: false,
    };
  },
  beforeMount() {
    Object.assign(this.query, this.$route.query);

  },
  mounted() {
    this.coinWallet()
  },

  methods: {
    coinWallet() {
      this.$api.coinWallet(this.query.coinId).then((response) => {
        if (!response.meta.success) return
        this.chainData = response.data
      })
    },


    validateAddress(params) {
      this.$api.validateAddress(params).then((response) => {
        if (!response.meta.success) return false
        return true
      }).then((res) => {
        if (res) {
          this.show = true
        }
      })
    },

    onSubmit() {
      const params = {
        address: this.address,
        coinId: this.query.coinId,
        remark: this.remark,
        rechargeType: this.chainData.chainList ? this.chainData.chainList[this.active].chain : '',
      }
      if (!this.address) return this.$notify(this.$t('M.recharge_withdraw_failure_0014'))
      if (!this.address) return this.$notify(this.$t('M.account_failure_00115'))
      this.params = params
      this.validateAddress(params)
    },
    // 确认交易密码的回调
    onSubmitPass() {
      this.remark = ''
      this.address = ''
      this.show = false
      this.getAssets()
      setTimeout(() => {
        this.$router.back()
      }, 1000);

    },
    onClosed() {
      this.show = false
    },



    getAssets() {
      const params = {
        coinId: this.query.coinId,
        rechargeType: this.chainData.chainList ? this.chainData.chainList[this.active].chain : '',
      }
      this.$api.getAssets(params).then((response) => {
        const { coinAddress } = response.data
        this.$store.commit('SET_WITHDRAW_ADDRESS', coinAddress.userWithdrawAddressDtoList)
      }).catch(() => {
        this.$store.commit('SET_WITHDRAW_ADDRESS', {})
      })

    },

    onChange(i) {
      this.active = i
    }

  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0 15px;
}
.main {
  .tabs {
    .tabs_item {
      display: flex;
      align-items: center;
      .btn {
        width: 85px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 400;
        color: #9299a9;
        border: 1px solid #9299a9;
        margin-right: 15px;
      }
      .active_btn {
        background: #00b897;
        font-weight: 600;
        color: #ffffff;
        border: none;
      }
    }
  }
  .cell {
    font-size: 14px;
    font-weight: 400;
    .tit {
      font-size: 14px;
      color: #191b27;
      padding: 20px 0 15px;
      font-weight: 600;
      span {
        font-size: 12px;
        color: #9299a9;
      }
    }
    .slot_button {
      display: flex;
      align-items: center;
      img {
        width: 19px;
        height: 19px;
      }
    }
    .button {
      // width: 58px;
      padding: 0 0 0 15px;
      height: 100%;
      text-align: center;
      border-left: 1px solid #ebebf0;
      color: #00b897;
      margin-left: 10px;
    }
    .desc {
      font-size: 12px;
      color: #9299a9;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      > span {
        > span {
          color: #ff6060;
        }
      }
    }
  }
}
::v-deep .cell .van-field {
  width: 100%;
  height: 43px;
  border-radius: 4px;
  border: 1px solid #c7ccd8;
  color: #9299a9;
  .van-field__control {
    color: #9299a9;
  }
}
</style>