<template>
    <div class="home">
      <van-nav-bar :title="$t('M.Position')" left-arrow @click-left="leftArrow">
          <template #left>
            <van-icon name="arrow-left" size="1.2em" />
          </template>
        </van-nav-bar>
        <van-tabs  v-model="activePopup" line-width="16px" line-height="3px" title-active-color="#00b897"
                title-inactive-color="#5C6573" color="#00b897" @change="changeBarPopup">
            <van-tab title-class="titItem" :title="$t('M.Position')+'('+DataList.length+')'">
              <van-row style="padding: 5px 15px;background-color: #f7f7f7;">
                <van-col style="text-align: right;" offset="12" span="12">
                  <van-popover
                    v-model="showPopover"
                    trigger="click"
                    :actions="actions"
                    @select="onSelect"
                    placement="bottom-end"
                    overlay
                  >
                    <template #reference><span>{{actionsContent}}</span><van-icon name="arrow-down" /></template>
                  </van-popover>
                </van-col>
              </van-row>
              <div style="overflow-y: scroll;height: calc(100vh - 130px);">
              <!-- <van-list v-model="loading" :finished="finished" @load="onLoad" loading="loading">  -->
                <div class="box"  v-for="(item,index) in DataList" :key="index">
                    <van-row class="row"  gutter="5">
                      <van-col span="22"><span class="Sub title">{{item.tradeName}}</span></van-col>
<!--                      <van-col span="2"><span @click.stop="$emit('popup',item,'share')" class="Sub title"><van-icon color="#00b897" name="share" /></span></van-col>-->
                    </van-row>
                    <van-row gutter="5">
                      <van-col span="24" class="margin">
                        <van-tag v-if="item.openType!='OPEN_DOWN'" color="#e7f7f2" text-color="#18AE83">{{tradeMode2(item.openType)}}</van-tag>
                        <van-tag v-else color="#ffefef" text-color="#e9697e">{{tradeMode2(item.openType)}}</van-tag>
                        <van-tag color="#f8f8f8" text-color="#5C6573">{{tradeMode(item.positionsType)}}</van-tag>
                        <van-tag @click="$emit('popup',item,'3')" color="#f8f8f8" text-color="#5C6573">{{item.lever}}X<van-icon color="#00b897" name="edit" /></van-tag>
                      </van-col>
                    </van-row>
                    <van-row >
                      <van-col class="left" span="8"><span>{{$t('M.PL')}}({{item.buyCoinNickname}})</span></van-col>
                      <van-col class="center" span="8"><span>{{$t('M.Open interest')}}({{item.sellCoinNickname}})
                        <van-icon @click="explanatoryData(item)" name="question-o" />
                      </span></van-col>
                      <van-col class="right" v-if="item.positionsType=='BY'" span="8"><span>{{$t('M.Margin-1')}}({{item.buyCoinNickname}})</span></van-col>
                      <van-col class="right" v-else span="8"><span>{{$t('M.Initial margin')}}({{item.buyCoinNickname}})</span></van-col>
                    </van-row>
                    <van-row>
                      <van-col class="left" span="8"><span class="Sub" :class="Math.sign(item.allIncomeAmount) == -1?'red':'gre'">{{Math.sign(item.allIncomeAmount)==-1?'':'+'}}{{item.allIncomeAmount}}({{Math.sign(item.allIncomeAmount)==-1?'':'+'}}{{ item.allIncomeAmountRate }})</span></van-col>
                      <van-col class="center" span="8"><span class="Sub">{{item.maxHoldAmount}}</span></van-col>
                      <!-- <van-col v-if="item.positionsType=='BY'" span="8"><span class="Sub">{{item.margin}}</span></van-col> -->
                      <van-col class="right"  span="8"><span class="Sub">{{item.positionAmount}}</span></van-col>
                    </van-row>
                    <van-row >
                      <van-col class="left" span="8"><span>{{$t('M.Avg open price')}}({{item.buyCoinNickname}})</span></van-col>
                      <van-col class="center" span="8"><span>{{$t('M.Margin ratio')}}</span></van-col>
                    </van-row>
                    <van-row >
                      <van-col class="left" span="8"><span class="Sub">{{item.openPrice}}</span></van-col>
                      <van-col class="center" span="8"><span class="Sub">{{(item.positionRate-0).toFixed(4)}}%</span></van-col>
                    </van-row>
                    <van-row v-if="item.positionsType=='TOTAL'" gutter="5">
                      <van-col span="8"><van-button @click="$emit('popup',item,'5')" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.TP | SL')}}</span></van-button></van-col>
                      <van-col span="8"><van-button @click="$emit('popup',item,'6')" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.Pingclose')}}</span></van-button></van-col>
                      <van-col span="8"><van-button @click="$emit('popup',item,'8')" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.MKT Close ALL')}}</span></van-button></van-col>
                    </van-row>
                    <van-row v-else gutter="5">
                      <van-col span="6"><van-button @click="$emit('popup',item,'4')" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.Margin-2')}}</span></van-button></van-col>
                      <van-col span="6"><van-button @click="$emit('popup',item,'5')" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.TP | SL')}}</span></van-button></van-col>
                      <van-col span="6"><van-button @click="$emit('popup',item,'6')" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.Pingclose')}}</span></van-button></van-col>
                      <van-col span="6"><van-button @click="$emit('popup',item,'8')" color="#cde3f7" block size="small"><span style="color: #00b897;">{{$t('M.MKT Close ALL')}}</span></van-button></van-col>
                    </van-row>
                    <van-divider />
                  </div>
                  <!-- </van-list> -->
                </div>
            </van-tab>
            <van-tab title-class="titItem" :title="$t('M.Position history')">
              <van-row style="padding: 5px 15px;background-color: #f7f7f7;">
                <van-col style="text-align: right;" offset="12" span="12">
                  <van-popover
                    v-model="showPopover1"
                    trigger="click"
                    :actions="actions1"
                    @select="onSelect1"
                    placement="bottom-end"
                    overlay
                  >
                    <template #reference><span>{{actionsContent1}}</span><van-icon name="arrow-down" /></template>
                  </van-popover>
                </van-col>
              </van-row>
              <div style="overflow-y: scroll;height: calc(100vh - 130px);">
              <van-list v-model="loading" :finished="finished" @load="onLoad" loading="loading"> 
                <div class="box"  v-for="(item,index) in historyList" :key="index">
                    <van-row class="row"  gutter="5">
                      <van-col span="22"><span class="Sub title">{{item.tradeName}}</span></van-col>
                      <van-col span="2"><span @click.stop="$emit('popup',item,'share')" class="Sub title"><van-icon color="#00b897" name="share" /></span></van-col>
                    </van-row>
                    <van-row gutter="5">
                      <van-col span="24" class="margin">
                        <van-tag v-if="item.openType!='OPEN_DOWN'" color="#e7f7f2" text-color="#18AE83">{{tradeMode(item.openType)}}</van-tag>
                        <van-tag v-else color="#ffefef" text-color="#e9697e">{{tradeMode(item.openType)}}</van-tag>
                        <van-tag color="#f8f8f8" text-color="#5C6573">{{tradeMode(item.positionsType)}}</van-tag>
                        <span>{{item.lever}}X</span>
                        <span style="float:right">{{tradeMode(item.status)}}</span>
                      </van-col>
                    </van-row>
                    <van-row >
                      <van-col span="8"><span>{{$t('M.Avg cost')}}({{item.buyCoinNickname}})</span></van-col>
                      <van-col span="8"><span>{{$t('M.Closing PnL gains')}}({{item.buyCoinNickname}})
                        <!-- <van-icon name="question-o" /> -->
                      </span></van-col>
                      <van-col span="8"><span>{{$t('M.Max Open int')}}({{item.sellCoinNickname}})</span></van-col>
                    </van-row>
                    <van-row>
                      <van-col span="8"><span class="Sub" >{{ item.openPrice }}</span></van-col>
                      <van-col span="8"><span class="Sub">{{item.incomeAmount}}</span></van-col>
                      <van-col span="8"><span class="Sub">{{item.maxHoldAmount}}</span></van-col>
                    </van-row>
                    <van-row >
                      <van-col span="8"><span>{{$t('M.Avg close price')}}({{item.buyCoinNickname}})</span></van-col>
                      <van-col span="8"><span>{{$t('M.Closing PnL ratio')}}</span></van-col>
                      <van-col span="8"><span>{{$t('M.Closed')}}({{item.sellCoinNickname}})</span></van-col>
                    </van-row>
                    <van-row >
                      <van-col span="8"><span class="Sub">{{item.unwindPrice}}</span></van-col>
                      <van-col span="8"><span class="Sub">{{cutOutPointLength((parseFloat(item.incomeRate) * 100),2) }}%</span></van-col>
                      <van-col span="8"><span class="Sub">{{item.unwindAmount}}</span></van-col>
                    </van-row>
                    <van-row >
                      <van-col span="12"><span >{{$t('M.Open Time')}}</span></van-col>
                      <van-col span="12" class="right"><span >{{item.createTime}}</span></van-col>
                    </van-row>
                    <van-row >
                      <van-col span="12"><span >{{$t('M.Close out time')}}</span></van-col>
                      <van-col span="12" class="right"><span >{{item.allTime}}</span></van-col>
                    </van-row>
                    <van-divider />
                  </div>
                  </van-list>
                </div>
            </van-tab>
        </van-tabs>
    </div>
  </template>

  <script>
  import { mapGetters } from "vuex";
  export default {
    computed: {
    ...mapGetters(["isLogin", "user", "lang"]),
    },
    props:{
      userActualPositionAccountList:{
      type:Array|null,
      default: []
    },
      tradeListUpDataItem:{
        type:Array|null,
        default: []
      },
      tradeList:{
        type:Array|null,
        default: []
      },
    },
    watch:{
      userActualPositionAccountList:{
        handler(newValue, oldValue) {
          // console.log('?????');
          if (newValue && newValue.length > 0) {
            newValue.forEach(item => {
              if (newValue.length == this.DataList.length) {
                this.DataList.forEach((items, index) => {
                  if (item.contractPositionVo.id == items.id) {
                    this.DataList[index].positionAmount = item.positionAmount.toFixed(4)
                    this.DataList[index].openPrice = item.openPrice
                    this.DataList[index].preForcePrice = item.preForcePrice
                    this.DataList[index].nowTagPrice = item.nowTagPrice
                    this.DataList[index].positionRate = item.positionRate * 100
                    this.DataList[index].holdIncome = item.holdIncome
                    this.DataList[index].holdIncomeRate = ((item.holdIncomeRate*100).toFixed(2))*1+'%'
                  }
                })
              } else {
                this.currentPosition(this.actionsKey)
              }
            })
          }
        },
        deep: true
      },
      tradeListUpDataItem:{
        handler(newQuestion, oldQuestion) {
          const tradePairListArr = new Map(this.tradeList.map(item => [item.symbol, item]));
          this.DataList.forEach(item => {
            if(item.symbol == newQuestion.tradeName){
              const matchingItemB = tradePairListArr.get(item.symbol);
              //手续费绿
              let feeMaker = item.openType === "OPEN_UP" ? matchingItemB.openMaker : matchingItemB.openTaker //4.46218025
              //开仓价格
              let openPrice = item.openPrice
              //开仓数量
              let openNumber = item.holdAmount
              //手续费
              let feeNumber = openPrice * openNumber * feeMaker
              if (item.openType === "OPEN_UP") {
                let shouyiNumber = (newQuestion.last - openPrice) * openNumber - feeNumber
                let shouyiNumberBai = (shouyiNumber / (openPrice * openNumber)) * item.lever
                item.allIncomeAmount = shouyiNumber.toFixed(4)
                item.allIncomeAmountRate = (shouyiNumberBai * 100).toFixed(2)
              } else {
                let shouyiNumber = (openPrice - newQuestion.last) * openNumber - feeNumber
                let shouyiNumberBai = (shouyiNumber / (openPrice * openNumber)) * item.lever
                item.allIncomeAmount = shouyiNumber.toFixed(4)
                item.allIncomeAmountRate = (shouyiNumberBai * 100).toFixed(2)
              }
            }
          });
        },
        deep: true
      },
    },
      data(){
          return{
              loading: false,
              finished: false,
              activeSubsetShow2:false,
              orderShow:false,
              showPopover:false,
              showPopover1:false,
              showPopover2:false,
              actions:[{text: this.$t('M.Margin mode'),key:''},{text: this.$t('M.Cross'),key:'TOTAL'},{text: this.$t('M.Isolated'),key:'BY'},{text: this.$t('M.liquidation'),key:''}],
              actions1:[{text: this.$t('M.Recent close'),key:this.$t('M.Recent close')},{text: this.$t('M.Highest PnL ratio'),key:this.$t('M.Highest PnL ratio')},{text: this.$t('M.Lowest PnL ratio'),key:this.$t('M.Lowest PnL ratio')}],
              actions2:[{text: this.$t('M.all'),key:'ALL'},{text: this.$t('M.Transfer'),key:'CONTRACT_TRANSFER'},{text: this.$t('M.tab_trade'),key:'CONTRACT_DEAL'},{text: this.$t('M.liquidation'),key:'CONTRACT_FORCE_CLOSE'},{text: this.$t('M.Margin transfer'),key:'CONTRACT_EARNEST_MONEY_TRANSFER'},{text: this.$t('M.Capital fee'),key:'CONTRACT_FUND_RATE'}],
              actionsContent: this.$t('M.Margin mode'),
              actionsContent1: this.$t('M.Recent close'),
              actionsContent2: this.$t('M.all'),
              activePopup:0,
              value:'',
              DataList:[],
              historyList:[],
              fundList:[],
              historyPositionParams:{
                pageNumber:1,
                pageSize:10,
                condition:''
              },
              fundParams:{
                pageNumber:1,
                pageSize:10,
                contractType:''
              },
              actionsKey:'',
              actionsKey1:this.$t('M.Recent close'),
              actionsKey2:'ALL',
          }
      },
      mounted(){
          this.currentPosition(this.actionsKey)
          this.historyPosition(this.$t('M.Recent close'))
          this.fundBill('ALL')
      },
      methods:{
        tradeMode2(key){
            if(key =='OPEN_UP') return this.$t('M.Long')
            if(key =='OPEN_DOWN') return this.$t('M.Short')
          },
          apiData(){
            this.currentPosition(this.actionsKey)
          },
          //仓位查询
          currentPosition(key){
            if (!this.isLogin)return
              this.$api.currentPosition({positionsType:key}).then(res=>{
                this.DataList = res.data.list
              })
          },
          //历史仓位查询
          historyPosition(key){
            if (!this.isLogin)return
            this.historyPositionParams.condition = key
              this.$api.historyPosition(this.historyPositionParams).then(res=>{
                console.log(res);
                this.historyList = res.data.hlist.list
              })
          },
          //资金流水
          fundBill(key){
            if (!this.isLogin)return
            this.fundParams.contractType = key
            this.$api.fundBill(this.fundParams).then(res=>{
              this.fundList = res.data.fundBill.list
              console.log(res);
            })
          },
          fundColor(a,b){
            let num = (a-0)+(b-0)
            if(num < 0)return true
            return false
          },
          fundNum(a,b){
            let num = (a-0)+(b-0)
            if(num < 0)return '+'+num
            return '-'+num
          },
          tradeMode(key){
            if(key =='OPEN_UP') return this.$t('M.Open long')
            if(key =='OPEN_DOWN') return this.$t('M.Open short')
            if(key =='CLOSE_UP') return this.$t('M.Close long')
            if(key =='CLOSE_DOWN') return this.$t('M.Close short')
            if(key =='BY') return this.$t('M.Isolated')
            if(key =='TOTAL') return this.$t('M.Cross')
            if(key =='NO_UNWIND') return this.$t('M.Unfilled')
            if(key =='PART_UNWIND') return this.$t('M.Partially closed')
            if(key =='ALL_UNWIND') return this.$t('M.All Closed')
            return '-'
        },
          leftArrow(){
              this.$emit('dreg')
          },
          onSelect(e){
              this.actionsContent = e.text
              this.actionsKey = e.key
              this.currentPosition(e.key)
          },
          onSelect1(e){
              this.actionsContent1 = e.text
              this.historyPositionParams.pageNumber = 1
              this.actionsKey2 = e.key
              this.historyPosition(e.key)
          },
          onSelect2(e){
              this.actionsContent2 = e.text
              this.actionsKey2 = e.key
              this.fundParams.pageNumber = 1
              this.fundBill(e.key)
          },
          //批量撤单确认按键
          orderConfirm(){
              this.orderShow=false
          },
           //点击品种弹窗
          SelectPopup(){
              this.$emit('popup','2')
              this.activeSubsetShow1 = true
          },
          onLoad() {
            if (!this.isLogin)return
            if(this.activePopup==1){
            this.historyPositionParams.pageNumber++;
            this.$api.historyPosition(this.historyPositionParams).then(res=>{
                if(res.data.hlist.list.length != 0) {
                  res.data.hlist.list.forEach(element => {
                    this.historyList.push(element)
                  });
                  this.loading = false
                }else{
                  this.loading = false
                  this.finished = true
                }
            })
          }
          if(this.activePopup==2){
            // this.fundParams.pageNumber++;
            // this.$api.fundBill(this.fundParams).then(res=>{
            //           if(res.data.fundBill.list.length != 0) {
            //             res.data.fundBill.list.forEach(element => {
            //             this.fundList.push(element)
            //             });
            //             this.loading = false
            //           }else{
            //             this.loading = false
            //             this.finished = true
            //           }
            // })
          }
          },
          changeBarPopup(e){
            // if(e==0){
            //   this.currentPosition(this.actionsKey)
            // }
            // if(e==1){
            //   this.historyPosition(this.actionsContent1)
            // }
            // if(e==3){
            //   this.historyPosition(this.actionsContent1)
            // }
          }

      }
  }
  </script>
  <style lang="scss">
  .dropdown {
      .van-dropdown-menu__bar{
          background: none;
          height: auto;
          box-shadow: none;
      }
      .cell{
          padding: 10px 10px!important;
      }
      .dropdownTitle{

      }
      .van-dropdown-menu__item{
          justify-content: left;
      }
  }
  </style>
  <style lang="scss" scoped>


  .box{
    padding: 10px;
    .van-row{
      margin-bottom: 10px;
      .margin{
        span{
            margin-right: 10px;
          }
      }
      span{
        color: #5C6573;
        word-break: break-all;
      }
      .Sub{
        color: #000;
      }
      .title{
        font-size: 16px;
      }
      .left{text-align: left;}
      .right{text-align: right;}
      .center{text-align: center;}
      .red{
        color: #e54d68;
      }
      .gre{
        color: #1baf84;
      }
    }
  }
  .titItem {
      font-size: 16px;
      font-weight: 600;
    }
  </style>
