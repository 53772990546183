<!-- home -->
<template>
  <div class="phoneOption container">
    <div id="commonhead" class="commonhead comheadmenu " style="display: block;">
      <img @click="back" class="commonhead-icon" src="../../assets/img/public/ic_back.png" /> <!-- ../../assets/img/back.png -->
      <span class="commonhead-tit">{{$t('M.phone_code')}}</span>
    </div>
    <div class="main-content">
      <div class="top-functional">
        <van-cell class="item-cell" value="130****0505">
          <template #title>
            <span class="item-title">{{$t('M.phone')}}</span>
          </template>
        </van-cell>

        <van-cell class="item-cell">
          <!-- Use the title slot to customize the title -->
          <template #title>
            <span class="item-title">{{$t('M.phone_verification')}}</span>
          </template>
          <template #right-icon>
            <van-switch @click="onBankSwitchClick" :disabled="isBankBind" @change="onBankEnableChange" v-model="isBankEnable" size="20"
              active-color="#00b897" inactive-color="#5C6573" />
          </template>
        </van-cell>

        <van-cell class="item-cell" is-link>
          <template #title>
            <span class="item-title">{{$t('M.change_phone')}}</span>
          </template>
        </van-cell>
      </div>
    </div>
  </div>
</template>

<script>
// import settingsService from '@/api/settings.js'
import { Toast } from 'vant'

export default {
  data() {
    return {
      isBankEnable: false,
      isAlipayEnable: false,
      isWeixinEnable: false,
      isXilianEnable: false,
      isPaypalEnable: false,
      isAlipayBind: false,
      isPaypalBind: false,
      isXilianBind: false,
      isWeixinBind: false,
      isBankBind: false
    }
  },

  computed: {},

  mounted() {
    this.getReceiptMethods()
  },

  methods: {
    async getReceiptMethods() {
      try {
        const res = await settingsService.getReceiptMethods()
        // console.log(res)
        if (res.meta.success) {
          const methodsData = res.data
          if (methodsData) {
            this.isAlipayBind = methodsData.isAlipayBind
            this.isBankBind = methodsData.isBankBind
            this.isWeixinBind = methodsData.isWeixinBind
            this.isXilianBind = methodsData.isXilianBind
            this.isPaypalBind = methodsData.isPaypalBind

            if (methodsData.isBnakEnable === 'enable') {
              this.isBankEnable = true
            } else {
              this.isBankEnable = false
            }

            if (methodsData.isAlipayEnable === 'enable') {
              this.isAlipayEnable = true
            } else {
              this.isAlipayEnable = false
            }

            if (methodsData.isWeixinEnable === 'enable') {
              this.isWeixinEnable = true
            } else {
              this.isWeixinEnable = false
            }

            if (methodsData.isXilianEnable === 'enable') {
              this.isXilianEnable = true
            } else {
              this.isXilianEnable = false
            }

            if (methodsData.isPaypalEnable === 'enable') {
              this.isPaypalEnable = true
            } else {
              this.isPaypalEnable = false
            }
          }
        } else {
          Toast(res.meta.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    back() {
      this.$router.go(-1);//返回上一层
    },
    onBankEnableChange(checked) {
      // console.log(this.isBankEnable)
      const params = {}
      params.type = 'Bankcard'
      if (this.isBankEnable) {
        params.status = 'enable'
      } else {
        params.status = 'disable'
      }

      settingsService.receiptMethodsEnable(params)
        .then((res) => {
          if (!res.meta.success) {
            Toast(res.meta.message)
            this.isBankEnable = !this.isBankEnable
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    onAlipayEnableChange(checked) {
      // console.log(this.isAlipayEnable)
      const params = {}
      params.type = 'Alipay'
      if (this.isAlipayEnable) {
        params.status = 'enable'
      } else {
        params.status = 'disable'
      }

      settingsService.receiptMethodsEnable(params)
        .then((res) => {
          if (!res.meta.success) {
            Toast(res.meta.message)
            this.isAlipayEnable = !this.isAlipayEnable
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    onWeixinEnableChange(checked) {
      // console.log(this.isWeixinEnable)
      const params = {}
      params.type = 'Wechat'
      if (this.isWeixinEnable) {
        params.status = 'enable'
      } else {
        params.status = 'disable'
      }

      settingsService.receiptMethodsEnable(params)
        .then((res) => {
          if (!res.meta.success) {
            Toast(res.meta.message)
            this.isWeixinEnable = !this.isWeixinEnable
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    onXilianEnableChange(checked) {
      // console.log(this.isXilianEnable)
      const params = {}
      params.type = 'WestUnion'
      if (this.isXilianEnable) {
        params.status = 'enable'
      } else {
        params.status = 'disable'
      }

      settingsService.receiptMethodsEnable(params)
        .then((res) => {
          if (!res.meta.success) {
            Toast(res.meta.message)
            this.isXilianEnable = !this.isXilianEnable
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    onPaypalEnableChange(checked) {
      // console.log(this.isPaypalEnable)
      const params = {}
      params.type = 'PAYPAL'
      if (this.isPaypalEnable) {
        params.status = 'enable'
      } else {
        params.status = 'disable'
      }

      settingsService.receiptMethodsEnable(params)
        .then((res) => {
          if (!res.meta.success) {
            Toast(res.meta.message)
            this.isPaypalEnable = !this.isPaypalEnable
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    onAlipaySwitchClick() {
      if (this.isAlipayBind) {
        Toast('请先绑定支付宝')
      }
    },
    onBankSwitchClick() {
      if (this.isBankBind) {
        Toast('请先绑定银行卡')
      }
    },
    onWeixinSwitchClick() {
      if (this.isWeixinBind) {
        Toast('请先绑定微信')
      }
    },
    onXiLianSwitchClick() {
      if (this.isXilianBind) {
        Toast('请先绑定西联汇款')
      }
    },
    onPaypalSwitchClick() {
      if (this.isPaypalBind) {
        Toast('请先绑定PAYPAL')
      }
    },
    toBankSetting() {
      this.$router.push({ name: 'ReceiptBankSetting' })
    },
    toAlipaySetting() {
      this.$router.push({ name: 'ReceiptAlipaySetting' })
    },
    toWechatSetting() {
      this.$router.push({ name: 'ReceiptWechatSetting' })
    },
    toXilianSetting() {
      this.$router.push({ name: 'ReceiptXilianSetting' })
    },
    toPaypalSetting() {
      this.$router.push({ name: 'ReceiptPaypalSetting' })
    }
  }
}
</script>
<style lang="scss" scoped>
.phoneOption {
  min-height: 100vh;
  width: 100vw;
  background-color: #f8f8f8;
  .navbar {
    position: fixed;
    width: 100vw;
    background-color: #ffffff;
    // /deep/ .van-ellipsis{
    //   color: #ffffff !important;
    // }

    // /deep/ .van-icon {
    //   color: #ffffff !important;
    // }
  }

  .commonhead {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 44px;
    line-height: 44px;
    font-size: 2.8vw;
    background: #ffffff;
    color: #191b27;
    text-align: center;
    border-bottom: solid 1px #ebebf0;
  }

  .commonhead-icon {
    width: 10px;
    margin-top: 14px;
    margin-left: 20px;
    float: left;
  }

  .commonhead-tit {
    font-size: 4.2vw;
    margin-left: -10vw;
  }

  .commonhead-user {
    position: absolute;
    width: 6.8vw;
    top: 1.8vw;
    right: 28px;
  }

  .navbar-handle {
    width: 18px;
  }

  .van-hairline--bottom::after {
    border-bottom-width: 0rem;
  }

  .main-content {
    width: 100vw;
    height: 100vh;
    // padding: 30px;
    display: flex;
    display: -webkit-flex; /* Safari */
    flex-direction: column;
  }

  .top-functional {
    width: 92vw;
    height: 160px;
    margin-left: 4vw;
    margin-top: 60px;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .sec-functional {
    width: 92vw;
    height: 108px;
    margin-left: 4vw;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .item-cell {
    height: 52.5px;
    background-color: transparent;
    padding: 14.5px;

    .receipt-icon {
      width: 14px;
      height: 14px;
      margin-top: 5px;
    }

    .receipt-title {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    .receipt-content {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    .item-title {
      color: #191b27;
    }

    .item-custom-bx {
      position: relative;
      display: inline-block;
      float: right;
      color: #9299a9;
    }

    .ic-notice {
      left: -20px;
      top: 4px;
      width: 16px;
      height: 16px;
      position: absolute;
    }

    .van-cell__value {
      color: #9299a9;
    }
  }

  .item-cell.top-cell {
    height: 70px;
  }

  .tip-txt {
    margin: 12px 15px;
    font-size: 12px;
    color: #5c6573;
  }

  .item-cell::after {
    border-bottom: 1.5px solid #ebebf0;
  }

  .cell-level {
    width: 100%;

    .level-bx {
      width: 100%;
    }

    .level-tit {
      display: inline-block;
      font-size: 15px;
    }

    .level-tip {
      display: inline-block;
      font-size: 12px;
      color: #00b897;
      float: right;
    }

    .level-bar {
      display: inline-block;
      width: 98.33px;
      height: 4px;
      background-color: #d5d5d5;
    }

    .level-bar.selected {
      background-color: #00b897;
    }

    .mg-lft {
      margin-left: 10px;
    }
  }
}

// /deep/ {
//   /* 滚动条隐藏 */
//   ::-webkit-scrollbar {
//     width: 0px;
//   }
// }
</style>
