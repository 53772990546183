<template>
  <div class="popupCertain" >
    <div class="title">
      <div class="titletop">{{$t('M.Leverage_BB')}}</div>
      <div class="titlebottom">{{ itemData.tradeName }}</div>
    </div>
    <div class="sub">{{$t('M.Current')}}{{ itemData.lever }}.0X</div>
    <div class="pper">
      <van-stepper class="popupStepper" disabled :show-plus='false' :show-minus='false' v-model="value" @change="selectChange" input-width="40px" button-size="24px" />
    </div>
    <div class="activeNum">
      <div v-for="(item, index) in leverList" :key="index" @click="changeNumber(activeNum = item)"
        :class="activeNum == item ? 'active' : ''">{{ item }}X</div>

    </div>
    <div class="describe">
      <div>
        <span>{{$t('M.Max position size at current leverage')}}:</span><span style="color: #00b897;">{{ contractData.openAmount }}{{ itemData.sellCoinNickname
        }}</span>
      </div>
      <div>
        <span>{{$t('M.Required position margin at current leverage')}}</span><span style="color: #00b897;">{{ contractData.needPosition }}{{ itemData.buyCoinNickname
        }}</span>
      </div>
    </div>
    <div class="buttoncon">
      <van-button color="#00b897" size="small" round block @click="confirmLever">{{$t('M.confirm')}}</van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['itemData'],
  data() {
    return {
      value: '',
      activeValue: '',
      leverList: ['10', '20', '50','100', '125'],
      activeNum: '',
      contractData: {}
    }
  },
  mounted() {
    this.value = this.itemData.lever
    this.activeValue = this.itemData.lever
    this.selectChange()
  },
  methods: {
    selectChange() {
      this.activeNum = this.value
      console.log(this.itemData);
      let params = {
        tradeId: this.itemData.contractTradeTypeId,
        positionsType: this.itemData.positionsType,
        tradeMode: this.itemData.openType,
        lever: this.value
      };
      this.$api.selectChange(params).then(res => {
        this.contractData = res.data
      })
    },
    confirmLever() {
      let params = {
        tradeId: this.itemData.contractTradeTypeId,
        positionsType: this.itemData.positionsType,
        tradeMode: this.itemData.openType,
        lever: this.value
      };
      this.$api.changeLever(params).then(res => {
        this.$emit('dreg')
      });
    },
    changeNumber(name) {
      this.value = name
    }
  }
}
</script>

<style lang="scss">
.popupCertain {
  padding: 10px;

  .van-tabs__wrap {
    height: auto;
  }

  .van-tabs__nav--card {
    margin: 0;
  }

  .buttoncon {
    margin: 15px 0;
  }

  .describe {
    margin-top: 10px;
    padding: 5px;
    background-color: #f8f8f8;

    div {
      padding: 5px;
    }
  }

  .title {
    border-bottom: 1px solid #e3e5eb;

    .titletop {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 5px;
    }

    .titlebottom {
      // font-size: 16px;
      color: #000;
      padding-bottom: 5px;
    }
  }

  .sub {
    text-align: center;
    padding: 10px;
    color: #5C6573;
  }

  .pper {
    text-align: center;
    padding: 5px;
    margin-bottom: 10px;

    .popupStepper {

      .van-stepper__minus,
      .van-stepper__plus {
        background-color: white;
        border: 1px solid #00b897;
        color: #00b897;
        border-radius: 5px;
      }

      .van-stepper__input {
        color: #00b897!important;
        -webkit-text-fill-color: #00b897;
        font-size: 18px;
        background-color: #fff;
      }

      .van-stepper__minus--disabled {
        background-color: white;
        border: 1px solid #e3e5eb;
        color: #e3e5eb;
        border-radius: 5px;
      }
    }
  }

  .activeNum::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .activeNum {
    overflow-x: auto;
    border: 1px solid #00b897;
    white-space: nowrap;

    div {
      display: inline-block;
      height: 30px;
      border-right: 1px solid #00b897;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      line-height: 30px;
      text-align: center;
      width: 70px;
    }

    div:last-child {
      border-right: none;
    }

    .active {
      color: #fff;
      background-color: #00b897;
    }
  }
}</style>
