export const M = {
    "Slideright": "Slide to the right",
    "Pleaseverification": "Please complete security verification",
    "Pleaseorder": "Please click in order",
    "Verificationagain": "Verification failed, please try again",
    "10 orders": "10 Orders",
    "24h High": "24H High",
    "24h Low": "24H Low",
    "24h Trading Vol": "24h Trading Vol",
    "24h Vol": "24h Vol",
    "ADL mechanism": "ADL Mechanism",
    "AMT": "Minimum",
    "Activity _home": "Activity",
    "Actual transaction number": "Actual transaction number",
    "Actual transaction price": "Actual transaction price",
    "Add margin": "Margin call",
    "Adjust leverage": "Adjust Leverage",
    "Adjust success": "Adjust Success",
    "Advanced limit order offers 3": "Advanced limit order offers3 more order options than a regular limit order, including“Post Only”, “Fill or Kil“ and“Immediate or Cancel“The regular limit order has been defaulted as “Good till Canceled”",
    "Alipay": "Alipay",
    "All Closed": "All Closed",
    "All Filled": "All Filled",
    "AllDetails": "Details",
    "AllType": "All Types",
    "Amount can flat": "Max",
    "Appeal_reason": "Appeal Reason",
    "Applet service": "Applet Service",
    "Are you sure to cancel the order？": "Are you sure to cancel the order？",
    "Asked_to_record": "Record video as required",
    "Asset conversion": "Equity Value",
    "Asset_types": "Asset Type",
    "Assets": "Assets",
    "At the current price": "At the current price",
    "Avail.": "Available",
    "Avail. margin": "Avail. margin",
    "Available": "Available",
    "Avg close price": "Avg.close price",
    "Avg open price": "Avg.open price",
    "Avg.price": "Average Price",
    "BBO": "BBO",
    "Bankcard": "Bank Card",
    "Bankruptcy price": "Bankruptcy Price",
    "Batch Cancel": "Batch Cancel",
    "Branch_address": "Address",
    "Buy crypto_home": "Buy",
    "Calculations are not based on": "The calculation result is for reference only. The liquidation price only represents the data of only one open position under one contract.",
    "Can't calculate": "Unable to calculate",
    "Cancel": "Cancel",
    "Cancel all": "Batch Cancel",
    "Cancel order": "Cancel Order",
    "Cancel_the_login": "Cancel Login",
    "Capital cost": "Capital Cost",
    "Capital cost - Expenditure": "Capital-Expenditure",
    "Capital fee": "Capital Fee",
    "Capital fee - all": "Capital Fee - all",
    "Capital fee - income": "Capital Fee - income",
    "Capital success": "Cancelled",
    "Charge_money": "Deposit",
    "Chinese_Yuan": "RMB",
    "Close all of": "Confirm to close ",
    "Close long": "Close Long",
    "Close out time": "End time of all liquidation",
    "Close price": "Close Price",
    "Close short": "Close Short",
    "Closed": "Liquidation Quantity",
    "Closing PnL gains": "Profit",
    "Closing PnL ratio": "Profit Rate",
    "Closing profit": "Closing Profit",
    "coin_failure_0002": "Currency data does not exist",
    "Coming soon": "Coming Soon",
    "Common functions": "Common Functions",
    "Communtiy_home": "Communtiy",
    "Conceal canceled orders": "Hidden Revocation",
    "Confirm_the_login": "Confirm Login",
    "Confirm_to_mention_money": "Confirm",
    "Cont": "Sheet",
    "Copy link": "Copy Link",
    "Cross": "Cross",
    "Currency_rate_description": "Rate Description",
    "Current": "Current",
    "Delete_option": "Delete",
    "Determine_to_cancel": "Are you sure you want to cancel?",
    "Don't show me again": "No more reminders",
    "Draw_inconsistency": "Inconsistent password input again",
    "Draw_the_unlock": "Please swipe to set a new password",
    "Enter email address": "Enter email address",
    "Enter expected PnL": "Enter Expected PnL",
    "Enter expected PnL Ratio": "Enter Expected PnL Ratio",
    "Enter leverage from 1 up to 125": "Enter leverage from 1 up to 125",
    "Enter phone number": "Enter phone number",
    "Enter the verification code": "Enter verification code",
    "Equity": "Currency Rights",
    "Est liquidation price": "Est liquidation price",
    "Face value": "Contract Value",
    "Filed Value": "Transaction Value",
    "Fill or Kill": "Completed or canceled immediately",
    "Filled amt": "Volume",
    "Filled time": "Settlement Time",
    "Flash_strong": "Flash",
    "Funding rate": "Funding Rate",
    "Funding rate - 1": "Funding Rate",
    "Funding rate comparison": "Funding Rate Comparison",
    "Funding rate time": "Funding rate time",
    "Fuzzy_information": "Blur",
    "Gesture_error": "Enter the password incorrectly",
    "Google_code": "Google verification code",
    "Help_home": "Help",
    "Hide_no_asset_currency": "Hide without assets",
    "Highest PnL ratio": "Highest Yield",
    "Historical record": "History",
    "I agree to": "Tick Agree",
    "I have read and agree to this agreement": "I have read and agree to this agreement",
    "If_the_buyer_is_at": "If the buyer is at",
    "Immediately or Cancel": "Close immediately and cancel the remaining",
    "In force": "In force",
    "Index": "Spot Index",
    "Index Price": "Index Price",
    "Index_contrct": "Spot Index",
    "Info": "Introduction to the contract",
    "Initial margin": "IMR",
    "Instrument": "Current trading varieties",
    "Insurance fund": "Insurance Fund",
    "Interest bearing record": "Interest bearing record",
    "Invalid_qr_code": "Invalid qr code",
    "Invite code(Optional)": "Invitation Code (Optional)",
    "InviteCodeMuster": "Please enter the invite code (required)",
    "Invite_home": "Invitation",
    "Isolated": "Isolated",
    "Lack_of_edge": "Missing",
    "Last Price": "Market Price",
    "Latest Filled price": "Last",
    "Latest Filled price01": "Last Price",
    "Latest price": "Last Price",
    "Leverage": "Leverage",
    "Leverage after increase": "Leverage after increase：",
    "Leverage11": "Leverage",
    "Leverage_BB": "Adjust Margin",
    "Limit order is an order": "Limit order is an order that limits the maximum buying price of the buyer, and the minimum selling price of the setler. After youf order is placed, our system will post it on the book, and match it with the orders available - at the price you specified or better.",
    "Limit price": "Limit",
    "Limit/Market": "Limit/Market",
    "LimitAndMarket": "Limit/Market",
    "Liquidation price": "Liquidation",
    "Liquidation price after increase": "Liquidation price after increase：",
    "Long": "Long",
    "Lowest PnL ratio": "Lowest Yield",
    "MAIL_COUNTRY_NOT_EXITS": "The current country does not exist! Please add the message and send it again.",
    "MAIL_NOT_MAIL_PROVIDER": "There is no mail service provider, please add it and send mail later!",
    "MAIL_TEMPLATE_NOT_FOUND": "The current mail template does not exist! Please add it and send it again",
    "MAIL_TO_ADDRESS_NOT_FOUND": "The current email address does not exist. Please check it again and send it again.",
    "MJRefreshAutoFooterIdleText": "Tap or pull up to load more",
    "MJRefreshAutoFooterNoMoreDataText": "No data",
    "MJRefreshAutoFooterRefreshingText": "Loading...",
    "MJRefreshBackFooterIdleText": "Pull up to load more",
    "MJRefreshBackFooterNoMoreDataText": "No data",
    "MJRefreshBackFooterPullingText": "Release to load more.",
    "MJRefreshBackFooterRefreshingText": "Loading...",
    "MJRefreshHeaderDateTodayText": "Today",
    "MJRefreshHeaderIdleText": "Pull down to refresh",
    "MJRefreshHeaderLastTimeText": "Last Updated:",
    "MJRefreshHeaderNoneLastDateText": "No Record",
    "MJRefreshHeaderPullingText": "Release to refresh",
    "MJRefreshHeaderRefreshingText": "Loading...",
    "MKT Close ALL": "MKT Close ALL",
    "Margin add": "Add Margin",
    "Margin mode": "Margin Mode",
    "Margin ratio": "Margin Rate",
    "Margin reduce": "Remove Margin",
    "Margin transfer": "Margin Transfer",
    "Margin-1": "Margin Balance",
    "Margin-2": "Margin",
    "Margined": "Contracts",
    "Mark price": "Mark Price",
    "Market": "Market",
    "Market Order Dig": "Buy and sell immediately at the current price",
    "Market Overview": "Market Overview",
    "Market_transaction": "Market Price",
    "Max": "Max",
    "Max Open int": "Max Open int",
    "Max buy": "Max Buy",
    "Max long": "Max Long",
    "Max position size at current leverage": "Max position size at current leverage：",
    "Max sell": "Max Sell",
    "Max short": "Max Short",
    "Max position size at current leverage": "Max position size at current leverage",
    "Mention_money": "Withdraw",
    "Mention_money_address": "Please enter the correct address",
    "Mention_money_tips_ios": "prompt\n1. Enter the withdrawal address directly, you need to verify the fund password, SMS, email or Google verification code.\n2. Add the address binding to your own authentication address. You do not need to enter the verification again for the next time.",
    "Messages_notification": "*Please confirm that your bank card has SMS notification service enabled",
    "Mini Programs": "Mini Programs",
    "Minimum_handling_charge": "The number of coins must be greater than the handling fee",
    "Minimum_withdrawal": "Minimum Withdrawal",
    "Most increase": "Max Increase Amt",
    "Most reduce": "Max Decrease Amt",
    "Must be integer multiple of": "Must be integer multiple of",
    "Not Enabled": "Not Enabled",
    "Not_on": "Can't be put on the shelves",
    "Not_to_mention_money": "In order to ensure the security of funds, it is not possible to perform some sensitive operations within 1h after resetting the transaction password",
    "OTC account": "Fiat Account",
    "OTC assets": "OTC  Wallet",
    "OTC交易须知": "OTC Trading Notes",
    "On Hold": "Freeze",
    "One-way": "One-way",
    "One/Two-way": "One/Two-way",
    "Open Time": "Open Time",
    "Open amount": "Open Amount",
    "Open int.": "Open int.",
    "Open interest": "Open Interest",
    "Open long": "Open Long",
    "Open orders": "Open Orders",
    "Open price": "Open Price",
    "Open short": "Open Short",
    "Order Details": "Order Details",
    "Order Sorting": "Order Sorting",
    "Order Value": "Order Value",
    "Order amt": "Total Order",
    "Order history": "Order History",
    "Order price": "Order Price",
    "Order trigger direction": "Order trigger direction",
    "Order type": "Order Type",
    "PL": "PL",
    "PL Ratio": "Return",
    "Pair01": "Contract Currency",
    "Parse_failure": "Failed Save",
    "Partially Filled": "Partially Filled",
    "Partially closed": "Partially Closed",
    "Password": "Password",
    "Payment_type": "Receipt Type",
    "Perpetual": "Perpetual",
    "Perpetual funds daybook": "Perpetual bill",
    "Perpetual01": "Contract",
    "Pingclose": "Close",
    "Please input a password": "Please input a password",
    "Position": "Position",
    "Position balance": "Position Balance",
    "Position history": "Historical Positions",
    "QR_code": "QR Code",
    "Quantity cannot exceed": "Quantity cannot exceed 125",
    "Quantity not less than": "Quantity not less than 1",
    "Recent close": "Recent Close",
    "Recording_requirements": "Record video request",
    "Required position margin at current leverage": "Required position margin at current leverage：",
    "Reset_transaction_password": "Reset pay password",
    "Revoked": "Revoked",
    "SL": "SL",
    "SL order": "Stop Loss Order",
    "SL order price": "SL order price",
    "SL order price can't be empty": "SL order price can't be empty",
    "SL trigger price": "SL trigger price",
    "SL trigger price can't be empty": "SL trigger price can't be empty",
    "SMS_30_OUT_RESTRICTED": "The verification code is sent frequently, and only one can be sent every 30 seconds.",
    "SMS_BAD_ARGUMENT_FORMAT": "The phone format is incorrect.",
    "SMS_COUNTRY_NOT_EXITS": "This country does not exist, please check and send a text message!",
    "SMS_DAY_LIMIT_PER_MOBILE": "The number of times the same mobile phone number was sent exceeded the limit within 24 hours",
    "SMS_DUP_IN_SHORT_TIME": "Repeat the same content within 30 seconds of the same mobile number",
    "SMS_FAILURE": "Failed to send",
    "SMS_HOUR_LIMIT_PER_MOBILE": "The number of times the same mobile phone number was sent exceeded the limit within 1 hour",
    "SMS_MARKET_FORBIDDEN": "Marketing SMS is temporarily unavailable due to operator control",
    "SMS_NOT_SMS_PROVIDER": "No SMS service provider, please add a message before sending!",
    "SMS_NOT_SMS_PROVIDER_TEMPLATE": "There is no SMS service provider template yet, please add it before sending SMS!",
    "SMS_PHONE_OVER_COUNT": "This mobile phone number verification code is sent more than once, please try it after 24 hours!",
    "SMS_PHONE_PROHIBIT_SEND": "This mobile phone is forbidden to send text messages. Please try it after 24 hours!",
    "SMS_SUCCESS": "Sent Successfully",
    "SMS_TOO_MANY_TIME_IN_5": "Repeat the same content more than 3 times within 5 minutes of the same mobile phone number",
    "SMS_UNKNOWN_EXCEPTION": "Unknown abnormality occurred in the system",
    "SMS_code": "SMS verification code",
    "Save picture": "Save Picture",
    "Scanning_to_obtain": "Scan the QR to get deposit address",
    "See auto deleverage liquidation for details": "See auto deleverage liquidation for details",
    "Service_home": "Service",
    "Set_transaction_password": "Set payment password",
    "Settlement1": "Settlement",
    "Settlrment coin": "Settlrment Coin",
    "Share time": "Share Time",
    "Shielding success": "Shielding Success",
    "Short": "Short",
    "Sign Up": "Register",
    "Sign in": "Login",
    "Simplified_Chinese": "English",
    "StatusOrder": "Status",
    "Stop loss": "Stop loss",
    "Stop profit stop loss": "Stop profit stop loss at least choose one, otherwise can not order",
    "Sustainable account": "Contract Account",
    "Sustainable assets": "Contract Assets",
    "Switch to PnL": "Switch to PnL",
    "Switch to PnL ratio": "Switch to PnL ratio",
    "TP": "TP",
    "TP order": "TP order",
    "TP order price": "TP order price",
    "TP order price can't be empty": "TP order price can't be empty",
    "TP trigger price": "TP trigger price",
    "TP trigger price can't be empty": "TP trigger price can't be empty",
    "TP | SL": "Stop P/L",
    "TP | SL order": "TP | SL order",
    "Take profit": "Take profit",
    "Telegraphic_transfer_address": "Account",
    "The Calculator": "Calculator",
    "The maximum flatable quantity is": "Max Avail. position",
    "The maximum leverage is": "The maximum leverage is",
    "The minimum flatable quantity is": "The minimum flatable quantity is",
    "The minimum leverage is": "The minimum leverage is",
    "The poster has been saved to the album": "The poster has been saved to the album",
    "The_correct_sample": "Correct",
    "The_input_signal": "Swipe to enter your password",
    "The_new_address": "New Address",
    "They_are_the_number_of": "Open amount",
    "Tick size": "Tick Size",
    "To_update": "Update",
    "Toggle_front_camera": "Switch the front camera keep the head centered",
    "Total": "Total",
    "Total coin": "Total Coin",
    "Total cont": "Total Cont",
    "Total value": "Total Value",
    "Total_asset_valuation": "Total Value",
    "Trade type": "Trade Type",
    "Trade_sector": "Please Choose",
    "Transfer": "Transfer",
    "Transfer_to_remind": "Please be sure to remark when transferring money (otherwise, at your own risk)",
    "Trigger mark price": "Trigger Mark Price",
    "Trigger price": "Trigger price",
    "Two-way": "Two-way",
    "UPL": "UPL",
    "US_dollar": "USD",
    "Unblocked": "Unblocked",
    "Uneasy lies the head that wears a crown": "Uneasy lies the head\n that wears a crown",
    "Unfilled": "Unfilled",
    "Unfilled11": "Unfilled",
    "Upload_collection_code": "Please upload the receipt code",
    "Usdt-Margined": "Usdt-Margined",
    "Users who register": "Users who register for the first time must complete the login password setting",
    "Verification Code": "Code",
    "Videoing_requirement": "Shooting Requirements",
    "View": "View",
    "View more": "View More",
    "Waiting effect": "Waiting effect",
    "Waiting_for_the_payment": "To be pay",
    "Wechat": "WeChat",
    "WestUnion": "Western Union",
    "Without_her": "Not Seen",
    "Your closing number": "Your closed position is less than the minimum position",
    "Your have pending Cross orders": "Your have pending Cross orders.Cancel them before adjusting your leverage.",
    "Your have pending isolated orders": "Your have pending isolated orders.Cancel them before adjusting your leverage.",
    "abandoned": "Have given up",
    "abnormal_assets": "Account assets are abnormal, this operation cannot be performed temporarily!",
    "about_us": "About Us",
    "account": "Account",
    "account_empty": "Account Error",
    "account_failure_0001": "The transaction flow ID adds REDIS failed!",
    "account_failure_0002": "Insufficient to use, can't handle!",
    "account_failure_0003": "User freeze increases logging failure!",
    "account_failure_0004": "User freeze increase failed!",
    "account_failure_0005": "The merchant deducted the available log records failed!",
    "account_failure_0006": "Merchant deductions available for failure!",
    "account_failure_0007": "User available to increase logging failure!",
    "account_failure_0008": "User available increase failed!",
    "account_failure_0009": "Merchants add new currency, users fail to add new currency!",
    "account_failure_0010": "Merchant failed to add new currency!",
    "account_failure_00100": "Merchant ID cannot be empty!",
    "account_failure_00101": "User ID cannot be empty",
    "account_failure_00102": "Currency ID cannot be empty",
    "account_failure_00103": "Quantity cannot be empty",
    "account_failure_00104": "The quantity cannot be less than the minimum transaction volume",
    "account_failure_00105": "Transaction password cannot be empty",
    "account_failure_00106": "Transaction password is wrong",
    "account_failure_00107": "Price cannot be empty",
    "account_failure_00108": "The unit price cannot be less than the minimum unit price",
    "account_failure_00109": "User does not exist!",
    "account_failure_0011": "Merchant Wallet This currency already exists!",
    "account_failure_00110": "PUSH asset failed to launch!",
    "account_failure_00111": "PUSHID cannot be empty",
    "account_failure_00112": "PUSH asset payment failed!",
    "account_failure_00113": "PUSH asset cancellation failed!",
    "account_failure_00114": "Unable to get the currency address",
    "account_failure_00115": "The coin address cannot be empty!",
    "account_failure_00116": "Adding a coin address failed",
    "account_failure_00117": "ID cannot be empty",
    "account_failure_00118": "Deleting the coin address failed",
    "account_failure_00119": "Network anomalies, please try again later!",
    "account_failure_0012": "Insufficient freezing, can't handle!",
    "account_failure_00120": "The coin address already exists",
    "account_failure_00121": "No PUSH currency",
    "account_failure_00122": "The phone verification code is empty!",
    "account_failure_00123": "The mailbox verification code is empty!",
    "account_failure_00124": "Google verification code is empty!",
    "account_failure_00125": "Verification code error!",
    "account_failure_00126": "Prohibit Self-push!",
    "account_failure_00127": "The coin address is not legal!",
    "account_failure_00129": "It is not allowed to change the password for more than 24 hours.",
    "account_failure_0013": "Merchant freeze deduction log record failed!",
    "account_failure_00130": "Asset is unbalanced and cannot be operated",
    "account_failure_00131": "Account assets abnormal, transaction status disabled",
    "account_failure_00132": "Invalid Tag",
    "account_failure_00133": "Token not exist or disabled",
    "account_failure_0014": "Merchant freeze deduction failed!",
    "account_failure_0015": "Users can increase the logging failure with interest!",
    "account_failure_0016": "User available interest increase failed!",
    "account_failure_0017": "The merchant can use the interest deduction log record failed!",
    "account_failure_0018": "Merchants can use interest deductions to fail!",
    "account_failure_0019": "User available to freeze failed!",
    "account_failure_0020": "User available to freeze failed, logging failed!",
    "account_failure_0021": "User freeze to available logging failed!",
    "account_failure_0022": "User freeze to available failure!",
    "account_failure_0023": "Merchants have failed to increase available logging!",
    "account_failure_0024": "Merchants increase availability available!",
    "account_failure_0025": "User freeze deduction log record failed!",
    "account_failure_0026": "User freeze deduction failed!",
    "account_failure_0027": "Users can deduct log records failed!",
    "account_failure_0028": "User available deduction failed!",
    "account_failure_0029": "Rollback operation failed User freeze recovery log record failed!",
    "account_failure_0030": "Rollback operation failed User freeze recovery failed!",
    "account_failure_0031": "The assets are not enough to roll back!",
    "account_failure_0032": "Failed rollback operation merchant freeze recovery log record failed!",
    "account_failure_0033": "Rollback operation failed, merchant asset recovery failed",
    "account_failure_0034": "Merchant freeze failed to increase logging!",
    "account_failure_0035": "Merchant freeze increased!",
    "account_failure_0036": "Merchants have failed to freeze log records!",
    "account_failure_0037": "Merchants available to freeze failed!",
    "account_failure_0038": "Merchant freeze to available logging failed!",
    "account_failure_0039": "Merchant freezes to availability failure!",
    "account_failure_0040": "Platform available to increase logging failure!",
    "account_failure_0041": "Platform available increase failed!",
    "account_failure_0042": "The transaction executed abnormally!",
    "account_failure_0043": "The transaction has failed to execute!",
    "account_failure_0044": "The transaction has been executed successfully!",
    "account_failure_0045": "The transaction is being executed!",
    "account_failure_0046": "Successful Processing!",
    "account_failure_0047": "Rollback operation failed platform asset recovery failed",
    "account_failure_0048": "Rollback operation failed platform asset recovery logging failed",
    "account_failure_0049": "The platform already exists!",
    "account_failure_0050": "The platform failed to add a new currency!",
    "account_failure_0051": "The platform already exists!",
    "account_failure_0053": "The transaction type is wrong!",
    "account_failure_0054": "Merchants increase the failure!",
    "account_failure_0055": "Merchants can increase logging failures!",
    "account_failure_0056": "User table merchants, platform revenue transfer failed!",
    "account_failure_0057": "User table merchant, platform revenue transfer log record failed!",
    "account_failure_0058": "The user can go to financial management failed!",
    "account_failure_0059": "User available to the financial logging failed!",
    "account_failure_0060": "Insufficient financial management, unable to execute!",
    "account_failure_0061": "User financing failed to be available!",
    "account_failure_0062": "User financing is available, logging fails!",
    "account_failure_0063": "Failed, no actionable data!",
    "account_failure_0064": "Temporarily Unavailable",
    "account_failure_0065": "No right to view this",
    "account_failure_0071": "User lock to freeze abnormal!",
    "account_failure_0072": "User deposit record not exist or modified",
    "activitie_end": "Activity Ends",
    "activity_invalid": "Expired",
    "actual_amount": "Total actual volume",
    "actual_exchange_amount": "Actual Convert",
    "actual_to_account": "Actual Arrival",
    "addSuccess": "Added successfully",
    "add_channel_function": "Added verification channel function",
    "add_charge_and_transfer_function": "Added functions for depositing money and transferring entries",
    "added_alert_setting_function": "Added alert setting function",
    "added_day_mode_toggle": "Added day mode switching function",
    "address_tag": "Label",
    "advanced_limit_order": "Advanced Limit Order",
    "advertising": "Advertisement",
    "advertising_management": "Advertising management",
    "affirm": "Confirm",
    "affirm_pass": "Confirm",
    "again_digits6": "Re-enter",
    "again_password": "Enter your login password again",
    "airdrop_coin": "Airdrop Currency",
    "airdrop_date": "Airdrop Time",
    "airdrop_number": "Number of airdrops",
    "alipay_account": "Alipay Account",
    "alipay_upload": "* Alipay upload QR code method: Open Alipay home page > collect money > save the picture, upload the receipt code of the mobile phone album.",
    "all": "All",
    "all_buy": "Buy All",
    "all_country": "All Countries",
    "all_order": "All Orders",
    "all_sell": "Sell All",
    "already_use": "Activated",
    "amount_of_payout": "Amount of payout",
    "announcement_all": "All Announcements",
    "announcement_center": "Announcement",
    "announcement_official": "Official Announcement",
    "annualized_rate": "Annualized rate",
    "appeal_cancel": "Determine to cancel",
    "appeal_complete": "Determine to complete",
    "appeal_dispose_time": "Appeal Time",
    "appeal_of_time": "Submission Time",
    "apply_locked": "Apply for lock",
    "asset_allocatio": "Asset Distribution",
    "assets_asset_valuation": "Asset Valuation",
    "assets_can_transfer": "Transferable",
    "assets_cash_flow": "Funding Records",
    "assets_coin_funds_flow": "Spot bill",
    "assets_currency_assets": "Spot Assets",
    "assets_deposit_income_tip": "The value of the income is calculated by the real-time exchange rate.",
    "assets_fiat_assets": "Fiat Assets",
    "assets_fiat_money_flow": "Fiat bill",
    "assets_flowing_details": "Record Details",
    "assets_prompt_001": "[Please backup your key properly to prevent loss]",
    "assets_reduced_asset": "Asset Conversion",
    "assets_row_mining": "Row Mining",
    "assets_swipe_direction": "Tranfer Direction",
    "assets_transfer": "Transfer",
    "assets_transfer_coin": "Transfer Currency",
    "assets_transfer_count": "Number of transfer",
    "assets_transfer_currency": "Transfer of funds",
    "assets_transfer_dialog_bb": "Spot Account",
    "assets_transfer_dialog_fiat": "Fiat Account",
    "assets_transfer_dialog_from": "From",
    "assets_transfer_dialog_transfer_to": "Transfer to",
    "assets_transfer_dialog_wallet": "Wallet Account",
    "assets_transfer_record": "Transfer Record",
    "assets_transfer_to": "to",
    "assets_wallet": "Wallet",
    "assets_wallet_assets": "Wallet Assets",
    "assets_wallet_assets_transfer_tip": "* Transactions can only be made by transferring wallet assets to a counter account. No transfer fee is charged for transfers between accounts.",
    "assets_wallet_funds_flow": "Wallet bill",
    "at_least_one": "Turn on at least one transaction password verification status",
    "attention_blacklist": "Follow/Block",
    "attention_to_see": "Want to follow me, Click to view!",
    "audit_result": "Audit Results",
    "authenticated": "Verified",
    "authentication": "Authentication",
    "available_balance": "Available",
    "average_purchase_price": "Average purchase price",
    "avoid_close_set": "Setting for payment",
    "back": "Back",
    "balance": "Balance",
    "bank_card": "Bank Card",
    "bank_name": "Bank Name",
    "bank_num": "Card Number",
    "barcode_in_the_box_scan": "barcode in the box scan",
    "bb_assets": "Currency Assets",
    "bb_history_finished": "Complete",
    "bb_time": "Time",
    "bb_used": "Enable",
    "be_usable": "Be usable",
    "bear_consequences_your_investment": "I have known and voluntarily assumed the investment risk. My certification code is:",
    "become": "Become",
    "been_authenticated": "You have real name Authentication",
    "beginner_guide_title": "The world's leading digital asset trading platform",
    "belong_level": "Hierarchy",
    "between_and": "To",
    "bill": "Bill",
    "billing_details": "Bill Details",
    "bind_email": "Bind Email",
    "bind_google": "Google Authenticator",
    "bind_google_authenticator": "Bind Google Authenticator",
    "bind_phone": "Binding Phone",
    "branch_name": "Branch Name",
    "bug_order": "Buy",
    "buy": "Buy",
    "buy_1": "Buy",
    "buy_now": "Buy Now",
    "buy_quantity": "Purchase Quantity",
    "buy_rate": "Buying Renewal",
    "buyer": "Buyer",
    "buyer_has_paid_attention": "Buyer has paid attention",
    "buyer_payment": "The buyer has paid",
    "buyer_phone": "Phone",
    "buyer_uid": "The Opponent's",
    "calculate": "Calculate",
    "calculations are for your reference only": "Calculations are for your reference only",
    "can_available": "Available",
    "canceled": "Cancelled",
    "cancellations": "Cancel",
    "centre": "Middle",
    "change1": "Modify",
    "change_gesture_password": "Change Password",
    "change_google": "Change Google Authenticator",
    "change_pay_style": "Replacement Method",
    "change_phone": "Change Phone",
    "charge_after_the_transaction": "Charge after the transaction",
    "charge_out": "The fee must be",
    "check_the_agree": "Agree to check",
    "china": "China",
    "choose_picture": "Select Image",
    "clearing_time": "Settlement Time",
    "click_enter_batch_edit_page": "Click to enter the batch edit page",
    "click_on_the_copy": "Click to copy",
    "clinch_a_deal": "Other traded several times (0 : unlimited)",
    "close": "Close",
    "closed_successfully": "Closed Successfully",
    "cloud_mining_pool": "Cloud Mining Pool",
    "coin_failure_0001": "Currency does not exist",
    "collect": "Collection",
    "collection_time": "Collection Time",
    "commission": "Commissioned",
    "common_failure": "Failure!",
    "common_failure_0001": "Network Anomaly",
    "common_problem": "Operating Instructions",
    "common_success": "Success",
    "complete": "Complete",
    "completed": "Completed",
    "computer_login": "Computer Login",
    "confirm": "Confirm",
    "confirm_exit": "Confirm Exit?",
    "confirm_password": "Confirm the login password",
    "confirm_submission": "Confirm Submission",
    "confirm_the_purchase": "Confirm Purchase",
    "confirm_to_give_up": "Confirm Waiver",
    "confirm_to_sell": "Confirm Sale",
    "confirm_upgrade": "Confirm Upgrade",
    "confirm_vote": "Confirm Vote",
    "confirmation_information": "Confirmation Information",
    "confrim_delete_warn": "Are you sure you want to delete the alert?",
    "contact_service": "Customer Service",
    "contact_us": "Contact Us",
    "contract_Order": "Order",
    "contrct Agree Content": "In order to protect your R/I,you need to read it carefully before opening swaps《BitMatrix Perpetual Swap Agreements》",
    "contrct Agree Content footer": "《BitMatrix Perpetual Swap Agreements》",
    "contrct Agree Content header": "In order to protect your R/I,you need to read it carefully before opening swaps",
    "contrct_decimal": "Decimal",
    "convert_into": "Equivalent",
    "converted_currency": "Converted Currency",
    "copartner-upgrade-failure": "Upgrade Unsuccessful",
    "copartner-upgrade-no": "Did not meet the upgrade conditions, can not be upgraded",
    "copartner-upgrade-ok": "Can be upgraded",
    "copartner-upgrade-success": "Update Successed",
    "copy_content": "Already copied to the pasteboard",
    "country": "Country",
    "create_time": "Creation Time",
    "cumulative_income:": "Accumulated Income：",
    "cumulative_participation": "Cumulative Participation",
    "currency": "Currency",
    "currency_fee": "Currency Rates",
    "currency_information": "Currency Data",
    "currency_name": "Token Name",
    "currency_not_found": "Currency type does not exist",
    "currency_select": "Currency Choice",
    "current_day_return": "Demand Return",
    "current_entrust": "Commission",
    "current_identity": "Current Status",
    "current_polling_date": "Current voting time",
    "current_status": "Current Status",
    "currently_available": "Currently Available",
    "date": "Date",
    "date_of_birth": "Date of birth",
    "day_highs_and_lows": "Gainers",
    "day_highs_and_lows1": "Change ",
    "day_income": "Day",
    "day_trading": "Day Trading",
    "daytime": "Daytime",
    "ddress_management": "Address Management",
    "deal_amount": "Total",
    "deal_done": "Deal Done",
    "deal_gross": "Transaction/total",
    "deal_of_the_week": "Deal of the week",
    "deal_price": "Average",
    "deal_sum": "Sum",
    "deal_the_detail": "Order Details",
    "deal_volume": "Turnover",
    "deduct_coin": "Deduction Currency",
    "default_settlement": "Default Settlement",
    "defaults": "Default",
    "deposit": "One key deposit'",
    "deposit_details": "Deposit Details",
    "deposit_income": "Deposit Income",
    "deposit_lock": "Deposit Lock",
    "deposit_lock_details": "Deposit lock details",
    "deposit_returned_principal": "Returned Principal",
    "depth": "Depth",
    "detailed_rules": "Detail Rules",
    "details": "Details",
    "digits6": "Enter 6-digit password",
    "direct_market": "Direct Promotion",
    "direct_reward": "Direct Reward",
    "direction": "Direction",
    "discount_Rate": "Discount Rate",
    "discount_currency_introduction": "Currency Introduction",
    "discount_dialog_tip1": "Congratulations! You meet",
    "discount_dialog_tip2": "Participation conditions, and randomly selected as a lucky user by the system, after receiving the qualifications will have participation rights!",
    "discount_dialog_tip3": "You already have",
    "discount_dialog_tip4": "Qualification for the redemption, the activity amount is limited, please participate in the redemption within the event time!",
    "discount_dialog_tip5": "Cannot participate after giving up",
    "discount_dialog_tip6": "Activities and cannot apply to participate.",
    "discount_dialog_tip7": "Note: Successful deduction will be deducted",
    "discount_purchase": "Discount Redemption",
    "discounted_or_original_price": "Discounted Price / Original Price",
    "dispose_result": "Process Result",
    "disqualification": "Disqualification",
    "divided_earnings": "Distribution Income",
    "drag": "Slide to the right",
    "each_input": "Verify transaction password for each transaction",
    "edt_selfchoose": "Editor's Choice",
    "effective": "Effective",
    "eligibility": "Eligibility",
    "email": "Email",
    "email_address_format": "Enter your vaild email",
    "email_code": "Email code",
    "empty_deal_pass": "Password cannot be empty",
    "end time": "End Time",
    "end_date": "End Date",
    "end_time": "End Time",
    "enter_account": "Please enter phone/email",
    "enter_cause_of_complaint": "Please enter the reason for the complaint",
    "enter_fb_buy_price": "Please enter the purchase price",
    "enter_fb_sell_price": "Please enter the selling price",
    "enter_your_password": "Enter your password",
    "enter_password": "Please enter password",
    "entrust_amount": "Amount",
    "entrust_failure_0001": "Network Anomaly",
    "entrust_failure_0002": "Query type error",
    "entrust_failure_0003": "Match type cannot be empty",
    "entrust_failure_0004": "Merchant ID cannot be empty",
    "entrust_failure_0005": "User ID cannot be empty",
    "entrust_failure_0006": "User does not exist",
    "entrust_failure_0007": "Transaction ID cannot be empty",
    "entrust_failure_0009": "Transaction pair does not exist",
    "entrust_failure_0010": "Transaction password cannot be empty",
    "entrust_failure_0011": "Transaction password is wrong",
    "entrust_failure_0012": "The order type cannot be empty",
    "entrust_failure_0013": "The number of orders cannot be empty",
    "entrust_failure_0014": "The price of the limit order can not be empty",
    "entrust_failure_0015": "The order ID cannot be empty",
    "entrust_failure_0016": "Order does not exist",
    "entrust_failure_0017": "The order has been deleted",
    "entrust_failure_0018": "The order cannot be withdrawn",
    "entrust_failure_0019": "Version number cannot be empty",
    "entrust_failure_0020": "Market order cannot be withdrawn",
    "entrust_failure_0021": "Current data has been updated",
    "entrust_failure_0022": "There is currently no order and cannot be placed on the market order.",
    "entrust_failure_0023": "Transaction is disabled or related currency is disabled",
    "entrust_failure_0024": "The transaction is open to the transaction",
    "entrust_failure_0025": "The commission price is lower than the minimum price or higher than the maximum price",
    "entrust_failure_0026": "The number of orders is below the minimum limit or above the maximum limit",
    "entrust_failure_0027": "This account has been banned from trading, please consult the customer service",
    "entrust_failure_0030": "Exceeding the current trading pair highest rise{} in one day, can not  place orders",
    "entrust_failure_0031": "Exceeding the current trading pair lowest drop{} in one day, can not  place orders",
    "entrust_msg_match_type_001": "Limit Order",
    "entrust_msg_match_type_002": "Market Order",
    "entrust_msg_status_001": "Unfilled",
    "entrust_msg_status_002": "Partial Deal",
    "entrust_msg_status_003": "Complete Transaction",
    "entrust_msg_status_004": "Cancel",
    "entrust_msg_type_001": "Sell Orders",
    "entrust_msg_type_002": "Pay the bill",
    "entrusted_price": "Price",
    "entry_orders": "Open Order",
    "equal_principal": "Equivalent Principal",
    "error_email": "Email Error",
    "error_network_anomaly": "Network Anomaly",
    "error_phone": "Enter a valid phone",
    "error_unknow": "Network Error",
    "event_detail": "Activity Details",
    "event_detail_warn_tip": "* Reminder: Digital assets are innovative assets with the characteristics of large price fluctuations. Please make rational judgments and choose investment decisions carefully!",
    "event_start_time": "Event start time",
    "every day": "Every Day",
    "everyday": "Every Day",
    "exchange": "Convert",
    "exchange_currency": "Exchange Currency",
    "exchange_details": "Conversion details",
    "exchange_history": "Records",
    "exchange_quantity": "Exchange Quantity",
    "exchange_rules": "Exchange Rules",
    "exchangeable": "Avbl",
    "exclusive_posters": "Generate exclusive posters",
    "exclusive_posters-Video": "Generate Posters",
    "exit": "Log out",
    "expected_return": "Estimated Revenue",
    "expired": "Expired",
    "express_area": "Express Area",
    "face_recognition": "Face ID",
    "failure": "Failure",
    "fb_lookup": "Click to view",
    "fb_order_number": "Order Number",
    "feeze_thaw_msg_status_0001": "Frozen",
    "feeze_thaw_msg_status_0002": "Thawed",
    "fill_in_at_least_one": "Please fill in at least one item",
    "fill_wrong": "Filling in errors may result in loss of assets, please check carefully",
    "filtrate": "Filter",
    "filtrate_content": "Filter payment methods, country, Fiat click here!",
    "financial_management_is_cancel": "Deposit proceeds have been cancelled! Do not submit again!",
    "financial_management_is_delete": "This type of financing has been deleted",
    "financial_management_is_disable": "This type of financing has been banned.",
    "financial_management_not_cancel": "Regular FX investment can not be cancelled!",
    "financial_management_number_not_found": "Investment amount should not be empty",
    "financial_management_record_add_failure": "User's assets are insufficient, after deposit to do the financial investment!",
    "financial_management_record_less_than_min_number": "Added coins is less than the minimum number set {}",
    "financial_management_type_not_found": "Financial record shows failure, financial type selection error!",
    "financial_management_type_not_null": "Investment type should not be empty",
    "find_password": "Retrieve Password",
    "fingerprint": "Fingerprint",
    "fingerprint_verification": "Fingerprint Authentication",
    "first_bind_phone": "Please bind the phone first",
    "first_period": "Initial Period",
    "following_account": "To the following account",
    "free": "Fee",
    "Takerfree": "TakerFee",
    "Makerfree": "MakerFee",
    "freeze": "Freeze",
    "freeze_to_be_processed": "Frozen",
    "fuc_use_discount": "Use {} to pay transaction fees *",
    "futu": "Sub",
    "gathering": "Receipt",
    "gears": "Type",
    "generation1": "1 Generation",
    "gesture_error_limit": "Gesture password input error times already reached limit",
    "gesture_password": "Gesture Password",
    "gesture_unlock": "Gesture Unlock",
    "get_code": "Get Code",
    "goSign": "Register",
    "go_change": "Modify",
    "go_login": "Please login first",
    "go_pay": "Pay",
    "go_setting": "Setting",
    "go_to_pick_up": "Go to pick up",
    "go_to_receiving": "To collect money",
    "go_to_redeem": "Go to redeem",
    "google_verification_code": "Google Code",
    "grievance_freeze": "Grievance Freeze",
    "has_been_on": "The Published",
    "have_been_frozen": "Frozen",
    "have_received": "Received",
    "help_center": "Help Center",
    "hide_canceled": "Hidden Revocation",
    "hide_other": "Hide other pending orders",
    "high_frequency_partner": "High frequency partner",
    "highest_single": "Highest",
    "highfrequency_fee_sharing": "High frequency sharing service charge",
    "hint": "Tips",
    "hint_password": "Enter  Password",
    "hint_phone_email": "Phone/Email",
    "historical_transaction": "Historical Transaction",
    "history": "History",
    "home_page": "Home",
    "horizontal_version": "Horizontal",
    "hours": "h",
    "i18n_failure_0001": "System language query failed",
    "i18n_failure_0002": "Country dictionary query failed",
    "iTo_draw_again": "Enter your password again",
    "i_see": "I know",
    "i_see1": "I know",
    "id_card_back_direction_failure": "The wrong direction of the image on the back of the ID card",
    "id_card_front_direction_failure": "The wrong direction of the image on the front of the ID card",
    "id_cord": "ID Card",
    "id_type": "Types",
    "identification": "Identification Failure",
    "identity_authentication": "Authentication",
    "identity_card": "ID Card",
    "if_at": "If at",
    "ignore": "Ignore",
    "immediately_invited": "Invite Now",
    "immediately_join": "Join Now",
    "in_trading": "Open Order",
    "income": "Profit",
    "income_currency_type": "Deposit Type",
    "income_deposit_principal": "Deposit Principal",
    "incomeing": "In Revenue",
    "increment quantity": "The input quantity cannot exceed the maximum increment quantity",
    "indirect_promotion": "Indirect Promotion",
    "indirect_reward": "Indirect Reward",
    "individual_selling_tips": "Can't sell orders to yourself",
    "input_Branch_address": "Enter the branch address",
    "input_address_labels": "Please enter the label",
    "input_alipay_account": "Enter an Alipay account",
    "input_bank_card_number": "Enter the bank card number",
    "input_bank_name": "Enter the bank name",
    "input_buyer_uid": "Please enter the buyer UID",
    "input_currency_referred": "Please enter the short name of the currency",
    "input_deposit_amount": "Please enter the amount of money deposited",
    "input_email": "Enter the email number",
    "input_email_code": "Enter the email code",
    "input_id_cord": "Enter identification number",
    "input_lock_amount": "Please enter the number of locks",
    "input_mention_money_address": "Enter the coin address",
    "input_money": "Enter the amount",
    "input_name": "Your name",
    "input_newphone_code": "Please enter a new phone number",
    "input_nike_name": "Enter a nickname",
    "input_old_google_code": "Old Google verification code",
    "input_old_pass": "Old password",
    "input_paypal_acctount": "Enter a paypal account",
    "input_phone": "Enter the phone",
    "input_phone_code": "Verification code",
    "input_price": "Price",
    "input_quantity": "Please enter Amount",
    "input_remark": "Enter a note",
    "input_service_charge": "Enter the fee",
    "input_trading_volume": "Enter transaction price",
    "input_transaction_password": "Enter the fund password",
    "input_wechat_account": "WeChat account",
    "input_wire_transfer": "Enter address",
    "inputs_numbers_greater_can_number": "The number of inputs is greater than the number of transfers",
    "insufficient_light": "Dim",
    "interest": "Interest",
    "invitation_details": "Invitation Details",
    "invitation_register_ad_not_found": "App sharing invitation registration ad does not exist temporarily",
    "invitation_register_ad_tatle_not_found": "App sharing invitation registration ad title does not exist for the time being",
    "invitation_register_coin_not_found": "App sharing invitation registration reward currency temporarily does not exist",
    "invitation_register_logo_not_found": "App sharing invitation registration logo does not exist temporarily",
    "invitation_register_not_found": "The share invitation registration connection does not exist yet",
    "invite_code": "Invitation Code (optional)",
    "invite_code1": "Invitation Code",
    "invite_commission": "Commision",
    "invite_gift": "Invited Gift",
    "invite_reward": "Invitation Reward",
    "invited_UID": "Invited UID",
    "invitees_are_available": "Inviter are available",
    "inviter-is-user": "The backfilled invitee cannot be my own",
    "inviter-not-exist": "Backfilled invitees do not exist",
    "inviter_no_null": "Referrer cannot be empty",
    "ios_24小时排行榜": "24-hour leaderboard",
    "ios_OTC交易须知": "OTC Trading Notes",
    "ios_PAYPAL": "PAYPAL",
    "ios_otc_order_prompt_001": "If you have not paid you within the minute and click the 'Confirm Payment' button, the system will automatically cancel the order after the countdown ends.",
    "ios_otc_order_prompt_002": "If the payment is not made to the seller within a few minutes and the 'confirm payment' button is clicked, the system will automatically cancel the order after the countdown ends.",
    "ios_万": "Ten Thousand",
    "ios_上传身份证反面": "Upload ID card reverse side",
    "ios_上传身份证正面": "Upload ID card front",
    "ios_两次密码不一致请再次输入密码": "If the password is inconsistent twice, Enter the password again.",
    "ios_个确认大约需要": "Confirmation is about needed",
    "ios_中国大陆": "China Mainland",
    "ios_中级合伙人": "Mid-level Partner",
    "ios_主版块": "Main section",
    "ios_之间": "between",
    "ios_买单": "Buy",
    "ios_买方必须成交过几次(0等于不限制)": "Buyer must traded times(0 equals to no limit)",
    "ios_二维码/条形码": "QR code / Barcode",
    "ios_付款": "Payment",
    "ios_充币地址": "Deposit Address",
    "ios_先设置交易密码": "Set the password first",
    "ios_关闭照明": "Turn off lighting",
    "ios_分享失败": "Sharing Failure",
    "ios_分享成功": "Sharing Success",
    "ios_初级合伙人": "Junior Partner",
    "ios_卖单": "Sell",
    "ios_卖家已付款提示": "Seller has paid tips",
    "ios_卖方必须成交过几次(0等于不限制)": "Seller must traded times(0 equals to no limit)",
    "ios_去解绑": "Unbundling",
    "ios_发布成功": "Successfully Released",
    "ios_发送成功": "Sent Successfully",
    "ios_各种费率说明": "Various rate descriptions",
    "ios_团队人数不足": "Insufficient team size",
    "ios_复制QQ号": "Copy QQ number",
    "ios_复制地址": "Copy Address",
    "ios_如果你已完成线下付款，请点击下方已付款按钮。如果未完成付款，却点击已付款将会受到严厉惩罚，甚至永久冻结账户！": "If you have completed your offline payment, please click the paid button below. If you don't complete the payment, you will be severely punished if you click on the payment, or even permanently freeze the account!",
    "ios_客户端或在线钱包将您需要充值的": "The client or online wallet will need to be recharged",
    "ios_客户端或在线钱包将您需要充幣的": "The client or online wallet will charge you",
    "ios_客服": "Customer Service",
    "ios_密码设置成功！": "The password is set successfully!",
    "ios_将该地址设置为认证地址": "Set this address as the authentication address",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充值，不影响到账。\n3、最小充值金额": "Hour hours, please be patient.\n2, the same address can be recharged multiple times, does not affect the account.\n3. Minimum recharge amount",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充幣，不影响到账。\n3、最小充幣金额": "Hour hours, please be patient.\n2, the same address can be recharged multiple times, does not affect the account.\n3. Minimum recharge amount",
    "ios_已付款": "Paid",
    "ios_币币": "Trades",
    "ios_常见问题": "Common Problem",
    "ios_开启照明": "Turn on lighting",
    "ios_意见反馈": "Feedback",
    "ios_成交": "Market Trades",
    "ios_我的": "Account",
    "ios_手机号错误": "Mobile number error",
    "ios_提交成功，等待认证": "Waiting for certification",
    "ios_提币数量不能大于手续费": "The amount of coins can not be greater than the handling fee",
    "ios_提币数量减去手续费后不能": "The amount of coins can not be reduced after the handling fee",
    "ios_收款二维码": "Payment Code",
    "ios_收藏成功": "Successful Collection",
    "ios_数目发送到该地址。发送完成后，系统会自动在此交易获得": "The number is sent to this address. After the sending is completed, the system will automatically get the transaction.",
    "ios_暂无网络": "No Network",
    "ios_最多可锁": "Maximum Lockable",
    "ios_最大限额": "Maximum Limit",
    "ios_最小限额": "Minimum Limit",
    "ios_未解锁": "Not Unlocked",
    "ios_没有中国大陆身份证的用户请通过网站版高级认证": "Users who do not have a Chinese ID card should pass the website version of the advanced certification.",
    "ios_法币": "OTC",
    "ios_注册表示同意": "Registry Consent",
    "ios_注册表示同意用户协议": "Registration agrees to the User Agreement",
    "ios_登录过期，请重新登录": "Login expired, please login again",
    "ios_直接推荐人uid": "Direct recommendation uid",
    "ios_直推人数不足": "Insufficient number of direct recommendation",
    "ios_绑定成功": "Binding Success",
    "ios_请上传您的身份证": "Please upload your ID card",
    "ios_请上传身份证反面照": "Please upload your ID card.",
    "ios_请上传身份证正面照": "Please upload a positive photo of your ID card",
    "ios_请先绑定收款方式": "Please bind the payment method first.",
    "ios_请先设置支付方式": "Please set the payment method first.",
    "ios_请输入价格和数量": "Please enter price and quantity",
    "ios_请输入新邮箱": "Please input the email address",
    "ios_请输入正确的账号格式": "Enter the correct account format",
    "ios_请输入身份证号": "Enter identification number",
    "ios_请输入限额": "Enter a limit",
    "ios_请通过": "Please Pass",
    "ios_资产": "Funds",
    "ios_超时": "Timeout",
    "ios_身份证号": "Identity Number",
    "ios_退出成功": "Exit Successfully",
    "ios_邀请人可获得 * 的佣金": "The inviter can get a commission of *",
    "ios_锁仓数量不能为空": "The number of locked positions cannot be empty",
    "ios_间接接推荐人uid": "Indirect referral uid",
    "ios_隐藏其他挂单": "Hide other pending orders",
    "ios_高级合伙人": "Senior Partner",
    "is_bind": "Bound",
    "is_delete_select": "Do you want to delete optional",
    "issued": "Issued",
    "issued_to_lock": "Issued to lock",
    "k_close": "Receive",
    "k_max": "High",
    "k_min": "Low",
    "k_num": "Amount",
    "k_open": "Open",
    "kline_target": "Target",
    "language": "Language",
    "lead_content": "Order inquiry, post advertising, payment settings, follow/block are all moved here!",
    "least_four": "Please connect at least 4 points",
    "less-than-min": "Below the minimum lock limit",
    "lf there are pending": "lf there are pending pending close orders(Limit or Stop)they will be canceled before closing all.",
    "limit": "Limiting Setting",
    "limit_orderAA": "Limit Order",
    "limit_set": "Limit Setting (optional)",
    "limited_price": "Limit Price",
    "liquidation": "Liquidation",
    "liquidation AMT": "Liquidation amt.",
    "liquidation details": "Liquidation Details",
    "loading": "Loading",
    "lock-expire": "Locked position has expired",
    "lock_amount": "Number of locks",
    "lock_duration": "Locking Time",
    "lock_person": "Locker",
    "lock_record": "Lock Record",
    "lock_remaining": "lock Margin",
    "lock_reward": "Lockout Reward",
    "lock_time": "Lock Time",
    "locked": "Locked",
    "locked_closed": "Closed",
    "locked_insufficient_balance": "Insufficient Funds",
    "locked_settled": "Settled",
    "locked_unsettlement": "Unsettlement",
    "locked_warehouse": "Locked",
    "log_in": "Login",
    "login": "Login",
    "login_expired": "Login is invalid, please login again.",
    "login_forget_pwd": "Forget Password?",
    "login_name_txt": "Username",
    "login_pass": "Login Password",
    "logion_welcome_txt": "Login Please",
    "mail": "Email",
    "mailbox": "Email Verification",
    "mailbox_bind_success": "Email binding successfully",
    "mailbox_new": "Email",
    "main_picture": "Main",
    "manage_reward_details": "Manage reward details",
    "management_reward": "Management Reward",
    "management_share": "Management Share",
    "mandarin": "Read the numbers in Mandarin",
    "mandarin_reading_content": "Please read the following in standard language",
    "manual_recharge_msg_status_0001": "Waiting for recharge",
    "manual_recharge_msg_status_0002": "Examination Passed",
    "manual_recharge_msg_status_0003": "Recharge Successful",
    "manual_recharge_msg_type_0001": "Ordinary Recharge",
    "manual_recharge_msg_type_0002": "Fund Processing",
    "manual_recharge_msg_type_0003": "Event Gift",
    "margin": "Margin",
    "market_failure_00001": "Get the home page failed",
    "market_failure_00002": "Merchant ID does not exist",
    "market_failure_00003": "There are no trading areas available under the merchant",
    "market_failure_00004": "Failed to get currency trading",
    "market_failure_00005": "Transaction pair name cannot be empty",
    "market_failure_00006": "No section information available under the merchant",
    "market_failure_00007": "Failed to get transaction information",
    "market_failure_00008": "Failed to get K line data",
    "market_failure_00009": "Failed to get depth data",
    "market_failure_00010": "Request parameter error",
    "market_failure_00011": "Invalid transaction pair",
    "market_low_price": "Market highest purchase price",
    "market_order": "Market Order",
    "market_price": "Market Price",
    "market_sell_price": "Market minimum selling price",
    "market_switch_moved_here": "The horizontal / vertical market switch has been moved here",
    "match": "Match",
    "match_the_number_of": "Matches",
    "max_lock_volume": "Maximum lock volume",
    "maximum_30_characters": "Maximum 30 characters",
    "maximum_coins": "The maximum amount of coins is",
    "maximum_withdrawal": "Maximum Withdrawal",
    "mention_a_currency": "Amount",
    "min_lock_volume": "Minimum lock volume",
    "min_recharge_amount": "Small amount not posted, the recharge completed but not accumulated,deposit reaches the minimum amount {} {} will be automatically posted",
    "minhour": "Session",
    "minimum_coins": "The minimum amount of coins is",
    "minimum_purchase_quantity": "Purchased quantity is greater than the remaining quantity of this order {}",
    "minimum_quantity_sold": "Sold quantity is greater than the remaining quantity of this order {}",
    "minutes": "m",
    "mobile phone": "Phone Number",
    "money": "Amount",
    "more": "More",
    "more Or than": "Long position: Face value x Number of contracts x Average Closing Price-Face value x Number of contracts x Average oning price\n\nShort Position: Face value x Number of contractsxAverage Opening Price-Face value x umber of contracts x Average Closing Price",
    "more-than-max": "Exceed the maximum lock limit",
    "more11": "More ",
    "more_can_save": "Maximum accessibility",
    "more_home": "More",
    "msg_camera_framework_bug": "Please turn on camera permissions",
    "must_retain_one": "Mobile phones, Google, and mailboxes at least retain one type of",
    "my_income": "My Income",
    "my_prompt_001": "* After the mobile phone number is successfully modified, you need to login with the new mobile phone number.",
    "my_prompt_002": "* Tips: Please be sure to use your real-name account. The activated payment method will be displayed to the buyer during the OTC transaction. You can set up 5 types but activate up to three types.",
    "my_prompt_003": "* After the mailbox is successfully modified, you need to login with a new mailbox.",
    "my_vote": "My Voting",
    "name": "Name",
    "name_empty": "Name cannot be empty",
    "nationality": "Citizenship",
    "need_me_record_video": "Need me to record video",
    "never_enter": "Never enter transaction password",
    "new_address": "Add Address",
    "new_pass": "New Password",
    "new_pass_empty": "New Password",
    "new_pass_minimum": "Password cannot be less than 6 digits",
    "new_report_statistics": "New report statistics",
    "newest_versions": "The current version is the latest version！",
    "next": "Next Step",
    "nick_name": "Merchant",
    "nickname_des_txt": "Nickname only supports Chinese and English, numbers, and the length is limited to 15 characters",
    "nighttime": "Nighttime",
    "ninety_days": "90 Days",
    "no-lock-detail": "No lock information",
    "no_Senior_certification": "Senior certification first!",
    "no_data": "No Data",
    "no_flash": "The current device does not have a flash and cannot turn on the illumination.",
    "no_more_data": "No More",
    "no_photo_taking": "Your device does not support taking photos",
    "no_price": "Price cannot be empty",
    "no_quantity": "Enter the correct quantity",
    "no_realname_auth": "Please first perform real name certification",
    "no_use": "Not Activated",
    "none": "Nothing",
    "not_bound": "Unbound",
    "not_frequency_partner": "You're not a partner yet",
    "not_redemption": "Not eligible for redemption",
    "not_started": "Has not started",
    "not_to_issue": "Not Issued",
    "not_trading": "The currency is not currently tradable",
    "notpass": "The audit failed",
    "null_address_tag": "Address label cannot be empty",
    "null_code": "Verification code must be filled",
    "null_email": "E-mail can not be empty",
    "null_password": "Password can not be blank",
    "null_phone": "Phone cannot be empty",
    "number": "Piece",
    "number_empty": "Amount cannot be 0",
    "number_of_arrivals": "Number of arrivals",
    "number_of_issues": "Total Payment",
    "number_of_withdrawal": "Daily Withdrawals",
    "obtained": "Obtained",
    "occlusion": "Occlusion",
    "occupy": "Occupy",
    "official_community": "Official Community",
    "official_verification_channel": "Authentication Channel",
    "old_pass": "Enter Password",
    "old_pass_empty": "Enter the original password",
    "on_sale": "Sell",
    "online_service": "Online Service",
    "open XKD perpetual swap": "Open BitMatrix perpetual swap",
    "open perpetual swap": "Open Contract Trade",
    "open_app": "Open App",
    "open_it": "Start",
    "open_successfully": "Open Successfully",
    "open_touchID": "Is the TouchID turned on?",
    "opposite_side_uid": "Other Side",
    "option": "Options",
    "optional": "Optional",
    "optional_area": "Favorite Area",
    "options": "Options",
    "order": "Bill",
    "order time": "Order Time",
    "order time1": "Order time",
    "order_a_complaint": "Order Appeal",
    "order_details": "Order Details",
    "order_form": "Order",
    "order_history": "Historical Order",
    "order_inquiry": "Order Tracking",
    "order_number": "Number",
    "order_time": "Order Time",
    "ordinary_partner": "General Partner",
    "otc_account": "Account",
    "otc_confirm_Put_the_coin": "Confirm that you have received the other party's remittance! (Warning: Do not click to confirm before confirming the payment. If there is a dispute, please contact customer service complaint)",
    "otc_confirm_the_payment": "Confirm Payment",
    "otc_confirm_the_receipt": "Confirm",
    "otc_failure_0001": "The account is disabled, please contact customer service as soon as possible",
    "otc_failure_0002": "The account has not passed the advanced authentication and cannot perform the OTC operation.",
    "otc_failure_0003": "Account transaction password is incorrect",
    "otc_failure_0004": "Order ID is illegal",
    "otc_failure_0006": "Change state failed",
    "otc_failure_0009": "The pending order type does not exist",
    "otc_failure_0010": "Undo Failed",
    "otc_failure_0011": "The pending order is currently completed or has been revoked",
    "otc_failure_0012": "The current user is not an OTC merchant and does not have access to this feature.",
    "otc_failure_0013": "Thaw failed, undo failed",
    "otc_failure_0015": "No such pending order type",
    "otc_failure_0016": "The number of pending orders cannot be less than or equal to 0",
    "otc_failure_0017": "The transaction price cannot be less than or equal to 0",
    "otc_failure_0018": "Single minimum limit cannot be less than 0",
    "otc_failure_0019": "The maximum single limit cannot be less than the single minimum limit",
    "otc_failure_0020": "Does not support the currency",
    "otc_failure_0021": "The currency is currently unavailable",
    "otc_failure_0022": "Incorrect business currency ID",
    "otc_failure_0023": "The single minimum limit cannot be less than the minimum currency of the currency{}",
    "otc_failure_0024": "The single maximum limit cannot be greater than the maximum size of the currency{}",
    "otc_failure_0025": "The number of decimal places in the pending order is greater than {}",
    "otc_failure_0026": "The total amount of the pending order cannot be less than the ordering limit{}",
    "otc_failure_0027": "The total amount of pending orders cannot be greater than the user limit{}",
    "otc_failure_0028": "The total amount of the pending order cannot be greater than the merchant limit{}",
    "otc_failure_0029": "The transaction price cannot be less than the lowest price{}",
    "otc_failure_0030": "Transaction price cannot be greater than the maximum price{}",
    "otc_failure_0031": "Current user is not bound to a payment account",
    "otc_failure_0032": "Freeze failed, pending order failed",
    "otc_failure_0033": "The maximum number of orders processed at the same time cannot be less than 0.",
    "otc_failure_0034": "Buyers must not be less than 0",
    "otc_failure_0035": "The user has been certified or is reviewing",
    "otc_failure_0036": "Insufficient number of successful OTC transactions",
    "otc_failure_0037": "Thaw failed, undo failed",
    "otc_failure_0038": "Applicant's current account {} available balance is insufficient{}",
    "otc_failure_0039": "No current currency",
    "otc_failure_0040": "Current currency is not available",
    "otc_failure_0041": "Submitted successfully, please wait for review",
    "otc_failure_0042": "Sorry, the user is currently pending review and cannot apply again.",
    "otc_failure_0043": "No such transaction type",
    "otc_failure_0044": "Prohibit self-selling",
    "otc_failure_0046": "This pending order processed the maximum number of orders{} has reached the limit, please try again later",
    "otc_failure_0047": "Buyer must have sold {} times",
    "otc_failure_0048": "The number of purchases cannot be less than or equal to 0",
    "otc_failure_0049": "The number of purchases cannot be greater than the current remaining quantity of the pending order",
    "otc_failure_0050": "The pending order ID is incorrect.",
    "otc_failure_0051": "The total amount of purchase cannot be less than the minimum currency transaction {}",
    "otc_failure_0052": "The purchase amount cannot be greater than the maximum currency transaction limit{}",
    "otc_failure_0053": "Current pending order type is not sold",
    "otc_failure_0054": "The current pending order status is incorrect.",
    "otc_failure_0055": "The purchase amount cannot be less than the minimum order limit {}",
    "otc_failure_0056": "The purchase amount cannot be greater than the maximum order limit {}",
    "otc_failure_0057": "No such status",
    "otc_failure_0058": "Change the number of pending order records is incorrect",
    "otc_failure_0059": "Freeze failed, the order failed",
    "otc_failure_0060": "Please bind the merchant specified type of receiving account in the payment setting!",
    "otc_failure_0061": "Seller must have been dealt with {} times",
    "otc_failure_0062": "The quantity sold cannot be less than or equal to 0",
    "otc_failure_0063": "The sold quantity cannot be greater than the current remaining quantity of the pending order",
    "otc_failure_0064": "Current pending order type is not buy",
    "otc_failure_0065": "The total amount sold cannot be less than the minimum currency transaction {}",
    "otc_failure_0066": "The sale amount cannot be greater than the maximum currency transaction limit{}",
    "otc_failure_0067": "The selling amount cannot be less than the minimum order limit {}",
    "otc_failure_0068": "The selling amount cannot be greater than the maximum order limit {}",
    "otc_failure_0069": "Payment account ID is incorrect",
    "otc_failure_0070": "Confirm payment failed",
    "otc_failure_0073": "Order change status failed",
    "otc_failure_0074": "The currency failed, confirming that the payment failed",
    "otc_failure_0075": "Payment method cannot be empty",
    "otc_failure_0076": "Message failed to send{}",
    "otc_failure_0077": "The transaction currency does not exist",
    "otc_failure_0078": "The currency does not exist",
    "otc_failure_0079": "Order status change failed",
    "otc_failure_0080": "The transaction currency is disabled",
    "otc_failure_0081": "The currency is disabled",
    "otc_failure_0082": "The order does not exist",
    "otc_failure_0083": "OTC currently does not set trading currency",
    "otc_failure_0084": "OTC currently does not set the transaction currency",
    "otc_failure_0085": "Insufficient number of pending orders",
    "otc_failure_0090": "This account has been banned from trading OTC, please contact customer service",
    "otc_failure_0091": "Ordinary users cannot make OTC pending orders, please apply for merchants first",
    "otc_failure_0092": "The current order receipt has not timed out and the buyer cannot appeal",
    "otc_failure_0093": "The order has been appealed, please contact customer service",
    "otc_failure_0094": "This action is not possible depending on the rules of your registration.",
    "otc_failure_0095": "This order is not available for trading",
    "otc_failure_0096": "The seller's account asset is abnormal, and the operation cannot be performed temporarily.",
    "otc_failure_0097": "Today's OTC cancellations have exceeded the limit, trading is prohibited",
    "otc_failure_0098": "You currently have an uncompleted order, please continue to place the order after completing the order.",
    "otc_margin_certification": "Deposit Certification",
    "otc_mine_account_paid": "I have paid",
    "otc_order_prompt_003": "If the buyer does not pay you within the specified time and clicks the 'Confirm Payment' button, the system will automatically cancel the order after the countdown ends.",
    "otc_picture_no_null": "Please upload at least one image",
    "otc_prompt_001": "1, Order payment is valid for 30 minutes, please pay in time and click the (I have paid) button.\n2, Seller's currency is locked by the system, please rest assured to place an order.",
    "otc_rate": "Rate",
    "otc_report_order": "Order",
    "otc_success_0001": "Successful Cancellation",
    "otc_success_0002": "Successful Sale",
    "otc_success_0003": "Successful Buy",
    "otc_success_0004": "Confirm payment success",
    "otc_success_0005": "Confirm receipt is successful, order is over",
    "otc_success_0006": "Successful Appeal",
    "otc_waiting_for_confirmation": "Wait Confirmation",
    "other_settings": "Other Setting",
    "overtime_pay": "Timeout",
    "param-error": "Parameter Error",
    "partner": "Partner",
    "partner_commission": "Partners can get commission from their subordinates",
    "partner_commission_fee": "Commission Fee",
    "partner_current_level": "Current Level",
    "partner_get_all_subordinate": "Can put all subordinates",
    "partner_management": "Partner Management",
    "partner_need_pay": "Need to pay",
    "partner_need_recommend": "Need direct recommendation",
    "partner_recommended": "Directly Recommended",
    "partner_start_and_end_time": "Start and end time",
    "partner_team": "Team",
    "partner_team_member": "The number of team members is required to reach",
    "partner_upgrade_immediately": "Upgrade Now",
    "pass_muster": "Not meet the requirements, click here",
    "passed": "Passed",
    "passport": "Passport",
    "password_disagree": "Two passwords are inconsistent",
    "password_is_different": "Inconsistent password input",
    "password_text": "Password",
    "password_wrong": "Set 8-20 digit login password",
    "past_activities": "Past Events",
    "pause_recharge": "Can not recharge",
    "pause_withdraw": "Can not withdraw",
    "pay_number": "Payment Amount",
    "pay_wey": "Payment Method",
    "payee": "Payee",
    "payfor_type": "Method",
    "payment": "Payment",
    "payment_amount": "Amount",
    "payment_currency": "Payment Currency",
    "payment_has_been": "(I have paid)",
    "payment_term": "Payment Method",
    "paypal_account": "Account",
    "pending_review": "Pending Review",
    "people": "People",
    "period": "Stage",
    "personal_detail": "Personal Data",
    "personal_purchase_tips": "Can't buy your own order",
    "phone": "Mobile Phone",
    "phone_code": "Phone Code",
    "phone_register": "Phone",
    "phone_verification": "SMS Verification",
    "phone_verify": "Phone Verification",
    "photo_album": "Album",
    "please_enter": "Please Enter",
    "please_enter_abbreviation_or_letter": "Enter search content",
    "please_input_content": "Please input content",
    "please_pay": "Please Pay",
    "please_verification": "Please Verify",
    "pop_Date": "Date",
    "pop_reset": "Reset",
    "position at market price?": " Long Position at market price.",
    "post_only": "Only maker",
    "price": "Price",
    "price_cannot_be_less": "The price cannot be less than {}",
    "price_cannot_be_more": "The price cannot be greater than {}",
    "price_empty": "The price cannot be 0",
    "principal": "Principal",
    "promote_links": "Promote Url",
    "promotion_statistical": "Promotion Statistics",
    "prtner_invalid": "Invalid",
    "publish_a_trade_order": "Create Order",
    "purchase": "Buy",
    "push": "PUSH Assets",
    "push_maximum_volume": "PUSH quantity is greater than available balance {}",
    "push_minimum_trading_volume": "PUSH quantity is less than the minimum transaction amount {}",
    "quantity": "Amount",
    "quantity_deal": "Quantity / Deal",
    "quota": "Limit",
    "range": "Range",
    "real_income": "Real Income",
    "real_name_authentication": "Verified",
    "reason": "Reason",
    "rebate_amount": "Rebate Amount",
    "rebate_record": "Rebate Record",
    "rebate_reward": "Rebate Reward",
    "receiving_set": "Collection Setting",
    "receiving_set_title": "Please set the payment method first.",
    "recently_deal1": "Recent Transaction",
    "recharge_notice": "Recharge notes：\n1. Please send the number of {} you need to recharge to this address through {} client or online wallet.After sending, the system will automatically recharge the digital currency to your account on this website after the transaction has been confirmed for {} times. Please wait patiently.\n2. The same address can be recharged many times without affecting the account.\n3. Minimum recharge amount: {}",
    "recharge_source": "Top-up Source",
    "recharge_withdraw_failure_0001": "Network Anomaly",
    "recharge_withdraw_failure_0002": "Transaction password cannot be empty",
    "recharge_withdraw_failure_0003": "Verification code must be filled",
    "recharge_withdraw_failure_0004": "Merchant ID cannot be empty",
    "recharge_withdraw_failure_0005": "User ID cannot be empty",
    "recharge_withdraw_failure_0006": "User does not exist",
    "recharge_withdraw_failure_0007": "Currency ID cannot be empty",
    "recharge_withdraw_failure_0009": "Currency does not exist",
    "recharge_withdraw_failure_0010": "Google verification code can't be empty",
    "recharge_withdraw_failure_0011": "Transaction password is wrong",
    "recharge_withdraw_failure_0012": "Recharge type cannot be empty",
    "recharge_withdraw_failure_0013": "The number of recharges cannot be empty",
    "recharge_withdraw_failure_0014": "Recharge notes cannot be empty",
    "recharge_withdraw_failure_0015": "Recharge ID cannot be empty",
    "recharge_withdraw_failure_0016": "Recharge list does not exist",
    "recharge_withdraw_failure_0017": "Only waiting for recharge can be deleted",
    "recharge_withdraw_failure_0018": "Only wait for the recharge to be reviewed",
    "recharge_withdraw_failure_0019": "Only the approval of the approval can issue a freeze.",
    "recharge_withdraw_failure_0020": "Version number cannot be empty",
    "recharge_withdraw_failure_0021": "Parameter Error",
    "recharge_withdraw_failure_0022": "Current data has been updated",
    "recharge_withdraw_failure_0023": "ID cannot be empty",
    "recharge_withdraw_failure_0024": "Only locked status can be reviewed",
    "recharge_withdraw_failure_0025": "Only wait for the withdrawal status to lock",
    "recharge_withdraw_failure_0026": "Cancel only when waiting for cash withdrawal or lock status",
    "recharge_withdraw_failure_0027": "The number of freezes cannot be empty",
    "recharge_withdraw_failure_0028": "The reason for freezing cannot be empty",
    "recharge_withdraw_failure_0029": "Thawed notes cannot be empty",
    "recharge_withdraw_failure_0030": "The amount of coins cannot be empty",
    "recharge_withdraw_failure_0031": "The coin address cannot be empty",
    "recharge_withdraw_failure_0032": "Fee cannot be empty",
    "recharge_withdraw_failure_0033": "Abnormal handling fee",
    "recharge_withdraw_failure_0034": "Data does not exist",
    "recharge_withdraw_failure_0035": "The asset list cannot be thawed",
    "recharge_withdraw_failure_0036": "The coin address is incorrect",
    "recharge_withdraw_failure_0041": "This account has been forbidden to withdraw money, please consult the customer service",
    "recharge_withdraw_failure_0042": "The number of coins has reached the limit of the day",
    "recharge_withdraw_failure_0043": "The total amount of coins raised exceeds the current day limit",
    "recharge_withdraw_failure_0044": "Obtaining abnormal VIP level information",
    "recharge_withdraw_failure_0045": "Obtaining abnormal currency information",
    "recharge_withdraw_failure_0046": "Do not withdraw coins within 24 hours after resetting the password!",
    "recharge_withdraw_failure_0048": "Get the maximum value of a single coin",
    "recharge_withdraw_failure_0049": "Get the minimum value of a single coin",
    "recharge_withdraw_failure_0050": "The number of inputs must be less than the maximum limit",
    "recharge_withdraw_failure_0051": "The number of inputs must be greater than the minimum limit",
    "recharge_withdraw_msg_status_0001": "Waiting for withdrawal",
    "recharge_withdraw_msg_status_0002": "Sending",
    "recharge_withdraw_msg_status_0003": "Cash withdrawal success",
    "recharge_withdraw_msg_status_0004": "Cancel",
    "recharge_withdraw_msg_status_0005": "Confirming",
    "recharge_withdraw_msg_status_0006": "Recharge Successful",
    "recharge_withdraw_msg_status_0007": "Audited",
    "recharge_withdraw_msg_type_0001": "Deposit",
    "recharge_withdraw_msg_type_0002": "Withdraw",
    "recharge_withdraw_msg_withdraw_type_0001": "Platform Exchange",
    "recharge_withdraw_msg_withdraw_type_0002": "External Coin",
    "record": "Recording",
    "record_the_video": "Click to record video",
    "recording": "Rerecord",
    "redeem_now": "Redeem Now",
    "redeemed": "Redeemed",
    "redeeming": "Exchange",
    "redemption_amount": "Redemption amount",
    "redemption_time": "Redemption time",
    "referrer": "Recommender",
    "register": "Register",
    "registered_successfully": "Registration Success",
    "registration_award_details": "Registration reward details",
    "registration_incentives": "Registration Reward",
    "registration_time": "Registration Time",
    "regular": "Regular",
    "regular_return": "Regular monthly return",
    "release": "Receipt",
    "release_operation": "Post an order, click here to operate!",
    "release_the_order": "Release Order",
    "release_time": "Payment Time",
    "remark": "Remarks",
    "remember_state": "Remember Me",
    "repeal": "Cancel",
    "reset": "Reset",
    "reset_login_password": "Modify the login password",
    "reset_pass": "Reset Password",
    "residue": "Remaining",
    "retrieve_the_deposit": "Retrieve Money",
    "return_amount": "Return Amount",
    "return_method": "Return interest method",
    "return_the_principal": "Return of principal",
    "revenue_details": "Details of earnings",
    "revocation_all": "Revoke All",
    "reward_amount": "Number of rewards",
    "reward_locked": "Reward Lock",
    "reward_status": "Reward Status",
    "reward_type": "Reward Type",
    "rewards_record": "Reward Record",
    "right_now_register": "Register new user",
    "roll_out": "Transfer Out",
    "save": "Save",
    "save_coin": "Deposit",
    "save_coin_count": "Deposit Amount",
    "save_coin_days": "Deposit Days",
    "save_coin_duration": "Deposit Time",
    "save_currency": "Deposit",
    "save_money_amount": "Total deposit amount",
    "save_successfully": "Successful Save",
    "save_the_image": "Download",
    "scan_QR_code": "Please do not scan the QR code of unknown source",
    "scan_result": "Scan Result",
    "search": "Search For",
    "search_coin_placeholder": "Search Coins",
    "search_keyword": "Search Keyword",
    "search_name": "Search Name",
    "search_result": "Search Results",
    "search_trade_pair_placeholder": "Search for the trading pair you are interested in",
    "security_center": "Security Center",
    "security_level": "Security Level",
    "select_date_of_borth": "Please select a date of birth",
    "select_delete_warn": "Please select an alert to delete",
    "sell": "Sell",
    "sell_1": "Sell",
    "sell_order": "Sell",
    "sell_quantity": "Quantity of sale",
    "sell_rate": "Selling Renewal",
    "seller": "Seller",
    "seller_phone": "Mobile Number",
    "send": "Send",
    "senior_certification": "Advanced Authentication",
    "senior_certification1": "Advanced Authentication",
    "service": "Customer Service QQ:",
    "service_charge": "Fee",
    "session_timeout": "Login expired, please login again",
    "set_Password": "Modify",
    "set_gesture_pass": "Please set the gesture password",
    "set_login_password": "Setting login password",
    "setting": "Setting",
    "setting_alipay": "Setting Alipay",
    "setting_bank_card": "Setting bank card",
    "setting_message_warn_tip": "* After saving is enabled, you can receive a warning message, and close it without prompting.",
    "setting_paypal": "Setting Paypal",
    "setting_paypassword": "Please set the pay password first.",
    "setting_wechat": "Setting WeChat",
    "setting_western_union": "Setting Western Union",
    "share_invitation_link": "Share invitation link",
    "share_link": "Share a link to your friends",
    "share_to_do": "Share To",
    "shift_to": "Transfer",
    "single_least": "Single minimum limit is less than {}",
    "single_minimum": "Minimum",
    "single_more": "Single minimum limit is greater than {}",
    "skip": "Skip",
    "small_exchange": "Conversion",
    "small_exchange_tip": "* Reminder: You can only redeem assets whose valuation is less than {} {} within 24 hours. The currency of the delisting transaction is temporarily not convertible. The provisional exchange fee is {}",
    "small_exchange_tip-1": "* Reminder: The market price changes in real time, and the actual price prevails.The delisted currency is not convertible, the current conversion fee is {0}%",
    "small_exchange_valuation": "Valuation",
    "small_exchange_valuation-1": "BTC Value",
    "smallest_unit_price": "PUSH unit price is less than the minimum unit price {}",
    "so adjusted to": "So adjusted to",
    "sold_out": "Has been removed",
    "start time": "Starting Time",
    "start_date": "Start Date",
    "start_time": "Starting Time",
    "statistics_report": "Report Statistics",
    "status": "State",
    "submit": "Submit Video",
    "submitBtn": "Submit",
    "succeed": "Success",
    "successful_invitation": "Successful Invitation",
    "successfully_received": "Successfully Received",
    "successfully_set": "Success",
    "super_partner": "Super Partner",
    "switch_account": "Switch Account",
    "system_top_up": "System top-up",
    "system_unlock": "System Unlock",
    "tab_assets": "Assets",
    "tab_home": "Home",
    "tab_markets": "Markets",
    "tab_swaps": "Swaps",
    "tab_trade": "Spot",
    "taking_pictures": "Take a photo",
    "telegraph": "Telegraph group:",
    "temporary_not_online_contact_service": "Not yet online, please contact customer service",
    "the_day": "Day",
    "the_event_is_over": "The event is over",
    "the_latest_price": "Latest Price",
    "the_password_is_correct": "The password is correct",
    "theme": "Theme",
    "this_month": "This Month",
    "this_week": "This Week",
    "ticket": "Ticket",
    "time_of_payment": "Payment Time",
    "time_the_input": "No need to enter transaction password within {0} h",
    "timeout": "Timeout",
    "timeout_affirm": "Timeout not confirmed",
    "timeout_deal": "Timeout",
    "timeout_to_cancel": "Timeout",
    "to_confirm": "To Confirm",
    "total_buyer": "Total Seller",
    "total_count": "Total",
    "total_exchange": "Total Convert",
    "total_lock_amount": "Total lock volume",
    "total_money": "Total",
    "total_return": "Total expected revenue",
    "trade_fee": "Trade Rate",
    "trade_type": "Transaction Type",
    "trading_currency": "Trading Currency",
    "trading_fiat_currency": "Trading fiat currency",
    "trading_instructions": "Trading Instructions",
    "trading_on": "Pair",
    "trading_pair_abbreviation": "Please enter the short name of the transaction pair",
    "trading_this_month": "Trading this month",
    "trading_volume": "Volume",
    "transaction_description": "Transaction Description",
    "transaction_password": "Fund Password",
    "transaction_price": "Final Price",
    "transfer_number": "Transfer in and out quantity",
    "try_it_now": "Try it now",
    "type": "Types",
    "unable_to_access_album": "The current device does not support access to the album",
    "unaccalimed": "Unaccalimed",
    "unavailable": "Unavailable",
    "under_review": "Under Review",
    "univalence": "Price",
    "unlock_details": "Unlock Details",
    "unlock_mode": "Unlock Mode",
    "unlock_period": "Unlock Circle",
    "unlock_plan": "Unlock Plan",
    "unlock_plan_unlock": "Unlock",
    "unlock_record": "Unlock Record",
    "unlock_time": "Unlock Time",
    "unlocked": "Unlocked",
    "unredeemed": "Unredeemed",
    "unverified": "Not Authentication",
    "up_to_three": "Enable up to three",
    "upgrade": "Upgrade",
    "upload_again": "Re-upload",
    "upload_front": "Upload document front",
    "upload_identity_card": "Please upload your ID",
    "upload_image_success": "Upload image successfully",
    "upload_information_as_required": "The data must be uploaded strictly in accordance with the above requirements, otherwise the authentication will fail",
    "upload_passport": "Please upload your passport",
    "upload_passport_info_picture": "Upload passport information page photos",
    "upload_reverse": "Upload the reverse side of the document",
    "ups_and_downs": "Change",
    "ups_downs_habit": "Ups and downs habits",
    "usable": "Avbl",
    "used": "Used",
    "user-bind-failure": "Inviter binding failed",
    "user-bind-success": "Inviter to bind successfully",
    "user-collection-error": "Failed",
    "user-country-code-not-empty": "International code cannot be empty",
    "user-disable-trade": "This account is forbidden to trade, please contact customer service!",
    "user-down-notbind": "The user cannot bind the subordinate user.",
    "user-email-fail-often": "Mail is sent frequently, please resend after 120 seconds",
    "user-email-not-empty": "Email address cannot be empty",
    "user-email-verification-code-out": "The mailbox verification code timed out, please resend",
    "user-fail-advanced-auth": "Advanced authentication failed",
    "user-fail-advanced-authed": "The user has been certified by the senior",
    "user-fail-assetnotenough-error": "Insufficient Assets",
    "user-fail-bank-count-error": "Open up to 3 payment methods",
    "user-fail-bank-inotc": "Payment method has an order in otc and cannot be disabled",
    "user-fail-cardno-repeat-error": "The ID number has been used",
    "user-fail-email-code": "Email verification code error",
    "user-fail-google-binded": "Google has bound",
    "user-fail-google-code": "Google verification code error",
    "user-fail-inviter-notexist": "The recommender does not exist",
    "user-fail-login-disable": "This account is forbidden to login, please contact customer service!",
    "user-fail-login-lock": "Account is locked",
    "user-fail-login-password": "'User name or password error, remaining retry times {}",
    "user-fail-mail-binded": "The mailbox has been bound",
    "user-fail-not-realname": "Please first real name certification",
    "user-fail-oldpassword-error": "The original password is wrong",
    "user-fail-partner-not-found": "Merchant does not exist",
    "user-fail-partnernotfound": "Merchant does not exist",
    "user-fail-password": "The username or password is incorrect",
    "user-fail-password-blank": "Password can not be blank",
    "user-fail-password-blank1": "Confirm password can not be blank",
    "user-fail-password-repeat": "The new password you set is the same as the old password, please reset it!",
    "user-fail-password-thin": "Password strength is too weak",
    "user-fail-passwword-eq-paypassword": "The transaction password is the same as the login password. Please reset it.",
    "user-fail-paypassword-error": "Payment password error",
    "user-fail-paypassword-notset": "Please set the payment password first.",
    "user-fail-paypassword-set": "Payment password is set",
    "user-fail-phone-binded": "The phone number has been bound",
    "user-fail-realname-auth": "User real name authentication failed",
    "user-fail-realname-authed": "User has been certified by real name",
    "user-fail-realname-notset": "Please first real name certification",
    "user-fail-reg": "Registration failed, server error",
    "user-fail-reg-mail": "Email format is incorrect",
    "user-fail-reg-mail-exist": "The email has been registered",
    "user-fail-reg-phone-exist": "The phone number has been registered",
    "user-fail-securitycount": "Keep at least one verification method",
    "user-fail-sms-code": "SMS verification code error",
    "user-fail-sms-code-new": "New mobile phone number SMS verification code error",
    "user-fail-sms-code-old": "Old mobile phone number SMS verification code error",
    "user-fail-usernameisblank": "Username can not be empty",
    "user-id-or-nationCode—is-null": "User id or international code is empty",
    "user-login-success": "Login Successful",
    "user-mail-fail": "Email failed to send",
    "user-mail-success": "Email sent successfully",
    "user-not-exist": "The user does not exist",
    "user-notopen-success": "Not Open",
    "user-phone-not-empty": "Phone number cannot be empty",
    "user-sms-fail": "Send failed, server error",
    "user-sms-fail-often": "Send SMS frequently",
    "user-sms-success": "SMS sent successfully",
    "user-sms-verification-code-out": "Verification code timed out, please resend",
    "user-success-advanced-auth": "Advanced authentication succeeded",
    "user-success-realname-auth": "User real name authentication succeeded",
    "user-validate-code-fail": "Incorrect verification code",
    "user-validate-fail": "Verification failed",
    "user-vip-common-fail": "Purchase VIP failed",
    "user_agreement": "Please tick the agree user service agreement",
    "user_collection_cencel_success": "Cancelled",
    "user_collection_insert_success": "Collected",
    "user_pay_disable_state": "The transaction password error of this account has been exceeded. Please try again after 2 hours",
    "user_pay_is_error": "Transaction password error, you can also enter {} times, exceeding the limit will be frozen for 2 hours",
    "user_pay_password_not_setting": "You have not set a transaction password, please set the transaction password first.",
    "user_protocol": "User Agreement",
    "user_service_agreement": "《User Services Agreement》",
    "user_vip_has_expire": "This account VIP expires and cannot be upgraded!",
    "user_vip_update_failure": "This account does not support renewal and upgrade for suspend, please consult customer service for details!",
    "valid_identity_card": "Enter the correct ID number",
    "valuation": "Deposit valuation",
    "verification_code_error": "Verification code error",
    "verification_failed_001": "Verification Failed",
    "verification_finish": "Verification Completed",
    "version_updating": "New version update",
    "versions": "Version",
    "vertical_version": "Vertical",
    "view_activity": "View Activity",
    "view_lock": "View lock position",
    "volamount": "Buyer must traded {} times",
    "volamount_sell": "Seller must traded {} times",
    "vote": "Vote",
    "vote_add_coin": "Voting List",
    "vote_return": "Return",
    "voting_coin": "Voting Currency",
    "voting_number": "Votes",
    "voting_time": "Voting Time",
    "wait_buyer_payment": "Wait Payment",
    "wait_for_payment": "Pending payment",
    "waiting_for_receiving": "To be confirmed",
    "warm_prompt": "Tips",
    "warning_setTitle": "Warning Settings",
    "wechat_account": "Account",
    "wechat_upload": "* WeChat upload QR code method: Open WeChat Home > My > Wallet > Down payment > Save the picture, upload the receipt code of the mobile phone album.",
    "week": "w",
    "welcome": "Welcome to BitMatrix",
    "welcome_to": "Welcome to",
    "winning_record": "Winning Record",
    "withdrawal_amount": "Daily withdrawal limit",
    "withdrawal_charge": "Withdrawal Charge",
    "xilian": "Western Union",
    "yesterday_income": "Yesterday's earnings",
    "you_have_selected": "Selected",
    "MoreNot9": "The selected modules cannot exceed 14",
    "Home page apps": "Home page apps",
    "Home_Edit": "Edit",
    "recently_deal01": "Trade",
    "depth01": "Depth",
    "depth02": "Depth",
    "discount_currency_introduction01": "CURRENCY INTRODUCTION",
    "Info001": "Info",

    "Avg cost": "Average Price",
    "Long position": "Long",
    "Short position": "Short",
    "Hold on": "Hold on",
    "Done": "Done",
    "new_version": "New version found",
    "Announcement": "Announcement",
    "View details": "Check Details",

    "Option Assets": "Option Assets",
    "Option Account": "Option Account",
    "Option": "Option",
    "1M": "1M",
    "5M": "5M",
    "15M": "15M",
    "30M": "30M",
    "1H": "1H",
    "Delivery Time": "Delivery Time",
    "Call": "Long",
    "Put": "Short",
    "Draw": "Draw",
    "Draw2": "Draw",
    "Call1": "Long",
    "Put1": "Short",
    "Draw1": "Draw",
    "Buy-2": "Buy",
    "Rate of return": "Return",
    "Change": "Change",
    "Buy Option": "Buy",
    "Awaiting": "Awaiting",
    "Delivery": "Delivery",
    "Checkout": "Records",
    "Front": "Front",
    "Back": "Back",
    "delivery settlement": "Delivery Settlement",
    "Ongoing": " Ongoing",
    "Pending purchase": "About to Open",
    "Billing Amount": "Billing Amount",
    "Amount": "Amount",
    "Delivery result": "Delivery Result",
    "Delivery time": "Delivery Time",
    "Up": "Long",
    "Down": "Short",
    "lookUp": "Call",
    "lookDown": "Put",
    "Delivery details": "Delivery details",
    "Index Composition": "Index Composition",
    "Exchange": "Exchange",
    "Change2": "Change",
    "Weights": "Weights",
    "Order number": "Order Number",
    "Option Session": "Option Session",
    "Buy Time": "Buy Time",
    "Opening time": "Opening Time",
    "Bet Type": "Bet Type",
    "Opening Price": "Opening Price",
    "Delivery Price": "Delivery Price",
    "Settlement Currency": "Settlement Currency",
    "K-line": "K-line",
    "UpSelect": "Select",
    "DownSelect": "Select",
    "Front has ended, please place another order": "Front has ended, please place another order",
    "Successfully ordered": "OrderSuccess",
    "Insufficient balance": "Insufficient balance",
    "Quantity purchased must be greater than the minimum limit": "Quantity purchased must be greater than the minimum limit",
    "Quantity purchased must be less than the maximum limit": "Quantity purchased must be less than the maximum limit",
    "The current time is not in the purchase time range": "The current time is not in the purchase time range",
    "expected": "Expected",
    "To_a_game": "Again",
    "Settlement Result": "Settlement Result",
    "Delivery-1": "Delivery",
    "Ups and downs": "Change",
    "duration": "Duration",
    "Option funds daybook": "Option bill",

    "Open innovative options trading": "Open innovative options trading",
    "Confirm Open": "Confirm Open",
    "Option Agree Content": "In order to protect your rights, Before opening Options you must carefully read the《Innovative Options Trading Use Agreement》",
    "Option Agree Content footer": "《Innovative Options Trading Use Agreement》",
    "Option Agree Content header": "In order to protect your rights, Before opening Options you must carefully read the",
    "Service upgrade, please try again later!": "Service upgrade, please try again later!",

    "month": "M",

    "Invitation": "Invitation",
    "Markets": "Markets",
    "Swaps": "Swaps",
    "Trade": "Transaction",
    "Trade-1": "Trade",
    "Buy": "Buy",
    "Help": "Help",
    "Service": "Service",
    "Activity": "Activity",
    "Communtiy": "Communtiy",
    "No search results": "No search results",
    "The option account asset is 0, please transfer from the wallet account to the option account first.": "The option account asset is 0, please transfer from the wallet account to the option account first.",
    "Perpetual account assets are 0, please transfer from wallet account to perpetual account first.": "Perpetual account assets are 0, please transfer from wallet account to perpetual account first.",
    "Financing": "Financing",
    "Load": "Load",
    "Replay001": "Replay",
    "Constellation Program": "Hot - Constellation Program",
    "Constellation Program2": "Hot - Constellation Finance",
    "rate rankings": "Institutions with a single win rate rankings",
    "rate rankings2": "Top Financial Institutions of the Week",

    "CXT Quotes": "Quotes",
    "Digital asset trading tool service platform": "Digital asset trading tool service platform",
    "Strategic cooperation": "Strategic cooperation",

    "Financing assets": "Financing assets",
    "Remove 0 from the first number when registering!": "Remove 0 from the first number when registering!",
    "Are you sure you want to quit": "Are you sure you want to quit ?",


    "Closing Price": "Closing Price",
    "Formula interpretation": "Formula",
    "Note: If the input box is left blank": "Note: If the input box is left blank, it means that the current exchange data has not been obtained in this field",
    "Option Sessions": "Option Sessions",
    "Result": "Result",
    "When data for an exchange is not available": "When data for an exchange is not available ,the weigt is 0%.When the availability of data source ≤2，it is defined as invalid",
    "Assume that the index is derived from the opening prices of five exchanges": "Assume that the index is derived from the opening prices of five exchanges :A、B、C、D、E；The closing prices are :A`、B`、C`、D`、E`",
    "The proportion of those five exchanges are": "The proportion of those five exchanges are :a%、b%、c%、d%、e%、",
    "Stream bureau": "Stream bureau",
    "(valid data source ≤ 2)": "(valid data source ≤ 2)",
    "Option calculator": "Option calculator",
    "Round selection": "Round selection",
    "Select option": "Select option",
    "Average opening price": "Average opening price",
    "Average closing price": "Average closing price",
    "Calculate now": "Calculate now",
    "Calculation Results": "Calculation Results",
    "Calculating Time": "Calculating Time",
    "Management": "Management",
    "Other_home": "Other",
    "All applications": "All applications",
    "Boon": "Boon",
    "Coin_Address_draw": "Blockchain Address",
    "Congratulations": "Congratulations",
    "Profit": "Profit",
    "Gainers": "Gainers",
    "Drop": "Drop",


    "Order Price": "Order Price",
    "Order Price1": "Order Price",
    "Sec Swap": "Sec Swap",
    "Expected Return": "Expected Return",
    "Sec Swap Assets": "Sec Swap Assets",
    "Sec Swap Bill": "Sec Swap Bill",
    "Sec Swap Account": "Sec Swap Account",
    "Go Login": "Login",
    "NSO Session": "NSO Session",
    "Wallet Value1": "Wallet Value",
    "Spot Value1": "Spot Value",
    "PERP Value1": "PERP Value",
    "Option Value1": "Option Value",
    "Fiat Value1": "Fiat Value",
    "Pledge_Amount": "Pledge Amount",
    "total_return2": "Total Return",
    "Violation_Loss": "Violation Loss",
    "Cycle": "Cycle",
    "Locked2": "Locked",
    "Time_Left": "Time Left",
    "Cancel_Pledge": "Cancel Pledge",
    "countDown_day": "D ",
    "Entrust2": "Entrust",
    "All2": "All",
    "Ongoing2": "Ongoing",
    "Near2": "Upcoming",
    "Ended2": "Ended",
    "Cancel the pledge?": "Cancel the pledge?",
    "Lock-up mining": "Lock-up mining",
    "Rules Description": "Rules",
    "Today Expected": "Today Expected",
    "Order Escrow": "Order Escrow",
    "Minimum": "Minimum",
    "Daily Return": "Daily Return",
    "Buy-3": "Buy",
    "Minimum Amount": "Minimum Amount",
    "Lockup Period": "Lockup Period",
    "Confirm Pledge": "Confirm Pledge",
    "Buy-4": "Buy",
    "Completed2": "Completed",
    "Cancelled2": "Cancelled",
    "Conversion Starter": "STO Launch",
    "Listing": "Listing",
    "Total Target": "Target",
    "Conversion Ratio": "Ratio",
    "Single Limited": "Limited",
    "Participate Order": "Participate Order",
    "Fundraised": "Fundraised",
    "Current Progress": "Current Progress",
    "Time Left": "Time Left",
    "Start Time": "Start Time",
    "End Time": "End Time",
    "Conversion Amount": "Conversion Amount",
    "Conversion Now": "Conversion Now",
    "Conditions": "Conditions",
    "Preheating": "Preheating",
    "Records": "Records",
    "Select": "Select",
    "To": "To",
    "Active Currency": "Active Currency",
    "Amount-3": "Price",
    "Conversion Time": "Conversion Time",
    "Arrival Time": "Arrival Time",
    "Not Arrived": "Not Arrived",
    "Hours-1": "Hours",
    "Risk warning: It is displayed": "Risk warning: It is displayed for innovative assets and has the characteristics of digital assets. Please judge the price carefully and choose your investment decision carefully!",
    "Week-1": "Week",
    "S-1": "Sec",
    "minutes-2": "M",
    "You must pass basic KYC": "You must pass basic KYC",
    "You must pass advanced KYC": "You must pass advanced KYC",
    "Account order exceeds": "Account order exceeds",
    "Suspend": "Suspend",
    "Successfully": "Successfully",
    "Ongoing-1": "Ongoing",
    "Failed": "Failed",
    "New_Token_Subscribe": "STO Project",
    "More_awaits_you": "More wealth awaits you",
    "Distance Start": "Distance Start",
    "day-2": "Day",
    "Must Eligible": "Must Eligible",
    "Custodial funds": "Custodial funds",
    "Default Ratio": "Default Ratio",
    "Wallet Available": "Wallet Available",
    "Done-2": "Confirm",
    "Eligible Amount": "Eligible Amount",
    "Eligible Price": "Eligible Price",
    "New Token Subscribe": "STO Project",
    "Subscribe Now": "Subscribe Now",
    "Review Time": "Review Time",
    "Review Passed": "Review Passed",
    "Review Failed": "Review Failed",
    "Not Reviewed": "Not Reviewed",
    "Denial Reason": "Denial Reason",
    "If not received, please contact service": "If not received, please contact service",
    "Returned to wallet": "Returned to wallet",
    "From wallet assets": "From wallet assets",
    "Day-3": "d",
    "Sec Swap asset is 0, please transfer from wallet account to Sec Swap first": "Sec Swap asset is 0, please transfer from wallet account to Sec Swap first",
    "Settlement": "Settlement",
    "Completed": "Completed",
    "Password Setting": "Password Setting",
    "Set password and email": "Set password and email",
    "Email Settings": "Email Settings",
    "First-time registered users mus": "First-time registered users must complete the login password and email settings",
    "Driver ID": "Driver ID",
    "Preview": "Preview",
    "cancelTime": "Cancel time",
    'Total_assets_valuation': 'Total account assets value',
    'Invite Friends': 'Invite Friends',
    'RedGreen': 'Red+ Green-',
    'GreenRed': 'Green+ Red-',
    'Color Preference': 'Color Preference',
    'Fuzzy Information': 'Fuzzy Information',
    'user_bind_xilain_trade_pwd': 'Input Trading password',
    "login_please_input1": "Please enter SMS code",
    "user_please_input9": "Enter Google verification code",
    "user_link_name": "Blockchain Type",
    'user_coin_bring_together': 'Value',

    "chongbi0": 'Things to take note:',
    "chongbi1": '1、Please do not deposit into the wrong blockchain address as it is irreversible.',
    "chongbi2": '2、send the deposit number{0} to the address via {1} client or wallet.After sending, system will automatic deposit  your account after obtain {2} confirm for the trade.Please be patient.',
    "chongbi3": '2、Minimum deposit will be 5 USDT worth.',
    "chongbi4": '3、Contact the customer service support for assistance.',

    "currency_withdrawal_rate_rules": '{0} Withdrawal rate rules:',
    "currency_withdrawal_rate_rules1": '1. For the safety of the funds, the platform might call you for verification purposes; ',
    "currency_withdrawal_rate_rules2": '2. {0} Only after {1} confirmation of the recharge, withdrawals are allowed; ',
    "currency_withdrawal_rate_rules3": '2. Available cash amount ≤ available assets-unrecognized assets; ',
    "currency_withdrawal_rate_rules4": '3. Maximum withdrawals {0} times per day and maximum withdrawals {1} per day',
    "currency_transaction_password": "Password",
    'long_press_to_save_to_album': 'Press change to click and Hold the QR Photo to save',
    'the_fee_is_outside_the_range': 'The fee is outside the range',
    'start_and_end_date': 'Start and end date',
    'The_withdrawal_amount_cannot_be_less': 'The withdrawal amount cannot be less than {0}',
    'withdrawal_details': 'Withdrawal details ',
    "RegisterRemove0": 'Remove 0 from the first number when registering',
    "closeLockUpPopup": "Cancel Pledge will charge Violation Loss for {0} days,Violation Loss amount is '{1}{2}',Actual principal received is '{3}{4}'.",
    "LockUpDuan": "LUM",
    "TopGainers": "漲跌榜",
    "NewTokenSubscribe": "STO Project",
    "MoreWealthAwaitsYou": "More wealth awaits you",
    "TopGainers": "Top Gainers",
    "Newest": "Newest",
    "Hot": "Hot",
    "DatChange": "Change",
    "SetPassword": "Set password",
    "comm_password": "Trading password",
    "unraise_recovering_password": "After recovering the password, 1 hours can not be withdrawal",
    "PDleaseUseSafariBrowser": "Please use Safari browser",
    "ADddToDesktop": "Add BitMatrix to Desktop",
    "CDlickBelow": "Click Below",
    "SDelectAddToHomeScreen": "Select Add to Home Screen",
    "ADddToDesktop2": "Add BitMatrix to Desktop",
    "CDommonbrowserAddMethod": "Common browser add method",
    'user_senior_text1': 'note',
    'user_senior_text2': 'Photo file size cannot exceed 5m! The file format must be JPG, BMP, PNG, etc.',
    'user_senior_text3': 'Please make sure the photo has no watermark, no stains, clear identity information, complete avatar, non-text reverse photo! Do not use PS for photo processing!',
    'user_senior_text4': 'Holding',
    'user_senior_text5': 'ID photo',
    'user_senior_text6': ': Requires you to hold your',
    'user_senior_text7': 'Date of the day',
    'user_senior_text8': 'and',
    'user_senior_text9': 'UID',
    'user_senior_text10': 'And take a non mirror image.',
    'user_senior_text11': ', The other holding a handwritten one with your handwriting',
    'user_senior_text12': 'Hold the blank paper and please write the following, and those who do not match will not be approved:',
    'user_senior_text13': 'I have known and voluntarily assumed the investment risk',
    'user_senior_text14': '.',
    'user_ID_card': 'ID card',
    "user_passport": "passport",
    'Driver_ID': 'Driver ID',
    "user_senior_upload3": "Upload the photo of holding certificate + uid + date",
    "ServiceUpgrade": 'Service upgrade in progress',
    "ReferencePrice": "Reference Price",
    "referenceTips": "Non-final transaction unit price, for reference only",
    "NewCoinsLaunch": "New Coins Launch",
    "CurrentlyPurchased": "Currently Purchased",
    "TotalMarketValue": "Total Market Value",
    "IEORatio": "Ratio",
    "CurrencyIntroduction": "Introduction",
    "ConvertNow": "Convert Now",
    "RemainingAmount": "Remaining Amount",
    "referenceTips": "Subject to the actual purchase price",
    "ServiceUpgrade": 'Service upgrade in progress',
    "LastDays7": "Last 7 days",
    "LastDays30": "Last 30 days",
    "LastDays90": "Last 90 days",
    'hide_coin': 'Hide coins less than {0} BTC',
    "LockUpTips": "Note: The remaining {0}{1} assets that have not been reviewed and passed have been returned to your wallet account.",
    "PromotionalMining": "Promotional mining",
    'GoldAssets': 'Gold Assets',
    'ModifyEmail': 'Modify email',
    'NewEmail': 'New email',
    'NewEmailAddress': 'New email address',
    'VerifyNewEmail': 'Verify new email',
    'EnterNewEmailCode': 'Enter new email code',
    'VerifyOldEmail': 'Verify old email',
    'EnterOldEmailCode': 'Enter old email code',
    'VerifyLoginPassword': 'Verify login password',
    'EnterLoginPassword': 'Enter login password',
    "loan": "Lending Center",
    "loanText": "Provide safe and reliable currency borrowing services that can serve a variety of purposes with greater liquidity without the need to sell existing cryptocurrency assets.",
    "loanQuery": "Loan consultation",
    'Stop_surplus_tips': "When the market price reaches {}, the profit stop Commission will be triggered, and the profit is expected to be {} after the transaction",
    'Stop_loss_tips': "The stop loss order will be triggered when the market price reaches {}, and the loss is expected to be {} after the transaction",
    'Stop_surplus_tips1': "When the market price reaches {}, the profit stop entrustment will be triggered, and the loss is expected to be {} after the transaction",
    'Stop_loss_tips1': "The stop loss order will be triggered when the market price reaches {}, and the profit is expected to be {} after the transaction",
    'Countdown': "Countdown",
    'contract_After_adjustment': 'After adjustment, {} USDTs will be transferred from the account as new deposits',
    'contract_subject_to': '(Based on the actual amount transferred in)',
    'contract_the_used_margin': 'After adjustment, the used margin will be reduced by {} USDTs, with the reduced portion remaining in the position',
    'contract_transferred_to': 'Transferable to account balance',
    'contract_After_adjustment_q': 'After adjustment, the margin occupied by the position will increase by {} USDTs',
    'contract_the_used_margin_q': 'After adjustment, the margin occupied by the position will be reduced by {} USDTs',
    'Please1': 'Please enter the stop profit trigger price',
    'Please2': 'Please enter the commission price for profit cessation',
    'Please3': 'Please enter the stop loss trigger price',
    'Please4': 'Please enter the stop loss commission price',
    'Stop profit6': 'Stop profit trigger price must be greater than the latest transaction price',
    'stop loss7': 'The stop loss trigger price must be less than the latest transaction price',
    'Please8': 'Please enter the quantity',
    'Please9': 'There is no comparable quantity',
    'CountdownTime': 'Countdown',
    'ModifyEmail': 'Modify email',
    'NewEmail': 'New email',
    'NewEmailAddress': 'New email address',
    'VerifyNewEmail': 'Verify new email',
    'EnterNewEmailCode': 'Enter new email code',
    'VerifyOldEmail': 'Verify old email',
    'EnterOldEmailCode': 'Enter old email code',
    'VerifyLoginPassword': 'Verify login password',
    'EnterLoginPassword': 'Enter login password',
    h1: 'Get Qualified',
    h2: 'RebateRankingList',
    h3: 'Becoming a partner can earn',
    h4: 'Upgrade conditions',
    h5: 'Purchase of junior partners requires payment',
    h6: 'Current balance',
    h7: 'Genesis Partner',
    h8: 'Rebate',
    h9: 'Upgrade now',
    h10: 'It is already the highest level',
    h11: 'Rebate bonus',
    h12: 'Ranking rebates',
    h13: 'Team size',
    h14: 'Rebate records',
    h15: 'Invitee',
    h16: 'Settled',
    h17: 'Unsettled',
    h18: 'Enjoying subordinates',
    h19: 'Payment required',
    h20: 'Upgrade',
    h21: 'Rebate details',
    h22: 'Ranking',
    h23: 'User',
    h24: 'Rebate rewards',
    h25: 'Current level can obtain',
    h26: 'The commission obtained is fully converted into the cumulative value of USDT.',
    h27: 'Need to directly push {} ordinary users',
    h28: '{} valid regular users have been directly pushed',
    h29: 'Team size needs to reach {} people',
    h30: 'Team size {} people',
    "loan": "Lending Center",
    "loanText": "Provide safe and reliable currency borrowing services that can serve a variety of purposes with greater liquidity without the need to sell existing cryptocurrency assets.",
    "loanQuery": "Loan consultation",
    'Stop profit10': 'Stop profit trigger price needs to be less than the latest transaction price',
    'stop loss10': 'The stop loss trigger price needs to be greater than the latest transaction price',
    Earn:"Earn",
    Simple:"Simple & Secure",
    Low1:"Low risk and stable income",
    Low2:"Low risk, steady returns, redeemable at any time",
    CurrentAccount:"Current Account",
    Stable:"Stable value-added, a tool for wealth growth",
    EarnLocked:"Earn Locked",
    Searchpopular:"Search popular coins and start earning",
    Annualized:"Annualized return rate of {}%, safe assets, ultra-high returns",
    CoinsLC:"Coins",
    PurchaseLC:"Purchase amount",
    PurchaseLC2:'(minimum purchase is{})',
    Maximum:"Maximum",
    EstimatedInterest:"Estimated Interest",
    RuleLC:"Rule",
    SubscriptionTime:"Subscription Time",
    Interestaccrualtime:"Interest accrual time",
    RevenueTime:"Revenue Time",
    RedemptionCycle:"Redemption Cycle",
    ReturnNow:"Return Now",
    BuyLC:"Buy",
    PurchaseSuccessful:"Purchase Successful",
    DepositLC:"Deposit",
    Yesterday:"Yesterday's Profit",
    TotalLC:"Total deposit amount",
    TotalReturn:"Total Return",
    DepositValuation:"Deposit Valuation",
    DepositDetail:"Deposit Detail",
    RevenueDetails:"Revenue Details",
    DepositType:"Deposit Type",
    ReturnMethod:"Return Method",
    DepositAmount:"Deposit Amount",
    APR:"APR",
    Expected:"Expected",
    DepositCycle:"Deposit Cycle",
    CreationTime:"Creation Time",
    EarnProfit:"Earn Profit",
    DepositCost:"Deposit Cost",
    TotalRelease:"Total Release",
    ReleaseTime:"Release Time",
    Flexible:"Flexible",
    Fixed:"Fixed",
    Freeze:"Freeze",
    Completed3:"Completed",
    Cancelled:"Cancelled",
    DefaultSettlement:"Default Settlement",
    PurchaseAmount:"Purchase Amount(unlimited)",
    RetrieveDeposited:'Retrieve deposited coins',
    PreviewConversion:'Preview Conversion',
    ToEnsureThe:'To ensure the security of your account and meet higher transaction limits, we need to perform advanced identity verification.',
    ToVerification:'Click the button below to contact customer service for verification.',
    ToContact:'Contact customer service for verification immediately.',
    "comm_nickname_rule1": "Cannot exceed 7 Chinese characters or 15 English letters",
    "comm_nickname_rule2": "Cannot contain spaces and special characters",
    "otc_upload_picture3":"UUpload images can only be in jpeg/jpg/png/bmp format!",
    "CertificationDifficulties":'Is there a problem with the verification?',
    "CertifiedTipsP2P":'All the above third party merchants are certified by BitMatrix Trading Exchange.',
    'OneClickBackhand':'Reverse',
    'EmptyReversal':'Reverse Short Position to Long Position',
    'MultipleReversalsEmpty':'Reverse Long Position to Short Position',
    'BackhandOpenLong':'Short to Long',
    'BackhandOpenEmpty':'Long to Short',
    'DontShowMeAgain':"Don't Show Again",
    "TransferRequiresClosing":"The current contract position is still open. Please close it before proceeding.",
}