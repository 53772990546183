export const M = {
    "Slideright": "Tieni premuto il cursore e trascinalo a destra",
    "Pleaseverification": "Completa la verifica di sicurezza",
    "Pleaseorder": "Clicca in sequenza",
    "Verificationagain": "Verifica fallita, riprova",
    "10 orders": "10 ordini",
    "24h High": "Massimo 24H",
    "24h Low": "Minimo 24H",
    "24h Trading Vol": "Volume 24 ore",
    "24h Vol": "Volume 24H",
    "ADL mechanism": "Meccanismo ADL",
    "AMT": "Quantità",
    "Activity _home": "Attività",
    "Actual transaction number": "Numero di transazioni effettive",
    "Actual transaction price": "Prezzo di transazione effettivo",
    "Add margin": "Aggiungi margine",
    "Adjust leverage": "Regola leva",
    "Adjust success": "Regolazione riuscita",
    "Advanced limit order offers 3": "Gli ordini limite avanzati offrono tre meccanismi di attivazione: \"Solo maker (Post only)\", \"Tutto o annulla (FillOrKill)\", \"Esegui subito e annulla il resto (ImmediateOrCancel)\"; gli ordini limite normali sono sempre validi.",
    "Alipay": "Alipay",
    "All Closed": "Tutti chiusi",
    "All Filled": "Tutti eseguiti",
    "AllDetails": "Visualizza dettagli di liquidazione",
    "AllType": "Tutti i tipi",
    "Amount can flat": "Quantità liquidabile",
    "Appeal_reason": "Motivo dell'appello",
    "Applet service": "Servizio applet",
    "Are you sure to cancel the order？": "Sei sicuro di annullare l'ordine?",
    "Asked_to_record": "Registrare il video secondo le istruzioni sottostanti",
    "Asset conversion": "Conversione asset",
    "Asset_types": "Tipi di asset",
    "At the current price": "Ordina al miglior prezzo di mercato",
    "Avail.": "Disponibile",
    "Avail. margin": "Margine disponibile",
    "Available": "Disponibile",
    "Avg close price": "Prezzo medio di chiusura",
    "Avg open price": "Prezzo medio di apertura",
    "Avg.price": "Prezzo medio",
    "BBO": "Prezzo di mercato",
    "Bankcard": "Carta bancaria",
    "Bankruptcy price": "Prezzo di liquidazione",
    "Batch Cancel": "Annulla in blocco",
    "Branch_address": "Indirizzo filiale",
    "Buy crypto_home": "Compra criptovalute",
    "Calculations are not based on": "I risultati sono solo a scopo informativo, questo prezzo di liquidazione rappresenta solo le informazioni di un contratto in un'unica direzione.",
    "Can't calculate": "Impossibile calcolare",
    "Cancel": "Annulla",
    "Cancel all": "Annulla in blocco",
    "Cancel order": "Annulla ordine",
    "Cancel_the_login": "Annulla login",
    "Capital cost": "Flusso di capitale",
    "Capital cost - Expenditure": "Costo capitale - Spese",
    "Capital fee": "Commissione di capitale",
    "Capital fee - all": "Commissione di capitale - Totale",
    "Capital fee - income": "Commissione di capitale - Reddito",
    "Capital success": "Annullato con successo",
    "Charge_money": "Carica fondi",
    "Chinese_Yuan": "Yuan cinese",
    "Close all of": "Confermi di chiudere",
    "Close long": "Chiudi long",
    "Close out time": "Tempo di chiusura",
    "Close price": "Prezzo di chiusura",
    "Close short": "Chiudi short",
    "Closed": "Volume chiuso",
    "Closing PnL gains": "Guadagni di chiusura",
    "Closing PnL ratio": "Rapporto di guadagno di chiusura",
    "Closing profit": "Profitto di chiusura",
    "coin_failure_0002": "Dati per la moneta non disponibili",
    "Common functions": "Funzioni comuni",
    "Communtiy_home": "Comunità",
    "Conceal canceled orders": "Nascondi ordini annullati",
    "Confirm_the_login": "Conferma login",
    "Confirm_to_mention_money": "Conferma prelievo",
    "Cont": "Contratti",
    "Copy link": "Copia link",
    "Cross": "Cross margin",
    "Currency_rate_description": "Descrizione delle tariffe",
    "Current": "Attuale",
    "Delete_option": "Elimina",
    "Determine_to_cancel": "Confermi di annullare?",
    "Don't show me again": "Non mostrare di nuovo",
    "Draw_inconsistency": "Le password inserite non corrispondono",
    "Draw_the_unlock": "Trascina per impostare una nuova password",
    "Enter email address": "Inserisci il tuo indirizzo email",
    "Enter expected PnL": "Inserisci il guadagno atteso",
    "Enter expected PnL Ratio": "Inserisci il rapporto di guadagno atteso",
    "Enter leverage from 1 up to 125": "Inserisci leva da 1 a 125",
    "Enter phone number": "Inserisci il tuo numero di telefono",
    "Enter the verification code": "Inserisci il codice di verifica",
    "Equity": "Equità della moneta",
    "Est liquidation price": "Prezzo di liquidazione stimato",
    "Face value": "Valore nominale",
    "Filed Value": "Valore eseguito",
    "Fill or Kill": "Tutto o annulla",
    "Filled amt": "Quantità eseguita",
    "Filled time": "In base all'orario di esecuzione",
    "Flash_strong": "Luce intensa",
    "Funding rate": "Tasso di finanziamento",
    "Funding rate - 1": "Tasso di finanziamento",
    "Funding rate comparison": "Confronto tasso di finanziamento",
    "Funding rate time": "Tempo del tasso di finanziamento",
    "Fuzzy_information": "Informazioni vaghe",
    "Gesture_error": "Errore nella password",
    "Google_code": "Codice Google",
    "Help_home": "Aiuto",
    "Hide_no_asset_currency": "Nascondi monete senza asset",
    "Highest PnL ratio": "Rapporto di guadagno massimo",
    "Historical record": "Registro storico",
    "I agree to": "Accetto",
    "I have read and agree to this agreement": "Ho letto e accetto questo accordo",
    "If_the_buyer_is_at": "Se l'acquirente è",
    "Immediately or Cancel": "Subito o annulla il resto",
    "In force": "In vigore",
    "Index": "Indice spot",
    "Index Price": "Prezzo indice",
    "Index_contrct": "Indice spot",
    "Info": "Panoramica del contratto",
    "Initial margin": "Margine iniziale",
    "Instrument": "Tipologia di trading attuale",
    "Insurance fund": "Fondo di riserva",
    "Interest bearing record": "Registro di interessi",
    "Invalid_qr_code": "Codice QR non valido",
    "Invite code(Optional)": "Inserisci il codice di invito (facoltativo)",
    "InviteCodeMuster": "Inserisci il codice di invito (obbligatorio)",
    "Invite_home": "Invita",
    "Isolated": "Isolato",
    "Lack_of_edge": "Mancanza di margine",
    "Last Price": "Prezzo di mercato",
    "Latest Filled price": "Prezzo di esecuzione più recente",
    "Latest Filled price01": "Ultimo prezzo",
    "Latest price": "Ultimo prezzo",
    "Leverage": "Leva",
    "Leverage after increase": "Leva dopo l'aggiunta:",
    "Leverage11": "Leva attuale",
    "Leverage_BB": "Regola leva",
    "Limit order is an order": "Un ordine limite è un ordine in cui l'utente imposta la quantità e il prezzo accettabile. Quando il mercato soddisfa le aspettative, il sistema esegue al miglior prezzo entro il limite.",
    "Limit price": "Prezzo limite",
    "Limit/Market": "Limite/Market",
    "LimitAndMarket": "Limite/Market",
    "Liquidation price": "Prezzo di liquidazione",
    "Liquidation price after increase": "Prezzo di liquidazione dopo l'aggiunta:",
    "Long": "Long",
    "Lowest PnL ratio": "Rapporto di guadagno minimo",
    "MAIL_COUNTRY_NOT_EXITS": "Il paese attuale non esiste! Aggiungi prima di inviare nuovamente l'email",
    "MAIL_NOT_MAIL_PROVIDER": "Nessun fornitore di email disponibile, aggiungere prima di inviare nuovamente l'email!",
    "MAIL_TEMPLATE_NOT_FOUND": "Il modello di email attuale non esiste! Aggiungere prima di inviare nuovamente",
    "MAIL_TO_ADDRESS_NOT_FOUND": "L'indirizzo email attuale non esiste, controlla prima di inviare nuovamente",
    "MJRefreshAutoFooterIdleText": "Clicca o scorri per caricare di più",
    "MJRefreshAutoFooterNoMoreDataText": "Tutti i dati sono stati caricati",
    "MJRefreshAutoFooterRefreshingText": "Caricamento di ulteriori dati...",
    "MJRefreshBackFooterIdleText": "Scorri per caricare di più",
    "MJRefreshBackFooterNoMoreDataText": "Tutti i dati sono stati caricati",
    "MJRefreshBackFooterPullingText": "Rilascia per caricare di più",
    "MJRefreshBackFooterRefreshingText": "Caricamento di ulteriori dati...",
    "MJRefreshHeaderDateTodayText": "Oggi",
    "MJRefreshHeaderIdleText": "Scorri per aggiornare",
    "MJRefreshHeaderLastTimeText": "Ultimo aggiornamento:",
    "MJRefreshHeaderNoneLastDateText": "Nessun record",
    "MJRefreshHeaderPullingText": "Rilascia per aggiornare",
    "MJRefreshHeaderRefreshingText": "Aggiornamento in corso...",
    "MKT Close ALL": "Chiusura totale al mercato",
    "Margin add": "Aggiungi margine",
    "Margin mode": "Modalità di tutte le posizioni",
    "Margin ratio": "Rapporto di margine",
    "Margin reduce": "Riduci margine",
    "Margin transfer": "Trasferimento di margine",
    "Margin-1": "Saldo margine",
    "Margin-2": "Regola margine",
    "Margined": "Contratti",
    "Mark price": "Prezzo di riferimento",
    "Market": "Prezzo di mercato",
    "Market Order Dig": "L'ordine di mercato consente di ordinare al miglior prezzo di mercato attuale per un trading rapido.",
    "Market Overview": "Panoramica del mercato",
    "Market_transaction": "Transazione a prezzo di mercato",
    "Max": "Massimo annullabile",
    "Max Open int": "Massimo volume aperto",
    "Max buy": "Max acquistabile",
    "Max long": "Max long",
    "Max position size at current leverage": "Massima dimensione posizione attuale:",
    "Max sell": "Max vendibile",
    "Max short": "Max short",
    "Max position size at current leverage": "Massima dimensione posizione attuale",
    "Mention_money": "Prelievo",
    "Mention_money_address": "Inserisci un indirizzo corretto",
    "Mention_money_tips_ios": "Suggerimenti\n1. Inserisci direttamente l'indirizzo di prelievo, verifica con password, SMS, email o codice Google.\n2. Aggiungi indirizzo legato per evitare di reinserire la verifica per future operazioni.",
    "Messages_notification": "*Verifica che la tua carta bancaria sia abilitata per le notifiche SMS",
    "Mini Programs": "Applet",
    "Minimum_handling_charge": "L'importo del prelievo deve essere maggiore della commissione",
    "Minimum_withdrawal": "Minimo prelievo per transazione",
    "MoreNot9": "I moduli selezionati non possono superare i 14",
    "Most increase": "Aumento massimo",
    "Most reduce": "Riduzione massima",
    "Must be integer multiple of": "Deve essere un multiplo intero di",
    "Not Enabled": "Non abilitato",
    "Not_on": "Non disponibile",
    "Not_to_mention_money": "Per la sicurezza dei tuoi fondi, non puoi effettuare prelievi o operazioni sensibili per un'ora dopo la reimpostazione della password di trading.",
    "OTC account": "Conto OTC",
    "OTC assets": "Wallet OTC",
    "OTC交易须知": "Informazioni sul trading OTC",
    "On Hold": "In sospeso",
    "One-way": "Unidirezionale",
    "One/Two-way": "Unidirezionale/Direzionale",
    "Open Time": "Tempo di apertura",
    "Open amount": "Quantità di apertura",
    "Open int.": "Interesse aperto",
    "Open interest": "Interesse aperto",
    "Open long": "Apri lungo",
    "Open orders": "Ordini aperti",
    "Open price": "Prezzo di apertura",
    "Open short": "Apri corto",
    "Order Details": "Dettagli dell'ordine",
    "Order Sorting": "Ordinamento degli ordini",
    "Order Value": "Valore dell'ordine",
    "Order amt": "Importo dell'ordine",
    "Order history": "Storico ordini",
    "Order price": "Prezzo dell'ordine",
    "Order trigger direction": "Direzione di attivazione dell'ordine",
    "Order type": "Tipo di ordine",
    "PL": "Profitto",
    "PL Ratio": "Rapporto di profitto",
    "Pair01": "Tipo di contratto",
    "Parse_failure": "Salvataggio fallito",
    "Partially Filled": "Parzialmente eseguito",
    "Partially closed": "Parzialmente chiuso",
    "Password": "Password",
    "Payment_type": "Tipo di pagamento",
    "Perpetual": "Contratto perpetuo",
    "Perpetual funds daybook": "Registro fondi perpetui",
    "Perpetual01": "Perpetuo",
    "Pingclose": "Chiudi posizione",
    "Please input a password": "Si prega di inserire",
    "Position": "Posizione",
    "Position balance": "Saldo della posizione",
    "Position history": "Storico posizioni",
    "QR_code": "Codice QR",
    "Quantity cannot exceed": "La quantità non può superare 125",
    "Quantity not less than": "La quantità non deve essere inferiore a 1",
    "Recent close": "Chiudi più recente",
    "Recording_requirements": "Requisiti di registrazione video",
    "Required position margin at current leverage": "Margine richiesto per la posizione attuale:",
    "Reset_transaction_password": "Reimposta password di transazione",
    "Revoked": "Revocato",
    "SL": "Stop loss",
    "SL order": "Ordine di stop loss",
    "SL order price": "Prezzo dell'ordine di stop loss",
    "SL order price can't be empty": "Il prezzo dell'ordine di stop loss non può essere vuoto",
    "SL trigger price": "Prezzo di attivazione di stop loss",
    "SL trigger price can't be empty": "Il prezzo di attivazione di stop loss non può essere vuoto",
    "SMS_30_OUT_RESTRICTED": "Invio del codice di verifica troppo frequente, è possibile inviarne uno ogni 30 secondi.",
    "SMS_BAD_ARGUMENT_FORMAT": "Formato del numero di telefono non corretto.",
    "SMS_COUNTRY_NOT_EXITS": "Questo paese non esiste, controlla e riprova!",
    "SMS_DAY_LIMIT_PER_MOBILE": "Numero massimo di invii superato per lo stesso numero di telefono in 24 ore",
    "SMS_DUP_IN_SHORT_TIME": "Invio ripetuto dello stesso contenuto per lo stesso numero di telefono entro 30 secondi",
    "SMS_FAILURE": "Invio fallito",
    "SMS_HOUR_LIMIT_PER_MOBILE": "Numero massimo di invii superato per lo stesso numero di telefono in 1 ora",
    "SMS_MARKET_FORBIDDEN": "A causa di restrizioni dell'operatore, i messaggi di marketing non possono essere inviati temporaneamente",
    "SMS_NOT_SMS_PROVIDER": "Nessun fornitore di SMS disponibile, aggiungi prima di inviare nuovamente!",
    "SMS_NOT_SMS_PROVIDER_TEMPLATE": "Nessun modello disponibile per questo fornitore di SMS, aggiungi prima di inviare nuovamente!",
    "SMS_PHONE_OVER_COUNT": "Numero massimo di invii di codici di verifica superato per questo numero di telefono, riprova dopo 24 ore!",
    "SMS_PHONE_PROHIBIT_SEND": "Questo numero di telefono è vietato dal ricevere SMS, riprova dopo 24 ore!",
    "SMS_SUCCESS": "Invio riuscito",
    "SMS_TOO_MANY_TIME_IN_5": "Invio ripetuto dello stesso contenuto per lo stesso numero di telefono più di 3 volte in 5 minuti",
    "SMS_UNKNOWN_EXCEPTION": "Si è verificata un'eccezione sconosciuta nel sistema",
    "SMS_code": "Codice di verifica SMS",
    "Save picture": "Salva immagine",
    "Scanning_to_obtain": "Scansiona per ottenere l'indirizzo di deposito",
    "See auto deleverage liquidation for details": "Consulta i dettagli della liquidazione automatica",
    "Set_transaction_password": "Imposta la password di transazione",
    "Settlement1": "Tempo fino alla liquidazione",
    "Settlrment coin": "Moneta di liquidazione",
    "Share time": "Tempo di condivisione",
    "Shielding success": "Blocco riuscito",
    "Short": "Corto",
    "Sign Up": "Registrati",
    "Sign in": "Accedi",
    "Simplified_Chinese": "Cinese semplificato",
    "StatusOrder": "Stato dell'ordine",
    "Stop loss": "Imposta stop loss",
    "Stop profit stop loss": "Devi selezionare almeno uno tra stop profit e stop loss, altrimenti non puoi effettuare ordini",
    "Sustainable account": "Conto perpetuo",
    "Sustainable assets": "Attività perpetue",
    "Switch to PnL": "Passa a guadagni",
    "Switch to PnL ratio": "Passa al rapporto di guadagni",
    "TP": "Take profit",
    "TP order": "Ordine di take profit",
    "TP order price": "Prezzo dell'ordine di take profit",
    "TP order price can't be empty": "Il prezzo dell'ordine di take profit non può essere vuoto",
    "TP trigger price": "Prezzo di attivazione di take profit",
    "TP trigger price can't be empty": "Il prezzo di attivazione di take profit non può essere vuoto",
    "TP | SL": "Take profit e stop loss",
    "TP | SL order": "Ordine di take profit e stop loss",
    "Take profit": "Imposta take profit",
    "Telegraphic_transfer_address": "Indirizzo per bonifico",
    "The Calculator": "Calcolatrice",
    "The maximum flatable quantity is": "La quantità massima di chiusura è",
    "The maximum leverage is": "La leva massima è",
    "The minimum flatable quantity is": "La quantità minima di chiusura è",
    "The minimum leverage is": "La leva minima è",
    "The poster has been saved to the album": "Il poster è stato salvato nell'album",
    "The_correct_sample": "Esempio corretto",
    "The_input_signal": "Trascina per inserire la password",
    "The_new_address": "Nuovo indirizzo",
    "They_are_the_number_of": "Numero di ordini",
    "Tick size": "Dimensione minima di variazione",
    "To_update": "Aggiornare",
    "Toggle_front_camera": "Si prega di passare alla fotocamera frontale e mantenere la testa centrata",
    "Total": "Quantità totale",
    "Total coin": "Numero totale di monete",
    "Total cont": "Numero totale di contratti",
    "Total value": "Valore totale della posizione",
    "Total_asset_valuation": "Valutazione totale degli asset",
    "Trade type": "Tipo di affare",
    "Trade_sector": "Si prega di selezionare",
    "Transfer": "Trasferimento",
    "Transfer_to_remind": "Si prega di annotare durante il trasferimento (altrimenti le conseguenze sono a proprio carico)",
    "Trigger mark price": "Prezzo di attivazione del prezzo di riferimento",
    "Trigger price": "Prezzo di attivazione",
    "Two-way": "Bidirezionale",
    "UPL": "Utile non realizzato",
    "US_dollar": "Dollaro statunitense",
    "Unblocked": "Sbloccato",
    "Uneasy lies the head that wears a crown": "Chi porta la corona non dorme mai tranquillo",
    "Unfilled": "Non eseguito",
    "Unfilled11": "In attesa di esecuzione",
    "Upload_collection_code": "Si prega di caricare il codice di pagamento",
    "Usdt-Margined": "Contratto USDT",
    "Users who register": "Gli utenti che si registrano per la prima volta devono completare l'impostazione della password di accesso",
    "Verification Code": "Codice di verifica",
    "Videoing_requirement": "Requisiti per la registrazione video",
    "View": "Visualizza",
    "View more": "Dettagli della posizione",
    "Waiting effect": "In attesa di attivazione",
    "Waiting_for_the_payment": "In attesa di pagamento",
    "Wechat": "WeChat",
    "WestUnion": "Western Union",
    "Without_her": "Esclusa dalla ripresa",
    "abandoned": "Abbandonato",
    "abnormal_assets": "Anomalie negli asset dell'account, operazione non possibile!",
    "about_us": "Chi siamo",
    "account": "Account",
    "account_empty": "Account non valido",
    "account_failure_0001": "Errore nell'aggiunta dell'ID della transazione a REDIS!",
    "account_failure_0002": "Disponibilità insufficiente, impossibile elaborare!",
    "account_failure_0003": "Errore nella registrazione del log di aumento del congelamento dell'utente!",
    "account_failure_0004": "Errore nell'aumento del congelamento dell'utente!",
    "account_failure_0005": "Errore nella registrazione del log di detrazione dell'utente!",
    "account_failure_0006": "Errore nella detrazione dell'utente!",
    "account_failure_0007": "Errore nella registrazione del log di aumento della disponibilità dell'utente!",
    "account_failure_0008": "Errore nell'aumento della disponibilità dell'utente!",
    "account_failure_0009": "Errore nell'aggiunta di nuove monete da parte del merchant, errore nell'aggiunta di nuove monete da parte dell'utente!",
    "account_failure_0010": "Errore nell'aggiunta di nuove monete da parte del merchant!",
    "account_failure_00100": "L'ID del merchant non può essere vuoto!",
    "account_failure_00101": "L'ID utente non può essere vuoto",
    "account_failure_00102": "L'ID della moneta non può essere vuoto",
    "account_failure_00103": "La quantità non può essere vuota",
    "account_failure_00104": "La quantità non può essere inferiore al volume di scambio minimo",
    "account_failure_00105": "La password di transazione non può essere vuota",
    "account_failure_00106": "Password di transazione errata",
    "account_failure_00107": "Il prezzo non può essere vuoto",
    "account_failure_00108": "Il prezzo unitario non può essere inferiore al prezzo unitario minimo",
    "account_failure_00109": "L'utente non esiste!",
    "account_failure_0011": "Il wallet del merchant per questa moneta esiste già!",
    "account_failure_00110": "Errore nell'inizializzazione dell'attivo PUSH!",
    "account_failure_00111": "Il PUSHID non può essere vuoto",
    "account_failure_00112": "Errore nel pagamento degli attivi PUSH!",
    "account_failure_00113": "Errore nella cancellazione degli attivi PUSH!",
    "account_failure_00114": "Impossibile ottenere l'indirizzo di deposito",
    "account_failure_00115": "L'indirizzo di prelievo non può essere vuoto!",
    "account_failure_00116": "Errore nell'aggiunta dell'indirizzo di prelievo",
    "account_failure_00117": "L'ID non può essere vuoto",
    "account_failure_00118": "Errore nella cancellazione dell'indirizzo di prelievo",
    "account_failure_00119": "Errore di rete, riprova più tardi!",
    "account_failure_0012": "Congelamento insufficiente, impossibile elaborare!",
    "account_failure_00120": "Questo indirizzo di prelievo esiste già",
    "account_failure_00121": "Nessuna moneta PUSH disponibile",
    "account_failure_00122": "Il codice di verifica del telefono è vuoto!",
    "account_failure_00123": "Il codice di verifica dell'email è vuoto!",
    "account_failure_00124": "Il codice di verifica di Google è vuoto!",
    "account_failure_00125": "Codice di verifica errato!",
    "account_failure_00126": "Autopush vietato!",
    "account_failure_00127": "L'indirizzo di prelievo non è valido!",
    "account_failure_00129": "Modifiche alla password non consentite entro 24 ore",
    "account_failure_0013": "Errore nella registrazione del log di detrazione per il congelamento del merchant!",
    "account_failure_00130": "Attività sbilanciate, operazione non consentita",
    "account_failure_00131": "Anomalie negli asset dell'account, stato di transazione disabilitato",
    "account_failure_00132": "Etichetta non corrispondente",
    "account_failure_00133": "La tipologia di moneta non esiste o è stata disabilitata",
    "account_failure_0014": "Errore nella detrazione del congelamento del merchant!",
    "account_failure_0015": "Errore nella registrazione del log di aumento degli interessi disponibili per l'utente!",
    "account_failure_0016": "Errore nell'aumento degli interessi disponibili per l'utente!",
    "account_failure_0017": "Errore nella registrazione del log di detrazione degli interessi disponibili per il merchant!",
    "account_failure_0018": "Errore nella detrazione degli interessi disponibili per il merchant!",
    "account_failure_0019": "Errore nel congelamento degli interessi disponibili per l'utente!",
    "account_failure_0020": "Errore nel congelamento degli interessi disponibili per l'utente, errore nel log di registrazione!",
    "account_failure_0021": "Errore nella registrazione del log di congelamento per l'utente!",
    "account_failure_0022": "Errore nel congelamento degli interessi disponibili per l'utente!",
    "account_failure_0023": "Errore nella registrazione del log di aumento degli interessi disponibili per il merchant!",
    "account_failure_0024": "Errore nell'aumento degli interessi disponibili per il merchant!",
    "account_failure_0025": "Errore nella registrazione del log di detrazione del congelamento per l'utente!",
    "account_failure_0026": "Errore nella detrazione del congelamento per l'utente!",
    "account_failure_0027": "Errore nella registrazione del log di detrazione degli interessi disponibili per l'utente!",
    "account_failure_0028": "Errore nella detrazione degli interessi disponibili per l'utente!",
    "account_failure_0029": "Errore nel ripristino del congelamento dell'utente, errore nel log di registrazione!",
    "account_failure_0030": "Errore nel ripristino del congelamento dell'utente!",
    "account_failure_0031": "Asset insufficienti per il ripristino!",
    "account_failure_0032": "Errore nel ripristino del congelamento del merchant, errore nel log di registrazione!",
    "account_failure_0033": "Errore nel ripristino degli asset del merchant",
    "account_failure_0034": "Errore nella registrazione del log di aumento del congelamento del merchant!",
    "account_failure_0035": "Errore nell'aumento del congelamento del merchant!",
    "account_failure_0036": "Errore nella registrazione del log di detrazione degli interessi disponibili per il merchant!",
    "account_failure_0037": "Errore nella detrazione degli interessi disponibili per il merchant!",
    "account_failure_0038": "Errore nella registrazione del log di congelamento per il merchant!",
    "account_failure_0039": "Errore nel congelamento per il merchant!",
    "account_failure_0040": "Errore nella registrazione del log di aumento degli asset disponibili per la piattaforma!",
    "account_failure_0041": "Errore nell'aumento degli asset disponibili per la piattaforma!",
    "account_failure_0042": "L'operazione ha restituito un'eccezione!",
    "account_failure_0043": "L'operazione è stata eseguita con errore!",
    "account_failure_0044": "L'operazione è stata eseguita con successo!",
    "account_failure_0045": "L'operazione è in fase di esecuzione!",
    "account_failure_0046": "Operazione riuscita!",
    "account_failure_0047": "Errore nel ripristino degli asset della piattaforma",
    "account_failure_0048": "Errore nella registrazione del log di ripristino degli asset della piattaforma",
    "account_failure_0049": "Questa moneta esiste già sulla piattaforma!",
    "account_failure_0050": "Errore nell'aggiunta di nuove monete da parte della piattaforma!",
    "account_failure_0051": "Questa moneta esiste già sulla piattaforma!",
    "account_failure_0053": "Tipo di transazione errato!",
    "account_failure_0054": "Errore nell'aumento degli asset disponibili per il merchant!",
    "account_failure_0055": "Errore nella registrazione del log di aumento degli asset disponibili per il merchant!",
    "account_failure_0056": "Errore nel trasferimento di reddito tra l'utente e il merchant!",
    "account_failure_0057": "Errore nella registrazione del log di trasferimento di reddito tra l'utente e il merchant!",
    "account_failure_0058": "Errore nel trasferimento degli asset disponibili per la gestione!",
    "account_failure_0059": "Errore nella registrazione del log di trasferimento degli asset disponibili per la gestione!",
    "account_failure_0060": "Gestione insufficiente, impossibile eseguire!",
    "account_failure_0061": "Errore nel trasferimento degli asset disponibili per la gestione!",
    "account_failure_0062": "Errore nella registrazione del log di trasferimento degli asset disponibili per la gestione!",
    "account_failure_0063": "Errore nel ripristino dell'operazione, nessun dato operativo disponibile!",
    "account_failure_0064": "Questa moneta non è disponibile al momento",
    "account_failure_0065": "Non hai i diritti per visualizzare questa moneta, contatta il servizio clienti ufficiale",
    "account_failure_0071": "Errore nel blocco del deposito per l'utente!",
    "account_failure_0072": "Il record di deposito dell'utente non esiste o è stato modificato",
    "activitie_end": "Attività conclusa",
    "activity_invalid": "Scaduta",
    "actual_amount": "Importo effettivo",
    "actual_exchange_amount": "Quantità effettiva scambiata",
    "actual_to_account": "Importo effettivo accreditato",
    "addSuccess": "Aggiunta riuscita",
    "add_channel_function": "Aggiunta della funzione di verifica del canale",
    "add_charge_and_transfer_function": "Aggiunta della funzione di deposito e trasferimento",
    "added_alert_setting_function": "Aggiunta della funzione di impostazione degli avvisi",
    "added_day_mode_toggle": "Aggiunta della funzione di attivazione della modalità giorno",
    "address_tag": "Etichetta",
    "advanced_limit_order": "Ordine limite avanzato",
    "advertising": "Pubblicità",
    "advertising_management": "Gestione pubblicitaria",
    "affirm": "Conferma",
    "affirm_pass": "Conferma password",
    "again_digits6": "Inserisci nuovamente la nuova password",
    "again_password": "Inserisci nuovamente la password di accesso",
    "airdrop_coin": "Moneta in airdrop",
    "airdrop_date": "Data di airdrop",
    "airdrop_number": "Quantità di airdrop",
    "alipay_account": "Account Alipay",
    "alipay_upload": "*Metodo per caricare il codice QR su Alipay: apri la homepage di Alipay> Ricevi> Salva immagine, quindi carica il codice di pagamento salvato nella galleria fotografica.",
    "all": "Tutti",
    "all_buy": "Tutti gli acquisti",
    "all_country": "Tutti i paesi",
    "all_order": "Tutti gli ordini",
    "all_sell": "Tutte le vendite",
    "already_use": "Già attivato",
    "amount_of_payout": "Importo del pagamento",
    "announcement_all": "Tutte le comunicazioni",
    "announcement_center": "Centro notifiche",
    "announcement_official": "Comunicazione ufficiale",
    "annualized_rate": "Tasso annualizzato",
    "appeal_cancel": "Appello annullato, ordine cancellato",
    "appeal_complete": "Appello completato, ordine completato",
    "appeal_dispose_time": "Tempo di gestione dell'appello",
    "appeal_of_time": "Tempo di invio dell'appello",
    "apply_locked": "Richiesta di blocco",
    "asset_allocatio": "Distribuzione degli asset",
    "assets_asset_valuation": "Valutazione degli asset",
    "assets_can_transfer": "Trasferibile",
    "assets_cash_flow": "Flusso di cassa",
    "assets_coin_funds_flow": "Flusso di fondi in criptovalute",
    "assets_currency_assets": "Asset in criptovalute",
    "assets_deposit_income_tip": "Questo valore di rendimento è calcolato in base al tasso di cambio in tempo reale, i rendimenti specifici saranno quelli effettivi.",
    "assets_fiat_assets": "Asset OTC",
    "assets_fiat_money_flow": "Flusso di cassa OTC",
    "assets_flowing_details": "Dettagli del flusso",
    "assets_prompt_001": "[Si prega di eseguire un backup della chiave per evitare perdite]",
    "assets_reduced_asset": "Asset ridotti",
    "assets_row_mining": "Mining a ordine aperto",
    "assets_swipe_direction": "Direzione del trasferimento",
    "assets_transfer": "Trasferimento",
    "assets_transfer_coin": "Moneta da trasferire",
    "assets_transfer_count": "Quantità da trasferire",
    "assets_transfer_currency": "Trasferimento di fondi",
    "assets_transfer_dialog_bb": "Account in criptovalute",
    "assets_transfer_dialog_fiat": "Account in valuta fiat",
    "assets_transfer_dialog_from": "Da",
    "assets_transfer_dialog_transfer_to": "Transfer to",
    "assets_transfer_dialog_wallet": "Wallet Account",
    "assets_transfer_record": "Transfer Record",
    "assets_transfer_to": "To",
    "assets_wallet": "Wallet",
    "assets_wallet_assets": "Wallet Assets",
    "assets_wallet_assets_transfer_tip": "*Only transfers to the corresponding account of wallet assets can be traded. Transfers between accounts incur no fees.",
    "assets_wallet_funds_flow": "Wallet Funds Flow",
    "at_least_one": "At least one trading password verification status must be enabled",
    "attention_blacklist": "Follow / Block",
    "attention_to_see": "Want to follow me? Click to view!",
    "audit_result": "Audit Result",
    "authenticated": "Authenticated",
    "authentication": "Authentication",
    "available_balance": "Available Balance",
    "average_purchase_price": "Average Purchase Price",
    "avoid_close_set": "Password-Free Setting",
    "back": "Back",
    "balance": "Balance",
    "bank_card": "Bank Card",
    "bank_name": "Bank Name",
    "bank_num": "Bank Card Number",
    "barcode_in_the_box_scan": "Place the QR code/barcode in the box for automatic scanning",
    "bb_assets": "Coin-Coin Assets",
    "bb_history_finished": "Completed",
    "bb_time": "Time",
    "bb_used": "Enabled",
    "be_usable": "Usable",
    "bear_consequences_your_investment": "I acknowledge and voluntarily bear the investment risks. My verification code is:",
    "become": "Become",
    "been_authenticated": "You have been verified",
    "beginner_guide_title": "World's Leading Digital Asset Trading Platform",
    "belong_level": "Belonging Level",
    "between_and": "Between",
    "bill": "Bill",
    "billing_details": "Billing Details",
    "bind_email": "Bind Email",
    "bind_google": "Google Authenticator",
    "bind_google_authenticator": "Bind Google Authenticator",
    "bind_phone": "Bind Phone",
    "branch_name": "Branch Name",
    "bug_order": "Buy Order",
    "buy": "Buy",
    "buy_1": "Buy",
    "buy_now": "Buy Now",
    "buy_quantity": "Purchase Quantity",
    "buy_rate": "Buy Fee Rate",
    "buyer": "Buyer",
    "buyer_has_paid_attention": "Buyer has paid attention",
    "buyer_payment": "Buyer has paid",
    "buyer_phone": "Buyer's Phone Number",
    "buyer_uid": "Buyer",
    "calculate": "Calculate",
    "calculations are for your reference only": "Calculations are for reference only",
    "can_available": "Available",
    "canceled": "Canceled",
    "cancellations": "Order Cancellations",
    "centre": "Center",
    "change1": "Change",
    "change_gesture_password": "Change Password",
    "change_google": "Change Google Authenticator",
    "change_pay_style": "Change Payment Method",
    "change_phone": "Change Phone",
    "charge_after_the_transaction": "Fees charged after transaction",
    "charge_out": "Fees must be paid by",
    "check_the_agree": "Agree to terms",
    "china": "China",
    "choose_picture": "Choose Picture",
    "clearing_time": "Clearing Time",
    "click_enter_batch_edit_page": "Click to enter batch edit page",
    "click_on_the_copy": "Click to copy",
    "clinch_a_deal": "Counterparty must have traded several times (0 means no limit)",
    "close": "Close",
    "closed_successfully": "Closed Successfully",
    "cloud_mining_pool": "Cloud Mining Pool",
    "coin_failure_0001": "Currency does not exist",
    "collect": "Favorites",
    "collection_time": "Collection Time",
    "commission": "Commission Earned Equivalent",
    "common_failure": "Failure!",
    "common_failure_0001": "Network Exception",
    "common_problem": "Operating Instructions",
    "common_success": "Success",
    "complete": "Completed",
    "completed": "Transaction Completed",
    "computer_login": "Computer Login",
    "confirm": "Confirm",
    "confirm_exit": "Confirm Exit?",
    "confirm_password": "Please confirm login password",
    "confirm_submission": "Confirm Submission",
    "confirm_the_purchase": "Confirm Purchase",
    "confirm_to_give_up": "Confirm to Give Up",
    "confirm_to_sell": "Confirm to Sell",
    "confirm_upgrade": "Confirm Upgrade",
    "confirm_vote": "Confirm Vote",
    "confirmation_information": "Confirmation Information",
    "confrim_delete_warn": "Confirm to Delete Warning?",
    "contact_service": "Contact Customer Service",
    "contact_us": "Contact Us",
    "contract_Order": "Contract Order",
    "contrct Agree Content": "To protect your rights, please read the 'BitMatrix Futures Contract Agreement' carefully before opening a contract.",
    "contrct Agree Content footer": "'BitMatrix Futures Contract Agreement'",
    "contrct Agree Content header": "To protect your rights, please read carefully before opening a contract",
    "contrct_decimal": "Decimal",
    "convert_into": "Convert to",
    "converted_currency": "Converted Currency",
    "copartner-upgrade-failure": "Upgrade Failed",
    "copartner-upgrade-no": "Upgrade conditions not met, cannot upgrade",
    "copartner-upgrade-ok": "Can Upgrade",
    "copartner-upgrade-success": "Upgrade Successful",
    "copy_content": "Copied to clipboard",
    "country": "Country",
    "create_time": "Creation Time",
    "cumulative_income:": "Cumulative Income:",
    "cumulative_participation": "Cumulative Participation",
    "currency": "Currency",
    "currency_fee": "Currency Fee Rate",
    "currency_information": "Currency Details",
    "currency_name": "Currency Name",
    "currency_not_found": "Currency type does not exist",
    "currency_select": "Currency Selection",
    "current_day_return": "Current Day Return",
    "current_entrust": "Current Entrust",
    "current_identity": "Current Identity",
    "current_polling_date": "Current Polling Date",
    "current_status": "Current Status",
    "currently_available": "Currently Available",
    "date": "Date",
    "date_of_birth": "Date of Birth",
    "day_highs_and_lows": "Daily Highs and Lows",
    "day_highs_and_lows1": "Daily Highs and Lows",
    "day_income": "Daily Income",
    "day_trading": "Same Day Trading",
    "daytime": "Daytime",
    "ddress_management": "Address Management",
    "deal_amount": "Total Transaction Amount",
    "deal_done": "Completed",
    "deal_gross": "Transaction/Total Volume",
    "deal_of_the_week": "Weekly Transactions",
    "deal_price": "Transaction Average Price",
    "deal_sum": "Transaction Amount",
    "deal_the_detail": "Order Details",
    "deal_volume": "Transaction Volume",
    "deduct_coin": "Deduct Currency",
    "default_settlement": "Default Settlement",
    "defaults": "Defaults",
    "deposit": "One-Click Deposit",
    "deposit_details": "Deposit Details",
    "deposit_income": "Deposit Income",
    "deposit_lock": "Deposit Lock",
    "deposit_lock_details": "Deposit Lock Details",
    "deposit_returned_principal": "Returned Principal",
    "depth": "Depth",
    "detailed_rules": "Detailed Rules",
    "details": "Details",
    "digits6": "Please enter 6 digits",
    "direct_market": "Direct Promotion",
    "direct_reward": "Direct Reward",
    "direction": "Direction",
    "discount_Rate": "Discount Rate",
    "discount_currency_introduction": "Currency Introduction",
    "discount_dialog_tip1": "Congratulations! You meet the",
    "discount_dialog_tip2": "participation conditions, and have been randomly selected as a lucky user. After qualification, you will have the right to participate!",
    "discount_dialog_tip3": "You are qualified for",
    "discount_dialog_tip4": "Redemption, limited quota, please participate within the activity time!",
    "discount_dialog_tip5": "After giving up qualification, you cannot participate",
    "discount_dialog_tip6": "in the activity and cannot apply for participation.",
    "discount_dialog_tip7": "Note: Successful qualification will deduct",
    "discount_purchase": "Discount Redemption",
    "discounted_or_original_price": "Discounted Price/Original Price",
    "dispose_result": "Disposal Result",
    "disqualification": "Qualification Abandoned",
    "divided_earnings": "Divided Earnings",
    "drag": "Please hold the slider and drag it to the far right",
    "each_input": "Verify transaction password for each transaction",
    "edt_selfchoose": "Edit Favorites",
    "effective": "Effective",
    "eligibility": "Eligibility",
    "email": "Email Registration",
    "email_address_format": "Please enter a valid email address",
    "email_code": "Email Verification Code",
    "empty_deal_pass": "Transaction password cannot be empty",
    "end time": "End Time",
    "end_date": "End Date",
    "end_time": "End Time",
    "enter_account": "Please enter phone/email",
    "enter_cause_of_complaint": "Please enter complaint reason",
    "enter_fb_buy_price": "Please enter purchase price",
    "enter_fb_sell_price": "Please enter selling price",
    "enter_your_password": "Please enter login password",
    "enter_password": "Please enter password",
    "entrust_amount": "Entrust Amount",
    "entrust_failure_0001": "Network Exception",
    "entrust_failure_0002": "Query Type Error",
    "entrust_failure_0003": "Matching Type cannot be empty",
    "entrust_failure_0004": "Merchant ID cannot be empty",
    "entrust_failure_0005": "User ID cannot be empty",
    "entrust_failure_0006": "User does not exist",
    "entrust_failure_0007": "Trading Pair ID cannot be empty",
    "entrust_failure_0009": "Trading Pair does not exist",
    "entrust_failure_0010": "Transaction password cannot be empty",
    "entrust_failure_0011": "Transaction password is incorrect",
    "entrust_failure_0012": "Order type cannot be empty",
    "entrust_failure_0013": "Order quantity cannot be empty",
    "entrust_failure_0014": "Limit order price cannot be empty",
    "entrust_failure_0015": "Order ID cannot be empty",
    "entrust_failure_0016": "Order does not exist",
    "entrust_failure_0017": "This order has been deleted",
    "entrust_failure_0018": "This order cannot be canceled",
    "entrust_failure_0019": "Version number cannot be empty",
    "entrust_failure_0020": "Market order cannot be canceled",
    "entrust_failure_0021": "Current data has been updated",
    "entrust_failure_0022": "There are no current orders, cannot place market order",
    "entrust_failure_0023": "Trading pair is disabled or related currencies are disabled",
    "entrust_failure_0024": "This trading pair has not opened for trading",
    "entrust_failure_0025": "Order price is below the minimum limit or above the maximum limit",
    "entrust_failure_0026": "Order quantity is below the minimum or above the maximum limit",
    "entrust_failure_0027": "This account has been banned from trading, please consult customer service",
    "entrust_failure_0030": "Exceeds the current trading pair's daily maximum increase of {}, cannot place order",
    "entrust_failure_0031": "Exceeds the current trading pair's daily maximum decrease of {}, cannot place order",
    "entrust_msg_match_type_001": "Limit Order",
    "entrust_msg_match_type_002": "Market Order",
    "entrust_msg_status_001": "Unfilled",
    "entrust_msg_status_002": "Partially Filled",
    "entrust_msg_status_003": "Fully Filled",
    "entrust_msg_status_004": "Canceled",
    "entrust_msg_type_001": "Sell Order",
    "entrust_msg_type_002": "Buy Order",
    "entrusted_price": "Entrusted Price",
    "entry_orders": "Pending Orders",
    "equal_principal": "Equal Principal",
    "error_email": "Email is incorrect",
    "error_network_anomaly": "Network anomaly",
    "error_phone": "Please enter a valid phone number",
    "error_unknow": "Network error",
    "event_detail": "Event Details",
    "event_detail_warn_tip": "*Warm reminder: Digital assets are innovative assets with high price volatility. Please make rational judgments and choose investment decisions carefully!",
    "event_start_time": "Event Start Time",
    "every day": "Every Day",
    "everyday": "Every Day",
    "exchange": "Instant Exchange",
    "exchange_currency": "Exchange Currency",
    "exchange_details": "Instant Exchange Details",
    "exchange_history": "Instant Exchange History",
    "exchange_quantity": "Exchange Quantity",
    "exchange_rules": "Exchange Rules",
    "exchangeable": "Exchangeable",
    "exclusive_posters": "Generate Exclusive Posters",
    "exclusive_posters-Video": "Generate Posters",
    "exit": "Exit",
    "expected_return": "Expected Return",
    "expired": "Expired",
    "express_area": "Quick Area",
    "face_recognition": "Face Recognition",
    "failure": "Failure",
    "fb_lookup": "Click to View",
    "fb_order_number": "Order Number",
    "feeze_thaw_msg_status_0001": "Freezing",
    "feeze_thaw_msg_status_0002": "Unfrozen",
    "fill_in_at_least_one": "Please fill in at least one item",
    "fill_wrong": "Incorrect entry may lead to asset loss, please verify carefully",
    "filtrate": "Filter",
    "filtrate_content": "Filter payment method, country, fiat currency here!",
    "financial_management_is_cancel": "Deposit income has been canceled! Please do not submit again!",
    "financial_management_is_delete": "This financial type has been deleted",
    "financial_management_is_disable": "This financial type has been disabled",
    "financial_management_not_cancel": "Regular investment cannot be canceled!",
    "financial_management_number_not_found": "Investment quantity cannot be empty",
    "financial_management_record_add_failure": "User's personal assets are insufficient, please deposit before investing!",
    "financial_management_record_less_than_min_number": "The added quantity is less than the set minimum quantity {}",
    "financial_management_type_not_found": "Financial record information display failed, incorrect financial type selection!",
    "financial_management_type_not_null": "Investment type cannot be empty",
    "find_password": "Retrieve Password",
    "fingerprint": "Fingerprint",
    "fingerprint_verification": "Fingerprint Verification",
    "first_bind_phone": "Please bind your phone number first",
    "first_period": "First Period",
    "following_account": "To the following account",
    "free": "Free of Charge",
    "Takerfree": "Taker Fee",
    "Makerfree": "Maker Fee",
    "freeze": "Freeze",
    "freeze_to_be_processed": "Appeal freeze, awaiting processing",
    "fuc_use_discount": "Use {} to pay trading fees * discount",
    "futu": "Sub-graph",
    "gathering": "Collection",
    "gears": "Gears",
    "generation1": "1st Generation",
    "gesture_error_limit": "Gesture password input error limit reached",
    "gesture_password": "Gesture Password",
    "gesture_unlock": "Gesture Unlock",
    "get_code": "Get Verification Code",
    "goSign": "Go to Register",
    "go_change": "Go to Modify",
    "go_login": "Please log in first",
    "go_pay": "Go to Pay",
    "go_setting": "Go to Settings",
    "go_to_pick_up": "Go to Collect",
    "go_to_receiving": "Go to Receive",
    "go_to_redeem": "Go to Redeem",
    "google_verification_code": "Google Verification Code",
    "grievance_freeze": "Appeal Freeze",
    "has_been_on": "Has been listed",
    "have_been_frozen": "Has been frozen",
    "have_received": "Has been received",
    "help_center": "Help Center",
    "hide_canceled": "Hide Canceled",
    "hide_other": "Show All Pending Orders",
    "high_frequency_partner": "High-Frequency Partner",
    "highest_single": "Highest Single",
    "highfrequency_fee_sharing": "High Frequency Fee Sharing",
    "hint": "Warm Reminder",
    "hint_password": "Please enter password",
    "hint_phone_email": "Please enter phone/email",
    "historical_transaction": "Historical Transactions",
    "history": "History",
    "home_page": "Home Page",
    "horizontal_version": "Horizontal Version",
    "hours": "Hours",
    "i18n_failure_0001": "System language query failed",
    "i18n_failure_0002": "Country dictionary query failed",
    "iTo_draw_again": "Please enter password again",
    "i_see": "I understand",
    "i_see1": "Got it",
    "id_card_back_direction_failure": "ID card reverse image direction error",
    "id_card_front_direction_failure": "ID card front image direction error",
    "id_cord": "Document Number",
    "id_type": "Document Type",
    "identification": "Identification Failed",
    "identity_authentication": "Identity Authentication",
    "identity_card": "Identity Card",
    "if_at": "If at",
    "ignore": "Ignore",
    "immediately_invited": "Invite Immediately",
    "immediately_join": "Join Immediately",
    "in_trading": "In Trading",
    "income": "Income",
    "income_currency_type": "Deposit Currency Type",
    "income_deposit_principal": "Deposit Principal",
    "incomeing": "Generating Income",
    "increment quantity": "Input quantity cannot exceed the maximum increment",
    "indirect_promotion": "Indirect Promotion",
    "indirect_reward": "Indirect Reward",
    "individual_selling_tips": "Cannot sell to your own order",
    "input_Branch_address": "Please enter branch address",
    "input_address_labels": "Please enter labels",
    "input_alipay_account": "Please enter Alipay account",
    "input_bank_card_number": "Please enter bank card number",
    "input_bank_name": "Please enter bank name",
    "input_buyer_uid": "Please enter buyer UID",
    "input_currency_referred": "Please enter currency abbreviation",
    "input_deposit_amount": "Please enter deposit quantity",
    "input_email": "Please enter email",
    "input_email_code": "Please enter email verification code",
    "input_id_cord": "Please enter document number",
    "input_lock_amount": "Please enter lock quantity",
    "input_mention_money_address": "Please enter withdrawal address",
    "input_money": "Please enter amount",
    "input_name": "Please enter name",
    "input_newphone_code": "Please enter new phone number",
    "input_nike_name": "Please enter nickname",
    "input_old_google_code": "Please enter old Google verification code",
    "input_old_pass": "Please enter old password",
    "input_paypal_acctount": "Please enter PayPal account",
    "input_phone": "Please enter phone number",
    "input_phone_code": "Please enter verification code",
    "input_price": "Please enter price",
    "input_quantity": "Please enter quantity",
    "input_remark": "Please enter remark",
    "input_service_charge": "Please enter service charge",
    "input_trading_volume": "Please enter trading volume",
    "input_transaction_password": "Please enter transaction password",
    "input_wechat_account": "Please enter WeChat account",
    "input_wire_transfer": "Please enter wire transfer address",
    "inputs_numbers_greater_can_number": "Input quantity greater than transferable quantity",
    "insufficient_light": "Insufficient light",
    "interest": "Interest",
    "invitation_details": "Invitation Reward Details",
    "invitation_register_ad_not_found": "App share invitation registration ad temporarily does not exist",
    "invitation_register_ad_tatle_not_found": "App share invitation registration ad title temporarily does not exist",
    "invitation_register_coin_not_found": "App share invitation registration reward currency temporarily does not exist",
    "invitation_register_logo_not_found": "App share invitation registration logo temporarily does not exist",
    "invitation_register_not_found": "Share invitation registration link temporarily does not exist",
    "invite_code": "Invitation Code (Optional)",
    "invite_code1": "Invitation Code",
    "invite_commission": "Commission",
    "invite_gift": "Invitation Gift",
    "invite_reward": "Invitation Reward",
    "invited_UID": "Invited UID",
    "invitees_are_available": "Inviter can receive",
    "inviter-is-user": "The refilled inviter cannot be yourself",
    "inviter-not-exist": "The refilled inviter does not exist",
    "inviter_no_null": "Referrer cannot be empty",
    "ios_24小时排行榜": "24 Hour Leaderboard",
    "ios_OTC交易须知": "OTC Trading Notes",
    "ios_PAYPAL": "PAYPAL",
    "ios_otc_order_prompt_001": "If you do not click 'Confirm Payment' within minutes, the system will automatically cancel this order after the countdown ends",
    "ios_otc_order_prompt_002": "If you do not pay the seller within minutes and click 'Confirm Payment', the system will automatically cancel this order after the countdown ends",
    "ios_万": "Ten Thousand",
    "ios_上传身份证反面": "Upload ID Card Back",
    "ios_上传身份证正面": "Upload ID Card Front",
    "ios_两次密碼不一致请再次输入密碼": "The two passwords do not match, please enter the password again",
    "ios_个确认大约需要": "Confirmation will take approximately",
    "ios_中国大陆": "Mainland China",
    "ios_中级合伙人": "Intermediate Partner",
    "ios_主版块": "Main Section",
    "ios_之间": "Between",
    "ios_买单": "Buy Order",
    "ios_买方必须成交过几次(0等于不限制)": "Buyer must have completed a certain number of transactions (0 means no limit)",
    "ios_二维码/条形码": "QR Code/Barcode",
    "ios_付款": "Payment",
    "ios_充币地址": "Deposit Address",
    "ios_先设置交易密码": "Please set your trading password first",
    "ios_先设置交易密碼": "Please set your trading password first",
    "ios_关闭照明": "Turn Off Lighting",
    "ios_分享失败": "Share Failed",
    "ios_分享成功": "Share Successful",
    "ios_初级合伙人": "Junior Partner",
    "ios_卖单": "Sell Order",
    "ios_卖家已付款提示": "Seller has made payment",
    "ios_卖方必须成交过几次(0等于不限制)": "Seller must have completed a certain number of transactions (0 means no limit)",
    "ios_去解绑": "Go to Unbind",
    "ios_发布成功": "Published Successfully",
    "ios_发送成功": "Sent Successfully",
    "ios_各种费率说明": "Rate Descriptions",
    "ios_团队人数不足": "Insufficient Team Members",
    "ios_复制QQ号": "Copy QQ Number",
    "ios_复制地址": "Copy Address",
    "ios_如果你已完成线下付款，请点击下方已付款按钮。如果未完成付款，却点击已付款将会受到严厉惩罚，甚至永久冻结账户！": "If you have completed offline payment, please click the 'Paid' button below. If you click 'Paid' without completing payment, you will face severe penalties, including permanent account freeze!",
    "ios_客户端或在线钱包将您需要充值的": "The client or online wallet will show you the amount you need to recharge",
    "ios_客户端或在线钱包将您需要充幣的": "The client or online wallet will show you the amount you need to deposit",
    "ios_客服": "Customer Service",
    "ios_密碼设置成功！": "Password Set Successfully!",
    "ios_将该地址设置为认证地址": "Set this address as the authentication address",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充值，不影响到账。\n3、最小充值金额": "Hours required, please be patient.\n2. The same address can be recharged multiple times without affecting the receipt.\n3. Minimum recharge amount",
    "ios_小时时间，请耐心等待。\n2、同一个地址可多次充幣，不影响到账。\n3、最小充幣金额": "Hours required, please be patient.\n2. The same address can be recharged multiple times without affecting the receipt.\n3. Minimum deposit amount",
    "ios_已付款": "Paid",
    "ios_币币": "Currency to Currency",
    "ios_常见问题": "Frequently Asked Questions",
    "ios_开启照明": "Turn On Lighting",
    "ios_意见反馈": "Feedback",
    "ios_成交": "Transaction Completed",
    "ios_我的": "My",
    "ios_手机号错误": "Incorrect Phone Number",
    "ios_提交成功，等待认证": "Submission Successful, Awaiting Verification",
    "ios_提币数量不能大于手续费": "Withdrawal amount cannot exceed the fee",
    "ios_提币数量减去手续费后不能": "Withdrawal amount minus the fee cannot be",
    "ios_收款二维码": "Receiving QR Code",
    "ios_收藏成功": "Saved Successfully",
    "ios_数目发送到该地址。发送完成后，系统会自动在此交易获得": "Amount sent to this address. Once completed, the system will automatically record this transaction",
    "ios_暂无网络": "No Network Available",
    "ios_最多可锁": "Maximum Lockable",
    "ios_最大限额": "Maximum Limit",
    "ios_最小限额": "Minimum Limit",
    "ios_未解锁": "Not Unlocked",
    "ios_没有中国大陆身份证的用户请通过网站版高级认证": "Users without a Mainland China ID card, please use the website for advanced verification",
    "ios_法币": "Fiat Currency",
    "ios_注册表示同意": "Registration indicates agreement",
    "ios_注册表示同意用户协议": "Registration indicates agreement to the user agreement",
    "ios_登录过期，请重新登录": "Login expired, please log in again",
    "ios_直接推荐人uid": "Direct Referrer UID",
    "ios_直推人数不足": "Insufficient Direct Referrals",
    "ios_绑定成功": "Binding Successful",
    "ios_请上传您的身份证": "Please upload your ID card",
    "ios_请上传身份证反面照": "Please upload a photo of the back of your ID card",
    "ios_请上传身份证正面照": "Please upload a photo of the front of your ID card",
    "ios_请先绑定收款方式": "Please bind a payment method first",
    "ios_请先设置支付方式": "Please set a payment method first",
    "ios_请输入价格和数量": "Please enter price and quantity",
    "ios_请输入新邮箱": "Please enter a new email",
    "ios_请输入正确的账号格式": "Please enter a valid account format",
    "ios_请输入身份证号": "Please enter ID card number",
    "ios_请输入限额": "Please enter the limit",
    "ios_请通过": "Please pass through",
    "ios_资产": "Assets",
    "ios_超时": "Timeout",
    "ios_身份证号": "ID Card Number",
    "ios_退出成功": "Logout Successful",
    "ios_邀请人可获得 * 的佣金": "Referrer can earn * commission",
    "ios_锁仓数量不能为空": "Lock quantity cannot be empty",
    "ios_间接接推荐人uid": "Indirect Referrer UID",
    "ios_隐藏其他挂单": "Hide Other Orders",
    "ios_高级合伙人": "Senior Partner",
    "is_bind": "Bound",
    "is_delete_select": "Are you sure you want to delete the selection?",
    "issued": "Issued",
    "issued_to_lock": "Issued to Lock",
    "k_close": "Close",
    "k_max": "High",
    "k_min": "Low",
    "k_num": "Amount",
    "k_open": "Open",
    "kline_target": "Indicator",
    "language": "Language",
    "lead_content": "Order inquiries, ad publishing, payment settings, follow/blacklist have all moved here!",
    "least_four": "Please connect at least 4 points",
    "less-than-min": "Below minimum lock amount limit",
    "lf there are pending": "If there are pending limit orders in the opposite direction, they will be canceled before full liquidation.",
    "limit": "Limit Settings",
    "limit_orderAA": "Limit Order",
    "limit_set": "Impostazioni limite (opzionali)",
    "limited_price": "Prezzo limite",
    "liquidation": "Liquidazione",
    "liquidation AMT": "Quantità di liquidazione",
    "liquidation details": "Dettagli liquidazione",
    "loading": "Caricamento",
    "lock-expire": "Scadenza del blocco",
    "lock_amount": "Quantità bloccata",
    "lock_duration": "Durata del blocco",
    "lock_person": "Persona che blocca",
    "lock_record": "Registro di blocco",
    "lock_remaining": "Rimanente bloccato",
    "lock_reward": "Ricompensa di blocco",
    "lock_time": "Tempo di blocco",
    "locked": "Bloccato",
    "locked_closed": "Chiuso",
    "locked_insufficient_balance": "Saldo insufficiente",
    "locked_settled": "Saldato",
    "locked_unsettlement": "Non saldato",
    "locked_warehouse": "In blocco",
    "log_in": "Accesso",
    "login": "Accesso",
    "login_expired": "Accesso scaduto, riprovare.",
    "login_forget_pwd": "Hai dimenticato la password?",
    "login_name_txt": "Nome utente",
    "login_pass": "Password di accesso",
    "logion_welcome_txt": "Benvenuto nell'accesso",
    "mail": "Email",
    "mailbox": "Verifica email",
    "mailbox_bind_success": "Email collegata con successo",
    "mailbox_new": "Email",
    "main_picture": "Immagine principale",
    "manage_reward_details": "Dettagli gestione ricompense",
    "management_reward": "Gestione ricompense",
    "management_share": "Condivisione gestione",
    "mandarin": "Leggi i numeri in mandarino",
    "mandarin_reading_content": "Leggi il contenuto seguente in lingua standard",
    "manual_recharge_msg_status_0001": "In attesa di ricarica",
    "manual_recharge_msg_status_0002": "Approvato",
    "manual_recharge_msg_status_0003": "Ricarica riuscita",
    "manual_recharge_msg_type_0001": "Ricarica normale",
    "manual_recharge_msg_type_0002": "Elaborazione fondi",
    "manual_recharge_msg_type_0003": "Regalo promozionale",
    "margin": "Margine",
    "market_failure_00001": "Errore nel recupero della home",
    "market_failure_00002": "ID commerciante non esiste",
    "market_failure_00003": "Nessuna area disponibile per il commerciante",
    "market_failure_00004": "Errore nel recupero dei dati di trading",
    "market_failure_00005": "Nome della coppia di trading non valido",
    "market_failure_00006": "Nessuna informazione disponibile per il commerciante",
    "market_failure_00007": "Errore nel recupero delle informazioni della coppia di trading",
    "market_failure_00008": "Errore nel recupero dei dati K-line",
    "market_failure_00009": "Errore nel recupero dei dati di profondità",
    "market_failure_00010": "Errore nei parametri della richiesta",
    "market_failure_00011": "Coppia di trading non valida",
    "market_low_price": "Prezzo massimo di acquisto di mercato",
    "market_order": "Ordine a mercato",
    "market_price": "Prezzo di mercato",
    "market_sell_price": "Prezzo minimo di vendita di mercato",
    "market_switch_moved_here": "Passaggio tra orizzontale/verticale spostato qui",
    "match": "Corrispondenza",
    "match_the_number_of": "Numero di corrispondenza",
    "max_lock_volume": "Massimo volume bloccato",
    "maximum_30_characters": "Massimo 30 caratteri",
    "maximum_coins": "Quantità massima di prelievo",
    "maximum_withdrawal": "Massimo prelievo per transazione",
    "mention_a_currency": "Quantità di prelievo",
    "min_lock_volume": "Minimo volume bloccato",
    "min_recharge_amount": "Importo minimo per la ricarica",
    "minhour": "Minuti",
    "minimum_coins": "Quantità minima di prelievo",
    "minimum_purchase_quantity": "Quantità di acquisto maggiore della rimanente",
    "minimum_quantity_sold": "Quantità di vendita maggiore della rimanente",
    "minutes": "Minuti",
    "mobile phone": "Numero di cellulare",
    "money": "Importo",
    "more": "Di più",
    "more Or than": "Long: valore * numero * prezzo di chiusura - valore * numero * prezzo di apertura\n\nShort: valore * numero * prezzo di apertura - valore * numero * prezzo di chiusura",
    "more-than-max": "Superato il limite massimo di blocco",
    "more11": "Di più",
    "more_can_save": "Massimo depositabile",
    "more_home": "Di più",
    "msg_camera_framework_bug": "Abilitare i permessi della fotocamera",
    "must_retain_one": "Devi mantenere almeno uno tra cellulare, Google, email",
    "my_income": "Il mio guadagno",
    "my_prompt_001": "*Dopo aver cambiato il numero di cellulare, utilizzare il nuovo numero per accedere.",
    "my_prompt_002": "*Nota: utilizzare solo un conto reale. I metodi di pagamento attivati saranno mostrati all'acquirente in OTC, fino a 5 ma attivabili solo 3.",
    "my_prompt_003": "*Dopo aver cambiato l'email, utilizzare la nuova email per accedere.",
    "my_vote": "Il mio voto",
    "name": "Nome",
    "name_empty": "Il nome non può essere vuoto",
    "nationality": "Nazionalità",
    "need_me_record_video": "Video da registrare personalmente",
    "never_enter": "Non inserire mai la password di trading",
    "new_address": "Nuovo indirizzo",
    "new_pass": "Nuova password",
    "new_pass_empty": "Inserire la nuova password",
    "new_pass_minimum": "La password deve avere almeno 6 caratteri",
    "new_report_statistics": "Nuova statistica report",
    "newest_versions": "La versione attuale è già l'ultima!",
    "next": "Passo successivo",
    "nick_name": "Nickname",
    "nickname_des_txt": "Il nickname può contenere solo lettere e numeri, massimo 15 caratteri",
    "nighttime": "Notturno",
    "ninety_days": "Ultimi 90 giorni",
    "no-lock-detail": "Nessuna informazione di blocco",
    "no_Senior_certification": "Effettuare prima la verifica avanzata",
    "no_data": "Nessun dato disponibile",
    "no_flash": "Dispositivo senza flash, impossibile attivare la luce",
    "no_more_data": "Nessun altro dato",
    "no_photo_taking": "Il tuo dispositivo non supporta la fotocamera",
    "no_price": "Il prezzo non può essere vuoto",
    "no_quantity": "Inserire una quantità corretta",
    "no_realname_auth": "Effettuare prima la verifica dell'identità",
    "no_use": "Non attivato",
    "none": "Nessuno",
    "not_bound": "Non collegato",
    "not_frequency_partner": "Non sei ancora un partner",
    "not_redemption": "Nessun diritto di riscatto",
    "not_started": "Non iniziato",
    "not_to_issue": "Non emesso",
    "not_trading": "Questa criptovaluta non può essere scambiata",
    "notpass": "Verifica non superata",
    "null_address_tag": "L'etichetta dell'indirizzo non può essere vuota",
    "null_code": "Il codice di verifica non può essere vuoto",
    "null_email": "L'email non può essere vuota",
    "null_password": "La password non può essere vuota",
    "null_phone": "Il numero di telefono non può essere vuoto",
    "number": "Numero",
    "number_empty": "La quantità non può essere 0",
    "number_of_arrivals": "Numero di arrivi",
    "number_of_issues": "Quantità totale emessa",
    "number_of_withdrawal": "Numero di prelievi giornalieri",
    "obtained": "Ritirato",
    "occlusion": "Ostruzione del viso",
    "occupy": "Occupato",
    "official_community": "Comunità ufficiale",
    "official_verification_channel": "Canale di verifica",
    "old_pass": "Vecchia password",
    "old_pass_empty": "Inserire la vecchia password",
    "on_sale": "In vendita",
    "online_service": "Servizio clienti online",
    "open XKD perpetual swap": "Attiva il contratto perpetuo BitMatrix",
    "open perpetual swap": "Attiva contratto perpetuo",
    "open_app": "Apri l'app",
    "open_it": "Apri",
    "open_successfully": "Apertura riuscita",
    "open_touchID": "Attivare TouchID?",
    "opposite_side_uid": "Controparte",
    "option": "Opzione",
    "optional": "Opzionale",
    "optional_area": "Area opzionale",
    "options": "Modalità di selezione",
    "order": "Ordine",
    "order time": "Per tempo di ordine",
    "order time1": "Tempo di ordine",
    "order_a_complaint": "Rivolgi un reclamo",
    "order_details": "Dettagli ordine",
    "order_form": "Modulo d'ordine",
    "order_history": "Storico ordini",
    "order_inquiry": "Richiesta ordine",
    "order_number": "Numero d'ordine",
    "order_time": "Tempo di emissione ordine",
    "ordinary_partner": "Partner ordinario",
    "otc_account": "Account",
    "otc_confirm_Put_the_coin": "Conferma di aver ricevuto il pagamento dalla controparte! (Attenzione: non confermare prima di ricevere il pagamento, contattare il servizio clienti in caso di dispute)",
    "otc_confirm_the_payment": "Conferma pagamento",
    "otc_confirm_the_receipt": "Conferma ricezione",
    "otc_failure_0001": "Account disabilitato, contattare il servizio clienti",
    "otc_failure_0002": "Account non verificato, impossibile effettuare operazioni OTC",
    "otc_failure_0003": "Password di trading errata",
    "otc_failure_0004": "ID ordine non valido",
    "otc_failure_0006": "Errore nel cambio di stato",
    "otc_failure_0009": "Tipo di ordine non esistente",
    "otc_failure_0010": "Annullamento fallito",
    "otc_failure_0011": "Ordine già completato o annullato",
    "otc_failure_0012": "Utente non è un commerciante OTC, senza diritti per questa funzione",
    "otc_failure_0013": "Sblocco fallito, annullamento fallito",
    "otc_failure_0015": "Tipo di ordine non esistente",
    "otc_failure_0016": "Quantità d'ordine non può essere minore o uguale a 0",
    "otc_failure_0017": "Prezzo di transazione non può essere minore o uguale a 0",
    "otc_failure_0018": "Limite minimo per ordine non può essere minore di 0",
    "otc_failure_0019": "Limite massimo per ordine non può essere minore del minimo",
    "otc_failure_0020": "Valuta fiat non supportata",
    "otc_failure_0021": "Valuta fiat attualmente non disponibile",
    "otc_failure_0022": "ID valuta del commerciante errato",
    "otc_failure_0023": "Limite minimo per ordine non può essere minore di {}",
    "otc_failure_0024": "Limite massimo per ordine non può essere maggiore di {}",
    "otc_failure_0025": "Quantità ordine ha decimali superiori a {}",
    "otc_failure_0026": "Importo totale ordine non può essere minore di {}",
    "otc_failure_0027": "Importo totale ordine non può superare il limite utente di {}",
    "otc_failure_0028": "Importo totale ordine non può superare il limite commerciante di {}",
    "otc_failure_0029": "Prezzo non può essere minore di {}",
    "otc_failure_0030": "Prezzo non può essere maggiore di {}",
    "otc_failure_0031": "Utente non ha account di pagamento collegato",
    "otc_failure_0032": "Blocco fallito, ordine fallito",
    "otc_failure_0033": "Max numero di ordini simultanei non può essere minore di 0",
    "otc_failure_0034": "Numero di transazioni minime per l'acquirente non può essere minore di 0",
    "otc_failure_0035": "Utente già certificato come commerciante o in fase di verifica",
    "otc_failure_0036": "Numero di transazioni OTC di successo insufficienti",
    "otc_failure_0037": "Sblocco fallito, annullamento fallito",
    "otc_failure_0038": "Saldo disponibile insufficiente in {}: {}",
    "otc_failure_0039": "Nessuna valuta attuale",
    "otc_failure_0040": "Valuta attuale non disponibile",
    "otc_failure_0041": "Invio riuscito, attendere la verifica",
    "otc_failure_0042": "Utente attualmente in attesa di verifica, non può ripetere la richiesta",
    "otc_failure_0043": "Tipo di transazione non disponibile",
    "otc_failure_0044": "Autocommercio vietato",
    "otc_failure_0046": "Numero massimo di ordini contemporanei {} raggiunto, riprovare più tardi",
    "otc_failure_0047": "Il compratore deve aver completato {} transazioni",
    "otc_failure_0048": "Quantità di acquisto non può essere minore o uguale a 0",
    "otc_failure_0049": "Quantità di acquisto non può superare la quantità rimanente",
    "otc_failure_0050": "ID ordine errato",
    "otc_failure_0051": "Importo totale di acquisto non può essere inferiore al minimo {}",
    "otc_failure_0052": "Importo totale di acquisto non può superare il massimo {}",
    "otc_failure_0053": "Tipo di ordine attuale non è vendita",
    "otc_failure_0054": "Stato dell'ordine non corretto",
    "otc_failure_0055": "Importo di acquisto non può essere inferiore al minimo per singolo ordine {}",
    "otc_failure_0056": "Importo di acquisto non può superare il massimo per singolo ordine {}",
    "otc_failure_0057": "Nessuno stato disponibile",
    "otc_failure_0058": "Numero di modifiche all'ordine non corretto",
    "otc_failure_0059": "Blocco fallito, prelievo fallito",
    "otc_failure_0060": "Collegare un account di pagamento specifico nel settaggio!",
    "otc_failure_0061": "Il venditore deve aver completato {} transazioni",
    "otc_failure_0062": "Quantità di vendita non può essere minore o uguale a 0",
    "otc_failure_0063": "Quantità di vendita non può superare la quantità rimanente",
    "otc_failure_0064": "Tipo di ordine attuale non è acquisto",
    "otc_failure_0065": "Importo totale di vendita non può essere inferiore al minimo {}",
    "otc_failure_0066": "Importo totale di vendita non può superare il massimo {}",
    "otc_failure_0067": "Importo di vendita non può essere inferiore al minimo per singolo ordine {}",
    "otc_failure_0068": "Importo di vendita non può superare il massimo per singolo ordine {}",
    "otc_failure_0069": "ID account di pagamento errato",
    "otc_failure_0070": "Conferma pagamento fallita",
    "otc_failure_0073": "Cambio stato ordine fallito",
    "otc_failure_0074": "Trasferimento valuta fallito, conferma ricezione fallita",
    "otc_failure_0075": "Metodo di pagamento non può essere vuoto",
    "otc_failure_0076": "Invio SMS fallito {}",
    "otc_failure_0077": "Valuta di transazione non esistente",
    "otc_failure_0078": "Moneta fiat non esistente",
    "otc_failure_0079": "Cambio stato ordine fallito",
    "otc_failure_0080": "Valuta di transazione disabilitata",
    "otc_failure_0081": "Moneta fiat disabilitata",
    "otc_failure_0082": "Ordine non esistente",
    "otc_failure_0083": "OTC attualmente non ha valuta fiat configurata",
    "otc_failure_0084": "OTC attualmente non ha valuta di transazione configurata",
    "otc_failure_0085": "Quantità rimanente dell'ordine insufficiente",
    "otc_failure_0090": "Account bloccato per trading OTC, contattare il supporto",
    "otc_failure_0091": "Utenti normali non possono creare ordini OTC, richiedere status commerciante",
    "otc_failure_0092": "Pagamento non scaduto, compratore non può contestare",
    "otc_failure_0093": "Ordine già contestato, contattare il supporto",
    "otc_failure_0094": "Operazione non consentita secondo la normativa locale",
    "otc_failure_0095": "Ordine non disponibile per il trading",
    "otc_failure_0096": "Patrimonio dell'account venditore anomalo, operazione non consentita",
    "otc_failure_0097": "Numero massimo di cancellazioni OTC giornaliere superato, trading vietato",
    "otc_failure_0098": "Hai ordini incompleti, completali prima di procedere.",
    "otc_margin_certification": "Certificazione del margine",
    "otc_mine_account_paid": "Ho effettuato il pagamento",
    "otc_order_prompt_003": "Se il compratore non effettua il pagamento entro il termine e clicca su 'Ho pagato', l'ordine sarà automaticamente annullato al termine del countdown",
    "otc_picture_no_null": "Carica almeno un'immagine",
    "otc_prompt_001": "1. La validità del pagamento è di 30 minuti, paga rapidamente e clicca 'Ho pagato'.\n2. La valuta del venditore è bloccata e custodita dal sistema, ordina in sicurezza.",
    "otc_rate": "Commissione",
    "otc_report_order": "Ordine completato",
    "otc_success_0001": "Annullamento riuscito",
    "otc_success_0002": "Vendita riuscita",
    "otc_success_0003": "Acquisto riuscito",
    "otc_success_0004": "Conferma pagamento riuscita",
    "otc_success_0005": "Conferma ricezione riuscita, ordine completato",
    "otc_success_0006": "Contestazione riuscita",
    "otc_waiting_for_confirmation": "In attesa di conferma dal venditore",
    "other_settings": "Altre impostazioni",
    "overtime_pay": "Tempo scaduto, ordine annullato",
    "param-error": "Errore nei parametri",
    "partner": "Partner",
    "partner_commission": "I partner guadagnano commissioni dai loro sottoposti",
    "partner_commission_fee": "Commissione di servizio",
    "partner_current_level": "Livello attuale",
    "partner_get_all_subordinate": "Può ottenere da tutti i sottoposti",
    "partner_management": "Gestione partner",
    "partner_need_pay": "Pagamento richiesto",
    "partner_need_recommend": "Richiesta di raccomandazione diretta",
    "partner_recommended": "Raccomandato",
    "partner_start_and_end_time": "Data di inizio e fine",
    "partner_team": "Team",
    "partner_team_member": "Numero di membri del team richiesto",
    "partner_upgrade_immediately": "Aggiorna subito",
    "pass_muster": "Se non soddisfa i requisiti, clicca qui",
    "passed": "Superato",
    "passport": "Passaporto",
    "password_disagree": "Le due password non corrispondono",
    "password_is_different": "Le password inserite non corrispondono",
    "password_text": "Password",
    "password_wrong": "Imposta una password di 8-20 caratteri",
    "past_activities": "Attività precedenti",
    "pause_recharge": "Questa valuta non può essere ricaricata",
    "pause_withdraw": "Questa valuta non può essere prelevata",
    "pay_number": "Quantità di pagamento",
    "pay_wey": "Metodo di pagamento",
    "payee": "Beneficiario",
    "payfor_type": "Tipo di pagamento",
    "payment": "Pagamento",
   "payment_amount": "Importo del pagamento",
    "payment_currency": "Valuta di pagamento",
    "payment_has_been": "(Ho pagato)",
    "payment_term": "Metodo di pagamento",
    "paypal_account": "Account PayPal",
    "pending_review": "In attesa di revisione",
    "people": "Persone",
    "period": "Periodo",
    "personal_detail": "Dettagli personali",
    "personal_purchase_tips": "Non puoi acquistare il tuo ordine",
    "phone": "Telefono",
    "phone_code": "Codice di verifica telefono",
    "phone_register": "Registrazione telefono",
    "phone_verification": "Verifica SMS",
    "phone_verify": "Verifica telefono",
    "photo_album": "Album fotografico",
    "please_enter": "Si prega di inserire",
    "please_enter_abbreviation_or_letter": "Si prega di inserire contenuto di ricerca",
    "please_input_content": "Si prega di inserire il contenuto",
    "please_pay": "Si prega di pagare",
    "please_verification": "Si prega di verificare",
    "pop_Date": "Data",
    "pop_reset": "Ripristina",
    "position at market price?": "Chiudere la posizione al prezzo di mercato?",
    "post_only": "Solo Maker (Post only)",
    "price": "Prezzo",
    "price_cannot_be_less": "Il prezzo non può essere inferiore a {}",
    "price_cannot_be_more": "Il prezzo non può superare {}",
    "price_empty": "Il prezzo non può essere 0",
    "principal": "Capitale",
    "promote_links": "Link promozionali",
    "promotion_statistical": "Statistiche promozionali",
    "prtner_invalid": "Non valido",
    "publish_a_trade_order": "Pubblica un ordine di scambio",
    "purchase": "Acquisto",
    "push": "PUSH risorse",
    "push_maximum_volume": "La quantità PUSH supera il saldo disponibile {}",
    "push_minimum_trading_volume": "La quantità PUSH è inferiore al volume minimo {}",
    "quantity": "Quantità",
    "quantity_deal": "Quantità/Transazione",
    "quota": "Limite",
    "range": "Intervallo",
    "real_income": "Guadagno reale",
    "real_name_authentication": "Verifica dell'identità",
    "reason": "Motivo",
    "rebate_amount": "Importo del rimborso",
    "rebate_record": "Registro dei rimborsi",
    "rebate_reward": "Premio rimborso",
    "receiving_set": "Impostazioni di ricezione",
    "receiving_set_title": "Imposta prima il metodo di ricezione",
    "recently_deal1": "Ultimi scambi",
    "recharge_notice": "Avviso di ricarica\n1. Inviare la quantità di {} necessaria all'indirizzo tramite il client {} o portafoglio online. Dopo l'invio, il sistema accrediterà automaticamente la valuta virtuale sul tuo account dopo {} conferme. Attendere pazientemente.\n2. È possibile ricaricare più volte allo stesso indirizzo senza influire sul saldo.\n3. Importo minimo di ricarica {}.",
    "recharge_source": "Fonte di ricarica",
    "recharge_withdraw_failure_0001": "Problema di rete",
    "recharge_withdraw_failure_0002": "La password di transazione non può essere vuota",
    "recharge_withdraw_failure_0003": "Il codice di verifica non può essere vuoto",
    "recharge_withdraw_failure_0004": "ID commerciante non può essere vuoto",
    "recharge_withdraw_failure_0005": "ID utente non può essere vuoto",
    "recharge_withdraw_failure_0006": "Utente non esistente",
    "recharge_withdraw_failure_0007": "ID valuta non può essere vuoto",
    "recharge_withdraw_failure_0009": "Valuta non esistente",
    "recharge_withdraw_failure_0010": "Il codice di verifica di Google non può essere vuoto",
    "recharge_withdraw_failure_0011": "Password di transazione errata",
    "recharge_withdraw_failure_0012": "Tipo di ricarica non può essere vuoto",
    "recharge_withdraw_failure_0013": "Quantità di ricarica non può essere vuota",
    "recharge_withdraw_failure_0014": "Nota di ricarica non può essere vuota",
    "recharge_withdraw_failure_0015": "ID di ricarica non può essere vuoto",
    "recharge_withdraw_failure_0016": "Ordine di ricarica non esistente",
    "recharge_withdraw_failure_0017": "Solo le ricariche in attesa possono essere eliminate",
    "recharge_withdraw_failure_0018": "Solo le ricariche in attesa possono essere verificate",
    "recharge_withdraw_failure_0019": "Solo le approvate possono essere sbloccate",
    "recharge_withdraw_failure_0020": "Il numero di versione non può essere vuoto",
    "recharge_withdraw_failure_0021": "Errore nei parametri",
    "recharge_withdraw_failure_0022": "I dati attuali sono stati aggiornati",
    "recharge_withdraw_failure_0023": "ID non può essere vuoto",
    "recharge_withdraw_failure_0024": "Solo le ricariche in stato bloccato possono essere verificate",
    "recharge_withdraw_failure_0025": "Solo le richieste di prelievo in attesa possono essere bloccate",
    "recharge_withdraw_failure_0026": "Solo in attesa di prelievo o bloccate possono essere annullate",
    "recharge_withdraw_failure_0027": "Quantità di sblocco non può essere vuota",
    "recharge_withdraw_failure_0028": "Motivo di sblocco non può essere vuoto",
    "recharge_withdraw_failure_0029": "Nota di sblocco non può essere vuota",
    "recharge_withdraw_failure_0030": "Quantità di prelievo non può essere vuota",
    "recharge_withdraw_failure_0031": "Indirizzo di prelievo non può essere vuoto",
    "recharge_withdraw_failure_0032": "Commissione non può essere vuota",
    "recharge_withdraw_failure_0033": "Commissione anomala",
    "recharge_withdraw_failure_0034": "Dati non esistenti",
    "recharge_withdraw_failure_0035": "Questo asset non può essere sbloccato",
    "recharge_withdraw_failure_0036": "Indirizzo di prelievo non corretto",
    "recharge_withdraw_failure_0041": "Questo account è stato bloccato per il prelievo, contattare il supporto",
    "recharge_withdraw_failure_0042": "Numero massimo di prelievi giornalieri raggiunto",
    "recharge_withdraw_failure_0043": "Quantità totale di prelievi supera il limite giornaliero",
    "recharge_withdraw_failure_0044": "Errore nel recupero delle informazioni sul livello VIP",
    "recharge_withdraw_failure_0045": "Errore nel recupero delle informazioni sulla valuta del commerciante",
    "recharge_withdraw_failure_0046": "Prelievo vietato entro 24 ore dopo il reset della password!",
    "recharge_withdraw_failure_0048": "Errore nel recupero del valore massimo di prelievo",
    "recharge_withdraw_failure_0049": "Errore nel recupero del valore minimo di prelievo",
    "recharge_withdraw_failure_0050": "La quantità inserita deve essere inferiore al limite massimo",
    "recharge_withdraw_failure_0051": "La quantità inserita deve essere superiore al limite minimo",
    "recharge_withdraw_msg_status_0001": "In attesa di prelievo",
    "recharge_withdraw_msg_status_0002": "In invio",
    "recharge_withdraw_msg_status_0003": "Prelievo riuscito",
    "recharge_withdraw_msg_status_0004": "Annullato",
    "recharge_withdraw_msg_status_0005": "In verifica",
    "recharge_withdraw_msg_status_0006": "Ricarica riuscita",
    "recharge_withdraw_msg_status_0007": "Verificato",
    "recharge_withdraw_msg_type_0001": "Ricarica",
    "recharge_withdraw_msg_type_0002": "Prelievo",
    "recharge_withdraw_msg_withdraw_type_0001": "Trasferimento tra piattaforme",
    "recharge_withdraw_msg_withdraw_type_0002": "Prelievo esterno",
    "record": "Registro",
    "record_the_video": "Clicca per registrare video",
    "recording": "Registrazione",
    "redeem_now": "Riscatta ora",
    "redeemed": "Già riscattato",
    "redeeming": "Riscattando",
    "redemption_amount": "Quantità riscattata",
    "redemption_time": "Tempo di riscatto",
    "referrer": "Referente",
    "register": "Registrati",
    "registered_successfully": "Registrazione avvenuta con successo",
    "registration_award_details": "Dettagli premio registrazione",
    "registration_incentives": "Incentivi registrazione",
    "registration_time": "Tempo di registrazione",
    "regular": "Regolare",
    "regular_return": "Ritorno regolare mensile",
    "release": "Pubblica",
    "release_operation": "Pubblica ordine, clicca qui!",
    "release_the_order": "Pubblica ordine",
    "release_time": "Tempo di emissione",
    "remark": "Nota",
    "remember_state": "Ricorda stato di accesso",
    "repeal": "Annulla",
    "reset": "Reimposta",
    "reset_login_password": "Modifica password di accesso",
    "reset_pass": "Reimposta password",
    "residue": "Residuo",
    "retrieve_the_deposit": "Recupera deposito",
    "return_amount": "Quantità di ritorno",
    "return_method": "Metodo di ritorno",
    "return_the_principal": "Ritorna capitale",
    "revenue_details": "Dettagli guadagno",
    "revocation_all": "Annulla tutto",
    "reward_amount": "Quantità premio",
    "reward_locked": "Premio bloccato",
    "reward_status": "Stato premio",
    "reward_type": "Tipo di premio",
    "rewards_record": "Registro premi",
    "right_now_register": "Registrazione nuovi utenti",
    "roll_out": "Trasferisci",
    "save": "Salva",
    "save_coin": "Deposita",
    "save_coin_count": "Quantità depositata",
    "save_coin_days": "Giorni di deposito",
    "save_coin_duration": "Durata deposito",
    "save_currency": "Deposita valuta",
    "save_money_amount": "Quantità totale depositata",
    "save_successfully": "Salvato con successo",
    "save_the_image": "Scarica immagine",
    "scan_QR_code": "Non scansionare codici QR sconosciuti",
    "scan_result": "Risultato scansione",
    "search": "Cerca",
    "search_coin_placeholder": "Cerca valuta di interesse",
    "search_keyword": "Parola chiave",
    "search_name": "Cerca nome",
    "search_result": "Risultato ricerca",
    "search_trade_pair_placeholder": "Cerca coppia di scambio di interesse",
    "security_center": "Centro sicurezza",
    "security_level": "Livello sicurezza",
    "select_date_of_borth": "Seleziona data di nascita",
    "select_delete_warn": "Seleziona avviso da eliminare",
    "sell": "Vendi",
    "sell_1": "Vendi",
    "sell_order": "Ordine di vendita",
    "sell_quantity": "Quantità da vendere",
    "sell_rate": "Commissione di vendita",
    "seller": "Venditore",
    "seller_phone": "Telefono venditore",
    "send": "Invia",
    "senior_certification": "Certificazione avanzata",
    "senior_certification1": "Certificazione avanzata",
    "service": "Servizio clienti QQ:",
    "service_charge": "Commissione",
    "session_timeout": "Sessione scaduta, accedi di nuovo",
    "set_Password": "Modifica",
    "set_gesture_pass": "Imposta password gestuale",
    "set_login_password": "Imposta password di accesso",
    "setting": "Impostazioni",
    "setting_alipay": "Imposta Alipay",
    "setting_bank_card": "Imposta carta bancaria",
    "setting_message_warn_tip": "*Attiva per ricevere avvisi, disattiva per non ricevere.",
    "setting_paypal": "Imposta PayPal",
    "setting_paypassword": "Imposta password di transazione",
    "setting_wechat": "Imposta WeChat",
    "setting_western_union": "Imposta Western Union",
    "share_invitation_link": "Condividi link di invito",
    "share_link": "Condividi link con amici",
    "share_to_do": "Condividi a",
    "shift_to": "Trasferisci a",
    "single_least": "Importo minimo singolo inferiore a {}",
    "single_minimum": "Minimo singolo",
    "single_more": "Importo minimo singolo superiore a {}",
    "skip": "Salta",
    "small_exchange": "Piccola conversione",
    "small_exchange_tip": "*Nota: Puoi convertire solo una volta in 24 ore per asset sotto {}. Asset non scambiabili temporaneamente.",
    "small_exchange_tip-1": "*Nota: Prezzi di mercato variabili. Asset non scambiabili temporaneamente.",
    "small_exchange_valuation": "Valutazione",
    "small_exchange_valuation-1": "Valutazione BTC",
    "smallest_unit_price": "Prezzo PUSH inferiore al minimo {}",
    "so adjusted to": "multiplo intero di, sarà automaticamente aggiustato a",
    "sold_out": "Non disponibile",
    "start time": "Tempo di inizio",
    "start_date": "Data di inizio",
    "start_time": "Tempo di inizio",
    "statistics_report": "Rapporto statistico",
    "status": "Stato",
    "submit": "Invia video",
    "submitBtn": "Invia",
    "succeed": "Successo",
    "successful_invitation": "Invito riuscito",
    "successfully_received": "Ricevuto con successo",
    "successfully_set": "Impostato con successo",
    "super_partner": "Super partner",
    "switch_account": "Cambia account",
    "system_top_up": "Ricarica sistema",
    "system_unlock": "Sblocco sistema",
    "tab_assets": "Asset",
    "tab_home": "Home",
    "tab_markets": "Mercati",
    "tab_swaps": "Contratti",
    "tab_trade": "Scambio",
    "taking_pictures": "Scattare foto",
    "telegraph": "Gruppo Telegram:",
    "temporary_not_online_contact_service": "Non online, contatta il supporto",
    "the_day": "Giorno",
    "the_event_is_over": "Evento concluso",
    "the_latest_price": "Ultimo prezzo",
    "the_password_is_correct": "Password corretta",
    "theme": "Tema",
    "this_month": "Questo mese",
    "this_week": "Questa settimana",
    "ticket": "Biglietto",
    "time_of_payment": "Tempo di pagamento",
    "time_the_input": "{0}h senza inserire password di transazione",
    "timeout": "Scaduto",
    "timeout_affirm": "Scaduto senza conferma",
    "timeout_deal": "Scaduto, concluso automaticamente",
    "timeout_to_cancel": "Scaduto, annullato automaticamente",
    "to_confirm": "Per confermare",
    "total_buyer": "Totale venditori",
    "total_count": "Totale",
    "total_exchange": "Totale scambio",
    "total_lock_amount": "Quantità totale bloccata",
    "total_money": "Importo totale",
    "total_return": "Ritorno totale previsto",
    "trade_fee": "Commissione di scambio",
    "trade_type": "Tipo di scambio",
    "trading_currency": "Valuta di scambio",
    "trading_fiat_currency": "Valuta fiat",
    "trading_instructions": "Istruzioni di scambio",
    "trading_on": "Scambio su",
    "trading_pair_abbreviation": "Inserisci abbreviazione coppia di scambio",
    "trading_this_month": "Scambi di questo mese",
    "trading_volume": "Volume di scambio",
    "transaction_description": "Descrizione della transazione",
    "transaction_password": "Password di transazione",
    "transaction_price": "Prezzo di transazione",
    "transfer_number": "Quantità trasferita",
    "try_it_now": "Provalo ora",
    "type": "Tipo",
    "unable_to_access_album": "Dispositivo non supporta accesso all'album",
    "unaccalimed": "Non riscattato",
    "unavailable": "Non disponibile",
    "under_review": "In revisione",
    "univalence": "Prezzo unitario",
    "unlock_details": "Dettagli sblocco",
    "unlock_mode": "Modalità sblocco",
    "unlock_period": "Periodo di sblocco",
    "unlock_plan": "Piano di sblocco",
    "unlock_plan_unlock": "Sblocca",
    "unlock_record": "Registro sblocco",
    "unlock_time": "Tempo di sblocco",
    "unlocked": "Sbloccato",
    "unredeemed": "Non riscattato",
    "unverified": "Non verificato",
    "up_to_three": "Massimo tre attivi",
    "upgrade": "Aggiorna",
    "upload_again": "Ricarica",
    "upload_front": "Carica fronte documento",
    "upload_identity_card": "Carica documento d'identità",
    "upload_image_success": "Immagine caricata con successo",
    "upload_information_as_required": "Carica dati secondo i requisiti, altrimenti verifica fallita",
    "upload_passport": "Carica il tuo passaporto",
    "upload_passport_info_picture": "Carica foto pagina informativa passaporto",
    "upload_reverse": "Carica retro documento",
    "ups_and_downs": "Variazione odierna",
    "ups_downs_habit": "Abitudine di variazione",
    "usable": "Utilizzabile",
    "used": "Utilizzato",
    "user-bind-failure": "Errore di associazione referente",
    "user-bind-success": "Associazione referente riuscita",
    "user-collection-error": "Errore",
    "user-country-code-not-empty": "Codice internazionale non può essere vuoto",
    "user-disable-trade": "Account attuale non può scambiare, contattare supporto!",
    "user-down-notbind": "Utente non può associare utenti inferiori",
    "user-email-fail-often": "Invio email frequente, riprovare dopo 120 secondi",
    "user-email-not-empty": "Indirizzo email non può essere vuoto",
    "user-email-verification-code-out": "Codice verifica email scaduto, invia di nuovo",
    "user-fail-advanced-auth": "Verifica avanzata fallita",
    "user-fail-advanced-authed": "Utente già verificato avanzato",
    "user-fail-assetnotenough-error": "Asset insufficienti",
    "user-fail-bank-count-error": "Massimo tre metodi di pagamento attivi",
    "user-fail-bank-inotc": "Metodo di pagamento con ordini in OTC, non disabilitabile",
    "user-fail-cardno-repeat-error": "Numero documento già in uso",
    "user-fail-email-code": "Codice verifica email errato",
    "user-fail-google-binded": "Google già associato",
    "user-fail-google-code": "Codice verifica Google errato",
    "user-fail-inviter-notexist": "Referente non esiste",
    "user-fail-login-disable": "Account attuale bloccato, contattare supporto!",
    "user-fail-login-lock": "Account bloccato",
    "user-fail-login-password": "Nome utente o password errati, tentativi rimasti: {}",
    "user-fail-mail-binded": "Email già associata",
    "user-fail-not-realname": "Effettua prima la verifica dell'identità",
    "user-fail-oldpassword-error": "Password originale errata",
    "user-fail-partner-not-found": "Partner non esiste",
    "user-fail-partnernotfound": "Partner non esiste",
    "user-fail-password": "Nome utente o password errati",
    "user-fail-password-blank": "Password non può essere vuota",
    "user-fail-password-blank1": "Conferma password non può essere vuota",
    "user-fail-password-repeat": "Nuova password uguale alla precedente, riprova!",
    "user-fail-password-thin": "Forza password insufficiente",
    "user-fail-passwword-eq-paypassword": "Password di transazione uguale alla password di accesso, riprova",
    "user-fail-paypassword-error": "Password di pagamento errata",
    "user-fail-paypassword-notset": "Imposta prima la password di pagamento",
    "user-fail-paypassword-set": "Password di pagamento impostata",
    "user-fail-phone-binded": "Numero di telefono già associato",
    "user-fail-realname-auth": "Verifica identità fallita",
    "user-fail-realname-authed": "Utente già verificato",
    "user-fail-realname-notset": "Effettua prima la verifica dell'identità",
    "user-fail-reg": "Registrazione fallita, errore server",
    "user-fail-reg-mail": "Formato email non corretto",
    "user-fail-reg-mail-exist": "Email già registrata",
    "user-fail-reg-phone-exist": "Numero di telefono già registrato",
    "user-fail-securitycount": "Mantenere almeno un metodo di verifica",
    "user-fail-sms-code": "Codice verifica SMS errato",
    "user-fail-sms-code-new": "Codice SMS nuovo numero errato",
    "user-fail-sms-code-old": "Codice SMS vecchio numero errato",
    "user-fail-usernameisblank": "Nome utente non può essere vuoto",
    "user-id-or-nationCode—is-null": "ID utente o codice internazionale vuoti",
    "user-login-success": "Accesso riuscito",
    "user-mail-fail": "Invio email fallito",
    "user-mail-success": "Email inviata con successo",
    "user-not-exist": "Utente non esiste",
    "user-notopen-success": "Non ancora attivato",
    "user-phone-not-empty": "Numero di telefono non può essere vuoto",
    "user-sms-fail": "Invio fallito, errore server",
    "user-sms-fail-often": "Invio SMS frequente",
    "user-sms-success": "SMS inviato con successo",
    "user-sms-verification-code-out": "Codice verifica scaduto, invia di nuovo",
    "user-success-advanced-auth": "Verifica avanzata riuscita",
    "user-success-realname-auth": "Verifica identità riuscita",
    "user-validate-code-fail": "Codice verifica errato",
    "user-validate-fail": "Verifica fallita",
    "user-vip-common-fail": "Acquisto VIP fallito",
    "user_agreement": "Accetta l'accordo di servizio",
    "user_collection_cencel_success": "Cancellato",
    "user_collection_insert_success": "Aggiunto ai preferiti",
    "user_pay_disable_state": "Password di transazione errata, account bloccato per 2 ore",
    "user_pay_is_error": "Password di transazione errata, tentativi rimasti: {}, bloccato per 2 ore",
    "user_pay_password_not_setting": "Password di transazione non impostata, impostala prima",
    "user_protocol": "Protocollo utente",
    "user_service_agreement": "Accordo di servizio utente",
    "user_vip_has_expire": "VIP scaduto, impossibile aggiornare!",
    "user_vip_update_failure": "Non supporta rinnovo o aggiornamento, contattare supporto!",
    "valid_identity_card": "Inserisci un numero documento valido",
    "valuation": "Valutazione deposito",
    "verification_code_error": "Codice verifica errato",
    "verification_failed_001": "Verifica fallita",
    "verification_finish": "Verifica completata",
    "version_updating": "Aggiornamento versione",
    "versions": "Versioni",
    "vertical_version": "Versione verticale",
    "view_activity": "Visualizza attività",
    "view_lock": "Visualizza blocco",
    "volamount": "Acquirente deve aver completato {} transazioni",
    "volamount_sell": "Venditore deve aver completato {} transazioni",
    "vote": "Vota",
    "vote_add_coin": "Vota per aggiungere moneta",
    "vote_return": "Restituisci",
    "voting_coin": "Moneta votante",
    "voting_number": "Numero di voti",
    "voting_time": "Tempo di voto",
    "wait_buyer_payment": "In attesa pagamento acquirente",
    "wait_for_payment": "In attesa di pagamento",
    "waiting_for_receiving": "In attesa conferma pagamento venditore",
    "warm_prompt": "Nota bene",
    "warning_setTitle": "Impostazioni avviso",
    "wechat_account": "Account WeChat",
    "wechat_upload": "*Metodo di upload codice QR WeChat: Apri WeChat > Io > Portafoglio > Ricevi pagamenti > Salva immagine, carica il codice ricevuto.",
    "week": "Settimana",
    "welcome": "Benvenuto su BitMatrix",
    "welcome_to": "Benvenuto a",
    "winning_record": "Registro vincente",
    "withdrawal_amount": "Limite prelievo giornaliero",
    "withdrawal_charge": "Commissione prelievo",
    "xilian": "Western Union",
    "yesterday_income": "Guadagno di ieri",
    "you_have_selected": "Hai selezionato",
    "Your closing number": "La tua quantità di chiusura è inferiore al minimo!",
    "Your have pending Cross orders": "Hai ordini cross pendenti, annulla tutti gli ordini prima di modificare la leva",
    "Your have pending isolated orders": "Hai ordini isolati pendenti, annulla tutti gli ordini prima di modificare la leva",
    "Home page apps": "Applicazioni homepage",
    "Home_Edit": "Modifica",
    "recently_deal01": "Transazioni",
    "depth01": "Profondità",
    "depth02": "Grafico di profondità",
    "discount_currency_introduction01": "Introduzione alla valuta",
    "Info001": "Introduzione",
    "Avg cost": "Prezzo medio",
    "Long position": "Posizione lunga",
    "Short position": "Posizione corta",
    "Hold on": "Attendere",
    "Done": "Fatto",
    "new_version": "Nuova versione disponibile",
    "Announcement": "Annuncio",
    "View details": "Visualizza dettagli",
    "Option Assets": "Attivi opzioni",
    "Option Account": "Account opzioni",
    "Option": "Opzione",
    "1M": "1 min",
    "5M": "5 min",
    "15M": "15 min",
    "30M": "30 min",
    "1H": "1 h",
    "Delivery Time": "Tempo fino alla consegna",
    "Call": "Acquisto",
    "Put": "Vendita",
    "Draw": "Pareggio",
    "Draw2": "Pareggio",
    "Call1": "Lungo",
    "Put1": "Corto",
    "Draw1": "Pareggio",
    "Buy-2": "Acquista",
    "Rate of return": "Tasso di rendimento",
    "Change": "Variazione",
    "Buy Option": "Acquista opzione",
    "Awaiting": "In attesa di consegna",
    "Delivery": "Le mie consegne",
    "Checkout": "Registro consegna",
    "Front": "Corrente",
    "Back": "Prossima",
    "Coming soon": "In arrivo",
    "delivery settlement": "Liquidazione in corso",
    "Ongoing": "In acquisto",
    "Pending purchase": "In attesa di acquisto",
    "Billing Amount": "Quantità di liquidazione",
    "Amount": "Quantità acquisto",
    "Delivery result": "Risultato consegna",
    "Delivery time": "Tempo di consegna",
    "Up": "Aumento",
    "Down": "Diminuzione",
    "lookUp": "Acquista",
    "lookDown": "Vendi",
    "Delivery details": "Dettagli consegna",
    "Index Composition": "Composizione indice",
    "Exchange": "Borsa",
    "Change2": "Variazione",
    "Weights": "Pesi",
    "Order number": "Numero ordine",
    "Option Session": "Sessione opzioni",
    "Buy Time": "Tempo acquisto",
    "Opening time": "Orario apertura",
    "Bet Type": "Tipo di acquisto",
    "Delivery Price": "Prezzo di consegna",
    "Settlement Currency": "Valuta di liquidazione",
    "K-line": "Grafico K",
    "UpSelect": "Selezione aumento",
    "DownSelect": "Selezione diminuzione",
    "Front has ended, please place another order": "La sessione è finita, effettua un nuovo ordine",
    "Successfully ordered": "Ordine effettuato con successo",
    "Insufficient balance": "Saldo insufficiente",
    "Quantity purchased must be greater than the minimum limit": "Quantità acquistata deve superare il minimo",
    "Quantity purchased must be less than the maximum limit": "Quantità acquistata deve essere inferiore al massimo",
    "The current time is not in the purchase time range": "L'orario attuale non è nel range di acquisto",
    "expected": "Guadagno previsto",
    "To_a_game": "Un'altra partita",
    "Settlement Result": "Risultato liquidazione",
    "Delivery-1": "Consegna",
    "Ups and downs": "Variazione",
    "duration": "Durata",
    "Option funds daybook": "Movimenti fondi opzioni",
    "Open innovative options trading": "Apri trading opzioni innovative",
    "Confirm Open": "Conferma apertura",
    "Option Agree Content": "Per proteggere i tuoi diritti, leggi attentamente il 'Contratto di utilizzo del trading di opzioni innovative' prima di aprire",
    "Option Agree Content footer": "'Contratto di utilizzo del trading di opzioni innovative'",
    "Option Agree Content header": "Per proteggere i tuoi diritti, leggi attentamente",
    "Service upgrade, please try again later!": "Aggiornamento servizio, riprova più tardi!",
    "month": "Mese",
    "Invitation": "Invito",
    "Markets": "Mercati",
    "Swaps": "Contratti",
    "Trade": "Scambia",
    "Trade-1": "Vai a scambiare",
    "Assets": "Attivi",
    "Buy": "Acquista",
    "Help": "Aiuto",
    "Service": "Servizio clienti",
    "Activity": "Attività",
    "Communtiy": "Comunità",
    "No search results": "Nessun risultato trovato",
    "The option account asset is 0, please transfer from the wallet account to the option account first.": "L'asset dell'account opzioni è 0, trasferisci prima dal portafoglio.",
    "Perpetual account assets are 0, please transfer from wallet account to perpetual account first.": "L'asset dell'account perpetuo è 0, trasferisci prima dal portafoglio.",
    "Financing": "Investimenti",
    "Load": "Carica",
    "Replay001": "Ripeti",
    "Constellation Program": "Presentazione - Programma Constellation",
    "Constellation Program2": "Presentazione - Finanza Constellation",
    "rate rankings": "Classifica tasso di vincita",
    "rate rankings2": "Stelle della finanza di questa settimana",
    "CXT Quotes": "Mercato",
    "Digital asset trading tool service platform": "Piattaforma servizi strumenti trading asset digitali",
    "Service_home": "Servizio",
    "Strategic cooperation": "Cooperazione strategica",
    "Financing assets": "Attivi finanziari",
    "Remove 0 from the first number when registering!": "Rimuovi 0 dal primo numero durante la registrazione!",
    "Are you sure you want to quit": "Sei sicuro di voler uscire?",
    "Opening Price": "Prezzo di apertura",
    "Closing Price": "Prezzo di chiusura",
    "Formula interpretation": "Interpretazione formula",
    "Note: If the input box is left blank": "Nota: Se il campo è vuoto, i dati non sono disponibili",
    "Option Sessions": "Sessioni opzioni",
    "Result": "Risultato",
    "When data for an exchange is not available": "*Se i dati per una borsa non sono disponibili, il peso è 0%. Se le fonti valide ≤ 2, è un pareggio.",
    "Assume that the index is derived from the opening prices of five exchanges": "Assumiamo che l'indice derivi dai prezzi di apertura di cinque borse: A, B, C, D, E; i prezzi di chiusura sono A`, B`, C`, D`, E`.",
    "The proportion of those five exchanges are": "I pesi delle cinque borse sono: a%, b%, c%, d%, e%.",
    "Stream bureau": "Pareggio",
    "(valid data source ≤ 2)": "(fonte dati valida ≤ 2)",
    "Option calculator": "Calcolatore opzioni",
    "Round selection": "Selezione round",
    "Select option": "Seleziona opzione",
    "Average opening price": "Prezzo medio apertura",
    "Average closing price": "Prezzo medio chiusura",
    "Calculate now": "Calcola ora",
    "Calculation Results": "Risultati calcolo",
    "Calculating Time": "Tempo di calcolo",
    "Management": "Gestione",
    "Other_home": "Altro",
    "All applications": "Tutte le applicazioni",
    "Boon": "Vantaggi",
    "Coin_Address_draw": "Indirizzo prelievo",
    "Congratulations": "Congratulazioni",
    "Profit": "Profitto",
    "Gainers": "Guadagni",
    "Drop": "Perdita",
    "Order Price": "Prezzo acquisto",
    "Order Price1": "Prezzo di acquisto",
    "Sec Swap": "Contratto secondario",
    "Expected Return": "Rendimento previsto",
    "Sec Swap Assets": "Attivi contratto secondario",
    "Sec Swap Bill": "Movimenti contratto secondario",
    "Sec Swap Account": "Account contratto secondario",
    "Go Login": "Vai al login",
    "NSO Session": "Sessione NSO",
    "Wallet Value1": "Valore portafoglio",
    "Spot Value1": "Valore scambio",
    "PERP Value1": "Valore perpetuo",
    "Option Value1": "Valore opzioni",
    "Fiat Value1": "Portafoglio fiat",
    "Pledge_Amount": "Quantità pegno",
    "total_return2": "Rendimento totale",
    "Violation_Loss": "Penale",
    "Cycle": "Ciclo",
    "Locked2": "Bloccato",
    "Time_Left": "Tempo rimanente",
    "Cancel_Pledge": "Annulla pegno",
    "countDown_day": "Giorni",
    "Entrust2": "Ordine fiduciario",
    "All2": "Tutti",
    "Ongoing2": "In corso",
    "Near2": "Inizia a breve",
    "Ended2": "Terminato",
    "Cancel the pledge?": "Sei sicuro di voler annullare il pegno?",
    "Completed2": "Completato",
    "Cancelled2": "Annullato",
    "Lock-up mining": "Mining bloccato",
    "Rules Description": "Descrizione regole",
    "Today Expected": "Guadagno previsto oggi",
    "Order Escrow": "Ordini in custodia",
    "Minimum": "Minimo",
    "Daily Return": "Rendimento giornaliero",
    "Buy-3": "Acquista ora",
    "Minimum Amount": "Importo minimo pegno",
    "Lockup Period": "Periodo di blocco",
    "Confirm Pledge": "Conferma pegno",
    "Buy-4": "Acquista",
    "Conversion Starter": "Inizio conversione",
    "Listing": "Lista",
    "Total Target": "Obiettivo totale",
    "Conversion Ratio": "Rapporto di conversione",
    "Single Limited": "Limite individuale",
    "Participate Order": "Ordine di partecipazione",
   "Fundraised": "Fondi raccolti",
    "Current Progress": "Progresso attuale",
    "Time Left": "Tempo rimanente",
    "Start Time": "Orario di inizio",
    "End Time": "Orario di fine",
    "Conversion Amount": "Quantità da acquistare",
    "Conversion Now": "Acquista ora",
    "Conditions": "Condizioni di partecipazione",
    "Preheating": "In fase di riscaldamento",
    "Records": "Storico acquisti",
    "Select": "Seleziona",
    "To": "A",
    "Active Currency": "Valuta attiva",
    "Amount-3": "Importo",
    "Conversion Time": "Tempo di acquisto",
    "Arrival Time": "Tempo di arrivo",
    "Not Arrived": "Non ancora arrivato",
    "Hours-1": "Ore",
    "Risk warning: It is displayed": "Avviso di rischio: asset innovativi, con caratteristiche delle criptovalute. Valuta attentamente il prezzo e le decisioni d'investimento!",
    "Week-1": "Settimana",
    "S-1": "Secondi",
    "minutes-2": "Minuti",
    "You must pass basic KYC": "Devi completare la verifica KYC di base",
    "You must pass advanced KYC": "Devi completare la verifica KYC avanzata",
    "Account order exceeds": "L'ordine dell'account supera",
    "Suspend": "Sospendi transazione",
    "Successfully": "Acquisto riuscito",
    "Ongoing-1": "Acquisto in corso",
    "Failed": "Acquisto fallito",
    "New_Token_Subscribe": "Acquisto nuovo token",
    "More_awaits_you": "Maggiore ricchezza ti attende",
    "Distance Start": "Tempo rimanente all'inizio",
    "day-2": "Giorni",
    "Must Eligible": "Deve soddisfare i requisiti",
    "Custodial funds": "Fondos custoditi",
    "Default Ratio": "Percentuale di penale",
    "Wallet Available": "Saldo disponibile",
    "Done-2": "Completato",
    "Eligible Amount": "Quantità approvata",
    "Eligible Price": "Importo approvato",
    "New Token Subscribe": "Sottoscrizione nuovo token",
    "Subscribe Now": "Sottoscrivi ora",
    "Review Time": "Tempo di revisione",
    "Review Passed": "Revisione approvata",
    "Review Failed": "Revisione non approvata",
    "Not Reviewed": "Non ancora rivisto",
    "Denial Reason": "Motivo di rifiuto",
    "If not received, please contact service": "Se non ricevuto, contattare il servizio clienti!",
    "Returned to wallet": "Restituito al portafoglio",
    "From wallet assets": "Disponibile dal portafoglio",
    "Day-3": "Giorni",
    "Sec Swap asset is 0, please transfer from wallet account to Sec Swap first": "Il saldo del conto di swap secondario è 0, trasferisci prima dal portafoglio",
    "Settlement": "In fase di regolamento",
    "Completed": "Regolamento completato",
    "Password Setting": "Impostazione password",
    "Set password and email": "Imposta password ed email",
    "Email Settings": "Impostazioni email",
    "First-time registered users mus": "Gli utenti registrati devono impostare password ed email",
    "Driver ID": "Patente di guida",
    "Preview": "Richiesta di prezzo",
    "cancelTime": "Tempo di cancellazione",
    "Total_assets_valuation": "Valutazione totale degli asset",
    "Invite Friends": "Invita amici",
    "RedGreen": "Rosso aumenta, verde diminuisce",
    "GreenRed": "Verde aumenta, rosso diminuisce",
    "Color Preference": "Preferenza di colore",
    "Fuzzy Information": "Informazioni poco chiare",
    "user_bind_xilain_trade_pwd": "Inserisci la password di trading",
    "login_please_input1": "Inserisci il codice SMS",
    "user_please_input9": "Inserisci il codice Google",
    "user_link_name": "Nome della catena",
    "user_coin_bring_together": "Combinare",
    "chongbi0": "Istruzioni per il deposito:",
    "chongbi1": "1. È vietato depositare asset diversi da {0}, qualsiasi asset non {1} non sarà recuperabile.",
    "chongbi2": "2. Invia la quantità di {1} da depositare all'indirizzo tramite il client {0} o il portafoglio online. Dopo l'invio, il sistema accrediterà automaticamente i {2} dopo {2} conferme.",
    "chongbi3": "3. È possibile effettuare più depositi allo stesso indirizzo senza problemi.",
    "chongbi4": "4. Importo minimo di deposito {0}.",
    "currency_withdrawal_rate_rules": "Regole per il prelievo di {0}:",
    "currency_withdrawal_rate_rules1": "1. Per la sicurezza dei fondi, la piattaforma potrebbe confermare tramite telefono il tuo prelievo;",
    "currency_withdrawal_rate_rules2": "2. I depositi di {0} sono consentiti solo dopo {1} conferme;",
    "currency_withdrawal_rate_rules3": "3. L'importo prelevabile ≤ saldo disponibile - asset non confermati;",
    "currency_withdrawal_rate_rules4": "4. Massimo {0} prelievi al giorno, massimo {1} per prelievo.",
    "currency_transaction_password": "Password",
    "long_press_to_save_to_album": "Tieni premuto per salvare nell'album",
    "the_fee_is_outside_the_range": "La commissione è al di fuori dell'intervallo",
    "start_and_end_date": "Data di inizio e fine",
    "The_withdrawal_amount_cannot_be_less": "L'importo del prelievo non può essere inferiore a {0}",
    "withdrawal_details": "Dettagli prelievo",
    "RegisterRemove0": "Rimuovi 0 dal primo numero alla registrazione",
    "closeLockUpPopup": "La cancellazione dello staking comporterà una penale di {0} giorni, l'importo della penale è '{1}{2}', l'importo effettivo accreditato è '{3}{4}'.",
    "LockUpDuan": "Mining con blocco",
    "NewTokenSubscribe": "Acquisto nuovo token",
    "MoreWealthAwaitsYou": "Maggiore ricchezza ti aspetta",
    "TopGainers": "Classifica guadagni",
    "Newest": "Classifica nuovi token",
    "Hot": "Classifica popolare",
    "DatChange": "Variazione giornaliera",

    "SetPassword": "Imposta password",
    "comm_password": "Password di trading",
    "unraise_recovering_password": "Dopo il recupero della password, non sarà possibile prelevare per 1 ora",
    "PDleaseUseSafariBrowser": "Si prega di utilizzare il browser Safari",
    "ADddToDesktop": "Aggiungi BitMatrix al desktop",
    "CDlickBelow": "Clicca qui sotto",
    "SDelectAddToHomeScreen": "E poi seleziona aggiungi alla schermata principale",
    "ADddToDesktop2": "Aggiungi BitMatrix al desktop",
    "CDommonbrowserAddMethod": "Metodo di aggiunta per browser comuni",
    "user_senior_text1": "Nota",
    "user_senior_text2": "La dimensione del file immagine non deve superare 5M! Il formato deve essere jpg, bmp, png, ecc.! ",
    "user_senior_text3": "Assicurati che l'immagine sia senza filigrana, pulita, informazioni chiare, foto del viso completa, senza testo riflesso! Non modificare l'immagine!",
    "user_senior_text4": "In mano",
    "user_senior_text5": "Foto documento",
    "user_senior_text6": ": Devi tenere in mano il tuo",
    "user_senior_text7": "Data di oggi",
    "user_senior_text8": "e",
    "user_senior_text9": "UID",
    "user_senior_text10": "su un foglio di carta e scattare una foto non speculare.",
    "user_senior_text11": ", con l'altra mano tieni un foglio bianco con scritto",
    "user_senior_text12": "Scrivi a mano il seguente contenuto, se non corrisponde non verrà approvato:",
    "user_senior_text13": "\"Sono a conoscenza e accetto i rischi di investimento\"",
    "user_senior_text14": ".",
    "user_ID_card": "Carta d'identità",
    "user_passport": "Passaporto",
    "Driver_ID": "Patente di guida",
    "user_senior_upload3": "Carica foto documento + UID + data",
    "ServiceUpgrade": "Aggiornamento del servizio",
    "ReferencePrice": "Prezzo di riferimento",
    "referenceTips": "Non il prezzo finale, solo per riferimento",
    "NewCoinsLaunch": "Lancio nuovi token",
    "CurrentlyPurchased": "Attualmente acquistato",
    "TotalMarketValue": "Valore di mercato totale",
    "IEORatio": "Rapporto di acquisto",
    "CurrencyIntroduction": "Introduzione alla valuta",
    "ConvertNow": "Acquista ora",
    "RemainingAmount": "Importo rimanente",
    "referenceTips": "Basato sul prezzo effettivo di acquisto",
    "ServiceUpgrade": "Aggiornamento del servizio",
    "LastDays7": "Ultimi 7 giorni",
    "LastDays30": "Ultimi 30 giorni",
    "LastDays90": "Ultimi 90 giorni",
    "LockUpTips": "Nota: gli asset non approvati {0}{1} sono stati restituiti al tuo portafoglio.",
    "PromotionalMining": "Mining promozionale",

    "hide_coin": "Nascondi valute inferiori a {0} BTC",
    "GoldAssets": "Asset in oro",
    "ModifyEmail": "Modifica email",
    "NewEmail": "Nuova email",
    "NewEmailAddress": "Inserisci nuova email",
    "VerifyNewEmail": "Verifica nuova email",
    "EnterNewEmailCode": "Inserisci codice della nuova email",
    "VerifyOldEmail": "Verifica vecchia email",
    "EnterOldEmailCode": "Inserisci codice della vecchia email",
    "VerifyLoginPassword": "Verifica password di accesso",
    "EnterLoginPassword": "Inserisci password di accesso",
    "loan": "Centro prestiti",
    "loanText": "Offriamo un servizio di prestito sicuro e affidabile, per vari scopi, con maggiore liquidità, senza vendere asset esistenti.",
    "loanQuery": "Consultazione prestiti",
    "Stop_surplus_tips": "Quando il prezzo di mercato raggiunge {}, verrà attivato l'ordine di take profit, previsto guadagno {}",
    "Stop_loss_tips": "Quando il prezzo di mercato raggiunge {}, verrà attivato l'ordine di stop loss, previsto perdita {}",
    "Stop_surplus_tips1": "Quando il prezzo di mercato raggiunge {}, verrà attivato l'ordine di take profit, previsto perdita {}",
    "Stop_loss_tips1": "Quando il prezzo di mercato raggiunge {}, verrà attivato l'ordine di stop loss, previsto guadagno {}",
    "Countdown": "Conto alla rovescia",
    "contract_After_adjustment": "Dopo l'aggiustamento, verranno trasferiti {} USDT come nuovo margine",
    "contract_subject_to": "(soggetto all'importo effettivo trasferito)",
    "contract_the_used_margin": "Dopo l'aggiustamento, il margine utilizzato diminuirà di {} USDT, la parte ridotta rimarrà nella posizione,",
    "contract_transferred_to": "Può essere trasferito al saldo dell'account",
    "contract_After_adjustment_q": "Dopo l'aggiustamento, il margine occupato nella posizione aumenterà di {} USDT",
    "contract_the_used_margin_q": "Dopo l'aggiustamento, il margine occupato nella posizione diminuirà di {} USDT",
    "Please1": "Inserisci il prezzo di attivazione del take profit",
    "Please2": "Inserisci il prezzo dell'ordine di take profit",
    "Please3": "Inserisci il prezzo di attivazione dello stop loss",
    "Please4": "Inserisci il prezzo dell'ordine di stop loss",
    "Stop profit6": "Il prezzo di attivazione del take profit deve essere maggiore del prezzo di transazione più recente",
    "stop loss7": "Il prezzo di attivazione dello stop loss deve essere inferiore al prezzo di transazione più recente",
    "Please8": "Inserisci la quantità",
    "Please9": "Nessuna quantità disponibile per chiudere",
    "CountdownTime": "Tempo di conto alla rovescia",
    "h1": "Richiesta di partner",
    "h2": "Classifica commissioni",
    "h3": "Diventando partner puoi guadagnare",
    "h4": "Requisiti di upgrade",
    "h5": "Per acquistare un partner di livello base è necessario pagare",
    "h6": "Saldo attuale",
    "h7": "Partner originario",
    "h8": "Commissioni",
    "h9": "Aggiorna ora",
    "h10": "Sei già al livello massimo",
    "h11": "Bonus commissioni",
    "h12": "Classifica commissioni",
    "h13": "Numero di membri del team",
    "h14": "Registro commissioni",
    "h15": "Persone invitate",
    "h16": "Già regolato",
    "h17": "Non ancora regolato",
    "h18": "Possedere subordinati",
    "h19": "Da pagare",
    "h20": "Aggiorna",
    "h21": "Dettagli commissioni",
    "h22": "Classifica",
    "h23": "Utente",
    "h24": "Bonus commissioni",
    "h25": "Livello attuale consente di ottenere",
    "h26": "Accumulo totale delle commissioni convertite in USDT.",
    "h27": "Necessario invitare {} utenti normali",
    "h28": "Hai già invitato {} utenti normali validi",
    "h29": "Necessario un team di {} persone",
    "h30": "Numero di membri del team {}",
    "Stop profit10": "Il prezzo di attivazione del take profit deve essere inferiore al prezzo di transazione più recente",
    "stop loss10": "Il prezzo di attivazione dello stop loss deve essere superiore al prezzo di transazione più recente",
    "Earn": "Investimenti",
    "Simple": "Guadagnare e spendere senza problemi",
    "Low1": "Basso rischio, rendimento stabile",
    "Low2": "Basso rischio, rendimento solido, riscattabile in qualsiasi momento",
    "CurrentAccount": "Conto di risparmio",
    "Stable": "Crescita sicura, strumento per aumentare la ricchezza",
    "EarnLocked": "Investimenti a lungo termine",
    "Searchpopular": "Investimenti facili, goditi i rendimenti in qualsiasi momento",
    "Annualized": "Rendimento annualizzato {}%, asset sicuri, alta resa",
    "CoinsLC": "Valute",
    "PurchaseLC": "Importo di acquisto",
    "PurchaseLC2": "({} min.)",
    "Maximum": "Massimo",
    "EstimatedInterest": "Interesse stimato",
    "RuleLC": "Regole",
    "SubscriptionTime": "Tempo di sottoscrizione",
    "Interestaccrualtime": "Tempo di accumulo interesse",
    "RevenueTime": "Tempo di rendimento",
    "RedemptionCycle": "Ciclo di riscatto",
    "ReturnNow": "Restituisci ora",
    "BuyLC": "Acquista",
    "PurchaseSuccessful": "Acquisto riuscito",
    "DepositLC": "Deposito",
    "Yesterday": "Rendimento di ieri",
    "TotalLC": "Totale depositato",
    "TotalReturn": "Rendimento totale",
    "DepositValuation": "Valutazione del deposito",
    "DepositDetail": "Dettagli del deposito",
    "RevenueDetails": "Dettagli sui rendimenti",
    "DepositType": "Tipo di deposito",
    "ReturnMethod": "Metodo di rimborso",
    "DepositAmount": "Quantità depositata",
    "APR": "Tasso annuale",
    "Expected": "Rendimento previsto",
    "DepositCycle": "Ciclo di deposito",
    "CreationTime": "Tempo di creazione",
    "EarnProfit": "Interesse corrente",
    "DepositCost": "Capitale di deposito",
    "TotalRelease": "Totale rilasciato",
    "ReleaseTime": "Tempo di rilascio",
    "Flexible": "Flessibile",
    "Fixed": "Fisso",
    "Freeze": "Congelato",
    "Completed3": "Completato",
    "Cancelled": "Annullato",
    "DefaultSettlement": "Regolamento per inadempimento",
    "PurchaseAmount": "Importo di acquisto (senza limiti)",
    "RetrieveDeposited": "Recupera deposito",
    "PreviewConversion": "Anteprima risultati di cambio",
    "ToEnsureThe": "Per garantire la sicurezza del tuo account e soddisfare requisiti di trading superiori, dobbiamo effettuare una verifica avanzata della tua identità.",
    "ToVerification": "Clicca il pulsante per contattare il servizio clienti per la verifica.",
    "ToContact": "Contatta il servizio clienti per la verifica",
    "otc_upload_picture3":"Le immagini da caricare possono essere solo in formato jpeg/jpg/png/bmp!",
    "CertificationDifficulties":"C'è un problema con la verifica?",
    "comm_nickname_rule1": "Non può superare 15 lettere.",
    "comm_nickname_rule2": "Non può contenere spazi e caratteri speciali.",
    contract_entrust_failure_0080 : "L'importo dell'ordine non può essere inferiore a 100 USDT.",
}