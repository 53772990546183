<template>

  <!-- 密码弹框 -->
  <van-popup v-model="show" position="bottom" :close-on-click-overlay="false">
    <div class="icon_close" @click="onClose">
      <img src="@/assets/img/assets/icon_close.png" alt="">
    </div>
    <div class="title">{{$t('M.currency_transaction_password')}}</div>
    <van-field v-model="payCode" center type="password" :placeholder="$t('M.user_bind_xilain_trade_pwd')" :formatter="formatterNum" maxlength="6">
    </van-field>

    <div class="btn_wrap">
      <div class="button submit_button" @click="onSubmit">{{$t('M.affirm')}}</div>
    </div>

  </van-popup>
</template>

<script>
import { mapGetters, } from 'vuex'

export default {
  name: 'OnlyPassword',
  props: {
    show: {
      type: Boolean,
    }
  },
  computed: {
    ...mapGetters([
      'token', 'coinList', 'user', 'partnerId'
    ])
  },
  data() {
    return {
      payCode: '',
      valiBtn: this.$t("M.send"),
      disabledCode: false,
      valiBtn2: this.$t("M.send"),
      disabledCode2: false,
    };
  },

  mounted() {
    this.reSetForm()
    this.payCode = ''
  },

  methods: {
    reSetForm() {
      this.payCode = ''
    },
    onClose() {
      this.$emit('onClose')
      this.payCode = ''
    },

    // 确认按钮
    onSubmit() {
      if (this.payCode.length != 6) {
        this.$notify(this.$t("M.enter_password"))
        return
      }
      this.$emit('onSubmit', this.payCode)
      this.payCode = ''
    },
    formatterNum(value) {
      return value.replace(/[^0-9]/g, '')
    }
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  max-height: 70%;
  min-height: 30%;
  padding: 0 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .title {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
  }
  .van-field {
    width: 100%;
    height: 43px;
    border-radius: 4px;
    border: 1px solid #c7ccd8;
    color: #9299a9;
    margin-bottom: 15px;
    .van-field__control {
      color: #9299a9;
    }
  }
  .btn_wrap {
    margin: 0;
  }
}
.van-button {
  padding: 0;
  border: none;
  color: #00b897;
  background-color: transparent;
}
.van-button--disabled {
  color: #00b897;
}
</style>