<template>
    <div class="popupCertain3">
            <div class="title">
              <div class="titletop">{{itemData.tradeName}}</div>
              <div class="titlebottom"><span style="color: #00b897;">·{{tradeMode(itemData.openType)}}·{{ tradeMode(itemData.positionsType) }}·{{ itemData.lever }}X</span></div>
            </div>
            <van-row class="row">
                <van-col span="20">
                    <van-field class="pperinput" :style="{border:showtab?'1px solid #999':'none',background:'#f8f8f8'}" :disabled="showtab"  v-model="number1" type="number" :placeholder="$t('M.input_price')">
                        <template #extra>
                            <span style="color: #a2a2a2;border-left: 1px solid #ddd;padding: 0 10px;">{{itemData.buyCoinNickname}}</span>
                        </template>
                    </van-field>
                </van-col>
                <van-col class="right" span="4">
                    <div @click="tabClick"  class="Nactive">
                        {{$t('M.market_price')}}
                        <img v-show="showtab" style="position: absolute;right:0;bottom:0" src="../../assets/img/assets/active-y.svg" />
                    </div>
                </van-col>
            </van-row>
            <div class="sub">
                {{$t('M.Latest Filled price')}}<span style="color: #00b897;">{{symbolData.last}}</span>
            </div>
            <van-field class="pperinput" style="border: 1px solid #00b897;border-bottom: none;border-radius:0;"  v-model="number" type="number" :placeholder="$t('M.AMT')">
                    <template #extra>
                        <span style="color: #a2a2a2;border-left: 1px solid #00b897;padding: 0 10px;">{{itemData.sellCoinNickname}}</span>
                    </template>
            </van-field>
            <div class="activeNum" >
              <div @click="changeNumber(activeNum = 10)" :class="activeNum=='10'?'active':''">10%</div>
              <div @click="changeNumber(activeNum = 20)" :class="activeNum=='20'?'active':''" >20%</div>
              <div @click="changeNumber(activeNum = 50)" :class="activeNum=='50'?'active':''" >50%</div>
              <div @click="changeNumber(activeNum = 80)" :class="activeNum=='80'?'active':''" >80%</div>
              <div @click="changeNumber(activeNum = 100)" :class="activeNum=='100'?'active':''" >100%</div>
            </div>
            <div class="sub">
                <span v-if="number<itemData.maxHoldAmount">{{symbolData.faceValue}}{{$t('M.so adjusted to')}}{{numTit}}</span>
                <span v-else>{{$t('M.The maximum flatable quantity is')}}{{ itemData.maxHoldAmount }}</span>
            </div>
            <div class="sub">
                {{$t('M.Amount can flat')}}：{{itemData.holdAmount}}{{ itemData.sellCoinNickname }}
            </div>
            <div class="buttoncon">
              <van-button color="#00b897" size="small" round block @click="saveCloseEntrust">{{$t('M.Pingclose')}}</van-button>
            </div>
        </div>
  </template>

  <script>
  import {Toast} from 'vant';
  import {mapGetters} from "vuex";

  export default {
    computed: {
    ...mapGetters(["isLogin", "user", "lang"]),
    },
    props:['itemData','latestDone'],
    data(){
        return{
            showtab:true,
            activeNum:'0.1',
            number:'',
            number1:'',
          numTit: 0,
          symbolData: {
            last: 0,
            faceValue:0,
          }
        }
    },
    mounted(){
      // this.changeNumber()

      Toast.loading({
        message: this.$t('M.loading'),
        forbidClick: true,
        duration: 0,
      });
      this.$api.contractGetTradeTypeTicker({
        tradeName: this.itemData.symbol,
        partnerId: "472437831826935808",
      }).then((data) => {
        this.symbolData = data.data
        Toast.clear();
      })
    },
    methods:{
      saveCloseEntrust(){
        if (!this.isLogin)return
        // console.log(this);
        let a =Number(this.latestDone)+(Number(this.latestDone)/100*10)
        let b =Number(this.latestDone)-(Number(this.latestDone)/100*10)
        console.log(a,b,this.number1);
        if(this.number-0>this.itemData.maxHoldAmount-0){
          return Toast(this.$t('M.otc_failure_0085'))
        }
        if(!this.showtab){
          if(this.number1<a && this.number1>b){
          }else{
            return Toast(this.$t('M.entrust_failure_0025'))
          }
        }
        let params = {
            positionId:this.itemData.id,
            positionsType:this.itemData.positionsType,
            tradeMode:this.itemData.openType === "OPEN_UP" ? "CLOSE_UP" : "CLOSE_DOWN",
            closeType:this.showtab?'MARKET':'LIMIT',
            price:this.number1,
            count:this.number,
            tradeId:this.itemData.contractCoinId,
            source:'IOS'
          }
        console.log('params',params)
          this.$api.saveCloseEntrust(params).then(res=>{
            this.$emit('dreg')
            this.$emit('update')
            this.$emit('upMoney')
          })

      },
        tabClick(){
            this.showtab = !this.showtab;
            if(this.showtab){
              this.number1 = 0
              this.activeNum = ''
            }
        },
        tradeMode(key){
            if(key =='OPEN_UP') return this.$t('M.Open long')
            if(key =='OPEN_DOWN') return this.$t('M.Open short')
            if(key =='CLOSE_UP') return this.$t('M.Close long')
            if(key =='CLOSE_DOWN') return this.$t('M.Close short')
            if(key =='BY') return this.$t('M.Isolated')
            if(key =='TOTAL') return this.$t('M.Cross')
        },
        changeNumber(){
        let a = ((this.itemData.holdAmount-0)/100*(this.activeNum-0))
          console.log(a)
        this.number = this.closestMultiple(a,this.symbolData.faceValue)
          console.log(this.number)
        this.numTit = this.closestMultiple(a,this.symbolData.faceValue)
        if(this.activeNum == 100){
          this.number = this.itemData.holdAmount
          this.numTit = this.itemData.holdAmount
        }
        // this.getPreForcePrice()
      },
      closestMultiple(n, x){
       if (x > n){
        return x;
       }else{
          n = n + parseInt(x / 2, 10);
          n = n - (n % x);
          return n;
       }
      }
    }
  }
  </script>

  <style lang="scss">
  .popupCertain3{
    padding: 10px;
    .van-tabs__wrap{
        height: auto;
    }
    .subTitle{
        padding-bottom: 10px;
        color: #a2a2a2;
        border-bottom: 1px solid #eee;
    }
    .tab1{
        margin: 10px 0;
        .van-tabs__nav--card{
            margin: 0;
            border-radius: 5px;
        }
    }
    .row{
        padding: 10px 0;
        .Nactive{
          position: relative;
            padding: 5px 10px;
            height: 33px;
            background: #f8f8f8;
            color: #000;
            margin-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            position: relative;
        }
        .active{
          position: relative;
            padding: 5px 10px;
            height: 33px;
            background: #f8f8f8;
            color: #10c9aa;
            margin-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            position: relative;
        }
        .right{
            text-align: right;
        }
    }
    .buttoncon{
      margin: 15px 0;
    }
    .describe{
      margin-top: 10px;
      padding: 5px;
      background-color: #f8f8f8;
      div{
        padding: 5px;
      }
    }
    .title{
      border-bottom: 1px solid #e3e5eb;
      margin-bottom: 10px;
      .titletop{
        color: #000;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 5px;
      }
      .titlebottom{
        // font-size: 16px;
        color: #000;
        padding-bottom: 5px;
      }
    }
    .sub{
      padding-bottom: 10px;
      color: #a2a2a2;
      span{
        color: #00b897;
      }
    }
    .pper{
        margin: 10px 0;
    }
    .pperinput{
            background: #f8f8f8;
            border-radius: 5px;
        }
        .activeNum{
          margin: 0 0 10px 0;
      border: 1px solid #00b897;
      display: flex;
      div{
        flex: 1;
        height: 30px;
        border-right: 1px solid #00b897;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      div:last-child{
        border-right: none;
      }
      .active{
        color: #fff;
        background-color: #00b897;
      }
    }
  }
  </style>
